import React from 'react'
import { ConfirmPasswordTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

const ConfirmPasswordPage = () => {
  useRenderLogger('ConfirmPasswordPage')
  const { authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const checkPassword = authStore.checkPassword || (() => {})

  return (
    <>
      {
        currentUser && !currentUser.isExistPassword ? (
          <Redirect to="/p/register/password" />
        ) : (
          false
        )
      }

      {/* 이메일 & 패스워드 앱 개발 완료되면 적용 */}
      {/* {currentUser && !currentUser.isExistPassword ? (
        <Redirect to="/p/register/password" />
      ) : (
        currentUser &&
        !(currentUser.isEmailAuth && currentUser.isPhoneAuth) && (
          <Redirect to="/p/exchange/need-auth" />
        )
      )} */}
      <ConfirmPasswordTemplate checkPassword={checkPassword} />
    </>
  )
}

export default observer(ConfirmPasswordPage)
