import { Div, ImageIcon } from '@components/atoms'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const MobileCastPlayer = ({ cast }) => {
  const { castStore } = useStore()

  const createCastHistory = castStore.createCastHistory || (() => {})

  const playerRef = useRef(null)

  const [isFirstPlay, setIsFirstPlay] = useState(false)

  const [url, setUrl] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)
  const [volume, setVolume] = useState(0.5)
  const [playedSeconds, setPlayedSeconds] = useState(0)
  const [isTouchingProgressBar, setIsTouchingProgressBar] = useState(false)

  const handlePlay = useCallback(
    e => {
      if (cast?.id && !isFirstPlay) {
        setIsFirstPlay(true)
        createCastHistory(cast.id)
      }
    },
    [setIsFirstPlay, isFirstPlay, createCastHistory, cast?.id],
  )

  const handleStart = e => {}
  const handleBuffer = e => {}
  const handleBufferEnd = e => {}
  const handleError = e => {}
  const handlePause = e => {}

  const handleProgress = useCallback(
    e => {
      // console.log('jhlim', 'handleProgress', isTouchingProgressBar)
      if (playing && !isTouchingProgressBar) {
        setPlayedSeconds(e.playedSeconds)
      }
    },
    [playing, setPlayedSeconds, isTouchingProgressBar],
  )
  const handleSeek = useCallback(
    sec => {
      setPlayedSeconds(sec)
    },
    [setPlayedSeconds],
  )
  const handleEnded = useCallback(() => {
    setPlaying(false)
    setPlayedSeconds(0)
  }, [setPlaying, setPlayedSeconds])
  // 재생 시간 사용자가 조절하는 함수
  const handleSeekToTouchStart = useCallback(
    e => {
      // console.log('handleSeekToTouchStart', parseFloat(e?.touches?.[0]?.clientX), parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width), parseFloat(e?.touches?.[0]?.target?.max), parseFloat(e?.touches?.[0]?.clientX) / parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width) * parseFloat(e?.touches?.[0]?.target?.max), e)
      if(!isNaN(parseFloat(e?.touches?.[0]?.clientX)) && !isNaN(parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width)) && !isNaN(parseFloat(e?.touches?.[0]?.target?.max))) {
        setPlayedSeconds(parseFloat(e?.touches?.[0]?.clientX) / parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width) * parseFloat(e?.touches?.[0]?.target?.max))
        setIsTouchingProgressBar(true)
      }
    },
    [setPlayedSeconds, setIsTouchingProgressBar],
  )

  const handleSeekToTouchMove = useCallback(
    e => {
      // console.log('handleSeekToTouchMove', parseFloat(e?.touches?.[0]?.clientX), parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width), parseFloat(e?.touches?.[0]?.target?.max), parseFloat(e?.touches?.[0]?.clientX) / parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width) * parseFloat(e?.touches?.[0]?.target?.max), e)
      if(!isNaN(parseFloat(e?.touches?.[0]?.clientX)) && !isNaN(parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width)) && !isNaN(parseFloat(e?.touches?.[0]?.target?.max))) {
        setPlayedSeconds(parseFloat(e?.touches?.[0]?.clientX) / parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width) * parseFloat(e?.touches?.[0]?.target?.max))
        setIsTouchingProgressBar(true)
      }
    },
    [setPlayedSeconds, setIsTouchingProgressBar],
  )

  const handleSeekToTouchEnd = useCallback(
    e => {
      // console.log('handleSeekToTouchEnd', playedSeconds, e?.target?.value, parseFloat(e?.touches?.[0]?.clientX), parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width), parseFloat(e?.touches?.[0]?.target?.max), parseFloat(e?.touches?.[0]?.clientX) / parseFloat(e?.touches?.[0]?.target?.getBoundingClientRect()?.width) * parseFloat(e?.touches?.[0]?.target?.max), e)
      // playerRef.current.seekTo(playedSeconds)
      if(!isNaN(parseFloat(e?.target?.value))) {
        playerRef.current.seekTo(parseFloat(e?.target?.value))
      }
      setIsTouchingProgressBar(false)
    },
    // [playedSeconds, setIsTouchingProgressBar],
    [setIsTouchingProgressBar],
  )

  const handleNext15Sec = useCallback(() => {
    playerRef
      && playerRef.current
      && playerRef.current.seekTo(parseFloat(playedSeconds + 15))
  }, [playedSeconds])

  const handlePrev15Sec = useCallback(() => {
    playerRef
      && playerRef.current
      && playerRef.current.seekTo(parseFloat(playedSeconds - 15))
  }, [playedSeconds])

  useEffect(() => {
    if (cast) {
      if (cast.LiveRoomRecording) {
        setUrl(
          /* chrome web mobile view 만 안되는듯? 추가적인 테스트가 없어서 주석처리 */
          // cast.LiveRoomRecording?.m3u8Url
          ''
          || cast.LiveRoomRecording?.mp3Url
          || cast.LiveRoomRecording?.mp4Url,
        )
      }
      else {
        setUrl(
          /* chrome web view 만 안되는듯? 추가적인 테스트가 없어서 주석처리 */
          // cast.AudioFile?.m3u8Url
          ''
          || cast.AudioFile?.mp3Url
          || cast.AudioFile?.originalUrl,
        )
      }
    }
  }, [cast])

  return (
    <Div position="relative" width="100%" height="200px">
      <ControllerView
        imageUrl={cast?.imageUrl}
        playing={playing}
        setPlaying={setPlaying}
        handlePrev15Sec={handlePrev15Sec}
        handleNext15Sec={handleNext15Sec}
      />
      {
        cast && url && (
          <>
            <ReactPlayer
            // lazy load 제거 => 이전 재생 도중, 교체 url이 너무 빠르게 교체되는 버그 픽스
            // 2022. 05. 12
            // Copotter
              disabledeferredloading="true"
              playsinline
              ref={playerRef}
              config={
                {
                  file: {
                    // 중요! hls option일 때, 원하는 위치부터 load 해주는 기능
                    // => 기존의 load error로 재생 멈추는 로직이 50% 가량 없어졌어요!
                    hlsOptions: {
                      startPosition: 0,
                    },
                    attributes: {
                      // crossOrigin: 'true',
                      controlsList: 'nodownload', // <- this is the important bit
                      id: 'audio-elem ent',
                      autoPlay: false,
                    },
                  },
                }
              }
              className="react-player"
              width="100"
              height="100"
              url={url}
              pip={false}
              playing={playing}
              controls={false}
              // light={false} // false여야 autoPlay
              loop={false}
              playbackRate={1.0}
              volume={volume}
              muted={muted}
              onPlay={handlePlay}
              onStart={handleStart}
              onBuffer={handleBuffer}
              onBufferEnd={handleBufferEnd}
              onError={handleError}
              // onEnablePIP={handleEnablePIP}
              // onDisablePIP={handleDisablePIP}
              onPause={handlePause}
              onEnded={handleEnded}
              onProgress={handleProgress}
              onSeek={handleSeek}
            // style={{ display: 'none' }}
            />

            <ProgressBar
              type="range"
              min={0}
              max={cast?.duration}
              step="any"
              value={playedSeconds || 0}
              onChange={(e) => {}}

              /**
               * mobile web 대응
               */
              onTouchStart={handleSeekToTouchStart}
              onTouchMove={handleSeekToTouchMove}
              onTouchEnd={handleSeekToTouchEnd}

              // onChange={(e) => console.log('onChange', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}

              // onTouchStart={(e) => console.log('onTouchStart', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onTouchMove={(e) => console.log('onTouchMove', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onTouchEnd={(e) => console.log('onTouchEnd', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onTouchCancel={(e) => console.log('onTouchCancel', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}

              // onMouseUp={(e) => console.log('onMouseUp', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onMouseMove={(e) => console.log('onMouseMove', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onMouseDown={(e) => console.log('onMouseDown', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
              // onProgress={(e) => console.log('onProgress', e?.touches?.[0]?.clientX, e?.touches?.[0]?.screenY, e?.target, e?.target?.value, e)}
            />
          </>
        )
      }
    </Div>
  )
}

export default MobileCastPlayer

const ProgressBar = styled.input`
  background: ${({ value, max }) => `
  linear-gradient(
    to right,
    #5b49f3 0%,
    #5b49f3 ${(value / max) * 100}%,
    #d4d4d4 ${(value / max) * 100}%,
    #d4d4d4 100%
  )
  `};

  position: absolute;
  z-index: 10;
  bottom: -2px;
  left: -2px;

  border-radius: 2px;
  height: 2px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;

  -webkit-appearance: none;

  ::-webkit-slider-thumb {
    background-color: #5b49f3;
    border: solid 5px #5b49f3;
    border-radius: 0;

    height: 10px;
    width: 10px;
    border-radius: 50%;
    -webkit-appearance: none;
  }
`

// const ProgressBar = styled.input.attrs(props => ({
//   // 고정적인 Props를 전달할 수 있습니다.
//   type: 'range',
//   // 혹은 다이나믹한 Props도 전달할 수 있습니다.
//   // size: props.size || "100px",
// }))`
//   -webkit-appearance: none;

//   overflow: hidden;
//   position: absolute;
//   z-index: 10;
//   bottom: 0px;
//   left: 0;
//   width: 100vw;
//   height: 4px;
//   margin: 0;
//   background-color: ${colors.brown_grey};
//   cursor: pointer;

//   :focus {
//     outline: none;
//   }

//   ::-webkit-slider-runnable-track {
//     background: #ddd;
//   }

//   /*
//   * 1. Set to 0 width and remove border for a slider without a thumb
//   */
//   ::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     width: 0px; /* 1 */
//     height: 0px;
//     background: ${colors.brown_grey};
//     box-shadow: -100vw 0 0 100vw ${colors.primary};
//   }

//   ::-moz-range-track {
//     height: 0px;
//     background: #ddd;
//   }

//   ::-moz-range-thumb {
//     background: ${colors.brown_grey};
//     height: 0px;
//     width: 0px;
//     border-radius: 0 !important;
//     box-shadow: -100vw 0 0 100vw ${colors.primary};
//     box-sizing: border-box;
//   }

//   ::-ms-fill-lower {
//     background: ${colors.primary};
//   }

//   ::-ms-thumb {
//     background: ${colors.brown_grey};
//     height: 0px;
//     width: 0px;
//     box-sizing: border-box;
//   }

//   ::-ms-ticks-after {
//     display: none;
//   }

//   ::-ms-ticks-before {
//     display: none;
//   }

//   ::-ms-track {
//     background: #ddd;
//     color: transparent;
//     height: 0px;
//     border: none;
//   }

//   ::-ms-tooltip {
//     display: none;
//   }
// `

const ControllerView = ({
  imageUrl,
  playing,
  setPlaying,
  handlePrev15Sec,
  handleNext15Sec,
}) => {
  const [showController, setIsShowController] = useState(true)

  useEffect(() => {
    if (playing) {
      setTimeout(() => {
        setIsShowController(false)
      }, 2000)
    }
    else {
      setIsShowController(true)
    }
  }, [playing])

  return (
    <>
      <Div
        zIndex={1}
        position="absolute"
        width="100%"
        height="100%"
        left="0"
        top="0"
        background={`url(${imageUrl}) center / cover no-repeat`}
      />
      <Div
        position="absolute"
        width="100%"
        height="100%"
        left="0"
        top="0"
        backgroundColor={colors.black_24}
        opacity={!showController ? 0 : '0.56'}
        zIndex={2}
        transition="0.35s"
        onClick={() => setIsShowController(true)}
      />
      {
        showController && (
          <>
            <Div position="absolute" width="100%" height="100%" zIndex={3}>
              <Div
                position="absolute"
                width="100%"
                height="100%"
                left="0"
                top="0"
                zIndex={1}
                onClick={
                  () => {
                    setIsShowController(false)
                  }
                }
              />
              <ImageIcon
                name="prev15SecWhite"
                position="absolute"
                top="calc(50% - 16px)"
                left="calc(25% - 16px)"
                width="32px"
                height="32px"
                cursor="pointer"
                zIndex={2}
                onClick={handlePrev15Sec}
              />
              <ImageIcon
                name={playing ? 'pauseBtnWhite' : 'playBtnWhite'}
                position="absolute"
                top="calc(50% - 16px)"
                left="calc(50% - 16px)"
                width="32px"
                height="32px"
                cursor="pointer"
                zIndex={2}
                onClick={() => setPlaying(!playing)}
              />
              <ImageIcon
                name="next15SecWhite"
                position="absolute"
                top="calc(50% - 16px)"
                left="calc(75% - 16px)"
                width="32px"
                height="32px"
                cursor="pointer"
                zIndex={2}
                onClick={handleNext15Sec}
              />
            </Div>
          </>
        )
      }
    </>
  )
}
