import { upload } from '@utils/'
import { Network } from '..'

export default class MixtapeNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getMixtape = async (id, params) => {
    if (!id) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${id}`,
        'get',
        params,
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork getMixtape error: ', err)
    }
  }
  getMixtapeFromLinkKey = async (linkKey, params) => {
    if (!linkKey) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/_linkKey/${linkKey}`,
        'get',
        params,
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork getMixtapeFromLinkKey error: ', err)
    }
  }
  getMixtapeList = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes',
        'get',
        params,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork getMixtapeList error: ', err)
    }
  }

  // 흐름 리스트 카운트
  // will deprecated
  getMixtapesCount = async params => {
    try {
      const { data } = await this.network._axiosApi(
        '/mixtapes/counts',
        'get',
        params,
      )
      return (data && data.totalCount) || []
    }
    catch (error) {
      console.log('MixtapeNetwork getMixtapesCount error: ', error)
    }
  }

  putMixtape = async (id, params) => {
    if (!id) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${id}`,
        'put',
        params,
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork putMixtape error: ', err)
    }
  }

  postMixtape = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes',
        'post',
        params,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork postMixtape error: ', err)
    }
  }

  postMixtapeSongList = async (mixtapeId, valueList) => {
    try {
      if (isNaN(parseInt(mixtapeId)) || !Array.isArray(valueList)) {
        return
      }

      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${mixtapeId}/songs`,
        'post',
        valueList,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork postMixtapeSongList error: ', err)
    }
  }

  deleteMixtape = async id => {
    if (!id) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${id}`,
        'delete',
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork deleteMixtape error: ', err)
    }
  }

  // 흐름 이미지 업로드
  uploadMixtapeImage = async formData => {
    try {
      const { data } = await upload(
        '/uploads/mixtapes/',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('MixtapeNetwork uploadMixtapeImage error: ', error)
    }
  }

  getListenedMixtapeList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes/home/personalization',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork getListenedMixtapeList error: ', err)
    }
  }

  getPersonalizationMixtapeList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes/home/personalization',
        'get',
        where,
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork getPersonalizationMixtapeList error: ', err)
    }
  }

  /**
   * users-like-mixtapes
   */
  getLikeList = async where => {
    try {
      if (!where.userId) {
        return
      }

      const { data } = await this.network._axiosApiAuth(
        '/users-like-mixtapes',
        'get',
        {
          // __include: 'Mixtapes',
          // __order: 'createdAtDesc',
          ...where,
        },
      )
      return data.map(elem => elem.Mixtape) || []
    }
    catch (err) {
      console.log('MixtapeNetwork getUsersLikeMixtapesList error: ', err)
    }
  }

  getPopularRealtimeMixtapeList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes/popular/realtime',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork getPopularRealtimeMixtapeList error: ', err)
    }
  }

  getHomePopularityMixtapeList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/mixtapes/home/popularity',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork getHomePopularityMixtapeList error: ', err)
    }
  }

  postMixtapeLike = async mixtapeId => {
    try {
      if (!mixtapeId) {
        return
      }

      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${mixtapeId}/likes`,
        'post',
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork postMixtapeLike error: ', err)
    }
  }

  deleteMixtapeLike = async mixtapeId => {
    if (!mixtapeId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/mixtapes/${mixtapeId}/likes`,
        'delete',
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork deleteMixtapeLike error: ', err)
    }
  }

  /**
   * users-play-mixtapes
   */
  postUsersPlayMixtapesList = async value => {
    if (!value) {
      return
    }
    try {
      const { data } = await this.network._axiosApiAuth(
        '/users-play-mixtapes',
        'post',
        value,
      )
      return data || []
    }
    catch (err) {
      console.log('MixtapeNetwork postUsersPlayMixtapesList error: ', err)
    }
  }
  putUsersPlayMixtapes = async (id, value) => {
    if (!id || !value) {
      return
    }
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users-play-mixtapes/${id}`,
        'put',
        value,
      )
      return data || null
    }
    catch (err) {
      console.log('MixtapeNetwork putUsersPlayMixtapes error: ', err)
    }
  }
}
