import React from 'react'
import styled from 'styled-components'
import { tablet, desktop, fullHD } from '@styles/media'
import { Modal, Color } from '@components/atoms'
import { SwitchSection, MoveBtn, RoundBtn } from '@components/molecules'
import { PricingCard } from '@components/organisms'
import { colors } from '@resources/colors'

const ModalTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  padding: 0 20px 0 20px;
  word-break: keep-all;

  @media ${desktop} {
    font-family: NotoSansCJKkr-Bold;
    font-size: 2.375rem;
  }
`

const ModalDesktopTitle = styled(ModalTitle)`
  display: none;

  @media ${tablet} {
    display: none;
  }

  @media ${desktop} {
    display: block;
  }
`

const ModalMobileTitle = styled(ModalTitle)`
  display: block;

  @media ${tablet} {
    display: block;
  }

  @media ${desktop} {
    display: none;
  }
`

const ModalDescription = styled.p`
  font-size: 1.25rem;
  color: #646464;
  margin-top: 20px;
  text-align: center;
  display: none;

  @media ${tablet} {
    display: none;
  }

  @media ${desktop} {
    display: block;
  }
`

const ModalDesktopContents = styled.div`
  height: 60vh;
  margin: 20px;
  display: none;

  @media ${tablet} {
    display: none;
  }

  @media ${desktop} {
    height: 65vh;
    margin: 0 50px;
    display: block;
  }

  @media ${fullHD} {
    height: 75vh;
    margin: 0 80px;
    display: block;
  }
`

const ModalMobileContents = styled.div`
  display: block;
  padding: 30px 24px 30px 24px;

  @media ${tablet} {
    display: block;
  }

  @media ${desktop} {
    display: none;
  }
`

const PricingList = styled.div`
  display: flex;
  align-items: center;
`

const Plan = styled.p`
  flex: 1;
  font-size: 1.4rem;
  white-space: nowrap;
  padding: 0 20px 0 0;
`

const Price = styled.p`
  flex: 1;
  font-size: 0.7rem;
  text-align: right;
`

const MiddleLine = styled.div`
  border: solid 1px #f4f4f4;
  margin: 14px 0;
`

const MobileDescription = styled.p`
  font-size: 0.6rem;
  color: #949494;
  margin: 12px 0 30px 0;
`

const View = ({ isFirstPlan, isShowing, toggle }) => {
  return (
    <Modal.View isShowing={isShowing} toggle={toggle}>
      {
        !isFirstPlan ? (
          <>
            <ModalDesktopTitle>플랜을 시작해 보세요</ModalDesktopTitle>
            <ModalMobileTitle>
            흐름 서비스의 DJ에게
              <br />
            선곡료를 드리면서
              <br />
            광고 없이 즐기세요
            </ModalMobileTitle>
            <ModalDescription>
            지금 바로 플랜을 시작하고 광고 없는 흐름을 즐겨보세요
            </ModalDescription>
          </>
        ) : (
          <>
            <ModalTitle>플랜을 다시 시작해볼까요?</ModalTitle>
            <ModalDescription>
            지금 다시 시작하고 광고 없이, 더 편하게 즐겨보세요
            </ModalDescription>
          </>
        )
      }

      <ModalMobileContents>
        <PricingList>
          <Plan>
            <Color color="#66bc6a">Standard</Color>
          </Plan>
          <Price>2,900원/ 1개월</Price>
        </PricingList>
        <MiddleLine />
        <PricingList>
          <Plan>
            <Color color="#ff9742">For Student</Color>
          </Plan>
          <Price>2,900원/ 1개월</Price>
        </PricingList>
        <MobileDescription>
          3개월 단위로 구독하면 더 많은 할인 혜택
        </MobileDescription>
        <RoundBtn text="플랜 시작하기" color={colors.primary} />
      </ModalMobileContents>

      <ModalDesktopContents>
        <SwitchSection
          offSwitchName="1개월"
          offSwitchContents={
            <div>
              <PricingCard
                title={<Color color="#66bc6a">Standard</Color>}
                price="3900"
                period="1개월"
                priceDescription="1개 계정"
                description="모든 광고 제거, 별 20개 매달 충전\nDJ 선곡료 지급"
                mainButton={{ text: '플랜 시작하기', color: colors.primary }}
              />
              <PricingCard
                pointWording={
                  <div>
                    <span>학생 인증하면 1,000원 할인!</span>
                  </div>
                }
                title={<Color color="#ff9742">For Student</Color>}
                discountPrice="2900"
                period="1개월"
                priceDescription="1개 계정"
                description="모든 광고 제거, 별 10개 매달 충전\nDJ 선곡료 지급"
                mainButton={{ text: '플랜 시작하기', color: colors.primary }}
              />
            </div>
          }
          onSwitchName="3개월"
          onSwitchContents={
            <div>
              <PricingCard
                title={<Color color="#66bc6a">Standard</Color>}
                originalPrice="11700"
                discountPrice="10900"
                period="3개월"
                priceDescription="1개 계정"
                description="모든 광고 제거, 3개월에 별 60개 충전\nDJ 선곡료 지급"
                mainButton={{ text: '플랜 시작하기', color: colors.primary }}
              />
              <PricingCard
                pointWording={
                  <div>
                    <span>학생 인증하면 1,000원 할인!</span>
                  </div>
                }
                title={<Color color="#ff9742">For Student</Color>}
                originalPrice="8700"
                discountPrice="7900"
                period="3개월"
                priceDescription="1개 계정"
                description="모든 광고 제거, 3개월에 별 30개 충전\nDJ 선곡료 지급"
                mainButton={{ text: '플랜 시작하기', color: colors.primary }}
              />
            </div>
          }
        />
        {
          isFirstPlan && (
            <MoveBtn text="결제 내역 보기" style={{ margin: '50px auto' }} />
          )
        }
        {
          !isFirstPlan && (
            <>
              <Color
                color="#646464"
                style={
                  {
                    display: 'block',
                    textAlign: 'center',
                    marginTop: '30px',
                  }
                }
              >
              플랜 취소 후 다음 결제일이 돌아오기 전에 다시 플랜을 시작할 경우
                <br />
              예정되었던 결제일 이후 결제일 부터 해당 결제금이 청구 됩니다.
              </Color>
              <MoveBtn
                href="https://hreum.me/p/policy/terms"
                text="서비스 이용 주의사항 자세히 보기"
                style={{ margin: '30px auto 50px auto' }}
              />
            </>
          )
        }
      </ModalDesktopContents>
    </Modal.View>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
