import React, { useEffect, useState } from 'react'
import { DjMixtapeListTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'
import { isMobile } from 'react-device-detect'

const DjMixtapeListPage = ({ location }) => {
  useRenderLogger('DjMixtapeListPage')
  const { authStore, songStore, mixtapeStore, playerStore } = useStore()

  /* store */
  const currentUser = authStore.currentUser || null
  const createTagList = songStore.createTagList || null

  const myMixtapeList = mixtapeStore.myMixtapeList || null
  const myMixtapeListCount = mixtapeStore.myMixtapeListCount || null
  const fetchMyMixtapeList = mixtapeStore.fetchMyMixtapeList || (() => {})
  const updateMixtape = mixtapeStore.updateMixtape || (() => {})
  const updatePublishMixtape = mixtapeStore.updatePublishMixtape || (() => {})
  const deleteMixtape = mixtapeStore.deleteMixtape || (() => {})
  const getTempMixtape = mixtapeStore.getTempMixtape || (() => {})
  /* store end */

  const [currentPage, setCurrentPage] = useState(1)
  const { isLoading, setIsLoading } = useTagLoading()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPage > 1) {
        await fetchMyMixtapeList(4 * (currentPage - 1))
      }
      else if (currentPage <= 1) {
        await fetchMyMixtapeList()
      }
      setIsLoading(false)
    }
    fetchData()
  }, [fetchMyMixtapeList, currentPage, setIsLoading])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  /**
   * song
   */
  const fetchSongDetail = songStore.fetchSongDetail || (() => {})
  const fetchMyUploadSongList = songStore.fetchMyUploadSongList || (() => {})
  const uploadSongList = songStore.uploadSongList || null
  const uploadSongListCount = songStore.uploadSongListCount || null
  const removeSong = songStore.removeSong || (() => {})
  const updateSong = songStore.updateSong || (() => {})
  const updateSongInfo = songStore.updateSongInfo || (() => {})
  const _stopPlayer = playerStore._stopPlayer || (() => {})
  const stopPlayer = playerStore.stopPlayer || null
  const currentPlayMixtape = playerStore.currentPlayMixtape || null
  const [currentPageSong, setCurrentPageSong] = useState(1)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPageSong > 1) {
        await fetchMyUploadSongList(10 * (currentPageSong - 1))
      }
      else if (currentPageSong <= 1) {
        await fetchMyUploadSongList()
      }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyUploadSongList, currentPageSong, setIsLoading])

  return (
    <DjMixtapeListTemplate
      isLoading={isLoading}
      myMixtapeList={myMixtapeList}
      myMixtapeListCount={myMixtapeListCount}
      currentUser={currentUser}
      createTagList={createTagList}
      updateMixtape={updateMixtape}
      updatePublishMixtape={updatePublishMixtape}
      deleteMixtape={deleteMixtape}
      getTempMixtape={getTempMixtape}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      /**
       * song
       */
      songs={uploadSongList}
      songsCount={uploadSongListCount}
      removeSong={removeSong}
      fetchSongDetail={fetchSongDetail}
      updateSong={updateSong}
      updateSongInfo={updateSongInfo}
      _stopPlayer={_stopPlayer}
      stopPlayer={stopPlayer}
      currentPlaySong={currentPlayMixtape}
      state={location.state}
      currentPageSong={currentPageSong}
      setCurrentPageSong={setCurrentPageSong}
    />
  )
}

export default observer(DjMixtapeListPage)
