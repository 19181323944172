import React, { useEffect } from 'react'
import { API_URL } from '@consts/'
import { isMobile } from 'react-device-detect'

const StarPaymentForm = ({ paymentParams, submitRef, setPaymentParams }) => {
  useEffect(() => {
    if (paymentParams) {
      submitRef.current.submit()
    }
  }, [paymentParams, submitRef])

  return (
    <div
      style={
        {
          width: '100%',
          height: '200vh',
          background: 'transparent',
          position: 'fixed',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          zIndex: '2000',
        }
      }
    >
      {
        paymentParams && (
          <>
            <div
              style={
                {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  zIndex: 9999,
                }
              }
              onClick={
                e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setPaymentParams(null)
                }
              }
            />
            <iframe
              id="payment-iframe"
              title="플랜 결제하기 - 흐름"
              name="paymentForm"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              style={
                {
                  // // width: '500px',
                  // // height: '620px',
                  // width: '100%',
                  // height: '100%',
                  // overflow: 'hidden',
                  // position: 'fixed',
                  // margin: 0,
                  // padding: 0,
                  // // top: '160px',
                  // // left: '40%',
                  // top: 0,
                  // left: 0,
                  // right: '0',
                  // bottom: '0',
                  // border: 'none',
                  // borderRadius: '16px',
                  // boxShadow: '0 5px 14px 0 rgba(0, 0, 0, 0.16)',
                  // zIndex: '9999',
                  // background: 'white',
                  width: isMobile ? '100vw' : '837px',
                  height: isMobile ? '100vh' : '601px',
                  top: isMobile ? 0 : '160px',
                  left: isMobile ? 0 : '40%',
                  right: '0',
                  bottom: '0',
                  overflow: 'hidden',
                  position: 'fixed',
                  margin: 0,
                  padding: 0,
                  border: 'none',
                  borderRadius: '16px',
                  boxShadow: '0 5px 14px 0 rgba(0, 0, 0, 0.16)',
                  zIndex: '9999',
                  background: 'white',
                }
              }
            />
            <form
              ref={submitRef}
              id="paymentForm"
              method="POST"
              target="paymentForm"
              action={`${API_URL}/purchases/inicis/request/stars`}
              style={{ display: 'none' }}
            >
              {
                Object.keys(paymentParams).map((key, index) => {
                  return (
                    <input
                      key={index}
                      type="hidden"
                      name={key}
                      value={paymentParams[key]}
                    />
                  )
                })
              }

              <input type="submit" value="결제요청" />
            </form>
          </>
        )
      }
    </div>
  )
}

export default StarPaymentForm
