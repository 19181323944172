import React, { useEffect } from 'react'
import { Flex, H3 } from '@components/atoms'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { mobile } from '@styles/media'
import { CastListItem, CastShimmer } from '@components/molecules'
import { observer } from 'mobx-react'

// 전체 영역
const ListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1704px;
  width: 100%;
  margin-bottom: 30px;

  @media ${mobile} {
    min-width: auto;
  }
`

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
`

// // 정렬 버튼
// const SortBtn = styled(Button)`
//   width: 80px;
//   height: 40px;
//   border-radius: 20px;
//   border: ${props => (props.active ? `solid 2px ${colors.primary}` : 'none')};
//   color: ${props =>
//     props.active ? `${colors.primary}` : `${colors.brown_grey}`};
// `

// 리스트 내용 영역
const ListContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

/**
 * 카테고리별 캐스트 리스트 컴포넌트
 */
const CategoryCastList = ({
  offset,
  setOffset,
  haveMore,
  setHaveMore,
  list,
  category,
  title,
  keyword,
  fetchHistoryCastList,
  fetchPopularCastList,
  fetchLatestCastList,
  fetchFollowCastList,
  fetchRecommendationCastList,
  fetchSearchCastList,
}) => {
  const hideIdx = 0
  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(offset + 20)
  }

  useEffect(() => {
    if (offset === 0) {
      if (list && list.length < 20) {
        setHaveMore(false)
      }
      // else if (list && list.length >= 20) {
      //   setHaveMore(true)
      // }
    }
  }, [offset, list, setHaveMore, category])

  useEffect(() => {
    const fetchMoreList = async () => {
      if (offset !== 0) {
        let isMore = true
        switch (category) {
          case 'history':
            isMore = await fetchHistoryCastList()
            setHaveMore(isMore)
            break
          case 'popular':
            isMore = await fetchPopularCastList()
            setHaveMore(isMore)
            break
          case 'latest':
            isMore = await fetchLatestCastList()
            setHaveMore(isMore)
            break
          case 'follow':
            isMore = await fetchFollowCastList()
            setHaveMore(isMore)
            break
          case 'recommendation':
            isMore = await fetchRecommendationCastList()
            setHaveMore(isMore)
            break
          case 'search':
            isMore = await fetchSearchCastList(keyword, {
              limit: 20,
              offset,
            })
            setHaveMore(isMore)
            break
          default:
            break
        }
      }
    }
    fetchMoreList()

    // eslint-disable-next-line
  }, [setHaveMore, category, offset, keyword, fetchPopularCastList])

  return (
    <ListBox style={{ marginTop: category === 'tag' && '16px' }}>
      <ListHeader>
        <H3 type="Bold">{title}</H3>
      </ListHeader>
      {
        <InfiniteScroll
          dataLength={list.length}
          scrollThreshold={0.9}
          next={countOffset}
          hasMore={haveMore}
          hasChildren
          loader={
            <ReactLoading
              type="spin"
              color="black"
              style={
                {
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 50px auto',
                }
              }
            />
          }
          className="scroll_div"
          scrollableTarget="scrollableDiv"
          style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
        >
          <ListContent>
            {
list?.length ? (
  list.map((item, index) => {
    if (index > list.length - hideIdx - 1) return <></>
    return (
      <CastListItem
        item={item}
        key={`${category}-${index}-${item.id}`}
        category={category}
      />
    )
  })
) : (
  <>
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
  </>
)
            }
          </ListContent>
        </InfiniteScroll>
      }
    </ListBox>
  )
}

export default observer(CategoryCastList)
