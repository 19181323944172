import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9070;
  background: white;
  border-radius: 16px;
  /* height: 210px; */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

/**
 * 삭제 / 취소 구조의 팝업 공용 컴포넌트
 * title: 제목
 * body : 내용
 * onDelete : 삭제 핸들러
 * onClose : 취소 핸들러
 * deleteText : 삭제 버튼 워딩 커스텀
 */

const View = ({ isShowing, toggle, src }) => {
  const imgElement = React.useRef(null)
  const [imgWidth, setImgWidth] = React.useState(500)
  const [imgHeight, setImgHeight] = React.useState(500)

  if (!src) {
    return <></>
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <img
                src={src}
                style={{ width: imgWidth, height: imgHeight }}
                alt={'img'}
                ref={imgElement}
                onLoad={
                  () => {
                    const width = imgElement.current.naturalWidth
                    const height = imgElement.current.naturalHeight
                    setImgWidth(width)
                    setImgHeight(height)
                    if (height > width) {
                      setImgHeight(720)
                      setImgWidth((width / height) * 720)
                    }
                    else {
                      setImgWidth(720)
                      setImgHeight((height / width) * 720)
                    }
                  }
                }
              />
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}
