import React from 'react'
import styled from 'styled-components'
import { H4, Caption1, Flex } from '@components/atoms'
import { Table, Pagination, Spin } from 'antd'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import { dateYMD } from '@utils/format'
import { colors } from '@resources/colors'
import { PageLayout } from '..'

type Props = {
  noticeList: any,
  navhNoticeDetail: any,
  _stopPlayer: any,
  stopPlayer: any,
  songsCount: any,
  currentPage: any,
  setCurrentPage: any,
  isLoading: any,
  noticeListCount: number,
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

// 업로드 목록이 있을 시, 흐름 홈
const NoticeListTemplate: React.FC<Props> = ({
  noticeList,
  navhNoticeDetail,
  currentPage,
  setCurrentPage,
  isLoading,
  noticeListCount,
}) => {
  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      width: '500px',
      render: (text, record) => {
        return (
          <Flex
            type="column"
            style={{ alignItems: 'flex-start', cursor: 'pointer' }}
            onClick={() => navhNoticeDetail(record.linkKey)}
          >
            <Caption1 color="#242424">{record.title}</Caption1>
            <Caption1
              type="Regular"
              color={colors.brown_grey}
              style={{ marginTop: 8 }}
            >
              {dateYMD(record.createdAt)}
            </Caption1>
          </Flex>
        )
      },
    },
  ]

  return (
    <PageLayout>
      <ListBox>
        <Flex
          style={
            {
              marginBottom: '40px',
              width: '100%',
              justifyContent: 'center',
              position: 'relative',
            }
          }
        >
          <H4>공지사항</H4>
        </Flex>
        <Table
          columns={columns}
          dataSource={noticeList}
          // pagination={{ pageSize: 10, defaultPageSize: 10 }}
          pagination={false}
          pageSize={10}
          size={'small'}
          loading={
            {
              spinning: isLoading,
              indicator: <Spin size="large" />,
            }
          }
          style={
            {
              backgroundColor: 'white',
              borderRadius: '16px',
              boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
              width: '100%',
              padding: '30px 40px',
            }
          }
          rowKey="id"
        />
        <Pagination
          style={{ marginTop: '20px' }}
          pageSize={10}
          current={currentPage}
          onChange={setCurrentPage}
          total={noticeListCount}
          showSizeChanger={false}
          showQuickJumper={false}
        />
      </ListBox>
    </PageLayout>
  )
}

export default NoticeListTemplate
