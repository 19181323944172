import React, { useCallback, useEffect } from 'react'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import {
  SalonDetailTemplate,
  SalonReserveTemplate,
} from '@components/templates'
import { useHistory, useLocation } from 'react-router-dom'
import { ConfirmPopup, SalonPasswordPopup } from '@components/molecules'
import {
  SalonEditModal,
  SalonMixtapeModal,
  SalonUserModal,
} from '@components/organisms'

const SalonDetailPage = ({ match }) => {
  useRenderLogger('SalonDetailPage')
  const { authStore, liveRoomStore, customerCenterStore } = useStore()
  const history = useHistory()

  const {
    isShowing: showSalon,
    toggle: toggleSalon,
  } = SalonEditModal.useModal()

  const {
    isShowing: showSalonMixtape,
    toggle: toggleSalonMixtape,
  } = SalonMixtapeModal.useModal()

  const {
    isShowing: showConfirm,
    toggle: toggleConfirm,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showPassword,
    toggle: togglePassword,
  } = SalonPasswordPopup.usePopup()

  const {
    currLiveRoom,
    needConfirmGuestFromListener,
    needConfirmHost,
    needConfirmListener,
    showSalonUser,
    toggleSalonUser,
    isJoinedLiveRoom,
    setIsJoinedLiveRoom,
  } = liveRoomStore

  const { currentUser } = authStore

  const currentUserId = currentUser?.id

  const location = useLocation()

  const [confirmTitle, setConfirmTitle] = React.useState('')
  const [confirmBody, setConfirmBody] = React.useState('')
  const [isReadyJoin, setIsReadyJoin] = React.useState('not') // 비공개 살롱 비밀번호 인증 성공 시,
  const [isBlockedLiveRooms, setIsBlockedLiveRooms] = React.useState(false)

  const checkPopupState = useCallback(() => {
    if (showConfirm) return
    // end salon
    // if (!currLiveRoom && isJoinedLiveRoom) {
    //   setConfirmTitle('살롱이 종료되었습니다')
    //   setConfirmBody('')
    //   toggleConfirm()
    //   setIsJoinedLiveRoom(false)
    // }

    // confirm guest
    if (needConfirmGuestFromListener) {
      setConfirmTitle('게스트 신청 승인')
      setConfirmBody(
        '살롱 게스트가 되었습니다.\n마이크를 켜고 대화에 참여하세요',
      )
      toggleConfirm()
    }

    // confirm host
    if (needConfirmHost) {
      setConfirmTitle('호스트 권한 승인')
      setConfirmBody(
        '살롱 호스트가 되었습니다\n살롱의 설정을 변경하고\n참여자를 관리할 수 있습니다',
      )
      toggleConfirm()
    }

    if (needConfirmListener) {
      setConfirmTitle('리스너가 되었습니다')
      setConfirmBody('리스너로 전환되었습니다')
      toggleConfirm()
    }
    // eslint-disable-next-line
  }, [
    setIsJoinedLiveRoom,
    isJoinedLiveRoom,
    currLiveRoom,
    needConfirmGuestFromListener,
    needConfirmHost,
    needConfirmListener,
    // showConfirm,
    // toggleConfirm,
  ])
  React.useEffect(() => {
    checkPopupState()
  }, [checkPopupState])

  React.useEffect(() => {
    const fetchCurrLiveRoom = async () => {
      if (match.params.linkKey) {
        await liveRoomStore.fetchLiveRoomDetailFromLinkKey(match.params.linkKey)
        // 방장일 때, 검증완료
        if (liveRoomStore.currLiveRoom.userId === authStore.currentUser?.id) {
          setIsReadyJoin('completed')
        }
        // 비공개 살롱, 비밀번호 검증
        else if (liveRoomStore.currLiveRoom.isPrivate) {
          togglePassword()
        }
        // 공개 살롱, Join 준비 완료
        else if (!liveRoomStore.currLiveRoom.isPrivate) {
          setIsReadyJoin('completed')
        }
      }
    }

    // 도메인을 통해서 들어왔을 때 || 만들어서 들어온게 아닐 때
    if (!location?.state?.isPassword && !location?.state?.isCreated) {
      fetchCurrLiveRoom()
    }
    // eslint-disable-next-line
  }, [authStore, liveRoomStore, location, match])

  /**
   * todo:
   * 뒤로가기 => 팝업 취소 눌러도 주소는 계속 뒤로감
   */
  const unblock = history.block(
    '페이지를 나가시면 살롱에서 퇴장됩니다.\n그래도 나가시겠습니까?',
  )
  React.useEffect(() => {
    return () => {
      unblock()
      if (liveRoomStore.isJoinedLiveRoom) {
        liveRoomStore.leaveLiveRoom()
      }
    }

    // Todo: 한슬님 디펜던시 경고가 떠서 한번 체크해봐주세요!
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if(isBlockedLiveRooms) {
      unblock()
      history.goBack()
    }
  }, [isBlockedLiveRooms])

  React.useEffect(() => {
    if(currLiveRoom?.['isBlockedUnknownUser'] && !currentUserId) {
      setIsBlockedLiveRooms(true)
    }
  }, [currLiveRoom?.['isBlockedUnknownUser'], currentUserId])

  React.useEffect(() => {
    // match.params.liveRoomId로 joinLiveRoomFromLinkKey
    const fetchJoinCurrLiveRoom = async () => {
      if (match.params.linkKey) {
        const res = await liveRoomStore.joinLiveRoomFromLinkKey(match.params.linkKey)
        if (!res) {
          setIsBlockedLiveRooms(true)
        }
      }
    }

    // 리스트에서 패스워드를 입력 || 살롱을 만들어서 입장
    if (location?.state?.isPassword || location?.state?.isCreated) {
      fetchJoinCurrLiveRoom()
    }
    // 검증 완료 후, Join
    else if (isReadyJoin === 'completed') fetchJoinCurrLiveRoom()
    // unmount 시, leaveLiveRoom
    return () => {}
  }, [liveRoomStore, match, history, location, isReadyJoin, currentUserId])

  if (
    currLiveRoom?.isReserved
    && !currLiveRoom?.isOpened
    && !location?.state?.isCreated
  ) {
    return <SalonReserveTemplate currLiveRoom={currLiveRoom} />
  }
  return (
    <>
      <SalonDetailTemplate
        sendLiveRoomUserFeedback={
          customerCenterStore.sendLiveRoomUserFeedback || (() => {})
        }
        sendLiveRoomFeedback={
          customerCenterStore.sendLiveRoomFeedback || (() => {})
        }
        toggleSalon={toggleSalon}
        toggleSalonMixtape={toggleSalonMixtape}
      />
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={toggleConfirm}
        title={confirmTitle}
        body={confirmBody}
        onClose={
          () => {
            if (confirmTitle === '살롱이 종료되었습니다') {
              history.push('/p/salon/list')
            }
          }
        }
      />
      <SalonEditModal.View isEdit isShowing={showSalon} toggle={toggleSalon} />
      <SalonUserModal.View isShowing={showSalonUser} toggle={toggleSalonUser} />
      <SalonMixtapeModal.View
        isShowing={showSalonMixtape}
        toggle={toggleSalonMixtape}
      />

      <SalonPasswordPopup.View
        isShowing={showPassword}
        toggle={togglePassword}
        salonPassword={liveRoomStore?.currLiveRoom?.password}
        onOk={() => setIsReadyJoin('completed')}
        onCancel={() => history.push('/p/salon/list')}
      />
    </>
  )
}

export default observer(SalonDetailPage)
