import { upload } from '@utils/axios'
import { Network } from '.'

export default class UserNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getUser = async (userId, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('UserNetwork getUser error: ', error)
    }
  }
  getUserFromAccount = async (account, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/_account/${account}`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('UserNetwork getUserFromLinkKey error: ', error)
    }
  }

  // 유저 조회
  getUsers = async params => {
    try {
      const { data } = await this.network._axiosApi(`/users`, 'get', params)
      return data || []
    }
    catch (error) {
      console.log('UserNetwork getUsers error: ', error)
    }
  }

  // 유저 조회
  getJoinablePhone = async params => {
    try {
      const { data } = await this.network._axiosApi(`/users/joinable/phone`, 'get', params)
      return data || []
    }
    catch (error) {
      console.log('UserNetwork getUsers error: ', error)
    }
  }

  // 회원 등록
  postUser = async params => {
    try {
      const { data } = await this.network._axiosApi(`/users/`, 'post', params)
      return data || []
    }
    catch (error) {
      console.log('UserNetwork postUsers error: ', error)
    }
  }

  // 회원 탈퇴
  deleteUser = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${params.id}`,
        'delete',
      )
      return data || []
    }
    catch (error) {
      console.log('UserNetwork deleteUser error: ', error)
    }
  }

  // 회원 수정
  putUser = async (id, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${id}`,
        'put',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('UserNetwork putUser error: ', error)
      throw error
    }
  }

  // 회원 수정
  uploadKakaoImage = async filePath => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/uploads/users/kakao`,
        'post',
        { profileUrl: filePath },
      )
      return data || []
    }
    catch (error) {
      console.log('UserNetwork uploadKakaoImage error: ', error)
    }
  }

  // 구독한 유저
  getUsersFollows = async userId => {
    if (!userId) return
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/follows`,
        'get',
        {
          discJockeyLevel: 0,
          discJockeyLevelOperator: 'gte',
        },
      )
      return data || null
    }
    catch (error) {
      console.log('UserNetwork getUsersFollows error: ', error)
    }
  }

  postUserFollow = async (userId, value) => {
    if (isNaN(parseInt(userId)) || !value) return

    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/follows`,
        'post',
        value,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork postUserFollow error: ', err)
    }
  }
  putUserFollow = async (userId, followId, where) => {
    if (isNaN(parseInt(userId)) || isNaN(parseInt(followId))) return

    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/follows/${followId}`,
        'put',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork putFollow error: ', err)
    }
  }
  deleteUserFollow = async (userId, followId) => {
    if (isNaN(parseInt(userId)) || isNaN(parseInt(followId))) return

    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/follows/${followId}`,
        'delete',
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork deleteUserFollow error: ', err)
    }
  }

  uploadUserImage = async formData => {
    try {
      const { data } = await upload(
        '/uploads/users',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('UserNetwork uploadUserImage error: ', error)
    }
  }

  postUsersCheckPassword = async (email, password) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/users/check-password',
        'post',
        { email, password },
      )
      return data || null
    }
    catch (error) {
      console.error('UserNetwork postUsersCheckPassword error: ', error)
    }
  }

  getPopularTags = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/popular-tags`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork getPopularTags error: ', err)
    }
  }

  getUserFollowingCount = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users-follow-users/counts`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork getUserFollowingCount error: ', err)
    }
  }

  getUsersJoinable = async userInfo => {
    try {
      const { data } = await this.network._axiosApi(
        `/users/joinable`,
        'get',
        userInfo,
      )
      return data || {}
    }
    catch (error) {
      console.log('UserNetwork getUsersJoinable error: ', error)
    }
  }

  blockUser = async (userId, value) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/blocks`,
        'post',
        value,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork blockUser error: ', err)
    }
  }
  deleteBlockUser = async (userId, blockedUserId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/blocks/${blockedUserId}`,
        'delete',
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork deleteBlockUser error: ', err)
    }
  }
  getBlockUsers = async (userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${userId}/blocks`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('UserNetwork getBlockUsers error: ', err)
    }
  }

  getBlockedUsers = async (blockingUserId, currentUserId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/${blockingUserId}/blocks/${currentUserId}`,
        'get',
      )
      return data || null
    }
    catch (err) {
      console.log('UserNetwork getBlockedUsers error: ', err)
    }
  }

  getHomeRecommendation = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/users/recommendations',
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('UserNetwork getHomeRecommendation error: ', error)
    }
  }

  getDeepLinks = async params => {
    try {
      const { data } = await this.network._axiosApi('/deeplinks', 'get', params)
      return data || []
    }
    catch (error) {
      console.log('UserNetwork getDeepLinks error: ', error)
    }
  }
}
