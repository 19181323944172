import { Flex } from '@components/atoms'
import { images } from '@images/'
import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'
import { MentionInput } from '..'

const CommentInputBox = React.forwardRef((props, ref) => {
  const {
    submitComment,
    value,
    setValue,
    setTargetUser,
    onClick,
    handleSearchMention,
    _autoCompleteUserObj,
    style,
    ...inputProps
  } = props

  const [keyword, setKeyword] = React.useState('')
  const [isTrigger, setIsTrigger] = React.useState(false)

  const fetchMentionList = async value => {
    if (value.lastIndexOf('@') > -1) {
      const triggerIdx = value.lastIndexOf('@')

      if (value[triggerIdx + 1] === '[') return

      setIsTrigger(true)
      const searchKeyword = value.substring(triggerIdx + 1, value.length)
      if (!_autoCompleteUserObj[value]) await handleSearchMention(searchKeyword)
      setKeyword(searchKeyword)
    }
    else {
      setIsTrigger(false)
      setKeyword('')
    }
  }

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setValue(event.target.value)
    fetchMentionList(event.target.value)
    // if (mentions?.length > 0) {
    //   setTargetUser(
    //     mentions.map(mention => ({
    //       id: mention.id,
    //       name: mention.display.substr(1),
    //     })),
    //   )
    // }
  }

  return (
    <InputBox style={style && style}>
      <MentionInput
        value={value}
        setValue={setValue}
        handleChange={handleChange}
        handleSubmit={submitComment}
        ref={ref}
        mentionList={_autoCompleteUserObj[keyword]}
        isTrigger={isTrigger}
        setIsTrigger={setIsTrigger}
        {...inputProps}
      />
      <SendImg
        onClick={() => value && submitComment(value)}
        src={value ? images.salon_chat_send_img : images.salon_chat_send_img}
        alt="send_img"
      />
    </InputBox>
  )
})

export default CommentInputBox

const InputBox = styled(Flex)`
  width: 100%;
  height: 48px;

  padding: 8px 8px 8px 16px;
  border-radius: 28px;
  backdrop-filter: blur(24px);
  background-color: ${colors.white_f4};
`

const SendImg = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
`
