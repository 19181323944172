import styled from 'styled-components';

const EventLayout = styled.div`
  display:flex;
  flex-direction:column;
  max-width: 500px;
  width:100%;

  align-items:center;
  box-sizing:border-box;
  padding:56px 24px;
`

export default EventLayout