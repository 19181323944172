import React from 'react'
import styled from 'styled-components'
// import Link from 'next/link'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import { Img } from '@components/atoms'
import EventLayout from './EventLayout'


// const resultingGif = require('../../public/images/resulting_gif.gif')

const SocialkingResultingTemplate = () => {
  return (
    <EventLayout>
      <Link href="/">
        <Img
          src={'/images/hreum_logo.png'}
          width={132}
          height={24}
          alt="hreum_logo"
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <Img
        loading="lazy"
        src={'/images/socialking_loading_gif.gif'}
        style={
          {
            minWidth: 320,
            maxWidth: 320,
            width: '100%',
            objectFit: 'cover',
            height: 110,
            marginTop: 32,
          }
        }
        alt="resulting"
      />
      <Text style={{ marginTop: 40, marginBottom: 32 }}>분석중..</Text>
      <DescriptionText>
        {`인맥왕 테스트는 요즘 MZ세대에게 핫한\n 흐름에서 제작한 테스트에요.`}
      </DescriptionText>
      <DescriptionText style={{ marginTop: 24, fontWeight: 'bold' }}>
        {`대학생 부터 인플루언서까지\n 다양한 사람들과 소통하고 싶다면\n‘살롱’에서 실시간으로 음성 소통을 즐겨보세요!`}
      </DescriptionText>
    </EventLayout>
  )
}

export default SocialkingResultingTemplate

const Text = styled.h4`
  font-family: BMDOHYEON;
  font-weight: 500;
  font-size: 24px;
  margin: 0;

  /* @media ${mobile} { font-size : 24px;} */
`

const DescriptionText = styled.h5`
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  font-size: 14px;
  white-space: pre-wrap;
`
