import React, { useEffect } from 'react'
import { TextWrapper, H1, WarningUseService, NewLine } from '@components/atoms'
import { StudentPlanForm } from '@components/organisms'
import { PageLayout } from '..'

const ValidationStudentTemplate = props => {
  const { isValidatedStudent, validateStudent } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageLayout>
      <TextWrapper>
        <H1>
          학생 플랜 
          {' '}
          <NewLine isMobile />
          시작하기
        </H1>
      </TextWrapper>

      <StudentPlanForm
        isValidatedStudent={isValidatedStudent}
        validateStudent={validateStudent}
      />
      <WarningUseService />
    </PageLayout>
  )
}

export default ValidationStudentTemplate
