import React, { useEffect, useState } from 'react'
import {
  FlexRowBox,
  FlexColumnBox,
  MixtapeTagBox,
  CautionPopup,
} from '@components/molecules'
import { images } from '@resources/'
import { H6, Caption1, Caption2, Flex } from '@components/atoms'
import styled from 'styled-components'

import { secondToTimeString } from '@utils/time'
import { converseUnitEng } from '@utils/format'
import { Icon } from 'antd'
import { tablet, mobileAndTablet } from '@styles/media'
import { DJ_LEVEL, MIXTAPE_WARNING_STATUS } from '@consts/'
import { useHistory } from 'react-router-dom'
import { getUniqueObjectArray } from '@utils/string'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'

const CoverImage = ({ src, updatedState, deletedState }) => {
  return (
    <>
      {
        updatedState && (
          <DelayUpdateWrapper>
            <DelayUpdateBox>변경 대기중</DelayUpdateBox>
          </DelayUpdateWrapper>
        )
      }
      {
        deletedState && (
          <DelayUpdateWrapper>
            <DelayUpdateBox>삭제 대기중</DelayUpdateBox>
          </DelayUpdateWrapper>
        )
      }
      <Image src={src} alt="cover_img" />
    </>
  )
}

/**
 *플라 리스트 아이템
 * 재생과 관련된 루트이기 때문에 useStore, observer 연결
 */
const MixtapeListCard = ({
  currentUser,
  mixtape,
  loadDelete,
  updatePublishMixtape,
  setPopupTitle,
  setPopupBody,
  confirmToggle,
  okCancelToggle,
  setTargetId,
  getTempMixtape,
  category,
}) => {
  const { playerStore } = useStore()
  const fetchLoadPlayMixtape = playerStore.fetchLoadPlayMixtape || (() => {})
  const [uniqueTagList, setUniqueTagList] = useState([])

  const { isShowing, toggle } = CautionPopup.usePopup()

  const history = useHistory()
  const publishingMixtape = async () => {
    if (!mixtape.isPublished) {
      if (currentUser.discJockeyLevel >= DJ_LEVEL.BABY) {
        if (mixtape.totalPlayTime >= 1800) {
          await updatePublishMixtape({
            id: mixtape['id'],
            isPublished: true,
            publishedAt: new Date(),
            warningStatus: mixtape['warningStatus'],
          })
        }
        else {
          setPopupTitle('공개 실패')
          setPopupBody('재생시간이 30분 이상이어야 공개할 수 있습니다!')
          confirmToggle()
        }
      }
      else {
        setPopupBody('DJ 심사에 통과해야 공개할 수 있습니다!')
        confirmToggle()
      }
    }
    else if (!mixtape.isPublishedDelayUpdate) {
      setTargetId(mixtape['id'])
      okCancelToggle()
    }
    else if (mixtape.isPublished) {
      await updatePublishMixtape({
        id: mixtape['id'],
        isPublished: false,
        publishedAt: new Date(),
      })
      setPopupTitle('플라 비공개')
      setPopupBody('마지막 오디오 변경 후\n 비공개로 변경됩니다.')
      confirmToggle()
    }
  }

  useEffect(() => {
    const tagList =
      mixtape && mixtape.MixtapesHaveTags.map(item => !!item.Tag && item.Tag)

    setUniqueTagList(tagList && getUniqueObjectArray(tagList, 'keyword'))
  }, [mixtape])

  return (
    <Card>
      <PlayBtn
        src={images.mixtape_play_btn_img}
        alt="play_btn"
        onClick={
          async () => {
            const isAuth = await fetchLoadPlayMixtape(mixtape, category)
            isAuth
            && history.push({
              pathname: `/plra/${mixtape && mixtape.linkKey}`,
              state: { category },
            })
          }
        }
      />
      <CoverImage
        updatedState={mixtape.isDelayUpdating}
        deletedState={mixtape.isDelayDeleting}
        src={mixtape.imageUrl || images.mixtape}
        onError={e => (e.target.src = images.mixtape)}
      />
      <MixtapeInfo>
        <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
          <Flex type="column">
            <Title>{mixtape.name}</Title>
            <Description color="#949494">{mixtape.description}</Description>
          </Flex>
          {
            mixtape.warningStatus < MIXTAPE_WARNING_STATUS.NOT_WARNING && (
              <CautionImg
                src={images.caution_img}
                alt="caution_img"
                onClick={toggle}
              />
            )
          }
        </Flex>
        <TagList>
          {/* {uniqueTagList.map((item, index) => (
            <MixtapeTagBox tag={item} key={index} />
          ))} */}
          {
            uniqueTagList && uniqueTagList[0] && (
              <MixtapeTagBox tag={uniqueTagList && uniqueTagList[0]} />
            )
          }
          {
            uniqueTagList && uniqueTagList[1] && (
              <MixtapeTagBox tag={uniqueTagList && uniqueTagList[1]} />
            )
          }
          {
            uniqueTagList && uniqueTagList[2] && (
              <MixtapeTagBox tag={uniqueTagList && uniqueTagList[2]} />
            )
          }
        </TagList>
        <FlexRowBox
          style={
            {
              flex: '2',
              width: '100%',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }
          }
        >
          <FlexRowBox>
            <Img16 src={images.like_black_img} alt="like_img" />
            <Caption2 style={{ margin: '0 8px 0 4px' }}>
              {converseUnitEng(mixtape.likeCount)}
            </Caption2>
            <Img16 src={images.play_black_img} alt="play_img" />
            <Caption2 style={{ margin: '0 16px 0 4px' }}>
              {converseUnitEng(mixtape.playCount)}
            </Caption2>
            <Caption2 color="#949494">
              {secondToTimeString(mixtape.totalPlayTime)}
            </Caption2>
          </FlexRowBox>
          <FlexRowBox>
            <Caption1
              style={{ cursor: 'pointer' }}
              color={mixtape.isPublished ? '#ea4653' : colors.secondary}
              onClick={publishingMixtape}
            >
              {
                mixtape.isPublished
                  ? `비공개하기${
                    mixtape.isDelayUpdating && !mixtape.isPublishedDelayUpdate
                      ? '(비공개 대기 중)'
                      : ''
                  }`
                  : '공개하기'
              }
            </Caption1>
            <Icon
              type="edit"
              style={{ fontSize: 24, margin: '0 20px', cursor: 'pointer' }}
              onClick={
                async () => {
                  await getTempMixtape(mixtape['id'])
                  history.push('/p/dj/mixtape/update')
                }
              }
            />
            <Icon
              onClick={() => loadDelete(mixtape['id'])}
              type="delete"
              style={{ fontSize: 24, cursor: 'pointer' }}
            />
          </FlexRowBox>
        </FlexRowBox>
      </MixtapeInfo>
      <CautionPopup.View isShowing={isShowing} toggle={toggle} />
    </Card>
  )
}

export default observer(MixtapeListCard)

const Card = styled(FlexRowBox)`
  position: relative;
  max-width: 920px;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`

const MixtapeInfo = styled(FlexColumnBox)`
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 750px;
  width: 100%;
  height: 150px;

  @media ${tablet} {
    height: 120px;
  }
`

const Title = styled(H6)`
  display: inline-block;
  width: 100%;
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 여러 줄 자르기 추가 스타일 */
  white-space: normal;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const Description = styled(Caption1)`
  margin: 6px 0 16px 0;

  display: inline-block;
  width: 100%;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 여러 줄 자르기 추가 스타일 */
  white-space: normal;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const TagList = styled(FlexRowBox)`
  width: 100%;
  max-width: 750px;
  overflow-x: auto;
  div:first-child {
    margin: 0 5px 0 0;
  }
  div {
    margin: 0 5px;
  }
`

const Img16 = styled.img`
  width: 16px;
  height: 16px;
`

const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 16px;

  @media ${tablet} {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }
`

const DelayUpdateWrapper = styled(FlexRowBox)`
  position: absolute;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  border-radius: 16px;
  background-color: rgba(36, 36, 36, 0.5);

  @media ${mobileAndTablet} {
    width: 120px;
    height: 120px;
  }
`
const DelayUpdateBox = styled(FlexRowBox)`
  position: absolute;
  width: 118px;
  height: 30px;
  border-radius: 6px;
  background-color: #ea4653;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;

  @media ${mobileAndTablet} {
    width: 90px;
    height: 20px;
    font-size: 10px;
  }
`

const CautionImg = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 8px;
`

const PlayBtn = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 22px; // 카드 영역 패딩 + 하단 패딩 + 버튼 높이
  left: 106px; // 이미지 영역 + 우측 패딩 + 버튼 넓이
  cursor: pointer;
`
