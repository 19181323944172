import { Caption2, Caption3, Flex, Image, ProfileImg } from '@components/atoms'
import { CommentThreeDotPopup, MentionCaption } from '@components/molecules'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { timeForToday } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CommentListItem = ({
  item,
  handleReply,
  handleEdit,
  handleDelete,
  handleReport,
}) => {
  const { authStore, commentStore, feedStore, userStore } = useStore()
  const {
    isShowing: showThreeDot,
    toggle: toggleThreeDot,
  } = CommentThreeDotPopup.usePopup()

  const [hover, setHover] = React.useState(false)

  const currentUser = authStore.currentUser || null
  const feedDetail = feedStore.feedDetail || null
  const _fetchUserAccount = userStore.fetchUserAccount

  const isWriter = feedDetail?.userId === currentUser?.id
  const isChild = !!item?.parentCommentId
  const isLiked = item?.UsersLikeComments?.slice()?.find(
    val => val?.userId === currentUser?.id,
  )
  const isMine = item?.userId === currentUser?.id

  const handleLike = async (commentId, type = 'like') => {
    try {
      if (type === 'like') {
        await commentStore.likeComment(commentId, currentUser?.id)
      }
      else {
        await commentStore.unLikeComment(commentId, currentUser?.id)
      }

      if (item?.targetTableName === 'Mixtapes') {
        // await commentStore.fetchMixtapeReply(item?.targetId)
        await commentStore.fetchTargetMixtapeReply(item?.targetId)
      }
      else if (item?.targetTableName === 'Feeds') {
        await commentStore.fetchFeedReply(item?.targetId)
      }
      else if (item?.targetTableName === 'Casts') {
        await commentStore.fetchCastReply(item?.targetId)
      }
    }
    catch (e) {
      console.log('ws', e)
    }
  }

  const handleTogglePin = async (isPin, id) => {
    await commentStore.togglePin(isPin, id)
  }

  return (
    <Item
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Content>
        <Link to={`/user/${item?.User?.account}`} target="_blank">
          <ProfileImg
            src={item?.User?.imageUri}
            profileColor={item?.User?.profileColor}
            style={
              {
                width: isChild ? 32 : 40,
                height: isChild ? 32 : 40,
                borderRadius: '50%',
              }
            }
          />
        </Link>
        <Flex type="column" style={{ width: '100%', margin: '0 16px 0 8px' }}>
          <Caption2 align="left" type="Bold">
            {item?.User?.name}
          </Caption2>
          <MentionCaption content={item?.rawContent} fetchUserAccount={_fetchUserAccount} />
          <Flex style={{ marginTop: 4, justifyContent: 'space-between' }}>
            <Flex>
              <Caption3 color={colors.grey_94} style={{ marginRight: 12 }}>
                {timeForToday(item?.createdAt)}
              </Caption3>
              {
                (item?.likeCount > 0 || item?.UsersLikeComments?.length > 0) && (
                  <Caption3 color={colors.grey_94} style={{ marginRight: 12 }}>
                    {
                      `좋아요 ${item?.likeCount
                    || item?.UsersLikeComments?.length}개`
                    }
                  </Caption3>
                )
              }
              <Caption3
                color={colors.grey_94}
                style={{ cursor: 'pointer' }}
                onClick={() => handleReply(item)}
              >
                답글달기
              </Caption3>
            </Flex>
            <Flex>
              {
item?.DonationCastHistory?.starValue && (
                  <Flex style={{ alignItems: 'center' }}>
                    <img
                      src={images.donation_star_icon}
                      style={{ width: 20, height: 20, marginRight: 4 }}
                      alt="donation"
                    />
                    <Caption3 color={colors.grey_64}>
                      {item?.DonationCastHistory?.starValue}
                    </Caption3>
                  </Flex>
                )
              }
            </Flex>
          </Flex>
        </Flex>
        <Flex type="column" style={{ marginTop: 6 }}>
          <Image
            src={
              isLiked ? images.heart_img_red_12 : images.heart_img_gray_default
            }
            style={{ cursor: 'pointer' }}
            onClick={
              () => {
                if (isLiked) {
                  handleLike(item?.id, 'unLike')
                }
                else {
                  handleLike(item?.id)
                }
              }
            }
          />
          {
item?.pinnedAt && (
              <Image
                src={images.comment_pin_img}
                style={{ width: 16, height: 16, marginTop: 8 }}
              />
            )
          }

          {
            hover && (
              <Image
                style={{ marginTop: 8, cursor: 'pointer' }}
                src={images.three_dot_horizontal_img}
                onClick={toggleThreeDot}
              />
            )
          }
        </Flex>
      </Content>
      {
item?.ChildComments?.length > 0 && (
          <ChildCommentList>
            {
              item.ChildComments.map(item => (
                <CommentListItem
                  item={item}
                  key={item.id}
                  handleReply={() => handleReply(item, true)}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleReport={handleReport}
                />
              ))
            }
          </ChildCommentList>
        )
      }
      <CommentThreeDotPopup.View
        isShowing={showThreeDot}
        toggle={toggleThreeDot}
        isMine={isMine}
        isChild={isChild}
        isWriter={isWriter}
        onUpdate={
          () => {
            toggleThreeDot()
            handleEdit(item.id, item.rawContent)
          }
        }
        onDelete={
          () => {
            toggleThreeDot()
            handleDelete(item.id)
          }
        }
        onReport={
          () => {
            toggleThreeDot()
            handleReport('comment', currentUser['id'], item)
          }
        }
        onTogglePin={
          () => {
            toggleThreeDot()
            handleTogglePin(!item.pinnedAt, item)
          }
        }
      />
    </Item>
  )
}

export default observer(CommentListItem)

const Item = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`

const Content = styled(Flex)`
  width: 100%;
  align-items: flex-start;
`

const ChildCommentList = styled(Flex)`
  flex-direction: column;
  margin: 24px 0 0px 48px;

  > div {
    margin-bottom: 24px;
  }
`
