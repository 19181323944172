import { observable, action } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { TagModel } from './models'

export default class TagStore {
  @observable autoCompleteTagList
  @observable representiveTagList
  @observable popularTags
  @observable _autoCompleteTagObj

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.autoCompleteTagList = []
    this.representiveTagList = [] // 추천 대표 태그
    this.popularTags = [] // 추천 대표 태그
    this._autoCompleteTagObj = {}
  }

  @action.bound
  async fetchAutocompleteTags(inputText) {
    if (!inputText) {
      this.autoCompleteTagList = []
      return
    }

    // return this.store.useLoading(async () => {
    const tagList = await this.network.tagNetwork.getTags({
      keyword: `${inputText}%`,
      keywordOperator: 'like',
      __order: 'totalCountDesc',
    })

    if (!tagList) return

    this.autoCompleteTagList =
      tagList.map(elem => new TagModel(this.store, elem)) || []
    // return !!tagList
    return this.autoCompleteTagList
    // })
  }

  // 추천 태그 ( 관리 태그 )
  // 랜덤 6개 fetch
  @action.bound
  fetchRepresentiveTagList = async () => {
    return this.store.useLoading(async () => {
      const { data } = await this.network.tagNetwork.getRepresentiveTags({})
      this.representiveTagList = data
        .filter(elem => !!elem)
        .map(elem => elem.keyword)

      return !!this.representiveTagList
    })
  }

  @action.bound
  fetchHomeRepresentiveTagList = async () => {
    return this.store.useLoading(async () => {
      const { data } = await this.network.tagNetwork.getRepresentiveTags({})
      this.representiveTagList = data
        .filter(elem => !!elem)
        .map(elem => elem.keyword)

      // 활성화된 태그 주입
      if (this.store.searchStore.mixtapeSearchKeyword) {
        this.representiveTagList.filter(
          elem => elem !== this.store.searchStore.mixtapeSearchKeyword,
        )
        this.representiveTagList.unshift(
          this.store.searchStore.mixtapeSearchKeyword,
        )
      }

      return !!this.representiveTagList
    })
  }

  @action.bound
  async fetchPopularTag() {
    const popularTags = await this.network.tagNetwork.getTagsPopularties()
    if (!popularTags) return
    this.popularTag = popularTags
    return popularTags
  }
}
