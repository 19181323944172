import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { TempMixtapeSongItem } from '@components/molecules'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { observer } from 'mobx-react'

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  max-height: 720px;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 40px 44px;
  box-sizing: border-box;
  overflow: auto;

  > div {
    padding: 11px 0;
    border-bottom: 1px solid #f4f4f4;
  }

  > div:first-child {
    padding: 0 0 11px 0;
  }
`

const TempMixtapeSongList = ({
  songs,
  tempMixtape,
  replaceMixtapeSongListToTempMixtape,
  deleteMixtapeSongToTempMixtape,
}) => {
  const [dragList, setDragList] = useState(songs)

  useEffect(() => {
    setDragList(songs)
    // eslint-disable-next-line
  }, [songs.length])

  const moveList = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = dragList[dragIndex]
      setDragList(
        update(dragList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [dragList],
  )

  const handleDelete = (item) => {
    const mixtapeSongIndex =
      tempMixtape &&
      tempMixtape['MixtapesHaveSongs'].findIndex(
        (elem) => elem['songId'] === item['songId'],
      )

    deleteMixtapeSongToTempMixtape(tempMixtape, mixtapeSongIndex)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ListBox>
        {dragList.map((item, index) => (
          <TempMixtapeSongItem
            tempMixtape={tempMixtape}
            song={item}
            index={index}
            key={item.songId}
            id={item.songId}
            replaceMixtapeSongListToTempMixtape={
              replaceMixtapeSongListToTempMixtape
            }
            moveList={moveList}
            songs={dragList}
            handleDelete={handleDelete}
          />
        ))}
      </ListBox>
    </DndProvider>
  )
}

// export default TempMixtapeSongList
export default observer(TempMixtapeSongList)
