import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Modal, H5, Flex, Caption1 } from '@components/atoms'
import { colors } from '@resources/colors'

import { useObserver } from 'mobx-react'
import { useStore } from '@utils/hooks'
import {
  CommentInputBox,
  DeletePopup,
  UserReportPopup,
} from '@components/molecules'
import { CommentListItem } from '..'

const CommentMessage = ({ message }) => {
  return (
    <MessageAnimation>
      <MessageBox>
        <Caption1 color={colors.white_ff} type="Regular">
          {message}
        </Caption1>
      </MessageBox>
    </MessageAnimation>
  )
}

// 댓글 팝업 View
const View = ({ isShowing, toggle, targetId }) => {
  const inputRef = useRef(null) // 댓글입력창 ref

  const { authStore, commentStore, customerCenterStore } = useStore()
  const [commentValue, setCommentValue] = useState('') // 댓글 내용
  const [message, setMessage] = useState('') // 댓글 액션 메세지
  const [showMessage, setShowMessage] = useState(false) // 댓글 메세지 노출 유무
  const [isEdit, setIsEdit] = useState(false) // 댓글 수정 유무
  const [editId, setEditId] = useState(null) //  댓글 수정 아이디
  const [parentCommentId, setParentCommentId] = useState(null) // 답글 부모 댓글 id
  const [reason, setReason] = useState(1)

  const [targetUser, setTargetUser] = useState(null) // 답글받을 유저 정보

  const targetInfo = React.useRef(null)

  const _autoCompleteUserObj = commentStore._autoCompleteUserObj || null

  const {
    isShowing: deleteShowing,
    toggle: deleteToggle,
  } = DeletePopup.useModal()

  const {
    isShowing: showUserReport,
    toggle: toggleUserReport,
  } = UserReportPopup.usePopup()

  // 댓글 액션 메세지 호출
  const actionMessage = message => {
    setMessage(message)
    setShowMessage(true)
    setTimeout(() => setShowMessage(false), 2500)
  }

  const handleEdit = (id, content) => {
    setEditId(id)
    setIsEdit(true)
    setCommentValue(content)
    inputRef.current.focus()
  }

  const showDelete = id => {
    setEditId(id)
    deleteToggle()
  }

  const handleSearchMention = async keyword => {
    const list = await commentStore.searchUserList(keyword)

    return list
  }

  const handleReply = (item, isChild = false) => {
    if (!isChild) setParentCommentId(item.id)
    if (isChild) setParentCommentId(item.parentCommentId)
    setCommentValue(`@[${item.User.name}](id:${item.User.id}) `)
    setTargetUser([{ id: item.User.id, name: item.User.name }])
    inputRef.current.focus()
  }

  const handleReport = async (userId, reason) => {
    if (!targetInfo || !reason) return

    let _reason = ''
    if (reason === 1)
      _reason =
        targetInfo.current.type === 'user'
          ? '부적절한 플라 내용'
          : '부적절한 댓글 내용'
    else _reason = '커뮤니티 가이트라인 위반'

    const message =
      `[신고내용]`
      + `\n<br>신고 사유: ${_reason}`
      + `\n<br> 텍스트 내용: ${targetInfo.current.item.content}`
      + `\n<br>\n<br> [댓글 정보]`
      + `\n<br> id: ${targetInfo.current.item.id}\n<br> 작성자: ${targetInfo.current.item.User.name}\n<br> 내용: ${targetInfo.current.item.content}\n<br>\n<br> [신고자 정보]`
      + `\n<br> userId: ${userId}`

    if (targetInfo.current.type === 'user')
      await customerCenterStore.sendFeedsFeedback(userId, _reason, message)
    else await customerCenterStore.sendFeedback({ type: '댓글 신고', message })
  }

  // 댓글 삭제 함수
  const removeComment = async () => {
    await commentStore.removeComment(editId, 'Mixtapes')
    actionMessage('댓글이 삭제되었습니다.')
  }

  // 댓글 보내기 함수
  const createComment = async content => {
    if (!content) return

    const contentDOM = document.getElementsByClassName(
      'comment_input_mentions-textarea__input',
    )

    const params = [
      {
        userId: authStore.currentUser && authStore.currentUser.id,
        targetId,
        isReply: 1,
        targetTableName: 'Mixtapes',
        content: contentDOM[0].innerHTML,
        rawContent: content,
        Users: targetUser,
        parentCommentId: parentCommentId || null,
      },
    ]

    if (isEdit) {
      if (!editId) return
      await commentStore.editComment(editId, params[0], 'Mixtapes')
      actionMessage('댓글이 수정이 완료되었습니다.')
    }
    else {
      await commentStore.createMixtapeComment(params)
    }

    setIsEdit(false)
    setEditId(null)
    setCommentValue('')
  }

  const _toggleUserReport = (type, userId, item) => {
    targetInfo.current = { type, userId, item }
    toggleUserReport()
  }

  // fetch 댓글
  // 댓글 리스트 스크롤 최하단으로
  useEffect(() => {
    if (isShowing) {
      commentStore.fetchTargetMixtapeReply(targetId)
    }
    else {
      setIsEdit(false)
      setCommentValue('')
    }
  }, [isShowing, commentStore, targetId])

  return useObserver(() => (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle} noHeader>
        <ModalBody>
          <CustomHeader>
            <H5>댓글</H5>
          </CustomHeader>
          <CommentList>
            {
              commentStore.targetCommentList
              && commentStore.targetCommentList.length > 0
              && commentStore.targetCommentList.map(item => (
                <CommentListItem
                  item={item}
                  key={item.id}
                  handleReply={handleReply}
                  handleEdit={handleEdit}
                  handleDelete={showDelete}
                  handleReport={_toggleUserReport}
                />
              ))
            }
          </CommentList>
          <InputArea>
            <CommentInputBox
              ref={inputRef}
              placeholder="댓글 달기"
              value={commentValue}
              setValue={setCommentValue}
              setTargetUser={setTargetUser}
              submitComment={createComment}
              handleSearchMention={handleSearchMention}
              _autoCompleteUserObj={_autoCompleteUserObj}
              style={
                {
                  width: 592,
                  alignItems: 'center',
                }
              }
            />
          </InputArea>
          {showMessage && <CommentMessage message={message} />}
          <DeletePopup.View
            isShowing={deleteShowing}
            toggle={deleteToggle}
            title="댓글 삭제"
            body={`이 댓글을\n정말 삭제하시겠습니까?`}
            onDelete={
              () => {
                removeComment()
                deleteToggle()
              }
            }
          />
          <UserReportPopup.View
            isShowing={showUserReport}
            toggle={toggleUserReport}
            onOk={handleReport}
            onCancel={() => console.log('onCancel')}
            userId={targetInfo.current?.userId}
            type={targetInfo.current?.type}
            reason={reason}
            setReason={setReason}
          />
        </ModalBody>
      </Modal.View>
    </>
  ))
}

export default {
  View,
  useModal: Modal.useModal,
}

/**
 * StyleSheet
 */

const ModalBody = styled(Flex)`
  max-width: 640px;
  max-height: 840px;
  width: 100%;
  height: 100%;

  flex-direction: column;
`

const CustomHeader = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 100%;
  border-bottom: solid 2px #f4f4f4;
`

const CommentList = styled(Flex)`
  flex-direction: column;
  padding: 16px;
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

const InputArea = styled(Flex)`
  border-top: solid 2px #f4f4f4;
  padding: 24px;
  width: 100%;
  height: fit-content;
  min-height: 96px;
  max-height: 144px;
`

// Comment Action Message
const messageFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const MessageAnimation = styled.div`
  animation-name: ${messageFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100;
`

const MessageBox = styled(Flex)`
  width: 196px;
  height: 52px;
  border-radius: 32px;
  box-shadow: 0 4px 8px 0 rgba(36, 36, 36, 0.4);
  border: solid 1px #242424;
  background-color: #242424;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  left: 35%;
`
