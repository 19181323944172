import React from 'react'
import styled from 'styled-components'

const Btn = styled.label`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: fit-content;
  min-width: 74px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 40px;
  background-color: ${props => (props.active ? '#f4f4f4' : '#ffffff')};
  border-radius: 20px;
  border: ${props =>
    props.active ? '1px solid #646464' : '1px solid #f4f4f4'};
  color: #646464;
`

const GenreBtn = ({ tag, onPress, selectedCheck, tempGenreList }) => {
  const isSelected = selectedCheck(tag)

  return (
    <Btn active={isSelected} onClick={() => onPress(tag)}>
      {`#${tag['keyword']}`}
    </Btn>
  )
}

export default GenreBtn
