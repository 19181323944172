import React, { useState } from 'react'
// antd
import { message } from 'antd'
import { STORAGE_URL } from '@consts'

import Files from 'react-butterfiles'
import styled from 'styled-components'
import { Caption1 } from '@components/atoms'
import { Spinner } from '@components/molecules'
import { upload } from '@utils/axios'
import { colors } from '@resources/colors'
import { images } from '@images/'

const UploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 518px;
  height: 326px;
  border-radius: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
`

const AdultUploadForm = ({
  setFileUri,
  fileUrl,
  setFileUrl,
  uploadSongImage,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState(null)
  const [fileName, setFileName] = useState(null)

  return (
    <Files
      multiple={false}
      maxSize="100mb"
      convertToBase64
      accept={['application/pdf', 'image/jpg', 'image/png', 'image/jpeg']}
      onSuccess={
        async ([selectedFile]) => {
          setLoading(true)
          try {
            const { file } = selectedFile.src
            // console.log('file', file)
            // console.log('file type?', file.type)
            setFileType(file.type)
            setFileName(file.name)
            const formData = new FormData()
            formData.append('file', file)

            // console.log('file2', formData)
            const uploadedFile = await upload(
              '/uploads/adult-certifications',
              sessionStorage.getItem('jwt_token'),
              formData,
            )

            // console.log('file3', uploadedFile.data)
            setFileUri(uploadedFile.data.fileUri)
            setFileUrl(STORAGE_URL + uploadedFile.data.fileUri)

            message.success('파일 업로드 완료')
            // console.log('File was successfully uploaded!')
            setLoading(false)
          }
          catch (e) {
          // console.log('An error occurred!', e.message)
            message.error('파일 업로드 실패')
            setLoading(false)
          }
        }
      }
      onError={errors => message.error(`파일 업로드 에러 :${errors}`)}
    >
      {
        ({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div {...getDropZoneProps({ className: 'myDropZone' })}>
            <UploadBox onClick={browseFiles}>
              {
                fileUrl ? (
                  fileType === 'application/pdf' ? (
                    <>
                      <img
                        src={images.pdf_file_img}
                        alt="pdf_file"
                        style={
                          {
                            width: '72px',
                            height: '72px',
                            marginBottom: '12px',
                          }
                        }
                      />
                      <Caption1>{fileName}</Caption1>
                    </>
                  ) : (
                    <img
                      style={
                        {
                          width: '100%',
                          height: '100%',
                          borderRadius: '16px',
                        }
                      }
                      src={fileUrl}
                      alt="file images"
                    />
                  )
                ) : loading ? (
                  <>
                    <Spinner
                      type="spinningBubbles"
                      color={colors.primary}
                      width="48px"
                      height="48px"
                    />
                    <Caption1 color="rgba(148,148,148,0.6)">
                  파일 업로드 중 ...
                    </Caption1>
                  </>
                ) : (
                  <Caption1 color="rgba(148,148,148,0.6)">
                이 곳을 클릭 하거나 마우스로 옮겨서 업로드 해주세요.
                  </Caption1>
                )
              }
            </UploadBox>
          </div>
        )
      }
    </Files>
  )
}

export default AdultUploadForm
