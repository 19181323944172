import React from 'react'
import styled from 'styled-components'
import FlexRowBox from './FlexRowBox'
import { tablet } from '@styles/media'

const TagBox = styled(FlexRowBox)`
  justify-content: center;
  width: fit-content;
  white-space: pre;
  padding: 12px;
  height: 30px;
  border-radius: 20px;
  background-color: #f4f4f4;
  color: #646464;
  font-size: 12px;

  @media ${tablet} {
    font-size: 10px;
    padding: 6px;
    height: 20px;
  }
`

const MixtapeTagBox = ({ tag }) => {
  return <TagBox>{`#${tag && tag.keyword}`}</TagBox>
}

export default MixtapeTagBox
