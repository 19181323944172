import React, { useCallback, useEffect, useRef, useState } from 'react'
// import ReactPlayer from 'react-player/lazy'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled, { css, keyframes } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import screenfull from 'screenfull'

import { images } from '@resources/'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { secondToTimeString } from '@utils/time'

import { oneEllipsisStyle } from '@styles/fontStyles'
import { Caption1, Caption2, Flex, H3, H6 } from '@components/atoms'
import { CopiedPopup, Spinner } from '@components/molecules'
import { handleSongImgError } from '@utils/handler'
import { IS_DEV } from '@consts/'
import { message } from 'antd'
import { findDOMNode } from 'react-dom'
import { isMobile } from 'react-device-detect'

import { useDrop, useDrag } from 'react-dnd'
import update from 'immutability-helper'
import { useRenderLogger, useStore } from '@utils/hooks'
import PlayerManager from '@utils/PlayerManager'
import { dateFormat } from '@utils/format'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DragAndDrop from '@utils/DragAndDrop'

const PlayerLogger = (title, content) => {
  if (!IS_DEV) return
  const css = 'color:#594ee9; font-size:15px;'
  const style = [
    'font-size: 18px;',
    'padding:0px;',
    `background: url(${images.cast_orange_img}) no-repeat;`,
    'background-size: contain;',
  ].join(' ')

  if (content) {
    return console.log('%c   %c[CAST PLAYER]', style, css, title, ':', content)
  }
  return console.log('%c   %c[CAST PLAYER]', style, css, title)
}

const CurrentControllListItem = ({
  index,
  cast,
  handleDetailRoute,
  moveList,
  id,
  originList,
  removeCurrentControllList,
  updateCurrentControllList,
}) => {
  const handleDnd = async (d, h) => {
    await moveList(d, h)
  }

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      handleDnd(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        updateCurrentControllList()
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Flex ref={ref} style={{ opacity }}>
      <CastBox
        onClick={() => handleDetailRoute(cast)}
        style={{ position: 'relative' }}
      >
        <CastCover
          src={cast && cast.imageUrl ? cast.imageUrl : images.default_song_img}
          onError={e => handleSongImgError(e)}
        />
        <CastInfoBox style={{ maxWidth: '100%' }}>
          <H6 align="left" color={colors.white_ff} style={oneEllipsisStyle}>
            {cast && cast.title}
          </H6>
          <Caption1
            align="left"
            type="Regular"
            color={colors.grey_d4}
            style={oneEllipsisStyle}
          >
            {cast && cast.User && cast.User.name}
          </Caption1>
          <Caption1
            align="left"
            type="Regular"
            color={colors.grey_d4}
            style={oneEllipsisStyle}
          >
            {cast && dateFormat(cast.updatedAt || new Date())}
          </Caption1>
        </CastInfoBox>
        <img
          src={images.trash_img_white}
          onClick={
            e => {
              e.preventDefault()
              e.stopPropagation()

              removeCurrentControllList(cast)
            }
          }
          style={
            {
              width: '24px',
              height: '24px',
              marginRight: '80px',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
            }
          }
          alt="trash_img"
        />
        <img
          src={images.dnd_img_white}
          style={
            {
              width: '30px',
              height: '30px',
              marginRight: '20px',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
            }
          }
          alt="dnd_img"
        />
      </CastBox>
    </Flex>
  )
}

/**
 * title : 캐스트 플레이어
 * createdAt : 2021. 12. 11
 * @author LEEHAEHUN
 */
const CastPlayer = () => {
  const { castStore } = useStore()
  useRenderLogger('CastPlayer')

  const playerRef = useRef() // player element

  // const [pip, setPip] = useState(false) // pip모드
  // const [url, setUrl] = useState(null) // ==> Store로 관리
  // const [muted, setMuted] = useState(true) // ==> Store로 관리
  // const [volume, setVolume] = useState(0.3) // 볼륨 ( 기본값 30%) ==> Store로 관리
  const {
    url,
    muted,
    volume,
    playing,
    currentPlayCast,
    currentUsersPlayCasts,
    isAutoNext,
    currentPlayCategory,
    currentPlaySort,
    stopPlayer,
    interactPlay,
    currentControlList,
    currentCastIdx,
  } = castStore
  const setMuted = castStore.setMuted || (() => {})
  const setVolume = castStore.setVolume || (() => {})
  const setPlaying = castStore.setPlaying || (() => {})
  const updateInteractPlay = castStore.updateInteractPlay || (() => {})
  const updateCastHistory = castStore.updateCastHistory || (() => {})
  const nextPlayCast = castStore.nextPlayCast || (() => {})
  const fetchLoadPlayCast = castStore.fetchLoadPlayCast || (() => {})
  const _likeCast = castStore._likeCast || (() => {})
  const toggleAutoNext = castStore.toggleAutoNext || (() => {})
  const updateCurrentControllList =
    castStore.updateCurrentControllList || (() => {})
  const createCastHistory = castStore.createCastHistory || (() => {})
  /* PLAYER STATE END */

  const [started, setStarted] = useState(false) // 재생된 지
  const [duration, setDuration] = useState(0) // 곡 시간
  const [playedSeconds, setPlayedSeconds] = useState(0) // 재생 시간(초)
  const [syncedTime, setSyncedTime] = useState(false) // 싱크 확인 중인 시간(초)
  const [seeking, setSeeking] = useState(true)

  const [prevVolume, setPrevVolume] = useState(0) // 새로고침 전 볼륨
  const [buffering, setBuffering] = useState(false) // 버퍼링 상태

  const [isMore, setIsMore] = useState(false) // 플레이어 풀모드 여부
  const [isFullscreen, setIsFullScreen] = useState(false) // 풀스크린 여부
  const [showCurrentSetting, setShowCurrentSetting] = useState(false) // 현재 캐스트 설정 팝업 노출 여부

  const [linkCopied, setLinkCopied] = useState(false) // 현 주소 복사 여부

  const history = useHistory()

  const handleCopy = () => {
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 2500)
  }

  // 재생 스타트
  const handleStart = async () => {
    PlayerLogger('onStart')
    /**
     * Chrom Autoplay Policy 이슈 대응
     * 2021. 05. 07
     * muted default에서 플레이 함수 호출 시, muted = false 후 playinga 처리
     * 실험 중...
     */
    if (!started) {
      await setMuted(true)
      setTimeout(async () => {
        await setMuted(false)
        prevVolume && setVolume(prevVolume)
        setPrevVolume(0)
      }, 300)
    }
    setStarted(true)


    // console.log('jhlim', 'seekTo', '1', parseFloat(currentPlayCast.lastPlayTimeStamp))
    if((parseFloat(currentPlayCast?.duration - parseFloat(currentPlayCast.lastPlayTimeStamp)) > 10)) {
      console.log('seek!')
      playerRef && playerRef.current && playerRef.current.seekTo(parseFloat(currentPlayCast.lastPlayTimeStamp))
    }

    await createCastHistory(currentPlayCast?.id)
  }

  // 재생 시작
  const handlePlay = async e => {
    PlayerLogger('onPlay')
    await updateInteractPlay(true)

    // playerRef
    //   && playerRef.current
    //   && playerRef.current.seekTo(parseFloat(currentPlayCast.lastPlayTimeStamp))

    if (url) {
      setPlaying(true)
    }
  }

  // 재생 중지
  const handlePause = () => {
    PlayerLogger('onPause')
    setPlaying(false)
    updateCastHistory(
      currentPlayCast?.id,
      currentUsersPlayCasts?.id,
      playedSeconds,
    )
  }

  // 재생/중지 버튼
  const handlePlayPause = async () => {
    PlayerLogger('onPlayPause')
    if (!url) return

    await updateInteractPlay(true)

    if (playing) {
      setPlaying(false)
      setBuffering(false)
    }
    else if (!playing) {
      setPlaying(true)
    }
  }

  // 음소거 토글
  const handleToggleMuted = () => {
    setMuted(!muted)
  }

  // 곡이 끝났을 때
  // 다음 플라 자동 재생 여부 분기
  const handleEnded = async () => {
    PlayerLogger('onEnded')
    setPlayedSeconds(duration)

    if (isAutoNext) {
      PlayerLogger('next playList...')
      await nextPlayCast()
    }
    else {
      PlayerLogger('current playList end...')
      handlePause()
    }
  }

  // 재생 시간 저장
  // 기본값(1초)당 progress callback
  const handleProgress = state => {
    // PlayerLogger('onProgress', state)
    if (playing) {
      setPlayedSeconds(state.playedSeconds)
    }
  }

  // 볼륨 조절
  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value))
  }

  // 현재 리스트의 정보 담아서
  // 플라 상세로 이동
  const handleDetailRoute = item => {
    history.push({
      pathname: `/cast/${item && item.linkKey}`,
      state: {
        category: currentPlayCategory,
        sort: currentPlaySort,
      },
    })
    setIsMore(false)
  }

  const handleError = async e => {
    PlayerLogger('onError', e)
    setPlaying(false)
    const error = e.toString()
    if (error === 'hlsError') {
      await handlePause()
      setTimeout(async () => {
        await handlePlay()
      }, 200)
    }
  }

  const handleBuffer = () => {
    PlayerLogger('onBuffer')
    setBuffering(true)
  }

  const handleBufferEnd = () => {
    PlayerLogger('onBufferEnd')
    setBuffering(false)
  }

  const handleFullScreen = () => {
    if (!isFullscreen) {
      screenfull.request(findDOMNode(playerRef.current))
      setIsMore(true)
    }
    else {
      screenfull.exit(findDOMNode(playerRef.current))
    }
  }

  const handleMore = () => {
    if (isMore) {
      screenfull.exit(findDOMNode(playerRef.current))
      setIsMore(false)
    }
    else {
      setIsMore(true)
    }
  }

  const handleNext15Sec = () => {
    // console.log('jhlim', 'seekTo', '2', parseFloat(playedSeconds + 15))
    playerRef
      && playerRef.current
      && playerRef.current.seekTo(parseFloat(playedSeconds + 15))

    updateCastHistory(
      currentPlayCast?.id,
      currentUsersPlayCasts?.id,
      playedSeconds,
    )
  }

  const handlePrev15Sec = () => {
    // console.log('jhlim', 'seekTo', '3', parseFloat(playedSeconds - 15))
    playerRef
      && playerRef.current
      && playerRef.current.seekTo(parseFloat(playedSeconds - 15))

    updateCastHistory(
      currentPlayCast?.id,
      currentUsersPlayCasts?.id,
      playedSeconds,
    )
  }

  // 재생 시간 사용자가 조절하는 함수
  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    setPlayedSeconds(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    setSeeking(false)
    // console.log('jhlim', 'seekTo', '4', parseFloat(e.target.value))
    playerRef.current.seekTo(parseFloat(e.target.value))
  }

  /**
   * 버퍼링이 15초가 걸릴 때, error 간주 => 일시정지
   */
  const bufferInterval = useRef(null)
  useEffect(() => {
    if (buffering) {
      PlayerLogger('Buffering start...')
      bufferInterval.current = setInterval(() => {
        PlayerLogger('Buffering...')
        setPlaying(false)
        setBuffering(false)
        // syncCurrentSong()
        message.error('오디오 파일에 문제가 발생하였습니다.')
        clearInterval(bufferInterval.current)
      }, 15000)
    }
    else {
      PlayerLogger('Buffering end...')
      clearInterval(bufferInterval.current)
    }
  }, [buffering, setPlaying])

  /**
   * 재생 중일 때, 10초에 한번 씩
   * 로컬 플레이어 시간과 서버 플레이 시간 비교,
   * 오차 범위 10초 이상 시,
   * &&
   * 재생 중인 시간 서버에 로그
   *
   * 2020. 12. 18
   * 재생 중, 싱크 로직 제거
   */
  useEffect(() => {
    const syncing = async () => {
      if (playing && started && currentPlayCast && currentPlayCast.id) {
        if (!syncedTime) setSyncedTime(new Date().getTime())

        if (new Date().getTime() - syncedTime > 10000) {
          PlayerLogger('Play Logging...')
          updateCastHistory(
            currentPlayCast?.id,
            currentUsersPlayCasts?.id,
            playedSeconds,
          )

          setSyncedTime(new Date().getTime())
        }
      }
    }
    syncing()

    // eslint-disable-next-line
  }, [
    started,
    syncedTime,
    playedSeconds,
    currentPlayCast,
    currentUsersPlayCasts,
  ])

  // 플레이어 펼쳤을 때, 영역 밖의 컨텐츠 스크롤 잠금
  useEffect(() => {
    if (isMore) {
      disableBodyScroll()
    }
    else {
      enableBodyScroll()
    }
  }, [isMore, isFullscreen])

  // 풀스크린 변화 시,
  useEffect(() => {
    if (!isMobile) {
      screenfull.onchange(e => {
        setIsFullScreen(!isFullscreen)
      })
    }
  }, [isFullscreen])

  // 플레이어 상태 로컬에 저장
  useEffect(() => {
    if (currentPlayCast && currentPlayCast.id) {
      const playStatus = {
        playing,
        castId: currentPlayCast['id'],
        categoryname: currentPlayCategory,
        sortBy: currentPlaySort,
        volume,
      }
      PlayerManager().setValue('player', {
        type: 'cast',
        ...playStatus,
      })
      sessionStorage.setItem('cast-play-status', JSON.stringify(playStatus))
      const induceBar = document.getElementById('app-induce-bar')
      if (induceBar && !isMobile) {
        induceBar.style.marginBottom = '80px'
      }
    }
    else {
      const induceBar = document.getElementById('app-induce-bar')
      if (induceBar) {
        induceBar.style.marginBottom = '0px'
      }
    }
  }, [playing, currentPlayCast, currentPlayCategory, currentPlaySort, volume])

  // 플레이어의 로컬 상태 가져와서 재생
  useEffect(() => {
    const playStatus = JSON.parse(sessionStorage.getItem('cast-play-status'))
    const checkSession = async () => {
      // 살롱 재생일 때 제거
      if (sessionStorage.getItem('cast-play-status')) {
        if (playStatus && playStatus.castId) {
          await fetchLoadPlayCast(
            { id: playStatus.castId },
            playStatus.categoryname,
            playStatus.sortBy,
            playStatus.playing,
          )
          setPrevVolume(playStatus.volume)

          if (playStatus.playing) {
            setPlaying(true)
          }
        }
      }
    }

    checkSession()
  }, [fetchLoadPlayCast, setPlaying])

  // 외부에서 강제로 중지시킬 때
  useEffect(() => {
    if (stopPlayer) {
      setPlaying(false)
    }
  }, [stopPlayer, setPlaying])

  useEffect(() => {
    const prevPlayStatus = JSON.parse(
      sessionStorage.getItem('cast-play-status'),
    )
    if (url && prevPlayStatus?.playing) {
      setPlaying(true)
    }
    else if (!prevPlayStatus?.playing) {
      setPlaying(false)
      if (url && interactPlay) {
        PlayerManager().setValue('player', {
          type: 'cast',
          ...prevPlayStatus,
          playing: true,
        })

        sessionStorage.setItem(
          'cast-play-status',
          JSON.stringify({ ...prevPlayStatus, playing: true }),
        )
      }
    }
  }, [url, interactPlay, setPlaying])

  const [hidePlayer, setHidePlayer] = useState(false)
  /**
   * 전체 화면 일때, 마우스 움직임 없을 시(3초), 플레이어 숨기기
   */
  const moveTimeOut = useRef(null)
  useEffect(() => {
    if (isMore && isFullscreen) {
      document.onmousemove = function(e) {
        setHidePlayer(false)
        if (!moveTimeOut.current) {
          setHidePlayer(false)
          moveTimeOut.current = setTimeout(() => {
            setHidePlayer(true)
          }, 3000)
        }
        else {
          clearTimeout(moveTimeOut.current)
          setHidePlayer(false)
          moveTimeOut.current = setTimeout(() => {
            setHidePlayer(true)
          }, 3000)
        }
      }
    }
    else {
      document.onmousemove = null
      clearTimeout(moveTimeOut.current)
      setHidePlayer(false)
    }
  }, [isMore, isFullscreen])

  const playOnSpaceBar = useCallback(
    async e => {
      if (e.code === 'Space') {
        // e.preventDefault()
        PlayerLogger('onPlayPauseSpaceBar')
        if (!url) return

        await updateInteractPlay(true)

        if (playing) {
          setPlaying(false)
          setBuffering(false)
          // await updateCastHistory(
          //   currentPlayCast?.id,
          //   currentUsersPlayCasts?.id,
          //   playedSeconds,
          // )
        }
        else if (!playing) {
          // await updateCastHistory(
          //   currentPlayCast?.id,
          //   currentUsersPlayCasts?.id,
          //   playedSeconds,
          // )
          setPlaying(true)
        }
      }
    },
    [
      // updateCastHistory,
      url,
      playing,
      setPlaying,
      updateInteractPlay,
    ],
  )

  // ply and pause on Space bar
  useEffect(() => {
    if (document.getElementById('hreum-player')) {
      document
        .getElementById('hreum-player')
        .addEventListener('keypress', playOnSpaceBar)
    }

    return () => {
      if (document.getElementById('hreum-player')) {
        document
          .getElementById('hreum-player')
          .removeEventListener('keypress', playOnSpaceBar)
      }
    }
  }, [playOnSpaceBar])

  useEffect(() => {
    if (document.getElementById('hreum-player')) {
      if (isFullscreen) {
        document.addEventListener('keypress', playOnSpaceBar)
      }
    }

    return () => document.removeEventListener('keypress', playOnSpaceBar)
  }, [isFullscreen, playOnSpaceBar])

  // drag logic
  const [dragList, setDragList] = useState([])

  useEffect(() => {
    setDragList(currentControlList)
  }, [currentControlList])

  const moveList = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = dragList[dragIndex]
      setDragList(
        update(dragList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [dragList],
  )
  // drag logic end

  useEffect(() => {
    if (currentPlayCast) {
      setDuration(
        currentPlayCast?.LiveRoomRecording
          ? currentPlayCast?.LiveRoomRecording?.duration
          : currentPlayCast?.AudioFile?.duration,
      )
      setPlayedSeconds(currentPlayCast?.lastPlayTimeStamp)

      /* react-player 예외처리 pr 중. */
      if (
        playerRef
        && playerRef.current
        && playerRef.current.player
        && playerRef.current.player.isReady
      ) {
        // console.log('jhlim', 'seekTo', '5', parseFloat(currentPlayCast?.lastPlayTimeStamp))
        playerRef
          && playerRef.current
          && playerRef.current.seekTo(
            parseFloat(currentPlayCast?.lastPlayTimeStamp),
          )
      }
    }
  }, [currentPlayCast])

  // 로그인 한 유저,
  // 재생 플라가 있을 때,
  // 플레이어 render
  if (
    // authStore.currentUser &&
    currentPlayCast
    && currentPlayCast.id
  ) {
    return (
      <Box
        tabIndex="0"
        id="hreum-player"
        isMore={isMore}
        backgroundImage={currentPlayCast && currentPlayCast.imageUrl}
      >
        <ReactPlayer
          // lazy load 제거 => 이전 재생 도중, 교체 url이 너무 빠르게 교체되는 버그 픽스
          // 2022. 05. 12
          // Copotter
          disabledeferredloading="true"
          ref={playerRef}
          config={
            {
              file: {
              // 중요! hls option일 때, 원하는 위치부터 load 해주는 기능
              // => 기존의 load error로 재생 멈추는 로직이 50% 가량 없어졌어요!
                hlsOptions: {
                  startPosition: currentPlayCast?.lastPlayTimeStamp || 0,
                },
                attributes: {
                // crossOrigin: 'true',
                  controlsList: 'nodownload', // <- this is the important bit
                  id: 'audio-elem ent',
                  autoPlay: false,
                },
              },
            }
          }
          className="react-player"
          width="100"
          height="100"
          url={url}
          pip={false}
          playing={playing}
          controls={false}
          // light={false} // false여야 autoPlay
          loop={false}
          playbackRate={1.0}
          volume={volume}
          muted={muted}
          onPlay={handlePlay}
          onReady={
            () => {
            // 2021. 03. 24
            // 준비될 시, 바로 재생
              PlayerLogger('onReady')
              castStore.isLoadingPlayer = false
              if (url && interactPlay) {
                setPlaying(true)
              }
            }
          }
          onStart={handleStart}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onSeek={e => PlayerLogger('onSeek', e)}
          onError={handleError}
          // onEnablePIP={handleEnablePIP}
          // onDisablePIP={handleDisablePIP}
          onPause={handlePause}
          onEnded={handleEnded}
          onProgress={handleProgress}
          onDuration={(d) => PlayerLogger('onDuration', d)}

          onPlaybackRateChange={(...params) => PlayerLogger('onPlaybackRateChange', ...params)}
          onClickPreview={(...params) => PlayerLogger('onClickPreview', ...params)}
          onEnablePIP={(...params) => PlayerLogger('onEnablePIP', ...params)}
          onDisablePIP={(...params) => PlayerLogger('onDisablePIP', ...params)}

          style={{ display: 'none' }}
        />
        <BlurBackground />
        <BottomGradient />
        <Header isHide={hidePlayer}>
          <ProgressBar
            type="range"
            min={0}
            max={currentPlayCast?.duration}
            step="any"
            value={playedSeconds || ''}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
          <FullToMiniBtn
            src={
              isFullscreen
                ? images.miniscreen_btn_img
                : images.fullscreen_btn_img
            }
            alt="screen_mode_img"
            onClick={handleFullScreen}
          />
          <ToggleMore
            src={isMore ? images.move_down_white_img : images.move_up_white_img}
            onClick={handleMore}
          />
          <InfoBox>
            <LiveImage src={images.cast_orange_img} />
            <TrackImage
              src={
                currentPlayCast && currentPlayCast.imageUrl
                  ? currentPlayCast.imageUrl
                  : images.default_song_img
              }
              onError={e => handleSongImgError(e)}
            />
            <TextBox>
              <Caption1
                color="#ffffff"
                type="Bold"
                align="left"
                style={{ ...oneEllipsisStyle, width: '100%' }}
              >
                {
                  currentPlayCast && currentPlayCast.title
                    ? currentPlayCast.title
                    : '캐스트 제목'
                }
              </Caption1>
              <Caption1
                color="#d4d4d4"
                align="left"
                style={{ ...oneEllipsisStyle, width: '100%' }}
              >
                {
                  currentPlayCast && currentPlayCast.User
                    ? currentPlayCast.User.name
                    : '만든이'
                }
              </Caption1>
            </TextBox>
          </InfoBox>
          <PlayControl>
            <img
              src={images.prev_15_sec_img}
              style={{ width: 24, height: 24, marginRight: 34 }}
              onClick={handlePrev15Sec}
              alt="prev_btn"
            />
            {
              castStore.isLoadingPlayer || buffering ? (
                <Spinner
                  width={40}
                  height={40}
                  type="spokes"
                  color={colors.white_ff}
                />
              ) : (
                <PlayPauseBtn
                  onClick={handlePlayPause}
                  src={playing ? images.pause_btn_img : images.play_btn_img}
                />
              )
            }
            <img
              src={images.next_15_sec_img}
              style={{ width: 24, height: 24, marginLeft: 34 }}
              onClick={handleNext15Sec}
              alt="next_btn"
            />
          </PlayControl>

          <VolumenControl>
            <Caption2
              type="Bold"
              color={colors.white_ff}
              style={{ marginRight: '30px' }}
            >
              {
                currentPlayCast
                && `${secondToTimeString(
                  parseInt(playedSeconds),
                )}  /  ${secondToTimeString(duration)}`
              }
            </Caption2>
            <MuteBtn
              src={muted ? images.volume_mute_img : images.volume_btn_img}
              onClick={handleToggleMuted}
            />
            <VolumenBar
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume || ''}
              onChange={handleVolumeChange}
            />
          </VolumenControl>
        </Header>
        {
          isFullscreen ? (
            <MoreContents style={{ marginTop: '24px' }}>
              <ScreenImage
                hidePlayer={hidePlayer}
                src={currentPlayCast && currentPlayCast.imageUrl}
                alt="screen_img"
              />
            </MoreContents>
          ) : (
            <MoreContents>
              <CurrentInfo>
                <H3
                  type="Bold"
                  align="left"
                  color={colors.white_ff}
                  style={{ marginBottom: '24px' }}
                >
                현재 캐스트
                </H3>
                <CastBox onClick={() => handleDetailRoute(currentPlayCast)}>
                  <Flex
                    style={
                      {
                        alignItems: 'center',
                        width: '100%',
                        position: 'relative',
                      }
                    }
                  >
                    <CastCover
                      src={
                        currentPlayCast && currentPlayCast.imageUrl
                          ? currentPlayCast.imageUrl
                          : images.default_song_img
                      }
                      onError={e => handleSongImgError(e)}
                    />
                    <CastInfoBox>
                      <H6
                        align="left"
                        color={colors.white_ff}
                        style={{ oneEllipsisStyle }}
                      >
                        {currentPlayCast && currentPlayCast.title}
                      </H6>
                      <Caption1
                        align="left"
                        type="Regular"
                        color={colors.grey_d4}
                        style={oneEllipsisStyle}
                      >
                        {
                          currentPlayCast
                        && currentPlayCast.User
                        && currentPlayCast.User.name
                        }
                      </Caption1>
                      <Caption1
                        align="left"
                        type="Regular"
                        color={colors.grey_d4}
                        style={oneEllipsisStyle}
                      >
                        {
                          currentPlayCast
                        && dateFormat(currentPlayCast.updatedAt || new Date())
                        }
                      </Caption1>
                    </CastInfoBox>
                    <H6
                      type="Regular"
                      color={colors.white_ff}
                      style={{ width: '54px' }}
                    >
                      {
currentPlayCast?.LiveRoomRecording
  ? currentPlayCast?.LiveRoomRecording
                        && secondToTimeString(
                          currentPlayCast.LiveRoomRecording.duration,
                        )
  : currentPlayCast?.AudioFile
                        && secondToTimeString(currentPlayCast.AudioFile.duration)
                      }
                    </H6>
                    <LikeBtn
                      src={
                      currentPlayCast['UsersLikeCasts']?.length > 0
                        ? images.like_white_img
                        : images.like_white_default_img
                      }
                      onClick={
                        e => {
                          e.stopPropagation()
                          _likeCast()
                        }
                      }
                    />
                    <CastToggleMore
                      onClick={
                        e => {
                          e.preventDefault()
                          e.stopPropagation()
                          setShowCurrentSetting(true)
                        }
                      }
                      src={images.three_dot_white_img}
                    />

                    {
                      showCurrentSetting && (
                        <>
                          <div
                            style={
                              {
                                position: 'fixed',
                                width: '100vw',
                                height: '100vh',
                                zIndex: 10,
                              }
                            }
                            onClick={
                              e => {
                                e.preventDefault()
                                e.stopPropagation()
                                setShowCurrentSetting(false)
                              }
                            }
                          />

                          <ThreeDotList>
                            <ThreeDotItem
                              onClick={
                                async e => {
                                  e.stopPropagation()
                                  // console.log('jhlim', 'seekTo', '6', parseFloat(currentPlayCast?.LiveRoomRecording ? currentPlayCast?.LiveRoomRecording?.duration : currentPlayCast?.AudioFile?.duration))
                                  playerRef
                              && playerRef.current
                              && playerRef.current.seekTo(
                                parseFloat(
                                  currentPlayCast?.LiveRoomRecording
                                    ? currentPlayCast?.LiveRoomRecording
                                        ?.duration
                                    : currentPlayCast?.AudioFile?.duration,
                                ),
                              )

                                  await updateCastHistory(
                              currentPlayCast?.id,
                              currentUsersPlayCasts?.id,
                              currentPlayCast?.LiveRoomRecording
                                ? currentPlayCast?.LiveRoomRecording?.duration
                                : currentPlayCast?.AudioFile?.duration,
                                  )

                                  nextPlayCast()
                                }
                              }
                            >
                              <Caption2>청취 완료한 캐스트로 설정</Caption2>
                            </ThreeDotItem>
                            <ThreeDotItem
                              onClick={
                                e => {
                                  e.stopPropagation()
                                }
                              }
                            >
                              <CopyToClipboard
                                text={window.location.href}
                                onCopy={handleCopy}
                              >
                                <Caption2>링크 복사</Caption2>
                              </CopyToClipboard>
                            </ThreeDotItem>
                            <ThreeDotItem
                              onClick={
                                e => {
                                  e.stopPropagation()
                                  nextPlayCast()
                                }
                              }
                            >
                              <Caption2 color={colors.warning}>삭제</Caption2>
                            </ThreeDotItem>
                          </ThreeDotList>
                        </>
                      )
                    }

                    {linkCopied && <CopiedPopup />}
                  </Flex>
                </CastBox>
              </CurrentInfo>

              <CurrentInfo style={{ marginTop: '48px', height: '65%' }}>
                <Flex
                  style={
                    {
                      marginBottom: '24px',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }
                  }
                >
                  <H3 type="Bold" align="left" color={colors.white_ff}>
                  다음 캐스트
                  </H3>
                  <Flex>
                    <Caption1
                      color={colors.white_ff}
                      style={{ marginRight: '20px', marginTop: '2px' }}
                    >
                    자동 재생
                    </Caption1>
                    <CheckBoxWrapper>
                      <CheckBox
                        id={'checkbox'}
                        type="checkbox"
                        checked={isAutoNext}
                        onChange={toggleAutoNext}
                        color={'#ffffff'}
                      />
                      <CheckBoxLabel
                        htmlFor={'checkbox'}
                        color={colors.brown_grey}
                      />
                    </CheckBoxWrapper>
                  </Flex>
                </Flex>
                <ControlList>
                  <DragAndDrop>
                    {
                      dragList
                    && dragList.length > 1
                    && dragList.map(
                      (cast, index) =>
                        currentCastIdx < index && (
                          <CurrentControllListItem
                            key={`${cast.id}`}
                            index={index}
                            cast={cast}
                            handleDetailRoute={handleDetailRoute}
                            moveList={moveList}
                            id={`${cast.id}`}
                            originList={currentControlList}
                            removeCurrentControllList={
                              target => {
                                const filteredList = dragList.filter(
                                  item => item.id !== target.id,
                                )
                                setDragList(filteredList)
                              }
                            }
                            updateCurrentControllList={
                              () =>
                                updateCurrentControllList(dragList)
                            }
                          />
                        ),
                    )
                    }
                    {
                      dragList
                    && dragList.length > 1
                    && dragList.map(
                      (cast, index) =>
                        currentCastIdx > index && (
                          <CurrentControllListItem
                            key={`${cast.id}`}
                            index={index}
                            cast={cast}
                            handleDetailRoute={handleDetailRoute}
                            moveList={moveList}
                            id={`${cast.id}`}
                            originList={currentControlList}
                            removeCurrentControllList={
                              target => {
                                const filteredList = dragList.filter(
                                  item => item.id !== target.id,
                                )
                                setDragList(filteredList)
                              }
                            }
                            updateCurrentControllList={
                              () =>
                                updateCurrentControllList(dragList)
                            }
                          />
                        ),
                    )
                    }
                  </DragAndDrop>
                </ControlList>
              </CurrentInfo>
            </MoreContents>
          )
        }
      </Box>
    )
  }
}

export default observer(CastPlayer)

// not yet use
// const handleClose = () => {
//   // setUrl(null)
//   setPlaying(false)
// }
// // PIP 모드가 가능할 때,
// const handleEnablePIP = () => {
//   setPip(true)
// }

// // PIP 모드가 불가능할 때,
// const handleDisablePIP = () => {
//   setPip(false)
// }

const moreAni = keyframes`
0%  { transform: translateY(calc(100% - 80px)); top: calc(100vh - 80px);}
50% { transform: translateY(50%); top:50%;}
100% { transform: translateY(0%); top:0;}
`

const unMoreAni = keyframes`
0% { transform: translateY(0%); top:0;}
50% { transform: translateY(50%); top:50%;}
100%  { transform: translateY(calc(100% - 80px)); top: calc(100vh - 80px);}
`

const Box = styled(Flex)`

  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  position: fixed;
  max-width: 100vw;
  min-width: 652px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  ${props =>
    props.isMore
      ? css`
          animation: ${moreAni} 0.1s linear;
          top: 0;
        `
      : css`
          animation: ${unMoreAni} 0.1s linear;
          top: calc(100vh - 80px);
        `}

  ${props =>
    props.backgroundImage
      ? css`
          background-image: url(${props.backgroundImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          background-color: #242424;
        `}

  @media ${mobile} {
    display: none;
  }
`

const BlurBackground = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
  z-index: 1;
`

const ToggleMore = styled.img`
  position: absolute;
  top: 28px;
  right: 50px;
  z-index: 9999;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const Header = styled(Flex)`
  margin-top: 18px;
  width: 100%;
  justify-content: left;
  align-items: center;
  z-index: 2;
  padding: 0 20%;

  ${props =>
    props.isHide
    && css`
      display: none;
    `}
`

const InfoBox = styled(Flex)`
  /* width: 356px; */
  max-width: 32%;
  width: 100%;
  justify-content: left;
  align-items: center;
`
const LiveImage = styled.img`
  width: 44px;
  height: 20px;
  margin-right: 16px;
`

const TrackImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 4px;
`

const TextBox = styled(Flex)`
  flex-direction: column;
  max-width: 60%;
  width: 100%;
  align-items: flex-start;
  margin-left: 16px;
  justify-content: space-between;
  height: 44px;
`

const PlayControl = styled(Flex)`
  max-width: 55%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const PlayPauseBtn = styled.img`
  width: 40px;
  height: 40px;
`

const VolumenControl = styled(Flex)`
  max-width: 30%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

const MuteBtn = styled.img`
  width: 20px;
  height: 20px;
`

const VolumenBar = styled.input`
  width: 112px;
  height: 5px;
  border-radius: 2px;
  border: solid 4px var(--brown-grey);
`

const MoreContents = styled(Flex)`
  z-index: 2;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  max-width: 100%;
  min-width: 652px;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const ScreenImage = styled.img`
  /* width: 872px; */
  /* height: 872px; */
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 15%;
  ${props =>
    props.hidePlayer
    && css`
      /* top: 90px; */
    `}
`

const CurrentInfo = styled(Flex)`
  width: 652px;
  flex-direction: column;
`

const CastBox = styled(Flex)`
  width: 652px;
  height: 88px;
  border-radius: 16px;
  padding: 24px;
  align-items: center;
  cursor: pointer;
`

const CastCover = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  border: solid 1px ${colors.grey_d4};
  background-color: rgba(36, 36, 36, 0.5);
`

const CastInfoBox = styled(Flex)`
  /* max-width: 266px; */
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 88px 0 16px;
`

const LikeBtn = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 48px;
`

const CastToggleMore = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 32px;
  cursor: pointer;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  top: 80%;
  width: 100%;
  height: 20%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(36, 36, 36, 0) 100%
  );
`

const CheckBoxWrapper = styled.div`
  position: relative;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: calc(50% - 32px);
  width: 40px;
  height: 24px;
  border-radius: 16px;
  background: ${colors.brown_grey};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 40px;
  height: 24px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: ${colors.secondary};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 18px;
      transition: 0.2s;
    }
  }
`

const ControlList = styled(Flex)`
  flex-direction: column;
  > div:last-child {
    margin-bottom: 160px;
  }
`

const FullToMiniBtn = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 100px;
  z-index: 9999;
`

const ThreeDotList = styled(Flex)`
  z-index: 11;
  top: 45px;
  right: -100px;
  background: white;
  position: absolute;
  flex-direction: column;
  border-radius: 16px;

  > div:last-child {
    border: none;
  }
`

const ThreeDotItem = styled(Flex)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #f4f4f4;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ProgressBar = styled.input.attrs(props => ({
  // 고정적인 Props를 전달할 수 있습니다.
  type: 'range',
  // 혹은 다이나믹한 Props도 전달할 수 있습니다.
  // size: props.size || "100px",
}))`
  -webkit-appearance: none;

  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  margin: 0;
  background-color: ${colors.brown_grey};
  cursor: pointer;

  :focus {
    outline: none;
  }

  ::-webkit-slider-runnable-track {
    background: #ddd;
  }

  /*
  * 1. Set to 0 width and remove border for a slider without a thumb
  */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0px; /* 1 */
    height: 0px;
    background: ${colors.brown_grey};
    box-shadow: -100vw 0 0 100vw ${colors.primary};
  }

  ::-moz-range-track {
    height: 0px;
    background: #ddd;
  }

  ::-moz-range-thumb {
    background: ${colors.brown_grey};
    height: 0px;
    width: 0px;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw ${colors.primary};
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: ${colors.primary};
  }

  ::-ms-thumb {
    background: ${colors.brown_grey};
    height: 0px;
    width: 0px;
    box-sizing: border-box;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: #ddd;
    color: transparent;
    height: 0px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
`
