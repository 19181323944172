import { Caption2, Flex } from '@components/atoms'
import { colors } from '@resources/colors'
import { convertToPricingComma } from '@utils/string'
import React from 'react'

const IconValueBox = ({ style, iconSrc, iconWidth, iconHeight, value }) => {
  return (
    <Flex
      style={
        {
          width: 'auto',
          height: '36px',
          padding: '9px 11px',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '18px',
          backgroundColor: '#f8f8ff',
          ...style,
        }
      }
    >
      <img
        src={iconSrc}
        alt="icon_img"
        style={
          {
            width: iconWidth || 12,
            height: iconHeight || 12,
            marginRight: 4,
          }
        }
      />
      <Caption2 color={colors.primary}>
        {convertToPricingComma(value || 0)}
      </Caption2>
    </Flex>
  )
}

export default IconValueBox
