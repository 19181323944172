import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Caption1, Flex, H6 } from '@components/atoms'
import { MixtapeListCard } from '@components/organisms'
import { Pagination, Spin, Table, Tooltip, Icon, message } from 'antd'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import {
  DeletePopup,
  ConfirmPopup,
  OkCancelPopup,
  MobileOptionPopup,
  ListPlayer,
} from '@components/molecules'
import { Link, useHistory } from 'react-router-dom'
import { DJ_LEVEL } from '@consts/'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { PageLayout } from '..'
// import 'antd/dist/antd.css'

const DATA_TYPE = {
  HREUM: 'HREUM',
  SONG: 'SONG',
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

const Accounting = styled(Caption1)`
  display: flex;
  width: 103px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${colors.primary};
  border-radius: 20px;
  position: relative;
  left: 85%;
  bottom: 40px;
  font-size: 14px;
`
const AddBtnContainer = styled.div`
  display: flex;
  position: relative;
  left: 40%;
  bottom: 40px;
`

const Addbtn = styled(Link)`
  display:flex;
  cursor: pointer;
  width: 103px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${colors.primary};
  border-radius: 20px;
  bottom: 40px;
  font-size:14px;
}
`

/**
 * song
 */
const NameBox = styled.div`
  float: left;
  p {
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  @media ${mobile} {
    width: 192px;
  }
`

const ThreeDot = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    display: none;
  }

  span {
    margin: 0 10px;
  }

  @media ${mobile} {
    span {
      display: none;
    }
    img {
      display: block;
    }
  }
`
const Box = styled.div`
  position: absolute;
  z-index: 1000;
  top: 25px;
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const TooltipContent = styled.div`
  width: 435px;
  padding: 14px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;

  @media ${mobile} {
    width: 308px;
    padding: 12px;
  }
`
const DotImg = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const CustomTooltip = ({ content, style }) => {
  const [show, setShow] = useState(false)
  return (
    <div>
      {show && <Box style={style}>{content}</Box>}
      <Icon
        type="copyright"
        style={
          {
            fontSize: 25,
            color: colors.secondary,
            cursor: 'pointer',
          }
        }
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </div>
  )
}

// 업로드 목록이 있을 시, 흐름 홈
const DjMixtapeListTemplate = ({
  currentUser,
  myMixtapeList,
  updateMixtape,
  updatePublishMixtape,
  deleteMixtape,
  getTempMixtape,
  currentPage,
  setCurrentPage,
  myMixtapeListCount,
  isLoading,

  /**
   * songs
   */
  songs,
  songsCount,
  fetchSongDetail,
  removeSong,
  state,
  currentPlaySong,
  _stopPlayer,
  stopPlayer,
  currentPageSong,
  setCurrentPageSong,
}) => {
  const history = useHistory()
  const [type, setType] = useState(
    state?.type === DATA_TYPE['SONG'] ? DATA_TYPE['SONG'] : DATA_TYPE['HREUM'],
  )
  const [targetId, setTargetId] = useState(null)
  const [popupTitle, setPopupTitle] = useState('')
  const [popupBody, setPopupBody] = useState('')

  const { isShowing, toggle } = ConfirmPopup.usePopup()
  const {
    isShowing: okCancelShowing,
    toggle: okCancelToggle,
  } = OkCancelPopup.usePopup()

  const { isShowing: showPopup, toggle: deleteToggle } = DeletePopup.useModal()
  const { isShowing: showBanPopup, toggle: banToggle } = ConfirmPopup.usePopup()

  const loadDelete = mixtapeId => {
    setTargetId(mixtapeId)
    deleteToggle()
  }

  const onDelete = () => {
    if (currentUser['discJockeyLevel'] === DJ_LEVEL['APPLICANT']) {
      alert('심사중 에는 삭제하실 수 없습니다.')
      return
    }
    deleteMixtape(targetId)
    deleteToggle()
  }

  const onOk = () => {
    updateMixtape({
      id: targetId,
      isPublished: true,
      publishedAt: new Date(),
    })
  }

  /**
   * song
   */
  const [nowSong, setNowSong] = useState()
  // const [targetIdSong, setTargetIdSong] = useState(null)
  const [targetSong, setTargetSong] = useState({})
  const {
    isShowing: showMobileSong,
    toggle: mobileToggleSong,
  } = MobileOptionPopup.useModal()
  const {
    isShowing: showPopupSong,
    toggle: popupToggleSong,
  } = DeletePopup.useModal()
  const loadMobilePopupSong = info => {
    setTargetSong(info)
    // setTargetIdSong(info.id)
    mobileToggleSong()
  }

  const loadDeleteSong = (info, isMobile) => {
    if (isMobile) {
      mobileToggleSong()
      popupToggleSong()
    }
    else {
      setTargetSong(info)
      popupToggleSong()
    }
  }

  const onDeleteSong = () => {
    if(targetSong && targetSong.id){
      deleteSong(targetSong.id)
    }

    popupToggleSong()
  }

  const deleteSong = async id => {
    await removeSong(id)
  }

  const songColumns = [
    {
      key: 'image',
      dataIndex: 'imageUrl',
      width: '40px',
      render: imageUrl => (
        <img
          style={{ width: '40px', height: '40px', background: 'aliceblue' }}
          src={imageUrl}
          alt="img"
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '500px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{record.name}</Caption1>
            <Caption1 color="#949494">{record.singer}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'function',
      dataIndex: 'download',
      width: '148px',
      render: (text, record) => {
        return (
          <ThreeDot>
            <span>
              <Tooltip title="재생">
                <Icon
                  type="play-circle"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      record.mp3Uri
                        ? setNowSong(record)
                        : message.error(
                          '컨버팅 중 입니다. 잠시 후 새로고침 해주세요.',
                        )
                    }
                  }
                />
              </Tooltip>
            </span>
            <span>
              {
                <a href={record.mp3Url || record.originalUri} download>
                  <Tooltip title="다운로드">
                    <Icon
                      type="download"
                      style={{ fontSize: 25, color: colors.primary }}
                    />
                  </Tooltip>
                </a>
              }
            </span>
            <span
              style={{ position: 'relative' }}
              onClick={
                async () => {
                  await fetchSongDetail(record.id)
                  history.push('/p/dj/song/edit')
                }
              }
            >
              {
                record.plamUrlStatus === 1 || record.plamUrlStatus === 5 ? (
                  <CustomTooltip
                    content={
                      <TooltipContent>
                        <Caption1 color="#646464" align="left">
                        검수 완료된 저작물로, 음악 저작권법에 따라 저작자가
                        공표한 정보로 대체됩니다.
                        </Caption1>
                      </TooltipContent>
                    }
                  />
                ) : (
                  <Tooltip title="수정">
                    <Icon
                      type="edit"
                      style={
                        {
                          fontSize: 25,
                          color: colors.primary,
                          cursor: 'pointer',
                        }
                      }
                    />
                  </Tooltip>
                )
              }
            </span>
            <span>
              <Tooltip title="삭제">
                <Icon
                  type="delete"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      loadDeleteSong(record, false)
                    }
                  }
                />
              </Tooltip>
            </span>
            <DotImg
              src={images.three_dot_img}
              alt="dot"
              onClick={() => loadMobilePopupSong(record)}
            />
          </ThreeDot>
        )
      },
    },
  ]

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      width: '100%',
      render: (text, record) => {
        return (
          <MixtapeListCard
            currentUser={currentUser}
            mixtape={record}
            loadDelete={loadDelete}
            updatePublishMixtape={updatePublishMixtape}
            confirmToggle={toggle}
            setPopupTitle={setPopupTitle}
            setPopupBody={setPopupBody}
            setTargetId={setTargetId}
            okCancelToggle={okCancelToggle}
            getTempMixtape={getTempMixtape}
            category="mine"
          />
        )
      },
    },
  ]

  return (
    <>
      <OkCancelPopup.View
        isShowing={okCancelShowing}
        toggle={okCancelToggle}
        onOk={onOk}
        title="비공개 대기 중인 플라"
        body={'비공개를 취소하고 다시 공개로\n변경하시겠습니까?'}
      />
      <ConfirmPopup.View
        isShowing={isShowing}
        toggle={toggle}
        title={popupTitle}
        body={popupBody}
      />
      <ConfirmPopup.View
        isShowing={showBanPopup}
        toggle={banToggle}
        title={'플라 생성 불가능'}
        body={'경고 누적으로 DJ 자격이 박탈되어\n 흐름 생성이 불가능합니다'}
      />
      <DeletePopup.View
        isShowing={showPopup}
        toggle={deleteToggle}
        onDelete={onDelete}
        body={'마지막 오디오 재생 종료 후\n삭제됩니다.'}
      />
      <PageLayout>
        <ListPlayer
          nowSong={nowSong}
          _stopPlayer={_stopPlayer}
          stopPlayer={stopPlayer}
          currentPlayMixtape={currentPlaySong}
        />
        <ListBox>
          {
            //   type === DATA_TYPE['HREUM']
            // && myMixtapeList
            // && myMixtapeList.length < 1 ? (
            //       <Flex type="column" style={{ alignItems: 'center' }}>
            //         <H4>
            //           {currentUser && currentUser.name}
            //       님의
            //           {' '}
            //           <span style={{ color: colors.primary }}>0</span>
            //       개의 플라
            //         </H4>
            //         {
            //           currentUser
            //     && currentUser.discJockeyLevel === DJ_LEVEL['APPLICANT'] ? (
            //               <Accounting color={colors.primary}>DJ 심사중</Accounting>
            //             ) : currentUser && currentUser.bannedDJAt ? (
            //               <Addbtn
            //                 style={{ position: 'initial', marginTop: 40 }}
            //                 onClick={() => banToggle()}
            //               >
            //                 <img
            //                   src={images.plus_white_img}
            //                   alt="plus_btn"
            //                   style={{ marginRight: '6px' }}
            //                 />
            //                 {
            //                   currentUser
            //         && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
            //                     ? 'DJ 신청'
            //                     : '플라 추가'
            //                 }
            //               </Addbtn>
            //             ) : (
            //               <Addbtn
            //                 style={{ position: 'initial', marginTop: 40 }}
            //                 to="/p/dj/mixtape/add"
            //                 onClick={() => getTempMixtape('reset')}
            //               >
            //                 <img
            //                   src={images.plus_white_img}
            //                   alt="plus_btn"
            //                   style={{ marginRight: '6px' }}
            //                 />
            //                 {
            //                   currentUser
            //         && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
            //                     ? 'DJ 신청'
            //                     : '플라 추가'
            //                 }
            //               </Addbtn>
            //             )
            //         }
            //       </Flex>
            //     ) :
            <>
              {
                type === DATA_TYPE['HREUM'] ? (
                  <H4>
                    {currentUser && currentUser.name}
                  님의
                    {' '}
                    <span style={{ color: colors.primary }}>
                      {myMixtapeListCount || 0}
                    </span>
                  개의 플라
                  </H4>
                ) : (
                  <H4>
                    {currentUser && currentUser.name}
                  님의
                    {' '}
                    <span style={{ color: colors.primary }}>
                      {songsCount || 0}
                    </span>
                  개의 음악
                  </H4>
                )
              }
              <AddBtnContainer>
                <Addbtn to="/p/dj/song/upload" style={{ marginRight: '20px' }}>
                  음악 업로드
                </Addbtn>
                {
                  currentUser
                && currentUser.discJockeyLevel === DJ_LEVEL['APPLICANT'] ? (
                      <Accounting color={colors.primary}>DJ 심사중</Accounting>
                    ) : currentUser && currentUser.bannedDJAt ? (
                      <Addbtn onClick={() => banToggle()}>
                        <img
                          src={images.plus_white_img}
                          alt="plus_btn"
                          style={{ marginRight: '6px' }}
                        />
                        {
                          currentUser
                    && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
                            ? 'DJ 신청'
                            : '플라 추가'
                        }
                      </Addbtn>
                    ) : (
                      <Addbtn
                        to="/p/dj/mixtape/add"
                        onClick={() => getTempMixtape('reset')}
                      >
                        <img
                          src={images.plus_white_img}
                          alt="plus_btn"
                          style={{ marginRight: '6px' }}
                        />
                        {
                          currentUser
                    && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
                            ? 'DJ 신청'
                            : '플라 추가'
                        }
                      </Addbtn>
                    )
                }
              </AddBtnContainer>
              <Flex
                style={
                  {
                    marginBottom: 24,
                    width: '100%',
                    position: 'relative',
                  }
                }
              >
                <H6
                  onClick={() => setType(DATA_TYPE['HREUM'])}
                  type={type === DATA_TYPE['HREUM'] ? 'Bold' : 'Regular'}
                  style={
                    {
                      cursor: 'pointer',
                    }
                  }
                >
                  {`플라`}
                </H6>
                <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>|</div>
                <H6
                  onClick={() => setType(DATA_TYPE['SONG'])}
                  type={type === DATA_TYPE['SONG'] ? 'Bold' : 'Regular'}
                  style={
                    {
                      cursor: 'pointer',
                    }
                  }
                >
                  {`음악`}
                </H6>
              </Flex>
              {
                (
                  (type === DATA_TYPE['HREUM'] && myMixtapeList && myMixtapeList.length > 0)
                  || (type === DATA_TYPE['SONG'] && songs && songs.length > 0)
                ) && (
                  <>
                    <Table
                      columns={
                        type === DATA_TYPE['HREUM'] ? columns : songColumns
                      }
                      dataSource={
                        type === DATA_TYPE['HREUM'] ? myMixtapeList : songs
                      }
                      // pagination={{ pageSize: 5, defaultPageSize: 5 }}
                      pagination={false}
                      pageSize={10}
                      loading={
                        {
                          spinning: isLoading,
                          indicator: <Spin size="large" />,
                        }
                      }
                      size={'small'}
                      style={
                        {
                          backgroundColor: 'white',
                          borderRadius: '16px',
                          boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
                          width: '100%',
                          padding: '0 20px',
                          maxHeight: '1024px',
                          height: '100%',
                        }
                      }
                      rowKey="id"
                    />
                    {
                      type === DATA_TYPE['HREUM'] ? (
                        <Pagination
                          style={{ marginTop: '20px' }}
                          pageSize={4}
                          current={currentPage}
                          onChange={setCurrentPage}
                          total={myMixtapeListCount}
                          showSizeChanger={false}
                          showQuickJumper={false}
                        />
                      ) : (
                        <Pagination
                          style={{ marginTop: '20px' }}
                          pageSize={10}
                          current={currentPageSong}
                          onChange={setCurrentPageSong}
                          total={songsCount}
                          showSizeChanger={false}
                          showQuickJumper={false}
                        />
                      )
                    }
                  </>
                )
              }
            </>
          }
        </ListBox>
        {/** song */}
        <DeletePopup.View
          isShowing={showPopupSong}
          toggle={popupToggleSong}
          onDelete={onDeleteSong}
          body={'삭제 시 저장된 오디오의\n해당 데이터가 모두 삭제됩니다.'}
        />
        <MobileOptionPopup.View
          isShowing={showMobileSong}
          toggle={mobileToggleSong}
          info={targetSong}
          onDelete={() => loadDeleteSong(false, true)}
          setNowSong={setNowSong}
          mp3Url={targetSong.mp3Url}
          fetchSongDetail={fetchSongDetail}
        />
      </PageLayout>
    </>
  )
}

export default DjMixtapeListTemplate
