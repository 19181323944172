import React from 'react'
import styled from 'styled-components'
import {
  BodyWrapper,
  LogoImg,
  H1,
  H6,
  FormInput,
  Form,
  WarningMsg,
} from '@components/atoms'
import { SubmitBtn } from '@components/molecules'
// import { Link } from 'react-router-dom';

const FormGroup = styled.div`
  margin: 50px 0 40px 0;
  width: 100%;
`

const TempPassword = (props) => {
  const { email, setEmail, _tempPassword, success } = props

  return (
    <BodyWrapper>
      <LogoImg />

      <H1 style={{ marginBottom: '20px' }}>임시 비밀번호 발급</H1>

      <H6 color="#646464">
        가입한 이메일을 입력하시면 해당 이메일로
        <br />
        임시 비밀번호를 전송됩니다.
      </H6>

      <Form>
        <FormGroup>
          <FormInput
            placeholder="가입한 이메일 입력"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {success === 'N' ? (
            <WarningMsg mobileSize="10">가입된 이메일이 아닙니다.</WarningMsg>
          ) : success === 'kakao' ? (
            <WarningMsg mobileSize="10">카카오로 가입된 계정입니다.</WarningMsg>
          ) : (
            false
          )}
        </FormGroup>

        <H6 color="#949494">
          *이메일을 분실 하셨다면 info@hreum.me로 이메일을 보내주십시오.
        </H6>

        {/* <Link to="/p/find/temp-complete"> */}
        <SubmitBtn
          onClick={(e) => _tempPassword(e)}
          style={{ marginTop: '30px' }}
        >
          전송하기
        </SubmitBtn>
        {/* </Link> */}
      </Form>
    </BodyWrapper>
  )
}

export default TempPassword
