import React, { useState, useEffect } from 'react'
import {
  Form,
  FormInput,
  FormTextArea,
  FormLabel,
  Caption1,
} from '@components/atoms'
import styled from 'styled-components'
import {
  SubmitBtn,
  FlexColumnBox,
  RowToColumnBox,
  SelectBox,
  Tooltip,
  FlexRowBox,
} from '@components/molecules'
import { mobile, tablet } from '@styles/media'
import { useForm, Controller } from 'react-hook-form'
import { message } from 'antd'
import { colors } from '@resources/colors'
import ImageUploadForm from './ImageUploadForm'
import TagForm from './TagForm'
import { images } from '@images/'

const TooltipContent = styled.div`
  width: 435px;
  padding: 14px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;

  @media ${mobile} {
    width: 308px;
    padding: 12px;
  }
`

const SubmitBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  text-align: center;

  @media ${mobile} {
    margin: 15px 0px;
  }
`

const FormGroup = styled.div`
  padding: 10px 0px;
  width: 100%;

  @media ${mobile} {
    padding: 8px 0px;
  }
`

const AudioForm = styled(Form)`
  width: 100%;
  max-width: 1000px;

  @media ${mobile} {
    width: 100%;
  }
`

const ImageUploadBox = styled(FormGroup)`
  width: 452px;
  @media ${mobile} {
    width: 100%;
    padding: 20px 0px 12px 0px;
  }
`

const LicenseGroup = styled(FormGroup)`
  width: 518px;

  @media ${mobile} {
    width: 100%;
  }
`

const AudioInfoInputBox = styled(FlexColumnBox)`
  width: 100%;
  max-width: 518px;

  @media ${tablet} {
    padding-left: 20px;
  }

  @media ${mobile} {
    width: 100%;
    height: 320px;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
`

const UpdateForm = props => {
  const {
    _getYoutubeLink,
    createTagList,
    song,
    info,
    updateSong,
    updateSongInfo,
    uploadSongImage,
    setIsUpdate,
  } = props

  const {
    register,
    handleSubmit,
    control,
    getValues,
    // errors,
    // setError,
    // clearError,
    setValue,
  } = useForm()

  const [imageUri, setImageUri] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [tags, setTags] = useState([])

  // 유저 유튜브 URL 셀프 수정
  const setYoutubeUrl = async () => {
    if (!getValues().youtubeUrl && getValues().singer && getValues().name) {
      const res = await _getYoutubeLink(getValues().name, getValues().singer)
      setValue([{ youtubeUrl: res }, { yLinkId: res }])
    }
  }

  // 등록하기
  const onSubmit = async data => {
    const params = {
      name: data.name,
      singer: data.singer,
      imageUri,
      lyrics: data.lyrics,
      license: data.license.value,
      youtubeUrl: data.youtubeUrl,
    }

    const updated = await updateSong(song && song.id, params)
    const updatedInfo = await updateSongInfo(song && song.id, tags, [])
    const createdTag = await createTagList(song && song.id, tags)

    if (updated && updatedInfo && createdTag) {
      setIsUpdate(true)
      message.success('수정 성공!')
    }
    else {
      setIsUpdate(false)
      message.error('수정 실패.')
    }
  }

  useEffect(() => {
    setImageUri(song && song.imageUri)
    setImageUrl(song && song.imageUrl)
    const TempTags = []
    info && info.Tags && info.Tags.map(tag => TempTags.push(tag.keyword))
    setTags(TempTags)

    if (song && song.license === 0) {
      setValue([{ license: { value: '0', label: '표준 흐름 라이선스' } }])
    }
    else {
      setValue([
        { license: { value: '1', label: '크리에이티브 커먼즈 - 저작자 표시' } },
      ])
    }
  }, [info, song, setValue])

  return (
    <AudioForm onSubmit={handleSubmit(onSubmit)}>
      <RowToColumnBox
        style={
          {
            width: '100%',
            alignItems: 'end',
            justifyContent: 'space-between',
          }
        }
      >
        <ImageUploadBox>
          {
            (song && song.plamUrlStatus === 1)
          || (song && song.plamUrlStatus === 5) ? (
                <Img
                  src={imageUrl || images.default_song_img}
                  alt={'error'}
                  onError={e => (e.target.src = images.default_song_img)}
                />
              ) : (
                <ImageUploadForm
                  setImageUri={setImageUri}
                  imageUrl={imageUrl}
                  setImageUrl={setImageUrl}
                  uploadSongImage={uploadSongImage}
                />
              )
          }
        </ImageUploadBox>
        <AudioInfoInputBox>
          <FormGroup>
            <FormLabel color="#646464" required>
              제목
            </FormLabel>
            <FormInput
              name="name"
              ref={register({ required: true })}
              onBlur={() => setYoutubeUrl()}
              placeholder="제목 입력"
              defaultValue={song && song.name}
              disabled={
                (song && song.plamUrlStatus === 1)
                || (song && song.plamUrlStatus === 5)
              }
            />
          </FormGroup>
          <FormGroup>
            <FormLabel color="#646464" required>
              아티스트 명
            </FormLabel>
            <FormInput
              name="singer"
              ref={register({ required: true })}
              onBlur={() => setYoutubeUrl()}
              placeholder="아티스트 명 입력"
              defaultValue={song && song.singer}
              disabled={
                (song && song.plamUrlStatus === 1)
                || (song && song.plamUrlStatus === 5)
              }
            />
          </FormGroup>
          <FormGroup>
            <FormLabel color="#646464">유튜브 링크</FormLabel>
            <FormInput
              name="youtubeUrl"
              ref={register()}
              placeholder="URL 링크 입력"
              defaultValue={song && song.youtubeUrl}
              disabled={
                (song && song.plamUrlStatus === 1)
                || (song && song.plamUrlStatus === 5)
              }
            />
          </FormGroup>
        </AudioInfoInputBox>
      </RowToColumnBox>

      <FormGroup>
        <FormLabel color="#646464">가사</FormLabel>
        <FormTextArea
          name="lyrics"
          ref={register()}
          style={{ height: '150px', resize: 'none', margin: 0 }}
          placeholder="가사 입력"
          defaultValue={song && song.lyrics}
          disabled={
            (song && song.plamUrlStatus === 1)
            || (song && song.plamUrlStatus === 5)
          }
        />
      </FormGroup>

      {/* 태그 입력 */}
      <FormGroup>
        <TagForm tags={tags} setTags={setTags} />
      </FormGroup>

      {/* 라이센스 */}
      <FlexRowBox style={{ width: '100%', justifyContent: 'center' }}>
        <LicenseGroup>
          <Controller
            as={
              (song && song.plamUrlStatus === 1)
              || (song && song.plamUrlStatus === 5) ? (
                  <SelectBox
                    style={
                      {
                        width: '100%',
                        height: '48px',
                        borderRadius: '7px',
                        border: 'solid 1px #949494',
                        backgroundColor: '#ffffff',
                      }
                    }
                  />
                ) : (
                  <SelectBox
                    style={
                      {
                        width: '100%',
                        height: '48px',
                        borderRadius: '7px',
                        border: 'solid 1px #949494',
                        backgroundColor: '#ffffff',
                      }
                    }
                    options={
                      [
                        { value: '0', label: '표준 흐름 라이선스' },
                        { value: '1', label: '크리에이티브 커먼즈 - 저작자 표시' },
                      ]
                    }
                  />
                )
            }
            placeholder="라이센스 선택"
            control={control}
            rules={{ required: true }}
            onChange={
              ([selected]) => {
              // Place your logic here
                return selected
              }
            }
            name="license"
          />
        </LicenseGroup>
        <Tooltip
          content={
            <TooltipContent>
              <Caption1 color="#646464">
                표준 흐름 라이선스는 서비스 약관에서 확인할 수 있습니다.
                또는 크리에이티브 커먼즈 저작자 표시 라이선스를 사용할 수
                있습니다.
              </Caption1>
            </TooltipContent>
          }
        />
      </FlexRowBox>

      <SubmitBox>
        {
          (song && song.plamUrlStatus === 1)
        || (song && song.plamUrlStatus === 5) ? (
              <div style={{ padding: '11px' }}>
            해당 저작물은 음악 저작권법에 따라 저작자가 공표한 정보로
            대체됩니다.
              </div>
            ) : (
              <></>
            )
        }
        <SubmitBtn
          type="submit"
          disabled={
            (song && song.plamUrlStatus === 1)
            || (song && song.plamUrlStatus === 5)
          }
        >
          등록하기
        </SubmitBtn>
      </SubmitBox>
    </AudioForm>
  )
}

export default UpdateForm
