import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex, Button, FormInput } from '@components/atoms'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  /* height: 100%; */
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 24px 28px 16px 28px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const Subtitle = styled.div`
  font-family: NotoSansCJKkr-Medium;
  font-size: 12px;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const TagBtn = ({ tag, onClick = () => {} }) => {
  if (tag) {
    return (
      <TagBox
        isChecked={tag.isChecked}
        onClick={() => onClick(tag)}
        style={{ marginRight: '8px', marginBottom: '8px' }}
      >
        {tag?.keyword}
      </TagBox>
    )
  }
}

const View = ({
  isShowing,
  toggle,
  subjectTagList = [],
  defaultTagList = [],
  onSubmit = () => {},
  validate = () => {
    return true
  },
}) => {
  const [subjectTagItemList, setSubjectTagItemList] = useState(
    subjectTagList.map(_tag => {
      return { ..._tag, isChecked: false }
    }),
  )
  const [defaultTagItemList, setDefaultTagItemList] = useState(
    defaultTagList.map(_tag => {
      return { ..._tag, isChecked: false }
    }),
  )
  const [tagInput, setTagInput] = useState('')

  const subjectTagItemListCount = subjectTagItemList.filter(
    _tag => _tag.isChecked,
  ).length
  const defaultTagItemListCount = defaultTagItemList.filter(
    _tag => _tag.isChecked,
  ).length

  useEffect(() => {
    if (!isShowing) {
      setSubjectTagItemList(
        subjectTagList.map(_tag => {
          return { ..._tag, isChecked: false }
        }),
      )
      setDefaultTagItemList(
        defaultTagList.map(_tag => {
          return { ..._tag, isChecked: false }
        }),
      )
      setTagInput('')
    }

    // eslint-disable-next-line
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay
          onClick={
            () => {
              toggle()
            }
          }
        />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <ModalBody>
              <H6>태그 추가</H6>
              <Flex
                style={
                  {
                    width: '100%',
                    marginTop: '12px',
                    marginBottom: '12px',
                  }
                }
              >
                <Subtitle>주제</Subtitle>
                <Subtitle style={{ color: '#0066ff' }}>(필수)</Subtitle>
                <Flex style={{ flex: 1 }} />
                <Subtitle>{`${subjectTagItemListCount}/2`}</Subtitle>
              </Flex>
              <TagList>
                {
                  subjectTagItemList.map((_tag, index) => (
                    <TagBtn
                      key={`${_tag?.title}-${index}`}
                      tag={_tag}
                      onClick={
                        tag => {
                          tag.isChecked = !tag.isChecked
                          const _tempCount = subjectTagItemList.filter(
                            item => item.isChecked,
                          ).length
                          if (_tempCount > 2) {
                            tag.isChecked = !tag.isChecked
                            return
                          }
                          const _newTagList = [...subjectTagItemList]
                          setSubjectTagItemList(_newTagList)
                        }
                      }
                    />
                  ))
                }
              </TagList>
              <Flex
                style={
                  {
                    width: '100%',
                    marginTop: '12px',
                    marginBottom: '12px',
                  }
                }
              >
                <Subtitle>태그</Subtitle>
                <Subtitle style={{ color: '#949494' }}>(선택)</Subtitle>
                <Flex style={{ flex: 1 }} />
                <Subtitle>{`${defaultTagItemListCount}/15`}</Subtitle>
              </Flex>
              <Flex
                style={
                  {
                    width: '100%',
                    height: '40px',
                    marginBottom: '12px',
                  }
                }
              >
                <FormInput
                  style={
                    {
                      marginRight: '20px',
                      backgroundColor: '#f4f4f4',
                      border: '0px',
                    }
                  }
                  placeholder="새로 추가할 태그를 입력해주세요"
                  value={tagInput}
                  onChange={e => setTagInput(e.target.value.trim())}
                />
                <TagAddBtn
                  isInputed={tagInput.length !== 0}
                  onClick={
                    () => {
                      if (!validate(tagInput)) {
                        return
                      }
                      if (defaultTagItemListCount >= 15) {
                        return
                      }
                      const existTag = defaultTagItemList.find(
                        tag => tag.keyword === tagInput,
                      )
                      if (existTag) {
                        existTag.isChecked = true
                        setDefaultTagItemList([...defaultTagItemList])
                        setTagInput('')
                        return
                      }
                      const _tag = {
                        keyword: tagInput,
                        isChecked: true,
                      }
                      setDefaultTagItemList([...defaultTagItemList, _tag])
                      setTagInput('')
                    }
                  }
                >
                    추가
                </TagAddBtn>
              </Flex>
              <TagList>
                {
                  defaultTagItemList.map((_tag, index) => (
                    <TagBtn
                      key={`${_tag?.title}-${index}`}
                      tag={_tag}
                      isCheck={_tag?.isChecked}
                      onClick={
                        tag => {
                          tag.isChecked = !tag.isChecked
                          const _tempCount = defaultTagItemList.filter(
                            item => item.isChecked,
                          ).length
                          if (_tempCount > 15) {
                            tag.isChecked = !tag.isChecked
                            return
                          }
                          const _newTagList = [...defaultTagItemList]
                          setDefaultTagItemList(_newTagList)
                        }
                      }
                    />
                  ))
                }
              </TagList>
              <Flex
                justify="space-between"
                style={{ width: '100%', marginTop: 24 }}
              >
                <CancelBtn
                  onClick={
                    () => {
                      toggle()
                    }
                  }
                >
                    취소
                </CancelBtn>
                <ConfirmBtn
                  onClick={
                    () => {
                      const tagList = [
                        ...subjectTagItemList
                          .filter(item => item.isChecked)
                          .map(item => {
                            return { keyword: item.keyword }
                          }),
                        ...defaultTagItemList
                          .filter(item => item.isChecked)
                          .map(item => {
                            return { keyword: item.keyword }
                          }),
                      ]
                      onSubmit(tagList)
                    }
                  }
                >
                    변환
                </ConfirmBtn>
              </Flex>
            </ModalBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}

const TagList = styled(Flex)`
  /* flex-direction: row; */
  flex-wrap: wrap;
  width: 472px;
  /* height: 472px; */
  padding: 4px;
  border-radius: 4px;
`

const TagBox = styled(Button)`
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 14px;
  padding: 13px 7px;
  min-width: 44px;
  border: ${props =>
    props.isChecked ? '1px solid #646464' : '1px solid #d4d4d4'};
  color: #242424;
  font-size: 10px;
  font-family: NotoSansCJKkr-Medium;
  background-color: ${props => (props.isChecked ? '#5b49f3' : null)};
`

const TagAddBtn = styled(Button)`
  justify-content: center;
  align-items: center;
  width: 30px;
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color: ${props => (props.isInputed ? '#0066ff' : '#bfbfbf')};
`

// const MbtiBox = styled(Button)`
//   display: flex;
//   width: 113px;
//   height: 113px;
//   border-radius: 4px;
//   border-width: 1px;
//   border-color: #234234;
//   border-style: solid;
//   background-color: ${colors.white_fa};

//   font-family: NotoSansCJKkr-Bold;
//   font-size: 16px;
//   color: ${({ active }) => (active ? colors.primary : colors.grey_94)};

//   ${({ active }) =>
//     active
//     && css`
//       border: 1px solid ${colors.primay};
//     `}
// `

// const MbtiTestBtn = styled(Button)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 48px;
//   border-radius: 8px;
//   border: solid 1px ${colors.primary};
//   background-color: ${colors.white_ff};

//   font-size: 14px;
//   color: ${colors.primary};
// `

const CancelBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 48px;
  padding: 14px 99px;
  border-radius: 8px;
  background-color: ${colors.white_f4};

  font-size: 14px;
  color: ${colors.grey_64};
`

const ConfirmBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 48px;
  padding: 14px 99px;
  border-radius: 8px;
  background-color: ${colors.primary};

  font-size: 14px;
  color: ${colors.white_ff};
`
