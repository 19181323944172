import React, { useState, useRef } from 'react'
import { Tag, Input, Tooltip } from 'antd'
import { Caption1, H5 } from '@components/atoms'
import { FlexRowBox } from '@components/molecules'
import { images } from '@images/'
import styled from 'styled-components'

const TagForm = props => {
  const {
    defaultTagList = [],
    setDefaultTagList = () => {},
    title = '태그 추가',
    tags,
    setTags,
    validate = () => {
      return true
    },
  } = props
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInpuValue] = useState('')

  const inputId = useRef()

  const handleClose = removedTag => {
    setTags(tags.filter(tag => tag !== removedTag))
  }

  const showInput = async () => {
    await setInputVisible(true)
    inputId.current.focus()
  }

  const handleInputChange = e => {
    if (e && e.target && typeof e.target.value === 'string') {
      const stringWithoutSpace = e.target.value.replace(/\s+/g, '')
      setInpuValue(stringWithoutSpace)
    }
  }

  const handleInputConfirm = () => {
    const tempInputValue = inputValue.replace(/\s+/g, '')

    setInputVisible(false)
    setInpuValue('')

    if (!validate(tempInputValue)) {
      return
    }
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags.push(tempInputValue) // spread operator로 하면 새 배열이 만들어져서 참조 안됨
      setTags(tags)
    }
  }

  return (
    <div>
      <FlexRowBox>
        <H5>{title}</H5>
        {/* <Caption1 color="#949494" style={{ marginLeft: '10px' }}>
          *오디오 검색에 활용 됩니다.
        </Caption1> */}
      </FlexRowBox>
      <FlexRowBox style={{ margin: '7px 0 5px 0', flexWrap: 'wrap' }}>
        {
          defaultTagList.map((tag, index) => {
            return (
              <DefaultTag
                onClick={
                  () => {
                    if (validate()) {
                      setDefaultTagList(tag)
                    }
                  }
                }
                isActive={tag.isSelected}
                key={`${index}-${tag.keyword}`}
                stype={{ whiteSpace: 'pre' }}
              >
                {`${tag.keyword}`}
              </DefaultTag>
            )
          })
        }
      </FlexRowBox>
      <FlexRowBox style={{ margin: '7px 0 5px 0', flexWrap: 'wrap' }}>
        {
          tags.map((tag, index) => {
            const isLongTag = tag.length > 20
            const tagElem = (
              <Tag
                style={
                  {
                    width: 'fit-content',
                    height: '40px',
                    borderRadius: '20px',
                    backgroundColor: '#f4f4f4',
                    border: 'solid 2px #f2f2f2',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px 14px',
                    margin: '7px 14px 7px 0px',
                  }
                }
                key={tag}
                closable={false}
                onClose={() => handleClose(tag)}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                <img
                  src={images.x_btn_img}
                  alt="close"
                  onClick={() => handleClose(tag)}
                  style={
                    {
                      marginLeft: '10px',
                      width: '14px',
                      height: '14px',
                      cursor: 'pointer',
                    }
                  }
                />
              </Tag>
            )
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            )
          })
        }
        {
          inputVisible && (
            <Input
              ref={inputId}
              type="text"
              style={
                {
                  width: '103px',
                  height: '40px',
                  borderRadius: '20px',
                  border: 'solid 2px #f4f4f4',
                  padding: '10px 14px',
                  margin: '7px 14px 7px 0px',
                }
              }
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )
        }
        {
          !inputVisible && (
            <Tag
              onClick={showInput}
              style={
                {
                  width: '103px',
                  height: '40px',
                  borderRadius: '20px',
                  backgroundColor: 'white',
                  border: 'solid 2px #f2f2f2',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '7px 14px 7px 0px',
                }
              }
            >
              <img
                src={images.plus_btn_img}
                alt="plus_btn"
                style={{ width: '14px', height: '14px', marginRight: '6px' }}
              />
              <Caption1 color="#646464">태그 입력</Caption1>
            </Tag>
          )
        }
      </FlexRowBox>
    </div>
  )
}
export default TagForm

const DefaultTag = styled.label`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: fit-content;
  min-width: 74px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 40px;
  background-color: ${props => (props.isActive ? '#f4f4f4' : '#ffffff')};
  border-radius: 20px;
  border: ${props =>
    props.isActive ? '1px solid #646464' : '1px solid #f4f4f4'};
  color: #646464;
  cursor: pointer;
`
