import styled from 'styled-components'
import { mobile } from '@styles/media'

/*
 *   Sub Title Text Component
 *   option props : size(font-size), color
 */
const SubTitle = styled.p`
  text-align: center;
  font-family: NotoSansCJKkr-Medium;
  font-size: 1.2rem;
  color: ${props => props.color};

  @media ${mobile} {
    font-size: 1rem;
  }
`

export default SubTitle
