import { Flex, H3, H5, H6 } from '@components/atoms'
import { LiveRoomModel } from '@stores/models'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'

import { ReserveSalonCard, SalonCard } from '@components/organisms'
import { colors } from '@resources/colors'
import { useHistory } from 'react-router-dom'
import { PageLayout } from '..'

interface ISalonListTemplate {
  representiveTagList: any;
  salonSearchKeyword: string;
  salonList: Array<LiveRoomModel>;
  handleSearchTag: Function;
  hasMore: boolean;
  handleScrollList: Function;
}

const SalonListTemplate: React.FC<ISalonListTemplate> = ({
  representiveTagList,
  salonSearchKeyword,
  salonList,
  handleSearchTag,
  hasMore,
  handleScrollList,
  isInitializedPage,
}) => {
  const history = useHistory()

  const firstReserveLiveRoomIdx = salonList?.findIndex(
    liveRoom => liveRoom['isReserved'] && !liveRoom['isOpened'],
  )

  return (
    <PageLayout>
      <Flex type="column" style={{ width: '100%', maxWidth: 1680 }}>
        <H3 align="left" type="Bold" style={{ marginBottom: 24 }}>
          SALON 살롱
        </H3>
        <Flex>
          <H6
            onClick={() => history.push('/p/salon/list')}
            type="Bold"
            style={
              {
                cursor: 'pointer',
                borderBottom: `2px solid ${colors.black_24}`,
                marginRight: 28,
              }
            }
          >
            살롱
          </H6>
          <H6
            onClick={() => history.push('/p/salon/reservationList')}
            type="Bold"
            color={colors.grey_bf}
            style={{ cursor: 'pointer' }}
          >
            오픈 예정 살롱
          </H6>
        </Flex>
      </Flex>
      <TagList>
        <TagBox
          onClick={() => handleSearchTag('')}
          active={!salonSearchKeyword}
        >
          전체
        </TagBox>
        {
          representiveTagList.map(
            (tag, index) =>
              index < 5 && (
                <TagBox
                  key={`${tag}_${index}`}
                  onClick={() => handleSearchTag(tag)}
                  active={salonSearchKeyword === tag}
                >
                  {`#${tag}`}
                </TagBox>
              ),
          )
        }
      </TagList>
      {/* 기본 살롱 리스트 => 스토어 기본 로딩 렌더 */
      /* 태그별 살롱 리스트 => 태그별 로딩 렌더 */
        !isInitializedPage || hasMore || (salonList && salonList.length > 0) ? (
          <Content>
            <InfiniteScroll
            // scrollableTarget="scrollableDiv"
              dataLength={salonList.length}
              scrollThreshold={0.9}
              next={handleScrollList}
              hasMore={hasMore}
              className="scroll_div"
              scrollableTarget="scrollableDiv"
              // style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
              style={{ width: '100%', overflow: 'visible' }}
              hasChildren
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
            >
              <List style={{ marginTop: 48 }}>
                {
                  salonList
                && salonList.map((room, index) => {
                  if (room['isReserved'] && !room['isOpened']) {
                    if (index === firstReserveLiveRoomIdx) {
                      // 첫번째 예약살롱일 때, 줄바꿈 div 삽입
                      return (
                        <>
                          <div style={{ width: '100%' }} />
                          <ReserveSalonCard
                            salon={room}
                            key={`reserve_${room.id}`}
                            // type="list"
                            type="reservationList"
                          />
                        </>
                      )
                    }
                    return (
                      <ReserveSalonCard
                        salon={room}
                        key={`reserve_${room.id}`}
                        // type="list"
                        type="reservationList"
                      />
                    )
                  }
                  return <SalonCard item={room} key={room.id} />
                })
                }
              </List>
            </InfiniteScroll>
          </Content>
        ) : (
          <H5>생성된 살롱이 없습니다!</H5>
        )
      }
    </PageLayout>
  )
}

export default observer(SalonListTemplate)

const TagList = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

const TagBox = styled(Flex)`
  transition: 0.35s;
  justify-content: center;
  align-items: center;

  width: 248px;
  height: 64px;
  padding: 22px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${({ active }) => (active ? '#594ee9' : '#ffffff')};
  color: ${({ active }) => (active ? '#ffffff' : '#242424')};

  cursor: pointer;
`

const Content = styled(Flex)`
  max-width: 1704px;
  width: calc(100% + 39px);
  margin-left: -24px;
  height: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* width: calc(100% + 39px);

  margin-left: -24px; */

  > div {
    margin-left: 24px;
    margin-bottom: 48px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
