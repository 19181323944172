import React from 'react'
import styled from 'styled-components'

const Container = styled.span`
  color: ${props => props.color};
`

const Color = ({ children, color, style }) => {
  return (
    <Container color={color} style={style}>
      {children}
    </Container>
  )
}

export default Color
