import styled from 'styled-components'
import { mobile, tablet, smallDesktop } from '@styles/media'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #ffffff;
  /* max-width: 1275px; */
  /* max-width:1704px; */
  max-width:calc(100vw - 216px);
  width: 100%;
  height: 100%;
  min-height: 990px;
  padding: 50px 48px 88px 48px;
  align-items: center;

  /* @media ${smallDesktop} {
    align-items: unset;
  } */

  /*
  @media ${tablet} {
    width: 100%;
    margin: 0 auto;
    min-height: 800px;
    padding: 80px 40px 100px 40px;
  } */

  @media ${mobile} {
    max-width:100%;
    width:100%;
    height:100%;
    align-items: center;
    padding: 40px 20px 20px 20px;
  }
`

export default BodyWrapper
