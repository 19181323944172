import { observable } from 'mobx';

import { UserModel } from './'

export default class UsersHaveAlbumsModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable userId
  @observable albumId

  @observable User
  @observable Album

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.userId = props.userId
      this.albumId = props.albumId

      this.User = props.User && new UserModel(stores, props.User)
      // this.Album = props.Album && new AlbumModel(stores, props.Album)
    }
  }
}