import { Spin } from 'antd'
import React, { useState } from 'react'

/**
 * 특정 Tag 영역 안에서 Spinner를 사용하고 싶을 때
 * created at 2020. 11. 03
 * made by leehaehun
 */
export default ({ style } = {}) => {
  const [isLoading, setIsLoading] = useState(false)

  const Spinner = () => {
    if (isLoading) {
      return (
        <div
          style={
            {
              position: 'absolute',
              display: 'flex',
              top: 0,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(36, 36, 36, 0.5)',
              zIndex: 10,
              ...style,
            }
          }
        >
          <Spin size="large" />
        </div>
      )
    }
    return <></>
  }

  return {
    Spinner,
    isLoading,
    setIsLoading,
  }
}
