import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  H6,
  Flex,
  Caption1,
  Caption2,
  Button,
  ProfileImg,
} from '@components/atoms'
import { images } from '@resources/'
import { observer } from 'mobx-react'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { colors } from '@resources/colors'
import { DeletePopup } from '@components/molecules'
import { useStore } from '@utils/hooks'
import {
  SubscriptionModalTemplate,
  SubscriptionTemplate,
} from '@components/templates'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4050;
  background: white;
  border-radius: 16px;
  max-width: 800px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle }) => {
  const { isShowing: showDelete, toggle: toggleDelete } = DeletePopup.useModal()
  const { authStore, purchaseStore } = useStore()

  const currentUser = authStore.currentUser || null

  const myPlan = purchaseStore.myPlan || null
  const getMyPlan = purchaseStore.getMyPlan || (() => {})
  const isValidatedStudent = purchaseStore.isValidatedStudent || null
  const getValidateStudent = purchaseStore.getValidateStudent || (() => {})
  const getPaymentParams = purchaseStore.getPaymentParams || (() => {})
  const getPurchasePlans = purchaseStore.getPurchasePlans || (() => {})

  const [isEdit, setIsEdit] = React.useState(false)

  React.useEffect(() => {
    if (isShowing) {
      getMyPlan()
      getValidateStudent()
    }
  }, [getMyPlan, getValidateStudent, isShowing])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => {}} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <EditBtn
              onClick={
                () => {
                  setIsEdit(!isEdit)
                }
              }
            >
              <img
                src={images.x_btn_img}
                alt="close_btn"
                style={{ width: '16px', height: '16px' }}
                onClick={
                  () => {
                    toggle()
                  }
                }
              />
            </EditBtn>
            <MobdlBody
              style={
                {
                  position: 'relative',
                  width: '100%',
                  padding: '24px 24px 40px 24px',
                }
              }
            >
              <Flex style={{ height: 64 }} justify="center">
                <H6>{`플랜 시작`}</H6>
              </Flex>
              <SubscriptionModalTemplate
                user={currentUser}
                isValidatedStudent={isValidatedStudent}
                getPaymentParams={getPaymentParams}
                // purchasePlan={purchasePlan}
                getPurchasePlans={getPurchasePlans}
                myPlan={myPlan}
                mainToggle={toggle}
              />
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}

const EditBtn = styled(Caption1)`
  z-index: 5000;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const UserList = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const UserItem = styled(Flex)`
  transition: 0.35s;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`

const AcceptBtn = styled(Button)`
  transition: 0.35s;
  width: 48px;
  height: 32px;
  border-radius: 4px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 12px;
`

const DeleteBtnList = styled(Flex)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;

  > div {
    width: 50%;
    height: 100%;
  }
`
