import styled from 'styled-components'
import { mobile } from '@styles/media'

const RowToColumnBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${mobile} {
    flex-direction: column;
  }
`

export default RowToColumnBox
