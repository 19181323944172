import { images } from '@images/'
import { handleProfileImgError } from '@utils/handler'
import React from 'react'
import styled from 'styled-components'

const ProfileImg = (
  props: React.ImgHTMLAttributes & React.CSSProperties & string,
) => {
  const { src, style, profileColor, ...imgProps } = props
  return (
    <Image
      {...imgProps}
      src={src || images.default_profile_img}
      style={style}
      alt="profile_img"
      profileColor={profileColor}
      onError={e => handleProfileImgError(e)}
    />
  )
}

export default ProfileImg

const Image = styled.img`
  background-color: ${({ profileColor }) => profileColor && profileColor};
`
