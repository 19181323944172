import {
  Caption1,
  H6,
  ProfileImg,
  Div,
  ImageIcon,
  StyledButton,
} from '@components/atoms'
import { MobileLeadPopup } from '@components/molecules'
import { MobileCastPlayer } from '@components/organisms'
import { colors } from '@resources/colors'
import {
  oneEllipsisStyle,
  threeEllipsisStyle,
  twoEllipsisStyle,
} from '@styles/fontStyles'
import { converseUnitEng, secondsToTime } from '@utils/format'
import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const MobileCastTemplate = ({ castDetail, dynamicLink = '', onToast }) => {
  const { isShowing, toggle } = MobileLeadPopup.usePopup()

  return (
    <>
      <Box>
        <Div
          position="sticky"
          top="0"
          zIndex={3}
          width="100%"
          height="48px"
          padding="14px 20px"
          backgroundColor={colors.white_ff}
        >
          <ImageIcon name="hreumLogo" width="144px" height="20px" />
        </Div>
        <MobileCastPlayer cast={castDetail} />
        <Div
          display="flex"
          flex="1"
          flexDirection="column"
          cursor="pointer"
          onClick={toggle}
        >
          <Div display="flex" marginTop="10px" padding="0px 16px">
            <StyledButton
              designType="borderFillRoundEA"
              size="xs"
              height="28px"
              padding="6px 10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginRight="8px"
            >
              <ImageIcon
                name="emptyHeartBlack"
                width="16px"
                height="16px"
                marginRight="6px"
              />
              <span>{converseUnitEng(castDetail?.likeCount || 0)}</span>
            </StyledButton>
            <StyledButton
              designType="borderFillRoundEA"
              size="xs"
              height="28px"
              padding="6px 10px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ImageIcon
                name="emptyCommentBlack"
                width="16px"
                height="16px"
                marginRight="6px"
              />
              <span>{converseUnitEng(castDetail?.commentCount || 0)}</span>
            </StyledButton>
          </Div>

          <H6
            style={
              {
                ...twoEllipsisStyle,
                marginTop: 12,
                padding: '0px 16px',
                textAlign: 'left',
              }
            }
          >
            {castDetail?.title || ''}
          </H6>

          <Div display="flex" marginTop="4px" padding="0px 16px">
            <Caption1 color={colors.grey_64} type="Regular">
              {`${secondsToTime(castDetail?.duration || 0)}`}
            </Caption1>
            <InfoDot />
            <Caption1 color={colors.grey_64} type="Regular">
              {`조회수 ${converseUnitEng(castDetail?.playCount || 0)}회`}
            </Caption1>
            <InfoDot />
            <Caption1 color={colors.grey_64} type="Regular">
              {moment(castDetail?.publishedAt).format('YYYY. MM. DD')}
            </Caption1>
          </Div>

          <Br />

          <Div display="flex" justifyContent="space-between" padding="0px 16px">
            <Div display="flex" alignItems="center">
              <ProfileImg
                src={castDetail?.User?.imageUri}
                profileColor={castDetail?.User?.profileColor}
                style={
                  {
                    marginRight: 6,
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                  }
                }
              />
              <Caption1
                color={colors.grey_64}
                align="left"
                style={
                  {
                    ...oneEllipsisStyle,
                  }
                }
              >
                {castDetail?.User?.name}
              </Caption1>
              {
castDetail?.User?.isInfluencer && (
                  <ImageIcon
                    name="influencerBadge"
                    width="12px"
                    height="12px"
                    marginLeft="2px"
                  />
                )
              }
            </Div>
            <StyledButton
              designType="primary"
              size="xs"
              padding="0 8px"
              textAlign="center"
              fontSize="10px"
              lineHeight="20px"
            >
              팔로우
            </StyledButton>
          </Div>

          <Br />

          {
castDetail?.CastsHaveTags?.length > 0 && (
              <>
                <Div
                  display="flex"
                  width="100%"
                  height="30px"
                  overflowX="scroll"
                  overflowY="hidden"
                >
                  {
castDetail?.CastsHaveTags?.map(tag => (
  <Tag key={tag.Tag.id}>{`#${tag.Tag.keyword}`}</Tag>
))
                  }
                </Div>
                <Br />
              </>
            )
          }

          <Div display="flex" padding="0 16px">
            <StyledButton
              designType="borderEA"
              size="xm"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex="1"
              marginRight="12px"
            >
              <ImageIcon
                name="present"
                width="24px"
                height="24px"
                marginRight="2px"
              />
              <Caption1>선물하기</Caption1>
            </StyledButton>
            <StyledButton
              designType="borderEA"
              size="xm"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex="1"
              onClick={
                e => {
                  e.stopPropagation()

                  const tempElem = document.createElement('textarea')
                  tempElem.value = dynamicLink
                  document.body.appendChild(tempElem)

                  tempElem.select()
                  document.execCommand('copy')
                  document.body.removeChild(tempElem)

                  onToast({ msg: '링크가 복사되었습니다.' })
                }
              }
            >
              <ImageIcon
                name="clipBlack"
                width="16px"
                height="16px"
                marginRight="2px"
              />
              <Caption1>공유하기</Caption1>
            </StyledButton>
          </Div>

          <Br height="6px" />

          <Div display="flex" flexDirection="column" padding="0 16px">
            <H6 align="left">에피소드 소개</H6>
            <Caption1
              type="Regular"
              style={{ ...threeEllipsisStyle, textAlign: 'left', marginTop: 8 }}
            >
              {castDetail?.description || '-'}
            </Caption1>
          </Div>
        </Div>
      </Box>
      <MobileLeadPopup.View
        isShowing={isShowing}
        toggle={toggle}
        dynamicLink={dynamicLink}
      />
    </>
  )
}

export default observer(MobileCastTemplate)

const Box = styled(Div)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > *::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`

const InfoDot = () => {
  return (
    <Div
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingLeft="6px"
      paddingRight="6px"
    >
      <Div
        width="2px"
        height="2px"
        backgroundColor={colors.grey_64}
        borderRadius="1px"
      />
    </Div>
  )
}

const Br = styled(Div)`
  width: 100vw;
  height: 1px;
  background-color: ${colors.white_f4};
  margin: 12px 0px;
`

const Tag = styled(Div)`
  width: fit-content;
  height: 28px;
  border-radius: 16px;
  border: 1px solid ${colors.white_f4};
  background-color: ${colors.white_f4};
  padding: 4px 8px;
  line-height: 18px;
  font-size: 12px;
  margin-left: 16px;
  white-space: pre;
`
