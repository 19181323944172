import React from 'react'
import { Flex, H3, Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import MixtapeListItem from '@components/molecules/item/MixtapeListItem'
import { Link } from 'react-router-dom'
import { mobile } from '@styles/media'

// 전체 영역
const ListBox = styled(Flex)`
  max-width: 1704px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  @media ${mobile} {
    min-width: auto;
  }
`

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;

  max-width: 1607px;
  width: 100%;
`

// 리스트 내용 영역
const ListContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

/**
 * 모든 카테고리 플라 리스트 컴포넌트
 */
const WholeMixtapeList = ({
  myListenedMixtapeList,
  totalMixtapeList,
  myLikeMixtapeList,
  myFollowMixtapeList,
}) => {
  const maxItemCount = window.innerWidth >= 1905 ? 6 : 5
  return (
    <>
      {
totalMixtapeList?.length > 0 && (
          <ListBox>
            <ListHeader>
              <H3 type="Bold">인기 플라</H3>
              <Link to="/p/plra/list/total">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                전체 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                totalMixtapeList
              && totalMixtapeList.map(
                (item, index) =>
                  index < maxItemCount && (
                    <MixtapeListItem
                      item={item}
                      key={`total_${item.id}`}
                      category="total"
                    />
                  ),
              )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
myListenedMixtapeList?.length > 0 && (
          <ListBox>
            <ListHeader>
              <H3 type="Bold">즐겨듣는 플라</H3>
              <Link to="/p/plra/list/history">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                전체 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                myListenedMixtapeList
              && myListenedMixtapeList.map(
                (item, index) =>
                  index < maxItemCount && (
                    <MixtapeListItem
                      item={item}
                      key={`history_${item.id}`}
                      category="history"
                    />
                  ),
              )
              }
            </ListContent>
          </ListBox>
        )
      }

      {
myLikeMixtapeList?.length > 0 && (
          <ListBox>
            <ListHeader>
              <H3 type="Bold">좋아하는 플라</H3>
              <Link to="/p/plra/list/like">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                전체 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                myLikeMixtapeList
              && myLikeMixtapeList.map(
                (item, index) =>
                  index < maxItemCount && (
                    <MixtapeListItem
                      item={item}
                      key={`like_${item.id}`}
                      category="like"
                    />
                  ),
              )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
myFollowMixtapeList?.length > 0 && (
          <ListBox>
            <ListHeader>
              <H3 type="Bold">구독한 DJ의 플라</H3>
              <Link to="/p/plra/list/subscribe">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                전체 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                myFollowMixtapeList
              && myFollowMixtapeList.map(
                (item, index) =>
                  index < maxItemCount && (
                    <MixtapeListItem
                      item={item}
                      key={`follow_${item.id}`}
                      category="subscribe"
                    />
                  ),
              )
              }
            </ListContent>
          </ListBox>
        )
      }
    </>
  )
}

export default WholeMixtapeList
