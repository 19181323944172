import { Caption2, Flex, H3 } from '@components/atoms'
import { SwitchTabs, YnPopup } from '@components/molecules'
import {
  StarAccountSection,
  StarChargeSection,
  StarPaymentForm,
} from '@components/organisms'
import { colors } from '@resources/colors'
import { addComma } from '@utils/string'
import { throttle } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { PageLayout } from '..'

const StarMainTemplate = ({
  currentUser,
  fetchTotalStarHistories,
  myStarHistoryList,
  tabIndex,
  setTabIndex,
  fetchPurchaseStar,
  purchaseStarList,
  purchaseRequstStar,
  paymentParams,
  setPaymentParams,
  exchangeStar,
}) => {
  const submitRef = React.createRef()

  const [exchangeInfo, setExchangeInfo] = React.useState({
    starValue: 0,
    revenueValue: 0,
  })

  const {
    isShowing: showPointToStar,
    toggle: togglePointToStar,
  } = YnPopup.usePopup()

  const _togglePointToStar = (starVal, revenueVal) => {
    setExchangeInfo({
      starValue: starVal,
      revenueValue: revenueVal,
    })
    togglePointToStar()
  }

  const handleExchangeStar = throttle(async () => {
    const data = {
      starValue: exchangeInfo.starValue,
      revenueValue: -1 * exchangeInfo.revenueValue,
    }

    const result = await exchangeStar(data)
    if (!result) return
    if (result?.response?.status === 409) {
      alert('포인트가 부족합니다')

      return
    }

    alert(`별 ${addComma(exchangeInfo.starValue)}개 충전 완료`)
  }, 250)

  return (
    <PageLayout style={{ paddingBottom: 0 }}>
      <Content>
        <H3 style={{ width: '100%' }} align="left" type="Bold">
          별
        </H3>
        <SwitchTabs
          isSwitchOn={0}
          setIsSwitchOn={setTabIndex}
          firstSwitchName="별 통장"
          // secondSwitchName="별 충전"
          firstSwitchContents={
            <StarAccountSection
              currentUser={currentUser}
              fetchTotalStarHistories={fetchTotalStarHistories}
              myStarHistoryList={myStarHistoryList}
            />
          }
          // secondSwitchContents={
          //   <StarChargeSection
          //     currentUser={currentUser}
          //     fetchPurchaseStar={fetchPurchaseStar}
          //     purchaseStarList={purchaseStarList}
          //     purchaseRequstStar={purchaseRequstStar}
          //     paymentParams={paymentParams}
          //     setPaymentParams={setPaymentParams}
          //     exchangeStar={exchangeStar}
          //     _togglePointToStar={_togglePointToStar}
          //   />
          // }
          headerStyle={
            {
              width: '248px',
              alignSelf: 'flex-end',
              marginTop: '-50px',
            }
          }
          contentStyle={{ overflow: 'visible', height: 864 }}
        />
      </Content>
      <PayFooter>
        <Caption2
          align="left"
          style={{ marginTop: 40 }}
          color={colors.brown_grey}
        >
          - 결제 금액의 30%는 전자결제 수수료(스토어 수수료)로 지급됩니다.
        </Caption2>
        {/* <Caption2
          align="left"
          style={{ marginTop: 16 }}
          color={colors.brown_grey}
        >
          - 결제 금액의 10%는 흐름드살롱 수수료로 지급됩니다.
        </Caption2> */}
        <Caption2
          align="left"
          style={{ marginTop: 16 }}
          color={colors.brown_grey}
        >
          - 구매한 별의 유효기간은 구매일로부터 3년입니다.
        </Caption2>
        <Caption2
          align="left"
          style={{ marginTop: 16 }}
          color={colors.brown_grey}
        >
          - 선물하지 않은 별은 본인이 직접 결제한 경우에만 7일 이내에 환불할 수
          있습니다.
        </Caption2>
        <Caption2
          align="left"
          style={{ marginTop: 16 }}
          color={colors.brown_grey}
        >
          - 포인트로 구매한 별은 환불이 불가능합니다.
        </Caption2>
        <Caption2
          align="left"
          style={{ marginTop: 16 }}
          color={colors.brown_grey}
        >
          - 자세한 안내는 유료서비스 이용 약관 및 고객센터를 통하여 확인하세요.
        </Caption2>
      </PayFooter>
      {
        paymentParams && (
          <StarPaymentForm
            paymentMethod="CARD"
            paymentParams={paymentParams}
            setPaymentParams={setPaymentParams}
            submitRef={submitRef}
          />
        )
      }
      <YnPopup.View
        isShowing={showPointToStar}
        toggle={togglePointToStar}
        onOk={handleExchangeStar}
        title="별 충전하기"
        body={
          `${addComma(exchangeInfo.revenueValue)} 포인트를 사용해 별 ${
            exchangeInfo.starValue
          }개를 충전합니다`
        }
      />
    </PageLayout>
  )
}

export default StarMainTemplate

const Content = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
  flex: 1;
  padding-bottom: 88px;
`

const PayFooter = styled(Flex)`
  flex-direction: column;
  padding: 0 26%;
  width: 100vw;
  height: 228px;
  /* height: 268px; */
  background-color: #f7f8fa;
`
