import { observable, computed } from 'mobx'
import { STORAGE_URL } from '../../resources'
import LiveRoomModel from './LiveRoomModel'

export default class LiveRoomRecordingModel {
  @observable id
  @observable title
  @observable resourceId
  @observable sid
  @observable uid
  @observable agoraRtcChannelId
  @observable mp4Uri
  @observable m3u8Uri
  @observable startedAt
  @observable stoppedAt
  @observable createdAt
  @observable updatedAt
  @observable duration

  @observable userId
  @observable liveRoomId

  @observable LiveRoom

  @computed get mp4Url() {
    return this.mp4Uri ? STORAGE_URL + this.mp4Uri : ''
  }
  @computed get m3u8Url() {
    return this.m3u8Uri ? STORAGE_URL + this.m3u8Uri : ''
  }

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id
      this.title = props.title
      this.resourceId = props.resourceId
      this.sid = props.sid
      this.uid = props.uid
      this.agoraRtcChannelId = props.agoraRtcChannelId
      this.mp4Uri = props.mp4Uri
      this.m3u8Uri = props.m3u8Uri
      this.startedAt = props.startedAt
      this.stoppedAt = props.stoppedAt
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.userId = props.userId
      this.liveRoomId = props.liveRoomId
      this.duration = props.duration

      this.LiveRoom =
        (props.LiveRoom && new LiveRoomModel(stores, props.LiveRoom)) || null
    }
  }
}
