import { Network } from '.'

export default class FeedNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getFeed = async (feedId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/${feedId}`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork getFeed error: ', err)
    }
  }

  getFeedFromLinkKey = async (feedId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/_linkKey/${feedId}`,
        'get',
        where,
      )
      return { isError: false, data: data || null }
    }
    catch (error) {
      console.log('getFeedFromLinkKey getFeed error: ', error)
      return { isError: true, error: error?.response?.data?.error }
    }
  }

  getFeedList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth('/feeds', 'get', where)
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork getFeedList error: ', err)
    }
  }

  postFeedLike = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/feeds/like',
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork postFeedLike error: ', err)
    }
  }

  deleteFeedLike = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/feeds/like',
        'delete',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork deleteFeedLike error: ', err)
    }
  }

  postFeeds = async where => {
    try {
      const { data } = await this.network._axiosApiAuth('/feeds', 'post', where)
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork postFeeds error: ', err)
    }
  }

  putFeeds = async (feedId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/${feedId}`,
        'put',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork putFeeds error: ', err)
    }
  }

  deleteFeeds = async (feedId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/${feedId}`,
        'delete',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork deleteFeeds error: ', err)
    }
  }

  getSuggestFeedList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/recommendation`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork getSuggestFollowList error: ', err)
    }
  }

  getFeedListCommentPreview = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/preview/comment`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork getFeedListCommentPreview error: ', err)
    }
  }

  getFeedNestedCommentPreview = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feeds/preview/comment/reply`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('FeedNetwork getFeedNestedCommentPreview error: ', err)
    }
  }
}
