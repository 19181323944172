import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  H6,
  Flex,
  Button,
  Caption1,
  Caption2,
  FormInput,
  FormLabel,
  Caption3,
  FormTextArea,
} from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { colors } from '@resources/colors'
import { TagBox, HouseToggleBtn, HouseTopicBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import { HOUSE_SUBJECT_TAG_LIST, HOUSE_DEFAULT_TAG_LIST } from '@consts/'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { message } from 'antd'
import { HouseImageUploadForm, HouseMembershipCard } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;
  overflow-y: scroll;

  width: 100%;
  height: 100%;
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 24px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle, isEdit = false }) => {
  const { tagStore, houseStore, authStore } = useStore()
  const history = useHistory()

  const { tempHouse } = houseStore
  const _autoCompleteTagObj = tagStore._autoCompleteTagObj || {}
  const fetchAutocompleteTags = tagStore.fetchAutocompleteTags || (() => {})

  const createHouse = houseStore.createHouse || (() => {})
  const updateHouse = houseStore.updateHouse || (() => {})
  const checkTitleDuplicated = houseStore.checkTitleDuplicated || (() => {})

  const [tempTopicList, setTempTopicList] = useState([])
  const [tempTagList, setTempTagList] = React.useState([]) // 주제 리스트

  const [tagInput, setTagInput] = React.useState('') // 주제 검색값
  const [isSearching, setIsSearching] = React.useState(false) // 주제 검색중
  const [showAcceptContents, setShowAcceptContents] = React.useState(false)

  const isOwner = !isEdit || tempHouse?.userId === authStore.currentUser?.id

  let contentAcceptText = '호스트'
  switch (tempHouse?.contentPermissionLevel) {
    case HOUSES_HAVE_USERS_LEVEL['FOLLOWER']:
      contentAcceptText = '팔로워'
      break
    case HOUSES_HAVE_USERS_LEVEL['MEMBER']:
      contentAcceptText = '멤버'
      break
    default:
      contentAcceptText = '호스트'
      break
  }

  /* Topic Logic */
  const _onPressGenre = tag => {
    const selectedIndex = tempTopicList?.findIndex(
      elem => elem['keyword'] === tag['keyword'],
    )

    if (selectedIndex < 0) {
      if (tempTopicList?.length >= 3) {
        alert(`최대 3개까지 선택 가능합니다.`)
      }
      else {
        tempTopicList && tempTopicList.push(tag)
      }
    }
    else {
      tempTopicList && tempTopicList.splice(selectedIndex, 1)
    }
    setTempTopicList([...tempTopicList])
  }

  const selectedCheck = tag => {
    return (
      tempTopicList?.findIndex(elem => elem['keyword'] === tag['keyword']) >= 0
    )
  }
  /** ****************** */

  // 자동완성 검색
  const searchTagList = async text => {
    try {
      if (_autoCompleteTagObj[text]) {
        return
      }
      _autoCompleteTagObj[text] = { list: [] }
      const tagList = await fetchAutocompleteTags(text)

      if (_autoCompleteTagObj[text]) {
        _autoCompleteTagObj[text]['list'] = tagList
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  // 태그 검색어
  const handleChangeTag = async e => {
    setTagInput(e.target.value)
    await searchTagList(e.target.value)
  }

  // 추천 태그
  const handleClickRequestTag = tag => {
    if (tempTagList.length >= 15) return
    const { keyword } = tag

    setIsSearching(false)
    setTagInput('')

    const index = tempTagList.findIndex(
      elem => elem && elem['keyword'] === keyword,
    )
    if (index < 0) {
      tempTagList.push({ keyword })
      setTempTagList([...tempTagList])
    }
  }

  // 선택 태그 제거
  const handleRemoveTag = (tag, index) => {
    tempTagList.splice(index, 1)
    setTempTagList([...tempTagList])
  }

  const submitHouse = async () => {
    if (isEdit) {
      const isEdited = await await updateHouse(
        tempHouse,
        tempTopicList?.map(tag => tag['keyword']),
        tempTagList.map(tag => tag['keyword']),
      )
      if (isEdited) {
        toggle()
      }
    }
    else {
      if (!tempHouse?.title) {
        message.error('새로고침하고 다시 시도해주세요.')
        return
      }
      const isDuplicate = await checkTitleDuplicated(tempHouse.title)
      if (isDuplicate) {
        message.error('이미 존재하는 하우스명입니다.')
        return
      }

      const isCreated = await createHouse(
        tempHouse,
        tempTopicList?.map(tag => tag['keyword']),
        tempTagList.map(tag => tag['keyword']),
      )
      if (isCreated) {
        history.push({
          pathname: `/house/${isCreated['linkKey']}`,
        })
      }
    }
  }

  React.useEffect(() => {
    if (isEdit && houseStore.tempHouse) {
      setTempTopicList(
        houseStore?.tempHouse?.HousesHaveTags?.filter(
          item => item.level !== 30,
        ).map(item => {
          return {
            keyword: item.Tag.keyword,
          }
        }),
      )
      setTempTagList(
        houseStore?.tempHouse?.HousesHaveTags?.filter(
          item => item.level !== 10,
        ).map(item => {
          return {
            keyword: item.Tag.keyword,
          }
        }),
      )
    }
  }, [isEdit, houseStore, houseStore.tempHouse])

  React.useEffect(() => {
    if (!isEdit) {
      houseStore.updateTempHouse()
    }
  }, [isEdit, houseStore])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <ModalBody style={{ height: '91%', overflowY: 'auto' }}>
              {
                isSearching && (
                  <Overlay onClick={() => setIsSearching(false)} />
                )
              }
              <H6 style={{ marginBottom: 12 }}>
                {`하우스 ${isEdit ? '수정하기' : '만들기'}`}
              </H6>
              <RequiredBox>
                <Flex style={{ width: 136, marginRight: 16 }}>
                  <HouseImageUploadForm
                    imageUri={tempHouse?.imageUri}
                    uploadImage={
                      filePath => {
                        houseStore.uploadImage(filePath)
                      }
                    }
                  />
                </Flex>
                <Flex type="column" style={{ width: '100%' }}>
                  <TypeListBox
                    style={{ marginBottom: 8, position: 'relative' }}
                    onClick={
                      () => {
                        setShowAcceptContents(!showAcceptContents)
                      }
                    }
                  >
                    <Caption3>콘텐츠 구성 허용</Caption3>
                    <Caption3 color={colors.primary}>
                      {contentAcceptText}
                    </Caption3>
                    {
                      showAcceptContents && (
                        <AcceptContentsBox>
                          <AcceptContentsItem
                            onClick={
                              e => {
                                e.stopPropagation()
                                houseStore.updateTempHouse(
                                  'contentPermissionLevel',
                                  HOUSES_HAVE_USERS_LEVEL['HOST'],
                                )
                                setShowAcceptContents(false)
                              }
                            }
                          >
                            호스트
                          </AcceptContentsItem>
                          <AcceptContentsItem
                            onClick={
                              e => {
                                e.stopPropagation()
                                houseStore.updateTempHouse(
                                  'contentPermissionLevel',
                                  HOUSES_HAVE_USERS_LEVEL['MEMBER'],
                                )
                                setShowAcceptContents(false)
                              }
                            }
                          >
                            멤버
                          </AcceptContentsItem>
                          <AcceptContentsItem
                            onClick={
                              e => {
                                e.stopPropagation()
                                houseStore.updateTempHouse(
                                  'contentPermissionLevel',
                                  HOUSES_HAVE_USERS_LEVEL['FOLLOWER'],
                                )
                                setShowAcceptContents(false)
                              }
                            }
                          >
                            팔로워
                          </AcceptContentsItem>
                        </AcceptContentsBox>
                      )
                    }
                  </TypeListBox>
                  <TypeListBox style={{ marginBottom: 8 }}>
                    <Caption3>멤버 리스트 비공개</Caption3>
                    <HouseToggleBtn
                      checked={tempHouse.isPrivateMemberList}
                      onChange={
                        e => {
                          houseStore.updateTempHouse(
                            'isPrivateMemberList',
                            e.target.checked,
                          )
                        }
                      }
                    />
                  </TypeListBox>
                  <TypeListBox>
                    <Caption3>팔로워 멤버 룸 비공개</Caption3>
                    <HouseToggleBtn
                      checked={tempHouse.isPrivateMemberRoom}
                      onChange={
                        e => {
                          houseStore.updateTempHouse(
                            'isPrivateMemberRoom',
                            e.target.checked,
                          )
                        }
                      }
                    />
                  </TypeListBox>
                </Flex>
              </RequiredBox>

              <FormGroup style={{ display: 'flex', marginTop: 24 }}>
                <Flex type="column" style={{ width: '100%' }}>
                  <FormLabel
                    style={
                      {
                        fontSize: 12,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }
                  >
                    <Caption2>
                        주제
                      <Caption2 color={colors.primary}>&nbsp;(필수)</Caption2>
                    </Caption2>
                    <Caption2>{`${tempTopicList?.length || 0} / 3`}</Caption2>
                  </FormLabel>

                  <Flex style={{ width: '100%', gap: 8, flexWrap: 'wrap' }}>
                    {
                      HOUSE_SUBJECT_TAG_LIST.map((tag, index) => (
                        <HouseTopicBtn
                          key={index}
                          onPress={_onPressGenre}
                          selectedCheck={selectedCheck}
                          tag={tag}
                        />
                      ))
                    }
                  </Flex>
                </Flex>
              </FormGroup>
              <FormGroup style={{ display: 'flex' }}>
                <Flex type="column" style={{ width: '100%' }}>
                  <FormLabel
                    style={
                      {
                        fontSize: 12,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }
                  >
                    <Caption2>
                        이름
                      <Caption2 color={colors.primary}>&nbsp;(필수)</Caption2>
                    </Caption2>
                  </FormLabel>
                  <Flex
                    align="center"
                    style={
                      {
                        border: '1px solid #949494',
                        borderRadius: 7,
                        width: '100%',
                      }
                    }
                  >
                    <FormInput
                      style={{ border: 'none', fontSize: 12, height: 40 }}
                      placeholder="하우스 이름을 입력해주세요 (20자 이내)"
                      value={tempHouse.title}
                      onChange={
                        e => {
                          houseStore.updateTempHouse('title', e.target.value)
                        }
                      }
                    />
                  </Flex>
                </Flex>
              </FormGroup>
              <FormGroup>
                <Flex type="column">
                  <FormLabel
                    style={
                      {
                        fontSize: 12,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }
                  >
                    <Caption2>
                        설명
                      <Caption2 color={colors.grey_94}>&nbsp;(선택)</Caption2>
                    </Caption2>
                  </FormLabel>
                  <Flex
                    align="center"
                    style={
                      {
                        border: '1px solid #949494',
                        borderRadius: 7,
                        width: '100%',
                      }
                    }
                  >
                    <FormTextArea
                      style={
                        {
                          border: 'none',
                          fontSize: 12,
                          margin: 0,
                          resize: 'none',
                        }
                      }
                      placeholder="하우스 설명을 입력해주세요"
                      value={tempHouse.description}
                      onChange={
                        e =>
                          houseStore.updateTempHouse(
                            'description',
                            e.target.value,
                          )
                      }
                    />
                  </Flex>
                </Flex>
              </FormGroup>
              {/* <FormGroup>
                <Flex type="column">
                  <FormLabel
                    style={
                      {
                        fontSize: 12,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }
                  >
                    <Caption2>
                        링크
                      <Caption2 color={colors.grey_94}>&nbsp;(선택)</Caption2>
                    </Caption2>
                  </FormLabel>
                  <Flex
                    align="center"
                    style={
                      {
                        border: '1px solid #949494',
                        borderRadius: 7,
                        width: '100%',
                      }
                    }
                  >
                    <FormInput
                      style={{ border: 'none', fontSize: 12, height: 40 }}
                      placeholder="링크를 입력해주세요"
                      value={tempHouse.link}
                      onChange={
                        e =>
                          houseStore.updateTempHouse('link', e.target.value)
                      }
                    />
                  </Flex>
                </Flex>
              </FormGroup> */}
              <FormGroup style={{ position: 'relative' }}>
                <FormLabel
                  style={
                    {
                      fontSize: 12,
                      marginBottom: 8,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }
                  }
                >
                  <Caption2>
                      태그
                    <Caption2 color={colors.grey_94}>&nbsp;(선택)</Caption2>
                  </Caption2>
                  <Caption2>{`${tempTagList?.length || 0} / 15`}</Caption2>
                </FormLabel>

                <FormInput
                  style={{ fontSize: 12, height: 40 }}
                  placeholder="원하시는 주제를 입력해보세요"
                  onFocus={() => setIsSearching(true)}
                  value={tagInput}
                  onChange={handleChangeTag}
                  onKeyDown={
                    e => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        handleClickRequestTag({ keyword: tagInput })
                      }
                    }
                  }
                />
                {
                  isSearching && !!tagInput && (
                    <SearchBox>
                      {
                        _autoCompleteTagObj
                        && _autoCompleteTagObj[tagInput]
                        && _autoCompleteTagObj[tagInput]['list'].map(item => {
                          return (
                            <SearchItem
                              onClick={() => handleClickRequestTag(item)}
                              key={item.id}
                            >
                              <Caption1>{`#${item.keyword}`}</Caption1>
                              <Caption1 color="#949494">{`${item.totalCount}개`}</Caption1>
                            </SearchItem>
                          )
                        })
                      }
                    </SearchBox>
                  )
                }
              </FormGroup>
              <Flex style={{ width: '100%', gap: 8, flexWrap: 'wrap' }}>
                {
                  HOUSE_DEFAULT_TAG_LIST.map((tag, index) => (
                    <TagBox
                      item={tag}
                      index={index}
                      key={index}
                      onClick={handleClickRequestTag}
                    />
                  ))
                }
              </Flex>
              {
tempTagList?.length > 0 && (
                  <Flex
                    style={
                      {
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: 8,
                        marginTop: 8,
                      }
                    }
                  >
                    {
                      tempTagList.map((tag, index) => (
                        <TagBox
                          item={tag}
                          isTemp
                          onRemove={handleRemoveTag}
                          index={index}
                          key={tag.keyword}
                        />
                      ))
                    }
                  </Flex>
                )
              }

              {isOwner && <HouseMembershipCard />}

              <ButtonList>
                {
                  isEdit && tempHouse.userId === authStore?.currentUser?.id && (
                    <DeletedBtn
                      onClick={
                        async () => {
                          await houseStore.deleteHouse(tempHouse)
                          toggle()
                          history.push('/')
                        }
                      }
                      disabled={
                        !tempHouse?.title
                        || !!tempTopicList?.length < 1
                        || !tempHouse?.imageUri
                      }
                    >
                      하우스 삭제하기
                    </DeletedBtn>
                  )
                }
                <SelectedBtn
                  onClick={submitHouse}
                  disabled={
                    !tempHouse?.title
                      || !!tempTopicList?.length < 1
                      || !tempHouse?.imageUri
                  }
                >
                  {isEdit ? '저장하기' : '만들기'}
                </SelectedBtn>
              </ButtonList>
            </ModalBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}

const RequiredBox = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`

const ButtonList = styled(Flex)`
  position: absolute;
  bottom: 16px;

  width: calc(100% - 16px);
  height: 48px;
  gap: 8px;
`

const DeletedBtn = styled(Button)`
  flex: 1;
  height: 100%;
  border-radius: 8px;
  background-color: ${colors.white_fa};
  color: ${colors.warning};
  font-size: 14px;
`

const SelectedBtn = styled(Button)`
  flex: 1;
  height: 100%;
  border-radius: 8px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 14px;

  :disabled {
    background-color: ${colors.white_f4};
    color: ${colors.grey_d4};
  }
`

const TypeListBox = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid ${colors.grey_d4};
  color: ${colors.black};
`

const FormGroup = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`

const SearchBox = styled(Flex)`
flex-direction:column;
    position:absolute;
    width : 472px;
    height:fit-content;
    max-height:180px;
    overflow: auto;
    justify-content : flex-start;
    border-radius: 7px;
    backdrop-filter: blur(30px);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    z-index:2;
}

  > div {
    width:100%;
    height:48px;
    padding : 12px 20px;
  }
`

const SearchItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const AcceptContentsBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 0;
  width: 320px;
  height: fit-content;
  max-height: 128px;
  overflow: auto;
  justify-content: flex-start;
  border-radius: 7px;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 2;
`

const AcceptContentsItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 11px 8px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`
