import { action, observable } from 'mobx'
import { Store } from '.'
import { NoticeModel, FAQModel } from './models'
import { Network } from './networks'

export default class CustomerCenterStore {
  @observable noticeList // 공지사항 목록
  @observable noticeListCount // 공지사항 총 row 수 ( for pagination )
  @observable noticeDetail // 상세 공지사항

  @observable faqList // FAQ 목록

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = () => {
    this.noticeList = []
    this.noticeListCount = 0
    this.noticeDetail = null

    this.faqList = []
  }

  @action.bound
  async sendFeedback({ type, message }) {
    try {
      const deviceInfo = `getUserAgent : ${navigator.userAgent}`

      const { data } = await this.network._axiosApiAuth('/feedbacks', 'post', {
        type,
        message: message + deviceInfo,
      })
      return data
    }
    catch (err) {
      console.log('customerCenterStore sendFeedback error: ', err)
    }
  }

  /**
   * 공지사항 목록 패치
   */
  @action fetchNoticeList = async option => {
    const __offset = (option && option.offset && option.offset) || 0

    const result = await this.network.customerCenterNetwork.getNotices({
      __limit: 10,
      __offset,
      __order: 'updatedAtDesc',
    })

    if (!result) return false

    if (option?.offset === 0) {
      const res = await this.network.customerCenterNetwork.getNoticesCount({
        __limit: 10,
        __offset,
        __order: 'updatedAtDesc',
      })
      if (!res) return false

      this.noticeListCount = res.totalCount
    }

    this.noticeList = result.map(notice => new NoticeModel(this.store, notice))
    return true
  }

  @action fetchNoticeDetail = noticeId => {
    return this.store.useLoading(async () => {
      const res = await this.network.customerCenterNetwork.getNotices({
        id: noticeId,
      })

      if (!res) return
      this.noticeDetail = new NoticeModel(this.store, res[0])
    })
  }
  @action fetchNoticeDetailFromLinkKey = noticeLinkKey => {
    return this.store.useLoading(async () => {
      const res = await this.network.customerCenterNetwork.getNoticeFromLinkKey(noticeLinkKey)

      if (!res) return
      this.noticeDetail = new NoticeModel(this.store, res)
    })
  }

  /**
   * FAQ 목록 패치
   */
  @action fetchFAQList = async () => {
    const result = await this.network.customerCenterNetwork.getFAQList()

    if (!result) return false

    this.faqList = result.map(faq => new FAQModel(this.store, faq))
    return true
  }

  @action sendAudioFeedback = async (songId, content) => {
    if (!content) return

    const options = {
      appVersion: 'web',
      manufacturer: 'web',
      model: 'web',
      systemName: 'web',
      systemVersion: 'web',
      // appVersion: await DeviceInfo.getVersion(),
      // manufacturer: await DeviceInfo.getManufacturer(),
      // model: await DeviceInfo.getModel(),
      // systemName: await DeviceInfo.getSystemName(),
      // systemVersion: await DeviceInfo.getSystemVersion(),
      ...content,
    }

    const result = await this.network.customerCenterNetwork.postAudioFeedBack(
      songId,
      options,
    )

    return result
  }

  @action sendLiveRoomFeedback = async (liveRoomId, reason, message) => {
    const { currentUser } = this.store.authStore

    if (!liveRoomId) {
      console.error('liveRoomId is required')
      return false
    }

    if (
      !currentUser
      || !currentUser['id']
      || !currentUser['email']
      || !currentUser['name']
    ) {
      console.error('currentUser is required')
      return false
    }

    const where = {
      type: '살롱 신고',
      reason,
      message,
      contactClient: {
        id: currentUser['id'],
        email: currentUser['email'],
        nickname: currentUser['name'],
      },
    }

    const data = await this.network.customerCenterNetwork.postLiveRoomFeedback(
      liveRoomId,
      where,
    )
    if (!data || data.length === 0) {
      console.error('sendLiveRoomFeedback error')
      return false
    }
    return true
  }

  @action sendLiveRoomUserFeedback = async (userId, reason, message) => {
    const { currentUser } = this.store.authStore

    if (currentUser['id'] === userId) return false

    if (!userId) {
      console.error('userId is required')
      return false
    }

    if (
      !currentUser
      || !currentUser['id']
      || !currentUser['email']
      || !currentUser['name']
    ) {
      console.error('currentUser is required')
      return false
    }

    const where = {
      type: '살롱 신고',
      reason,
      message,
      // contactClient: {
      //   id: currentUser['id'],
      //   email: currentUser['email'],
      //   nickname: currentUser['name'],
      // },
    }

    const data = await this.network.customerCenterNetwork.postLiveRoomUserFeedback(
      userId,
      where,
    )
    if (!data || data.length === 0) {
      console.error('sendLiveRoomUserFeedback error')
      return false
    }
    return true
  }

  @action sendCastsFeedback = async ({ castId, reason, message }) => {
    const { currentUser } = this.store.authStore

    if (!castId) {
      console.error('castId is required')
      return false
    }

    // if (
    //   !currentUser
    //   || !currentUser['id']
    //   || !currentUser['email']
    //   || !currentUser['name']
    // ) {
    //   console.error('currentUser is required')
    //   return false
    // }

    const where = {
      type: '캐스트 신고',
      reason,
      message,
    }

    if (currentUser) {
      where.contactClient = JSON.stringify({
        id: currentUser['id'],
        email: currentUser['email'],
        nickname: currentUser['name'],
      })
    }

    const data = await this.network.customerCenterNetwork.postFeedFeedback(
      castId,
      where,
    )
    if (!data || data.length === 0) {
      console.error('sendFeedsFeedback error')
      return false
    }
    return true
  }
  @action sendFeedsFeedback = async ({ feedId, reason, message }) => {
    const { currentUser } = this.store.authStore

    if (!feedId) {
      console.error('feedId is required')
      return false
    }

    // if (
    //   !currentUser
    //   || !currentUser['id']
    //   || !currentUser['email']
    //   || !currentUser['name']
    // ) {
    //   console.error('currentUser is required')
    //   return false
    // }

    const where = {
      type: '피드 신고',
      reason,
      message,
    }

    if (currentUser) {
      where.contactClient = JSON.stringify({
        id: currentUser['id'],
        email: currentUser['email'],
        nickname: currentUser['name'],
      })
    }

    const data = await this.network.customerCenterNetwork.postFeedFeedback(
      feedId,
      where,
    )
    if (!data || data.length === 0) {
      console.error('sendFeedsFeedback error')
      return false
    }
    return true
  }
}
