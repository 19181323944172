import { observable, computed } from 'mobx'
import { v4 } from 'uuid'

import { create } from 'mobx-persist'
import { IS_DEV } from '@consts/'
import AppStateStore from './AppStateStore'
import AuthStore from './AuthStore'
import SongStore from './SongStore'
import UserStore from './UserStore'
import PurchaseStore from './PurchaseStore'
import RevenueStore from './RevenueStore'
import MixtapeStore from './MixtapeStore'
import TagStore from './TagStore'
import SearchStore from './SearchStore'
import PlayerStore from './PlayerStore'
import CustomerCenterStore from './CustomerCenterStore'
import CommentStore from './CommentStore'
import LiveRoomStore from './LiveRoomStore'
import LiveRoomRecordingStore from './LiveRoomRecordingStore'
import LiveRoomSearchStore from './LiveRoomSearchStore'
import StarStore from './StarStore'
import FeedStore from './FeedStore'
import CastStore from './CastStore'
import HouseStore from './HouseStore'
import { Network } from './networks'

export {
  AuthStore,
  SongStore,
  UserStore,
  PurchaseStore,
  AppStateStore,
  RevenueStore,
  MixtapeStore,
  TagStore,
  SearchStore,
  CustomerCenterStore,
  PlayerStore,
  CommentStore,
  LiveRoomStore,
  LiveRoomRecordingStore,
  LiveRoomSearchStore,
  StarStore,
  FeedStore,
  CastStore,
  HouseStore,
}

export class Store {
  @computed get isLoading() {
    const list = Object.keys(this.handlerObj)
    let res = false
    if (this.handlerObj && list && list.length > 0) {
      res = true
    }

    return res
  }
  @observable handlerObj = {}

  _network: Network

  authStore: AuthStore
  appStateStore: AppStateStore
  userStore: UserStore
  appStateStore: AppStateStore
  songStore: SongStore
  purchaseStore: PurchaseStore
  revenueStore: RevenueStore
  mixtapeStore: MixtapeStore
  TagStore: TagStore
  SearchStore: SearchStore
  CustomerCenterStore: CustomerCenterStore
  PlayerStore: PlayerStore
  CommentStore: CommentStore
  LiveRoomStore: LiveRoomStore
  LiveRoomRecordingStore: LiveRoomRecordingStore
  LiveRoomSearchStore: LiveRoomSearchStore
  StarStore: StarStore
  FeedStore: FeedStore
  CastStore: CastStore
  HouseStore: HouseStore

  constructor(hydrateCallback) {
    this._network = new Network({
      getJwt: () => this.authStore['jsonWebToken'],
      getCustomHeader: () => ({
        // authorization: jwt,
        // 'hreum-app-version': `${appVersion}`,
        // 'hreum-app-identifier-id': this?.appIdentifiersStore?.appIdentifierId,
        'hreum-user-id': this?.authStore?.currentUser?.id,
        'hreum-platform': 'web',
        'hreum-web-version': '2.11.42',
      }),
    })

    this.authStore = new AuthStore(this, this._network)
    this.userStore = new UserStore(this, this._network)
    this.songStore = new SongStore(this, this._network)
    this.appStateStore = new AppStateStore(this, this._network)
    this.purchaseStore = new PurchaseStore(this, this._network)
    this.revenueStore = new RevenueStore(this, this._network)
    this.mixtapeStore = new MixtapeStore(this, this._network)
    this.tagStore = new TagStore(this, this._network)
    this.searchStore = new SearchStore(this, this._network)
    this.customerCenterStore = new CustomerCenterStore(this, this._network)
    this.playerStore = new PlayerStore(this, this._network)
    this.commentStore = new CommentStore(this, this._network)
    this.liveRoomStore = new LiveRoomStore(this, this._network)
    this.liveRoomRecordingStore = new LiveRoomRecordingStore(
      this,
      this._network,
    )
    this.liveRoomSearchStore = new LiveRoomSearchStore(this, this._network)
    this.starStore = new StarStore(this, this._network)
    this.feedStore = new FeedStore(this, this._network)
    this.castStore = new CastStore(this, this._network)
    this.houseStore = new HouseStore(this, this._network)

    if (IS_DEV) console.log(`[DEV][LOG][Store][constructor] end`)
    const hydrate = create({
      storage: localStorage, // or AsyncStorage in react-native.
      // default: localStorage
      jsonify: true, // if you use AsyncStorage, here shoud be true
      // default: true
    })

    hydrate('searchStore', this.searchStore)
    hydrate('authStore', this.authStore)
    hydrate('liveRoomStore', this.liveRoomStore)
  }

  initClient = async () => {
    await Promise.all([
      this.authStore
        && this.authStore.initClient
        && this.authStore.initClient(),
    ])
    if (IS_DEV) console.log(`[DEV][LOG][Store][initClient] end`)
  }

  useLoading = async func => {
    if (this && func) {
      const handlerKey = v4()
      this.handlerObj[handlerKey] = { isLoading: true }

      let res = null
      try {
        res = await func()
      }
      catch (error) {
        console.trace('[Store] [useLoading] error', `${func.name}`, error)
      }

      delete this.handlerObj[handlerKey]

      return res
    }
  }
}
