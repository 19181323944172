export const convertFromVolume = volume => {
  /**
   * 볼륨 => 슬라이더 변환
   * - 0 ~ 0.2 구간까지는 0% ~ 50%
   * - 0.2 ~ 1 구간까지는 50% ~ 100%
   */
  let _volume = 0
  if (volume <= 0.2) {
    _volume = volume * 250
  }
  else {
    _volume = (volume + 0.6) / 0.016
  }
  return _volume
}

export const convertToVolume = volume => {
  /**
   * 슬라이더 => 볼륨 변환
   * - 0 ~ 50% 구간까지는 0 ~ 0.2
   * - 50% ~100% 구간까지는 0.2 ~ 1
   */
  let _volume = 0
  if (volume <= 50) {
    _volume = volume / 250
  }
  else {
    _volume = volume * 0.016 - 0.6
  }
  return _volume
}
