import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Flex, Img } from '@components/atoms';
import { mbtiData } from './mbtiData';
import { QuizBtn } from '.'
import EventLayout from './EventLayout';


const RankdCard = ({mbti}) => {
  return (
    <Link href={`/p/socialking/${mbti}`}>
      <RankBox>
        <Img src={mbtiData[mbti]['imageName']} alt="esfj" />
        <BmText size={14}>{mbti.toUpperCase()}</BmText>
        <ApText size={12} color="#646464">{mbtiData[mbti]['rate']}</ApText>
      </RankBox>
    </Link>
  )
}
const SocialkingRankingTemplate = () => {
  return (
    <EventLayout>
      <Link href="/">
        <Img
          src={'/images/hreum_logo.png'}
          width={132}
          height={24}
          alt="hreum_logo"
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <BmText size={20} style={{margin:'26px 0 24px 0'}}>인맥왕 서열표 👑</BmText>
      <RankingBox>
        <BmText size={16} style={{marginBottom:10}}>💎 다이아몬드</BmText>
        <Flex style={{width:276, justifyContent:'space-between'}}>
          <RankdCard mbti='esfj' />
          <RankdCard mbti='esfp' />
        </Flex>
      </RankingBox>
      <RankingBox>
        <BmText size={16} style={{marginBottom:10}}>🎖 플래티넘</BmText>
        <Flex style={{width:276, justifyContent:'space-between', alignItems:'center'}}>
          <RankdCard mbti='enfp' />
          <Flex type="column">
            <RankdCard mbti='enfj' />
            <RankdCard mbti='entp' />
          </Flex>
          <RankdCard mbti='estp' />
        </Flex>
      </RankingBox>
      <RankingBox>
        <BmText size={16} style={{marginBottom:10}}>🥇 골드</BmText>
        <Flex style={{width:276, justifyContent:'space-between'}}>
          <RankdCard mbti='estj' />
          <RankdCard mbti='isfj' />
          <RankdCard mbti='entj' />
        </Flex>
      </RankingBox>
      <RankingBox>
        <BmText size={16} style={{marginBottom:10}}>🥈 실버</BmText>
        <Flex style={{width:276, justifyContent:'space-between', flexWrap:'wrap'}}>
          <RankdCard mbti='intj' />
          <RankdCard mbti='isfp' />
          <RankdCard mbti='infp' />
          <RankdCard mbti='istp' />
          <RankdCard mbti='istj' />
          <RankdCard mbti='infj' />
        </Flex>
      </RankingBox>
      <RankingBox>
        <BmText size={16} style={{marginBottom:10}}>🥉 브론즈</BmText>
        <Flex style={{width:276, justifyContent:'space-around', flexWrap:'wrap'}}>
          <RankdCard mbti='intp' />
        </Flex>
      </RankingBox>
      <QuizBtn
        quiz="돌아가기"
        style={
          {
            backgroundColor: '#5d4afc',
            color: '#ffffff',
            width:'100%',
            maxWidth: 752,
            marginTop:40
          }
        }
        onClick={() => window.history.back()}
      />
    </EventLayout>
  );
};

export default SocialkingRankingTemplate;

const BmText = styled.span`
  font-family: BMDOHYEON;
  font-size: ${({ size }) => (size ? `${size}px` : '32px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  margin: 0;
  padding: 0;
`

const ApText = styled.span`
  font-family: AppleSDGothicNeo;
  font-size: ${({ size }) => (size ? `${size}px` : '32px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  margin: 0;
  padding: 0;
`

const RankingBox = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  width: 100%;
  max-width:752px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  background-color: #ffffff;
  margin:16px 0;
`

const RankBox = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  width:76px;
  height: 125px;
  margin:10px 0;
  cursor: pointer;

  img {
    width:76px;
    height:76px;
    object-fit:cover;
    margin-bottom:7px;
    border-radius: 50%;
  }

  > span {
    margin:5px 0 0px 0;
  }
`