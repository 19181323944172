import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 0.875rem;
  text-align: left;
  color: #646464;
  padding: 120px 20px 100px 20px;
  max-width: 970px;
  margin: auto;
`

const WarningUseService = () => {
  return (
    <Wrapper>
      서비스 이용 주의사항
      <br />
      <br />
      국내에서 결제완료된 흐름(HREUM de salon) 프리미엄 서비스 플랜은
      국내에서만 이용하실 수 있습니다.
      <br />
      <br />
      해외 서비스 대상국가가 아닌 지역에서는 서비스 이용이 불가하며,
      <br />
      일부 콘텐츠는 저작권자의 별도 요청, 저작권 문제, 기타 사업권역 문제 등으로
      국내 혹은 해외 서비스 이용이 사전 예고 없이 제한될 수 있습니다.
      <br />
      흐름(HREUM de salon)서비스에서 유튜브 등의 제3자 서비스로 연결되는
      콘텐츠의 경우 광고 삭제가 불가능하며 제3자 서비스의 사정에 따라 콘텐츠
      이용에 제한이 있을 수 있습니다.
      <br />
      회사는 음성대화 기능 등을 제공하는 일부 서비스 내에서 이용자간 신고가 있는 경우, 신고된 이용자의 음성정보를 저장 및 보관할 수 있으며 이 정보는 회사만 보유합니다.
      회사는 이용자간 분쟁 조정, 민원 처리를 위한 목적에 한하여, 제 3 자는 법령에 따라 권한이 부여된 경우에 한하여 이 정보를 열람할 수 있습니다. 회사는 부정이용 방지 및 관리의 목적에 따라 신고 접수시부터 3년간 해당 정보를 3년간 보관 후 파기합니다.
      단, 저 사양 단말기의 경우에는 신고된 음성정보가 저장되지 않을 수 있습니다.
      <br />
      <br />
      서비스 이용 권장사양
      <br />
      <br />
      PC : windows와 Mac 최신버젼 권장(웹 브라우저: 크롬 최신버젼 권장)
      <br />
      스마트폰 : Android 4.0 이상 | iOS 11.0 이상
      <br />
      태블릿 : Android 4.0 이상 | iOS 11.0 이상
      <br />
      <br />
      이용권 구매 주의사항
      <br />
      <br />
      모든 이용권은 부가세(VAT)포함 가격입니다.
      <br />
      <br />
      상품 가격과 플랜 내용은 내부 정책으로 인해 적절한 공지와 함께 변경 될 수
      있습니다.
      <br />
      <br />
      자동결제는 이용 플랜에 따라 1개월 단위 혹은 1년 단위로 이루어지므로
      구매하신 날과 매월 혹은 매년 동일한 날짜에 결제가 이루어집니다.
      <br />
      자동결제 시 결제 당일을 제외한 이용기간 중 언제든지 자동결제를 해지 및
      전환하실 수 있습니다.
      <br />
      자동결제 플랜을 해지할 경우 더 이상 추가 결제되지 않으며 남은 기간 동안은
      계속 이용하실 수 있습니다.
      <br />
      <br />
      모든 종류의 할인 프로모션 상품은 사전 예고 없이 종료 될 수 있습니다.
      <br />
      또한 인증 등을 통해 할인받을 수 있는 다양한 플랜 상품은 할인 혜택이
      적용되어 있으므로 서비스팀 내부 규정에 따른 다른 추가혜택 적용이 어려울 수
      있습니다.
      <br />
      <br />
      통신사, 신용카드 정보 변경 및 기타 결제수단의 잔액부족 시에는 결제실패로
      자동결제가 해지될 수 있습니다.
      <br />
      <br />
      고객센터를 통한 자동결제해지 신청은 본인만 가능합니다.
      <br />
      <br />
      모든 흐름(HREUM de salon)프리미엄 상품은 선불결제 상품으로 이미
      결제하여 구매 및 사용하신 이용권에 대해서는 결제취소 및 환불되지 않습니다.
      <br />
    </Wrapper>
  )
}

export default WarningUseService
