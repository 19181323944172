import { action, observable } from 'mobx'

import { isEmptyObject } from '@utils/vallidation'
import { LiveRoomRecordingModel } from './models'

export default class LiveRoomRecordingStore {
  @observable currLiveRoomRecording
  @observable myLiveRoomRecordingList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network
    this.init()
  }

  @action init = () => {
    this.clearLiveRoomRecording()
  }

  @action clearLiveRoomRecording = () => {
    this.currLiveRoomRecording = null
    this.myLiveRoomRecordingList = []
  }

  @action checkCurrLiveRoomRecording = async ({ liveRoomId }) => {
    if (!liveRoomId) {
      console.error('Required params.', `liveRoomId: ${liveRoomId}`)
    }
    const where = {
      liveRoomId,
    }
    const liveRoomRecording = await this.network.liveRoomRecordingNetwork.checkLiveRoomRecording(
      where,
    )

    if (!liveRoomRecording || isEmptyObject(liveRoomRecording)) {
      this.currLiveRoomRecording = null
      return
    }

    this.currLiveRoomRecording = liveRoomRecording
  }

  @action fetchMyLiveRoomRecordingList = async ({ userId }) => {
    if (!userId) {
      console.error('Required params.', `userId: ${userId}`)
    }

    const where = {
      userId,
      __order: 'createdAtDesc',
    }
    const liveRoomRecordingList = await this.network.liveRoomRecordingNetwork.getLiveRoomRecordingList(
      where,
    )

    if (!liveRoomRecordingList) {
      this.myLiveRoomRecordingList = []
      return
    }

    this.myLiveRoomRecordingList = liveRoomRecordingList.map(
      liveRoomRecording =>
        new LiveRoomRecordingModel(this.store, liveRoomRecording),
    )
  }

  @action deleteLiveRoomRecording = async ({ liveRoomRecordingId }) => {
    if (!liveRoomRecordingId) {
      console.error('Required params.', `userId: ${liveRoomRecordingId}`)
    }

    const deleteResult = await this.network.liveRoomRecordingNetwork.deleteLiveRoomRecording(
      liveRoomRecordingId,
    )

    if (!deleteResult) {
      return
    }

    this.myLiveRoomRecordingList = this.myLiveRoomRecordingList.filter(
      liveRoomRecording => liveRoomRecording['id'] !== deleteResult['id'],
    )
  }

  @action updateLiveRoomRecording = async ({ liveRoomRecordingId, title }) => {
    if (!liveRoomRecordingId) {
      console.error('Required params.', `userId: ${liveRoomRecordingId}`)
    }

    const where = {
      title,
    }

    const updateResult = await this.network.liveRoomRecordingNetwork.putLiveRoomRecording(
      liveRoomRecordingId,
      where,
    )

    if (!updateResult || isEmptyObject(updateResult)) {
      return false
    }

    this.myLiveRoomRecordingList.map(liveRoomRecording => {
      if (liveRoomRecording['id'] === updateResult['id']) {
        liveRoomRecording['title'] = updateResult['title']
      }

      return true
    })

    return true
  }

  @action startRecording = async ({
    agoraUid,
    liveRoomId,
    agoraRtcChannelId,
    agoraRtcUserToken,
  }) => {
    return this.store.useLoading(async () => {
      const where = {
        agoraUid,
        liveRoomId,
        agoraRtcChannelId,
        agoraRtcUserToken,
      }
      const startedResult = await this.network.liveRoomRecordingNetwork.startLiveRoomRecording(
        where,
      )

      if (!startedResult) {
        /**
         * 에러 처리
         */

        return false
      }

      this.currLiveRoomRecording = {
        ...startedResult,
        LiveRoomUser: {
          User: {
            id: this.store?.authStore?.currentUser['id'],
            name: this.store?.authStore?.currentUser['name'],
          },
        },
      }
      await this.store.liveRoomStore._sendActionLiveRoomRecording({
        liveRoomRecording: this.currLiveRoomRecording,
      })

      return true
    })
  }

  @action stopRecording = async ({ liveRoomId }) => {
    return this.store.useLoading(async () => {
      const where = {
        liveRoomId,
      }
      const stoppedResult = await this.network.liveRoomRecordingNetwork.stopLiveRoomRecording(
        where,
      )

      /**
       * 201, 204 받으면 녹음 종료하고 rtm 발송
       *
       */
      if (!stoppedResult) {
        /**
         * 에러 처리
         */
        return false
      }

      await this.store.liveRoomStore._sendActionLiveRoomRecording({
        liveRoomRecording: {
          ...(stoppedResult?.id ? stoppedResult : this.currLiveRoomRecording),
          LiveRoomUser: {
            User: {
              id: this.store?.authStore?.currentUser['id'],
              name: this.store?.authStore?.currentUser['name'],
            },
          },
        },
        isStop: true,
      })
      this.clearLiveRoomRecording()

      return true
    })
  }
}
