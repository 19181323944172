import { Caption1, Flex, H4, H5 } from '@components/atoms'
import { SelectYearMonthBox, StarHistoryItem } from '@components/molecules'
import { colors } from '@resources/colors'
import { addComma } from '@utils/string'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const StarAccountSection = ({
  currentUser,
  fetchTotalStarHistories,
  myStarHistoryList,
}) => {
  const [selected, setSelected] = React.useState(
    moment()
      .date(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0),
  )

  React.useEffect(() => {
    fetchTotalStarHistories({
      filter: 'all',
      date: selected,
    })
  }, [selected, fetchTotalStarHistories])

  return (
    <Flex type="column">
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Box justify="center" align="center">
          <H5 color={colors.brown_grey} style={{ marginBottom: 12 }}>
            환전 및 선물 가능한 별
          </H5>
          <H4>{`${addComma(currentUser.currentStar || 0)}개`}</H4>
        </Box>
        <Box>
          <Flex
            justify="space-between"
            style={{ width: '100%', marginBottom: 24 }}
          >
            <Caption1 color={colors.brown_grey}>환전한 별</Caption1>
            <Caption1>
              {`${addComma(currentUser.exchangedStar || 0)}개`}
            </Caption1>
          </Flex>
          <Flex
            justify="space-between"
            style={{ width: '100%', marginBottom: 24 }}
          >
            <Caption1 color={colors.brown_grey}>선물한 별</Caption1>
            <Caption1>{`${addComma(currentUser.sendedStar || 0)}개`}</Caption1>
          </Flex>
          <Flex
            justify="space-between"
            style={{ width: '100%', marginBottom: 24 }}
          >
            <Caption1 color={colors.brown_grey}>선물받은 별</Caption1>
            <Caption1>
              {`${addComma(currentUser.receivedStar || 0)}개`}
            </Caption1>
          </Flex>
          <Flex
            justify="space-between"
            style={{ width: '100%', marginBottom: 24 }}
          >
            <Caption1 color={colors.brown_grey}>유효기간 경과 예정 별</Caption1>
            <Caption1>
              {`${addComma(currentUser.toExpiredStar || 0)}개`}
            </Caption1>
          </Flex>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Caption1 color={colors.brown_grey}>유효기간 경과 별</Caption1>
            <Caption1>{`${addComma(currentUser.expiredStar || 0)}개`}</Caption1>
          </Flex>
        </Box>
      </Flex>
      <Box
        style={{ maxWidth: '100%', width: '100%', height: 580, marginTop: 24 }}
      >
        <SelectYearMonthBox
          selected={selected}
          setSelected={setSelected}
          style={{ marginLeft: -16 }}
        />
        <StarHistoryList>
          {
            myStarHistoryList && myStarHistoryList.length > 0 ? (
              myStarHistoryList.map((item, index) => (
                <StarHistoryItem
                  item={item}
                  index={index}
                  key={item.id}
                  myStarHistoryList={myStarHistoryList}
                />
              ))
            ) : (
              <Caption1>별 통장 내역이 없습니다:)</Caption1>
            )
          }
        </StarHistoryList>
      </Box>
    </Flex>
  )
}

export default StarAccountSection

const Box = styled(Flex)`
  flex-direction: column;
  max-width: 520px;
  width: 100%;
  height: 260px;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
`

const StarHistoryList = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-height: 516px;
  height: 100%;

  > div:not(:first-child) {
    margin-top: 8px;
  }

  overflow: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`
