import { Caption1, Flex, ProfileImg } from '@components/atoms'
import { STORAGE_URL } from '@consts/'
import { colors } from '@resources/colors'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { DJBadge } from '..'

const UserFollowInfoItem = observer(
  ({ style, item, currentUserId, handleUser, handleFollow }) => {
    return (
      <Item style={style && style}>
        <UserImage
          onClick={handleUser}
          src={item?.imageUri && `${STORAGE_URL}${item?.imageUri}`}
          profileColor={item?.profileColor}
        />
        <Flex
          onClick={handleUser}
          type="column"
          align="flex-start"
          justify="center"
          style={{ cursor: 'pointer', width: 300, marginRight: 32 }}
        >
          <DJBadge
            discJockeyLevel={item?.discJockeyLevel}
            style={{ marginBottom: 4 }}
          />
          <Caption1>{item?.name}</Caption1>
        </Flex>
        <FollowBtn
          followed={item?.isFollowed}
          onClick={() => handleFollow(item)}
        >
          {item?.isFollowed ? '팔로잉' : '팔로우'}
        </FollowBtn>
      </Item>
    )
  },
)

export default UserFollowInfoItem

const Item = styled(Flex)`
  width: 100%;
  align-items: center;
`

const UserImage = styled(ProfileImg)`
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;
`

const FollowBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 36px;
  border-radius: 4px;
  background-color: ${({ followed }) =>
    followed ? colors.white_f4 : colors.primary};

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${({ followed }) => (followed ? colors.grey_94 : colors.white_ff)};
}
`
