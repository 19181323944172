import React, { useState } from 'react'
import { H5, Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import {
  FlexColumnBox,
  FlexRowBox,
  SearchAudioBox,
  SongItem,
} from '@components/molecules'
import { images } from '@images/'
import { useStore } from '@utils/hooks'

const SearchHistorytBox = styled(FlexColumnBox)`
  width: 100%;
  margin: 20px 0;
  justify-content: flex-start;
  align-items: end;
  height: 100%;
  max-height: 550px;
`

const HistoryList = styled(FlexColumnBox)`
    width: 100%;
    align-items:end;

    p {
        margin 8px 0;
    }
`

const SearchAudioSection = () => {
  const { searchStore, mixtapeStore } = useStore()
  const [isSearched, setIsSearched] = useState(false)

  const audioSearchHistory = searchStore.audioSearchHistory || null
  const songResults = searchStore.songResults || null
  const fetchSearchSongs = searchStore.fetchSearchSongs || (() => {})
  const deleteAudioSearchHistory =
    searchStore.deleteAudioSearchHistory || (() => {})
  const clearAudioSearchHistory =
    searchStore.clearAudioSearchHistory || (() => {})

  const tempMixtape = mixtapeStore.tempMixtape || null
  const addMixtapeSongListToTempMixtape =
    mixtapeStore.addMixtapeSongListToTempMixtape || (() => {})
  const deleteMixtapeSongToTempMixtape =
    mixtapeStore.deleteMixtapeSongToTempMixtape || (() => {})

  const handleAdd = item => {
    addMixtapeSongListToTempMixtape(tempMixtape, [item])
  }
  const handleDelete = item => {
    const mixtapeSongIndex =
      tempMixtape
      && tempMixtape['MixtapesHaveSongs'].findIndex(
        elem => elem['songId'] === item['id'],
      )

    deleteMixtapeSongToTempMixtape(tempMixtape, mixtapeSongIndex)
  }

  const handleSearch = async keyword => {
    await fetchSearchSongs(keyword)
    setIsSearched(true)
  }

  return (
    <FlexColumnBox>
      <SearchAudioBox
        fetchSearchSongs={fetchSearchSongs}
        setIsSearched={setIsSearched}
      />
      {
        isSearched ? (
          songResults.length === 0 ? (
            <HistoryList>
              <Caption1>검색 결과가 없습니다.</Caption1>
            </HistoryList>
          ) : (
            <SearchHistorytBox>
              {
                songResults.map(item => (
                  <SongItem
                    item={item}
                    key={item.id}
                    handleAdd={handleAdd}
                    handleDelete={handleDelete}
                    isExist={
                      tempMixtape
                  && tempMixtape['MixtapesHaveSongs'].findIndex(
                    elem => elem['songId'] === item['id'],
                  ) > -1
                    }
                  />
                ))
              }
            </SearchHistorytBox>
          )
        ) : (
          <SearchHistorytBox>
            <FlexRowBox
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <H5 type="Bold" style={{ marginBottom: '8px' }}>
              검색 기록
              </H5>
              <Caption1
                color="#646464"
                style={{ cursor: 'pointer' }}
                onClick={clearAudioSearchHistory}
              >
              모두 지우기
              </Caption1>
            </FlexRowBox>
            <HistoryList>
              {
                audioSearchHistory.length === 0 ? (
                  <Caption1>검색 기록이 없습니다.</Caption1>
                ) : (
                  audioSearchHistory.map((item, index) => (
                    <FlexRowBox
                      style={
                        {
                          width: '100%',
                          justifyContent: 'flex-start',
                          cursor: 'pointer',
                        }
                      }
                      key={index}
                      onClick={() => handleSearch(item)}
                    >
                      <Caption1>{item}</Caption1>
                      <img
                        src={images.x_btn_img}
                        alt="x_btn"
                        style={
                          {
                            width: '10px',
                            height: '10px',
                            marginLeft: '6px',
                            cursor: 'pointer',
                          }
                        }
                        onClick={
                          e => {
                            e.stopPropagation()
                            deleteAudioSearchHistory(index)
                          }
                        }
                      />
                    </FlexRowBox>
                  ))
                )
              }
            </HistoryList>
          </SearchHistorytBox>
        )
      }
    </FlexColumnBox>
  )
}

export default observer(SearchAudioSection)
