import { observable } from 'mobx'

export default class BankAccountModel {
  @observable id
  @observable createdAt
  @observable updatedAt
  @observable responseAt
  @observable requestAt

  @observable bankName
  @observable accountHolder
  @observable accountNumber

  /**
   * 처리 상태
   * 1. 0: 미처리
   * 2. 11: 관리자 승인
   * 3. 12: 관리자 반려
   * 4. 21: 자동 승인
   * 5. 22: 자동 반려
   */
  @observable responseState

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.responseAt = props.responseAt
      this.requestAt = props.requestAt

      this.bankName = props.bankName
      this.accountHolder = props.accountHolder
      this.accountNumber = props.accountNumber
      this.responseState = props.responseState
    }
  }
}
