import React, { useState } from 'react'
// antd
import { message } from 'antd'
import { STORAGE_URL } from '@consts'

import Files from 'react-butterfiles'
import styled from 'styled-components'
import { Caption1 } from '@components/atoms'
import { Spinner } from '@components/molecules'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const UploadImg = styled.img`
  width: 48px;
  height: 48px;
`

const ImageUploadBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #d4d4d4;
  cursor: pointer;

  width: 100%;
  max-height: 320px;
  min-height: 320px;
  height: 100%;
  margin-bottom: -50px;

  z-index: 1;
`

const UserBackgroundImageBox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 320px;
  min-height: 320px;
  margin-bottom: -50px;

  background-color: ${colors.black_24};
  opacity: 0.8;
  cursor: pointer;
  z-index: 2;
`

const CameraBtn = styled.img`
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 48px;
  height: 48px;
`

const BackgroundUploadForm = ({
  setImageUri,
  imageUrl,
  setImageUrl,
  uploadImage,
  style,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Files
      multiple={false}
      maxSize="15mb"
      convertToBase64
      accept={['image/jpg', 'image/png', 'image/jpeg']}
      onSuccess={
        async ([selectedFile]) => {
          setLoading(true)
          try {
            const { file } = selectedFile.src
            const formData = new FormData()
            formData.append('image', file)

            const uploadedImage = await uploadImage(
              formData,
              sessionStorage.getItem('jwt_token'),
            )

            setImageUri(uploadedImage.imageUri)
            setImageUrl(STORAGE_URL + uploadedImage.imageUri)

            message.success('이미지 업로드 완료')
            // console.log('File was successfully uploaded!')
            setLoading(false)
          }
          catch (e) {
          // console.log('An error occurred!', e.message)
            message.error('이미지 업로드 실패')
            setLoading(false)
          }
        }
      }
      onError={
        errors => {
          message.error(
            errors[0].type === 'maxSizeExceeded'
              ? '15MB를 넘는 파일입니다!'
              : errors[0].type === 'unsupportedFileType'
                ? '지원하지 않은 확장자입니다!'
                : `이미지 업로드 에러 : ${errors[0].type}`,
          )
        }
      }
    >
      {
        ({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div
            {...getDropZoneProps({ className: 'myDropZone' })}
            style={{ width: '100%' }}
          >
            <UserBackgroundImageBox onClick={browseFiles}>
              <CameraBtn src={images.camera_img_white} />
            </UserBackgroundImageBox>
            <ImageUploadBox style={style} onClick={browseFiles}>
              {
                imageUrl ? (
                  <img
                    style={
                      {
                        width: '100%',
                        height: 320,
                        objectFit: 'cover',
                      }
                    }
                    src={imageUrl}
                    alt="avatar"
                  />
                ) : loading ? (
                  <>
                    <Spinner
                      type="spinningBubbles"
                      color={colors.primary}
                      width="48px"
                      height="48px"
                    />
                    <Caption1 color="#949494">이미지 파일 업로드 중 ...</Caption1>
                  </>
                ) : (
                  <UploadImg src={images.upload_img} alt="upload_btn" />
                )
              }
            </ImageUploadBox>
          </div>
        )
      }
    </Files>
  )
}

export default BackgroundUploadForm
