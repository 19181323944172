import { Button, Div, Img } from '@components/atoms'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { SalonEditModal } from '..'

const HouseSalonSection = ({ houseDetail, isMyPage, myLevel }) => {
  const { authStore, liveRoomStore, houseStore } = useStore()

  const history = useHistory()

  const {
    isShowing: showOpenSalon,
    toggle: toggleOpenSalon,
  } = SalonEditModal.useModal()

  const handleOpenSalon = async () => {
    const result = await liveRoomStore.getDevicesPermission()
    if (!result) return
    if (liveRoomStore.agoraDevice) toggleOpenSalon()
  }

  const fetchUserHouseList = async () => {
    const options = {
      __required: 'HousesHaveUsers',
      'HousesHaveUsers.userId': authStore?.currentUser?.id,
      isHaveContentPermission: 1,
      __attributes:
        'id,linkKey,contentPermissionLevel,imageUri,title,HousesHaveUsers.id,HousesHaveUsers.userId,HousesHaveUsers.level',
    }

    const houseList = await houseStore.fetchHouseList(options)
    return houseList || []
  }

  return (
    <Div width="100%" padding="24px 0" display="flex" gap="24px">
      <OpenSalonBtn
        onClick={
          () => {
            history.push(`/p/cast-house/manage/${houseDetail?.id}`, {
              isUpload: true,
            })
          }
        }
        style={{ backgroundColor: colors.primary, color: colors.white_ff }}
      >
        <Img
          src={images.cast_icon_img_white}
          width="20px"
          height="20px"
          marginRight="6px"
        />
        캐스트 추가
      </OpenSalonBtn>
      <OpenSalonBtn onClick={handleOpenSalon}>
        <Img
          src={images.sidebar_inactive_salon}
          width="20px"
          height="20px"
          marginRight="6px"
        />
        하우스 살롱 열기
      </OpenSalonBtn>
      <SalonEditModal.View
        isShowing={showOpenSalon}
        toggle={toggleOpenSalon}
        houseDetail={houseDetail}
      />
    </Div>
  )
}

export default observer(HouseSalonSection)

const OpenSalonBtn = styled(Button)`
  width: 248px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;

  background-color: ${colors.white_f4};

  font-size: 12px;
`
