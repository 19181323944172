import styled from 'styled-components'

const Div = styled.div.attrs((props: React.HTMLProps<HTMLDivElement>) => ({
  style: {
    ...props,
    ...props.style,
  },
}))` // <React.CSSProperties> // typescript 도입 시, 스타일링 autocomplete 가능해짐.
  transition: transform 0.35s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes toastFadeInOut {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }

  @keyframes slideDown {
    0% {
      transform: translateY(0vh);
    }
    100% {
      transform: translateY(100vh);
    }
  }

  @keyframes slideDownIn {
    0% {
      transform: translateY(-100vh);
    }
    100% {
      transform: translateY(0vh);
    }
  }

  @keyframes slideDownOut {
    0% {
      transform: translateY(0vh);
    }
    100% {
      transform: translateY(-100vh);
    }
  }

  @keyframes rotateX {
    0% {
      opacity: 0;
      transform: rotateX(-90deg);
    }
    50% {
      transform: rotateX(-20deg);
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }
`

export default Div
