import { Caption2 } from '@components/atoms'
import { ReserveSalonCard } from '@components/organisms'
import { colors } from '@resources/colors'
import React from 'react'
import { PageLayout } from '..'

const QRCode = require('qrcode.react')

const SalonReserveTemplate = ({ currLiveRoom }) => {
  return (
    <PageLayout
      style={{ backgroundColor: colors.white_f4 }}
      rootStyle={{ backgroundColor: colors.white_f4 }}
    >
      <ReserveSalonCard salon={currLiveRoom} />
      <Caption2
        style={{ margin: '32px 0 24px 0', whiteSpace: 'pre' }}
        color={colors.grey_64}
      >
        {
          '살롱이 닫히기 전에 HREUM de SALON\n 앱에 접속해 실시간 오디오 소통을 즐겨보세요!'
        }
      </Caption2>
      <QRCode
        value={currLiveRoom?.dynamicLink}
        size={64}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}
        renderAs={'svg'}
        imageSettings={
          {
            src: 'https://hreum.me/favicon.ico',
            x: null,
            y: null,
            height: 12,
            width: 12,
            excavate: true,
          }
        }
      />
      <Caption2 style={{ marginTop: 6 }}>모바일 앱 다운로드</Caption2>
    </PageLayout>
  )
}

export default SalonReserveTemplate
