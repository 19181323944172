import { Caption1, Caption3, Flex, H6, Image } from '@components/atoms'
import {
  CastCommentInputBox,
  DeletePopup,
  FeedThreeDotPopup,
  UserReportPopup,
} from '@components/molecules'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { CommentModel } from '@stores/models'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { CommentListItem } from '..'

const CommentMessage = ({ message }) => {
  return (
    <MessageAnimation>
      <MessageBox>
        <Caption1 color={colors.white_ff} type="Regular">
          {message}
        </Caption1>
      </MessageBox>
    </MessageAnimation>
  )
}

const CastDetailCommentSection = ({
  castId,
  totalCount = 0,
  commentList = [],
}: {
  castId: String,
  totalCount?: Number,
  commentList?: CommentModel[],
}) => {
  const { authStore, commentStore, customerCenterStore } = useStore()

  const [textArea, setTextArea] = React.useState('')
  const [targetUser, setTargetUser] = React.useState([])
  const [isEdit, setIsEdit] = React.useState(false) // 댓글 수정 유무
  const [editId, setEditId] = React.useState(null) //  댓글 수정 아이디
  const [message, setMessage] = React.useState('') // 댓글 액션 메세지
  const [showMessage, setShowMessage] = React.useState(false) // 댓글 메세지 노출 유무
  const [parentCommentId, setParentCommentId] = React.useState(null) // 답글 부모 댓글 id
  const [parentCommentName, setParentCommentName] = React.useState(null) // 답글 부모 유저 이름
  const [reason, setReason] = React.useState(0) // 신고 사유

  const _init = () => {
    setTextArea('')
    setTargetUser([])
    setIsEdit(false)
    setEditId(null)
    setMessage('')
    setShowMessage(false)
    setParentCommentId(null)
    setParentCommentName(null)
    setReason(0)
  }

  const {
    isShowing: deleteShowing,
    toggle: deleteToggle,
  } = DeletePopup.useModal()

  const {
    isShowing: showThreeDot,
    toggle: toggleThreeDot,
  } = FeedThreeDotPopup.usePopup()

  const {
    isShowing: showUserReport,
    toggle: toggleUserReport,
  } = UserReportPopup.usePopup()

  const inputRef = React.useRef(null)
  const targetInfo = React.useRef(null)

  // 댓글 액션 메세지 호출
  const actionMessage = message => {
    setMessage(message)
    setShowMessage(true)
    setTimeout(() => setShowMessage(false), 2500)
  }

  const submitComment = async () => {
    const contentDOM = document.getElementsByClassName(
      'comment_input_mentions-textarea__input',
    )

    const params = [
      {
        userId: authStore?.currentUser?.id,
        targetId: castId,
        isReply: 1,
        targetTableName: 'Casts',
        content: contentDOM[0].innerHTML,
        rawContent: textArea,
        Users: targetUser,
        parentCommentId: parentCommentId || null,
      },
    ]

    if (isEdit) {
      if (!editId) return
      await commentStore.editComment(editId, params[0], 'Casts')
      actionMessage('댓글이 수정이 완료되었습니다.')
    }
    else {
      const result = await commentStore.postComment(params, 'Casts')
      if (result) {
        actionMessage('댓글 등록 완료')
      }
    }

    setIsEdit(false)
    setEditId(null)
    setTextArea('')
    setParentCommentId(null)
  }

  const handleSearchMention = async keyword => {
    return await commentStore.searchUserList(keyword)
  }

  const handleReply = (item, isChild = false) => {
    setParentCommentName(item.User.name)
    if (!isChild) {
      setParentCommentId(item.id)
    }
    if (isChild) {
      setParentCommentId(item.parentCommentId)
    }
    setTextArea(`@[${item.User.name}](id:${item.User.id}) `)
    setTargetUser([{ id: item.User.id, name: item.User.name }])
    inputRef.current.focus()
  }

  const handleEdit = (id, rawContent) => {
    setEditId(id)
    setIsEdit(true)
    setTextArea(rawContent)
    inputRef.current.focus()
  }

  // 댓글 삭제 함수
  const removeComment = async () => {
    await commentStore.removeComment(editId, 'Casts')
    actionMessage('댓글이 삭제되었습니다.')
  }

  const handleDelete = id => {
    setEditId(id)
    deleteToggle()
  }

  const _toggleUserReport = (type, userId, item) => {
    targetInfo.current = { type, userId, item }
    toggleUserReport()
  }

  const handleReport = async (userId, reason) => {
    if (!targetInfo || !reason) return

    let _reason = ''
    if (reason === 1)
      _reason =
        targetInfo.current.type === 'user'
          ? '부적절한 피드 내용'
          : '부적절한 댓글 내용'
    else _reason = '커뮤니티 가이트라인 위반'

    const message =
      `[신고내용]`
      + `\n<br>신고 사유: ${_reason}`
      + `\n<br> 텍스트 내용: ${targetInfo.current.item.content}`
      + `\n<br>\n<br> [댓글 정보]`
      + `\n<br> id: ${targetInfo.current.item.id}\n<br> 작성자: ${targetInfo.current.item.User.name}\n<br> 내용: ${targetInfo.current.item.content}\n<br>\n<br> [신고자 정보]`
      + `\n<br> userId: ${userId}`

    if (targetInfo.current.type === 'user')
      await customerCenterStore.sendCastsFeedback(userId, _reason, message)
    else await customerCenterStore.sendFeedback({ type: '댓글 신고', message })
  }

  useEffect(() => {
    return () => {
      _init()
    }
  }, [castId])

  return (
    <Flex type="column" style={{ width: '100%' }}>
      <Flex style={{ textAlign: 'left' }}>
        <H6>댓글</H6>
        <H6 style={{ marginLeft: 4, color: colors.grey_64 }}>
          {totalCount}
개
        </H6>
      </Flex>

      <Flex
        style={
          {
            width: '100%',
            height: 1,
            margin: '16px 0 24px 0',
            backgroundColor: colors.white_f4,
          }
        }
      />

      {/* 입력창 */}
      {
        parentCommentId && (
          <ChildReplyStatus>
            <Caption3 color={colors.grey_94} align="left">
              {`@${parentCommentName}님에게 답글 남기는 중`}
            </Caption3>
            <Image
              src={images.x_btn_img}
              style={{ width: 12, height: 12, cursor: 'pointer' }}
              onClick={() => setParentCommentId(null)}
            />
          </ChildReplyStatus>
        )
      }
      <Flex style={{ marginBottom: 24 }}>
        <CastCommentInputBox
          ref={inputRef}
          aria-label="With textarea"
          placeholder="댓글 달기"
          value={textArea}
          setValue={setTextArea}
          setTargetUser={setTargetUser}
          submitComment={submitComment}
          handleSearchMention={handleSearchMention}
          _autoCompleteUserObj={commentStore._autoCompleteUserObj || null}
          currentUserImage={authStore?.currentUser?.imageUri || ''}
          currentUserProfileColor={authStore?.currentUser?.profileColor || ''}
        />
      </Flex>
      {/* 입력창 End */}

      <Flex type="column" style={{ width: '100%', gap: 24 }}>
        {
commentList?.length > 0
          && commentList.map(comment => (
            <CommentListItem
              key={comment.id}
              item={comment}
              handleReply={handleReply}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleReport={_toggleUserReport}
            />
          ))
        }
      </Flex>
      {showMessage && <CommentMessage message={message} />}
      <DeletePopup.View
        isShowing={deleteShowing}
        toggle={deleteToggle}
        title="댓글 삭제"
        body={`이 댓글을\n정말 삭제하시겠습니까?`}
        onDelete={
          () => {
            removeComment()
            deleteToggle()
          }
        }
      />
      <UserReportPopup.View
        isShowing={showUserReport}
        toggle={toggleUserReport}
        onOk={handleReport}
        onCancel={() => console.log('onCancel')}
        userId={targetInfo.current?.userId}
        type={targetInfo.current?.type}
        reason={reason}
        setReason={setReason}
      />
      {/* 기능 보류 */}
      <FeedThreeDotPopup.View
        isShowing={showThreeDot}
        toggle={toggleThreeDot}
      />
    </Flex>
  )
}

export default observer(CastDetailCommentSection)

// Comment Action Message
const messageFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const MessageAnimation = styled.div`
  animation-name: ${messageFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100;
`

const MessageBox = styled(Flex)`
  width: 196px;
  height: 52px;
  border-radius: 32px;
  box-shadow: 0 4px 8px 0 rgba(36, 36, 36, 0.4);
  border: solid 1px #242424;
  background-color: #242424;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 200px;
  left: 35%;
`

const ChildReplyStatus = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${colors.white_f4};
  width: 100%;
  min-height: 48px;
  height: 48px;
  padding: 0 16px 0 24px;
`
