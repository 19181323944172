import { observable, action, computed } from 'mobx'
import { STORAGE_URL, images } from '../../resources'
import { UserModel } from '.'

export default class AudioFileModel {
  @observable id
  @observable title
  @observable _imageUri
  @observable imageUri
  @observable imageUrl
  @observable imageSource

  @observable description

  @observable mp3Uri
  @observable mp3Url

  @observable m3u8Uri
  @observable m3u8Url

  @observable originalUri
  @observable originalUrl

  @observable isLiveRoomRecording
  @observable isYoutubeAudioFile
  @observable youtubePublishedAt
  @observable youtubeVideoId
  @observable youtubeChannelId

  @observable plamUrl
  @observable plamUrlStatus
  @observable plamArtistUrl
  @observable plamUrlMemo

  @observable createdAt
  @observable updatedAt

  @observable thumbnailUri
  @observable thumbnailUrl
  @observable thumbnailSource
  @observable duration = 0

  @observable userId
  @observable User

  @observable _AudioFileTexts

  @computed get AudioFileTexts() {
    return this._AudioFileTexts
  }

  set AudioFileTexts(value) {
    this._AudioFileTexts = value || []
  }

  constructor(stores, props) {
    this.duration = 0
    if (props) {
      this.id = props.id
      this.title = props.title

      this.AudioFileTexts = props?.AudioFileTexts

      this._imageUri = props._imageUri || props.imageUri
      this.imageUri =
        this._imageUri
        && (this._imageUri.slice(0, 4) === 'http'
          ? this._imageUri
          : STORAGE_URL + this._imageUri)
      this.imageUrl = this.imageUri
      this.imageSource = this.imageUri ? { uri: this.imageUri } : images.song

      this.description = props.description

      this.mp3Uri = props.mp3Uri
      this.originalUri = props.originalUri
      this.mp3Url =
        this.mp3Uri
        && (this.mp3Uri.slice(0, 4) === 'http'
          ? this.mp3Uri
          : STORAGE_URL + this.mp3Uri)
      this.originalUrl =
        this.originalUri
        && (this.originalUri.slice(0, 4) === 'http'
          ? this.originalUri
          : STORAGE_URL + this.originalUri)
      this.normalizedMp3Uri = props.normalizedMp3Uri
      this.normalizedMp3Url =
        this.normalizedMp3Uri
        && (this.normalizedMp3Uri.slice(0, 4) === 'http'
          ? this.normalizedMp3Uri
          : STORAGE_URL + this.normalizedMp3Uri)
      // if (IS_ANDROID) {
      this.m3u8Uri = props.m3u8Uri
      this.m3u8Url = this.m3u8Uri && STORAGE_URL + this.m3u8Uri
      this.normalizedM3u8Uri = props.normalizedM3u8Uri
      this.normalizedM3u8Url =
        this.normalizedM3u8Uri && STORAGE_URL + this.normalizedM3u8Uri
      // }

      this.isLiveRoomRecording = props.isLiveRoomRecording
      this.isYoutubeAudioFile = props.isYoutubeAudioFile
      this.youtubePublishedAt = props.youtubePublishedAt
      this.youtubeVideoId = props.youtubeVideoId
      this.youtubeChannelId = props.youtubeChannelId

      /**
       * plamUrlStatus
       * - 0: 오디오 검수전
       * - 1: DB 수동 검수
       * - 5: 관리자 페이지 검수
       * - 10: 오디오 검수 불가
       * - 15: 오디오 url 누락: url이 없거나, url 주소가 잘못됨(접근 불가)
       * - 20: 플램인 데이터 접근불가(url변경 또는 트랙 삭제)
       */
      this.plamUrlStatus = props.plamUrlStatus
      this.plamUrl = props.plamUrl
      this.plamArtistUrl = props.plamArtistUrl
      this.plamUrlMemo = props.plamUrlMemo

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      if (this.imageUri && typeof this.imageUri === 'string') {
        const splitedPath = this.imageUri.split('/')
        const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]

        this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
        this.thumbnailUrl = this.thumbnailUri
        this.thumbnailSource = this.thumbnailUri && { uri: this.thumbnailUri }
      }

      this.duration = props.duration || this.duration

      this.userId = props.userId
      this.User = (props.User && new UserModel(stores, props.User)) || {}
    }
  }

  @action failLoadThumbnail = () => {
    this.thumbnailUri = null
    this.thumbnailSource = null
  }

  @action failLoadImage = () => {
    this.imageSource = images.song
  }
}
