import { IS_DEV } from '@consts/'
import RTMClient from './rtm-client'

const agoraAppId = '8f97e741ca404adca74579c8c2fb3a9f'

const rtmLogger = (...props) => {
  IS_DEV && console.log('[rtmLogger]', ...props)
}

export class AgoraRtm {
  static AGORA_APP_ID = agoraAppId
  static rtmEngine: RTMClient | NULL = null
  static joinedChannelId: String = ''
  static _handlerMessageReceived: (data: Object) => void | NULL = null
  static _handlerChannelMessageReceived: (data: Object) => void | NULL = null
  static _handlerChannelMemberLeft: (data: Object) => void | NULL = null

  static init = async ({
    handlerMessageReceived,
    handlerChannelMessageReceived,
    handlerChannelMemberLeft,
  }) => {
    try {
      if (this.rtmEngine) {
        await this._clear()
      }

      /* rtm */
      this.rtmEngine = new RTMClient()

      /**
       * @event
       * @param evt The received event object
       * error | occurs when wrapper emit error  | on("error") |
       */
      this.rtmEngine.on('error', evt =>
        rtmLogger('[LOG]', '[AgoraRtm]', '[event]', '[error]', evt),
      )
      /**
       * @event
       * @param evt The received event object {@link ConnectionState}
       * connectionStateChanged | occurs when connection state changed |
       */
      // this.rtmEngine.on("connectionStateChanged", evt =>
      this.rtmEngine.on('ConnectionStateChanged', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[connectionStateChanged]',
          ConnectionState(evt['state']),
          connectionchangeReason(evt['reason']),
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMPeerMessage}
       * messageReceived | occurs when message received |
       */
      // this.rtmEngine.on("messageReceived", evt =>
      this.rtmEngine.on('MessageFromPeer', evt => {
        rtmLogger('[LOG]', '[AgoraRtm]', '[event]', '[messageReceived]', evt)
        this.onMessageReceived(evt)
      })
      /**
       * @event
       * @param evt The received event object {@link RTMLocalInvitationMessage}
       * LocalInvitationReceivedByPeer | occurs when local inviation received by peer |
       */
      this.rtmEngine.on('LocalInvitationReceivedByPeer', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[LocalInvitationReceivedByPeer]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMLocalInvitationMessage}
       * LocalInvitationAccepted | occurs when local invitation accepted |
       */
      this.rtmEngine.on('LocalInvitationAccepted', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[LocalInvitationAccepted]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMLocalInvitationMessage}
       * LocalInvitationRefused | occurs when local invitation refused |
       */
      this.rtmEngine.on('LocalInvitationRefused', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[LocalInvitationRefused]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMLocalInvitationMessage}
       * LocalInvitationCanceled | occurs when local invitation canceled |
       */
      this.rtmEngine.on('LocalInvitationCanceled', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[LocalInvitationCanceled]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMLocalInvitationErrorMessage}
       * LocalInvitationFailure | occurs when local invitation failure |
       */
      this.rtmEngine.on('LocalInvitationFailure', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[LocalInvitationFailure]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMRemoteInvitationErrorMessage}
       * RemoteInvitationFailure | occurs when remote invitation failure |
       */
      this.rtmEngine.on('RemoteInvitationFailure', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[RemoteInvitationFailure]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMRemoteInvitationMessage}
       * RemoteInvitationReceived | occurs when remote invitation received |
       */
      this.rtmEngine.on('RemoteInvitationReceived', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[RemoteInvitationReceived]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMRemoteInvitationMessage}
       * RemoteInvitationAccepted | occurs when remote invitation accepted |
       */
      this.rtmEngine.on('RemoteInvitationAccepted', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[RemoteInvitationAccepted]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMRemoteInvitationMessage}
       * RemoteInvitationRefused | occurs when remote invitation refused |
       */
      this.rtmEngine.on('RemoteInvitationRefused', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[RemoteInvitationRefused]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMRemoteInvitationMessage}
       * RemoteInvitationCanceled | occurs when remote invitation canceled |
       */
      this.rtmEngine.on('RemoteInvitationCanceled', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[RemoteInvitationCanceled]',
          evt,
        ),
      )

      this.rtmEngine.on('PeersOnlineStatusChanged', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[PeersOnlineStatusChanged]',
          evt,
        ),
      )

      /**
       * @event
       * @param evt The received event object {@link RTMChannelMessage}
       * channelMessageReceived | occurs when received channel message |
       */
      // this.rtmEngine.on("channelMessageReceived", evt => {
      this.rtmEngine.on('ChannelMessage', evt => {
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[channelMessageReceived]',
          evt,
        )
        this.onChannelMessageReceived(evt)
      })
      /**
       * @event
       * @param evt The received event object {@link RTMMemberInfo}
       * channelMemberJoined | occurs when some one joined in the subscribed channel
       */
      // this.rtmEngine.on("channelMemberJoined", evt =>
      this.rtmEngine.on('MemberJoined', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[channelMemberJoined]',
          evt,
        ),
      )
      this.rtmEngine.on('AttributesUpdated', evt =>
        rtmLogger('[LOG]', '[AgoraRtm]', '[event]', '[AttributesUpdated]', evt),
      )
      this.rtmEngine.on('MemberCountUpdated', evt =>
        rtmLogger(
          '[LOG]',
          '[AgoraRtm]',
          '[event]',
          '[MemberCountUpdated]',
          evt,
        ),
      )
      /**
       * @event
       * @param evt The received event object {@link RTMMemberInfo}
       * channelMemberLeft | occurs when sone one left from u subscribed channel
       */
      // this.rtmEngine.on("channelMemberLeft", evt =>
      this.rtmEngine.on('MemberLeft', evt => {
        rtmLogger('[LOG]', '[AgoraRtm]', '[event]', '[channelMemberLeft]', evt)
        this.onChannelMemberLeft(evt)
      })
      /**
       * @event
       * @param evt The received event object
       * TokenExpired | occurs when token has expired |
       */
      this.rtmEngine.on('TokenExpired', evt =>
        rtmLogger('[LOG]', '[AgoraRtm]', '[event]', '[TokenExpired]', evt),
      )

      await this.rtmEngine.init(this.AGORA_APP_ID)

      this._handlerMessageReceived = handlerMessageReceived || null
      this._handlerChannelMessageReceived =
        handlerChannelMessageReceived || null
      this._handlerChannelMemberLeft = handlerChannelMemberLeft || null
    }
    catch (error) {
      console.error('[ERROR]', '[AgoraRtm]', `[init]`, `catch error`, error)
    }
    /* rtm end */
  }
  static _clear = async () => {
    try {
      if (this.rtmEngine) {
        await this.leaveChannel()
        // await this.rtmEngine.removeEvents();
        // await this.rtmEngine.destroyClient();
        this.rtmEngine = null
      }
      return true
    }
    catch (error) {
      console.error('[ERROR]', '[AgoraRtm]', `[_clear]`, `catch error`, error)
    }
    return false
  }

  static joinChannel = async (agoraRtmChannelId, agoraRtmUserToken, uid) => {
    if (!this.rtmEngine) {
      console.error('[ERROR]', '[AgoraRtm]', `[joinChannel]`, `not init`)
      return false
    }
    if (!agoraRtmUserToken || !uid) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[joinChannel]`,
        `required params agoraRtmUserToken=${agoraRtmUserToken}, uid=${uid}`,
      )
      return false
    }

    /* rtm */
    try {
      await this.leaveChannel()

      await this.rtmEngine.login({ token: agoraRtmUserToken, uid: `${uid}` })
      await this.rtmEngine.joinChannel(agoraRtmChannelId)
      this.joinedChannelId = agoraRtmChannelId
      return true
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[joinChannel]`,
        `catch error`,
        error,
      )
    }
    return false
    /* rtm end */
  }

  static leaveChannel = async () => {
    if (!this.rtmEngine) {
      // console.error('[ERROR]', '[AgoraRtm]', `[leaveChannel]`, `not init`)
      return false
    }

    try {
      if (this.joinedChannelId) {
        /**
         * login 한 상태를 유지하도록 아키텍처를 변경하거나,
         * logout 으로 leaveChannel 대체
         *
         * 둘 다 수행하면
         *   IOS 레드박스 에러
         *   Android 아무런 문구없이 Throw 후 해당 javascript func 스택 모두 강제 종료.
         */
        // await this.rtmEngine.leaveChannel(this.joinedChannelId)
        await this.rtmEngine.logout()
        this.joinedChannelId = ''
      }
      return true
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[leaveChannel]`,
        `catch error`,
        error,
      )
    }
    return false
  }

  static sendChannelMessage = async data => {
    if (!this.rtmEngine) {
      console.error('[ERROR]', '[AgoraRtm]', `[sendChannelMessage]`, `not init`)
      return false
    }
    if (!this.joinedChannelId) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[sendChannelMessage]`,
        `not joined`,
      )
      return false
    }
    if (!data || typeof data !== 'object') {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[sendChannelMessage]`,
        `no data. data must be object type.`,
      )
      return false
    }

    try {
      await this.rtmEngine.sendMessageByChannelId(
        this.joinedChannelId,
        JSON.stringify(data),
      )
      return true
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[sendChannelMessage]`,
        `catch error`,
        error,
      )
    }
    return false
  }

  static onChannelMessageReceived = async evt => {
    const jsonString = evt && evt['text']
    if (!jsonString) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[onChannelMessageReceived]`,
        `no jsonString`,
      )
      return false
    }

    try {
      if (typeof this._handlerChannelMessageReceived === 'function') {
        const jsonObj = JSON.parse(jsonString)
        await this._handlerChannelMessageReceived(jsonObj)
      }
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[onChannelMessageReceived]`,
        `catch error`,
        error,
      )
    }
    return false
  }

  /* peer message receive */
  static onMessageReceived = async evt => {
    const jsonString = evt && evt['text']
    if (!jsonString) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[onMessageReceived]`,
        `no jsonString`,
      )
      return false
    }

    try {
      if (typeof this._handlerMessageReceived === 'function') {
        const jsonObj = JSON.parse(jsonString)
        await this._handlerMessageReceived(jsonObj)
      }
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[onMessageReceived]`,
        `catch error`,
        error,
      )
    }
    return false
  }

  // 웹과 앱에서 오는 인자가 다름.
  // app evt : evt { channelId, uid }
  // web evt : evt = uid
  static onChannelMemberLeft = async evt => {
    // if (!evt || !evt.channelId || !evt.uid) {
    if (!evt) {
      console.error('[ERROR]', '[AgoraRtm]', `[onChannelMemberLeft]`, `no evt`)
      return false
    }

    try {
      if (typeof this._handlerChannelMemberLeft === 'function') {
        await this._handlerChannelMemberLeft({
          // channelId: evt.channelId, // Store default 인자로 변경
          uid: evt,
        })
      }
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[onChannelMemberLeft]`,
        `catch error`,
        error,
      )
    }
    return false
  }

  static sendPeerMessage = async (peerId, data) => {
    if (!this.rtmEngine) {
      console.error('[ERROR]', '[AgoraRtm]', `[sendPeerMessage]`, `not init`)
      return false
    }
    if (!this.joinedChannelId) {
      console.error('[ERROR]', '[AgoraRtm]', `[sendPeerMessage]`, `not joined`)
      return false
    }
    if (!data || typeof data !== 'object') {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[sendPeerMessage]`,
        `no data. data must be object type.`,
      )
      return false
    }

    try {
      await this.rtmEngine.sendPeerMessage(JSON.stringify(data), `${peerId}`)
      return true
    }
    catch (error) {
      console.error(
        '[ERROR]',
        '[AgoraRtm]',
        `[sendPeerMessage]`,
        `catch error`,
        peerId,
        data,
        error,
      )
    }
    return false
  }
}

const ConnectionState = state => {
  let _state = null
  switch (state) {
    case 1:
      _state = 'Disconnected'
      break
    case 2:
      _state = 'Connecting'
      break
    case 3:
      _state = 'Connected'
      break
    case 4:
      _state = 'Reconnecting'
      break
    case 5:
      _state = 'Aborted'
      break
    default:
      break
  }

  return _state
}

const connectionchangeReason = reason => {
  let _reason = null
  switch (reason) {
    case 1:
      _reason = 'Login'
      break
    case 2:
      _reason = 'LoginSuccess'
      break
    case 3:
      _reason = 'LoginFailure'
      break
    case 4:
      _reason = 'LoginTimeout'
      break
    case 5:
      _reason = 'Interrupted'
      break
    case 6:
      _reason = 'Logout'
      break
    case 7:
      _reason = 'BannedByServer'
      break
    case 8:
      _reason = 'RemoteLogin'
      break
    default:
      break
  }
  return _reason
}
