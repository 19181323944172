import React from 'react'
import { CompleteRegister } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

const CompleteRegisterPage = () => {
  useRenderLogger('CompleteRegisterPage')
  const { authStore } = useStore()

  const currentUser = authStore.currentUser || null

  return <CompleteRegister user={currentUser} />
}

export default observer(CompleteRegisterPage)
