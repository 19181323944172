import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  BodyWrapper,
  H5,
  H6,
  Caption1,
  FormInput,
  WarningMsg,
  Form,
  LogoImg,
  TextWrapper,
  Flex,
} from '@components/atoms'
import {
  RoundBtn,
  SubmitBtn,
  KakaoLoginBtn,
  FlexRowBox,
} from '@components/molecules'
import { Link, useHistory } from 'react-router-dom'
import CheckTextBox from '@components/molecules/box/CheckTextBox'
import { mobile } from '@styles/media'
import { STATUS_CODE_401_MSG } from '@consts/'

// const FormGroup = styled.div`
//   padding: 30px 0px;
//   width: 100%;

//   @media ${mobile} {
//     padding: 20px 0px;
//   }
// `

const Hr = styled.hr`
  background-color: #949494;
  margin: 0;
  padding: 0;
`

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FooterBox = styled.div`
  width: 100%;
  text-align: center;
  h5 {
    margin: 60px 0px 30px 0px;
  }

  @media ${mobile} {
    h5 {
      margin: 30px 0px 24px 0px;
    }
  }
`

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const KakaoBox = styled(FlexRowBox)`
  margin: 50px 0;

  @media ${mobile} {
    margin: 30px 0;
  }
`

const OrLine = styled.p`
  height: 24px;
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #d4d4d4;
  }

  span {
    width: 60px;
    height: 24px;
    margin: 0 0 0 -25px;
    font-size: 16px;
    line-height: 24px;

    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
    background: #fff;
    color: rgba(0, 0, 0, 0.38);
    text-align: center;
  }
`

const LoginForm = props => {
  const {
    isAutoLogin,
    toggleAutoLogin,
    login,
    error,
    setError,
    kakaoLogin,
  } = props
  const [checkYn, setCheckYn] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setError(false)
  }, [setError])

  const _login = async e => {
    e.preventDefault()
    const loginRes = await login(email, password, isAutoLogin)
    if (loginRes && loginRes.response) {
      setError(loginRes.response)
    }
  }

  const verifyEmail = () => {
    // 이메일 검증 스크립트 작성
    const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    // 검증에 사용할 정규식 변수 regExp에 저장

    if (email.match(regExp) != null) {
      setCheckYn(false)
    }
    else if (email !== '') {
      setCheckYn(true)
    }
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleKakaoLogin = async (kakao_account, kToken) => {
    const isGo = await kakaoLogin(kakao_account, kToken)
    if (isGo === 'KAKAO_REGISTER') {
      history.push({
        pathname: '/p/register/privacy',
        state: { isKakao: true },
      })
    }
  }

  return (
    <BodyWrapper>
      <LogoImg />
      <Form>
        <HeaderBox>
          <TextWrapper>
            <H6 color="#646464">흐름 앱에서&nbsp;</H6>
            <H6 color="#646464">사용하는 계정으로 로그인 가능합니다</H6>
          </TextWrapper>
        </HeaderBox>
        <KakaoBox>
          <KakaoLoginBtn kakaoLogin={handleKakaoLogin} />
        </KakaoBox>
        <OrLine>
          <span>또는</span>
        </OrLine>

        <InputBox>
          <FormInput
            placeholder="가입한 이메일 입력"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onBlur={() => verifyEmail()}
          />
        </InputBox>
        <InputBox>
          <FormInput
            type="password"
            placeholder="비밀번호 입력"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          {
            checkYn ? (
              <WarningMsg>이메일 형식이 올바르지 않습니다</WarningMsg>
            ) : (
              false
            )
          }

          {error && <WarningMsg>{STATUS_CODE_401_MSG[error]}</WarningMsg>}
        </InputBox>

        <FlexRowBox style={{ width: '100%', justifyContent: 'space-between' }}>
          <CheckTextBox
            onClick={toggleAutoLogin}
            checked={isAutoLogin}
            text="로그인 상태유지"
          />

          <Flex justify="center" align="center">
            <Link to="/p/find/email">
              <Caption1 style={{ cursor: 'pointer' }} color="#242424">
                이메일 찾기
              </Caption1>
            </Link>
            <Flex
              style={
                {
                  width: 1,
                  height: 12,
                  backgroundColor: '#242424',
                  margin: '0 12px',
                  marginTop: 4,
                }
              }
            />
            <Link to="/p/find/password">
              <Caption1 style={{ cursor: 'pointer' }} color="#242424">
                비밀번호 찾기
              </Caption1>
            </Link>
          </Flex>
        </FlexRowBox>

        <SubmitBtn style={{ margin: '40px 0px' }} onClick={e => _login(e)}>
          로그인
        </SubmitBtn>

        <FooterBox>
          <Hr />
          <H5 color="#646464">혹시, 흐름이 처음 이신가요?</H5>
          <Link to="/p/register">
            <div>
              <RoundBtn
                style={{ padding: '12px 100px' }}
                borderColor="#949494"
                color="#949494"
                text="회원가입"
                // href="/p/register"
              />
            </div>
          </Link>
        </FooterBox>
      </Form>
    </BodyWrapper>
  )
}

export default LoginForm
