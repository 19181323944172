export const convertToPricingComma = str => {
  if (str == null) return false
  if (typeof str !== 'string') {
    const toStr = str.toString()
    return (
      (toStr && toStr.replace && toStr.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
      || ''
    )
  }
  return (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
}

export const lPad = str => {
  str += ''
  if (str.length < 2) {
    str = `0${  str}`
  }
  return str
}

export const sliceFromBack = (str, digits) =>
  str.substring(str.length - digits, str.length)

export const capitalize = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const hideNumber = str => {
  let result = ''
  // 함수를 완성해주세요
  const hide_num = str.length - 4
  result = str.substr(hide_num)
  for (let i = 0; i < hide_num; i++) {
    result = `*${  result}`
  }
  return result
}

export const getUniqueObjectArray = (array, key) => {
  // return array.filter((item, i) => {
  //   return array.findIndex((item2, j) => {
  //     return item.key === item2.key;
  //   }) === i;
  // });
  const tempArray = []
  const resultArray = []
  for (let i = 0; i < array.length; i++) {
    const item = array[i]

    if (tempArray.includes(item[key])) {
      continue
    }
    else {
      resultArray.push(item)
      tempArray.push(item[key])
    }
  }
  return resultArray
}

export const addComma = num => {
  if (typeof num !== 'number') {
    return ''
  }

  const regexp = /\B(?=(\d{3})+(?!\d))/g
  return num.toString().replace(regexp, ',')
}
