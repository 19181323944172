import React, { useState, useEffect , useCallback } from 'react'
import {
  Form,
  FormInput,
  FormTextArea,
  FormLabel,
  Caption1,
  Caption2,
  Caption3,
  H5,
  Button,
  Flex,
  ProfileImg,
  Img,
  ImageIcon,
} from '@components/atoms'
import styled from 'styled-components'
import {
  SubmitBtn,
  FlexColumnBox,
  RowToColumnBox,
  SelectBox,
  Tooltip,
  FlexRowBox,
  HouseToggleBtn,
  TimePicker,
} from '@components/molecules'
import { mobile, tablet } from '@styles/media'
import { useForm, Controller } from 'react-hook-form'
import { message } from 'antd'
import Calendar from 'react-calendar'
import '@styles/Calendar.css'
import '@styles/custom-antd.css'
import { STORAGE_URL } from '@consts'
import { colors } from '@resources/colors'
import {
  CASTS_HAVE_TAGS_LEVEL,
  CAST_UPLOAD_TYPE,
  SUBJECT_TAG_LIST,
  NOT_ALLOWED_TAG_LIST,
  DEFAULT_TAG_LIST,
} from '@consts/cast'
import { images } from '@images'
import HouseEditModal from '@components/organisms/modal/HouseEditModal'
import moment from 'moment'
import CastImageUploadForm from './CastImageUploadForm'
import AudioUploadForm from './AudioUploadForm'
import TagForm from './TagForm'


const TooltipContent = styled.div`
  width: 435px;
  padding: 14px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;

  @media ${mobile} {
    width: 308px;
    padding: 12px;
  }
`

const SubmitBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  text-align: center;

  @media ${mobile} {
    margin: 15px 0px;
  }
`

const FormGroup = styled.div`
  padding: 10px 0px;
  width: 100%;

  @media ${mobile} {
    padding: 8px 0px;
  }
`

const AudioForm = styled(Form)`
  width: 100%;
  max-width: 1000px;

  @media ${mobile} {
    width: 100%;
  }
`

const ImageUploadBox = styled(FormGroup)`
  /* max-width: 452px; */
  width: 100%;
  @media ${mobile} {
    padding: 20px 0px 12px 0px;
  }
`

const LicenseGroup = styled(FormGroup)`
  width: 518px;

  @media ${mobile} {
    width: 100%;
  }
`

const AudioInfoInputBox = styled(FlexColumnBox)`
  width: 100%;
  /* max-width: 518px; */

  @media ${tablet} {
    padding-left: 20px;
  }

  @media ${mobile} {
    height: 320px;
  }
`

const TopicGroup = styled(FlexRowBox)`
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  justify-content: flex-start;

  label {
    margin: 7px;
  }
`

const TopicTag = styled.label`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: fit-content;
  min-width: 74px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 40px;
  background-color: ${props => (props.isActive ? '#f4f4f4' : '#ffffff')};
  border-radius: 20px;
  border: ${props =>
    props.isActive ? '1px solid #646464' : '1px solid #f4f4f4'};
  color: #646464;
  cursor: pointer;
`

const SystemGroup = styled(FlexRowBox)`
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  justify-content: flex-start;

  label {
    margin: 7px;
  }
`

const SystemTag = styled.label`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  width: fit-content;
  min-width: 74px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 20px;
  color: #646464;
`

/**
 * cast upload type select
 */
const TypeListBox = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid ${colors.grey_d4};
  color: ${colors.black};
  background-color: #ffffff;

  margin-top: 10px;
  min-width: 200px;

  @media ${mobile} {
    margin-left: 0px;
  }
`

const HouseAddingBtn = styled(Button)`
  width: 100%;
  height: 80px;
  padding: 8px 24px;
  border-radius: 8px;
  border: solid 1px #f4f4f4;
  background-color: var(--white);

  justify-content: flex-start;
  margin-top: 10px;
  min-width: 200px;

  @media ${mobile} {
    margin-left: 0px;
  }
`
const HouseAddingTitle = styled.div`
  width: 244px;
  height: 20px;
  margin: 11px 0 4px 0;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
`
const HouseAddingSubTitle = styled.div`
  width: 119px;
  height: 18px;
  margin: 4px 0 11px 0;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #06f;
`

const AcceptContentsBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  max-width: 465px;
  height: fit-content;
  max-height: 128px;
  overflow: auto;
  justify-content: flex-start;
  border-radius: 7px;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 2;

  min-width: 200px;
`

const AcceptContentsItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 11px 8px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`

const TypeItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 11px 8px;
  align-items: center;
  /* justify-content: space-between; */

  :hover {
    background-color: #f4f4f4;
  }
`

const ReservedTypeListBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid ${colors.grey_d4};
  color: ${colors.black};
  background-color: #ffffff;

  margin-top: 10px;
  min-width: 200px;

  @media ${mobile} {
    margin-left: 0px;
  }
`

const ReservedAcceptContentsBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 42px;
  /* left: 0; */
  /* width: 100%; */
  right: 0;
  max-width: 465px;
  height: fit-content;
  max-height: 512px;
  overflow: auto;
  justify-content: flex-start;
  border-radius: 7px;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 2;

  min-width: 200px;
  /* justify-content: cetner;
  align-contents: center; */
`
const ReservedAtOk = styled(Button)`
  width: 264px;
  height: 40px;
  margin: 22px 12px 12px;
  padding: 11px 0;
  border-radius: 4px;
  background-color: #5b49f3;

  justify-content: center;
  align-contents: center;
`

const CastUploadForm = props => {
  const {
    user,
    createCast,
    setIsUploaded,
    uploadCastAudio,
    uploadCastImage,
    createAudioFile,
    getTag,
    isEdit,
    cast,
    updateCast,
    type,
    recording,

    /** case select upload place */
    fetchUserHouseList,
    basePlace,
    isPersonal = false,
  } = props

  const {
    register,
    handleSubmit,
    control,
    // errors,
    // setError,
    // clearError,
    setValue,
  } = useForm()

  const [audioFormData, setAudioFormData] = useState()
  const [butterfilesFile, setButterfilesFile] = useState()
  const [imageUri, setImageUri] = useState(cast ? cast.imageUri : null)
  const [imageUrl, setImageUrl] = useState(cast ? cast.imageUrl : null)
  const [normalTagList, setNormalTagList] = useState([])
  const [topicTagList, setTopicTagList] = useState([])
  const [defaultTagList, setDefaultTagList] = useState([])
  const [etcTagList, setEtcTagList] = useState([])

  /**
   * cast select upload place
   */
  const UPLOAD_PLACE_TYPE = {
    PERSONAL: 'PERSONAL',
    HOUSE: 'HOUSE',
  }
  const [showUserUploadPlaceList, setShowUserUploadPlaceList] = React.useState(
    false,
  )

  // const _basePersonalPlace = { item: user, type: UPLOAD_PLACE_TYPE['PERSONAL'] }
  const _basePlace = basePlace
    ? {
      item: basePlace,
      type: isPersonal
        ? UPLOAD_PLACE_TYPE['PERSONAL']
        : UPLOAD_PLACE_TYPE['HOUSE'],
    }
    : null
  const [selectedPlace, setSelectedPlace] = React.useState(_basePlace)
  const [uploadPlaceList, setUploadPlaceList] = React.useState([])

  /**
   * House Cast select type
   */
  const HOUSE_TYPE = {
    PUBLIC: 'PUBLIC',
    MEMBER: 'MEMBER',
  }

  const [showType, setShowType] = useState(false)
  const [selectedType, setSelectedType] = useState(HOUSE_TYPE['PUBLIC'])

  const [showReservedPublishedAt, setShowReservedPublishedAt] = useState(false)
  const [isReserved, setIsReserved] = useState(cast?.isReserved || false)
  const [reservedPublishedAt, setReservedPublishedAt] = useState(cast?.reservedPublishedAt || null)

  const {
    isShowing: showHoustEditModal,
    toggle: toggleHouseEditModal,
  } = HouseEditModal.useModal()

  const _fetchUserHouseList = useCallback(async () => {
    const houseList = await fetchUserHouseList()
    if(houseList) {
      const _uploadPlaceList = [
        ...houseList.map(item => {
          return { item, type: UPLOAD_PLACE_TYPE['HOUSE'] }
        }),
      ]
      setUploadPlaceList(_uploadPlaceList)
      if(!selectedPlace && _uploadPlaceList?.length > 0) {
        setSelectedPlace(_uploadPlaceList[0])
      }
    }
  }, [selectedPlace])
  useEffect(() => {
    _fetchUserHouseList()
    return () => {}
  }, [])

  const _loadSelectedType = _type => {
    switch (_type) {
      case HOUSE_TYPE['PUBLIC']:
        return (
          <Flex style={{ alignItems: 'center' }}>
            <Img
              src={images.salon_type_public}
              width="20px"
              height="20px"
              style={{ marginRight: '8px' }}
            />
            <Caption3>공개</Caption3>
          </Flex>
        )
      case HOUSE_TYPE['MEMBER']:
        return (
          <Flex style={{ alignItems: 'center' }}>
            <Img
              src={images.salon_type_member}
              width="20px"
              height="20px"
              style={{ marginRight: '8px' }}
            />
            <Caption3>멤버</Caption3>
          </Flex>
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    setTopicTagList(
      SUBJECT_TAG_LIST.map(_tag => {
        return { ..._tag, isSelected: false }
      }),
    )
    setDefaultTagList(
      DEFAULT_TAG_LIST.map(_tag => {
        return { ..._tag, isSelected: false }
      }),
    )

    if (cast) {
      setImageUri(cast.imageUri)
      setImageUrl(cast.imageUrl)

      const { CastsHaveTags } = cast
      if (CastsHaveTags) {
        const _normalTagList = CastsHaveTags.filter(
          _tag =>
            _tag.level === CASTS_HAVE_TAGS_LEVEL['NO']
            || _tag.level === CASTS_HAVE_TAGS_LEVEL['NORMAL'],
        )
        const _normalTagListKeyword = _normalTagList
          .filter(_castsHaveTags => {
            // defaultTagList에 등록되지 않은 추가 태그들만 필터
            const index = defaultTagList.findIndex(
              __tag => __tag.keyword === _castsHaveTags.Tag.keyword,
            )
            if (index < 0) {
              return true
            }
            return false
          })
          .map(_tag => _tag.Tag.keyword)

        setNormalTagList(_normalTagListKeyword)

        const _topicTagList = CastsHaveTags.filter(
          _tag => _tag.level === CASTS_HAVE_TAGS_LEVEL['TOPIC'],
        )

        _topicTagList.map(_tag => {
          const index = topicTagList.findIndex(
            __tag => __tag.keyword === _tag.Tag.keyword,
          )
          if (index >= 0) {
            topicTagList[index].isSelected = true
            return true
          }
          return true
        })
        const tempTopicTagList = [...topicTagList]
        setTopicTagList(tempTopicTagList)

        _normalTagList.map(_tag => {
          const index = defaultTagList.findIndex(
            __tag => __tag.keyword === _tag.Tag.keyword,
          )
          if (index >= 0) {
            defaultTagList[index].isSelected = true
          }
        })

        const tempDefaultTagList = [...defaultTagList]
        setDefaultTagList(tempDefaultTagList)

        const _etcTagList = CastsHaveTags.filter(
          _tag =>
            _tag.level !== CASTS_HAVE_TAGS_LEVEL['NO']
            && _tag.level !== CASTS_HAVE_TAGS_LEVEL['NORMAL']
            && _tag.level !== CASTS_HAVE_TAGS_LEVEL['TOPIC'],
        )
        const _etcTagListKeyword = _etcTagList.map(_castsHaveTags => {
          return {
            keyword: _castsHaveTags.Tag.keyword,
            level: _castsHaveTags.level,
          }
        })

        setEtcTagList(_etcTagListKeyword)
      }
    }
    // eslint-disable-next-line
  }, [cast])

  useEffect(() => {
    if (recording) {
      const { LiveRoom } = recording
      setImageUri(LiveRoom?.imageUri)
      setImageUrl(LiveRoom?.imageUrl)
    }
  }, [recording])

  // 메타데이터 이미지 업로드
  const _uploadCastImage = async picture => {
    if (!picture || !picture['data'] || !picture['format']) return
    setImageUri('')

    const byteArray = new Uint8Array(picture['data'])
    const blob = new Blob([byteArray], { type: picture['format'] })

    const imageFormData = new FormData()
    imageFormData.append('image', blob)

    const uploadedSong = await uploadCastImage(
      imageFormData,
      sessionStorage.getItem('jwt_token'),
    )
    if (!uploadedSong || !uploadedSong['imageUri']) return

    setImageUri(uploadedSong['imageUri'])
    setImageUrl(STORAGE_URL + uploadedSong['imageUri'])
    return uploadedSong
  }

  const _mergeTagList = ({
    topicList = [],
    normalList = [],
    defaultList = [],
    etcList = [],
  }) => {
    const _topicList = topicList
      .filter(_tag => !!_tag.isSelected)
      .map(_tag => {
        return {
          keyword: _tag.keyword,
          castsHaveTagsLevel: CASTS_HAVE_TAGS_LEVEL['TOPIC'],
        }
      })
    const _normalList = normalList.map(_tag => {
      return {
        keyword: _tag,
        castsHaveTagsLevel: CASTS_HAVE_TAGS_LEVEL['NORMAL'],
      }
    })

    const _defaultList = defaultList
      .filter(_tag => !!_tag.isSelected)
      .map(_tag => {
        return {
          keyword: _tag.keyword,
          castsHaveTagsLevel: CASTS_HAVE_TAGS_LEVEL['NORMAL'],
        }
      })

    const _etcList = etcList.map(_tag => {
      return {
        keyword: _tag.keyword,
        castsHaveTagsLevel: _tag.level,
      }
    })

    return [..._topicList, ..._normalList, ..._defaultList, ..._etcList]
  }

  const _createCast = async (_cast, _tagList) => {
    if (!selectedPlace?.item?.id || !selectedPlace?.type) {
      message.error('업로드 경로가 선택되지 않았습니다.')
      return
    }

    if (selectedPlace?.type === UPLOAD_PLACE_TYPE['HOUSE']) {
      _cast['houseId'] = selectedPlace['item']['id']
    }
    else {
      _cast['houseId'] = null
    }

    _cast['isHouseMember'] = selectedType === HOUSE_TYPE['MEMBER']

    const result = await createCast(_cast, _tagList)
    return result
  }

  /**
   * 등록하기
   * 1. 캐스트 생성
   *  1) 오디오 파일 업로드하여 캐스트 생성
   *  2) 녹음 파일 등록하여 캐스트 생성
   * 2. 캐스트 수정
   */
  const onSubmit = async data => {
    if (!imageUri) {
      message.warn('이미지를 선택해주세요')
      return
    }

    if (topicTagList.filter(_tag => _tag.isSelected).length === 0) {
      message.warn('주제를 선택해주세요')
      return
    }

    let utcReservedPublishedAt = null
    if(reservedPublishedAt){
      utcReservedPublishedAt = moment(reservedPublishedAt).subtract(moment().utcOffset(), 'minute').format('YYYY-MM-DD HH:mm')
    }

    if (isEdit) {
      const _castUpdateParams = {
        id: cast.id,
        title: data.title,
        description: data.description,
        imageUri: imageUri && imageUri,
        // Todo: 22.06.16 수정 시, 하우스 멤버 수정 뷰가 없어서, 자동으로 공개처리 되는 이슈
        // 임시 로직으로, 수정 전 값이 업데이트 되게 처리.
        // isHouseMember: selectedType === HOUSE_TYPE['MEMBER'],
        isHouseMember: cast['isHouseMember'],
        reservedPublishedAt: utcReservedPublishedAt,
        isReserved: !!reservedPublishedAt && isReserved,
      }

      const tagList = _mergeTagList({
        topicList: topicTagList,
        normalList: normalTagList,
        defaultList: defaultTagList,
        etcList: etcTagList,
      })

      const updatedCast = await updateCast(_castUpdateParams, tagList)

      if (!updatedCast) {
        message.error('캐스트 수정 실패')
        return
      }

      setIsUploaded(true)
      message.success('캐스트 수정 완료')
    }
    if (type === CAST_UPLOAD_TYPE['UPLOAD']) {
      // if (!audioFormData) {
      //   message.error('오디오 파일을 업로드 해주세요.')
      //   return
      // }
      if (!butterfilesFile) {
        message.error('오디오 파일을 업로드 해주세요.')
        return
      }

      // 오디오 파일 업로드
      const uploadedAudio = await uploadCastAudio(butterfilesFile)
      // const uploadedAudio = await uploadCastAudio(audioFormData)
      if (!uploadedAudio) {
        return
      }

      const createdAudioFile = await createAudioFile({
        title: data.title,
        description: data.description,
        imageUri: imageUri && imageUri,
        originalUri: uploadedAudio.originalUri,
        duration: uploadedAudio.duration,
        userId: user['id'],
      })

      if (!createdAudioFile) return

      const _castParams = {
        title: createdAudioFile.title,
        description: createdAudioFile.description,
        isPublished: true,
        audioFileId: createdAudioFile.id,
        userId: createdAudioFile.userId,
        imageUri: createdAudioFile.imageUri,
        reservedPublishedAt: utcReservedPublishedAt,
        isReserved: !!reservedPublishedAt && isReserved,
      }

      const tagList = _mergeTagList({
        topicList: topicTagList,
        normalList: normalTagList,
        defaultList: defaultTagList,
      })
      const createdCast = await _createCast(_castParams, tagList)

      if (!createdCast) {
        message.error('캐스트 등록 실패')
        return
      }

      setIsUploaded(true)
      message.success('캐스트 등록 완료')
    }
    if (type === CAST_UPLOAD_TYPE['RECORDING'] && recording) {
      const _castParams = {
        title: data.title,
        description: data.description,
        isPublished: true,
        imageUri: imageUri && imageUri,
        duration: recording.duration || 0,
        userId: user['id'],
        liveRoomRecordingId: recording['id'],
        reservedPublishedAt: utcReservedPublishedAt,
        isReserved: !!reservedPublishedAt && isReserved,
      }

      const tagList = _mergeTagList({
        topicList: topicTagList,
        normalList: normalTagList,
        defaultList: defaultTagList,
      })
      const createdCast = await _createCast(_castParams, tagList)

      if (!createdCast) {
        message.error('캐스트 등록 실패')
        return
      }

      setIsUploaded(true)
      message.success('캐스트 등록 완료')
    }
  }

  return (
    <AudioForm onSubmit={handleSubmit(onSubmit)}>
      {
        !isEdit && type === CAST_UPLOAD_TYPE['UPLOAD'] && (
          <FormGroup>
            <AudioUploadForm
              setAudioFormData={setAudioFormData}
              setButterfilesFile={setButterfilesFile}
              audioFormData={audioFormData}
              setValue={setValue}
              _uploadSongImage={_uploadCastImage}
              imageUri={imageUri}
              getTag={getTag}
              isCastUpload={type === CAST_UPLOAD_TYPE['UPLOAD']}
            />
          </FormGroup>
        )
      }
      {
        // <div
        //   stype={
        //     {
        //       display: 'flex',
        //       width: '100%',
        //       justifyContent: 'flex-start',
        //     }
        //   }
        // >
        //   <div>오디오 파일</div>
        //   <div>{recording.title}</div>
        // </div>
      }
      <RowToColumnBox
        style={
          {
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }
        }
      >
        <ImageUploadBox>
          <CastImageUploadForm
            setImageUri={setImageUri}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            uploadSongImage={uploadCastImage}
            title="캐스트 이미지"
          />
        </ImageUploadBox>
        <Flex
          type="column"
          style={
            {
              width: '100%',
              marginLeft: 18,
            }
          }
        >
          {
            !isEdit && (
              selectedPlace
                ? (
                  <TypeListBox
                    type="button" // type submit이 되어 form의 submit이 실행되게 됨.
                    style={
                      {
                        marginBottom: 8,
                        position: 'relative',
                        pointer: 'cursor',
                      }
                    }
                    onClick={
                      async e => {
                        setShowUserUploadPlaceList(!showUserUploadPlaceList)
                        _fetchUserHouseList()
                      }
                    }
                  >
                    {
                  selectedPlace?.type === UPLOAD_PLACE_TYPE['HOUSE'] ? (
                    <House house={selectedPlace['item']} />
                  ) : (
                    <></>
                  )
                    }
                    <Flex align="center">
                      <Caption3
                        color={colors.brown_grey}
                        style={{ marginRight: '12px' }}
                      >
                        {
                      selectedPlace?.type === UPLOAD_PLACE_TYPE['HOUSE']
                        ? '하우스'
                        : ''
                        }
                      </Caption3>
                      <Img
                        src={
                          showUserUploadPlaceList
                            ? images.move_up_gray_img
                            : images.move_down_gray_img
                        }
                        width="12px"
                        height="12px"
                      />
                    </Flex>
                    {
                      showUserUploadPlaceList && (
                        <AcceptContentsBox>
                          {
                            uploadPlaceList.map(place => {
                              return (
                                <AcceptContentsItem
                                  onClick={
                                    e => {
                                      e.stopPropagation()
                                      setSelectedPlace(place)
                                      setShowUserUploadPlaceList(false)
                                    }
                                  }
                                  key={place?.['item']?.['id']}
                                >
                                  <House house={place?.['item']} />
                                  <Caption3>하우스</Caption3>
                                </AcceptContentsItem>
                              )
                            })
                          }
                        </AcceptContentsBox>
                      )
                    }
                  </TypeListBox>
                )
                : (
                  <HouseAddingBtn
                    type="button" // type submit이 되어 form의 submit이 실행되게 됨.
                    style={
                      {
                        marginBottom: 8,
                        position: 'relative',
                        pointer: 'cursor',
                      }
                    }
                    onClick={
                      async e => {
                        toggleHouseEditModal()
                      }
                    }
                  >
                    <Flex
                      type="column"
                    >
                      <HouseAddingTitle>캐스트를 등록할 하우스를 만들어 주세요 :)</HouseAddingTitle>
                      <HouseAddingSubTitle>지금 하우스 만들러 가기</HouseAddingSubTitle>
                    </Flex>
                    <Flex
                      style={
                        {
                          width:'100%',
                          justifyContent: 'flex-end',
                        }
                      }
                    >
                      <ImageIcon
                        name={'addingHouse'}
                        width="96px"
                        height="64px"
                      />
                    </Flex>
                  </HouseAddingBtn>
                )
            )
          }
          {
            selectedPlace?.type === UPLOAD_PLACE_TYPE['HOUSE'] && (
              <TypeListBox
                type="button"
                style={
                  {
                    marginBottom: 8,
                    position: 'relative',
                    pointer: 'cursor',
                  }
                }
                onClick={
                  () => {
                    setShowType(!showType)
                  }
                }
              >
                {_loadSelectedType(selectedType)}
                <Img
                  src={
                    showUserUploadPlaceList
                      ? images.move_up_gray_img
                      : images.move_down_gray_img
                  }
                  width="12px"
                  height="12px"
                />
                {
                  showType && (
                    <AcceptContentsBox>
                      <TypeItem
                        onClick={
                          () => {
                            setShowType(false)
                            setSelectedType(HOUSE_TYPE['PUBLIC'])
                          }
                        }
                      >
                        <Img
                          src={images.salon_type_public}
                          width="20px"
                          height="20px"
                          style={{ marginRight: '8px' }}
                        />
                        <Caption3>공개</Caption3>
                      </TypeItem>
                      <TypeItem
                        onClick={
                          () => {
                            setShowType(false)
                            setSelectedType(HOUSE_TYPE['MEMBER'])
                          }
                        }
                      >
                        <Img
                          src={images.salon_type_member}
                          width="20px"
                          height="20px"
                          style={{ marginRight: '8px' }}
                        />
                        <Caption3>멤버</Caption3>
                      </TypeItem>
                    </AcceptContentsBox>
                  )
                }
              </TypeListBox>
            )
          }
          {
            selectedPlace?.type === UPLOAD_PLACE_TYPE['HOUSE'] && (
              <ReservedTypeListBox
                style={
                  {
                    marginBottom: 8,
                    position: 'relative',
                    pointer: 'cursor',
                  }
                }
              >
                <Button
                  style={{ width:'inherit', justifyContent:'inherit' }}
                  onClick={
                    (e) => {
                      e.preventDefault()
                      setIsReserved(!isReserved)
                      setShowReservedPublishedAt(!isReserved)
                    }
                  }
                >
                  <Flex style={{ alignItems: 'center' }}>
                    <Caption3>예약하기</Caption3>
                  </Flex>
                  <HouseToggleBtn
                    checked={isReserved}
                    onChange={e => {}}
                  />
                </Button>
                {
                  showReservedPublishedAt && (
                    <ReservedAcceptContentsBox>
                      <Calendar
                        onChange={
                          (value) => {
                            const newReservedPublishedAt = moment(`${moment(value).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
                            console.log(
                              'jhlim',
                              'Calendar', '\n',
                              'value', moment(value).format('YYYY-MM-DD HH:mm'), '\n',
                              'newReservedPublishedAt', newReservedPublishedAt, '\n'
                            )
                            // setShowReservedPublishedAt(!showReservedPublishedAt)
                            if(value) {
                              setIsReserved(true)
                              setReservedPublishedAt(newReservedPublishedAt)
                            }
                          }
                        }
                        value={reservedPublishedAt ? new Date(reservedPublishedAt) : new Date()}
                      />
                      <TimePicker
                        reservedPublishedAt={reservedPublishedAt}
                        setReservedPublishedAt={
                          (_reservedPublishedAt) => {
                            console.log(
                              'jhlim',
                              'TimePicker', '\n',
                              '_reservedPublishedAt', _reservedPublishedAt, '\n',
                            )
                            setReservedPublishedAt(_reservedPublishedAt)
                          }
                        }
                      />
                      <Flex align={'center'} justify={'center'}>
                        <ReservedAtOk onClick={() => {setShowReservedPublishedAt(!showReservedPublishedAt)}}>
                          <Caption2 color="#FFFFFF">선택하기</Caption2>
                        </ReservedAtOk>
                      </Flex>
                    </ReservedAcceptContentsBox>
                  )
                }
              </ReservedTypeListBox>
            )
          }
        </Flex>
      </RowToColumnBox>
      <AudioInfoInputBox>
        <FormGroup>
          <FormLabel color="#646464" required>
            제목
          </FormLabel>
          <FormInput
            name="title"
            ref={register({ required: true })}
            // onBlur={() => setYoutubeUrl()}
            placeholder="제목 입력"
            defaultValue={cast && cast.title}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel color="#646464">설명</FormLabel>
          <FormTextArea
            name="description"
            ref={register({ required: false })}
            style={{ height: '218px' }}
            placeholder="설명 입력"
            defaultValue={cast && cast.description}
          />
        </FormGroup>
      </AudioInfoInputBox>
      {/* 태그 입력 */}
      <FormGroup>
        <FlexRowBox style={{ marginTop: '20px', marginBottom: '5px' }}>
          <H5>{'주제 태그(최대 3개)'}</H5>
          <Caption1 color="#949494" style={{ marginLeft: '8px' }}>
            {'  * 필수'}
          </Caption1>
        </FlexRowBox>
        <TopicGroup style={{ marginBottom: '20px' }}>
          {
            topicTagList.map((tag, index) => (
              <TopicTag
                onClick={
                  () => {
                    tag.isSelected = !tag.isSelected
                    if (topicTagList.filter(_tag => _tag.isSelected).length > 3) {
                      message.error('주제 태그는 최대 3개 까지 선택할 수 있습니다.')
                      tag.isSelected = !tag.isSelected
                      return
                    }
                    const _topicTagList = [...topicTagList]
                    setTopicTagList(_topicTagList)
                  }
                }
                isActive={tag.isSelected}
                key={`${index}-${tag.keyword}`}
                style={{ whiteSpace: 'pre' }}
              >
                {`${tag.icon}  ${tag.keyword}`}
              </TopicTag>
            ))
          }
        </TopicGroup>
        <TagForm
          title={'일반 태그(최대 15개)'}
          defaultTagList={defaultTagList}
          setDefaultTagList={
            _tag => {
              _tag.isSelected = !_tag.isSelected
              const _defaultTagList = [...defaultTagList]
              setDefaultTagList(_defaultTagList)
            }
          }
          tags={normalTagList}
          setTags={setNormalTagList}
          validate={
            tag => {
              if (
                defaultTagList.filter(_tag => _tag.isSelected).length
                + normalTagList.length
              >= 15
              ) {
                message.error('일반 태그는 최대 15개 까지 선택할 수 있습니다.')
                return false
              }

              const isInvalidateTag = NOT_ALLOWED_TAG_LIST.map(
                _tag => _tag.keyword,
              ).includes(tag)

              if (isInvalidateTag) {
                message.error('해당 태그는 일반 태그로 등록할 수 없습니다.')
                return false
              }
              return true
            }
          }
        />
        {
          etcTagList.filter(
            _tag => _tag.level === CASTS_HAVE_TAGS_LEVEL['VISIBLE_SYSTEM'],
          ).length === 0 ? (
              <></>
            ) : (
              <>
                <FlexRowBox style={{ marginTop: '20px', marginBottom: '5px' }}>
                  <H5>{'시스템 태그'}</H5>
                </FlexRowBox>
                <SystemGroup style={{ marginBottom: '20px' }}>
                  {
                    etcTagList
                      .filter(
                        _tag =>
                          _tag.level === CASTS_HAVE_TAGS_LEVEL['VISIBLE_SYSTEM'],
                      )
                      .map((_tag, index) => (
                        <SystemTag key={`${index}-${_tag.keyword}`}>
                          {_tag.keyword}
                        </SystemTag>
                      ))
                  }
                </SystemGroup>
              </>
            )
        }
      </FormGroup>

      {/* 라이센스 */}
      <FlexRowBox style={{ width: '100%', justifyContent: 'center' }}>
        <LicenseGroup>
          <Controller
            as={
              <SelectBox
                style={
                  {
                    width: '100%',
                    height: '48px',
                    borderRadius: '7px',
                    border: 'solid 1px #949494',
                    backgroundColor: '#ffffff',
                  }
                }
                options={
                  [
                    { value: '0', label: '표준 흐름 라이선스' },
                    { value: '1', label: '크리에이티브 커먼즈 - 저작자 표시' },
                  ]
                }
              />
            }
            placeholder="라이센스 선택"
            control={control}
            rules={{ required: true }}
            onChange={
              ([selected]) => {
              // Place your logic here
                return selected
              }
            }
            defaultValue={{ value: '0', label: '표준 흐름 라이선스' }}
            name="license"
          />
        </LicenseGroup>
        <Tooltip
          content={
            <TooltipContent>
              <Caption1 color="#646464">
                표준 흐름 라이선스는 서비스약관에서 확인할 수 있습니다. 또는
                크리에이티브 커먼즈 저작자 표시 라이선스를 사용할 수 있습니다.
              </Caption1>
            </TooltipContent>
          }
        />
      </FlexRowBox>

      <SubmitBox>
        <SubmitBtn type="submit">등록하기</SubmitBtn>
      </SubmitBox>

      <HouseEditModal.View
        isEdit={false}
        isShowing={showHoustEditModal}
        toggle={toggleHouseEditModal}
      />
    </AudioForm>
  )
}

export default CastUploadForm

const User = ({ user }) => {
  return (
    <Flex align="center">
      <UserImage src={user['imageUri']} profileColor={user['profileColor']} />
      <Caption2 style={{ marginLeft: '8px' }} color="#242424">
        {user['name']}
      </Caption2>
    </Flex>
  )
}

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const House = ({ house }) => {
  return (
    <Flex align="center">
      <HouseImage src={STORAGE_URL + house['imageUri']} />
      <Caption2 style={{ marginLeft: '8px' }} color="#242424">
        {house['title']}
      </Caption2>
    </Flex>
  )
}

const HouseImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 6px;
`
