import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { mobile } from '@styles/media'
import { Caption1, Div, H6 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const fadeIn1 = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const ModalOverlay = styled.div`
  animation: ${fadeIn1} 0.35s linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100vw;
  height: 100vh;
  /* -webkit-backdrop-filter: blur(24.1px); */
  /* backdrop-filter: blur(24.1px); */
  /* background-color: rgba(36, 36, 36, 0.4); */
  background-color: rgba(36, 36, 36, 0.5);
  min-width: 300px;
`

const Modal = styled.div`
  animation: ${fadeIn1} 0.35s linear;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8889;
  border-radius: 16px;
  /* overflow: scroll; */
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;

  background-color: ${colors.white_ff};
  border-radius: 16px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const ConfirmBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: #5b49f3;
  font-family: NotoSansCJKkr-Regular;
  color: #ffffff;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onClose,
  body,
  dynamicLink = 'https://app.hreum.me/stores',
}) => {
  const getBody = () => {
    if (body) {
      return body
    }
    return (
      <Div display="flex" flexDirection="column" flex="1" marginLeft="16px">
        <Caption1
          color={colors.grey_64}
          align="left"
          style={{ marginBottom: 2 }}
        >
          흐름 HREUM
        </Caption1>
        <H6 align="left">
          앱에서 쉽고 편하게
          <br />
더 많은 기능을 이용해보세요
        </H6>
      </Div>
    )
  }
  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay
          onClick={
            () => {
              if (onClose) {
                onClose()
              }
              else {
                toggle()
              }
            }
          }
        />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <Div
                display="flex"
                width="100%"
                marginBottom="14px"
                padding="10px"
              >
                <img
                  src={images.salon_favicon_img}
                  alt="salon_logo"
                  style={{ width: 64, height: 64 }}
                />
                {getBody()}
              </Div>
              <ConfirmBtn onClick={() => (window.location = dynamicLink)}>
                <H6>앱으로 보기</H6>
              </ConfirmBtn>
            </MobdlBody>
            <H6
              type="Regular"
              color={colors.white_f4}
              style={{ marginTop: 14 }}
              onClick={
                () => {
                  if (onClose) {
                    onClose()
                  }
                  else {
                    toggle()
                  }
                }
              }
            >
                괜찮아요, 모바일웹으로 볼게요
            </H6>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
