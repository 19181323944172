import { Flex, H5, Caption1 } from '@components/atoms'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { SalonCard } from '..'
import ReserveSalonCard from '../card/ReserveSalonCard'

const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  max-width: 1607px;
  width: 100%;
`

const HomeSalonSection = ({ active = false }) => {
  const { userStore, liveRoomStore } = useStore()

  const list = liveRoomStore.liveRoomList || []
  const nextCursor = liveRoomStore.liveRoomListNextCursor || null

  const fetchLiveRoomList = liveRoomStore.fetchLiveRoomList || (() => {})

  const firstReserveLiveRoomIdx = list?.findIndex(
    liveRoom => liveRoom['isReserved'] && !liveRoom['isOpened'],
  )

  // useEffect(() => {
  //   if (active) {
  //     liveRoomStore.fetchLiveRoomList({ limit: 10 })
  //   }
  //   else {
  //     liveRoomStore.fetchLiveRoomList({ limit: 4, isOnlyOpened: true })
  //   }
  // }, [active, fetchLiveRoomList])

  /** test */

  const handleFetchHomeRecommendation = useCallback(
    async ({ __limit, init = false, isOnlyOpened }) => {
      await fetchLiveRoomList({
        limit: __limit,
        isReset: init,
        isOnlyOpened: isOnlyOpened || false,
      })
    },
    [fetchLiveRoomList],
  )

  useEffect(() => {
    if (active) {
      handleFetchHomeRecommendation({ __limit: 20, init: true })
    }
    else {
      handleFetchHomeRecommendation({ __limit: 8, init: true, isOnlyOpened: true })
    }
  }, [active])

  return (
    <Flex
      type="column"
      style={
        {
          marginTop: 20,
          maxWidth: 1704,
          width: '100%',
        }
      }
    >
      {
        list && (
          <>
            <ListHeader>
              <H5 type="Bold" align="left">
              살롱 | 라이브 오디오
              </H5>
              <Link to="/p/salon/list">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더보기
                </Caption1>
              </Link>
            </ListHeader>

            <InfiniteScroll
              style={{ overflowX: 'hidden' }}
              dataLength={list.length}
              scrollThreshold={0.7}
              next={() => handleFetchHomeRecommendation({ __limit: 20 })}
              hasMore={active && nextCursor}
              scrollableTarget="scrollableDiv"
              hasChildren
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
            >
              <List active={active}>
                {
                  list
                && list.map((room, index) => {
                  if (room['isReserved'] && !room['isOpened']) {
                    if (index === firstReserveLiveRoomIdx) {
                      // 첫번째 예약살롱일 때, 줄바꿈 div 삽입
                      return (
                        <>
                          <div style={{ width: '100%' }} />
                          <ReserveSalonCard
                            salon={room}
                            key={`reserve_${room.id}`}
                            // type="list"
                            type="reservationList"
                          />
                        </>
                      )
                    }
                    return (
                      <ReserveSalonCard
                        salon={room}
                        key={`reserve_${room.id}`}
                        // type="list"
                        type="reservationList"
                      />
                    )
                  }
                  return <SalonCard item={room} key={room.id} />
                })
                }
              </List>
            </InfiniteScroll>
          </>
        )
      }
    </Flex>
  )
}

export default observer(HomeSalonSection)

const List = styled(Flex)`
  margin: 0 0 24px 0;
  padding: 24px 0 24px 0;
  flex-wrap: wrap;
  width: calc(100% + 39px);
  max-height: ${props => props.active ? undefined : '456px'};
  overflow: hidden;

  gap: 24px;

  @media ${mobile} {
    justify-content: center;
  }
`

// users/recommedations 로직 개발되면?

// import { Flex, H5, Caption1 } from '@components/atoms'
// import { colors } from '@resources/colors'
// import { mobile } from '@styles/media'
// import { Link } from 'react-router-dom'
// import React, { useCallback, useEffect, useState } from 'react'
// import styled from 'styled-components'
// import { observer } from 'mobx-react'
// import { useStore } from '@utils/hooks'
// import InfiniteScroll from 'react-infinite-scroll-component'
// import ReactLoading from 'react-loading'
// import { SalonCard } from '..'
// import ReserveSalonCard from '../card/ReserveSalonCard'

// const ListHeader = styled(Flex)`
//   justify-content: space-between;
//   align-items: center;
//   max-width: 1607px;
//   width: 100%;
// `

// const HomeSalonSection = ({ active = false }) => {
//   const { userStore } = useStore()

//   const fetchHomeRecommendation =
//     userStore.fetchHomeRecommendation || (() => {})

//   const [list, setList] = useState([])
//   const [nextCursor, setNextCursor] = useState(null)

//   const firstReserveLiveRoomIdx = list?.findIndex(
//     liveRoom => liveRoom['isReserved'] && !liveRoom['isOpened'],
//   )

//   const handleFetchHomeRecommendation = useCallback(
//     async ({ __limit, init = false }) => {
//       const { contentList, __nextCursor } = await fetchHomeRecommendation({
//         __nextCursor: init ? null : nextCursor,
//         contentTableName: 'LiveRooms',
//         __limit,
//       })

//       if (init) {
//         setList(contentList)
//       }
//       else {
//         setList(prev => prev.concat(contentList))
//       }
//       setNextCursor(__nextCursor)
//     },
//     [fetchHomeRecommendation, setList, setNextCursor, nextCursor],
//   )

//   useEffect(() => {
//     if (active) {
//       handleFetchHomeRecommendation({ __limit: 20 })
//     }
//     else {
//       handleFetchHomeRecommendation({ __limit: 4, init: true })
//     }
//   }, [active])

//   return (
//     <Flex
//       type="column"
//       style={
//         {
//           marginTop: 20,
//           maxWidth: 1704,
//           width: '100%',
//         }
//       }
//     >
//       {
//         list && (
//           <>
//             <ListHeader>
//               <H5 type="Bold" align="left">
//               살롱 | 라이브 오디오
//               </H5>
//               <Link to="/p/salon/list">
//                 <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
//                 더보기
//                 </Caption1>
//               </Link>
//             </ListHeader>

//             <InfiniteScroll
//               style={{ overflowX: 'hidden' }}
//               dataLength={list.length}
//               scrollThreshold={0.7}
//               next={() => handleFetchHomeRecommendation({ __limit: 20 })}
//               hasMore={active && nextCursor}
//               scrollableTarget="scrollableDiv"
//               hasChildren
//               loader={
//                 <ReactLoading
//                   type="spin"
//                   color="black"
//                   style={
//                     {
//                       width: '50px',
//                       height: '50px',
//                       margin: '0 auto 50px auto',
//                     }
//                   }
//                 />
//               }
//             >
//               <List>
//                 {
//                   list
//                 && list.map((room, index) => {
//                   if (room['isReserved'] && !room['isOpened']) {
//                     if (index === firstReserveLiveRoomIdx) {
//                       // 첫번째 예약살롱일 때, 줄바꿈 div 삽입
//                       return (
//                         <>
//                           <div style={{ width: '100%' }} />
//                           <ReserveSalonCard
//                             salon={room}
//                             key={`reserve_${room.id}`}
//                             // type="list"
//                             type="reservationList"
//                           />
//                         </>
//                       )
//                     }
//                     return (
//                       <ReserveSalonCard
//                         salon={room}
//                         key={`reserve_${room.id}`}
//                         // type="list"
//                         type="reservationList"
//                       />
//                     )
//                   }
//                   return <SalonCard item={room} key={room.id} />
//                 })
//                 }
//               </List>
//             </InfiniteScroll>
//           </>
//         )
//       }
//     </Flex>
//   )
// }

// export default observer(HomeSalonSection)

// const List = styled(Flex)`
//   padding: 24px 0 48px 0;
//   flex-wrap: wrap;
//   width: calc(100% + 39px);

//   gap: 24px;

//   @media ${mobile} {
//     justify-content: center;
//   }
// `
