import React from 'react'
import styled from 'styled-components'
import { images } from '@resources/'
import { mobileDesktop } from '@styles/media'

const SocialButton = styled.img.attrs(props => ({
  src: props.src,
  alt: props.alt,
}))`
  width: 100%;
  height: 100%;
`

const SocialButtonLink = styled.a.attrs(props => ({
  href: props.href,
  target: props.target,
}))`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;

  @media ${mobileDesktop} {
    margin-right: 20px;
    margin-left: 0;
  }
`

const SocialLinkBox = ({ children, href, target }) => {
  return (
    <div>
      <SocialButtonLink href="https://blog.naver.com/hreums" target="_blank">
        <SocialButton src={images.social_naver} alt="Social Naver Button" />
      </SocialButtonLink>
      <SocialButtonLink
        href="https://www.youtube.com/channel/UCh-Ve_0dDvwWYXEd1on3psg"
        target="_blank"
      >
        <SocialButton src={images.social_youtube} alt="Social Youtube Button" />
      </SocialButtonLink>
      <SocialButtonLink href="https://www.facebook.com/HREUM/" target="_blank">
        <SocialButton
          src={images.social_facebook}
          alt="Social Facebook Button"
        />
      </SocialButtonLink>
      <SocialButtonLink
        href="https://www.instagram.com/hreum_official/"
        target="_blank"
      >
        <SocialButton
          src={images.social_instagram}
          alt="Social Instagram Button"
        />
      </SocialButtonLink>
    </div>
  )
}

export default SocialLinkBox
