export const images = {
  live_room_recording_default_img: require('./live_room_recording_default_img.png'),
  radio_select_img: require('./radio_select_img.png'),
  radio_unselect_img: require('./radio_unselect_img.png'),
  sandwatch_img: require('./sandwatch_img.png'),
  check_img: require('./check_img.png'),
  unCheck_img: require('./unCheck_img.png'),
  green_check_img: require('./green_check_img.png'),
  pdf_file_img: require('./pdf_file_img.png'),
  play_black_img: require('./play_black_img.png'),
  play_black2_img: require('./play_black2_img.png'),
  like_black_img: require('./like_black_img.png'),

  plus_white_img: require('./plus_white_img.png'),
  plus_main_img: require('./plus_main_img.png'),
  plus_gray_img: require('./plus_gray_img.png'),

  check_white_img: require('./check_white_img.png'),
  remove_red_img: require('./remove_red_img.png'),

  onboard_img_1: require('./onboard_img_1.png'),
  onboard_img_2: require('./onboard_img_2.png'),
  onboard_img_3: require('./onboard_img_3.png'),
  onboard_img_4: require('./onboard_img_4.png'),

  user: require('./default_profile.png'),
  mixtape: require('./mixtape.png'),
  donation_star_icon: require('./donation_star_icon.png'),

  social_facebook: require('./social_facebook.png'),
  social_youtube: require('./social_youtube.png'),
  social_naver: require('./social_naver.png'),
  social_instagram: require('./social_instagram.png'),

  move_img: require('./move_img.png'),
  caution_img: require('./caution_img.png'),
  caution_img_red: require('./caution_img_red.png'),
  move_black_img: require('./move_black_img.png'),
  prev_black_img: require('./prev_black_img.png'),
  move_default_img: require('./move_default_img.png'),
  prev_default_img: require('./prev_default_img.png'),
  search_white_btn_img: require('./search_white_btn_img.png'),
  search_gray_btn_img: require('./search_gray_btn_img.png'),

  check_empty_img: require('./check_empty_img.png'),
  check_blue_img: require('./check_blue_img.png'),
  check_purple_img: require('./check_purple_img.webp'),
  default_song_img: require('./default_song_img.png'),
  default_profile_img: require('./default_profile_img.png'),
  play_song_img: require('./play_song_img.png'),
  default_profile: require('./default_profile.png'),
  circle_close_btn_img: require('./circle_close_btn_img.png'),
  mixtape_play_btn_img: require('./mixtape_play_btn_img.png'),
  mixtape_play_btn_black_img: require('./mixtape_play_btn_black_img.png'),
  mixtape_pause_btn_black_img: require('./mixtape_pause_btn_black_img.png'),

  move_down_gray_img: require('./move_down_gray_img.png'),
  move_down_white_img: require('./move_down_white_img.png'),
  move_up_white_img: require('./move_up_white_img.png'),
  move_up_gray_img: require('./move_up_gray_img.png'),

  comment_default_img: require('./comment_default_img.png'),
  like_red_img: require('./like_red_img.png'),
  like_default_img: require('./like_default_img.png'),
  like_white_default_img: require('./like_white_default_img.png'),
  like_white_img: require('./like_white_img.png'),
  triangle_gray_img: require('./triangle_gray_img.png'),

  live_img: require('./live_img.png'),
  live_white_img: require('./live_white_img.png'),
  cast_orange_img: require('./cast_orange_img.png'),

  play_btn_img: require('./play_btn_img.png'),
  pause_btn_img: require('./pause_btn_img.png'),
  volume_btn_img: require('./volume_btn_img.png'),
  volume_mute_img: require('./volume_mute_img.png'),
  volume_btn_img_black: require('./volume_btn_img_black.png'),
  volume_mute_img_gray: require('./volume_mute_img_gray.png'),
  volume_btn_img_gray: require('./volume_btn_img_gray.png'),

  temp_log: require('./temp_log.png'),

  three_dot_horizontal_img: require('./three_dot_horizontal_img.png'),
  three_dot_white_img: require('./three_dot_white_img.png'),
  three_dot_black: require('./three_dot_black.png'),

  fullscreen_btn_img: require('./fullscreen_btn_img.png'),
  miniscreen_btn_img: require('./miniscreen_btn_img.png'),

  x_btn_img: require('./x_btn_img.png'),

  dnd_img: require('./dnd_img.png'),
  dnd_img_white: require('./dnd_img_white.png'),

  // 캐스트 리스트 정보 아이콘
  play_gray_img: require('./play_gray_img.png'),
  play_gray_d4_img: require('./play_gray_d4_img.png'),
  like_gray_img: require('./like_gray_img.png'),
  youtube_img: require('./youtube_img.png'),
  rss_img: require('./rss_img.png'),

  sidebar_inactive_home: require('./sidebar/sidebar_inactive_home.png'),
  sidebar_inactive_mixtapes: require('./sidebar/sidebar_inactive_mixtapes.png'),
  sidebar_inactive_plan: require('./sidebar/sidebar_inactive_plan.png'),
  sidebar_inactive_notice: require('./sidebar/sidebar_inactive_notice.png'),
  sidebar_inactive_faq: require('./sidebar/sidebar_inactive_faq.png'),
  sidebar_inactive_service: require('./sidebar/sidebar_inactive_service.png'),
  sidebar_inactive_download: require('./sidebar/sidebar_inactive_download.png'),
  sidebar_inactive_upload: require('./sidebar/sidebar_inactive_download.png'),
  sidebar_inactive_star: require('./sidebar/sidebar_inactive_star.png'),
  sidebar_inactive_salon: require('./sidebar/sidebar_inactive_salon.png'),
  sidebar_inactive_cast: require('./sidebar/sidebar_inactive_cast.png'),

  sidebar_active_home: require('./sidebar/sidebar_active_home.png'),
  sidebar_active_mixtapes: require('./sidebar/sidebar_active_mixtapes.png'),
  sidebar_active_plan: require('./sidebar/sidebar_active_plan.png'),
  sidebar_active_notice: require('./sidebar/sidebar_active_notice.png'),
  sidebar_active_faq: require('./sidebar/sidebar_active_faq.png'),
  sidebar_active_service: require('./sidebar/sidebar_active_service.png'),
  sidebar_active_download: require('./sidebar/sidebar_active_download.png'),
  sidebar_active_upload: require('./sidebar/sidebar_active_download.png'),
  sidebar_active_star: require('./sidebar/sidebar_active_star.png'),
  sidebar_active_salon: require('./sidebar/sidebar_active_salon.png'),
  sidebar_active_cast: require('./sidebar/sidebar_active_cast.png'),

  copy_link_img_white: require('./copy_link_img_white.png'),
  copy_link_img_gray: require('./copy_link_img_gray.png'),
  copy_link_img_black: require('./copy_link_img_black.png'),
  app_qrcode_img: require('./app_qrcode_img.png'),
  app_download_background_img: require('./app_download_background_img.png'),
  camera_img_white: require('./camera_img_white.png'),

  /**
   * salon
   */
  salon_logo: require('./salon_logo.png'),
  burger_img: require('./burger_img.png'),
  salon_host_img: require('./salon_host_img.png'),
  salon_mute_mic_img: require('./salon_mute_mic_img.png'),
  salon_chat_send_img: require('./salon_chat_send_img.png'),
  present_img: require('./present_img.png'),
  salon_listener_img: require('./salon_listener_img.png'),
  salon_star_img: require('./salon_star_img.png'),
  salon_50star_img: require('./salon_50star_img.png'),
  salon_100star_img: require('./salon_100star_img.png'),
  salon_1000star_img: require('./salon_1000star_img.png'),
  salon_first_star: require('./salon_first_star.png'),
  salon_second_star: require('./salon_second_star.png'),
  salon_third_star: require('./salon_third_star.png'),
  salon_rec_inactive_img: require('./salon/salon_rec_inactive_img.webp'),
  salon_rec_active_img: require('./salon/salon_rec_active_img.webp'),
  salon_type_public: require('./salon/salon_type_public.png'),
  salon_type_private: require('./salon/salon_type_private.png'),
  salon_type_member: require('./salon/salon_type_member.png'),
  salon_type_social: require('./salon/salon_type_social.png'),

  house_type_member_icon: require('./house_type_member_icon.png'),
  house_type_member_icon_yellow: require('./house_type_member_icon_yellow.png'),

  select_down_gray: require('./select_down_gray.png'),
  share_web_img: require('./share_web_img.png'),
  share_img_black: require('./share_img_black.png'),
  share_img_white: require('./share_img_white.png'),

  bio_email_img: require('./bio_email_img.png'),
  bio_youtube_img: require('./bio_youtube_img.png'),
  bio_instagram_img: require('./bio_instagram_img.png'),

  badge_babydj_img: require('./badge_babydj_img.png'),
  badge_dj_img: require('./badge_dj_img.png'),
  badge_partnerdj_img: require('./badge_partnerdj_img.png'),
  badge_official_img: require('./badge_official_img.png'),

  tmp_salon_mobile_view: require('./tmp_salon_mobile_view.png'),
  hreum_salon_logo_img: require('./hreum_salon_logo_img.png'),
  salon_favicon_img: require('./salon_favicon_img.png'),

  close_button_img: require('./close_button_img.png'),
  three_dot_img: require('./three_dot.png'),
  plus_btn_img: require('./plus_btn_img.png'),
  upload_img: require('./upload_img.png'),
  clip_hreum_img: require('./clip_hreum_img.png'),

  money_img: require('./money_img.png'),
  question_mark: require('./question_mark.png'),

  kakao_login_btn_img: require('./kakao_login_btn_img.png'),
  google_down_img: require('./google_down_img.png'),
  apple_down_img: require('./apple_down_img.png'),
  hreum_logo: require('./hreum_logo.png'),
  mixtape_vol_img_white: require('./mixtape_vol_img_white.png'),
  mixtape_mute_img_white: require('./mixtape_mute_img_white.png'),
  salon_require_guest_img: require('./salon_require_guest_img.png'),
  mic_vol_img_white: require('./mic_vol_img_white.png'),
  mic_mute_img_white: require('./mic_mute_img_white.png'),
  request_list_img: require('./request_list_img.png'),
  heart_img_red_12: require('./heart_img_red_12.png'),
  heart_img_red_24: require('./heart_img_red_24.webp'),
  broadcast_img_white: require('./broadcast_img_white.png'),
  imgae_upload_img_gray: require('./imgae_upload_img_gray.png'),
  circle_close_btn_img_gray: require('./circle_close_btn_img_gray.png'),
  circle_close_btn_img_trans: require('./circle_close_btn_img_trans.png'),
  all_mixtape_btn_img: require('./all_mixtape_btn_img.png'),
  all_mixtape_mute_img: require('./all_mixtape_mute_img.png'),
  lock_img_red: require('./lock_img_red.png'),
  lock_img_white: require('./lock_img_white.png'),
  alram_img_gray: require('./alram_img_gray.png'),
  alram_img_blue: require('./alram_img_blue.png'),
  salon_public_img: require('./salon_public_img.png'),
  heart_img_gray_default: require('./heart_img_gray_default.png'),
  heart_img_black_default: require('./heart_img_black_default.png'),
  salon_mobile_view: require('./salon_mobile_view.png'),
  comment_pin_img: require('./comment_pin_img.png'),
  ad_hreum_plan_img: require('./ad_hreum_plan_img.png'),
  trash_img_white: require('./trash_img_white.png'),
  prev_15_sec_img: require('./prev_15_sec_img.png'),
  next_15_sec_img: require('./next_15_sec_img.png'),

  house_icon_img: require('./house_icon_img.png'),
  house_member_icon_img: require('./house_member_icon_img.png'),
  house_follower_icon_img: require('./house_follower_icon_img.png'),
  mixtape_add_img: require('./mixtape_add_img.png'),

  people_img_black: require('./people_img_black.png'),
  alarm_img_black: require('./alarm_img_black.png'),
  forbidden_img_black: require('./forbidden_img_black.png'),

  mic_img_white: require('./mic_img_white.png'),
  person_img_white: require('./person_img_white.png'),
  mic_img_gray: require('./mic_img_gray.png'),
  person_img_gray: require('./person_img_gray.png'),

  image_list_img_white: require('./image_list_img_white.png'),
  qr_icon_img: require('./qr_icon_img.png'),
  cast_icon_img_white: require('./cast_icon_img_white.png'),
}
