import React from 'react'
import { Div, Caption1 } from '@components/atoms'
import { colors } from '@resources/colors'
import { observer } from 'mobx-react'
import { useStore } from './hooks'

const Toasts = () => {
  const { appStateStore } = useStore()

  const toastState = appStateStore.toastState || []

  return (
    <>
      {
        toastState.map(toast => {
          if (toast) {
            return (
              <Div
                key={toast.hash}
                display="flex"
                borderRadius="6px"
                background="rgba(36, 36, 36)"
                width="340px"
                justifyContent="center"
                alignItems="center"
                transform="translate(-50%)"
                position="fixed"
                zIndex={toast.option?.zIndex ? toast.option?.zIndex : 1000}
                top={toast.option?.bottom ? `${toast.option?.bottom}px` : '70px'}
                bottom={toast.option?.top ? `${toast.option?.top}px` : 'auto'}
                left="50%"
                backdropFilter="blur(15px)"
                whiteSpace="pre"
                animation={
                  `${(toast.option?.expires ?? 3000)
                / 1000}s linear toastFadeInOut forwards`
                }
                pointerEvents="none"
              >
                <Caption1
                  color={colors.white_ff}
                  style={
                    {
                      textAlign: 12,
                      margin: '12px 20px',
                    }
                  }
                >
                  {toast.msg}
                </Caption1>
              </Div>
            )
          }
          return null
        })
      }
    </>
  )
}

export default observer(Toasts)
