import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Flex, FormInput, Button, Caption1 } from '@components/atoms'
import styled, { css } from 'styled-components'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { useObserver } from 'mobx-react'
import { useLocation, useHistory } from 'react-router-dom'
import { mobile } from '@styles/media'

const Area = styled(Flex)`
  max-width: 100%;
  width: 100%;
  height: 88px;
  position: sticky;
  top: 56px;
  background-color: ${colors.white_ff};
  z-index: 4;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
`

const Box = styled(Flex)`
  width: 100%;
  margin: 24px auto 0 auto;
  height: 48px;
  border-radius: 24px;
  background-color: ${colors.white_f4};
  align-items: center;
  justify-content: space-around;
  z-index: 10;

  @media ${mobile} {
    min-width: auto;
  }
`

const SearchInput = styled(FormInput)`
  border: none;
  background-color: transparent;
  padding: 12px 16px;
  font-size: 16px;
  font-family: NotoSansCJKkr-Regular;
`

const SearchBtn = styled(Button)`
  width: 72px;
  height: 36px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(25, 130, 223, 0.16);
  background-color: ${colors.primary};
  margin-right: 6px;
`

const AutoCompleteList = styled(Flex)`
  position: absolute;
  flex-direction: column;
  top: 78px;
  /* left: 200px; */
  left: 0px;
  width: 100%;
  height: 404px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
  padding: 20px;
  overflow: hidden;
  z-index: 11;

  > div {
    padding: 8px 2px;
  }

  /* > div:first-child {
    padding-top: 0;
  } */
`

const AutoCompleteItem = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  ${props =>
    props.focus
    && css`
      background-color: #d4d4d4;
    `}
`

const CastSearchBar = ({ style, setHaveMore, setOffset }) => {
  const { searchStore } = useStore()
  const mixtapeSearchKeyword = searchStore.mixtapeSearchKeyword || null
  const mixtapeSearchHistory = searchStore.mixtapeSearchHistory || null

  const setMixtapeSearchKeyword =
    searchStore.setMixtapeSearchKeyword || (() => {})
  const addMixtapeSearchHistory =
    searchStore.addMixtapeSearchHistory || (() => {})
  const deleteMixtapeSearchHistory =
    searchStore.deleteMixtapeSearchHistory || (() => {})
  /* store end */

  const location = useLocation()
  const history = useHistory()

  // 검색 내역 스크롤 로직
  const historyRef = useRef(null)
  let historyNode
  let historyCurrentScroll
  if (historyRef.current) historyNode = ReactDOM.findDOMNode(historyRef.current)
  if (historyNode) historyCurrentScroll = historyNode.scrollTop
  // 검색 내역 스크롤 로직 end

  const [keyword, setKeyword] = useState('')
  const [isFocus, setIsFocus] = useState(false) // 검색창 포커스 유무

  const [cursorIndex, setCursorIndex] = useState(-1) // 검색 내역 커서

  const handleSearch = async _keyword => {
    let keyword = ''
    if (!_keyword && cursorIndex === -1) return

    keyword = _keyword

    if (cursorIndex !== -1) {
      if (_keyword !== '') {
        keyword = searchStore.autoCompleteKeywordList[cursorIndex]
      }
      else {
        keyword = mixtapeSearchHistory[cursorIndex]
      }
    }

    setHaveMore && setHaveMore(true)
    setOffset && setOffset(0)
    await setMixtapeSearchKeyword(keyword)
    await addMixtapeSearchHistory(keyword)

    // 과거의 이해훈이 플라 검색 로직에서 왜 이것을 여기서 한번, 페이지가서 한번 하였는지 의문...
    // await fetchTagKeywordList()
    // await fetchSearchCastList(keyword)
    setIsFocus(false)
    history.push(`/p/cast/list/search?keyword=${keyword}`)
  }

  useEffect(() => {
    // 검색 결과 화면일 때, 검색했던 검색어 가져오기
    if (location.pathname === '/p/cast/list/search' && mixtapeSearchKeyword) {
      setKeyword(mixtapeSearchKeyword)
    }
  }, [location, mixtapeSearchKeyword])

  return useObserver(() => (
    <Area style={style}>
      {isFocus && <Overlay onClick={() => setIsFocus(false)} />}
      <Box>
        <SearchInput
          placeholder="캐스트 제목, 설명, DJ, 태그 등"
          value={keyword}
          onChange={
            e => {
              setCursorIndex(-1)
              setKeyword(e.target.value)
              setIsFocus(true)
              searchStore.fetchAutoCompleteKeywordCastList(e.target.value)
            }
          }
          // onKeyDown={e => e.keyCode === 13 && handleSearch(keyword)}
          onFocus={() => setIsFocus(true)}
          onKeyDown={
            e => {
              if (keyword !== '') {
                if (e.keyCode === 40) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState < searchStore.autoCompleteKeywordList.length - 1
                      ? prevState + 1
                      : prevState,
                  )
                }

                if (e.keyCode === 38) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState === 0 ? prevState : prevState - 1,
                  )
                }
              }
              else {
                if (e.keyCode === 40) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState < mixtapeSearchHistory.length - 1
                      ? prevState + 1
                      : prevState,
                  )
                  if (cursorIndex > 8) {
                    historyNode.scrollTo(0, historyCurrentScroll + 54)
                  }
                }
                if (e.keyCode === 38) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState === 0 ? prevState : prevState - 1,
                  )
                  if (cursorIndex < mixtapeSearchHistory.length - 8) {
                    historyNode.scrollTo(0, historyCurrentScroll - 54)
                  }
                }
              }

              if (e.key === 'Enter') {
                handleSearch(keyword)
              }
            }
          }
        />
        <SearchBtn onClick={() => handleSearch(keyword)}>
          <img
            src={images.search_white_btn_img}
            alt="search_btn"
            style={{ width: '24px', height: '24px' }}
          />
        </SearchBtn>
      </Box>
      {
        isFocus
        && (keyword === '' ? (
          <AutoCompleteList style={{ overflow: 'auto' }} ref={historyRef}>
            {
              mixtapeSearchHistory.length > 0 ? (
                mixtapeSearchHistory.map((word, index) => (
                  <AutoCompleteItem
                    key={`${keyword}_${index}`}
                    onClick={() => handleSearch(word)}
                    focus={cursorIndex === index}
                  >
                    <Caption1>{word}</Caption1>
                    <img
                      src={images.circle_close_btn_img}
                      alt="remove_btn"
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                      onClick={
                        e => {
                          e.stopPropagation()
                          deleteMixtapeSearchHistory(index)
                        }
                      }
                    />
                  </AutoCompleteItem>
                ))
              ) : (
                <AutoCompleteItem>검색 내역이 없습니다.</AutoCompleteItem>
              )
            }
          </AutoCompleteList>
        ) : (
          <AutoCompleteList>
            {
              searchStore.autoCompleteKeywordList
            && searchStore.autoCompleteKeywordList.length !== 0 ? (
                  searchStore.autoCompleteKeywordList.map((item, index) => (
                    <AutoCompleteItem
                      key={item}
                      onClick={() => handleSearch(item)}
                      focus={cursorIndex === index}
                    >
                      <Flex>
                        <img
                          src={images.search_gray_btn_img}
                          alt="search_img"
                          style={
                            {
                              width: '20px',
                              height: '20px',
                              marginRight: '8px',
                            }
                          }
                        />
                        <Caption1 type="Regular">{item}</Caption1>
                      </Flex>
                    </AutoCompleteItem>
                  ))
                ) : (
                  <AutoCompleteItem>검색 내역이 없습니다.</AutoCompleteItem>
                )
            }
          </AutoCompleteList>
        ))
      }
    </Area>
  ))
}

export default CastSearchBar
