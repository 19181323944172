import React from 'react'
import styled from 'styled-components'
import Button from '@components/atoms/button'
import { Link } from 'react-router-dom'
import { images } from '@resources/'
import { colors } from '@resources/colors'

const P = styled.p`
  font-family: NotoSansCJKkr-Medium;
  font-size: ${props => props.size}rem;
  color: ${props => props.color};
`
const Img = styled.img`
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;
  margin-top: 1px;
`
const MoveBtn = props => {
  const handleClick = e => {
    if (props.onClick) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      props.onClick(e)
    }
  }
  return (
    <Link to={props.href || '#'}>
      <Button
        onClick={handleClick}
        size={props.size}
        color={colors.secondary}
        style={props.style}
      >
        <P color={colors.secondary}>{props.text}</P>
        {
          !props.noImage && (
            <Img src={images.move_img} alt="nextBtn" size={props.size} />
          )
        }
      </Button>
    </Link>
  )
}
export default MoveBtn
