import React, { useEffect } from 'react'
import { MixtapeDetailTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { MixtapeCommentModal } from '@components/organisms'
import { isMobile } from 'react-device-detect'
import { Flex, H4 } from '@components/atoms'
import { MoveBtn } from '@components/molecules'
import { images } from '@resources/'

interface Props {
  location: Any;
  match: Any;
}

const MixtapeDetailPage = ({ location, match }: Props) => {
  useRenderLogger('MixtapeDetailPage')

  const {
    mixtapeStore,
    userStore,
    songStore,
    searchStore,
    commentStore,
    authStore,
  } = useStore()
  const history = useHistory()

  const {
    isShowing,
    toggle: handleToggleComment,
  } = MixtapeCommentModal.useModal()

  const toggleComment = () => {
    if (!authStore.currentUser) {
      authStore.tryAuth()
    }
    else {
      handleToggleComment()
    }
  }

  const handleSearchTag = async tag => {
    await searchStore.searchTagKeyword(tag)
    await searchStore.fetchTagLatestMixtapeList(tag)
    history.push(`/p/plra/list/tag?keyword=${tag}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    mixtapeStore.fetchMixtapeDetailFromLinkKey(match.params.linkKey)
      .then(() => {
        if(mixtapeStore.mixtapeDetail?.id) {
          commentStore.fetchTargetMixtapeReply(mixtapeStore.mixtapeDetail?.id)
        }
      })

    return () => {
      mixtapeStore.initDetail()
    }
  }, [match, mixtapeStore, commentStore])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  if (mixtapeStore.mixtapeDetail) {
    return (
      <>
        {
          !(
            !mixtapeStore.mixtapeDetail?.isPublished
          || mixtapeStore.mixtapeDetail?.deletedAt
          ) ? (
              <MixtapeDetailTemplate
                mixtape={mixtapeStore.mixtapeDetail || null}
                targetCommentList={commentStore.targetCommentList || []}
                likeMixtape={mixtapeStore.likeMixtape || (() => {})}
                dislikeMixtape={mixtapeStore.dislikeMixtape || (() => {})}
                follow={userStore.follow || (() => {})}
                unFollow={userStore.unFollow || (() => {})}
                likeSong={songStore.likeSong || (() => {})}
                disLikeSong={songStore.disLikeSong || (() => {})}
                category={
                  location.state
              && location.state.category
              && location.state.category
                }
                sort={location.state && location.state.sort && location.state.sort}
                handleSearchTag={handleSearchTag}
                toggleComment={toggleComment}
              />
            ) : (
              <Flex
                type="column"
                justify="center"
                align="center"
                style={{ width: '100%', height: '100vh' }}
              >
                <img
                  src={images.caution_img_red}
                  style={{ width: 64, height: 64 }}
                  alt="caution_icon"
                />
                <H4 style={{ margin: '24px 0 32px 0' }}>
              비공개되었거나 삭제된 플라입니다.
                </H4>
                <MoveBtn href="/" text="홈으로 가기" />
              </Flex>
            )
        }
        <MixtapeCommentModal.View
          toggle={toggleComment}
          isShowing={isShowing}
          targetId={
            mixtapeStore.mixtapeDetail && mixtapeStore.mixtapeDetail['id']
          }
        />
      </>
    )
  }
  return <div />
}

export default observer(MixtapeDetailPage)
