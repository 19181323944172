import React, { useEffect, useState } from 'react'
import { matchPath, Route, Switch, useLocation } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import { IS_DEV } from '@consts/'
import { AdHreumPlanPopup, AppInduceBar } from '@components/molecules'
import { isMobile } from 'react-device-detect'
import {
  PlanMyInfoPage,
  CancelPage,
  PaymentCompletePage,
  SubscriptionPage,
  ValidationStudentPage,
  CompleteRegisterPage,
  LoginPage,
  RegisterPage,
  TempCompletePage,
  TempPasswordPage,
  MixtapeListPage,
  UploadPage,
  CastUploadPage,
  CastEditPage,
  CastListPage,
  CastCategoryListPage,
  NeedAuthPage,
  ConfirmPasswordPage,
  RevenueInfoPage,
  RegisterPasswordPage,
  DjMixtapeListPage,
  DjMixtapeAddPage,
  AccountPrivacyPage,
  CastManagePage,
  HouseCastManagePage,
  AudioPage,
  SongDetailPage,
  MixtapeCategoryTotalListPage,
  MixtapeCategoryListPage,
  MixtapeDetailPage,
  FindEmailPage,
  FindPasswordPage,
  SettingWayPage,
  NeedAdultPage,
  AccountPage,
  NoticeListPage,
  NoticeDetailPage,
  FaqPage,
  SalonDetailPage,
  SalonListPage,
  SalonReservationListPage,
  StarMainPage,
  StarChargeFailPage,
  UserMyInfoPage,
  UserDetailPage,
  UserUpdatePage,
  DynamicPage,
  CastDetailPage,
  HouseDetailPage,
  SearchPage,
  HomePage,
  FeedDetailPage,
  TermsSample,
  PolicyTermsPage,
  PolicyPrivacyPage,
  PolicyMarketingPage,
  PolicyLocationPage,
} from './page'

import { InitializePage, Protected } from './utils'

import PlayerContainer from './components/organisms/PlayerContainer'
import SocialkingPage from './page/Socialking'
import SocialkingResultPage from './page/Socialking/[mbti]'
import SocialkingRankingPage from './page/Socialking/ranking'

// 라우트 정보
const routes = [
  // 홈
  {
    key: 'DynamicPage',
    exact: true,
    path: '/',
    component: DynamicPage,
  },
  // 흐름
  {
    key: 'MixtapeListPage',
    exact: true,
    path: '/p/plra/list',
    component: Protected(MixtapeListPage),
  },
  {
    key: 'MixtapeCategoryTotalListPage',
    exact: true,
    path: '/p/plra/list/total/guest',
    component: MixtapeCategoryTotalListPage,
  },
  {
    key: 'MixtapeCategoryListPage',
    exact: true,
    path: '/p/plra/list/:category',
    component: MixtapeCategoryListPage,
  },
  {
    key: 'MixtapeDetailPage',
    exact: true,
    path: '/plra/:linkKey',
    component: MixtapeDetailPage,
  },

  // Cast
  {
    key: 'CastManagePage',
    exact: true,
    path: '/p/cast/manage',
    component: Protected(CastManagePage),
  },
  {
    key: 'HouseCastManagePage',
    exact: true,
    path: '/p/cast-house/manage/:houseId',
    component: Protected(HouseCastManagePage),
  },
  {
    key: 'CastUploadPage',
    exact: true,
    path: '/p/cast/upload',
    component: Protected(CastUploadPage),
  },
  {
    key: 'CastEditPage',
    exact: true,
    path: '/p/cast/edit/:id',
    component: Protected(CastEditPage),
  },
  {
    key: 'CastListPage',
    exact: true,
    path: '/p/cast/list',
    component: CastListPage,
  },
  {
    key: 'CastCategoryListPage',
    exact: true,
    path: '/p/cast/list/:category',
    component: CastCategoryListPage,
  },
  {
    key: 'CastDetailPage',
    exact: true,
    path: '/cast/:linkKey',
    component: CastDetailPage,
  },

  // 오디오
  {
    key: 'AudioPage',
    exact: true,
    path: '/p/audio',
    component: Protected(AudioPage),
  },
  {
    key: 'SongDetailPage',
    exact: true,
    path: '/p/dj/song/edit',
    component: Protected(SongDetailPage),
  },
  {
    key: 'UploadPage',
    exact: true,
    path: '/p/dj/song/upload',
    component: Protected(UploadPage),
  },

  // 회원
  {
    key: 'LoginPage',
    exact: true,
    path: '/p/login',
    component: LoginPage,
  },
  {
    key: 'RegisterPage',
    exact: true,
    path: '/p/register',
    component: RegisterPage,
  },
  {
    key: 'AccountPrivacyPage',
    exact: true,
    path: '/p/register/privacy',
    component: AccountPrivacyPage,
  },
  {
    key: 'CompleteRegisterPage',
    exact: true,
    path: '/p/register/complete',
    component: CompleteRegisterPage,
  },
  {
    key: 'TempPasswordPage',
    exact: true,
    path: '/p/find/temp-password',
    component: TempPasswordPage,
  },
  {
    key: 'TempCompletePage',
    exact: true,
    path: '/p/find/temp-complete',
    component: TempCompletePage,
  },
  {
    key: 'FindEmailPage',
    exact: true,
    path: '/p/find/email',
    component: FindEmailPage,
  },
  {
    key: 'FindPasswordPage',
    exact: true,
    path: '/p/find/password',
    component: FindPasswordPage,
  },
  {
    key: 'RegisterPasswordPage',
    exact: true,
    path: '/p/register/password',
    component: Protected(RegisterPasswordPage),
  },
  // 구독
  {
    key: 'PlanMyInfoPage',
    exact: true,
    path: '/p/plan',
    component: Protected(PlanMyInfoPage),
  },
  // {
  //   key: 'SubscriptionPage',
  //   exact: true,
  //   path: '/p/plan/subscribe',
  //   component: Protected(SubscriptionPage),
  // },
  {
    key: 'CancelPage',
    exact: true,
    path: '/p/plan/cancel',
    component: Protected(CancelPage),
  },
  {
    key: 'PaymentCompletePage',
    exact: true,
    path: '/p/plan/payment/complete',
    component: Protected(PaymentCompletePage),
  },
  {
    key: 'ValidationStudentPage',
    exact: true,
    path: '/p/plan/student/validation',
    component: Protected(ValidationStudentPage),
  },
  // 포인트 환전
  {
    key: 'NeedAuthPage',
    exact: true,
    path: '/p/exchange/need-auth',
    component: Protected(NeedAuthPage),
  },
  {
    key: 'ConfirmPasswordPage',
    exact: true,
    path: '/p/exchange/confirm-password',
    component: Protected(ConfirmPasswordPage),
  },
  {
    key: 'RevenueInfoPage',
    exact: true,
    path: '/p/exchange/revenue/info',
    component: Protected(RevenueInfoPage),
  },
  {
    key: 'SettingWayPage',
    exact: true,
    path: '/p/exchange/setting-way',
    component: Protected(SettingWayPage),
  },
  {
    key: 'NeedAdultPage',
    exact: true,
    path: '/p/exchange/need-adult',
    component: Protected(NeedAdultPage),
  },
  {
    key: 'AccountPage',
    exact: true,
    path: '/p/exchange/account',
    component: Protected(AccountPage),
  },
  // DJ 흐름
  {
    key: 'DjMixtapeListPage',
    exact: true,
    path: '/p/dj/mixtape/list',
    component: Protected(DjMixtapeListPage),
  },
  {
    key: 'DjMixtapeAddPage[add]',
    exact: true,
    path: '/p/dj/mixtape/add',
    component: Protected(DjMixtapeAddPage),
  },
  {
    key: 'DjMixtapeAddPage[list]',
    exact: true,
    path: '/p/dj/mixtape/update',
    component: Protected(DjMixtapeAddPage),
  },
  // 공지 사항
  {
    key: 'NoticeListPage',
    exact: true,
    path: '/p/notice/list',
    component: NoticeListPage,
  },
  {
    key: 'NoticeDetailPage',
    exact: true,
    path: '/notice/:linkKey',
    component: NoticeDetailPage,
  },
  // 자주하는 질문
  {
    key: 'FaqPage',
    exact: true,
    path: '/p/faq',
    component: FaqPage,
  },
  // 살롱
  {
    key: 'SalonListPage',
    exact: true,
    path: '/p/salon/list',
    component: SalonListPage,
  },
  {
    key: 'SalonReservationListPage',
    exact: true,
    path: '/p/salon/reservationlist',
    component: SalonReservationListPage,
  },
  {
    key: 'SalonDetailPage',
    exact: true,
    path: '/salon/:linkKey',
    // component: Protected(SalonDetailPage),
    component: SalonDetailPage,
  },
  // 별
  {
    key: 'StarMainPage',
    exact: true,
    path: '/p/star/main',
    component: Protected(StarMainPage),
  },
  {
    key: 'StarChargeFailPage',
    exact: true,
    path: '/p/star/charge/fail',
    component: Protected(StarChargeFailPage),
  },
  // 유저
  {
    key: 'UserMyInfoPage',
    exact: true,
    path: '/p/user/myinfo',
    component: Protected(UserMyInfoPage),
  },
  {
    key: 'UserDetailPage',
    exact: true,
    path: '/user/:account',
    component: UserDetailPage,
  },
  {
    key: 'UserUpdatePage',
    exact: true,
    path: '/p/user/update',
    component: Protected(UserUpdatePage),
  },
  {
    key: 'HouseDetailPage',
    exact: true,
    path: '/house/:linkKey',
    component: HouseDetailPage,
  },
  {
    key: 'FeedDetailPage',
    exact: true,
    path: '/feed/:linkKey',
    component: FeedDetailPage,
  },
  {
    key: 'SearchPage',
    exact: true,
    path: '/p/search',
    component: SearchPage,
  },


  {
    key: 'SocialkingPage',
    exact: true,
    path: '/p/socialking',
    component: SocialkingPage,
  },
  {
    key: 'SocialkingRankingPage',
    exact: true,
    path: '/p/socialking/ranking',
    component: SocialkingRankingPage,
  },
  {
    key: 'SocialkingResultPage',
    exact: true,
    path: '/p/socialking/:mbti',
    component: SocialkingResultPage,
  },


  // 이용약관들
  {
    key: 'PolicyTermsPage',
    exact: true,
    path: '/p/policy/terms/',
    component: PolicyTermsPage,
  },
  {
    key: 'PolicyPrivacyPage',
    exact: true,
    path: '/p/policy/privacy/',
    component: PolicyPrivacyPage,
  },
  {
    key: 'PolicyMarketingPage',
    exact: true,
    path: '/p/policy/marketing/',
    component: PolicyMarketingPage,
  },
  {
    key: 'PolicyLocationPage',
    exact: true,
    path: '/p/policy/location/',
    component: PolicyLocationPage,
  },

  // 홈
  {
    key: 'DefaultPage',
    exact: true,
    path: '/*',
    component: HomePage,
  },

]

export default InitializePage(() => {
  // 페이지 로그
  const location = useLocation()
  React.useLayoutEffect(() => {
    const currentRoute = routes.find(route =>
      matchPath(location.pathname, route),
    )

    if (IS_DEV && currentRoute)
      console.log(
        `%c[DEV][LOG]%c[Routes][Current Routing Component] : %c${currentRoute.key}`,
        'color:red;',
        'color:white;',
        'color:#594ee9; font-size:12px;',
      )

    // 유저 첫 방문 시, 서비스 소개 페이지로 이동
    // 개발 모드 분기 처리
    // 흐름, 살롱 다이나믹 링크일 때, 분기 처리
    // if (
    //   !IS_DEV
    //   && !localStorage.getItem('hreum_first_visit')
    //   && !(
    //     location.search.includes('?liveRoomId=')
    //     || location.search.includes('?mixtapeId=')
    //     || location.search.includes('?castId=')
    //   )
    // ) {
    //   localStorage.setItem('hreum_first_visit', true)
    //   window.location = 'https://hreum.kr'
    // }

    // 모바일에서 웹 접근 시, 서비스 소개 페이지로 이동
    // 개발 모드 분기 처리
    // 흐름, 살롱 다이나믹 링크일 때, 분기 처리
    if (
      !IS_DEV
      && window.innerWidth <= 420
      && !(
        location.search.includes('liveRoomId=')
        || location.search.includes('mixtapeId=')
        || location.search.includes('castId=')
        || location?.pathname.includes('/socialking')
        || location?.pathname.includes('/p/policy')
      )
    ) {
      window.location = 'https://hreum.kr'
    }
  }, [location])

  const [isDisabledAppInduceBar, setIsDisabledAppInduceBar] = useState(false)
  useEffect(() => {
    if(
      location?.pathname.includes('/p/socialking')
    ) {
      setIsDisabledAppInduceBar(true)
    }
    return () => {}
  }, [])

  return (
    <LastLocationProvider>
      {
        !isMobile && (
          <>
            <PlayerContainer />
            {/* <AdHreumPlanPopup /> */}
          </>
        )
      }
      {!isDisabledAppInduceBar && <AppInduceBar />}
      <Switch>
        {
          routes.map(route => (
            <Route
              key={route.key}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))
        }
      </Switch>
    </LastLocationProvider>
  )
})
