import {
  Caption2,
  Caption3,
  FormInput,
} from '@components/atoms'
import { images } from '@resources/'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import {
  CastUploadTypeSelectPopup,
  YoutubeVideoCastUploadPopup,
  CastUploadRecordingPopup,
  ConfirmPopup,
} from '@components/molecules'
import { TagSelectModal } from '@components/organisms'
import 'react-pro-sidebar/dist/css/styles.css'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  CAST_UPLOAD_TYPE,
  YOUTUBE_UPLOAD_TYPE,
  DEFAULT_TAG_LIST,
  SUBJECT_TAG_LIST,
  NOT_ALLOWED_TAG_LIST,
  YOUTUBE_SYNC_ERROR,
  RSS_SYNC_ERROR,
} from '@consts/cast'
import { dateMDMS } from '@utils/format'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const CAST_UPLOAD_SELECT_TYPE = {
  UPLOAD: 'UPLOAD',
  RECORDING: 'RECORDING',
  YOUTUBE: 'YOUTUBE',
}
const TAG_SELECT_TYPE = {
  RSS: 'RSS',
  YOUTUBE: 'YOUTUBE',
}

const CastPopup = ({
  showCastUploadPopup,
  popupCastUploadToggle,
}) => {
  const history = useHistory()
  const { authStore, castStore, liveRoomRecordingStore } = useStore()

  const currentUser = authStore.currentUser || null
  const houseDetail = null

  const fetchMyUploadedRecordingList =
    liveRoomRecordingStore.fetchMyLiveRoomRecordingList || (() => {})
  const requestYoutubeSync = castStore.requestYoutubeSync || (() => {})
  const requestRssSync = castStore.requestRssSync || (() => {})
  const myLiveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null

  const [tagSelectType, setTagSelectType] = useState(null)
  const [castUploadSelectType, setCastUploadSelectType] = useState(
    CAST_UPLOAD_SELECT_TYPE['UPLOAD'],
  )
  const [uploadYoutubeLink, setUploadYoutubeLink] = useState('')
  const [uploadRssLink, setUploadRssLink] = useState('')
  const [rssResultPopupContent, setRssResultPopupContent] = useState(null)
  const [selectedRecording, setSelectedRecording] = useState(null)
  const [youtubeUploadType, setYoutubeUploadType] = useState(
    YOUTUBE_UPLOAD_TYPE['VIDEO'],
  )
  const [youtubeResultPopupContent, setYoutubeResultPopupContent] = useState(
    null,
  )

  // const {
  //   isShowing: showCastUploadTypeSelectPopup,
  //   toggle: popupCastUploadTypeSelectToggle,
  // } = CastUploadTypeSelectPopup.useModal()
  const {
    isShowing: showUploadYoutubeResultPopup,
    toggle: popupUploadYoutubeResultToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: showUploadRssResultPopup,
    toggle: popupUploadRssResultToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: showYoutubeVideoCastUploadPopup,
    toggle: popupYoutubeVideoCastUploadToggle,
  } = YoutubeVideoCastUploadPopup.useModal()
  const {
    isShowing: showTagSelect,
    toggle: toggleTagSelect,
  } = TagSelectModal.useModal()
  const {
    isShowing: showCastUploadRecordingPopup,
    toggle: popupCastUploadRecordingToggle,
  } = CastUploadRecordingPopup.useModal()


  const onCastUploadTypeSelect = async () => {
    let _houseDetail = null
    switch (castUploadSelectType) {
      case CAST_UPLOAD_SELECT_TYPE['UPLOAD']:
        if(houseDetail) {
          _houseDetail = {
            id: houseDetail?.['id'],
            title: houseDetail?.['title'],
            imageUri: houseDetail?.['imageUri'],
          }
        }

        history.push({
          pathname: '/p/cast/upload',
          state: { type: CAST_UPLOAD_TYPE['UPLOAD'], basePlace: _houseDetail },
        })
        break
      case CAST_UPLOAD_SELECT_TYPE['RECORDING']:
        await fetchMyUploadedRecordingList({ userId: currentUser['id'] })
        popupCastUploadRecordingToggle()
        break
      case CAST_UPLOAD_SELECT_TYPE['YOUTUBE']:
        setUploadYoutubeLink('')
        setYoutubeUploadType(YOUTUBE_UPLOAD_TYPE['VIDEO'])
        popupYoutubeVideoCastUploadToggle()
        break
      default:
        break
    }

    popupCastUploadToggle()
  }

  const onYoutubeConvertNext = () => {
    if (uploadYoutubeLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }

    popupYoutubeVideoCastUploadToggle()
    setTagSelectType(TAG_SELECT_TYPE['YOUTUBE'])
    toggleTagSelect()
  }

  const onYoutubeVideoCastUpload = async (tagKeywordList = []) => {
    if (uploadYoutubeLink?.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }

    const houseParams = {
      user: currentUser,
    }

    const param = {
      tagKeywordList,
    }

    if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']) {
      param['youtubeVideoUrl'] = uploadYoutubeLink
    }
    else if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['CHANNEL']) {
      param['youtubeChannelUrl'] = uploadYoutubeLink
    }

    const result = await requestYoutubeSync(houseParams, param)

    setYoutubeResultPopupContent()
    if (result.isSuccess) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환'
          : '채널 연동 완료'
      const body =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO'] ? (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 동영상이 캐스트로 변환 중입니다.'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#242424'}>{'최대 10분'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        ) : (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 채널의 동영상 캐스트 변환 등록이'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#949494'}>{'진행중입니다. '}</Caption2>
              <Caption2 color={'#242424'}>{'최대 1일'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        )
      setYoutubeResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환 실패'
          : '채널 연동 실패'
      let body = <></>
      switch (YOUTUBE_SYNC_ERROR[result.errorLabel]) {
        case YOUTUBE_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크가 정확하지 않습니다.'
                    : '유튜브 채널 링크가 정확하지 않습니다.'
                }
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break

        case YOUTUBE_SYNC_ERROR['DUPLICATED_CHANNEL']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'이미 연동된 채널입니다.'}</Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['DUPLICATED_VIDEO']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 동영상입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'네트워크가 불안정합니다.'}
              </Caption2>
            </div>
          )
          break
        default:
          return
      }

      setYoutubeResultPopupContent({ title, body })
    }

    popupUploadYoutubeResultToggle()
    toggleTagSelect()
  }

  const getDurationConvert = duration => {
    const minutes = Math.floor(duration / 60 || 0)
    const seconds = duration % 60
    if (minutes) {
      return `${minutes}분 ${seconds}초`
    }

    return `${seconds}초`
  }

  const onCastUploadRecordingSelect = async () => {
    if (!selectedRecording) {
      message.warn('캐스트로 만들 녹음 오디오를 선택해주세요.')
      return
    }
    const recording = {
      ...selectedRecording,
      LiveRoom: {
        imageUri: selectedRecording['LiveRoom'].imageUri,
        imageUrl: selectedRecording['LiveRoom'].imageUrl,
      },
    }

    const _recording = { ...recording }
    delete _recording['stores']

    let _houseDetail = null
    if(houseDetail) {
      _houseDetail = {
        id: houseDetail?.['id'],
        title: houseDetail?.['title'],
        imageUri: houseDetail?.['imageUri'],
      }
    }

    history.push({
      pathname: `/p/cast/upload`,
      state: {
        type: CAST_UPLOAD_TYPE['RECORDING'],
        recording: _recording,
        basePlace: _houseDetail,
      },
    })
    popupCastUploadRecordingToggle()
  }


  const onRssCastUpload = async (tagKeywordList = []) => {
    if (uploadRssLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    const param = {
      tagKeywordList: tagKeywordList.map(item => item.keyword),
      rssUrl: uploadRssLink,
    }

    const result = await requestRssSync(param)
    setRssResultPopupContent(null)

    if (result.isSuccess) {
      const title = 'RSS 캐스트 연동 신청 완료'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'RSS 링크의 캐스트 변환 등록이 진행중입니다.'}
          </Caption2>
          <div style={{ display: 'flex' }}>
            <Caption2 color={'#242424'}>{'최대 3일'}</Caption2>
            <Caption2 color={'#949494'}>{'까지 소요될 수 있습니다.'}</Caption2>
          </div>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title = 'RSS 캐스트 연동 오류'
      let body = <></>
      switch (RSS_SYNC_ERROR[result.errorLabel]) {
        case RSS_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'RSS 채널 링크가 정확하지 않습니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['DUPLICATED_RSS']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 RSS 링크입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'네트워크가 불안정합니다'}</Caption2>
            </div>
          )
          break
        default:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'서버 오류. 관리자에게 문의하세요.'}
              </Caption2>
            </div>
          )
          break
      }

      setRssResultPopupContent({ title, body })
    }
    else {
      const title = 'RSS 캐스트 연동 오류'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'서버 오류. 관리자에게 문의하세요.'}
          </Caption2>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }

    popupUploadRssResultToggle()
    toggleTagSelect()
  }

  return (
    <>
      <CastUploadTypeSelectPopup.View
        isShowing={showCastUploadPopup}
        toggle={popupCastUploadToggle}
        onChange={onCastUploadTypeSelect}
        title={'캐스트 등록'}
        body={
          <div>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['UPLOAD'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['UPLOAD']
              }
            >
              업로드로 추가
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['UPLOAD']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button select'}
              />
            </UploadTypeSelectItem>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['RECORDING'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['RECORDING']
              }
            >
              녹음한 오디오로 추가
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['RECORDING']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button unselected'}
              />
            </UploadTypeSelectItem>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['YOUTUBE'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['YOUTUBE']
              }
            >
              유튜브 동영상 변환
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['YOUTUBE']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button unselected'}
              />
            </UploadTypeSelectItem>
          </div>
        }
      />
      <YoutubeVideoCastUploadPopup.View
        isShowing={showYoutubeVideoCastUploadPopup}
        toggle={popupYoutubeVideoCastUploadToggle}
        onChange={onYoutubeConvertNext}
        title={
          youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
            ? '유튜브 동영상 변환'
            : '유튜브 채널 캐스트 연동'
        }
        body={
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크를 입력해주시면,'
                    : '유튜브 채널 링크를 입력해주시면,'
                }
              </Caption2>
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '자동으로 캐스트로 변환하여 등록합니다.'
                    : '전체 동영상을 자동으로 캐스트로 변환하여 등록합니다.'
                }
              </Caption2>
            </div>
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '288px',
                  height: '58px',
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  marginTop: '16px',
                }
              }
            >
              <Caption3 color={'#ea4653'}>
                *타인의 저작물 혹은 뮤직비디오를 변환하여 캐스트를 제공할 시
              </Caption3>
              <Caption3 color={'#ea4653'}>
                저작권법에 따라 계정 제한 및 처벌받을 수 있습니다.
              </Caption3>
            </div>

            <FormInput
              style={
                {
                  border: '0px',
                  backgroundColor: '#f4f4f4',
                  marginTop: '16px',
                }
              }
              placeholder={
                youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                  ? '유튜브 동영상 주소 입력'
                  : '유튜브 채널 주소 입력'
              }
              value={uploadYoutubeLink}
              onChange={e => setUploadYoutubeLink(e.target.value.trim())}
            />
          </div>
        }
      />
      <TagSelectModal.View
        isShowing={showTagSelect}
        toggle={toggleTagSelect}
        // mbti={wathcedMbti}
        subjectTagList={SUBJECT_TAG_LIST}
        defaultTagList={DEFAULT_TAG_LIST}
        validate={
          _tag => {
            const isInvalidateTag = NOT_ALLOWED_TAG_LIST.map(
              __tag => __tag.keyword,
            ).includes(_tag)
            if (isInvalidateTag) {
              message.error('해당 태그는 일반 태그로 등록할 수 없습니다.')
              return false
            }
            return true
          }
        }
        onSubmit={
          tagList => {
            if (tagSelectType === TAG_SELECT_TYPE['YOUTUBE']) {
              onYoutubeVideoCastUpload(tagList)
            }
            else if (tagSelectType === TAG_SELECT_TYPE['RSS']) {
              onRssCastUpload(tagList)
            }
          }
        }
      />
      <CastUploadRecordingPopup.View
        isShowing={showCastUploadRecordingPopup}
        toggle={popupCastUploadRecordingToggle}
        onChange={onCastUploadRecordingSelect}
        title={'녹음한 오디오'}
        body={
          <div
            style={
              {
                overflowY: 'scroll',
                height: '402px',
                maxWidth: '408px',
              }
            }
          >
            {
              myLiveRoomRecordingList.map(recording => {
                let backgroundColor = null
                if (recording && selectedRecording) {
                  backgroundColor =
                  recording['id'] === selectedRecording['id'] ? '#f4f4f4' : null
                }
                return (
                  <div
                    style={
                      {
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '8px',
                        paddingRight: '16px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        cursor: 'pointer',
                        backgroundColor,
                      }
                    }
                    onClick={
                      () => {
                        setSelectedRecording(recording)
                      }
                    }
                    key={recording['id']}
                  >
                    <img
                      style={
                        {
                          width: '40px',
                          height: '40px',
                          borderRadius: '4px',
                          marginRight: '8px',
                        }
                      }
                      src={
                      recording?.LiveRoom?.imageUrl
                      || images.live_room_recording_default_img
                      }
                      alt={'img'}
                    />
                    <div>
                      <div
                        style={
                          {
                            maxWidth: '290px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: 'NotoSansCJKkr-Regular',
                            fontSize: '12px',
                            color: '#242424',
                          }
                        }
                      >
                        {recording?.title}
                      </div>
                      <div
                        style={
                          {
                            fontFamily: 'NotoSansCJKkr-Regular',
                            fontSize: '12px',
                            color: '#949494',
                          }
                        }
                      >
                        {dateMDMS(recording?.createdAt)}
                      </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                      style={
                        {
                          fontFamily: 'NotoSansCJKkr-Regular',
                          fontSize: '12px',
                          color: '#949494',
                          alignSelf: 'center',
                        }
                      }
                    >
                      {getDurationConvert(recording?.duration)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      />
      {
        rssResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadRssResultPopup}
            toggle={
              () => {
                popupUploadRssResultToggle()
                window.location.reload()
              }
            }
            title={rssResultPopupContent.title}
            body={rssResultPopupContent.body}
          />
        )
      }
      {
        youtubeResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadYoutubeResultPopup}
            toggle={
              () => {
                popupUploadYoutubeResultToggle()
                window.location.reload()
              }
            }
            title={youtubeResultPopupContent.title}
            body={youtubeResultPopupContent.body}
          />
        )
      }
    </>
  )
}

const UploadTypeSelectItem = styled.div`
  display: flex;
  width: 288px;
  height: 56px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;

  border: ${props => (props.isSelected ? 'solid 1px #5b49f3' : null)};
  background-color: ${props => (props.isSelected ? '#f8f8ff' : '#ffffff')};
  color: ${props => (props.isSelected ? '#5b49f3' : '#242424')};
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  cursor: pointer;
`

const RadioButton = styled.img`
  flex-direction: row;
  width: 20px;
  height: 20px;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

export default {
  View: observer(CastPopup),
  useModal,
}
