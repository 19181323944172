import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Caption2, Div, ImageIcon, Overlay } from '@components/atoms'
import styled, { css } from 'styled-components'
import { colors } from '@resources/colors'
import { observer } from 'mobx-react'
import { useQuery, useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'

const AutocompleteItem = ({
  item,
  handleSearch,
  deleteSearchHistory,
  focus,
}) => {
  return (
    <Div
      width="100%"
      height="40px"
      minHeight="40px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="0 12px"
      cursor="pointer"
      onClick={handleSearch}
      backgroundColor={focus ? colors.white_f4 : colors.white_ff}
    >
      <Caption2>{item || ''}</Caption2>
      <ImageIcon
        name="circleCloseBtn"
        width="16px"
        height="16px"
        onClick={
          e => {
            e.stopPropagation()
            deleteSearchHistory()
          }
        }
      />
    </Div>
  )
}

const GlobalSearchBar = () => {
  const { searchStore } = useStore()
  const history = useHistory()
  const query = useQuery()

  const globalInputKeyword = searchStore.globalInputKeyword || ''
  const searchHistory = searchStore.searchHistory || []

  const addSearchHistory = searchStore.addSearchHistory || (() => {})
  const deleteSearchHistory = searchStore.deleteSearchHistory || (() => {})
  const setGlobalKeyword = searchStore.setGlobalKeyword || (() => {})
  const setGlobalInputKeyword = searchStore.setGlobalInputKeyword || (() => {})

  const inputRef = useRef(null)
  const [isFocus, setIsFocus] = useState(false) // 검색 박스 포커스
  const [cursorIndex, setCursorIndex] = useState(-1) // 검색 내역 커서

  // const throttledValue = useThrottle(globalInputKeyword, 500)

  // 검색 내역 스크롤 로직
  const historyRef = useRef(null)
  let historyNode
  let historyCurrentScroll
  if (historyRef.current) historyNode = ReactDOM.findDOMNode(historyRef.current)
  if (historyNode) historyCurrentScroll = historyNode.scrollTop
  // 검색 내역 스크롤 로직 end

  const handleSearch = useCallback(
    keyword => {
      setIsFocus(false)
      inputRef.current.blur()

      if (searchHistory && cursorIndex !== -1) {
        history.push(`/p/search?keyword=${searchHistory[cursorIndex]}`)
        addSearchHistory(searchHistory[cursorIndex])
      }
      else {
        history.push(`/p/search?keyword=${keyword}`)
        addSearchHistory(keyword)
      }
    },
    [addSearchHistory, cursorIndex, searchHistory, history],
  )

  // useEffect(() => {
  //   setGlobalKeyword(throttledValue || '')
  // }, [throttledValue])

  useEffect(() => {
    if (query?.keyword) {
      setGlobalInputKeyword(query.keyword)
      setGlobalKeyword(query.keyword)
    }
  }, [query.keyword, setGlobalInputKeyword, setGlobalKeyword])

  return (
    <>
      <Bar isFocus={isFocus}>
        <Div
          display="flex"
          height="36px"
          borderRadius="4px"
          border={`1px solid ${colors.white_f4}`}
        >
          <SearchInput
            ref={inputRef}
            placeholder="검색어를 입력해주세요"
            value={globalInputKeyword}
            onFocus={() => setIsFocus(true)}
            onChange={
              e => {
                setCursorIndex(-1)
                setGlobalInputKeyword(e.target.value)
              }
            }
            onKeyDown={
              e => {
                if (e.keyCode === 40) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState < searchHistory.length - 1
                      ? prevState + 1
                      : prevState,
                  )
                  if (cursorIndex > 4) {
                    historyNode.scrollTo(0, historyCurrentScroll + 40)
                  }
                }
                if (e.keyCode === 38) {
                  e.preventDefault()
                  setCursorIndex(prevState =>
                    prevState === 0 ? prevState : prevState - 1,
                  )
                  if (cursorIndex < searchHistory.length - 4) {
                    historyNode.scrollTo(0, historyCurrentScroll - 40)
                  }
                }

                if (e.key === 'Enter') {
                  handleSearch(globalInputKeyword)
                }
              }
            }
          />
          <Div
            cursor="pointer"
            width="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={colors.white_fa}
          >
            <ImageIcon name="searchBlack" width="20px" height="20px" />
          </Div>
        </Div>
        {
          isFocus && searchHistory?.length > 0 && (
            <Div
              ref={historyRef}
              paddingTop="4px"
              display="flex"
              flexDirection="column"
              overflowY="auto"
            >
              {
                searchHistory.map((item, index) => (
                  <AutocompleteItem
                    key={item}
                    item={item}
                    handleSearch={() => handleSearch(item)}
                    deleteSearchHistory={() => deleteSearchHistory(index)}
                    focus={cursorIndex === index}
                  />
                ))
              }
            </Div>
          )
        }
      </Bar>
      {isFocus && <Overlay onClick={() => setIsFocus(false)} />}
    </>
  )
}

export default observer(GlobalSearchBar)

const Bar = styled(Div)`
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border-radius: 4px;

  ${({ isFocus }) =>
    isFocus
      ? css`
          max-width: 528px;
          max-height: 288px;
          height: fit-content;
          background-color: ${colors.white_ff};
          padding: 4px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        `
      : css`
          max-width: 520px;
          height: 36px;
          border: none;
        `}
`

const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  padding: 8px 12px;
  border: none;
  background-color: ${colors.white_f4};

  ::placeholder {
    color: ${colors.grey_bf};
  }
`
