import { observable } from 'mobx'

export default class AdultCertificationModel {
  @observable id
  @observable createdAt
  @observable updatedAt
  @observable requestAt
  @observable responseAt

  /**
   * 1. 0: 미처리
   * 2. 1: 주민등록증 사진
   * 3. 2: 미성년자 보호자 동의 파일
   */
  @observable fileType
  /**
   * 1. 0: 미처리
   * 2. 1: 성인 인증
   * 3. 2: 보호자 동의
   */
  @observable fileUri
  @observable type

  /**
   * 처리 상태
   * 1. 0: 미처리
   * 2. 11: 관리자 승인
   * 3. 12: 관리자 반려
   * 4. 21: 자동 승인
   * 5. 22: 자동 반려
   */
  @observable responseState

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.requestAt = props.requestAt
      this.responseAt = props.responseAt

      this.fileUri = props.fileUri
      this.fileType = props.fileType
      this.type = props.type
      this.responseState = props.responseState
    }
  }
}
