import React from 'react'
import styled from 'styled-components'
import { H3, Caption1 } from '@components/atoms'
import { Standard, ForStudent, MoveBtn } from '@components/molecules'
import { mobile, tablet, mobileAndTablet } from '@styles/media'
import { getTime } from '@utils/time'
import { colors } from '@resources/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  // height: 428px;
  max-width: 1000px;
  min-width: 750px;
  border-radius: 16px;
  box-shadow: 0 3px 20px 0 rgba(36, 36, 36, 0.1);
  /* margin-top: 30px; */
  /* margin-bottom: 20px; */
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;

  @media ${mobile} {
    /* padding-top: 20px; */
    padding: 20px 10px;
  }

  @media ${mobileAndTablet} {
    min-width: 250px;
  }
`

const InfoBox = styled.div`
  position: relative;
  display: flex;
  height: 217px;
  padding: 20px;

  @media ${tablet} {
    display: block;
    height: 200px;
  }

  @media ${mobile} {
    display: block;
    height: 175px;
  }
`

const InfoTitleWrapper = styled.div`
  width: 250px;

  @media ${mobileAndTablet} {
    width: 100%;
  }
`

const InfoTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 16px;

  @media ${mobileAndTablet} {
    margin-bottom: 0;
  }
`

const InfoWrapper = styled.div`
  width: 100%;
`

const PaymentMethodInfo = styled.div`
  color: #646464;
`
const Method = styled.span`
  margin-right: 10px;
`
const CardNumber = styled.span``
const PhoneNumber = styled.span``
const PaymentNextDate = styled.p`
  margin-top: 20px;

  @media ${mobileAndTablet} {
    margin-top: 16px;
  }
`

const PaymentButtonGroup = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;

  div:not(:first-child) {
    margin-top: 20px;
    margin-left: 0;
  }

  @media ${mobileAndTablet} {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 16px;

    div {
      display: inline-block;
    }

    div:not(:first-child) {
      margin-top: 35px;
      margin-left: 40px;
    }
  }
`

const TextCenterWrap = styled.div`
  display: flex;
  align-items: center;
`

const RightTopButton = styled.div`
  @media ${tablet} {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  @media ${mobile} {
    position: absolute;
    top: 26px;
    right: 20px;
  }
`

const MiddleLine = styled.div`
  border: solid 0.5px rgba(148, 148, 148, 0.24);
`

const ForStudentWording = styled.span`
  color: ${colors.secondary};
  margin-left: 20px;
  white-space: nowrap;
`

const PlanStartButtonWrapper = styled.div`
  color: ${colors.primary};
  margin-left: 20px;
  white-space: nowrap;

  @media ${tablet} {
    position: absolute;
    margin-top: 5px;
    top: 40px;
    right: 40px;
  }

  @media ${mobile} {
    position: absolute;
    margin-top: 5px;
    top: 24px;
    right: 26px;
  }
`

const PlanInfoCard = ({
  email,
  plan,
  platform,
  paymentMethod,
  paymentCardNumber,
  paymentPhoneNumber,
  autoRenewing,
  paymentNextDate,
  isExpired,
}) => {
  return (
    <Container>
      <InfoBox>
        <InfoTitleWrapper>
          <InfoTitle>결제 정보</InfoTitle>
          <RightTopButton>
            {
              platform === 3 && plan && autoRenewing && (
                <MoveBtn
                  text="플랜 취소하기"
                  href="/p/plan/cancel"
                  style={{ marginLeft: '7px' }}
                  size="0.9"
                  noImage
                />
              )
            }
            {/* {plan && !autoRenewing && (
              <MoveBtn
                text="플랜 다시 시작하기"
                href="/p/plan/subscribe"
                onClick={handleReStart}
                noImage
              />
            )} */}
          </RightTopButton>
        </InfoTitleWrapper>
        <InfoWrapper>
          <PaymentMethodInfo>
            {
              paymentMethod && (
                <div>
                  <Method>{paymentMethod}</Method>
                  {
                    paymentCardNumber && (
                      <CardNumber>{paymentCardNumber}</CardNumber>
                    )
                  }
                  {
                    paymentPhoneNumber && (
                      <PhoneNumber>{paymentPhoneNumber}</PhoneNumber>
                    )
                  }
                  {
                    paymentNextDate && (
                      <PaymentNextDate>
                        {autoRenewing ? '다음 결제일' : '만료일'}
                        {' : '}
                        {getTime(paymentNextDate, 'YYYY-MM-DD')}
                      </PaymentNextDate>
                    )
                  }
                  {
                    platform !== 3 && !isExpired && (
                      <Caption1 style={{ marginTop: '20px' }} color="#949494">
                    *구독 취소는 해당 스토어에서 가능합니다
                      </Caption1>
                    )
                  }
                </div>
              )
            }
            {!plan && <span>결제 정보가 없습니다</span>}
          </PaymentMethodInfo>
          <PaymentButtonGroup>
            {/* {plan && autoRenewing && <MoveBtn text="결제 수단 수정" />} */}
            {/* <MoveBtn text="결제 내역 보기" /> */}
          </PaymentButtonGroup>
        </InfoWrapper>
      </InfoBox>

      <MiddleLine />

      <InfoBox>
        <InfoTitleWrapper>
          <InfoTitle style={{ marginTop: 5 }}>플랜 세부 정보</InfoTitle>
        </InfoTitleWrapper>
        <InfoWrapper>
          {
            plan === 'student' && (
              <TextCenterWrap
                style={
                  {
                    display: 'flex',
                    alignItems: 'center',
                  }
                }
              >
                <ForStudent
                  style={
                    {
                      textAlign: 'left',
                      display: 'inline-block',
                      position: 'relative',
                      top: '-3px',
                    }
                  }
                />
                <ForStudentWording>학생 할인 중</ForStudentWording>
              </TextCenterWrap>
            )
          }

          {
            plan === 'standard' && (
              <TextCenterWrap>
                <Standard
                  style={{ textAlign: 'left', position: 'relative', top: '-3px' }}
                />
              </TextCenterWrap>
            )
          }

          {
            !plan && (
              <TextCenterWrap>
                <H3
                  style={
                    {
                      textAlign: 'left',
                      display: 'inline-block',
                      position: 'relative',
                      top: '0px',
                      whiteSpace: 'unset',
                      lineHeight: '38px',
                    }
                  }
                >
                아직 플랜이 없어요
                </H3>
                <PlanStartButtonWrapper>
                  {/* <MoveBtn text="플랜 시작하기" href="/p/plan/subscribe" noImage /> */}
                </PlanStartButtonWrapper>
              </TextCenterWrap>
            )
          }

          <>
            <p style={{ color: '#646464', marginTop: '20px' }}>{email}</p>
            {
              plan && (
                <p style={{ color: '#646464', marginTop: '20px' }}>
                - 흐름 서비스 모든 광고 제거
                </p>
              )
            }
          </>
        </InfoWrapper>
      </InfoBox>
    </Container>
  )
}

export default PlanInfoCard
