import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Flex, Caption2 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import CopyToClipboard from 'react-copy-to-clipboard'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { CopiedPopup } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`

const Modal = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  width: 320px;
`

const MobdlBody = styled.div``

const ButtonList = styled(Flex)`
  flex-direction: column;
  > div:last-child {
    border: none;
  }
`

const ButtomItem = styled(Flex)`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #f4f4f4;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle, shareLink, isMyPage }) => {
  const [linkCopied, setLinkCopied] = useState(false)
  const [blockedUser, setBlockedUser] = useState([])

  const { authStore, userStore, appStateStore } = useStore()

  const handleCopy = () => {
    setLinkCopied(true)
    setTimeout(() => {
      setLinkCopied(false)
      toggle()
    }, 2500)
  }

  const handleBlock = async () => {
    if (blockedUser?.length > 0) {
      try {
        await userStore.deleteBlockUser(
          authStore?.currentUser?.id,
          userStore?.thisUser?.id,
        )
        setBlockedUser([])
      }
      catch (error) {
        console.error('UserRelationPopup handleBlock error', error)
      }
    }
    else {
      try {
        const res = await userStore.blockUser(
          authStore?.currentUser?.id,
          userStore?.thisUser?.id,
        )
        setBlockedUser([res])
      }
      catch (error) {
        console.error('UserRelationPopup handleBlock error', error)
      }
    }
  }

  useEffect(() => {
    const fetchIsBlock = async () => {
      const res = await userStore.fetchBlockUser(
        authStore?.currentUser?.id,
        userStore?.thisUser?.id,
      )
      setBlockedUser(res)
    }

    if (authStore?.currentUser?.id) {
      fetchIsBlock()
    }
  }, [authStore, userStore])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        {linkCopied && <CopiedPopup />}
        <ModalOverlay onClick={toggle} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <ButtonList>
                {
                  isMyPage ? (
                    <ButtomItem
                      onClick={
                        () => {
                          appStateStore.onModal({ type: 'myBlockUser' })
                          toggle()
                        }
                      }
                    >
                      <Caption2>차단 목록</Caption2>
                    </ButtomItem>
                  ) : (
                    <ButtomItem
                      onClick={
                        () => {
                          handleBlock()
                          toggle()
                        }
                      }
                    >
                      <Caption2 color={colors.warning}>
                        {blockedUser?.length > 0 ? '차단 해제' : '차단 하기'}
                      </Caption2>
                    </ButtomItem>
                  )
                }
                <ButtomItem>
                  <CopyToClipboard text={shareLink} onCopy={handleCopy}>
                    <Caption2>링크 공유하기</Caption2>
                  </CopyToClipboard>
                </ButtomItem>
              </ButtonList>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  usePopup,
}
