import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
// import { gaLogPageView, initGA, LOG_EVENT } from '../../components/Analytics'
import { useHistory } from 'react-router-dom'
import {
  SocialkingMainTemplate,
  SocialkingQuizTemplate,
  SocialkingResultingTemplate,
} from '../../components/socialking'
import { IS_DEV } from '../../resources'

const SocialkingPage = () => {
  const history = useHistory()


  const [step, setStep] = useState(0)
  const [mbti, setMbti] = useState('')

  const progress = useRef({ IE: 0, SN: 0, TF: 0, PJ: 0 })

  const handleAnswer = (quiz, answerYn) => {
    if (answerYn === 'Y') progress.current[quiz.type] += 1
    if (answerYn === 'N') progress.current[quiz.type] -= 1
    setStep(step + 1)
  }

  useEffect(() => {
    let mbtiProgress = ''
    if (step === 13) {
      if (progress.current['IE'] > 0) mbtiProgress = 'I'
      if (progress.current['IE'] < 0) mbtiProgress = 'E'

      if (progress.current['SN'] > 0) mbtiProgress += 'S'
      if (progress.current['SN'] < 0) mbtiProgress += 'N'

      if (progress.current['TF'] > 0) mbtiProgress += 'T'
      if (progress.current['TF'] < 0) mbtiProgress += 'F'

      if (progress.current['PJ'] > 0) mbtiProgress += 'P'
      if (progress.current['PJ'] < 0) mbtiProgress += 'J'

      setMbti(mbtiProgress)
      // LOG_EVENT.LANDING_CLICK_COMPLETE_SOCIALKING()
    }
  }, [step, progress.current])

  useEffect(() => {
    let redirect
    if (mbti.length >= 4) {
      redirect = setInterval(() => {
        history.push({
          pathname: `/p/socialking/${mbti.toLowerCase()}`,
          state: { isPassword: false },
        })
      }, 4000)
    }

    return () => {
      clearInterval(redirect)
    }
  }, [mbti])

  // LOGGING Init and ENTER_PAGE
  // useEffect(() => {
  //   initGA()
  //   gaLogPageView()
  //   // LOG_EVENT.LANDING_ENTER_SOCIALKING_TEST_MAIN(IS_DEV)
  // }, [])

  return (
    <div
      style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
        }
      }
    >
      <Helmet>
        <title>인맥왕 테스트</title>
        <meta
          name="description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta property="og:title" content="인맥왕 테스트" />
        <meta property="og:url" content="https://hreum.me/p/socialking/" />
        <meta
          property="og:description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta
          property="og:image"
          content={`https://storage.hreum.me/resources/socialking/socialking_og_img.png`}
        />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          name="keywords"
          content="인맥왕,흐름 mbti,흐름 mbti,흐름 인맥왕 테스트, 흐름 인맥왕 테스트,mbti,MBTI,엠비티아이,인맥왕 테스트,인맥왕테스트"
        />

        <meta
          name="twitter:domain"
          content="https://hreum.me/p/socialking/"
        />
        <meta name="twitter:title" content="인맥왕 테스트" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="인맥왕 테스트" />
        <meta
          name="twitter:description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta
          name="twitter:image"
          content="https://storage.hreum.me/resources/socialking/socialking_og_img.png"
        />
      </Helmet>
      {
        step === 0 ? (
          <SocialkingMainTemplate setStep={setStep} />
        ) : step < 13 ? (
          <SocialkingQuizTemplate step={step} handleAnswer={handleAnswer} />
        ) : (
          step === 13 && <SocialkingResultingTemplate />
        )
      }
    </div>
  )
}

export default SocialkingPage
