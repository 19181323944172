import {
  Caption1,
  Caption2,
  Flex,
  Image,
  Img,
  ProfileImg,
} from '@components/atoms'
import {
  ConfirmPopup,
  CopiedPopup,
  DeletePopup,
  YnPopup,
} from '@components/molecules'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { images } from '@images/'
import { colors } from '@resources/colors'
import {
  oneEllipsisStyle,
  threeEllipsisStyle,
  twoEllipsisStyle,
} from '@styles/fontStyles'
import { dateMDMS } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const ReserveSalonCard = ({ salon, type = 'card' }) => {
  const { authStore, liveRoomStore, houseStore } = useStore()
  const history = useHistory()

  const currentUser = authStore.currentUser || null
  const isMine = currentUser && salon && currentUser['id'] === salon.userId
  const isHouseSalon = !!salon?.houseId

  const [linkCopied, setLinkCopied] = React.useState(false)
  const [showThreeDot, setShowThreeDot] = React.useState(false)
  const [confirmPopupInfo, setConfirmPopupInfo] = React.useState({
    title: '',
    body: '',
    onClose: () => {},
  })
  const {
    isShowing: showConfirm,
    toggle: toggleConfirm,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showHouseMember,
    toggle: toggleHouseMember,
  } = YnPopup.usePopup()

  const { isShowing: showHide, toggle: toggleHide } = DeletePopup.useModal()

  const isMyHouseLiveRoom =
    authStore.currentUser
    && authStore.myDetailJoinedHouseList?.filter(
      house =>
        house.id === salon?.houseId
        && house?._myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'],
    )?.length > 0

  const onHide = async () => {
    const res = await liveRoomStore.hideReservationLiveRoom(salon)
    if (!res) return

    toggleHide()
  }

  const handleReserve = async liveRoom => {
    let res
    if (salon.isReservedOthers) {
      res = await liveRoomStore.reserveJoinLiveRoom(liveRoom, false)
      if (!res) return
      setConfirmPopupInfo({
        title: '살롱 참여 예약 취소',
        body: '예약된 살롱이 취소되었습니다',
      })
      toggleConfirm()
    }
    else {
      let myHouseLevel;
      if(liveRoom?.houseId){
        myHouseLevel = await houseStore.fetchMyHouseLevel(liveRoom?.houseId)
      }
      if (isHouseSalon && myHouseLevel < HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
        // setConfirmPopupInfo({
        //   title: '하우스 멤버 공개 캐스트',
        //   body: '멤버가 아니어서\n입장할 수 없습니다.',
        //   onClose: () => {
        //     history.push(`/house/${salon.House.linkKey}`)
        //   },
        // })
        toggleHouseMember()
        // toggleConfirm()
        return
      }
      res = await liveRoomStore.reserveJoinLiveRoom(liveRoom, true)
      if (!res) return
      setConfirmPopupInfo({
        title: '살롱 참여 예약 완료',
        body: '예약된 살롱은 앱을 통해\n알림을 받을 수 있습니다',
      })
      toggleConfirm()
    }
  }

  const handleCopy = () => {
    setShowThreeDot(false)
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 2500)
  }

  const handleOpenSalon = async () => {
    const isCreated = await liveRoomStore.createLiveRoom(salon, false, true)
    if (isCreated) {
      history.push({
        pathname: `/salon/${isCreated['linkKey']}`,
        state: { isPassword: true, isCreated: true },
      })
    }
  }

  return (
    <Card type={type} isMine={isMine}>
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Flex align="center">
          {
            !!salon?.isPrivate && (
              <Image
                src={images.lock_img_red}
                alt="lock_img"
                width="20px"
                height="20px"
              />
            )
          }
          {
            !!salon?.isHouseMember && (
              <Image
                src={images.house_type_member_icon_yellow}
                alt="lock_img"
                width="20px"
                height="20px"
              />
            )
          }
          <Caption2 color={colors.grey_94} style={{ marginLeft: 6 }}>
            {`${dateMDMS(salon?.reservedOpenedAt)} 오픈 예정`}
            {/* {`${moment(salon?.reservedOpenedAt).format('YYYY.MM.DD hh:mm')} 오픈 예정`} */}
          </Caption2>
        </Flex>
        <Flex align="center" style={{ position: 'relative' }}>
          {/* <CopyToClipboard text={salon?.dynamicLink} onCopy={handleCopy}>
            <Image
              src={images.share_img_black}
              alt="lock_img"
              width="16px"
              height="16px"
              style={{ marginRight: 22, cursor: 'pointer' }}
            />
          </CopyToClipboard> */}
          {
            (!currentUser || !isMine) && (
              <Image
                src={
                  salon.isReservedOthers
                    ? images.alram_img_blue
                    : images.alram_img_gray
                }
                alt="lock_img"
                width="20px"
                height="20px"
                style={{ cursor: 'pointer' }}
                onClick={() => handleReserve(salon)}
              />
            )
          }
          <Image
            src={images.three_dot_black}
            alt="three_dot_img"
            width="20px"
            height="20px"
            style={{ cursor: 'pointer', marginLeft: 22 }}
            onClick={() => setShowThreeDot(true)}
          />
          {
            showThreeDot && (
              <>
                <Overlay onClick={() => setShowThreeDot(false)} />
                <ThreeDot>
                  {
                    isMine && (
                      <ThreeDotItem onClick={handleOpenSalon}>
                        <Caption2>살롱 열기</Caption2>
                      </ThreeDotItem>
                    )
                  }
                  <ThreeDotItem>
                    <CopyToClipboard
                      text={salon?.dynamicLink}
                      onCopy={handleCopy}
                    >
                      <Caption2>공유하기</Caption2>
                    </CopyToClipboard>
                  </ThreeDotItem>
                  <ThreeDotItem
                    onClick={
                      () => {
                        toggleHide()
                        setShowThreeDot(false)
                      }
                    }
                  >
                    <Caption2 color={colors.warning}>삭제하기</Caption2>
                  </ThreeDotItem>
                </ThreeDot>
              </>
            )
          }
        </Flex>
      </Flex>
      <Flex
        style={{ width: '100%', height: '120px', margin: '16px 0 0 0' }}
        justify="space-between"
      >
        {
salon?.imageUrl && (
            <SalonImage src={salon?.imageUrl} style={{ marginRight: 16 }} />
          )
        }
        <Flex
          type="column"
          justify="space-between"
          style={{ width: '100%', height: '100%' }}
        >
          <Flex align="end">
            <Caption1 type="Medium" align="left" style={twoEllipsisStyle}>
              {salon?.title}
            </Caption1>
          </Flex>
          {
salon?.LiveRoomsHaveTags && salon?.LiveRoomsHaveTags.length > 0 && (
              <TopicList>
                {
salon?.LiveRoomsHaveTags.map(tag => (
  <Tag key={tag.Tag.id}>
    <Caption2
      color={colors.grey_64}
      style={{ textAlign: 'left', ...oneEllipsisStyle }}
    >
      {`#${tag.Tag.keyword}`}
    </Caption2>
  </Tag>
))
                }
              </TopicList>
            )
          }
        </Flex>
      </Flex>
      <Flex type="column" style={{ width: '100%' }}>
        <Flex style={{ width: '100%', margin: '13px 0 16px 0' }}>
          <Caption2 align="left" style={threeEllipsisStyle}>
            {salon?.description}
          </Caption2>
        </Flex>
        <Flex align="center" justify="flex-start" style={{ width: '100%' }}>
          <ProfileImg
            style={
              {
                width: 24,
                height: 24,
                borderRadius: '50%',
                marginRight: 8,
              }
            }
            src={isHouseSalon ? salon?.House?.imageUrl : salon?.User?.imageUri}
            profileColor={salon?.User?.profileColor}
          />
          <Caption2>
            {isHouseSalon ? salon?.House?.title : salon?.User?.name}
          </Caption2>
          {
            isHouseSalon && (
              <Img
                src={images.house_icon_img}
                width="16px"
                height="16px"
                marginLeft="4px"
              />
            )
          }
        </Flex>
      </Flex>
      {linkCopied && <CopiedPopup />}
      <ConfirmPopup.View
        isShowing={showConfirm}
        toggle={toggleConfirm}
        title={confirmPopupInfo.title}
        body={confirmPopupInfo.body}
        onClose={confirmPopupInfo.onClose}
      />
      <YnPopup.View
        isShowing={showHouseMember}
        toggle={toggleHouseMember}
        title="하우스 멤버 콘텐츠"
        body={'해당 콘텐츠는 멤버만 청취 가능합니다.'}
        yText="멤버 가입하기"
        onOk={
          () => {
            history.push(`/house/${salon?.House?.linkKey}`)
          }
        }
      />
      <DeletePopup.View
        isShowing={showHide}
        toggle={toggleHide}
        onDelete={onHide}
        title="오픈 예정 살롱 삭제"
        body={'선택한 오픈예정 살롱을 삭제하면\n더 이상 노출되지 않습니다.'}
        deleteText="확인"
      />
    </Card>
  )
}

export default observer(ReserveSalonCard)

const Card = styled(Flex)`
  flex-direction: column;
  justify-content: ${({ type }) =>
    type === 'list' ? 'center' : 'space-between'};
  align-items: center;
  width: ${({ type }) =>
    type === 'list' ? '384px' : type === 'reservationList' ? '384px' : '328px'};
  height: ${({ type }) => (type === 'list' ? '192px' : '292px')};
  border-radius: 8px;
  box-shadow: ${({ isMine }) =>
    isMine ? 'none' : '0 1px 3px 0 rgba(0, 0, 0, 0.16)'};
  background-color: ${({ isMine }) =>
    isMine ? colors.white_f4 : colors.white_ff};

  padding: 16px 16px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
`

const SalonImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 12px;
`

const ThreeDot = styled(Flex)`
  position: absolute;
  width: 144px;
  flex-direction: column;
  top: 28px;
  right: 0;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.white_ff};
  z-index: 11;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 16%);

  > div {
    border-bottom: 1px solid ${colors.white_f4};
  }
  > div:last-child {
    border-bottom: none;
  }
`

const ThreeDotItem = styled(Flex)`
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const TopicList = styled(Flex)`
  width: 100%;
  height: 64px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;

  overflow: hidden;

  > div {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`

const Tag = styled(Flex)`
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: auto;
  height: 28px;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #f4f4f4;
`
