import React from 'react'
import { Caption1, Caption2 } from '@components/atoms'
import { secondToTimeString } from '@utils/time'
import styled from 'styled-components'
import { images } from '@images/'
import FlexColumnBox from '../box/FlexColumnBox'
import FlexRowBox from '../box/FlexRowBox'

const onelineStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '530px',
  textAlign: 'left',
}

const IsExitstWrapper = styled(FlexRowBox)`
  position: absolute;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  border-radius: 5px;
  background-color: rgba(36, 36, 36, 0.5);
`

const ItemBox = styled(FlexRowBox)`
  position: relative;
  height: 40px;
  width: 100%;
  margin: 9px 0;
  :hover {
    background-color: rgb(230, 245, 254);
  }
`

const SongItem = ({ style, item, handleAdd, handleDelete, isExist }) => {
  const handleClick = item => {
    if (isExist) {
      handleDelete(item)
    }
    else {
      handleAdd(item)
    }
  }

  return (
    <ItemBox onClick={() => handleClick(item)} style={style}>
      {
        isExist && (
          <IsExitstWrapper>
            <img
              src={images.check_white_img}
              alt="is_exist_img"
              style={{ width: '16px', height: '16px' }}
            />
          </IsExitstWrapper>
        )
      }
      <img
        src={item.imageUrl}
        alt="item_img"
        style={
          {
            width: '40px',
            height: '40px',
            borderRadius: '5px',
            backgroundColor: '#ff9742',
          }
        }
      />
      <FlexColumnBox
        style={
          {
            width: '100%',
            height: '100%',
            maxWidth: '552px',
            margin: '0 12px',
            alignItems: 'flex-start',
          }
        }
      >
        <Caption1 style={onelineStyle}>{item.name}</Caption1>
        <Caption1 color="#949494" style={onelineStyle}>
          {item.singer}
        </Caption1>
      </FlexColumnBox>
      <Caption2 color="#949494">{secondToTimeString(item.duration)}</Caption2>
    </ItemBox>
  )
}

export default SongItem
