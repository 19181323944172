import React from 'react'
import styled from 'styled-components'
import {
  H1,
  H6,
  SubTitle,
  TextWrapper,
  TitleWrapper,
  DescriptionWrapper,
} from '@components/atoms'
import { CancelBtn, MoveBtn } from '@components/molecules'
import { mobile } from '@styles/media'
import { getTime } from '@utils/time'
import { PageLayout } from '..'

const DateWrapper = styled.div`
  margin-top: 20px;

  @media ${mobile} {
    margin-top: 16px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 40px;

  @media ${mobile} {
    margin-top: 30px;
  }
`

const CancelTemplate = ({ user, myPlan, cancelPlan, isCanceled }) => {
  const myPlanInfo = myPlan && myPlan.validation ? myPlan.validation : null
  const isValidate = myPlanInfo && myPlanInfo.isValidate

  return (
    <>
      {/* Cancel */}
      {
        !isCanceled && isValidate && (
          <PageLayout style={{ marginBottom: '200px' }}>
            <SubTitle>
              {user.name}
님
            </SubTitle>

            <TitleWrapper>
              <TextWrapper style={{ whiteSpace: 'nowrap' }}>
                <H1>정말 취소&nbsp;</H1>
                <H1>하시나요?</H1>
              </TextWrapper>
            </TitleWrapper>

            <DescriptionWrapper>
              <TextWrapper>
                <H6>예정되었던 다음 결제일 이전까지는</H6>
                <H6>기존 결제한 플랜의 혜택을 이용하실 수 있습니다.</H6>
              </TextWrapper>
            </DescriptionWrapper>

            <DateWrapper>
              <H6>
              예정되었던 다음 결제일 :
                {' '}
                {getTime(myPlanInfo.expirationDate, 'YYYY-MM-DD')}
              </H6>
            </DateWrapper>

            <ButtonWrapper>
              <CancelBtn
                onClick={cancelPlan}
                text="취소하기"
                style={{ marginTop: '30px' }}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <MoveBtn
                href="/p/plan"
                text="내 구독 정보로 이동"
                style={{ marginTop: '30px' }}
              />
            </ButtonWrapper>
          </PageLayout>
        )
      }

      {/* Cancel Complete */}
      {
        isCanceled && isValidate && (
          <PageLayout>
            <SubTitle>어나니머스 아티스트 (Anonymous Artist)님의</SubTitle>

            <TitleWrapper>
              <TextWrapper>
                <H1 style={{ whiteSpace: 'nowrap' }}>플랜 취소&nbsp;</H1>
                <H1>완료</H1>
              </TextWrapper>
            </TitleWrapper>

            <DescriptionWrapper>
              <TextWrapper>
                <H6>예정되었던 다음 결제일 이전까지는</H6>
                <H6>기존 결제한 플랜의 혜택을 이용하실 수 있습니다.</H6>
              </TextWrapper>
            </DescriptionWrapper>

            <DateWrapper>
              <H6>예정되었던 다음 결제일 : 2021년 02월 21일</H6>
            </DateWrapper>

            <ButtonWrapper>
              <MoveBtn href="/p/plan" text="내 구독 정보로 이동" />
            </ButtonWrapper>
          </PageLayout>
        )
      }
    </>
  )
}

export default CancelTemplate
