import { Caption1, Flex } from '@components/atoms'
import React from 'react'
import styled from 'styled-components'
import { STAR_HISTORY_TYPE } from '@consts/'
import moment from 'moment'
import { colors } from '@resources/colors'
import { twoEllipsisStyle } from '@styles/fontStyles'
import { addComma } from '@utils/string'

const ColoredNumber = ({ number = 0 }) => {
  if (typeof number !== 'number') return null

  if (number >= 0) {
    return (
      <Flex type="column" style={{ alignItems: 'flex-end' }}>
        <Caption1 type="Bold" color={colors.primary}>
          {`+ ${addComma(Math.abs(number))}개`}
        </Caption1>
      </Flex>
    )
  }
  return (
    <Flex type="column" style={{ alignItems: 'flex-end' }}>
      <Caption1 type="Bold" color={colors.black_24}>
        {`- ${addComma(Math.abs(number))}개`}
      </Caption1>
    </Flex>
  )
}

const StarHistoryItem = ({ myStarHistoryList, item, index }) => {
  const message = () => {
    if (item?.type === STAR_HISTORY_TYPE.PURCHASED) {
      return '충전'
    }
    if (item?.type === STAR_HISTORY_TYPE.EXCHANGED) {
      return '환전'
    }
    if (item?.type === STAR_HISTORY_TYPE.GIFTED) {
      if (item?.changedStarValue >= 0) {
        return '받은 별'
      }
      return '보낸 별'
    }
    if (item?.type === STAR_HISTORY_TYPE.EXPIRED) {
      return '소멸된 별'
    }
    return ''
  }

  return (
    <Item>
      {
        (moment(myStarHistoryList?.[index - 1]?.changedAt).format('YYYYMMDD')
        !== moment(myStarHistoryList?.[index]?.changedAt).format('YYYYMMDD')
        || index === 0) && (
          <Flex align="center" style={{ width: '100%' }}>
            <Caption1
              align="left"
              color={colors.grey_64}
              style={{ marginRight: 9 }}
            >
              {`${moment(item?.changedAt).format('DD일 (ddd)')}`}
            </Caption1>
            <Br />
          </Flex>
        )
      }
      <Flex
        justify="space-between"
        align="center"
        style={
          {
            width: '100%',
            marginTop: 16,
          }
        }
      >
        <Flex type="column">
          <Caption1 type="Bold" style={twoEllipsisStyle}>
            {item?.systemMessage}
          </Caption1>
          <Caption1
            align="left"
            color={colors.brown_grey}
            style={{ marginTop: 8 }}
          >
            {message()}
          </Caption1>
        </Flex>
        <ColoredNumber number={item?.changedStarValue} />
      </Flex>
    </Item>
  )
}

export default StarHistoryItem

const Item = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  height: 116px;
  padding: 12px 0px;
`

const Br = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.white_f4};
`
