import React from 'react'
import { BodyWrapper, Flex } from '@components/atoms'
import { Footer, Header, LoginModal, Sidebar } from '@components/organisms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'

type Props = {
  style: any,
  footerStyle: any,
}

const PageLayout: React.FC<Props> = observer(
  ({ children, rootStyle, style, footerStyle }) => {
    const { authStore } = useStore()
    const failAuth = authStore.failAuth || null

    React.useEffect(() => {
      document.getElementById('scrollableDiv').scrollTo(0, 0)
    }, [])

    return (
      <>
        <Header />
        <Flex style={{ maxWidth: '100vw', width: '100%', ...rootStyle }}>
          <Sidebar />
          <BodyWrapper id="content_area" style={style}>
            {children}
          </BodyWrapper>
        </Flex>
        <Footer style={footerStyle} />
        {/* 비회원이 회원의 기능을 시도했을 때, 로그인 팝업 노출 */}
        {failAuth && <LoginModal />}
      </>
    )
  },
)

export default PageLayout
