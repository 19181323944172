import React, { useLayoutEffect, useState } from 'react'
import { Provider, Observer } from 'mobx-react'

import { Loading } from '@components/molecules'

import { Sounds } from '@components/organisms'
import { Store } from '../stores'
import { PortalWrapper, Toast } from '.'
import Modal from './Modal'

/**
 * Router(모든 페이지) 를 감싸는 Wrapper
 */
export default Component => {
  /* useState 로 리렌더 될 때 스토어 다시 초기화 하지 않도록 클로저를 이용한 전역으로 스토어 선언 */
  const store = new Store()

  const InitializePage = () => {
    const [isInitialized, setIsInitialized] = useState(false)

    // useEffect 전에, store.initClinit()
    useLayoutEffect(() => {
      const fetchInit = async () => {
        await store.initClient()

        // store.initClinit() 후, jwt 검사 => store.authStore.login()
        if (store.authStore.jsonWebToken) {
          await store.authStore.login()
          // await store.authStore.fetchMyJoinedHouse()
        }

        setIsInitialized(true)
      }
      fetchInit()
      return () => {
        // setIsInitialized(false)
      }

      // eslint-disable-next-line
    }, [store])

    if (!isInitialized) {
      return <div />
    }

    return (
      <Provider {...store}>
        <div className="initialComponent" style={{ width: '100vw' }}>
          <Component />
          <Observer>
            {
              () =>
                (!store || !store || store.isLoading) && (
                  <Loading type="spin" color="black" />
                // <Loading type="spin" color="white" />
                )
            }
          </Observer>
          <Sounds />
        </div>
        <PortalWrapper>
          <Modal />
          <Toast />
        </PortalWrapper>
      </Provider>
    )
  }
  return InitializePage
}

// import React from 'react'
// import { Provider, Observer } from 'mobx-react'

// import { Loading } from '@components/molecules'
// import { Store } from '../stores'

// export default Component => {
//   class InitializePage extends React.Component {
//     // static getInitialProps({ req, query, params, state }) {
//     //   return {
//     //     initialState: Component.getInitialProps ? Component.getInitialProps({ query, params }) : null
//     //   }
//     // }

//     constructor(props) {
//       super(props)

//       this.store = new Store()
//       this.store.initClient()
//     }

//     async componentDidMount() {
//       await this.store.authStore.login()
//     }

//     render() {
//       return (
//         <Provider {...this.store}>
//           <Component isServer={this.props.isServer} />

//           <Observer>
//             {
//               () =>
//                 (!this.store || !this.store || this.store.isLoading) && (
//                   <Loading type="spin" color="white" />
//                 )
//             }
//           </Observer>
//         </Provider>
//       )
//     }
//   }
//   return InitializePage
// }
