import { action, computed, observable } from 'mobx'
import { FOLLOWER_ORDER_TYPE, FOLLOWING_ORDER_TYPE, DJ_LEVEL } from '@consts/'
import { Network } from './networks'
import { Store } from '.'
import {
  CastModel,
  FeedModel,
  HousesHaveUsersModel,
  LiveRoomModel,
  MixtapeModel,
  UserModel,
} from './models'

class UserViewModel {
  @observable _elem = null
  @computed get elem() {
    return this._elem
  }
  set elem(value) {
    if (
      this._elem
      && value
      && typeof this._elem === 'object'
      && typeof value === 'object'
    ) {
      Object.keys(value).forEach(key => (this._elem[key] = value[key]))
    }
    else {
      this._elem = value
    }
  }

  screenCount = 0
  constructor(store: Store, props) {
    if (props) {
      this.elem = props.elem || null
      this.screenCount = props.screenCount || 0
    }
  }
}

export default class UserStore {
  // @observable currentUser
  // @observable jsonWebToken
  @observable thisUser
  @observable userDetailFollowerUserList
  @observable userDetailFollowingUserList
  @observable userDetailJoinedHouseList
  @observable userDetailObj = {}
  @observable popularTags

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.thisUser = null
    this.popularTags = []
    this.userDetailFollowerUserList = []
    this.userDetailFollowingUserList = []
    this.userDetailJoinedHouseList = []
  }

  @action.bound
  async initClient() {
    this.thisUser = null
  }

  @action.bound
  updateUser = async (user, params) => {
    if (!params) return

    const res = await this.network.userNetwork.putUser(
      this.store.authStore.currentUser['id'],
      params,
    )

    return res
  }

  @action.bound
  getUserDetailObj = user => {
    if (!user || !user.id) {
      return user
    }

    if (!this.store.userStore.userDetailObj[user.id]) {
      this.store.userStore.userDetailObj[user.id] = new UserViewModel(
        this.store,
        {
          elem: user,
          screenCount: 0,
        },
      )
    }
    // else this.store.userStore.userDetailObj[user['id']]['elem'] = user

    return this.store.userStore.userDetailObj[user.id].elem
  }

  @action follow = async followUser => {
    if (followUser.discJockeyLevel >= DJ_LEVEL['OFFICIAL']) {
      alert('OFFICIAL 계정은 팔로우를 취소할 수 없습니다.')
      return
    }
    this.store.authStore.tryAuth()

    const user = this.store.authStore.currentUser
    if (
      !followUser
      || isNaN(parseInt(followUser.id))
      || !user
      || isNaN(parseInt(user.id))
    ) {
      return
    }

    const usersFollowUsersList = await this.network.userNetwork.postUserFollow(
      user.id,
      [{ followId: followUser.id }],
    )
    if (!usersFollowUsersList) {
      return
    }

    // 로컬 데이터 fetch
    followUser['isFollowed'] = true
    followUser['followerCount'] += 1

    // app 로컬 데이터 관리
    // if (this.followUserList) {
    //   followUser.isFollowed = true

    //   /**
    //    * sync list memory
    //    */
    //   this.followUserList.splice(0, 0, followUser)

    //   /**
    //    * sync obj memory
    //    */
    //   this._syncIsFollowed(followUser.id, true)
    // }
  }
  @action updateFollow = async (followUser, value) => {
    if (followUser.discJockeyLevel >= DJ_LEVEL['OFFICIAL']) {
      alert('OFFICIAL 계정은 팔로우를 취소할 수 없습니다.')
      return
    }
    this.store.authStore.tryAuth()

    const user = this.store.authStore.currentUser
    if (
      !followUser
      || isNaN(parseInt(followUser.id))
      || !user
      || isNaN(parseInt(user.id))
    ) {
      return
    }

    const usersFollowUsersList = await this.network.userNetwork.putUserFollow(
      user.id,
      followUser.id,
      value,
    )
    if (usersFollowUsersList) {
      followUser['UsersFollowedUsers'][0] = usersFollowUsersList
    }

    // 로컬 데이터 fetch
    // followUser['isFollowed'] = true
  }
  @action unFollow = async followUser => {
    if (followUser.discJockeyLevel >= DJ_LEVEL['OFFICIAL']) {
      alert('OFFICIAL 계정은 팔로우를 취소할 수 없습니다.')
      return
    }
    this.store.authStore.tryAuth()

    const user = this.store.authStore.currentUser
    if (
      !followUser
      || isNaN(parseInt(followUser.id))
      || !user
      || isNaN(parseInt(user.id))
    ) {
      return
    }

    const usersFollowUsers = await this.network.userNetwork.deleteUserFollow(
      user.id,
      followUser.id,
    )
    if (!usersFollowUsers) {
      return
    }

    // 로컬 데이터 fetch
    followUser['isFollowed'] = false
    followUser['followerCount'] -= 1
    // app 로컬 데이터 관리
    // if (this.followUserList) {
    //   followUser.isFollowed = false

    //   /**
    //    * sync list memory
    //    */
    //   const index = this.followUserList.findIndex(
    //     (elem) => elem.id === followUser.id,
    //   )
    //   index > -1 &&
    //     index < this.followUserList.length &&
    //     this.followUserList.splice(index, 1)

    //   /**
    //    * sync obj memory
    //    */
    //   this._syncIsFollowed(followUser.id, false)
    // }
  }

  @action.bound
  async uploadImage(file) {
    // return this.store.useLoading(async () => {
    const res = await this.network.userNetwork.uploadUserImage(file)
    return res
    // })
  }

  @action fetchUser = async userId => {
    if (isNaN(parseInt(userId))) {
      return
    }

    const { currentUser } = this.store.authStore

    const params = {
      __addColumn: 'isFollowed',
    }

    if (currentUser?.id) {
      params['__include'] = 'UsersFollowedUsers'
      params['UsersFollowedUsers.userId'] = currentUser.id
    }

    return this.store.useLoading(async () => {
      const user = await this.network.userNetwork.getUser(userId, params)
      if (!user) {
        return
      }

      this.thisUser = new UserModel(this.store, user)

      this.userDetailFollowerUserList = []
      this.userDetailFollowingUserList = []

      return new UserModel(this.store, user)
    })
  }
  @action fetchUserFromAccount = async userAccount => {
    if (!userAccount) {
      return
    }

    const { currentUser } = this.store.authStore

    const params = {
      __addColumn: 'isFollowed',
    }

    if (currentUser?.id) {
      params['__include'] = 'UsersFollowedUsers'
      params['UsersFollowedUsers.userId'] = currentUser.id
    }

    return this.store.useLoading(async () => {
      const user = await this.network.userNetwork.getUserFromAccount(userAccount, params)
      if (!user) {
        return
      }

      this.thisUser = new UserModel(this.store, user)

      this.userDetailFollowerUserList = []
      this.userDetailFollowingUserList = []

      return new UserModel(this.store, user)
    })
  }

  @action getPopularTags = async userId => {
    return this.store.useLoading(async () => {
      const popularTags = await this.network.userNetwork.getPopularTags({
        userId,
      })
      if (!popularTags || popularTags.length === 0) {
        this.popularTags = []
        return
      }
      this.popularTags = popularTags

      // this.syncUserDetailObj({ id: userId, popularTags })

      return popularTags
    })
  }

  /**
   * 해당 유저를 구독한 유저 패치
   * @param {number} userId
   * @param {object} orderType
   * @param {string} searchText
   * @param {object} lastItem
   * @returns followerUserList
   */
  @action fetchFollowerUserList = async ({
    userId,
    orderType = FOLLOWER_ORDER_TYPE[0],
    searchText,
    lastItem,
  }) => {
    if (!userId || isNaN(parseInt(userId))) {
      return []
    }
    // if (!stores.authStore?.isLogined()) return []
    if (lastItem?.isFinished) {
      return []
    }

    const option = {
      __required: 'UsersFollowUsers',
      'UsersFollowUsers.followId': userId,
      __attributes: `id,account,name,imageUri,profileColor,discJockeyLevel,\
UsersFollowUsers.id,UsersFollowUsers.createdAt,UsersFollowUsers.followId`,
      __limit: 20,
      __order: `UsersFollowUsers.createdAtDesc`,
      __pageOrder: 'desc',
      __pageField: 'UsersFollowUsers.createdAt',
      __pageValue: lastItem?.UsersFollowUsers?.[0]?.createdAt,
      __pageIdValue: lastItem?.id,
      __pageLimit: 20,
    }

    if (this.store.authStore?.isLogined?.()) {
      option['__include'] = 'UsersFollowedUsers'
      option[
        'UsersFollowedUsers.userId'
      ] = this.store.authStore?.currentUser?.id
      option['__attributes'] = `id,account,name,imageUri,profileColor,discJockeyLevel,\
UsersFollowUsers.id,UsersFollowUsers.createdAt,UsersFollowUsers.followId,\
UsersFollowedUsers.id,UsersFollowedUsers.userId,UsersFollowedUsers.createdAt`
    }

    if (orderType.title === FOLLOWER_ORDER_TYPE[1].title) {
      option.__order = `UsersFollowUsers.createdAtAsc`
      option.__pageOrder = 'asc'
    }

    if (searchText || searchText?.length > 0) {
      option.name = `%${searchText}%`
      option.nameOperator = 'like'
    }

    const result = await this.network.userNetwork.getUsers(option)

    if (!result || result?.length <= 0) {
      if (lastItem) {
        lastItem.isFinished = true
      }
      return 0
    }

    const followerUserList = result?.map(UserListItem => {
      const user = UserListItem
      if (user?.UsersFollowedUsers?.length > 0) {
        user.isFollowed = true
      }
      return user
      // const syncedUser = this.syncUserDetailObj(user)
      // return syncedUser
    })

    this.userDetailFollowerUserList = this.userDetailFollowerUserList.concat(
      followerUserList,
    )

    return followerUserList.length
  }

  /**
   * 해당 유저가 구독한 유저 패치
   * @param {number} userId
   * @param {object} orderType
   * @param {string} searchText
   * @param {object} lastItem
   * @returns followerUserList
   */
  @action fetchFollowingUserList = async ({
    userId,
    orderType = FOLLOWING_ORDER_TYPE[0],
    searchText,
    lastItem,
  }) => {
    if (!userId || isNaN(parseInt(userId))) {
      return []
    }
    if (lastItem?.isFinished) {
      return []
    }

    const option = {
      __required: 'UsersFollowedUsers',
      'UsersFollowedUsers.userId': userId,
      __attributes: `id,account,name,imageUri,profileColor,discJockeyLevel,\
UsersFollowedUsers.id,UsersFollowedUsers.createdAt,UsersFollowedUsers.userId`,
      __limit: 20,
      __order: `UsersFollowedUsers.createdAtDesc`,
      __pageOrder: 'desc',
      __pageField: 'UsersFollowedUsers.createdAt',
      __pageValue: lastItem?.UsersFollowedUsers?.[0]?.createdAt,
      __pageIdValue: lastItem?.id,
      __pageLimit: 20,
    }

    if (this.store.authStore?.isLogined()) {
      option['__include'] = 'UsersFollowedUsers.Follows.UsersFollowedUsers'
      option[
        'UsersFollowedUsers.Follows.UsersFollowedUsers.userId'
      ] = this.store.authStore?.currentUser?.id
      option['__attributes'] = `id,account,name,imageUri,profileColor,discJockeyLevel,\
UsersFollowedUsers.id,UsersFollowedUsers.createdAt,UsersFollowedUsers.userId,\
UsersFollowedUsers.Follows.id,UsersFollowedUsers.Follows.UsersFollowedUsers.id`
    }

    if (orderType.title === FOLLOWING_ORDER_TYPE[1].title) {
      option.__order = `UsersFollowedUsers.createdAtAsc`
      option.__pageOrder = 'asc'
    }

    if (searchText || searchText?.length > 0) {
      option.name = `%${searchText}%`
      option.nameOperator = 'like'
    }

    const result = await this.network.userNetwork.getUsers(option)

    if (!result || !result?.length > 0) {
      if (lastItem) {
        lastItem.isFinished = true
      }
      return 0
    }
    // const followingUserList = result.map(
    //   (UserListItem) => new UserModel(stores, UserListItem),
    // )

    const followingUserList = result?.map(UserListItem => {
      const user = UserListItem
      if (
        user?.UsersFollowedUsers?.[0]?.Follow?.UsersFollowedUsers?.length > 0
      ) {
        user.isFollowed = true
      }
      return user
      // const syncedUser = this.syncUserDetailObj(user)
      // return syncedUser
    })

    this.userDetailFollowingUserList = this.userDetailFollowingUserList.concat(
      followingUserList,
    )

    return followingUserList.length
  }

  @action fetchFollowingUser = async ({ userId, anotherId }) => {
    if (!userId || isNaN(parseInt(userId))) {
      return []
    }

    const option = {
      __required: 'UsersFollowedUsers',
      'UsersFollowedUsers.userId': userId,
      __attributes: `id,account,name,account,imageUri,profileColor,discJockeyLevel,isOfflineMode,isConnected,isInfluencer,\
UsersFollowedUsers.id,UsersFollowedUsers.createdAt,UsersFollowedUsers.userId`,
      __limit: 1,
      id: anotherId,
    }

    const result = await this.network.userNetwork.getUsers(option)

    const followingUserList = result?.map(UserListItem => {
      const user = UserListItem
      if (
        user?.UsersFollowedUsers?.[0]?.Follow?.UsersFollowedUsers?.length > 0
      ) {
        user.isFollowed = true
      }
      return user
      // const syncedUser = this.syncUserDetailObj(user)
      // return syncedUser
    })

    return followingUserList
  }

  @action fetchUserFollowingCount = async userId => {
    if (!userId) return

    return this.store.useLoading(async () => {
      const result = await this.network.userNetwork.getUserFollowingCount({
        userId,
      })
      if (!result) return

      if (this.thisUser) {
        this.thisUser['followingCount'] = result?.totalCount
      }
      this.userFollowingCount = result?.totalCount

      return result?.totalCount
    })
  }

  @action fetchIsFollowed = async user => {
    if (!user || isNaN(parseInt(user.id))) {
      return
    }

    const fetchedUser = await this.network.userNetwork.getUser(user.id, {
      __addColumn: 'isFollowed',
      __attributes: 'id',
    })
    if (!fetchedUser || !fetchedUser.isFollowed) {
      user.isFollowed = false
      return false
    }
    user.isFollowed = true

    return true
  }

  @action fetchUserJoinedHouse = async userId => {
    if (!userId) return

    return this.store.useLoading(async () => {
      const response = await this.network.userNetwork.getUser(userId, {
        __include: 'HousesHaveUsers.Houses',
        // 'HousesHaveUsers.Houses.isPrivateMemberList': 0,
        'HousesHaveUsers.level': 0,
        'HousesHaveUsers.levelOperator': 'gte',
      })

      if (!response || response.HousesHaveUsers.length === 0) return

      this.userDetailJoinedHouseList = response.HousesHaveUsers
        // .sort((a, b) => a.House.updatedAt > b.House.updatedAt)
        .map(item => new HousesHaveUsersModel(this.store, item))
    })
  }

  @action blockUser = async (userId, blockedUserId) => {
    const result = await this.network.userNetwork.blockUser(userId, [
      {
        blockedUserId,
      },
    ])
    if (!result) {
      return
    }

    return result
  }
  @action deleteBlockUser = async (userId, blockedUserId) => {
    const result = await this.network.userNetwork.deleteBlockUser(
      userId,
      blockedUserId,
    )
    if (!result) {
      return null
    }

    return result
  }

  @action fetchAllBlcokUsers = async (
    lastItem = null,
    // , searchText = ''
  ) => {
    if (!this.store.authStore?.currentUser || lastItem?.isFinished) {
      return
    }

    const options = {
      __required: 'UsersBlockedUsers',
      'UsersBlockedUsers.userId': this.store.authStore?.currentUser?.id,
      __limit: 20,
      __attributes:
        'description,followerCount,id,account,name,account,imageUri,profileColor,discJockeyLevel,isInfluencer',
      __order: 'UsersBlockedUsers.createdAtDesc',
      __pageField: 'UsersBlockedUsers.createdAt',
      __pageOrder: 'desc',
      __pageValue: lastItem?.UsersBlockedUsers?.[0]?.createdAt,
      __pageIdValue: lastItem?.id,
      __pageLimit: 20,
      'UsersBlockedUsers.isSystem': 0,
    }

    // if (searchText?.length > 0) {
    //   options.name = `%${searchText}%`
    //   options.nameOperator = 'like'
    // }

    const result = await this.network.userNetwork.getUsers(options)

    if (!result || result?.length <= 0) {
      if (lastItem) {
        lastItem.isFinished = true
      }

      return null
    }

    const blockUsersList = result.map(val => {
      val.isBlocked = true
      return new UserModel(this.store, val)
    })

    return blockUsersList
  }

  @action fetchBlockUser = async (userId, blockedUserId) => {
    const result = await this.network.userNetwork.getBlockUsers(userId, {
      id: blockedUserId,
    })
    if (!result) {
      return null
    }

    return result
  }

  @action fetchIsBlcoked = async (blockingUserId, blockedUserId) => {
    const result = await this.network.userNetwork.getBlockedUsers(
      blockingUserId,
      blockedUserId,
    )
    if (!result) {
      return false
    }

    return true
  }

  @action fetchHomeRecommendation = async option => {
    // const option = nextCursor
    //   ? {
    //     __limit: limit,
    //     __nextCursor: nextCursor,
    //     __tagKeywords: tagKeywords,
    //   }
    //   : {
    //     __limit: limit,
    //     __tagKeywords: tagKeywords,
    //   }
    try {
      const fetchRecommendation = await this.network.userNetwork.getHomeRecommendation(
        option,
      )
      const recommendationList = fetchRecommendation?.contentList?.map(val => {
        if (val?.dataType === 'Casts') return new CastModel(this.store, val)
        // return stores.castStore.syncCastDetailObj(val)
        if (val?.dataType === 'Mixtapes')
          return new MixtapeModel(this.store, val)
        // return stores.mixtapeStore.syncMixtapeDetailObj(val)
        if (val?.dataType === 'Feeds') return new FeedModel(this.store, val)
        // return stores.feedStore.syncFeedDetailObj(val)
        if (val?.dataType === 'LiveRooms')
          return new LiveRoomModel(this.store, val)
      })

      const temp = {
        contentList: [...recommendationList],
        __nextCursor: fetchRecommendation?.__nextCursor,
      }
      return temp
    }
    catch (error) {
      console.error(error)
    }
  }

  @action fetchUserAccount = async (userId) => {
    if(!userId) {
      return
    }

    const fetchedUser = await this.network.userNetwork.getUser(
      userId,
      {__attributes:'account'},
    )
    if (!fetchedUser?.account) {
      return
    }

    return fetchedUser?.account
  }

}
