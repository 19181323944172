import { Network } from '.'

export default class CommentNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getComments = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/comments',
        'get',
        params,
      )

      return data || []
    }
    catch (err) {
      console.log('CommentNetwork getComments error: ', err)
    }
  }

  /**
   * comments
   * value: [{...CommentModel, Tags: [{ ...TagModel }]}]
   * ex ) [{userId:1...., Tags: [{keyword: '111'}, {keyword: '222}]}]
   */
  postComments = async value => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/comments',
        'post',
        value,
      )
      return data || []
    }
    catch (err) {
      console.log('CommentNetwork postComments error: ', err)
      throw err
    }
  }

  deleteComments = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/${id}`,
        'delete',
      )
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork deleteComments error: ', err)
      throw err
    }
  }

  putComments = async (id, value) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/${id}`,
        'put',
        value,
      )
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork putComments error: ', err)
      throw err
    }
  }

  postCommentsLike = async (commentId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/like`,
        'post',
        {
          commentId,
          userId,
        },
      )

      if (!data) return
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork postCommentsLike error: ', err)
      throw err
    }
  }

  deleteCommentsLike = async (commentId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/like`,
        'delete',
        {
          commentId,
          userId,
        },
      )

      if (!data) return
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork deleteCommentsLike error: ', err)
      throw err
    }
  }

  putCommentsPin = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/pin`,
        'put',
        {
          id,
        },
      )

      if (!data) return
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork putCommentsPin error: ', err)
      throw err
    }
  }

  putCommentsUnpin = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/comments/unpin`,
        'put',
        {
          id,
        },
      )

      if (!data) return
      return data || {}
    }
    catch (err) {
      console.log('CommentNetwork putCommentsUnpin error: ', err)
      throw err
    }
  }
}
