import React, { useEffect } from 'react'
import { Div, Flex, H4, H6 } from '@components/atoms'
import styled, { css, keyframes } from 'styled-components'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { MixtapeDetailPlayListItem } from '@components/molecules'
import { secondToTimeString } from '@utils/time'
import { useMoreWidth, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'

const View = styled(Flex)`
  flex-direction: column;
  max-width: 684px;
  width: 100%;
  height: 800px;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 32px 16px;
  overflow: auto;
`

const ListHeader = styled(Flex)`
  justify-content: space-between;
  height: 64px;
  margin-bottom: 36px;
  padding: 0 10px;
`

const LiveImage = styled.img`
  margin-right: 16px;
  width: 56px;
  height: 28px;
`

const PlayBtn = styled.img`
  width: 64px;
  height: 64px;
  cursor: pointer;
`

const PlayList = styled(Flex)`
  flex-direction: column;
  > div {
    margin: 4px 0;
  }

  > div:first-child {
    margin-top: 0;
  }
`

const marqueeAni = keyframes`
/* from {transform: translate(0, 0);}
to {transform: translate(-100%, 0); } */
/* 0% {transform: translate(0, 0);}
49% {transform: translate(-100%, 0); }
50% {transform: translate(100%, 0); }
100% {transform: translate(0, 0);} */
from   { transform: translate(0%);}
  99%,to { transform: translate(-100%);}
`
const Marquee = styled.div`
  max-width: 468px;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${props =>
    props.haveMore
    && css`
      > h4:after {
        content: '';
        white-space: nowrap;
        padding-right: 50px;
      }

      > h4 {
        margin: 0;
        padding-left: 100%;
        display: inline-block;
        white-space: nowrap;
        animation: ${marqueeAni} 7s linear infinite;
      }
    `}
`

const MixtapeDetailPlayList = ({
  mixtape,
  likeSong,
  disLikeSong,
  category,
  sort,
}) => {
  const { playerStore } = useStore()
  const fetchLoadPlayMixtape = playerStore.fetchLoadPlayMixtape || (() => {})

  const { haveMore } = useMoreWidth({
    defaultWidth: 486,
    id: 'name_box',
    item:
      mixtape
      && mixtape.MixtapesHaveSongs
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex]
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song.name,
  })

  useEffect(() => {
    const scrollBox = document.getElementById('detail_playlist')
    scrollBox.scrollTop = 0
  }, [mixtape])

  return (
    <View id="detail_playlist">
      <ListHeader>
        <Flex
          type="column"
          style={{ height: '100%', justifyContent: 'space-between' }}
        >
          <Flex style={{ alignItems: 'center' }}>
            {/* <LiveImage src={images.live_img} alt="live_img" /> */}
            <Div
              width="44px"
              height="24px"
              fontSize="12px"
              color={colors.white_ff}
              backgroundColor={colors.primary}
              padding="3px 6px"
              borderRadius="4px"
              marginRight="16px"
            >
              PLRA
            </Div>
            <Marquee id="name_box" haveMore={haveMore}>
              <H4 type="Bold" align="left" style={{ whiteSpace: 'nowrap' }}>
                {
                  mixtape
                  && mixtape.MixtapesHaveSongs
                  && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex]
                  && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
                  && `${
                    mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song.name
                  } - ${
                    mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
                      .singer
                  }`
                }
              </H4>
            </Marquee>
          </Flex>
          <Flex>
            <H6 color={colors.primary} style={{ marginRight: '16px' }}>
              {mixtape && secondToTimeString(mixtape.totalPlayTime)}
            </H6>
            <H6 color={colors.brown_grey}>
              {mixtape && mixtape.MixtapesHaveSongs.length}
곡
            </H6>
          </Flex>
        </Flex>
        <PlayBtn
          src={images.mixtape_play_btn_black_img}
          alt="play_btn"
          onClick={() => fetchLoadPlayMixtape(mixtape, category, sort)}
        />
      </ListHeader>
      <PlayList>
        {
          mixtape
          && mixtape.MixtapesHaveSongs.map(song => (
            <MixtapeDetailPlayListItem
              item={song}
              key={song.id}
              isPlaying={song.songOrder === mixtape.songPlayedIndex}
              likeSong={likeSong}
              disLikeSong={disLikeSong}
            />
          ))
        }
      </PlayList>
    </View>
  )
}

export default observer(MixtapeDetailPlayList)
