import { observable, computed } from 'mobx'
import { images } from '@resources'
import { STORAGE_URL } from '@consts/'
import { SongModel, UserModel, CommentModel, TagModel } from '.'

export default class MixtapeModel {
  @observable id
  @observable name
  @observable description
  @observable totalPlayTime = 0
  @observable imageUri
  @observable imageUrl
  @observable imageSource
  @observable isPublished = 0
  @observable publishedAt
  @observable userId

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable User = null
  @observable MixtapesHaveSongs = []
  @observable MixtapeMoods = []
  @observable UsersLikeMixtapes = []

  @observable thumbnailUri
  @observable thumbnailUrl
  @observable thumbnailSource

  @observable isLiked = false
  @observable likeCount = 0
  @observable songCount = 0
  @observable playCount = 0

  @observable commentList = []
  @observable mixtapePlayedTime = 0
  @observable songPlayedTime = 0
  @observable songPlayedIndex = 0

  /**
   * for delay update - 2019.09.09 jhlim
   */
  @observable delayUpdatedAt = null
  @observable nameDelayUpdate
  @observable descriptionDelayUpdate
  @observable totalPlayTimeDelayUpdate
  @observable imageUriDelayUpdate
  @observable imageUrlDelayUpdate
  @observable imageSourceDelayUpdate
  @observable isPublishedDelayUpdate
  @observable publishedAtDelayUpdate
  @observable isRecommendedDelayUpdate

  @observable MixtapesHaveSongsDelayUpdate = []
  @observable MixtapesHaveTags = []
  @observable MixtapesHaveTagsDelayUpdate = []

  @computed get isDelayUpdating() {
    return !this.deletedAt && !!this.delayUpdatedAt
  }
  @computed get isDelayDeleting() {
    return !!this.deletedAt
  }

  @computed get isSetAutoImage() {
    return !(
      (!this.isDelayUpdating
        && this.imageSource.uri
        && this.imageSource.uri.includes('uploads/mixtapes'))
      || (this.isDelayUpdating
        && this.imageSourceDelayUpdate.uri
        && this.imageSourceDelayUpdate.uri.includes('uploads/mixtapes'))
    )
  }

  @observable warningStatus
  @observable linkKey

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.description = props.description
      this.totalPlayTime = (props.totalPlayTime && props.totalPlayTime) || 0
      this.imageUri = props.imageUri
      this.imageUrl = this.imageUri ? STORAGE_URL + this.imageUri : ''
      this.imageSource = this.imageUrl ? { uri: this.imageUrl } : images.mixtape
      this.isPublished = (props.isPublished && props.isPublished) || 0
      this.publishedAt = props.publishedAt
      this.userId = props.userId

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      if (this.imageUri && typeof this.imageUri === 'string') {
        const splitedPath = this.imageUri.split('/')
        const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]

        this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
        this.thumbnailUrl = this.thumbnailUri
          ? STORAGE_URL + this.thumbnailUri
          : ''
        this.thumbnailSource = this.thumbnailUrl && { uri: this.thumbnailUrl }
      }

      this.User =
        (props.User && props.User instanceof UserModel
          ? props.User
          : new UserModel(stores, props.User)) || null
      this.MixtapesHaveSongs =
        (props.MixtapesHaveSongs
          && props.MixtapesHaveSongs.map((mixtapesHaveSongs) => ({
            ...mixtapesHaveSongs,
            Song: mixtapesHaveSongs['Song']
              ? new SongModel(stores, mixtapesHaveSongs['Song'])
              : null,
          })))
        || []
      // this.MixtapeMoods = (props.MixtapeMoods && props.MixtapeMoods.map(mixtapeMoods => new MixtapeMoodModel(stores, mixtapeMoods))) || []
      this.UsersLikeMixtapes =
        (props.UsersLikeMixtapes
          && props.UsersLikeMixtapes.map((usersLikeMixtapes) => ({
            ...usersLikeMixtapes,
            User: usersLikeMixtapes['User']
              ? usersLikeMixtapes['User'] instanceof UserModel
                ? usersLikeMixtapes['User']
                : new UserModel(stores, usersLikeMixtapes['User'])
              : null,
          })))
        || []

      this.isLiked = props.isLiked
      if (
        stores
        && stores.authStore
        && stores.authStore.currentUser
        && stores.authStore.currentUser['id']
      ) {
        const index = this.UsersLikeMixtapes.findIndex(
          (usersLikeMixtapes) =>
            usersLikeMixtapes
            && usersLikeMixtapes['userId'] === stores.authStore.currentUser['id'],
        )
        if (index > -1) this.isLiked = true
      }

      this.likeCount = props.likeCount
      this.playCount = props.playCount
      this.songCount = props.songCount

      this.commentList =
        (props.commentList
          && props.commentList
            .map((comment) => new CommentModel(stores, comment))
            .sort(
              (a, b) =>
                new Date(b['createdAt']).getTime()
                - new Date(a['createdAt']).getTime(),
            ))
        || []

      this.mixtapePlayedTime = props.mixtapePlayedTime || 0
      this.songPlayedTime = props.songPlayedTime || 0
      this.songPlayedIndex = props.songPlayedIndex || 0

      /**
       * for delay update - 2019.09.09 jhlim
       */
      this.delayUpdatedAt = props.delayUpdatedAt
      this.nameDelayUpdate = props.nameDelayUpdate
      this.descriptionDelayUpdate = props.descriptionDelayUpdate
      this.totalPlayTimeDelayUpdate = props.totalPlayTimeDelayUpdate
      this.imageUriDelayUpdate = props.imageUriDelayUpdate
      this.imageUrlDelayUpdate = this.imageUriDelayUpdate
        ? STORAGE_URL + this.imageUriDelayUpdate
        : ''
      this.imageSourceDelayUpdate = this.imageUrlDelayUpdate
        ? { uri: this.imageUrlDelayUpdate }
        : images.mixtape
      this.isPublishedDelayUpdate = props.isPublishedDelayUpdate
      this.publishedAtDelayUpdate = props.publishedAtDelayUpdate
      this.isRecommendedDelayUpdate = props.isRecommendedDelayUpdate

      this.MixtapesHaveSongsDelayUpdate =
        (props.MixtapesHaveSongsDelayUpdate
          && props.MixtapesHaveSongsDelayUpdate.map((mixtapesHaveSongs) => ({
            ...mixtapesHaveSongs,
            Song: mixtapesHaveSongs['Song']
              ? new SongModel(stores, mixtapesHaveSongs['Song'])
              : null,
          })))
        || []

      this.MixtapesHaveTags =
        (props.MixtapesHaveTags
          && props.MixtapesHaveTags.map((mixtapesHaveTag) => ({
            ...mixtapesHaveTag,
            Tag: mixtapesHaveTag['Tag']
              ? new TagModel(stores, mixtapesHaveTag['Tag'])
              : null,
          })))
        || []

      this.MixtapesHaveTagsDelayUpdate =
        (props.MixtapesHaveTagsDelayUpdate
          && props.MixtapesHaveTagsDelayUpdate.map((mixtapesHaveTag) => ({
            ...mixtapesHaveTag,
            Tag: mixtapesHaveTag['Tag']
              ? new TagModel(stores, mixtapesHaveTag['Tag'])
              : null,
          })))
        || []

      this.warningStatus = props.warningStatus
      this.linkKey = props.linkKey
    }
  }
}
