import { observable } from 'mobx'

import { UserModel } from '.'

export default class UsersFollowedUsersModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable userId
  @observable followId
  @observable isFollowForFollow

  @observable level

  @observable User

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.userId = props.userId
      this.followId = props.followId

      this.level = props.level

      this.isFollowForFollow = props.isFollowForFollow

      this.User = props.User && new UserModel(stores, props.User)
      this.Follow = props.Follow && new UserModel(stores, props.Follow)
    }
  }
}
