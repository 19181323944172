import React from 'react'
import { CastSearchBar, CategoryCastList } from '@components/organisms'
import { SearchTagCard } from '@components/molecules'
import { Flex } from '@components/atoms'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { PageLayout } from '..'

const CategoryCastListTemplate = ({
  offset,
  setOffset,
  haveMore,
  setHaveMore,
  category,
  keyword,
  historyCastList,
  popularCastList,
  latestCastList,
  followCastList,
  recommendationCastList,
  searchedCastList,
  fetchHistoryCastList,
  fetchPopularCastList,
  fetchLatestCastList,
  fetchFollowCastList,
  fetchRecommendationCastList,
  fetchSearchCastList,
  popularTag,
  handleSearchTag,
}) => {
  const selectList = _category => {
    switch (_category) {
      case 'history':
        return historyCastList
      case 'popular':
        return popularCastList
      case 'latest':
        return latestCastList
      case 'follow':
        return followCastList
      case 'recommendation':
        return recommendationCastList
      case 'search':
        return searchedCastList
      default:
        return []
    }
  }
  const selectTitle = _category => {
    switch (_category) {
      case 'history':
        return '최근 들은 캐스트'
      case 'popular':
        return '인기 캐스트'
      case 'latest':
        return '최신 캐스트'
      case 'follow':
        return '팔로우한 유저의 캐스트'
      case 'recommendation':
        return '추천 캐스트'
      case 'search':
        return '검색 결과'
      default:
        return []
    }
  }
  return (
    <PageLayout style={{ paddingTop: '10' }}>
      {/* 나중에 검색 추가시 추가 */}
      <CastSearchBar
        haveMore={haveMore}
        setHaveMore={setHaveMore}
        setOffset={setOffset}
      />

      <SearchTagCardList>
        {
          popularTag
          && popularTag.map(tag => (
            <SearchTagCard
              key={`tag-${tag?.id}`}
              tag={tag.keyword}
              onClick={_tag => handleSearchTag(tag.keyword)}
            />
          ))
        }
      </SearchTagCardList>

      <CategoryCastList
        offset={offset}
        setOffset={setOffset}
        haveMore={haveMore}
        setHaveMore={setHaveMore}
        category={category}
        keyword={keyword}
        title={selectTitle(category)}
        fetchHistoryCastList={fetchHistoryCastList}
        fetchPopularCastList={fetchPopularCastList}
        fetchLatestCastList={fetchLatestCastList}
        fetchFollowCastList={fetchFollowCastList}
        fetchRecommendationCastList={fetchRecommendationCastList}
        fetchSearchCastList={fetchSearchCastList}
        list={selectList(category)}
      />
    </PageLayout>
  )
}

export default CategoryCastListTemplate

const SearchTagCardList = styled(Flex)`
  margin: 32px 0 40px 0;
  width: 100%;

  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
