import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex, Button, Caption1, Div } from '@components/atoms'
import { SalonMixtapeItem, SwitchTabList } from '@components/molecules'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { colors } from '@resources/colors'
import MyUploadAudioSection from '../section/MyUploadAudioSection'

const LIVE_ROOM_HREUM_TAG_LIST = [
  { id: null, keyword: '전체' },
  { id: 543, keyword: '댄스' },
  { id: 306, keyword: '랩' },
  { id: 507, keyword: '발라드' },
  { id: 1410, keyword: '소울' },
  { id: 905, keyword: '아이돌' },
  { id: 377, keyword: '알앤비' },
  { id: 530, keyword: '인디' },
  { id: 1150, keyword: '재즈' },
  { id: 858, keyword: '팝' },
  { id: 282, keyword: '힙합' },
]

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  /* padding: 24px 26px 16px 26px; */
  padding: 24px 0 16px 0;
`

const CustomInifiniteScroll = styled(InfiniteScroll)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle }) => {
  const { liveRoomStore, authStore, playerStore } = useStore()

  const popularMixtapeData = liveRoomStore.popularMixtapeData || null
  const likedMixtapeData = liveRoomStore.likedMixtapeData || null
  const currentUser = authStore.currentUser || null

  const [switchIndex, setSwitchIndex] = React.useState(0)
  const [selectedSong, setSelectedSong] = React.useState(null)
  const [selectedMixtape, setSelectedMixtape] = React.useState(null)
  const [currTag, setCurrTag] = React.useState(LIVE_ROOM_HREUM_TAG_LIST[0])

  const handleSwitch = useCallback(
    index => {
      setSwitchIndex(index)
    },
    [switchIndex],
  )

  const handleSelected = mixtape => {
    if (mixtape && selectedMixtape && mixtape['id'] === selectedMixtape['id'])
      setSelectedMixtape(null)
    else setSelectedMixtape(mixtape)
  }

  const handlePlaySelected = useCallback(async () => {
    // 오디오일 때,
    if (switchIndex === 2) {
      await liveRoomStore.addAudioInLiveRoom(selectedSong)
      toggle()
    }
    // 플라일 때,
    else {
      await liveRoomStore.addMixtapeInLiveRoom(selectedMixtape)
      toggle()
    }
  }, [switchIndex, selectedSong, selectedMixtape])

  const fetchScroll = () => {
    if (switchIndex === 0) {
      if (currTag.id) {
        liveRoomStore.fetchLiveRoomTagPopularMixtapeData(currTag.keyword, false)
      }
      // 전체 검색
      else {
        liveRoomStore.fetchLiveRoomPopularMixtapeData(false)
      }
    }
    else if (currTag.id) {
      liveRoomStore.fetchLiveRoomTagLikedMixtapeData(
        currentUser['id'],
        currTag.id,
        false,
      )
    }
    // 전체 검색
    else {
      liveRoomStore.fetchLiveRoomLikedMixtapeData(currentUser['id'], false)
    }
  }

  React.useEffect(() => {
    // 인기 플라
    if (switchIndex === 0 && isShowing) {
      // 태그 검색
      if (currTag.id) {
        liveRoomStore.fetchLiveRoomTagPopularMixtapeData(currTag.keyword, true)
      }
      // 전체 검색
      else {
        liveRoomStore.fetchLiveRoomPopularMixtapeData(true)
      }
    }
    // 좋아하는 플라
    else if (switchIndex === 1 && isShowing) {
      if (currTag.id) {
        liveRoomStore.fetchLiveRoomTagLikedMixtapeData(
          currentUser['id'],
          currTag.id,
          true,
        )
      }
      // 전체 검색
      else {
        liveRoomStore.fetchLiveRoomLikedMixtapeData(currentUser['id'], true)
      }
    }
  }, [isShowing, switchIndex, liveRoomStore, currTag, currentUser])

  React.useEffect(() => {
    if (isShowing) {
      if (
        playerStore.currentPlayMixtape
        && !liveRoomStore.currentPlayingLiveRoomAudio
      ) {
        setSelectedMixtape(playerStore.currentPlayMixtape)
      }
      else {
        setSelectedMixtape(null)
      }

      if (liveRoomStore.currentPlayingLiveRoomAudio) {
        setSelectedSong(liveRoomStore.currentPlayingLiveRoomAudio)
      }
      else {
        setSelectedSong(null)
      }
    }
  }, [isShowing, playerStore, liveRoomStore])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H6 style={{ marginBottom: 12 }}>재생할 플라 선택</H6>
              <SwitchTabList
                headerStyle={{ padding: '0 26px' }}
                switchIndex={switchIndex}
                handleSwitch={handleSwitch}
                switchTitleList={['인기 플라', '좋아하는 플라', '오디오']}
                switchContentList={
                  [
                    <Flex type="column">
                      <TagList>
                        {
                          LIVE_ROOM_HREUM_TAG_LIST.map(tag => (
                            <TagBox
                              key={tag.id}
                              onClick={() => setCurrTag(tag)}
                              active={currTag === tag}
                            >
                              {tag.keyword}
                            </TagBox>
                          ))
                        }
                      </TagList>
                      <CustomInifiniteScroll
                        dataLength={popularMixtapeData.list.length}
                        scrollThreshold={0.8}
                        next={fetchScroll}
                        hasMore={!popularMixtapeData.isFinished}
                        scrollableTarget="scrollableDiv"
                        height={560}
                        loader={
                          <ReactLoading
                            type="spin"
                            color="black"
                            style={
                              {
                                width: '50px',
                                height: '50px',
                                margin: '0 auto 50px auto',
                              }
                            }
                          />
                        }
                      >
                        {
popularMixtapeData?.list?.length === 0 ? (
  <Caption1 style={{ marginTop: 100 }} color="#bfbfbf">
                            인기 플라가 없습니다 :)
  </Caption1>
) : (
                          popularMixtapeData?.list?.map(item => (
                            <SalonMixtapeItem
                              item={item}
                              key={item.id}
                              selected={
                                selectedMixtape
                                && selectedMixtape['id'] === item.id
                              }
                              onSelect={handleSelected}
                            />
                          ))
)
                        }
                      </CustomInifiniteScroll>
                    </Flex>,
                    <Flex type="column">
                      <TagList>
                        {
                          LIVE_ROOM_HREUM_TAG_LIST.map(tag => (
                            <TagBox
                              key={tag.id}
                              onClick={() => setCurrTag(tag)}
                              active={currTag === tag}
                            >
                              {tag.keyword}
                            </TagBox>
                          ))
                        }
                      </TagList>
                      <CustomInifiniteScroll
                        dataLength={likedMixtapeData.list.length}
                        scrollThreshold={0.8}
                        next={fetchScroll}
                        hasMore={!likedMixtapeData.isFinished}
                        scrollableTarget="scrollableDiv"
                        height={560}
                        loader={
                          <ReactLoading
                            type="spin"
                            color="black"
                            style={
                              {
                                width: '50px',
                                height: '50px',
                                margin: '0 auto 50px auto',
                              }
                            }
                          />
                        }
                      >
                        {
likedMixtapeData?.list?.length === 0 ? (
  <Caption1 style={{ marginTop: 100 }} color="#bfbfbf">
                            좋아하는 플라가 없습니다 :)
  </Caption1>
) : (
                          likedMixtapeData?.list?.map(item => (
                            <SalonMixtapeItem
                              item={item}
                              key={item.id}
                              selected={
                                selectedMixtape
                                && selectedMixtape['id'] === item.id
                              }
                              onSelect={handleSelected}
                            />
                          ))
)
                        }
                      </CustomInifiniteScroll>
                    </Flex>,
                    <Div display="flex" flexDirection="column" padding="0 20px">
                      <MyUploadAudioSection
                        listHeight={568}
                        from={'live'}
                        handleSelectedSong={song => setSelectedSong(song)}
                        selectedSong={selectedSong}
                      />
                    </Div>,
                  ]
                }
              />
              <SelectedBtn
                onClick={handlePlaySelected}
                disabled={
                  switchIndex === 2 ? !selectedSong : !selectedMixtape
                }
              >
                {`선택한 ${switchIndex === 2 ? '오디오' : '플라'} 재생`}
              </SelectedBtn>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}

const TagList = styled(Flex)`
  display: flex;
  width: calc(100% + 26px);
  overflow-x: auto;
  height: 32px;
  margin-bottom: 16px;

  > div {
    margin-right: 8px;
  }
  > div:first-child {
    margin-left: 26px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`

const TagBox = styled(Flex)`
  transition: 0.35s;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;

  height: 32px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 18px;
  background-color: ${({ active }) =>
    active ? colors.primary : colors.white_f4};
  color: ${({ active }) => (active ? colors.white_ff : colors.grey_94)};

  cursor: pointer;
`

const SelectedBtn = styled(Button)`
  width: calc(100% - 16px);
  height: 48px;
  border-radius: 24px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 14px;

  :disabled {
    background-color: ${colors.white_f4};
    color: ${colors.grey_d4};
  }
`
