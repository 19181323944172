import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.type && props.type};
  justify-content: ${props => props.justify && props.justify};
  align-items: ${props => props.align && props.align};
`

export default Flex
