import { action, observable, computed } from 'mobx'
import { secondToTimeString } from '@utils/time'
import { images } from '@resources/'
import { message } from 'antd'
import {
  DJ_LEVEL,
  MIXTAPE_WARNING_STATUS,
  MIXTAPES_HAVE_TAGS_LEVEL,
  STORAGE_URL,
} from '@consts/'
import { MixtapeModel } from './models'
import { Store } from '.'
import { Network } from './networks'

export default class MixtapeStore {
  @observable wholeMixtapeList // 전체 흐름 목록
  @observable myMixtapeList // 나의 흐름 리스트
  @observable myMixtapeListCount // 나의 흐름 전체 row 수

  @observable userMixtapeList // 특정 유저의 흐름 목록

  @observable _tempMixtape
  @computed get tempMixtape() {
    return this._tempMixtape
  }
  set tempMixtape(value) {
    if (
      this._tempMixtape
      && value
      && typeof this._tempMixtape === 'object'
      && typeof value === 'object'
    ) {
      Object.keys(value).forEach(key => (this._tempMixtape[key] = value[key]))
    }
    else {
      this._tempMixtape = value
    }
  }

  @observable blackListUser

  @observable myListenedMixtapeList // 기록 흐름 리스트
  @observable totalMixtapeList // 전체 흐름 리스트
  @observable myLikeMixtapeList // 좋아하는 흐름 리스트
  @observable myFollowMixtapeList // 구독한 DJ의 흐름 리스트
  @observable mixtapeDetail // 상세 흐름

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.wholeMixtapeList = {}
    this.myMixtapeList = []
    this.userMixtapeList = []
    this.myMixtapeListCount = 0
    this.tempMixtape = new MixtapeModel(this.store, this.tempMixtape)

    this.myListenedMixtapeList = []
    this.totalMixtapeList = []
    this.myLikeMixtapeList = []
    this.myFollowMixtapeList = []

    this.blackListUser = []
  }

  @action.bound
  async initClient() {}

  @action.bound
  initDetail = () => {
    this.mixtapeDetail = null
  }

  @action.bound
  async fetchMyMixtapeList(__offset = 0, __limit = 4) {
    // return this.store.useLoading(async () => {
    const resList = await this.network.mixtapeNetwork.getMixtapeList({
      userId: this.store.authStore.currentUser.id,
      __order: 'createdAtDesc',
      __pageOrder: 'createdAtDesc',
      __include: 'MixtapesHaveTags.Tags,Users',
      __addColumn: 'likeCount,playCount',
      __offset,
      __limit,
      'MixtapesHaveTags.level': MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
      __attributes: `id,linkKey,userId,createdAt,name,totalPlayTime,imageUri,warningStatus,isPublished,delayUpdatedAt,deletedAt,publishedAt,likeCount,playCount,mixtapesHaveSongsUpdatedAt,\
Users.name,Users.imageUri,Users.id,Users.account,\
MixtapesHaveTags.id,MixtapesHaveTags.level,MixtapesHaveTags.Tags.id,MixtapesHaveTags.Tags.keyword`,
    })

    if (__offset < 1) {
      const listCount = await this.network.mixtapeNetwork.getMixtapesCount({
        userId: this.store.authStore.currentUser.id,
        // __order: 'createdAtDesc',
        // __required: 'MixtapesHaveSongs,MixtapesHaveTags.Tags,Users',
        // __addColumn: 'likeCount,playCount',
        // __offset,
        // __limit,
      })
      this.myMixtapeListCount = listCount.length === 0 ? 0 : listCount
    }

    const list = resList.map((item, index) => {
      item.MixtapesHaveTags =
        resList[index]
        && resList[index].MixtapesHaveTags
        && resList[index].MixtapesHaveTags
      item.MixtapesHaveTagsDelayUpdate =
        resList[index]
        && resList[index].MixtapesHaveTagsDelayUpdate
        && resList[index].MixtapesHaveTagsDelayUpdate
      item.User = resList[index] && resList[index].User && resList[index].User
      return item
    })

    if (list) {
      this.myMixtapeList = list.map(item => new MixtapeModel(this.store, item))
      return !!this.myMixtapeList
    }
    // })
  }

  @action.bound
  async fetchUserMixtapeList({
    lastItem,
    __limit = 4,
    userId,
    popularChartLevel,
  }) {
    // return this.store.useLoading(async () => {
    const resList = await this.network.mixtapeNetwork.getMixtapeList({
      userId,
      __order: 'createdAtDesc',
      __include: 'MixtapesHaveSongs,MixtapesHaveTags.Tags,Users',
      isPublished:
        this.store.authStore.currentUser
        && userId === this.store.authStore.currentUser['id']
          ? 0
          : 1,
      __addColumn: 'likeCount,playCount',
      // __offset,
      __limit,
      'MixtapesHaveTags.level': MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
      __attributes: `id,linkKey,userId,createdAt,name,totalPlayTime,imageUri,warningStatus,isPublished,delayUpdatedAt,deletedAt,publishedAt,likeCount,playCount,mixtapesHaveSongsUpdatedAt,\
Users.name,Users.imageUri,Users.id,Users.account,\
MixtapesHaveTags.id,MixtapesHaveTags.level,MixtapesHaveTags.Tags.id,MixtapesHaveTags.Tags.keyword`,
      __pageIdValue: lastItem?.id,
      __pageValue: lastItem?.createdAt,
      __pageOrder: 'desc',
      __pageLimit: __limit,
      __pageField: 'createdAt',
      popularChartLevel,
    })

    if (!resList) {
      this.userMixtapeList = []
      return
    }

    const list = resList.map((item, index) => {
      item.MixtapesHaveTags =
        resList[index]
        && resList[index].MixtapesHaveTags
        && resList[index].MixtapesHaveTags
      item.MixtapesHaveTagsDelayUpdate =
        resList[index]
        && resList[index].MixtapesHaveTagsDelayUpdate
        && resList[index].MixtapesHaveTagsDelayUpdate
      item.User = resList[index] && resList[index].User && resList[index].User
      return item
    })

    if (list) {
      this.userMixtapeList = this.userMixtapeList.concat(
        list.map(item => new MixtapeModel(this.store, item)),
      )
      return list.length
    }
    // })
  }

  /**
   * 흐름 비공개/공개 액션 흐름 수정 액션에서 분리
   * @param {*} mixtape
   */
  @action.bound
  async updatePublishMixtape(mixtape) {
    if (mixtape['warningStatus'] === MIXTAPE_WARNING_STATUS.UNPUBLISHABLE) {
      alert('경고일 기준 7일 후 공개가 가능합니다.')
      return false
    }
    return this.store.useLoading(async () => {
      if (
        typeof mixtape['isPublished'] === 'boolean'
        || typeof mixtape['isPublished'] === 'number'
      ) {
        const publishedMixtape = await this.network.mixtapeNetwork.putMixtape(
          mixtape['id'],
          { isPublished: mixtape['isPublished'], publishedAt: new Date() },
        )
        if (!publishedMixtape) return
      }

      alert('플라가 수정되었습니다.')
      await this.fetchMyMixtapeList()
    })
  }

  @action.bound
  async updateMixtape(mixtape) {
    return this.store.useLoading(async () => {
      if (!mixtape || !mixtape['id']) return
      if (
        !this.store
        || !this.store.authStore
        || !this.store.authStore.currentUser
        || !this.store.authStore.currentUser['id']
      )
        return

      const {
        name,
        description,
        imageUri,
        // isPublished,
        publishedAt,
        MixtapesHaveSongs,
        genreTagList,
        normalTagList,
      } = mixtape

      const filteredMixtapesHaveSongsList =
        MixtapesHaveSongs
        && MixtapesHaveSongs.filter(
          mixtapesHaveSongs => !!mixtapesHaveSongs['songId'],
        )

      const value = {
        name,
        description,
        imageUri,
        // isPublished,
        publishedAt,
        userId: this.store.authStore.currentUser['id'],
        genreTagList,
        normalTagList,
        mixtapesHaveSongsList:
          filteredMixtapesHaveSongsList
          && filteredMixtapesHaveSongsList.map((mixtapesHaveSongs, index) => ({
            songId: mixtapesHaveSongs['songId'],
            songOrder: index,
          })),
      }

      const newMixtape = await this.network.mixtapeNetwork.putMixtape(
        mixtape['id'],
        value,
      )
      if (!newMixtape) return

      // await this.replaceMixtapeSongList(newMixtape, MixtapesHaveSongs)
      newMixtape['totalPlayTime'] = mixtape['totalPlayTime']

      if (mixtape['warningStatus'] === MIXTAPE_WARNING_STATUS.UNPUBLISHABLE) {
        alert('경고일 기준 7일 후 공개가 가능합니다.')
        return newMixtape
      }
      if (
        typeof mixtape['isPublished'] === 'boolean'
        || typeof mixtape['isPublished'] === 'number'
      ) {
        const publishedMixtape = await this.network.mixtapeNetwork.putMixtape(
          mixtape['id'],
          { isPublished: mixtape['isPublished'] },
        )
        if (!publishedMixtape) return
      }

      alert('플라가 수정되었습니다.')
      await this.fetchMyMixtapeList()
      return newMixtape
    })
  }

  // @action.bound
  // async replaceMixtapeSongList(mixtape, mixtapesHaveSongsList) {
  //   if (!mixtape || !mixtapesHaveSongsList) return
  //   const filteredMixtapesHaveSongsList = mixtapesHaveSongsList.filter(
  //     mixtapesHaveSongs => !!mixtapesHaveSongs['songId'],
  //   )
  //   // if (!(filteredMixtapesHaveSongsList.length > 0)) return

  //   const _mixtapesHaveSongsList = await this.network.mixtapeNetwork.postMixtapeSongList(
  //     mixtape['id'],
  //     filteredMixtapesHaveSongsList.map((mixtapesHaveSongs, index) => ({
  //       songId: mixtapesHaveSongs['songId'],
  //       songOrder: index,
  //     })),
  //   )
  //   if (!_mixtapesHaveSongsList || !(_mixtapesHaveSongsList.length > 0)) return

  //   return _mixtapesHaveSongsList
  // }

  @action.bound
  async deleteMixtape(mixtapeId) {
    if (!mixtapeId) return

    const deletedMixtape = await this.network.mixtapeNetwork.deleteMixtape(
      mixtapeId,
    )
    if (!deletedMixtape) return

    await this.fetchMyMixtapeList()
  }

  @action.bound
  async addMixtapeSongListToTempMixtape(mixtape, songList) {
    if (!mixtape || !mixtape['MixtapesHaveSongs'] || !songList) return
    const filteredSongList = songList.filter(song => !!song['id'])
    if (!(filteredSongList.length > 0)) return

    if (!songList['m3u8Uri']) {
      const fetchedSong = await this.network.songNetwork.getSong(
        songList[0]['id'],
      )
      if (fetchedSong && !fetchedSong.m3u8Uri) {
        message.error('컨버팅 중인 곡입니다. 잠시 후 다시 이용해주세요.')
        return
      }
    }

    /**
     * 컨버팅 중 인지 체크
     */

    /**
     * 오디오 중복체크. 일단 오디오 1개 추가하는 것만 적용
     */
    const isExist = mixtape['MixtapesHaveSongs'].find(elem => {
      return elem['songId'] === songList[0]['id']
    })
    if (isExist !== undefined) {
      alert(`이미 현재 플라에 추가된 오디오입니다.`)
      return
    }

    const res = await this._addTotalPlayTime(mixtape, filteredSongList)
    if (!res) return

    /**
     * 로컬에 오디오 추가
     */
    const mixtapesHaveSongsList = filteredSongList.map((song, index) => ({
      songId: song['id'],
      songOrder: mixtape['MixtapesHaveSongs'].length + index,
      Song: song,
    }))
    if (!mixtape['MixtapesHaveSongs']) {
      mixtape['MixtapesHaveSongs'] = mixtapesHaveSongsList
    }
    else {
      mixtape['MixtapesHaveSongs'].push(...mixtapesHaveSongsList)
    }

    /**
     * 1. 처음에 아무것도 입력 안한경우 : imageSource = 37
     * 2. 직접 이미지를 입력한 경우는 패스 : uri: ...mixtapes/images ...
     * 3. 첫곡 추가가 아닌 경우는 패스
     * 4. 곡에도 이미지가 없는 경우는 패스
     */
    if (
      (mixtape
        && mixtape.MixtapesHaveSongs.length === 1
        && (mixtape.imageSource === images.mixtape
          || mixtape.imageSource === undefined))
      || !(
        mixtape.imageSource
        && mixtape.imageSource.uri
        && mixtape.imageSource.uri.includes('uploads/mixtapes')
      )
    ) {
      if (
        mixtape.MixtapesHaveSongs[0].Song._imageUri !== ''
        && mixtape.MixtapesHaveSongs[0].Song._imageUri !== undefined
        && mixtape.MixtapesHaveSongs[0].Song._imageUri !== null
      ) {
        mixtape.imageUri = mixtape.MixtapesHaveSongs[0].Song._imageUri
        mixtape.imageSource = { uri: STORAGE_URL + mixtape.imageUri }
      }
    }

    message.success(
      `오디오가 추가되었습니다.\n총 재생시간: ${secondToTimeString(
        mixtape['totalPlayTime'],
      )}`,
    )
  }

  @action.bound
  async _addTotalPlayTime(mixtape, songList) {
    if (!mixtape || isNaN(parseInt(mixtape['totalPlayTime'], 10)) || !songList)
      return
    const filteredSongList = songList.filter(song => !!song['id'])
    if (!(filteredSongList.length > 0)) return

    /**
     * 오디오 duration 체크
     */
    await this.store.songStore.fetchDurationList(filteredSongList)

    /**
     * 로컬에 totalPlayTime 업데이트
     */
    const totalPlayTime = filteredSongList.reduce((_sum, elem) => {
      _sum += parseInt(elem['duration'], 10) || 0
      return _sum
    }, 0)
    // if (mixtape.totalPlayTime + totalPlayTime > LIMIT_MIXTAPE_SECONDS) {
    //   showToast(`추가할 수 없습니다.\nFlow가 ${LIMIT_MIXTAPE_MINUTES}분을 초과합니다.`)
    //   return
    // }
    mixtape.totalPlayTime += totalPlayTime
    return mixtape
  }

  @action.bound
  async deleteMixtapeSongToTempMixtape(mixtape, index) {
    if (!mixtape || !mixtape['MixtapesHaveSongs'] || !(index >= 0)) return

    const mixtapesHaveSongsList = mixtape['MixtapesHaveSongs'].splice(index, 1)
    if (
      !mixtapesHaveSongsList
      || !(mixtapesHaveSongsList.length > 0)
      || isNaN(parseInt(mixtapesHaveSongsList[0]['songId'], 10))
    )
      return

    /**
     * update totalPlayTime
     */
    if (isNaN(parseInt(mixtape['totalPlayTime'], 10))) {
      mixtape['totalPlayTime'] = 0
      return
    }

    let duration = 0
    if (
      !mixtapesHaveSongsList[0]['Song']
      || !mixtapesHaveSongsList[0]['Song']['duration']
    ) {
      const songList = await this.store.songStore.fetchDurationList([
        { id: mixtapesHaveSongsList[0]['songId'] },
      ])
      if (!songList || !(songList.length > 0)) return

      duration = songList[0]['duration']
    }
    else {
      duration = mixtapesHaveSongsList[0]['Song']['duration']
    }

    mixtape['totalPlayTime'] -= duration
    if (mixtape['totalPlayTime'] < 1) mixtape['totalPlayTime'] = 0

    if (index === 0 && mixtape.isSetAutoImage) {
      // const sendData = {
      //   imageUri: mixtape.MixtapesHaveSongs.length === 0 ? null : mixtape.MixtapesHaveSongs[0].Song._imageUri
      // }

      // const result = await this.network.mixtapeNetwork.putMixtape(mixtape['id'], sendData)
      // if (!result) return

      if (mixtape.MixtapesHaveSongs.length === 0) {
        mixtape.imageUri = null
        mixtape.imageUriDelayUpdate = null
        // mixtape.imageSource = images.mixtape
        // mixtape.imageUrl = ''
        // mixtape.thumbnailSource = undefined
        mixtape.thumbnailUri = undefined
        // mixtape.thumbnailUrl = undefined
      }
      // else if (
      //   !(
      //     mixtape.imageSource.uri &&
      //     mixtape.imageSource.uri.includes('uploads/mixtapes')
      //   )
      // ) {
      //   mixtape.imageUri = mixtape.MixtapesHaveSongs[0].Song._imageUri
      //   mixtape.imageUrl = mixtape.imageUri
      //     ? STORAGE_URL + mixtape.imageUri
      //     : ''
      //   mixtape.imageSource = mixtape.imageUrl
      //     ? { uri: mixtape.imageUrl }
      //     : images.mixtape
      //   if (mixtape.imageUri) {
      //     const splitedPath = mixtape.imageUri.split('/')
      //     const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]

      //     mixtape.thumbnailUri = `${splitedPath.join(
      //       '/',
      //     )}/thumbnail/${filename}`
      //     mixtape.thumbnailUrl = mixtape.thumbnailUri
      //       ? STORAGE_URL + mixtape.thumbnailUri
      //       : ''
      //     mixtape.thumbnailSource = mixtape.thumbnailUrl && {
      //       uri: mixtape.thumbnailUrl,
      //     }
      //   }
      // }
      else {
        mixtape.imageUri = mixtape.MixtapesHaveSongs[0].Song._imageUri
        mixtape.imageUriDelayUpdate =
          mixtape.MixtapesHaveSongs[0].Song._imageUri
      }
    }
    message.error(
      `오디오가 삭제되었습니다.\n총 재생시간: ${secondToTimeString(
        mixtape['totalPlayTime'],
      )}`,
    )
  }

  @action.bound
  async replaceMixtapeSongListToTempMixtape(mixtape, songList) {
    if (!mixtape || !songList) return

    const mixtapesHaveSongsList = await songList.map((item, index) => ({
      songId: item.Song['id'],
      songOrder: index,
      Song: item.Song,
    }))

    mixtape['MixtapesHaveSongs'] = mixtapesHaveSongsList

    if (
      (mixtape
        && mixtape.MixtapesHaveSongs.length !== 0
        && (mixtape.imageSource === images.mixtape
          || mixtape.imageSource === undefined))
      || !(
        mixtape.imageSource
        && mixtape.imageSource.uri
        && mixtape.imageSource.uri.includes('uploads/mixtapes')
      )
    ) {
      if (
        mixtape.MixtapesHaveSongs[0].Song._imageUri !== ''
        && mixtape.MixtapesHaveSongs[0].Song._imageUri !== undefined
        && mixtape.MixtapesHaveSongs[0].Song._imageUri !== null
      ) {
        mixtape.imageUri = mixtape.MixtapesHaveSongs[0].Song._imageUri
        mixtape.imageSource = { uri: STORAGE_URL + mixtape.imageUri }
      }
    }
  }

  @action.bound
  async uploadMixtapeImage(file) {
    if (!file) return

    const res = await this.network.mixtapeNetwork.uploadMixtapeImage(file)
    this.tempMixtape.imageUri = res.imageUri
    this.tempMixtape = new MixtapeModel(this.store, this.tempMixtape)

    return res
  }

  @action.bound
  async createMixtape(mixtape) {
    return this.store.useLoading(async () => {
      if (!mixtape) return
      if (
        !this.store
        || !this.store.authStore
        || !this.store.authStore.currentUser
        || !this.store.authStore.currentUser['id']
      )
        return

      const {
        name,
        description,
        imageUri,
        MixtapesHaveSongs,
        isPublished,
        genreTagList,
        normalTagList,
      } = mixtape

      const filteredMixtapesHaveSongsList = MixtapesHaveSongs.filter(
        mixtapesHaveSongs => !!mixtapesHaveSongs['songId'],
      )

      const value = {
        name,
        description,
        imageUri,
        userId: this.store.authStore.currentUser['id'],
        isPublished,
        publishedAt: new Date(),
        genreTagList,
        normalTagList,
        mixtapesHaveSongsList: filteredMixtapesHaveSongsList.map(
          (mixtapesHaveSongs, index) => ({
            songId: mixtapesHaveSongs['songId'],
            songOrder: index,
          }),
        ),
      }

      const newMixtapeList = await this.network.mixtapeNetwork.postMixtape([
        value,
      ])
      if (!newMixtapeList || !(newMixtapeList.length > 0)) return
      const newMixtape = newMixtapeList[0]

      // await this.replaceMixtapeSongList(newMixtape, MixtapesHaveSongs)

      alert('플라가 생성되었습니다.')
      return newMixtape
    })
  }

  @action.bound
  async applyMixtapeForDJ(mixtape) {
    if (
      mixtape
      && this.store.authStore.currentUser.discJockeyLevel < DJ_LEVEL['BABY']
    ) {
      const { currentUser } = this.store.authStore
      const email =
        currentUser && currentUser.email ? currentUser.email : 'error'

      const id = currentUser && currentUser.id ? currentUser.id : 'error'
      const mixtapeId =
        mixtape && mixtape.id
          ? mixtape.id
          : this.tempMixtape.id
            ? this.tempMixtape.id
            : 'error'
      const mixtapeTitle = mixtape && mixtape.name ? mixtape.name : 'error'
      const mixtapeDescription =
        mixtape && mixtape.description ? mixtape.description : 'error'

      const text = `\n<br>\n<br> [사용자 이메일]\n<br>${email}\n<br>\n<br> [사용자 기기 appIdentifier]\n<br>PC\n<br>\n<br> [사용자 아이디]\n<br>${id}\n<br>\n<br> [흐름 아이디]\n<br>${mixtapeId}\n<br>\n<br> [플라 제목]\n<br>${mixtapeTitle}\n<br>\n<br> [플라 설명]\n<br>${mixtapeDescription}\n<br>\n<br>\n<br>`

      const res = await Promise.all([
        this.store.customerCenterStore.sendFeedback({
          type: '흐름 DJ 신청',
          message: text,
        }),
        this.store.userStore.updateUser(currentUser, {
          discJockeyLevel: DJ_LEVEL['APPLICANT'],
        }),
      ])

      if (res[0] && res[1]) {
        message.success(
          'DJ 신청이 완료되었습니다. 영업일 기준 최대 24시간 내에 반영됩니다.\n24시간 내에 반영이 안 된 경우\n info@hreum.me로 문의바랍니다.',
        )
      }
    }
  }

  @action.bound
  async getTempMixtape(mixtapeId) {
    if (!mixtapeId) return
    if (mixtapeId === 'reset') {
      this.tempMixtape = new MixtapeModel(this.store, [])
      return
    }

    const result = await this.network.mixtapeNetwork.getMixtape(mixtapeId, {
      __include: 'MixtapesHaveSongs.Songs,Users,MixtapesHaveTags.Tags',
      __addColumn: 'likeCount,playCount,mixtapePlayedTime',
    })
    if (!result) return

    const newMixtape = new MixtapeModel(this.store, result)
    newMixtape['MixtapesHaveTags'] =
      (result['MixtapesHaveTags']
        && result['MixtapesHaveTags'].filter(item => item['level'] <= 21))
      || []
    newMixtape['MixtapesHaveTagsDelayUpdate'] =
      (result['MixtapesHaveTagsDelayUpdate']
        && result['MixtapesHaveTagsDelayUpdate'].filter(
          item => item['level'] <= 21,
        ))
      || []

    /**
     * for delay update - 2019.09.09 jhlim
     */
    if (newMixtape['isDelayUpdating']) {
      if (newMixtape['nameDelayUpdate']) {
        newMixtape['name'] = newMixtape['nameDelayUpdate']
      }
      if (newMixtape['totalPlayTimeDelayUpdate']) {
        newMixtape['totalPlayTime'] = newMixtape['totalPlayTimeDelayUpdate']
      }
      if (newMixtape['isPublishedDelayUpdate']) {
        newMixtape['isPublished'] = newMixtape['isPublishedDelayUpdate']
      }
      if (newMixtape['publishedAtDelayUpdate']) {
        newMixtape['publishedAt'] = newMixtape['publishedAtDelayUpdate']
      }
      if (newMixtape['isRecommendedDelayUpdate']) {
        newMixtape['isRecommended'] = newMixtape['isRecommendedDelayUpdate']
      }
      if (newMixtape['descriptionDelayUpdate']) {
        newMixtape['description'] = newMixtape['descriptionDelayUpdate']
      }
      if (newMixtape['imageUriDelayUpdate']) {
        newMixtape['imageUri'] = newMixtape['imageUriDelayUpdate']
      }
      if (newMixtape['imageUrlDelayUpdate']) {
        newMixtape['imageUrl'] = newMixtape['imageUrlDelayUpdate']
      }
      if (newMixtape['imageSourceDelayUpdate']) {
        newMixtape['imageSource'] = newMixtape['imageSourceDelayUpdate']
      }
      if (newMixtape['MixtapesHaveSongsDelayUpdate']) {
        newMixtape['MixtapesHaveSongs'] =
          newMixtape['MixtapesHaveSongsDelayUpdate']
      }
      if (newMixtape['MixtapesHaveTagsDelayUpdate']) {
        newMixtape['MixtapesHaveTags'] =
          newMixtape['MixtapesHaveTagsDelayUpdate']
      }
    }

    // const { MixtapesHaveTags } = newMixtape
    /**
     * 서버에서 가져온 태그리스트 중에서 장르태그, 3번째 태그 분리
     */
    // this.genreTagList = []
    // this.normalTagList = []
    // MixtapesHaveTags.forEach((item) => {
    //   if (item['level'] === 11) {
    //     this.normalTagList.push({ keyword: item['Tag']['keyword'] })
    //   } else if (item['level'] === 21) {
    //     this.genreTagList.push({ keyword: item['Tag']['keyword'] })
    //   }
    // })

    this.tempMixtape = newMixtape
    return !!newMixtape
  }

  // 홈 화면 기록 흐름 리스트
  @action.bound
  fetchMyListenedMixtapeList = async () => {
    if (!this.store.authStore.currentUser['id']) return

    // return this.store.useLoading(async () => {
    const res = await this.network.mixtapeNetwork.getListenedMixtapeList({
      userId: this.store.authStore.currentUser['id'],
      isIncludeUser: 1,
    })
    if (!res) return

    this.myListenedMixtapeList =
      res
      && res
        .filter(elem => !!elem)
        .slice(0, 50)
        .filter(elem => !!elem && elem.isPublished)
        .map(elem => new MixtapeModel(this.store, elem))
    // })
  }

  // 홈 화면 개인 흐름 리스트
  @action.bound
  fetchPersonalizationMixtapeList = async ({
    __nextCursor,
    __limit,
  }) => {
    // return this.store.useLoading(async () => {
    const fetchedPersonalizationMixtapeList = await this.network.mixtapeNetwork.getPersonalizationMixtapeList({
      __typeKeywords: 'RECENT,PERSONALIZATION,POPULAR',
      __nextCursor,
      __limit,
    })
    if (!fetchedPersonalizationMixtapeList) return

    const personalizationList = fetchedPersonalizationMixtapeList.mixtapeList.map(elem => new MixtapeModel(this.store, elem))

    return {
      contentList: personalizationList,
      __nextCursor: fetchedPersonalizationMixtapeList?.__nextCursor,
    }
  }

  // 홈 화면 전체 흐름 리스트
  @action.bound
  fetchTotalMixtapeList = async option => {
    const limit = option && option.limit ? option.limit : 4 // 기본 4개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    /**
     * 2020. 05. 22. 금 - 31seul
     * [최신 노출기준 변경 로직]
     * 최신 흐름 30개씩 가져오기
     * 유저당 흐름 2개까지만 최신순 노출, 그 이후 흐름은 필터로 자름
     * 2개 이상 노출된 유저는 blackList로 등록
     * 하단에 이어서 패치할때 blackList유저는 거름
     * 최신 탭 리패치시 black list user 초기화
     */
    if (offset === 0) {
      this.blackListUser = []
    }

    // return this.store.useLoading(async () => {
    const res = await this.network.mixtapeNetwork.getMixtapeList({
      popularChartLevel: 0,
      isPublished: 1,
      mixtapesHaveSongsUpdatedAt: new Date(),
      mixtapesHaveSongsUpdatedAtOperator: 'lt',
      // userId: '',
      // userIdOperator: 'ne',
      userId: this.blackListUser
        .filter(item => item.count >= 2)
        .map(item => item.id)
        .join(','),
      userIdOperator:
        this.blackListUser.filter(item => item.count >= 2).length <= 1
          ? 'ne'
          : 'notIn',
      __limit: limit,
      __offset: offset,
      __order: 'mixtapesHaveSongsUpdatedAtDesc',
      __include: 'Users',
      __attributes:
        'id,linkKey,name,imageUri,publishedAt,totalPlayTime,playCount,description,Users.name,Users.imageUri,Users.id,Users.account',
    })

    if (!res) return
    const _mixtapeList = res.filter(
      mixtape =>
        /**
         * 흐름 제목이 5자 초과
         * 설명 필수
         * 유저 프로필 이미지 필수
         * warningStatus 150 이상만
         */
        mixtape.name
        && mixtape.name.length > 5
        && mixtape.description
        && mixtape.description.length > 0
        && mixtape.User
        && mixtape.User.imageUri
        && (typeof mixtape.warningStatus !== 'number'
          || mixtape.warningStatus >= MIXTAPE_WARNING_STATUS.JUST_WARNING),
    )

    const _mixtapeListUserFilter = _mixtapeList.filter(item => {
      const userId = item.User.id
      if (this.blackListUser.length === 0) {
        this.blackListUser.push({ id: userId, count: 1 })
        return true
      }
      const index = this.blackListUser.findIndex(
        userItem => userItem.id === userId,
      )

      if (index < 0) {
        this.blackListUser.push({ id: userId, count: 1 })
        return true
      }

      if (this.blackListUser[index].count === 1) {
        this.blackListUser[index].count = 2
        return true
      }
      return false
    })

    if (offset === 0) {
      this.totalMixtapeList = _mixtapeListUserFilter
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))
    }
    else {
      this.totalMixtapeList = this.totalMixtapeList.concat(
        _mixtapeListUserFilter
          .filter(elem => !!elem)
          .map(elem => new MixtapeModel(this.store, elem)),
      )
    }

    return res.length === limit
  }

  // 홈 화면 전체(인기) 흐름 리스트
  @action.bound
  fetchHomePopularTotalMixtapeList = async () => {
    return this.store.useLoading(async () => {
      const res = await this.network.mixtapeNetwork.getPopularRealtimeMixtapeList()

      this.totalMixtapeList = res
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))

      return !!res
    })
  }

  // 홈 화면 좋아하는 흐름 리스트
  @action.bound
  fetchMyLikeMixtapeList = async option => {
    const limit = option && option.limit ? option.limit : 6 // 기본 6개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    if (!this.store.authStore.currentUser['id']) return
    return this.store.useLoading(async () => {
      const res = await this.network.mixtapeNetwork.getLikeList({
        userId: this.store.authStore.currentUser['id'],
        'Mixtapes.isPublished': 1,
        __attributes:
          'Users.id,Users.account,Users.name,Users.imageUri,Mixtapes.id,Mixtapes.linkKey,Mixtapes.name,Mixtapes.description,Mixtapes.imageUri,Mixtapes.isPublished,Mixtapes.publishedAt,Mixtapes.totalPlayTime,Mixtapes.playCount',
        __required: 'Mixtapes.Users',
        __limit: limit,
        __offset: offset,
      })

      this.myLikeMixtapeList = res
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))

      return !!this.myLikeMixtapeList
    })
  }

  // 홈 화면 구독한 DJ의 흐름 리스트
  @action.bound
  fetchMyFollowMixtapeList = async option => {
    const limit = option && option.limit ? option.limit : 6 // 기본 6개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    if (!this.store.authStore.currentUser['id']) return
    // return this.store.useLoading(async () => {
    const userIdList = await this.network.userNetwork.getUsersFollows(
      this.store.authStore.currentUser['id'],
    )

    const res = await this.network.mixtapeNetwork.getMixtapeList({
      isPublished: 1,
      mixtapesHaveSongsUpdatedAt: new Date(),
      mixtapesHaveSongsUpdatedAtOperator: 'lt',
      userId: userIdList.map(user => user['id']).join(','),
      userIdOperator: 'or',
      __include: 'Users',
      __limit: limit,
      __offset: offset,
      __order: 'mixtapesHaveSongsUpdatedAtDesc',
      __attributes:
        'id,linkKey,name,imageUri,description,Users.id,Users.account,Users.name,Users.imageUri,publishedAt,totalPlayTime,playCount',
      popularChartLevel: 0,
    })

    if (offset === 0) {
      this.myFollowMixtapeList = res
        ?.filter(elem => !!elem)
        ?.map(elem => new MixtapeModel(this.store, elem))
    }
    else {
      this.myFollowMixtapeList = this.myFollowMixtapeList.concat(
        res
          .filter(elem => !!elem)
          .map(elem => new MixtapeModel(this.store, elem)),
      )
    }

    if (!res) return
    return res.length === limit
    // })
  }

  /* 홈 스크린에서 하는 흐름 fetch 통합
   * => 카테고리별 흐름 fetch 중 무한스크롤 로직이 있는
   * 흐름 fetch 함수에 useLoading 제거 => 무한스크롤 loading 추가
   */
  @action.bound
  fetchHomeMixtapeList = () => {
    return this.store.useLoading(async () => {
      this.fetchMyListenedMixtapeList()
      // this.fetchTotalMixtapeList()
      this.fetchHomePopularTotalMixtapeList()
      this.fetchMyLikeMixtapeList()
      this.fetchMyFollowMixtapeList()
    })
  }

  /**
   * mixtape crud
   */
  @action fetchMixtapeDetail = async mixtapeId => {
    if (!mixtapeId) return
    return this.store.useLoading(async () => {
      /**
       * 테이블 조인으로 인한 연산속도가 느림.
       * 차라리 따로 fetch 하도록 수정 - 20190911 jhlim
       * todo - database 서버 성능 향상
       *
       * 따로 fetch 추가 - 20200207 jhlim
       * 테스트 mixtapeId=962
       * Android / IOS 10 회 시도 평균
       * 예전 걸리는 시간 1.2 sec
       * 지금 걸리는 시간 0.2 sec
       */
      const asyncResult1 = this.network.mixtapeNetwork.getMixtape(mixtapeId, {
        __include: `MixtapesHaveSongs.Songs,Users${
          this.store.authStore.currentUser?.id
            ? ',MixtapesHaveSongs.Songs.UsersLikeSongs'
            : ''
        }`,
        'MixtapesHaveSongs.Songs.UsersLikeSongs.userId': this.store.authStore // 좋아요한 오디오 체크
          .currentUser?.id,
        __addColumn: `${
          this.store.authStore.currentUser?.id ? 'isFollowed,isLiked,' : ''
        }likeCount,playCount,mixtapePlayedTime`,
      })
      const asyncResult2 = this.store.authStore.currentUser?.id
        ? this.network.mixtapeNetwork.getMixtape(mixtapeId, {
          __include: 'UsersLikeMixtapes',
        })
        : []
      const asyncResult3 = this.network.mixtapeNetwork.getMixtape(mixtapeId, {
        __include: 'MixtapesHaveTags.Tags',
      })
      const [result1, result2, result3] = await Promise.all([
        asyncResult1,
        asyncResult2,
        asyncResult3,
      ])

      // const commentList = await this.store.commentStore.fetchTargetMixtapeReply(
      //   mixtapeId,
      //   'Mixtapes',
      //   1,
      // )

      if (
        !result1
        || !result2
        || !result3
        // || !commentList
      )
        return

      const mixtape = new MixtapeModel(this.store, result1)
      // mixtape['commentList'] = commentList || []
      mixtape['UsersLikeMixtapes'] = result2['UsersLikeMixtapes'] || []
      mixtape['MixtapesHaveTags'] =
        (result3['MixtapesHaveTags']
          && result3['MixtapesHaveTags'].filter(
            item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
          ))
        || []
      mixtape['MixtapesHaveTagsDelayUpdate'] =
        (result3['MixtapesHaveTagsDelayUpdate']
          && result3['MixtapesHaveTagsDelayUpdate'].filter(
            item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
          ))
        || []

      /**
       * User 정보는 userDetailObj 와 동기화 - jhlim 20191227
       */
      if (mixtape && mixtape['User'] && mixtape['User']['id']) {
        // mixtape['User'] = this.store.userStore.syncUserDetailObj(Object.keys(result1['User']).reduce((obj, key) => ({ ...obj, [key]: mixtape['User'][key] })))
        mixtape['User'] = this.store.userStore.getUserDetailObj(mixtape['User'])
      }

      // 본인 흐름인지
      if (
        mixtape
        && mixtape['User']
        && mixtape['User']['id'] === this.store.authStore.currentUser?.id
      ) {
        // mixtape['isFollowed'] = 'isMine'
        mixtape['User']['isFollowed'] = 'isMine'
      }
      this.mixtapeDetail = mixtape
      return !!this.mixtapeDetail
      // return this.syncMixtapeDetailObj(mixtape)
    })
  }
  @action fetchMixtapeDetailFromLinkKey = async mixtapeLinkKey => {
    if (!mixtapeLinkKey) return
    return this.store.useLoading(async () => {
      /**
       * 테이블 조인으로 인한 연산속도가 느림.
       * 차라리 따로 fetch 하도록 수정 - 20190911 jhlim
       * todo - database 서버 성능 향상
       *
       * 따로 fetch 추가 - 20200207 jhlim
       * 테스트 mixtapeId=962
       * Android / IOS 10 회 시도 평균
       * 예전 걸리는 시간 1.2 sec
       * 지금 걸리는 시간 0.2 sec
       */
      const asyncResult1 = this.network.mixtapeNetwork.getMixtapeFromLinkKey(mixtapeLinkKey, {
        __include: `MixtapesHaveSongs.Songs,Users${
          this.store.authStore.currentUser?.id
            ? ',MixtapesHaveSongs.Songs.UsersLikeSongs'
            : ''
        }`,
        'MixtapesHaveSongs.Songs.UsersLikeSongs.userId': this.store.authStore // 좋아요한 오디오 체크
          .currentUser?.id,
        __addColumn: `${
          this.store.authStore.currentUser?.id ? 'isFollowed,isLiked,' : ''
        }likeCount,playCount,mixtapePlayedTime`,
      })
      const asyncResult2 = this.store.authStore.currentUser?.id
        ? this.network.mixtapeNetwork.getMixtapeFromLinkKey(mixtapeLinkKey, {
          __include: 'UsersLikeMixtapes',
        })
        : []
      const asyncResult3 = this.network.mixtapeNetwork.getMixtapeFromLinkKey(mixtapeLinkKey, {
        __include: 'MixtapesHaveTags.Tags',
      })
      const [result1, result2, result3] = await Promise.all([
        asyncResult1,
        asyncResult2,
        asyncResult3,
      ])

      // const commentList = await this.store.commentStore.fetchTargetMixtapeReply(
      //   mixtapeId,
      //   'Mixtapes',
      //   1,
      // )

      if (
        !result1
        || !result2
        || !result3
        // || !commentList
      )
        return

      const mixtape = new MixtapeModel(this.store, result1)
      // mixtape['commentList'] = commentList || []
      mixtape['UsersLikeMixtapes'] = result2['UsersLikeMixtapes'] || []
      mixtape['MixtapesHaveTags'] =
        (result3['MixtapesHaveTags']
          && result3['MixtapesHaveTags'].filter(
            item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
          ))
        || []
      mixtape['MixtapesHaveTagsDelayUpdate'] =
        (result3['MixtapesHaveTagsDelayUpdate']
          && result3['MixtapesHaveTagsDelayUpdate'].filter(
            item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
          ))
        || []

      /**
       * User 정보는 userDetailObj 와 동기화 - jhlim 20191227
       */
      if (mixtape && mixtape['User'] && mixtape['User']['id']) {
        // mixtape['User'] = this.store.userStore.syncUserDetailObj(Object.keys(result1['User']).reduce((obj, key) => ({ ...obj, [key]: mixtape['User'][key] })))
        mixtape['User'] = this.store.userStore.getUserDetailObj(mixtape['User'])
      }

      // 본인 흐름인지
      if (
        mixtape
        && mixtape['User']
        && mixtape['User']['id'] === this.store.authStore.currentUser?.id
      ) {
        // mixtape['isFollowed'] = 'isMine'
        mixtape['User']['isFollowed'] = 'isMine'
      }
      this.mixtapeDetail = mixtape
      return !!this.mixtapeDetail
      // return this.syncMixtapeDetailObj(mixtape)
    })
  }

  @action.bound
  likeMixtape = async mixtape => {
    const user = this.store.authStore.currentUser
    if (!user || !user['id'] || !mixtape || !mixtape['id']) return

    // const usersLikeMixtapesList = await this.network.mixtapeNetwork.postUsersLikeMixtapesList([{ userId: user['id'], mixtapeId: mixtape['id'] }])
    // if (!usersLikeMixtapesList || !(usersLikeMixtapesList.length > 0)) return
    // mixtape['UsersLikeMixtapes'] = [{ userId: user['id'], mixtapeId: mixtape['id'], id: usersLikeMixtapesList[0]['id'] }]
    const likedMixtape = await this.network.mixtapeNetwork.postMixtapeLike(
      mixtape['id'],
    )
    if (!likedMixtape) return

    // 로컬 데이터 fetch
    mixtape['isLiked'] = true
    mixtape['likeCount'] += 1

    // this._updateLocalMixtapeData(mixtape['id'])
    // await this.store.mixtapeStore.fetchLikedMixtapeList(user['id'])
    if (this.myLikeMixtapeList)
      this.myLikeMixtapeList.push(new MixtapeModel(this.store, likedMixtape))
    return true
  }

  @action.bound
  dislikeMixtape = async mixtape => {
    const user = this.store.authStore.currentUser
    if (!user || !user['id'] || !mixtape || !mixtape['id']) return
    // if (!mixtape || !mixtape['UsersLikeMixtapes'] || !(mixtape['UsersLikeMixtapes'].length > 0)) return
    // const usersLikeMixtapes = mixtape['UsersLikeMixtapes'].find(elem => elem['userId'] === user['id'])
    // if (!usersLikeMixtapes || !usersLikeMixtapes['id']) return

    // const deletedUsersLikeMixtapes = await this.network.mixtapeNetwork.deleteUsersLikeMixtapes(usersLikeMixtapes['id'])
    // if (!deletedUsersLikeMixtapes) return
    const dislikedMixtape = await this.network.mixtapeNetwork.deleteMixtapeLike(
      mixtape['id'],
    )
    if (!dislikedMixtape) return

    // 로컬 데이터 fetch
    mixtape['isLiked'] = false
    mixtape['likeCount'] -= 1

    // this._updateLocalMixtapeData(mixtape['id'])
    // await this.store.mixtapeStore.fetchLikedMixtapeList(user['id'])
    if (this.myLikeMixtapeList) {
      const index = this.myLikeMixtapeList.findIndex(
        _mixtape =>
          _mixtape['id']
          && dislikedMixtape['id']
          && _mixtape['id'] === dislikedMixtape['id'],
      )
      this.myLikeMixtapeList.splice(index, 1)
    }
    return true
  }
}
