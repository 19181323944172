import { Div, H5 } from '@components/atoms'
import {
  HomeUserListItem,
  HomeHouseListItem,
  SearchContentsSection,
} from '@components/organisms'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'

const SearchTotalSection = ({
  contentFilter,
  setContentFilter,
  handleContents,
}) => {
  const { userStore, searchStore } = useStore()

  const [userList, setUserList] = useState([])
  const [houseList, setHouseList] = useState([])

  const follow = userStore.follow || (() => {})
  const unFollow = userStore.unFollow || (() => {})

  const globalKeyword = searchStore.globalKeyword || null
  const fetchSearchUserList = searchStore.fetchSearchUserList || (() => {})
  const fetchSearchHouseList = searchStore.fetchSearchHouseList || (() => {})

  const handleSearchUser = useCallback(
    async _globalKeyword => {
      const list = await fetchSearchUserList(_globalKeyword, 0)
      setUserList(list)
    },
    [fetchSearchUserList],
  )

  const handleSearchHouse = useCallback(
    async _globalKeyword => {
      const list = await fetchSearchHouseList(_globalKeyword, 0)
      setHouseList(list)
    },
    [fetchSearchHouseList],
  )

  const handleFollowUser = useCallback(
    user => {
      if (user?.isFollowed) {
        unFollow(user)
      }
      else {
        follow(user)
      }
    },
    [follow, unFollow],
  )

  useEffect(() => {
    if (globalKeyword) {
      handleSearchUser(globalKeyword)
      handleSearchHouse(globalKeyword)
    }

    return () => {
      setUserList([])
      setHouseList([])
    }
  }, [globalKeyword])

  return (
    <Div display="flex" flexDirection="column">
      {
userList?.length > 0 && (
          <Div marginBottom="40px">
            <H5 type="Bold" align="left">
            유저
            </H5>
            <Div
              display="flex"
              width="100%"
              flexWrap="wrap"
              gap="24px"
              marginTop="24px"
            >
              {
userList?.map(user => (
  <HomeUserListItem
    item={user}
    key={user?.id}
    handleFollowUser={handleFollowUser}
  />
))
              }
            </Div>
          </Div>
        )
      }
      {
houseList?.length > 0 && (
          <Div marginBottom="40px">
            <H5 type="Bold" align="left">
            하우스
            </H5>
            <Div
              display="flex"
              width="100%"
              flexWrap="wrap"
              gap="24px"
              marginTop="24px"
            >
              {
houseList?.map(house => (
  <HomeHouseListItem item={house} key={house?.id} />
))
              }
            </Div>
          </Div>
        )
      }
      <SearchContentsSection
        contentFilter={contentFilter}
        setContentFilter={setContentFilter}
        handleFocus={handleContents}
      />
    </Div>
  )
}

export default observer(SearchTotalSection)
