import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { mobile, tablet } from '@styles/media'

import { observer } from 'mobx-react'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { Button, Div, ImageIcon, ProfileImg } from '@components/atoms'
import { AppDownloadModal, SalonEditModal } from '..'
import GlobalSearchBar from '../GlobalSearchBar'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
`

const MenuWrapper = styled.div`
  // 화면 상단 고정
  position: sticky;
  top: 0;

  max-width: 100vw;
  width: 100%;
  /* box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.045); */
  border-bottom: solid 1px #f4f4f4;
  background: #ffffff;
  z-index: 1100;

  padding: 0 24px;

  @media ${mobile} {
    padding: 0;
  }
`

const MenuNav = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 0 auto;
  height: 56px;
  padding: 8px 0;
  box-sizing: border-box;

  @media ${tablet} {
    padding: 10px;
    height: 70px;
  }

  @media ${mobile} {
    height: 50px;
  }
`

const AuthArea = styled.div`
  position: relative;

  display: flex;
  align-items: center;
`

const Profile = styled.div`
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px ${colors.white_f4};

    @media ${tablet} {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      margin-right: 20px;
    }

    @media ${mobile} {
      width: 34px;
      height: 34px;
      border-radius: 34px;
      margin-right: 20px;
    }
  }

  span {
    align-self: center;
    padding: 8px;
    font-family: NotoSanSCJKkr-Medium;
    font-size: 1rem;

    max-width: 314px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${tablet} {
      display: none;
    }

    @media ${mobile} {
      display: none;
    }
  }
`

const SubMenu = styled.div`
  text-align: center;
  position: absolute;
  top: 3rem;
  right: 5px;
  z-index: 10;
  // width : 314px;
  width: 112px;
  border-radius: 8px;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  div:first-child {
    display: none;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  @media ${tablet} {
    right: 20px;
    left: initial;

    div:first-child {
      margin: 10px 16px;
      cursor: pointer;
      width: 233px;
      height: 30px;
    }
  }

  @media ${mobile} {
    top: 42px;
    right: 20px;
    left: initial;

    div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 16px;
      cursor: pointer;
      width: 233px;
      height: 30px;
    }
  }
`

const MenuList = styled.div`
  cursor: pointer;
  width: 100%;
  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = () => {
  const { authStore, liveRoomStore, appStateStore, houseStore } = useStore()
  const currentUser = authStore.currentUser || null
  const currLiveRoom = liveRoomStore.currLiveRoom || null

  const logout = authStore.logout || (() => {})
  const tryAuth = authStore.tryAuth || (() => {})
  const toggleShowSidebar = appStateStore.toggleShowSidebar || (() => {})

  const [showMenu, setShowMenu] = useState(false)

  const {
    isShowing: showOpenSalon,
    toggle: toggleOpenSalon,
  } = SalonEditModal.useModal()

  const {
    isShowing: showAppDownload,
    toggle: toggleAppDownload,
  } = AppDownloadModal.useModal()

  const handleOpenSalon = async () => {
    const result = await liveRoomStore.getDevicesPermission()
    if (!result) return
    if (liveRoomStore.agoraDevice) toggleOpenSalon()
  }

  const fetchUserHouseList = async () => {
    const options = {
      __required: 'HousesHaveUsers',
      'HousesHaveUsers.userId': currentUser?.id,
      isHaveContentPermission: 1,
      __attributes:
        'id,linkKey,contentPermissionLevel,imageUri,title,HousesHaveUsers.id,HousesHaveUsers.userId,HousesHaveUsers.level',
    }

    const houseList = await houseStore.fetchHouseList(options)
    return houseList || []
  }

  return (
    <MenuWrapper>
      <MenuNav>
        <Div display="flex" alignItems="center" justifyContent="center">
          {showMenu && <Overlay onClick={() => setShowMenu(false)} />}
          <ToggleSidebarBtn onClick={toggleShowSidebar}>
            <img src={images.burger_img} alt="burger_btn" />
          </ToggleSidebarBtn>
          <NavLink exact to="/" activeClassName="active">
            <ImageIcon name="logo" width="144px" height="20px" />
          </NavLink>
        </Div>

        <GlobalSearchBar />

        <AuthArea>
          <AppDownloadBtn onClick={toggleAppDownload}>
            <img src={images.qr_icon_img} alt="broadcast" />
앱 다운로드
          </AppDownloadBtn>
          {
            currentUser ? (
              <>
                {
                  !currLiveRoom && (
                    <OpenSalonBtn onClick={handleOpenSalon}>
                      <img src={images.broadcast_img_white} alt="broadcast" />
                  살롱 열기
                    </OpenSalonBtn>
                  )
                }
                {/* <PlanBtn to="/p/plan">플랜</PlanBtn> */}
                <Profile onClick={() => setShowMenu(!showMenu)}>
                  <ProfileImg
                    src={currentUser?.imageUri}
                    profileColor={currentUser?.profileColor}
                  />
                  {
                    showMenu ? (
                      <SubMenu>
                        <MenuList>{currentUser && currentUser.name}</MenuList>
                        <MenuList>
                          <Link
                            to={`/user/${currentUser?.account}`}
                            style={
                              {
                                width: '100%',
                                height: '100%',
                                color: '#242424',
                              }
                            }
                          >
                        내 채널
                          </Link>
                        </MenuList>
                        <MenuList onClick={logout}>로그아웃</MenuList>
                      </SubMenu>
                    ) : (
                      false
                    )
                  }
                </Profile>
              </>
            ) : (
              <LoginBtn onClick={tryAuth}>로그인</LoginBtn>
            )
          }
        </AuthArea>
      </MenuNav>
      <SalonEditModal.View
        isShowing={showOpenSalon}
        toggle={toggleOpenSalon}
        fetchUserHouseList={fetchUserHouseList}
      />
      <AppDownloadModal.View
        isShowing={showAppDownload}
        toggle={toggleAppDownload}
      />
    </MenuWrapper>
  )
}

export default observer(Header)

const ToggleSidebarBtn = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`

const AppDownloadBtn = styled(Button)`
  width: 116px;
  height: 32px;
  margin-right: 24px;
  border-radius: 20px;
  border: solid 1px ${colors.grey_d4};
  background-color: ${colors.white_ff};
  font-size: 14px;
  color: ${colors.black_24};
  line-height: 20px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`

const OpenSalonBtn = styled(Button)`
  width: 116px;
  height: 32px;
  margin-right: 24px;
  border-radius: 20px;
  border: solid 1px ${colors.primary};
  background-color: ${colors.primary};
  font-size: 14px;
  color: ${colors.white_ff};
  line-height: 20px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    object-fit: contain;
  }
`

const PlanBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;

  width: 72px;
  height: 32px;
  border-radius: 20px;
  border: solid 1px #594ee9;
  background-color: #ffffff;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #594ee9;

  margin-right: 24px;
`

const LoginBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;

  width: 72px;
  height: 32px;
  border-radius: 20px;
  border: solid 1px #594ee9;
  background-color: #ffffff;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #594ee9;

  margin-right: 24px;
`
