import {
  Caption1,
  Div,
  H5,
  H6,
  ImageIcon,
  ProfileImg,
  StyledButton,
} from '@components/atoms'
import { STORAGE_URL } from '@consts/'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { addComma } from '@utils/string'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const LiveRoomFinishSection = () => {
  const { liveRoomStore, authStore, userStore } = useStore()
  const history = useHistory()

  const currentUser = authStore.currentUser || null
  const currLiveRoom = liveRoomStore._currLiveRoom || null
  const deletedCurrLiveRoom = liveRoomStore.deletedCurrLiveRoom || null
  const currLiveRoomUser = liveRoomStore.currLiveRoomUser || null

  const { level = 0 } = currLiveRoomUser || {}

  const isOwner = level >= LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
  const ownerUser = currLiveRoom['User']

  // 진행 시간
  const getDiffTime = useCallback(() => {
    if (deletedCurrLiveRoom) {
      const totalSeconds = moment(deletedCurrLiveRoom?.deletedAt).diff(
        deletedCurrLiveRoom?.openedAt,
        'seconds',
      )
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = Math.floor((totalSeconds % 3600) % 60)
      if (hours <= 0 && minutes <= 0) {
        return `${totalSeconds}초`
      }
      if (hours <= 0) {
        return `${minutes}분 ${seconds}초`
      }
      return `${hours}시간 ${minutes}분`
    }
  }, [deletedCurrLiveRoom])

  // 청취자 수
  const cumulativeUserCount = deletedCurrLiveRoom?.cumulativeUserCount
    ? addComma(deletedCurrLiveRoom?.cumulativeUserCount)
    : 1

  // 스피커 수
  const speakerCount = deletedCurrLiveRoom?.speakerCount || 1

  // 총 참여자
  const userList = deletedCurrLiveRoom?.LiveRoomsHaveUsers?.slice()

  const [rating, setRating] = useState(null) // 평점
  const [isFollow, setIsFollow] = useState(false) // 팔로우 여부

  // 평점 코멘트
  const getRatingText = useCallback(() => {
    switch (rating) {
      case 1:
        return '너무 불편해요 \u{1F625}'
      case 2:
        return '불편해요 \u{1F615}'
      case 3:
        return '잘 모르겠어요 \u{1F914}'
      case 4:
        return '좋아요 \u{1F44D}'
      case 5:
        return '정말 좋아요 \u{1F970}'
      default:
        return ''
    }
  }, [rating])

  // 평가 후, 나가기
  const handleSurveyAndQuit = async () => {
    await liveRoomStore.survaysLiveRoom(currLiveRoom?.id, rating)
    if (liveRoomStore.isJoinedLiveRoom) {
      await liveRoomStore.finishLiveRoomLocal(currLiveRoom?.id, false)
    }

    history.push('/p/salon/list')
  }

  // 호스트 팔로우
  const handleFollow = useCallback(async () => {
    if (isFollow) {
      await userStore.unFollow(ownerUser)
      setIsFollow(false)
    }
    else {
      await userStore.follow(ownerUser)
      setIsFollow(true)
    }
  }, [isFollow, ownerUser])

  useEffect(() => {
    // 호스트가 아닐 경우에만 호스트 팔로우 여부 패치
    if (!isOwner) {
      const fetchFollowHost = async () => {
        const followHost = await userStore.fetchFollowingUser({
          userId: currentUser?.id,
          anotherId: currLiveRoom?.userId,
        })

        if (followHost?.length > 0) {
          setIsFollow(true)
        }
        else {
          setIsFollow(false)
        }
      }

      fetchFollowHost()
    }
  }, [isOwner])

  // 기존 살롱에서 체크하던 ping로직 구독 해제
  useEffect(() => {
    liveRoomStore._clearTimer()

    // 나갈 시, 종료된 살롱 정보 초기화
    return () => {
      liveRoomStore.deletedCurrLiveRoom = null
    }
  }, [])

  return (
    <Section>
      <BlurBackImg />
      <Div
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color={colors.white_ff}
        zIndex="2"
      >
        {
          isOwner && deletedCurrLiveRoom ? (
            <>
              <ImageIcon name="whiteLogo" width="144px" height="16px" />
              <H5 style={{ lineHeight: '32px', margin: '48px 0 28px' }}>
              살롱이 종료되었습니다
              </H5>

              <LiveRoomFinishInfoBox>
                <Caption1 color={colors.white_ff}>
                  {deletedCurrLiveRoom?.title}
                </Caption1>

                <Div
                  display="flex"
                  flexDirection="column"
                  gap="12px"
                  margin="16px 0"
                >
                  <FinishInfoItem>
                    <Div display="flex" alignItems="center">
                      <ImageIcon
                        name="whiteClock"
                        width="16px"
                        height="16px"
                        marginRight="6px"
                      />
                      <Caption1 type="Regular" color={colors.white_ff}>
                      진행 시간
                      </Caption1>
                    </Div>
                    <Caption1 color={colors.white_ff}>{getDiffTime()}</Caption1>
                  </FinishInfoItem>
                  <FinishInfoItem>
                    <Div display="flex" alignItems="center">
                      <ImageIcon
                        name="whitePeople"
                        width="16px"
                        height="16px"
                        marginRight="6px"
                      />
                      <Caption1 type="Regular" color={colors.white_ff}>
                      참여자
                      </Caption1>
                    </Div>
                    <Caption1 color={colors.white_ff}>
                      {`${cumulativeUserCount || 0}명`}
                    </Caption1>
                  </FinishInfoItem>
                  <FinishInfoItem>
                    <Div display="flex" alignItems="center">
                      <ImageIcon
                        name="whiteSpeaker"
                        width="16px"
                        height="16px"
                        marginRight="6px"
                      />
                      <Caption1 type="Regular" color={colors.white_ff}>
                      스피커
                      </Caption1>
                    </Div>
                    <Caption1 color={colors.white_ff}>
                      {`${speakerCount || 0}명`}
                    </Caption1>
                  </FinishInfoItem>
                </Div>

                <Div display="flex" flexWrap="wrap" gap="12px">
                  {
userList?.map((user, index) => {
  if (index === 26) {
    return (
      <Div
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="24px"
        height="24px"
        borderRadius="50%"
        backgroundColor={colors.white_ff}
        fontSize="10px"
        color={colors.grey_64}
      >
        {`+${userList.length - 26}`}
      </Div>
    )
  }
  if (index < 26) {
    return (
      <ProfileImg
        src={
                          user?.User && `${STORAGE_URL}${user.User.imageUri}`
        }
        profileColor={user?.User?.profileColor}
        style={{ width: 24, height: 24, borderRadius: '50%' }}
      />
    )
  }
  return <></>
})
                  }
                </Div>
              </LiveRoomFinishInfoBox>

              <Div
                display="flex"
                flexDirection="column"
                position="absolute"
                bottom="40px"
                width="calc(100% - 80px)"
              >
                <StyledButton
                  designType="roundPrimary"
                  onClick={
                    async () => {
                      await liveRoomStore.finishLiveRoomLocal(
                    currLiveRoom?.id,
                    false,
                      )
                      history.push('/')
                    }
                  }
                >
                완료
                </StyledButton>
              </Div>
            </>
          ) : (
            <>
              <H5 style={{ lineHeight: '32px' }}>
              살롱이 종료되었습니다
                <br />
              이번 살롱은 어떠셨나요?
              </H5>

              <Div margin="28px 0 20px" display="flex" gap="16px">
                <ImageIcon
                  name={rating >= 1 ? 'activeStar' : 'inactiveStar'}
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  onClick={() => setRating(1)}
                />
                <ImageIcon
                  name={rating >= 2 ? 'activeStar' : 'inactiveStar'}
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  onClick={() => setRating(2)}
                />
                <ImageIcon
                  name={rating >= 3 ? 'activeStar' : 'inactiveStar'}
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  onClick={() => setRating(3)}
                />
                <ImageIcon
                  name={rating >= 4 ? 'activeStar' : 'inactiveStar'}
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  onClick={() => setRating(4)}
                />
                <ImageIcon
                  name={rating >= 5 ? 'activeStar' : 'inactiveStar'}
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  onClick={() => setRating(5)}
                />
              </Div>
              <H6>{getRatingText()}</H6>

              <Div
                display="flex"
                flexDirection="column"
                position="absolute"
                bottom="40px"
                width="calc(100% - 80px)"
              >
                <Div
                  display="flex"
                  justifyContent="space-between"
                  marginBottom="40px"
                >
                  <Caption1 align="left">
                  살롱 호스트를 팔로우하고
                    <br />
                  다음 살롱에도 참여해보세요
                  </Caption1>
                  <StyledButton
                    designType={isFollow ? "unfollow" : "primary"}
                    size="sm"
                    width="80px"
                    onClick={handleFollow}
                  >
                    {isFollow ? '팔로잉' : '팔로우'}
                  </StyledButton>
                </Div>
                <StyledButton
                  designType="roundPrimary"
                  onClick={handleSurveyAndQuit}
                >
                나가기
                </StyledButton>
              </Div>
            </>
          )
        }
      </Div>
    </Section>
  )
}

export default observer(LiveRoomFinishSection)

const Section = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  z-index: 1000;
`

const BlurBackImg = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  object-fit: contain;

  background-color: rgba(0, 0, 0, 0.28);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* z-index: 2; */
  position: absolute;
  border-radius: 16px;
  z-index: 1;
  left: 0;
  top: 0;
`

const LiveRoomFinishInfoBox = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 360px;

  padding: 12px 16px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
`

const FinishInfoItem = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: ${colors.black_24};
  padding: 0 12px;
`
