import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H6 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FlexRowBox, RowToColumnBox } from '@components/molecules'
import { Link } from 'react-router-dom'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`

const Modal = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  width: 250px;
  height: 250px;
`

const MobdlBody = styled.div`
  padding: 0px 16px;
`

const ButtonList = styled(RowToColumnBox)`
  height: 250px;
  > div:last-child {
    border: none;
  }
`

const ButtomItem = styled(FlexRowBox)`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #f4f4f4;
  justify-content: center;
  align-items: center;
`

const ButtonLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  color: #242424;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  info,
  toggle,
  onClose,
  onDelete,
  onUpdate,
  fetchSongDetail,
  mp3Url,
  setNowSong,
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={handleClose} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <ButtonList>
                <ButtomItem>
                  <ButtonLink href={mp3Url} download>
                    <H6>다운로드</H6>
                  </ButtonLink>
                </ButtomItem>
                <ButtomItem>
                  <ButtonLink
                    href="#/"
                    onClick={
                      () => {
                        setNowSong(info)
                        toggle()
                      }
                    }
                  >
                    <H6>재생</H6>
                  </ButtonLink>
                </ButtomItem>
                <ButtomItem onClick={() => fetchSongDetail(info.id)}>
                  <Link
                    to={
                      {
                        pathname: '/p/audio/song',
                      }
                    }
                    style={
                      {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                      }
                    }
                  >
                    <H6>수정</H6>
                  </Link>
                </ButtomItem>
                <ButtomItem onClick={onDelete}>
                  <H6 color="#ea4653">삭제</H6>
                </ButtomItem>
                <ButtomItem onClick={handleClose}>
                  <H6 color={colors.secondary}>취소</H6>
                </ButtomItem>
              </ButtonList>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}
