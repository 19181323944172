import * as React from 'react'
import styled, { css } from 'styled-components'
import { SocialLinkBox } from '@components/molecules'
import { Flex } from '@components/atoms'
import { Link } from 'react-router-dom'
import { mobileDesktop } from '@styles/media'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'

const Footer = ({ style }) => {
  const { playerStore } = useStore()
  const isPlaying = playerStore.url || null

  return (
    <Container style={style && style} isPlaying={isPlaying}>
      <Content>
        <LeftView>
          <Flex>
            <Flex style={{ width: 104 }}>
              <Link to="/p/faq">
                <EventText>Q&A</EventText>
              </Link>
            </Flex>
            <Flex style={{ width: 104 }} type="column">
              <a
                href="https://hreum.kr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EventText>HREUM</EventText>
              </a>
              <a
                href="https://hreum.kr/plra"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EventText>PLRA</EventText>
              </a>
              <a
                href="https://hreum.kr/1month"
                target="_blank"
                rel="noopener noreferrer"
              >
                <EventText>PLAN</EventText>
              </a>
            </Flex>
            <Flex style={{ width: 'auto', flexDirection: 'column' }}>
              <Link to="/p/cast/manage">
                <EventText>My CAST</EventText>
              </Link>
              <Link to="/p/dj/mixtape/list">
                <EventText>My HREUM</EventText>
              </Link>
            </Flex>
          </Flex>

          <CompanyInfoBox>
            <CompanyRow>
              <CompanyField>
                <HeadText>대표명</HeadText>
                <ValueText>김동현</ValueText>
              </CompanyField>
              <Flex>
                <HeadText>회사 주소</HeadText>
                <ValueText>서울시 성북구 보문동 5가 173</ValueText>
              </Flex>
            </CompanyRow>
            <CompanyRow>
              <CompanyField>
                <HeadText>회사명</HeadText>
                <ValueText>주식회사 나이비</ValueText>
              </CompanyField>
              <Flex>
                <HeadText>사업자등록번호</HeadText>
                <ValueText>724-88-00593</ValueText>
              </Flex>
            </CompanyRow>
            <Flex>
              <HeadText>통신 판매업 신고번호</HeadText>
              <ValueText>제 2018-서울성북-0376호</ValueText>
            </Flex>
          </CompanyInfoBox>
        </LeftView>
        <RightView>
          <SocialLinkBox />
          <Flex style={{ margin: '32px 0 24px 0' }}>
            <a
              href="/p/policy/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EventText style={{ marginRight: 24 }}>Privacy Policy</EventText>
            </a>
            <a
              href="/p/policy/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EventText>Terms of Use</EventText>
            </a>
          </Flex>
          <Text>All rights reserved 2019. naivy Inc.</Text>
        </RightView>
      </Content>
    </Container>
  )
}

export default observer(Footer)

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: #646464;
  background-color: #f7f8fa;
  width: 100%;

  ${({ isPlaying }) =>
    isPlaying
      ? css`
          height: 432px;
          padding-bottom: 90px;
        `
      : css`
          height: 352px;
        `}

  @media ${mobileDesktop} {
    height: 800px;
  }
`

const Content = styled(Flex)`
  max-width: 1024px;
  width: 100%;
  height: 100%;
  padding: 48px 0;
  box-sizing: border-box;

  @media ${mobileDesktop} {
    flex-direction: column;
  }
`

const LeftView = styled(Flex)`
  flex: 2;
  flex-direction: column;

  @media ${mobileDesktop} {
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 24px;
  }
`
const RightView = styled(Flex)`
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  @media ${mobileDesktop} {
    justify-content: center;
    align-items: flex-start;
    margin-left: 24px;
  }
`

const CompanyInfoBox = styled(Flex)`
  flex-direction: column;
  margin-top: 56px;
`

const CompanyRow = styled(Flex)`
  margin-bottom: 24px;

  @media ${mobileDesktop} {
    flex-direction: column;
  }
`

const CompanyField = styled(Flex)`
  width: 188px;
  justify-content: flex-start;

  @media ${mobileDesktop} {
    margin-bottom: 24px;
  }
`

const Text = styled.span`
  font-family: NotoSansCJKkr-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #646464;
`

const EventText = styled(Text)`
  cursor: pointer;
`

const HeadText = styled(Text)`
  margin-right: 16px;
`

const ValueText = styled(Text)`
  color: #949494;
`
