import { Button, Caption1, Flex } from '@components/atoms'
import { colors } from '@resources/colors'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const AppUseBtn = () => {
  return (
    <a
      href="https://app.hreum.me/stores"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div style={{ position: 'relative' }}>
        <Btn>앱으로 이동</Btn>
        <BtnBoxEffect />
        <BtnBoxEffect2 />
      </div>
    </a>
  )
}

const AppInduceBar = () => {
  const [show, setShow] = useState(true)

  // 다시 보지않기 로직
  // useEffect(() => {
  //   setShow(!sessionStorage.getItem('showAppInduceBar'))
  // }, [])

  if (show) {
    return (
      <Bar id="app-induce-bar">
        <Caption1 color={colors.white_ff}>
          웹에서는 제한된 기능만을 제공합니다. 앱에서 더 많은 기능들로 흐름을
          즐기세요!
        </Caption1>
        <Flex align="center">
          {/* <Caption1
            color={colors.white_ff}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={
              () => {
                sessionStorage.setItem('showAppInduceBar', true)
                setShow(false)
              }
            }
          >
            다시 보지않기
          </Caption1> */}
          <Caption1
            color={colors.white_ff}
            style={
              {
                cursor: 'pointer',
                textDecoration: 'underline',
                margin: '0 16px',
                wordBreak: 'keep-all',
              }
            }
            onClick={() => setShow(false)}
          >
            닫기
          </Caption1>
          <AppUseBtn />
        </Flex>
      </Bar>
    )
  }
  return <></>
}

export default AppInduceBar

const Bar = styled(Flex)`
  position: fixed;
  z-index: 9999;
  bottom: 0;

  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  width: 100vw;
  height: 64px;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #5b49f3;
`

const Btn = styled(Button)`
  width: 96px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  background-color: ${colors.white_ff};
  color: ${colors.black};
`

const BtnBoxEffect = styled.div`
  position: absolute;
  width: 96px;
  height: 49px;
  opacity: 0.24;
  background-color: #ffffff;

  top: 0;
  left: 13px;
  transform: skew(30deg);
`

const BtnBoxEffect2 = styled.div`
  position: absolute;
  width: 17px;
  height: 49px;
  opacity: 0.24;
  background-color: #ffffff;
  top: 33px;
  left: 15px;
  transform: skew(30deg);
`
