import React, { useState } from 'react'
import styled from 'styled-components'
import { desktop, fullHD, mobileDesktop } from '@styles/media'
import { colors } from '@resources/colors'
import { Flex } from '@components/atoms'

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  margin-top: 64px;
`

const Title = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 2rem;

  @media ${desktop} {
    font-size: 2.5rem;
  }

  @media ${fullHD} {
    font-size: 2.5rem;
  }
`
const Description = styled.p`
  margin-top: 72px;
`
const SwitchLabel = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  z-index: 2;

  width: 68px;
  height: 28px;

  color: ${({ isSwitchOn, color }) => (isSwitchOn ? color : '#949494')};
  font-size: 16px;

  @media ${mobileDesktop} {
    font-size: 14px;
  }
`
const Contents = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 36px;
`

const SwitchOffContents = styled.div`
  position: relative;
  transform: translate(${({ isSwitchOn }) => (isSwitchOn ? '0' : '-100%')});
  -webkit-transition: -webkit-transform 1s ease;
  -moz-transition: -moz-transform 1s ease;
  -o-transition: -o-transform 1s ease;
  /* edited the line below by adding top also*/
  transition: transform 1s ease, top 1s ease;
`
const SwitchOnContents = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  transform: translate(${({ isSwitchOn }) => (isSwitchOn ? '0' : '100%')});
  -webkit-transition: -webkit-transform 1s ease;
  -moz-transition: -moz-transform 1s ease;
  -o-transition: -o-transform 1s ease;
  /* edited the line below by adding top also*/
  transition: transform 1s ease, top 1s ease;
`

const ActiveLabelBackground = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;

  transform: translate(${({ isSwitchOn }) => (isSwitchOn ? '0' : '100%')});
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  /* edited the line below by adding top also*/
  transition: transform 0.5s ease, top 0.5s ease;

  width: 68px;
  height: 28px;
  background-color: #5b49f3;
  border-radius: 32px;
`

const SwitchSection = ({
  title,
  description,
  onSwitchName,
  offSwitchName,
  onSwitchContents,
  offSwitchContents,
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false)

  const handleSwitch = () => {
    setIsSwitchOn(!isSwitchOn)
  }

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}

      <div
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }
        }
      >
        <div
          style={
            {
              position: 'relative',
              display: 'flex',
              width: '144px',
              height: 36,
              justifyContent: 'center',
              backgroundColor: '#f4f4f4',
              borderRadius: 18,
              paddingTop: 4,
              boxSizing: 'border-box',
            }
          }
        >
          <ActiveLabelBackground
            isSwitchOn={!isSwitchOn}
            onClick={handleSwitch}
          />
          <SwitchLabel
            isSwitchOn={!isSwitchOn}
            color={colors.white}
            onClick={handleSwitch}
          >
            {offSwitchName}
          </SwitchLabel>
          <SwitchLabel
            isSwitchOn={isSwitchOn}
            color={colors.white}
            onClick={handleSwitch}
          >
            {onSwitchName}
          </SwitchLabel>
          <Flex
            style={{
              width: 32,
              height: 16,
              borderRadius: 8,
              backgroundColor: '#ea4653',
              position: 'absolute',
              top: 10,
              right: -40,
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: 10,
            }}
          >
            추천
          </Flex>
        </div>
      </div>

      <Contents>
        <SwitchOnContents isSwitchOn={isSwitchOn}>
          {onSwitchContents}
        </SwitchOnContents>
        <SwitchOffContents isSwitchOn={!isSwitchOn}>
          {offSwitchContents}
        </SwitchOffContents>
      </Contents>
    </Container>
  )
}

export default SwitchSection
