import { Network } from '.'

export default class LiveRoomNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getLiveRoom = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${id}`,
        'get',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork getLiveRoom error: ', error)
    }
  }
  getLiveRoomFromLinkKey = async (linkKey, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/_linkKey/${linkKey}`,
        'get',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork getLiveRoomFromLinkKey error: ', error)
    }
  }

  getLiveRoomList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork getLiveRoomList error: ', error)
    }
  }

  getRecoomendLiveRoomList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/recommendations`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork getRecoomendLiveRoomList error: ', error)
    }
  }

  postLiveRoom = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms`,
        'post',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoom error: ', error)
    }
  }

  postCompleteLiveRoom = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/complete`,
        'post',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork postCompleteLiveRoom error: ', error)
    }
  }

  putLiveRoom = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${id}`,
        'put',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork putLiveRoom error: ', error)
    }
  }

  deleteLiveRoom = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${id}`,
        'delete',
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork deleteLiveRoom error: ', error)
    }
  }

  /**
   * liveRoomsHaveUsers api
   */

  getLiveRoomsHaveUsers = async (liveRoomId, userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users/${userId}`,
        'get',
        where,
      )
      return data || null
    }
    catch (error) {
      console.log('LiveRoomNetwork getLiveRoomsHaveUsers error: ', error)
    }
  }

  postLiveRoomsHaveUsers = async (liveRoomId, userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users/${userId}`,
        'post',
        where,
      )
      return { data: data || [] }
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoomsHaveUsers error: ', error)
      return { error: true, errorCode: error?.response?.data?.errorCode }
    }
  }

  postLiveRoomsHaveGuest = async (liveRoomId, where) => {
    try {
      const { data } = await this.network._axiosApi(
        `/live-rooms/${liveRoomId}/join`,
        'post',
        where,
      )
      return { data: data || [] }
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoomsHaveGuest error: ', error)
      return { error: true, errorCode: error?.response?.data?.errorCode }
    }
  }

  putLiveRoomsHaveUsersList = async (liveRoomId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users`,
        'put',
        where,
      )
      return { data: data || [] }
    }
    catch (error) {
      console.log('LiveRoomNetwork putLiveRoomsHaveUsers error: ', error)
      return { error: true, errorCode: error?.response?.data?.errorCode }
    }
  }

  postCompleteLiveRoomsHaveUsers = async (liveRoomId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users/${userId}/complete`,
        'post',
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomNetwork postCompleteLiveRoomsHaveUsers error: ',
        error,
      )
    }
  }

  putLiveRoomsHaveUsers = async (liveRoomId, userId, value) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users/${userId}`,
        'put',
        value,
      )
      return { data: data || {} }
    }
    catch (error) {
      console.log('LiveRoomNetwork putLiveRoomsHaveUsers error: ', error)
    }
  }

  deleteLiveRoomsHaveUsers = async (liveRoomId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/users/${userId}`,
        'delete',
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork deleteLiveRoomsHaveUsers error: ', error)
    }
  }

  postLiveRoomPing = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/ping`,
        'post',
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoomPing error: ', error)
    }
  }

  postLiveRoomReserve = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/reserve`,
        'post',
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoomReserve error: ', error)
    }
  }

  deleteLiveRoomReserve = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/reserve`,
        'delete',
      )
      return data || {}
    }
    catch (error) {
      console.log('LiveRoomNetwork deleteLiveRoomReserve error: ', error)
    }
  }

  postLiveRoomReservationHide = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/hide`,
        'post',
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork postLiveRoomReservationHide error: ', error)
    }
  }

  putFixedComment = async (liveRoomId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/fixed-comment`,
        'put',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork putFixedComment error: ', error)
    }
  }

  deleteFixedComment = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/fixed-comment`,
        'delete',
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork deleteFixedComment error: ', error)
    }
  }

  postSurvaysSalon = async (liveRoomId, body) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/survays`,
        'post',
        body,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomNetwork putSurvaysSalon error: ', error)
    }
  }

  getDeletedLiveRoom = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/joined-users`,
        'get',
      )

      return data || []
    }
    catch (error) {
      console.log(
        'LiveRoomNetwork getRecommendationReservedLiveRoomList error: ',
        error,
      )
    }
  }

  getRecommendationsReservedLiveRoom = async (options) => {
    try {
      const { data } = await this.network._axiosApi(
        `/live-rooms/recommendations/reserved-live-rooms`,
        'get',
        options,
      )

      return data || []
    }
    catch (error) {
      console.log(
        'LiveRoomNetwork getRecommendationsReservedLiveRoom error: ',
        error,
      )
    }
  }
  getDeletedLiveRoom = async liveRoomId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-rooms/${liveRoomId}/joined-users`,
        'get',
      )

      return data || []
    }
    catch (error) {
      console.log(
        'LiveRoomNetwork getRecommendationReservedLiveRoomList error: ',
        error,
      )
    }
  }
}
