import { Caption1, Div, Flex, H6, Image, Img } from '@components/atoms'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useStore } from '@utils/hooks'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { FeedCard, FeedDetailModal } from '..'

const HouseDetailFeedSection = ({ isMyPage, houseDetail }) => {
  const { authStore, houseStore } = useStore()

  const {
    isShowing: showDetail,
    toggle: toggleDetail,
  } = FeedDetailModal.useModal()

  const houseFeedList = houseStore.houseFeedList || null

  const [hasMore, setHasMore] = React.useState(true)
  const [targetFeedId, setTargetFeedId] = React.useState(null)

  const handleLoadDetail = async id => {
    const isAuth = await authStore.tryAuth()
    if (!isAuth) return

    setTargetFeedId(id)
    toggleDetail()
  }

  const fetchFeedList = async () => {
    if (houseDetail?.id) {
      const option = {
        __pageLimit: 20,
        __pageField: 'publishedAt',
        __pageOrder: 'desc',
        __pageIdValue:
          houseStore.houseFeedList?.[houseStore.houseFeedList?.length - 1]
            ?.id,
        __pageValue:
          houseStore.houseFeedList?.[houseStore.houseFeedList?.length - 1]
            ?.publishedAt,
      }

      const result = await houseStore.fetchFeedList(houseDetail.id, option)

      setHasMore(result?.length >= 20)
    }
  }

  React.useLayoutEffect(() => {
    return () => {
      houseStore.houseFeedInit()
    }
  }, [houseStore, houseDetail])

  React.useEffect(() => {
    const _fetchFeedList = async () => {
      if (houseDetail?.id) {
        const option = {
          __pageLimit: 20,
          __pageField: 'publishedAt',
          __pageOrder: 'desc',
          __pageIdValue:
            houseStore.houseFeedList?.[houseStore.houseFeedList?.length - 1]
              ?.id,
          __pageValue:
            houseStore.houseFeedList?.[houseStore.houseFeedList?.length - 1]
              ?.publishedAt,
        }

        const result = await houseStore.fetchFeedList(houseDetail.id, option)

        setHasMore(result?.length >= 20)
      }
    }
    _fetchFeedList()
  }, [houseStore, houseDetail])

  return (
    <FeedListBox>
      <Flex justify="flex-end" align="flex-end" />
      <InfiniteScroll
        dataLength={houseFeedList.length}
        scrollThreshold={0.9}
        next={fetchFeedList}
        hasMore={hasMore}
        hasChildren
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
        className="scroll_div"
        scrollableTarget="scrollableDiv"
        style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <List>
          {
houseFeedList?.length < 1 ? (
  <Flex
    type="column"
    style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
  >
    <H6 color={colors.grey_64}>피드가 없습니다</H6>
    {
      isMyPage && (
        <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
                  피드 업로드는 앱에서 하실 수 있습니다
        </Caption1>
      )
    }
  </Flex>
) : (
  houseFeedList.map((item, index) => (
    // FeedListItem이 Image태그밖에 없어서 임시 컴포넌트
    <FeedCard
      item={item}
      key={item.id}
      handleLoadDetail={handleLoadDetail}
    />
  ))
)
          }
        </List>
      </InfiniteScroll>
      <FeedDetailModal.View
        isShowing={showDetail}
        toggle={toggleDetail}
        feedId={targetFeedId}
        fromHouse
      />
    </FeedListBox>
  )
}

export default observer(HouseDetailFeedSection)

const FeedListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -28px;

  width: calc(100% + 28px);

  > div {
    margin-left: 28px;
    margin-bottom: 28px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
