// 메타데이터 긁어오기
export const getTag = async (file: File) => {
  var jsmediatags = require('jsmediatags')

  try {
    /**
     * https://github.com/aadsm/jsmediatags 라이브러리 사용
     */
    const tag = await new Promise((resolve, reject) =>
      jsmediatags.read(file, {
        onSuccess: tag => resolve(tag),
        onError: error => reject(error),
      }),
    )
    return tag || null
  } catch (error) {}
}
