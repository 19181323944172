import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 0.875rem;
  text-align: left;
  color: #646464;
  max-width: 970px;
  margin: 50px 0 100px 0;
  line-height: 25px;
  word-break: keep-all;
`

const P = styled.p`
  margin-top: 15px;
`

const SubscribeMsgShort = () => {
  return (
    <Wrapper>
      <P>모든 이용권은 부가세(VAT)포함 가격입니다.</P>
      <P>
        플랜을 변경하거나 결제 수단을 변경하기 위해서는 플랜을 취소하고 만료
        예정일 이후에 원하는 플랜 혹은 결제 수단으로 다시 결제하셔야 합니다.
      </P>
      <P>
        국내에서 결제 완료된 흐름(HREUM de salon) 프리미엄 서비스 플랜은
        국내에서만 이용하실 수 있습니다.
      </P>
      <P>
        해외 서비스 대상국가가 아닌 지역에서는 서비스 이용이 불가하며, 일부
        콘텐츠는 저작권자의 별도 요청, 저작권 문제, 기타 사업권역 문제 등으로
        국내 혹은 해외 서비스 이용이 사전 예고 없이 제한될 수 있습니다.
      </P>
    </Wrapper>
  )
}

export default SubscribeMsgShort
