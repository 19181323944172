import React, { useState, useEffect, useCallback } from 'react'
import {
  Form,
  FormInput,
  FormTextArea,
  FormLabel,
  Caption1,
  Caption2,
} from '@components/atoms'
import styled from 'styled-components'
import {
  SubmitBtn,
  FlexColumnBox,
  RowToColumnBox,
  Tooltip,
  FlexRowBox,
  GenreBtn,
  SearchTagBox,
  TempTagBox,
  RoundBtn,
  OkCancelPopup,
  ConfirmPopup,
  OnboardingPopup,
} from '@components/molecules'
import { mobile, mobileAndTablet } from '@styles/media'
import { useForm } from 'react-hook-form'
import { genreTagList, DJ_LEVEL } from '@consts/'
import { secondToTimeString } from '@utils/time'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { SearchAudioModal, TempMixtapeSongList } from '..'

import MixtapeImageUploadForm from './upload/MixtapeImageUploadForm'
// import { message } from 'antd'
// import { STORAGE_URL } from '@consts'

const TooltipContent = styled.div`
  width: 265px;
  text-align: left;
  padding: 16px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;
  font-size: 14px;

  @media ${mobile} {
    width: 265px;
    padding: 12px;
  }
`

const SubmitBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  text-align: center;

  @media ${mobile} {
    margin: 15px 0px;
  }
`

const FormGroup = styled.div`
  padding: 10px 0px;
  width: 100%;

  @media ${mobile} {
    padding: 8px 0px;
  }
`

const AudioForm = styled(Form)`
  width: 100%;
  max-width: 1000px;

  @media ${mobile} {
    width: 100%;
  }
`

const ImageUploadBox = styled(FormGroup)`
  max-width: 452px;
  width: 100%;
  @media ${mobile} {
    padding: 20px 0px 12px 0px;
  }
`

const AudioInfoInputBox = styled(FlexColumnBox)`
  width: 100%;
  max-width: 518px;

  @media ${mobileAndTablet} {
    padding-left: 20px;
  }

  @media ${mobile} {
    height: 320px;
  }
`

const GenreList = styled(FlexRowBox)`
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  justify-content: flex-start;

  label {
    margin: 7px;
  }
`

const MixtapeAddForm = ({
  mixtapeStore,
  currentUser,
  uploadMixtapeImage,
  fetchAutocompleteTags,
  autoCompleteTagList,
  tempMixtape,
  addMixtapeSongListToTempMixtape,
  replaceMixtapeSongListToTempMixtape,
  deleteMixtapeSongToTempMixtape,
  createMixtape,
  applyMixtapeForDJ,
  setIsCompleted,
  isUpdated,
  updateMixtape,
}) => {
  const [tempGenreList, setTempGenreList] = useState([])
  const [tempTagList, setTempTagList] = useState([])

  // const [imageUri, setImageUri] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)

  // const [isTagging, setIsTagging] = useState(false)
  const [inputText, setInputText] = useState('')

  const [errorTitle, setErrorTitle] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const { isShowing, toggle } = SearchAudioModal.useModal()
  const {
    isShowing: accountShowing,
    toggle: accountToggle,
  } = OkCancelPopup.usePopup()
  const {
    isShowing: requiredNameShowing,
    toggle: requiredNameToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: onboardingShowing,
    toggle: onboardingToggle,
  } = OnboardingPopup.usePopup()

  const {
    isShowing: tagOverShowing,
    toggle: tagOverToggle,
  } = ConfirmPopup.usePopup()

  const {
    register,
    handleSubmit,
    // getValues,
    // errors,
    // setError,
    // clearError,
    setValue,
  } = useForm()

  /* Genre Logic */
  const _onPressGenre = tag => {
    const selectedIndex = tempGenreList.findIndex(
      elem => elem['keyword'] === tag['keyword'],
    )

    if (selectedIndex < 0) {
      if (tempGenreList.length >= 5) {
        alert(`최대 5개까지 선택 가능합니다.`)
      }
      else {
        tempGenreList.push(tag)
      }
    }
    else {
      tempGenreList.splice(selectedIndex, 1)
    }
    setTempGenreList([...tempGenreList])
  }

  const selectedCheck = tag => {
    return (
      tempGenreList.findIndex(elem => elem['keyword'] === tag['keyword']) >= 0
    )
  }
  /** ****************** */

  /* Tag Logic */
  const _onPressAdd = async text => {
    // 태그 개수 15개 제한 로직 반영 createdAt 2020. 11. 10 leehaehun
    if (tempTagList.length >= 15) {
      tagOverToggle()
      return
    }
    if (text) {
      const index = tempTagList.findIndex(
        elem => elem && elem['keyword'] === text,
      )
      if (index < 0) {
        tempTagList.push({ keyword: text })
        setTempTagList([...tempTagList])
      }
    }

    /* 태그 자동완성 리스트 초기화 */
    await fetchAutocompleteTags('')

    setInputText('')
    // setIsTagging(false)
  }

  const _onChangeText = async text => {
    setInputText(text)

    await fetchAutocompleteTags(text)
  }

  const _onPressTag = (tag, index) => {
    tempTagList.splice(index, 1)
    setTempTagList([...tempTagList])
  }
  /** *********************** */

  const handleOK = async () => {
    await applyMixtapeForDJ(tempMixtape)
    setIsCompleted(true)
  }

  // 등록하기
  const onSubmit = async data => {
    if (tempMixtape && tempMixtape.totalPlayTime >= 1800) {
      // 공개 및 신청

      /* vallidation */
      if (data.name === '') {
        setErrorTitle('공개 실패')
        setErrorMsg('제목을 입력해주세요')
        requiredNameToggle()
        return
      }
      // 태그 개수 15개 제한 로직 반영 createdAt 2020. 11. 10 leehaehun
      if (tempTagList.length > 15) {
        tagOverToggle()
        return
      }
      if (tempGenreList.length < 1) {
        setErrorTitle('공개 실패')
        setErrorMsg('장르 태그를 설정해주세요')
        requiredNameToggle()
        return
      }
      /** ******** */

      // DJ가 아닐때 일단 비공개 처리
      if (currentUser.discJockeyLevel < DJ_LEVEL['BABY']) {
        tempMixtape['isPublished'] = 0
        alert('DJ가 되어야 공개하실 수 있습니다.')
      }
      else if (currentUser.discJockeyLevel >= DJ_LEVEL['BABY']) {
        tempMixtape['isPublished'] = 1
        tempMixtape['publishedAt'] = new Date()
      }
      tempMixtape['name'] = data.name
      tempMixtape['description'] = data.description
      tempMixtape['genreTagList'] = tempGenreList
      tempMixtape['normalTagList'] = tempTagList

      if (isUpdated) {
        const updatedMixtape = await updateMixtape(tempMixtape)
        if (!updatedMixtape) return
        if (
          updatedMixtape
          && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
        ) {
          await accountToggle()
        }
        else {
          setIsCompleted(true)
        }
      }
      else {
        const newMixtape = await createMixtape(tempMixtape)
        if (!newMixtape) return

        tempMixtape['id'] = newMixtape['id']

        if (newMixtape && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']) {
          await accountToggle()
        }
        else {
          setIsCompleted(true)
        }
      }
    }
    else {
      // 임시 저장
      /* required */

      if (data.name === '') {
        setErrorTitle('임시저장 실패')
        setErrorMsg('제목을 입력해주세요')
        requiredNameToggle()
        return
      }
      if (tempGenreList.length < 1) {
        setErrorTitle('임시저장 실패')
        setErrorMsg('장르 태그를 설정해주세요')
        requiredNameToggle()
        return
      }
      /** ******** */
      // 임시저장 일때, 비공개 처리
      tempMixtape['isPublished'] = 0

      tempMixtape['name'] = data.name
      tempMixtape['description'] = data.description
      tempMixtape['genreTagList'] = tempGenreList
      tempMixtape['normalTagList'] = tempTagList

      if (isUpdated) {
        const updatedMixtape = await updateMixtape(tempMixtape)
        if (!updatedMixtape) return
        setIsCompleted(true)
      }
      else {
        const newMixtape = await createMixtape(tempMixtape)
        if (!newMixtape) return
        setIsCompleted(true)
      }
    }
  }

  const changeImage = useCallback(
    mixtape => {
      mixtape.imageSource = tempMixtape.imageSource
    },
    [tempMixtape.imageSource],
  )

  useEffect(() => {
    if (
      currentUser
      && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
      && !onboardingShowing
    ) {
      // 팝업
      onboardingToggle()
    }

    setValue([
      { name: tempMixtape.name },
      { description: tempMixtape.description },
    ])
    setTempTagList(
      tempMixtape.MixtapesHaveTags.filter(item => item['level'] === 11).map(
        item => item['Tag'],
      ),
    )
    setTempGenreList(
      tempMixtape.MixtapesHaveTags.filter(item => item['level'] === 21).map(
        item => item['Tag'],
      ),
    )
    changeImage(tempMixtape)
    // eslint-disable-next-line
  }, [currentUser, tempMixtape, setValue])

  return (
    <AudioForm onSubmit={handleSubmit(onSubmit)}>
      <RowToColumnBox
        style={
          {
            width: '100%',
            alignItems: 'end',
            justifyContent: 'space-between',
          }
        }
      >
        <ImageUploadBox>
          <MixtapeImageUploadForm
            tempMixtape={tempMixtape}
            // setImageUri={setImageUri}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            uploadSongImage={uploadMixtapeImage}
            title="플라 대표 이미지"
          />
        </ImageUploadBox>
        <AudioInfoInputBox>
          <FormGroup>
            <FormLabel color="#646464" required>
              제목
            </FormLabel>
            <FormInput name="name" ref={register()} placeholder="제목 입력" />
          </FormGroup>
          <FormGroup>
            <FormLabel color="#646464">설명</FormLabel>
            <FormTextArea
              name="description"
              ref={register()}
              style={{ height: '216px', resize: 'none', margin: 0 }}
              placeholder="설명 입력"
            />
          </FormGroup>
        </AudioInfoInputBox>
      </RowToColumnBox>

      <FlexRowBox style={{ width: '100%', justifyContent: 'center' }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <FormLabel
              color="#646464"
              tooltip={
                <Tooltip
                  content={
                    <TooltipContent>
                      적절한 태그는 더 많은 사람들이
                      <br />
                      플라를 발견하고 즐길 수 있도록 합니다.
                      <br />
                      최소 1개, 최대 5개까지 선택 가능합니다.
                    </TooltipContent>
                  }
                />
              }
            >
              장르 추가
            </FormLabel>
            <Caption2 color="#949494">* 필수로 설정해주세요.</Caption2>
            <Caption1 style={{ marginLeft: '10px' }}>
              {`${tempGenreList.length} / 5`}
            </Caption1>
          </div>
          <div>
            <GenreList>
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[0]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[1]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[2]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[3]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[4]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[5]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[6]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[7]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[8]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[9]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[10]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[11]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[12]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[13]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[14]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[15]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[16]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[17]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[18]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[19]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[20]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[21]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[22]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[23]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[24]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[25]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[26]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[27]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[28]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[29]}
              />
              <GenreBtn
                onPress={_onPressGenre}
                selectedCheck={selectedCheck}
                tempGenreList={tempGenreList}
                tag={genreTagList[30]}
              />
            </GenreList>
          </div>
        </FormGroup>
      </FlexRowBox>

      <FlexRowBox style={{ width: '100%', justifyContent: 'center' }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <FormLabel>태그 추가</FormLabel>
            <Caption1 style={{ marginLeft: '12px' }}>
              {`${tempTagList.length} / 15`}
            </Caption1>
          </div>
          <SearchTagBox
            inputText={inputText}
            onChange={_onChangeText}
            autoCompleteTagList={autoCompleteTagList}
            onClick={_onPressAdd}
          />
          {
            tempTagList.length > 0 && (
              <FlexRowBox style={{ flexWrap: 'wrap' }}>
                {
                  tempTagList.map((item, index) => (
                    <TempTagBox
                      item={item}
                      key={`temp-tag-box-${index}`}
                      index={index}
                      onRemove={_onPressTag}
                    />
                  ))
                }
              </FlexRowBox>
            )
          }
        </FormGroup>
      </FlexRowBox>

      <FlexColumnBox
        style={{ marginTop: '20px', width: '100%', justifyContent: 'center' }}
      >
        <FormGroup>
          <div>
            <FormLabel>선곡 리스트</FormLabel>
            <Caption1 style={{ margin: '0 10px' }}>
              총 재생시간
              {' '}
              {secondToTimeString(tempMixtape && tempMixtape.totalPlayTime)}
            </Caption1>
            <Caption1>
              {(tempMixtape && tempMixtape.MixtapesHaveSongs.length) || 0}
곡
            </Caption1>
          </div>
          <FlexRowBox
            style={{ justifyContent: 'space-between', marginTop: '-20px' }}
          >
            <Caption2 color="#949494">
              *소수의 가수, 앨범으로 선곡하여 전송권을 침해하지 말아주세요 (Ex.
              아이유 노래 모음, 쇼미7 모음)
            </Caption2>
            <RoundBtn
              type="button"
              text="오디오 추가"
              color={colors.primary}
              style={
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '116px',
                  fontSize: '14px',
                }
              }
              img={images.plus_main_img}
              imgStyle={{ width: '14px', height: '14px', marginRight: '6px' }}
              onClick={toggle}
            />
          </FlexRowBox>
        </FormGroup>
      </FlexColumnBox>
      {
        tempMixtape.MixtapesHaveSongs.length > 0 && (
          <TempMixtapeSongList
            tempMixtape={tempMixtape}
            songs={tempMixtape.MixtapesHaveSongs}
            replaceMixtapeSongListToTempMixtape={
              replaceMixtapeSongListToTempMixtape
            }
            deleteMixtapeSongToTempMixtape={deleteMixtapeSongToTempMixtape}
          />
        )
      }
      <SubmitBox>
        <SubmitBtn type="submit">
          {
            tempMixtape && tempMixtape.totalPlayTime < 1800
              ? '임시저장'
              : currentUser && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT']
                ? '제출하기'
                : '공개하기'
          }
        </SubmitBtn>
      </SubmitBox>
      <SearchAudioModal.View
        isShowing={isShowing}
        toggle={toggle}
        tempMixtape={tempMixtape}
        addMixtapeSongListToTempMixtape={addMixtapeSongListToTempMixtape}
      />
      <OkCancelPopup.View
        isShowing={accountShowing}
        toggle={accountToggle}
        title="DJ 신청"
        body="DJ 신청을 진행하시곘습니까?"
        onOk={handleOK}
        onCancel={() => setIsCompleted(true)}
      />
      <ConfirmPopup.View
        isShowing={requiredNameShowing}
        toggle={requiredNameToggle}
        title={errorTitle}
        body={errorMsg}
      />
      <ConfirmPopup.View
        isShowing={tagOverShowing}
        toggle={tagOverToggle}
        title={'태그 추가 실패'}
        body={'태그가 너무 많습니다'}
      />
      <OnboardingPopup.View
        isShowing={onboardingShowing}
        toggle={onboardingToggle}
      />
    </AudioForm>
  )
}

export default observer(MixtapeAddForm)
