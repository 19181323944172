import React, { useState } from 'react'
import { SongDetailTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

const SongDetailPage = () => {
  useRenderLogger('SongDetailPage')
  const { songStore } = useStore()

  const currentSong = songStore.currentSong || null
  const currentSongInfo = songStore.currentSongInfo || (() => {})
  const createTagList = songStore.createTagList || (() => {})
  const updateSong = songStore.updateSong || (() => {})
  const uploadSongImage = songStore.uploadSongImage || (() => {})
  const updateSongInfo = songStore.updateSongInfo || (() => {})

  const [isUpdated, setIsUpdate] = useState(false)

  return (
    <>
      {
        isUpdated && (
          <Redirect
            to={{ pathname: '/p/dj/mixtape/list', state: { type: 'SONG' } }}
          />
        )
      }
      <SongDetailTemplate
        loadData={currentSong}
        info={currentSongInfo}
        updateSong={updateSong}
        updateSongInfo={updateSongInfo}
        createTagList={createTagList}
        uploadSongImage={uploadSongImage}
        setIsUpdate={setIsUpdate}
      />
    </>
  )
}

export default observer(SongDetailPage)
