import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Flex, Caption2 } from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopiedPopup } from '@components/molecules'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 440px;
  max-height: 456px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, dynamicLink }) => {
  const [linkCopied, setLinkCopied] = useState(false) // 이메일 주소 완료 팝업

  const handleCopy = () => {
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 2500)
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <SalonLogo src={images.salon_logo} style={{ marginTop: 48 }} />
              <LinkBox style={{ margin: '40px 0 32px 0' }}>
                <Caption2 type="Medium" style={{ width: '100%' }}>
                  {dynamicLink}
                </Caption2>
                <CopyToClipboard text={dynamicLink} onCopy={handleCopy}>
                  <CopyLinkBtn>
                    <img src={images.copy_link_img_white} alt="copy_btn" />
                  </CopyLinkBtn>
                </CopyToClipboard>
              </LinkBox>
              <Caption2 color={colors.grey_64}>
                  모바일에서 위 주소에 접속하시면
                <br />
                  살롱에 참여할 수 있습니다
              </Caption2>
              <Caption2
                color={colors.grey_64}
                style={{ margin: '16px 0 24px 0' }}
              >
                  살롱이 닫히기 전에 HREUM de SALON
                <br />
                  앱에 접속해 실시간 오디오 소통을 즐겨보세요!
              </Caption2>
              <img
                src={images.app_qrcode_img}
                style={{ width: 104, height: 104 }}
                alt="app_qr_img"
              />
              <Caption2 style={{ margin: '6px 0 32px 0' }}>
                  모바일 앱 다운로드
              </Caption2>
              {linkCopied && <CopiedPopup />}
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}

const SalonLogo = styled.img`
  width: 116px;
  height: 48px;
  object-fit: contain;
`

const LinkBox = styled(Flex)`
  width: 376px;
  height: 40px;
  justify-content: flex-start;
  align-items: center;

  border-radius: 24px;
  border: solid 1px #d4d4d4;
  background-color: #f4f4f4;
  padding: 4px;

  box-sizing: border-box;
`

const CopyLinkBtn = styled(Flex)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  border-radius: 50%;

  > img {
    width: 16px;
    height: 16px;
  }
`
