import { axiosApi } from '@utils/axios'

import AuthNetwork from './AuthNetwork'
import UserNetwork from './UserNetwork'
import SongNetwork from './SongNetwork'
import PurchaseNetwork from './PurchaseNetwork'
import RevenueNetwork from './RevenueNetwork'
import MixtapeNetwork from './MixtapeNetwork'
import TagNetwork from './TagNetwork'
import SearchNetwork from './SearchNetwork'
import CommentNetwork from './CommentNetwork'
import LiveRoomNetwork from './LiveRoomNetwork'
import LiveRoomRecordingNetwork from './LiveRoomRecordingNetwork'
import CustomerCenterNetwork from './CustomerCenterNetwork'
import StarNetwork from './StarNetwork'
import FeedNetwork from './FeedNetwork'
import CastNetwork from './CastNetwork'
import HouseNetwork from './HouseNetwork'

export class Network {
  _axiosApi = axiosApi
  _axiosApiAuth = axiosApi

  authNetwork: AuthNetwork
  UserNetwork: UserNetwork
  SongNetwork: SongNetwork
  PurchaseNetwork: PurchaseNetwork
  RevenueNetwork: RevenueNetwork
  MixtapeNetwork: MixtapeNetwork
  TagNetwork: TagNetwork
  SearchNetwork: SearchNetwork
  CommentNetwork: CommentNetwork
  LiveRoomNetwork: LiveRoomNetwork
  LiveRoomRecordingNetwork: LiveRoomRecordingNetwork
  CustomerCenterNetwork: CustomerCenterNetwork
  StarNetwork: StarNetwork
  FeedNetwork: FeedNetwork
  CastNetwork: CastNetwork
  HouseNetwork: HouseNetwork

  constructor(props) {
    props.getJwt && this.createAxiosApiAuth(props.getJwt, props.getCustomHeader)

    this.authNetwork = new AuthNetwork(this)
    this.userNetwork = new UserNetwork(this)
    this.songNetwork = new SongNetwork(this)
    this.purchaseNetwork = new PurchaseNetwork(this)
    this.revenueNetwork = new RevenueNetwork(this)
    this.mixtapeNetwork = new MixtapeNetwork(this)
    this.tagNetwork = new TagNetwork(this)
    this.searchNetwork = new SearchNetwork(this)
    this.commentNetwork = new CommentNetwork(this)
    this.liveRoomNetwork = new LiveRoomNetwork(this)
    this.liveRoomRecordingNetwork = new LiveRoomRecordingNetwork(this)
    this.customerCenterNetwork = new CustomerCenterNetwork(this)
    this.starNetwork = new StarNetwork(this)
    this.feedNetwork = new FeedNetwork(this)
    this.castNetwork = new CastNetwork(this)
    this.houseNetwork = new HouseNetwork(this)
  }

  createAxiosApiAuth = (getJwt, getCustomHeader) => {
    this._axiosApiAuth = async (url, method = 'GET', data, options, baseURL) => {
      // const jwtToken = (getJwt && getJwt()) || ''
      const jwtToken = getJwt ? getJwt() : ''

      let customHeader =
        typeof getCustomHeader === 'function' && getCustomHeader()
      customHeader = (typeof customHeader === 'object' && customHeader) || {}

      const _options = {
        ...options,
        headers: {
          Authorization: jwtToken,
          ...customHeader,
          ...(options && typeof options['headers'] === 'object'
            ? options['headers']
            : {}),
        },
      }
      return axiosApi(url, method, data, _options, baseURL)
    }
  }
}
