import axios from 'axios'
import { API_URL, IGNORE_ERROR_ROUTER } from '@consts'
import moment from 'moment'
import { message } from 'antd'

const IS_DEV =
  process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development'

/*
 * MobX 통신
 */

const apiHandler = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  validateStatus: status => {
    return status >= 200 && status < 300
  },
})

apiHandler.defaults.timeout = 10000

apiHandler.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const API_ROUTE =
      error.response
      && error.response.config
      && error.response.config.url.replaceAll(API_URL, '')

    // 특정 라우터 에러 무시
    if (
      IGNORE_ERROR_ROUTER.find(
        router => API_ROUTE && API_ROUTE.startsWith(router),
      )
    )
      return
    console.warn('[error][request]', error)
    if (error && error.response) {
      if (error.response.data) {
        if (error.response.data.toastMessage) {
          alert(error.response.data.toastMessage)
        }

        if (error.response.data.message) {
          alert(error.response.data.message)
        }
      }
    }
    else {
      message.error('네트워크 연결 상태를 확인해주세요.')
      console.log('axios error', error)
      // alert('네트워크 연결 상태를 확인해주세요.')
    }
    if (error?.response?.status === 401) {
      return { data: { status: 401, response: error?.response?.data?.status } }
    }
    throw error
  },
)

/**
 * axios#request(config)
 * axios#get(url[, config])
 * axios#delete(url[, config])
 * axios#head(url[, config])
 * axios#options(url[, config])
 * axios#post(url[, data[, config]])
 * axios#put(url[, data[, config]])
 * axios#patch(url[, data[, config]])
 * axios#getUri([config])
 */
/**
 * config 종류
 * https://github.com/axios/axios
 * 'Request Config' 검색
 */
export async function axiosApi(
  url,
  method = 'GET',
  data,
  options = {},
  baseURL = API_URL,
) {
  try {
    const _method = method.toLowerCase()
    data =
      _method === 'get'
        ? { ...options, params: { ...data } }
        : _method === 'delete'
          ? { ...options, data }
          : data

    if (IS_DEV) {
      const _startTime = new Date().getTime()
      try {
        const res = await apiHandler[_method](baseURL + url, data, options)
        console.log(
          `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
            + `[DEV][LOG][axiosApi][respond]`,
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          res,
        )
        return res
      }
      catch (error) {
        console.log(
          `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
            + `[ERROR][axiosApi][respond]`,
          `[${new Date().getTime() - _startTime}]`,
          _method,
          url,
          data,
          error && error.response,
          error,
        )
        throw error
      }
    }
    else {
      const res = await apiHandler[_method](baseURL + url, data, options)
      return res
    }
  }
  catch (error) {
    console.log('axiosApi error', error)
    throw error
  }
}

export const upload = async (url, token, data, baseURL = API_URL) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: token,
    },
    timeout: 60000,
  }
  const res = await axios.post(baseURL + url, data, config)
  return res
}

export const axiosDefault = async (url, method, data, options) => {
  try {
    const _method = method.toLowerCase()
    const config = {
      ...(options || {}),
      headers: {
        ...(options?.['headers'] || {}),
      },
      // onUploadProgress: (e) => console.log('jhlim', 'onUploadProgress', e)
    }
    if (IS_DEV) {
      const _startTime = new Date().getTime()
      const res = await axios[_method](url, data, config)
      console.log(
        `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
          + `[DEV][LOG][axiosApi][respond]`,
        `[${new Date().getTime() - _startTime}]`,
        _method,
        url,
        data,
        res,
      )
      return res
    }

    const res = await axios[_method](url, data, config)
    return res

  }
  catch(error) {
    console.log('axiosDefault error', error)
    throw error
  }
}
