import React from 'react'
import ReactLoading from 'react-loading'

const Spinner = ({ type, color, delay, height, width }) => {
  return (
    <ReactLoading
      type={type && type}
      color={color && color}
      delay={delay && delay}
      height={height && height}
      width={width && width}
    />
  )
}

export default Spinner
