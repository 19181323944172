import styled from 'styled-components'
import Div from './Div'

const styleIcon = {
  photo: 'icon/icon-photo.png',
  activeStar: 'icon/icon-active-star.png',
  inactiveStar: 'icon/icon-inactive-star.png',
  addingHouse: 'icon/icon-adding-house.png',
  logo: 'icon/icon-logo.png',
  whiteLogo: 'icon/icon-white-logo.png',
  whiteClock: 'icon/icon-white-clock.png',
  whiteSpeaker: 'icon/icon-white-speaker.png',
  whitePeople: 'icon/icon-white-people.png',
  whitePin: 'icon/icon-white-pin.png',
  grayArrowBottom: 'icon/icon-gray-arrowBottom.png',
  liveRoomSocialType: 'icon/icon-liveroom-social-type.png',
  searchBlack: 'icon/icon-search-black.png',
  circleCloseBtn: 'icon/icon-circle-close-btn.png',
  circlePlusPrimary: 'icon/icon-circle-plus-primary.png',
  houseOwnerBadge: 'icon/icon-house-owner-badge.png',
  houseMemeberBadge: 'icon/icon-house-member-badge.png',
  houseFollowerBadge: 'icon/icon-house-follower-badge.png',
  noContents: 'icon/icon-no-contents.png',
  hreumLogo: 'icon/icon-hreumLogo.png',
  playBtnWhite: 'icon/icon-play-btn-white.png',
  pauseBtnWhite: 'icon/icon-pause-btn-white.png',
  next15SecWhite: 'icon/icon-next-15-sec-white.png',
  prev15SecWhite: 'icon/icon-prev-15-sec-white.png',
  emptyHeartBlack: 'icon/icon-empty-heart-black.png',
  emptyCommentBlack: 'icon/icon-empty-comment-black.png',
  influencerBadge: 'icon/icon-influencer-badge.png',
  present: 'icon/icon-present.png',
  clipBlack: 'icon/icon-clip-black.png',
  houseMembership: 'icon/icon-house-membership.png',
}

const ImageIcon = styled(Div)`
  ${({ name, backgroundSize, backgroundPosition }) =>
    name
    && `background: url(/images/${styleIcon[name]}) ${backgroundPosition
      || 'center'} / ${backgroundSize || 'cover'} no-repeat`}
`

export default ImageIcon
