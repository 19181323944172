import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import { Flex, ProfileImg, Caption2 } from '@components/atoms'
import { Mention, MentionsInput } from 'react-mentions'

const MentionInput = React.forwardRef((props, ref) => {
  const {
    value,
    setValue,
    mentionList,
    style,
    handleChange,
    handleSubmit,
    isTrigger,
    setIsTrigger,
    ...inputProps
  } = props

  const [focusIdx, setFocusIdx] = React.useState(0)

  const mentionRef = React.useRef(null)
  let mentionNode
  let mentionCurrentScroll = null
  if (mentionRef.current) mentionNode = ReactDOM.findDOMNode(mentionRef.current)
  if (mentionNode) mentionCurrentScroll = mentionNode.scrollTop

  const handleSelected = async e => {
    if (!isTrigger && !e.shiftKey && e.charCode === 13 && handleSubmit) {
      e.preventDefault()
      if (value.trim().length === 0) return

      if (value) {
        await handleSubmit(value)
        setValue('')
      }
    }

    if (!mentionList || (mentionList && !mentionList.list)) return
    if (isTrigger) {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        setIsTrigger(false)
        const selected = mentionList.list[focusIdx]

        const triggerIdx = value.lastIndexOf('@')

        if (value[triggerIdx + 1] === '[') return

        const searchKeyword = value.substring(triggerIdx + 1, value.length)

        setValue(
          prev =>
            `${prev.replace(
              `@${searchKeyword}`,
              `@[${selected.display}](id:${selected.id})`,
            )}`,
        )
      }
    }
  }

  const handlKeyDownMention = e => {
    if (!mentionList || (mentionList && !mentionList.list)) return
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
      e.stopPropagation()
    }

    if (isTrigger) {
      // 한글이 편집중 일때, keyCode === 229로 한번 더오는 이슈 방지
      if (e.key === 'ArrowUp' && e.keyCode !== 229) {
        if (mentionList.list.length === focusIdx + 1) {
          mentionNode && mentionNode.scrollTo(0, 0)
          setFocusIdx(0)
        }
        else {
          setFocusIdx(focusIdx + 1)
          if (focusIdx + 2 !== 1 && focusIdx + 2 > 3) {
            if (focusIdx + 1 === 4) {
              mentionNode && mentionNode.scrollTo(0, mentionCurrentScroll - 150)
            }
            else {
              mentionNode && mentionNode.scrollTo(0, mentionCurrentScroll - 50)
            }
          }
        }
      }
      if (e.key === 'ArrowDown') {
        if (focusIdx === 0) {
          mentionNode && mentionNode.scrollTo(0, -mentionNode?.scrollHeight)
          setFocusIdx(mentionList.list.length - 1)
        }
        else {
          setFocusIdx(focusIdx - 1)
          if (
            focusIdx - 2 !== mentionList.list.length + 1
            && mentionCurrentScroll !== 0
          ) {
            mentionNode && mentionNode.scrollTo(0, mentionCurrentScroll + 50)
          }
        }
      }
    }
  }

  return (
    <MentionInputBox style={style && style}>
      {
        isTrigger && mentionList && mentionList.list?.length > 0 && (
          <SuggestionList ref={mentionRef}>
            {
              mentionList.list.map((item, index) => (
                <SuggestionListItem key={item.id} isFocus={focusIdx === index}>
                  <ProfileImg
                    src={item.imageUrl}
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                  <Caption2 style={{ width: '100%', marginLeft: 8 }} align="left">
                    {item.display}
                  </Caption2>
                </SuggestionListItem>
              ))
            }
          </SuggestionList>
        )
      }
      <MentionsInput
        inputRef={ref}
        value={value}
        onKeyDown={handlKeyDownMention}
        onKeyPress={handleSelected}
        onChange={
          (event, newValue, newPlainTextValue, mentions) => {
            setFocusIdx(0)
            handleChange(event, newValue, newPlainTextValue, mentions)
          }
        }
        style={
          {
            width: '100%',
          }
        }
        className="comment_input_mentions-textarea"
        {...inputProps}
      >
        <Mention
          markup={'@[__display__](id:__id__)'}
          displayTransform={(id, display) => `@${display}`}
          trigger="@"
          data={[]}
          className="mentions__mention"
        />
      </MentionsInput>
    </MentionInputBox>
  )
})

export default MentionInput

const MentionInputBox = styled(Flex)`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
`

const SuggestionList = styled(Flex)`
  position: absolute;
  bottom: 56px;
  left: 8px;
  flex-direction: column-reverse;
  background-color: ${colors.white_f4};

  width: 328px;
  max-height: 160px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  > div {
    margin: 2px 0px;
  }

  > div:first-child {
    margin-bottom: 0;
  }
`
const SuggestionListItem = styled(Flex)`
  width: 100%;
  min-height: 48px;
  align-items: center;
  background-color: ${({ isFocus }) =>
    isFocus ? colors.white_f4 : 'transparent'};
`
