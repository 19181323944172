import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { H3, Modal } from '@components/atoms'
import {
  CheckTextBox,
  Standard,
  ForStudent,
  RoundBtn,
} from '@components/molecules'
import { PaymentForm } from '@components/organisms'
import { mobile } from '@styles/media'
import { convertToPricingComma } from '@utils/string'
import { colors } from '@resources/colors'

const PopupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;

  @media ${mobile} {
    width: 100%;
  }
`

const Popup = styled.div`
  padding: 20px;

  width: 100%;
`

const PopupTitle = styled.h2`
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  border-bottom: 1px solid #eeeeee;
  padding: 0 100px 15px 100px;
  margin: 0 0 15px 0;

  @media ${mobile} {
    padding: 0 10px 15px 10px;
  }
`
const Item = styled.div`
  display: flex;
  padding: 10px;
`
const ItemName = styled.p`
  flex: 1;
  align-self: center;
  text-align: left;
`
const ItemValue = styled.p`
  flex: 1;
  align-self: center;
  text-align: right;
`

const PaymentMethod = styled.div`
  border-top: 1px solid #eee;
  margin-top: 15px;
  padding: 10px;

  & > div {
    display: flex;
    justify-content: flex-start;
  }

  & img {
    margin: 10px;
  }
`

const Agreement = styled.div`
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #eee;
  margin-top: 10px;

  & img {
    margin: 10px 10px 10px 20px;
  }
`

const ButtonWrapper = styled.div`
  padding: 0 0 10px 0;
  margin: 10px 20px 10px 20px;
`

const ErrorMessage = styled.p`
  color: red;
  margin: 15px 10px 25px 10px;
`

const View = ({
  user,
  purchasePlan,
  paymentParams,
  isShowing,
  toggle,
  isOnIframe,
  setIsOnIframe,
  getPaymentParams,
  setPaymentParams,
}) => {
  const submitRef = React.createRef()
  const [errorMessage, setErrorMessage] = useState('')
  const [paymentInfo, setPaymentInfo] = useState({
    isAgreed: false,
    paymentMethod: '',
  })

  const handleClear = () => {
    setPaymentInfo({
      isAgreed: false,
      paymentMethod: '',
    })
  }

  const handleChange = (key, value) => {
    paymentInfo[key] = value
    setPaymentInfo({ ...paymentInfo })
  }

  const handleClose = () => {
    setIsOnIframe(false)
    handleClear()
  }

  const handlePay = async () => {
    if (paymentInfo.isAgreed && paymentInfo.paymentMethod) {
      toggle()
      handleClear()
      setErrorMessage('')

      const paymentParamsResult = await getPaymentParams({
        purchaseItemId: purchasePlan.id,
        paymentMethod: paymentInfo.paymentMethod,
        isMobileWeb: !!isMobile,
      })

      setPaymentParams(paymentParamsResult)
    }
    else {
      setErrorMessage('결제수단과 동의여부를 선택해주세요')
    }
  }

  const planName = useMemo(() => {
    if (purchasePlan?.name) {
      const parseName = purchasePlan?.name.split('_')
      if (parseName?.length >= 2) {
        return parseName[2]?.charAt(0).toUpperCase() + parseName[2]?.slice(1)
      }
    }
    return ''
  }, [purchasePlan?.name])

  return (
    <>
      {
        purchasePlan && (
          <>
            {
              isOnIframe && (
                <PaymentForm
                  paymentMethod={paymentInfo.paymentMethod}
                  paymentParams={paymentParams}
                  submitRef={submitRef}
                  isOnIframe={isOnIframe}
                />
              )
            }
            <Modal.View
              isShowing={isShowing}
              toggle={toggle}
              onClose={handleClose}
            >
              <PopupWrapper>
                <Popup>
                  <PopupTitle>플랜 결제</PopupTitle>

                  <Item>
                    <ItemName>플랜 이름</ItemName>
                    {
                      planName && (
                        <H3 style={{ fontSize: '1.5rem' }}>{planName}</H3>
                      )
                    }
                    {/* {
                      purchasePlan.plan === 'student' ? (
                        <ForStudent style={{ fontSize: '1.5rem' }} />
                      ) : (
                        <Standard style={{ fontSize: '1.5rem' }} />
                      )
                    } */}
                  </Item>

                  <Item>
                    <ItemName>결제 단위</ItemName>
                    <ItemValue>
                      {purchasePlan.period}
                      {' '}
개월
                    </ItemValue>
                  </Item>

                  <Item>
                    <ItemName>결제 금액</ItemName>
                    {
                      purchasePlan && (
                        <ItemValue>
                          {convertToPricingComma(purchasePlan.price)}
원 /
                          {' '}
                          {purchasePlan.period}
                      개월
                        </ItemValue>
                      )
                    }
                  </Item>

                  <PaymentMethod>
                    <ItemName>결제 수단</ItemName>

                    <CheckTextBox
                      onClick={() => handleChange('paymentMethod', 'CARD')}
                      checked={paymentInfo.paymentMethod === 'CARD'}
                      text="신용카드"
                    />
                  </PaymentMethod>

                  <Agreement>
                    <CheckTextBox
                      onClick={
                        () =>
                          handleChange('isAgreed', !paymentInfo.isAgreed)
                      }
                      checked={paymentInfo.isAgreed}
                      text="매월 정기 결제 동의합니다."
                    />
                  </Agreement>

                  {
                    errorMessage
                  && !(paymentInfo.isAgreed && paymentInfo.paymentMethod) && (
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                    )
                  }

                  <ButtonWrapper>
                    <RoundBtn
                      text="결제하기"
                      color={colors.primary}
                      onClick={handlePay}
                      style={{ width: '100%' }}
                    />
                  </ButtonWrapper>
                </Popup>
              </PopupWrapper>
            </Modal.View>
          </>
        )
      }
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
