// import { useRouter } from 'next/router'
import React, { useState } from 'react'
import styled from 'styled-components'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import Link from 'next/link'

import { CopiedPopup } from '@components/molecules'
import { Flex, Img } from '@components/atoms'
import { useWindowSize } from '@utils/hooks'
import { Link, useHistory } from 'react-router-dom'
import { QuizBtn } from '.'
import { mbtiData } from './mbtiData'
import EventLayout from './EventLayout'
import SocialkingKakaoShareButton from './SocialkingKakaoShareButton'

const facebook_share_btn = require('../../../public/images/facebook_share_btn.png')
const twitter_share_btn = require('../../../public/images/twitter_share_btn.png')
const naver_blog_share_btn = require('../../../public/images/share_naver_blog_img.png')
const url_share_btn = require('../../../public/images/url_share_btn.png')

// type Props = {
//   mbtiType: string
//   shareNaverBlog: React.MouseEventHandler<HTMLImageElement>
// }

const StatusBar = ({ value }) => {
  return (
    <Flex style={{ width: 248, justifyContent: 'space-between' }}>
      <Flex
        style={
          {
            width: 48,
            height: 8,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            background:
            value > 0
              ? 'linear-gradient(to right,rgb(163, 79, 248),rgb(145, 79, 248))'
              : '#d4d4d4',
          }
        }
      />
      <Flex
        style={
          {
            width: 48,
            height: 8,
            background:
            value > 1
              ? 'linear-gradient(to right,rgb(145, 79, 248),rgb(133, 79, 248))'
              : '#d4d4d4',
          }
        }
      />
      <Flex
        style={
          {
            width: 48,
            height: 8,
            background:
            value > 2
              ? 'linear-gradient(to right,rgb(133, 79, 248),rgb(121, 79, 248))'
              : '#d4d4d4',
          }
        }
      />
      <Flex
        style={
          {
            width: 48,
            height: 8,
            background:
            value > 3
              ? 'linear-gradient(to right,rgb(121, 79, 248),rgb(110, 79, 248))'
              : '#d4d4d4',
          }
        }
      />
      <Flex
        style={
          {
            width: 48,
            height: 8,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            background:
            value > 4
              ? 'linear-gradient(to right,rgb(110, 79, 248),rgb(98, 79, 248))'
              : '#d4d4d4',
          }
        }
      />
    </Flex>
  )
}

const SocialkingResultTemplate = ({
  mbtiType,
  shareNaverBlog,
}) => {
  const deviceSize = useWindowSize()
  const history = useHistory()
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const handleCopy = () => {
    // LOG_EVENT.LANDING_CLICK_SHARE_DIRECT_SOCIALKING()
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  return (
    <EventLayout style={{ padding: 24 }}>
      <Link href="/">
        <Img
          src={'/images/hreum_logo.png'}
          width={132}
          height={24}
          alt="hreum_logo"
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <BmText size={20} style={{ margin: '32px 0 16px 0' }}>
        {mbtiData[mbtiType]?.title}
      </BmText>
      <Text
        size={20}
        color="#5b49f3"
        style={{ textAlign: 'center', width: 300, wordBreak: 'keep-all' }}
      >
        {mbtiType?.toUpperCase?.()}
      </Text>
      <Img
        src={mbtiData[mbtiType]?.imageName}
        width={216}
        height={216}
        alt="mbti_img"
        style={
          {
            borderRadius: '50%',
            margin: '40px 0',
            objectFit: 'cover',
          }
        }
      />
      <MbtiStatusBox>
        <Flex style={{ alignItems: 'center', marginBottom: 20 }}>
          <BmText size={16} style={{ marginRight: 8 }}>
            {mbtiData[mbtiType]?.rating}
          </BmText>
          <ApText size={14} color="#5b49f3" weight={500}>
            {`(${mbtiData[mbtiType]?.rate})`}
          </ApText>
        </Flex>
        <Flex style={{ alignItems: 'center', margin: '12px 0' }}>
          <BmText
            size={12}
            style={{ marginRight: 10, width: 48, textAlign: 'center' }}
          >
            친화력
          </BmText>
          <StatusBar value={mbtiData[mbtiType]?.affinity} />
        </Flex>
        <Flex style={{ alignItems: 'center', margin: '12px 0' }}>
          <BmText
            size={12}
            style={{ marginRight: 10, width: 48, textAlign: 'center' }}
          >
            대화능력
          </BmText>
          <StatusBar value={mbtiData[mbtiType]?.conversation} />
        </Flex>
        <Flex style={{ alignItems: 'center', margin: '12px 0' }}>
          <BmText
            size={12}
            style={{ marginRight: 10, width: 48, textAlign: 'center' }}
          >
            매력
          </BmText>
          <StatusBar value={mbtiData[mbtiType]?.charm} />
        </Flex>
        <Flex style={{ alignItems: 'center', margin: '12px 0' }}>
          <BmText
            size={12}
            style={{ marginRight: 10, width: 48, textAlign: 'center' }}
          >
            친절함
          </BmText>
          <StatusBar value={mbtiData[mbtiType]?.kindness} />
        </Flex>
      </MbtiStatusBox>
      <DescriptionBox>
        <Flex
          style={{ width: '100%', justifyContent: 'center', marginBottom: 32 }}
        >
          <BmText size={16}>유형 설명</BmText>
        </Flex>
        {
mbtiData[mbtiType]?.description.map((item, index) => {
  return (
    <DescriptionItem key={index}>
      <div
        style={
          {
            marginTop: 8,
            minWidth: 4,
            maxWidth: 4,
            width: '100%',
            height: 4,
            backgroundColor: '#5d4afc',
            marginRight: 12,
            borderRadius: 2,
          }
        }
      />
      <ApText weight={400} size={14}>
        {item}
      </ApText>
    </DescriptionItem>
  )
})
        }
      </DescriptionBox>

      <PartnerBox
        onClick={
          () => {
            // LOG_EVENT.LANDING_CLICK_COMMUNICATION_TO_BEST_SOCIALKING()
            history.push({
              pathname: `https://app.hreum.me/stores`,
              state: { isPassword: false },
            })
          }
        }
      >
        <BmText size={16} style={{ marginBottom: 12 }}>
          🏆 나의 인맥 서열을 높여줄
        </BmText>
        <BmText size={16}>
          <BmText size={16} color="#5b49f3">
            찰떡 궁합
            {' '}
          </BmText>
          친구는?
        </BmText>
        <Img
          src={mbtiData[mbtiData[mbtiType]?.best_partner]?.imageName}
          width={104}
          height={104}
          style={
            {
              borderRadius: '50%',
              margin: '24px 0',
              objectFit: 'cover',
            }
          }
        />
        <BmText
          size={20}
          color="#5b49f3"
          style={{ textAlign: 'center', wordBreak: 'keep-all' }}
        >
          {mbtiData[mbtiType]?.best_partner?.toUpperCase()}
        </BmText>
        <BmText size={16} style={{ margin: '12px 0 32px 0' }}>
          {mbtiData[mbtiData[mbtiType]?.best_partner]?.title}
        </BmText>
        <Img
          src={mbtiData[mbtiType]?.liveRoomImagePath}
          width={264}
          height={220}
          alt="liveRoom"
        />
        <QuizBtn
          onClick={
            (e) => {
              e.stopPropagation()
              e.preventDefault()
              // LOG_EVENT.LANDING_CLICK_COMMUNICATION_TO_BEST_SOCIALKING()
              history.push({
                pathname: `https://app.hreum.me/stores`,
                state: { isPassword: false },
              })
            }
          }
          quiz={
            `살롱에서 ${mbtiData[
              mbtiType
            ]?.best_partner?.toUpperCase()} 친구랑 소통하기`
          }
          style={
            {
              width: 264,
              marginTop: 39,
              backgroundColor: '#5d4afc',
              color: '#ffffff',
              height: 48,
            }
          }
        />
      </PartnerBox>

      <ApText style={{ marginTop: 64 }} size={20} weight={700}>
        이 결과 공유하기
      </ApText>
      <ShareBox>
        <div>
          <SocialkingKakaoShareButton
            // onClick={() => LOG_EVENT.LANDING_CLICK_SHARE_KAKAO_SOCIALKING()}
            title={mbtiData[mbtiType]?.title}
            imageUrl={`https://storage.hreum.me/resources/socialking/socialking_${mbtiType}_img.png`}
          />
          <div>
            <FacebookShareButton
              url={`https://hreum.me/p/socialking/${mbtiType}`}
            >
              <Img
                src={facebook_share_btn}
                width={48}
                height={48}
                alt="facebook_share"
              />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={`https://hreum.me/p/socialking/${mbtiType}`}
            >
              <Img
                src={twitter_share_btn}
                width={48}
                height={48}
                alt="twitter_share"
              />
            </TwitterShareButton>
          </div>
        </div>
        <div>
          <div>
            <Img
              src={naver_blog_share_btn}
              width={48}
              height={48}
              style={{ cursor: 'pointer' }}
              alt="naver_share"
              onClick={shareNaverBlog}
            />
          </div>
          <CopyToClipboard
            text={window?.location?.href}
            onCopy={handleCopy}
          >
            <div style={{ cursor: 'pointer' }}>
              <Img src={url_share_btn} width={48} height={48} alt="url_share" />
            </div>
          </CopyToClipboard>
        </div>
      </ShareBox>
      <QuizBtn
        quiz="테스트 다시하기"
        style={
          {
            backgroundColor: '#5d4afc',
            color: '#ffffff',
            maxWidth: 328,
          }
        }
        onClick={
          () => {
            // LOG_EVENT.LANDING_CLICK_RESTART_SOCIALKING()
            history.push({
              pathname: '/p/socialking',
              state: { isPassword: false },
            })
          }
        }
      />
      <QuizBtn
        quiz="인맥왕 서열표"
        color="#646464"
        style={
          {
            maxWidth: 328,
            marginTop: 16,
          }
        }
        onClick={
          () => {
            // LOG_EVENT.LANDING_CLICK_RANKING_SOCIALKING()
            history.push({
              pathname: '/p/socialking/ranking',
              state: { isPassword: false },
            })
          }
        }
      />
      <a
        style={
          {
            cursor: 'pointer',
            marginTop: 66,
          }
        }
        onClick={
          () => {
            // LOG_EVENT.LANDING_CLICK_BANNER_SOCIALKING()
            // if (deviceSize <= 480) LOG_EVENT.LANDING_CLICK_APP_STORE_SOCIALKING()
          }
        }
        href={
          deviceSize <= 480
            ? 'https://app.hreum.me/stores'
            : 'https://hreum.me'
        }
      >
        <BannerImg src={'/images/socialking_test_banner_img.png'} />
      </a>
      {isCopied && <CopiedPopup />}
    </EventLayout>
  )
}

export default SocialkingResultTemplate

const Text = styled.p`
  font-family: ${({ type }) =>
    type ? `NotoSansCJKkr-${type}` : 'NotoSansCJKkr-Bold'};
  font-size: ${({ size }) => (size ? `${size}px` : '32px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  margin: 0;
  padding: 0;
`

const BmText = styled.span`
  font-family: BMDOHYEON;
  font-size: ${({ size }) => (size ? `${size}px` : '32px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  margin: 0;
  padding: 0;
`

const ApText = styled.p`
  font-family: AppleSDGothicNeo;
  font-size: ${({ size }) => (size ? `${size}px` : '32px')};
  font-weight: ${({ weight }) => weight || 500};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  margin: 0;
  padding: 0;
`

const MbtiStatusBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 752px;
  height: 232px;

  flex-direction: column;
  padding: 20px 18px 0px 18px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 12%);
  box-sizing: border-box;
  background-color: #ffffff;
`

const DescriptionBox = styled.div`
  max-width: 752px;
  width: 100%;
  border-radius: 16px;
  background-color: #fafafa;
  padding: 32px 16px;
  box-sizing: border-box;

  margin: 24px 0;

  div + div {
    margin-top: 20px;
  }
`

const DescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* align-items:center; */
  align-items: end;
`

const PartnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 752px;
  box-sizing: border-box;
  height: 638px;
  padding: 24px 16px 16px 16px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 12%);
  background-color: #ffffff;
`

const BannerImg = styled.img`
  max-width: 500px;
  width: 100vw;
  max-height: 269.333333333333333px;
  height: 53.866666666666667vw;
  object-fit: cover;
`

const ShareBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 48px 0;

  > div {
    display: flex;
  }

  > div + div {
    margin-top: 24px;
  }

  div + div {
    margin-left: 32px;
  }
`
