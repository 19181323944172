import { IS_DEV } from '@consts/'
import rtc from '../../public/AgoraRTC_N-4.7.3'
import RTCClient from './rtc-client'

export const ClientRole = {
  /**
   * 1: A host can both send and receive streams.
   */
  Broadcaster: 'host',
  /**
   * 2: The default role. An audience can only receive streams.
   */
  Audience: 'audience',
}

const agoraAppId = '8f97e741ca404adca74579c8c2fb3a9f'

const rtcLogger = (type = 'log', eventName, ...evt) => {
  if (IS_DEV && type === 'log')
    console.log('[LOG]', '[AgoraRtc]', '[event]', `[${eventName}]`, ...evt)
  else console.error('[ERROR]', '[AgoraRtc]', `[${eventName}]`, ...evt)
}

export class AgoraRtc {
  static AGORA_APP_ID = agoraAppId
  static rtcEngine: RTCClient | NULL = null
  static joinedChannelId: String = ''
  static isConnected: Boolean = false
  static allMuted: Boolean = false
  static localTrackPublished: Boolean = false

  static localAudioStream = null
  static remoteAudioStreams = []

  static init = async () => {
    try {
      if (this.rtcEngine) {
        await this._clear()
      }

      this.rtcEngine = new RTCClient()

      this.rtcEngine.on('user-published', async (user, mediaType) => {
        rtcLogger('log', 'user-published', user, mediaType)
        // Initiate the subscription
        await this.rtcEngine.client.subscribe(user, mediaType)

        // If the subscribed track is an audio track
        if (mediaType === 'audio') {
          const { audioTrack } = user
          // Play the audio
          this.remoteAudioStreams.push(audioTrack)
          // 전체 음소거 시,
          if (this.allMuted) return
          audioTrack.play()
        }
        else {
          const { videoTrack } = user
          // Play the video
          videoTrack.play('AGORA_PLAYER')
        }
      })

      this.rtcEngine.on('user-unpublished', async (user, mediaType) => {
        rtcLogger('log', 'user-unpublished', user, mediaType)

        if (mediaType === 'audio') {
          const { audioTrack } = user
          // Play the audio
          this.remoteAudioStreams.pop(audioTrack)
        }
      })

      this.rtcEngine.on('connection-state-change', (curState, prevState) => {
        rtcLogger('log', 'connection-state-change', curState, prevState)
      })

      await this.rtcEngine.init(this.AGORA_APP_ID)

      rtc.onMicrophoneChanged = async info => {
        console.log('microphone changed!', info.state, info.device)

        /**
         * 외부 장치가 연결되면 연결된 장치로 전환
         * 외부 장치가 해제되면 기본 장치로 전환
         * 아무 장치도 없으면 연결 X
         */
        const deviceList = await rtc.getMicrophones()
        const { state } = info

        let deviceId = deviceList[0]['deviceId']

        if (state === 'ACTIVE') {
          deviceId = info.device['deviceId']
        }

        if (this.localAudioStream && this.deviceId) {
          this.localAudioStream.setDevice(deviceId)
        }
      }

      rtc.onPlaybackDeviceChanged = async info => {
        console.log('speaker changed!', info.state, info.device)
      }
    }
    catch (error) {
      rtcLogger('err', 'init catch error', error)
    }
  }

  static _clear = async () => {
    try {
      if (this.rtcEngine) {
        await this.leaveChannel()
        // await this.rtcEngine.removeEvents();
        // await this.rtcEngine.destroyClient();
        this.rtcEngine = null
      }
      return true
    }
    catch (error) {
      rtcLogger('err', '_clear catch error', error)
    }
    return false
  }

  static joinChannel = async (agoraRtcChannelId, agoraRtcUserToken, uid) => {
    rtcLogger('log', 'agoraRtc joinChannel')
    if (!this.rtcEngine) {
      rtcLogger('err', 'joinChannel not init')
      return false
    }
    if (!agoraRtcUserToken || !uid) {
      rtcLogger(
        'err',
        `joinChannel required params agoraRtcUserToken=${agoraRtcUserToken}, uid=${uid}`,
      )
      return false
    }

    /* rtm */
    try {
      await this.leaveChannel()

      await this.rtcEngine.joinChannel({
        appId: agoraAppId,
        token: agoraRtcUserToken,
        channel: agoraRtcChannelId,
        uid: `${uid}`,
      })
      // await this.rtcEngine.joinChannel(agoraRtcChannelId)
      this.joinedChannelId = agoraRtcChannelId
      this.isConnected = true
      return true
    }
    catch (error) {
      this.isConnected = false
      rtcLogger('err', 'joinChannel catch error', error)
    }
    return false
    /* rtm end */
  }

  static leaveChannel = async () => {
    if (!this.rtcEngine) {
      rtcLogger('err', 'leaveChannel not init')
      return false
    }

    try {
      if (this.joinedChannelId) {
        /**
         * login 한 상태를 유지하도록 아키텍처를 변경하거나,
         * logout 으로 leaveChannel 대체
         *
         * 둘 다 수행하면
         *   IOS 레드박스 에러
         *   Android 아무런 문구없이 Throw 후 해당 javascript func 스택 모두 강제 종료.
         */
        // await this.rtcEngine.leaveChannel(this.joinedChannelId)
        await this.rtcEngine.client.leave()
        this.joinedChannelId = ''
      }
      return true
    }
    catch (error) {
      rtcLogger('err', 'leaveChannel catch error', error)
    }
    return false
  }

  static setClientRole = async role => {
    if (role === ClientRole.Broadcaster) {
      if (!this.localAudioStream) await this.createAudio(true)
      await this.rtcEngine.client.setClientRole(role)
      await this.publish()
    }
    else {
      if (this.localTrackPublished) await this.unpulishLocalAudio()
      await this.rtcEngine.client.setClientRole(role)
    }
  }

  static createAudio = async () => {
    this.localAudioStream = await this.rtcEngine.createAudio()
  }

  static muteLocalAudioStream = async toMute => {
    if (!this.localAudioStream) return
    if (toMute) {
      this.localAudioStream.setVolume(0)
      // this.localAudioStream.setMuted(true) => 스테레오 이슈 발생 코드
      // await this.localAudioStream.setEnabled(false)
      // this.localAudioStream.stop()
      // await this.unpulishLocalAudio()
    }
    else {
      // this.localAudioStream.setMuted(false) => 스테레오 이슈 발생 코드
      this.localAudioStream.setVolume(200)
      // await this.localAudioStream.setEnabled(true)
      // this.localAudioStream.play()
      // await this.publish()
    }
  }

  static muteAllRemoteAudioStreams(toMute) {
    if (!this.remoteAudioStreams || this.remoteAudioStreams.length < 1) return
    if (toMute) {
      this.allMuted = true
      // this.remoteAudioStreams.map(stream => stream.disableAudio())
      this.remoteAudioStreams.map(stream => stream.stop())
    }
    else {
      this.allMuted = false
      // this.remoteAudioStreams.map(stream => stream.enableAudio())
      this.remoteAudioStreams.map(stream => stream.play())
    }
  }

  static publish = async () => {
    try {
      // Remove this line if the channel profile is not live broadcast.
      await this.rtcEngine.client.publish([this.localAudioStream])
      rtcLogger('log', 'pulish success')
      this.localTrackPublished = true
    }
    catch (e) {
      rtcLogger('err', 'pulish failed', e)
      this.localTrackPublished = false
    }
  }

  static unpulishLocalAudio = async () => {
    if (!this.localAudioStream) return
    try {
      // Remove this line if the channel profile is not live broadcast.
      this.localAudioStream.stop()
      if (this.rtcEngine) {
        await this.rtcEngine.unpublish([this.localAudioStream])
      }
      rtcLogger('log', 'unpulishLocalAudio success')
    }
    catch (e) {
      rtcLogger('err', 'unpulishLocalAudio failed', e)
    }
  }

  static getListener(eventName, func) {
    return this.rtcEngine.getListeners(eventName, func)
  }

  static addListener(eventName, func) {
    return this.rtcEngine.on(eventName, func)
  }

  static enableAudioVolumeIndication() {
    return this.rtcEngine.enableAudioVolumeIndicator()
  }

  static removeAllListeners() {
    return this.rtcEngine.removeAllListeners()
  }

  static getDevices = async () => {
    try {
      const res = await rtc.getDevices() // 카메라도 포함됨
      if (res.length > 0) {
        return res
      }
    }
    catch (error) {
      console.error('get devices error!', error)
      return false
    }
  }

  static getMicrophones = async () => {
    try {
      const res = await rtc.getMicrophones()
      if (res.length > 0) {
        return res
      }
    }
    catch (error) {
      console.error('get devices error!', error)
      return false
    }
  }

  static getConnectionState = async () => {
    if (this.rtcEngine) return this.rtcEngine.connectionState
  }

  static getCurrMicrophone = () => {
    if (!this.localAudioStream) {
      console.error('localAudioStream is null')
      return null
    }

    return this.localAudioStream._deviceName
  }
}
