import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
// import styled from 'styled-components'
import { Loading } from '@components/molecules'
import { Redirect } from 'react-router-dom'
// import { OnlyPremiumPopup } from '@components/organisms'
import { useStore } from './hooks'

/**
 * Auth 체크를 하는 HOC for page컴포넌트
 * hooks 사용하면서, functional component로 리펙토링
 * updated at 2020. 09. 10
 */
export default Component => {
  const Protected = ({ match, location }) => {
    const { authStore } = useStore()
    const [isLoading, setIsLoading] = useState(true)
    // const [isAuthenticated, setIsAuthenticated] = useState(false)

    // const {
    //   isShowing: isPremiumShowing,
    //   toggle: togglePremium,
    // } = OnlyPremiumPopup.usePopup()

    useEffect(() => {
      authStore.login().then(res => {
        if (authStore.jsonWebToken) {
          // setIsAuthenticated(true)
          setIsLoading(false)
        }
        else {
          // setIsAuthenticated(false)
          setIsLoading(false)
        }
      })
    }, [authStore, authStore.jsonWebToken])

    const _logout = () => {
      authStore.logout()
    }

    return (
      <div className="authComponent" style={{ width: '100vw' }}>
        {
          isLoading ? (
            <Loading type="spin" color="white" />
          ) : authStore.currentUser ? (
            <>
              <Component
                match={match}
                location={location}
                logout={_logout}
                user={authStore.currentUser}
              />
              {/* <OnlyPremiumPopup.View
                isShowing={isPremiumShowing}
                toggle={togglePremium}
              /> */}
            </>
          ) : (
            <Redirect
              to={
                {
                  pathname: '/p/login',
                  state: {
                    from: { pathname: location.pathname, search: location.search },
                  },
                }
              }
            />
          )
        }
      </div>
    )
  }

  return observer(Protected)
}
