import { Div } from '@components/atoms'
import {
  HomeCastSection,
  HomeHreumSection,
  HomeSalonSection,
} from '@components/organisms'
import { colors } from '@resources/colors'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const HomeContentsSection = () => {
  const [contentFilter, setContentFilter] = useState('total')
  return (
    <Div display="flex" flexDirection="column" width="100%" alignItems="center">
      <Div
        margin="12px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="calc(100% + 96px)"
        padding="0 48px"
        height="70px"
        borderTop={`1px solid ${colors.white_f4}`}
        borderBottom={`1px solid ${colors.white_f4}`}
      >
        <Div
          display="flex"
          alignItems="center"
          gap="12px"
          maxWidth="1704px"
          width="100%"
          height="100%"
        >
          <TagBox
            onClick={() => setContentFilter('total')}
            active={contentFilter === 'total'}
          >
            전체
          </TagBox>
          <TagBox
            onClick={() => setContentFilter('liveRooms')}
            active={contentFilter === 'liveRooms'}
          >
            살롱 | 라이브 오디오
          </TagBox>
          <TagBox
            onClick={() => setContentFilter('casts')}
            active={contentFilter === 'casts'}
          >
            캐스트 | 팟캐스트 콘텐츠
          </TagBox>
          <TagBox
            onClick={() => setContentFilter('mixtapes')}
            active={contentFilter === 'mixtapes'}
          >
            플라 | 음악 플레이리스트 라디오
          </TagBox>
        </Div>
      </Div>
      {
        (contentFilter === 'total' || contentFilter === 'liveRooms') && (
          <HomeSalonSection active={contentFilter === 'liveRooms'} />
        )
      }
      {
        (contentFilter === 'total' || contentFilter === 'casts') && (
          <HomeCastSection active={contentFilter === 'casts'} />
        )
      }
      {
        (contentFilter === 'total' || contentFilter === 'mixtapes') && (
          <HomeHreumSection active={contentFilter === 'mixtapes'} />
        )
      }
    </Div>
  )
}

export default HomeContentsSection

const TagBox = styled(Div)`
  transition: 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
  height: 36px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 19px;

  ${({ active }) =>
    active
      ? css`
          background-color: ${colors.primary};
          border: 1px solid ${colors.primary};
          color: ${colors.white_f4};
        `
      : css`
          background-color: ${colors.white_fa};
          border: 1px solid ${colors.white_f4};
          color: ${colors.black_24};
        `}
`
