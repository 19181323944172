import { DJ_LEVEL } from '@consts/'
import { images } from '@resources/'
import React from 'react'
import styled from 'styled-components'

const DJBadge = ({ isMine, discJockeyLevel, style }) => {
  /**
   *  0 <= null < 10
   *  10 <= 심사중 < 100
   *  100 <= BABY < 200
   *  200 <= GENERAL < 300
   *  300 <= PARTNEL < 1000
   *  1000 <= OFFICIAL
   */

  if (
    discJockeyLevel >= DJ_LEVEL.NOT_YET
    && discJockeyLevel < DJ_LEVEL.APPLICANT
  ) {
    return <div />
  }
  if (
    isMine
    && discJockeyLevel >= DJ_LEVEL.APPLICANT
    && discJockeyLevel < DJ_LEVEL.BABY
  ) {
    return (
      <span style={{ ...style, fontFamily: 'NotoSansCJKkr-Bold' }}>
        심사 중
      </span>
    )
  }
  if (discJockeyLevel >= DJ_LEVEL.BABY && discJockeyLevel < DJ_LEVEL.GENERAL) {
    return (
      <Badge src={images.badge_babydj_img} alt="badge_icon" style={style} />
    )
  }
  if (
    discJockeyLevel >= DJ_LEVEL.GENERAL
    && discJockeyLevel < DJ_LEVEL.PARTNER
  ) {
    return <Badge src={images.badge_dj_img} alt="badge_icon" style={style} />
  }
  if (
    discJockeyLevel >= DJ_LEVEL.PARTNER
    && discJockeyLevel < DJ_LEVEL.OFFICIAL
  ) {
    return (
      <Badge src={images.badge_partnerdj_img} alt="badge_icon" style={style} />
    )
  }
  if (discJockeyLevel >= DJ_LEVEL.OFFICIAL) {
    return (
      // <Badge src={images.badge_official_img} alt="badge_icon" style={style} />
      <></>
    )
  }

  return <div />
}

export default DJBadge

const Badge = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`
