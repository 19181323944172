import { Div } from '@components/atoms'
import { CustomTab } from '@components/molecules'
import {
  SearchHouseSection,
  SearchTotalSection,
  SearchUserSection,
  SearchContentsSection,
} from '@components/organisms'
import React, { useState } from 'react'
import PageLayout from './PageLayout'

const SearchTemplate = () => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0)
  const [contentFilter, setContentFilter] = useState('total')

  return (
    <PageLayout>
      <CustomTab
        // forceRenderTabPanel
        // defaultIndex={userDetail.discJockeyLevel > DJ_LEVEL['BABY'] ? 2 : 0}
        defaultIndex={0}
        selectedIndex={selectedTabIdx}
        onSelect={
          index => {
            setSelectedTabIdx(index)
            if (index === 0) {
              setContentFilter('total')
            }
          }
        }
        className="user_detail_tab"
        style={{ width: '100%', maxWidth: 1704 }}
        menu={['전체', '유저', '하우스', '콘텐츠']}
        contents={
          [
            <SearchTotalSection
              handleContents={() => setSelectedTabIdx(3)}
              contentFilter={contentFilter}
              setContentFilter={setContentFilter}
            />,
            <SearchUserSection />,
            <SearchHouseSection />,
            <SearchContentsSection
              contentFilter={contentFilter}
              setContentFilter={setContentFilter}
            />,
          ]
        }
      />
    </PageLayout>
  )
}

export default SearchTemplate
