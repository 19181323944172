import React, { useState, useEffect, useCallback } from 'react'
import { Caption1, Flex } from '@components/atoms'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { FlexColumnBox, SongItem } from '@components/molecules'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { debounce } from 'lodash'
import { useStore, useTagLoading } from '@utils/hooks'
import { images } from '@images/'

const AudioList = styled(FlexColumnBox)`
  width: 100%;
  margin: 0;
  justify-content: flex-start;
  align-items: end;
  height: 100%;
  max-height: ${({ listHeight }) => listHeight ?? listHeight}px;

  > div {
    width: 100%;
  }
`

const NoAudioBox = styled(FlexColumnBox)`
  width: 100%;
  height: 100%;
  min-height: ${({ listHeight }) => listHeight ?? listHeight}px;
  align-items: center;

  p {
    margin: 16px 0;
  }
`

const SearchInputBox = styled(Flex)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #f4f4f4;
  border: none;
  padding: 10px;
`

const SearchInput = styled.input`
  border: none;
  flex: 1;
  font-size: 14px;
  background-color: #f4f4f4;
  font-family: NotoSansCJKkr-Medium;
  margin-left: 8px;
`

const MyUploadAudioSection = ({
  listHeight = 600,
  from = 'mixtape',
  selectedSong = null,
  handleSelectedSong = () => {},
}) => {
  const { songStore, mixtapeStore } = useStore()
  const [offset, setOffset] = useState(0)
  const [keyword, setKeyword] = useState('')
  // const [filteredList, setFiltertedList] = useState([])

  // const uploadSongList = songStore.uploadSongList || null
  // const uploadSongListCount = songStore.uploadSongListCount || null
  const autocompleteObj = songStore.autocompleteObj || null
  const currentAutocomplete = songStore.currentAutocomplete || null
  // const fetchMyUploadSongList = songStore.fetchMyUploadSongList || (() => {})
  const fetchAutocomplete = songStore.fetchAutocomplete || (() => {})

  const tempMixtape = mixtapeStore.tempMixtape || null
  const addMixtapeSongListToTempMixtape =
    mixtapeStore.addMixtapeSongListToTempMixtape || (() => {})
  const deleteMixtapeSongToTempMixtape =
    mixtapeStore.deleteMixtapeSongToTempMixtape || (() => {})

  const { setIsLoading, Spinner } = useTagLoading({
    style: {
      marginLeft: '-40px',
      height: 700,
      backgroundColor: 'white',
    },
  })

  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(offset + 10)
  }

  const handleAdd = useCallback(
    item => {
      if (from === 'live') {
        handleSelectedSong(item)
      }
      else {
        addMixtapeSongListToTempMixtape(tempMixtape, [item])
      }
    },
    [from],
  )
  const handleDelete = useCallback(
    item => {
      if (from === 'live') {
        handleSelectedSong(null)
      }
      else {
        const mixtapeSongIndex =
          tempMixtape
          && tempMixtape['MixtapesHaveSongs'].findIndex(
            elem => elem['songId'] === item['id'],
          )

        deleteMixtapeSongToTempMixtape(tempMixtape, mixtapeSongIndex)
      }
    },
    [from],
  )

  const handleSearch = debounce(value => {
    // 검색 시, offset 초기화
    if (offset > 1) {
      setOffset(0)
    }
    setKeyword(value)
  }, 300)

  useEffect(() => {
    const fetchMoreData = async () => {
      // 첫 로드나, 검색 시에만 스피너
      if (offset < 1) setIsLoading(true)
      // 검색 시 && 살롱에서
      if (keyword !== '' && from === 'live') {
        await fetchAutocomplete(keyword, offset, false)
      }
      else {
        await fetchAutocomplete(keyword, offset)
      }
      if (offset < 1) setIsLoading(false)
    }
    fetchMoreData()
  }, [offset, keyword, fetchAutocomplete, setIsLoading, from])

  // useEffect(() => {
  //   if (currentAutocomplete && currentAutocomplete.autocompleteSongList) {
  //     setFiltertedList(
  //       currentAutocomplete && currentAutocomplete.autocompleteSongList,
  //     )
  //     // if (offset < 1) {
  //     //   console.log('[test] 1111')
  //     //   setFiltertedList(
  //     //     currentAutocomplete && currentAutocomplete.autocompleteSongList,
  //     //   )
  //     // }
  //     // else if (offset > 0) {
  //     //   console.log('[test] 2222')
  //     //   setFiltertedList(prevState =>
  //     //     prevState.concat(
  //     //       currentAutocomplete && currentAutocomplete.autocompleteSongList,
  //     //     ),
  //     //   )
  //     // }
  //   }
  // }, [currentAutocomplete, offset])

  // useEffect(() => {
  //   if (currentAutocomplete && currentAutocomplete.fetchedLength) {
  //     setOffset(currentAutocomplete.fetchedLength)
  //   }
  // }, [currentAutocomplete])

  // useEffect(() => {
  //   return () => {
  //     console.log('[test] didmount?')
  //     setFiltertedList([])
  //     setOffset(0)
  //     setKeyword('')
  //   }
  // }, [])

  return (
    <>
      <FlexColumnBox>
        <Spinner />
        {/* 검색 박스 (임시) */}
        <Flex>
          <SearchInputBox>
            <img
              src={images.search_gray_btn_img}
              alt="search_btn"
              style={{ width: '20px', height: '20px' }}
            />
            <SearchInput
              placeholder="크리에이터 또는 오디오"
              autoFocus
              // value={keyword}
              onChange={e => handleSearch(e.target.value)}
            />
          </SearchInputBox>
        </Flex>
        {/* 검색 박스 (임시) */}
        {
          currentAutocomplete
        && currentAutocomplete.autocompleteSongList
        && currentAutocomplete.autocompleteSongList.length < 1 ? (
              <NoAudioBox listHeight={listHeight}>
                <Caption1>
                  {
                    keyword !== ''
                      ? '검색 결과가 없습니다.'
                      : '아직 업로드한 오디오가 없습니다.'
                  }
                </Caption1>
              </NoAudioBox>
            ) : (
              <AudioList id="scrollableDiv" listHeight={listHeight}>
                {
                  currentAutocomplete && (
                    <InfiniteScroll
                      scrollableTarget="scrollableDiv"
                      hasChildren
                      dataLength={currentAutocomplete.autocompleteSongList.length}
                      scrollThreshold={0.9}
                      height={590}
                      next={countOffset}
                      hasMore={
                        autocompleteObj[keyword]
                  && autocompleteObj[keyword].totalLength
                    > currentAutocomplete.autocompleteSongList.length
                      }
                      loader={
                        <ReactLoading
                          type="spin"
                          color="black"
                          style={
                            {
                              width: '50px',
                              height: '50px',
                              margin: '0 auto 50px auto',
                            }
                          }
                        />
                      }
                    >
                      {
                        currentAutocomplete.autocompleteSongList
                  && currentAutocomplete.autocompleteSongList.map(item => (
                    <SongItem
                      style={{ margin: '18px 0' }}
                      item={item}
                      key={item.id}
                      handleAdd={handleAdd}
                      handleDelete={handleDelete}
                      isExist={
                        from === 'live'
                          ? selectedSong?.id === item?.id
                          : tempMixtape
                            && tempMixtape['MixtapesHaveSongs'].findIndex(
                              elem => elem['songId'] === item['id'],
                            ) > -1
                      }
                    />
                  ))
                      }
                    </InfiniteScroll>
                  )
                }
              </AudioList>
            )
        }
      </FlexColumnBox>
    </>
  )
}

export default observer(MyUploadAudioSection)
