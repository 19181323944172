import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { RegisterPasswordTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { useRenderLogger, useStore } from '@utils/hooks'

const RegisterPasswordPage = () => {
  useRenderLogger('RegisterPasswordPage')
  const { authStore } = useStore()

  const [isCompleted, setIsCompleted] = useState(false)

  const currentUser = authStore.currentUser || null
  const registerPassword = authStore.registerPassword || (() => {})

  return (
    <>
      {
        isCompleted && (
          <Redirect to={{ pathname: '/p/exchange/confirm-password' }} />
        )
      }
      {
        currentUser && currentUser.isExistPassword && (
          <Redirect to={{ pathname: '/p/exchange/confirm-password' }} />
        )
      }
      <RegisterPasswordTemplate
        registerPassword={registerPassword}
        setIsCompleted={setIsCompleted}
      />
    </>
  )
}

export default observer(RegisterPasswordPage)
