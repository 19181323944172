import { StarMainTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import QueryString from 'qs'
import React from 'react'

const StarMainPage = () => {
  useRenderLogger('StarMainPage')

  const { authStore, starStore, purchaseStore } = useStore()
  const [tabIndex, setTabIndex] = React.useState(false)
  const [paymentParams, setPaymentParams] = React.useState(null)

  React.useEffect(() => {
    const param = document.location.href.split('?')
    const query = QueryString.parse(param[1], {
      ignoreQueryPrefix: true,
    })

    if (query && query.tab === 'charge') {
      setTabIndex(true)
    }

    const trackingPurchase = e => {
      const code = e.data && e.data.code ? e.data.code : ''
      setPaymentParams(null)

      if (code === 'SUCCESS') {
        alert('결제 완료!')
        window.location.href = '/p/star/main'
      }
      else if (code === 'FAIL') {
        alert('결제가 실패되었습니다. 흐름 운영팀으로 문의 부탁드립니다.')
      }
    }

    window.addEventListener('message', trackingPurchase)

    return () => {
      window.removeEventListener('message', trackingPurchase)
    }
  }, [])

  if (authStore.currentUser) {
    return (
      <StarMainTemplate
        currentUser={authStore.currentUser}
        fetchTotalStarHistories={
          starStore.fetchTotalStarHistories || (() => {})
        }
        exchangeStar={starStore.exchangeStar || (() => {})}
        myStarHistoryList={starStore.myStarHistoryList || []}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        fetchPurchaseStar={purchaseStore.fetchPurchaseStar || (() => {})}
        purchaseRequstStar={purchaseStore.purchaseRequstStar || (() => {})}
        purchaseStarList={purchaseStore.purchaseStarList || []}
        paymentParams={paymentParams}
        setPaymentParams={setPaymentParams}
      />
    )
  }
  return null
}

export default observer(StarMainPage)
