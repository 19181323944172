import React from 'react'
import { Div, Flex, H4 } from '@components/atoms'
import { images } from '@images/'
import styled from 'styled-components'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { colors } from '@resources/colors'

const SalonChattBtnList = ({
  level,
  requestedGuest,
  requestCount,
  isMutedMic,
  handleExitLiveRoom = () => {},
  handleRequestSpeaker = () => {},
  handleSwitchMuteStateMyMic = () => {},
  handleShowPresentStar = () => {},
  toggleRequestGuest = () => {},
}) => {
  return (
    <Div display="flex" flex="1" alignItems="center" gap="16px">
      <ChattBtn onClick={handleExitLiveRoom}>
        <H4>&#128682;</H4>
      </ChattBtn>
      <ChattBtn
        onClick={
          () => {
            handleShowPresentStar()
          }
        }
      >
        <H4>&#127873;</H4>
      </ChattBtn>
      {
        level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] ? (
          <ChattBtn
            style={{ position: 'relative' }}
            onClick={
              () => {
                toggleRequestGuest()
              }
            }
          >
            {
              !!requestCount && (
                <Flex
                  style={
                    {
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      borderRadius: '50%',
                      fontFamily: 'NotoSansCJKkr-Regular',
                      color: colors.white_ff,
                      minWidth: 16,
                      height: 16,
                      backgroundColor: colors.warning,
                      fontSize: '10px',
                    }
                  }
                  justify="center"
                  align="center"
                >
                  {requestCount}
                </Flex>
              )
            }
            <img src={images.request_list_img} alt="request_list_icon" />
          </ChattBtn>
        ) : level > LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'] ? (
          <ChattBtn
            onClick={
              () => {
                handleSwitchMuteStateMyMic(isMutedMic)
              }
            }
          >
            <img
              style={{ width: 28, height: 28, minWidth: 28, minHeight: 28 }}
              src={
                isMutedMic ? images.mic_mute_img_white : images.mic_vol_img_white
              }
              alt="mic_vol_img"
            />
          </ChattBtn>
        ) : (
          <ChattBtn
            onClick={
              () => {
                handleRequestSpeaker(requestedGuest)
              }
            }
          >
            <img
              style={{ opacity: requestedGuest ? 0.48 : 1 }}
              src={images.salon_require_guest_img}
              alt="require_guest_icon"
            />
          </ChattBtn>
        )
      }
      {
        level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
          <ChattBtn
            onClick={
              () => {
                handleSwitchMuteStateMyMic(isMutedMic)
              }
            }
          >
            <img
              style={{ width: 28, height: 28, minWidth: 28, minHeight: 28 }}
              src={
                isMutedMic ? images.mic_mute_img_white : images.mic_vol_img_white
              }
              alt="mic_vol_img"
            />
          </ChattBtn>
        )
      }
    </Div>
  )
}

export default SalonChattBtnList

const ChattBtn = styled(Flex)`
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  /* backdrop-filter: blur(24px); */
  background-color: #242424;

  img {
    width: 36px;
    height: 36px;
  }
`
