import { action, computed, observable } from 'mobx'
import { message } from 'antd'
import { upload } from '../utils'
import { HOUSES_HAVE_USERS_LEVEL } from '../resources'
import { CastModel, HouseModel } from './models'

import { Store } from '.'
import { Network } from './networks'

class HouseViewModel {
  @observable _elem = null
  @computed get elem() {
    return this._elem
  }
  set elem(value) {
    if (
      this._elem
      && value
      && typeof this._elem === 'object'
      && typeof value === 'object'
    ) {
      Object.keys(value).forEach(key => {
        this._elem[key] = value[key]
      })
    }
    else {
      this._elem = value
    }
  }

  screenCount = 0
  constructor(stores, props) {
    if (props) {
      this.elem = props.elem || null
      this.screenCount = props.screenCount || 0
    }
  }
}

export default class HouseStore {
  @observable tempHouse
  @observable houseDetailObj = {}
  @observable houseFeedList
  @observable houseCastList
  @observable houseCastListCount

  @observable currentHouse

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = async () => {
    this.tempHouse = null
    this.houseDetailObj = {}
    this.houseFeedInit()

    this.currentHouse = null
  }

  @action houseFeedInit = async () => {
    this.houseFeedList = []
    this.houseCastList = []
    this.houseCastListCount = 0
  }

  @action initAfterLogin = async (
    jwt,
    loginedUser,
    appIdentifierId,
    appVersion,
  ) => {}

  @action initAfterLogout = async () => {
    this.init()
  }

  @action final = async () => {
    this.init()
  }

  @action.bound updateTempHouse = async (key, value) => {
    if (!key && !value && typeof value !== 'boolean') {
      this.tempHouse = new HouseModel(this.store, {})
    }
    else {
      this.tempHouse[key] = value
    }
  }

  @action syncHouseDetailObj = house => {
    if (!house || !house['id']) return house

    if (!this.houseDetailObj[house['id']]) {
      this.houseDetailObj[house['id']] = new HouseViewModel(this.store, {
        elem: new HouseModel(this.store, house),
        screenCount: 0,
      })
    }
    else {
      this.houseDetailObj[house['id']]['elem'] = house
    }

    return this.houseDetailObj[house['id']]['elem']
  }

  @action getHouseDetailObj = house => {
    if (!house || !house['id']) return house

    if (!this.houseDetailObj[house['id']])
      this.houseDetailObj[house['id']] = new HouseViewModel(this.store, {
        elem: new HouseModel(this.store, house),
        screenCount: 0,
      })

    return this.houseDetailObj[house['id']]['elem']
  }

  @action uploadImage = async formData => {
    const { jsonWebToken } = this.store.authStore

    upload('/uploads/houses/image', jsonWebToken, formData).then(({ data }) => {
      this.tempHouse.imageUri = data.imageUri
      // this.tempCast = new CastModel(this.store, this.tempCast)
    })
  }

  @action fetchHouseDetail = async (houseId, options) => {
    if (!houseId && !this.store?.authStore?.isLogined?.()) return

    // const options = {
    // __include: `HousesHaveUsers.Users`,
    // __attributes: `Users.id,Users.account,Users.name`
    // }

    const result = await this.network.houseNetwork.getHouse(houseId, options)

    if (!result) return

    const house = this.syncHouseDetailObj(result)

    this.currentHouse = house

    return house
  }

  @action fetchMyHouseLevel = async (houseId) => {
    const result = await this.network.houseNetwork.getHouse(houseId, {
      __include: 'HousesHaveUsers',
      'HousesHaveUsers.userId': this.store?.authStore?.currentUser?.id,
      __attributes:
        'id,HousesHaveUsers.id,HousesHaveUsers.level,HousesHaveUsers.userId',
    })
    if (!result || !(result?.HousesHaveUsers?.length > 0)) return false

    return result?.HousesHaveUsers?.[0]?.level
  }


  @action fetchHouseDetailFromLinkKey = async (houseLinkKey, options) => {
    if (!houseLinkKey && !this.store?.authStore?.isLogined?.()) return

    // const options = {
    // __include: `HousesHaveUsers.Users`,
    // __attributes: `Users.id,Users.account,Users.name`
    // }

    const result = await this.network.houseNetwork.getHouseFromLinkKey(
      houseLinkKey,
      options,
    )

    if (!result) return

    const house = this.syncHouseDetailObj(result)

    this.currentHouse = house

    return house
  }

  @action fetchHouseList = async options => {
    const result = await this.network.houseNetwork.getHouseList(options)

    if (!result) return

    return result
  }

  @action fetchHouseCount = async options => {
    const result = await this.network.houseNetwork.getHouseCount(options)

    if (!result) return

    return result
  }

  @action fetchHouseMembers = async (houseId, options) => {
    if (!houseId) {
      console.log('[fetchHouseMembers] error : houseId required')
    }

    const result = await this.network.houseNetwork.getHouseUsersList(
      houseId,
      options,
    )
    if (!result) return

    // const userList = result?.map((val) => new HousesHaveUsersModel(this.store, val))

    return result
  }

  @action createHouseConnect = async houseId => {
    if (!houseId) {
      console.log('[createHouseConnect] error : houseId required')
    }

    const result = await this.network.houseNetwork.postUserHouseConnect(houseId)
    if (!result) return

    return result
  }

  @action checkTitleDuplicated = async title => {
    if (!title) return

    const result = await this.network.houseNetwork.getHouseDuplication({
      title,
    })
    if (!result) return

    const isDuplicate = result?.isDuplicatedTitle

    return isDuplicate
  }

  @action createHouse = async (
    house,
    mainTagKeywordList,
    subTagKeywordList,
  ) => {
    if (!house) return
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    )
      return

    const {
      title,
      description,
      imageUri,
      isPrivateMemberList,
      isPrivateMemberRoom,
      contentPermissionLevel,
      premiumMemberStar,
      isPremiumMember,
    } = house

    const value = {
      title,
      description,
      imageUri,
      isPrivateMemberList,
      isPrivateMemberRoom,
      contentPermissionLevel,
      mainTagKeywordList,
      subTagKeywordList,
      premiumMemberStar,
      isPremiumMember,
    }

    const result = await this.network.houseNetwork.postHouse([value])
    if (!result || !(result.length > 0)) return
    const newHouse = this.syncHouseDetailObj(result[0])

    message.success('하우스가 생성되었습니다.')

    return newHouse
  }

  @action updateHouse = async (
    house,
    mainTagKeywordList,
    subTagKeywordList,
  ) => {
    if (!house || !house['id']) return
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    )
      return

    const {
      title,
      description,
      imageUri,
      isPrivateMemberList,
      isPrivateMemberRoom,
      contentPermissionLevel,
      premiumMemberStar,
      isPremiumMember,
    } = house

    const value = {
      title,
      description,
      imageUri,
      isPrivateMemberList,
      isPrivateMemberRoom,
      contentPermissionLevel,
      mainTagKeywordList,
      subTagKeywordList,
      premiumMemberStar,
      isPremiumMember,
    }

    const result = await this.network.houseNetwork.putHouse(house['id'], value)
    if (!result) return

    message.success('하우스가 수정되었습니다.')

    return this.syncHouseDetailObj(result)
  }

  @action hideYoutubeChannelSync = async house => {
    if (!house || !house['id']) return
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    )
      return

    const value = {
      isShowYoutubeChannelSyncButton: false,
    }
    const result = await this.network.houseNetwork.putHouse(house['id'], value)
    if (!result) return

    return this.syncHouseDetailObj(result)
  }

  @action hideRssInfoSync = async house => {
    if (!house || !house['id']) return
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    )
      return

    const value = {
      isShowRssInfoSyncButton: false,
    }
    const result = await this.network.houseNetwork.putHouse(house['id'], value)
    if (!result) return

    return this.syncHouseDetailObj(result)
  }

  @action hideInstagramFeedSync = async house => {
    if (!house || !house['id']) return
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    )
      return

    const value = {
      isShowInstagramFeedSyncButton: false,
    }
    const result = await this.network.houseNetwork.putHouse(house['id'], value)
    if (!result) return

    return this.syncHouseDetailObj(result)
  }

  @action deleteHouse = async house => {
    if (!house || !house['id']) return

    const result = await this.network.houseNetwork.deleteHouse(house['id'])
    if (!result) return

    const index = this.store.authStore?.currentUser?.HousesHaveUsers?.findIndex(
      val => val?.houseId === house?.id,
    )

    if (index > -1) {
      this.store.authStore.currentUser.HousesHaveUsers = this.store.authStore?.currentUser?.HousesHaveUsers?.splice(
        index,
        1,
      )
    }

    message.success('하우스가 삭제되었습니다.')

    return result
  }

  @action inviteHouse = async (houseId, options) => {
    if (!houseId) return

    const result = await this.network.houseNetwork.postHouseUsersInvite(
      houseId,
      options,
    )
    if (!result) return

    return result
  }

  @action followHouse = async (houseId, userId) => {
    if (!houseId || !userId) return

    const result = await this.network.houseNetwork.postHouseUser(
      houseId,
      userId,
    )
    // if (!result) return

    return result
  }

  @action updateHouseUser = async (houseId, userId, options) => {
    if (!houseId || !userId) return

    const result = await this.network.houseNetwork.putHouseUser(
      houseId,
      userId,
      options,
    )
    if (!result) return

    return result
  }

  @action updateHouseUserList = async (houseId, options) => {
    if (!houseId) return

    const result = await this.network.houseNetwork.putHouseUserList(
      houseId,
      options,
    )
    if (!result) return

    return result
  }

  @action deleteHouseUser = async (houseId, userId, name) => {
    if (!houseId || !userId) return

    const result = await this.network.houseNetwork.deleteHouseUser(
      houseId,
      userId,
    )
    if (!result) return

    /**
     * 하우스에서 삭제되면 그룹 메시지 유저에서도 삭제
     */
    // this.store.groupMessageStore.leaveGroup({ groupId: houseId, userId, name })

    return result
  }

  @action fetchFeedList = async (houseId, options = {}) => {
    const defaultWhere = {
      __limit: 20,
      houseId,
      __order: 'publishedAtDesc',
    }
    const _addedAttributes =
      'id,linkKey,createdAt,editedAt,publishedAt,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account'

    const _fetchOptionList = [
      {
        __attributes: `\
id,houseId,userId,content,rawContent,likeCount,commentCount,audioFileId,isHouseMember,createdAt,editedAt,publishedAt,\
FeedsHaveUsers.id,FeedsHaveUsers.userId,\
Houses.id,Houses.title,Houses.imageUri,Houses.isPremiumMember,Houses.linkKey,\
Users.id,Users.name,Users.imageUri,Users.profileColor,Users.isInfluencer,Users.linkKey,\
FeedImages.id,FeedImages.imageUri,FeedImages.feedId,\
AudioFiles.id,AudioFiles.isFeed,AudioFiles.originalUri,AudioFiles.duration`,
        __include:
          'Houses,Users,FeedImages,FeedsHaveUsers,AudioFiles,AudioFiles.AudioFileTexts',
      },

      /* 안쓰는 데이터 fetch 주석. 추후에도 리스트 fetch 가 아닌 상세 fetch 에서만 태그와 멘션을 fetch 하는 시나리오 고려해야 함 */
      // {
      //   __include: 'FeedsHaveUsers.Users',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account`,
      // },
      // {
      //   __include: 'FeedsHaveTags.Tags',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveTags.id,FeedsHaveTags.Tags.id`,
      // },
    ]
    if (this.store.authStore?.isLogined()) {
      _fetchOptionList.push({
        __include: 'UsersLikeFeeds',
        'UsersLikeFeeds.userId': this.store.authStore?.currentUser?.id,
        __attributes: `${
          _addedAttributes ? `${_addedAttributes},` : ``
        }id,UsersLikeFeeds.id,UsersLikeFeeds.userId`,
      })
    }

    Object.assign(defaultWhere, options)

    let result = null
    if (defaultWhere['__include']) {
      result = await this.network.feedNetwork.getFeedList(defaultWhere)
    }
    else {
      const resultList = await Promise.all(
        _fetchOptionList.map(_fetchOption => {
          return this.network.feedNetwork.getFeedList({
            ..._fetchOption,
            ...defaultWhere,
          })
        }),
      )

      if (resultList && resultList.length > 0) {
        const baseResult = resultList[0]
        resultList.forEach(_result => {
          if (_result && Array.isArray(_result)) {
            _result.map(feed => {
              const feedIndex = baseResult.findIndex(
                _baseFeed => _baseFeed['id'] === feed['id'],
              )
              if (feedIndex > -1) {
                baseResult[feedIndex] = { ...feed, ...baseResult[feedIndex] }
              }
              return true
            })
          }
        })
        result = baseResult
      }
    }

    if (!result) {
      this.houseFeedList = []
      return
    }

    const feedList = result?.map(val =>
      this.store.feedStore.syncFeedDetailObj(val),
    )

    if (this.houseFeedList.length > 0 && options?.__pageIdValue) {
      this.houseFeedList = this.houseFeedList.concat(feedList)
    }
    else {
      this.houseFeedList = feedList
    }

    return feedList
  }

  @action fetchLiveRoomList = async ({
    houseId,
    lastValue = null,
    lastValueId = null,
  }) => {
    // const ret = {
    //   isFetched: false,
    // }

    const options = {
      __limit: 50,
      houseId,
    }

    const result = await this.network.liveRoomNetwork.getRecommendLiveRoomList(
      options,
    )

    return result
  }

  @action fetchCastList = async (houseId, __offset = 0) => {
    if (!houseId) {
      console.error('[CastStore][fetchMyCastList] houseId is required.')
      return
    }
    const _houseId = parseInt(houseId)
    let _offset = __offset

    if (this.currentHouse && _houseId !== this.currentHouse['id']) {
      this.houseCastList = []
      _offset = 0
    }

    const __limit = 20

    const where = {
      __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings,Users,Houses',
      __order: 'createdAtDesc',
      houseId: _houseId,
      __limit,
      __offset: _offset,
      isPublished: 1,
    }

    const castList = await this.network.castNetwork.getCastList(where)

    if (_offset === 0) {
      const castListCount = await this.network.castNetwork.getCastListCount(
        where,
      )

      this.houseCastListCount = castListCount
    }

    if (!castList) {
      console.error('[CastStore][fetchMyCastList] getCastList failed.')
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))

    if (_offset) {
      this.houseCastList = [
        ...this.houseCastList.slice(0, _offset),
        ..._castList,
      ]
    }
    else {
      this.houseCastList = _castList
    }

    return castList.length >= __limit
  }

  @action fetchManagedCastList = async (houseId, __offset = 0) => {
    if (!houseId) {
      console.error('[CastStore][fetchMyCastList] houseId is required.')
      return
    }

    if (this.currentHouse && houseId !== this.currentHouse['id']) {
      this.houseCastList = []
    }

    if (!this.currentHouse?.HousesHaveUsers?.[0]) {
      return
    }

    const _level = this.currentHouse?.HousesHaveUsers?.[0]?.level

    if (!this.store?.authStore?.currentUser?.['id']) {
      return
    }

    let where = {
      __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings,Users,Houses',
      __order: 'createdAtDesc',
      houseId,
      __limit: 10,
      __offset,
      isPublished: 1,
    }

    if (_level >= HOUSES_HAVE_USERS_LEVEL['HOST']) {
      where = {
        __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings,Users,Houses',
        __order: 'createdAtDesc',
        houseId,
        __limit: 10,
        __offset,
        __orField: 'userId,isPublished',
        isPublished: 1,
        userId: this.store?.authStore?.currentUser?.['id'],
      }
    }
    else {
      where = {
        __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings,Users,Houses',
        __order: 'createdAtDesc',
        houseId,
        __limit: 10,
        __offset,
        userId: this.store?.authStore?.currentUser?.['id'],
      }
    }

    const castList = await this.network.castNetwork.getCastList(where)

    if (__offset === 0) {
      const castListCount = await this.network.castNetwork.getCastListCount(
        where,
      )

      this.houseCastListCount = castListCount
    }

    if (!castList) {
      console.error('[CastStore][fetchMyCastList] getCastList failed.')
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))

    this.houseCastList = _castList

    return castList.length >= 20
  }

  /**
   * 그룹 메시지에 가입할 수 있는 유저인지 체크
   *  - private room: member level 이상
   *  - public rooom: follower level 이상
   */
  @action checkGroupMessageValidation = async (houseId, userId) => {
    const _house = await this.store.houseStore.fetchHouseDetail(houseId, {
      __include: 'HousesHaveUsers',
      'HousesHaveUsers.userId': userId,
    })

    if (!_house) {
      return false
    }
    const member = _house.HousesHaveUsers.find(housesHaveUsers => {
      return housesHaveUsers.userId === userId
    })

    const { level } = member
    const { isPrivateMemberRoom } = _house

    if (isPrivateMemberRoom) {
      return !!(level >= HOUSES_HAVE_USERS_LEVEL['MEMBER'])
    }

    return !!(level >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER'])
  }

  @action fetchHomeHouseRecommendation = async ({ limit = 10, nextCursor }) => {
    const option = nextCursor
      ? {
        __limit: limit,
        __nextCursor: nextCursor,
      }
      : {
        __limit: limit,
      }
    try {
      const fetchRecommendation = await this.network.houseNetwork.getHousesRecommendations(
        option,
      )
      const houseList = fetchRecommendation?.houseList?.map(
        val => new HouseModel(this.store, val),
      )

      const temp = {
        houseList,
        __nextCursor: fetchRecommendation?.__nextCursor,
      }
      return temp
    }
    catch (error) {
      console.error(error)
    }
  }
}
