import React from 'react'
import {
  BodyWrapper,
  H1,
  H3,
  H4,
  H6,
  TextWrapper,
  LogoImg,
} from '@components/atoms'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { GoogleBtn, AppleBtn, MoveBtn } from '@components/molecules'
import { Link } from 'react-router-dom'
import { colors } from '@resources/colors'

const MsgBox = styled.div`
  font-family: NotoSansCJKkr-Medium;
  font-size: 1rem;
  color: #646464;
  text-align: center;

  @media ${mobile} {
    font-size: 0.75rem;
  }
`

const CompleteRegister = props => {
  const { user } = props

  return (
    <BodyWrapper>
      <LogoImg />
      <TextWrapper>
        <H1>환영합니다</H1>
      </TextWrapper>

      <TextWrapper style={{ margin: '30px 0px' }}>
        <H4>
          {user && user.name}
          {' '}
님
        </H4>
      </TextWrapper>

      <TextWrapper style={{ marginBottom: '40px' }}>
        <MsgBox>
          {user && user.email}
          {' '}
로 인증 메일을 보냈습니다.
          <MsgBox />
(자동
          생성된 닉네임은 앱에서 수정이 가능합니다.)
        </MsgBox>
      </TextWrapper>

      <TextWrapper style={{ marginBottom: '20px' }}>
        <AppleBtn />
        <GoogleBtn />
      </TextWrapper>

      <H6 color="#646464">흐름을 더 풍부하게 활용하는 방법</H6>
      <Link to="https://hreum.me/premium/">
        <H3 color={colors.primary} style={{ margin: '10px' }}>
          PREMIUM으로 즐기기
        </H3>
      </Link>
      <MoveBtn href="/" text="홈으로 이동" />
    </BodyWrapper>
  )
}

export default CompleteRegister
