import React, { useState } from 'react'
import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  H6,
  ImageIcon,
  StyledButton,
} from '@components/atoms'
import { colors } from '@resources/colors'
import styled from 'styled-components'
import { images } from '@images/'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'

const HouseMembershipCard = () => {
  const { houseStore } = useStore()

  const tempHouse = houseStore.tempHouse || null
  const updateTempHouse = houseStore.updateTempHouse || (() => {})

  const [isSetting, setIsSetting] = useState(false)
  const [monthSubs, setMonthSubs] = useState(false)

  const handleStarPlan = async value => {
    if (!tempHouse?.isPremiumMember) {
      await updateTempHouse('isPremiumMember', true)
    }
    await updateTempHouse('premiumMemberStar', value)
  }

  return (
    <Div
      display="flex"
      flexDirection="column"
      width="100%"
      height="auto"
      backgroundColor={colors.white_fa}
      border={`1px solid ${colors.white_f4}`}
      borderRadius="8px"
      marginTop="40px"
    >
      <Div
        display="flex"
        flexDirection="column"
        width="100%"
        height="auto"
        padding="24px"
      >
        <H6 type="Bold" align="left">
          하우스 멤버십
        </H6>
        <Div display="flex">
          <Caption2 align="left" style={{ marginTop: 12 }}>
            멤버십 유저에게 다양한 독점 혜택을 제공하고
            <Caption2 color={colors.primary}>수익을 창출</Caption2>
해 보세요
          </Caption2>
          <ImageIcon name="houseMembership" width="200px" height="92px" />
        </Div>
        <Div display="flex">
          <StyledButton
            designType="primary"
            size="ms"
            width="92px"
            height="28px"
            onClick={() => setIsSetting(!isSetting)}
          >
            {
              !isSetting && (tempHouse.premiumMemberStar || monthSubs)
                ? '설정 변경'
                : '멤버십 설정'
            }
          </StyledButton>
          {
            isSetting && (
              <StyledButton
                designType="whiteF4"
                size="ms"
                width="92px"
                height="28px"
                marginLeft="8px"
                onClick={() => setIsSetting(false)}
              >
              취소
              </StyledButton>
            )
          }
        </Div>
      </Div>
      {
        isSetting && (
          <Div
            display="flex"
            flexDirection="column"
            borderTop={`1px solid ${colors.white_f4}`}
            padding="24px"
          >
            <Caption2 align="left">
            유저가 하우스 멤버로 가입한 시점부터 월별로 구독료가 차감되며,
              <br />
            멤버십 가입 유저는 하우스 멤버로서 하우스에서 제공하는
              <br />
              <Caption2 type="Bold">
              멤버 콘텐츠 접근 및 멤버룸 안에서 활동
              </Caption2>
            할 수 있습니다.
            </Caption2>

            <StarInput
              placeholder="직접 입력"
              value={tempHouse.premiumMemberStar}
              onChange={e => handleStarPlan(e.target.value)}
            />

            <StarPlanList>
              <StarPlanBtn
                active={tempHouse.premiumMemberStar === '10'}
                onClick={() => handleStarPlan('10')}
              >
                <img src={images.salon_star_img} alt="10star_icon" />
                <Caption1>10개</Caption1>
              </StarPlanBtn>
              <StarPlanBtn
                active={tempHouse.premiumMemberStar === '50'}
                onClick={() => handleStarPlan('50')}
              >
                <img src={images.salon_50star_img} alt="50star_icon" />
                <Caption1>50개</Caption1>
              </StarPlanBtn>
              <StarPlanBtn
                active={tempHouse.premiumMemberStar === '100'}
                onClick={() => handleStarPlan('100')}
              >
                <img src={images.salon_100star_img} alt="100star_icon" />
                <Caption1>100개</Caption1>
              </StarPlanBtn>
              <StarPlanBtn
                active={tempHouse.premiumMemberStar === '1000'}
                onClick={() => handleStarPlan('1000')}
              >
                <img src={images.salon_1000star_img} alt="1000star_icon" />
                <Caption1>1,000개</Caption1>
              </StarPlanBtn>
            </StarPlanList>
            <Caption3 align="left" style={{ margin: '8px 0 24px 0' }}>
            *기존 멤버십 구독료 변경 시 
              {' '}
              <Caption3>다음 달부터 적용</Caption3>
            됩니다.
            </Caption3>

            {/* Todo: 앱 선행 개발 이후 로직 구현 */}
            {/* <MonthSubscribeBox>
              <Div display="flex" justifyContent="space-between">
                <Caption1>1개월 구독</Caption1>
                <SwitchButton
                  isSwitchOn={monthSubs}
                  handleSwitch={() => setMonthSubs(!monthSubs)}
                />
              </Div>
              {monthSubs && <StarInput placeholder="직접 입력" />}
            </MonthSubscribeBox> */}
          </Div>
        )
      }
    </Div>
  )
}

export default observer(HouseMembershipCard)

const StarInput = styled.input`
  margin: 24px 0 16px 0;
  width: 100%;
  border: none;
  background-color: ${colors.white_f4};
  border-radius: 8px;
  height: 40px;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;

  ::placeholder {
    color: ${colors.grey_bf};
  }
`

const StarPlanList = styled(Div)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin-top: 16px;
  }
`

const StarPlanBtn = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  max-width: 202px;
  width: 100%;
  height: 72px;

  border: solid 1px ${colors.white_f4};
  border-radius: 8px;

  background-color: ${({ active }) => (active ? '#f8f8ff' : colors.white_ff)};
  p {
    color: ${({ active }) => (active ? colors.primary : colors.black_24)};
  }

  img {
    width: 48px;
    height: 48px;
    margin-right: 24px;
  }
`

const MonthSubscribeBox = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.white_fa};
  background-color: ${colors.white_ff};
  border-radius: 8px;
  min-height: 40px;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 20px;
`

const CheckBoxWrapper = styled.div`
  position: relative;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: calc(50% - 20px);
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background: ${({ color }) => color || colors.grey_d4};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;

    width: 16px;
    height: 16px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 10px;
  width: 40px;
  height: 20px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: ${({ color, value }) =>
    value ? color || colors.primary : colors.grey_d4};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 22px;
      transition: 0.2s;
    }
  }
`

const SwitchButton = ({ isSwitchOn, handleSwitch, color }) => {
  const randomString = Math.random()
    .toString(36)
    .substr(2, 11)

  return (
    <div>
      <CheckBoxWrapper>
        <CheckBox
          id={randomString}
          type="checkbox"
          value={isSwitchOn}
          onChange={handleSwitch}
          color={color}
        />
        <CheckBoxLabel htmlFor={randomString} color={color} />
      </CheckBoxWrapper>
    </div>
  )
}
