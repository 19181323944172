import { observable, action, computed } from 'mobx'
import { STORAGE_URL } from '@consts/'
import { images } from '@resources'
import { persist } from 'mobx-persist'
import { GenreModel, MoodModel } from '.'

const categoryString = [null, '커버', '자작곡']
const genreString = ['힙합', '발라드']

export default class SongModel {
  @observable id
  @observable name
  @persist @observable _imageUri
  @observable imageUri
  @observable imageUrl
  @observable imageSource
  @observable singer
  @observable credit
  @observable license
  @observable lyrics
  @observable description
  @observable youtubeUrlLevel
  @observable youtubeUrl
  @observable mp3Url
  @observable originalUri
  @observable mp3Uri
  @observable m3u8Uri
  @observable normalizedM3u8Uri
  @observable m3u8Url
  @observable normalizedM3u8Url
  @observable likeCount
  @observable playCount
  @observable category
  @observable genre
  @observable isAdult

  @observable createdAt
  @observable updatedAt

  @observable albumOrder
  @observable albumId

  @observable thumbnailUri
  @observable thumbnailSource

  @observable tagCount
  @observable duration = 0

  @observable plamUrl
  @observable plamUrlMemo
  @observable plamUrlStatus

  @observable Genres = []
  @observable Moods = []
  @observable Tags = []
  @observable UsersLikeSongs = []

  @computed get isLiked() {
    // 좋아요 여부
    return !!(this.UsersLikeSongs && this.UsersLikeSongs.length > 0)
  }

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.name = props.name
      this.singer = props.singer
      this.credit = props.credit
      this.license = props.license
      this._imageUri = props._imageUri || props.imageUri
      this.imageUri = props.imageUri || props.imageUri
      this.imageUrl = STORAGE_URL + this.imageUri
      this.imageSource = this.imageUrl ? this.imageUrl : '/static/song.png'
      this.lyrics = props.lyrics
      this.description = props.description
      this.youtubeUrlLevel = props.youtubeUrlLevel
      this.youtubeUrl = props.youtubeUrl
      this.originalUri = props.originalUri || props.originalUri
      this.mp3Uri = props.mp3Uri || props.mp3Uri
      this.mp3Url = this.mp3Uri && STORAGE_URL + this.mp3Uri
      this.m3u8Uri = props.m3u8Uri || props.m3u8Uri
      this.normalizedM3u8Uri =
        props.normalizedM3u8Uri || props.normalizedM3u8Uri
      this.normalizedM3u8Url = STORAGE_URL + this.normalizedM3u8Uri
      this.m3u8Url = STORAGE_URL + this.m3u8Uri

      this.likeCount = props.likeCount
      this.playCount = props.playCount

      this.category = categoryString[props.category || 0]
      this.genre = genreString[props.genre || 0]
      this.isAdult = props.isAdult

      this.createdAt = props.createdAt
      this.updatedAt = props.createdAt

      this.albumOrder = props.albumOrder
      this.albumId = props.albumId

      this.plamUrl = props.plamUrl
      this.plamUrlMemo = props.plamUrlMemo
      this.plamUrlStatus = props.plamUrlStatus

      this.Genres =
        (props.Genres
          && props.Genres.map(elem => new GenreModel(stores, elem)))
        || []
      this.Moods =
        (props.Moods && props.Moods.map(elem => new MoodModel(stores, elem)))
        || []
      /**
       * tag 받아오는 것도 추가해야함. but tag는 SongsHaveTags로 받아오기 때문에 따로 적용해야할 듯
       */

      if (typeof this.imageUri === 'string') {
        const splitedPath = this.imageUri.split('/')
        const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]

        this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
        this.thumbnailSource = this.thumbnailUri && { uri: this.thumbnailUri }
      }

      this.tagCount = props.tagCount
      this.duration = props.duration
      this.UsersLikeSongs = props.UsersLikeSongs
    }
  }

  @action failLoadThumbnail = () => {
    this.thumbnailUri = null
    this.thumbnailSource = null
  }

  @action failLoadImage = () => {
    this.imageSource = images.song
  }
}
