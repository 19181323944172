import React, { useState } from 'react'
import {
  H1,
  H6,
  Caption1,
  Form,
  FormLabel,
  FormInput,
  Caption2,
  WarningMsg,
} from '@components/atoms'
import { FlexRowBox, RoundBtn } from '@components/molecules'
import styled from 'styled-components'
import { convertToPricingComma } from '@utils/string'
import { colors } from '@resources/colors'
import { PageLayout } from '..'

const FormGroup = styled.div`
  margin: 20px 0 50px 0;
  width: 100%;
`

const PlaceHolder = ({ accountPrice, price }) => {
  return (
    <FlexRowBox
      style={
        {
          position: 'absolute',
          top: '60px',
          right: '10px',
        }
      }
    >
      <Caption2 color="#949494" style={{ marginRight: '8px' }}>
        환전 가능 포인트
      </Caption2>
      <Caption2 color={accountPrice > price ? '#ea4653' : colors.secondary}>
        {`${convertToPricingComma(price)} P`}
      </Caption2>
    </FlexRowBox>
  )
}

const AccountTemplate = ({
  currentUser,
  postRevenueHistories,
  setComplete,
}) => {
  const [accountPrice, setAccountPrice] = useState('')

  const hadleAccount = async () => {
    if (
      accountPrice === ''
      || accountPrice > (currentUser && currentUser.receivableRevenue)
      // || (accountPrice > 10000 && accountPrice % 10000 !== 0)
      // || accountPrice < 10000
    ) {
      return false
    }
    const res = await postRevenueHistories(accountPrice)
    if (res) {
      setComplete(true)
    }
  }

  return (
    <PageLayout>
      <H1>환전 신청</H1>
      <H6 style={{ margin: '20px 0px 10px 0px' }}>
        흐름(HREUM de salon) 활동내역 중 커뮤니티 가이드라인 및 약관
        위반사항이 있을 경우
        <br />
        일정 기간 환전 및 포인트 적립이 중단 될 수 있습니다.
      </H6>
      <Caption1 color="#949494">
        {/* *누적된 환전 신청은 익월 15일 부터 영업일 기준 3일 이내에 심사 및 환전
          됩니다. */}
        *누적된 환전 신청은 익월 15일 부터 영업일 기준 3일 이내에 심사후 1포인트
        당 1원으로 환전하여 지급하며,
        <br />
        전체 환전 신청 금액에서 원천공제세금과 환전수수료 8.3%가 공제 후
        지급됩니다.
      </Caption1>

      <Form>
        <FormGroup style={{ position: 'relative' }}>
          <FormLabel color="#646464">환전 신청 포인트 입력</FormLabel>
          <FormInput
            type="number"
            step="10000"
            placeholder="만 포인트 단위로 입력"
            value={accountPrice}
            onChange={e => setAccountPrice(e.target.value)}
          />
          <PlaceHolder
            accountPrice={accountPrice}
            price={currentUser && currentUser.receivableRevenue}
          />
          {/* {
            accountPrice > (currentUser && currentUser.receivableRevenue) && (
              <WarningMsg>환전 가능 포인트를 초과합니다.</WarningMsg>
            )
          }
          {
            accountPrice > 10000 && accountPrice % 10000 !== 0 && (
              <WarningMsg>만 포인트 단위로 입력해주세요.</WarningMsg>
            )
          }
          {
            accountPrice !== '' && accountPrice < 10000 && (
              <WarningMsg>만 포인트 단위로 입력해주세요.</WarningMsg>
            )
          } */}
        </FormGroup>

        <RoundBtn
          onClick={() => hadleAccount()}
          text="완료"
          color="#ffffff"
          style={
            {
              width: '320px',
              height: '48px',
              borderRadius: '24px',
              border: `solid 2px ${colors.primary}`,
              backgroundColor: colors.primary,
            }
          }
        />
      </Form>
    </PageLayout>
  )
}

export default AccountTemplate
