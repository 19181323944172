import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Caption1, Div, H5, H6, Img } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/'
import { converseUnitEng } from '@utils/format'
import { useMoreHeight, useStore } from '@utils/hooks'
import CopyToClipboard from 'react-copy-to-clipboard'
import { HouseEditModal } from '..'
import HouseSalonSection from './HouseSalonSection'

const MemberBadge = ({ myLevel }) => {
  return (
    <FollowBtn
      followed
      style={{ borderRadius: 14, width: 72, cursor: 'initial' }}
    >
      <Img
        src={
          myLevel === HOUSES_HAVE_USERS_LEVEL['MEMBER']
            ? images.house_member_icon_img
            : images.salon_host_img
        }
        width="16px"
        height="16px"
        marginRight="5px"
      />
      {myLevel === HOUSES_HAVE_USERS_LEVEL['MEMBER'] ? '멤버' : '호스트'}
    </FollowBtn>
  )
}

const HouseDetailInfoSection = ({ isMyPage, houseDetail }) => {
  const [myLevel, setMyLevel] = useState(null)
  const [showThreeDot, setShowThreeDot] = useState(false)
  const [isMoreTagList, setIsMoreTagList] = useState(false)

  const tagListRef = useRef(null)

  const { houseStore, authStore, appStateStore } = useStore()
  const houseId = houseDetail?.id

  const { onToast } = appStateStore

  const {
    isShowing: showHoustEditModal,
    toggle: toggleHouseEditModal,
  } = HouseEditModal.useModal()

  const { haveMore, handleShowMore, showMore } = useMoreHeight({
    defaultHeight: 38,
    id: 'description_box',
    item: houseDetail?.description,
  })

  const handleSecession = async () => {
    try {
      await houseStore.deleteHouseUser(
        houseId,
        authStore.currentUser.id,
        authStore.currentUser.name,
      )
      setMyLevel(null)
    }
    catch (error) {
      console.error('HouseDetailInfoSection handleSecession error', error)
    }
  }

  const handleRequest = async () => {
    if (!authStore.currentUser) return

    if (myLevel === HOUSES_HAVE_USERS_LEVEL['FOLLOWER']) {
      try {
        const res = await houseStore.updateHouseUser(
          houseId,
          authStore.currentUser.id,
          {
            level: HOUSES_HAVE_USERS_LEVEL['REQUEST'],
          },
        )

        setMyLevel(res?.level)
      }
      catch (error) {
        console.error('HouseDetailInfoSection handleRequest error', error)
        if (error?.response?.status === 409) {
          alert('별이 부족합니다.')
        }
      }
    }
    else if (myLevel === HOUSES_HAVE_USERS_LEVEL['REQUEST']) {
      try {
        const res = await houseStore.updateHouseUser(
          houseId,
          authStore.currentUser.id,
          {
            level: HOUSES_HAVE_USERS_LEVEL['FOLLOWER'],
          },
        )
        setMyLevel(res?.level)
      }
      catch (error) {
        console.error('HouseDetailInfoSection handleRequest error', error)
      }
    }
  }

  const handleFollow = async () => {
    if (!authStore.currentUser) return

    if (myLevel === null || myLevel === undefined) {
      try {
        const res = await houseStore.followHouse(
          houseId,
          authStore.currentUser.id,
        )
        setMyLevel(res?.level)

        if (res?.level === HOUSES_HAVE_USERS_LEVEL['FOLLOWER']) {
          houseDetail.followerCount += 1
        }
        else if (res?.level >= HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
          houseDetail.memberCount += 1
        }
      }
      catch (error) {
        console.error('HouseDetailInfoSection handleFollow error', error)
      }
    }
    else if (myLevel <= HOUSES_HAVE_USERS_LEVEL['REQUEST']) {
      try {
        const res = await houseStore.deleteHouseUser(
          houseId,
          authStore.currentUser.id,
          authStore.currentUser.name,
        )
        setMyLevel(null)

        if (res?.level <= HOUSES_HAVE_USERS_LEVEL['REQUEST']) {
          houseDetail.followerCount -= 1
        }
        else if (res?.level >= HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
          houseDetail.memberCount -= 1
        }
      }
      catch (error) {
        console.error('HouseDetailInfoSection handleFollow error', error)
      }
    }
  }

  const handleScrollTagList = useCallback(
    (direction = 'right') => {
      if (tagListRef) {
        if (direction === 'right') {
          tagListRef.current.scrollTo({
            left: tagListRef.current.scrollLeft + 300,
            behavior: 'smooth',
          })
        }
        else {
          tagListRef.current.scrollTo({
            left: tagListRef.current.scrollLeft - 300,
            behavior: 'smooth',
          })
        }
      }
    },
    [tagListRef],
  )

  useEffect(() => {
    const fetchHouseInfo = async () => {
      if (houseId) {
        if (authStore?.currentUser?.id) {
          const result = await houseStore.fetchHouseDetail(houseId, {
            __include: 'HousesHaveUsers',
            'HousesHaveUsers.userId': authStore?.currentUser?.id,
          })

          setMyLevel(
            result?.HousesHaveUsers?.[0]?.level > 0
              ? result?.HousesHaveUsers?.[0]?.level
              : result?.HousesHaveUsers?.[0]?.level === 0
                ? 0
                : null,
          )
        }
      }
    }

    fetchHouseInfo()
  }, [houseId, authStore, authStore.currentUser, houseStore])

  useEffect(() => {
    if (tagListRef.current) {
      setIsMoreTagList(tagListRef.current?.scrollWidth >= 1064)
    }
  }, [houseDetail?.HousesHaveTags])

  return (
    <Div display="flex" flexDirection="column" width="100%" position="relative">
      <BlurBackground />
      <BackgroundImage src={houseDetail?.imageUrl} />

      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="432px"
        padding="32px"
        zIndex={3}
      >
        <Img
          src={houseDetail?.imageUrl}
          width="235px"
          height="235px"
          borderRadius="8px"
        />
        <Div display="flex" alignItems="center" margin="16px 0 9px 0">
          <Img
            src={images.house_icon_img}
            width="20px"
            height="20px"
            marginTop="3px"
            marginRight="5px"
          />
          <H5 color={colors.white_ff}>{houseDetail?.title}</H5>
        </Div>
        <Div display="flex" gap="16px">
          <Caption1 color={colors.white_ff}>
            {converseUnitEng(houseDetail?.memberCount || 0)}
            {' '}
멤버
          </Caption1>
          <Caption1 color={colors.white_ff}>
            {
              converseUnitEng(
                (houseDetail?.followerCount || 0)
                + (houseDetail?.memberCount || 0),
              )
            }
            {' '}
            팔로워
          </Caption1>
        </Div>
        <Div display="flex" marginTop="20px" alignItems="center">
          {
            myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'] && (
              <MemberBadge myLevel={myLevel} />
            )
          }
          {
            !isMyPage && myLevel < HOUSES_HAVE_USERS_LEVEL['MEMBER'] && (
              <>
                <FollowBtn
                  followed={
                    myLevel !== null
                  && myLevel >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                  }
                  onClick={handleFollow}
                >
                  {
                    myLevel !== null
                && myLevel >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                      ? '팔로잉'
                      : '팔로우'
                  }
                </FollowBtn>
                {
                  myLevel !== null
                && myLevel >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                && myLevel < HOUSES_HAVE_USERS_LEVEL['MEMBER'] && (
                    <RequestMemberBtn
                      marginRight="16px"
                      onClick={handleRequest}
                      requested={myLevel === HOUSES_HAVE_USERS_LEVEL['REQUEST']}
                    >
                      {
                        myLevel === HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                          ? '멤버 가입'
                          : '신청 중..'
                      }
                    </RequestMemberBtn>
                  )
                }
              </>
            )
          }
          <Div
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="28px"
            height="28px"
            background="rgba(255, 255, 255, 0.16)"
            borderRadius="50%"
            cursor="pointer"
          >
            <CopyToClipboard
              text={window.location.href}
              onCopy={
                () => {
                  onToast({ msg: '링크가 복사되었습니다.' })
                }
              }
            >
              <Img src={images.share_img_white} width="16px" height="16px" />
            </CopyToClipboard>
          </Div>
          {
            myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER']
            && myLevel < HOUSES_HAVE_USERS_LEVEL['OWNER'] && (
              <Div
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="28px"
                height="28px"
                background="rgba(255, 255, 255, 0.16)"
                borderRadius="50%"
                marginLeft="16px"
                cursor="pointer"
                onMouseEnter={
                  () => {
                    setShowThreeDot(true)
                  }
                }
                onMouseLeave={
                  () => {
                    setShowThreeDot(false)
                  }
                }
                position="relative"
              >
                <Img
                  src={images.three_dot_white_img}
                  width="16px"
                  height="16px"
                />
                {
                  showThreeDot && (
                    <Div
                      position="absolute"
                      top="27px"
                      left="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor={colors.white_ff}
                      borderRadius="8px"
                      width="136px"
                    >
                      <Div
                        width="100%"
                        height="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="12px"
                        color={colors.warning}
                        cursor="pointer"
                        onClick={handleSecession}
                      >
                      하우스 탈퇴하기
                      </Div>
                    </Div>
                  )
                }
              </Div>
            )
          }
        </Div>
        {
          myLevel >= HOUSES_HAVE_USERS_LEVEL['HOST'] && (
            <Div
              display="flex"
              maxWidth="1064px"
              width="100%"
              justifyContent="flex-end"
              gap="8px"
            >
              <FollowBtn
                followed
                style={
                  {
                    borderRadius: 4,
                    margin: 0,
                  }
                }
              >
                <Img
                  src={images.salon_star_img}
                  width="24px"
                  height="24px"
                  marginTop="1px"
                  marginRight="5px"
                />
                {Number(houseDetail?.currentStar || 0).toLocaleString()}
              </FollowBtn>
              {
                myLevel === HOUSES_HAVE_USERS_LEVEL['OWNER'] && (
                  <FollowBtn
                    followed
                    style={
                      {
                        width: 96,
                        backgroundColor: 'rgba(36, 36, 36, 0.4)',
                        borderRadius: 4,
                        marginRight: 0,
                      }
                    }
                    onClick={
                      () => {
                        houseStore.tempHouse = houseDetail
                        toggleHouseEditModal()
                      }
                    }
                  >
                하우스 수정
                  </FollowBtn>
                )
              }
            </Div>
          )
        }
      </Div>
      <Div
        display="flex"
        justifyContent="space-between"
        padding="0 24px"
        alignItems="center"
        width="100%"
        height="52px"
        borderBottom={`1px solid ${colors.white_f4}`}
      >
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="32px"
          height="32px"
          backgroundColor={isMoreTagList ? colors.white_f4 : colors.white_ff}
          borderRadius="50%"
        >
          {
            isMoreTagList && (
              <Img
                src={images.move_down_gray_img}
                width="12px"
                height="12px"
                transform="rotate(90deg)"
                onClick={() => handleScrollTagList('left')}
              />
            )
          }
        </Div>

        <TagList ref={tagListRef}>
          {
houseDetail?.HousesHaveTags?.map(tag => (
  <TagBox key={tag.id}>{tag?.Tag?.keyword}</TagBox>
))
          }
        </TagList>

        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="32px"
          height="32px"
          backgroundColor={isMoreTagList ? colors.white_f4 : colors.white_ff}
          borderRadius="50%"
        >
          {
            isMoreTagList && (
              <Img
                src={images.move_down_gray_img}
                width="12px"
                height="12px"
                transform="rotate(-90deg)"
                onClick={() => handleScrollTagList('right')}
              />
            )
          }
        </Div>
      </Div>

      <Div
        padding="24px 0"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          maxWidth="1064px"
          borderBottom={`1px solid ${colors.grey_d4}`}
        >
          <H6 align="left" style={{ marginBottom: 16 }}>
            하우스 소개
          </H6>
          {/* <Div
            height="1px"
            width="100%"
            backgroundColor={colors.white_f4}
            margin="16px 0"
          /> */}
          <Caption1
            id="description_box"
            align="left"
            type="Regular"
            style={
              {
                whiteSpace: 'pre',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: haveMore && !showMore ? '2' : 'initial',
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
                maxHeight: haveMore && !showMore ? 38 : 'initial',
                marginBottom: 24,
              }
            }
          >
            {houseDetail?.description}
          </Caption1>
          {
            haveMore && (
              <Caption1
                align="right"
                style={{ cursor: 'pointer', marginTop: 2 }}
                color={colors.primary}
                onClick={handleShowMore}
              >
                {showMore ? '접기' : '더 보기'}
              </Caption1>
            )
          }
          {/* Todo: 유튜브 링크 컬럼 부재 */}
          {/* <Div display="flex" alignItems="center" marginTop="19px">
            <Img src={images.share_img_black} width="12px" height="12px" marginRight="4px" />
          </Div> */}
        </Div>

        {
          myLevel >= houseDetail?.contentPermissionLevel && (
            <Div width="100%" maxWidth="1064px">
              <HouseSalonSection
                houseDetail={houseDetail}
                isMyPage={isMyPage}
                myLevel={myLevel}
              />
            </Div>
          )
        }
      </Div>
      <HouseEditModal.View
        isEdit
        isShowing={showHoustEditModal}
        toggle={toggleHouseEditModal}
      />
    </Div>
  )
}

export default observer(HouseDetailInfoSection)

const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 432px;
  min-height: 432px;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 2;
`

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 432px;
  min-height: 432px;
  height: 100%;

  object-fit: cover;
  z-index: 1;
`

const FollowBtn = styled(Div)`
display:flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ followed }) =>
    followed ? 'rgba(255, 255 ,255, 0.16)' : colors.primary};

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.white_ff};

  margin-right:16px;
}
`

const TagList = styled(Div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: 1064px;
  height: 100%;
  padding: 10px 0;
  gap: 8px;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`

const TagBox = styled(Div)`
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border: 1px solid ${colors.white_f4};
  background-color: ${colors.white_f4};
  border-radius: 18px;
  font-size: 12px;
  word-break: keep-all;
`

const RequestMemberBtn = styled(Div)`
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  width: 68px;
  border-radius: 4px;
  background-color: ${({ requested }) =>
    requested ? colors.grey_94 : '#67d119'};
  color: ${colors.white_ff};
  font-size: 12px;
`
