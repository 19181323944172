import { action, autorun, observable } from 'mobx'
import { IS_DEV, MIXTAPES_HAVE_TAGS_LEVEL } from '@consts/'
import { isMobile } from 'react-device-detect'
import PlayerManager from '@utils/PlayerManager'
import { Network } from './networks'
import { Store } from '.'
import { MixtapeModel } from './models'

export default class PlayerStore {
  @observable playerType

  // 프리미엄 회원 인증 여부
  @observable myPlayAuth

  // 재생 시도 여부
  @observable isTryPlay

  @observable currentPlayMixtape
  @observable currentPlaySong
  @observable currentUsersPlayMixtapes
  @observable currentUsersPlayMixtapesTmpTime
  @observable currentUsersPlaySong
  @observable currentUsersPlaySongTmpTime

  @observable currentControlList // 현재 재생 중인 흐름 연관 리스트
  @observable isAutoNext // 다음 흐름 자동 재생
  @observable currentMixtapeIdx // 현재 흐름 index

  @observable currentPlayCategory // 현재 재생 중인 흐름 리스트 카테고리
  @observable currentPlaySort // 현재 재생 중인 흐름 리스트 정렬

  @observable songPlayeIdx // 현재 재생 중인 곡의 흐름 인덱스
  @observable interactPlay // 사용자가 직접 재생시켰을 때
  @observable stopPlayer // 플레이어 밖에서 플레이어를 멈출 때

  @observable playing // 재생 여부
  @observable url // 플레이어 url
  @observable muted // 플레이어 음소거 유무
  @observable volume // 플레이어 볼륨
  @observable fromSalon // 살롱에서 튼 흐름인지
  @observable controlledLocalSalonVolume // 로컬에서 살롱 흐름 볼륨 직접조절 여부 => 호스트가 볼륨 조절해도 영향 x

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.playerType = null

    this.myPlayAuth = true
    this.isTryPlay = false

    this.currentPlayMixtape = {}
    this.currentPlaySong = {}
    this.currentUsersPlayMixtapes = {}
    this.currentUsersPlayMixtapesTmpTime = new Date()
    this.currentUsersPlaySong = {}
    this.currentUsersPlaySongTmpTime = new Date()

    this.currentControlList = []
    this.isAutoNext = false
    this.currentMixtapeIdx = false

    this.currentPlayCategory = null
    this.currentPlaySort = null

    this.songPlayedIndex = 0
    this.stopPlayer = false

    this.playing = true
    this.url = null
    this.muted = true

    // 추후 playerStatus 일괄 persist로 로직 수정해야함.
    const playStatus = JSON.parse(sessionStorage.getItem('hreum-play-status'))
    this.volume = playStatus?.volume ? playStatus.volume : 0.3
    this.fromSalon = false

    // 현재 재생 중인 흐름이 바뀔 때 트리거
    autorun(() => {
      this.songPlayedIndex = this.currentPlayMixtape['songPlayedIndex']
    })
  }

  @action.bound
  async initClient() {}

  @action.bound
  async clear() {
    this.init()
    PlayerManager().removeValue('player')
    sessionStorage.removeItem('hreum-play-status')
  }

  /** setter */
  @action.bound
  setPlayerType = async value => {
    this.playerType = value
  }

  @action.bound
  setPlaying = async value => {
    this.playing = value
  }

  @action.bound
  setMuted = async value => {
    this.muted = value
  }

  @action.bound
  setVolume = async value => {
    this.volume = value
    const playStatus = JSON.parse(sessionStorage.getItem('hreum-play-status'))
    const _playStatus = {
      ...playStatus,
      volume: value,
    }
    PlayerManager().setValue('player', { type: 'mixtape', ..._playStatus })

    sessionStorage.setItem('hreum-play-status', JSON.stringify(_playStatus))
  }

  @action.bound
  setFromSalon = async value => {
    this.fromSalon = value
  }

  /**
   * 재생한 흐름 상세
   */
  @action.bound
  fetchLoadMixtapeDetail = async mixtapeId => {
    if (!mixtapeId || !this.store.authStore.currentUser['id']) return
    /**
     * 테이블 조인으로 인한 연산속도가 느림.
     * 차라리 따로 fetch 하도록 수정 - 20190911 jhlim
     * todo - database 서버 성능 향상
     *
     * 따로 fetch 추가 - 20200207 jhlim
     * 테스트 mixtapeId=962
     * Android / IOS 10 회 시도 평균
     * 예전 걸리는 시간 1.2 sec
     * 지금 걸리는 시간 0.2 sec
     */
    const asyncResult1 = this.network.mixtapeNetwork.getMixtape(mixtapeId, {
      __include:
        'MixtapesHaveSongs.Songs,Users,MixtapesHaveSongs.Songs.UsersLikeSongs',
      'MixtapesHaveSongs.Songs.UsersLikeSongs.userId': this.store.authStore // 좋아요한 오디오 체크
        .currentUser['id'],
      __addColumn: 'isFollowed,isLiked,likeCount,playCount,mixtapePlayedTime',
    })
    const asyncResult2 = this.network.mixtapeNetwork.getMixtape(mixtapeId, {
      __include: 'UsersLikeMixtapes',
    })
    const asyncResult3 = this.network.mixtapeNetwork.getMixtape(mixtapeId, {
      __include: 'MixtapesHaveTags.Tags',
    })
    const [result1, result2, result3] = await Promise.all([
      asyncResult1,
      asyncResult2,
      asyncResult3,
    ])

    // const commentList = await this.store.commentStore.fetchTargetMixtapeReply(
    //   mixtapeId,
    //   'Mixtapes',
    //   1,
    // )

    if (
      !result1
      || !result2
      || !result3
      // || !commentList
    )
      return

    const mixtape = new MixtapeModel(this.store, result1)
    // mixtape['commentList'] = commentList || []
    mixtape['UsersLikeMixtapes'] = result2['UsersLikeMixtapes'] || []
    mixtape['MixtapesHaveTags'] =
      (result3['MixtapesHaveTags']
        && result3['MixtapesHaveTags'].filter(
          item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
        ))
      || []
    mixtape['MixtapesHaveTagsDelayUpdate'] =
      (result3['MixtapesHaveTagsDelayUpdate']
        && result3['MixtapesHaveTagsDelayUpdate'].filter(
          item => item['level'] <= MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
        ))
      || []

    /**
     * User 정보는 userDetailObj 와 동기화 - jhlim 20191227
     */
    if (mixtape && mixtape['User'] && mixtape['User']['id']) {
      // mixtape['User'] = this.store.userStore.syncUserDetailObj(Object.keys(result1['User']).reduce((obj, key) => ({ ...obj, [key]: mixtape['User'][key] })))
      mixtape['User'] = this.store.userStore.getUserDetailObj(mixtape['User'])
    }

    // 본인 흐름인지
    if (
      mixtape
      && mixtape['User']
      && mixtape['User']['id'] === this.store.authStore.currentUser['id']
    ) {
      // mixtape['isFollowed'] = 'isMine'
      mixtape['User']['isFollowed'] = 'isMine'
    }

    // mixtape['songPlayedTime'] = mixtape[
    //   'MixtapesHaveSongs'
    // ].filter(
    //   song =>
    //     song['songOrder'] === mixtape['songPlayedIndex'],
    // )[0].Song['duration'] - 6
    this.currentPlayMixtape = mixtape
  }

  /**
   * 로그인한 유저가 프리미엄 회원인지 체크
   */
  @action.bound
  async fetchLoadPlayMixtape(
    mixtape,
    categoryName = null,
    sortBy = null,
    isInteractPlay = true,
  ) {
    this.currentUsersPlaySong = {}
    this.store.castStore.currentPlayCast = {}
    this.currentPlayMixtape = {}
    this.currentPlaySong = {}

    if (isMobile) {
      alert('PC 혹은 App에서만 지원하는 기능입니다.')
      return false
    }

    // 비회원 클릭 시, 로그인 유도
    this.store.authStore.tryAuth()

    if (!mixtape.isPublished || mixtape.deletedAt) {
      alert('비공개되었거나 삭제된 플라입니다.')
      return false
    }

    if (!mixtape) return
    return this.store.useLoading(async () => {
      // 유료 회원 로직 제거
      // this.myPlayAuth = !!(
      //   this.store.authStore.currentUser
      //   && this.store.authStore.currentUser.adLevel >= AD_LEVEL.STUDENT
      // )
      this.myPlayAuth = true

      this.isTryPlay = true

      if (!this.myPlayAuth) return false

      await this.fetchLoadMixtapeDetail(mixtape.id)

      this.currentPlaySong = this.currentPlayMixtape[
        'MixtapesHaveSongs'
      ].filter(
        song =>
          song['songOrder'] === this.currentPlayMixtape['songPlayedIndex'],
      )[0]

      // this.logPlayMixtape(this.currentPlayMixtape)

      this.fetchControlList(categoryName, sortBy)
      this.url =
        this.currentPlaySong
        && this.currentPlaySong.Song
        && this.currentPlaySong.Song.normalizedM3u8Url
      this.interactPlay = isInteractPlay
      this.stopPlayer = false

      return !!this.currentPlaySong
    })
  }

  @action.bound
  async fetchLoadPlayMixtapeById(
    mixtapeId,
    categoryName = null,
    sortBy = null,
    isInteractPlay = true,
  ) {
    this.currentUsersPlaySong = {}
    this.store.castStore.currentPlayCast = {}
    this.currentPlayMixtape = {}
    this.currentPlaySong = {}

    if (isMobile) {
      alert('PC 혹은 App에서만 지원하는 기능입니다.')
      return false
    }

    if (!mixtapeId) return
    return this.store.useLoading(async () => {
      // 유료 회원 로직 제거
      // this.myPlayAuth =
      //   this.store.authStore.currentUser
      //   && (this.store.authStore.currentUser.adLevel >= AD_LEVEL.STUDENT
      //     || this.store.authStore.currentUser.adLevel === AD_LEVEL.NEW_USER)
      //     ? true
      //     : !!IS_DEV
      this.myPlayAuth = true

      this.isTryPlay = true

      if (!this.myPlayAuth) return false

      await this.fetchLoadMixtapeDetail(mixtapeId)

      this.currentPlaySong = this.currentPlayMixtape[
        'MixtapesHaveSongs'
      ].filter(
        song =>
          song['songOrder'] === this.currentPlayMixtape['songPlayedIndex'],
      )[0]

      // this.logPlayMixtape(this.currentPlayMixtape)

      this.fetchControlList(categoryName, sortBy)
      this.interactPlay = isInteractPlay
      this.stopPlayer = false

      this.url =
        this.currentPlaySong
        && this.currentPlaySong.Song
        && this.currentPlaySong.Song.normalizedM3u8Url

      return !!this.currentPlaySong
    })
  }

  // 재생 접근 여부
  // 체크 후, 다시 false
  @action.bound
  async updateIsTryPlay(value) {
    this.isTryPlay = value
  }

  @action.bound
  reFetchPlayMixtape = async mixtape => {
    if (!this.store.authStore.currentUser) return
    const mixtapeId =
      mixtape && mixtape['id']
        ? mixtape['id']
        : this.currentPlayMixtape && this.currentPlayMixtape['id']
    if (!mixtapeId) return

    const fetchedMixtape = await this.network.mixtapeNetwork.getMixtape(
      mixtapeId,
      {
        __addColumn: 'mixtapePlayedTime,isLiked',
        __include:
          'Users,MixtapesHaveSongs.Songs,MixtapesHaveSongs.Songs.UsersLikeSongs',
        'MixtapesHaveSongs.Songs.UsersLikeSongs.userId': this.store.authStore
          .currentUser['id'], // 좋아요한 오디오 체크
      },
    )

    const _currentPlayMixtape = new MixtapeModel(this.store, fetchedMixtape)
    const _currentPlaySong = _currentPlayMixtape['MixtapesHaveSongs']?.filter(
      song => song['songOrder'] === _currentPlayMixtape['songPlayedIndex'],
    )[0]

    this.currentPlayMixtape = _currentPlayMixtape
    this.currentPlaySong = _currentPlaySong
    this.url =
      _currentPlaySong
      && _currentPlaySong.Song
      && _currentPlaySong.Song['normalizedM3u8Url']

    this.stopPlayer = false
  }

  // 흐름 내 다음 곡 가져오기
  @action.bound
  getNextSong = async () => {
    const isLast = await this.isLastCurrentSong()
    if (isLast) {
      this.songPlayedIndex = 0
    }
    else {
      this.songPlayedIndex += 1
    }

    // 현재 재생 중인 곡의 다음 곡
    const _currentPlaySong = this.currentPlayMixtape[
      'MixtapesHaveSongs'
    ].filter(song => song['songOrder'] === this.songPlayedIndex)[0]

    // 맨 처음 부터 재생
    // this.currentPlayMixtape['songPlayedTime'] = _currentPlaySong.Song['duration'] - 6
    this.currentPlayMixtape['songPlayedTime'] = 0

    /* CastPlayer useEffect 와 싱크 */
    this.currentPlaySong = _currentPlaySong

    this.url =
      _currentPlaySong
      && _currentPlaySong.Song
      && _currentPlaySong.Song.normalizedM3u8Url
  }

  @action.bound
  syncCurrentSong = async () => {
    // return this.store.useLoading(async () => {
    await this.reFetchPlayMixtape()

    this.currentPlaySong = this.currentPlayMixtape['MixtapesHaveSongs']?.filter(
      song => song['songOrder'] === this.currentPlayMixtape['songPlayedIndex'],
    )[0]

    this.url =
      this.currentPlaySong
      && this.currentPlaySong.Song
      && this.currentPlaySong.Song.normalizedM3u8Url

    this.logPlayMixtape(this.currentPlayMixtape)
    // })
  }

  /**
   * 재생한 시간, 재생 중인 흐름 등등의 로그 저장
   * @param {*} mixtape
   */
  @action.bound
  logPlayMixtape = async mixtape => {
    // 살롱 재생일 때, 흐름 로그 제거
    if (this.fromSalon) return
    if (!mixtape) return

    // 동시 시청자 수
    // this.playingMixtapeListeningUserCount =
    //   LISTENING_COUNT_SWITCH && (await this.fetchListeningUserCount(mixtape.id))

    if (
      !this.currentUsersPlayMixtapes
      || this.currentUsersPlayMixtapes['mixtapeId'] !== mixtape['id']
    ) {
      const user = this.store.authStore.currentUser || {}
      const startTime = new Date()

      const value = {
        startTime,
        playTime: 0,

        userId: user['id'],
        mixtapeId: mixtape['id'],
      }

      const usersPlayMixtapesList = await this.network.mixtapeNetwork.postUsersPlayMixtapesList(
        [value],
      )
      if (!usersPlayMixtapesList || !(usersPlayMixtapesList.length > 0)) return

      this.currentUsersPlayMixtapes = usersPlayMixtapesList[0]
      this.currentUsersPlayMixtapesTmpTime = startTime

      if (IS_DEV) {
        console.log(
          '===[PLAYER STORE][CREATE] currentUsersPlayMixtapes.mixtapeId',
          this.currentUsersPlayMixtapes['mixtapeId'],
          'playTime',
          this.currentUsersPlayMixtapes['playTime'],
          'sec ===',
        )
      }
    }
    else {
      const stopTime = new Date()
      const playTime =
        this.currentUsersPlayMixtapes['playTime']
        + (stopTime.getTime() - this.currentUsersPlayMixtapesTmpTime.getTime())
          / 1000
      const value = {
        stopTime,
        playTime,
      }

      const usersPlayMixtapes = await this.network.mixtapeNetwork.putUsersPlayMixtapes(
        this.currentUsersPlayMixtapes['id'],
        value,
      )
      if (!usersPlayMixtapes) return

      this.currentUsersPlayMixtapes = usersPlayMixtapes
      this.currentUsersPlayMixtapesTmpTime = stopTime
      if (IS_DEV) {
        console.log(
          '===[PLAYER STORE][UPDATE] currentUsersPlayMixtapes.mixtapeId',
          this.currentUsersPlayMixtapes['mixtapeId'],
          'playTime',
          this.currentUsersPlayMixtapes['playTime'],
          'sec ===',
        )
      }
    }
  }

  @action.bound
  updateCurrentUsersPlayMixtapesTmpTime = value => {
    this.currentUsersPlayMixtapesTmpTime = value
  }

  @action.bound
  updateCurrentUsersPlaySongTmpTime = value => {
    this.currentUsersPlaySongTmpTime = value
  }

  @action.bound
  updateInteractPlay = value => {
    this.interactPlay = value
  }

  /**
   * 재생한 시간, 재생 중인 곡 등등의 로그 저장
   * @param {*} mixtape
   */
  @action.bound
  logPlaySong = async song => {
    if (!song) return

    if (
      !this.currentUsersPlaySong
      || this.currentUsersPlaySong['songId'] !== song['songId']
    ) {
      const user = this.store.authStore.currentUser || {}
      const startTime = new Date()

      const value = {
        startTime,
        tmpRestartTime: new Date(),
        playTime: 0,

        userId: user['id'],
        mixtapeId: song['mixtapeId'],
        songId: song['songId'],
        songTotalTime: song['Song']['duration'],
      }

      const usersPlaySongList = await this.network.songNetwork.postUsersPlaySongs(
        [value],
      )
      if (!usersPlaySongList || !(usersPlaySongList.length > 0)) return
      this.currentUsersPlaySong = usersPlaySongList[0]
      this.currentUsersPlaySongTmpTime = startTime
      if (IS_DEV) {
        console.log(
          '===[PLAYER STORE][CREATE] currentUsersPlaySong.songId',
          this.currentUsersPlaySong['songId'],
          'playTime',
          this.currentUsersPlaySong['playTime'],
          'sec ===',
        )
      }
    }
    else {
      const stopTime = new Date()
      const playTime =
        this.currentUsersPlaySong['playTime']
        + (stopTime.getTime() - this.currentUsersPlaySongTmpTime.getTime()) / 1000
      const value = {
        stopTime,
        playTime,
      }

      const usersPlaySongList = await this.network.songNetwork.putUsersPlaySongs(
        this.currentUsersPlaySong['id'],
        value,
      )
      if (!usersPlaySongList) return

      this.currentUsersPlaySong = usersPlaySongList
      this.currentUsersPlaySongTmpTime = stopTime
      if (IS_DEV) {
        console.log(
          '===[PLAYER STORE][UPDATE] currentUsersPlaySong.songId',
          this.currentUsersPlaySong['songId'],
          'playTime',
          this.currentUsersPlaySong['playTime'],
          'sec ===',
        )
      }
    }
  }

  // 현재 흐름의 마지막 곡 인지
  isLastCurrentSong = () => {
    return (
      this.currentPlayMixtape['MixtapesHaveSongs']
      && this.currentPlayMixtape['MixtapesHaveSongs'].length > 0
      && this.currentPlaySong['songOrder']
      && this.currentPlayMixtape['MixtapesHaveSongs'].length
        === this.currentPlaySong['songOrder'] + 1
    )
  }

  // 다음 흐름 자동 재생 토글
  @action.bound
  toggleAutoNext = () => {
    this.isAutoNext = !this.isAutoNext
  }

  /**
   * 재생한 흐름 종류의 다음 흐름 리스트
   * @param {*} value
   */
  @action.bound
  fetchControlList = async (categoryName = null, sortBy = null) => {
    this.currentPlayCategory = categoryName
    this.currentPlaySort = sortBy

    switch (categoryName) {
      case 'history':
        await this.store.mixtapeStore.fetchMyListenedMixtapeList()
        this.currentControlList = this.store.mixtapeStore.myListenedMixtapeList
        break
      case 'total':
        if (sortBy === 'popular') {
          await this.store.mixtapeStore.fetchHomePopularTotalMixtapeList()
        }
        else if (sortBy === 'latest') {
          await this.store.mixtapeStore.fetchTotalMixtapeList({ limit: 20 })
        }
        this.currentControlList = this.store.mixtapeStore.totalMixtapeList
        break
      case 'like':
        await this.store.mixtapeStore.fetchMyLikeMixtapeList({ limit: 20 })
        this.currentControlList = this.store.mixtapeStore.myLikeMixtapeList
        break
      case 'subscribe':
        await this.store.mixtapeStore.fetchMyFollowMixtapeList({ limit: 20 })
        this.currentControlList = this.store.mixtapeStore.myFollowMixtapeList
        break
      case 'search':
        await this.store.searchStore.fetchSearchMixtapeList(
          this.store.searchStore.mixtapeSearchKeyword,
        )
        this.currentControlList = this.store.searchStore.searchedMixtapeList
        break
      case 'tag':
        if (sortBy === 'popular') {
          await this.store.searchStore.fetchTagPopulartMixtapeList(
            this.store.searchStore.mixtapeTagKeyword,
          )
        }
        else if (sortBy === 'latest') {
          await this.store.searchStore.fetchTagLatestMixtapeList(
            this.store.searchStore.mixtapeTagKeyword,
          )
        }
        this.currentControlList = this.store.searchStore.tagMixtapeList
        break
      default:
        break
    }

    if (this.currentControlList && this.currentControlList.length > 1) {
      const currIndex = this.currentControlList.findIndex(
        item => item.id === this.currentPlayMixtape.id,
      )

      this.currentMixtapeIdx = currIndex
    }
  }

  @action.bound
  nextPlayMixtape = async () => {
    if (this.currentControlList.length > 1) {
      const currIndex = this.currentControlList.findIndex(
        item => item.id === this.currentPlayMixtape.id,
      )

      this.currentMixtapeIdx = currIndex

      const nextIndex =
        currIndex !== this.currentControlList.length - 1 ? currIndex + 1 : 0

      await this.reFetchPlayMixtape(this.currentControlList[nextIndex])
      this.currentControlList.shift()
      // await this.fetchControlList(
      //   this.currentPlayCategory,
      //   this.currentPlaySort,
      // )
    }
  }

  @action.bound
  _likeMixtape = () => {
    if (this.currentPlayMixtape['isLiked']) {
      this.store.mixtapeStore.dislikeMixtape(this.currentPlayMixtape)
    }
    else {
      this.store.mixtapeStore.likeMixtape(this.currentPlayMixtape)
    }
  }

  @action.bound
  _likeSong = song => {
    if (song['isLiked']) {
      this.store.songStore.disLikeSong(song)
    }
    else {
      this.store.songStore.likeSong(song)
    }
  }

  // 흐름 플레이어 중지
  @action.bound
  _stopPlayer = () => {
    this.stopPlayer = true
  }

  @action.bound
  updateCurrentControllList = list => {
    this.currentControlList = list
  }

  // 살롱에서 흐름을 틀 때 ( 흐름 플레이어 뷰 노출 x )
  @action.bound
  async fetchLoadPlaySalonMixtape(mixtape, mixtapeVolume) {
    this.currentUsersPlaySong = {}
    this.store.castStore.currentPlayCast = {}
    this.currentPlayMixtape = {}
    this.currentPlaySong = {}

    if (isMobile) {
      alert('PC 혹은 App에서만 지원하는 기능입니다.')
      return false
    }

    // 비회원 클릭 시, 로그인 유도
    this.store.authStore.tryAuth()

    if (!mixtape.isPublished || mixtape.deletedAt) {
      alert('비공개되었거나 삭제된 플라입니다.')
      return false
    }

    if (!mixtape) return
    return this.store.useLoading(async () => {
      // 살롱 재생
      this.setFromSalon(true)

      // 유료 회원 로직 제거
      // this.myPlayAuth = !!(
      //   this.store.authStore.currentUser
      //   && this.store.authStore.currentUser.adLevel >= AD_LEVEL.STUDENT
      // )
      this.myPlayAuth = true

      this.isTryPlay = true

      if (!this.myPlayAuth) return false

      await this.fetchLoadMixtapeDetail(mixtape.id)

      this.currentPlaySong = this.currentPlayMixtape[
        'MixtapesHaveSongs'
      ].filter(
        song =>
          song['songOrder'] === this.currentPlayMixtape['songPlayedIndex'],
      )[0]

      // this.logPlayMixtape(this.currentPlayMixtape)
      // this.fetchControlList(categoryName, sortBy)

      this.stopPlayer = false
      this.interactPlay = true

      this.url =
        this.currentPlaySong
        && this.currentPlaySong.Song
        && this.currentPlaySong.Song.normalizedM3u8Url
      this.volume = mixtapeVolume

      // 살롱으로 실행했을 때, session에 살롱 실행 상태 저장
      const prevStatus = sessionStorage.getItem('hreum-play-status')
      const newStatus = {
        ...JSON.parse(prevStatus),
        salon: true,
        volume: mixtapeVolume,
      }
      PlayerManager().setValue('player', { type: 'mixtape', ...newStatus })

      sessionStorage.setItem('hreum-play-status', JSON.stringify(newStatus))

      return !!this.currentPlaySong
    })
  }

  @action.bound
  async fetchLoadPlayLiveRoomAudio(song) {
    if (this.currentPlayMixtape) {
      this.currentPlayMixtape.songPlayedTime = 0
    }

    this.myPlayAuth = true
    this.isTryPlay = true
    if (!this.myPlayAuth) return false

    return this.store.useLoading(async () => {
      this.setFromSalon(true)
      this.stopPlayer = false
      this.interactPlay = true
      this.currentPlaySong = { Song: song }
      this.url =
        this.currentPlaySong
        && this.currentPlaySong.Song
        && this.currentPlaySong.Song.normalizedM3u8Url
      await this.setPlaying(true)
    })
  }

  @action.bound
  async endedPlayLiveRoomAudio() {
    if (this.store.liveRoomStore.currentPlayingLiveRoomMixtape) {
      // 오디오가 끝나고, 재생 중이던 흐름이 있을 때

      // Question. 오디오 종료 시, 재생 중이던 흐름 재생되게 하는 함수
      // this.syncCurrentSong()
      // or
      if (this.store?.liveRoomStore?.currLiveRoom?.Mixtape) {
        this.store.liveRoomStore.currLiveRoom['Mixtape'] = null
      }
      this._stopPlayer()
    }
    else {
      // 오디오가 끝나고, 재생 중이던 흐름이 없을 때
      if (this.store?.liveRoomStore?.currLiveRoom?.Mixtape) {
        this.store.liveRoomStore.currLiveRoom['Mixtape'] = null
      }
      this.store.liveRoomStore.currentPlayingLiveRoomAudio = null
      this._stopPlayer()
    }
  }
}
