import React, { useEffect } from 'react'
import { CancelTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const CancelPage = () => {
  useRenderLogger('CancelPage')
  const { authStore, purchaseStore } = useStore()

  const currentUser = authStore.currentUser || null

  const myPlan = purchaseStore.myPlan || null
  const getMyPlan = purchaseStore.getMyPlan || (() => {})
  const isCanceled = purchaseStore.isCanceled || null
  const cancelPlan = purchaseStore.cancelPlan || (() => {})

  useEffect(() => {
    window.scrollTo(0, 0)
    getMyPlan()
  }, [getMyPlan])

  return (
    <CancelTemplate
      user={currentUser}
      myPlan={myPlan}
      isCanceled={isCanceled}
      cancelPlan={cancelPlan}
    />
  )
}

export default observer(CancelPage)
