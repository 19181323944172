import { HouseDetailTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

const HouseDetailPage = ({ match, location, history }) => {
  useRenderLogger('HouseDetailPage')

  const [houseDetail, setHouseDetail] = useState(null)
  const [isMyPage, setIsMyPage] = useState(false)

  const { houseStore, authStore } = useStore()

  useEffect(() => {
    history.replace()

    return () => {
    }
  }, [])
  useEffect(() => {
    if (match?.params?.linkKey) {
      houseStore.fetchHouseDetailFromLinkKey(match.params.linkKey, {
        __include: 'HousesHaveTags.Tags',
      }).then((fetchedHouse) => {
        setHouseDetail(houseStore.houseDetailObj[fetchedHouse?.id]?.elem || null)
      })
    }
  }, [match, houseStore])

  useEffect(() => {
    if (houseDetail) {
      setIsMyPage(houseDetail.userId === authStore?.currentUser?.id)
    }
  }, [houseDetail, authStore])

  return <HouseDetailTemplate
    isMyPage={isMyPage}
    houseDetail={houseDetail}
    initFeedId={location?.state?.init?.feedId || null}
  />
}

export default observer(HouseDetailPage)
