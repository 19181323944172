import { SalonListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import QueryString from 'qs'
import React from 'react'

interface ISalonListPage {}

const SalonListPage: React.FC<ISalonListPage> = () => {
  useRenderLogger('SalonListPage')

  const { liveRoomSearchStore, liveRoomStore } = useStore()

  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)
  // const [dynamicLink, setDynamicLink] = React.useState('')
  const [isInitializedPage, setIsInitializedPage] = React.useState(false)

  const param = document.location.href.split('?')

  // 리스트 스크롤링
  const handleScrollList = () => {
    setOffset(offset + 20)
  }

  // fetch 태그 검색
  const handleSearchTag = async tag => {
    setOffset(0)

    liveRoomSearchStore.setSearchedText(tag)
    if (tag === '') {
      /* 기본 살롱 리스트 => 스토어 기본 로딩 렌더 */
      setIsInitializedPage(false)
      window.history.replaceState(null, null, '/p/salon/list')
    }
    else {
      /* 태그별 살롱 리스트 => 태그별 로딩 렌더 */
      setHasMore(
        !liveRoomSearchStore.currentAutocomplete
          || !liveRoomSearchStore.currentAutocomplete.isFinishedLiveRoom,
      )
      window.history.replaceState(null, null, `?tag=${tag}`)
    }
  }

  /* QeuryString 과 liveRoomSearchStore.searchedText 싱크 */
  React.useEffect(() => {
    const query = QueryString.parse(param[1], {
      ignoreQueryPrefix: true,
    })
    if (query.tag && query.tag !== liveRoomSearchStore.searchedText) {
      liveRoomSearchStore.setSearchedText(query.tag)
    }
    else if (!query.tag && query.tag !== liveRoomSearchStore.searchedText) {
      if (!liveRoomSearchStore.searchedText) {
        window.history.replaceState(null, null, '/p/salon/list')
      }
      else {
        window.history.replaceState(
          null,
          null,
          `?tag=${liveRoomSearchStore.searchedText}`,
        )
      }
    }

    // eslint-disable-next-line
  }, [liveRoomSearchStore])

  /* 기본 데이터 fetch 및 페이지네이션 fetch */
  React.useEffect(() => {
    const fetchData = async () => {
      if (liveRoomSearchStore.searchedText === '') {
        const result = await liveRoomStore.fetchLiveRoomList({
          limit: 50,
          isReset: offset === 0,
        })
        if (!result || result.isFinished) {
          setHasMore(false)
        }
        else {
          setHasMore(true)
        }
      }
      else {
        await liveRoomSearchStore.fetchAutocomplete(
          liveRoomSearchStore.searchedText,
          offset,
        )
        // liveRoomSearchStore 의 fetchedLength 변수 이용
        // if (!result) setHasMore(false)
        // else setHasMore(true)
        setHasMore(
          liveRoomSearchStore.currentAutocomplete
            && !liveRoomSearchStore.currentAutocomplete.isFinishedLiveRoom,
        )
      }
      setIsInitializedPage(true)
    }
    fetchData()

    // eslint-disable-next-line
  }, [
    liveRoomStore,
    liveRoomSearchStore,
    // eslint-disable-next-line
    liveRoomSearchStore?.searchedText,
    offset,
  ])

  return (
    <SalonListTemplate
      representiveTagList={['일상', '음악', '친목', '연애', '자기계발'] || []}
      salonSearchKeyword={liveRoomSearchStore.searchedText || ''}
      salonList={
        (liveRoomSearchStore.searchedText
          ? liveRoomSearchStore.currentAutocomplete
            && liveRoomSearchStore.currentAutocomplete.autocompleteLiveRoomList
          : liveRoomStore.liveRoomList) || []
      }
      // salonList={
      //   liveRoomSearchStore.searchedText
      //     ? liveRoomSearchStore.currentAutocomplete.autocompleteLiveRoomList
      //     : liveRoomStore.liveRoomList.concat(
      //       liveRoomStore.allReservationLiveRoomList,
      //     )
      // }
      handleSearchTag={handleSearchTag}
      hasMore={isInitializedPage && hasMore}
      isInitializedPage={isInitializedPage}
      handleScrollList={handleScrollList}
    />
  )
}

export default observer(SalonListPage)
