import React, { useState } from 'react'
import { SearchMixtapeForm, CategoryMixtapeList } from '@components/organisms'
import { Flex, Caption1, Caption2 } from '@components/atoms'
import { SearchTagCard } from '@components/molecules'
import styled, { keyframes, css } from 'styled-components'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import { twoEllipsisStyle } from '@styles/fontStyles'
import { mobile } from '@styles/media'
import { PageLayout } from '..'

const leftFade = keyframes`from {transform: translateX(100%);}to {transform: translateX(0);}`
const rightFade = keyframes`from {transform: translateX(-100%);}to {transform: translateX(0);}`

// 검색어 태그 리스트 박스
const SearchKeywordTagListBox = styled(Flex)`
  max-width: 1704px;
  width: 100%;
  margin: 32px 0 40px 0;
  height: 72px;
  padding: 2px 1px 6px 1px;
  align-items: center;
  overflow: hidden;

  @media ${mobile} {
    flex-direction: column;
    min-width: auto;
  }
`

// 검색어 태그 리스트
const SearchKeywordTagList = styled(Flex)`
  max-width: 1496px;
  width: 100%;
  padding: 0 16px;
  justify-content: flex-start;
  height: 64px;

  > div {
    margin-right: 30px;
  }

  > div:last-child {
    margin-right: 0px;
  }

  ${props =>
    props.isTagMove === 'next'
      ? css`
          animation: ${leftFade} 500ms linear;
        `
      : props.isTagMove === 'prev'
        ? css`
          animation: ${rightFade} 500ms linear;
        `
        : false}
`

const SearchKeywordTagMoveBtn = styled(Flex)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white_ff};
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: ${props => (props.isTagMove !== 'none' ? 1 : 0)};

  > img {
    width: 14px;
    height: 14px;
  }
`

// 태그 리스트
const SearchTagCardList = styled(Flex)`
  margin: 32px 0 40px 0;
  width: 100%;

  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    flex-direction: column;
    min-width: auto;
    justify-content: center;
  }
`

// 태그 리스트 아이템
const SearchTagCardItem = styled(Flex)`
  max-width: 280px;
  width: 100%;
  height: 64px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

let searchTagListIndex = 0 // 검색어 관련 태그 리스트 index

const HomeMixtapeListTemplate = ({
  tag,
  offset,
  setOffset,
  haveMore,
  setHaveMore,
  category,
  keyword,
  handleSearchTag,
  fetchTagKeywordList,
  searchTagKeywordList,
  searchedMixtapeList,
  representiveTagList,
  myListenedMixtapeList,
  totalMixtapeList,
  myLikeMixtapeList,
  myFollowMixtapeList,
  tagMixtapeList,
  fetchHomePopularTotalMixtapeList,
  fetchTotalMixtapeList,
  fetchMyFollowMixtapeList,
  fetchSearchMixtapeList,
  fetchTagLatestMixtapeList,
  fetchTagPopulartMixtapeList,
  fetchMyLikeMixtapeList,
}) => {
  const [isTagMove, setIsTagMove] = useState('none') // 태그 애니메이션 좌,우 상태값

  const handleSearchTagListIndex = value => {
    if (value === 'next') {
      searchTagListIndex += 5
    }
    else if (value === 'prev') {
      searchTagListIndex -= 5
    }
  }

  const handleTagMove = async value => {
    await handleSearchTagListIndex(value)
    setIsTagMove(value)
    setTimeout(() => {
      setIsTagMove('none')
    }, [500])
    fetchTagKeywordList(searchTagListIndex)
  }

  return (
    <PageLayout style={{ paddingTop: '0' }}>
      <SearchMixtapeForm
        haveMore={haveMore}
        setHaveMore={setHaveMore}
        setOffset={setOffset}
      />
      {
        category === 'search' ? (
          <SearchKeywordTagListBox>
            <SearchKeywordTagMoveBtn
              onClick={
                searchTagListIndex !== 0 ? () => handleTagMove('prev') : () => {}
              }
              isTagMove={isTagMove}
            >
              <img
                src={
                  searchTagListIndex !== 0
                    ? images.prev_black_img
                    : images.prev_default_img
                }
                alt="prev_btn"
              />
            </SearchKeywordTagMoveBtn>
            <SearchKeywordTagList isTagMove={isTagMove}>
              {
                searchTagKeywordList
              && searchTagKeywordList.map(item => (
                <SearchTagCardItem
                  key={item.id}
                  onClick={() => handleSearchTag(item.keyword)}
                >
                  <Caption1
                    align="left"
                    style={{ ...twoEllipsisStyle, width: '152px' }}
                  >
                    {`# ${item.keyword}`}
                  </Caption1>
                  <Flex
                    style={
                      {
                        width: '48px',
                        height: '30px',
                        borderRadius: '15px',
                        backgroundColor: '#eff7ff',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    }
                  >
                    <Caption2 color={colors.primary}>
                      {item.totalCount}
개
                    </Caption2>
                  </Flex>
                </SearchTagCardItem>
              ))
              }
            </SearchKeywordTagList>
            <SearchKeywordTagMoveBtn
              onClick={
                () =>
                  searchTagKeywordList
              && searchTagKeywordList.length === 5
              && handleTagMove('next')
              }
              isTagMove={isTagMove}
            >
              <img
                src={
                  searchTagKeywordList && searchTagKeywordList.length < 5
                    ? images.move_default_img
                    : images.move_black_img
                }
                alt="next_btn"
              />
            </SearchKeywordTagMoveBtn>
          </SearchKeywordTagListBox>
        ) : category !== 'tag' ? (
          <SearchTagCardList>
            {
              representiveTagList
            && representiveTagList.map(tag => (
              <SearchTagCard
                key={tag}
                tag={tag}
                onClick={tag => handleSearchTag(tag)}
              />
            ))
            }
          </SearchTagCardList>
        ) : (
          false
        )
      }
      <CategoryMixtapeList
        offset={offset}
        setOffset={setOffset}
        haveMore={haveMore}
        setHaveMore={setHaveMore}
        category={category}
        keyword={keyword}
        fetchHomePopularTotalMixtapeList={fetchHomePopularTotalMixtapeList}
        fetchTotalMixtapeList={fetchTotalMixtapeList}
        fetchMyFollowMixtapeList={fetchMyFollowMixtapeList}
        fetchSearchMixtapeList={fetchSearchMixtapeList}
        fetchTagLatestMixtapeList={fetchTagLatestMixtapeList}
        fetchTagPopulartMixtapeList={fetchTagPopulartMixtapeList}
        fetchMyLikeMixtapeList={fetchMyLikeMixtapeList}
        tag={tag && tag}
        list={
          category === 'history'
            ? myListenedMixtapeList
            : category === 'search'
              ? searchedMixtapeList
              : category === 'tag'
                ? tagMixtapeList
                : category === 'total'
                  ? totalMixtapeList
                  : category === 'subscribe'
                    ? myFollowMixtapeList
                    : category === 'like'
                      ? myLikeMixtapeList
                      : []
        }
      />
    </PageLayout>
  )
}

export default HomeMixtapeListTemplate
