import { Caption1, Flex, H6 } from '@components/atoms'
import { CastListItem } from '@components/molecules'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'

const HouseDetailCastSection = ({ isMyPage, houseDetail }) => {
  const { houseStore } = useStore()
  const history = useHistory()

  const houseCastList = houseStore.houseCastList || null

  const [hasMore, setHasMore] = React.useState(true)

  const fetchCastList = async () => {
    if (houseDetail?.id) {
      const isMore = await houseStore.fetchCastList(houseDetail.id, houseStore.houseCastList?.length)
      setHasMore(isMore)
    }
  }

  React.useLayoutEffect(() => {
    return () => {
      houseStore.houseCastList = []
    }
  }, [houseStore, houseDetail])

  React.useEffect(() => {
    const _fetchCastList = async () => {
      if (houseDetail?.id) {
        const isMore = await houseStore.fetchCastList(houseDetail.id)
        setHasMore(isMore)
      }
    }
    _fetchCastList()
  }, [houseStore, houseDetail])

  return (
    <MixtapeListBox>
      <InfiniteScroll
        dataLength={houseCastList.length}
        scrollThreshold={0.9}
        next={fetchCastList}
        hasMore={hasMore}
        hasChildren
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
        className="scroll_div"
        scrollableTarget="scrollableDiv"
        style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
      >
        <List>
          {
houseCastList?.length < 1 ? (
  <Flex
    type="column"
    style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
  >
    <H6 color={colors.grey_64}>캐스트가 없습니다</H6>
    {
      isMyPage && (
        <Caption1
          onClick={() => history.push('/p/cast/manage')}
          color={colors.grey_bf}
          style={{ cursor: 'pointer', marginTop: 8 }}
        >
                  지금 바로 캐스트를 등록해보세요!
        </Caption1>
      )
    }
  </Flex>
) : (
  houseCastList.map((item, index) => (
    <CastListItem
      item={item}
      key={`${item.id}_${index}`}
      category="user"
    />
  ))
)
          }
        </List>
      </InfiniteScroll>
    </MixtapeListBox>
  )
}

export default observer(HouseDetailCastSection)

const MixtapeListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -24px;

  width: calc(100% + 24px);

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
