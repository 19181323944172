import React, { useState } from 'react'
import styled from 'styled-components'
// antd
import { message } from 'antd'

import Files from 'react-butterfiles'
import { Buffer } from 'buffer'
import { Spinner, FlexColumnBox } from '@components/molecules'
import { H6, Caption1, Caption2 } from '@components/atoms'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'
import { images } from '@images/'

const UploadImg = styled.img`
  width: 48px;
  height: 48px;
`

const AudioUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: 217px;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  cursor: pointer;

  @media ${mobile} {
    width: 100%;
    height: 165px;
  }
`

const AudioUploadForm = ({
  setAudioFormData,
  setButterfilesFile = () => {},
  audioFormData,
  setValue,
  _uploadSongImage,
  getTag,
  imageUri,
  isCastUpload = false,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState(false)

  // metadata 등록 함수
  const getTags = async file => {
    const tag = await getTag(file)
    const { lyrics = {}, picture = {} } = (tag && tag['tags']) || {}

    setValue([
      // 20200706 월
      // 메타데이터 노래 제목, 가수 긁어오기 제거
      // 사진과 가사 정도만 가져오는 방향으로.
      // { name: title || '' },
      // { singer: artist || '' },
      { lyrics: lyrics['lyrics'] || '' },
    ])

    if (picture) {
      // if (!imageUri) {
      _uploadSongImage(picture)
      // }
    }

    // if (title && artist) {
    //   const res = await _getYoutubeLink(title, artist)
    //   setValue([{ youtubeUrl: res }, { yLinkId: res }])
    //   if (!res) {
    //     setValue('yLinkId', Date.now())
    //   }
    // }
  }

  return (
    <Files
      multiple={false}
      maxSize="1gb"
      convertToBase64
      accept={
        [
          'audio/mpeg',
          'audio/mpeg3',
          'audio/mp3',
          'audio/wav',
          'audio/vnd.wave',
          'video/mp4',
          'audio/x-m4a',
        ]
      }
      onSuccess={
        async ([selectedFile]) => {
          setLoading(true)
          try {
            const { file, base64 } = selectedFile.src
            const splitedBase64 = base64?.split('base64,')
            const base64Data = splitedBase64?.length > 1 && splitedBase64[1] || ''
            let ext = null
            if(base64Data) {
              /* get 32 bytes = 32 * 4 / 3 */
              const headerBinary = Buffer.from(base64Data.substring(0, 43), 'base64')

              const ftypSize = headerBinary[0] * 256 * 256 * 256 + headerBinary[1] * 256 * 256 + headerBinary[2] * 256 + headerBinary[3]
              let type = null
              let majorBrand = null
              // let minorVersion = null
              // let compatibleBrands = null
              if(ftypSize >= 8) {
                type = headerBinary.slice(4, 8).toString()
              }
              if(ftypSize >= 12) {
                majorBrand = headerBinary.slice(8, 12).toString()
              }

              /**
               * https://en.wikipedia.org/wiki/List_of_file_signatures
               *
               * 문서에 따르면
               * mp4 file signiture 가 offset=4 부터 8바이트가 ftypisom 이다.
               */
              // if(ftypSize >= 16 && type === 'ftyp' && majorBrand === 'isom') {
              //   minorVersion = headerBinary[12] * 256 * 256 * 256 + headerBinary[13] * 256 * 256 + headerBinary[14] * 256 + headerBinary[15]
              // }
              // if(ftypSize > 16 && type === 'ftyp' && majorBrand === 'isom' && minorVersion === 512) {
              //   compatibleBrands = headerBinary.slice(16, ftypSize).toString()
              // }
              // if(compatibleBrands && compatibleBrands.includes('mp4')) {
              if(type === 'ftyp' && majorBrand === 'isom') {
                ext = 'mp4'
              }

              if(ext === 'mp4' && selectedFile) {
                selectedFile.custom = {
                  name: `${file.name}.${ext}`,
                  type: 'video/mp4',
                }
              }
            }
            setButterfilesFile(selectedFile)

            const _audioFormData = new FormData()
            _audioFormData.append('audio', file)

            getTags(file)
            setAudioFormData(_audioFormData)

            // 파일명 확장자 제거
            const fileDot = file.name.lastIndexOf('.')
            let fileNameText = ''
            if (fileDot) {
              fileNameText = file.name.substring(0, fileDot)
            }
            setValue('name', fileNameText)

            setFileName(file.name)

            message.success('오디오 파일 업로드 완료')
            // console.log('File was successfully uploaded!')
            setLoading(false)
          }
          catch (e) {
          // console.log('An error occurred!', e.message)
            message.error('오디오 파일 업로드 실패')
            setLoading(false)
          }
        }
      }
      onError={
        errors => {
          if (errors[0].type === 'maxSizeExceeded') {
            message.error('200MB를 넘는 파일입니다!')
          }
          else if (errors[0].type === 'unsupportedFileType') {
            message.error('지원하지 않은 확장자입니다!')
          }
          else {
            message.error(`오디오 업로드 에러 : ${errors[0].type}`)
          }
        }
      }
    >
      {
        ({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div {...getDropZoneProps({ className: 'myDropZone' })}>
            <AudioUploadBox onClick={browseFiles}>
              {
                audioFormData ? (
                  <FlexColumnBox
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <UploadImg src={images.clip_hreum_img} alt="file" />
                    <H6>{fileName}</H6>
                    <Caption1 style={{ margin: '3px 0 0 0' }} color="#949494">
                  오디오 변경 클릭
                    </Caption1>
                  </FlexColumnBox>
                ) : loading ? (
                  <>
                    <Spinner
                      type="spinningBubbles"
                      color={colors.primary}
                      width="48px"
                      height="48px"
                    />
                    <H6 style={{ margin: '16px 0 9px 0' }}>
                  오디오 파일을 업로드 해주세요.
                    </H6>
                    <Caption1 color="#949494">오디오 파일 업로드 중 ...</Caption1>
                  </>
                ) : (
                  <>
                    <UploadImg src={images.upload_img} alt="upload_btn" />
                    <H6 style={{ margin: '16px 0 9px 0' }}>
                  오디오 파일을 업로드 해주세요.
                    </H6>
                    <Caption1 color="#949494">
                  1gb 이하의 mp3, mp4, WAV 오디오 파일
                    </Caption1>
                    {
                      isCastUpload && (
                        <FlexColumnBox
                          style={
                            {
                              backgroundColor: '#fafafa',
                              padding: '13px 20px 13px 20px',
                              marginTop: '10px',
                              marginBottom: '10px',
                              borderRadius: '8px',
                            }
                          }
                        >
                          <Caption2 style={{ color: '#646464' }}>
                      음악 저작권상 음원 파일로 캐스트를 구성할 수 없습니다.
                      음원 파일로 캐스트를 구성한 경우,
                          </Caption2>
                          <Caption2 style={{ color: '#646464' }}>
                      저작권자의 권익보호를 위해 계정 제한 및 해당 캐스트는
                      예고없이 삭제될 수 있습니다.
                          </Caption2>
                        </FlexColumnBox>
                      )
                    }
                  </>
                )
              }
            </AudioUploadBox>
          </div>
        )
      }
    </Files>
  )
}

export default AudioUploadForm
