import { action, observable } from 'mobx'
import { DJ_LEVEL } from '@consts/'
import { Network } from './networks'
import { Store } from '.'
import {
  BankAccountModel,
  UserModel,
  // RevenueHistoryModel,
  RevenueNotificationModel,
  RevenueHistoryModel,
} from './models'

export default class RevenueStore {
  @observable accumulation
  @observable currentBankAccount
  @observable revenueNotifications
  @observable revenueHistories

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = () => {
    this.accumulation = 0
    this.currentBankAccount = null
  }

  @action.bound
  async fetcAccumulation() {
    if (
      !this.store.authStore.currentUser ||
      this.store.authStore.currentUser['discJockeyLevel'] < DJ_LEVEL['GENERAL']
    )
      return

    const data = await this.network.revenueNetwork.getAccumulations()
    if (!data) return

    this.accumulation = data.accumulation || 0
  }

  @action.bound
  async fetchRevenue() {
    return this.store.useLoading(async () => {
      const data = await this.network.revenueNetwork.getRevenueUser(
        this.store.authStore.currentUser.id,
      )
      if (!data) return

      this.store.authStore.currentUser = new UserModel(this.store, data)
    })
  }

  @action.bound
  async fetchRevenueNotifications() {
    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.getRevenueNotifications({
        __limit: 5,
        __order: 'createdAtDesc',
        userId: this.store.authStore.currentUser.id,
      })
      if (res) {
        this.revenueNotifications =
          res
            .filter((elem) => !!elem)
            .map((elem) => new RevenueNotificationModel(this.store, elem)) || []
      }
    })
  }

  @action.bound
  async fetchRevenueHistories() {
    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.getRevenueHistories({
        // __limit: 5,
        __order: 'createdAtDesc',
        userId: this.store.authStore.currentUser.id,
      })
      if (res) {
        this.revenueHistories =
          res
            .filter((elem) => !!elem)
            .map((elem) => new RevenueHistoryModel(this.store, elem)) || []
      }
    })
  }

  @action.bound
  async getBankAccount() {
    return this.store.useLoading(async () => {
      // 승인된 (11) 상태의 계좌 중 최신순으로 1개의 계좌.
      const res = await this.network.revenueNetwork.getBankAccount({
        __limit: 1,
        userId: this.store.authStore.currentUser.id,
        responseState: 11,
        __order: 'responseAtDesc',
      })
      if (res && res.length > 0) {
        this.currentBankAccount = new BankAccountModel(this.store, res[0])
      }
    })
  }

  @action.bound
  async agreePartnerDJTerms() {
    return this.store.useLoading(async () => {
      const updatedUser = await this.network.userNetwork.putUser(
        this.store.authStore.currentUser.id,
        { isAgreePartnerDJTerms: true },
      )
      this.store.authStore.currentUser = new UserModel(this.store, updatedUser)
    })
  }

  @action.bound
  async postBankAccount(params) {
    return this.store.useLoading(async () => {
      const addedBankAccount = await this.network.revenueNetwork.postBankAccount(
        params,
      )
      if (addedBankAccount) {
        this.currentBankAccount = new BankAccountModel(
          this.store,
          addedBankAccount,
        )
      }

      return !!addedBankAccount
    })
  }

  @action.bound
  async postAdultCertification(params) {
    return this.store.useLoading(async () => {
      const addedAdultCertification = await this.network.revenueNetwork.postAdultCertification(
        params,
      )
      if (addedAdultCertification) {
        return addedAdultCertification
      }
    })
  }

  @action.bound
  async postRevenueHistories(requestRevenueAmount) {
    return this.store.useLoading(async () => {
      if (!requestRevenueAmount) return

      await this.getBankAccount()

      if (!this.currentBankAccount) {
        alert('계좌 정보를 찾지 못하였습니다.')
        return
      }

      const addedRevenueHistories = await this.network.revenueNetwork.postRevenueHistories(
        [
          {
            requestRevenueAmount: Number(requestRevenueAmount),
            bankAccountId: this.currentBankAccount.id,
            bankName: this.currentBankAccount.bankName,
            accountHolder: this.currentBankAccount.accountHolder,
            accountNumber: this.currentBankAccount.accountNumber,
          },
        ],
      )

      if (addedRevenueHistories) {
        return true
      }
    })
  }

  @action.bound
  async readedRevenueNotifications(userId) {
    return this.store.useLoading(async () => {
      if (!userId) return

      const readedRevenueNotifications = await this.network.revenueNetwork.putRevenueNotifications(
        {
          where: { userId: userId },
          value: { isReaded: true },
        },
      )

      return !!readedRevenueNotifications
    })
  }

  @action.bound
  async accountRevenueLicense() {
    return this.store.useLoading(async () => {
      const res = await this.network.revenueNetwork.postRevenueLicense()
      return !!res
    })
  }
}
