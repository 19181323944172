import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H6, Caption1, Flex, H5 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  min-width: 400px;
  max-height: 508px;
  padding: 50px 20px 20px 20px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const ConfirmBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: ${colors.primary};
  color: #ffffff;
  cursor: pointer;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle }) => {
  const handleClose = () => {
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H5 type="Bold">해당 플라는 7일간 비공개 처리되며</H5>
              <Flex style={{ marginBottom: '36px' }}>
                <H5 type="Bold">14일간</H5>
                <img
                  src={images.caution_img}
                  alt="caution_img"
                  style={
                    {
                      width: '40px',
                      height: '40px',
                      position: 'relative',
                      bottom: 5,
                    }
                  }
                />
                <H5 type="Bold">버튼이 노출 됩니다</H5>
              </Flex>
              <H6>
                  추가적인 전송권 침해가 확인되면
                <br />
                  DJ 자격 영구 박탈 및 수익 소멸 등의
                <br />
                  제재가 있을 수 있습니다.
              </H6>
              <Caption1
                color={colors.brown_grey}
                style={{ margin: '24px 0 50px 0' }}
              >
                  소수의 아티스트, 앨범의 음악으로만 이루어진 플라는
                <br />
                  아티스트의 저작권자의 전송권을 침해합니다.
                <br />
                  흐름 서비스는 ‘디지털 음성 송신’ 계약에 따라
                <br />
                  아티스트의 권리를 보호하고, 나아가 유저분들에게
                <br />
                  다양한 음악적 경험을 드리고자
                <br />
                  전송권을 침해하지 않는 음악 콘텐츠만을
                <br />
                  제공하고 있습니다.
              </Caption1>
              <ConfirmBtn onClick={handleClose}>
                <H6>확인</H6>
              </ConfirmBtn>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
