import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useStore } from '@utils/hooks'
import {
  H5,
  H6,
  Caption1,
  FormInput,
  WarningMsg,
  Form,
  Flex,
} from '@components/atoms'
import { SubmitBtn, KakaoLoginBtn, FlexRowBox } from '@components/molecules'
import CheckTextBox from '@components/molecules/box/CheckTextBox'

import { STATUS_CODE_401_MSG } from '@consts/'
import { images } from '@resources/'
import { Link, useHistory } from 'react-router-dom'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  min-width: 450px;
  min-height: 540px;

  max-width: 720px;
  max-height: 888px;

  width: 100%;
  height: 100%;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const LoginModal = () => {
  const history = useHistory()
  const { authStore } = useStore()

  const login = authStore.login || (() => {})
  const kakaoLogin = authStore.kakaoLogin || (() => {})

  const [isShowing, setIsShowing] = useState(false)
  const [email, setEmail] = useState('') // 이메일
  const [isAutoLogin, setIsAutoLogin] = useState(false)
  const [error, setError] = useState(false)
  const [checkYn, setCheckYn] = useState(false)
  const [password, setPassword] = useState('')

  const toggleAutoLogin = () => {
    setIsAutoLogin(!isAutoLogin)
  }

  const toggle = () => {
    setIsShowing(!isShowing)
    authStore.failAuth = false
  }

  const _login = async e => {
    e.preventDefault()
    const loginRes = await login(email, password, isAutoLogin)
    if (loginRes && loginRes.response) {
      setError(loginRes.response)
    }
  }

  const verifyEmail = () => {
    // 이메일 검증 스크립트 작성
    const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    // 검증에 사용할 정규식 변수 regExp에 저장

    if (email.match(regExp) != null) {
      setCheckYn(false)
    }
    else if (email !== '') {
      setCheckYn(true)
    }
  }

  const handleKakaoLogin = async (kakao_account, kToken) => {
    const isGo = await kakaoLogin(kakao_account, kToken)
    if (isGo === 'KAKAO_REGISTER') {
      history.push({
        pathname: '/p/register/privacy',
        state: { isKakao: true },
      })
    }
  }

  useEffect(() => {
    setIsShowing(true)
    enableBodyScroll()

    return () => {
      disableBodyScroll()
    }
  }, [isShowing, authStore])

  useEffect(() => {
    setError(false)
  }, [setError])

  return ReactDOM.createPortal(
    <>
      {
        isShowing && (
          <>
            <ModalOverlay onClick={() => toggle()} />
            <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
              <Modal>
                <MobdlBody>
                  <Form>
                    <HeaderBox>
                      <SalonLogo src={images.salon_logo} />
                      <H6 color="#646464" style={{ marginTop: 50 }}>
                      흐름 앱에서 사용하는 계정으로 로그인 가능합니다
                      </H6>
                    </HeaderBox>
                    <KakaoBox>
                      <KakaoLoginBtn kakaoLogin={handleKakaoLogin} />
                    </KakaoBox>
                    <OrLine>
                      <span>또는</span>
                    </OrLine>

                    <InputBox>
                      <FormInput
                        placeholder="가입한 이메일 입력"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => verifyEmail()}
                      />
                    </InputBox>
                    <InputBox>
                      <FormInput
                        type="password"
                        placeholder="비밀번호 입력"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />

                      {
                        checkYn ? (
                          <WarningMsg>이메일 형식이 올바르지 않습니다</WarningMsg>
                        ) : (
                          false
                        )
                      }

                      {
                        error && (
                          <WarningMsg>{STATUS_CODE_401_MSG[error]}</WarningMsg>
                        )
                      }
                    </InputBox>

                    <FlexRowBox
                      style={{ width: '100%', justifyContent: 'space-between' }}
                    >
                      <CheckTextBox
                        onClick={toggleAutoLogin}
                        checked={isAutoLogin}
                        text="로그인 상태유지"
                      />

                      <Flex justify="center" align="center">
                        <Link to="/p/find/email">
                          <Caption1 style={{ cursor: 'pointer' }} color="#242424">
                          이메일 찾기
                          </Caption1>
                        </Link>
                        <Flex
                          style={
                            {
                              width: 1,
                              height: 12,
                              backgroundColor: '#242424',
                              margin: '0 12px',
                              marginTop: 4,
                            }
                          }
                        />
                        <Link to="/p/find/password">
                          <Caption1 style={{ cursor: 'pointer' }} color="#242424">
                          비밀번호 찾기
                          </Caption1>
                        </Link>
                      </Flex>
                    </FlexRowBox>

                    <SubmitBtn
                      style={{ margin: '40px 0px' }}
                      onClick={e => _login(e)}
                    >
                    로그인
                    </SubmitBtn>

                    <FooterBox>
                      <Hr />
                      <H5 color="#646464">혹시, 흐름이 처음 이신가요?</H5>
                      <Link to="/p/register">
                        <RegisterBtn>회원가입</RegisterBtn>
                      </Link>
                    </FooterBox>
                  </Form>
                </MobdlBody>
              </Modal>
            </ModalWrapper>
          </>
        )
      }
    </>,
    document.body,
  )
}

export default LoginModal

const Hr = styled.hr`
  background-color: #949494;
  margin: 0;
  padding: 0;
`

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FooterBox = styled.div`
  width: 100%;
  text-align: center;
  h5 {
    margin: 60px 0px 30px 0px;
  }

  @media ${mobile} {
    h5 {
      margin: 30px 0px 24px 0px;
    }
  }
`

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const KakaoBox = styled(FlexRowBox)`
  margin: 50px 0;

  @media ${mobile} {
    margin: 30px 0;
  }
`

const OrLine = styled.p`
  height: 24px;
  margin-bottom: 30px;
  position: relative;
  width: 100%;

  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #d4d4d4;
  }

  span {
    width: 60px;
    height: 24px;
    margin: 0 0 0 -25px;
    font-size: 16px;
    line-height: 24px;

    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
    background: #fff;
    color: rgba(0, 0, 0, 0.38);
    text-align: center;
  }
`

const SalonLogo = styled.img`
  width: 116px;
  height: 48px;
  object-fit: contain;
`

const RegisterBtn = styled.button`
  -webkit-appearance: none;
  border-radius: 24px;
  cursor: pointer;
  width: 320px;
  // margin : 40px 0px;
  padding: 12px 100px;
  font: inherit;
  border: 2px solid #949494;
  background: #ffffff;
  color: #949494;

  :hover {
    background: #949494;
    color: #ffffff;
  }

  @media ${mobile} {
    margin: 10px 0px;
    width: 100%;
  }
`
