import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  Caption1,
  Caption2,
  ProfileImg,
  H6,
  H5,
  Div,
  Img,
} from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import {
  oneEllipsisStyle,
  threeEllipsisStyle,
  twoEllipsisStyle,
} from '@styles/fontStyles'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { castDuration, converseUnitEng, timeConvert } from '@utils/format'
import moment from 'moment'
import { CASTS_HAVE_TAGS_LEVEL } from '@consts/cast'
import { useStore } from '@utils/hooks'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import InfoPopup from '../Popup/InfoPopup'
import { YnPopup, FlexRowBox } from '..'
import CastReservedImageText from './CastReservedImageText'

const ItemBox = styled(Flex)`
  position: relative;
  flex-direction: column;
  max-width: 248px;
  width: 100%;
  background-color: ${colors.white_ff};
  /* border: solid 1px ${colors.grey_d4}; */
  overflow: hidden;
  cursor: pointer;
`

const ItemImage = styled.img`
  width: 248px;
  max-height: 248px;
  height: auto;
`

const PlayTimeInfo = styled.div`
  position: absolute;
  z-index: 3;
  /* height: 24px; */
  /* padding: 10px 0; */
  padding: 3px 6px;
  right: 8px;
  bottom: 8px;

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-size: 10px;
  font-family: NotoSansCJKkr-Regular;
  color #ffffff;
`

// const PlayBtn = styled.img`
//   position: absolute;
//   width: 48px;
//   height: 48px;
//   right: 16px;
//   bottom: 150px; // ItemInfo.height + padding 16
//   cursor: pointer;
// `

const HeartInfo = styled.img`
  width: 12px;
  height: 12px;
`
const PlayInfo = styled.img`
  width: 12px;
  height: 12px;
`

const InfoCount = styled.div`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color: #646464;
`
const InfoTime = styled.div`
  font-size: 12px;
  font-family: NotoSansCJKkr-Regular;
  color ${colors.brown_grey};
`

const ItemInfo = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  /* padding: 8px 16px; */
  padding-top: 8px;
  max-width: 248px;
  width: 100%;
  height: 142px;
`

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`

/**
 * 플라 리스트 아이템
 * 재생과 관련된 루트이기 때문에 useStore, observer 연결
 * (추후, 컴포넌트 레벨 올려야 함..)
 */
const CastListItem = ({ item, category, sort }) => {
  const history = useHistory()
  const { authStore, castStore } = useStore()

  const {
    isShowing: showInfoPopup,
    toggle: popupInfoToggle,
  } = InfoPopup.usePopup()

  const {
    isShowing: showHouseMember,
    toggle: toggleHouseMember,
  } = YnPopup.usePopup()

  const isHouseCast = !!item?.houseId

  const isMyHouseLiveRoom =
    authStore.currentUser
    && authStore.myDetailJoinedHouseList?.filter(
      house =>
        house.id === item?.houseId
        && house?._myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'],
    )?.length > 0

  return (
    <>
      <ItemBox
        onClick={
          async e => {
            if (isHouseCast && item?.isHouseMember && !isMyHouseLiveRoom) {
              toggleHouseMember()
            }
            else {
              e.stopPropagation()

              history.push({
                pathname: `/cast/${item && item.linkKey}`,
                state: { category, sort },
              })
            }
          }
        }
      >
        {
item?.isHouseMember && (
            <Div
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="24px"
              height="24px"
              borderRadius="50%"
              backgroundColor="rgba(36, 36, 36, 0.56)"
              position="absolute"
              top="8px"
              right="8px"
              zIndex={3}
            >
              <Img
                src={images.house_type_member_icon}
                width="20px"
                height="20px"
              />
            </Div>
          )
        }
        <Flex
          type={'column'}
          style={
            {
              width: '248px',
              height: '140px',
              alignItems: 'center',
              justifyContent: 'center',
            }
          }
        >
          <BlurContainer
            background={(item && item.thumbnailUrl) || images.default_song_img}
          >
            <BlurBackImg />
            <MainImg
              src={(item && item.imageUrl) || images.default_song_img}
              alt="cast"
            />
            <CastLogo>CAST</CastLogo>
            <PlayTimeInfo>{castDuration(item?.duration || 0)}</PlayTimeInfo>
            <CastReservedImageText castDetail={item} />
          </BlurContainer>
        </Flex>
        <Flex style={{ paddingTop: '8px' }}>
          <UserImage
            src={isHouseCast ? item?.House?.thumbnailUrl : item?.User?.imageUri}
            profileColor={item?.User.profileColor}
            onClick={
              e => {
                e.stopPropagation()
                history.push(`/user/${item && item.User.account}`)
              }
            }
          />
          <Flex type="column" style={{ width: '100%', marginLeft: '8px' }}>
            <Caption1
              style={
                {
                  width: '100%',
                  marginBottom: '4px',
                  ...twoEllipsisStyle,
                }
              }
              align="left"
            >
              {item && item.title}
            </Caption1>
            <Flex
              onClick={
                e => {
                  if (isHouseCast) {
                    e.stopPropagation()
                    history.push(`/house/${item?.House?.linkKey}`)
                  }
                }
              }
              type="row"
              style={{ width: '100%', alignItems: 'center' }}
            >
              <Caption2
                color={colors.brown_grey}
                type={'Regular'}
                align="left"
                style={
                  {
                    ...oneEllipsisStyle,
                    maxHeight: '18px',
                    marginRight: '2px',
                  }
                }
              >
                {/* {item?.User?.name} */}
                {isHouseCast ? item?.House?.title : item?.User?.name}
              </Caption2>
              {isHouseCast && <HouseBadge src={images.house_icon_img} />}
            </Flex>
            <Flex style={{ width: '100%', alignItems: 'center' }}>
              <Caption2 color={colors.brown_grey} type={'Regular'}>
                {`조회수 ${converseUnitEng(item.playCount)}회`}
              </Caption2>
              <InfoDot />
              <InfoTime>{timeConvert(item.publishedAt)}</InfoTime>
            </Flex>
          </Flex>
        </Flex>
        {
item?.isHouseMember && (
            <YnPopup.View
              isShowing={showHouseMember}
              toggle={toggleHouseMember}
              title="하우스 멤버 콘텐츠"
              body={'해당 콘텐츠는 멤버만 청취 가능합니다.'}
              yText="멤버 가입하기"
              onOk={
                () => {
                  history.push(`/house/${item?.House?.linkKey}`)
                }
              }
            />
          )
        }
      </ItemBox>
      {
        // <InfoPopup.View
        //   isShowing={showInfoPopup}
        //   toggle={popupInfoToggle}
        //   content={
        //     <InfoBody
        //       item={item}
        //       onClickUser={
        //         e => {
        //           e.stopPropagation()
        //           history.push(`/user/${item && item.User.account}`)
        //         }
        //       }
        //     />
        //   }
        // />
      }
    </>
  )
}

export default observer(CastListItem)

const InfoBody = ({ item, onClickUser = () => {} }) => {
  if (!item) {
    return <></>
  }
  return (
    <>
      <Flex
        style={
          {
            alignItems: 'center',
            width: '100%',
            overflow: 'hidden',
          }
        }
      >
        <InfoImg
          src={(item && item.imageUrl) || images.default_song_img}
          alt="cast_img"
        />
        <Flex
          style={
            {
              width: '100%',
              flexDirection: 'column',
              marginLeft: '28px',
            // alignItems: 'flex-start',
            }
          }
        >
          <Flex
            style={
              {
                alignItems: 'center',
                color: '#242424',
              }
            }
          >
            <img
              src={images.heart_img_red_24}
              alt="cast_heart_img"
              style={{ width: '24', height: '24', marginRight: '8px' }}
            />
            <InfoCount
              style={
                {
                  size: '14px',
                  color: '#242424',
                  paddingBottom: '2px',
                }
              }
            >
              {converseUnitEng(item.likeCount)}
            </InfoCount>
          </Flex>
          <Flex
            style={
              {
                paddingRight: '10',
                width: '100%',
              }
            }
          >
            <Caption1
              style={
                {
                  ...twoEllipsisStyle,
                  color: '#242424',
                  fontSize: '24px',
                  width: '100%',
                }
              }
              align="left"
            >
              {item.title}
            </Caption1>
          </Flex>
          <Flex
            style={
              {
                width: '700px',
                alignItems: 'center',
                marginTop: '12px',
                marginBottom: '12px',
              }
            }
            onClick={onClickUser}
          >
            <UserImage
              src={item?.User.imageUri}
              profileColor={item?.User.profileColor}
              style={{ marginRight: '8px' }}
            />
            <Caption2
              color={colors.grey_64}
              align="left"
              style={
                {
                  ...oneEllipsisStyle,
                  width: '796px',
                  maxHeight: '18px',
                }
              }
            >
              {item && item.User.name}
            </Caption2>
          </Flex>
          <InfoTime>{moment(item.createdAt).format('YYYY. MM. DD')}</InfoTime>
          <TagGroup style={{ marginTop: '16px' }}>
            {
item?.CastsHaveTags.filter(
              _castsHaveTag =>
                _castsHaveTag.level
                  === CASTS_HAVE_TAGS_LEVEL['VISIBLE_SYSTEM']
                || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['TOPIC']
                || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['NORMAL']
                || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['NO'],
            )
              .sort((a, b) => {
                const aLevel = a.level
                const bLevel = b.level
                if (aLevel > bLevel) {
                  return 1
                }
                if (aLevel === bLevel) {
                  return 0
                }
                if (aLevel < bLevel) {
                  return -1
                }
              })
              .map(_castsHaveTag => (
                <Tag style={{ marginRight: '8px', marginBottom: '8px' }}>
                  {_castsHaveTag?.Tag?.keyword}
                </Tag>
              ))
            }
          </TagGroup>
        </Flex>
      </Flex>
      <Flex
        style={
          {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '40px',
          }
        }
      >
        <H6>{`에피소드 소개`}</H6>
        <div
          style={
            {
              width: '100%',
              height: '1px',
              backgroundColor: '#f4f4f4',
              marginTop: '16px',
              marginBottom: '16px',
            }
          }
        />
        <Caption2
          align="left"
          type={'Regular'}
          style={{ color: '#242424', marginBottom: '24px', whiteSpace: 'pre' }}
        >
          {item.description}
        </Caption2>
        <Flex
          style={
            {
              width: '100%',
              justifyContent: 'center',
              paddingTop: '12px',
              paddingBottom: '12px',
            }
          }
        >
          <H5>{`현재 캐스트는 흐름 앱에서 감상 가능합니다.`}</H5>
        </Flex>
      </Flex>
    </>
  )
}

const InfoImg = styled.img`
  width: 240px;
  height: auto;
  max-height: 240px;
  /* margin: 0 28px 40px 0;
  padding: 53px 0 52px; */
  border-radius: 8px;
  /* -webkit-backdrop-filter: blur(32.1px);
  backdrop-filter: blur(32.1px);
  background-color: #fff; */
`

const TagGroup = styled(FlexRowBox)`
  flex-wrap: wrap;
`

const Tag = styled(Flex)`
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: auto;
  height: 28px;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #f4f4f4;
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color: #646464;
`

const PlayBtn = styled.img`
  position: absolute;
  width: 48px;
  height: 48px;
  top: 184px; // 카드 영역 패딩 + 하단 패딩 + 버튼 높이
  right: 16px; // 이미지 영역 + 우측 패딩 + 버튼 넓이
  cursor: pointer;
`

/**
 * cast item
 */

const BlurContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

const BlurBackImg = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  object-fit: contain;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* z-index: 2; */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`

const MainImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
`

const InfoDot = () => {
  return (
    <div
      style={
        {
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '4px',
          paddingRight: '4px',
        }
      }
    >
      <div
        style={
          {
            width: '2px',
            height: '2px',
            backgroundColor: colors.brown_grey,
            borderRadius: '1px',
          }
        }
      />
    </div>
  )
}

const HouseBadge = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

const CastLogo = styled(Div)`
  position: absolute;
  left: 8px;
  top: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 20px;
  border-radius: 2px;
  background-color: #fda319;
  color: ${colors.white_ff};
  font-size: 10px;
  line-height: 18px;
  z-index: 2;
`
