import React from 'react'
import { SubTitle } from '@components/atoms'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PageLayout } from '..'

// 업로드 목록 없을 시, 흐름 홈
const AudioNolistTemplate = () => {
  return (
    <PageLayout style={{ height: '78vh' }}>
      <SubTitle>흐름에 오신 걸 환영합니다!</SubTitle>
      <SubTitle>멋진 플라가 될 오디오를 업로드해주세요!</SubTitle>
      <UploadBtn to="/p/audio/upload">오디오 업로드</UploadBtn>
    </PageLayout>
  )
}

export default AudioNolistTemplate

const UploadBtn = styled(Link)`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;
  cursor: pointer;

  border-radius: 20px;
  border: solid 1px #594ee9;
  background-color: #594ee9;

  color: #ffffff;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`
