import { Caption2, Flex } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import React from 'react'

const MobileLeadBox = ({ onClick }) => {
  return (
    <Flex
      onClick={onClick}
      align="center"
      style={
        {
          zIndex: 6000,
          padding: '0 16px',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: 64,
          boxShadow: '0 -2px 4px 0 rgba(0, 0, 0, 0.08)',
          backgroundColor: colors.white_ff,
        }
      }
    >
      <img
        src={images.salon_favicon_img}
        alt="favicon"
        style={{ width: 48, height: 48, marginRight: 12 }}
      />
      <Flex type="column" style={{ width: '100%' }}>
        <Caption2 align="left" type="Bold">
          흐름 ⎜HREUM
        </Caption2>
        <Caption2 align="left" color={colors.grey_94} style={{ marginTop: 2 }}>
          앱에서 참여하기
        </Caption2>
      </Flex>
      <Flex
        justify="center"
        align="center"
        style={
          {
            minWidth: 56,
            width: 56,
            height: 32,
            backgroundColor: colors.white_f4,
            borderRadius: 16,
          }
        }
      >
        <Caption2 color={colors.secondary}>열기</Caption2>
      </Flex>
    </Flex>
  )
}

export default MobileLeadBox
