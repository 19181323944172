import { Caption1, Caption2, Flex } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { convertToPricingComma, addComma } from '@utils/string'
import React from 'react'
import styled, { css } from 'styled-components'

const PresentStarForm = ({
  currentUser,
  starPlan,
  setStarPlan,
  handleSend,
  style,
  isSalon = false,
}) => {
  const [inputHide, setInputHide] = React.useState('')

  const handleStarPlan = value => {
    setStarPlan(value)
    setInputHide(true)
  }

  const handleCancel = () => {
    setStarPlan('')
  }

  return (
    <Flex
      type="column"
      style={
        {
          width: '100%',
          height: '100%',
          ...(style && style),
        }
      }
    >
      <Flex justify="space-between">
        <Caption1>선물 가능한 별</Caption1>
        <Caption1>
          {
            `${(currentUser
            && currentUser.currentStar
            && convertToPricingComma(currentUser.currentStar))
            || 0}개`
          }
        </Caption1>
      </Flex>
      <Caption2
        align="left"
        color={colors.brown_grey}
        style={{ margin: '7px 0 24px 0' }}
      >
        *별 선물은
        <strong style={{ color: colors.black_24 }}>
          &nbsp;10개 이상&nbsp;
        </strong>
        부터 가능합니다
      </Caption2>
      <StarInputBox inputHide={inputHide}>
        <input
          placeholder="직접 입력"
          type="number"
          value={starPlan}
          onChange={e => setStarPlan(e.target.value)}
          onFocus={() => setInputHide(false)}
          onBlur={
            () => {
              if (Number(starPlan) < 10) setStarPlan('')
              setInputHide(true)
            }
          }
        />
        {
          starPlan !== '' && (
            <Caption1 onClick={() => setInputHide(false)}>
              {`${addComma(Number(starPlan))} 개`}
            </Caption1>
          )
        }
      </StarInputBox>
      <StarPlanList>
        <StarPlanBtn
          active={starPlan === '10'}
          onClick={() => handleStarPlan('10')}
        >
          <img src={images.salon_star_img} alt="10star_icon" />
          <Caption1>10개</Caption1>
        </StarPlanBtn>
        <StarPlanBtn
          active={starPlan === '50'}
          onClick={() => handleStarPlan('50')}
        >
          <img src={images.salon_50star_img} alt="50star_icon" />
          <Caption1>50개</Caption1>
        </StarPlanBtn>
        <StarPlanBtn
          active={starPlan === '100'}
          onClick={() => handleStarPlan('100')}
        >
          <img src={images.salon_100star_img} alt="100star_icon" />
          <Caption1>100개</Caption1>
        </StarPlanBtn>
        <StarPlanBtn
          active={starPlan === '1000'}
          onClick={() => handleStarPlan('1000')}
        >
          <img src={images.salon_1000star_img} alt="1000star_icon" />
          <Caption1>1,000개</Caption1>
        </StarPlanBtn>
      </StarPlanList>
      <Flex style={{ margin: '24px 0 40px 0' }}>
        {
          isSalon && (
            <Caption2 align="left" color={colors.brown_grey}>
            *선물한 별은
              <strong style={{ color: colors.black_24 }}>
              &nbsp;살롱을 만든 호스트
              </strong>
            에게 선물됩니다
            </Caption2>
          )
        }
      </Flex>
      <Flex justify="space-between">
        <CancelBtn onClick={handleCancel}>취소</CancelBtn>
        <SendBtn
          onClick={handleSend}
          disabled={starPlan === '' || Number(starPlan) < 10}
        >
          선물
        </SendBtn>
      </Flex>
    </Flex>
  )
}

export default PresentStarForm

const StarInputBox = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 472px;
  height: 40px;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: ${colors.white_f4};

  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-family: NotoSansCJKkr-Medium;
    font-size: 14px;

    ${({ inputHide }) =>
    inputHide
      && css`
        color: transparent;
      `}
  }

  p {
    text-align: left;
    position: absolute;
    left: 14px;

    ${({ inputHide }) =>
    !inputHide
      && css`
        color: transparent;
      `}
  }
`

const StarPlanList = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin-top: 24px;
  }
`

const StarPlanBtn = styled(Flex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;

  max-width: 228px;
  width: 100%;
  height: 72px;

  border: solid 1px ${colors.white_f4};
  border-radius: 8px;

  background-color: ${({ active }) => (active ? '#f8f8ff' : colors.white_ff)};
  p {
    color: ${({ active }) => (active ? colors.primary : colors.black_24)};
  }

  img {
    width: 48px;
    height: 48px;
    margin-right: 17px;
  }
`

const CancelBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  max-width: 228px;
  width: 100%;
  height: 48px;

  color: ${colors.grey_64};
  background-color: ${colors.white_f4};
  border-radius: 8px;
`

const SendBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  max-width: 228px;
  width: 100%;
  height: 48px;

  color: ${({ disabled }) => (disabled ? colors.grey_d4 : colors.white_ff)};
  background-color: ${({ disabled }) =>
    disabled ? colors.white_f4 : colors.primary};
  border-radius: 8px;
`
