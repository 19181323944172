import { SearchTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

const SearchPage = () => {
  const { searchStore } = useStore()

  const globalKeyword = searchStore.globalKeyword || ''

  const setGlobalKeyword = searchStore.setGlobalKeyword || (() => {})
  const setGlobalInputKeyword = searchStore.setGlobalInputKeyword || (() => {})

  useEffect(() => {
    return () => {
      setGlobalKeyword('')
      setGlobalInputKeyword('')
    }
  }, [setGlobalKeyword, setGlobalInputKeyword])

  useEffect(() => {
    if (globalKeyword) {
      console.log('globalKeyword', globalKeyword)
    }
  }, [globalKeyword])

  return <SearchTemplate />
}

export default observer(SearchPage)
