import React from 'react'
import { Div } from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'

const SampleModal = ({ hash }) => {
  const { appStateStore } = useStore()

  const offModal = appStateStore.offModal || (() => {})

  return (
    <Div
      position="fixed"
      zIndex={9999}
      top={'30%'}
      left={'30%'}
      width="50vw"
      height="50vh"
      background="white"
    >
      <button onClick={() => offModal(hash)}>sample끄기</button>
    </Div>
  )
}

export default observer(SampleModal)
