import React, { useState } from 'react'
import styled from 'styled-components'
import { H4, Caption1, Flex, H6, FormInput, SubTitle } from '@components/atoms'
import { Table, Icon, Tooltip, message, Pagination, Spin } from 'antd'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import {
  DeletePopup,
  EditRecordingPopup,
  MobileOptionPopup,
  ListPlayer,
} from '@components/molecules'
import { Link, useHistory } from 'react-router-dom'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { dateMDMS } from '@utils/format'
import { PageLayout } from '..'

// import 'antd/dist/antd.css'
const UPLOADED_AUDIO_TYPE = {
  AUDIO: 'AUDIO',
  RECORDING: 'RECORDING',
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

const NameBox = styled.div`
  float: left;
  p {
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  @media ${mobile} {
    width: 192px;
  }
`

const DotImg = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const ThreeDot = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    display: none;
  }

  span {
    margin: 0 10px;
  }

  @media ${mobile} {
    span {
      display: none;
    }
    img {
      display: block;
    }
  }
`
const Box = styled.div`
  position: absolute;
  z-index: 1000;
  top: 25px;
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const TooltipContent = styled.div`
  width: 435px;
  padding: 14px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;

  @media ${mobile} {
    width: 308px;
    padding: 12px;
  }
`

const CustomTooltip = ({ content, style }) => {
  const [show, setShow] = useState(false)
  return (
    <div>
      {show && <Box style={style}>{content}</Box>}
      <Icon
        type="copyright"
        style={
          {
            fontSize: 25,
            color: colors.secondary,
            cursor: 'pointer',
          }
        }
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </div>
  )
}

// 업로드 목록이 있을 시, 흐름 홈
const AudioListTemplate = ({
  songs,
  liveRoomRecordingList,
  removeSong,
  deleteLiveRoomRecording,
  editLiveRoomRecording,
  user,
  fetchSongDetail,
  _stopPlayer,
  stopPlayer,
  songsCount,
  currentPage,
  setCurrentPage,
  isLoading,
  currentPlayMixtape,
}) => {
  const history = useHistory()

  const initType =
    songs.length === 0 && liveRoomRecordingList !== 0
      ? UPLOADED_AUDIO_TYPE['RECORDING']
      : UPLOADED_AUDIO_TYPE['AUDIO']
  const [type, setType] = useState(initType)
  const [targetId, setTargetId] = useState(null)
  const [target, setTarget] = useState({})
  const [nowSong, setNowSong] = useState()
  // const [currentPage, setCurrentPage] = useState(1)
  const [liveRoomRecording, setLiveRoomRecording] = useState({ title: 'init' })

  const { isShowing: showPopup, toggle: popupToggle } = DeletePopup.useModal()
  const {
    isShowing: showRecordingPopup,
    toggle: popupRecordingToggle,
  } = EditRecordingPopup.useModal()
  const {
    isShowing: showMobile,
    toggle: mobileToggle,
  } = MobileOptionPopup.useModal()

  const deleteSong = async id => {
    await removeSong(id)
  }

  const _deleteLiveRoomRecording = async id => {
    await deleteLiveRoomRecording({ liveRoomRecordingId: id })
  }

  const _editLiveRoomRecording = async () => {
    const { id, title } = liveRoomRecording
    const result = await editLiveRoomRecording({
      liveRoomRecordingId: id,
      title,
    })

    if (result) {
      popupRecordingToggle()
    }
  }

  const loadEditRecording = info => {
    setLiveRoomRecording(info)
    popupRecordingToggle()
  }

  const loadDelete = (info, isMobile) => {
    if (isMobile) {
      mobileToggle()
      popupToggle()
    }
    else {
      setTargetId(info.id)
      popupToggle()
    }
  }

  const loadMobilePopup = info => {
    setTarget(info)
    setTargetId(info.id)
    mobileToggle()
  }

  const onDelete = () => {
    if (type === UPLOADED_AUDIO_TYPE['AUDIO']) {
      deleteSong(targetId)
    }
    else {
      _deleteLiveRoomRecording(targetId)
    }

    popupToggle()
  }

  const songColumns = [
    {
      key: 'image',
      dataIndex: 'imageUrl',
      width: '40px',
      render: imageUrl => (
        <img
          style={{ width: '40px', height: '40px', background: 'aliceblue' }}
          src={imageUrl}
          alt="img"
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '500px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{record.name}</Caption1>
            <Caption1 color="#949494">{record.singer}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'function',
      dataIndex: 'download',
      width: '148px',
      render: (text, record) => {
        return (
          <ThreeDot>
            <span>
              <Tooltip title="재생">
                <Icon
                  type="play-circle"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      record.mp3Uri
                        ? setNowSong(record)
                        : message.error(
                          '컨버팅 중 입니다. 잠시 후 새로고침 해주세요.',
                        )
                    }
                  }
                />
              </Tooltip>
            </span>
            <span>
              <a href={record.mp3Url} download>
                <Tooltip title="다운로드">
                  <Icon
                    type="download"
                    style={{ fontSize: 25, color: colors.primary }}
                  />
                </Tooltip>
              </a>
            </span>
            <span
              style={{ position: 'relative' }}
              onClick={
                async () => {
                  await fetchSongDetail(record.id)
                  history.push('/p/audio/song')
                }
              }
            >
              {
                record.plamUrlStatus === 1 || record.plamUrlStatus === 5 ? (
                  <CustomTooltip
                    content={
                      <TooltipContent>
                        <Caption1 color="#646464" align="left">
                        검수 완료된 저작물로, 음악 저작권법에 따라 저작자가
                        공표한 정보로 대체됩니다.
                        </Caption1>
                      </TooltipContent>
                    }
                  />
                ) : (
                  <Tooltip title="수정">
                    <Icon
                      type="edit"
                      style={
                        {
                          fontSize: 25,
                          color: colors.primary,
                          cursor: 'pointer',
                        }
                      }
                    />
                  </Tooltip>
                )
              }
            </span>
            <span>
              <Tooltip title="삭제">
                <Icon
                  type="delete"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      loadDelete(record, false)
                    }
                  }
                />
              </Tooltip>
            </span>
            <DotImg
              src={images.three_dot_img}
              alt="dot"
              onClick={() => loadMobilePopup(record)}
            />
          </ThreeDot>
        )
      },
    },
  ]

  const recordingColumns = [
    {
      key: 'image',
      dataIndex: 'imageUrl',
      width: '40px',
      render: (text, record) => {
        if (record?.LiveRoom?.imageUrl) {
          return (
            <img
              style={{ width: '40px', height: '40px', background: 'aliceblue' }}
              src={record?.LiveRoom?.imageUrl}
              alt="img"
            />
          )
        }
        return <></>
      },
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '400px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{record?.title}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'date',
      dataIndex: 'date',
      width: '100px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{dateMDMS(record?.createdAt)}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'function',
      dataIndex: 'download',
      width: '148px',
      render: (text, record) => {
        return (
          <ThreeDot>
            {
              //   <span>
              //   <Tooltip title="재생">
              //     <Icon
              //       type="play-circle"
              //       style={{ fontSize: 25, color: colors.primary }}
              //       onClick={
              //         e => {
              //           const song = {
              //             mp3Uri: record.mp4Uri,
              //             name: record.title,
              //             singer: '',
              //             imageUri: record.LiveRoom?.imageUri,
              //           }
              //           setNowSong(song)
              //         }
              //       }
              //     />
              //   </Tooltip>
              // </span>
            }
            <span>
              <a href={record.mp4Url} download>
                <Tooltip title="다운로드">
                  <Icon
                    type="download"
                    style={{ fontSize: 25, color: colors.primary }}
                  />
                </Tooltip>
              </a>
            </span>
            <span
              style={{ position: 'relative' }}
              onClick={
                async () => {
                // await fetchSongDetail(record.id)
                // history.push('/p/audio/song')
                }
              }
            >
              <Tooltip title="수정">
                <Icon
                  type="edit"
                  style={
                    {
                      fontSize: 25,
                      color: colors.primary,
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    e => {
                      loadEditRecording(record)
                    }
                  }
                />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="삭제">
                <Icon
                  type="delete"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      loadDelete(record, false)
                    }
                  }
                />
              </Tooltip>
            </span>
            <DotImg
              src={images.three_dot_img}
              alt="dot"
              onClick={() => loadMobilePopup(record)}
            />
          </ThreeDot>
        )
      },
    },
  ]

  // useEffect(() => {
  //   if (currentPage > 1) {
  //     fetchMyUploadSongList(10 * (currentPage - 1))
  //   }
  //   else if (currentPage < 1) {
  //     fetchMyUploadSongList()
  //   }
  // }, [fetchMyUploadSongList, currentPage])

  return (
    <>
      <DeletePopup.View
        isShowing={showPopup}
        toggle={popupToggle}
        onDelete={onDelete}
        body={'삭제 시 저장된 오디오의\n해당 데이터가 모두 삭제됩니다.'}
      />
      <EditRecordingPopup.View
        isShowing={showRecordingPopup}
        toggle={popupRecordingToggle}
        onEdit={_editLiveRoomRecording}
        body={
          <FormInput
            style={{ backgroundColor: '#f4f4f4', borderWidth: 0 }}
            value={liveRoomRecording.title}
            onChange={
              event => {
                const _liveRoomRecording = {
                  ...liveRoomRecording,
                  title: event.target.value,
                }

                setLiveRoomRecording(_liveRoomRecording)
              }
            }
          />
        }
      />
      <MobileOptionPopup.View
        isShowing={showMobile}
        toggle={mobileToggle}
        info={target}
        onDelete={() => loadDelete(false, true)}
        setNowSong={setNowSong}
        mp3Url={target.mp3Url}
        fetchSongDetail={fetchSongDetail}
      />
      <PageLayout
        // style={{ height: '100vh' }}
        footerStyle={{ marginBottom: '80px' }}
      >
        <ListPlayer
          nowSong={nowSong}
          _stopPlayer={_stopPlayer}
          stopPlayer={stopPlayer}
          currentPlayMixtape={currentPlayMixtape}
        />
        <ListBox>
          <Flex
            style={
              {
                marginBottom: '40px',
                width: '100%',
                justifyContent: 'center',
                position: 'relative',
              }
            }
          >
            <H4>
              {user && user.name}
              님의
              {' '}
              <span style={{ color: colors.primary }}>{songsCount}</span>
              개의 업로드 오디오
            </H4>
            <UploadBtn to="/p/audio/upload">오디오 업로드</UploadBtn>
          </Flex>
          <Flex style={{ marginBottom: 24, width: '100%' }}>
            <H6
              onClick={() => setType(UPLOADED_AUDIO_TYPE['AUDIO'])}
              type="Bold"
              style={
                {
                  cursor: 'pointer',
                  marginRight: 28,
                  ...(type === UPLOADED_AUDIO_TYPE['AUDIO'] && {
                    borderBottom: `2px solid ${colors.black_24}`,
                  }),
                }
              }
              color={type !== UPLOADED_AUDIO_TYPE['AUDIO'] && colors.grey_bf}
            >
              오디오
            </H6>
            <H6
              onClick={() => setType(UPLOADED_AUDIO_TYPE['RECORDING'])}
              type="Bold"
              style={
                {
                  cursor: 'pointer',
                  ...(type === UPLOADED_AUDIO_TYPE['RECORDING'] && {
                    borderBottom: `2px solid ${colors.black_24}`,
                  }),
                }
              }
              color={
                type !== UPLOADED_AUDIO_TYPE['RECORDING'] && colors.grey_bf
              }
            >
              녹음
            </H6>
          </Flex>
          {
            type === UPLOADED_AUDIO_TYPE['AUDIO'] && songs.length === 0 ? (
              <>
                <SubTitle>흐름에 오신 걸 환영합니다!</SubTitle>
                <SubTitle>멋진 플라가 될 오디오를 업로드해주세요!</SubTitle>
              </>
            ) : (
              <>
                <Table
                  columns={
                    type === UPLOADED_AUDIO_TYPE['AUDIO']
                      ? songColumns
                      : recordingColumns
                  }
                  dataSource={
                    type === UPLOADED_AUDIO_TYPE['AUDIO']
                      ? songs
                      : liveRoomRecordingList.reverse()
                  }
                  // pagination={{ pageSize: 10, defaultPageSize: 10 }}
                  pagination={false}
                  pageSize={10}
                  size={'small'}
                  loading={
                    {
                      spinning: isLoading,
                      indicator: <Spin size="large" />,
                    }
                  }
                  style={
                    {
                      backgroundColor: 'white',
                      borderRadius: '16px',
                      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
                      width: '100%',
                    }
                  }
                  rowKey="id"
                />
                <Pagination
                  style={{ marginTop: '20px' }}
                  pageSize={10}
                  current={currentPage}
                  onChange={setCurrentPage}
                  total={songsCount}
                  showSizeChanger={false}
                  showQuickJumper={false}
                />
              </>
            )
          }
        </ListBox>
      </PageLayout>
    </>
  )
}

export default AudioListTemplate

const UploadBtn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;
  cursor: pointer;

  border-radius: 20px;
  border: solid 1px #594ee9;
  background-color: #594ee9;

  color: #ffffff;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;

  position: absolute;
  right: 0;
`
