import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H4, H6, Caption1, Flex } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  min-width: 320px;
  min-height: 210px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  color: #242424;
`

const Okbtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: ${colors.warning};
  color: #ffffff;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  onOk,
  userId,
  reason,
  setReason,
  type,
}) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (onOk) {
      onOk(userId, reason)
    }
    toggle()
  }

  React.useEffect(() => {
    if (!isShowing) setReason(0)
  }, [isShowing, setReason])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold" style={{ margin: '12px 0px 20px 0px' }}>
                {
                  type === 'user'
                    ? '유저 신고'
                    : type === 'comment'
                      ? '댓글 신고'
                      : '살롱 신고'
                }
              </H4>
              <Flex type="column" style={{ marginLeft: 12 }}>
                <Flex
                  style={{ marginBottom: 24, cursor: 'pointer' }}
                  onClick={() => setReason(1)}
                >
                  <img
                    src={
                      reason === 1
                        ? images.check_blue_img
                        : images.check_empty_img
                    }
                    alt="check_1"
                    style={{ width: 24, height: 24, marginRight: 12 }}
                  />
                  <Caption1>
                    {
                      type === 'user'
                        ? '부적절한 채팅 내용'
                        : type === 'comment'
                          ? '부적절한 댓글 내용'
                          : '부적절한 살롱 주제'
                    }
                  </Caption1>
                </Flex>
                <Flex
                  style={{ cursor: 'pointer' }}
                  onClick={() => setReason(2)}
                >
                  <img
                    src={
                      reason === 2
                        ? images.check_blue_img
                        : images.check_empty_img
                    }
                    alt="check_2"
                    style={{ width: 24, height: 24, marginRight: 12 }}
                  />

                  <Caption1>커뮤니티 가이드라인 위반</Caption1>
                </Flex>
              </Flex>
              <FlexRowBox
                style={{ justifyContent: 'space-between', marginTop: '30px' }}
              >
                <CancelBtn onClick={handleCancel}>
                  <H6>취소</H6>
                </CancelBtn>
                <Okbtn onClick={handleOk}>
                  <H6>신고</H6>
                </Okbtn>
              </FlexRowBox>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
