import React, { useEffect, useState } from 'react'
import { RevenueInfoTemplate } from '@components/templates'
import {
  DjAccountModal,
  ConfirmExchangeModal,
  StopExchangeModal,
} from '@components/organisms'
import { observer } from 'mobx-react'

import { Redirect } from 'react-router-dom'
import { useRenderLogger, useStore } from '@utils/hooks'

interface Props {
  location: Any;
}

const RevenueInfoPage = ({ location }: Props) => {
  useRenderLogger('RevenueInfoPage')
  const { authStore, revenueStore } = useStore()

  const currentUser = authStore.currentUser || null

  const currentBankAccount = revenueStore.currentBankAccount || null
  const revenueNotifications = revenueStore.revenueNotifications || null
  const revenueHistories = revenueStore.revenueHistories || null
  const fetchRevenue = revenueStore.fetchRevenue || (() => {})
  const fetchRevenueHistories = revenueStore.fetchRevenueHistories || (() => {})
  const getBankAccount = revenueStore.getBankAccount || (() => {})
  const agreePartnerDJTerms = revenueStore.agreePartnerDJTerms || (() => {})
  const readedRevenueNotifications =
    revenueStore.readedRevenueNotifications || (() => {})
  const fetchRevenueNotifications =
    revenueStore.fetchRevenueNotifications || (() => {})

  const [isAllow, setIsAllow] = useState(true)

  const {
    isShowing: showConfirm,
    toggle: confirmToggle,
  } = ConfirmExchangeModal.useModal()

  const {
    isShowing: stopShowing,
    toggle: stopToggle,
  } = DjAccountModal.useModal()
  const { isShowing, toggle } = DjAccountModal.useModal()

  useEffect(() => {
    if (!location.state) {
      // 비밀번호 확인 페이지 검증
      setIsAllow(false)
    }
    if (currentUser && currentUser.revenueLevel === 1004) {
      // 환전 신청 중..
      confirmToggle()
    }
    else if (currentUser && currentUser.revenueLevel === 3001) {
      // 포인트 환전 중지 상태
      stopToggle()
    }

    fetchRevenue()
    fetchRevenueHistories()
    // 계좌 조회
    getBankAccount()
    // 최근 활동 갱신
    fetchRevenueNotifications()
    return () => {
      if (sessionStorage.getItem('jwt_token')) {
        // isReaded unmount시, 호출
        readedRevenueNotifications(currentUser.id)
      }
    }
    // eslint-disable-next-line
  }, [fetchRevenue, getBankAccount, fetchRevenueNotifications, location])

  return (
    <>
      {!isAllow && <Redirect to="/p/exchange/confirm-password" />}
      <StopExchangeModal.View isShowing={stopShowing} toggle={stopToggle} />
      <DjAccountModal.View
        isShowing={isShowing}
        toggle={toggle}
        agreePartnerDJTerms={agreePartnerDJTerms}
      />
      <ConfirmExchangeModal.View
        isShowing={showConfirm}
        toggle={confirmToggle}
        level={currentUser && currentUser.revenueLevel}
      />
      <RevenueInfoTemplate
        currentUser={currentUser}
        agreePartnerDJTerms={agreePartnerDJTerms}
        currentBankAccount={currentBankAccount}
        revenueNotifications={revenueNotifications}
        accountToggle={toggle}
        confirmToggle={confirmToggle}
        stopToggle={stopToggle}
        revenueHistories={revenueHistories}
      />
    </>
  )
}

export default observer(RevenueInfoPage)
