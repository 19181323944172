import React, { useState } from 'react'
import {
  Form,
  FormInput,
  FormLabel,
  Caption2,
  Button,
  WarningMsg,
  InvisibleInput,
} from '@components/atoms'
import styled from 'styled-components'
import { FlexRowBox, SubmitBtn, BankBox } from '@components/molecules'
import { Controller, useForm } from 'react-hook-form'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'
import { ChangeConfirmModal } from '..'

const FormGroup = styled.div`
  margin: 10px 0px;
  width: 100%;
`

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const SettingWayForm = ({
  postBankAccount,
  getCheckPhone,
  getCheckEmail,
  time,
  timer,
  code,
  setCode,
  currentUser,
  smsCheck,
  setComplete,
  checkPassword,
  isResetting,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    setError,
    control,
  } = useForm({ mode: 'onBlur' })

  const [confirmPhone, setConfirmPhone] = useState('') // 휴대폰 번호 중복 체크
  const [sendConfirm, setSendConfirm] = useState(false) // 인증번호 전송완료 여부
  const [correct, setCorrect] = useState(false) // 인증번호 일치 여부
  const [pwError, setPwError] = useState(false) // 비밀번호 일치 여부
  const [accountError, setAccountError] = useState('none') // 계좌번호 일치 여부

  const { isShowing, toggle } = ChangeConfirmModal.useModal()

  const confirmAccountNumber = value => {
    if (value !== '' && getValues().accountNumber !== '') {
      setAccountError(false)
      if (value !== getValues().accountNumber) {
        setAccountError('error')
      }
      else {
        setAccountError('pass')
      }
    }
  }

  const authPassword = async password => {
    if (password !== '') {
      const res = await checkPassword(password)
      if (res.status === 401) {
        setPwError(res.response)
      }
      else {
        setPwError('NoError')
      }
    }
  }

  // 회원가입
  const onSubmit = async data => {
    const params = [
      {
        bankName: data.bankName,
        accountHolder: data.accountHolder,
        accountNumber: data.accountNumber,
      },
    ]

    // vallidation 입력 체크
    if (!pwError || pwError !== 'NoError') {
      setPwError('비밀번호 인증을 해주세요.')
      return false
    }
    if (accountError === 'none' || accountError === 'error') {
      setAccountError('error')
      return false
    }
    if (confirmPhone !== 'Y') {
      return false
    }
    if (correct !== 'Y') {
      setError([{ type: 'required', name: 'phone' }])
      return false
    }

    const res = await postBankAccount(params)
    if (res) {
      if (isResetting) {
        toggle()
      }
      else {
        setComplete(true)
      }
    }
    else {
    }
  }

  // 핸드폰 인증
  const _smsCheck = async e => {
    e.preventDefault()

    const result = await smsCheck(getValues().phone, code)
    if (result && result.status === 401) {
      setCorrect('N')
    }
    else {
      setCorrect('Y')
    }
  }

  const verifyPhone = async () => {
    if (currentUser.phone !== getValues().phone) {
      setConfirmPhone('N')
    }
    else {
      setConfirmPhone('Y')
    }
  }

  return (
    <>
      {
        isResetting && (
          <ChangeConfirmModal.View
            isShowing={isShowing}
            toggle={toggle}
            handleConfirm={() => setComplete(true)}
          />
        )
      }
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel color="#646464">은행 선택</FormLabel>
          <Controller
            as={<BankBox />}
            id="bankName"
            name="bankName"
            placeholder="은행 선택"
            control={control}
            onChange={([selected]) => selected.value}
            defaultValue={null}
            rules={{ required: true }}
            options={
              [
                {
                  value: '카카오뱅크',
                  label: '카카오뱅크',
                  icon: 'kakao',
                },
                {
                  value: 'IBK기업은행',
                  label: 'IBK기업은행',
                  icon: 'ibk',
                },
                {
                  value: 'KDB산업은행',
                  label: 'KDB산업은행',
                  icon: 'kdb',
                },
                {
                  value: 'NH농협은행',
                  label: 'NH농협은행',
                  icon: 'nh',
                },
                {
                  value: '신한은행',
                  label: '신한은행',
                  icon: 'shinhan',
                },
                {
                  value: '우리은행',
                  label: '우리은행',
                  icon: 'woori',
                },
                {
                  value: 'SC제일은행',
                  label: 'SC제일은행',
                  icon: 'sc',
                },
                {
                  value: 'KEB하나은행',
                  label: 'KEB하나은행',
                  icon: 'hana',
                },
                {
                  value: 'KB국민은행',
                  label: 'KB국민은행',
                  icon: 'kb',
                },
                {
                  value: '씨티은행',
                  label: '씨티은행',
                  icon: 'citi',
                },
                {
                  value: '한국수출입은행',
                  label: '한국수출입은행',
                  icon: 'korea',
                },
                {
                  value: '수협은행',
                  label: '수협은행',
                  icon: 'suhyup',
                },
                {
                  value: 'DGB대구은행',
                  label: 'DGB대구은행',
                  icon: 'dgb',
                },
                {
                  value: 'BNK부산은행',
                  label: 'BNK부산은행',
                  icon: 'bnk',
                },
                {
                  value: '광주은행',
                  label: '광주은행',
                  icon: 'kwangju',
                },
                {
                  value: '제주은행',
                  label: '제주은행',
                  icon: 'shinhan',
                },
                {
                  value: '전북은행',
                  label: '전북은행',
                  icon: 'kwangju',
                },
                {
                  value: 'BNK경남은행',
                  label: 'BNK경남은행',
                  icon: 'bnk',
                },
                {
                  value: '케이뱅크',
                  label: '케이뱅크',
                  icon: 'k',
                },
              ]
            }
          />
          {
            errors && errors.bankName && errors.bankName.type === 'required' && (
              <WarningMsg>은행을 선택해주세요.</WarningMsg>
            )
          }
        </FormGroup>
        <FormGroup>
          <FlexRowBox style={{ alignItems: 'baseline' }}>
            <FormLabel color="#646464">예금주 이름 입력</FormLabel>
            <Caption2 color="#949494">
              &nbsp;* 이름이 계좌 정보와 다를 경우 환전이 중지될 수 있습니다.
            </Caption2>
          </FlexRowBox>
          <FormInput
            placeholder="예금주 이름 입력"
            name="accountHolder"
            ref={
              register({
                required: true,
                pattern: /^[가-힣|a-z|A-Z]+$/,
              })
            }
          />
          {
            errors
            && errors.accountHolder
            && errors.accountHolder.type === 'required' && (
              <WarningMsg>예금주를 입력해주세요.</WarningMsg>
            )
          }
          {
            errors
            && errors.accountHolder
            && errors.accountHolder.type === 'pattern' && (
              <WarningMsg>올바른 이름을 입력해주세요.</WarningMsg>
            )
          }
        </FormGroup>
        <FormGroup>
          <FormLabel color="#646464">계좌 번호 입력</FormLabel>
          <FormInput
            placeholder="- 없이 입력 (122333444455)"
            name="accountNumber"
            autoComplete={'off'}
            ref={
              register({
                required: true,
                validate: value =>
                  setValue('accountNumber', value.replace(/-/g, '')),
              })
            }
          />
          <FormInput
            style={{ marginTop: '16px' }}
            placeholder="- 없이 계좌번호 재입력"
            autoComplete={'off'}
            onBlur={e => confirmAccountNumber(e.target.value)}
          />
          {
            accountError === 'error' && (
              <WarningMsg>두 계좌번호가 일치하지 않습니다.</WarningMsg>
            )
          }
          {
            errors
            && errors.accountNumber
            && errors.accountNumber.type === 'required' && (
              <WarningMsg>계좌번호를 입력해주세요.</WarningMsg>
            )
          }
        </FormGroup>
        <FormGroup>
          <FormLabel color="#646464">계정 비밀번호 입력</FormLabel>
          <InvisibleInput />
          <FormInput
            placeholder="비밀번호 입력"
            type="password"
            autoComplete={'off'}
            onBlur={e => authPassword(e.target.value)}
            name="password"
            ref={register({ required: true })}
          />
          {
            pwError && pwError !== 'NoError' && (
              <WarningMsg>{pwError}</WarningMsg>
            )
          }
          {
            errors && errors.password && errors.password.type === 'required' && (
              <WarningMsg>비밀번호 인증을 해주세요.</WarningMsg>
            )
          }
          {/* 휴대폰 번호 */}
          <InputBox style={{ display: 'inherit' }}>
            <FormLabel color="#646464">등록한 휴대폰 번호 인증</FormLabel>
            <FlexRowBox>
              <FormInput
                name="phone"
                ref={
                  register({
                    required: true,
                    pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                  })
                }
                placeholder="ex. 01005260221 ( - 없이 )"
                disabled={sendConfirm}
                style={
                  confirmPhone === 'N'
                  || (errors.phone && {
                    border: '1px solid #ea4653',
                  })
                }
                onChange={() => verifyPhone()}
              />
              {
                !errors.phone && confirmPhone === 'Y' ? (
                  sendConfirm ? (
                    <Button
                      disabled
                      style={
                        {
                          color: '#66bc6a',
                          padding: '0px 20px 0px 40px',
                          whiteSpace: 'pre',
                        }
                      }
                    >
                    전송완료
                    </Button>
                  ) : (
                    <Button
                      onClick={
                        e => {
                          time(e, getValues().phone)
                          setSendConfirm(true)
                        }
                      }
                      style={
                        {
                          color: colors.secondary,
                          padding: '0px 19px',
                          whiteSpace: 'pre',
                        }
                      }
                    >
                    인증번호 전송
                    </Button>
                  )
                ) : (
                  <Button
                    style={
                      {
                        color: 'rgba(148, 148, 148, 0.6)',
                        padding: '0px 19px',
                        whiteSpace: 'pre',
                      }
                    }
                    disabled
                  >
                  인증번호 전송
                  </Button>
                )
              }
            </FlexRowBox>
            {
              errors.phone && errors.phone.type === 'pattern' && (
                <WarningMsg>올바르지 않은 휴대폰 번호입니다.</WarningMsg>
              )
            }
            {
              !errors.phone && confirmPhone === 'N' && (
                <WarningMsg>등록된 번호와 일치하지 않습니다.</WarningMsg>
              )
            }
            {
              errors.phone && errors.phone.type === 'required' && (
                <WarningMsg>휴대폰 인증을 해주세요.</WarningMsg>
              )
            }
          </InputBox>

          {/* 인증번호 입력 박스 */}
          {
            sendConfirm ? (
              <>
                <InputBox>
                  <FlexRowBox>
                    <FlexRowBox
                      style={
                        {
                          position: 'relative',
                          width: '100%',
                        }
                      }
                    >
                      <FormInput
                        style={{ width: '100%', height: 'fit-content' }}
                        placeholder="인증번호 6자리 입력"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        disabled={correct === 'Y'}
                      />
                      {
                        correct !== 'Y' && (
                          <span
                            style={
                              {
                                color: '#646464',
                                position: 'absolute',
                                right: '5%',
                                zIndex: '10',
                                paddingTop: '4px',
                              }
                            }
                          >
                            {timer}
                          </span>
                        )
                      }
                    </FlexRowBox>
                    {
                      correct === 'Y' ? (
                        <Button
                          style={
                            {
                              color: '#66bc6a',
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                          disabled
                        >
                      인증완료
                        </Button>
                      ) : code !== '' && timer !== '00:00' ? (
                        <Button
                          onClick={e => _smsCheck(e)}
                          style={
                            {
                              color: colors.secondary,
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                        >
                      인증하기
                        </Button>
                      ) : (
                        <Button
                          style={
                            {
                              color: '#949494',
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                          disabled
                        >
                      인증하기
                        </Button>
                      )
                    }
                  </FlexRowBox>
                  <FlexRowBox>
                    <FlexRowBox
                      style={
                        {
                          width: '750px',
                        }
                      }
                    >
                      {
                        correct === 'N' ? (
                          <WarningMsg>인증번호가 올바르지 않습니다.</WarningMsg>
                        ) : timer === '00:00' ? (
                          <WarningMsg>시간이 초과되었습니다.</WarningMsg>
                        ) : (
                          <WarningMsg />
                        )
                      }
                    </FlexRowBox>
                    {
                      correct !== 'Y' && (
                        <WarningMsg
                          onClick={
                            e => {
                              time(e, getValues().phone)
                              setSendConfirm(true)
                            }
                          }
                          style={
                            {
                              padding: 0,
                              color: '#949494',
                              cursor: 'pointer',
                              marginRight: 'initial',
                              width: '100%',
                            }
                          }
                        >
                      인증번호 재전송
                        </WarningMsg>
                      )
                    }
                  </FlexRowBox>
                </InputBox>
              </>
            ) : (
              false
            )
          }
        </FormGroup>

        {
          isResetting ? (
            <SubmitBtn style={{ marginTop: '50px' }} type="submit">
            변경 요청
            </SubmitBtn>
          ) : (
            <SubmitBtn style={{ marginTop: '50px' }} type="submit">
            완료
            </SubmitBtn>
          )
        }
      </Form>
    </>
  )
}

export default SettingWayForm
