import { Caption1, Flex, H6 } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { handleSongImgError } from '@utils/handler'
import { secondToTimeString } from '@utils/time'
import { useObserver } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import { EqualizerBox } from '..'

const View = styled(Flex)`
  max-width: 652px;
  width: 100%;
  height: 88px;
  border-radius: 16px;
  padding: 8px 16px 8px 8px;
  position: relative;

  background-color: ${props =>
    props.isPlaying ? colors.white_f4 : colors.white_ff};
`

const TrackImage = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  border: solid 1px ${colors.grey_d4};
  background-color: rgba(36, 36, 36, 0.5);
`

const OpacityImage = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  background-color: black;
  opacity: 0.3;
`

const InfoBox = styled(Flex)`
  max-width: 390px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 46px 0 16px;
`

const LikeBtn = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 32px;
`

const MixtapeDetailPlayListItem = ({
  isPlaying,
  item,
  likeSong,
  disLikeSong,
}) => {
  const _likeSong = () => {
    if (item && item.Song && item.Song.isLiked) {
      disLikeSong(item.Song)
    }
    else {
      likeSong(item.Song)
    }
  }

  return useObserver(() => (
    <View isPlaying={isPlaying}>
      <TrackImage
        src={
          item && item.Song.imageUrl
            ? item.Song.imageUrl
            : images.default_song_img
        }
        alt="track_img"
        onError={e => handleSongImgError(e)}
      />
      {
        isPlaying && (
          <>
            <OpacityImage />
            <EqualizerBox style={{ top: '38px', left: '34px' }} />
          </>
        )
      }
      <InfoBox>
        <H6 align="left" style={{ marginBottom: '6px' }}>
          {item && item.Song.name}
        </H6>
        <Caption1 type="Regular" align="left" color={colors.brown_grey}>
          {item && item.Song.singer}
        </Caption1>
      </InfoBox>
      <Flex
        style={
          {
            alignItems: 'center',
            minWidth: '158px',
            justifyContent: 'flex-end',
          }
        }
      >
        <H6 type="Regular" color={colors.brown_grey}>
          {item && secondToTimeString(item.Song.duration)}
        </H6>
        <LikeBtn
          src={
            item.Song.isLiked ? images.like_red_img : images.like_default_img
          }
          alt="like_img"
          onClick={_likeSong}
        />
      </Flex>
    </View>
  ))
}

export default MixtapeDetailPlayListItem
