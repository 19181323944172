import React from 'react'

/**
 * defaultHeight: 기본 태그의 높이값
 * id : 태그의 id
 * item : 태그 안의 동적인 item
 * @return
 *  haveMore : defaultHeight을 넘는 지,
 *  handleShowMore : showMore toggle 함수
 *  showMore : 더보기 유무
 */
export default ({ defaultHeight, id, item }) => {
  item = item || []

  const [haveMore, setHaveMore] = React.useState(false) // 넘치는 지 여부
  const [showMore, setShowMore] = React.useState(false) // 더보기 유무

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  // 박스의 높이를 구해서 넘치는(더보기) 유무를 계산
  React.useLayoutEffect(() => {
    if (item.length && id) {
      const boxHeight = document.getElementById(id).clientHeight

      if (boxHeight > defaultHeight) {
        setHaveMore(true)
      }
      else {
        setHaveMore(false)
      }
    }

    return () => {
      setHaveMore(false)
      setShowMore(false)
    }
  }, [item, id, defaultHeight, item.length])

  return { haveMore, handleShowMore, showMore }
}
