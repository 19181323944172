import styled from 'styled-components'
import { desktop, tablet, mobile } from '@styles/media'

const NewLine = styled.br`
  display: none;

  @media ${desktop} {
    display: ${(props) => (props.isDesktop ? 'block' : 'none')};
  }

  @media ${tablet} {
    display: ${(props) => (props.isTablet ? 'block' : 'none')};
  }

  @media ${mobile} {
    display: ${(props) => (props.isMobile ? 'block' : 'none')};
  }
`

export default NewLine
