import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H4, H6 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FlexRowBox } from '@components/molecules'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9070;
  background: white;
  border-radius: 16px;
  width: 320px;
  max-height: 560px;
  overflow: hidden;
  /* height: 210px; */
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  color: #242424;
  cursor: pointer;
`

const ChangeBtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #5b49f3;
  color: #ffffff;
  cursor: pointer;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onClose,
  onChange,
  title = '정말로 변경하시겠습니까?',
  body,
  onChangeText = '다음',
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold" style={{ margin: '12px 0px 20px 0px' }}>
                {title}
              </H4>
              {body}
              <FlexRowBox
                style={{ justifyContent: 'space-between', marginTop: '16px' }}
              >
                <CancelBtn onClick={handleClose}>
                  <H6>취소</H6>
                </CancelBtn>
                <ChangeBtn onClick={onChange}>
                  <H6>{onChangeText}</H6>
                </ChangeBtn>
              </FlexRowBox>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}
