import React from 'react'
import { SettingWayForm } from '@components/organisms'
import { H1, H6 } from '@components/atoms'
import { PageLayout } from '..'

const SettingWayTemplate = ({
  postBankAccount,
  getCheckPhone,
  getCheckEmail,
  time,
  timer,
  code,
  setCode,
  smsCheck,
  setComplete,
  checkPassword,
  currentUser,
  isResetting,
}) => {
  return (
    <>
      {
        isResetting ? (
          <PageLayout>
            <H1>환전 수단 변경</H1>
            <H6 style={{ margin: '20px 0 40px 0' }}>
            환전 포인트가 지급될 수단을 변경 합니다.
              <br />
            제출된 변경 정보는 서비스팀에서 검토 후 승인 혹은 반려 될 수
            있습니다.
              <br />
            예금주 이름은 ‘성인인증(보호자 동의서 제출’의 이름과 동일해야
            합니다.
            </H6>
            <SettingWayForm
              isResetting={isResetting}
              postBankAccount={postBankAccount}
              code={code}
              getCheckPhone={getCheckPhone}
              setCode={setCode}
              time={(e, phone) => time(e, phone)}
              timer={timer}
              smsCheck={smsCheck}
              setComplete={setComplete}
              currentUser={currentUser}
              checkPassword={checkPassword}
            />
          </PageLayout>
        ) : (
          <PageLayout>
            <H1>환전 수단 설정</H1>
            <H6 style={{ margin: '20px 0 40px 0' }}>
            환전 포인트가 지급받을 수단을 설정 합니다.
              <br />
            수단 변경 시 오랜 시간이 소요될 수 있으므로 신중히 입력해 주십시오.
            </H6>
            <SettingWayForm
              isResetting={isResetting}
              postBankAccount={postBankAccount}
              code={code}
              getCheckPhone={getCheckPhone}
              setCode={setCode}
              time={(e, phone) => time(e, phone)}
              timer={timer}
              smsCheck={smsCheck}
              setComplete={setComplete}
              currentUser={currentUser}
              checkPassword={checkPassword}
            />
          </PageLayout>
        )
      }
    </>
  )
}

export default SettingWayTemplate
