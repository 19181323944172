import { observable } from 'mobx'

import { UserModel, SongModel } from '.'

export default class UsersSingSongsModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable userId
  @observable songId

  @observable User
  @observable Song

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.userId = props.userId
      this.songId = props.songId

      this.User = props.User && new UserModel(stores, props.User)
      this.Song = props.Song && new SongModel(stores, props.Song)
    }
  }
}
