import React from 'react'
import { UploadForm } from '@components/organisms'
import { PageLayout } from '..'

const UploadTemplate = props => {
  const {
    user,
    uploadSong,
    _getYoutubeLink,
    createTagList,
    setIsUploaded,
    getTag,
    uploadSongAudio,
    uploadSongImage,
  } = props

  return (
    <PageLayout>
      <UploadForm
        user={user}
        uploadSong={uploadSong}
        _getYoutubeLink={_getYoutubeLink}
        createTagList={createTagList}
        setIsUploaded={setIsUploaded}
        getTag={getTag}
        uploadSongAudio={uploadSongAudio}
        uploadSongImage={uploadSongImage}
      />
    </PageLayout>
  )
}

export default UploadTemplate
