import React, { useCallback, useEffect, useRef, useState } from 'react'
// import ReactPlayer from 'react-player/lazy'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled, { css, keyframes } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import screenfull from 'screenfull'

import { images } from '@resources/'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { secondToTimeString } from '@utils/time'

import { oneEllipsisStyle } from '@styles/fontStyles'
import { Caption1, Caption2, Div, Flex, H3, H4, H6 } from '@components/atoms'
import { EqualizerBox, Spinner } from '@components/molecules'
import { handleSongImgError } from '@utils/handler'
import { IS_DEV } from '@consts/'
import { message } from 'antd'
import { findDOMNode } from 'react-dom'
import { isMobile } from 'react-device-detect'

import { DndProvider, useDrop, useDrag } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { useRenderLogger, useStore } from '@utils/hooks'
import PlayerManager from '@utils/PlayerManager'

/**
 * todo
 * Song 오브젝트 서버와 duration 다른 것들 prefetch 만 해놓고 서버와 차이만큼 딜레이 후에 실행하게
 */
const PlayerLogger = (title, content) => {
  if (!IS_DEV) return
  const css = 'color:#594ee9; font-size:15px;'
  const style = [
    'font-size: 18px;',
    'padding:0px;',
    `background: url(${images.live_img}) no-repeat;`,
    'background-size: contain;',
  ].join(' ')

  if (content) {
    return console.log('%c   %c[HREUM PLAYER]', style, css, title, ':', content)
  }
  return console.log('%c   %c[HREUM PLAYER]', style, css, title)
}

const CurrentControllListItem = ({
  index,
  mixtape,
  handleDetailRoute,
  moveList,
  id,
  updateCurrentControllList,
}) => {
  const handleDnd = async (d, h) => {
    await moveList(d, h)
    // replaceMixtapeSongListToTempMixtape(tempMixtape, songs)
  }

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      handleDnd(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        // replaceMixtapeSongListToTempMixtape(tempMixtape, songs)
        updateCurrentControllList()
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Flex ref={ref} style={{ opacity }}>
      <MixtapeBox
        onClick={() => handleDetailRoute(mixtape)}
        style={{ position: 'relative' }}
      >
        <MixtapeCover
          src={
            mixtape && mixtape.imageUrl
              ? mixtape.imageUrl
              : images.default_song_img
          }
          onError={e => handleSongImgError(e)}
        />
        <MixtapeInfoBox style={{ maxWidth: '100%' }}>
          <H6 align="left" color={colors.white_ff} style={oneEllipsisStyle}>
            {mixtape && mixtape.name}
          </H6>
          <Caption1
            align="left"
            type="Regular"
            color={colors.grey_d4}
            style={oneEllipsisStyle}
          >
            {mixtape && mixtape.User && mixtape.User.name}
          </Caption1>
        </MixtapeInfoBox>
        <img
          src={images.dnd_img_white}
          style={
            {
              width: '30px',
              height: '30px',
              marginRight: '20px',
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
            }
          }
          alt="dnd_img"
        />
      </MixtapeBox>
    </Flex>
  )
}

/**
 * title : 플라 플레이어
 * createdAt : 2020. 09. 25
 * @author LEEHAEHUN
 */
const HreumPlayer = () => {
  const { playerStore, authStore, liveRoomStore } = useStore()
  useRenderLogger('HreumPlayer')

  /* PLAYER STATE */

  /* 고정 값 관리 */

  // const [controls, setControls] = useState(false)
  // const [light, setLight] = useState(false)
  // const [loop, setLoop] = useState(false)
  // const [playbackRate, setPlaybackRate] = useState(1.0) // 재생 속도 1

  /** ********** */

  const playerRef = useRef() // player element

  // const [pip, setPip] = useState(false) // pip모드
  // const [url, setUrl] = useState(null) // ==> Store로 관리
  // const [muted, setMuted] = useState(true) // ==> Store로 관리
  // const [volume, setVolume] = useState(0.3) // 볼륨 ( 기본값 30%) ==> Store로 관리
  // const [playing, setPlaying] = useState(true) // 재생 여부 (  url 있을 시, true로 자동 재생 )
  const { muted, volume, fromSalon, playing } = playerStore
  const setMuted = playerStore.setMuted || (() => {})
  const setVolume = playerStore.setVolume || (() => {})
  const setPlaying = playerStore.setPlaying || (() => {})
  /* PLAYER STATE END */

  const [started, setStarted] = useState(false) // 재생된 지
  const [duration, setDuration] = useState(0) // 곡 시간
  const [playedSeconds, setPlayedSeconds] = useState(0) // 재생 시간(초)
  const [syncedTime, setSyncedTime] = useState(false) // 싱크 확인 중인 시간(초)
  const [prevVolume, setPrevVolume] = useState(0) // 새로고침 전 볼륨
  const [buffering, setBuffering] = useState(false) // 버퍼링 상태

  const [isMore, setIsMore] = useState(false) // 플레이어 풀모드 여부
  const [currentMore, setCurrentMore] = useState(false) // 현재 플라의 펼치기 여부
  const [isFullscreen, setIsFullScreen] = useState(false) // 풀스크린 여부

  const history = useHistory()
  // not yet use
  // const [loaded, setLoaded] = useState(0)
  // const [played, setPlayed] = useState(0)
  // const [loadedSeconds, setLoadedSeconds] = useState(0)

  // 재생 스타트
  const handleStart = async () => {
    PlayerLogger('onStart')
    /**
     * Chrom Autoplay Policy 이슈 대응
     * 2021. 05. 07
     * muted default에서 플레이 함수 호출 시, muted = false 후 playinga 처리
     * 실험 중...
     */
    if (!started) {
      await setMuted(true)
      setTimeout(async () => {
        await setMuted(false)
        prevVolume && setVolume(prevVolume)
        setPrevVolume(0)
      }, 300)
    }
    setStarted(true)
  }

  // 재생 시작
  const handlePlay = async e => {
    PlayerLogger('onPlay')
    await playerStore.updateInteractPlay(true)
    // 2020 12. 18 재생 중 sync 로직 제거
    // await playerStore.reFetchPlayMixtape()

    // 2021. 03. 24
    // 재생 시, 서버와의 sync
    playerRef
      && playerRef.current
      && playerRef.current.seekTo(
        parseFloat(playerStore.currentPlayMixtape.songPlayedTime),
      )

    // handleStarted로 이동
    // // Chrome Autoplya Policy 이슈 대응
    // // setTimeout(() => {
    // //   setMuted(false)
    // //   prevVolume && setVolume(prevVolume)
    // //   setPrevVolume(0)
    // // }, 500)

    // /**
    //  * Chrom Autoplay Policy 이슈 대응
    //  * 2021. 05. 07
    //  * muted default에서 플레이 함수 호출 시, muted = false 후 playinga 처리
    //  * 실험 중...
    //  */

    // if (muted) {
    //   // 최초 시작에만 음소거 해제
    //   await setMuted(true)
    //   setTimeout(async () => {
    //     await setMuted(false)
    //     prevVolume && setVolume(prevVolume)
    //     setPrevVolume(0)
    //   }, 300)
    // }

    if (playerStore.url) {
      setPlaying(true)
    }
    // setPlayed(0)
    // setLoaded(0)
    // setPip(false)
  }

  // 재생 중지
  const handlePause = () => {
    setPlaying(false)
    playerStore.logPlayMixtape(playerStore.currentPlayMixtape)
    playerStore.logPlaySong(playerStore.currentPlaySong)
  }

  // 재생/중지 버튼
  const handlePlayPause = async () => {
    PlayerLogger('onPlayPause')
    if (!playerStore.url) return

    await playerStore.updateInteractPlay(true)
    // await playerStore.reFetchPlayMixtape()

    if (playing) {
      setPlaying(false)
      setBuffering(false)
      playerStore.logPlayMixtape(playerStore.currentPlayMixtape)
      playerStore.logPlaySong(playerStore.currentPlaySong)
    }
    else if (!playing) {
      // 중지 => 재생될 때,
      // 플레이중인 플라의 시간 초기화
      // 플레이중인 곡의 시간 초기화
      await playerStore.updateCurrentUsersPlayMixtapesTmpTime(new Date())
      await playerStore.updateCurrentUsersPlaySongTmpTime(new Date())
      await playerStore.syncCurrentSong()
      setPlaying(true)
    }
  }

  // 음소거 토글
  const handleToggleMuted = () => {
    setMuted(!muted)
  }

  // 곡이 끝났을 때
  // 다음 플라 자동 재생 여부 분기
  const handleEnded = async () => {
    PlayerLogger('onEnded')
    // Copotter : 오디오 송출 종료 시, 플레이어 중지
    if (fromSalon && liveRoomStore.currentPlayingLiveRoomAudio) {
      await playerStore.endedPlayLiveRoomAudio()
      return
    }
    const isLast = await playerStore.isLastCurrentSong()

    if (isLast && playerStore.isAutoNext) {
      PlayerLogger('next playList...')
      await playerStore.nextPlayMixtape()
      // setPlaying(true)
    }
    else {
      PlayerLogger('current playList...')
      // 한 플라 내에서 다음 곡 재생 시,
      // 서버 통신 제거 => 로컬에서 다음 곡 재생
      await playerStore.getNextSong()
      // await playerStore.syncCurrentSong()
      handlePlay()
    }
  }

  // Duration 조정
  const handleDuration = _duration => {
    PlayerLogger('onDuration', _duration)
  }

  // 재생 시간 저장
  // 기본값(1초)당 progress callback
  const handleProgress = state => {
    // PlayerLogger('onProgress', state)
    setPlayedSeconds(state.playedSeconds)
  }

  // 볼륨 조절
  const handleVolumeChange = e => {
    setVolume(parseFloat(e.target.value))
  }

  // 현재 리스트의 정보 담아서
  // 플라 상세로 이동
  const handleDetailRoute = item => {
    history.push({
      pathname: `/plra/${item && item.linkKey}`,
      state: {
        category: playerStore.currentPlayCategory,
        sort: playerStore.currentPlaySort,
      },
    })
    setIsMore(false)
  }

  const handleError = async e => {
    PlayerLogger('onError', e)
    setPlaying(false)
    const error = e.toString()
    if (error === 'hlsError') {
      await handlePause()
      setTimeout(async () => {
        await handlePlay()
      }, 200)
    }
  }

  const handleBuffer = () => {
    PlayerLogger('onBuffer')
    setBuffering(true)
  }

  const handleBufferEnd = () => {
    PlayerLogger('onBufferEnd')
    setBuffering(false)
  }

  const handleFullScreen = () => {
    if (!isFullscreen) {
      screenfull.request(findDOMNode(playerRef.current))
      setIsMore(true)
    }
    else {
      screenfull.exit(findDOMNode(playerRef.current))
    }
  }

  const handleMore = () => {
    if (isMore) {
      screenfull.exit(findDOMNode(playerRef.current))
      setIsMore(false)
    }
    else {
      setIsMore(true)
    }
  }

  /**
   * 버퍼링이 15초가 걸릴 때, error 간주 => 일시정지
   */
  const bufferInterval = useRef(null)
  useEffect(() => {
    if (buffering) {
      PlayerLogger('Buffering start...')
      bufferInterval.current = setInterval(() => {
        PlayerLogger('Buffering...')
        playerStore.setPlaying(false)
        setBuffering(false)
        playerStore.syncCurrentSong()
        message.error('오디오 파일에 문제가 발생하였습니다.')
        clearInterval(bufferInterval.current)
      }, 15000)
    }
    else {
      PlayerLogger('Buffering end...')
      clearInterval(bufferInterval.current)
    }
  }, [buffering, playerStore])

  /**
   * 플라 재생 시(currentPlaySong이 생길 때),
   * seek(재생 시간)을 현재 플라 재생중 인 시간 상태로 조정
   */
  useEffect(() => {
    if (playerStore.currentPlaySong && playerStore.currentPlaySong.Song) {
      setDuration(parseFloat(playerStore.currentPlaySong.Song['duration']))

      /* react-player 예외처리 pr 중. */
      if (
        playerRef
        && playerRef.current
        && playerRef.current.player
        && playerRef.current.player.isReady
      ) {
        playerRef
          && playerRef.current
          && playerRef.current.seekTo(
            parseFloat(playerStore.currentPlayMixtape['songPlayedTime'] || 0),
          )
      }
    }
  }, [playerStore, playerStore.currentPlaySong])

  /**
   * 재생 중일 때, 30초에 한번 씩
   * 로컬 플레이어 시간과 서버 플레이 시간 비교,
   * 오차 범위 30초 이상 시,
   * &&
   * 재생 중인 시간 서버에 로그
   *
   * 2020. 12. 18
   * 재생 중, 싱크 로직 제거
   */
  useEffect(() => {
    const syncing = async () => {
      if (
        playing
        && started
        && playerStore.currentPlayMixtape
        && playerStore.currentPlayMixtape.id
      ) {
        if (!syncedTime) setSyncedTime(new Date().getTime())

        if (new Date().getTime() - syncedTime > 30000) {
          // 2020 12. 18 재생 중 sync 로직 제거
          // PlayerLogger('CHECK SYNC...>>')
          // await playerStore.reFetchPlayMixtape()
          PlayerLogger('Play Logging...')
          await playerStore.logPlayMixtape(playerStore.currentPlayMixtape)
          await playerStore.logPlaySong(playerStore.currentPlaySong)

          setSyncedTime(new Date().getTime())

          // 2020 12. 18 재생 중 sync 로직 제거
          // PlayerLogger(
          //   `[SERVER]playedSeconds`,
          //   playerStore.currentPlayMixtape.songPlayedTime,
          // )
          // PlayerLogger(`[PLAYER]playedSeconds`, playedSeconds)
          // if (
          //   playerStore.currentPlayMixtape.songPlayedTime
          //     > parseInt(playedSeconds) + 5
          //   || playerStore.currentPlayMixtape.songPlayedTime
          //     < parseInt(playedSeconds) - 5
          // ) {
          //   PlayerLogger('ADJUST SYNC...>>')
          //   await playerStore.syncCurrentSong()
          // }
        }
      }
    }
    syncing()

    // eslint-disable-next-line
  }, [started, syncedTime, playedSeconds, playerStore])

  // 플레이어 펼쳤을 때, 영역 밖의 컨텐츠 스크롤 잠금
  useEffect(() => {
    if (isMore) {
      disableBodyScroll()
    }
    else {
      enableBodyScroll()
    }
  }, [isMore, isFullscreen])

  // 풀스크린 변화 시,
  useEffect(() => {
    if (!isMobile) {
      screenfull.onchange(e => {
        setIsFullScreen(!isFullscreen)
      })
    }
  }, [isFullscreen])

  // 플레이어 상태 로컬에 저장
  useEffect(() => {
    const playStatus = JSON.parse(sessionStorage.getItem('hreum-play-status'))
    // 살롱 재생일 때 제거
    if (playStatus?.salon || playerStore.fromSalon) return
    if (playerStore.currentPlayMixtape && playerStore.currentPlayMixtape.id) {
      const playStatus = {
        playing,
        mixtapeId: playerStore.currentPlayMixtape['id'],
        categoryname: playerStore.currentPlayCategory,
        sortBy: playerStore.currentPlaySort,
        volume,
      }
      PlayerManager().setValue('player', { type: 'mixtape', ...playStatus })
      sessionStorage.setItem('hreum-play-status', JSON.stringify(playStatus))
      const induceBar = document.getElementById('app-induce-bar')
      if (induceBar) {
        induceBar.style.marginBottom = '80px'
      }
    }
    else {
      const induceBar = document.getElementById('app-induce-bar')
      if (induceBar) {
        induceBar.style.marginBottom = '0px'
      }
    }
  }, [
    playing,
    playerStore,
    playerStore.currentPlayMixtape,
    // playerStore.currentPlayCategory,
    // playerStore.currentPlaySort,
    volume,
  ])

  // 플레이어의 로컬 상태 가져와서 재생
  useEffect(() => {
    /**
     * todo:
     * 웹 mount 시, 로컬 변수 로딩은 store 에서
     */
    const playStatus = JSON.parse(sessionStorage.getItem('hreum-play-status'))
    const checkSession = async () => {
      // 살롱 재생일 때 제거
      if (playStatus?.salon || playerStore.fromSalon) return
      if (sessionStorage.getItem('hreum-play-status')) {
        if (!playerStore.currentPlayMixtape.id) {
          if (authStore.currentUser) {
            if (playStatus && playStatus.mixtapeId) {
              await playerStore.fetchLoadPlayMixtapeById(
                playStatus.mixtapeId,
                playStatus.categoryname,
                playStatus.sortBy,
                playStatus.playing,
              )
              setPrevVolume(playStatus.volume)
            }
          }
        }
      }
    }

    checkSession()
  }, [authStore.currentUser, playerStore])

  // 외부에서 강제로 중지시킬 때
  useEffect(() => {
    if (playerStore.stopPlayer) {
      playerStore.setPlaying(false)
    }
  }, [playerStore, playerStore.stopPlayer])

  useEffect(() => {
    /**
     * todo:
     * 웹 mount 시, 로컬 변수 로딩은 store 에서
     */
    const prevPlayStatus = JSON.parse(
      sessionStorage.getItem('hreum-play-status'),
    )
    /* playerStore 생성될 때, playerStore.url 값은 들어가지 않으므로 현재 동작하지 않는 코드 */
    if (false && playerStore.url && prevPlayStatus?.playing) {
      playerStore.setPlaying(true)
    }
    else if (!prevPlayStatus?.playing) {
      playerStore.setPlaying(false)
      if (playerStore.url && playerStore.interactPlay) {
        PlayerManager().setValue('player', {
          type: 'mixtape',
          ...prevPlayStatus,
          playing: true,
        })

        sessionStorage.setItem(
          'hreum-play-status',
          JSON.stringify({ ...prevPlayStatus, playing: true }),
        )
      }
    }
  }, [
    playerStore,
    // playerStore.currentPlaySong,
    // playerStore.url,
    // playerStore.interactPlay,
  ])

  const [hidePlayer, setHidePlayer] = useState(false)
  /**
   * 전체 화면 일때, 마우스 움직임 없을 시(3초), 플레이어 숨기기
   */
  const moveTimeOut = useRef(null)
  useEffect(() => {
    if (isMore && isFullscreen) {
      document.onmousemove = function(e) {
        setHidePlayer(false)
        if (!moveTimeOut.current) {
          setHidePlayer(false)
          moveTimeOut.current = setTimeout(() => {
            setHidePlayer(true)
          }, 3000)
        }
        else {
          clearTimeout(moveTimeOut.current)
          setHidePlayer(false)
          moveTimeOut.current = setTimeout(() => {
            setHidePlayer(true)
          }, 3000)
        }
      }
    }
    else {
      document.onmousemove = null
      clearTimeout(moveTimeOut.current)
      setHidePlayer(false)
    }
  }, [isMore, isFullscreen])

  const playOnSpaceBar = useCallback(
    async e => {
      if (e.code === 'Space') {
        // e.preventDefault()
        PlayerLogger('onPlayPause')
        if (!playerStore.url) return

        await playerStore.updateInteractPlay(true)

        if (playing) {
          playerStore.setPlaying(false)
          setBuffering(false)
          playerStore.logPlayMixtape(playerStore.currentPlayMixtape)
          playerStore.logPlaySong(playerStore.currentPlaySong)
        }
        else if (!playing) {
          await playerStore.updateCurrentUsersPlayMixtapesTmpTime(new Date())
          await playerStore.updateCurrentUsersPlaySongTmpTime(new Date())
          await playerStore.syncCurrentSong()
          playerStore.setPlaying(true)
        }
      }
    },
    [playerStore, playing],
  )

  // ply and pause on Space bar
  useEffect(() => {
    if (document.getElementById('hreum-player')) {
      document
        .getElementById('hreum-player')
        .addEventListener('keypress', playOnSpaceBar)
    }

    return () => {
      if (document.getElementById('hreum-player')) {
        document
          .getElementById('hreum-player')
          .removeEventListener('keypress', playOnSpaceBar)
      }
    }
  }, [playOnSpaceBar])

  useEffect(() => {
    if (document.getElementById('hreum-player')) {
      if (isFullscreen) {
        document.addEventListener('keypress', playOnSpaceBar)
      }
    }

    return () => document.removeEventListener('keypress', playOnSpaceBar)
  }, [isFullscreen, playOnSpaceBar])

  // drag logic
  const [dragList, setDragList] = useState([])

  useEffect(() => {
    setDragList(playerStore.currentControlList)
  }, [playerStore.currentControlList])

  const moveList = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = dragList[dragIndex]
      setDragList(
        update(dragList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [dragList],
  )
  // drag logic end

  // 로그인 한 유저,
  // 재생 플라가 있거나, 재생오디오가 있을때
  // 플레이어 render
  if (
    authStore.currentUser
    && ((playerStore.currentPlayMixtape && playerStore.currentPlayMixtape.id)
      || playerStore.currentPlaySong?.Song)
  ) {
    return (
      <Box
        isSalon={fromSalon}
        tabIndex="0"
        id="hreum-player"
        // onKeyDown={
        //   e => {
        //     console.log('why?')
        //     e.preventDefault()
        //     e.stopPropagation()
        //     e.keyCode === 32 && handlePlayPause()
        //   }
        // }
        // onKeyPress={e => console.log('onKeyPress')}
        // onKeyDown={e => console.log('onKeyDown')}
        // onKeyDownCapture={e => console.log('onKeyDownCapture')}
        // onKeyUp={e => console.log('onKeyUp')}
        // onKeyUpCapture={e => console.log('onKeyUpCapture')}
        // onClick={e => console.log('onClick')}
        // tabIndex="0"
        isMore={isMore}
        backgroundImage={
          playerStore.currentPlayMixtape
          && playerStore.currentPlayMixtape.imageUrl
        }
      >
        <ReactPlayer
          // onKeyDown={
          //   e => {
          //     e.preventDefault()
          //     e.stopPropagation()
          //     e.keyCode === 32 && handlePlayPause()
          //   }
          // }
          // tabIndex="0"
          ref={playerRef}
          config={
            {
              file: {
              // 중요! hls option일 때, 원하는 위치부터 load 해주는 기능
              // => 기존의 load error로 재생 멈추는 로직이 50% 가량 없어졌어요!
                hlsOptions: {
                  startPosition:
                  playerStore.currentPlayMixtape['songPlayedTime'] || 0,
                },
                attributes: {
                // crossOrigin: 'true',
                  controlsList: 'nodownload', // <- this is the important bit
                  id: 'audio-elem ent',
                  autoPlay: true,
                },
              },
            }
          }
          className="react-player"
          width="100"
          height="100"
          url={playerStore.url}
          pip={false}
          playing={playing}
          controls={false}
          // light={false} // false여야 autoPlay
          loop={false}
          playbackRate={1.0}
          volume={volume}
          muted={muted}
          onPlay={handlePlay}
          onReady={
            () => {
            // 2021. 03. 24
            // 준비될 시, 바로 재생
              PlayerLogger('onReady')
              if (playerStore.url && playerStore.interactPlay) {
                setPlaying(true)
              }
            }
          }
          onStart={handleStart}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onSeek={e => PlayerLogger('onSeek', e)}
          onError={handleError}
          // onEnablePIP={handleEnablePIP}
          // onDisablePIP={handleDisablePIP}
          onPause={handlePause}
          onEnded={handleEnded}
          onProgress={handleProgress}
          onDuration={handleDuration}
          style={{ display: 'none' }}
        />
        <BlurBackground />
        <BottomGradient />
        <Header isHide={hidePlayer}>
          <FullToMiniBtn
            src={
              isFullscreen
                ? images.miniscreen_btn_img
                : images.fullscreen_btn_img
            }
            alt="screen_mode_img"
            onClick={handleFullScreen}
          />
          <ToggleMore
            src={isMore ? images.move_down_white_img : images.move_up_white_img}
            onClick={handleMore}
          />
          <InfoBox>
            {/* <LiveImage src={images.live_white_img} /> */}
            <Div
              width="44px"
              height="24px"
              fontSize="12px"
              color={colors.white_ff}
              backgroundColor={colors.primary}
              padding="3px 6px"
              borderRadius="4px"
              marginRight="24px"
            >
              PLRA
            </Div>
            <TrackImage
              src={
                playerStore.currentPlaySong && playerStore.currentPlaySong.Song
                  ? playerStore.currentPlaySong.Song.imageUrl
                  : images.default_song_img
              }
              onError={e => handleSongImgError(e)}
            />
            <TextBox>
              <Caption1
                color="#ffffff"
                type="Bold"
                align="left"
                style={{ ...oneEllipsisStyle, width: '100%' }}
              >
                {
                  playerStore.currentPlaySong && playerStore.currentPlaySong.Song
                    ? playerStore.currentPlaySong.Song.name
                    : '노래 제목'
                }
              </Caption1>
              <Caption1
                color="#d4d4d4"
                align="left"
                style={{ ...oneEllipsisStyle, width: '100%' }}
              >
                {
                  playerStore.currentPlaySong && playerStore.currentPlaySong.Song
                    ? playerStore.currentPlaySong.Song.singer
                    : '아티스트'
                }
              </Caption1>
            </TextBox>
          </InfoBox>
          <PlayControl>
            {
              buffering ? (
                <Spinner
                  width={40}
                  height={40}
                  type="spokes"
                  color={colors.white_ff}
                />
              ) : (
                <PlayPauseBtn
                  onClick={handlePlayPause}
                  src={playing ? images.pause_btn_img : images.play_btn_img}
                />
              )
            }
          </PlayControl>

          <VolumenControl>
            <Caption2
              type="Bold"
              color={colors.white_ff}
              style={{ marginRight: '30px' }}
            >
              {
                playerStore.currentPlaySong
                && `${secondToTimeString(
                  parseInt(playedSeconds),
                )}  /  ${secondToTimeString(duration)}`
              }
            </Caption2>
            <MuteBtn
              src={muted ? images.volume_mute_img : images.volume_btn_img}
              onClick={handleToggleMuted}
            />
            <VolumenBar
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={handleVolumeChange}
            />
          </VolumenControl>
        </Header>
        {
          isFullscreen ? (
            <MoreContents style={{ marginTop: '24px' }}>
              <ScreenImage
                hidePlayer={hidePlayer}
                src={
                  playerStore.currentPlayMixtape
                && playerStore.currentPlayMixtape.imageUrl
                }
                alt="screen_img"
              />
            </MoreContents>
          ) : (
            <MoreContents>
              <CurrentInfo>
                <H3
                  type="Bold"
                  align="left"
                  color={colors.white_ff}
                  style={{ marginBottom: '24px' }}
                >
                현재 플라
                </H3>
                <MixtapeBox
                  isMore={currentMore}
                  onClick={
                    () =>
                      handleDetailRoute(playerStore.currentPlayMixtape)
                  }
                >
                  <Flex style={{ alignItems: 'center', width: '100%' }}>
                    <MixtapeCover
                      src={
                        playerStore.currentPlayMixtape
                      && playerStore.currentPlayMixtape.imageUrl
                          ? playerStore.currentPlayMixtape.imageUrl
                          : images.default_song_img
                      }
                      onError={e => handleSongImgError(e)}
                    />
                    <MixtapeInfoBox>
                      <H6
                        align="left"
                        color={colors.white_ff}
                        style={{ oneEllipsisStyle }}
                      >
                        {
                          playerStore.currentPlayMixtape
                        && playerStore.currentPlayMixtape.name
                        }
                      </H6>
                      <Caption1
                        align="left"
                        type="Regular"
                        color={colors.grey_d4}
                        style={oneEllipsisStyle}
                      >
                        {
                          playerStore.currentPlayMixtape
                        && playerStore.currentPlayMixtape.User
                        && playerStore.currentPlayMixtape.User.name
                        }
                      </Caption1>
                    </MixtapeInfoBox>
                    <H6
                      type="Regular"
                      color={colors.white_ff}
                      style={{ width: '54px' }}
                    >
                      {
                        playerStore.currentPlayMixtape
                      && secondToTimeString(
                        playerStore.currentPlayMixtape.totalPlayTime,
                      )
                      }
                    </H6>
                    <LikeBtn
                      src={
                        playerStore.currentPlayMixtape['isLiked']
                          ? images.like_white_img
                          : images.like_white_default_img
                      }
                      onClick={
                        e => {
                          e.stopPropagation()
                          playerStore._likeMixtape()
                        }
                      }
                    />
                    <MixtapeToggleMore
                      onClick={
                        e => {
                          e.stopPropagation()
                          setCurrentMore(!currentMore)
                        }
                      }
                      src={
                        currentMore
                          ? images.move_up_white_img
                          : images.move_down_white_img
                      }
                    />
                  </Flex>
                  {
                    currentMore && (
                      <Flex
                        type="column"
                        style={
                          {
                            width: '100%',
                            marginTop: '24px',
                            borderTop: 'solid 2px #949494',
                          }
                        }
                      >
                        <H4
                          color={colors.white_ff}
                          type="Bold"
                          align="left"
                          style={{ margin: '24px 0 16px 0' }}
                        >
                      실시간 재생 목록
                        </H4>
                        <RealTimeList>
                          {
                            playerStore.currentPlayMixtape
                        && playerStore.currentPlayMixtape.MixtapesHaveSongs
                        && playerStore.currentPlayMixtape.MixtapesHaveSongs.map(
                          (item, index) => (
                            <RealTimeTrack
                              key={`${item.id}_realTimeList_${index}`}
                              isPlaying={
                                item.songOrder === playerStore.songPlayedIndex
                              }
                            >
                              <RealTimeTrackImage
                                src={
                                  item && item.Song.imageUrl
                                    ? item.Song.imageUrl
                                    : images.default_song_img
                                }
                                alt="track_img"
                                onError={e => handleSongImgError(e)}
                              />
                              {
                                item.songOrder
                                === playerStore.songPlayedIndex && (
                                  <>
                                    <RealTimeOpacityImage />
                                    <EqualizerBox
                                      style={{ top: '34px', left: '28px' }}
                                    />
                                  </>
                                )
                              }
                              <RealTimeInfoBox>
                                <H6
                                  align="left"
                                  style={{ marginBottom: '6px' }}
                                  color={colors.white_ff}
                                >
                                  {item && item.Song.name}
                                </H6>
                                <Caption1
                                  type="Regular"
                                  align="left"
                                  color={colors.grey_d4}
                                >
                                  {item && item.Song.singer}
                                </Caption1>
                              </RealTimeInfoBox>
                              <Flex style={{ alignItems: 'center' }}>
                                <H6 type="Regular" color={colors.white_ff}>
                                  {
                                    item
                                    && secondToTimeString(item.Song.duration)
                                  }
                                </H6>
                                <RealTimeLikeBtn
                                  src={
                                    item.Song.isLiked
                                      ? images.like_white_img
                                      : images.like_white_default_img
                                  }
                                  alt="like_img"
                                  onClick={
                                    e => {
                                      e.stopPropagation()
                                      playerStore._likeSong(item.Song)
                                    }
                                  }
                                />
                              </Flex>
                            </RealTimeTrack>
                          ),
                        )
                          }
                        </RealTimeList>
                      </Flex>
                    )
                  }
                </MixtapeBox>
              </CurrentInfo>

              <CurrentInfo style={{ marginTop: '48px', height: '65%' }}>
                <Flex
                  style={
                    {
                      marginBottom: '24px',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }
                  }
                >
                  <H3 type="Bold" align="left" color={colors.white_ff}>
                  다음 플라
                  </H3>
                  <Flex>
                    <Caption1
                      color={colors.white_ff}
                      style={{ marginRight: '20px', marginTop: '2px' }}
                    >
                    자동 재생
                    </Caption1>
                    <CheckBoxWrapper>
                      <CheckBox
                        id={'checkbox'}
                        type="checkbox"
                        checked={playerStore.isAutoNext}
                        onChange={playerStore.toggleAutoNext}
                        color={'#ffffff'}
                      />
                      <CheckBoxLabel
                        htmlFor={'checkbox'}
                        color={colors.brown_grey}
                      />
                    </CheckBoxWrapper>
                  </Flex>
                </Flex>
                <ControlList>
                  <DndProvider backend={HTML5Backend}>
                    {
                      dragList
                    && dragList.length > 1
                    && dragList.map(
                      (mixtape, index) =>
                        playerStore.currentMixtapeIdx < index && (
                          <CurrentControllListItem
                            key={`${mixtape.id}`}
                            index={index}
                            mixtape={mixtape}
                            handleDetailRoute={handleDetailRoute}
                            moveList={moveList}
                            id={`${mixtape.id}`}
                            updateCurrentControllList={
                              () =>
                                playerStore.updateCurrentControllList(dragList)
                            }
                          />
                        ),
                    )
                    }
                    {
                      dragList
                    && dragList.length > 1
                    && dragList.map(
                      (mixtape, index) =>
                        playerStore.currentMixtapeIdx > index && (
                          <CurrentControllListItem
                            key={`${mixtape.id}`}
                            index={index}
                            mixtape={mixtape}
                            handleDetailRoute={handleDetailRoute}
                            moveList={moveList}
                            id={`${mixtape.id}`}
                            updateCurrentControllList={
                              () =>
                                playerStore.updateCurrentControllList(dragList)
                            }
                          />
                        ),
                    )
                    }
                  </DndProvider>
                </ControlList>
              </CurrentInfo>
            </MoreContents>
          )
        }
      </Box>
    )
  }
}

export default observer(HreumPlayer)

// 재생 시간 사용자가 조절하는 함수
// const handleSeekMouseDown = (e) => {
//   setSeeking(true)
// }

// const handleSeekChange = (e) => {
//   setPlayed(parseFloat(e.target.value))
// }

// const handleSeekMouseUp = (e) => {
//   setSeeking(false)
//   playerRef.current.seekTo(parseFloat(e.target.value))
// }

// not yet use
// const handleClose = () => {
//   // setUrl(null)
//   setPlaying(false)
// }
// // PIP 모드가 가능할 때,
// const handleEnablePIP = () => {
//   setPip(true)
// }

// // PIP 모드가 불가능할 때,
// const handleDisablePIP = () => {
//   setPip(false)
// }

const moreAni = keyframes`
0%  { transform: translateY(calc(100% - 80px)); top: calc(100vh - 80px);}
50% { transform: translateY(50%); top:50%;}
100% { transform: translateY(0%); top:0;}
`

const unMoreAni = keyframes`
0% { transform: translateY(0%); top:0;}
50% { transform: translateY(50%); top:50%;}
100%  { transform: translateY(calc(100% - 80px)); top: calc(100vh - 80px);}
`

const Box = styled(Flex)`

  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  position: fixed;
  max-width: 100vw;
  min-width: 652px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  ${({ isSalon }) =>
    isSalon
    && css`
      display: none;
    `}

  ${props =>
    props.isMore
      ? css`
          animation: ${moreAni} 0.1s linear;
          top: 0;
        `
      : css`
          animation: ${unMoreAni} 0.1s linear;
          top: calc(100vh - 80px);
        `}

  ${props =>
    props.backgroundImage
      ? css`
          background-image: url(${props.backgroundImage});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `
      : css`
          background-color: #242424;
        `}

  @media ${mobile} {
    display: none;
  }
`

const BlurBackground = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
  z-index: 1;
`

const ToggleMore = styled.img`
  position: absolute;
  top: 28px;
  right: 50px;
  z-index: 9999;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const Header = styled(Flex)`
  margin-top: 18px;
  width: 100%;
  justify-content: left;
  align-items: center;
  z-index: 2;
  padding: 0 20%;

  ${props =>
    props.isHide
    && css`
      display: none;
    `}
`

const InfoBox = styled(Flex)`
  /* width: 356px; */
  max-width: 32%;
  width: 100%;
  justify-content: left;
  align-items: center;
`
const LiveImage = styled.img`
  width: 56px;
  height: 28px;
  margin-right: 24px;
`

const TrackImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 4px;
`

const TextBox = styled(Flex)`
  flex-direction: column;
  max-width: 60%;
  width: 100%;
  align-items: flex-start;
  margin-left: 16px;
  justify-content: space-between;
  height: 44px;
`

const PlayControl = styled(Flex)`
  max-width: 55%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const PlayPauseBtn = styled.img`
  width: 40px;
  height: 40px;
`

const VolumenControl = styled(Flex)`
  max-width: 30%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

const MuteBtn = styled.img`
  width: 20px;
  height: 20px;
`

const VolumenBar = styled.input`
  width: 112px;
  height: 5px;
  border-radius: 2px;
  border: solid 4px var(--brown-grey);
`

const MoreContents = styled(Flex)`
  z-index: 2;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  max-width: 100%;
  min-width: 652px;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const ScreenImage = styled.img`
  /* width: 872px; */
  /* height: 872px; */
  width: 40vw;
  height: 40vw;
  position: absolute;
  top: 15%;
  ${props =>
    props.hidePlayer
    && css`
      /* top: 90px; */
    `}
`

const CurrentInfo = styled(Flex)`
  width: 652px;
  flex-direction: column;
`

const MixtapeBox = styled(Flex)`
  ${props =>
    props.isMore
      ? css`
          width: 652px;
          border-radius: 16px;
          padding: 24px;
          height: fit-content;
          background-color: rgba(255, 255, 255, 0.1);
          align-items: center;
          flex-direction: column;
        `
      : css`
          width: 652px;
          height: 88px;
          border-radius: 16px;
          padding: 24px;
          align-items: center;
          cursor: pointer;
        `}
`

const MixtapeCover = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  border: solid 1px ${colors.grey_d4};
  background-color: rgba(36, 36, 36, 0.5);
`

const MixtapeInfoBox = styled(Flex)`
  /* max-width: 266px; */
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 46px 0 16px;
`

const LikeBtn = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 48px;
`

const MixtapeToggleMore = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 32px;
  cursor: pointer;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  top: 80%;
  width: 100%;
  height: 20%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(36, 36, 36, 0) 100%
  );
`

const CheckBoxWrapper = styled.div`
  position: relative;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: calc(50% - 32px);
  width: 40px;
  height: 24px;
  border-radius: 16px;
  background: ${colors.brown_grey};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 40px;
  height: 24px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: ${colors.secondary};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 18px;
      transition: 0.2s;
    }
  }
`

const ControlList = styled(Flex)`
  flex-direction: column;
  > div:last-child {
    margin-bottom: 160px;
  }
`

const RealTimeTrack = styled(Flex)`
  width: 100%;
  height: 72px;
  border-radius: 16px;
  padding: 8px;
  position: relative;

  background-color: ${props =>
    props.isPlaying ? 'rgba(255, 255, 255, 0.2)' : 'none'};
`

const RealTimeList = styled(Flex)`
  flex-direction: column;
  widht: 100%;
  > div {
    margin: 8px 0;
  }
`

const RealTimeTrackImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 8px;
  border: solid 1px ${colors.grey_d4};
  background-color: rgba(36, 36, 36, 0.5);
`

const RealTimeOpacityImage = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: black;
  opacity: 0.3;
`

const RealTimeInfoBox = styled(Flex)`
  max-width: 266px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 199px 0 16px;
`

const RealTimeLikeBtn = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 32px;
`

const FullToMiniBtn = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 100px;
  z-index: 9999;
`
