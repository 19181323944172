import { MyInfoTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'

type Props = {}

const UserMyInfoPage: React.FC<Props> = () => {
  useRenderLogger('UserMyInfoPage')

  const { authStore, userStore } = useStore()

  const currentUser = authStore.currentUser || null
  const getCheckEmail = authStore.getCheckEmail || (() => {})
  const checkPassword = authStore.checkPassword || (() => {})
  const getCheckName = authStore.getCheckName || (() => {})
  const updateUser = userStore.updateUser || (() => {})
  const uploadImage = userStore.uploadImage || (() => {})

  return (
    <MyInfoTemplate
      currentUser={currentUser}
      getCheckEmail={getCheckEmail}
      getCheckName={getCheckName}
      checkPassword={checkPassword}
      updateUser={updateUser}
      uploadImage={uploadImage}
    />
  )
}

export default observer(UserMyInfoPage)
