import React, { useEffect, useState } from 'react'
import { AudioListTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'

const AudioPage = () => {
  useRenderLogger('AudioPage')
  const {
    authStore,
    songStore,
    playerStore,
    liveRoomRecordingStore,
  } = useStore()

  const currentUser = authStore.currentUser || null
  const fetchSongDetail = songStore.fetchSongDetail || (() => {})
  const fetchMyUploadSongList = songStore.fetchMyUploadSongList || (() => {})
  const fetchMyUploadedRecordingList =
    liveRoomRecordingStore.fetchMyLiveRoomRecordingList || (() => {})

  const uploadSongList = songStore.uploadSongList || null
  const uploadSongListCount = songStore.uploadSongListCount || null

  const liveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null

  const removeSong = songStore.removeSong || (() => {})
  const deleteLiveRoomRecording =
    liveRoomRecordingStore.deleteLiveRoomRecording || (() => {})
  const updateLiveRoomRecording =
    liveRoomRecordingStore.updateLiveRoomRecording || (() => {})
  const updateSong = songStore.updateSong || (() => {})
  const updateSongInfo = songStore.updateSongInfo || (() => {})
  const createTagList = songStore.createTagList || null

  const _stopPlayer = playerStore._stopPlayer || (() => {})
  const stopPlayer = playerStore.stopPlayer || null
  const currentPlayMixtape = playerStore.currentPlayMixtape || null

  const [currentPage, setCurrentPage] = useState(1)
  const { isLoading, setIsLoading } = useTagLoading()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPage > 1) {
        await fetchMyUploadSongList(10 * (currentPage - 1))
      }
      else if (currentPage <= 1) {
        await fetchMyUploadSongList()
      }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyUploadSongList, currentPage, setIsLoading])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchMyUploadedRecordingList({ userId: currentUser['id'] })
      // if (currentPage > 1) {
      //   await fetchMyUploadedRecordingList(10 * (currentPage - 1))
      // }
      // else if (currentPage <= 1) {
      //   await fetchMyUploadedRecordingList()
      // }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyUploadedRecordingList, currentPage, setIsLoading])

  // 곡이 있으면 목록 페이지
  //     없으면 처음 입장 페이지
  if (!uploadSongList) {
    return <div>error...</div>
  }
  return (
    <AudioListTemplate
      isLoading={isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      user={currentUser}
      songs={uploadSongList}
      liveRoomRecordingList={liveRoomRecordingList}
      editLiveRoomRecording={updateLiveRoomRecording}
      songsCount={uploadSongListCount}
      removeSong={removeSong}
      deleteLiveRoomRecording={deleteLiveRoomRecording}
      fetchSongDetail={fetchSongDetail}
      createTagList={createTagList}
      updateSong={updateSong}
      updateSongInfo={updateSongInfo}
      _stopPlayer={_stopPlayer}
      stopPlayer={stopPlayer}
      currentPlayMixtape={currentPlayMixtape}
    />
  )
}

export default observer(AudioPage)
