import { Caption1, Div, ImageIcon } from '@components/atoms'
import { HomeHouseListItem } from '@components/organisms'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

const limit = 20
const SearchHouseSection = () => {
  const { searchStore, userStore } = useStore()

  const [startIndex, setStartIndex] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState([])

  const follow = userStore.follow || (() => {})
  const unFollow = userStore.unFollow || (() => {})

  const globalKeyword = searchStore.globalKeyword || null
  const fetchSearchHouseList = searchStore.fetchSearchHouseList || (() => {})

  const handleSearchUserList = useCallback(
    async (_globalKeyword, _startIndex) => {
      const _list = await fetchSearchHouseList(
        _globalKeyword,
        _startIndex,
        limit,
      )
      if (_list.length < limit) setHasMore(false)
      setList(prev => prev.concat(_list))
    },
    [fetchSearchHouseList],
  )

  const handleFollowUser = useCallback(
    user => {
      if (user?.isFollowed) {
        unFollow(user)
      }
      else {
        follow(user)
      }
    },
    [follow, unFollow],
  )

  useEffect(() => {
    if (globalKeyword) {
      handleSearchUserList(globalKeyword, startIndex, limit)
    }
  }, [globalKeyword, startIndex])

  useEffect(() => {
    return () => {
      setList([])
      setHasMore(true)
      setStartIndex(0)
    }
  }, [globalKeyword])

  return (
    <Div display="flex" flexDirection="column">
      <InfiniteScroll
        style={{ overflowX: 'hidden' }}
        dataLength={list.length}
        scrollThreshold={0.7}
        next={() => setStartIndex(prev => prev + 20)}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
        hasChildren
        loader={
          <ReactLoading
            type="spin"
            color="black"
            style={
              {
                width: '50px',
                height: '50px',
                margin: '0 auto 50px auto',
              }
            }
          />
        }
      >
        <Div
          display="flex"
          width="100%"
          flexWrap="wrap"
          gap="24px"
          marginTop="24px"
        >
          {
list?.length > 0 ? (
            list?.map(house => (
              <HomeHouseListItem item={house} key={house?.id} />
            ))
) : (
  <Div
    display="flex"
    width="100%"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    paddingTop="160px"
  >
    <Div display="flex" flexDirection="column" alignItems="center">
      <ImageIcon name="noContents" width="120px" height="120px" />
      <Div>
        <Caption1 type="Bold">"하우스"&nbsp;</Caption1>
        <Caption1
          color={colors.grey_64}
          style={{ margin: '12px 0 8px' }}
        >
                    검색 결과가 없어요
        </Caption1>
      </Div>
      <Caption1 color={colors.grey_bf}>
                  검색어를 확인해보세요!
      </Caption1>
    </Div>
  </Div>
)
          }
        </Div>
      </InfiniteScroll>
    </Div>
  )
}

export default observer(SearchHouseSection)
