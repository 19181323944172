import { observable, computed } from 'mobx'
import {
  STORAGE_URL,
  RSS_INFO_SYNC_STATE,
  CONNECTION_STATE,
  YOUTUBE_SYNC_STATE,
  INSTA_FEED_SYNC_STATE,
} from '../../resources'
import { UserModel, HousesHaveTagsModel, HousesHaveUsersModel } from '.'

export default class HouseModel {
  @observable id

  @observable title
  @observable description

  @observable isPrivateMemberList
  @observable isPrivateMemberRoom

  @observable contentPermissionLevel

  @observable isHouseNewContents

  @observable instagramFeedSyncState
  @observable instagramFeedIsDisabledByUser
  @observable isShowInstagramFeedSyncButton
  get instagramFeedConnectionState() {
    switch (this['instagramFeedSyncState']) {
      case INSTA_FEED_SYNC_STATE['NOTYET']:
        return CONNECTION_STATE['NOTYET']
      case INSTA_FEED_SYNC_STATE['SERVER_WAIT']:
      case INSTA_FEED_SYNC_STATE['SENDED_REQUEST']:
        return CONNECTION_STATE['PENDING']
      case INSTA_FEED_SYNC_STATE['SYNCING']:
      case INSTA_FEED_SYNC_STATE['SUCCESS']:
        if (this['instagramFeedIsDisabledByUser']) {
          return CONNECTION_STATE['DISABLED_BY_USER']
        }
        return CONNECTION_STATE['ENABLED']
      case INSTA_FEED_SYNC_STATE['INSTAGRAM_TOKEN_EXPIRED']:
        return CONNECTION_STATE['TOKEN_EXPIRED']
      default:
        return CONNECTION_STATE['UNHANDLED_EXCEPTION']
    }
  }
  @observable youtubeVideoSyncState
  @observable youtubeChannelSyncState
  @observable youtubeChannelIsDisabledByUser
  @observable isShowYoutubeChannelSyncButton
  get youtubeChannelConnectionState() {
    switch (this['youtubeChannelSyncState']) {
      case YOUTUBE_SYNC_STATE['NOTYET']:
        return CONNECTION_STATE['NOTYET']
      case YOUTUBE_SYNC_STATE['REQUEST']:
        return CONNECTION_STATE['PENDING']
      case YOUTUBE_SYNC_STATE['ACCEPTED']:
        if (this['youtubeChannelIsDisabledByUser']) {
          return CONNECTION_STATE['DISABLED_BY_USER']
        }
        return CONNECTION_STATE['ENABLED']
      default:
        return CONNECTION_STATE['UNHANDLED_EXCEPTION']
    }
  }
  @observable rssInfoSyncState
  @observable rssInfoIsDisabledByUser
  @observable isShowRssInfoSyncButton
  get rssInfoConnectionState() {
    switch (this['rssInfoSyncState']) {
      case RSS_INFO_SYNC_STATE['NO']:
        return CONNECTION_STATE['NOTYET']
      case RSS_INFO_SYNC_STATE['REQUEST']:
        return CONNECTION_STATE['PENDING']
      case RSS_INFO_SYNC_STATE['ACCEPTED']:
        if (this['rssInfoIsDisabledByUser']) {
          return CONNECTION_STATE['DISABLED_BY_USER']
        }
        return CONNECTION_STATE['ENABLED']
      default:
        return CONNECTION_STATE['UNHANDLED_EXCEPTION']
    }
  }

  @observable createdAt
  @observable updatedAt

  @observable memberCount
  @observable followerCount
  @observable hostUserCount
  @observable requestMemberCount

  @observable isPremiumMember
  @observable premiumMemberStar
  @observable premiumMemberStarUpdatedAt

  @observable currentStar

  @observable userId

  @observable _User = null

  @observable _HousesHaveTags = null
  @observable _CastRSSInfos = null
  @observable _HousesHaveUsers = null
  @observable _memberRequestList = null

  @observable _imageUri
  @observable _myLevel

  @computed get imageUri() {
    return this._imageUri
  }
  set imageUri(value) {
    this._imageUri = value

    /* thumbnailUri */
    if (this._imageUri && typeof this._imageUri === 'string') {
      const splitedPath = this._imageUri.split('/')
      const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]
      this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
    }
  }

  @computed get imageUrl() {
    return this._imageUri ? STORAGE_URL + this._imageUri : null
  }
  @computed get imageSource() {
    return this._imageUri ? { uri: STORAGE_URL + this._imageUri } : null
  }
  @computed get thumbnailUrl() {
    return this.thumbnailUri ? STORAGE_URL + this.thumbnailUri : null
  }
  @computed get thumbnailSource() {
    return this.thumbnailUri && { uri: STORAGE_URL + this.thumbnailUri }
  }

  @computed get User() {
    return this._User
  }

  set User(value) {
    this._User =
      (value && value instanceof UserModel
        ? value
        : new UserModel(this.stores, value)) || null
  }

  @computed get HousesHaveUsers() {
    return this._HousesHaveUsers
  }

  set HousesHaveUsers(value) {
    this._HousesHaveUsers =
      (value && value.map(val => new HousesHaveUsersModel(this.stores, val)))
      || []
  }

  @computed get memberRequestList() {
    return this._memberRequestList
  }

  set memberRequestList(value) {
    this._memberRequestList =
      (value && value.map(val => new HousesHaveUsersModel(this.stores, val)))
      || []
  }

  @computed get HousesHaveTags() {
    return this._HousesHaveTags
  }

  set HousesHaveTags(value) {
    this._HousesHaveTags =
      (value
        && value.map(
          houseHaveTag => new HousesHaveTagsModel(this.stores, houseHaveTag),
        ))
      || []
  }

  @computed get CastRSSInfos() {
    return this._CastRSSInfos
  }

  set CastRSSInfos(value) {
    this._CastRSSInfos =
      value || []
  }

  @observable linkKey

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id
      this.title = props.title

      this.imageUri = props.imageUri

      this.description = props.description

      this.isPrivateMemberList = props.isPrivateMemberList || false
      this.isPrivateMemberRoom = props.isPrivateMemberRoom || false

      /**
       * 콘텐츠 구성 가능 권한
       *  0: follower
       * 20: member
       * 30: host (default)
       */
      this.contentPermissionLevel = props.contentPermissionLevel

      this.isHouseNewContents = props.isHouseNewContents || false

      this.instagramFeedSyncState = props.instagramFeedSyncState
      this.instagramFeedIsDisabledByUser = props.instagramFeedIsDisabledByUser
      this.isShowInstagramFeedSyncButton = props.isShowInstagramFeedSyncButton
      this.youtubeVideoSyncState = props.youtubeVideoSyncState
      this.youtubeChannelSyncState = props.youtubeChannelSyncState
      this.youtubeChannelIsDisabledByUser = props.youtubeChannelIsDisabledByUser
      this.isShowYoutubeChannelSyncButton = props.isShowYoutubeChannelSyncButton
      this.rssInfoSyncState = props.rssInfoSyncState
      this.rssInfoIsDisabledByUser = props.rssInfoIsDisabledByUser
      this.isShowRssInfoSyncButton = props.isShowRssInfoSyncButton

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.memberCount = props.memberCount || 0
      this.followerCount = props.followerCount || 0
      this.hostUserCount = props.hostUserCount || 0
      this.requestMemberCount = props.requestMemberCount || 0

      this.isPremiumMember = props.isPremiumMember || false
      this.premiumMemberStar = props.premiumMemberStar
      this.premiumMemberStarUpdatedAt = props.premiumMemberStarUpdatedAt

      this.currentStar = props.currentStar || 0

      this.userId = props.userId

      this.User = props.User

      this.HousesHaveTags = props.HousesHaveTags
      this.CastRSSInfos = props.CastRSSInfos
      this.HousesHaveUsers = props.HousesHaveUsers
      this.memberRequestList = props.memberRequestList
      this._myLevel = props._myLevel
      this.linkKey = props.linkKey
    }
  }
}
