import { observable } from 'mobx'

import { CommentModel, TagModel } from '.'

export default class CommentsHaveTagsModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable commentId
  @observable tagId

  @observable Comment = {}
  @observable Tag = {}

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.commentId = props.commentId
      this.tagId = props.tagId

      this.Comment =
        props.Comment && new CommentModel(stores, props.Comment) /* || {} */
      this.Tag = props.Tag && new TagModel(stores, props.Tag) /* || {} */
    }
  }
}
