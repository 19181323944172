import { Caption1, Caption2, Flex, H6 } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { converseUnitEng, dateYMD, timeForToday } from '@utils/format'
import { useMoreHeight } from '@utils/hooks'
import { useObserver } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

const View = styled(Flex)`
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  height: 100%;
`

const CoverImage = styled.img`
  max-width: 480px;
  width: 100%;
  max-height: 480px;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`
const Descrption = styled(Caption1)`
  white-space: pre-wrap;
  height: 100%;
  overflow: hidden;
  line-height: 21px;

  ${props =>
    props.haveMore
    && !props.showMore
    && css`
      max-height: 63px;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      word-break: break-word;
      display: -webkit-box;
    `}
`

const Br = styled(Flex)`
  width: 100%;
  height: 1px;
  border-top: solid 1px #f4f4f4;
  margin: 24px 0;
`

const DjInfoBox = styled(Flex)`
  height: 48px;
  margin-bottom: 35px;
  align-items: center;

  > img {
    cursor: pointer;
    width: 48px;
    height: 48px;
    background-color: #ff8900;
    border-radius: 24px;
    margin-right: 16px;
  }
`

const MixtapeDetailInfo = ({
  mixtape,
  likeMixtape,
  dislikeMixtape,
  follow,
  unFollow,
  toggleComment,
  targetCommentList,
}) => {
  const history = useHistory()
  const { showMore, handleShowMore, haveMore } = useMoreHeight({
    defaultHeight: 63,
    id: 'description_box',
    item: mixtape && mixtape.description,
  })

  const _likeMixtape = () => {
    if (mixtape && mixtape.isLiked) {
      dislikeMixtape(mixtape)
    }
    else {
      likeMixtape(mixtape)
    }
  }

  const _followUser = () => {
    if (mixtape && mixtape.User.isFollowed) {
      unFollow(mixtape['User'])
    }
    else {
      follow(mixtape['User'])
    }
  }

  return useObserver(() => (
    <View>
      <CoverImage
        src={
          mixtape && mixtape.imageUrl
            ? mixtape.imageUrl
            : images.default_song_img
        }
        alt="cover_img"
      />
      <Flex
        type="column"
        style={
          {
            marginTop: '24px',
          }
        }
      >
        <Flex
          style={
            {
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              marginBottom: '16px',
            }
          }
        >
          <Flex style={{ height: '24px', alignItems: 'center' }}>
            <img
              onClick={toggleComment}
              src={images.comment_default_img}
              alt="like_img"
              style={
                {
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  cursor: 'pointer',
                }
              }
            />
            <Caption1>{targetCommentList && targetCommentList.length}</Caption1>
            <img
              onClick={_likeMixtape}
              src={
                mixtape && mixtape.isLiked
                  ? images.like_red_img
                  : images.like_default_img
              }
              alt="like_img"
              style={
                {
                  width: '24px',
                  height: '24px',
                  margin: '0 8px 0 16px',
                  cursor: 'pointer',
                }
              }
            />
            <Caption1>{mixtape && mixtape.likeCount}</Caption1>
          </Flex>
          <Flex
            type="column"
            style={{ height: '36px', alignItems: 'flex-end' }}
          >
            <Caption2 color={colors.grey_64}>
              {`재생 ${mixtape && converseUnitEng(mixtape.playCount)} 회`}
            </Caption2>
            <Caption2 color={colors.grey_94} type="Medium">
              {mixtape && dateYMD(mixtape.createdAt)}
              {
                mixtape
                && mixtape.publishedAt
                && `• ${timeForToday(mixtape.publishedAt)} 수정`
              }
            </Caption2>
          </Flex>
        </Flex>
        <Flex type="column">
          <Descrption
            id="description_box"
            type="Regular"
            align="left"
            haveMore={haveMore}
            showMore={showMore}
          >
            {mixtape && mixtape.description}
          </Descrption>
          {
            haveMore && (
              <Caption2
                align="right"
                type="Regular"
                color={colors.grey_94}
                style={{ cursor: 'pointer' }}
                onClick={() => handleShowMore()}
              >
                {!showMore ? '더보기' : '접기'}
              </Caption2>
            )
          }
        </Flex>
        <Br />
        <DjInfoBox>
          <img
            onClick={
              () =>
                history.push(`/user/${mixtape && mixtape.User.account}`)
            }
            src={
              mixtape && mixtape.User.thumbnailUri
                ? mixtape.User.thumbnailUri
                : images.default_song_img
            }
            alt="dj_img"
          />
          <Flex
            // type="column"
            style={
              {
                width: '100%',
                height: '48px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
            }
          >
            <Flex type="column" style={{ alignItems: 'flex-start' }}>
              <H6 style={{ marginBottom: '4px' }}>
                {mixtape && mixtape.User.name}
              </H6>
              <Caption1 color={colors.brown_grey} type="Regular">
                {`${mixtape && mixtape.User.followerCount}명 구독`}
              </Caption1>
            </Flex>

            {
              mixtape && mixtape.User.isFollowed === 'isMine' ? (
                false
              ) : mixtape.User.isFollowed ? (
                <Caption1
                  onClick={_followUser}
                  color={colors.brown_grey}
                  style={
                    {
                      cursor: 'pointer',
                      padding: '4px 15px',
                      border: `2px solid ${colors.white_f4}`,
                      backgroundColor: colors.white_f4,
                      borderRadius: '16px',
                    }
                  }
                >
                팔로잉
                </Caption1>
              ) : (
                <Caption1
                  onClick={_followUser}
                  type="Bold"
                  color={colors.primary}
                  style={
                    {
                      cursor: 'pointer',
                      padding: '4px 21px',
                      border: `2px solid ${colors.primary}`,
                      borderRadius: '16px',
                    }
                  }
                >
                팔로우
                </Caption1>
              )
            }
          </Flex>
        </DjInfoBox>
      </Flex>
    </View>
  ))
}

export default MixtapeDetailInfo
