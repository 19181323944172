export const agoraAppId = '8f97e741ca404adca74579c8c2fb3a9f'

export const LIVE_ROOM_TYPE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  SOCIAL: 'SOCIAL',
}

export const LIVE_ROOM_TYPE_DESCRIPTION = {
  PUBLIC: '*누구나 살롱에 입장할 수 있습니다',
  PRIVATE: '*링크가 있는 사람만 살롱에 입장할 수 있습니다',
}

export const LIVE_ROOMS_HAVE_USERS_LEVEL = {
  LISTENER: 0,
  REQUEST_GUEST: 10,
  GUEST: 20,
  HOST: 30,
  OWNER: 40,
}

export const LIVE_ROOM_ACTION_TYPE = {
  JOIN_LIVE_ROOM: 'JOIN_LIVE_ROOM',
  DELETE_LIVE_ROOM: 'DELETE_LIVE_ROOM',
  UPDATE_LIVE_ROOM: 'UPDATE_LIVE_ROOM',
  LEAVE_LIVE_ROOM: 'LEAVE_LIVE_ROOM',
  DONATE_LIVE_ROOM: 'DONATE_LIVE_ROOM',
  UPDATE_LIVE_ROOM_USER: 'UPDATE_LIVE_ROOM_USER',
  UPDATE_LIVE_ROOM_USER_LIST: 'UPDATE_LIVE_ROOM_USER_LIST',
  BAN_LIVE_ROOM_USER: 'BAN_LIVE_ROOM_USER',
}

export const LIVE_ROOM_MESSAGE_TYPE = {
  CHAT: 0,
  SYSTEM: 10,
  STAR: 20,
  RECORDING_START: 200,
  RECORDING_END: 210,
  END_SOON: 400,
}

export const LIVE_ROOM_TOAST_POSITION = -120

export const LIVE_ROOM_OPTION_MODAL_OWNER_ITEM_LIST = [1, 2, 3, 4, 5, 9]
export const LIVE_ROOM_OPTION_MODAL_HOST_ITEM_LIST = [1, 2, 3, 6, 4, 5, 9]
export const LIVE_ROOM_OPTION_MODAL_GUEST_ITEM_LIST = [0, 7, 2, 8, 9]
export const LIVE_ROOM_OPTION_MODAL_LISTENER_ITEM_LIST = [0, 2, 8, 9]

export const LIVE_ROOM_USER_MODAL_OWNER_CLICK_HOST_ITEM_LIST = [0, 2, 4, 5]
export const LIVE_ROOM_USER_MODAL_OWNER_HOST_CLICK_GUEST_ITEM_LIST = [
  0,
  1,
  3,
  4,
  5,
]
export const LIVE_ROOM_USER_MODAL_OWNER_HOST_CLICK_LISTENER_ITEM_LIST = [
  0,
  1,
  4,
  5,
]
export const LIVE_ROOM_USER_MODAL_LISTENER_GUEST_CLICK_ITEM_LIST = [0, 5]

export const LIVE_ROOM_MODAL_TYPE = {
  OPTION: 'OPTION',
  USER: 'USER',
}

export const LIVE_ROOM_REQUEST_TOPIC_LIST = [
  {
    keyword: '음악',
    check: false,
    icon: '🎶',
  },
  {
    keyword: '뉴스',
    check: false,
    icon: '🗞',
  },
  {
    keyword: '게임',
    check: false,
    icon: '🎮',
  },
  {
    keyword: '정치',
    check: false,
    icon: '⚖️',
  },
  {
    keyword: '운동',
    check: false,
    icon: '💪',
  },
  {
    keyword: '경제',
    check: false,
    icon: '📈',
  },
  {
    keyword: '언어',
    check: false,
    icon: '🗣',
  },
  {
    keyword: '신앙',
    check: false,
    icon: '🙏',
  },
  {
    keyword: '시사',
    check: false,
    icon: '🔍',
  },
  {
    keyword: '마음',
    check: false,
    icon: '🍀',
  },
  {
    keyword: '사랑',
    check: false,
    icon: '💘',
  },
  {
    keyword: '사회',
    check: false,
    icon: '👥',
  },
  {
    keyword: '과학',
    check: false,
    icon: '🧪',
  },
  {
    keyword: '책',
    check: false,
    icon: '📖',
  },
  {
    keyword: '커리어',
    check: false,
    icon: '🧑‍💻',
  },
  {
    keyword: '네트워킹',
    check: false,
    icon: '🌐',
  },

  {
    keyword: '문화예술',
    check: false,
    icon: '🎨',
  },
  {
    keyword: '스타트업',
    check: false,
    icon: '🚀',
  },
  {
    keyword: 'ASMR',
    check: false,
    icon: '👂',
  },

  {
    keyword: '스포츠',
    check: false,
    icon: '🏆',
  },
]
