import React from 'react'
import styled from 'styled-components'
import { mobileDesktop } from '@styles/media'
import { colors } from '@resources/colors'

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const SwitchLabel = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  z-index: 2;

  width: 50%;
  height: 40px;

  color: ${({ isSwitchOn, color }) => (isSwitchOn ? color : '#949494')};
  font-size: 16px;

  @media ${mobileDesktop} {
    font-size: 14px;
  }
`
const Contents = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 26px;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`

const ActiveLabelBackground = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  margin: 0px 2px;
  cursor: pointer;

  transform: translate(${({ switchIndex }) => `calc(100% * ${switchIndex})`});
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  transition: transform 0.5s ease, top 0.5s ease;

  width: ${({ switchLength }) => `calc(100% / ${switchLength || 2} - 2px)`};
  height: 36px;
  background-color: ${colors.white_ff};
  border-radius: 6px;
`

const SwitchTabList = ({
  handleSwitch = () => {},
  switchTitleList = [],
  switchContentList = [],
  switchIndex = 0,
  headerStyle,
  contentStyle,
}) => {
  return (
    <Container>
      <div
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...(headerStyle && headerStyle),
          }
        }
      >
        <div
          style={
            {
              position: 'relative',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              backgroundColor: colors.white_f4,
              borderRadius: 8,
            }
          }
        >
          <ActiveLabelBackground
            switchIndex={switchIndex}
            switchLength={switchTitleList.length || 2}
            onClick={() => handleSwitch(0)}
          />
          {
            switchTitleList.map((title, index) => (
              <SwitchLabel
                key={title}
                isSwitchOn={switchIndex === index}
                color={colors.black_24}
                onClick={() => handleSwitch(index)}
              >
                {title}
              </SwitchLabel>
            ))
          }
        </div>
      </div>

      <Contents style={contentStyle && contentStyle}>
        {switchContentList[switchIndex]}
      </Contents>
    </Container>
  )
}

export default SwitchTabList
