import React from 'react'
import styled from 'styled-components'
import { mobileAndTablet } from '@styles/media'

const Box = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: baseline;

  @media ${mobileAndTablet} {
    flex-direction: column;
    align-items: center;
  }
`

const TextWrapper = ({ children, style }) => {
  return <Box style={style}>{children}</Box>
}

export default TextWrapper
