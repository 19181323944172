import { images } from '@images/'
import React, { useState } from 'react'
import styled from 'styled-components'

const Box = styled.div`
  position: absolute;
  top: 25px;
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const Tooltip = ({ content, style }) => {
  const [show, setShow] = useState(false)
  return (
    <div style={{ position: 'relative' }}>
      <img
        src={images.question_mark}
        alt="question"
        style={{ margin: '5px 6px 0 6px', width: '20px', height: '20px' }}
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      {show && <Box style={style}>{content}</Box>}
    </div>
  )
}

export default Tooltip
