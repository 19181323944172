import styled from 'styled-components'
import { mobile } from '@styles/media'

const DescriptionWrapper = styled.div`
  margin-top: 40px;

  @media ${mobile} {
    margin-top: 20px;
  }
`

export default DescriptionWrapper
