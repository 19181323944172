import React from 'react'
import { H1, H6 } from '@components/atoms'
import { MoveBtn } from '@components/molecules'
import { PageLayout } from '..'

const NeedAuthTemplate = () => {
  return (
    <PageLayout>
      <H1>
        먼저 흐름 앱에서
        <br />
        이메일과 핸드폰을 인증해주세요
      </H1>
      <H6 style={{ margin: '20px 0px 40px 0px' }}>
        환전 내역 확인, 환전 신청 등 DJ 활동을 위해서는 먼저 <br />
        ‘흐름 앱 > 내 채널 > 3점 옵션(화면 상단) > 정보 수정’에서
        <br />
        본인 소유의 이메일과 핸드폰 번호를 등록하고 인증해 주세요.
      </H6>
      <MoveBtn text="홈으로 이동" href="/" />
    </PageLayout>
  )
}

export default NeedAuthTemplate
