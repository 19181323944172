import { NoticeDetailTemplate } from '@components/templates'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  match: any,
}

const NoticeDetailPage: React.FC<Props> = ({ match }) => {
  useRenderLogger('NoticeDetailPage')

  const { customerCenterStore } = useStore()

  const { linkKey } = match.params

  const noticeDetail = customerCenterStore.noticeDetail || null

  const history = useHistory()
  const { isLoading, setIsLoading } = useTagLoading()

  const handleMoveList = () => {
    history.push(`/p/notice/list`)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await customerCenterStore.fetchNoticeDetailFromLinkKey(linkKey)
      setIsLoading(false)
    }
    fetchData()
  }, [customerCenterStore, linkKey, setIsLoading])

  return (
    <NoticeDetailTemplate
      isLoading={isLoading}
      noticeDetail={noticeDetail}
      handleMoveList={handleMoveList}
    />
  )
}

export default observer(NoticeDetailPage)
