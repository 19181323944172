import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'

const StyledCheckBox = styled.div`
  display: inline-block;

  .circle {
    width: 16px;
    height: 16px;
    background: white;
    position: relative;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: 0.25s;
  }

  input[type='checkbox'] {
    width: 1px;
    height: 0px;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    border: 0;
    transition: 0.25s;
  }

  input[type='checkbox'] + .box {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 20px;
    border-radius: 50px;
    background: ${colors.grey_d4};
    transition: 0.25s;
  }

  input[type='checkbox']:checked + .box {
    background: ${colors.primary};
    transition: 0.25s;

    .circle {
      transform: translateX(20px);
      transition: 0.25s;
    }
  }
`

export default function HouseToggleBtn(
  props: React.InputHTMLAttributes<HTMLInputElement>,
) {
  const randomString = Math.random()
    .toString(36)
    .substr(2, 11)

  return (
    <StyledCheckBox>
      <input
        id={randomString}
        type="checkbox"
        {...props}
        checked={props.checked}
      />
      <label htmlFor={randomString} className="box">
        <div className="circle" />
      </label>
    </StyledCheckBox>
  )
}
