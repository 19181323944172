export const mbtiData = {
  intp: {
    title: '사회생활이 힘들어요',
    rate: '하위 1%',
    best_partner: 'entj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/entj.png',
    description: [
      '사람 많은 곳, 시끄러운 곳, 시간 약속 안 지키는 사람을 별로 좋아하지 않는 스타일입니다.',
      '공감 능력이 떨어져 냉소적이라는 말을 많이 듣는 편이라 시답지 않은 상사의 농담 얘기를 들으면 영혼없는 리액션을 하고 있지만 속으로는 화가 치밀어 올라요.',
      '사실 돈만 많이 있다면 사회생활을 굳이 하지 않는 게 정신건강에 좋은 편이에요.',
      '밖에 나가면 에너지를 쉽게 뺏기기 때문에 집에 혼자 있는걸 좋아해요. 주로 집에서 게임 등 혼자 몰두할 수 있는 취미를 가진 경우가 많아요.',
      '주로 친구들이랑 친하게 지내고는 싶지만 절대 먼저 연락하지 않아요. 왜냐하면 너무 귀찮거든요…스스로 자발적 아싸를 자초해요.',
      '친해질 사람이랑 안 맞는 사람은 잠깐 얘기해보면 바로 알 수 있어요.',
      '팩폭 좀 자제하라는 말을 자주 듣곤 해요.'
    ],
    imageName: require('../../../public/images/socialking_intp_img.png'),
    rating: '🥉 브론즈',
    affinity: 2,
    conversation: 2,
    charm: 3,
    kindness: 2
  },
  intj: {
    title: '이성적인 중대장',
    rate: '상위 35%',
    best_partner: 'entp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/entp.png',
    description: [
      '사회활동 및 단체활동하는 걸 별로 좋아하지는 않지만, 강한 의지와 결단력으로 친구들에게 든든한 버팀목이 되어주는 리더형 친구 스타일입니다',
      '평소 무표정과 원칙주의 성향으로 친구들에게 무뚝뚝하다는 소리를 많이 듣는 편이지만 그게 또 매력이기도 해요',
      '무논리와 감정적인 사람이랑 대화 하는 것 보다 하나의 주제를 가지고 토론할 수 있는 대화를 더 선호하는 편이에요',
      '칭찬과 감정표현에 인색한 편이지만 의외로 따뜻하고 관대한 사람이에요',
      '감정적인 위로보다는 현실적으로 도움이 될 만한 조언을 해주려고 노력해요',
      '인간관계에서 연락은 의무가 아니라 선택사항이라고 생각하는 편이에요',
      '효율적으로 계획을 짜는 걸 좋아해 친구들이랑 여행을 갈 때 주로 플랜짜는걸 담당해요',
      '항상 예외적인 상황까지 고려하여 플랜B까지 생각해 두는 편이죠',
    ],
    imageName: require('../../../public/images/socialking_intj_img.png'),
    rating: '🥈 실버',
    affinity: 3,
    conversation: 4,
    charm: 3,
    kindness: 2
  },
  infj: {
    title: '은밀하게 위대하게',
    rate: '하위 20%',
    best_partner: 'enfp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/enfp.png',
    description: [
      '세상살이 쉽지 않은 사람이지만 하지만 세상에 없어서는 안 될 사람이에요',
      '겉으로는 사회가 요구하는 모습, 타인이 요구하는 모습으로 최대한 맞춰 살아가지만 마음속에는 세상을 바꿀 계획을 하나씩 갖고 살아가요',
      '도움을 주고 싶은데 부담스러워할까 봐 선뜻 나서지 못하고 조용히 티 안나게 도와주는 타입이에요',
      '자기만의 깊은 세계가 있어 성격을 파악하기 힘들다는 말을 자주 듣고는 해요',
      '친구들을 만나면 친구의 말을 집중해서 들어주는 편이에요',
      '친구가 고민 상담을 하면 친구의 감정을 잘 읽어내고 공감해주며, 창의적인 조언을 해주기도 해요',
      '사람 많은 곳, 시끄러운 곳을 별로 좋아하지 않아요',
      '남들에게 피해 주고 싸우는 걸 좋아하지 않아 인간관계에서 항상 맞춰주는 입장이에요',
    ],
    imageName: require('../../../public/images/socialking_infj_img.png'),
    rating: '🥈 실버',
    affinity: 3,
    conversation: 3,
    charm: 3,
    kindness: 4
  },
  infp: {
    title: '극한의 이중성',
    rate: '상위 45%',
    best_partner: 'enfj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/enfj.png',
    description: [
      '사람 없이 못 사는 타입이지만 미움받을 용기가 부족한 편이라 인간관계가 인생에서 제일 어렵고 신경을 많이 쓰는 편이에요',
      '대인관계에서 문제가 생기면 밤새 꼽씹으며 우울 바다에서 빠져나오지 못해요',
      '하지만 고민이 해결되는 순간 언제 그랬냐는 듯 웃고 있어요',
      '사람 만나는 걸 좋아하지만, 혼자만의 시간도 필요해요 근데 혼자 있으면 또 금방 외로워져요',
      '나를 인정해주고 존중해 주는 사람이라면 우린 오늘부터 소울메이트!',
      '한 번 기죽으면 끝없이 기죽는 타입이라 항상 칭찬에 목말라 있어요',
      '다수보다 소수의 친구들과 노는 걸 더 선호해요',
      '내면의 자아가 끊임없이 싸우고 있어요 관심받고 싶은데.. 직접적으로 관심을 받는건 부담스러워..',
      '나가는 게 귀찮아 약속을 잘 잡지는 않지만, 막상 나가면 누구보다 잘 놀아요',
      '생각보다 타인에게 관심이 없고 ‘타인이 보는 나’에 더 신경쓰고 관심이 많은 편이에요',
    ],
    imageName: require('../../../public/images/socialking_infp_img.png'),
    rating: '🥈 실버',
    affinity: 3,
    conversation: 3,
    charm: 3,
    kindness: 4
  },
  isfp: {
    title: '얕고 넓은 친구관계',
    rate: '상위 40%',
    best_partner: 'estj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/estj.png',
    description: [
      '어색한 사람과 있으면 몸부터 굳는 타입 이 사람이 나를 지루해할까 봐 아무 말이나 나누면서 적응하려고 노력하는 스타일이에요',
      '사람들이랑 같이 있으면 기가 빨려요.. 영혼 가출!! 그래서 나만의 공간에서 보내는 개인적인 시간은 필수랍니다! 연락이 두절됐다면 집에서 충전 중인 거랍니다',
      '다른 사람의 부탁을 거절하기 힘들어하며 딱 잘라 못하는 편이에요',
      '공감 능력과 감정이입이 뛰어나 타인이 원하는 섬세한 부분까지도 파악을 잘해주는 스타일이에요',
      '평소에 행동이 느리고 귀차니즘이 심한 편이라 미룰 수 있을 때 까지 미루는 게으름의 끝판왕이에요 하지만 한번 삘타기 시작하면 제대로 하는 편이죠!',
      '다른 사람들은 나를 착하고 순한 양처럼 생각하지만 사실 스스로는 그렇게 생각하지 않는 편이에요 왜냐하면 속은 검은 늑대거든요',
      '남 눈치를 많이 보는 편이라 거절을 잘 못 해서 주로 양보를 하는 편이에요',
      '뭐!? 약속이 취소 됐다구요!? 고마워요 덕분에 쉴 수 있겠어요 :) 뼛속까지 집순이, 집돌이랍니다',
      '칭찬은 나를 춤추게 해요! 칭찬받는걸 좋아해서 칭찬 한번 받으면 하루종일 생각나고 기분이 좋아요! 하지만 상처도 잘 받는 편이라 욕먹으면 하루종일 생각나요..',
      '관심받고 싶지 않아요 하지만 관심 가져주세요 연락하기는 귀찮지만 나한테 연락 오는걸 좋아하는 편이에요',
    ],
    imageName: require('../../../public/images/socialking_isfp_img.png'),
    rating: '🥈 실버',
    affinity: 1,
    conversation: 3,
    charm: 2,
    kindness: 3
  },
  entp: {
    title: '프로수다러',
    rate: '상위 15%',
    best_partner: 'istj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/istj.png',
    description: [
      '생각이 깊고 눈치가 빨라 지루할 틈이 없는 전형적인 이야기꾼 형 친구 스타일이에요',
      '처음 보는 사람에게 말 거는데 거림낌이 없고 대화를 이끌어 나가죠',
      '논쟁하는 걸 좋아하고 직설적인 말투에 가끔 친구들에게 상처를 주는 경우도 있어요',
      '내 의견이랑 상대 의견이 다르면 설득해야만 해요 \n(내 의견으로 끝나야 기분이 조크든요~)',
      '친구가 힘들어할 때는 주로 감정적인 지지보다는 현실적인 조언을 건네주는 타입이에요',
      '주로 호감의 표시로 놀리고 장난치는 걸 좋아해요 특히 반응이 재밌을수록 더 좋아하는 경향이 있어 가끔 친구들이 정색하기도 해요',
      '잘못에 대한 인정이 빠른 편이에요 다만 상대방의 잘못도 있다면 꼭 짚어줘야 해요',
      '상처를 받아도 감정을 빨리 털어내고 잊어버려 뒤끝이 없는 편이에요',
    ],
    imageName: require('../../../public/images/socialking_entp_img.png'),
    rating: '🎖 플래티넘',
    affinity: 4,
    conversation: 5,
    charm: 4,
    kindness: 3
  },
  istp: {
    title: '인생은 독고다이',
    rate: '하위 5%',
    best_partner: 'enfp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/enfp.png',
    description: [
      '개인주의적 성향이 강하고 혼자만의 시간이 중요한 스타일이에요',
      '인간관계에서도 필요한 상황이 아니면 먼저 연락을 하지 않고, 깊은 관계일지라도 자주 연락하지 않는 편이에요',
      '대인관계는 원만한 편이지만 공사 구별이 뚜렷해요',
      '낯가림이 심한 편이라 무미건조해 보이지만 친해지면 말도 많고 장난도 잘 쳐요',
      '평소 무표정이 디폴트라 친구들에게 무뚝뚝하다는 소리를 많이 듣는 편이지만 그게 또 매력이기도 해요',
      '다른 사람들에게 별로 관심이 없는 편이라 남 일에 간섭을 잘 하지 않는 편이에요',
      '만사가 귀찮지만 그렇다고 뒤처지는 건 싫어요 중상위권 정도는 유지해줘야 해요',
      '시간과 노력을 절약할 수 있도록 최대한 가성비를 따져 생각하고 효율적으로 행동하려 해요',
      '사생활에 민감하니 간섭하지 말아 주세요 간섭받으면 나도 모르게 욕이 튀어나올지도 몰라요',
      '느낌이나 감정, 타인에 대한 고마운 마음을 표현하는걸 어려워할 때가 많아요',
      '좀 더 자신의 마음속에 있는 느낌이나 생각, 정보, 계획을 타인과 나누는 노력을 해보면 어떨까요?',
    ],
    imageName: require('../../../public/images/socialking_istp_img.png'),
    rating: '🥈 실버',
    affinity: 1,
    conversation: 3,
    charm: 2,
    kindness: 3
  },
  isfj: {
    title: '원하는대로 해 ~ 난 다 괜찮아',
    rate: '상위 24%',
    best_partner: 'estj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/estj.png',
    description: [
      '최대한 갈등을 만들기 원하지 않아 상대방의 입장에서 생각하고 행동하기 때문에 대부분 상대에게 맞춰주는 스타일이에요',
      '기본적으로 순하고 착하지만, 타인에게 만만해 보이지는 않는 타입이에요',
      '다수보다 소수의 친구들과 노는 걸 더 선호해요',
      '싸움 등의 갈등 상황을 불편해하고, 그런 상황을 보는 것 자체만으로 굉장히 스트레스를 받는 편이에요',
      '남에게 싫은 소리를 잘 못 하고 싫은 소리를 들은 경우엔 티는 안내지만 속상해하며 계속 곱씹어요',
      '아싸 무리에서 인싸, 인싸 무리에서는 아싸',
      '다른 사람의 감정을 잘 파악하고 공감도 잘해주는 편이에요',
      '전화 오면 고민만 오조오억 번 하고 받으니 되도록 카톡으로 연락해주세요',
      '정이 많아 좋아하는 사람에겐 내 모든 걸 보여주고 퍼주는 편이지만 한 번 정이 떨어지면 기회 따위 주지 않아요 제발 옆에 있을 때 잘해주세요',
      '남에게 크게 관심이 없는 편이지만 사소한 디테일들을 매우 잘 기억하는 편이에요',
    ],
    imageName: require('../../../public/images/socialking_isfj_img.png'),
    rating: '🥇 골드',
    affinity: 3,
    conversation: 3,
    charm: 4,
    kindness: 5
  },
  esfp: {
    title: '자유로운 영혼을 가진 슈퍼인싸',
    rate: '상위 5%',
    best_partner: 'infp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/infp.png',
    description: [
      '갑자기 흥얼거리며 즉흥적으로 춤을 추기 시작하는\n 흥이 많은 폭주 기관차 스타일이에요',
      '센스와 유머, 사교성이 좋아 친구들 사이에서도 \n분위기 메이커로 인싸 중에 인싸에요',
      '사람을 좋아해서 주변 사람 챙겨주는 걸 좋아해요',
      '학교나 직장에서 나를 모르는 사람은 간첩!',
      '관종끼가 많아 주목받는걸 무척이나 좋아해요',
      '자기애가 넘쳐 자기 자신을 무척이나 사랑해요',
      '눈치가 빠른 편이라 상대방의 기분을 잘 파악하는 편이에요',
      '흥이 너무 많다 보니 때로는 즉흥적인 즐거움에 \n심취해 의무나 책임을 회피하곤 하죠'

    ],
    imageName: require('../../../public/images/socialking_esfp_img.png'),
    rating: '💎 다이아몬드',
    affinity: 4,
    conversation: 5,
    charm: 5,
    kindness: 5
  },
  istj: {
    title: '나는 혼자가 편해',
    rate: '하위 10%',
    best_partner: 'entp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/entp.png',
    description: [
      '새로운 사람을 사귄다는 거 자체가 스트레스라 좁고 깊은 인간관계를 선호하는 스타일이에요',
      '사람이 많고 시끄러운 곳에 가면 기가 빨리기 때문에 혼자 집에 있는걸 좋아하는 편이에요',
      '백해무익한 무리보다는 혼자 있는걸 선호하는 스타일이에요',
      '내가 하는 일에 간섭하지 말아 주세요 도와주려는 마음은 고맙지만 혼자 할 수 있으면 혼자 해내는 게 마음 편하거든요!',
      '즉흥적인 것 보다 계획적이고 효율적인 걸 좋아하는 편이라 갑작스레 약속을 펑크내는 사람을 싫어하니 조심해주세요',
      '다른 사람한테 별로 관심 없는 편이라 얘기 들어주는 걸 힘들어해요 내 얘기 하는 것도 별로 좋아하지 않는데..',
      '남 얘기에 공감을 잘 못 해줘서 그런지 영혼 없다는 말을 자주 듣는 편이에요',
      '좀 더 자신과 타인의 감정에 민감하게 반응하려고 노력해보면 어떨까요?',
    ],
    imageName: require('../../../public/images/socialking_istj_img.png'),
    rating: '🥈 실버',
    affinity: 2,
    conversation: 3,
    charm: 3,
    kindness: 3
  },
  enfp: {
    title: '사람 좋아! 관심 좋아!',
    rate: '상위 10%',
    best_partner: 'isfj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/isfj.png',
    description: [
      '사람들을 좋아하고 사교성이 좋아 평판이 좋고 주변에서 따르는 사람들이 많은 핵인싸 타입이에요!',
      '친구를 두루두루 사귀는 것을 좋아해서 넓고 얕은 인간관계처럼 보이기도 하지만 사실 좁고 깊은 인간관계를 소유하고 있는 경우가 많아요',
      '주로 모임에서 분위기 메이커 역할을 하고, 외향적인 성격으로 사람들을 기쁘게 해주는데 타고난 능력을 갖췄어요',
      '침묵이 흐른다면 견딜 수가 없어요 나의 TMI라도 남발해야만 해요',
      '매일 새로운 나날들을 원해요 하고 싶은게 너무 많거든요!',
      '좋아하는 사람과 싫어하는 사람의 구별이 뚜렷해요 상대가 누구냐에 따라 천사가 되기도 하며 악마가 되기도 해요',
      '무례하게 대하는 사람에게는 마음속으로 선을 먼저 그어 버리는 타입이에요',
      '굉장히 활동적이고 인싸 같아 보이지만 은근 고민도 많은 편이에요! 가끔은 나만을 위한 시간도 필요해서 잠수를 타곤 해요',
      '아무리 걱정되는 일이 있어도 조금만 지나도 무슨 일 있었냐는 듯 생각 없이 잘 살아요',
      '사람을 좋아하는 만큼 상처 잘 받지만 금방 털어버리는 긍정왕이에요',
    ],
    imageName: require('../../../public/images/socialking_enfp_img.png'),
    rating: '🎖 플래티넘',
    affinity: 4,
    conversation: 4,
    charm: 4,
    kindness: 4
  },
  estj: {
    title: '잔소리를 좋아하는 냉철 인간',
    rate: '상위 19%',
    best_partner: 'isfj',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/isfj.png',
    description: [
      '주도해 나가는 능력이 탁월하고 친구들 사이에서도 뛰어난 결단력으로 리더십이 당연히 돋보이는 스타일이에요',
      '친구가 잘못된 방향으로 빠지는 것 같으면 폭풍 잔소리를 시전해요',
      '관심 없으면 이런 애정표현조차 하지 않는다고 생각해요',
      '상대방은 공감과 위로를 원하지만, 머릿속에는 어느새 상황을 분석하고 해결책 제시해 줄 생각 밖에 생각이 안 나 곤란할 때가 많아요',
      '겉으로는 로봇 냉철 인간 그 자체지만 알고보면 배려심과 생각이 깊은 편이에요',
      '나가서 노는 것보단 이것저것 배우는 게 더 가치 있다고 생각해요',
      '계획이 틀어지는 걸 싫어해요 완벽주의적 성격 때문에 스트레스를 받곤해요',
      '누군가 내 말에 반박하면 참을 수 없어요 왜냐하면 이미 머릿속에서 최고의 효율을 가진 최적의 방법이라고 판단했기 때문이죠',
      '하지만 납득이 될만한 근거를 가져오면 쿨하게 인~정하는 편이랍니다',
    ],
    imageName: require('../../../public/images/socialking_estj_img.png'),
    rating: '🥇 골드',
    affinity: 4,
    conversation: 3,
    charm: 4,
    kindness: 3
  },
  estp: {
    title: '뭘 고민하고 있어!',
    rate: '상위 12%',
    best_partner: 'enfp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/enfp.png',
    description: [
      '새로운 것을 경험하는데 주저함이 없어서 지루할 틈이 없어 옆에 있으면 새로운 것들을 경험할 수 있는 친구에요',
      '개방적인 사고를 하고 있어 누구와도 편견 없이 친해지며, 친구와 어울리기를 좋아하는 스타일이에요',
      '집에 있기보다는 사람들을 만나면서 에너지를 얻는 편이에요',
      '센스와 유며, 사교성이 좋아 친구들 사이에서도 분위기 메이커예요',
      '솔직함을 넘어 아주 직설적인 성격이라 가끔은 감정적인 갈등 상황에 부닥쳤을 때 그 상황을 악화시키기도 해요',
      '혼자 하는 활동이 아닌 단체로 움직여야 하는 상황에서 누군가 때문에 상황이 지체된다면 참지 못해 꼼꼼하지만 느린 사람과 트러블이 생기기가 쉬울 수 있어요',
      '사람마다 일 처리 방식이 다르다는 것을 이해해 보는 건 어떨까요?',
    ],
    imageName: require('../../../public/images/socialking_estp_img.png'),
    rating: '🎖 플래티넘',
    affinity: 4,
    conversation: 3,
    charm: 4,
    kindness: 3
  },
  esfj: {
    title: '마음먹으면 대통령과도 친해질 타입',
    rate: '상위 2%',
    best_partner: 'istp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/istp.png',
    description: [
      '모르는 사람과도 10분 만에 절친이 되는 핵인싸 스타일이에요',
      '누구보다 새로운 사람 만나기를 좋아하고 쉼 없이 폭풍 리액션을 보내는 인싸 중에 인싸에요',
      '항상 에너지가 넘치고 보기만 해도 기분이 좋아져 대체로 사람들이 좋아하고 금방 친해지는 편이에요',
      '새로운 사람을 만나는 걸 좋아하며 말이 많은 편이라 대화하는 걸 무척이나 즐거워해요',
      '어색한 분위기에서는 그 어색함을 풀어나가며 즐거움을 느끼는 편이에요',
      '계획이 틀어지는 걸 싫어져서 즉흥적으로 만나는 것보다는 구체적으로 약속 잡는 걸 좋아해요',
      '다른 사람의 눈치를 많이 보는 편이고 상대방에게 잘 맞춰주는 편이에요',
      '상담이나 고민 들어주는 걸 좋아해서 친구들이 자주 고민을 털어놓는 편이에요',
      '상대방의 감정을 먼저 배려하기 때문에 쓴소리를 잘 못 하는 편이에요',
    ],
    imageName: require('../../../public/images/socialking_esfj_img.png'),
    rating: '💎 다이아몬드',
    affinity: 5,
    conversation: 5,
    charm: 5,
    kindness: 5
  },
  entj: {
    title: '이 세상에 믿을 건 오직 나 자’신’뿐',
    rate: '상위 28%',
    best_partner: 'intp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/intp.png',
    description: [
      '새로운 사람들을 만나는 걸 좋아하지만 깊은 관계를 맺는 건 별로 좋아하지 않아요',
      '자기 주관이 확고하므로 안 맞는 친구가 있으면 칼같이 손절하는 편이에요',
      '부조리하고 비합리적인 것을 싫어하기 때문에 권위적이지 않고 후배들과도 잘 어울리는 스타일이에요',
      '효율을 중시하고 답답한 걸 싫어해서 답답한 친구를 만나면 결국 총대 메고 내가 다 해버리고 말아요',
      '열심히 일하며, 동시에 이루고 싶어 하는 꿈도 큰 전형적인 자본주의 야망가 타입이에요',
      '성공에 대한 자부심과 언제나 당당한 자신감이 넘쳐요',
      'Plz.. 제발.. 간섭하지 마세요 남이 나에게 이래라저래라하는 것 정말 딱 질색이에요',
      '친구가 힘들어할 때는 감정적인 지지보다는 현실적인 조언을 건네주는 게 더 효율적이라 생각해요',
      '투머치한 관심은 부담스럽지만 약간의 관종끼가 있어 관심받는 걸 좋아해요',
    ],
    imageName: require('../../../public/images/socialking_entj_img.png'),
    rating: '🥇 골드',
    affinity: 3,
    conversation: 3,
    charm: 3,
    kindness: 2
  },
  enfj: {
    title: '총알받이 오지랖퍼',
    rate: '상위 8%',
    best_partner: 'infp',
    liveRoomImagePath: 'https://storage.hreum.me/resources/socialking/liveroom/infp.png',
    description: [
      '사람을 좋아해서 정도 많고 주변 사람을 잘 챙기는 편이라 친구 관계가 좋은 편입니다',
      '사교성이 좋아 사람들과 잘 어울리는 편이고 싸움보다는 평화를 추구하는 스타일이에요',
      '매사에 긍정적이며 말솜씨가 좋아 친구들에게 긍정적인 영향을 주는 친구랍니다',
      '다수의 친구랑 있을 때는 분위기 메이커이지만 소수의 친구들과 어울릴 때 가벼운 재미부터 딥토크까지 보여주는 매력적인 당신이에요',
      '하지만 사람을 누구보다 좋아하는 만큼 사람에게 상처받으면 오랫동안 아파하는 편이에요',
      '화가 나면 겉으로 표현하지 않고 묵묵히 참아 내는 편이라 정말 감정적으로 화를 냈다면 누구든지 화를 낼 수준의 도를 넘는 행동을 했다는 뜻이에요',
      '정, 눈물, 동정심이 많은 감성적인 사람이라 가끔 사람들에게 이용당하는 일이 있을 수 있으니 조심해야 해요',
    ],
    imageName: require('../../../public/images/socialking_enfj_img.png'),
    rating: '🎖 플래티넘',
    affinity: 4,
    conversation: 4,
    charm: 4,
    kindness: 5
  },
}