import { Caption2, Div, ProfileImg, StyledButton } from '@components/atoms'
import { colors } from '@resources/colors'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

const HomeUserListItem = ({ item, handleFollowUser }) => {
  const history = useHistory()

  const isFollow = !!item.isFollowed
  return (
    <Div
      display="flex"
      width="384px"
      height="64px"
      border={`1px solid ${colors.white_f4}`}
      borderRadius="8px"
      backgroundColor={colors.white_ff}
      padding="12px 16px"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => history.push(`/user/${item.account}`)}
      cursor="pointer"
    >
      <Div display="flex">
        <ProfileImg
          src={item.thumbnailUri}
          profileColor={item.profileColor}
          style={{ width: 40, height: 40, marginRight: 8, borderRadius: '50%' }}
        />
        <Div display="flex" flexDirection="column" gap="2px">
          <Caption2 align="left" type="Bold">
            {item.name}
          </Caption2>
          <Caption2 align="left" color={colors.grey_94}>
            {`@${item.account}`}
          </Caption2>
        </Div>
      </Div>
      <Div>
        <StyledButton
          size="xs"
          designType={isFollow ? 'unfollow' : 'primary'}
          padding="3px 13px"
          onClick={
            e => {
              e.stopPropagation()
              handleFollowUser(item)
            }
          }
        >
          {isFollow ? '팔로잉' : '팔로우'}
        </StyledButton>
      </Div>
    </Div>
  )
}

export default observer(HomeUserListItem)
