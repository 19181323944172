import { Flex } from '@components/atoms'
import { colors } from '@resources/colors'
import React from 'react'
import styled, { css } from 'styled-components'

const Btn = styled(Flex)`
  transition: 0.15s;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
  padding: 13px;

  ${({ active }) =>
    active
      ? css`
          background-color: ${colors.primary};
          border: 1px solid ${colors.primary};
          color: ${colors.white_ff};
          font-size: 10px;
        `
      : css`
          background-color: ${colors.white_ff};
          border: 1px solid ${colors.grey_d4};
          color: ${colors.black_24};
          font-size: 10px;
        `}
`

const GenreBtn = ({ tag, onPress, selectedCheck }) => {
  const isSelected = selectedCheck(tag)

  return (
    <Btn active={isSelected} onClick={() => onPress(tag)}>
      {tag['keyword']}
    </Btn>
  )
}

export default GenreBtn
