import { Flex, H5, H6 } from '@components/atoms'
import { PageLayout } from '@components/templates'
import { INICIS_ERROR_CODE } from '@consts/'
import { colors } from '@resources/colors'
import { useRenderLogger } from '@utils/hooks'
import QueryString from 'qs'
import React from 'react'

const StarChargeFailPage = () => {
  useRenderLogger('StarChargeFailPage')

  const [errorMsg, setErrorMsg] = React.useState(null)
  const param = document.location.href.split('?')
  const query = QueryString.parse(param[1], {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    if (query.resultCode) {
      setErrorMsg(INICIS_ERROR_CODE[query.resultCode])
    }
  }, [query])

  return (
    <PageLayout>
      <H5>결제가 실패되었습니다. 흐름 운영팀으로 문의 부탁드립니다.</H5>
      {
        errorMsg && (
          <Flex style={{ marginTop: 20 }}>
            <H6>사유 : </H6>
            <H6 style={{ marginLeft: 5 }} color={colors.warning}>
              {errorMsg}
            </H6>
          </Flex>
        )
      }
    </PageLayout>
  )
}

export default StarChargeFailPage
