import { observable } from 'mobx'

export default class MoodModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable name
  @observable songId

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.name = props.name
      this.songId = props.songId
    }
  }
}