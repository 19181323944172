import { Caption1, Flex, H6 } from '@components/atoms'
import { CustomTab } from '@components/molecules'
import {
  UserDetailCastSection,
  UserDetailFeedSection,
  UserDetailHomeSection,
  UserDetailInfoSection,
  UserDetailMixtapeSection,
} from '@components/organisms'
import { colors } from '@resources/colors'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import styled from 'styled-components'
import { PageLayout } from '..'

const UserDetailTemplate = ({
  userDetail,
  isMyPage,
  unFollow,
  follow,
  updateFollow,
  currentUser,
  userMixtapeList,
  handleOffset,
  hasMoreMixtape,
  thisUserJoinedHouseList,
  initFeedId,
}) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0)

  return (
    <PageLayout style={{ position: 'relative', maxWidth: '100vw', padding: 0 }}>
      <UserBackgroundImageBox />
      <UserBackgroundImage src={userDetail?.backgroundImageUrl} />
      <UserDetailInfoSection
        currentUser={currentUser}
        isMyPage={isMyPage}
        userDetail={userDetail}
        style={{ marginBottom: 24, padding: '0 20px' }}
        unFollow={unFollow}
        follow={follow}
        updateFollow={updateFollow}
        thisUserJoinedHouseList={thisUserJoinedHouseList}
      />
      <CustomTab
        // forceRenderTabPanel
        // defaultIndex={userDetail.discJockeyLevel > DJ_LEVEL['BABY'] ? 2 : 0}
        defaultIndex={0}
        selectedIndex={selectedTabIdx}
        onSelect={index => setSelectedTabIdx(index)}
        className="user_detail_tab"
        style={{ width: '100%', maxWidth: 1064 }}
        menu={['홈', '피드', '캐스트', '플라']}
        contents={
          [
            <UserDetailHomeSection
              isMyPage={isMyPage}
              user={userDetail}
              setSelectedTabIdx={setSelectedTabIdx}
              initFeedId={initFeedId}
            />,
            <>
              {
                !isMyPage && userDetail?.isPrivate && !userDetail?.isFollowed ? (
                  <Flex
                    type="column"
                    style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
                  >
                    <H6 color={colors.grey_64}>비공개 계정입니다.</H6>
                    <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
                  피드를 보고 싶으면 팔로우를 요청하세요
                    </Caption1>
                  </Flex>
                ) : (
                  <UserDetailFeedSection initFeedId={initFeedId} userDetail={userDetail} />
                )
              }
            </>,
            <UserDetailCastSection userDetail={userDetail} />,
            <UserDetailMixtapeSection userDetail={userDetail} />,
          // <UserDetailMixtapeSection isMyPage={isMyPage} />,
          ]
        }
      />
    </PageLayout>
  )
}

export default observer(UserDetailTemplate)

const UserBackgroundImageBox = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 320px;
  min-height: 320px;
  margin-bottom: -50px;

  background-color: ${colors.black_24};
  opacity: 0.4;
  z-index: 2;
`

const UserBackgroundImage = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 320px;
  min-height: 320px;
  height: 100%;
  margin-bottom: -50px;

  z-index: 1;
`
