import React, { useEffect, useState } from 'react'
import {
  RevenueInfoCard,
  RevenueStepCard,
  RevenueAccountCard,
  RevenueListCard,
  ExchangeHistoryCard,
} from '@components/organisms'
import { H1 } from '@components/atoms'
import { FlexRowBox } from '@components/molecules'
import { PageLayout } from '..'

const RevenueInfoTemplate = ({
  currentUser,
  accountToggle,
  confirmToggle,
  currentBankAccount,
  revenueNotifications,
  revenueHistories,
  stopToggle,
}) => {
  const [lastRevenue, setLastRevenue] = useState(null)

  useEffect(() => {
    if (revenueHistories && revenueHistories.length > 0) {
      setLastRevenue(
        revenueHistories
          && revenueHistories.filter(item => item.responseState !== 0)[0],
      )
    }
  }, [revenueHistories])

  return (
    <PageLayout>
      <div style={{ width: '100%', maxWidth: '1000px' }}>
        <H1 style={{ marginBottom: '15px' }}>포인트 환전</H1>
        <RevenueInfoCard
          currentUser={currentUser}
          confirmToggle={confirmToggle}
          stopToggle={stopToggle}
          lastRevenue={lastRevenue}
        />
        {
          currentUser && currentUser.revenueLevel >= 1004 && (
            <FlexRowBox style={{ width: '100%', maxWidth: '1000px' }}>
              <RevenueAccountCard currentBankAccount={currentBankAccount} />
              <RevenueListCard revenueNotifications={revenueNotifications} />
            </FlexRowBox>
          )
        }
        {
          currentUser && currentUser.revenueLevel < 2001 ? (
            <RevenueStepCard
              currentUser={currentUser}
              accountToggle={accountToggle}
            />
          ) : (
            <ExchangeHistoryCard revenueHistories={revenueHistories} />
          )
        }
      </div>
    </PageLayout>
  )
}

export default RevenueInfoTemplate
