import { Network } from '.'

export default class UserNetwork {
  constructor(network: Network) {
    this.network = network
  }

  // 로그인 api
  postLogin = async params => {
    try {
      if (params.kToken) {
        const { data } = await this.network._axiosApi(
          '/users/kakao-login',
          'get',
          params,
        )
        return data || []
      } if (params.email) {
        const { data } = await this.network._axiosApi(
          '/users/login',
          'post',
          params,
        )
        return data || []
      }
      const { data } = await this.network._axiosApiAuth(
        '/users/login',
        'post',
      )
      return data || []

    }
    catch (error) {
      console.log('AuthNetwork postLogin error: ', error)
    }
  }

  // 인증번호 전송
  postSms = async phone => {
    try {
      const { data } = await this.network._axiosApiAuth(`/auth/sms`, 'post', {
        phone,
      })
      return data || []
    }
    catch (error) {
      console.log('AuthNetwork postSms error: ', error)
    }
  }

  // 인증번호 체크
  postSmsCheck = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/auth/sms/check`,
        'post',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('AuthNetwork postSmsCheck error: ', error)
    }
  }

  // 임시 비밀번호 API
  postTempPassword = async email => {
    try {
      const { data } = await this.network._axiosApi(
        `/users/temp-password`,
        'post',
        {
          email,
        },
      )
      return data || []
    }
    catch (error) {
      console.log('AuthNetwork postTempPassword error: ', error)
    }
  }

  // 비밀번호 변경 API
  putUsersSmsPwd = async params => {
    try {
      const { data } = await this.network._axiosApi(
        `/users/sms-pwd`,
        'put',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('AuthNetwork putUsersSmsPwd error: ', error)
    }
  }

  // 비밀번호 인증 API
  postCheckPassword = async (email, password) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users/check-password`,
        'post',
        { email, password },
      )
      return data || []
    }
    catch (error) {
      console.log('AuthNetwork postCheckPassword error: ', error)
    }
  }
}
