import styled from 'styled-components'
import { mobile } from '@styles/media'

const Form = styled.form`
  width: 518px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mobile} {
    width: 100%;
  }
`

export default Form
