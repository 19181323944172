import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  H6,
  Flex,
  Caption1,
  Caption2,
  Button,
  ProfileImg,
} from '@components/atoms'
import { images } from '@resources/'
import { observer } from 'mobx-react'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { colors } from '@resources/colors'
import { DeletePopup } from '@components/molecules'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(
  ({
    isShowing,
    toggle,
    requestUserList,
    acceptToGuest,
    deleteRequestUser,
  }) => {
    const {
      isShowing: showDelete,
      toggle: toggleDelete,
    } = DeletePopup.useModal()

    const [checkItems, setCheckItems] = React.useState([])
    const [allChecked, setAllChecked] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)

    const deleteRequestUsers = async () => {
      const res = await deleteRequestUser(checkItems)
      if (!res) return
      setCheckItems([])
      setAllChecked(false)
      toggleDelete()
      if (allChecked) toggle()
    }

    const _acceptToGuest = async user => {
      const accepted = await acceptToGuest(user)
      if (!accepted) return
      // alert(`게스트 승인 완료`)
    }

    // 체크박스 전체 단일 개체 선택
    const handleSingleCheck = (checked, id) => {
      if (checked) {
        setCheckItems([...checkItems, id])
      }
      else {
        // 체크 해제
        setCheckItems(checkItems.filter(el => el !== id))
        setAllChecked(false)
      }
    }

    // 체크박스 전체 선택
    const handleAllCheck = checked => {
      if (!checked) {
        const idArray = []
        // 전체 체크 박스가 체크 되면 id를 가진 모든 elements를 배열에 넣어주어서,
        // 전체 체크 박스 체크
        requestUserList.forEach(el => idArray.push(el.User.id))
        setCheckItems(idArray)
      }

      // 반대의 경우 전체 체크 박스 체크 삭제
      else {
        setCheckItems([])
      }
      setAllChecked(!allChecked)
    }

    return isShowing
      ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={() => toggle()} />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <EditBtn
                onClick={
                  () => {
                    setIsEdit(!isEdit)
                  }
                }
              >
                {isEdit ? '완료' : '편집'}
              </EditBtn>
              <MobdlBody
                style={
                  {
                    position: 'relative',
                    width: '100%',
                    padding: '24px 24px 40px 24px',
                  }
                }
              >
                <Flex style={{ height: 64 }} justify="center">
                  <H6>{`신청 ${requestUserList?.length}`}</H6>
                </Flex>
                <UserList>
                  {
                    requestUserList
                      && requestUserList.length > 0
                      && requestUserList.map(req => (
                        <UserItem key={req.id}>
                          {
                            isEdit && (
                              <img
                                onClick={
                                  () =>
                                    handleSingleCheck(
                                      !checkItems.includes(req.User.id),
                                      req.User.id,
                                    )
                                }
                                src={
                                  checkItems.includes(req.User.id)
                                    ? images.check_blue_img
                                    : images.check_empty_img
                                }
                                alt="check_1"
                                style={{ width: 24, height: 24, marginRight: 24 }}
                              />
                            )
                          }
                          <ProfileImg
                            style={{ marginRight: 8 }}
                            src={req?.User?.imageUri}
                            profileColor={req?.User?.profileColor}
                          />
                          <Caption2
                            type="Bold"
                            align="left"
                            style={
                              {
                                ...oneEllipsisStyle,
                                flex: 1,
                              }
                            }
                          >
                            {req.User.name}
                          </Caption2>
                          {
                            !isEdit && (
                              <AcceptBtn
                                style={{ marginLeft: 16 }}
                                onClick={() => _acceptToGuest(req.User)}
                              >
                              수락
                              </AcceptBtn>
                            )
                          }
                        </UserItem>
                      ))
                  }
                </UserList>
                {
                  isEdit && (
                    <DeleteBtnList>
                      <Flex
                        onClick={() => handleAllCheck(allChecked)}
                        justify="center"
                        align="center"
                        style={
                          {
                            backgroundColor: colors.white_f4,
                            color: colors.grey_64,
                          }
                        }
                      >
                        전체 선택
                      </Flex>
                      <Flex
                        onClick={() => checkItems.length > 0 && toggleDelete()}
                        justify="center"
                        align="center"
                        style={
                          {
                            backgroundColor:
                            checkItems.length > 0
                              ? colors.warning
                              : colors.grey_d4,
                            color: colors.white_ff,
                          }
                        }
                      >
                        삭제
                      </Flex>
                    </DeleteBtnList>
                  )
                }
              </MobdlBody>
            </Modal>
            <DeletePopup.View
              isShowing={showDelete}
              toggle={toggleDelete}
              onDelete={deleteRequestUsers}
              title="신청 내역 삭제"
              body="삭제된 신청은 복구할 수 없습니다"
            />
          </ModalWrapper>
        </>,
        document.body,
      )
      : null
  },
)

export default {
  View,
  useModal,
}

const EditBtn = styled(Caption1)`
  z-index: 5000;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const UserList = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const UserItem = styled(Flex)`
  transition: 0.35s;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`

const AcceptBtn = styled(Button)`
  transition: 0.35s;
  width: 48px;
  height: 32px;
  border-radius: 4px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 12px;
`

const DeleteBtnList = styled(Flex)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;

  > div {
    width: 50%;
    height: 100%;
  }
`
