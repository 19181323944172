import React from 'react'
import { CardBox, FlexRowBox } from '@components/molecules'
import { H4, Caption1, Caption2 } from '@components/atoms'
import styled from 'styled-components'
import { dateFormat } from '@utils/format'
import { colors } from '@resources/colors'

const Container = styled(FlexRowBox)`
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 264px;
  padding: 40px 30px;
  box-sizing: border-box !important;
`

const List = styled.span`
  font-size: 8px;
  color: ${colors.secondary};
  margin-left: 6px;
`

const NewList = () => {
  return <List>NEW</List>
}

const RevenueListCard = ({ revenueNotifications }) => {
  return (
    <CardBox style={{ width: '100%', maxWidth: '670px', margin: '15px 0px' }}>
      <Container>
        <H4 style={{ marginBottom: '12px' }}>최근 활동과 메세지</H4>
        {
          revenueNotifications
          && revenueNotifications.map(item => (
            <FlexRowBox
              style={
                {
                  width: '100%',
                  justifyContent: 'space-between',
                  margin: '4px 0',
                }
              }
              key={item.id}
            >
              <FlexRowBox>
                <Caption1 type="Regular">{item.message}</Caption1>
                {!item.isReaded && <NewList />}
              </FlexRowBox>
              <Caption2 style={{ color: '#949494' }} type="Regular">
                {dateFormat(item.createdAt)}
              </Caption2>
            </FlexRowBox>
          ))
        }
      </Container>
    </CardBox>
  )
}

export default RevenueListCard
