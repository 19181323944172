import React from 'react'
import styled, { keyframes } from 'styled-components'

const Equalizer = styled.div`
  align-items: flex-end;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 12px;
  overflow: hidden;
  opacity: 0.8;
`

const EqualizerBar = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  margin-right: 1px;
`

const eqAnimation = keyframes`from {transform: translateY(100%);}to {transform: translateY(0);}`
const Eq = styled.div`
  animation-name: ${eqAnimation};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: ${(props) => (props.duration ? props.duration : '0.3')}s;
  background-color: white;
  position: absolute;
  width: 3px;
  height: 100%;
  transform: translateY(100%);
  will-change: transform;
`

const EqualizerBox = ({ style }) => {
  return (
    <Equalizer style={style}>
      <EqualizerBar>
        <Eq duration={0.3} />
        <Eq duration={0.45} />
      </EqualizerBar>
      <EqualizerBar>
        <Eq duration={0.5} />
        <Eq duration={0.4} />
      </EqualizerBar>
      <EqualizerBar>
        <Eq duration={0.3} />
        <Eq duration={0.35} />
      </EqualizerBar>
      <EqualizerBar>
        <Eq duration={0.4} />
        <Eq duration={0.25} />
      </EqualizerBar>
    </Equalizer>
  )
}

export default EqualizerBox
