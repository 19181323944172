import { SalonMobileTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import QueryString from 'qs'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'

const MobileMixtapePage = () => {
  useRenderLogger('MobileMixtapePage')

  const { mixtapeStore } = useStore()

  const mixtapeDetail = mixtapeStore.mixtapeDetail || null

  const param = document.location.href.split('?')
  const query = QueryString.parse(param[1], {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const param = document.location.href.split('?')
    const query = QueryString.parse(param[1], {
      ignoreQueryPrefix: true,
    })

    if (query) {
      mixtapeStore.fetchMixtapeDetail(query.mixtapeId)
    }
  }, [mixtapeStore])

  return (
    <>
      {!isMobile && <Redirect to={`/plra/${query?.mixtapeId}`} />}
      <SalonMobileTemplate mixtapeDetail={mixtapeDetail} />
    </>
  )
}

export default observer(MobileMixtapePage)
