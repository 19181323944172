import React from 'react'
import {
  CardBox,
  FlexRowBox,
  Tooltip,
  ThreeStepBar,
  CheckTextBox,
} from '@components/molecules'
import styled from 'styled-components'
import { H5, Caption2, H6 } from '@components/atoms'
import { Link } from 'react-router-dom'
import { images } from '@images/'
import { colors } from '@resources/colors'

const Container = styled(FlexRowBox)`
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  height: 368px;
  padding: 40px 30px 0px 30px;
  box-sizing: border-box !important;
`

const Header = styled(FlexRowBox)`
  justify-content: flex-start;
  width: 100%;
  margin: 0px 0px 40px 0px;
`

const StepList = styled(FlexRowBox)`
  width: 100%;
  height: 84px;
  border-top: solid 1px #f4f4f4;
  cursor: pointer;
`

const TooltipContent = styled.div`
  width: 209px;
  padding: 16px;
  color: ${colors.secondary};
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
`

const StepBtn = styled.img`
  width: 16px;
  height: 16px;
`

const RevenueStepCard = ({ currentUser, accountToggle }) => {
  return (
    <CardBox style={{ margin: '15px 0px' }}>
      <Container>
        <Header>
          {
            currentUser && currentUser.revenueLevel >= 2001 ? (
              <>
                <img
                  src={images.green_check_img}
                  alt="money"
                  style={
                    {
                      width: '30px',
                      height: '30px',
                      margin: '0px 23px 0px -1px',
                    }
                  }
                />
                <H5 style={{ margin: '0px 480px 0px 10px' }}>
                환전 신청이 준비되었습니다.
                </H5>
              </>
            ) : currentUser && currentUser.revenueLevel < 1004 ? (
              <>
                <img
                  src={images.money_img}
                  alt="money"
                  style={
                    {
                      width: '30px',
                      height: '30px',
                      margin: '0px 23px 0px -1px',
                    }
                  }
                />
                <H5>환전 신청을 위한 3단계</H5>
                <Tooltip
                  content={
                    <TooltipContent>
                    포인트 환전 신청을 위해 아래 단계를 순서대로 완료해 주세요
                    </TooltipContent>
                  }
                />
                <Caption2
                  color="#949494"
                  style={{ margin: '0px 260px 0px 10px' }}
                >
                아래 단계를 순서대로 마치고 검토를 요청합니다.
                </Caption2>
              </>
            ) : (
              <>
                <img
                  src={images.sandwatch_img}
                  alt="money"
                  style={
                    {
                      width: '30px',
                      height: '30px',
                      margin: '0px 23px 0px -1px',
                    }
                  }
                />
                <H5 style={{ margin: '0px 520px 0px 10px' }}>
                현재 승인 대기중 입니다.
                </H5>
              </>
            )
          }

          <ThreeStepBar
            oneStep={currentUser && currentUser.revenueLevel >= 1001}
            twoStep={currentUser && currentUser.revenueLevel >= 1002}
            threeStep={currentUser && currentUser.revenueLevel >= 1003}
          />
        </Header>
        <StepList
          onClick={
            () =>
              !(currentUser && currentUser.revenueLevel >= 1001)
            && accountToggle()
          }
        >
          <CheckTextBox
            color={'green'}
            checked={currentUser && currentUser.revenueLevel >= 1001}
          />
          <H6 style={{ width: '870px', textAlign: 'left', marginLeft: '30px' }}>
            DJ 활동 약관에 동의하기
          </H6>
          {
            currentUser && currentUser.revenueLevel < 1001 && (
              <StepBtn src={images.move_black_img} alt="next2" />
            )
          }
        </StepList>
        <Link
          to={'/p/exchange/setting-way'}
          style={{ width: '100%', color: '#242424' }}
          disabled={currentUser.revenueLevel !== 1001}
        >
          <StepList>
            <CheckTextBox
              checked={currentUser.revenueLevel >= 1002}
              color={'green'}
            />
            <H6
              color={
                currentUser.revenueLevel >= 1002
                  ? '#242424'
                  : currentUser.revenueLevel !== 1001
                    ? '#dddddd'
                    : false
              }
              style={{ width: '870px', textAlign: 'left', marginLeft: '30px' }}
            >
              환전 수단을 설정하기
            </H6>
            {
              currentUser && currentUser.revenueLevel < 1002 && (
                <StepBtn src={images.move_black_img} alt="next" />
              )
            }
          </StepList>
        </Link>

        <Link
          to={'/p/exchange/need-adult'}
          style={{ width: '100%', color: '#242424' }}
          disabled={currentUser.revenueLevel !== 1002}
        >
          <StepList>
            <CheckTextBox
              checked={currentUser.revenueLevel >= 1003}
              color={'green'}
            />
            <H6
              color={
                currentUser.revenueLevel >= 1003
                  ? '#242424'
                  : currentUser.revenueLevel !== 1002
                    ? '#dddddd'
                    : false
              }
              style={{ width: '870px', textAlign: 'left', marginLeft: '30px' }}
            >
              성인 인증 (부모님 동의서 제출)
            </H6>
            {
              currentUser && currentUser.revenueLevel < 1003 && (
                <StepBtn src={images.move_black_img} alt="next" />
              )
            }
          </StepList>
        </Link>
      </Container>
    </CardBox>
  )
}

export default RevenueStepCard
