import React from 'react'
import styled from 'styled-components'
import { tablet } from '@styles/media'
import { images } from '@images/'
import FlexRowBox from './FlexRowBox'

const TagBox = styled(FlexRowBox)`
  justify-content: center;
  width: fit-content;
  padding: 12px;
  height: 40px;
  border-radius: 20px;
  background-color: #f4f4f4;
  color: #646464;
  font-size: 14px;
  margin: 7px;

  @media ${tablet} {
    font-size: 10px;
    padding: 6px;
    height: 20px;
  }
`

const TempTagBox = ({ item, index, onRemove }) => {
  return (
    <TagBox>
      {`#${item.keyword}`}
      <img
        src={images.x_btn_img}
        alt="close_btn"
        style={{ width: '14px', height: '14px', marginLeft: '10px' }}
        onClick={() => onRemove(item, index)}
      />
    </TagBox>
  )
}

export default TempTagBox
