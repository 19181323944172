import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  BodyWrapper,
  LogoImg,
  H1,
  H6,
  FormInput,
  Form,
  WarningMsg,
  Flex,
} from '@components/atoms'
import { RoundBtn, SubmitBtn } from '@components/molecules'
import Button from '@components/atoms/button'
import { mobile } from '@styles/media'
import { useForm } from 'react-hook-form'
import { colors } from '@resources/colors'

// import { Link } from 'react-router-dom';

const FormGroup = styled.div`
  margin: 50px 0 40px 0;
  width: 518px;

  @media ${mobile} {
    width: 100%;
  }
`

const ResponsiveH6 = styled(H6)`
  margin: 32px 0px 50px 0px;
  @media ${mobile} {
    margin: 0;
  }
`

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const FindPasswordTemplate = ({
  history,
  email,
  setEmail,
  complete,

  getCheckPhone,
  getCheckEmail,
  time,
  timer,
  code,
  setCode,
  smsCheck,
  setComplete,
  clearInterval,
  changePassword,
}) => {
  const { register, errors, getValues } = useForm({
    mode: 'onChange',
  })

  const [targetEmail, setTargetEmail] = useState('')

  const [existUser, setExistUser] = useState('') // 휴대폰 번호 회원 유무 체크
  const [sendConfirm, setSendConfirm] = useState(false) // 인증번호 전송완료 여부
  const [correct, setCorrect] = useState(false) // 인증번호 일치 여부
  const [phoneList, setPhoneList] = useState([]) // 개인정보 수집이용 동의 여부
  const [showChangePassword, setShowChangePassword] = useState(false) // 이메일 찾기 완료 여부

  const [confirmEmail, setConfirmEmail] = useState(false) // 인증 폰번호와 이메일이 일치하는지 여부
  const [equalPassword, setEqualPassword] = useState('none') // 비밀번호 일치 여부
  const [authPhone, setAuthPhone] = useState('') // 인증된 폰번호

  // 가입 회원인지 체크 및 가입 이메일 저장
  const verifyPhone = async () => {
    if (errors.phone && errors.phone.type === 'pattern') return
    const check = await getCheckPhone(getValues().phone)
    check
      && check.forEach(element => {
        setPhoneList(prevState => prevState.concat(element.email))
      })

    if (check && check.length === 0) {
      setExistUser('N')
      setPhoneList([])
    }
    else if (check && check.length >= 1) {
      setExistUser('Y')
    }
  }

  // 핸드폰 인증
  const _smsCheck = async e => {
    e.preventDefault()

    const result = await smsCheck(getValues().phone, code)
    if (result && result.status >= 400) {
      setCorrect('N')
    }
    else {
      setCorrect('Y')
      clearInterval()
    }
  }

  const handleShowChangePassword = () => {
    if (!confirmEmail) return
    setAuthPhone(getValues().phone)
    setShowChangePassword(true)
  }

  const handleChangePassword = async () => {
    if (equalPassword !== 'equal') return
    const completed = await changePassword({
      email: targetEmail,
      phone: authPhone,
      token: code,
      password: getValues().newPassword,
    })

    if (!completed) return

    alert('비밀번호가 변경되었습니다.')
    history.push('/p/login')
  }

  useEffect(() => {
    if (correct === 'Y' && targetEmail !== '') {
      if (phoneList.filter(item => item === targetEmail).length > 0) {
        setConfirmEmail(true)
      }
      else {
        setConfirmEmail(false)
      }
    }
  }, [correct, targetEmail, phoneList])

  return (
    <BodyWrapper>
      <LogoImg />

      {
        showChangePassword ? (
          <>
            <H1 style={{ marginBottom: '20px' }}>비밀번호 재설정</H1>
            <ResponsiveH6 color={colors.brown_grey}>
            새로운 비밀번호를 설정해주세요
            </ResponsiveH6>
            <FormGroup>
              <FormInput
                placeholder="새 비밀번호 (숫자, 영문자 조합 8자 이상)"
                name="newPassword"
                type="password"
                ref={
                  register({
                    required: true,
                    minLength: 8,
                    pattern: /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
                  })
                }
              />
              <FormInput
                style={{ marginTop: 20 }}
                placeholder="새 비밀번호 확인"
                type="password"
                onChange={
                  e => {
                    if (
                      e.target.value !== ''
                  && getValues().newPassword !== e.target.value
                    ) {
                      setEqualPassword('unequal')
                    }
                    if (getValues().newPassword === e.target.value) {
                      setEqualPassword('equal')
                    }
                  }
                }
              />

              {
                equalPassword === 'unequal' && (
                  <WarningMsg>비밀번호가 일치하지 않습니다</WarningMsg>
                )
              }
              {
                errors.newPassword && errors.newPassword.type === 'pattern' && (
                  <WarningMsg>영문 숫자 조합으로 입력해주세요.</WarningMsg>
                )
              }
            </FormGroup>
            <RoundBtn
              text="비밀번호 변경"
              borderColor={colors.primary}
              color={colors.white}
              style={
                {
                  width: 320,
                  height: 48,
                  marginTop: '1rem',
                  backgroundColor: colors.primary,
                }
              }
              onClick={handleChangePassword}
            />
          </>
        ) : (
          <>
            <H1 style={{ marginBottom: '20px' }}>비밀번호 찾기</H1>

            <H6 color="#646464">
            가입한 휴대폰 번호를 입력하시면
              <br />
            비밀번호를 찾으실 수 있습니다
            </H6>

            <Form>
              {/* 휴대폰 번호 */}
              <FormGroup>
                <FormInput
                  placeholder="가입한 이메일 입력"
                  name="email"
                  value={targetEmail}
                  onChange={e => setTargetEmail(e.target.value)}
                />
                {
                  correct === 'Y' && !confirmEmail && (
                    <WarningMsg>일치하는 회원정보가 없습니다</WarningMsg>
                  )
                }
                <InputBox style={{ display: 'inherit' }}>
                  <Flex>
                    <FormInput
                      name="phone"
                      ref={
                        register({
                          required: true,
                          pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                        })
                      }
                      placeholder="ex. 01005260221 ( - 없이 )"
                      disabled={sendConfirm}
                      style={
                        existUser === 'N'
                      || (errors.phone && {
                        border: '1px solid #ea4653',
                      })
                      }
                      onChange={() => verifyPhone()}
                    />
                    {
                      !errors.phone && existUser === 'Y' ? (
                        sendConfirm ? (
                          <Button
                            disabled
                            style={
                              {
                                color: '#66bc6a',
                                padding: '0px 20px 0px 40px',
                                whiteSpace: 'pre',
                              }
                            }
                          >
                        전송완료
                          </Button>
                        ) : (
                          <Button
                            onClick={
                              e => {
                                time(e, getValues().phone)
                                setSendConfirm(true)
                              }
                            }
                            style={
                              {
                                color: colors.secondary,
                                padding: '0px 19px',
                                whiteSpace: 'pre',
                              }
                            }
                          >
                        인증번호 전송
                          </Button>
                        )
                      ) : (
                        <Button
                          style={
                            {
                              color: '#949494',
                              padding: '0px 19px',
                              whiteSpace: 'pre',
                            }
                          }
                          disabled
                        >
                      인증번호 전송
                        </Button>
                      )
                    }
                  </Flex>
                  {
                    errors.phone && errors.phone.type === 'pattern' && (
                      <WarningMsg>올바르지 않은 휴대폰 번호입니다.</WarningMsg>
                    )
                  }
                  {
                    existUser === 'N' && phoneList.length === 0 && (
                      <WarningMsg>일치하는 회원정보가 없습니다</WarningMsg>
                    )
                  }
                  {/* {
              !errors.phone && phoneList.length > 0 && (
                <>
                  <WarningMsg>해당 번호로 가입된 계정이 있습니다.</WarningMsg>
                  {phoneList && <ExistPhoneList>{phoneList}</ExistPhoneList>}
                </>
              )
            } */}
                  {
                    errors.phone && errors.phone.type === 'required' && (
                      <WarningMsg>휴대폰 인증을 해주세요.</WarningMsg>
                    )
                  }
                </InputBox>

                {/* 인증번호 입력 박스 */}
                {
                  sendConfirm ? (
                    <>
                      <InputBox>
                        <Flex>
                          <Flex
                            style={
                              {
                                position: 'relative',
                                width: '100%',
                              }
                            }
                          >
                            <FormInput
                              style={{ width: '100%', height: 'fit-content' }}
                              placeholder="인증번호 6자리 입력"
                              value={code}
                              onChange={e => setCode(e.target.value)}
                              disabled={correct === 'Y'}
                            />
                            {
                              correct !== 'Y' && (
                                <span
                                  style={
                                    {
                                      color: '#646464',
                                      position: 'absolute',
                                      right: '5%',
                                      zIndex: '10',
                                      paddingTop: '10px',
                                    }
                                  }
                                >
                                  {timer}
                                </span>
                              )
                            }
                          </Flex>
                          {
                            correct === 'Y' ? (
                              <Button
                                style={
                                  {
                                    color: '#66bc6a',
                                    padding: '0px 20px 0px 40px',
                                    whiteSpace: 'pre',
                                  }
                                }
                                disabled
                              >
                          인증완료
                              </Button>
                            ) : code !== '' && timer !== '00:00' ? (
                              <Button
                                onClick={e => _smsCheck(e)}
                                style={
                                  {
                                    color: colors.secondary,
                                    padding: '0px 20px 0px 40px',
                                    whiteSpace: 'pre',
                                  }
                                }
                              >
                          인증하기
                              </Button>
                            ) : (
                              <Button
                                style={
                                  {
                                    color: '#949494',
                                    padding: '0px 20px 0px 40px',
                                    whiteSpace: 'pre',
                                  }
                                }
                                disabled
                              >
                          인증하기
                              </Button>
                            )
                          }
                        </Flex>
                        <Flex>
                          <Flex
                            style={
                              {
                                width: '750px',
                              }
                            }
                          >
                            {
                              correct === 'N' ? (
                                <WarningMsg>인증번호가 올바르지 않습니다.</WarningMsg>
                              ) : timer === '00:00' ? (
                                <WarningMsg>시간이 초과되었습니다.</WarningMsg>
                              ) : (
                                <WarningMsg />
                              )
                            }
                          </Flex>
                          {
                            correct !== 'Y' && (
                              <WarningMsg
                                onClick={
                                  e => {
                                    time(e, getValues().phone)
                                    setSendConfirm(true)
                                  }
                                }
                                style={
                                  {
                                    padding: 0,
                                    color: '#949494',
                                    cursor: 'pointer',
                                    marginRight: 'initial',
                                    width: '100%',
                                  }
                                }
                              >
                          인증번호 재전송
                              </WarningMsg>
                            )
                          }
                        </Flex>
                      </InputBox>
                    </>
                  ) : (
                    false
                  )
                }
              </FormGroup>

              <SubmitBtn
                disabled={!confirmEmail}
                onClick={handleShowChangePassword}
                style={{ marginTop: '30px' }}
              >
              찾기
              </SubmitBtn>
              {/* </Link> */}
            </Form>
          </>
        )
      }
    </BodyWrapper>
  )
}

export default FindPasswordTemplate
