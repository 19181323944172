const PlayerManager = () => {
  const getValue = (key?: string) => {
    try {
      return JSON.parse(localStorage.getItem(key || 'player') || 'null')
    }
    catch {
      return null
    }
  }

  const setValue = (key: string, value: any) => {
    localStorage.setItem(key || 'player', JSON.stringify(value))
  }

  const removeValue = (key: string) => {
    const value = getValue(key)
    if (value) {
      delete value[key]
    }

    const parse_cookie = JSON.stringify(value)
    if (value) {
      localStorage.setItem(key, parse_cookie)
    }
  }

  const clearValue = (key: string) => {
    localStorage.removeItem(key || 'player')
  }
  /**
   * 즐겨찾는 코인 관리 끝
   */

  return {
    getValue,
    setValue,
    removeValue,
    clearValue,
  }
}

export default PlayerManager
