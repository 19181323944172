import { Flex } from '@components/atoms'
import {
  IconValueBox,
  SalonUserStarListItem,
  SwitchTabs,
  YnPopup,
} from '@components/molecules'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { addComma } from '@utils/string'
import React from 'react'
import styled from 'styled-components'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'

import {
  LIVE_ROOM_MESSAGE_TYPE,
  LIVE_ROOMS_HAVE_USERS_LEVEL,
} from '@consts/liveRoom'
import { useHistory } from 'react-router-dom'
import { throttle } from 'lodash'
import { PresentStarForm } from '..'

const SalonListenerInfo = ({
  style,
  currentUser,
  currLiveRoom,
  currLiveRoomUserList,
  starCount,
  _toggleUserReport,
  presentStarToLive,
  sendChat,
  isSwitchOn,
  setIsSwitchOn,
  loadSalonUser,
}) => {
  const history = useHistory()
  const { liveRoomStore } = useStore()

  const currLiveRoomUser = liveRoomStore.currLiveRoomUser || null
  const currentPlayingLiveRoomAudio =
    liveRoomStore.currentPlayingLiveRoomAudio || null
  const currentPlayingLiveRoomMixtape =
    liveRoomStore.currentPlayingLiveRoomMixtape || null

  const { level = 0 } = currLiveRoomUser || {}

  const isShowMixtapeInfo =
    currLiveRoom
    && (currentPlayingLiveRoomMixtape || currentPlayingLiveRoomAudio)
    && (level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
      || !currLiveRoom['isMutedMixtape'])

  const [starPlan, setStarPlan] = React.useState('')

  const { isShowing: showLack, toggle: toggleLack } = YnPopup.usePopup()

  const handleSend = throttle(async () => {
    if (starPlan === '' || Number(starPlan) < 10) return
    if (currentUser && currentUser.currentStar < starPlan) {
      toggleLack()
      return
    }

    const result = await presentStarToLive(currLiveRoom?.id, {
      receivingUserId: currLiveRoom?.userId,
      starValue: Number(starPlan),
    })

    if (result?.response?.status === 409) {
      toggleLack()
      return
    }

    await sendChat(
      currentUser,
      `${addComma(Number(starPlan))}`,
      LIVE_ROOM_MESSAGE_TYPE['STAR'],
    )

    setStarPlan('')
  }, 250)

  return (
    <Info style={style && style}>
      <SwitchTabs
        isSwitchOn={isSwitchOn}
        setIsSwitchOn={setIsSwitchOn}
        firstSwitchName="참여자 목록"
        secondSwitchName="별 선물하기"
        firstSwitchContents={
          <Flex type="column">
            <Flex>
              <IconValueBox
                iconSrc={images.salon_listener_img}
                value={currLiveRoomUserList ? currLiveRoomUserList.length : 0}
                style={{ marginRight: 8 }}
              />
              <IconValueBox
                iconSrc={images.salon_star_img}
                value={starCount || 0}
                iconWidth={24}
                iconHeight={24}
              />
            </Flex>
            <UserStarList
              isShowMusicInfo={
                (level < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
                  && isShowMixtapeInfo)
                || level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
              }
            >
              {
                currLiveRoomUserList
                && currLiveRoomUserList.map((item, index) => (
                  <SalonUserStarListItem
                    loadSalonUser={loadSalonUser}
                    item={item}
                    key={item.id}
                    index={index}
                    _toggleUserReport={_toggleUserReport}
                    currentUserId={currentUser?.id}
                  />
                ))
              }
            </UserStarList>
          </Flex>
        }
        secondSwitchContents={
          <PresentStarForm
            isSalon
            currentUser={currentUser}
            starPlan={starPlan}
            setStarPlan={setStarPlan}
            handleSend={handleSend}
          />
        }
      />
      <YnPopup.View
        isShowing={showLack}
        toggle={toggleLack}
        yText="충전하기"
        title="별이 부족합니다"
        body="충전하기 버튼을 눌러 별을 충전해주세요"
        onOk={() => history.push('/p/star/main?tab=charge')}
      />
    </Info>
  )
}

export default observer(SalonListenerInfo)

const Info = styled(Flex)`
  flex: 1;
  width: 100%;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.white_ff};
  padding: 24px;
  height: 100%;
  /* max-height: 640px; */
`

const UserStarList = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  max-height: ${({ isShowMusicInfo }) => (isShowMusicInfo ? '608px' : '708px')};
  min-height: 508px;
  height: 100%;

  overflow-y: scroll;
  padding: 16px 0;

  flex-direction: column;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`
