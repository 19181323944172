import React, { useEffect } from 'react'
import { SubscriptionTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const SubscriptionPage = () => {
  useRenderLogger('SubscriptionPage')
  const { authStore, purchaseStore } = useStore()

  const currentUser = authStore.currentUser || null

  const myPlan = purchaseStore.myPlan || null
  const getMyPlan = purchaseStore.getMyPlan || (() => {})
  const isValidatedStudent = purchaseStore.isValidatedStudent || null
  const getValidateStudent = purchaseStore.getValidateStudent || (() => {})
  const getPaymentParams = purchaseStore.getPaymentParams || (() => {})
  const getPurchasePlans = purchaseStore.getPurchasePlans || (() => {})

  useEffect(() => {
    window.scrollTo(0, 0)
    getMyPlan()
    getValidateStudent()
  }, [getMyPlan, getValidateStudent])

  return (
    <SubscriptionTemplate
      user={currentUser}
      isValidatedStudent={isValidatedStudent}
      getPaymentParams={getPaymentParams}
      // purchasePlan={purchasePlan}
      getPurchasePlans={getPurchasePlans}
      myPlan={myPlan}
    />
  )
}

export default observer(SubscriptionPage)
