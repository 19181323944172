import React from 'react'
import styled from 'styled-components'
import { Modal, H4, Caption1 } from '@components/atoms'
import { RoundBtn, FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'

const ModalBody = styled(FlexRowBox)`
  width: 580px;
  height: 240px;
  flex-direction: column;
`

const View = ({ isShowing, toggle }) => {
  return (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody>
          <H4>환전 신청 완료</H4>
          <Caption1 style={{ margin: '20px 0 60px 0' }} color="#949494">
            익월 15일 부터 영업일 기준 3일 이내에
            <br />
            누적된 신청에 대한 심사 및 설정하신 계좌로 입금될 예정 입니다.
            <br />
            심사 결과에 따라 환전 승인 혹은 수익 창출 및 환전 신청이 중지될 수
            있습니다.
            <br />
            <br />
            신청 상태 및 환전 결과는 사이트의 환전 내역, 메세지, 그리고 이메일로
            안내 드립니다.
          </Caption1>
          <RoundBtn
            text="확인"
            style={
              {
                width: '230px',
                height: '48px',
                border: 'solid 2px #f4f4f4',
                backgroundColor: colors.primary,
                color: '#ffffff',
              }
            }
            onClick={toggle}
          />
        </ModalBody>
      </Modal.View>
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
