import React from 'react'
import { H3 } from '@components/atoms'

const Standard = ({ style }) => {
  return (
    <H3 color="#66bc6a" style={style}>
      Standard
    </H3>
  )
}

export default Standard
