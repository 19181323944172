import React from 'react'
import { CardBox, FlexRowBox, Tooltip } from '@components/molecules'
import styled from 'styled-components'
import { H4, Caption1 } from '@components/atoms'
import { convertToPricingComma } from '@utils/string'
import { Link } from 'react-router-dom'
import { dateFormat } from '@utils/format'
import { colors } from '@resources/colors'
import { images } from '@images/'

const Section = styled(FlexRowBox)`
  width: 33%;
  flex-direction: column;
`

const TooltipContent = styled.div`
  width: 209px;
  padding: 16px;
  color: ${colors.secondary}
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
`

const RevenueInfoCard = ({
  currentUser,
  confirmToggle,
  stopToggle,
  lastRevenue,
}) => {
  const confirmAccount = () => {
    if (currentUser && currentUser.revenueLevel < 1005) {
      confirmToggle()
    }
    else if (currentUser && currentUser.revenueLevel > 2001) {
      stopToggle()
    }
  }

  return (
    <CardBox style={{ margin: '15px 0px' }}>
      <FlexRowBox
        style={{ width: '100%', maxWidth: '1000px', height: '217px' }}
      >
        <Section>
          <FlexRowBox>
            <H4>마지막 환전 후 포인트</H4>
            <Tooltip
              content={
                <TooltipContent>
                  이전 잔여 포인트에서 마지막 환전 완료 포인트를 제외한 잔여
                  포인트입니다.
                </TooltipContent>
              }
            />
          </FlexRowBox>
          <Caption1 style={{ margin: '16px 0px 29px 0px', color: '#949494' }}>
            {
              lastRevenue
                ? dateFormat(lastRevenue.responseAt)
                : '환전 내역 없음'
            }
          </Caption1>
          <H4>
            {convertToPricingComma(currentUser && currentUser.currentRevenue)}
            {' '}
            포인트
          </H4>
        </Section>
        <Section
          style={
            {
              margin: '40px 0px',
              borderRight: '2px solid #f4f4f4',
              borderLeft: '2px solid #f4f4f4',
            }
          }
        >
          <H4 style={{ marginBottom: '65px' }}>총 누적 포인트</H4>
          <H4>
            {
              convertToPricingComma(
                currentUser && currentUser.cumulativeRevenue,
              )
            }
            {' '}
            포인트
          </H4>
        </Section>
        <Section>
          <FlexRowBox>
            <H4>환전 가능 포인트</H4>
            {/* <Tooltip
              style={{ right: -90 }}
              content={
                <TooltipContent>
                  마지막 환전 후 잔액이 2만 포인트 이상일 때 1만 포인트 단위로
                  환전 신청이 가능하 며 환전 심사중인 포인트를 제한 포인트가
                  표시 됩니다.
                </TooltipContent>
              }
            /> */}
          </FlexRowBox>
          <FlexRowBox
            style={{ margin: '16px 0px 29px 0px', cursor: 'pointer' }}
          >
            {
              currentUser && currentUser.revenueLevel !== 2001 ? (
                <Caption1
                  style={{ color: '#949494' }}
                  onClick={() => confirmAccount()}
                >
                환전 신청하기
                </Caption1>
              ) : (
                <Link
                  to="/p/exchange/account"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Caption1 color={colors.secondary}>환전 신청하기</Caption1>
                </Link>
              )
            }
            <img
              src={images.move_default_img}
              alt="move"
              style={{ width: '14px', height: '14px' }}
            />
          </FlexRowBox>
          {
            currentUser && currentUser.revenueLevel < 1003 ? (
              <H4 color="#949494">승인 요청 전</H4>
            ) : currentUser && currentUser.revenueLevel < 1005 ? (
              <H4 color="#949494">승인 대기중</H4>
            ) : (
              <H4>
                {
                  convertToPricingComma(
                    currentUser && currentUser.receivableRevenue,
                  )
                }
              포인트
              </H4>
            )
          }
        </Section>
      </FlexRowBox>
    </CardBox>
  )
}

export default RevenueInfoCard
