import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import AudioPlayer from 'react-h5-audio-player'

const Sounds = () => {
  const { appStateStore } = useStore()
  const { soundInfo } = appStateStore

  const listPlayerRef = React.useRef() // player element

  React.useEffect(() => {
    if (soundInfo?.url) {
      listPlayerRef.current.audio.current.play()
    }
  }, [soundInfo])

  return (
    <AudioPlayer
      ref={listPlayerRef}
      src={soundInfo?.url ? soundInfo.url : null}
      autoPlay
      style={{ display: 'none' }}
    />
  )
}

export default observer(Sounds)
