import React, { useState } from 'react'
import styled from 'styled-components'
import {
  H3,
  H4,
  Flex,
  H6,
  FormInput,
  Caption1,
  Caption2,
  Caption3,
} from '@components/atoms'
import { Table, Icon, Tooltip, message, Pagination, Spin } from 'antd'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import {
  YoutubeVideoCastUploadPopup,
  CastUploadRecordingPopup,
  CastUploadTypeSelectPopup,
  CastPublishChangePopup,
  DeletePopup,
  MobileOptionPopup,
  ConfirmPopup,
  RssCastUploadPopup,
  EditRecordingPopup,
} from '@components/molecules'
import { TagSelectModal } from '@components/organisms'
import { useHistory } from 'react-router-dom'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { dateMDMS } from '@utils/format'
import moment from 'moment'
import {
  CAST_UPLOAD_TYPE,
  YOUTUBE_SYNC_ERROR,
  YOUTUBE_SYNC_STATE,
  YOUTUBE_UPLOAD_TYPE,
  DEFAULT_TAG_LIST,
  SUBJECT_TAG_LIST,
  RSS_SYNC_STATE,
  RSS_SYNC_ERROR,
  NOT_ALLOWED_TAG_LIST,
} from '@consts/cast'
import { PageLayout } from '..'
// import 'antd/dist/antd.css'

const CAST_UPLOAD_SELECT_TYPE = {
  UPLOAD: 'UPLOAD',
  RECORDING: 'RECORDING',
  YOUTUBE: 'YOUTUBE',
}

const TAG_SELECT_TYPE = {
  RSS: 'RSS',
  YOUTUBE: 'YOUTUBE',
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

const NameBox = styled.div`
  float: left;
  p {
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  @media ${mobile} {
    width: 192px;
  }
`

const DotImg = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const ThreeDot = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    display: none;
  }

  span {
    margin: 0 10px;
  }

  @media ${mobile} {
    span {
      display: none;
    }
    img {
      display: block;
    }
  }
`
// const Box = styled.div`
//   position: absolute;
//   z-index: 1000;
//   top: 25px;
//   border-radius: 12px;
//   box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
//   background-color: #ffffff;
// `

// const TooltipContent = styled.div`
//   width: 435px;
//   padding: 14px;
//   color: ${colors.secondary};
//   font-family: NotoSansCJKkr-Regular;

//   @media ${mobile} {
//     width: 308px;
//     padding: 12px;
//   }
// `

const Title = styled.div`
  font-size: 14px;
  color: ${colors.black_24};
  font-family: NotoSansCJKkr-Medium;
  margin-bottom: 6px;
`
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.span`
  font-size: 12px;
  margin-right: 8px;
  color: ${colors.brown_grey};
  font-family: NotoSansCJKkr-Medium;
`
const SyncBtnText = styled.span`
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

const CastPublishChangeBtn = styled.div`
  width: 67px;
  color: ${props => (props.isPublished ? '#ea4653' : '#242424')};
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

// 업로드 목록이 있을 시, 흐름 홈
const CastManageListTemplate = ({
  castList = [],
  updateCast,
  deleteCast,
  user,
  fetchMyUploadedRecordingList,
  castListCount,
  currentPage,
  setCurrentPage,
  isLoading,
  myLiveRoomRecordingList,
  requestYoutubeSync,
  requestRssSync,
  updateUser,
  /**
   * recording
   */
  liveRoomRecordingList,
  editLiveRoomRecording,
  deleteLiveRoomRecording,
}) => {
  const history = useHistory()

  const [targetId, setTargetId] = useState(null)
  const [target, setTarget] = useState({})
  const [castUploadSelectType, setCastUploadSelectType] = useState(
    CAST_UPLOAD_SELECT_TYPE['UPLOAD'],
  )
  const [selectedRecording, setSelectedRecording] = useState(null)
  const [uploadYoutubeLink, setUploadYoutubeLink] = useState('')
  const [uploadRssLink, setUploadRssLink] = useState('')
  const [youtubeResultPopupContent, setYoutubeResultPopupContent] = useState(
    null,
  )
  const [rssResultPopupContent, setRssResultPopupContent] = useState(null)

  const [youtubeUploadType, setYoutubeUploadType] = useState(
    YOUTUBE_UPLOAD_TYPE['VIDEO'],
  )
  const [tagSelectType, setTagSelectType] = useState(null)
  const [
    isShowYoutubeChannelSyncButton,
    setIsShowYoutubeChannelSyncButton,
  ] = useState(user['isShowYoutubeChannelSyncButton'])
  const [isShowRssInfoSyncButton, setIsShowRssInfoSyncButton] = useState(
    user['isShowRssInfoSyncButton'],
  )

  const { isShowing: showPopup, toggle: popupToggle } = DeletePopup.useModal()

  const {
    isShowing: showUploadYoutubeResultPopup,
    toggle: popupUploadYoutubeResultToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: showUploadRssResultPopup,
    toggle: popupUploadRssResultToggle,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showRssCastUploadPopup,
    toggle: popupRssCastUploadToggle,
  } = RssCastUploadPopup.useModal()
  const {
    isShowing: showYoutubeVideoCastUploadPopup,
    toggle: popupYoutubeVideoCastUploadToggle,
  } = YoutubeVideoCastUploadPopup.useModal()
  const {
    isShowing: showCastUploadRecordingPopup,
    toggle: popupCastUploadRecordingToggle,
  } = CastUploadRecordingPopup.useModal()
  const {
    isShowing: showCastUploadTypeSelectPopup,
    toggle: popupCastUploadTypeSelectToggle,
  } = CastUploadTypeSelectPopup.useModal()
  const {
    isShowing: showCastPublishChangePopup,
    toggle: popupCastPublishChangeToggle,
  } = CastPublishChangePopup.useModal()
  const {
    // isShowing: showMobile,
    toggle: mobileToggle,
  } = MobileOptionPopup.useModal()

  const {
    isShowing: showTagSelect,
    toggle: toggleTagSelect,
  } = TagSelectModal.useModal()

  const loadDelete = (info, isMobile) => {
    if (isMobile) {
      mobileToggle()
      popupToggle()
    }
    else {
      setTargetId(info.id)
      popupToggle()
    }
  }

  const loadMobilePopup = info => {
    setTarget(info)
    setTargetId(info.id)
    mobileToggle()
  }

  const loadCastPublishChange = info => {
    setTarget(info)
    popupCastPublishChangeToggle()
  }

  const loadCastUploadTypeSelectPopup = info => {
    popupCastUploadTypeSelectToggle()
  }

  const onDelete = async () => {
    // call delete cast func
    await deleteCast(targetId)
    popupToggle()
  }

  const onCastUploadTypeSelect = async () => {
    switch (castUploadSelectType) {
      case CAST_UPLOAD_SELECT_TYPE['UPLOAD']:
        history.push({
          pathname: '/p/cast/upload',
          state: { type: CAST_UPLOAD_TYPE['UPLOAD'] },
        })
        break
      case CAST_UPLOAD_SELECT_TYPE['RECORDING']:
        await fetchMyUploadedRecordingList({ userId: user['id'] })
        popupCastUploadRecordingToggle()
        break
      case CAST_UPLOAD_SELECT_TYPE['YOUTUBE']:
        setUploadYoutubeLink('')
        setYoutubeUploadType(YOUTUBE_UPLOAD_TYPE['VIDEO'])
        popupYoutubeVideoCastUploadToggle()
        break
      default:
        break
    }

    popupCastUploadTypeSelectToggle()
  }

  const onCastRssUpload = async () => {
    setUploadRssLink('')
    popupRssCastUploadToggle()
  }

  const onCastYoutubeChannelUpload = async () => {
    setUploadYoutubeLink('')
    setYoutubeUploadType(YOUTUBE_UPLOAD_TYPE['CHANNEL'])
    popupYoutubeVideoCastUploadToggle()
  }

  const onCastPublishChange = async () => {
    const _cast = {
      id: target['id'],
      isPublished: !target['isPublished'],
    }
    await updateCast(_cast)
    popupCastPublishChangeToggle()
  }

  const onCastUploadRecordingSelect = async () => {
    if (!selectedRecording) {
      message.warn('캐스트로 만들 녹음 오디오를 선택해주세요.')
      return
    }
    const recording = {
      ...selectedRecording,
      LiveRoom: {
        imageUri: selectedRecording['LiveRoom'].imageUri,
        imageUrl: selectedRecording['LiveRoom'].imageUrl,
      },
    }

    const _recording = { ...recording }
    delete _recording['stores']

    history.push({
      pathname: `/p/cast/upload`,
      state: {
        type: CAST_UPLOAD_TYPE['RECORDING'],
        recording: _recording,
      },
    })
    popupCastUploadRecordingToggle()
  }

  const onRssConvertNext = () => {
    if (uploadRssLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    popupRssCastUploadToggle()
    setTagSelectType(TAG_SELECT_TYPE['RSS'])
    toggleTagSelect()
  }

  const onYoutubeConvertNext = () => {
    if (uploadYoutubeLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }

    popupYoutubeVideoCastUploadToggle()
    setTagSelectType(TAG_SELECT_TYPE['YOUTUBE'])
    toggleTagSelect()
  }

  const onRssCastUpload = async (tagKeywordList = []) => {
    if (uploadRssLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    const param = {
      tagKeywordList: tagKeywordList.map(item => item.keyword),
      rssUrl: uploadRssLink,
    }

    const result = await requestRssSync(param)
    setRssResultPopupContent(null)

    if (result.isSuccess) {
      const title = 'RSS 캐스트 연동 신청 완료'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'RSS 링크의 캐스트 변환 등록이 진행중입니다.'}
          </Caption2>
          <div style={{ display: 'flex' }}>
            <Caption2 color={'#242424'}>{'최대 3일'}</Caption2>
            <Caption2 color={'#949494'}>{'까지 소요될 수 있습니다.'}</Caption2>
          </div>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title = 'RSS 캐스트 연동 오류'
      let body = <></>
      switch (RSS_SYNC_ERROR[result.errorLabel]) {
        case RSS_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'RSS 채널 링크가 정확하지 않습니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['DUPLICATED_RSS']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 RSS 링크입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'네트워크가 불안정합니다'}</Caption2>
            </div>
          )
          break
        default:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'서버 오류. 관리자에게 문의하세요.'}
              </Caption2>
            </div>
          )
          break
      }

      setRssResultPopupContent({ title, body })
    }
    else {
      const title = 'RSS 캐스트 연동 오류'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'서버 오류. 관리자에게 문의하세요.'}
          </Caption2>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }

    popupUploadRssResultToggle()
    toggleTagSelect()
  }

  const onYoutubeVideoCastUpload = async (tagKeywordList = []) => {
    if (uploadYoutubeLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    const houseParams = {
      user,
    }

    const param = {
      tagKeywordList,
    }

    if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']) {
      param['youtubeVideoUrl'] = uploadYoutubeLink
    }
    else if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['CHANNEL']) {
      param['youtubeChannelUrl'] = uploadYoutubeLink
    }

    const result = await requestYoutubeSync(param)

    setYoutubeResultPopupContent()
    if (result.isSuccess) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환'
          : '채널 연동 완료'
      const body =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO'] ? (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 동영상이 캐스트로 변환 중입니다.'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#242424'}>{'최대 10분'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        ) : (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 채널의 동영상 캐스트 변환 등록이'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#949494'}>{'진행중입니다. '}</Caption2>
              <Caption2 color={'#242424'}>{'최대 1일'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        )
      setYoutubeResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환 실패'
          : '채널 연동 실패'
      let body = <></>
      switch (YOUTUBE_SYNC_ERROR[result.errorLabel]) {
        case YOUTUBE_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크가 정확하지 않습니다.'
                    : '유튜브 채널 링크가 정확하지 않습니다.'
                }
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break

        case YOUTUBE_SYNC_ERROR['DUPLICATED_CHANNEL']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'이미 연동된 채널입니다.'}</Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['DUPLICATED_VIDEO']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 동영상입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'네트워크가 불안정합니다.'}
              </Caption2>
            </div>
          )
          break
        default:
          return
      }

      setYoutubeResultPopupContent({ title, body })
    }

    popupUploadYoutubeResultToggle()
    toggleTagSelect()
  }

  const getPublishedDate = cast => {
    if (cast?.audioFileId) {
      if (cast?.AudioFile?.isYoutubeAudioFile) {
        return cast?.AudioFile?.youtubePublishedAt
      }
      return cast?.publishedAt || cast?.createdAt
    }
    return cast?.publishedAt || cast?.createdAt
  }

  const getDurationConvert = duration => {
    const minutes = Math.floor(duration / 60 || 0)
    const seconds = duration % 60
    if (minutes) {
      return `${minutes}분 ${seconds}초`
    }

    return `${seconds}초`
  }

  /**
   * recording
   */
  const [liveRoomRecording, setLiveRoomRecording] = useState({ title: 'init' })
  const [targetIdRecording, setTargetIdRecording] = useState(null)

  const {
    isShowing: showPopupRecordingEdit,
    toggle: popupToggleRecordingEdit,
  } = EditRecordingPopup.useModal()

  const _editLiveRoomRecording = async () => {
    const { id, title } = liveRoomRecording
    const result = await editLiveRoomRecording({
      liveRoomRecordingId: id,
      title,
    })

    if (result) {
      popupToggleRecordingEdit()
    }
  }
  const {
    isShowing: showPopupRecordingDelete,
    toggle: popupToggleRecordingDelete,
  } = DeletePopup.useModal()
  const loadEditRecording = info => {
    setLiveRoomRecording(info)
    popupToggleRecordingEdit()
  }
  const loadDeleteRecording = (info, isMobile) => {
    if (isMobile) {
      mobileToggleRecording()
      popupToggleRecordingDelete()
    }
    else {
      setTargetIdRecording(info.id)
      popupToggleRecordingDelete()
    }
  }
  const { toggle: mobileToggleRecording } = MobileOptionPopup.useModal()

  const onDeleteRecording = () => {
    _deleteLiveRoomRecording(targetIdRecording)

    popupToggleRecordingDelete()
  }

  const _deleteLiveRoomRecording = async id => {
    await deleteLiveRoomRecording({ liveRoomRecordingId: id })
  }

  const castColumns = [
    {
      key: 'image',
      dataIndex: 'imageUrl',
      width: '72px',
      render: imageUrl => (
        <img
          style={
            {
              width: '72px',
              height: '72px',
              background: 'aliceblue',
              borderRadius: '4px',
            }
          }
          src={imageUrl || images.live_room_recording_default_img}
          alt="img"
        />
      ),
    },
    {
      key: 'title',
      dataIndex: 'title',
      width: '500px',
      render: (text, record) => {
        return (
          <div>
            <Title color="#242424">{record.title}</Title>
            <InfoContainer>
              <Info>
                {moment(getPublishedDate(record)).format('YYYY. MM. DD')}
              </Info>
              <img
                style={
                  {
                    width: '12px',
                    height: '12px',
                    marginRight: '4px',
                  }
                }
                src={images.like_gray_img}
                alt={'like count'}
              />
              <Info>{record.likeCount}</Info>
              <img
                style={
                  {
                    width: '12px',
                    height: '12px',
                    marginRight: '4px',
                  }
                }
                src={images.play_gray_img}
                alt={'play count'}
              />
              <Info>{record.playCount}</Info>
              <Info>{`${getDurationConvert(record.duration)}`}</Info>
            </InfoContainer>
          </div>
        )
      },
    },
    {
      key: 'function',
      dataIndex: 'download',
      width: '148px',
      render: (text, record) => {
        return (
          <ThreeDot>
            <span>
              <Tooltip title="공개 상태 변경">
                <CastPublishChangeBtn
                  isPublished={record.isPublished}
                  onClick={() => loadCastPublishChange(record)}
                  style={{ cursor: 'pointer' }}
                >
                  {record.isPublished ? '비공개하기' : '공개하기'}
                </CastPublishChangeBtn>
              </Tooltip>
            </span>
            {
              // <span>
              //   <a href={record.mp3Url} download>
              //     <Tooltip title="다운로드">
              //       <Icon
              //         type="download"
              //         style={{ fontSize: 25, color: colors.primary }}
              //       />
              //     </Tooltip>
              //   </a>
              // </span>
            }
            <span
              style={{ position: 'relative' }}
              onClick={
                () => {
                // await fetchSongDetail(record.id)
                  history.push(`/p/cast/edit/${record.id}`)
                }
              }
            >
              <Tooltip title="수정">
                <Icon
                  type="edit"
                  style={
                    {
                      fontSize: 25,
                      color: colors.primary,
                      cursor: 'pointer',
                    }
                  }
                />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="삭제">
                <Icon
                  type="delete"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      loadDelete(record, false)
                    }
                  }
                />
              </Tooltip>
            </span>
            <DotImg
              src={images.three_dot_img}
              alt="dot"
              onClick={() => loadMobilePopup(record)}
            />
          </ThreeDot>
        )
      },
    },
  ]

  const recordingColumns = [
    {
      key: 'image',
      dataIndex: 'imageUrl',
      width: '40px',
      render: (text, record) => {
        if (record?.LiveRoom?.imageUrl) {
          return (
            <img
              style={{ width: '40px', height: '40px', background: 'aliceblue' }}
              src={record?.LiveRoom?.imageUrl}
              alt="img"
            />
          )
        }
        return <></>
      },
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '400px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{record?.title}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'date',
      dataIndex: 'date',
      width: '100px',
      render: (text, record) => {
        return (
          <NameBox>
            <Caption1 color="#242424">{dateMDMS(record?.createdAt)}</Caption1>
          </NameBox>
        )
      },
    },
    {
      key: 'function',
      dataIndex: 'download',
      width: '148px',
      render: (text, record) => {
        return (
          <ThreeDot>
            {
              //   <span>
              //   <Tooltip title="재생">
              //     <Icon
              //       type="play-circle"
              //       style={{ fontSize: 25, color: colors.primary }}
              //       onClick={
              //         e => {
              //           const song = {
              //             mp3Uri: record.mp4Uri,
              //             name: record.title,
              //             singer: '',
              //             imageUri: record.LiveRoom?.imageUri,
              //           }
              //           setNowSong(song)
              //         }
              //       }
              //     />
              //   </Tooltip>
              // </span>
            }
            <span>
              <a href={record.mp4Url} download>
                <Tooltip title="다운로드">
                  <Icon
                    type="download"
                    style={{ fontSize: 25, color: colors.primary }}
                  />
                </Tooltip>
              </a>
            </span>
            <span
              style={{ position: 'relative' }}
              onClick={
                async () => {
                // await fetchSongDetail(record.id)
                // history.push('/p/audio/song')
                }
              }
            >
              <Tooltip title="수정">
                <Icon
                  type="edit"
                  style={
                    {
                      fontSize: 25,
                      color: colors.primary,
                      cursor: 'pointer',
                    }
                  }
                  onClick={
                    e => {
                      loadEditRecording(record)
                    }
                  }
                />
              </Tooltip>
            </span>
            <span>
              <Tooltip title="삭제">
                <Icon
                  type="delete"
                  style={{ fontSize: 25, color: colors.primary }}
                  onClick={
                    e => {
                      loadDeleteRecording(record, false)
                    }
                  }
                />
              </Tooltip>
            </span>
            {
              // <DotImg
              //   src={images.three_dot_img}
              //   alt="dot"
              //   onClick={() => loadMobilePopup(record)}
              // />
            }
          </ThreeDot>
        )
      },
    },
  ]

  if (!user) return <></>
  return (
    <>
      {
        rssResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadRssResultPopup}
            toggle={
              () => {
                popupUploadRssResultToggle()
                window.location.reload()
              }
            }
            title={rssResultPopupContent.title}
            body={rssResultPopupContent.body}
          />
        )
      }
      {
        youtubeResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadYoutubeResultPopup}
            toggle={
              () => {
                popupUploadYoutubeResultToggle()
                window.location.reload()
              }
            }
            title={youtubeResultPopupContent.title}
            body={youtubeResultPopupContent.body}
          />
        )
      }
      <DeletePopup.View
        isShowing={showPopup}
        toggle={popupToggle}
        onDelete={onDelete}
        body={'삭제 시 저장된 캐스트의\n해당 데이터가 모두 삭제됩니다.'}
      />
      <RssCastUploadPopup.View
        isShowing={showRssCastUploadPopup}
        toggle={popupRssCastUploadToggle}
        onChange={onRssConvertNext}
        title={'RSS 캐스트 연동'}
        body={
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#242424'}>
                {'운영하고 계시는 팟캐스트 RSS를 입력해주시면,'}
              </Caption2>
              <Caption2 color={'#242424'}>
                {'오디오 콘텐츠를 제공하실 수 있습니다'}
              </Caption2>
            </div>
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '288px',
                  height: '58px',
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  marginTop: '16px',
                }
              }
            >
              <Caption3 color={'#ea4653'}>
                *타인의 저작물 혹은 뮤직비디오를 변환하여 캐스트를 제공할 시
              </Caption3>
              <Caption3 color={'#ea4653'}>
                저작권법에 따라 계정 제한 및 처벌받을 수 있습니다.
              </Caption3>
            </div>

            <FormInput
              style={
                {
                  border: '0px',
                  backgroundColor: '#f4f4f4',
                  marginTop: '16px',
                }
              }
              placeholder={'RSS 링크를 입력해주세요'}
              value={uploadRssLink}
              onChange={e => setUploadRssLink(e.target.value.trim())}
            />
          </div>
        }
      />
      <YoutubeVideoCastUploadPopup.View
        isShowing={showYoutubeVideoCastUploadPopup}
        toggle={popupYoutubeVideoCastUploadToggle}
        onChange={onYoutubeConvertNext}
        title={
          youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
            ? '유튜브 동영상 변환'
            : '유튜브 채널 캐스트 연동'
        }
        body={
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크를 입력해주시면,'
                    : '유튜브 채널 링크를 입력해주시면,'
                }
              </Caption2>
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '자동으로 캐스트로 변환하여 등록합니다.'
                    : '전체 동영상을 자동으로 캐스트로 변환하여 등록합니다.'
                }
              </Caption2>
            </div>
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '288px',
                  height: '58px',
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  marginTop: '16px',
                }
              }
            >
              <Caption3 color={'#ea4653'}>
                *타인의 저작물 혹은 뮤직비디오를 변환하여 캐스트를 제공할 시
              </Caption3>
              <Caption3 color={'#ea4653'}>
                저작권법에 따라 계정 제한 및 처벌받을 수 있습니다.
              </Caption3>
            </div>

            <FormInput
              style={
                {
                  border: '0px',
                  backgroundColor: '#f4f4f4',
                  marginTop: '16px',
                }
              }
              placeholder={
                youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                  ? '유튜브 동영상 주소 입력'
                  : '유튜브 채널 주소 입력'
              }
              value={uploadYoutubeLink}
              onChange={e => setUploadYoutubeLink(e.target.value.trim())}
            />
          </div>
        }
      />
      <CastUploadRecordingPopup.View
        isShowing={showCastUploadRecordingPopup}
        toggle={popupCastUploadRecordingToggle}
        onChange={onCastUploadRecordingSelect}
        title={'녹음한 오디오'}
        body={
          <div
            style={
              {
                overflowY: 'scroll',
                height: '402px',
                maxWidth: '408px',
              }
            }
          >
            {
              myLiveRoomRecordingList.map(recording => {
                let backgroundColor = null
                if (recording && selectedRecording) {
                  backgroundColor =
                  recording['id'] === selectedRecording['id'] ? '#f4f4f4' : null
                }
                return (
                  <div
                    style={
                      {
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '8px',
                        paddingRight: '16px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        cursor: 'pointer',
                        backgroundColor,
                      }
                    }
                    onClick={
                      () => {
                        setSelectedRecording(recording)
                      }
                    }
                    key={recording['id']}
                  >
                    <img
                      style={
                        {
                          width: '40px',
                          height: '40px',
                          borderRadius: '4px',
                          marginRight: '8px',
                        }
                      }
                      src={
                      recording?.LiveRoom?.imageUrl
                      || images.live_room_recording_default_img
                      }
                      alt={'img'}
                    />
                    <div>
                      <div
                        style={
                          {
                            maxWidth: '290px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontFamily: 'NotoSansCJKkr-Regular',
                            fontSize: '12px',
                            color: '#242424',
                          }
                        }
                      >
                        {recording?.title}
                      </div>
                      <div
                        style={
                          {
                            fontFamily: 'NotoSansCJKkr-Regular',
                            fontSize: '12px',
                            color: '#949494',
                          }
                        }
                      >
                        {dateMDMS(recording?.createdAt)}
                      </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                      style={
                        {
                          fontFamily: 'NotoSansCJKkr-Regular',
                          fontSize: '12px',
                          color: '#949494',
                          alignSelf: 'center',
                        }
                      }
                    >
                      {getDurationConvert(recording?.duration)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      />
      <CastUploadTypeSelectPopup.View
        isShowing={showCastUploadTypeSelectPopup}
        toggle={popupCastUploadTypeSelectToggle}
        onChange={onCastUploadTypeSelect}
        title={'캐스트 등록'}
        body={
          <div>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['UPLOAD'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['UPLOAD']
              }
            >
              업로드로 추가
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['UPLOAD']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button select'}
              />
            </UploadTypeSelectItem>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['RECORDING'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['RECORDING']
              }
            >
              녹음한 오디오로 추가
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['RECORDING']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button unselected'}
              />
            </UploadTypeSelectItem>
            <UploadTypeSelectItem
              onClick={
                () =>
                  setCastUploadSelectType(CAST_UPLOAD_SELECT_TYPE['YOUTUBE'])
              }
              isSelected={
                castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['YOUTUBE']
              }
            >
              유튜브 동영상 변환
              <RadioButton
                src={
                  castUploadSelectType === CAST_UPLOAD_SELECT_TYPE['YOUTUBE']
                    ? images.radio_select_img
                    : images.radio_unselect_img
                }
                alt={'button unselected'}
              />
            </UploadTypeSelectItem>
          </div>
        }
      />
      <CastPublishChangePopup.View
        isShowing={showCastPublishChangePopup}
        toggle={popupCastPublishChangeToggle}
        onChange={onCastPublishChange}
      />
      <PageLayout style={{}} footerStyle={{ marginBottom: '80px' }}>
        <ListBox>
          <Flex
            style={
              {
                marginBottom: '40px',
                width: '100%',
                justifyContent: 'flex-start',
                position: 'relative',
                alignItems:'flex-end',
              }
            }
          >
            <H3>녹음 관리</H3>

            <UploadBtn onClick={loadCastUploadTypeSelectPopup}>
              캐스트 등록
            </UploadBtn>
          </Flex>
          {
            !(liveRoomRecordingList?.length > 0) && false
              ? (
                <>
                  <H6 color={colors.grey_64} style={{ marginTop: 160 }}>
                  녹음이 없습니다
                  </H6>
                </>
              )
              : (
                <>
                  <Table
                    columns={recordingColumns}
                    dataSource={liveRoomRecordingList}
                    // pagination={{ pageSize: 10, defaultPageSize: 10 }}
                    pagination={false}
                    pageSize={10}
                    size={'small'}
                    loading={
                      {
                        spinning: isLoading,
                        indicator: <Spin size="large" />,
                      }
                    }
                    style={
                      {
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
                        width: '100%',
                      }
                    }
                    rowKey="id"
                  />
                  <Pagination
                    style={{ marginTop: '20px' }}
                    pageSize={10}
                    current={currentPage}
                    onChange={setCurrentPage}
                    total={castListCount || 0}
                    showSizeChanger={false}
                    showQuickJumper={false}
                  />
                </>
              )
          }
        </ListBox>
        <TagSelectModal.View
          isShowing={showTagSelect}
          toggle={toggleTagSelect}
          // mbti={wathcedMbti}
          subjectTagList={SUBJECT_TAG_LIST}
          defaultTagList={DEFAULT_TAG_LIST}
          validate={
            _tag => {
              const isInvalidateTag = NOT_ALLOWED_TAG_LIST.map(
                __tag => __tag.keyword,
              ).includes(_tag)
              if (isInvalidateTag) {
                message.error('해당 태그는 일반 태그로 등록할 수 없습니다.')
                return false
              }
              return true
            }
          }
          onSubmit={
            tagList => {
              if (tagSelectType === TAG_SELECT_TYPE['YOUTUBE']) {
                onYoutubeVideoCastUpload(tagList)
              }
              else if (tagSelectType === TAG_SELECT_TYPE['RSS']) {
                onRssCastUpload(tagList)
              }
            }
          }
        />
        {/** recording */}
        <DeletePopup.View
          isShowing={showPopupRecordingDelete}
          toggle={popupToggleRecordingDelete}
          onDelete={onDeleteRecording}
          body={'삭제 시 저장된 녹음 오디오의\n해당 데이터가 모두 삭제됩니다.'}
        />
        <EditRecordingPopup.View
          isShowing={showPopupRecordingEdit}
          toggle={popupToggleRecordingEdit}
          onEdit={_editLiveRoomRecording}
          body={
            <FormInput
              style={{ backgroundColor: '#f4f4f4', borderWidth: 0 }}
              value={liveRoomRecording.title}
              onChange={
                event => {
                  const _liveRoomRecording = {
                    ...liveRoomRecording,
                    title: event.target.value,
                  }

                  setLiveRoomRecording(_liveRoomRecording)
                }
              }
            />
          }
        />
        {
          // <MobileOptionPopup.View
          //   isShowing={showMobileRecording}
          //   toggle={mobileToggleRecording}
          //   info={targetRecording}
          //   onDelete={() => loadDeleteRecording(false, true)}
          //   setNowSong={setNowSongRecording}
          //   mp3Url={targetRecording.mp3Url}
          // />
        }
      </PageLayout>
    </>
  )
}

export default CastManageListTemplate

const RssSyncButton = ({ state = 0, onClick = () => {} }) => {
  switch (state) {
    case RSS_SYNC_STATE['NO']:
      return <SyncBtnText onClick={onClick}>RSS 캐스트 연동</SyncBtnText>
    case RSS_SYNC_STATE['REQUEST']:
      return (
        <SyncBtnText
          onClick={
            () =>
              message.info(
                'RSS 연동이 요청되었습니다. 관리자 승인 후 연동됩니다.',
              )
          }
        >
          RSS 연동 요청 완료
        </SyncBtnText>
      )
    case RSS_SYNC_STATE['REJECTED']:
      return (
        <SyncBtnText
          onClick={
            () => {
              message.info('올바른 링크로 다시 신청해주세요.')
              onClick()
            }
          }
        >
          RSS 연동 반려
        </SyncBtnText>
      )
    case RSS_SYNC_STATE['ACCEPTED']:
      return (
        <SyncBtnText
          onClick={
            () =>
              message.info('RSS가 연동되었습니다. (완료까지 최대 1일 소요)')
          }
        >
          RSS 연동 완료
        </SyncBtnText>
      )
    case RSS_SYNC_STATE['ADMIN']:
      return (
        <SyncBtnText
          onClick={() => message.info('관리자가 직접 연동한 RSS 입니다.')}
        >
          RSS 관리자 직접 연동
        </SyncBtnText>
      )
    default:
      return <></>
  }
}

const YoutubeChannelButton = ({ state = 0, onClick = () => {} }) => {
  switch (state) {
    case YOUTUBE_SYNC_STATE['NOT_REQUEST']:
      return (
        <SyncBtnText onClick={onClick}>유튜브 채널 캐스트 연동</SyncBtnText>
      )
    case YOUTUBE_SYNC_STATE['REQUEST']:
      return (
        <SyncBtnText
          onClick={
            () =>
              message.info(
                '채널 연동이 요청되었습니다. 관리자 승인 후 연동됩니다.',
              )
          }
        >
          채널 연동 요청 완료
        </SyncBtnText>
      )
    case YOUTUBE_SYNC_STATE['ACCEPTED']:
      return (
        <SyncBtnText
          onClick={
            () =>
              message.info('채널 연동이 진행중입니다. (최대 1일 소요)')
          }
        >
          채널 연동 진행중..
        </SyncBtnText>
      )
    case YOUTUBE_SYNC_STATE['REJECTED']:
      return (
        <SyncBtnText
          onClick={
            () => {
              message.info('올바른 채널로 다시 신청해주세요.')
              onClick()
            }
          }
        >
          채널 연동 반려
        </SyncBtnText>
      )
    case YOUTUBE_SYNC_STATE['SERVER_ERROR']:
      return (
        <SyncBtnText
          onClick={
            () => {
              message.info(
                '채널 연동중 에러가 발생했습니다. 연동을 다시 신청해주세요.',
              )
              onClick()
            }
          }
        >
          채널 연동 에러
        </SyncBtnText>
      )
    case YOUTUBE_SYNC_STATE['SUCCESS']:
      return (
        <SyncBtnText
          onClick={() => message.info('이미 채널 연동이 완료되었습니다.')}
        >
          유튜브 채널 연동 완료
        </SyncBtnText>
      )
    default:
      return <></>
  }
}

const RssBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;

  width: 204px;
  height: 32px;
  cursor: pointer;

  border-radius: 20px;
  border: solid 1px #d4d4d4;

  position: absolute;
  right: 360px;

  color: '#242424'
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

const YoutubeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;

  width: 224px;
  height: 32px;
  cursor: pointer;

  border-radius: 20px;
  border: solid 1px #d4d4d4;

  position: absolute;
  right: 130px;

  color: '#242424'
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

const UploadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 32px;
  cursor: pointer;

  border-radius: 20px;
  border: solid 1px;
  background-color: #594ee9;

  color: #ffffff;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;

  position: absolute;
  right: 0;
`
const UploadTypeSelectItem = styled.div`
  display: flex;
  width: 288px;
  height: 56px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;

  border: ${props => (props.isSelected ? 'solid 1px #5b49f3' : null)};
  background-color: ${props => (props.isSelected ? '#f8f8ff' : '#ffffff')};
  color: ${props => (props.isSelected ? '#5b49f3' : '#242424')};
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  cursor: pointer;
`

const RadioButton = styled.img`
  flex-direction: row;
  width: 20px;
  height: 20px;
`
