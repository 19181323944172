export const quizData = [
  {
    no: 1,
    type: 'IE',
    text: '사람들과 있을때 나는',
    answer1: '노는건 좋지만\n 너무 많은 사람들이 있으면 기 빨린다.',
    answer2: '스트레스 풀리는 느낌!\n 완전 재밌고 에너지 충전 완료!'
  },
  {
    no: 2,
    type: 'SN',
    text: '친구의 TMI가 길어질 때',
    answer1: '아무 생각 없이 멍을 때린다.',
    answer2: '“저녁 뭐 먹지..?” 등 대화와 상관없는\n상상을 이것저것 한다.'
  },
  {
    no: 3,
    type: 'TF',
    text: '친한 친구가 다른 친구와\n싸운후 고민상담을 한다면',
    answer1: '누가 더 잘못했는지 들어본다.',
    answer2: '“그 친구가 아주 나빴네” 라며\n같이 화를 내준다.'
  },
  {
    no: 4,
    type: 'PJ',
    text: '친구와 함께 가는 여행에서\n내가 여행 계획을 짜야 한다면',
    answer1: '중요한 것만 미리 정해놓고\n나머지는 가서 생각한다.',
    answer2: '사전에 꼼꼼하게 계획을 세운다.'
  },
  {
    no: 5,
    type: 'IE',
    text: '버스에서 내려 걸어가던 중\n10m 앞에 반 친구가 보인다.',
    answer1: '반갑게 달려가 인사한다. “안녕”',
    answer2: '“어차피 이따 볼테니까” 천천히 걸어간다.'
  },
  {
    no: 6,
    type: 'SN',
    text: '편의점에 갔더니 요즘\n핫한 과자가 한봉지 남은걸 발견했다.',
    answer1: '오늘은.. 이거 먹으려 했으니까!\n내가 사려했던 과자를 고른다.',
    answer2: '뭔데 그렇게 핫하지 ?\n궁금해서 사먹어본다.'
  },
  {
    no: 7,
    type: 'TF',
    text: '옷장 청소 중\n 오래된 옷을 발견했다.',
    answer1: '자리만 차지할 듯. 버리자!',
    answer2: '이 옷 입었을때 유럽여행 갔는데..\n이것도 다 추억이니까 냅두자'
  },
  {
    no: 8,
    type: 'PJ',
    text: '친구와 약속이 있을 때\n주로 나는',
    answer1: '준비를 미루고 미루다 결국 지각을 하는 편이다.\n어! 나 진짜 거의 다왔어 ㅎ(사실은 이제 출발)',
    answer2: '약속시간 10분 전에 도착해 있는다.'
  },
  {
    no: 9,
    type: 'IE',
    text: '나의\n인간관계는',
    answer1: '소수의 친구들하고만 완전 끈끈하다.',
    answer2: '넓게 두루두루 친해지는 편이다.'
  },
  {
    no: 10,
    type: 'SN',
    text: '미용사가 한 번도\n시도해본 적 없는\n머리스타일을 제안한다면?',
    answer1: '급격한 변화는 부담스러우니\n천천히 생각해본다.',
    answer2: '미용사 말을 믿고 도전해본다.'
  },
  {
    no: 11,
    type: 'TF',
    text: '친구와 싸우게 된\n나는 친구에게',
    answer1: '나는 이런 점은\n 너가 꼭 고쳤으면 좋겠어',
    answer2: '나는.. 너가 이렇게\n 말해서 서운하고 속상해..'
  },
  {
    no: 12,
    type: 'PJ',
    text: '친구를 만나러 가던 중 사람들이\n몰려 있는 버스킹 공연장을\n발견한 나는',
    answer1: '누구인지 궁금하다.\n일단 가서 구경한다.',
    answer2: '약속시간에 무리가 없는지 고려해본다.'
  }
]