import { colors } from '@resources/colors'
import { parseMentionView } from '@utils/format'
import { commentTimeStamp } from '@utils/time'
import Interweave, { Node } from 'interweave'
import React, { useEffect , useState } from 'react'

import { render } from 'react-dom'
import styled from 'styled-components'

function transform(node: HTMLElement, children: Node[]): React.ReactNode {
  if (node.tagName === 'SPAN') {
    return (
      <span
        style={{ color: colors.primary, cursor: 'pointer' }}
        onClick={() => console.log('good')}
      >
        {children}
      </span>
    )
  }
  if(node.tagName === 'A') {
    return (
      <a
        style={{ color: colors.primary, cursor: 'pointer' }}
        target="_blank"
        href={node?.href || ''}
      >
        {children}
      </a>
    )
  }
}

const formatTimeNode = (txt, key, time) => {
  return `<span>${txt}</span>`
}

const parseContent = async (content, fetchUserAccount, setNewFormatedText) => {
  const _newFormatedText = []

  const parsedMentionContent = await parseMentionView(content, fetchUserAccount)
  const splitedContent = parsedMentionContent.split('\n')

  splitedContent.forEach((retLine, rowIndex) => {
    const timeStamps = commentTimeStamp(retLine)

    if (timeStamps?.length) {
      let lastIndex = 0

      timeStamps.forEach((time, index) => {
        let initialStr = ''

        initialStr = retLine.substring(lastIndex, time.start)

        _newFormatedText.push(initialStr)

        const formattedTime = formatTimeNode(
          time?.hours
            ? ` ${time?.hours}:${time?.minutes}:${time?.seconds} `
            : ` ${time?.minutes}:${time?.seconds} `,
          `${time?.start}-${time?.time}-${time?.end}`,
          time?.time,
        )

        _newFormatedText.push(formattedTime)

        // update last index
        lastIndex = time.end + 1

        if (timeStamps.length - 1 === index) {
          const lastStr = retLine.substr(lastIndex) // remaining string

          _newFormatedText.push(lastStr)
        }
      })
    }
    else {
      _newFormatedText.push(retLine)
    }

    if (rowIndex !== splitedContent.length - 1) {
      _newFormatedText.push('\n')
    }
  })

  return _newFormatedText
}

const MentionCaption = props => {
  const {
    size = '12px',
    content = '',
    align = 'left',
    type = 'Regular',
    color = colors.black_24,
    style = {},
    fetchUserAccount,
    ...customProps
  } = props

  const [newFormatedText, setNewFormatedText] = useState([])

  useEffect(() => {
    parseContent(content, fetchUserAccount)
      .then((_newFormatedText) => {
        setNewFormatedText(_newFormatedText)
      })
    return () => {}
  }, [content, fetchUserAccount, setNewFormatedText])

  return (
    <Caption
      size={size}
      align={align}
      type={type}
      color={color}
      style={style && style}
      {...customProps}
    >
      <Interweave
        content={newFormatedText.join('')}
        transform={transform}
      />
    </Caption>
  )
}

export default MentionCaption

const Caption = styled.p`
  width: 100%;
  text-align: ${({ align }) => align};
  font-family: ${({ type }) => `NotoSansCJKkr-${type}`};}
  font-size: ${({ size }) => size};}
  white-space: pre-wrap;

  > a:visited {
    color: #0066ff;
  }
`
