import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, H3, H5, H6 } from '@components/atoms'
import { CheckTextBox, RoundBtn, FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'

const ModalBody = styled(FlexRowBox)`
  width: 1000px;
  height: 822px;
  flex-direction: column;
`

const Content = styled(FlexRowBox)`
  width: 100%;
  height: 480px;
  overflow: scroll;
  flex-direction: column;
  background-color: #fafafa;
  padding: 24px 30px;
`

const SubTitle = styled.span`
  font-family : NotoSansCJKkr-Bold;
  font-size : 18px;
  width:100%;
  margin: 30px; 0;
`

const ContentText = styled.p`
  font-family: NotoSansCJKkr-Regular;
  font-size: 18px;
  width: 100%;
  line-height: 34px;
`

const View = ({ isShowing, toggle, agreePartnerDJTerms }) => {
  const [isAgree, setIsAgree] = useState(false)

  const handleAgree = async () => {
    if (isAgree) {
      await agreePartnerDJTerms()
    }
    toggle()
  }

  return (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody>
          <H3>파트너 DJ 활동 약관 동의</H3>
          <H5 style={{ margin: '20px 0 40px 0' }}>
            포인트 환전 신청을 위해 약관에 동의해 주세요.
          </H5>
          <Content>
            <span
              style={
                {
                  fontFamily: 'NotoSansCJKkr-Bold',
                  fontSize: '24px',
                  width: '100%',
                }
              }
            >
              흐름(HREUM de salon) 이용자 수익 활동과 환전 이용약관
            </span>
            <SubTitle>약관 개요</SubTitle>

            <ContentText>
              귀하는 흐름(HREUM de salon) 서비스를 이용함으로써 (1)
              흐름(HREUM de salon) 서비스 약관, (2) 흐름(HREUM de
              salon) 유료 서비스 이용약관, (3)흐름(HREUM de
              salon)개인정보처리방침, (4) 흐름(HREUM de salon) 커뮤니티
              서비스 가이드라인정책에 동의하는 것으로 간주됩니다.
              <br />
              만일의 분쟁 발생시, 본 서비스 약관은 위 (1), (2), (3), (4)에
              나열한 정책 및 가이드라인보다 우선됩니다.
              <br />
              {' '}
본 서비스 약관과 기타 흐름(HREUM de salon) 약관을
              주의 깊게 읽어주시기 바랍니다.
              <br />
              위 서비스 약관에서 기입된 것과 같이 ‘이용자’는 ‘귀하’혹은 그와
              비슷한 통상적인 계약을 맺는 상대방을 뜻하는 말로 표현하며 개인
              또는 단체를 의미합니다.
              <br />
              {' '}
‘솔루션’, 그리고 ‘서비스’는 흐름(HREUM de salon)이
              제공하는 모든 종류의 소프트웨어 혹은 하드웨어 서비스, 그리고
              그것을 운영하는 주식회사 나이비(이하 ‘회사’)를 의미 합니다.
              <br />
              ‘포인트’, ‘수익금’ 혹은 ‘수익’이란 솔루션이 제공하는 서비스 내에서
              회사 내부 규정에 의해 이용자에게 분배되는 수익을 뜻합니다.
              <br />
              ‘환전' 혹은 ‘지급'이란 ‘포인트'로 지급된 수익을 귀하가 솔루션에
              등록한 계좌를 통해 현금화 하는 과정을 의미합니다.
              <br />
              ‘이벤트’란 귀하 혹은 타인이 솔루션 이용 중 발생하는 트래픽, 재생,
              좋아요, 등 콘텐츠에 대한 모든 종류의 행동 및 상태를 말합니다.
              <br />
            </ContentText>
            <SubTitle>제 1조 (서비스 이용)</SubTitle>
            <ContentText>
              수익 창출과 지급을 포함한 귀하의 모든 파트너DJ 활동은 솔루션이
              승인한 파트너DJ 계정을 통해서만 가능 합니다.
              <br />
              {' '}
회사는 국내외 법률적 근거 혹은 귀하와 계약을 체결한 서비스
              이용약관을 근거로 서비스 이용을 제한하거나 차단할 수 있습니다.
              <br />
              수익 활동 및 지급을 위한 이용약관에 동의하고 지급 신청을 함으로서
              귀하가 개인일 경우 만19세 이상의 민사상 행위 능력이 있음 혹은
              부모(혹은 후견인, 보호자 등)의 동의를 받은 것으로 간주됩니다.
              <br />
              {' '}
수익창출 및 지급 신청 서비스에서 귀하는 원칙적으로 한개의
              계정을 사용할 수 있습니다.
              <br />
              {' '}
귀하의 통제 혹은 통제에 참여하는 계정이 복수일 경우 이하의
              약관 내용에 따라 복수 계정으로 창출되는 수익의 일부 혹은 전부를
              지급받지 못할 수 있으며 향후 파트너DJ 활동 또한 제한 또는 차단 당
              할 수 있습니다.
              <br />
              귀하는 수익창출 및 지급 신청을 솔루션이 제공하고 안내하는 기술적인
              방법 혹은 오프라인 수단을 통해서만 이용할 수 있습니다.
              <br />
              흐름(HREUM de salon) 솔루션을 서비스 이용 약관 및 적용되는
              법률을 위반하는 방법으로 악용하지 마십시오. 예를 들어 솔루션이
              지정한 방법 외의 방법으로 솔루션의 운영을 방해하는 행위 따위를
              하지 말아야 합니다.
              <br />
            </ContentText>
            <SubTitle>제 2조 (서비스 및 약관 변경)</SubTitle>
            <ContentText>
              솔루션은 언제나 유저들에게 최고의 경험을 주기 위해 최선을 다하고
              있습니다.
              <br />
              이에 따라 회사는 솔루션에 얼마든지 새로운 기능을 추가하거나 운영
              방식을 변경할 수 있으며 일부 또는 전체의 기능이 삭제될 수
              있습니다.
              <br />
              회사는 귀하가 회사와 계약한 모든 약관의 내용을 수정할 권한을
              보유합니다.
              <br />
              회사는 수정된 부분을 본 페이지에 게시하며 그 사안이 시급하다고
              판단할 경우 예고 없이 약관 내용을 변경하고 솔루션의 기능을 수정할
              수 있습니다.
              <br />
              {' '}
이에 동의하지 않을 경우 수정된 약관 및 기능이 적용되는
              부분의 서비스 이용을 중단해야 합니다.
              <br />
            </ContentText>
            <SubTitle>제 3조 (수익 창출 및 지급)</SubTitle>
            <ContentText>
              귀하는 솔루션이 제시한 방법으로 수익 창출 활동을 할 수 있으며, DJ
              활동을 통한 수익 창출은 DJ로 승격된 이후부터 가능합니다. 솔루션이
              지정하는 방법으로 포인트 환전 심사 자격을 거친 이후에 환전 신청을
              할 수 있습니다.
              <br />
              솔루션과 맺은 약관과 본 약관의 제 4조에 따라 창출된 수익금을 지급
              신청의 대상이 되는 기간 동안 및 수익금이 지불되는 일자까지 귀하의
              계정이 솔루션과 맺은 약관의 내용, 그리고 관련 법률을 모두
              준수하였다고 판단하는 경우 귀하의 솔루션 내 활동에서 플라 재생,
              채널 방문 빈도 등의 콘텐츠와 관련된 일련의 유효하고 적법한
              이벤트에 관련하여 수익금을 지급 받을 것입니다.
              <br />
              수익금 지급 신청 및 지급은 아래와 같은 방식으로 진행됩니다.
              <br />
              솔루션이 지정한 기간(1달)동안 누적하여 신청한 수익금 지급 신청을
              회사 내규를 통해 검토하고 유효한 신청에 대하여 수익금을
              지급합니다.
              <br />
              20,000포인트 이상 누적된 지급 가능한 수익금에 대하여 10,000포인트
              단위로 신청이 가능합니다.
              <br />
              유효한 이벤트 및 유효한 신청 여부의 기준은 오로지 회사의 내규를
              통해 결정 됩니다.
              <br />
              회사가 귀하의 계정에 대한 약관, 관련 법률 등을 준수하는 양호한
              계정임을 조사하는 과정 중에는 수익 창출 및 지급에 대한 일체의
              서비스 이용이 보류 되거나 연기될 수 있습니다.
              <br />
              귀하는 올바르게 수익금이 지급될 수 있도록 지급 받는 계좌의 정보,
              연락처 등 개인정보를 바르게 제공하고 유지해야할 의무를 갖습니다.
              <br />
              회사는 조사 결과 계정의 상태가 양호하지 않다고 판단될 경우 계정이
              그러한 상태였던 기간을 고려하여 이전 기간에 회사가 귀하에게
              부당하게 지급했을 수 있는 금액을 30일 이내에 환불하도록 요구할 수
              있는 권한을 가집니다.
              <br />
              그리고 솔루션은 해당 조사에 대한 자세한 내용을 귀하에게 이메일 등
              전자적인 방법을 통해 전달합니다.
              <br />
              귀하는 회사가 대금을 지급받은 귀하의 콘텐츠 이용에 대해서만
              수익금을 받을 권리가 있다는 점을 인정하고 이에 동의합니다.
              <br />
              {' '}
만일 어떠한 사유에서든지 회사가 광고주 및 대금을 지급할
              의무가 있는 제 3자로 부터 지급받지 못하거나 회사가 상환할 경우
              귀하는 해당 콘텐츠에 대한 어떠한 수익금도 지급받을 권리가 없으며
              회사는 지급을 보류 및 취소할 수 있습니다.
              <br />
              회사는 귀하에게 지급하는 수익금을, 무효 활동으로 발생한 금액으로
              회사가 판단하는 금액을 제외하기 위해 지급을 보류하거나 조정할
              권한을 갖습니다.
              <br />
              {' '}
무효 활동은 다음을 포함하되 이에 국한되지 않습니다.
              <br />
              1. 귀하가 제어한다고 판단되는 컴퓨터 혹은 스마트폰 등의
              전자기기에서 발생한 클릭수, 노출수, 쿼리 또는 기타 이벤트를
              포함하여, 사람, 봇(bot), 자동화된 프로그램 또는 유사 장치에 의해
              발생된 콘텐츠에 대한 스팸 등의 무효 이벤트 2. 서비스 이용 과정 중
              허위진술이 있거나 타 이용자에게 콘텐츠에 대한 이벤트 행동을
              유도하는 등의 행위를 청탁하여 발생한 이벤트 3. 전자적인 방법을
              이용하여 수익창출 및 지급에 필요한 필수적인 이벤트 혹은 수익창출
              및 지급에 필요한 측정을 방해하는 이용자로부터 발생한 이벤트 4.
              솔루션의 모든 약관과 정책을 준수하지 않는 모든 이벤트 5. 귀하가
              제어한다고 판단되는 다른 계정에서 발생하는 모든 이벤트 그 외
              솔루션이 고의적인 위반행위를 나타내는 무효 활동을 탐지하는 경우
              귀하의 계정에 대한 수익창출 및 지급에 필요한 모든 이벤트로 비롯된
              수익금 및 지급금을 이에 따라 조정할 권리를 회사가 가집니다.
              <br />
              또한 귀하는 회사가 위와 같이 조정할 경우, 귀하에게 서비스 이용에
              관한 수익금을 지급받을 권리가 없다는 점에 대하여 인정하고
              동의합니다.
              <br />
            </ContentText>
            <SubTitle>
              제 4 조 (계약 종료, 중지 및 향후 수익금의 지급에 대한 권리)
            </SubTitle>
            <ContentText>
              솔루션은 경고 또는 사전 통지 없이 언제든지, 귀하의 무효 활동 또는
              솔루션 정책을 미준수 등의 사유로 귀하의 계정에 대한 향후 수익금
              지급을 일시적으로 중지하거나, 서비스 이용을 중지 또는 차단하거나,
              또는 귀하의 계정을 중지 또는 차단할 권한을 보유합니다.
              <br />
              회사는 귀하가 연속 6개월 이상의 기간 동안 활동을 하지 않는 경우
              귀하의 서비스 이용을 종료하고 귀하의 계정을 폐쇄할 수 있습니다.
              <br />
              {' '}
회사가 계정 활동이 없다는 이유로 귀하의 계정을 폐쇄하는
              경우, 그리고 귀하 계정의 마지막 지급 후 남은 수익금(이하 ‘잔액’)이
              적용되는 기준금액 이상인 경우, 회사는 해당 잔액을 본 약관 제3조
              내용에 따라 처리합니다.
              <br />
              {' '}
회사가 귀하의 계정을 폐쇄 혹은 차단한 경우, 귀하는 서비스
              이용을 위하여 새롭게 가입 신청을 제출할 수 있습니다.
              <br />
              솔루션에서 무효 활동을 야기하거나 이를 방지하지 못하거나 기타
              방식으로 솔루션 정책을 준수하지 못하는 등 솔루션 약관 위반으로
              인하여 회사가 귀하의 계정을 폐쇄하거나 차단하는 경우, 귀하는
              이전의 모든 서비스 이용에 대하여 향후 수익금을 지급받을 권리를
              가지지 않습니다.
              <br />
              귀하가 솔루션 약관을 위반하거나 회사가 귀하의 계정을 폐쇄 혹은
              차단한 경우, 귀하는 신규 계정을 생성하는 것이 금지되며, 회사의
              다른 서비스 이용에 제한이 있을 수 있습니다.
              <br />
              귀하의 서비스 이용과 관련하여 귀하에게 지급되었거나 지급 보류된
              수익금에 대한 이의를 제기할 경우, 이는 전자적인 방법으로 솔루션이
              공지 혹은 공개한 내용에 따라 전달해야 하며, 이러한 지급, 혹은
              지급에 대한 미이행 또는 계정에 대한 서비스 이용 제한 조치가
              이루어진 날로부터 30일 이내에 회사에게 전달해야 합니다.
              <br />
              {' '}
귀하가 이를 회사에게 전달하지 않거나 귀하의 귀책사유로
              회사가 이를 수신하지 못할 경우, 귀하는 그러한 논쟁이 되는 수익금
              지급 또는 이용 제한과 관련하여 일체의 청구를 포기하는 것으로
              간주됩니다.
              <br />
              귀하는 솔루션이 공지 혹은 공개한 방법으로 언제든 서비스 이용을
              중지할 수 있습니다.
              <br />
              {' '}
귀하의 계정은 회사가 귀하의 통지를 수령한 날로부터 10
              영업일 이내에 종료된 것으로 봅니다.
              <br />
              귀하가 귀하의 계정을 종료하고, 귀하 계정의 잔액이 적용되는
              기준금액 이상인 경우, 회사는 해당 잔액을 귀하가 서비스 이용을
              종료한 달의 마지막 날로부터 약 90일 이내에 본 약관의 제 3조에 따라
              지급합니다.
              <br />
              {' '}
적용되는 기준금액에 이르지 않는 모든 잔액은 지불되지
              않습니다.
              <br />
            </ContentText>
            <SubTitle>제 5조 (세금)</SubTitle>
            <ContentText>
              귀하와 회사 사이에서, 사이트에 게재된 이용자의 수익 창출을 위한
              광고와 관련하여 회사와 광고주 간의 거래와 연관된 세금이 있을 경우
              그 모든 세금의 책임은 회사에게 있습니다.
              <br />
              {' '}
회사의 순 수입을 기초로 한 세금 외에, 서비스와 관련된 모든
              해당 세금(있을 경우) 그 책임이 귀하에게 있습니다.
              <br />
              {' '}
필요할 경우, 서비스에 관하여 회사가 귀하에게 지급하는 모든
              지급금은 세금을 포함하여 처리되며 이는 조정이 불가능합니다.
              <br />
              {' '}
회사가 귀하에게 지급할 금액에서 세금을 보류해야 할 경우,
              회사는 귀하에게 이 사실을 통지해야 하며 보류 금액을 공제한
              지급금을 지급합니다.
              <br />
            </ContentText>
            <SubTitle>제6조 (테스트)</SubTitle>
            <ContentText>
              귀하는 회사가 귀하의 솔루션 이용에 영향을 미칠 수 있는 테스트를
              정기적 혹은 비정기적으로 시행하는 것을 승인합니다.
              <br />
              {' '}
테스트 결과가 회사의 계획에 맞게 시행, 도출 및 유효할 수
              있도록, 귀하는 회사가 위 테스트를 통지 없이 수행할 수 있도록
              승인합니다.
              <br />
            </ContentText>
            <SubTitle>제7조 (지적 재산과 상표 특징)</SubTitle>
            <ContentText>
              솔루션 약관에 명시적으로 기술된 경우 이외에, 쌍방 중 그 누구도
              상대방이 소유한 또는 상대방의 라이센스 제공자가 소유한 지적
              재산권의 권리, 소유권 또는 이익을 취하지 말아야 합니다.
              <br />
              회사가 귀하에게 솔루션 이용과 관련하여 소프트웨어를 제공할 경우,
              회사는 귀하에게 그 소프트웨어의 사용에 대한 비독점적, 서브
              라이선스를 부여할 수 없는 라이선스를 부여합니다.
              <br />
              {' '}
본 라이선스의 유일한 목적은 솔루션의 약관에 의거하여 허가된
              방식으로, 회사가 제공한 것과 같이 귀하가 서비스의 혜택을 이용하고
              누릴 수 있게 하기 위한 것입니다.
              <br />
              {' '}
법이 제한을 금지하거나 귀하가 회사의 서면 허가를 갖고 있지
              않는 한, 귀하는 솔루션의 서비스 또는 소프트웨어가 포함된 서비스의
              일부를 복사, 수정, 배포, 판매 또는 임대할 수 없으며, 그
              소프트웨어의 소스 코드를 리버스 엔지니어링 또는 추출하려는 시도를
              하지 말아야 합니다.
              <br />
              {' '}
귀하는 회사의 서비스, 소프트웨어 또는 문서에 첨부되거나
              포함된 회사의 저작권 공고, 상표 특징, 또는 기타 독점권 공고를
              제거, 또는 모호하게 처리, 또는 변경하지 말아야 합니다.
              <br />
              회사는 오로지 귀하의 서비스 사용에 관하여 그리고 본 약관에 따라,
              귀하가 회사의 상표 이름, 상표, 서비스마크, 로고, 도메인 이름 및
              기타 특유의 상표 특징(이하 ‘상표 특징’)을 사용할 수 있는
              비독점적인, 서브 라이선스를 부여할 수 없는 라이선스를 부여합니다.
              <br />
              {' '}
회사는 언제든지 본 라이선스를 취소할 권리를 보유합니다.
              <br />
              {' '}
귀하는 귀하가 회사의 상표 특징을 사용하여 발생되는 영업권이
              회사의 소유라는 점에 동의합니다.
              <br />
              회사는 회사의 프리젠테이션, 마케팅 자료, 고객 명단 및 재무보고서에
              귀하의 이름, 상표 특징을 포함할 수도 있으며 귀하는 이에
              동의합니다.
              <br />
            </ContentText>
            <SubTitle>제8조 (프라이버시 보호)</SubTitle>
            <ContentText>
              회사의 개인정보보호정책은 귀하가 회사의 솔루션을 이용할 경우
              회사가 귀하의 개인정보를 어떻게 처리하고 보호하는지를 설명하고
              있습니다.
              <br />
              {' '}
회사의 솔루션을 이용함으로써, 귀하는 회사가 회사의
              개인정보보호정책에 따라 그 데이터를 사용할 수 있도록 동의하는
              것으로 간주됩니다.
              <br />
              귀하는 귀하가 솔루션을 이용할 때 항상 회사가 전자적인 방법으로
              정확하게 표시하고 쉽게 접근 가능한 개인정보보호정책이 있을 것을
              확인합니다.
              <br />
              {' '}
해당 개인정보보호정책은 최종사용자에게 쿠키에 대한 명확하고
              포괄적인 정보, 기기 특정 정보, 위치 정보 및 서비스와 관련하여
              최종사용자의 기기에 저장되고, 접근되고, 또는 수집되는 기타
              정보이며 해당할 경우 쿠키 관리를 위한 최종 사용자의 옵션에 대한
              정보도 포함됩니다.
              <br />
              {' '}
귀하는 상업적으로 합리적인 노력을 기울여 최종 사용자의
              기기에 법률에 의하여 최종 사용자의 동의가 필요한 서비스와 관련하여
              쿠키, 기기특정 정보, 로케이션 정보 또는 기타 정보의 저장 및 접속에
              동의하도록 해야 합니다.
              <br />
            </ContentText>
            <SubTitle>제9조 (기밀 유지)</SubTitle>
            <ContentText>
              귀하는 회사의 서면 동의 없이 회사 기밀 정보를 공개하지 않는 것에
              동의합니다.
              <br />
              “회사 기밀 정보”는 다음 사항을 포함합니다.
              <br />
              ①모든 회사의 소프트웨어 및 기술, 서비스에 관한 문서 ②솔루션에 관한
              퍼포먼스와 관련된 클릭률 또는 기타 통계 ③솔루션에서의 모든
              비공개의 베타 또는 실험적 기능의 존재, 이에 관한 정보 ④회사에 의해
              기밀로 표시되거나 기타 사정에 의해 보통 기밀로 간주되는 기타 정보
              회사 기밀 정보는 귀하가 솔루션을 이용하기 전에 이미 알고 있었으며
              귀하의 잘못을 통해서가 아닌데도 대중에게 알려지고 귀하에 의해
              독점적으로 개발되었거나 제3자에 의해 귀하에게 합법적으로 제공된
              정보를 포함하지 않습니다.
              <br />
            </ContentText>
            <SubTitle>제10조 (배상 의무)</SubTitle>
            <ContentText>
              귀하는 회사가 제공하지 않는 서비스에서 제공되는 콘텐츠, 귀하의
              솔루션 이용, 또는 회사와 맺은 약관 위반을 포함하여 솔루션으로부터
              또는 솔루션과 관련되어 발생하는 모든 제3자 배상요구에 대한 회사와
              그 자회사, 대행사, 광고주의 손해를 배상하고 이들을 변호하는 것에
              동의합니다.
              <br />
              {' '}
회사의 광고주들은 본 배상의무의 제3자 수혜자들입니다.
              <br />
            </ContentText>
            <SubTitle>제11조 (진술과 보증 그리고 책임 부인)</SubTitle>
            <ContentText>
              귀하는 다음 사항을 진술하고 보증해야 합니다.
              <br />
              귀하가 본 약관에 동의할 모든 권한을 보유하고 있으며 귀하가
              솔루션을 이용할 수 있는 계정의 소유주이거나, 각 계정의 소유주를
              대행할 수 있는 법적 권한을 부여 받았으며 계정의 콘텐츠 게재에 대한
              의사결정자임 귀하의 본 약관 위반 또는 무효 활동으로 인해 회사가
              과거에 귀하가 생성한 솔루션의 계정을 종료하거나 사용을 제재한 적이
              없음 솔루션과의 약관 동의 또는 솔루션 약관에 의거한 이행이 귀하의
              제3자와의 계약 또는 제3자 권리를 위반하지 않음, 귀하가 회사에
              제공한 모든 정보가 정확하며 최신 정보임. 솔루션 약관에 명시적으로
              서술된 사항 이외에, 회사는 여하한 약속을 하지 않습니다.
              <br />
              솔루션 내 콘텐츠에 귀하의 콘텐츠를 제공하는 것을 거절할 수 있으며
              우리는 귀하의 계정과 관련하여 특정 광고 및 콘텐츠를 제공하는 것을
              보증하지 않습니다.
              <br />
              또한 우리는 솔루션 내에서의 콘텐츠 및 특정 기능, 또는 그 수익
              능력, 신뢰성, 이용가능성, 또는 귀하의 요구 충족 능력에 관하여
              어떠한 약속도 하지 않습니다.
              <br />
              회사는 법률이 허용하는 범위 내에서, 모든 명시적, 법률적, 또는
              묵시적 보증을 배제합니다.
              <br />
              {' '}
쌍방은 회사의 침해부재, 매매가능성 그리고 특정 목적의
              적합성에 대한 보증 또는 조건에 대한 명시적인 책임부인에 동의하고
              이를 인정합니다.
              <br />
              이러한 법률적 보증 또는 조건이 적용되며 배제 불가능한 범위 내에서,
              회사에게 허용되는 범위 내에서, 그 보증 또는 조건에 의거한 여하한
              클레임에 관하여, 회사는 그 재량으로, 솔루션이 제공하는 서비스의
              재공급 또는 재공급된 서비스의 비용 지불금에 대한 회사의 책임을
              제한합니다.
              <br />
              본 계약의 그 어느 것도, 법률적으로 배제되지 않거나 또는 관련법률에
              의하여 제한되지 않을 손실에 대한 회사의 보증이나 책임을 배제 또는
              제한하지 아니합니다.
              <br />
              일부 관할권에서는 특정 보증 또는 조건 또는 제한의 배제를 허용하지
              않으며 또는 부주의, 계약 위반 또는 묵시적 약관의 위반으로 인하여
              초래된 손실이나 손해, 또는 부수적이거나 결과적 손해에 대한 책임의
              배제를 허용하지 않습니다.
              <br />
              {' '}
따라서 귀하의 관할권에서 합법적인 제한만 귀하에게 적용되며,
              회사의 책임은 법률이 허용하는 최대 범위 내에서 제한됩니다.
              <br />
            </ContentText>
            <SubTitle>제12조 (책임의 제한)</SubTitle>
            <ContentText>
              법률이 허용하는 범위 내에서, 이하의 면책의무 또는 귀하가 본 약관과
              관련하여 지적 재산권, 기밀유지 의무 및/또는 독점적인 이익을 위반할
              경우를 제외하고 1 어떠한 경우에도 솔루션의 약관 하에서 쌍방은
              계약, 불법행위 또는 여타 법리에 포함된 결과적, 특별, 간접, 예시적,
              징벌적 손해, 또는 손실 및 경비에 대해 책임을 지지 않으며 제한된
              구제책의 본질적인 목적을 달성하지 못했을 경우에도 마찬가지 입니다.
              <br />
              2 각 솔루션 약관 하에서 각 당사자의 총배상 책임은 배상요구일 직전
              3개월 간 솔루션 약관과 관련하여 그 특정 당사자가 수령하여 보유하고
              있는 순금액으로 제한합니다.
              <br />
              쌍방은 상대방이 여기에 명시된 책임 제한을 신뢰하여 본 약관을
              체결하였으며 그 제한들이 쌍방 간 협상의 본질적인 근거라는 점을
              인정합니다.
              <br />
            </ContentText>
            <SubTitle>제13조 (기타 조항)</SubTitle>
            <ContentText>
              본 약관은 귀하의 서비스 이용에 관한 완전한 계약이며 그 주제에 관한
              이전의 또는 동시에 존재하는 계약들을 대체합니다.
              <br />
              또한 아래 두개의 경우 회사는 본 약관을 수정할 수 있습니다.
              <br />
              1 서면으로 본 약관을 수정하고 있음을 명시적으로 기술하여
              양당사자가 서명한 경우 2 또는 2조에 기술한 것과 같이, 회사가 본
              약관을 수정한 후에 귀하가 서비스를 계속 이용할 경우 본 약관에
              의거하여 귀하의 여하한 권리를 지정 또는 이전하는 것이
              불가능합니다.
              <br />
              1 쌍방은 독립적인 독립적인 도급자이며 약관은 대행사, 제휴관계,
              또는 합작투자를 생성시키지 않습니다.
              <br />
              2 본 약관은 제3 수혜자 권리도 생성시키지 아니합니다.
              <br />
              3 제4조에 기술된 사항 이외에, 쌍방 중 어느 한 당사자에 의한
              약관조항의 불이행은 권리포기를 구성하지 않습니다.
              <br />
              4 본 약관의 특정 내용이 시행 불가능한 것으로 밝혀질 경우, 약관의
              형평성은 그 효력을 전적으로 유지합니다.
              <br />
              5 제3조, 제4조, 제6조, 제10조, 제12조, 제13조는 종료될 경우에도
              유효하게 존속합니다.
              <br />
            </ContentText>
            <SubTitle>제14조 (준거법, 재판 관할)</SubTitle>
            <ContentText>
              1. 회사와 이용자 간에 발생한 분쟁에 관한 소송은 대한민국
              서울중앙지방법원을 관할 법원으로 합니다. 다만, 제소 당시 이용자의
              주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
              민사소송법상의 관할법원에 제기합니다.
              <br />
              2. 회사와 이용자 간에 제기된 소송에는 한국법을 적용합니다.
              <br />
            </ContentText>
            <SubTitle>부 칙 1.</SubTitle>
            <ContentText>
              이 약관은 2022년 01월 01일부터 적용됩니다.
              <br />
            </ContentText>
          </Content>
          <FlexRowBox
            style={{ margin: '40px 0px', cursor: 'pointer' }}
            onClick={() => setIsAgree(!isAgree)}
          >
            <CheckTextBox checked={isAgree} />
            <H6 style={{ marginLeft: '8px' }}>
              약관 내용을 모두 읽었으며 동의 합니다.
            </H6>
          </FlexRowBox>
          <RoundBtn
            text="확인"
            onClick={() => handleAgree()}
            style={
              isAgree
                ? {
                  width: '230px',
                  height: '48px',
                  border: 'solid 2px #f4f4f4',
                  backgroundColor: colors.primary,
                  color: '#ffffff',
                }
                : {
                  width: '230px',
                  height: '48px',
                  border: 'solid 2px #f4f4f4',
                  backgroundColor: '#f4f4f4',
                }
            }
          />
        </ModalBody>
      </Modal.View>
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
