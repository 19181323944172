import { UserDetailTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'

const UserDetailPage = ({ match, location, history }) => {
  useRenderLogger('UserDetailPage')

  const { userStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const thisUser = userStore.thisUser || null

  const [isMyPage, setIsMyPage] = React.useState(false)

  React.useEffect(() => {
    history.replace()

    return () => {
      userStore.thisUser = null
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (thisUser) {
      if (currentUser && thisUser && currentUser['id'] === thisUser['id'])
        setIsMyPage(true)
      else setIsMyPage(false)
    }
  }, [currentUser, thisUser, match])

  React.useEffect(() => {
    const fetchData = async () => {
      if (match?.params?.account) {
        const user = await userStore.fetchUserFromAccount(match.params.account)

        if(user) {
          const fetchBlock = async () => {
            const res = await userStore.fetchIsBlcoked(
              user.id,
              currentUser?.id,
            )
            if (res) {
              alert('존재하지 않는 유저입니다.')
              history.push('/')
              return true
            }
          }

          let isBlocked = false
          if (currentUser) {
            isBlocked = await fetchBlock()
          }

          if(!isBlocked) {
            await userStore.fetchUserFollowingCount(user.id)
            await userStore.fetchUserJoinedHouse(user.id)
          }
        }

      }
    }
    fetchData()
  }, [match, userStore])

  React.useEffect(() => {
    if (isMyPage && !currentUser) window.location.reload()
  }, [isMyPage, currentUser])

  React.useLayoutEffect(() => {
    userStore.init()
    // eslint-disable-next-line
  }, [match])

  return (
    <UserDetailTemplate
      currentUser={currentUser}
      userDetail={thisUser}
      isMyPage={isMyPage}
      follow={userStore.follow || (() => {})}
      unFollow={userStore.unFollow || (() => {})}
      updateFollow={userStore.updateFollow || (() => {})}
      thisUserJoinedHouseList={userStore.userDetailJoinedHouseList || []}
      initFeedId={location?.state?.init?.feedId || null}
    />
  )
}

export default observer(UserDetailPage)
