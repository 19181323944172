import { observable, computed } from 'mobx'
import { LIVE_ROOM_TYPE, STORAGE_URL } from '@consts/'
import { DOMAIN_URL } from '@consts/index'
import MixtapeModel from './MixtapeModel'
import UserModel from './UserModel'
import { HouseModel } from '.'

export default class LiveRoomModel {
  @observable id
  @observable title
  @observable description
  @observable isPrivate
  @observable password
  @observable isSocial
  @observable imageUri
  @observable isReserved
  @observable isOpened
  @observable isFinished

  @observable isMutedMic
  @observable isMutedMixtape
  @observable mixtapeVolume

  @observable userId
  @observable mixtapeId

  @observable createdAt
  @observable updatedAt
  @observable deletedAt
  @observable openedAt
  @observable reservedOpenedAt

  @observable speakerCount
  @observable fixedComment
  @observable fixedCommentUserId

  @observable isBlockedUnknownUser

  @observable LiveRoomsHaveUsers = []
  @observable LiveRoomsHaveTags = []
  // @observable UsersReserveLiveRooms = []
  @observable User = {}
  @observable Mixtape = null

  @computed get imageUrl() {
    return this.imageUri ? STORAGE_URL + encodeURIComponent(this.imageUri) : ''
  }
  @computed get imageSource() {
    return this.imageUri
      ? { uri: STORAGE_URL + encodeURIComponent(this.imageUri) }
      : ''
  }

  @observable fcmTopic
  @observable agoraChannelId
  @observable songPlayedIndex

  @computed get liveRoomType() {
    if (this.isPrivate) {
      return LIVE_ROOM_TYPE['PRIVATE']
    }
    return LIVE_ROOM_TYPE['PUBLIC']
  }

  @observable isReservedOthers = false

  @observable reservedCount = 0

  @computed get UsersReserveLiveRooms() {
    return this._UsersReserveLiveRooms
  }

  set UsersReserveLiveRooms(value) {
    this._UsersReserveLiveRooms =
      (value
        && value.map(usersReserveLiveRoom => ({
          ...usersReserveLiveRoom,
          User: usersReserveLiveRoom['User']
            ? usersReserveLiveRoom['User'] instanceof UserModel
              ? usersReserveLiveRoom['User']
              : new UserModel(this.stores, usersReserveLiveRoom['User'])
            : null,
        })))
      || []

    if (
      this.stores
      && this.stores.authStore
      && this.stores.authStore.currentUser
      && this.stores.authStore.currentUser['id']
    ) {
      const index = this._UsersReserveLiveRooms.findIndex(
        usersReserveLiveRoom =>
          usersReserveLiveRoom
          && usersReserveLiveRoom['userId']
            === this.stores.authStore.currentUser['id'],
      )

      if (index > -1) {
        this.isReservedOthers = true
      }
      else {
        this.isReservedOthers = false
      }
    }

    this.reservedCount = this._UsersReserveLiveRooms.length
  }

  @observable houseId
  @observable isHouseMember
  @observable House

  @observable FixedCommentUser
  @observable linkKey

  get dynamicLink() {
    return `${DOMAIN_URL}/salon/${this.linkKey}`
  }

  constructor(stores, props) {
    this.stores = stores

    if (props) {
      this.id = props.id
      this.title = props.title
      this.description = props.description
      this.isPrivate = props.isPrivate
      // this.dynamicLink = props.dynamicLink
      this.password = props.password
      this.isSocial = props.isSocial
      this.imageUri = props.imageUri
      this.isReserved = props.isReserved
      this.isOpened = props.isOpened
      this.isFinished = props.isFinished || false

      this.isMutedMic = props.isMutedMic
      this.isMutedMixtape = props.isMutedMixtape
      this.mixtapeVolume = props.mixtapeVolume

      this.userId = props.userId
      this.mixtapeId = props.mixtapeId

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
      this.openedAt = props.openedAt
      this.reservedOpenedAt = props.reservedOpenedAt

      this.fixedComment = props.fixedComment
      this.fixedCommentUserId = props.fixedCommentUserId

      this.speakerCount = props.speakerCount

      this.LiveRoomsHaveUsers =
        (props.LiveRoomsHaveUsers
          && props.LiveRoomsHaveUsers.map(liveRoomsHaveUsers => ({
            ...liveRoomsHaveUsers,
            User:
              (liveRoomsHaveUsers['User']
                && new UserModel(stores, liveRoomsHaveUsers['User']))
              || null,
          })))
        || []
      this.LiveRoomsHaveTags = props.LiveRoomsHaveTags || []
      this.UsersReserveLiveRooms = props.UsersReserveLiveRooms || []

      this.Mixtape =
        (props.Mixtape && new MixtapeModel(stores, props.Mixtape)) || null
      this.User = (props.User && new UserModel(this.store, props.User)) || null

      this.fcmTopic = props.fcmTopic
      this.agraChannelId = props.agoraChannelId

      this.isReservedOthers = props.isReservedOthers
      this.UsersReserveLiveRooms = props.UsersReserveLiveRooms

      this.songPlayedIndex = props.songPlayedIndex

      this.isBlockedUnknownUser = props.isBlockedUnknownUser

      this.houseId = props.houseId
      this.isHouseMember = props.isHouseMember
      this.House = (props.House && new HouseModel(stores, props.House)) || null

      this.FixedCommentUser =
        (props.FixedCommentUser
          && new UserModel(stores, props.FixedCommentUser))
        || null
      this.linkKey = props.linkKey
    }
  }
}
