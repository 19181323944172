import { observable } from 'mobx'
import { UserModel } from '.'
import CastModel from './CastModel'

export default class UsersLikeCastsModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable userId
  @observable castId

  @observable isDeleted

  @observable User = null
  @observable Cast = null

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.userId = props.userId
      this.castId = props.castId

      this.isDeleted = props.isDeleted

      this.User = (props.User && new UserModel(stores, props.User)) || null
      this.Cast = (props.Cast && new CastModel(stores, props.Cast)) || null
    }
  }
}
