import React from 'react'
import { Flex } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@resources/colors'

const Card = styled(Flex)`
  max-width: 248px;
  width: 100%;
  height: 64px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
  cursor: pointer;
  font-size: 14px;

  align-items: center;
  justify-content: center;
`

const SearchTagCard = ({ tag, onClick }) => {
  return <Card onClick={() => onClick(tag)}>{`# ${tag || '드라이브'}`}</Card>
}

export default SearchTagCard
