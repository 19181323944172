import { observable, action } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { SongModel, TagModel } from './models'
// import { UserModel, SongModel, GenreModel, MoodModel } from './models'
// import { STORAGE_URL } from '@consts/'

class Autocomplete {
  willFetchLength = 0
  fetchedLength = 0
  totalLength = 0
  @observable autocompleteSongList = []
  isFinishedSong = false

  @observable isLoadingAutocomplete = false

  keyword = ''
}

export default class SongStore {
  @observable uploadSongList
  @observable uploadSongListCount
  @observable currentSong
  @observable currentSongInfo
  @observable myLikeSongs

  @observable callLimit

  @observable tempUploadSong
  @observable tempTags
  @observable tempGenre
  @observable tempMoods
  @observable prevSong

  @observable autocompleteObj: Object<Autocomplete> = {}
  @observable currentAutocomplete: Autocomplete | null = null
  LIMIT_SEARCH = 10

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.uploadSongList = []
    this.uploadSongListCount = 0
    this.myLikeSongs = []
    this.currentSong = null
    this.currentSongInfo = {}
    this.callLimit = 0
    this.autocompleteObj = {}
    this.currentAutocomplete = null
  }

  // 흐름 추가 시, 나의 업로드한 곡 전체 fetch
  // @action.bound
  // async fetchUploadSongList() {
  //   return this.store.useLoading(async () => {
  //     const songList = await this.network.songNetwork.getUsersSingSongs({
  //       userId: this.store.authStore.currentUser.id,
  //       __include: 'Songs',
  //       __order: 'createdAtDesc',
  //     })

  //     this.uploadSongList =
  //       songList
  //         .filter(elem => !!elem)
  //         .map(elem => new SongModel(this.store, elem['Song'])) || []
  //   })
  // }

  // 페이지네이션 들어간 나의 업로드한 곡 fetch 함수
  // 위 fetch함수는 흐름 추가 시, 나의 업로드한 곡 검색 로직 미구현으로 적용 안함.
  @action.bound
  fetchMyUploadSongList = async (__offset = 0, keyword) => {
    if (!this.store.authStore.currentUser) return
    let params = {}
    if (keyword) {
      params = {
        __required: 'UsersSingSongs',
        'UsersSingSongs.userId': this.store.authStore.currentUser.id,
        __order: 'createdAtDesc',
        __limit: 10,
        __offset,

        nameOperator: keyword && 'like',
        name: keyword && `%${keyword}%`,
        // __or: 1,
        __orField: 'name,singer',
        singerOperator: 'like',
        singer: `%${keyword}%`,
      }
    }
    else {
      // await new Promise(resolve => {
      //   setTimeout(() => resolve(true), 1000)
      // })
      params = {
        __required: 'UsersSingSongs',
        'UsersSingSongs.userId': this.store.authStore.currentUser.id,
        __order: 'createdAtDesc',
        __limit: 10,
        __offset,
      }
    }

    const songList = await this.network.songNetwork.getSongs(params)

    if (!songList) return

    if (__offset === 0) {
      const songListCount = await this.network.songNetwork.getSongsCount(params)
      this.uploadSongListCount = songListCount.length === 0 ? 0 : songListCount
    }

    this.uploadSongList =
      songList
        .filter(elem => !!elem)
        .map(elem => new SongModel(this.store, elem)) || []

    return this.uploadSongList
  }

  @action.bound
  async fetchSongDetail(id) {
    return this.store.useLoading(async () => {
      const song = await this.network.songNetwork.getSong(id)
      const infos = await this.network.songNetwork.getSongInfo({ songId: id })

      if (!song && !infos) return false
      this.currentSong = new SongModel(this.store, song)

      this.currentSongInfo = {
        Tags:
          infos.Tags.filter(elem => !!elem).map(
            elem => new TagModel(this.store, elem['Tag']),
          ) || [],
      }
    })
  }

  @action.bound
  async fetchMyLikeSongs() {
    return this.store.useLoading(async () => {
      const songs = await this.network.songNetwork.getUsersLikeSongs({
        userId: this.store.authStore.currentUser['id'],
        __required: 'Songs',
        __order: 'createdAtDesc',
      })

      if (!songs) return

      this.myLikeSongs = songs.map(elem => {
        return elem['Song'] && new SongModel(this.store, elem['Song'])
      })
    })
  }

  @action.bound
  async updateSong(songId, params) {
    return this.store.useLoading(async () => {
      const updatedSong = await this.network.songNetwork.putSong(songId, params)

      return !!updatedSong
    })
  }

  @action.bound
  async updateSongInfo(songId, tags = [], moods = []) {
    return this.store.useLoading(async () => {
      const params = await {
        Tags: tags.map(data => {
          return { name: data, songId }
        }),
        Moods: moods.map(data => {
          return { name: data, songId }
        }),
      }
      const data = await this.network.songNetwork.putSongInfo(songId, params)

      return !!data
    })
  }

  @action.bound
  async createTagList(songId, tags) {
    return this.store.useLoading(async () => {
      const params = await tags.map(data => {
        return { name: data, songId }
      })

      const data = await this.network.songNetwork.postTagList(params)
      return !!data
    })
  }

  // 노래 등록
  @action.bound
  async uploadSong(params) {
    return this.store.useLoading(async () => {
      if (!params) return false
      const res = await this.network.songNetwork.postSong(params)
      if (!res) return false

      return res[0]
    })
  }

  // 유튜브 링크 가져오기 ( 메타데이터 )
  @action.bound
  async getYoutubeLink(title, artist) {
    // 동기 로딩 제거 => 등록하기 활성화 숙지 어려움,,, 추후 논의 필요
    // return this.store.useLoading(async () => {
    if (!title && !artist) return false
    const params = await {
      searchText: `${encodeURI(title)}+${encodeURI(artist)}`,
    }
    const res = await this.network.songNetwork.getYoutubeLink(params)
    this.callLimit += 1
    if (this.callLimit < 3 && !res.youtubeUrl) {
      const youtubeUrl = this.getYoutubeLink(title, artist)
      return youtubeUrl
    }
    if (res.youtubeUrl === null) {
      return 'LINK_ERROR'
    }
    return res.youtubeUrl

    // })
  }

  // 노래 삭제
  @action.bound
  async removeSong(id) {
    return this.store.useLoading(async () => {
      const deletedSong = await this.network.songNetwork.deleteSong(id)

      // no fetch => splice ( render -1 )
      const idx = this.uploadSongList.findIndex(
        song => song['id'] === deletedSong['id'],
      )
      this.uploadSongList.splice(idx, 1)
      this.uploadSongList = [...this.uploadSongList]

      return deletedSong
    })
  }

  @action.bound
  async uploadSongAudio(file) {
    return this.store.useLoading(async () => {
      const res = await this.network.songNetwork.uploadSongAudio(file)
      return res
    })
  }

  @action.bound
  async uploadSongImage(file) {
    // return this.store.useLoading(async () => {
    const res = await this.network.songNetwork.uploadSongImage(file)
    return res
    // })
  }

  @action.bound
  async fetchDurationList(songList) {
    if (!songList) return
    const filteredSongList = songList.filter(song => !!song['id'])
    if (!(filteredSongList.length >= 0)) return

    await Promise.all(
      filteredSongList.map(async song => {
        if (song['duration']) return

        const newSong = await this.fetchSongs(song['id'], {
          __addColumn: 'duration',
        })
        if (newSong) {
          song['duration'] = newSong['duration'] || 0
        }
      }),
    )
    return filteredSongList
  }

  @action.bound
  async fetchSongs(songId, options) {
    try {
      if (typeof songId !== 'number') return
      const { data } = await this.network._axiosApiAuth(
        `/songs/${songId}`,
        'get',
        options,
      )
      if (!data) return null
      return new SongModel(this.store, data)
    }
    catch (err) {
      console.log('SongStore fetchSongs error: ', err)
    }
  }

  @action.bound
  likeSong = async song => {
    if (!this.store.authStore.currentUser) return
    const data = await this.network.songNetwork.postLikeSong(song.id)

    if (data) {
      song['UsersLikeSongs'].push(data)
    }

    return !!data
  }

  @action.bound
  disLikeSong = async song => {
    if (!this.store.authStore.currentUser) return
    const data = await this.network.songNetwork.deleteLikeSong(song.id)

    if (data) {
      song['UsersLikeSongs'].pop(data)
    }

    return !!data
  }

  /**
   * cursor pagination 적용
   * 2020. 11. 24
   * LEEHAEHUN
   * @param {*} currentPage : 페이지 로드 카운트
   * @param {*} keyword  : 검색 키워드
   */
  @action.bound
  fetchMyUploadSongListByWhere = async (
    currentPage = 0,
    keyword,
    isMine = true, // Copotter : 자기 업로드가 아닌 모든 업로드 곡 검색 시 활용
  ) => {
    // 페이지 수
    const __limit = 10

    // 초기 파람값 생성
    const params = {
      __required: 'UsersSingSongs',
      __limit,
      __order: 'createdAtDesc',
    }

    if (isMine) {
      params['UsersSingSongs.userId'] = this.store.authStore.currentUser.id
    }

    // 검색 시, 검색 파람 로직 추가
    if (keyword) {
      params.name = `%${keyword}%`
      params.singer = `%${keyword}%`
      params.nameOperator = 'like'
      params.singerOperator = 'like'
      params.__orField = 'name,singer'
    }

    // 2번째 페이지 로드부터 커서 페이지네이션 로직 추가
    if (currentPage !== 0 && this.uploadSongList[__limit - 1]) {
      params.__pageLimit = __limit
      params.__pageIdValue = this.uploadSongList[__limit - 1]['id']
      params.__pageField = 'createdAt'
      params.__pageOrder = 'desc'
      params.__pageValue = this.uploadSongList[__limit - 1]['createdAt']
    }

    const songList = await this.network.songNetwork.getSongs(params)
    if (!songList) return

    if (currentPage === 0) {
      const songListCount = await this.network.songNetwork.getSongsCount(params)
      this.uploadSongListCount = songListCount
      // 검색한 리스트의 전체 카운트 저장
      this.autocompleteObj[keyword].totalLength = songListCount
    }

    this.uploadSongList =
      songList
        .filter(elem => !!elem)
        .map(elem => new SongModel(this.store, elem)) || []

    return this.uploadSongList
  }

  @action.bound
  async fetchAutocomplete(text, offset = 0, isMine = true) {
    // if (!text) return
    let _currentAutocomplete = null
    try {
      /**
       * 캐싱 추가 - jhlim 20191204
       */
      if (!this.autocompleteObj[text]) {
        this.autocompleteObj[text] = new Autocomplete()
        this.autocompleteObj[text].keyword = text
      }
      _currentAutocomplete = this.autocompleteObj[text]
      if (offset === 0) {
        _currentAutocomplete.isLoadingAutocomplete = true
      }
      /**
       * fetch 속도를 고려해서 await 하기 전에 this.currentAutocomplete 에 할당
       * 각 키워드 마다 fetch 하는 속도가 일정하지 않기 때문에 미리 설정
       * fetch 후에 this.currentAutocomplete 에 할당하면 fetch 속도에 따라 먼저 호출된 함수에서 fetch 가 더 느리면 먼저 호출된 키워드가 활성화됨.
       * await 전에 해야할 작업. (await 응답 순서를 보장하지 않으므로)
       * this.currentAutocomplete['isLoadingAutocomplete'] 값으로 autocomplete 로딩중 유무도 판단
       * jhlim 20191211
       */
      this.currentAutocomplete = _currentAutocomplete
      if (
        !(_currentAutocomplete.fetchedLength > offset)
        && !(_currentAutocomplete.willFetchLength > offset)
      ) {
        _currentAutocomplete.willFetchLength += this.LIMIT_SEARCH
        // this.autocompletePopularityList = []
        // this.autocompleteTagList = []
        // this.autocompleteUserList = []
        // this.autocompleteMixtapeList = []

        const [_autocompleteSongList = []] = await Promise.all([
          // this.fetchMyUploadSongListByWhere(text, offset),
          this.fetchMyUploadSongListByWhere(offset, text, isMine), // offset 로직 임시 적용
        ])
        _currentAutocomplete.isFinishedSong =
          !_autocompleteSongList
          || !(_autocompleteSongList.length >= this.LIMIT_SEARCH)

        const autocompleteSongList = _autocompleteSongList.map(
          song => new SongModel(this.store, song),
        )

        // let maxLength = autocompleteTagList.length
        // if (maxLength < autocompleteUserList.length) {
        //   maxLength = autocompleteUserList.length
        // }

        // const autocompletePopularityList = []

        // if (
        //   autocompleteSongList.length > 0
        //   && autocompleteSongList[0]
        //   && offset === 0
        // ) {
        //   autocompletePopularityList.push(autocompleteSongList[0])
        // }

        _currentAutocomplete.autocompleteSongList.splice(
          offset,
          0,
          ...autocompleteSongList,
        )
        _currentAutocomplete.fetchedLength += this.LIMIT_SEARCH
      }
    }
    catch (error) {
      console.log('jhlim error', error)
    }

    if (_currentAutocomplete) {
      _currentAutocomplete.isLoadingAutocomplete = false
    }
  }
}
