import { YnPopup } from '@components/molecules'
import { FeedDetailModal } from '@components/organisms'
import { ERROR_CODE } from '@consts/index'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const FeedDetailPage = ({match}) => {
  const [feedId, setFeedId] = useState(null)
  const [errorCode, setErrorCode] = useState(null)
  const [houseLinkKey, setHouseLinkKey] = useState(null)
  const history = useHistory()

  useRenderLogger('FeedDetailPage')

  const { feedStore } = useStore()

  const fetchFeedFromLinkKey = useCallback(
    async castLinkKey => {
      const result = await feedStore.fetchFeedFromLinkKey(castLinkKey)
      if(result) {
        if(result?.error?.code) {
          setErrorCode(result?.error?.code)
          setHouseLinkKey(result?.error?.data?.House?.linkKey || null)
        }
        else if(result?.data?.id){
          setFeedId(result?.data?.id)
        }
      }
    },
    [setErrorCode, setFeedId, feedStore],
  )

  useEffect(() => {
    if (match.params.linkKey) {
      fetchFeedFromLinkKey(match.params.linkKey)
    }
  }, [match, fetchFeedFromLinkKey])

  if(errorCode === ERROR_CODE['FEEDS_ONLY_HOUSE_MEMBER']) {
    return (
      <>
        <YnPopup.View
          isShowing
          toggle={() => { }}
          title={'하우스 멤버 콘텐츠'}
          body={'해당 콘텐츠는 멤버만 입장 가능합니다.'}
          yText={'멤버 가입하기'}
          onCancel={
            () => {
              history.push(`/`)
            }
          }
          onOk={
            () => {
              if(houseLinkKey) {
                history.push(`/house/${houseLinkKey}`)
              }
              else {
                history.push(`/`)
              }
            }
          }
        />
      </>
    )
  }

  if(!feedId) {
    return <></>
  }

  return (
    <>
      <FeedDetailModal.View
        isShowing
        toggle={() => {}}
        feedId={feedId}
      />
    </>
  )
}

export default observer(FeedDetailPage)
