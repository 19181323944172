import { upload } from '@utils/axios'
import { Network } from '.'

export default class SongNetwork {
  constructor(network: Network) {
    this.network = network
  }
  // 회원 업로드 노래
  getUsersSingSongs = async params => {
    try {
      const { data } = await this.network._axiosApi(
        '/users-sing-songs',
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getUsersSingSongs error: ', error)
    }
  }

  // 회원 업로드 노래 카운트
  // will deprecated
  getSongsCount = async params => {
    try {
      const { data } = await this.network._axiosApi(
        '/songs/counts',
        'get',
        params,
      )
      return (data && data.totalCount) || []
    }
    catch (error) {
      console.log('SongNetwork getSongsCount error: ', error)
    }
  }

  // 노래 리스트
  getSongs = async params => {
    try {
      const { data } = await this.network._axiosApi(`/songs`, 'get', params)
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getSongs error: ', error)
    }
  }
  // 노래 상세
  getSong = async id => {
    try {
      const { data } = await this.network._axiosApi(`/songs/${id}`, 'get')
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getSong error: ', error)
    }
  }

  // 노래 정보 조회
  getSongInfo = async params => {
    try {
      const { data } = await this.network._axiosApi(
        '/songs/info',
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getSong error: ', error)
    }
  }

  // 노래 등록
  postSong = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/songs/',
        'post',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork postSong error: ', error)
    }
  }

  // 노래 수정
  putSong = async (songId, params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/songs/${songId}`,
        'put',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork putSong error: ', error)
    }
  }

  // 노래 삭제
  deleteSong = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/songs/${id}`,
        'delete',
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork deleteSong error: ', error)
    }
  }

  // 유튜브 링크 검색
  getYoutubeLink = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/youtube/search/',
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getYoutubeLink error: ', error)
    }
  }

  // 노래 오디오 업로드
  uploadSongAudio = async formData => {
    try {
      const { data } = await upload(
        '/uploads/songs/audio',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('SongNetwork uploadSongAudio error: ', error)
    }
  }

  // 노래 이미지 업로드
  uploadSongImage = async formData => {
    try {
      const { data } = await upload(
        '/uploads/songs/image',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('SongNetwork uploadSongImage error: ', error)
    }
  }

  // 태그 등록
  postTagList = async (params, jwt) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/songs/tags',
        'post',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork posTagList error: ', error)
    }
  }

  // 노래 정보 수정
  putSongInfo = async (songId, params, jwt) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/songs/${songId}/info`,
        'put',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork putSongInfo error: ', error)
    }
  }

  // 좋아한 노래
  getUsersLikeSongs = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users-like-songs`,
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SongNetwork getUsersLikeSongs error: ', error)
    }
  }

  postLikeSong = async songId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/songs/${songId}/likes`,
        'post',
      )
      return data || null
    }
    catch (err) {
      console.log('SongNetwork postLikeSong error: ', err)
      throw err
    }
  }

  deleteLikeSong = async songId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/songs/${songId}/likes`,
        'delete',
      )
      return data
    }
    catch (err) {
      console.log('SongNetwork deleteLikeSong error: ', err)
      throw err
    }
  }

  /**
   * users-play-songs
   */
  postUsersPlaySongs = async value => {
    if (!value) {
      return
    }
    try {
      const { data } = await this.network._axiosApiAuth(
        '/users-play-songs',
        'post',
        value,
      )
      return data || []
    }
    catch (err) {
      console.log('SongNetwork postUsersPlaySongs error: ', err)
    }
  }
  putUsersPlaySongs = async (id, value) => {
    if (!id || !value) {
      return
    }
    try {
      const { data } = await this.network._axiosApiAuth(
        `/users-play-songs/${id}`,
        'put',
        value,
      )
      return data || null
    }
    catch (err) {
      console.log('SongNetwork putUsersPlaySongs error: ', err)
    }
  }
}
