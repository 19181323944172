import React from 'react'
import ReactLoading from 'react-loading'

const Loading = ({ type, color, delay, height, width }) => {
  return (
    <div className="loading_outer">
      <ReactLoading
        type={type && type}
        color={color && color}
        delay={delay && delay}
        height={height && height}
        width={width && width}
        className={'loading_inner'}
      />
    </div>
  )
}

export default Loading
