import React from 'react'
import { Div } from '@components/atoms'
import styled from 'styled-components'

const CastShimmer = () => {
  return (
    <Div display="flex" flexDirection="column" width="248px" height="202px">
      <Shimmer width="100%" height="140px" />
      <Div display="flex" marginTop="8px">
        <Shimmer
          width="24px"
          height="24px"
          borderRadius="50%"
          marginRight="8px"
        />
        <Div disaply="flex" flexDirection="column">
          <Shimmer width="180px" height="12px" borderRadius="6px" />
          <Shimmer
            width="88px"
            height="12px"
            borderRadius="6px"
            margin="8px 0"
          />
          <Shimmer width="116px" height="12px" borderRadius="6px" />
        </Div>
      </Div>
    </Div>
  )
}

export default CastShimmer

const Shimmer = styled(Div)`
  & {
    animation-delay: ${({ animationDelay }) => animationDelay || '2s'};
    animation: ${({ animation }) =>
    animation || `shimmer 3s infinite alternate-reverse linear`};
    background-size: ${({ width }) => width} 100%;
    background: #f6f7f8;
    background-image: ${({ backgroundImage }) =>
    backgroundImage
      || `
      linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    )
    `};
    background-repeat: no-repeat;
  }

  @keyframes shimmer {
    0% {
      background-position: -${({ width }) => width} 0;
    }
    100% {
      background-position: ${({ width }) => width} 0;
    }
  }
`
