import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile, tablet } from '@styles/media'
import { images } from '@resources/'
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  overflow: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5050;
  background: white;
  border-radius: 16px;

  @media ${mobile} {
    min-width: 300px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    transform: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;

  @media ${mobile} {
    width: 100vw;
    height: 100vh;

    margin: 0;
    top: 0;
  }
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-image: url(${images.close_button_img});
  background-size: 100% 100%;
  margin: 30px 30px -15px 0;

  // @media ${tablet} {
  //   width: 14px;
  //   height: 14px;
  //   margin: 20px 20px 0 0;
  // }

  // @media ${mobile} {
  //   width: 14px;
  //   height: 14px;
  //   margin: 20px 20px 0 0;
  // }
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    // !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onClose, children, noHeader }) => {
  useEffect(() => {
    if (isShowing) {
      document.addEventListener('keydown', function(e) {
        if (e.keyCode === 27) {
          toggle()
        }
      })
    }
    return () => {
      document.removeEventListener('keydown', toggle)
    }
  }, [isShowing, toggle])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => handleClose()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            {
              !noHeader && (
                <ModalHeader>
                  <CloseButton
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  />
                </ModalHeader>
              )
            }
            {children}
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}
