import { observable, action } from 'mobx'
import { STORAGE_URL } from '@consts/'
import { Store } from '.'
import { Network } from './networks'
import { CommentModel } from './models'

class CompleteData {
  @observable isLoading = false
  @observable list = []
  constructor(props) {
    if (props) {
      this.isLoading = props.isLoading
      this.list = props.list
    }
  }
}
export default class CommentStore {
  @observable targetCommentList // 특정 게시글( 흐름, 피드 등) 의 댓글 목록
  @observable _autoCompleteUserObj

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.targetCommentList = []
    this._autoCompleteUserObj = {}
  }

  @action fetchCommentsList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/comments',
        'get',
        where,
      )

      return (
        (Array.isArray(data)
          && data.map(elem => new CommentModel(this.store, elem)))
        || []
      )
    }
    catch (err) {
      console.log('CommentStore fetchCommentsList error: ', err)
    }
  }

  /**
   * Target 의 댓글을 보려고 시도할 시, 호출 되어야 함
   */
  @action fetchTargetReply = async (
    targetId,
    targetTableName = 'Songs',
    isReply = '1',
    isOnlyPinnedComment = false,
  ) => {
    if (isNaN(parseInt(targetId))) return false

    const comments = await this.fetchCommentsList({
      targetId,
      targetTableName,
      isReply,
      __include: `Users,CommentsHaveTags.Tags,UsersLikeComments,CommentsHaveUsers,ChildComments,ChildComments.Users,ChildComments.Users.UsersFollowedUsers,ChildComments.CommentsHaveTags.Tags,ChildComments.UsersLikeComments,ChildComments.CommentsHaveUsers${
        targetTableName === 'Casts' ? ',DonationCastHistories' : ''
      }`,
      __attributes: `Users.id,Users.account,Users.name,Users.imageUri,Users.profileColor,Users.isInfluencer,\
CommentsHaveTags.id,CommentsHaveTags.Tags.id,CommentsHaveTags.Tags.keyword,\
UsersLikeComments.id,UsersLikeComments.userId,\
ChildComments.UsersLikeComments.id,ChildComments.UsersLikeComments.userId,\
ChildComments.Users.id,ChildComments.Users.account,ChildComments.Users.name,ChildComments.Users.imageUri,ChildComments.Users.profileColor,ChildComments.Users.followerCount,ChildComments.Users.isInfluencer,\
ChildComments.Users.UsersFollowedUsers.id,ChildComments.Users.UsersFollowedUsers.userId,ChildComments.Users.UsersFollowedUsers.followId,\
CommentsHaveUsers.id,CommentsHaveUsers.userId`,
      'UsersLikeComments.userId': this.store.authStore?.currentUser?.id,
      parentCommentId: 'null',
      parentCommentIdOperator: 'is',
      __order: 'createdAtDesc',
      pinnedAt: 'null',
      pinnedAtOperator: isOnlyPinnedComment ? 'not' : 'is',
    })
    if (!comments) return false

    return comments
  }

  /**
   * Target 의 댓글을 보려고 시도할 시, 호출 되어야 함
   */
  @action.bound
  fetchTargetMixtapeReply = async (targetId, isReply = '1') => {
    if (isNaN(parseInt(targetId))) return false
    return this.store.useLoading(async () => {
      const res = await this.network.commentNetwork.getComments({
        targetId,
        targetTableName: 'Mixtapes',
        isReply: 1,
        __include:
          'Users,CommentsHaveTags.Tags,UsersLikeComments,CommentsHaveUsers,ChildComments,ChildComments.Users,ChildComments.Users.UsersFollowedUsers,ChildComments.CommentsHaveTags.Tags,ChildComments.UsersLikeComments,ChildComments.CommentsHaveUsers',
        __attributes: `Users.id,Users.account,Users.name,Users.imageUri,Users.profileColor,Users.isInfluencer,\
CommentsHaveTags.id,CommentsHaveTags.Tags.id,CommentsHaveTags.Tags.keyword,\
UsersLikeComments.id,UsersLikeComments.userId,\
ChildComments.UsersLikeComments.id,ChildComments.UsersLikeComments.userId,\
ChildComments.Users.id,ChildComments.Users.account,ChildComments.Users.name,ChildComments.Users.imageUri,ChildComments.Users.profileColor,ChildComments.Users.followerCount,ChildComments.Users.isInfluencer,\
ChildComments.Users.UsersFollowedUsers.id,ChildComments.Users.UsersFollowedUsers.userId,ChildComments.Users.UsersFollowedUsers.followId,\
CommentsHaveUsers.id,CommentsHaveUsers.userId`,
        'UsersLikeComments.userId': this.store.authStore?.currentUser?.id,
        parentCommentId: 'null',
        parentCommentIdOperator: 'is',
        __order: 'createdAtDesc',
      })
      if (!res) return false

      this.targetCommentList =
        (Array.isArray(res)
          && res.map(elem => new CommentModel(this.store, elem)))
        || []

      return !!this.targetCommentList
    })
  }

  @action.bound
  createMixtapeComment = async value => {
    const commentList = await this.network.commentNetwork.postComments(value)
    if (!commentList) return

    await this.fetchTargetMixtapeReply(commentList[0]['targetId'])
  }

  @action createCommentList = async value => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/comments',
        'post',
        value,
      )
      return (
        (Array.isArray(data)
          && data.map(elem => new CommentModel(this.store, elem)))
        || []
      )
    }
    catch (err) {
      console.log('CommentStore createCommentList error: ', err)
      throw err
    }
  }

  @action postComment = async (value, targetTableName = 'Songs') => {
    return this.store.useLoading(async () => {
      try {
        const commentList = await this.createCommentList(value)
        if (!commentList) return false

        if (targetTableName === 'Songs') {
          await this.fetchSongReply(commentList[0]['targetId'])
        }
        else if (targetTableName === 'Mixtapes') {
          // await this.fetchMixtapeReply(commentList[0]['targetId'])
          await this.fetchTargetMixtapeReply(commentList[0]['targetId'])
        }
        else if (targetTableName === 'Feeds') {
          await this.fetchFeedReply(commentList[0]['targetId'])
        }
        else if (targetTableName === 'Casts') {
          await this.fetchCastReply(commentList[0]['targetId'])
          this.store.castStore.castDetail.commentCount += 1
        }

        return true
      }
      catch (err) {
        console.log('postComment Error: ', err)
        throw err
      }
    })
  }

  @action.bound
  removeComment = async (id, targetTableName = 'Songs') => {
    const res = await this.network.commentNetwork.deleteComments(id)
    if (!res) return

    if (targetTableName === 'Songs') {
      await this.fetchSongReply(res['targetId'])
    }
    else if (targetTableName === 'Mixtapes') {
      // await this.fetchMixtapeReply(res['targetId'])
      await this.fetchTargetMixtapeReply(res['targetId'])
    }
    else if (targetTableName === 'Feeds') {
      await this.fetchFeedReply(res['targetId'])
    }
    else if (targetTableName === 'Casts') {
      await this.fetchCastReply(res['targetId'])
      this.store.castStore.castDetail.commentCount -= 1
    }
  }

  @action.bound
  editComment = async (id, value, targetTableName = 'Songs') => {
    const res = await this.network.commentNetwork.putComments(id, value)
    if (!res) return

    if (targetTableName === 'Songs') {
      await this.fetchSongReply(res['targetId'])
    }
    else if (targetTableName === 'Mixtapes') {
      // await this.fetchMixtapeReply(res['targetId'])
      await this.fetchTargetMixtapeReply(res['targetId'])
    }
    else if (targetTableName === 'Feeds') {
      await this.fetchFeedReply(res['targetId'])
    }
    else if (targetTableName === 'Casts') {
      await this.fetchCastReply(res['targetId'])
    }
  }

  @action.bound
  searchUserList = async keyword => {
    if (!keyword) return
    let mentionList = []

    try {
      if (this._autoCompleteUserObj[keyword]) {
        return
      }
      this._autoCompleteUserObj[keyword] = new CompleteData({
        isLoading: true,
      })
      const result = await this.network.userNetwork.getUsers({
        name: `%${keyword}%`,
        nameOperator: 'like',
        __limit: 10,
        __attributes: 'id,account,name,imageUri',
      })

      this.searchResult = result.map(item => ({
        id: item.id,
        display: item.name,
        imageUrl: STORAGE_URL + item.imageUri,
      }))

      mentionList = result.map(item => ({
        id: item.id,
        display: item.name,
        imageUrl: STORAGE_URL + item.imageUri,
      }))

      if (this._autoCompleteUserObj[keyword]) {
        this._autoCompleteUserObj[keyword]['list'] = this.searchResult
        this._autoCompleteUserObj[keyword]['isLoading'] = false
      }
    }
    catch (error) {
      console.log(error)
    }

    return mentionList || []
  }

  @action likeComment = async (commentId, userId) => {
    const result = await this.network.commentNetwork.postCommentsLike(
      commentId,
      userId,
    )
    if (!result) return

    return result
  }

  @action unLikeComment = async (commentId, userId) => {
    const result = await this.network.commentNetwork.deleteCommentsLike(
      commentId,
      userId,
    )
    if (!result) return

    return result
  }

  @action fetchSongReply = async (songId, isReply = '1') => {
    if (!songId) return

    const commentList = await this.fetchTargetReply(songId, 'Songs', isReply)
    if (!commentList) return

    this.comments =
      commentList.map(comment => new CommentModel(this.store, comment)) || []
    this.comments.forEach(comment => {
      comment['tags'] =
        comment['CommentsHaveTags']
        && comment['CommentsHaveTags'].map(elem => elem['Tag'])
    })
  }

  @action fetchMixtapeReply = async (mixtapeId, isReply = '1') => {
    if (!mixtapeId) return

    const commentList = await this.fetchTargetReply(
      mixtapeId,
      'Mixtapes',
      isReply,
    )
    if (!commentList) return

    if (
      this.store.mixtapeStore.mixtapeDetailObj[mixtapeId]
      && this.store.mixtapeStore.mixtapeDetailObj[mixtapeId]['elem']
    ) {
      this.store.mixtapeStore.mixtapeDetailObj[mixtapeId]['elem'][
        'commentList'
      ] =
        commentList.map(comment => new CommentModel(this.store, comment)) || []
    }
  }

  @action fetchFeedReply = async (feedId, isReply = '1') => {
    if (!feedId) return

    return this.store.useLoading(async () => {
      const result = await this.fetchTargetReply(feedId, 'Feeds', isReply)
      if (!result) return

      const commentList = result?.map(val => new CommentModel(this.store, val))

      if (
        this.store.feedStore.feedDetailObj[feedId]
        && this.store.feedStore.feedDetailObj[feedId]['elem']
      ) {
        this.store.feedStore.feedDetailObj[feedId]['elem']['commentList'] =
          commentList.map(comment => new CommentModel(this.store, comment))
          || []
      }
    })
  }

  @action fetchCastReply = async (castId, isReply = '1') => {
    if (!castId) return

    return this.store.useLoading(async () => {
      const pinnedResult = await this.fetchTargetReply(
        castId,
        'Casts',
        isReply,
        true,
      )

      const result = await this.fetchTargetReply(castId, 'Casts', isReply)

      if (!result) return

      const commentList = [
        ...new Set([
          ...pinnedResult?.map(val => new CommentModel(this.store, val)),
          ...result?.map(val => new CommentModel(this.store, val)),
        ]),
      ]

      if (this.store.castStore.castDetail) {
        this.store.castStore.castDetail['commentList'] =
          commentList.map(comment => new CommentModel(this.store, comment))
          || []
      }
    })
  }

  @action.bound
  togglePin = async (isPin, comment) => {
    if (!(comment && comment.id)) return
    return this.store.useLoading(async () => {
      let res
      if (isPin) {
        res = await this.network.commentNetwork.putCommentsPin(comment.id)
      }
      else if (!isPin) {
        res = await this.network.commentNetwork.putCommentsUnpin(comment.id)
      }

      if (!res) return

      const targetIdx = this.store.feedStore.feedDetail.commentList.findIndex(
        item => item.id === comment.id,
      )
      this.store.feedStore.feedDetail.commentList[targetIdx]['pinnedAt'] =
        res.data.pinnedAt

      return !!res
    })
  }
}
