import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { H5, H6, Caption2, Flex, InvisibleInput } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  width: 320px;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  height: 232px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const CancelBtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  color: #242424;
`

const Okbtn = styled.button`
  width: 144px;
  height: 48px;
  border-radius: 10px;
  border: none;
  background-color: ${({ disabled }) =>
    disabled ? colors.white_f4 : colors.primary};
  color: ${({ disabled }) => (disabled ? colors.grey_d4 : colors.white_ff)};
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  const toggle = () => {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({
  isShowing,
  toggle,
  onCancel,
  onOk,
  salonPassword,
  title = '암호 입력',
  body = '암호 4자리를 입력해주세요',
}) => {
  const [digit1, setDigit1] = React.useState('')
  const [digit2, setDigit2] = React.useState('')
  const [digit3, setDigit3] = React.useState('')
  const [digit4, setDigit4] = React.useState('')

  const [isCorrect, setIsCorrect] = React.useState(null)

  const digit1Ref = React.useRef(null)
  const digit2Ref = React.useRef(null)
  const digit3Ref = React.useRef(null)
  const digit4Ref = React.useRef(null)

  const password = digit1 + digit2 + digit3 + digit4

  const _init = () => {
    setDigit1('')
    setDigit2('')
    setDigit3('')
    setDigit4('')
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    toggle()
  }

  const handleOk = () => {
    if (onOk) {
      if (password === salonPassword) onOk()
      else {
        setIsCorrect(true)
        return
      }
    }
    toggle()
  }

  const handleInputPassword = (digit, value) => {
    setIsCorrect(false)
    if (digit === 1) {
      setDigit1(value)
      if (value !== '' && digit2Ref && digit2Ref.current) {
        digit2Ref.current.focus()
      }
    }
    if (digit === 2) {
      setDigit2(value)
      if (value !== '' && digit3Ref && digit3Ref.current) {
        digit3Ref.current.focus()
      }
    }
    if (digit === 3) {
      setDigit3(value)
      if (value !== '' && digit4Ref && digit4Ref.current) {
        digit4Ref.current.focus()
      }
    }
    if (digit === 4) {
      setDigit4(value)
    }
  }

  const handleKeyPress = (digit, e) => {
    setIsCorrect(false)
    if (e.key !== 'Backspace') return
    if (digit === 2) {
      setDigit2('')
      if (digit2 === '' && digit2Ref && digit2Ref.current) {
        digit1Ref.current.focus()
      }
    }
    if (digit === 3) {
      setDigit3('')
      if (digit3 === '' && digit3Ref && digit3Ref.current) {
        digit2Ref.current.focus()
      }
    }
    if (digit === 4) {
      setDigit4('')
      if (digit4 === '' && digit4Ref && digit4Ref.current) {
        digit3Ref.current.focus()
      }
    }
  }

  React.useEffect(() => {
    if (!isShowing) {
      _init()
    }
    else if (digit1Ref && digit1Ref.current) {
      digit1Ref.current.focus()
    }
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
      <div
        onClick={
          (e) => {
            if (digit1 === '' && digit1Ref && digit1Ref.current) {
              digit1Ref.current.focus()
            }
            else if (digit2 === '' && digit2Ref && digit2Ref.current) {
              digit2Ref.current.focus()
            }
            else if (digit3 === '' && digit3Ref && digit3Ref.current) {
              digit3Ref.current.focus()
            }
            else if (digit4 === '' && digit4Ref && digit4Ref.current) {
              digit4Ref.current.focus()
            }
          }
        }
      >
        <ModalOverlay onClick={onCancel} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                <H5 type="Bold" style={{ margin: '12px 0px 7px 0px' }}>
                  {title}
                </H5>
                <Caption2
                  color={isCorrect ? colors.warning : colors.grey_64}
                  style={{ whiteSpace: 'pre' }}
                >
                  {isCorrect ? '암호가 올바르지 않습니다' : body}
                </Caption2>
                <PasswordBox>
                  <EmptyPassword
                    isCorrect={isCorrect}
                    htmlFor="digit1"
                    value={digit1}
                  />
                  <EmptyPassword
                    isCorrect={isCorrect}
                    htmlFor="digit2"
                    value={digit2}
                  />
                  <EmptyPassword
                    isCorrect={isCorrect}
                    htmlFor="digit3"
                    value={digit3}
                  />
                  <EmptyPassword
                    isCorrect={isCorrect}
                    htmlFor="digit4"
                    value={digit4}
                  />
                </PasswordBox>
                <Flex justify="space-between">
                  <CancelBtn onClick={handleCancel} type="button">
                    <H6 color={colors.grey_64}>취소</H6>
                  </CancelBtn>
                  <Okbtn
                    type="submit"
                    onClick={handleOk}
                    disabled={
                      digit1 === ''
                        || digit2 === ''
                        || digit3 === ''
                        || digit4 === ''
                        || isCorrect
                    }
                  >
                    <H6>참여</H6>
                  </Okbtn>
                </Flex>
                <InvisibleInput
                  id="digit1"
                  value={digit1}
                  maxLength={1}
                  onChange={e => handleInputPassword(1, e.target.value)}
                  onKeyDown={e => handleKeyPress(1, e)}
                  ref={digit1Ref}
                />
                <InvisibleInput
                  id="digit2"
                  value={digit2}
                  maxLength={1}
                  onChange={e => handleInputPassword(2, e.target.value)}
                  onKeyDown={e => handleKeyPress(2, e)}
                  ref={digit2Ref}
                />
                <InvisibleInput
                  id="digit3"
                  value={digit3}
                  maxLength={1}
                  onChange={e => handleInputPassword(3, e.target.value)}
                  onKeyDown={e => handleKeyPress(3, e)}
                  ref={digit3Ref}
                />
                <InvisibleInput
                  id="digit4"
                  value={digit4}
                  maxLength={1}
                  onChange={e => handleInputPassword(4, e.target.value)}
                  onKeyDown={e => handleKeyPress(4, e)}
                  ref={digit4Ref}
                />
              </form>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </div>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}

const PasswordBox = styled(Flex)`
  width: 184px;
  height: 16px;
  justify-content: space-between;
  align-self: center;
  margin: 32px 0 40px 0;
`

const EmptyPassword = styled.label`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ value, isCorrect }) =>
    value ? (isCorrect ? colors.warning : colors.primary) : colors.grey_d4};
  box-shadow: ${({ value, isCorrect }) =>
    value
      ? isCorrect
        ? '0 2px 4px 0 rgba(234, 70, 83, 0.24)'
        : '0 2px 4px 0 rgba(91, 73, 243, 0.24)'
      : 'none'};
  cursor: pointer;
`
