import { Img } from '@components/atoms';
import React, { useEffect } from 'react'

const kakao_share_btn = require('../../../public/images/kakao_share_btn.png')

interface ISocialkingKakaoShareButton {
  title:string;
  imageUrl:string;
  onClick:any;
}

const SocialkingKakaoShareButton = ({
  title,
  imageUrl,
  onClick
}:ISocialkingKakaoShareButton) => {

  useEffect(() => {
    if(title && imageUrl){
      createKakaoButton()

      const btn = document.getElementById('kakao-link-btn')
      btn.addEventListener('click',onClick)
    }
  }, [title,imageUrl])

  const createKakaoButton = () => {
    // kakao sdk script이 정상적으로 불러와졌으면 window.Kakao로 접근이 가능합니다
    if (window.Kakao) {
      const kakao = window.Kakao
      // 중복 initialization 방지
      if (!kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        kakao.init('e03cbdf47ecdf5d7725c4f103ca213ed')
      }
      kakao.Link.createDefaultButton({
        // Render 부분 id=kakao-link-btn 을 찾아 그부분에 렌더링을 합니다
        container: '#kakao-link-btn',
        objectType: 'feed',
        content: {
          title,
          description: '인맥왕 테스트 I 나의 인맥은 상위 몇 퍼센트일까?',
          imageUrl, // i.e. process.env.FETCH_URL + '/logo.png'
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        // social: {
        //   likeCount: 77,
        //   commentCount: 55,
        //   sharedCount: 333,
        // },
        buttons: [
          {
            title: '테스트 하러가기',
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          }
        ],
      })
    }
  }
  return (
    <div className="kakao-share-button" style={{width:48,height:48}}>
      {/* Kakao share button */}
      <button id="kakao-link-btn" style={{border:'none', backgroundColor:'transparent', cursor:'pointer', margin:0,padding:0}}>
        <Img src={kakao_share_btn} alt="kakao-share-icon" style={{width:48,height:48}} />
      </button>
    </div>
  )
}
export default SocialkingKakaoShareButton