import { useStore } from '@utils/hooks'
import PlayerManager from '@utils/PlayerManager'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import CastPlayer from './CastPlayer'
import HreumPlayer from './HreumPlayer'

const PlayerContainer = () => {
  const { playerStore, castStore } = useStore()
  const { playerType = null, setPlayerType = () => {} } = playerStore

  const prevPlayer = PlayerManager().getValue()

  useEffect(() => {
    if (playerStore.currentPlayMixtape?.id) {
      setPlayerType('mixtape')
    }
  }, [playerStore.currentPlayMixtape, setPlayerType])

  useEffect(() => {
    if (castStore.currentPlayCast?.id) {
      setPlayerType('cast')
    }
  }, [castStore.currentPlayCast, setPlayerType])

  if (playerType === 'mixtape') return <HreumPlayer />
  if (playerType === 'cast') return <CastPlayer />
  if (!playerType) {
    if (prevPlayer?.type === 'mixtape') return <HreumPlayer />
    if (prevPlayer?.type === 'cast') return <CastPlayer />
  }
  return <></>
}

export default observer(PlayerContainer)
