import { observable, computed } from 'mobx'
import moment from 'moment'
import { STORAGE_URL } from '../../resources'
import {
  AudioFileModel,
  CommentModel,
  LiveRoomRecordingModel,
  UserModel,
  UsersLikeCastsModel,
} from '.'
import CastsHaveTagsModel from './CastsHaveTagsModel'
import HouseModel from './HouseModel'

export default class CastModel {
  @observable id
  @observable title

  @observable description

  @observable isPublished

  @observable createdAt
  @observable updatedAt
  @observable publishedAt

  @observable likeCount
  @observable playCount
  @observable commentCount

  @observable isReserved
  @observable reservedPublishedAt

  @observable userId
  @observable houseId
  @observable isHouseMember
  @observable _audioFileId
  get audioFileId() {
    return this._audioFileId
  }
  set audioFileId(value) {
    this._audioFileId = value
    if (this._audioFileId) {
      this._liveRoomRecordingId = null
    }
  }

  @observable _liveRoomRecordingId
  get liveRoomRecordingId() {
    return this._liveRoomRecordingId
  }
  set liveRoomRecordingId(value) {
    this._liveRoomRecordingId = value
    if (this._liveRoomRecordingId) {
      this._audioFileId = null
    }
  }

  get duration() {
    let _duration = 0

    if (this.audioFileId) {
      _duration = Math.floor(this.AudioFile?.duration || 0)
    }
    else if (this.liveRoomRecordingId) {
      if (this.LiveRoomRecording?.duration) {
        _duration = Math.floor(this.LiveRoomRecording?.duration || 0)
      }
      else if (!this.LiveRoomRecording?.stoppedAt) {
        _duration = 0
      }
      else {
        _duration = moment(this.LiveRoomRecording?.stoppedAt).diff(
          moment(this.LiveRoomRecording?.startedAt),
          'minutes',
        )
      }
    }
    else {
      _duration = 0
    }
    return _duration
  }

  @observable _User = null
  @observable _House = null
  @observable _AudioFile = null
  @observable _LiveRoomRecording = null

  @observable _CastsHaveTags = null
  @observable _UsersLikeCasts = null

  @observable _imageUri

  @computed get imageUri() {
    return this._imageUri
  }
  set imageUri(value) {
    this._imageUri = value

    /* thumbnailUri */
    if (this._imageUri && typeof this._imageUri === 'string') {
      const splitedPath = this._imageUri.split('/')
      const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]
      this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
    }
  }

  @computed get imageUrl() {
    return this._imageUri ? STORAGE_URL + this._imageUri : null
  }
  @computed get imageSource() {
    return this._imageUri ? { uri: STORAGE_URL + this._imageUri } : null
  }
  @computed get thumbnailUrl() {
    return this.thumbnailUri ? STORAGE_URL + this.thumbnailUri : null
  }
  @computed get thumbnailSource() {
    return this.thumbnailUri && { uri: STORAGE_URL + this.thumbnailUri }
  }

  @computed get User() {
    return this._User
  }

  set User(value) {
    this._User =
      (value && value instanceof UserModel
        ? value
        : new UserModel(this.stores, value)) || null
  }

  @computed get House() {
    return this._House
  }

  set House(value) {
    this._House =
      (value && value instanceof HouseModel
        ? value
        : new HouseModel(this.stores, value)) || null
  }

  @computed get AudioFile() {
    return this._AudioFile
  }
  set AudioFile(value) {
    this._AudioFile =
      (value
        && (value instanceof AudioFileModel
          ? value
          : new AudioFileModel(this.stores, value)))
      || null

    if (this._AudioFile) {
      this._LiveRoomRecording = null
    }
  }

  @computed get LiveRoomRecording() {
    return this._LiveRoomRecording
  }
  set LiveRoomRecording(value) {
    this._LiveRoomRecording =
      (value
        && (value instanceof LiveRoomRecordingModel
          ? value
          : new LiveRoomRecordingModel(this.stores, value)))
      || null

    if (this._LiveRoomRecording) {
      this._AudioFile = null
    }
  }

  @computed get CastsHaveTags() {
    return this._CastsHaveTags
  }

  set CastsHaveTags(value) {
    this._CastsHaveTags =
      (value
        && value.map(
          castsHaveTag => new CastsHaveTagsModel(this.stores, castsHaveTag),
        ))
      || []
  }
  @computed get UsersLikeCasts() {
    return this._UsersLikeCasts
  }

  set UsersLikeCasts(value) {
    this._UsersLikeCasts =
      (value
        && value.map(
          castsHaveTag => new UsersLikeCastsModel(this.stores, castsHaveTag),
        ))
      || []
  }

  @observable _commentList = []

  @computed get commentList() {
    return this._commentList
  }

  set commentList(value) {
    this._commentList =
      (value && value.map(comment => new CommentModel(this.stores, comment)))
      || []
  }

  @observable lastPlayTimeStamp
  @observable linkKey

  get isReservedYet() {
    return !!this.isReserved && !!this.reservedPublishedAt && moment(this.reservedPublishedAt) > moment()
  }

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id
      this.title = props.title

      this.imageUri = props.imageUri

      this.description = props.description

      this.isPublished = props.isPublished

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.publishedAt = props.publishedAt

      this.likeCount = props.likeCount
      this.playCount = props.playCount
      this.commentCount = props.commentCount

      this.isReserved = props.isReserved || false
      this.reservedPublishedAt = props.reservedPublishedAt

      this.userId = props.userId
      this.houseId = props.houseId
      this.audioFileId = props.audioFileId
      this.liveRoomRecordingId = props.liveRoomRecordingId

      this.User = props.User
      this.House = props.House
      this.isHouseMember = props.isHouseMember || false
      this.AudioFile = props.AudioFile
      this.LiveRoomRecording = props.LiveRoomRecording

      this.CastsHaveTags = props.CastsHaveTags
      this.UsersLikeCasts = props.UsersLikeCasts

      this.commentList = props.commentList
      this.lastPlayTimeStamp = props.lastPlayTimeStamp || 0
      this.linkKey = props.linkKey
    }
  }
}
