import { IS_DEV } from '@consts/'
import { useEffect } from 'react'

/**
 * 컴포넌트의 렌더 주기를 로깅하기 위한 훅
 * pages에 사용 중
 */
const useRenderLogger = componentName => {
  useEffect(() => {
    if (IS_DEV) {
      console.log(
        `%c[DEV]%c[LOG]%c[${componentName}]%c[useEffect]`,
        'color:red;',
        'color:#594ee9;',
        'color:white; font-size:12px;',
        'color:#EA9800;',
      )
    }
  }, [componentName])
}
export default useRenderLogger
