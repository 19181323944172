import { computed, observable } from 'mobx'

import UserModel from './UserModel'
import FeedImageModel from './FeedImageModel'
import FeedsHaveTagsModel from './FeedsHaveTagsModel'
import FeedsHaveUsersModel from './FeedsHaveUsersModel'
import CommentModel from './CommentModel'
import TagModel from './TagModel'
import { HouseModel } from '.'

export default class FeedModel {
  @observable id

  @observable content
  @observable rawContent

  /* count cache */
  @observable likeCount
  @observable commentCount
  @observable reportCount
  @observable viewCount
  @observable editedAt

  @observable createdAt
  @observable updatedAt
  @observable deletedAt
  @observable publishedAt

  @observable userId

  @observable _User = null

  @observable _FeedImages = []
  @observable _FeedsHaveTags = []
  @observable _FeedsHaveUsers = []

  @observable _UsersLikeMixtapes = []

  @observable _commentList = []
  @observable _commentPreview = []
  @observable _likePreview = []

  @observable isLiked = false
  @observable isHouseMember = false
  @observable audioFileId

  @observable House

  @computed get UsersLikeFeeds() {
    return this._UsersLikeFeeds
  }

  set UsersLikeFeeds(value) {
    this._UsersLikeFeeds =
      (value
        && value.map(usersLikeFeeds => ({
          ...usersLikeFeeds,
          User: usersLikeFeeds['User']
            ? usersLikeFeeds['User'] instanceof UserModel
              ? usersLikeFeeds['User']
              : new UserModel(this.stores, usersLikeFeeds['User'])
            : null,
        })))
      || []

    if (
      this.stores
      && this.stores.authStore
      && this.stores.authStore.currentUser
      && this.stores.authStore.currentUser['id']
    ) {
      const index = this._UsersLikeFeeds.findIndex(
        usersLikeFeeds =>
          usersLikeFeeds
          && usersLikeFeeds['userId'] === this.stores.authStore.currentUser['id'],
      )
      if (index > -1) {
        this.isLiked = true
      }
      else {
        this.isLiked = false
      }
    }
  }

  @computed get commentList() {
    return this._commentList
  }

  set commentList(value) {
    this._commentList =
      (value
        && value
          .map(comment => new CommentModel(this.stores, comment))
          .sort(
            (a, b) =>
              new Date(b['createdAt']).getTime()
              - new Date(a['createdAt']).getTime(),
          ))
      || []
  }

  @computed get commentPreview() {
    return this._commentPreview
  }

  set commentPreview(value) {
    this._commentPreview =
      (value && value.map(comment => new CommentModel(this.stores, comment)))
      || []
  }

  @computed get FeedImages() {
    return this._FeedImages
  }

  set FeedImages(value) {
    this._FeedImages =
      (value
        && value.map(feedImages => new FeedImageModel(this.stores, feedImages)))
      || []
  }

  @computed get FeedsHaveTags() {
    return this._FeedsHaveTags
  }

  set FeedsHaveTags(value) {
    this._FeedsHaveTags =
      (value
        && value.map(feedsHaveTags => ({
          ...feedsHaveTags,
          Tag: feedsHaveTags['Tag']
            ? new TagModel(this.stores, feedsHaveTags['Tag'])
            : null,
        })))
      || []
  }

  @computed get FeedsHaveUsers() {
    return this._FeedsHaveUsers
  }

  set FeedsHaveUsers(value) {
    this._FeedsHaveUsers =
      (value
        && value.map(feedsHaveUsers => ({
          ...feedsHaveUsers,
          User: feedsHaveUsers['User']
            ? new UserModel(this.stores, feedsHaveUsers['User'])
            : null,
        })))
      || []
  }

  @computed get likePreview() {
    return this._likePreview
  }

  set likePreview(value) {
    this._likePreview =
      (value
        && value?.map(likePreview => new UserModel(this.stores, likePreview)))
      || []
  }

  @computed get User() {
    return this._User
  }

  set User(value) {
    this._User =
      (value && value instanceof UserModel
        ? value
        : new UserModel(this.stores, value)) || null
  }

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.content = props.content
      this.rawContent = props.rawContent

      this.commentList = props.commentList
      this.commentPreview = props.commentPreview

      this.likePreview = props.likePreview

      /* count cache */
      this.likeCount = props.likeCount
      this.commentCount = props.commentCount
      this.reportCount = props.reportCount
      this.viewCount = props.viewCount
      /* count cache end */

      this.editedAt = props.editedAt

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt
      this.publishedAt = props.publishedAt

      this.userId = props.userId

      this.User = props.User
      this.audioFileId = props.audioFileId

      this.FeedImages =
        (Array.isArray(props.FeedImages)
          && props.FeedImages.map(
            FeedImage => new FeedImageModel(stores, FeedImage),
          ))
        || []
      this.FeedsHaveTags =
        (Array.isArray(props.FeedsHaveTags)
          && props.FeedsHaveTags.map(
            FeedsHaveTags => new FeedsHaveTagsModel(stores, FeedsHaveTags),
          ))
        || []
      this.FeedsHaveUsers =
        (Array.isArray(props.FeedsHaveUsers)
          && props.FeedsHaveUsers.map(
            FeedsHaveUsers => new FeedsHaveUsersModel(stores, FeedsHaveUsers),
          ))
        || []

      this.isLiked = props.isLiked
      this.UsersLikeFeeds = props.UsersLikeFeeds
      this.isHouseMember = props.isHouseMember
      this.House = (props.House && new HouseModel(stores, props.House)) || null
    }
  }
}
