import { Caption1, Flex, ProfileImg } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { convertToPricingComma } from '@utils/string'
import React from 'react'
import styled from 'styled-components'

const SalonUserStarListItem = ({
  item,
  _toggleUserReport = () => {},
  index,
  currentUserId,
  noHover = false,
  loadSalonUser,
}) => {
  const [hover, setHover] = React.useState(false)
  const [openThreeDot, setOpenThreeDot] = React.useState(false)

  const userRate = item && item.starValue > 0 && index + 1

  if (item) {
    return (
      <Flex
        onMouseEnter={() => !noHover && setHover(true)}
        onMouseLeave={
          () => {
            setHover(false)
            setOpenThreeDot(false)
          }
        }
        style={
          {
            position: 'relative',
            width: '100%',
            height: 64,
            minHeight: 64,
            borderRadius: 8,
            padding: '0px 8px',
            backgroundColor: hover ? colors.white_f4 : 'transparent',
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          align="center"
          style={{ cursor: 'pointer' }}
          // onClick={() => history.push(`/user/${item.User?.account}`)}
          onClick={() => loadSalonUser(item)}
        >
          <UserImageBox>
            <ProfileImg
              src={item.User?.imageUri}
              profileColor={item.User?.profileColor}
              style={
                {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  border: `solid 1px ${colors.white_f4}`,
                  borderRadius: '50%',
                }
              }
            />
            {
              userRate < 4 && userRate > 0 && (
                <RateBadge
                  src={
                    userRate === 1
                      ? images.salon_first_star
                      : userRate === 2
                        ? images.salon_second_star
                        : images.salon_third_star
                  }
                />
              )
            }
          </UserImageBox>
          <Caption1 style={oneEllipsisStyle}>{item.User?.name || ''}</Caption1>
        </Flex>
        {
          hover ? (
            <ThreeDot
              src={images.three_dot_horizontal_img}
              alt="three_dot_icon"
              onClick={() => setOpenThreeDot(true)}
            />
          ) : (
            <Flex align="center">
              <img
                src={images.salon_star_img}
                style={{ width: 24, height: 24 }}
                alt="salon_star_icon"
              />
              <Caption1>{convertToPricingComma(item.starValue || 0)}</Caption1>
            </Flex>
          )
        }

        {
          openThreeDot && item && item.User && currentUserId !== item.User.id && (
            <>
              <ThreeDotMenu>
                <Caption1
                  onClick={() => _toggleUserReport(item.User?.id)}
                  style={{ cursor: 'pointer' }}
                >
                신고하기
                </Caption1>
              </ThreeDotMenu>
            </>
          )
        }
      </Flex>
    )
  }
  return null
}

export default SalonUserStarListItem

const UserImageBox = styled(Flex)`
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 12px;
`

const RateBadge = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;

  width: 20px;
  height: 20px;
`

const ThreeDot = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  cursor: pointer;
`

const ThreeDotMenu = styled(Flex)`
  position: absolute;
  top: 10px;
  right: 8px;
  width: 112px;
  height: 44px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: ${colors.white_ff};
  z-index: 11;
`
