import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { HomeMixtapeListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { isMobile } from 'react-device-detect'

interface Props {
  match: Any;
  location: Any;
}

/**
 * 전체 || 좋아하는 || 구독한 || 기록 || 검색결과 || 태그 플라 전부 이곳에서.
 * @param {*} param0
 */
const MixtapeCategoryListPage = ({ match, location }: Props) => {
  useRenderLogger('MixtapeCategoryListPage')

  const history = useHistory()
  const { mixtapeStore, searchStore, tagStore } = useStore()

  const [offset, setOffset] = useState(0)
  const [haveMore, setHaveMore] = useState(true)

  const handleSearchTag = async tag => {
    setOffset(0)
    setHaveMore(true)
    // fetch 태그 검색
    await searchStore.searchTagKeyword(tag)
    await searchStore.fetchTagLatestMixtapeList(tag)
    history.push(`/p/plra/list/tag?keyword=${tag}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // 각 카테고리에 맞는 fetch
    if (match.params.category) {
      const { keyword } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        // 문자열 맨 앞의 ?를 생력
      })

      let isMore = true
      switch (match.params.category) {
        case 'history':
          tagStore.fetchRepresentiveTagList()
          mixtapeStore.fetchMyListenedMixtapeList()
          break
        case 'total':
          tagStore.fetchRepresentiveTagList()
          isMore = mixtapeStore.fetchTotalMixtapeList({ limit: 20 })
          // isMore = mixtapeStore.fetchHomePopularTotalMixtapeList()
          setHaveMore(isMore)
          break
        case 'like':
          tagStore.fetchRepresentiveTagList()
          isMore = mixtapeStore.fetchMyLikeMixtapeList({ limit: 20 })
          setHaveMore(isMore)
          break
        case 'subscribe':
          tagStore.fetchRepresentiveTagList()
          isMore = mixtapeStore.fetchMyFollowMixtapeList({ limit: 20 })
          setHaveMore(isMore)
          break
        case 'search':
          searchStore.setMixtapeSearchKeyword(keyword)
          isMore = searchStore.fetchSearchMixtapeList(keyword, {
            limit: 20,
          })
          // searchStore.fetchTagKeywordList(keyword)
          searchStore.fetchTagKeywordList()
          // tagStore.fetchRepresentiveTagList()
          setHaveMore(isMore)
          break
        case 'tag':
          searchStore.searchTagKeyword(keyword)
          isMore = searchStore.fetchTagLatestMixtapeList(keyword)
          setHaveMore(isMore)
          break
        default:
          break
      }
      setOffset(0)
    }
  }, [mixtapeStore, tagStore, searchStore, location, match])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return useObserver(() => (
    <HomeMixtapeListTemplate
      offset={offset}
      setOffset={setOffset}
      haveMore={haveMore}
      setHaveMore={setHaveMore}
      category={match.params.category}
      keyword={searchStore.mixtapeSearchKeyword}
      setMixtapeSearchKeyword={searchStore.setMixtapeSearchKeyword || null}
      fetchTagKeywordList={searchStore.fetchTagKeywordList || []}
      searchTagKeywordList={searchStore.searchTagKeywordList || []}
      searchedMixtapeList={searchStore.searchedMixtapeList || []}
      handleSearchTag={handleSearchTag}
      tag={searchStore.mixtapeTagKeyword}
      representiveTagList={tagStore.representiveTagList || []}
      myListenedMixtapeList={mixtapeStore.myListenedMixtapeList || []}
      totalMixtapeList={mixtapeStore.totalMixtapeList || []}
      myLikeMixtapeList={mixtapeStore.myLikeMixtapeList || []}
      myFollowMixtapeList={mixtapeStore.myFollowMixtapeList || []}
      tagMixtapeList={searchStore.tagMixtapeList || []}
      fetchHomePopularTotalMixtapeList={
        mixtapeStore.fetchHomePopularTotalMixtapeList || (() => {})
      }
      fetchTotalMixtapeList={mixtapeStore.fetchTotalMixtapeList || (() => {})}
      fetchMyFollowMixtapeList={
        mixtapeStore.fetchMyFollowMixtapeList || (() => {})
      }
      fetchSearchMixtapeList={searchStore.fetchSearchMixtapeList || (() => {})}
      fetchTagLatestMixtapeList={
        searchStore.fetchTagLatestMixtapeList || (() => {})
      }
      fetchTagPopulartMixtapeList={
        searchStore.fetchTagPopulartMixtapeList || (() => {})
      }
      fetchMyLikeMixtapeList={mixtapeStore.fetchMyLikeMixtapeList || (() => {})}
    />
  ))
}

export default MixtapeCategoryListPage
