import { Button, Caption2, Div } from '@components/atoms'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { useMoreWidth, useStore } from '@utils/hooks'
import { convertFromVolume, convertToVolume } from '@utils/math'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

const LiveRoomMusicBox = ({ toggleSalonMixtape }) => {
  const history = useHistory()
  const { liveRoomStore, playerStore } = useStore()

  const throttleMusicVolume =
    (liveRoomStore
      && liveRoomStore.currLiveRoom
      && !liveRoomStore.currLiveRoom['isMutedMixtape']
      && convertFromVolume(liveRoomStore.currLiveRoom['mixtapeVolume']))
    || 0

  const [musicVolume, setMusicVolume] = useState(throttleMusicVolume)

  const currLiveRoomUser = liveRoomStore.currLiveRoomUser || null
  const currLiveRoom = liveRoomStore._currLiveRoom || null
  const currentPlayingLiveRoomAudio =
    liveRoomStore.currentPlayingLiveRoomAudio || null
  const currentPlayingLiveRoomMixtape =
    liveRoomStore.currentPlayingLiveRoomMixtape || null
  const currPlaySong = playerStore.currentPlaySong || null

  const setDeleteLiveRoomMixtape =
    liveRoomStore.setDeleteLiveRoomMixtape || (() => {})
  const setDeleteLiveRoomAudio =
    liveRoomStore.setDeleteLiveRoomAudio || (() => {})

  const { level = 0 } = currLiveRoomUser || {}

  const throttleChangeVolume = value => {
    if (level < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) return
    // const { value } = e.target
    if (value === 0) {
      if (!liveRoomStore.currLiveRoom?.isMutedMixtape) {
        if (liveRoomStore.currentPlayingLiveRoomAudio) {
          liveRoomStore.setMuteLiveRoomAudio(
            liveRoomStore.currentPlayingLiveRoomAudio,
          )
        }
        else {
          liveRoomStore.setMuteLiveRoomMixtape(
            liveRoomStore.currentPlayingLiveRoomMixtape,
          )
        }
      }
    }
    else if (value > 0) {
      const volume = convertToVolume(value)

      if (liveRoomStore.currentPlayingLiveRoomAudio) {
        liveRoomStore.setVolumeLiveRoomAudio(
          liveRoomStore.currentPlayingLiveRoomAudio,
          volume,
        )
      }
      else {
        liveRoomStore.setVolumeLiveRoomMixtape(
          liveRoomStore.currentPlayingLiveRoomMixtape,
          volume,
        )
      }

      if (liveRoomStore.currLiveRoom['isMutedMixtape']) {
        if (liveRoomStore.currentPlayingLiveRoomAudio) {
          liveRoomStore.setPlayLiveRoomAudio(
            liveRoomStore.currentPlayingLiveRoomAudio,
          )
        }
        else {
          liveRoomStore.setPlayLiveRoomMixtape(
            liveRoomStore.currentPlayingLiveRoomMixtape,
          )
        }
      }
    }
  }

  const isShowMixtapeInfo =
    currLiveRoom
    && (currentPlayingLiveRoomMixtape || currentPlayingLiveRoomAudio)
    && level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
  const isPlayMixtape =
    currLiveRoom
    && (currentPlayingLiveRoomAudio || currentPlayingLiveRoomMixtape)

  const { haveMore } = useMoreWidth({
    defaultWidth: 400,
    id: 'name_box',
    item:
      currLiveRoom
      && currPlaySong
      && currPlaySong.Song
      && currPlaySong.Song.name,
  })

  const handleAllVolume = () => {
    if (currentPlayingLiveRoomAudio) {
      if (currLiveRoom['isMutedMixtape']) {
        liveRoomStore.setPlayLiveRoomAudio(currentPlayingLiveRoomAudio)
      }
      else {
        liveRoomStore.setMuteLiveRoomAudio(currentPlayingLiveRoomAudio)
      }
    }
    else if (currLiveRoom['isMutedMixtape']) {
      liveRoomStore.setPlayLiveRoomMixtape(currLiveRoom['Mixtape'])
    }
    else {
      liveRoomStore.setMuteLiveRoomMixtape(currLiveRoom['Mixtape'])
    }
  }

  const handleChangeVolume = e => {
    e.stopPropagation()
    setMusicVolume(e.target.value)
  }

  const _getPersonalMixtapeVolume = () => {
    if (
      liveRoomStore.personalMixtapeVolume >= 0
      && liveRoomStore.isPersonalVolumeOn
    ) {
      return convertFromVolume(liveRoomStore.personalMixtapeVolume)
    }
    if (
      liveRoomStore
      && liveRoomStore.currLiveRoom
      && !liveRoomStore.currLiveRoom['isMutedMixtape']
    ) {
      return convertFromVolume(liveRoomStore.currLiveRoom['mixtapeVolume'])
    }
    return 0
  }

  const handleAutoVolume = () => {
    playerStore.controlledLocalSalonVolume = false
    liveRoomStore.isPersonalVolumeOn = false

    if (
      liveRoomStore?.personalMixtapeVolume <= 0
      && liveRoomStore.currLiveRoom['mixtapeVolume'] > 0
    ) {
      liveRoomStore.isMutedOnlyMixtape = false
    }
    setMusicVolume(_getPersonalMixtapeVolume())
    playerStore.setVolume(liveRoomStore.currLiveRoom['mixtapeVolume'])
  }

  // 개인 볼륨 조절
  const handlePersonalVolumeChange = e => {
    if (!liveRoomStore.isPersonalVolumeOn)
      liveRoomStore.isPersonalVolumeOn = true
    if (!playerStore.controlledLocalSalonVolume)
      playerStore.controlledLocalSalonVolume = true

    playerStore.setVolume(convertToVolume(parseFloat(e.target.value)))
  }

  const handleOffMusic = useCallback(() => {
    if (currentPlayingLiveRoomAudio) {
      setDeleteLiveRoomAudio(currentPlayingLiveRoomAudio)
    }
    else {
      setDeleteLiveRoomMixtape(currLiveRoom?.Mixtape)
    }
  }, [currentPlayingLiveRoomAudio, currLiveRoom])

  useEffect(() => {
    setMusicVolume(_getPersonalMixtapeVolume())
  }, [throttleMusicVolume])

  return (
    <Div
      width="100%"
      display="flex"
      marginBottom={
        isPlayMixtape || level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
          ? '24px'
          : 0
      }
    >
      {
        !isShowMixtapeInfo && level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] ? (
          <Div
            display="flex"
            flexDirection="column"
            width="100%"
            height="120px"
            alignItems="center"
            justifyContent="center"
            gap="12px"
            backgroundColor={colors.white_ff}
            border={`1px dashed ${colors.grey_bf}`}
            borderRadius="16px"
            cursor="pointer"
            onClick={
              e => {
                e.stopPropagation()
                toggleSalonMixtape()
              }
            }
          >
            <Div
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="21px"
              height="21px"
              borderRadius="50%"
              backgrondColor={colors.primary}
            >
              <img src={images.plus_btn_img} alt="plus" />
            </Div>
            <Caption2 color={colors.grey_64}>
            재생할 플라를 선택해서 같이 들어요
            </Caption2>
          </Div>
        ) : (
          isPlayMixtape && (
            <SalonMixtapeInfo>
              <Div display="flex" alignItems="center" cursor="pointer">
                <SalonMixtapeImage
                  src={
                    currentPlayingLiveRoomAudio
                      ? currentPlayingLiveRoomAudio?.imageUrl
                      : currLiveRoom['Mixtape']?.imageUrl
                        ? currLiveRoom['Mixtape']?.imageUrl
                        : images.default_song_img
                  }
                  alt="salon_img"
                  onClick={
                    () => {
                      if (!currentPlayingLiveRoomAudio) {
                        history.push(
                          `/plra/${currLiveRoom['Mixtape']['linkKey']}`,
                        )
                      }
                    }
                  }
                />
                <Div
                  display="flex"
                  flex="1"
                  gap="12px"
                  justifyContent="space-between"
                >
                  <Div
                    display="flex"
                    flexDirection="column"
                    flex="1"
                    onClick={
                      () => {
                        if (!currentPlayingLiveRoomAudio) {
                          history.push(
                            `/plra/${currLiveRoom['Mixtape']['linkKey']}`,
                          )
                        }
                      }
                    }
                  >
                    {
                      currentPlayingLiveRoomAudio ? (
                        <>
                          <Caption2 align="left" style={oneEllipsisStyle}>
                            {currPlaySong?.Song?.name}
                          </Caption2>
                          <Caption2
                            type="Regular"
                            color={colors.grey_64}
                            align="left"
                            style={oneEllipsisStyle}
                          >
                            {currPlaySong?.Song?.singer}
                          </Caption2>
                        </>
                      ) : (
                        <>
                          <Caption2 align="left" style={oneEllipsisStyle}>
                            {currLiveRoom['Mixtape']?.name}
                          </Caption2>
                          <Caption2
                            type="Regular"
                            color={colors.grey_64}
                            align="left"
                            style={oneEllipsisStyle}
                          >
                            {currLiveRoom?.Mixtape?.User?.name}
                          </Caption2>
                        </>
                      )
                    }
                  </Div>
                  <VolumeControl>
                    <Div
                      cursor="pointer"
                      onClick={
                        e => {
                          e.stopPropagation()
                          handleAllVolume()
                        }
                      }
                    >
                      <img
                        src={
                          currLiveRoom['isMutedMixtape']
                            ? images.all_mixtape_mute_img
                            : images.all_mixtape_btn_img
                        }
                        alt="all_mixtape"
                      />
                    </Div>
                    <VolumenBar
                      type="range"
                      min={0}
                      max={100}
                      step="any"
                      value={
                        liveRoomStore.isPersonalVolumeOn
                      && level < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
                          ? convertFromVolume(playerStore.volume)
                          : musicVolume
                      }
                      // onChange={handleVolumeChange}
                      onMouseUp={e => throttleChangeVolume(e.target.value)}
                      onChange={
                        e => {
                          if (level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
                            handleChangeVolume(e)
                          }
                          else {
                            handlePersonalVolumeChange(e)
                          }
                        }
                      }
                    />
                  </VolumeControl>
                  {
                    level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] ? (
                      <ChangeMixtapeBtn
                        onClick={
                          e => {
                            e.stopPropagation()
                            toggleSalonMixtape()
                          }
                        }
                      >
                    변경
                      </ChangeMixtapeBtn>
                    ) : (
                      <ChangeMixtapeBtn
                        onClick={
                          e => {
                            e.stopPropagation()
                            handleAutoVolume()
                          }
                        }
                      >
                    Auto
                      </ChangeMixtapeBtn>
                    )
                  }
                </Div>
              </Div>
              <Div display="flex" width="100%" gap="12px">
                {
                  isPlayMixtape && (
                    <Div
                      display="flex"
                      alignItems="center"
                      justifyContent={
                        currentPlayingLiveRoomAudio ? 'center' : 'initial'
                      }
                      width="100%"
                      height="36px"
                      cursor="pointer"
                      borderRadius="4px"
                      backgroundColor={colors.white_fa}
                      border={`1px solid ${colors.white_f4}`}
                      padding="0 12px 0 8px"
                      overflow="hidden"
                      onClick={
                        () => {
                          if (!currentPlayingLiveRoomAudio) {
                            history.push(
                              `/plra/${currLiveRoom['Mixtape']['linkKey']}`,
                            )
                          }
                        }
                      }
                    >
                      {
                        currentPlayingLiveRoomAudio ? (
                          <Caption2 color={colors.grey_64}>
                      호스트가 오디오를 송출 중 입니다
                          </Caption2>
                        ) : (
                          <>
                            <img
                              src={images.play_song_img}
                              alt="current_play_song_icon"
                              style={
                                {
                                  width: 16,
                                  height: 16,
                                  marginRight: 4,
                                }
                              }
                            />
                            <Marquee id="name_box" haveMore={haveMore}>
                              <Caption2
                                color={colors.grey_64}
                                type="Bold"
                                align="left"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                {
                                  `${currPlaySong
                            && currPlaySong.Song
                            && currPlaySong.Song.singer} - ${currPlaySong
                            && currPlaySong.Song
                            && currPlaySong.Song.name}`
                                }
                              </Caption2>
                            </Marquee>
                          </>
                        )
                      }
                    </Div>
                  )
                }
                {
                  level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
                    <Div
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      gap="12px"
                    >
                      <ChangeMixtapeBtn
                        style={{ backgroundColor: colors.white_fa }}
                        onClick={
                          e => {
                            e.stopPropagation()
                            handleOffMusic()
                          }
                        }
                      >
                    끄기
                      </ChangeMixtapeBtn>
                    </Div>
                  )
                }
              </Div>
            </SalonMixtapeInfo>
          )
        )
      }
    </Div>
  )
}

export default observer(LiveRoomMusicBox)

const SalonMixtapeInfo = styled(Div)`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${colors.white_ff};
  padding: 16px;
  gap: 12px;
`

const SalonMixtapeImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 12px;
`

const ChangeMixtapeBtn = styled(Button)`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: solid 1px ${colors.white_f4};
  background-color: ${colors.white_ff};

  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
`

const marqueeAni = keyframes`
/* from {transform: translate(0, 0);}
to {transform: translate(-100%, 0); } */
/* 0% {transform: translate(0, 0);}
49% {transform: translate(-100%, 0); }
50% {transform: translate(100%, 0); }
100% {transform: translate(0, 0);} */
from   { transform: translate(0%);}
  99%,to { transform: translate(-100%);}
`
const Marquee = styled.div`
  max-width: 400px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: -3px;

  ${props =>
    props.haveMore
    && css`
      > p:after {
        content: '';
        white-space: nowrap;
        padding-right: 50px;
      }

      > p {
        margin: 0;
        padding-left: 100%;
        display: inline-block;
        white-space: nowrap;
        animation: ${marqueeAni} 7s linear infinite;
      }
    `}
`

const VolumeControl = styled(Div)`
  display: flex;
  align-items: center;
  width: 128px;
  height: 36px;
  padding: 6px 12px;
  border-radius: 22px;
  border: solid 1px ${colors.white_f4};
  background-color: ${colors.white_ff};

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 4px;
  }
`

const VolumenBar = styled.input`
  background: ${({ value }) => `
  linear-gradient(
    to right,
    #5b49f3 0%,
    #5b49f3 ${value}%,
    #d4d4d4 ${value}%,
    #d4d4d4 100%
  )
  `};
  border-radius: 2px;
  height: 2px;
  width: 80px;
  outline: none;
  transition: background 450ms ease-in;

  -webkit-appearance: none;

  ::-webkit-slider-thumb {
    background-color: #5b49f3;
    border: solid 5px #5b49f3;
    border-radius: 0;

    height: 10px;
    width: 10px;
    border-radius: 50%;
    -webkit-appearance: none;
  }
`
