import { Caption1, Caption2, Flex, Overlay } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components'

const firstDate = moment()
  .year(2021)
  .month(2)
  .date(1)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)

const today = moment()
  .date(1)
  .hours(0)
  .minutes(0)
  .seconds(0)
  .milliseconds(0)

const dateList = []
for (const i = firstDate; i <= today; i.add(1, 'month')) {
  dateList.push(i.toDate())
}

const _dateList = dateList.reverse()

const SelectYearMonthBox = ({
  style,
  selected,
  setSelected,
  handleSelected = () => {},
}) => {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSelect = value => {
    setSelected(value)
    setOpen(false)
  }

  return (
    <>
      <Box open={open} onClick={handleOpen} style={style && style}>
        <Caption1 type="Bold">
          {moment(selected).format('YYYY년 MM월')}
        </Caption1>
        <img
          src={images.select_down_gray}
          alt="down_icon"
          style={{ marginLeft: 8, width: 12, height: 12 }}
        />
        {
          open && (
            <OptionList>
              {
                _dateList.map(item => (
                  <Caption2
                    key={item}
                    onClick={
                      e => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleSelect(item)
                      }
                    }
                  >
                    {moment(item).format('YYYY년 MM월')}
                  </Caption2>
                ))
              }
            </OptionList>
          )
        }
      </Box>
      {
        open && (
          <Overlay
            onClick={
              () => {
                setOpen(false)
              }
            }
            style={
              {
                zIndex: 1,
                backgroundColor: 'rgba(36, 36, 36, 0.04)',
              }
            }
          />
        )
      }
    </>
  )
}

export default SelectYearMonthBox

const Box = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 132px;
  height: 38px;
  border-radius: 19px;
  z-index: 2;

  background-color: ${colors.white_ff};

  ${({ open }) =>
    open
      ? css`
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
        `
      : css`
          :hover {
            background-color: ${colors.white_fa};
          }
        `}
`

const OptionList = styled(Flex)`
  flex-direction: column;
  position: absolute;
  bottom: -190px;
  left: 0;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
  height: 186px;
  padding: 0px 17px 16px 17px;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  overflow: auto;
  z-index: 2;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  > p {
    margin-top: 16px;
    cursor: pointer;
  }
`
