import { Div, Flex, ImageIcon, Overlay } from '@components/atoms'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import Files from 'react-butterfiles'
import { message } from 'antd'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'

const SalonChattingSendForm = props => {
  const { liveRoomStore, authStore, appStateStore } = useStore()

  const setIsLoading = appStateStore.setIsLoading || (() => {})

  const [isFocus, setIsFocus] = useState(false)

  const { level, style, onClick, type = 'SALON_CHATT', ...inputProps } = props
  return (
    <>
      <Box style={style && style} type={type} isFocus={isFocus}>
        <TextArea
          {...inputProps}
          type={type}
          onFocus={() => setIsFocus(true)}
        />
        {
          isFocus && level >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] && (
            <Files
              multiple={false}
              maxSize="10mb"
              convertToBase64
              accept={['image/jpg', 'image/png', 'image/jpeg']}
              onSuccess={
                async ([selectedFile]) => {
                  setIsLoading(true)
                  try {
                    const { file } = selectedFile.src

                    const i = new Image()

                    i.onload = async () => {
                      const formData = new FormData()
                      formData.append('image', file)

                      await liveRoomStore.uploadLiveRoomChat(
                        formData,
                        authStore.currentUser,
                        { width: i.width, height: i.height },
                      )
                    }

                    i.src = selectedFile.src.base64

                    message.success('파일 업로드 완료')
                    // console.log('File was successfully uploaded!')
                    setIsLoading(false)
                  }
                  catch (e) {
                    // console.log('An error occurred!', e.message)
                    message.error('파일 업로드 실패')
                    setIsLoading(false)
                  }
                }
              }
              onError={errors => message.error(`파일 업로드 에러 :${errors}`)}
            >
              {
                ({ browseFiles, getDropZoneProps, getLabelProps }) => (
                  <Div
                    onClick={browseFiles}
                    width="36px"
                    height="36px"
                    minWidth="36px"
                    minHeight="36px"
                    borderRadius="50%"
                    backgroundColor={colors.grey_64}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <ImageIcon name="photo" width="20px" height="20px" />
                  </Div>
                )
              }
            </Files>
          )
        }
        <SendImg
          onClick={onClick && onClick}
          src={images.salon_chat_send_img}
          alt="send_img"
        />
      </Box>
      {isFocus && <Overlay onClick={() => setIsFocus(false)} />}
    </>
  )
}

export default observer(SalonChattingSendForm)

const Box = styled(Flex)`
  ${({ isFocus }) =>
    isFocus
      ? css`
          position: absolute;
          max-width: 100%;
          width: 100%;
          z-index: 1000;
        `
      : css`
          position: initial;
          z-index: 1;
        `}
  transition: all 0.25s linear;
  flex: 1;
  height: 48px;

  padding: 6px 6px 6px 16px;
  border-radius: 28px;
  backdrop-filter: blur(24px);
  background-color: ${({ type }) =>
    type === 'SALON_CHATT' ? colors.black_24 : colors.white_f4};

  gap: 12px;
`

const TextArea = styled.textarea`
  resize: none;
  font-family: NotoSansCJKkr-Medium;
  font-size: 14px;
  line-height: 1.9;
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;

  color: ${({ type }) =>
    type === 'SALON_CHATT' ? colors.white_ff : colors.black_24};
  :placeholder {
    color: ${colors.brown_grey};
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const SendImg = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  cursor: pointer;
`
