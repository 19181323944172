import React from 'react'
import styled from 'styled-components'
import { H4, Flex, H6, Caption2 } from '@components/atoms'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import { dateYMD } from '@utils/format'
import { colors } from '@resources/colors'
import { PageLayout } from '..'

type Props = {
  noticeDetail: any,
  handleMoveList: any,
  isLoading: any,
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

// 업로드 목록이 있을 시, 흐름 홈
const NoticeDetailTemplate: React.FC<Props> = ({
  noticeDetail,
  handleMoveList,
  isLoading,
}) => {
  return (
    <PageLayout>
      <ListBox>
        <Flex
          style={
            {
              marginBottom: '40px',
              width: '100%',
              justifyContent: 'center',
              position: 'relative',
            }
          }
        >
          <H4>공지사항</H4>
        </Flex>
        <Content>
          <HeadArea>
            <H6>{noticeDetail?.title}</H6>
            <Caption2 style={{ marginTop: 8 }} color={colors.grey_94}>
              {dateYMD(noticeDetail?.createdAt)}
            </Caption2>
          </HeadArea>
          <ContentArea>{noticeDetail?.content}</ContentArea>
          <Flex style={{ marginTop: 24, justifyContent: 'flex-start' }}>
            <ListBtn onClick={handleMoveList}>
              <Caption2 color={colors.grey_64} type="Medium">
                목록
              </Caption2>
            </ListBtn>
          </Flex>
        </Content>
      </ListBox>
    </PageLayout>
  )
}

export default NoticeDetailTemplate

const Content = styled(Flex)`
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 30px 40px;
`

const HeadArea = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 66px;
  border-bottom: solid 1px ${colors.grey_d4};

  justify-content: center;
  align-items: flex-start;
`

const ContentArea = styled(Flex)`
  width: 100%;
  min-height: 518px;
  height: 100%;
  justify-content: flex-start;
  padding: 32px 0px 16px 0px;
  white-space: pre;

  font-family: NotoSansCJKkr-Regular;
  font-size: 12px;
`

const ListBtn = styled(Flex)`
  width: 64px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
`
