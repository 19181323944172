import React, { useMemo } from 'react'
import { Caption1, Caption2, Caption3, Div, Flex, Image, Img, ProfileImg } from '@components/atoms'
import { images } from '@images/'
import { useStore } from '@utils/hooks'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { YnPopup } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import { urlify } from '@utils/format'
import styled from 'styled-components'
import { timeConvert } from '@utils/time'

const FeedCard = ({ item, handleLoadDetail, from = 'feed' }) => {
  const { authStore, feedStore } = useStore()
  const history = useHistory()

  const {
    isShowing: showHouseMember,
    toggle: toggleHouseMember,
  } = YnPopup.usePopup()

  const isMyHouseLiveRoom =
    authStore.currentUser
    && authStore.myDetailJoinedHouseList?.filter(
      house =>
        house.id === item?.House?.id
        && house?._myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'],
    )?.length > 0

  const handleClick = () => {
    if (item?.isHouseMember && !isMyHouseLiveRoom) {
      toggleHouseMember()
    }
    else {
      handleLoadDetail(item.id)
    }
  }

  const feedType = useMemo(() => {
    //     1) 텍스트(or 음성)만 있을 경우의 UI (이미지, 링크 X)
    // 2) 음성과 텍스트가 같이 있을 경우의 UI (이미지, 링크 X)
    // 3) 링크 AND (음성 or 텍스트가 있을 경우의 UI) → 링크 미리보기 이미지 활용 (페이스북, 트위터 참고)
    // 4) 이미지 AND (음성 or 텍스트 or 링크가 있을 경우의 UI) →  링크가 있을 경우에는 하이퍼 링크정도로 잡고 이미지 미리보기 이미지 활용
    if (item?.FeedImages?.length > 0) {
      return 'haveImage'
    }
    const urls = item?.content?.match(/(https?:\/\/[^\s]+)/g)
    if (urls?.length > 0) {
      return 'haveLink'
    }
    return 'haveText'
  }, [item])

  const handleLikeFeed = async feed => {
    if (feed) {
      if (feed && feed.isLiked) {
        const res = await feedStore.dislikeFeed(feed)
        if (!res) return

        feed.likeCount--
      }
      else {
        const res = await feedStore.likeFeed(feed)
        if (!res) return

        feed.likeCount++
      }
    }
  }

  return (
    <Flex
      onClick={handleClick}
      style={{ cursor: 'pointer', position: 'relative', overflow: 'hidden' }}
    >
      {
item?.FeedImages?.length > 1 && (
          <Img
            src={images.image_list_img_white}
            width="20px"
            height="20px"
            position="absolute"
            right="8px"
            top="8px"
          />
        )
      }
      {
item?.isHouseMember && (
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="24px"
            height="24px"
            position="absolute"
            right="8px"
            top="8px"
            borderRadius="50%"
            backgroundColor="rgba(36, 36, 36, 0.56)"
          >
            <Img src={images.house_type_member_icon} width="16px" height="16px" />
          </Div>
        )
      }
      {
        feedType !== 'haveImage' && item?.audioFileId && (
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="16px"
            height="16px"
            position="absolute"
            top="24px"
            right="16px"
          >
            <Img src={images.volume_btn_img_gray} width="16px" height="16px" />
          </Div>
        )
      }
      {
        feedType === 'haveImage' && item?.audioFileId && (
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="24px"
            height="24px"
            position="absolute"
            right="8px"
            bottom="8px"
            borderRadius="50%"
            backgroundColor="rgba(36, 36, 36, 0.56)"
          >
            <Img src={images.volume_btn_img} width="16px" height="16px" />
          </Div>
        )
      }
      {
        feedType === 'haveImage' ? (
          <Image
            src={item.FeedImages[0]?.imageUrl}
            width={from === 'feed' ? '336px' : '248px'}
            height={from === 'feed' ? '336px' : '248px'}
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Div
            width={from === 'feed' ? '336px' : '248px'}
            height={from === 'feed' ? '336px' : '248px'}
            border="1px solid #f4f4f4"
            padding="16px"
            display="flex"
            flexDirection="column"
            gap="12px"
          >
            <Div display="flex" alignItems="center" gap="8px" height="24px">
              <ProfileImg
                src={item?.User?.imageUri}
                profileColor={item?.User?.profileColor}
                style={{borderRadius:'50%', width:32, height:32}}
              />
              <Caption2>{item?.User?.name || ''}</Caption2>
            </Div>
            <StyledCaption1
              align="left"
              style={{ 
                flex:1, 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 7,
                WebkitBoxOrient: 'vertical',
                wordWrap: 'break-word',
              }}
              dangerouslySetInnerHTML={
                {
                  __html: urlify(item?.rawContent || ''),
                }
              }
            >
              {/* {item?.rawContent} */}
            </StyledCaption1>
            {/* {
              feedType === 'haveLink' && (
                <LinkPreview
                  // url={item?.content?.match(/(https?:\/\/[^\s]+)/g)[0]}
                  url={"https://www.naver.com"}
                  width={216}
                  height={48}
                  // descriptionLength={20}
                />
              )
            } */}
            <Div height="24px" display="flex" justifyContent="space-between">
              <Div>
                <Image
                  src={
                      item?.isLiked
                        ? images.heart_img_red_12
                        : images.heart_img_black_default
                  }
                  width="16px"
                  height="16px"
                  style={{ marginRight: 6 }}
                  onClick={() => handleLikeFeed(item)}
                />
                <Image
                  src={images.comment_default_img}
                  width="16px"
                  height="16px"
                />
              </Div>
              <Caption3 color="#949494">{timeConvert(item?.editedAt ?? item?.createdAt)}</Caption3>
            </Div>
          </Div>
        )
      }

      {
item?.isHouseMember && (
          <YnPopup.View
            isShowing={showHouseMember}
            toggle={toggleHouseMember}
            title="하우스 멤버 콘텐츠"
            body={'해당 콘텐츠는 멤버만 입장 가능합니다.'}
            yText="멤버 가입하기"
            onOk={
              () => {
                const newpath = `/house/${item?.House?.linkKey}`
                if(history?.location?.pathname !== newpath) {
                  history.push(newpath)
                }
              }
            }
          />
        )
      }
    </Flex>
  )
}

export default FeedCard

const StyledCaption1 = styled(Caption1)`
 a {
  color:blue !important;
  text-decoration: underline;
 }
`
