import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { H4, Caption1, Flex } from '@components/atoms'
import { Table, Spin } from 'antd'
import '@styles/custom-antd.css'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import { PageLayout } from '..'

type Props = {
  faqList: any,
  isLoading: any,
}

const ListBox = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 124px;
  @media ${mobile} {
    width: 100%;
  }
`

const FaqTemplate: React.FC<Props> = ({
  faqList,
  currentPage,
  setCurrentPage,
  isLoading,
}) => {
  const [list, setList] = useState([])

  const toggleRow = id => {
    const findIdx = list.findIndex(item => item.id === id)
    list[findIdx]['isExpanded'] = !list[findIdx]['isExpanded']
    setList([...list])
  }

  useEffect(() => {
    setList(
      faqList
        && faqList.map(el => {
          if (!Object.prototype.hasOwnProperty.call(el, 'isExpanded')) {
            return { ...el, ...{ isExpanded: false } }
          }

          return null
        }),
    )
  }, [faqList])

  const columns = [
    {
      key: 'title',
      dataIndex: 'title',
      width: '500px',
      render: (text, record) => {
        return (
          <RowItemBox
            onClick={() => toggleRow(record.id)}
            expanded={record.isExpanded}
          >
            <Flex type="column" style={{ alignItems: 'flex-start' }}>
              <Caption1
                color={record.isExpanded ? colors.primary : colors.black_24}
              >
                {record.title}
              </Caption1>
              {
                record.isExpanded && (
                  <Caption1
                    style={{ marginTop: 23, whiteSpace: 'pre-wrap' }}
                    align="left"
                  >
                    {record.content}
                  </Caption1>
                )
              }
            </Flex>
            <img
              src={
                record.isExpanded
                  ? images.move_up_gray_img
                  : images.move_down_gray_img
              }
              alt="move_btn"
            />
          </RowItemBox>
        )
      },
    },
  ]

  return (
    <PageLayout>
      <ListBox>
        <Flex
          style={
            {
              marginBottom: '40px',
              width: '100%',
              justifyContent: 'center',
              position: 'relative',
            }
          }
        >
          <H4>자주하는 질문</H4>
        </Flex>
        <Table
          className="faq_table"
          columns={columns}
          dataSource={list}
          // pagination={{ pageSize: 10, defaultPageSize: 10 }}
          pagination={false}
          // pageSize={10}
          size={'small'}
          loading={
            {
              spinning: isLoading,
              indicator: <Spin size="large" />,
            }
          }
          style={
            {
              backgroundColor: 'white',
              borderRadius: '16px',
              boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
              width: '100%',
              padding: '30px 40px',
            }
          }
          rowKey="id"
        />
      </ListBox>
    </PageLayout>
  )
}

export default FaqTemplate

const RowItemBox = styled(Flex)`
  padding: 24px 16px;
  box-sizing: border-box;

  align-items: ${({ expanded }) => (expanded ? 'flex-start' : 'center')};
  justify-content: space-between;
  cursor: pointer;

  height: ${({ expanded }) => (expanded ? 'auto' : '66px')};
  background-color: ${({ expanded }) => (expanded ? '#fafafa' : '#ffffff')};
`
