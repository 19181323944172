import React from 'react'
import { NeedAuthTemplate } from '@components/templates'
import { useRenderLogger } from '@utils/hooks'

const NeedAuthPage = () => {
  useRenderLogger('NeedAuthPage')

  return <NeedAuthTemplate />
}

export default NeedAuthPage
