import { action, computed, observable } from 'mobx'
import { Store } from '.'
import { Network } from './networks'
import { upload } from '../utils'
import { FeedModel } from './models'

class FeedViewModel {
  @observable _elem = null
  @computed get elem() {
    return this._elem
  }
  set elem(value) {
    if (
      this._elem
      && value
      && typeof this._elem === 'object'
      && typeof value === 'object'
    ) {
      Object.keys(value).forEach(key => {
        this._elem[key] = value[key]
      })
    }
    else {
      this._elem = value
    }
  }

  screenCount = 0
  constructor(stores, props) {
    if (props) {
      this.elem = props.elem || null
      this.screenCount = props.screenCount || 0
    }
  }
}

export default class FeedStore {
  @observable userFeedList // 특정 유저의 피드 목록
  @observable feedDetail // 상세 피드
  @observable myFeedList // 나의 피드 목록
  @observable feedDetailObj = {}
  @observable suggestFeedList = []
  @observable suggestFeedCursor = {}

  @observable mentionsMap

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = () => {
    this.myFeedList = []
    this.userFeedList = []
    this.feedDetailObj = {}
    this.feedDetail = {}
    this.mentionsMap = new Map()
  }

  addFeedScreen = (feedId: Number) => {
    if (!feedId) return
    if (
      this.feedDetailObj[feedId]
      && typeof this.feedDetailObj[feedId]['screenCount'] === 'number'
    ) {
      this.feedDetailObj[feedId]['screenCount'] += 1
    }
    else {
      this.feedDetailObj[feedId] = new FeedViewModel(this.store, {
        elem: new FeedModel(this.store, { id: feedId }),
        screenCount: 1,
      })
    }
  }
  deleteMixtapeScreen = (feedId: Number) => {
    if (!feedId) return
    if (
      this.feedDetailObj[feedId]
      && typeof this.feedDetailObj[feedId]['screenCount'] === 'number'
    ) {
      this.feedDetailObj[feedId]['screenCount'] -= 1
    }
  }

  @action syncFeedDetailObj = feed => {
    if (!feed || !feed['id']) return feed

    if (!this.feedDetailObj[feed['id']]) {
      this.feedDetailObj[feed['id']] = new FeedViewModel(this.store, {
        elem: new FeedModel(this.store, feed),
        screenCount: 0,
      })
    }
    else {
      this.feedDetailObj[feed['id']]['elem'] = feed
    }

    return this.feedDetailObj[feed['id']]['elem']
  }

  @action getFeedDetailObj = feed => {
    if (!feed || !feed['id']) return feed

    if (!this.feedDetailObj[feed['id']])
      this.feedDetailObj[feed['id']] = new FeedViewModel(this.store, {
        elem: new FeedModel(this.store, feed),
        screenCount: 0,
      })

    return this.mixtapeDetailObj[feed['id']]['elem']
  }

  @action fetchFeed = async (feedId, where) => {
    if (!feedId) return
    return this.store.useLoading(async () => {
      const result = await this.network.feedNetwork.getFeed(feedId, where)

      if (!result) return

      const commentList = await this.store.commentStore.fetchTargetReply(
        feedId,
        'Feeds',
        1,
      )

      result.commentList = commentList?.slice() || []

      const feed = await this.syncFeedDetailObj(result)
      this.feedDetail = feed
      return feed
    })
  }

  @action fetchFeedFromLinkKey = async (linkKey) => {
    if (!linkKey) return
    return this.store.useLoading(async () => {
      /* 현재 houseId,isHouseMember 가 있어야 권한검사 수행 */
      const result = await this.network.feedNetwork.getFeedFromLinkKey(linkKey, {__attributes:'id,houseId,isHouseMember'})
      return result
    })
  }

  @action fetchFeedList = async (userId, options = {}) => {
    const defaultWhere = {
      __limit: 20,
      userId,
      __order: 'createdAtDesc',
    }
    const _addedAttributes =
      'id,linkKey,createdAt,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account'

    const _fetchOptionList = [
      {
        /* index=0 은 base fetch 라 attribute 제한 없음. */
        __include: 'Users,FeedImages,FeedsHaveUsers',
      },

      /* 안쓰는 데이터 fetch 주석. 추후에도 리스트 fetch 가 아닌 상세 fetch 에서만 태그와 멘션을 fetch 하는 시나리오 고려해야 함 */
      // {
      //   __include: 'FeedsHaveUsers.Users',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account`,
      // },
      // {
      //   __include: 'FeedsHaveTags.Tags',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveTags.id,FeedsHaveTags.Tags.id`,
      // },
    ]
    if (this.store.authStore?.isLogined()) {
      _fetchOptionList.push({
        __include: 'UsersLikeFeeds',
        'UsersLikeFeeds.userId': this.store.authStore?.currentUser?.id,
        __attributes: `${
          _addedAttributes ? `${_addedAttributes},` : ``
        }id,UsersLikeFeeds.id,UsersLikeFeeds.userId`,
      })
    }

    Object.assign(defaultWhere, options)

    let result = null
    if (defaultWhere['__include']) {
      result = await this.network.feedNetwork.getFeedList(defaultWhere)
    }
    else {
      const resultList = await Promise.all(
        _fetchOptionList.map(_fetchOption => {
          return this.network.feedNetwork.getFeedList({
            ..._fetchOption,
            ...defaultWhere,
          })
        }),
      )

      if (resultList && resultList.length > 0) {
        const baseResult = resultList[0]
        resultList.forEach(_result => {
          if (_result && Array.isArray(_result)) {
            // eslint-disable-next-line
            _result.map(feed => {
              const feedIndex = baseResult.findIndex(
                _baseFeed => _baseFeed['id'] === feed['id'],
              )
              if (feedIndex > -1) {
                baseResult[feedIndex] = { ...feed, ...baseResult[feedIndex] }
              }
            })
          }
        })
        result = baseResult
      }
    }

    const feedList = result?.map(val => this.syncFeedDetailObj(val))

    return feedList
  }

  @action fetchUserFeedList = async (userId, options = {}) => {
    const defaultWhere = {
      __limit: 20,
      userId,
      __order: 'publishedAtDesc',
    }
    const _addedAttributes =
      'id,linkKey,publishedAt,createdAt,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account'

    const _fetchOptionList = [
      {
        __attributes: `\
id,houseId,userId,content,rawContent,likeCount,commentCount,audioFileId,isHouseMember,createdAt,editedAt,publishedAt,\
FeedsHaveUsers.id,FeedsHaveUsers.userId,\
Houses.id,Houses.title,Houses.imageUri,Houses.isPremiumMember,Houses.linkKey,\
Users.id,Users.name,Users.imageUri,Users.profileColor,Users.isInfluencer,Users.linkKey,\
FeedImages.id,FeedImages.imageUri,FeedImages.feedId,\
AudioFiles.id,AudioFiles.isFeed,AudioFiles.originalUri,AudioFiles.duration`,
        __include:
          'Users,FeedImages,FeedsHaveUsers,AudioFiles,AudioFiles.AudioFileTexts',
      },

      /* 안쓰는 데이터 fetch 주석. 추후에도 리스트 fetch 가 아닌 상세 fetch 에서만 태그와 멘션을 fetch 하는 시나리오 고려해야 함 */
      // {
      //   __include: 'FeedsHaveUsers.Users',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveUsers.id,FeedsHaveUsers.Users.id,FeedsHaveUsers.Users.account`,
      // },
      // {
      //   __include: 'FeedsHaveTags.Tags',
      //   __attributes: `${_addedAttributes ? `${_addedAttributes},` : ``}id,FeedsHaveTags.id,FeedsHaveTags.Tags.id`,
      // },
    ]
    if (this.store.authStore?.isLogined()) {
      _fetchOptionList.push({
        __include: 'UsersLikeFeeds',
        'UsersLikeFeeds.userId': this.store.authStore?.currentUser?.id,
        __attributes: `${
          _addedAttributes ? `${_addedAttributes},` : ``
        }id,UsersLikeFeeds.id,UsersLikeFeeds.userId`,
      })
    }

    Object.assign(defaultWhere, options)

    let result = null
    if (defaultWhere['__include']) {
      result = await this.network.feedNetwork.getFeedList(defaultWhere)
    }
    else {
      const resultList = await Promise.all(
        _fetchOptionList.map(_fetchOption => {
          return this.network.feedNetwork.getFeedList({
            ..._fetchOption,
            ...defaultWhere,
          })
        }),
      )

      if (resultList && resultList.length > 0) {
        const baseResult = resultList[0]
        resultList.forEach(_result => {
          if (_result && Array.isArray(_result)) {
            // eslint-disable-next-line
            _result.map(feed => {
              const feedIndex = baseResult.findIndex(
                _baseFeed => _baseFeed['id'] === feed['id'],
              )
              if (feedIndex > -1) {
                baseResult[feedIndex] = { ...feed, ...baseResult[feedIndex] }
              }
            })
          }
        })
        result = baseResult
      }
    }

    if (!result) {
      this.userFeedList = []
      return
    }

    const feedList = result?.map(val => this.syncFeedDetailObj(val))

    if (this.userFeedList.length > 0) {
      this.userFeedList = this.userFeedList.concat(feedList)
    }
    else {
      this.userFeedList = feedList
    }

    return feedList
  }

  @action likeFeed = async feed => {
    if (!this.store?.authStore?.isLogined() || !feed) {
      this.store.authStore.tryAuth()
      return
    }

    return this.store.useLoading(async () => {
      const where = {
        feedId: feed?.id,
      }

      const result = await this.network.feedNetwork.postFeedLike(where)

      if (!result) return

      feed['isLiked'] = true

      return result
    })
  }

  @action dislikeFeed = async feed => {
    if (!this.store?.authStore?.isLogined() || !feed) {
      this.store.authStore.tryAuth()
      return
    }

    return this.store.useLoading(async () => {
      const where = {
        feedId: feed?.id,
      }

      const result = await this.network.feedNetwork.deleteFeedLike(where)

      if (!result) return

      feed['isLiked'] = false

      return result
    })
  }

  @action uploadImage = async filePath => {
    try {
      if (!filePath) return null

      const formData = new FormData()
      const { token } = this.store.authStore

      formData.append('image', {
        uri: filePath,
        type: 'image/jpeg', // or photo.type
        name: 'testPhotoName',
      })

      const { data } = await upload('/uploads/feeds/image', token, formData)

      return data
    }
    catch (error) {
      console.error('[LOG][FeedStore][uploadImage] error', error)
      return false
    }
  }

  @action feedsUpload = async feed => {
    if (!feed) return

    const {
      content = '',
      rawContent = '',
      FeedImages = [],
      FeedsHaveUsers = [],
      FeedsHaveTags = [],
    } = feed

    const where = [
      {
        content,
        rawContent,
        FeedImages: FeedImages?.map(val => {
          return { imageUri: val?._imageUri }
        }),
        Users: FeedsHaveUsers?.map(val => ({ id: val.id, name: val.name })),
        Tags: FeedsHaveTags?.map(val => val),
      },
    ]

    const result = await this.network.feedNetwork.postFeeds(where)

    return result
  }

  @action feedsEditUpdate = async feed => {
    if (!feed) return

    const {
      id,
      content = '',
      rawContent = '',
      // FeedImages = [],
      FeedsHaveUsers = [],
      FeedsHaveTags = [],
    } = feed

    const where = {
      content,
      rawContent,
      // FeedImages: FeedImages?.map((val) => {
      //   return { imageUri: val?._imageUri }
      // }),
      Users: FeedsHaveUsers?.map(val => ({ id: val.id })),
      Tags: FeedsHaveTags?.map(val => ({ keyword: val.Tag.keyword })),
    }

    const result = await this.network.feedNetwork.putFeeds(id, where)

    return result
  }

  @action feedsDelete = async feed => {
    if (!feed) return

    const result = await this.network.feedNetwork.deleteFeeds(feed.id)

    return result
  }

  @action suggestFeed = async where => {
    const options = {
      recommendationUserIdList: this.store.userStore?.suggestFollowList
        ?.slice()
        ?.map(val => {
          if (val.id !== this.store?.authStore?.currentUser?.id) {
            return val.id
          }
        })
        .join(','),
      followRecentFeedId: 'null',
      followOldFeedId: 'null',
      popularityFeedId: 'null',
      recommendationFollowFeedId: 'null',
    }

    Object.assign(options, where)

    if (options.isFinished) {
      return
    }

    const result = await this.network.feedNetwork.getSuggestFeedList(options)

    if (!result || result?.length === 0) {
      this.suggestFeedCursor.isFinished = true
      return
    }

    if (this.suggestFeedList) {
      // this.suggestFeedList?.push(
      //   ...result?.feedList?.map((val) => this.syncFeedDetailObj(val)),
      // )
      this.suggestFeedList = [
        ...new Set([
          ...this.suggestFeedList,
          ...result?.feedList?.map(val => this.syncFeedDetailObj(val)),
        ]),
      ]
    }
    else {
      this.suggestFeedList = result?.feedList?.map(val =>
        this.syncFeedDetailObj(val),
      )
    }

    this.suggestFeedCursor = result?.cursor

    return result
  }

  @action fetchFeedListCommentPreview = async feedId => {
    if (!feedId) {
      console.error('fetchFeedListCommentPreview Error', 'feedId required')
      return []
    }

    const result = await this.network.feedNetwork.getFeedListCommentPreview({
      feedId,
    })
    if (!result) {
      return []
    }

    return result
  }

  @action fetchFeedNestedCommentPreview = async parentCommentId => {
    if (!parentCommentId) {
      console.error('fetchFeedNestedCommentPreview Error', 'feedId required')
      return []
    }

    const result = await this.network.feedNetwork.getFeedNestedCommentPreview({
      parentCommentId,
    })
    if (!result) {
      return []
    }

    return result
  }
}
