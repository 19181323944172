import { observable, action, computed } from 'mobx'
import { images } from '@resources'
import { STORAGE_URL, ARTIST_LEVEL } from '@consts/'
import { UsersSingSongsModel, UsersHaveAlbumsModel } from '.'
import UsersFollowUsersModel from './UsersFollowUsersModel'
import UsersFollowedUsersModel from './UsersFollowedUsersModel'

export default class UserModel {
  @observable id
  @observable account
  @observable isKakaoAccount
  @observable level
  @observable artistLevel
  @observable discJockeyLevel
  @observable adLevel
  @observable email
  @observable isPublishedEmail
  @observable phone
  @observable password
  @observable name
  @observable sex
  @observable birthday
  @observable isEmailAuth
  @observable isPhoneAuth
  @observable isAgreeMarketing
  @observable description
  @observable profileColor
  @observable imageKey
  @observable imageUri
  @observable imageSource
  @observable isRegistedSinger
  @observable bioEmail
  @observable bioWebsite
  @observable bioInstagram
  @observable bioYoutube
  @observable createdAt
  @observable updatedAt

  @observable mixtapeCount
  @observable followerCount
  @observable followingCount
  @observable mixtapePopularWeight
  @observable popularWeight

  @observable UsersSingSongs = []

  @observable UsersHaveAlbums = []
  @observable UsersFollowUsers = []
  @observable UsersFollowedUsers = []
  @observable Mixtapes = []

  @observable thumbnailUri
  @observable thumbnailSource

  @observable badgeId
  @observable badgeImageUri
  @observable badgeName

  @observable isFollowed = false

  @observable revenueLevel
  @observable isAgreePartnerDJTerms
  @observable bannedDJAt

  @observable warningStatus
  @observable firstWarningHistoryId

  /* 캐시된 revenue */
  @observable currentRevenue
  @observable cumulativeRevenue
  @observable receivableRevenue
  @observable revenueUpdatedAt
  @observable isExistPassword

  // star
  @observable currentStar
  @observable exchangedStar
  @observable receivedStar
  @observable sendedStar
  @observable expiredStar
  @observable toExpiredStar

  @observable backgroundImageUri
  @observable backgroundImageUrl

  @observable organization
  @observable duty
  @observable mbti
  @observable isInfluencer

  @observable youtubeVideoSyncState
  @observable isShowYoutubeChannelSyncButton
  @observable rssInfoSyncState
  @observable isShowRssInfoSyncButton

  @observable isPrivate

  @computed get badgeImageSource() {
    return (
      (this.badgeImageUri && {
        uri:
          this.badgeImageUri
          && (this.badgeImageUri.slice(0, 4) === 'http'
            ? this.badgeImageUri
            : STORAGE_URL + this.badgeImageUri),
      })
      || ''
    )
  }

  @computed get badgeThumbnailUri() {
    if (this.badgeImageUri && typeof this.badgeImageUri === 'string') {
      const splitedPath = this.badgeImageUri.split('/')
      const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]
      const thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
      return thumbnailUri
    }
    return ''
  }

  @computed get badgeThumbnailSource() {
    if (
      this.badgeImageUri
      && typeof this.badgeImageUri === 'string'
      && this.badgeThumbnailUri
      && typeof this.badgeThumbnailUri === 'string'
    ) {
      const badgeThumbnailSource = {
        uri:
          this.badgeThumbnailUri
          && (this.badgeThumbnailUri.slice(0, 4) === 'http'
            ? this.badgeThumbnailUri
            : STORAGE_URL + this.badgeThumbnailUri),
      }

      return badgeThumbnailSource
    }
    return ''
  }
  @observable linkKey

  constructor(stores, props) {
    this.artistLevel = ARTIST_LEVEL['NOT_YET']
    if (props) {
      this.id = props.id
      this.account = props.account
      this.isKakaoAccount = props.isKakaoAccount
      this.level = props.level
      this.artistLevel = props.artistLevel
      this.discJockeyLevel = props.discJockeyLevel
      this.adLevel = props.adLevel
      this.email = props.email
      this.isPublishedEmail = props.isPublishedEmail
      this.phone = props.phone
      this.password = props.password
      this.name = props.name
      this.sex = props.sex
      this.birthday = props.birthday
      this.isEmailAuth = props.isEmailAuth
      this.isPhoneAuth = props.isPhoneAuth
      this.isAgreeMarketing = props.isAgreeMarketing
      this.description = props.description
      this.profileColor = props.profileColor
      this.imageKey = props.imageUri
      this.imageUri =
        props.imageUri
        && (props.imageUri.slice(0, 4) === 'http'
          ? props.imageUri
          : STORAGE_URL + props.imageUri)
      this.imageSource = this.imageUri && { uri: this.imageUri }
      this.isRegistedSinger = props.isRegistedSinger
      this.bioEmail = props.bioEmail
      this.bioWebsite = props.bioWebsite
      this.bioInstagram = props.bioInstagram
      this.bioYoutube = props.bioYoutube
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.isExistPassword = props.isExistPassword

      this.popularTags = props.popularTags

      this.UsersFollowUsers =
        (props.UsersFollowUsers
          && props.UsersFollowUsers.map(
            elem => new UsersFollowUsersModel(stores, elem),
          ))
        || []
      this.UsersFollowedUsers =
        (props.UsersFollowedUsers
          && props.UsersFollowedUsers.map(
            elem => new UsersFollowedUsersModel(stores, elem),
          ))
        || []
      this.UsersSingSongs =
        (props.UsersSingSongs
          && props.UsersSingSongs.map(
            elem => new UsersSingSongsModel(stores, elem),
          ))
        || []
      this.UsersHaveAlbums =
        (props.UsersHaveAlbums
          && props.UsersHaveAlbums.map(
            elem => new UsersHaveAlbumsModel(stores, elem),
          ))
        || []
      // this.Mixtapes =
      //   (props.Mixtapes &&
      //     props.Mixtapes.map(elem =>
      //       elem instanceof MixtapeModel
      //         ? elem
      //         : new MixtapeModel(stores, elem),
      //     ).sort(
      //       (a, b) =>
      //         new Date(b['createdAt']).getTime() -
      //         new Date(a['createdAt']).getTime(),
      //     )) ||
      //   []

      if (this.imageUri && typeof this.imageUri === 'string') {
        const splitedPath = this.imageUri.split('/')
        const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]

        this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
        this.thumbnailSource = this.thumbnailUri && { uri: this.thumbnailUri }
      }

      this.badgeId = props.badgeId || null
      this.badgeImageUri = props.badgeImageUri || null
      this.badgeName = props.badgeName || null

      this.isFollowed = props.isFollowed

      this.mixtapeCount = props.mixtapeCount
      this.followerCount = props.followerCount
      this.followingCount = props.followingCount
      this.mixtapePopularWeight = props.mixtapePopularWeight
      this.popularWeight = props.popularWeight

      this.revenueLevel = props.revenueLevel
      this.isAgreePartnerDJTerms = props.isAgreePartnerDJTerms
      this.bannedDJAt = props.bannedDJAt

      this.warningStatus = props.warningStatus
      this.firstWarningHistoryId = props.firstWarningHistoryId

      this.currentRevenue = props.currentRevenue
      this.cumulativeRevenue = props.cumulativeRevenue
      this.receivableRevenue = props.receivableRevenue
      this.revenueUpdatedAt = props.revenueUpdatedAt

      this.currentStar = props.currentStar
      this.exchangedStar = props.exchangedStar
      this.receivedStar = props.receivedStar
      this.sendedStar = props.sendedStar
      this.expiredStar = props.expiredStar
      this.toExpiredStar = props.toExpiredStar

      this.popularTags = props.popularTags

      this.backgroundImageUri = props.backgroundImageUri
      this.backgroundImageUrl =
        props.backgroundImageUri
        && (props.backgroundImageUri.slice(0, 4) === 'http'
          ? props.backgroundImageUri
          : STORAGE_URL + props.backgroundImageUri)

      this.organization = props.organization
      this.duty = props.duty
      this.mbti = props.mbti
      this.isInfluencer = props.isInfluencer

      this.youtubeVideoSyncState = props.youtubeVideoSyncState
      this.isShowYoutubeChannelSyncButton = props.isShowYoutubeChannelSyncButton
      this.rssInfoSyncState = props.rssInfoSyncState
      this.isShowRssInfoSyncButton = props.isShowRssInfoSyncButton

      this.isPrivate = props.isPrivate || false
      this.linkKey = props.linkKey
    }
  }

  @action failLoadThumbnail = () => {
    this.thumbnailUri = null
    this.thumbnailSource = null
  }

  @action failLoadImage = () => {
    this.imageSource = images.user
  }
}
