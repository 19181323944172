import React from 'react'
import {
  SampleModal,
  ConfirmModal,
  MyBlockUserModal,
} from '@components/organisms/modal/index'

export type ModalsInterface = {
  /** 모달이 표현될 type */
  type: 'sample' | 'confirm' | 'myBlockUser',
  /** 모달에 바로 전달할 데이터 */
  payload?: any,
  /** 데이터가 아닌 추가 전달사항 */
  meta?: any,
  /** 해쉬값 */
  hash?: string,
  /** 현재 상태 */
  status?: 'on' | 'off',
}

/**
 * 모달에 들어갈 기본 설정들을 정리합니다.
 * 이와 더불어, interfcae의 type에도 추가해줘야 합니다!
 * @author Copotter
 */
export const modalsConfig = (hash: string) => ({
  sample: <SampleModal hash={hash} />,
  confirm: <ConfirmModal hash={hash} />,
  myBlockUser: <MyBlockUserModal hash={hash} />,
})
