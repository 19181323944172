import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 320px;
  max-height: 384px;

  background-image: url(${images.app_download_background_img});
  background-size: 101%;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle }) => {
  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <SalonLogo src={images.salon_logo} />
              <Flex
                style={
                  {
                    marginTop: 88,
                    width: 128,
                    height: 128,
                    borderRadius: 16,
                    backgroundColor: colors.white_ff,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                }
              >
                <img
                  src={images.app_qrcode_img}
                  style={{ width: 104, height: 104 }}
                  alt="app_qr_img"
                />
              </Flex>
              <H6 style={{ margin: '16px 0 32px 0' }} color={colors.white_ff}>
                  모바일 앱 다운로드
              </H6>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}

const SalonLogo = styled.img`
  width: 116px;
  height: 48px;
  object-fit: contain;
`
