import AgoraRTM from 'agora-rtm-sdk'
import EventEmitter from 'events'

export default class RTMClient extends EventEmitter {
  constructor() {
    super()
    this.channels = {}
    this._logined = false
  }

  init(appId) {
    this.client = AgoraRTM.createInstance(appId)
    this.subscribeClientEvents()
  }

  // subscribe client events
  subscribeClientEvents() {
    const clientEvents = ['ConnectionStateChanged', 'MessageFromPeer']
    clientEvents.forEach(eventName => {
      this.client.on(eventName, (...args) => {
        this.emit(eventName, ...args)
      })
    })
  }

  // subscribe channel events
  subscribeChannelEvents(channelName) {
    const channelEvents = ['ChannelMessage', 'MemberJoined', 'MemberLeft']
    channelEvents.forEach(eventName => {
      this.channels[channelName].channel.on(eventName, (...args) => {
        this.emit(eventName, args[0])
      })
    })
  }

  async login({ token, uid }) {
    if (this._logined) return
    this.accountName = uid
    return this.client
      .login({ uid: this.accountName, token })
      .then(() => {
        this._logined = true
      })
      .catch(err => {
        console.error('[rtm-client login] error', err)
      })
  }

  async logout() {
    return this.client
      .logout()
      .then(() => {
        this._logined = false
      })
      .catch(err => {
        console.error('[rtm-client logout] error', err)
      })
  }

  async joinChannel(name) {
    const channel = await this.client.createChannel(name)
    this.channels[name] = {
      channel,
      joined: false, // channel state
    }
    this.subscribeChannelEvents(name)
    return channel
      .join()
      .then(() => {
        this.channels[name].joined = true
      })
      .catch(err => {
        console.error('rtm-client joinChannel error', err)
        throw err
      })
  }

  async leaveChannel(name) {
    if (
      !this.channels[name]
      || (this.channels[name] && !this.channels[name].joined)
    )
      return
    return this.channels[name].channel
      .leave()
      .then(() => {
        this.channels[name].joined = false
      })
      .catch(err => {
        console.error('rtm-client leaveChannel error', err)
        throw err
      })
  }

  async sendMessageByChannelId(channelName, text) {
    if (!this.channels[channelName] || !this.channels[channelName].joined)
      return

    return this.channels[channelName].channel.sendMessage({ text })
  }

  async sendPeerMessage(text, peerId) {
    return this.client.sendMessageToPeer(
      { text },
      peerId.toString(),
      // ,{
      //   enableOfflineMessaging:false
      // }
    )
  }

  async queryPeersOnlineStatus(memberId) {
    return this.client.queryPeersOnlineStatus([memberId])
  }

  // send image
  async uploadImage(blob, peerId) {
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: 'IMAGE',
      fileName: 'agora.jpg',
      description: 'send image',
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    })
    return this.client.sendMessageToPeer(mediaMessage, peerId)
  }

  async sendChannelMediaMessage(blob, channelName) {
    if (!this.channels[channelName] || !this.channels[channelName].joined)
      return
    const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
      messageType: 'IMAGE',
      fileName: 'agora.jpg',
      description: 'send image',
      thumbnail: blob,
      // width: 100,
      // height: 200,
      // thumbnailWidth: 50,
      // thumbnailHeight: 200,
    })
    return this.channels[channelName].channel.sendMessage(mediaMessage)
  }

  async cancelImage(message) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 1000)
    await this.client.downloadMedia(message.mediaId, {
      cancelSignal: controller.signal,
      onOperationProgress: ({ currentSize, totalSize }) => {
        console.log(currentSize, totalSize)
      },
    })
  }
}
