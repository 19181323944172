import React, { useState } from 'react'
// antd
import { message } from 'antd'
import { STORAGE_URL } from '@consts'

import Files from 'react-butterfiles'
import styled from 'styled-components'
import { H6, Caption1 } from '@components/atoms'
import { Spinner } from '@components/molecules'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'
import { images } from '@images/'

const UploadImg = styled.img`
  width: 48px;
  height: 48px;
`

const ImageUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 520px;
  height: 292.5px;
  border-radius: 16px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  cursor: pointer;

  @media ${mobile} {
    width: 100%;
    height: 320px;
  }
`

const CastImageUploadForm = ({
  setImageUri,
  imageUrl,
  setImageUrl,
  title,
  uploadSongImage,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Files
      multiple={false}
      maxSize="15mb"
      convertToBase64
      accept={['image/jpg', 'image/png', 'image/jpeg']}
      onSuccess={
        async ([selectedFile]) => {
          setLoading(true)
          try {
            const { file } = selectedFile.src
            const formData = new FormData()
            formData.append('image', file)

            const uploadedImage = await uploadSongImage(
              formData,
              sessionStorage.getItem('jwt_token'),
            )

            setImageUri(uploadedImage.imageUri)
            setImageUrl(STORAGE_URL + uploadedImage.imageUri)

            message.success('이미지 업로드 완료')
            // console.log('File was successfully uploaded!')
            setLoading(false)
          }
          catch (e) {
          // console.log('An error occurred!', e.message)
            message.error('이미지 업로드 실패')
            setLoading(false)
          }
        }
      }
      onError={
        errors => {
          message.error(
            errors[0].type === 'maxSizeExceeded'
              ? '15MB를 넘는 파일입니다!'
              : errors[0].type === 'unsupportedFileType'
                ? '지원하지 않은 확장자입니다!'
                : `이미지 업로드 에러 : ${errors[0].type}`,
          )
        }
      }
    >
      {
        ({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div {...getDropZoneProps({ className: 'myDropZone' })}>
            <ImageUploadBox onClick={browseFiles}>
              {
                imageUrl ? (
                  <BlurContainer background={imageUrl}>
                    <BlurBackImg />
                    <MainImg src={imageUrl} alt="avatar" />
                  </BlurContainer>
                ) : loading ? (
                  <>
                    <Spinner
                      type="spinningBubbles"
                      color={colors.primary}
                      width="48px"
                      height="48px"
                    />
                    <H6 style={{ margin: '16px 0 9px 0' }}>{title}</H6>
                    <Caption1 color="#949494">이미지 파일 업로드 중 ...</Caption1>
                  </>
                ) : (
                  <>
                    <UploadImg src={images.upload_img} alt="upload_btn" />
                    <H6 style={{ margin: '16px 0 9px 0' }}>{title}</H6>
                    <Caption1 color="#949494">
                  15mb 이하 (16:9 사이즈 권장)
                    </Caption1>
                    <Caption1 color="#949494">*필수</Caption1>
                  </>
                )
              }
            </ImageUploadBox>
          </div>
        )
      }
    </Files>
  )
}

export default CastImageUploadForm

const BlurContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`

const BlurBackImg = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  object-fit: contain;
  border-radius: 16px;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* z-index: 2; */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`

const MainImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
  z-index: 2;
`
