import { Flex, H5, Caption1, Div, ImageIcon } from '@components/atoms'
import { MixtapeListItem } from '@components/molecules'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  max-width: 1607px;
  width: 100%;
`

const SearchHreumSection = ({ active = false, handleShowMore }) => {
  const { searchStore } = useStore()

  const globalKeyword = searchStore.globalKeyword || null
  const fetchSearchMitxtapesList =
    searchStore.fetchSearchMitxtapesList || (() => {})

  const [startIndex, setStartIndex] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState([])

  const handleSearchMixtapeList = useCallback(
    async (_globalKeyword, _startIndex, limit, _active) => {
      const _list = await fetchSearchMitxtapesList(
        _globalKeyword,
        _startIndex,
        limit,
      )
      if (_list.length < limit || !_active) setHasMore(false)
      setList(prev => prev.concat(_list))
    },
    [fetchSearchMitxtapesList],
  )

  useEffect(() => {
    if (!globalKeyword) return
    if (active) {
      handleSearchMixtapeList(globalKeyword, startIndex, 20, active)
    }
    else {
      handleSearchMixtapeList(globalKeyword, startIndex, 6, active)
    }
  }, [active, globalKeyword, startIndex])

  useEffect(() => {
    return () => {
      setList([])
      setHasMore(true)
      setStartIndex(0)
    }
  }, [active, globalKeyword])

  return (
    <Flex
      type="column"
      style={
        {
          maxWidth: 1704,
          width: '100%',
        }
      }
    >
      {
list?.length > 0 ? (
  <>
    <ListHeader>
      <H5 type="Bold" align="left">
              플라 | 음악 플레이리스트 라디오
      </H5>

      <Caption1
        color={colors.secondary}
        style={{ cursor: 'pointer' }}
        onClick={handleShowMore}
      >
              더보기
      </Caption1>
    </ListHeader>

    <InfiniteScroll
      style={{ overflowX: 'hidden' }}
      dataLength={list.length}
      scrollThreshold={0.7}
      next={() => setStartIndex(prev => prev + 20)}
      hasMore={hasMore}
      scrollableTarget="scrollableDiv"
      hasChildren
      loader={
        <ReactLoading
          type="spin"
          color="black"
          style={
            {
              width: '50px',
              height: '50px',
              margin: '0 auto 50px auto',
            }
          }
        />
      }
    >
      <List>
        {
          list
                && list.map(item => <MixtapeListItem item={item} key={item.id} />)
        }
      </List>
    </InfiniteScroll>
  </>
) : active ? (
  <Div
    display="flex"
    width="100%"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    paddingTop="90px"
  >
    <Div display="flex" flexDirection="column" alignItems="center">
      <ImageIcon name="noContents" width="120px" height="120px" />
      <Div>
        <Caption1 type="Bold">"플라"&nbsp;</Caption1>
        <Caption1 color={colors.grey_64} style={{ margin: '12px 0 8px' }}>
                검색 결과가 없어요
        </Caption1>
      </Div>
      <Caption1 color={colors.grey_bf}>검색어를 확인해보세요!</Caption1>
    </Div>
  </Div>
) : (
  <></>
)
      }
    </Flex>
  )
}

export default observer(SearchHreumSection)

const List = styled(Flex)`
  padding: 24px 0 48px 0;
  width: calc(100% + 39px);
  flex-wrap: wrap;
  gap: 24px;

  @media ${mobile} {
    flex-wrap: wrap;

    > div + div {
      margin-left: 0;
      margin-top: 24px;
    }
  }
`
