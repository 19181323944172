import moment from 'moment'
import 'moment-timezone'

export const getTime = (date, format) => {
  const d = moment(Number(date))
    .tz('Asia/Seoul')
    .format(format)

  return d
}

export const secondToTimeString = sec => {
  const isNag = sec < 0
  if (isNag) sec = -sec
  const hour = (sec - (sec % 3600)) / 3600
  const minute = (sec - hour * 3600 - (sec % 60)) / 60
  const second = sec % 60
  return (
    (isNag ? '-' : '')
    + (hour ? `${hour}:${minute < 10 ? '0' : ''}` : '')
    + (minute ? `${minute}:` : '0:')
    + (second < 10 ? `0${second}` : second)
  )
}

export const timeConvert = time => {
  const date = new Date(time)
  const currDate = new Date()

  const interval = currDate.getTime() + 3000 - date.getTime() // curdate가 서버에 보다 몇초정도 늦는듯

  if (Math.floor(interval / 31557600000) > 0) {
    return `${Math.floor(interval / 31557600000)}년 전`
  }
  if (Math.floor(interval / 2629800000) > 0) {
    return `${Math.floor(interval / 2629800000)}개월 전`
  }
  if (Math.floor(interval / 604800016.56) > 0) {
    return `${Math.floor(interval / 604800016.56)}주 전`
  }
  if (Math.floor(interval / 86400000) > 0) {
    return `${Math.floor(interval / 86400000)}일 전`
  }
  if (Math.floor(interval / 3600000) > 0) {
    return `${Math.floor(interval / 3600000)}시간 전`
  }
  if (Math.floor(interval / 60000) > 0) {
    return `${Math.floor(interval / 60000)}분 전`
  }
  return `${Math.floor(interval / 1000)}초 전`
}

export const commentTimeStamp = text => {
  if (text?.includes(':')) {
    const hourRegexp = /([0-9][0-9]):([0-5][0-9]):([0-5][0-9])/gm
    const minuteRegexp = /([0-5][0-9]):([0-5][0-9])/gm

    let match = ''
    const indexes = []

    while ((match = hourRegexp.exec(text))) {
      indexes.push({
        start: match.index,
        end: hourRegexp.lastIndex - 1,
        hours: match[1],
        minutes: match[2],
        seconds: match[3],
        time:
          parseInt(match[1], 10) * 60 * 60
          + parseInt(match[2], 10) * 60
          + parseInt(match[3], 10),
      })
    }

    while ((match = minuteRegexp.exec(text))) {
      // eslint-disable-next-line
      if (indexes?.findIndex(val => val?.start === match?.index) < 0) {
        indexes.push({
          start: match.index,
          end: minuteRegexp.lastIndex - 1,
          minutes: match[1],
          seconds: match[2],
          time: parseInt(match[1], 10) * 60 + parseInt(match[2], 10),
        })
      }
    }

    return indexes
  }
}
