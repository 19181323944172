import * as React from 'react'

import styled from 'styled-components'


import { PageLayout } from '@components/templates'
import { desktop } from '@styles/media'
import { Helmet } from 'react-helmet'

const PolicContainer = styled.div`
  max-width: 1194px;
  margin: 40px auto 100px auto;
  padding: 20px;

  @media ${desktop} {
    margin: 80px auto 200px auto;
    padding: 0;
  }
`

const PolicyTitle = styled.h1`
  font-family: NotoSansCJKkr-Bold;
  font-size: 2.375rem;
  margin-bottom: 40px;
`

// const PolicyItemPointTitle = styled.p`
//   font-family: NotoSansCJKkr-Bold;
//   font-size: 1.5rem;
//   margin: 30px 0;
//   line-height: 1.5;
// `

const PolicyItemTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 1.125rem;
  margin: 30px 0;
  line-height: 1.5;
`

const PolicyItemDescription = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 1.125rem;
  line-height: 1.89;
  color: #242424;
`

const PolicyPrivacyPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>
          흐름 개인정보 처리방침: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱
        </title>
        <meta name="robots" content="noindex" />
        <meta
          name="description"
          content="함께 나누는 이야기들이 우리들의 오디오 콘텐츠로, 소셜 오디오 커뮤니티 '흐름 (HREUM de salon)'"
        />
        <meta
          property="og:title"
          content="흐름 개인정보 처리방침: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱"
        />
        <meta
          name="twitter:title"
          content="흐름 개인정보 처리방침: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱"
        />
        <meta
          property="og:url"
          content="https://hreum.me/p/policy/privacy/"
        />
        <meta
          name="twitter:domain"
          content="https://hreum.me/p/policy/privacy/"
        />
        <meta
          property="og:description"
          content="함께 나누는 이야기들이 우리들의 오디오 콘텐츠로, 소셜 오디오 커뮤니티 '흐름 (HREUM de salon)'"
        />
        <meta property="og:site_name" content="흐름 | HREUM de salon" />
        {/* <meta
          property="og:image"
          content="https://hello.hreum.me/images/hreum_landing_thumbnail.png"
        /> */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="함께 나누는 이야기들이 우리들의 오디오 콘텐츠로, 소셜 오디오 커뮤니티 '흐름 (HREUM de salon)'"
        />
        {/* <meta
          name="twitter:image"
          content="https://hello.hreum.me/images/hreum_landing_thumbnail.png"
        /> */}
      </Helmet>
      <PolicContainer>
        <PolicyTitle>흐름(HREUM de salon) 개인정보처리방침</PolicyTitle>
        <PolicyItemDescription>
          흐름(HREUM de salon)은 정보통신망 이용촉진 및 정보보호 등에
          개인정보처리방침을 정하여 고객 권익 보호에 최선을 다하고 있습니다.
          <br />
이 개인정보처리방침은 흐름(HREUM de salon)에서 수집하는 정보의 유형과 수집
          방법, 정보를 수집하는 이유, 개인정보 보호 설정 등에 대한 이해를
          돕기 위한 것입니다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          본 개인정보처리방침에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 서비스: 흐름(HREUM de salon) 솔루션이 제공하는 인터넷 상의 모든 서비스
          <br />
          • 회원: 솔루션과 서비스 이용 계약을 체결하고 아이디를 부여 받아
          솔루션이 제공하는 서비스를 이용하는 자
          <br />
          • 회원등급: 솔루션이 제공하는 서비스에 대해 특정 기능 접근 여부
          등의 서비스 이용 경험에서 차이를 갖고 있는 솔루션 내의 회원 분류
          체계
          <br />
          • 콘텐츠: 예를 들면 오디오와 플레이리스트(이하 ‘플라’)처럼
          서비스를 이용한 자가 서비스 상으로 보거나, 서비스를 통하여
          접속하거나 또는 서비스를 통하여 기고할 수 있는 텍스트, 소프트웨어,
          스크립트, 그래픽, 사진, 이미지 파일, 사운드, 음악, 비디오, 선곡
          리스트, 시청각 혼합, 상호작용 내용과 기타 자료
        </PolicyItemDescription>

        <PolicyItemTitle>발효일: 2022년 01월 01일</PolicyItemTitle>
        <PolicyItemTitle>
          흐름(HREUM de salon)의 정책이 적용되는 형태는 아래와 같습니다.
        </PolicyItemTitle>

        <PolicyItemDescription>
          HREUM de salon WEB, HREUM de salon APP(Android, iOS), 사이트, 기기
          <br />
이 개인정보처리방침에 관해 궁금한 사항이 있으면 문의하세요.
        </PolicyItemDescription>

        <PolicyItemTitle>HREUM de salon에서 수집하는 정보의 유형</PolicyItemTitle>

        <PolicyItemDescription>
          HREUM de salon은 모든 회원에게 더 나은 서비스를 제공하기 위해 회원의 언어와
          같은 기본적인 정보와 회원이 가장 유용하다고 생각할 콘텐츠 등과
          같은 복합적인 정보를 수집합니다. 수집하는 정보와 그 정보가
          이용되는 방식은 사용자가 서비스를 어떻게 이용하고 개인정보 보호
          설정을 어떻게 관리하는지에 따라 다릅니다.
        </PolicyItemDescription>

        <PolicyItemTitle>회원이 생성하거나 제공하는 정보</PolicyItemTitle>

        <PolicyItemDescription>
          • 필수 제공 정보
          <br />
          회원은 계정을 만들 때 성명, 아이디, 이메일 주소, 휴대폰연락처, 비밀번호
          성별, 생년월일, 대화내용 등을 포함한 개인정보를 HREUM de salon에 제공합니다.
          <br />
          • 선택 제공 정보
          <br />
          계정에 성별, 프로필 이미지, 생년월일, 닉네임, 주소, 소속, 직위 등을 추가하도록 선택할 수
          있습니다. 계정에 로그인하지 않더라도, 서비스 업데이트를 받기 위해
          이메일 주소를 제공하는 것과 같이 정보를 제공하도록 선택할 수
          있습니다.
          <br />
          • 직접 업로드, 생성한 콘텐츠 정보
          <br />
          HREUM de salon은 회원이 서비스를 이용하면서 생성, 업로드하거나 다른
          사람에게 받는 콘텐츠를 수집합니다. 여기에는 회원이 저장하는
          오디오, 이미지, 댓글 등이 포함 됩니다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          회원이 서비스를 이용할 때 HREUM de salon이 수집하는 정보
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 회원의 활동
          <br />
          서비스 상의 회원 활동 정보를 수집하여 좋아할 만한 오디오, 플라(PLRA)
          등의 콘텐츠를 추천하는 목적으로 활용합니다. 회원은 HREUM de salon 계정에
          저장된 활동 정보를 확인하 고 관리할 수 있습니다. 수집하는 활동
          정보에는 다음이 포함될 수 있습니다. 검색하는 단어, 오디오 기능을
          사용할 때 음성 및 오디오 정보, 회원간 교류하거나 콘텐츠를
          공유하는 사람들, 어플리케이션 사용 기록.
          하우스에서 운용하는 멤버룸에서의 대화,
          부정 이용으로 신고될 시 관련 기관 보고에 필요한 대화 정보.
          <br />
          • 회원의 앱, 브라우저, 기기
          <br />
          회원이 HREUM de salon 서비스에 액세스할 때 사용하는 앱, 브라우저, 기기에
          대한 정보를 수집합니다. 이 정보를 이용하여 자동 업데이트 등을
          제공할 수 있습니다. 수집하 는 정보에는 고유 식별자, 브라우저 유형
          및 설정, 기기 유형 및 설정, 운영체제, 통신사명과 전화번호를 포함한
          모바일 네트워크 정보, 애플리케이션 버전 번호가 포함 될 수
          있습니다. 또한 IP주소, 비정상 종료 보고서, 시스템 활동, 요청
          날짜와 시간, 리퍼러 URL 등 사용자의 앱, 브라우저, 기기와 서비스의
          HREUM de salon 상호작용 등에 대 한 정보를 수집할 수 있습니다.
          <br />
          • 회원의 상태 정보 (위치, 기상, 온도 등)
          <br />
          서비스를 이용할 때 사용자 위치에 관한 정보를 수집하여 장소나 날씨.
          온도에 따른 오디오, 플라(PLRA) 등을 추천하는 기능 등을 제공할 수
          있습니다. HREUM de salon에서 수집하는 위치 데이터의 유형은 기기 및 계정
          설정에 따라 부분적으로 달라집니다. 위치 정확도는 다음과 같은 여러
          요인에 의해 결정됩니다. GPS, IP 주소, 기기의 센서 데이터, Wi-Fi
          액세스 포인트, 기지국, 블루투스 지원 기기 등 사용자의 기기 주변
          사물에 대한 정보.
          <br />
          • 업무 처리 과정에서 자동 생성, 추가 수집되는 정보
          <br />
          서비스 이용과정이나 서비스 제공 업무 처리 과정에서 다음과 같은
          정보들이 자동으로 생성되거나 추가로 수집될 수 있습니다. IP 주소,
          쿠키, 접속로그, 사용 일시, 서비스 이용 기록, 불량 이용 기록, 결제
          기록.
          <br />
          • 상세한 위치정보 이용에 대한 내용은 '흐름(HREUM de salon)'
          위치정보 이용약관'을 참고한다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          특정 서비스의 이용 시 추가적으로 수집되는 정보
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 회원이 HREUM de salon에 일반 문의를 남기거나 알림(통보) 대상 추가 및
          회원등급 변경 시에 개인정보 수집에 대한 별도 동의를 구한 후 성명,
          이메일 주소, 휴대폰 번호 등의 추가적인 정보가 수집될 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>개인정보 수집 방법</PolicyItemTitle>
        <PolicyItemTitle>
          HREUM de salon은 다음과 같은 방법으로 개인정보를 수집합니다.
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 어플리케이션, 홈페이지 수집, 서면, 게시판, 이메일, 오프라인
          수집(이벤트 응모, 세미나 참석 등
          <br />
          • 로그 분석 프로그램을 통한 생성정보 수집
          <br />
• ‘쿠키(cookie)’에 의한 정보 수집
        </PolicyItemDescription>

        <PolicyItemTitle>정보를 수집하는 이유 및 목적</PolicyItemTitle>

        <PolicyItemDescription>
          수집된 정보를 사용하여 더 나은 서비스를 구축합니다. HREUM de salon은
          회원들의 개인정보를 다음에서 고지하는 범위 내에서 사용하며, 고객의
          사전 동의 없이는 동 범위 를 초과하여 이용하거나 원칙적으로 회원의
          개인정보를 외부에 공개하지 않습니다. 고객들이 사전에 동의한 경우,
          법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
          방법에 따라 수사기관의 요구가 있는 경우에는 예외로 합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>서비스 제공</PolicyItemTitle>

        <PolicyItemDescription>
          • 회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의
          콘텐츠 공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>서비스 유지 및 개선</PolicyItemTitle>

        <PolicyItemDescription>
          • 문제 발생 시 원인을 추적하거나 회원이 신고하는 문제를 해결하는
          등 서비스 정상 운영을 위해 회원 정보를 이용합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>새 서비스 개발</PolicyItemTitle>

        <PolicyItemDescription>
          • 기존 서비스에서 수집하는 정보를 이용하여 새 서비스를 개발합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>맞춤 서비스 제공</PolicyItemTitle>

        <PolicyItemDescription>
          • 수집하는 정보를 이용하여 추천, 맞춤 콘텐츠 및 검색결과를
          제공하는 등 사용자를 위해 서비스를 맞춤 설정합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>실적 측정</PolicyItemTitle>

        <PolicyItemDescription>
          • HREUM de salon 서비스가 어떻게 이용되는지 분석하고 측정하는데 데이터를
          이용합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>사용자와 커뮤니케이션</PolicyItemTitle>

        <PolicyItemDescription>
          • 이메일 주소 등 수집 정보를 이용하여 회원과 직접 상호 작용합니다.
          이메일 주소와 같은 정보는 불만처리 등의 민원을 처리, 이벤트 및
          광고성 정보 제공 및 참여기회 제공 등을 위한 소통 창구로
          이용됩니다.
        </PolicyItemDescription>

        <PolicyItemTitle>HREUM de salon과 회원, 그리고 대중을 보호</PolicyItemTitle>

        <PolicyItemDescription>
          • 서비스의 안정성과 신뢰성을 개선하기 위해 정보를 이용합니다.
          여기에는 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인,
          개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사
          확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁
          조정을 위한 기록보존, 고지사항 전달 등이 포함됩니다.
        </PolicyItemDescription>

        <PolicyItemTitle>개인정보 보호 설정</PolicyItemTitle>

        <PolicyItemDescription>
          HREUM de salon 서비스 내에서 HREUM de salon에게 공유되는 정보와 다른 회원에게
          표시되는 내 정보를 관리할 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          개인 정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
        </PolicyItemTitle>

        <PolicyItemDescription>
          HREUM de salon은 쿠키를 통해 회원들의 이용형태, 인기 검색어, 고객 규모 등을
          파악합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 회원의
          브라우저에게 보내는 아주 작은 텍스트, 오디오, 이미지 파일로 회원 컴퓨터의
          하드디스크에 저장됩니다. 이후 회원이 서비스를 활용할 때 하드
          디스크에 저장되어 있는 쿠키의 내용을 읽어 회원의 환경설 정을
          유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다. 쿠키는 개인을
          식별하는 정보를 자동적/능동적으로 수집하지 않으며, 회원은 언제든지
          이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 회원은
          웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
          저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
          수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 일부 서비스는
          이용에 어려움이 있을 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>정보 관리 및 업데이트</PolicyItemTitle>

        <PolicyItemDescription>
          • 회원 정보 관리
          <br />
          회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 수정할
          수 있습니다. 회원의 개인정보에 대한 열람 또는 수정을 하고자 할
          경우에는 솔루션의 서비스 종류에 따른 회원정보 수정에 해당하는
          기능을 통해 본인 확인 절차를 거친 후 직접 열람 또는 수정하거나,
          개인정보 관련 담당자에게 이메일로 연락하면 지체 없이 수 정할 수
          있습니다. 만일 회원의 대리인이 방문하여 열람 또는 정정을 요구하는
          경우에는 회원의 진정한 대리인인지 여부를 확인하기위해, 대리관계를
          나타내는 증표 를 제시하도록 요구할 수 있습니다. 회원이 개인정보의
          오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지
          개인정보를 이용 또는 제공하지 않습니다. 또한 프로필 이미지, 닉네임
          등 선택 정보 를 추가 기입할 수 있습니다.
          <br />
          • 활동 기록 관리
          <br />
          회원은 검색 기록, 청취 기록 등의 활동 내용을 관리할 수 있습니다.
          활동의 일부 또는 전부를 삭제할 수 있습니다.
          <br />
          • 업로드 콘텐츠에 관한 정보 관리
          <br />
          HREUM de salon 서비스에서 회원은 다른 사람들과 콘텐츠를 공유할 수 있고 공유
          방식을 제어할 수 있습니다. 예를 들어 업로드한 플라(PLRA)을 공개적으로
          공유하거나 비공개로 유지할 수 있습니다. 공개적으로 정보를 공유할
          경우, HREUM de salon 검색을 비롯한 검색엔진을 통해 해당 콘텐츠에 액세스가
          가능해질 수 있다는 점을 유념하시기 바랍니 다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          개인정보의 보유, 이용기간 및 파기 절차
        </PolicyItemTitle>

        <PolicyItemDescription>
          회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
          달성되면 지체 없이 파기합니다. 단, 다음과 같이 데이터의 유형에
          따라 보관되는 기간이 다릅니다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          사용자가 삭제할 때까지 보관되는 정보
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 회원 가입 시 기입하는 필수 개인 정보
          <br />
          • 회원의 활동 내역
          <br />
          • 회원이 업로드한 콘텐츠
          <br />
          • 회원의 계정
        </PolicyItemDescription>

        <PolicyItemTitle>
          특정 기간이 지나면 만료되는 데이터
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의
          콘텐츠 공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
          <br />
          • 부정 이용으로 신고된 음성 및 텍스트, 회원 정보는 접수 시점부터 3년 보관 후 파기합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>
          계정을 삭제할 때까지 보관되는 정보
        </PolicyItemTitle>

        <PolicyItemDescription>
          • 회원이 HREUM de salon이 제공하는 기능과 어떻게 상호작용하는지, 어떻게
          하면 HREUM de salon 서비스를 개선할 수 있을지 이해하는 데 도움이 되는 일부
          데이터는 회원이 계정을 삭제할 때까지 보관됩니다.
        </PolicyItemDescription>

        <PolicyItemDescription>
          회원이 HREUM de salon 계정에 저장된 데이터를 삭제하면 HREUM은 해당 데이터를
          제거하는 절차를 즉시 시작합니다. 우선 정보가 더 이상 표시되지
          않도록 즉시 삭제하는 것을 목표로 하며, 이 정보는 더 이상 사용자의
          HREUM de salon 환경을 맞춤설정하는 데 사용되지 않습니다. 이후 HREUM de salon 보관
          시스템에서 데이터를 안전하고 완전하게 삭제합니다. 종이에 출력된
          정보의 경우 분쇄기로 분쇄하며 전자적 파일 형태로 저장된 개인정보는
          기록을 재생할 수 없는 기술적 방법으로 삭제합니다. 다만, 이용약관에서 기술한 약관에
          따라 탈퇴 후 1년간 저장해야하는 이유에 필요한 개인정보 요소들과
          관계법령의 규정에 의하여 보존할 필요가 있는 데이터는 지정된
          기간 동안 회원 정보를 보관합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>정보 보안 유지</PolicyItemTitle>

        <PolicyItemDescription>
          HREUM de salon은 회원 정보를 지속적으로 보호하는 보안 기능이 구축되어
          있습니다. 서비스를 유지하면서 얻는 유용한 정보는 보안 위협을
          감지하고 자동으로 차단하는 데 도움이 됩니다. 회원이 알아야 한다고
          생각되는 위험 요소를 감지하면 사용자에게 알리고 보호 기능을 강화할
          수 있는 단계를 안내합니다.
        </PolicyItemDescription>

        <PolicyItemDescription>
          다음과 같은 조치를 통해 정보 무단 액세스, 변경, 공개, 파기로부터
          사용자와 HREUM de salon을 보호하기 위해 노력하고 있습니다.
        </PolicyItemDescription>

        <PolicyItemDescription>
          • 암호화를 사용하여 전송 중에 데이터를 비공개로 유지합니다.
          <br />
          • 여러 단계의 인증, 내부 보안 진단 등 다양한 보안 기능을 통하여
          계정 보호를 지원합니다.
          <br />
          • 시스템 무단 액세스를 방지하기 위해 물리적 보안 조치를 포함하여
          HREUM de salon의 정보 수집, 저장 및 처리 관행을 검토합니다.
          <br />
• 개인정보 액세스 권한을 개인정보를 처리하기 위해 액세스가
          필요한 HREUM de salon 직원 및 대리인으로 제한됩니다. 액세스 권한을 가진
          사람은 계약을 통해 엄격한 기밀 유지의 의무를 갖게 되며 이러한
          의무를 어길 경우 제재를 받거나 계약이 해지될 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>방침 정보</PolicyItemTitle>

        <PolicyItemDescription>
          이 개인정보처리방침은 HREUM de salon 솔루션 내에서 적용됩니다. 다음
          항목에는 이 개인정보처리방침이 적용되지 않습니다.
        </PolicyItemDescription>

        <PolicyItemDescription>
          • HREUM de salon 솔루션을 광고하는 다른 회사 및 조직의 정보 관행
          <br />
• 타사 또는 개인이 제공하는 서비스
        </PolicyItemDescription>

        <PolicyItemDescription>
          HREUM de salon 은 이 개인정보처리방침을 수시로 변경할 수 있습니다.
          HREUM de salon 은 회원의 명시적인 동의 없이 이 개인정보처리방침에 설명된 회원의
          권한을 축소하지 않습 니다. 항상 마지막 변경 사항이 게시된 날짜를
          표시하고 회원이 검토를 위해 보관 처리된 버전에 액세스할 수 있도록
          합니다. 변경 사항이 중대할 경우에는 개인정보처 리방침과 관련한
          변경 고지 이메일을 발송하는 등 적극적으로 알립니다.
        </PolicyItemDescription>
      </PolicContainer>
      <PolicContainer>
        <PolicyTitle>흐름(HREUM de salon) 위치정보 이용약관</PolicyTitle>

        <PolicyItemTitle>제1조 (목적)</PolicyItemTitle>

        <PolicyItemDescription>
          이 약관은 흐름(HREUM de salon) (이하 “회사”)이 제공하는 위치정보사업 또는
          위치기반서비스사업과 관련하여 회사와 개인위치정보주체와의 권리,
          의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다
        </PolicyItemDescription>

        <PolicyItemTitle>제2조 (약관 외 준칙)</PolicyItemTitle>

        <PolicyItemDescription>
          이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률,
          정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법
          등 관계법령과 회사의 이용약관 및 개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제3조 (서비스 내용 및 요금)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.
          <br />
          ① 날씨 기반 플라(PLRA) 추천 서비스: 사용자의 위치에 따른 날씨 정보를 제공받아, 날씨 기반 플라(플레이리스트 라디오)를 제공합니다.
          <br />
          ② 피드 추천: 서비스 내 이미지와 텍스트, 오디오를 융합한 게시물인 피드에 위치 정보를 입력할 수 있고, 이를 통해 위치 검색 혹은 사용자의 현재 위치에 따른 피드를 추천할 수 있습니다.
          <br />
          ③ 유저 추천: 검색 혹은 사용자의 현재 위치에 따라 해당 위치와 인접해있는 유저를 추천할 수 있습니다.
          <br />
          ④ 살롱 추천: 검색 혹은 사용자의 현재 위치에 따라 살롱을 추천할 수 있습니다.
          <br />
          2. 제1항 위치기반서비스의 이용요금은 무료입니다
        </PolicyItemDescription>

        <PolicyItemTitle>제4조 (개인위치정보주체의 권리)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중 일부 또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및 위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
          <br />
          2. 개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다.
          <br />
          3. 개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의 일시적인 중지를 요구할 수 있습니다.
          이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 수단을 갖추고 있습니다
          <br />
          4. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다.
          이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
          <br />
          ① 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
          <br />
          ② 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
          <br />
          5. 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는 지체 없이 수집된 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 파기합니다.
          단, 동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실 확인자료에 한합니다.
          <br />
          6. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관 제13조의 연락처를 이용하여 회사에 요구할 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제5조 (법정대리인의 권리)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 만14세 미만 아동으로부터 개인위치정보를 수집ㆍ이용 또는 제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를 받아야 합니다.
          <br />
          2. 법정대리인은 만14세 미만 아동의 개인위치정보를 수집ㆍ이용ㆍ제공에 동의하는 경우 동의유보권, 동의철회권 및 일시중지권, 열람ㆍ고지요구권을 행사할 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제6조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및 보유기간)</PolicyItemTitle>

        <PolicyItemDescription>
          회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월 이상 보관합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제7조 (서비스의 변경 및 중지)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.
          <br />
          2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에 공지하거나 개인위치정보주체에게 통지합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제8조 (개인위치정보 제3자 제공시 즉시 통보)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의 개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를 제공하는 경우에는 제공 받는 자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를 받습니다.
          <br />
          2. 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
          <br />
          3. 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
          <br />
          ① 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신 기능을 갖추지 아니한 경우
          <br />
          ② 개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
        </PolicyItemDescription>

        <PolicyItemTitle>제9조 (8세 이하의 아동 등의 보호의무자의 권리)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
          <br />
          ① 8세 이하의 아동
          <br />
          ② 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진및직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)
          <br />
          ③ 금치산자
          <br />
          2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          <br />
          3. 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제10조 (손해배상)</PolicyItemTitle>

        <PolicyItemDescription>
          개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제11조 (분쟁의 조정)</PolicyItemTitle>

        <PolicyItemDescription>
          1. 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에 재정을 신청할 수 있습니다.
          <br />
          2. 회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>제12조 (사업자 정보)</PolicyItemTitle>

        <PolicyItemDescription>
          회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
          <br />
          상호 : 주식회사 나이비
          <br />
          주소 : 서울시 성북구 보문동 5가 173
          <br />
          전화번호 : 1833-6845
        </PolicyItemDescription>

        <PolicyItemTitle>부칙</PolicyItemTitle>

        <PolicyItemDescription>
          제 1 조 시행일
          <br />
          본 약관은 2021년 1월 1일부터 적용됩니다.
          <br />
          <br />
          제 2 조 위치정보관리 책임자 정보
          <br />
          회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이 서비스 이용과정에서 발생한 민원사항 처리를 비롯하여 개인위치정보주체의 권리 보호를 위해 힘쓰고 있습니다.
          <br />
          <br />
          위치정보 관리책임자 : 김동현
          <br />
          전화번호 : 1833-6845
        </PolicyItemDescription>
      </PolicContainer>
    </PageLayout>
  )
}

export default PolicyPrivacyPage
