import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Caption1 } from '@components/atoms'
import { STORAGE_URL } from '@consts/'
import { FlexRowBox, FlexColumnBox } from '@components/molecules'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { message } from 'antd'
import { mobile } from '@styles/media'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { images } from '@images/'
import { isEmptyObject } from '@utils/vallidation'

const Box = styled(FlexRowBox)`
  align-items: center;
  justify-content: center;
  z-index: 1500;
  position: fixed;
  bottom: ${props => (props.isExistHreumPlayer ? '80px' : '0px')};
  width: 100%;
  max-width: 100vw;
  min-width: 652px;
  height: 80px;
  background: #242424;
  padding: 0;
  margin: 0;
  left: 0;

  @media ${mobile} {
    .now_info {
      display: none;
    }

    bottom: 0px;
  }
`

const Inner = styled(FlexRowBox)`
  width: 100%;
  justify-content: flex-start;
  padding: 0 25%;
  align-items: center;

  @media ${mobile} {
    padding: 10%;
  }
`

const Audio = styled(AudioPlayer)`
  max-width: 55%;
  width: 100%;
  height: 80px;
  background: #242424;

  .rhap_time {
    color: white;
  }
`

const ListPlayer = ({
  nowSong,
  _stopPlayer,
  stopPlayer,
  currentPlayMixtape,
}) => {
  const listPlayerRef = useRef() // player element

  // const isExistHreumPlayer = sessionStorage.getItem('hreum-play-status')

  const converting = e => {
    message.error('컨버팅 중 인 곡입니다...')
  }

  useEffect(() => {
    if (!stopPlayer) {
      listPlayerRef.current.audio.current.pause()
    }
  }, [stopPlayer, listPlayerRef])

  return (
    <Box isExistHreumPlayer={!isEmptyObject(currentPlayMixtape)}>
      <Inner>
        {
          nowSong ? (
            <FlexRowBox
              className="now_info"
              style={
                {
                  maxWidth: '25%',
                  width: '100%',
                  justifyContent: 'left',
                  alignItems: 'center',
                }
              }
            >
              <img
                src={nowSong && STORAGE_URL + nowSong.imageUri}
                style={{ width: '60px', height: '60px' }}
                alt="now_song_img"
              />
              <FlexColumnBox
                style={
                  {
                    width: 'auto',
                    alignItems: 'flex-start',
                    marginLeft: '16px',
                  }
                }
              >
                <Caption1 color="#ffffff" style={oneEllipsisStyle}>
                  {nowSong && nowSong.name}
                </Caption1>
                <Caption1 color="#949494" style={oneEllipsisStyle}>
                  {nowSong && nowSong.singer}
                </Caption1>
              </FlexColumnBox>
            </FlexRowBox>
          ) : (
            <FlexRowBox
              className="now_info"
              style={
                {
                  maxWidth: '25%',
                  width: '100%',
                  justifyContent: 'left',
                  alignItems: 'center',
                }
              }
            >
              <img
                src={images.default_song_img}
                style={{ width: '60px', height: '60px' }}
                alt="now_song_img"
              />
              <FlexColumnBox
                style={
                  {
                    width: 'auto',
                    alignItems: 'flex-start',
                    marginLeft: '16px',
                  }
                }
              >
                <Caption1 color="#ffffff" style={oneEllipsisStyle}>
                노래 제목
                </Caption1>
                <Caption1 color="#949494" style={oneEllipsisStyle}>
                아티스트
                </Caption1>
              </FlexColumnBox>
            </FlexRowBox>
          )
        }
        <Audio
          ref={listPlayerRef}
          autoPlay
          controls
          src={nowSong && STORAGE_URL + nowSong.mp3Uri}
          onPlay={
            e => {
              e && e.type === 'play' && _stopPlayer()
            }
          }
          onPlayError={e => converting(e)}
          showJumpControls={false}
          showFilledProgress={false}
          customAdditionalControls={[]}
          customIcons={
            {
              play: (
                <img
                  src={images.play_btn_img}
                  alt="play_btn"
                  style={{ width: '32px', height: '32px' }}
                />
              ),
              pause: (
                <img
                  src={images.pause_btn_img}
                  alt="pause_btn"
                  style={{ width: '32px', height: '32px' }}
                />
              ),
              volume: (
                <img
                  src={images.volume_btn_img}
                  alt="volume_btn"
                  style={{ width: '20px', height: '20px' }}
                />
              ),
              volumeMute: (
                <img
                  src={images.volume_mute_img}
                  alt="volume_mute_zbtn"
                  style={{ width: '20px', height: '20px' }}
                />
              ),
            }
          }
          layout="stacked-reverse"
        />
      </Inner>
    </Box>
  )
}

export default ListPlayer
