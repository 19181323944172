import { Caption2, Div, ProfileImg, StyledButton } from '@components/atoms'
import { colors } from '@resources/colors'
import { converseUnitEng } from '@utils/format'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'

const HomeHouseListItem = ({ item }) => {
  const history = useHistory()
  return (
    <Div
      display="flex"
      width="384px"
      height="64px"
      border={`1px solid ${colors.white_f4}`}
      borderRadius="8px"
      backgroundColor={colors.white_ff}
      padding="12px 16px"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => history.push(`/house/${item.linkKey}`)}
      cursor="pointer"
    >
      <Div display="flex">
        <ProfileImg
          src={item.thumbnailUrl}
          style={{ width: 40, height: 40, marginRight: 8, borderRadius: 12 }}
        />
        <Div display="flex" flexDirection="column" gap="2px">
          <Caption2 align="left" type="Bold">
            {item.title}
          </Caption2>
          <Div display="flex">
            <Caption2 align="left">
              {`멤버수 ${converseUnitEng(item.memberCount || 0)}명`}
            </Caption2>
            <InfoDot />
            <Caption2 align="left">
              {`팔로워 ${converseUnitEng(item.followerCount || 0)}명`}
            </Caption2>
          </Div>
        </Div>
      </Div>
      {/* <Div>
        <StyledButton size="xs" designType="primary" padding="3px 13px">
          팔로우
        </StyledButton>
      </Div> */}
    </Div>
  )
}

export default observer(HomeHouseListItem)

const InfoDot = () => {
  return (
    <Div
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingLeft="4px"
      paddingRight="4px"
    >
      <Div
        width="2px"
        height="2px"
        backgroundColor={colors.black_24}
        borderRadius="1px"
      />
    </Div>
  )
}
