import React, { useEffect, useState } from 'react'
import { CastUploadTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { getTag } from '@utils/media'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const CastEditPage = ({ match }) => {
  useRenderLogger('CastEditPage')
  const { castStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const createCast = castStore.createCast || (() => {})
  const uploadCastAudio = castStore.uploadCastAudio || (() => {})
  const uploadCastImage = castStore.uploadCastImage || (() => {})
  const createAudioFile = castStore.createAudioFile || (() => {})
  const fetchCast = castStore.fetchCast || (() => {})
  const updateCast = castStore.updateCast || (() => {})

  const { id } = match.params

  const [isUploaded, setIsUploaded] = useState(false)
  const [cast, setCast] = useState()

  useEffect(() => {
    const _fetchCast = async () => {
      const _cast = await fetchCast(id)
      setCast(_cast)
    }
    _fetchCast()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {
        isUploaded
        && (cast?.houseId ? (
          <Redirect to={`/p/cast-house/manage/${cast.houseId}`} />
        ) : (
          <Redirect to="/p/cast/manage" />
        ))
      }
      <CastUploadTemplate
        user={currentUser}
        createCast={createCast}
        setIsUploaded={setIsUploaded}
        uploadCastAudio={uploadCastAudio}
        uploadCastImage={uploadCastImage}
        createAudioFile={createAudioFile}
        getTag={getTag}
        isEdit
        cast={cast}
        updateCast={updateCast}
      />
    </>
  )
}

export default observer(CastEditPage)
