import React from 'react'
import { UpdateForm } from '@components/organisms'
import { observer } from 'mobx-react'
import { PageLayout } from '..'

const SongDetailTemplate = ({
  loadData,
  info,
  updateSong,
  updateSongInfo,
  createTagList,
  uploadSongImage,
  setIsUpdate,
}) => {
  return (
    <PageLayout>
      <UpdateForm
        createTagList={createTagList}
        song={loadData}
        info={info}
        uploadSongImage={uploadSongImage}
        updateSong={updateSong}
        updateSongInfo={updateSongInfo}
        setIsUpdate={setIsUpdate}
      />
    </PageLayout>
  )
}

export default observer(SongDetailTemplate)
