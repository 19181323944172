import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex, Caption1 } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { SalonUserStarListItem, YnPopup } from '@components/molecules'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import { PresentStarForm } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const CustomInifiniteScroll = styled(InfiniteScroll)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(
  ({ isShowing, toggle, userId, handleSendAfterAction = () => {} }) => {
    const history = useHistory()

    const { starStore, authStore } = useStore()

    const userStarRankList = starStore.userStarRankList || null
    const currentUser = authStore.currentUser || null

    const [starPlan, setStarPlan] = React.useState('')
    const [offset, setOffset] = React.useState(0)
    const [hasMore, setHasMore] = React.useState(true)

    const { isShowing: showLack, toggle: toggleLack } = YnPopup.usePopup()

    const fetchPresentRankList = async () => {
      const result = await starStore.getStarHistoriesFromChannel(userId, {
        __limit: 30,
        __offset: offset,
      })

      if (result && result.length < 30) setHasMore(false)
      else setHasMore(true)
    }

    const handleSend = async () => {
      if (starPlan === '' || Number(starPlan) < 10) return
      if (currentUser && currentUser.currentStar < starPlan) {
        toggleLack()
        return
      }

      const result = await starStore.presentStarToChannel(userId, {
        starValue: Number(starPlan),
      })

      if (result?.response?.status === 409) {
        toggleLack()
        return
      }

      if (offset > 0) setOffset(0)
      else if (offset === 0) await fetchPresentRankList()
      setStarPlan('')

      await handleSendAfterAction()
    }

    const handleNext = () => {
      setOffset(offset + 30)
    }

    React.useEffect(() => {
      const fetchPresentRankList = async () => {
        const result = await starStore.getStarHistoriesFromChannel(userId, {
          __limit: 30,
          __offset: offset,
        })

        if (result && result.length < 30) setHasMore(false)
        else setHasMore(true)
      }
      fetchPresentRankList()
    }, [starStore, offset, userId])

    return isShowing
      ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={() => toggle()} />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <CloseBtn onClick={toggle} src={images.x_btn_img} />
              <MobdlBody>
                <CustomInifiniteScroll
                  dataLength={userStarRankList.length}
                  scrollThreshold={0.8}
                  next={handleNext}
                  hasMore={hasMore}
                  scrollableTarget="scrollableDiv"
                  height={740}
                  loader={
                    <ReactLoading
                      type="spin"
                      color="black"
                      style={
                        {
                          width: '50px',
                          height: '50px',
                          margin: '0 auto 50px auto',
                        }
                      }
                    />
                  }
                >
                  <Flex style={{ height: 64 }} justify="center">
                    <H6>별 선물하기</H6>
                  </Flex>
                  <PresentStarForm
                    currentUser={currentUser}
                    starPlan={starPlan}
                    setStarPlan={setStarPlan}
                    handleSend={handleSend}
                    style={{ maxHeight: 450, minHeight: 450 }}
                  />
                  <div
                    style={
                      {
                        height: 8,
                        width: '100%',
                        backgroundColor: colors.white_f4,
                      }
                    }
                  />
                  <Flex
                    type="column"
                    style={{ width: '100%', marginTop: 24 }}
                  >
                    <Caption1 type="Bold" align="left">
                        선물 순위
                    </Caption1>
                    {
                      userStarRankList
                        && userStarRankList.length > 0
                        && userStarRankList.map((item, index) => (
                          <SalonUserStarListItem
                            item={item}
                            key={item?.User?.id}
                            index={index}
                            currentUserId={currentUser?.id}
                            noHover
                          />
                        ))
                    }
                  </Flex>
                </CustomInifiniteScroll>
              </MobdlBody>
            </Modal>
            <YnPopup.View
              isShowing={showLack}
              toggle={toggleLack}
              yText="충전하기"
              title="별이 부족합니다"
              body="충전하기 버튼을 눌러 별을 충전해주세요"
              onOk={() => history.push('/p/star/main?tab=charge')}
            />
          </ModalWrapper>
        </>,
        document.body,
      )
      : null
  },
)

export default {
  View,
  useModal,
}

const CloseBtn = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  width: 16px;
  height: 16px;
  object-fit: cover;
`
