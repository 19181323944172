import ReactDOM from 'react-dom'

const PortalWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[],
}) => {
  const el: any = document.getElementById('modal')
  return ReactDOM.createPortal(children, el)
}

export default PortalWrapper
