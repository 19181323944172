import React, { useState } from 'react'
import {
  H1,
  H6,
  FormInput,
  WarningMsg,
  Form,
  InvisibleInput,
} from '@components/atoms'
import { RoundBtn } from '@components/molecules'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Redirect } from 'react-router-dom'
import { colors } from '@resources/colors'
import { PageLayout } from '..'

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const ConfirmPasswordTemplate = ({ checkPassword }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const authPassword = async password => {
    const res = await checkPassword(password)
    if (res.status === 401) {
      setError(res.response)
    }
    else {
      setError('NoError')
    }
  }

  return (
    <>
      {
        error === 'NoError' && (
          <Redirect
            to={{ pathname: '/p/exchange/revenue/info', state: { isAuth: true } }}
          />
        )
      }
      <PageLayout>
        <H1>비밀번호 확인</H1>
        <H6 style={{ marginTop: '20px' }}>
          본인 확인을 위해 계정의 비밀번호를 입력해주세요.
        </H6>
        <Form>
          <InputBox style={{ width: '518px', margin: '50px 0px' }}>
            <InvisibleInput />
            <FormInput
              placeholder="비밀번호 입력"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            {error && <WarningMsg>{error}</WarningMsg>}
          </InputBox>

          <RoundBtn
            onClick={() => authPassword(password)}
            text="완료"
            color="#ffffff"
            style={
              {
                width: '230px',
                height: '48px',
                borderRadius: '24px',
                border: `solid 2px ${colors.primary}`,
                backgroundColor: colors.primary,
              }
            }
          />
        </Form>
      </PageLayout>
    </>
  )
}

export default ConfirmPasswordTemplate
