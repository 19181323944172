import { Div, Flex, Image, Img } from '@components/atoms'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useStore } from '@utils/hooks'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { FeedCard, FeedDetailModal } from '..'

const UserDetailFeedSection = ({ initFeedId: _initFeedId, userDetail }) => {
  const { authStore, feedStore, appStateStore } = useStore()

  const {
    isShowing: showDetail,
    toggle: toggleDetail,
  } = FeedDetailModal.useModal()

  const userFeedList = feedStore.userFeedList || null

  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)
  const [targetFeedId, setTargetFeedId] = React.useState(null)
  const [initFeedId, setInitFeedId] = React.useState(_initFeedId)

  const handleLoadDetail = async id => {
    const isAuth = await authStore.tryAuth()
    if (!isAuth) return

    setTargetFeedId(id)
    toggleDetail()
  }

  const handleOffset = () => {
    setOffset(offset + 1)
  }

  React.useEffect(() => {
    appStateStore.__lockUserDetailFeedSection = {}
    return () => {
      appStateStore.__lockUserDetailFeedSection = {}
    }
  }, [appStateStore])

  React.useEffect(() => {
    return () => {
      feedStore.init()
    }
  }, [feedStore])

  React.useEffect(() => {
    const fetchFeedList = async () => {
      if (userDetail?.id) {
        const lockKey =
          feedStore.userFeedList?.[feedStore.userFeedList?.length - 1]?.id || 0
        if (!appStateStore.__lockUserDetailFeedSection[lockKey]) {
          appStateStore.__lockUserDetailFeedSection[lockKey] = true
          const option = {
            houseId: 'null',
            houseIdOperator: 'is',
            __pageLimit: 20,
            __pageField: 'publishedAt',
            __pageOrder: 'desc',
            __pageIdValue:
              feedStore.userFeedList?.[feedStore.userFeedList?.length - 1]?.id,
            __pageValue:
              feedStore.userFeedList?.[feedStore.userFeedList?.length - 1]
                ?.publishedAt,
          }

          const result = await feedStore.fetchUserFeedList(
            userDetail.id,
            option,
          )

          setHasMore(result?.length >= 20)
        }
      }
    }
    fetchFeedList()
  }, [feedStore, userDetail, offset])
  React.useEffect(() => {
    if (initFeedId) {
      const isAuth = authStore.tryAuth()
      if (!isAuth) return

      setTargetFeedId(initFeedId)
      toggleDetail()
      setInitFeedId(null)
    }

    return () => {}
  }, [])

  return (
    <FeedListBox>
      <Flex justify="flex-end" align="flex-end" />
      {
userFeedList?.length > 0 ? (
  <InfiniteScroll
    dataLength={userFeedList.length}
    scrollThreshold={0.9}
    next={handleOffset}
    hasMore={hasMore}
    hasChildren
    loader={
      <ReactLoading
        type="spin"
        color="black"
        style={
          {
            width: '50px',
            height: '50px',
            margin: '0 auto 50px auto',
          }
        }
      />
    }
    className="scroll_div"
    scrollableTarget="scrollableDiv"
    style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
  >
    <List>
      {
        userFeedList
              && userFeedList.map((item, index) => (
                // FeedListItem이 Image태그밖에 없어서 임시 컴포넌트
                <FeedCard
                  item={item}
                  key={item.id}
                  handleLoadDetail={handleLoadDetail}
                />
              ))
      }
    </List>
  </InfiniteScroll>
) : (
  <Div
    marginTop="84px"
    width="100%"
    textAlign="center"
    color={colors.grey_64}
  >
          피드가 없습니다
  </Div>
)
      }
      <FeedDetailModal.View
        isShowing={showDetail}
        toggle={toggleDetail}
        feedId={targetFeedId}
      />
    </FeedListBox>
  )
}

export default observer(UserDetailFeedSection)

const FeedListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -28px;

  width: calc(100% + 28px);

  > div {
    margin-left: 28px;
    margin-bottom: 28px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
