import { Flex, Caption1, Div } from '@components/atoms'
import { CastListItem } from '@components/molecules'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'

const UserDetailCastSection = ({
  userDetail,
}) => {
  const { authStore, castStore } = useStore()
  const history = useHistory()

  const userCastList = castStore.userCastList || null

  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)

  const handleOffset = () => {
    setOffset(offset + 1)
  }

  React.useEffect(() => {
    castStore.userCastList = []
  }, [castStore])

  React.useEffect(() => {
    const _fetchUserCastList = async () => {
      if (userDetail?.id) {
        const isMore = await castStore.fetchUserCastList(userDetail.id)
        setHasMore(isMore)
      }
    }
    _fetchUserCastList()
  }, [castStore, userDetail, offset])

  return (
    <MixtapeListBox>
      {
userCastList?.length > 0 ? (
  <InfiniteScroll
    dataLength={userCastList.length}
    scrollThreshold={0.9}
    next={handleOffset}
    hasMore={hasMore}
    hasChildren
    loader={
      <ReactLoading
        type="spin"
        color="black"
        style={
          {
            width: '50px',
            height: '50px',
            margin: '0 auto 50px auto',
          }
        }
      />
    }
    className="scroll_div"
    scrollableTarget="scrollableDiv"
    style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
  >
    <List>
      {
        userCastList
              && userCastList.map((item, index) => (
                <CastListItem
                  item={item}
                  key={`${item.id}_${index}`}
                  category="user"
                />
              ))
      }
    </List>
  </InfiniteScroll>
) : (
  <Div
    marginTop="84px"
    width="100%"
    textAlign="center"
    color={colors.grey_64}
  >
          캐스트가 없습니다
  </Div>
)
      }
    </MixtapeListBox>
  )
}

export default observer(UserDetailCastSection)

const MixtapeListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -24px;

  width: calc(100% + 24px);

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
