import { Caption1, Flex, ProfileImg } from '@components/atoms'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { LiveRoomUserModel } from '@stores/models'
import { Observer } from 'mobx-react-lite'
import React from 'react'
import styled, { css } from 'styled-components'

interface IBroadcasterUser {
  user: LiveRoomUserModel;
}

const BroadcasterUser: React.FC<IBroadcasterUser> = ({
  user,
  loadSalonUser,
}) => {
  const isHost = user.level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']

  return (
    <Flex
      type="column"
      style={{ height: 144, width: 88, alignItems: 'center' }}
    >
      <UserBox>
        <UserImageBox onClick={() => loadSalonUser(user)}>
          <Observer>
            {
              () => {
              // const volumnPercent = user['micVolume'] / 10
              // 볼륨 단계 별로 지정
                let volumeStep = 0
                switch (true) {
                  case user['micVolume'] < 1:
                    volumeStep = 0
                    break
                  case user['micVolume'] < 2:
                    volumeStep = 1
                    break
                  case user['micVolume'] < 10:
                    volumeStep = 2
                    break
                  case user['micVolume'] < 20:
                    volumeStep = 3
                    break
                  case user['micVolume'] < 25:
                    volumeStep = 4
                    break
                  case user['micVolume'] >= 25:
                    volumeStep = 5
                    break

                  default:
                    break
                }
                // if (volumnPercent > 0)
                if (volumeStep > 0)
                  return (
                    <UserVolume
                    // volumePercent={volumnPercent}
                      volumeStep={volumeStep}
                    />
                  )
                return <></>
              }
            }
          </Observer>
          <UserImage
            src={user?.User?.imageUri}
            profileColor={user?.User?.profileColor}
          />
        </UserImageBox>
        {
          isHost && (
            <UserStateBadge src={images.salon_host_img} alt="state_badge_img" />
          )
        }
        <Observer>
          {
            () => {
              return (
              user?.isMutedMic && (
                  <UserMicBadge>
                    <img src={images.salon_mute_mic_img} alt="mic_badge_img" />
                  </UserMicBadge>
                )
              )
            }
          }
        </Observer>
      </UserBox>

      <UserName color={colors.white_ff}>{user?.User?.name}</UserName>
    </Flex>
  )
}

export default BroadcasterUser

const UserBox = styled(Flex)`
  position: relative;

  width: 100px;
  height: 100px;

  justify-content: center;
  align-items: center;
`

const UserImageBox = styled(Flex)`
  justify-content: center;
  align-items: center;

  position: relative;

  width: 88px;
  height: 88px;

  margin-bottom: 12px;
  cursor: pointer;
`

const UserVolume = styled.div`
  z-index: 1;
  position: absolute;
  border-radius: 50%;

  /* ${({ volumePercent }) =>
    volumePercent
    && css`
      border: solid ${volumePercent * 4}px ${colors.primary};
      width: ${volumePercent * 8 + 90}px;
      height: ${volumePercent * 8 + 90}px;
    `} */

  ${({ volumeStep }) =>
    css`
      border: solid ${volumeStep}px ${colors.primary};
      width: ${volumeStep + 90}px;
      height: ${volumeStep + 90}px;
    `}
`

const UserImage = styled(ProfileImg)`
  z-index: 2;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 1px ${colors.white_ff};
`

const UserStateBadge = styled.img`
  z-index: 2;
  position: absolute;
  bottom: 6px;
  right: 0;
  width: 32px;
  height: 32px;
  object-fit: cover;
`

const UserMicBadge = styled(Flex)`
  z-index: 2;
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 32px;
  height: 32px;

  justify-content: center;
  align-items: center;

  background-color: ${colors.white_ff};
  border-radius: 50%;

  > img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
`

const UserName = styled(Caption1)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
