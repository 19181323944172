import { Caption1, Div, Flex, ProfileImg } from '@components/atoms'
import {
  LIVE_ROOMS_HAVE_USERS_LEVEL,
  LIVE_ROOM_MESSAGE_TYPE,
} from '@consts/liveRoom'
import { CAST_UPLOAD_TYPE } from '@consts/cast'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { urlify } from '@utils/format'
import React, { useCallback } from 'react'
import { STORAGE_URL } from '@consts'
import { useHistory } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { LinkPreview } from '@dhaiwat10/react-link-preview'
import { Observer, observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import CopyToClipboard from 'react-copy-to-clipboard'
import ImageFullPopup from '../../molecules/Popup/ImageFullPopup'

const SalonChattingMessage = ({
  data,
  index,
  _toggleUserReport,
  _toggleSubscriptPlan,
  currentUserId,
  loadSalonUser,
}) => {
  const { liveRoomStore, purchaseStore } = useStore()
  const history = useHistory()

  const currLiveRoomUser = liveRoomStore.currLiveRoomUser || null
  const deleteLiveroomMessage =
    liveRoomStore.deleteLiveroomMessage || (() => {})
  const { level = 0 } = currLiveRoomUser || {}

  const isHost = level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
  const isMine = currentUserId === data.LiveRoomUser.User.id

  const [hover, setHover] = React.useState(false)
  const [openThreeDot, setOpenThreeDot] = React.useState(false)

  // get ImageSize
  const imgElement = React.useRef(null)
  const [imgWidth, setImgWidth] = React.useState(280)
  const [imgHeight, setImgHeight] = React.useState(280)

  const isMyOwner =
    currLiveRoomUser?.level === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']

  const {
    isShowing: showFullImg,
    toggle: toggleFullImg,
  } = ImageFullPopup.useModal()

  const addImage = data => {
    if (data.imageUri) {
      return (
        <img
          src={STORAGE_URL + data.imageUri}
          ref={imgElement}
          style={
            {
              width: imgWidth,
              height: imgHeight,
              marginTop: '4px',
              borderRadius: '8px',
            }
          }
          alt={'img'}
          onLoad={
            () => {
              const width = imgElement.current.naturalWidth
              const height = imgElement.current.naturalHeight

              if (height > width) {
                setImgHeight(280)
                setImgWidth((width / height) * 280)
              }
              else {
                setImgWidth(280)
                setImgHeight((height / width) * 280)
              }
            }
          }
          onClick={toggleFullImg}
        />
      )
    }

    return (
      <>
        <Caption1
          align="left"
          type="Regular"
          color={colors.white_ff}
          style={
            {
              maxWidth: 412,
              width: '100%',
              overflowWrap: 'break-word',
              whiteSpace: 'pre-wrap',
            }
          }
          // 하이퍼링크 로직 추가
          dangerouslySetInnerHTML={
            {
              __html: urlify(data.chatMessage),
            }
          }
        />
        {
          data.linkUrl && (
            <div style={{ marginTop: '4px' }}>
              <LinkPreview
                url={data.linkUrl}
                width={300}
                height={300}
                descriptionLength={20}
              />
            </div>
          )
        }
      </>
    )
  }

  const handleDeleteChat = useCallback(async () => {
    await deleteLiveroomMessage(index)
    setOpenThreeDot(false)
  }, [data])

  if (data?.messageType === LIVE_ROOM_MESSAGE_TYPE['RECORDING_START']) {
    return (
      <Div display="flex" flexDirection="column">
        <Caption1 align="center" type="Regular" color={colors.white_ff}>
          {`${data?.message}`}
        </Caption1>
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="4px"
        >
          <Caption1 align="center" type="Regular" color={colors.white_ff}>
            REC
          </Caption1>
          <img
            src={images.salon_rec_active_img}
            alt="rec_icon"
            style={{ width: '16px', height: '16px' }}
          />
          <Caption1 align="center" type="Regular" color={colors.white_ff}>
            으로 녹음을 종료할 수 있습니다
          </Caption1>
        </Div>
      </Div>
    )
  }

  if (data?.messageType === LIVE_ROOM_MESSAGE_TYPE['RECORDING_END']) {
    return (
      <RecordingStopMessage>
        <div style={{ textAlign: 'center' }}>{`${data?.message}`}</div>
        {
data?.LiveRoomUser?.User.id === currentUserId && (
            <>
              <div style={{ textAlign: 'center' }}>
                {`녹음한 파일로 캐스트를 추가하고, 공유해보세요.`}
              </div>
              <CastAddBtn
                onClick={
                  () => {
                    const { Audio } = data

                    if (!Audio) return

                    delete Audio['LiveRoomUser']

                    const recording = {
                      ...Audio,
                      LiveRoom: Audio['LiveRoom'],
                    }

                    history.push({
                      pathname: `/p/cast/upload`,
                      state: {
                        type: CAST_UPLOAD_TYPE['RECORDING'],
                        recording,
                      },
                    })
                  }
                }
              >
                {'캐스트 추가하기'}
              </CastAddBtn>
            </>
          )
        }
      </RecordingStopMessage>
    )
  }

  if (data?.messageType === LIVE_ROOM_MESSAGE_TYPE['END_SOON']) {
    return (
      <Flex
        type={'column'}
        style={
          {
            margin: '16px 0',
          }
        }
      >
        <Caption1
          align="left"
          type="Regular"
          color={'#ffffff'}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {`[안내]\n살롱 이용시간이 ${data?.data?.remainTime}분 남았습니다.`}
        </Caption1>
        <Caption1
          align="left"
          type="Regular"
          color={'#d4d4d4'}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {
            isMyOwner ? (
              <>
                <Observer>
                  {
                    () => {
                      if (
                    purchaseStore?.myPlan?.adLevel === 200
                    && !purchaseStore?.isLimitSalonTime
                      ) {
                        return (
                          <>
                            {`플랜을 구독하고 시간제한 없이 살롱을 이용해보세요.`}
                            <PlanBtn disabled>플랜 구독 완료</PlanBtn>
                          </>
                        )
                      }
                      if (
                    purchaseStore?.myPlan?.adLevel === 200
                    && purchaseStore?.isLimitSalonTime
                      ) {
                        return `${data?.data?.remainTime}분 후 지금 이용중인 살롱이 자동 종료됩니다.\n*Standard 또는 Premium 플랜으로 변경하고\n시간제한 없이 이용해보세요.`
                      }

                      return (
                        <>
                          {`플랜을 구독하고 시간제한 없이 살롱을 이용해보세요.`}
                          <PlanBtn
                            onClick={
                              () => {
                                _toggleSubscriptPlan()
                              }
                            }
                          >
                        플랜 구독하기
                          </PlanBtn>
                        </>
                      )
                    }
                  }
                </Observer>
              </>
            ) : (
              `${data?.data?.remainTime}분 후 지금 이용중인 살롱이 자동 종료됩니다.`
            )
          }
        </Caption1>
      </Flex>
    )
  }

  if (data?.messageType === LIVE_ROOM_MESSAGE_TYPE['SYSTEM']) {
    if (data?.chatMessage) {
      return (
        <Caption1
          align="center"
          type="Regular"
          color={colors.white_ff}
          style={{ margin: '16px 0' }}
        >
          {`${data?.chatMessage}`}
        </Caption1>
      )
    }
    return (
      <Caption1
        align="center"
        type="Regular"
        color={colors.white_ff}
        style={{ margin: '16px 0' }}
      >
        {
          `${
          data?.LiveRoomUser?.User?.name ? data.LiveRoomUser.User.name : ''
          } ${data?.message}`
        }
      </Caption1>
    )
  }
  if (data?.messageType === LIVE_ROOM_MESSAGE_TYPE['STAR']) {
    return (
      <Flex
        justify="center"
        align="center"
        style={
          {
            position: 'relative',
            width: '100%',
            height: 80,
            minHeight: 80,
            borderRadius: 8,
          }
        }
      >
        <Flex align="center">
          <div
            style={
              {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 80,
                minHeight: 80,
                borderRadius: 8,
                backgroundColor: colors.white_ff,
                backdropFilter: 'blur(24px)',
                opacity: 0.3,
              }
            }
          />
          <ImageBox>
            {
data?.LiveRoomUser?.level
              >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
                <UserStateBadge
                  src={images.salon_host_img}
                  alt="state_badge_img"
                />
              )
            }
            <UserImage
              style={{ border: `solid 1px ${colors.white_f4}` }}
              level={data?.LiveRoomUser?.level}
              src={data?.LiveRoomUser?.User?.imageUri}
              profileColor={data.LiveRoomUser.User.profileColor}
            />
          </ImageBox>
          <Caption1
            align="center"
            type="Regular"
            color={colors.white_ff}
            style={
              {
                marginLeft: 12,
                maxWidth: 412,
                width: '100%',
                overflow: 'hidden',
              }
            }
          >
            {`${data?.LiveRoomUser?.User?.name}님이 별 ${data.chatMessage}개 선물!`}
          </Caption1>
        </Flex>
      </Flex>
    )
  }

  if (data) {
    return (
      <Flex
        align="baseline"
        style={{ position: 'relative', width: '100%' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ImageFullPopup.View
          isShowing={showFullImg}
          toggle={toggleFullImg}
          src={STORAGE_URL + data.imageUri}
        />
        <ImageBox>
          {
data?.LiveRoomUser?.level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <UserStateBadge src={images.salon_host_img} alt="state_badge_img" />
            )
          }
          <UserImage
            onClick={
              () =>
              // history.push(`/user/${data?.LiveRoomUser?.User?.account}`)
                loadSalonUser(data?.LiveRoomUser)
            }
            level={data?.LiveRoomUser?.level}
            src={data?.LiveRoomUser?.User?.imageUri}
            profileColor={data.LiveRoomUser.User.profileColor}
          />
        </ImageBox>
        <Flex type="column" style={{ width: '100%', marginLeft: 12 }}>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Caption1
              align="left"
              type="Regular"
              color={colors.white_ff}
              style={{ maxWidth: 412, width: '100%', overflow: 'hidden' }}
            >
              {`${data?.LiveRoomUser?.User?.name}`}
            </Caption1>
            {
              (isHost || !isMine) && hover && (
                <ThreeDot
                  src={images.three_dot_white_img}
                  alt="three_dot_icon"
                  onClick={() => setOpenThreeDot(true)}
                />
              )
            }
          </Flex>
          {addImage(data)}
        </Flex>
        {
          openThreeDot && data && data.LiveRoomUser && data.LiveRoomUser.User && (
            <>
              <ThreeDotMenu>
                {
                  !isMine && (
                    <Div
                      width="100%"
                      height="40px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      onClick={
                        () =>
                          _toggleUserReport(data?.LiveRoomUser?.User?.id)
                      }
                    >
                      <Caption1>신고하기</Caption1>
                    </Div>
                  )
                }
                {
                  isHost && (
                    <>
                      <Div
                        width="100%"
                        height="40px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        cursor="pointer"
                        onClick={
                          async () => {
                            await liveRoomStore.fixedComment(data)
                            setOpenThreeDot(false)
                          }
                        }
                      >
                        <Caption1>고정하기</Caption1>
                      </Div>
                    </>
                  )
                }
                <CopyToClipboard
                  text={data?.chatMessage}
                  onCopy={() => setOpenThreeDot(false)}
                >
                  <Div
                    width="100%"
                    height="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                  >
                    <Caption1>복사하기</Caption1>
                  </Div>
                </CopyToClipboard>
                {
                  (isHost || isMine) && (
                    <Div
                      width="100%"
                      height="40px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => handleDeleteChat()}
                    >
                      <Caption1 color={colors.warning}>삭제하기</Caption1>
                    </Div>
                  )
                }
              </ThreeDotMenu>
              <Overlay onClick={() => setOpenThreeDot(false)} />
            </>
          )
        }
      </Flex>
    )
  }
  return <></>
}

export default observer(SalonChattingMessage)

const RecordingStopMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-family: NotosansCJKkr-Regular;
  font-size: 14px;
  color: ${colors.white_ff};
  text-align: center;
  align-items: center;
  justify-content: center;
`
const CastAddBtn = styled.div`
  display: flex;
  width: 116px;
  height: 36px;
  background-color: #5b49f3;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  cursor: pointer;
`
const PlanBtn = styled.div`
  display: flex;
  width: 104px;
  height: 36px;
  background-color: #5b49f3;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-family: NotosansCJKkr-Medium;

  ${({ disabled }) =>
    disabled
    && css`
      cursor: none;
      background-color: #f4f4f4;
      color: #949494;
    `}
`

const ImageBox = styled.div`
  align-self: flex-start;
  position: relative;
  width: 48px;
  height: 48px;
`

const UserStateBadge = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  object-fit: cover;
`

const UserImage = styled(ProfileImg)`
  cursor: pointer;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;

  ${({ level }) =>
    level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
      ? css`
          border: solid 1px #feab17;
        `
      : css`
          border: solid 1px ${colors.white_f4};
        `}
`

const ThreeDot = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  cursor: pointer;
`

const ThreeDotMenu = styled(Flex)`
  position: absolute;
  top: 20px;
  right: 0;
  width: 112px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: ${colors.white_ff};
  z-index: 11;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
`
