import EventEmitter from 'events'
import AgoraRTC from '../../public/AgoraRTC_N-4.7.3'

export default class RTCClient extends EventEmitter {
  constructor() {
    super()
    this.client = null
  }

  init(appId) {
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })

    this.subscribeClientEvents()
  }

  // subscribe client events
  subscribeClientEvents() {
    const clientEvents = [
      'channel-media-relay-event',
      'channel-media-relay-state',
      'connection-state-change',
      'crypt-error',
      'exception',
      'is-using-cloud-proxy',
      'live-streaming-error',
      'live-streaming-warning',
      'media-reconnect-end',
      'media-reconnect-start',
      'network-quality',
      'stream-fallback',
      'stream-inject-status',
      'stream-type-changed',
      'token-privilege-did-expire',
      'token-privilege-will-expire',
      'user-info-updated',
      'user-joined',
      'user-left',
      'user-published',
      'user-unpublished',
      'volume-indicator',
    ]
    clientEvents.forEach(eventName => {
      this.client.on(eventName, (...args) => {
        // log event message
        this.emit(eventName, ...args)
      })
    })
  }

  // subscribe channel events
  // subscribeChannelEvents(channelName) {
  //   const channelEvents = ['user-published', 'connection-state-change']
  //   channelEvents.forEach(eventName => {
  //     this.client.on(eventName, (...args) => {
  //       console.log('emit ', eventName, args)
  //       this.emit(eventName, ...args)
  //     })
  //   })
  // }

  async logout() {
    return this.client.logout()
  }

  async joinChannel({ appId, token, channel, uid }) {
    try {
      await this.client.join(appId, channel, token, parseInt(uid))
      // await this.subscribeChannelEvents()
      return true
    }
    catch (e) {
      console.error('[rtc-client] join failed', e)
    }
    return false
  }

  async leaveChannel() {
    return await this.client.leave()
  }

  async setClientRole(role) {
    this.client.setClientRole(role)
  }

  async createAudio() {
    /**
     * windows 10 - Chrome 98.0.4758.82 테스트 완료
     */
    const microphoneList = await AgoraRTC.getMicrophones()
    // console.log('jhlim', 'getMicrophones', microphoneList)

    const localAudio = await AgoraRTC.createMicrophoneAudioTrack({
      encoderConfig: 'high_quality_stereo',
      // encoderConfig: 'high_quality',
      // encoderConfig: {
      //   sampleRate: 48000,
      //   stereo: true,
      //   bitrate: 128,
      // },

      ...(microphoneList
      && microphoneList.length > 0
      && microphoneList[0].deviceId
        ? { microphoneId: microphoneList[0].deviceId }
        : {}),
    })
    return localAudio
  }

  async enableAudioVolumeIndicator() {
    return this.client.enableAudioVolumeIndicator()
  }

  async removeAllListeners() {
    if (!this.client) return
    return this.client.removeAllListeners()
  }

  async unpublish(audio) {
    return this.client.unpublish(audio)
  }
}
