import React, { useEffect } from 'react'
import { ValidationStudentTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const ValidationStudentPage = () => {
  useRenderLogger('ValidationStudentPage')
  const { purchaseStore } = useStore()

  const isValidatedStudent = purchaseStore.isValidatedStudent || null
  const validateStudent = purchaseStore.validateStudent || (() => {})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ValidationStudentTemplate
      isValidatedStudent={isValidatedStudent}
      validateStudent={validateStudent}
    />
  )
}

export default observer(ValidationStudentPage)
