import React, { useCallback, useEffect, useState } from 'react'
import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  ProfileImg,
  StyledButton,
} from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { DJBadge } from '@components/molecules'
import { STORAGE_URL } from '@consts/'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import { converseUnitEng } from '@utils/format'
import { oneEllipsisStyle } from '@styles/fontStyles'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

const isAnimation = true
const isShow = true
const canClosed = true
const isRadius = true
const width = 520
const height = 800
const wait = true
const padding = '24px'
const MyBlockUserModal = ({ hash }) => {
  const { appStateStore, authStore, userStore } = useStore()

  const { currentUser } = authStore
  const offModal = appStateStore.offModal || (() => {})
  const getModalState = appStateStore.getModalState || (() => {})
  const fetchAllBlcokUsers = userStore.fetchAllBlcokUsers || (() => {})

  const userName = currentUser?.name
  const [payload, setPayload] = useState()
  const [list, setList] = useState([])

  const onClose = useCallback(() => {
    offModal(hash)
  }, [hash, offModal])

  const getPayload = useCallback(async () => {
    if (hash) {
      const state = await getModalState(hash)
      if (state?.payload) {
        setPayload(state.payload)
      }
    }
  }, [hash, getModalState])

  const fetchBlockUserList = useCallback(
    async lastItem => {
      const res = await fetchAllBlcokUsers(lastItem)
      if (!res) return
      setList(prev => prev.concat(res))
    },
    [fetchAllBlcokUsers],
  )

  const handleDeleteBlock = async thisUser => {
    try {
      await userStore.deleteBlockUser(currentUser?.id, thisUser?.id)
      setList(list.filter(user => user.id !== thisUser.id))
    }
    catch (error) {
      console.error('UserRelationPopup handleDeleteBlock error', error)
    }
  }

  useEffect(() => {
    getPayload()
    fetchBlockUserList()
  }, [fetchBlockUserList])

  return (
    <Div
      top="0"
      width="100vw"
      height="100%"
      overflow="hidden"
      position="fixed"
      zIndex={2000}
    >
      <Div
        width="100%"
        height="100%"
        position="fixed"
        bottom="0"
        animation={
          isAnimation
            ? isShow
              ? 'fadeIn forwards 0.25s'
              : 'fadeOut forwards ease-in 0.25s'
            : ''
        }
        background="rgba(0,0,0,0.3)"
        onClick={canClosed && wait ? onClose : () => {}}
      />
      <Div
        position="absolute"
        width={width}
        height={height}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        animation={
          isAnimation
            ? isShow
              ? 'fadeIn forwards 0.25s'
              : 'fadeOut forwards ease-in 0.1s'
            : ''
        }
      >
        <Div
          display="flex"
          flexDirection="column"
          alignItems="center"
          background="white"
          width={width}
          height={height}
          padding={padding ?? padding}
          borderRadius={isRadius ? '10px' : '0'}
        >
          <Caption1>차단 목록</Caption1>
          <Div
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            marginTop="16px"
            width="100%"
            gap="20px"
          >
            {
list?.length < 1 ? (
  <Div>차단한 유저가 없습니다.</Div>
) : (
  <InfiniteScroll
    style={{ overflowX: 'hidden' }}
    dataLength={list.length}
    scrollThreshold={0.7}
    next={() => fetchBlockUserList(list[list.length - 1])}
    hasMore={list[list.length - 1]?.isFinished}
    scrollableTarget="scrollableDiv"
    hasChildren
    loader={
      <ReactLoading
        type="spin"
        color="black"
        style={
          {
            width: '50px',
            height: '50px',
            margin: '0 auto 50px auto',
          }
        }
      />
    }
  >
    {
list?.map(user => (
  <BlockUserListItem
    item={user}
    key={user.id}
    userName={userName}
    handleDeleteBlock={handleDeleteBlock}
  />
))
    }
  </InfiniteScroll>
)
            }
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default observer(MyBlockUserModal)

const BlockUserListItem = ({ userName, handleDeleteBlock, item }) => {
  console.log('item', item)
  return (
    <Div width="100%" display="flex" alignItems="center">
      <UserImage
        src={item?.imageUri && `${STORAGE_URL}${item?.imageUri}`}
        profileColor={item?.profileColor}
      />
      <Div
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        flex="1"
        paddingRight="56px"
      >
        <Div display="flex" alignItems="center" gap="3px">
          <Caption2 type="Bold">{item?.name}</Caption2>
          <DJBadge discJockeyLevel={item?.discJockeyLevel} />
          <Caption2 color={colors.grey_64} type="Regular">
            {`@${item?.account}`}
          </Caption2>
        </Div>
        <Caption2 type="Regular" style={oneEllipsisStyle}>
          {item.description}
        </Caption2>
        <Caption3 type="Regular" color={colors.grey_94}>
          {
            `${userName || ''}님 외 ${converseUnitEng(
              item.followerCount - 1 || 0,
            )}명이 팔로우 합니다`
          }
        </Caption3>
      </Div>
      <StyledButton
        designType="whiteF4"
        size="sm"
        width="52px"
        color={colors.grey_94}
        onClick={() => handleDeleteBlock(item)}
      >
        해제
      </StyledButton>
    </Div>
  )
}

const UserImage = styled(ProfileImg)`
  cursor: pointer;
  width: 52px;
  height: 52px;
  margin-right: 12px;
  border-radius: 50%;
  object-fit: cover;
`
