import { H6 } from '@components/atoms'
import { colors } from '@resources/colors'
import { MobileLeadPopup } from '@components/molecules'
import { images } from '@resources/'
import {
  useRenderLogger,
  useStore,
  // useStore
} from '@utils/hooks'
import QueryString from 'qs'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SalonReserveTemplate } from '@components/templates'

const MobileSalonPage = () => {
  useRenderLogger('MobileSalonPage')

  const { isShowing, toggle } = MobileLeadPopup.usePopup()
  const { authStore, liveRoomStore } = useStore()

  const liveRoomDetail = liveRoomStore.liveRoomDetail || null

  const param = document.location.href.split('?')
  const query = QueryString.parse(param[1], {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const fetchLiveRoom = async () => {
      const param = await document.location.href.split('?')
      const query = await QueryString.parse(param[1], {
        ignoreQueryPrefix: true,
      })

      if (query) {
        if (authStore.jsonWebToken) {
          if (authStore.currentUser)
            await liveRoomStore.fetchLiveRoomDetail(query.liveRoomId, true)
        }
        else {
          await liveRoomStore.fetchLiveRoomDetail(query.liveRoomId, true)
        }
      }
    }
    fetchLiveRoom()
  }, [authStore, authStore.currentUser, liveRoomStore])

  // 열린 살롱이면 모바일 유도 페이지
  React.useState(() => {
    if (liveRoomDetail && liveRoomDetail.isOpened) toggle()
  }, [liveRoomDetail])

  React.useEffect(() => {
    if (liveRoomDetail && liveRoomDetail.isOpened) {
      setTimeout(() => {
        toggle()
      }, 2000)
    }
    // eslint-disable-next-line
  }, [liveRoomDetail])

  return (
    <div style={{ overflow: 'hidden' }}>
      {
        liveRoomDetail ? (
          liveRoomDetail.isReserved && !liveRoomDetail.isOpened ? (
            <SalonReserveTemplate currLiveRoom={liveRoomDetail} />
          ) : !isMobile ? (
            <Redirect to={`/salon/${query?.liveRoomId}`} />
          ) : (
            false
          )
        ) : (
          false
        )
      }
      <img
        src={images.salon_mobile_view}
        style={
          {
            width: '100vw',
            height: '100vh',
            objectFit: 'cover',
            overflow: 'hidden',
          }
        }
        alt="view_img"
        onClick={toggle}
      />
      <MobileLeadPopup.View
        dynamicLink={liveRoomDetail?.dynamicLink}
        isShowing={isShowing}
        toggle={toggle}
      />
    </div>
  )
}

export default observer(MobileSalonPage)
