import { observable } from 'mobx'
import { UserModel } from '.'

export default class UsersFollowUsersModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable userId
  @observable followId

  @observable User

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.userId = props.userId
      this.followId = props.followId

      this.User = props.User && new UserModel(stores, props.User)
    }
  }
}
