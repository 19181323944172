import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  Flex,
  ProfileImg,
} from '@components/atoms'
import {
  BioSocialBtn,
  DeletePopup,
  DJBadge,
  MbtiBadge,
  OkCancelPopup,
  UserRelationPopup,
} from '@components/molecules'
import { HouseEditModal } from '@components/organisms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { HousesHaveUsersModel } from '@stores/models'
import { addComma } from '@utils/string'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { FRIEND_LEVEL } from '@consts/'
import { PresentStarModal, UserFollowInfoModal } from '..'

const HouseBadge = ({ level = 0 }) => {
  let levelImage = images.house_member_icon_img
  switch (level) {
    case 40:
      levelImage = images.salon_host_img
      break
    case 30:
      levelImage = images.salon_host_img
      break
    case 20:
      levelImage = images.house_member_icon_img
      break
    default:
      levelImage = images.house_follower_icon_img
      break
  }

  return (
    <Flex
      style={
        {
          position: 'absolute',
          bottom: 0,
          right: 0,
          backgroundColor: colors.white_f4,
          borderRadius: '50%',
          width: 16,
          height: 16,
        }
      }
    >
      <img
        src={levelImage}
        alt="house_level_icon"
        style={{ width: 14, height: 14, position: 'absolute', top: 1, left: 1 }}
      />
    </Flex>
  )
}

const UserDetailInfoSection = observer(
  ({
    userDetail,
    style,
    isMyPage,
    unFollow,
    follow,
    updateFollow,
    currentUser,
    thisUserJoinedHouseList,
  }) => {
    const history = useHistory()

    const [showThreeDotFollow, setShowThreeDotFollow] = useState(false)

    const {
      isShowing: showHoustEditModal,
      toggle: toggleHouseEditModal,
    } = HouseEditModal.useModal()

    const followType = React.useRef(null)

    const {
      isShowing: showPresent,
      toggle: togglePresent,
    } = PresentStarModal.useModal()

    const {
      isShowing: showFollow,
      toggle: toggleFollow,
    } = UserFollowInfoModal.useModal()

    const {
      isShowing: showDistance,
      toggle: toggleDistance,
    } = OkCancelPopup.usePopup()

    const {
      isShowing: showUserRelation,
      toggle: toggleUserRelation,
    } = UserRelationPopup.usePopup()

    const { isShowing: showBlock, toggle: toggleBlock } = DeletePopup.useModal()

    const _followUser = () => {
      if (userDetail && userDetail.isFollowed) {
        setShowThreeDotFollow(true)
        // unFollow(userDetail)
      }
      else {
        follow(userDetail)
      }
    }

    const _toggleFollowInfo = type => {
      followType.current = type
      toggleFollow()
    }

    return (
      <Section style={style && style}>
        <Flex
          style={{ width: '100%', marginBottom: 24 }}
          justify="space-between"
        >
          <Flex align="flex-end">
            <UserImage
              src={userDetail?.imageUri}
              profileColor={userDetail?.profileColor}
              style={
                {
                  marginRight: 6,
                }
              }
            />
            <Div display="flex" alignItems="center" gap="8px">
              {
userDetail?.badgeId && (
                  <Div
                    width="auto"
                    height="20px"
                    borderRadius="14px"
                    backgroundColor={colors.white_f4}
                    display="flex"
                    alignItems="center"
                    padding="2px 5px 2px 2px"
                  >
                    <img
                      src={userDetail?.badgeThumbnailSource?.uri}
                      style={
                        {
                          width: '16px',
                          height: '16px',
                          marginRight: 4,
                        }
                      }
                      alt="badge"
                    />
                    <Caption3 style={{ lineHeight: '12px' }}>
                      {userDetail?.badgeName}
                    </Caption3>
                  </Div>
                )
              }
              <MbtiBadge mbti={userDetail?.mbti} style={{ marginBottom: 2 }} />
              <DJBadge
                isMine={userDetail?.id === currentUser?.id}
                discJockeyLevel={userDetail?.discJockeyLevel}
                style={
                  {
                    width: 20,
                    height: 20,
                    color: colors.primary,
                    fontSize: 14,
                  }
                }
              />
            </Div>

            <img
              src={images.three_dot_black}
              alt="three_dot"
              onClick={toggleUserRelation}
              style={
                {
                  cursor: 'pointer',
                  width: 20,
                  height: 20,
                  marginLeft: 14,
                }
              }
            />
          </Flex>
          <Flex
            type="column"
            style={{ width: 202, height: 104, justifyContent: 'flex-end' }}
          >
            {
              isMyPage && (
                <Flex style={{ justifyContent: 'space-between' }}>
                  <UserHeaderTopBtn onClick={() => history.push('/p/user/update')}>
                  채널 수정
                  </UserHeaderTopBtn>
                  <UserHeaderTopBtn onClick={() => history.push('/p/user/myinfo')}>
                  개인정보 수정
                  </UserHeaderTopBtn>
                </Flex>
              )
            }
            <Flex style={{ marginTop: 32, justifyContent: 'flex-end' }}>
              {
                isMyPage && (
                  <UserHeaderBottomBtn onClick={() => history.push('/p/star/main')}>
                    <img src={images.salon_star_img} alt="star_icon" />
                    <Caption2 color={colors.primary}>
                      {addComma(userDetail?.currentStar || 0)}
                    </Caption2>
                  </UserHeaderBottomBtn>
                )
              }
              {
                !isMyPage && (
                  <UserHeaderBottomBtn
                    onClick={togglePresent}
                    style={{ minWidth: 105, marginLeft: 8 }}
                  >
                    <img src={images.present_img} alt="present" />
                    <Caption2>선물하기</Caption2>
                  </UserHeaderBottomBtn>
                )
              }
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center">
          {
userDetail?.isInfluencer && (
              <img
                src={images.check_blue_img}
                style={{ width: 14, height: 14, marginRight: 4 }}
                alt="influencer"
              />
            )
          }
          <Caption1 align="left" type="Bold">
            {userDetail?.name}
          </Caption1>
          {
            (userDetail?.organization || userDetail?.duty) && (
              <Caption2 color={colors.grey_64} style={{ marginLeft: 8 }}>
                {`${userDetail?.organization || ''} ${userDetail?.duty || ''}`}
              </Caption2>
            )
          }
        </Flex>
        <Flex style={{ marginTop: 12 }}>
          <Caption1
            color={colors.grey_64}
            style={{ cursor: 'pointer' }}
            onClick={() => _toggleFollowInfo('follower')}
          >
            {addComma(userDetail?.followerCount || 0)}
          </Caption1>
          <Caption1
            style={{ cursor: 'pointer', margin: '0 16px 0 4px' }}
            color={colors.grey_94}
            onClick={() => _toggleFollowInfo('follower')}
          >
            팔로워
          </Caption1>
          <Caption1
            style={{ cursor: 'pointer' }}
            color={colors.grey_64}
            onClick={() => _toggleFollowInfo('following')}
          >
            {addComma(userDetail?.followingCount || 0)}
          </Caption1>
          <Caption1
            style={{ cursor: 'pointer', marginLeft: 4 }}
            color={colors.grey_94}
            onClick={() => _toggleFollowInfo('following')}
          >
            팔로잉
          </Caption1>
        </Flex>
        {
userDetail?.description && (
            <Caption2 style={{ marginTop: 16 }} type="Regular" align="left">
              {userDetail.description}
            </Caption2>
          )
        }
        {
userDetail?.bioWebsite && (
            <Flex style={{ marginTop: 16 }} align="center">
              <img
                src={images.share_web_img}
                alt="share_web"
                style={{ width: 12, height: 12, marginRight: 4 }}
              />
              <a
                href={userDetail.bioWebsite}
                target="_blank"
                rel="noopener noreferrer"
                style={
                  {
                    fontFamily: 'NotoSansCJKkr-Regular',
                    fontSize: 12,
                    color: colors.secondary,
                  }
                }
              >
                {userDetail.bioWebsite}
              </a>
            </Flex>
          )
        }
        <Flex style={{ marginTop: 16 }}>
          {
            !isMyPage && (
              <FollowBtn followed={userDetail?.isFollowed} onClick={_followUser}>
                {
userDetail?.isFollowed ? (
  <Flex align="center">
    <Caption1>팔로잉</Caption1>
    <img
      src={images.triangle_gray_img}
      style={{ width: 12, height: 12, marginLeft: 4 }}
      alt="down"
    />
    {
      showThreeDotFollow && (
        <>
          <ThreeDotMenu>
            <DotItem
              onClick={
                e => {
                  e.stopPropagation()
                  updateFollow(userDetail, {
                    level:
                                userDetail?.UsersFollowedUsers[0]?.level
                                === FRIEND_LEVEL['BEST_FRIEND']
                                  ? FRIEND_LEVEL['NORMAL_FRIEND']
                                  : FRIEND_LEVEL['BEST_FRIEND'],
                  })
                  setShowThreeDotFollow(false)
                }
              }
            >
              {
                `친한친구 ${
                            userDetail?.UsersFollowedUsers[0]?.level
                            === FRIEND_LEVEL['BEST_FRIEND']
                              ? '해제'
                              : '추가'
                }`
              }
            </DotItem>
            <DotItem
              onClick={
                e => {
                  e.stopPropagation()
                  setShowThreeDotFollow(false)
                  if (
                              userDetail?.UsersFollowedUsers[0]?.level
                              === FRIEND_LEVEL['DISTANCE_FRIEND']
                  ) {
                    updateFollow(userDetail, {
                      level: FRIEND_LEVEL['NORMAL_FRIEND'],
                    })
                  }
                  else {
                    toggleDistance()
                  }
                }
              }
            >
              {
                `거리두기 ${
                            userDetail?.UsersFollowedUsers[0]?.level
                            === FRIEND_LEVEL['DISTANCE_FRIEND']
                              ? '해제'
                              : ''
                }`
              }
            </DotItem>
            <DotItem
              style={{ color: colors.warning }}
              onClick={
                e => {
                  e.stopPropagation()
                  unFollow(userDetail)
                  setShowThreeDotFollow(false)
                }
              }
            >
                          팔로우 취소
            </DotItem>
          </ThreeDotMenu>
          <Overlay
            onClick={
              e => {
                e.stopPropagation()
                setShowThreeDotFollow(false)
              }
            }
          />
        </>
      )
    }
  </Flex>
) : (
  '팔로우'
)
                }
              </FollowBtn>
            )
          }
          {
userDetail?.bioInstagram && (
              <BioSocialBtn
                socialType="instagram"
                value={userDetail.bioInstagram}
              />
            )
          }
          {
userDetail?.bioYoutube && (
              <BioSocialBtn socialType="youtube" value={userDetail.bioYoutube} />
            )
          }
          {
userDetail?.bioEmail && (
              <BioSocialBtn socialType="email" value={userDetail.bioEmail} />
            )
          }
        </Flex>

        {
          isMyPage || thisUserJoinedHouseList?.length > 0 ? (
            <Flex type="column" style={{ margin: '16px 0', overflowX: 'scroll' }}>
              <Flex align="center">
                <img
                  src={images.house_icon_img}
                  style={{ width: 16, height: 16, marginRight: 4 }}
                  alt="house_icon"
                />
                <Caption2>하우스</Caption2>
              </Flex>
              <Flex style={{ marginTop: 13, gap: 8 }}>
                {
                  isMyPage && (
                    <Flex
                      style={
                        {
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 48,
                          minWidth: 48,
                          maxWidth: 48,
                          height: 48,
                          border: '1px solid #f4f4f4',
                          backgroundColor: colors.white_fa,
                          borderRadius: 16,
                          cursor: 'pointer',
                        }
                      }
                      onClick={toggleHouseEditModal}
                    >
                      <img
                        src={images.plus_gray_img}
                        style={
                          {
                            width: 16,
                            height: 16,
                          }
                        }
                        alt="hous_thumb"
                      />
                    </Flex>
                  )
                }
                {
thisUserJoinedHouseList?.map(
                (housesHaveUsers: HousesHaveUsersModel) => {
                  if (!housesHaveUsers.House) return
                  return (
                    <Flex
                      key={housesHaveUsers?.House?.id}
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onClick={
                        () =>
                          history.push(
                            `/house/${housesHaveUsers?.House?.linkKey}`,
                          )
                      }
                    >
                      <HouseBadge level={housesHaveUsers.level} />
                      <img
                        src={housesHaveUsers?.House?.thumbnailUrl}
                        style={
                          {
                            width: 48,
                            height: 48,
                            borderRadius: 16,
                            backgroundColor: colors.white_f4,
                          }
                        }
                        alt="hous_thumb"
                      />
                    </Flex>
                  )
                },
              )
                }
              </Flex>
            </Flex>
          ) : (
            <Div height="16px" />
          )
        }

        <PresentStarModal.View
          isShowing={showPresent}
          toggle={togglePresent}
          userId={userDetail?.id}
        />
        <UserFollowInfoModal.View
          isShowing={showFollow}
          toggle={toggleFollow}
          userId={userDetail?.id}
          type={followType.current}
        />
        <HouseEditModal.View
          isShowing={showHoustEditModal}
          toggle={toggleHouseEditModal}
        />
        <OkCancelPopup.View
          isShowing={showDistance}
          toggle={toggleDistance}
          onOk={
            () => {
              updateFollow(userDetail, {
                level: FRIEND_LEVEL['DISTANCE_FRIEND'],
              })
            }
          }
          title="거리두기"
          body={
            <Flex
              type="column"
              justify="center"
              align="center"
              style={{ width: 320 }}
            >
              <ProfileImg
                src={userDetail?.imageUri}
                profileColor={userDetail?.profileColor}
                style={
                  {
                    width: 56,
                    height: 56,
                    borderRadius: '50%',
                    marginBottom: 8,
                  }
                }
              />
              <Caption2>{`${userDetail?.name}님과\n문제가 있으신가요?`}</Caption2>
              <Flex type="column" style={{ marginTop: 24 }}>
                <Flex>
                  <img
                    src={images.forbidden_img_black}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                    alt="forbidden"
                  />
                  <Caption2 align="left" color={colors.grey_94}>
                    {
                      '회원님이 아는 사람을 차단하거나\n팔로우 취소하지 않고도 원하지 않는 교류를\n제한할 수 있습니다.'
                    }
                  </Caption2>
                </Flex>
                <Flex style={{ margin: '14px 0' }}>
                  <img
                    src={images.alarm_img_black}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                    alt="alarm"
                  />
                  <Caption2 align="left" color={colors.grey_94}>
                    상대방의 활동들이 나에게 노출되지 않습니다.
                  </Caption2>
                </Flex>
                <Flex>
                  <img
                    src={images.people_img_black}
                    style={{ width: 24, height: 24, marginRight: 12 }}
                    alt="people"
                  />
                  <Caption2 align="left" color={colors.grey_94}>
                    심심하기에서 매칭되지 않습니다.
                  </Caption2>
                </Flex>
              </Flex>
            </Flex>
          }
        />
        <UserRelationPopup.View
          isMyPage={isMyPage}
          isShowing={showUserRelation}
          toggle={toggleUserRelation}
          shareLink={document.domain}
          onBlock={toggleBlock}
        />
        <DeletePopup.View
          isShowing={showBlock}
          toggle={toggleBlock}
          deleteText="차단"
          title={`${userDetail?.name}을 차단하시겠습니까?`}
          body={
            '사용자를 차단할시 서로의 모든 활동을 숨기고\n 알림을 받지 않습니다\n\n차단 해제는 차단한 유저의 채널 혹은\n내 채널의 옵션에서 할 수 있습니다'
          }
        />
      </Section>
    )
  },
)

export default UserDetailInfoSection

const Section = styled(Flex)`
  max-width: 1064px;
  width: 100%;
  z-index: 2;

  flex-direction: column;
  width: 100%;

  padding-bottom: 24px;
  border-bottom: solid 1px ${colors.grey_d4};
`

const UserImage = styled(ProfileImg)`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  object-fit: cover;

  border: solid 4px #ffffff;
`

const UserHeaderTopBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 7px 17px;
  border-radius: 4px;
  background-color: rgba(36, 36, 36, 0.6);

  font-family: NotoSansCJKkr-Medium;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`

const UserHeaderBottomBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 4px 16px 4px 12px;
  border-radius: 18px;
  background-color: ${colors.white_f4};

  img {
    width: 28px;
    height: 28px;
    object-fit: cover;

    margin-right: 4px;
  }
`

const FollowBtn = styled(Flex)`
position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ followed }) =>
    followed ? colors.white_f4 : colors.primary};

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${({ followed }) => (followed ? colors.grey_94 : colors.white_ff)};
}
`

const ThreeDotMenu = styled(Flex)`
  position: absolute;
  top: 42px;
  left: 0;
  width: 136px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
  z-index: 11;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
`

const DotItem = styled(Flex)`
  font-size: 12px;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: ${colors.black_24};
`
