import React, { useState } from 'react'
// antd
import { message } from 'antd'
import { STORAGE_URL } from '@consts'

import Files from 'react-butterfiles'
import styled from 'styled-components'
import { Caption1, Caption2, Flex } from '@components/atoms'
import { Spinner } from '@components/molecules'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const UploadImg = styled.img`
  width: 32px;
  height: 32px;
`

const ImageUploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #d4d4d4;
  cursor: pointer;

  width: 136px;
  height: 136px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_f4};
`

const SalonImageUploadForm = ({
  // setImageUri,
  imageUri,
  uploadImage,
  style,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Files
      multiple={false}
      maxSize="15mb"
      convertToBase64
      accept={['image/jpg', 'image/png', 'image/jpeg']}
      onSuccess={
        async ([selectedFile]) => {
          setLoading(true)
          try {
            const { file } = selectedFile.src
            const formData = new FormData()
            formData.append('image', file)

            await uploadImage(formData)

            message.success('이미지 업로드 완료')
            // console.log('File was successfully uploaded!')
            setLoading(false)
          }
          catch (e) {
          // console.log('An error occurred!', e.message)
            message.error('이미지 업로드 실패')
            setLoading(false)
          }
        }
      }
      onError={
        errors => {
          message.error(
            errors[0].type === 'maxSizeExceeded'
              ? '15MB를 넘는 파일입니다!'
              : errors[0].type === 'unsupportedFileType'
                ? '지원하지 않은 확장자입니다!'
                : `이미지 업로드 에러 : ${errors[0].type}`,
          )
        }
      }
    >
      {
        ({ browseFiles, getDropZoneProps, getLabelProps }) => (
          <div
            {...getDropZoneProps({ className: 'myDropZone' })}
            style={{ width: '136px' }}
          >
            <ImageUploadBox style={style} onClick={browseFiles}>
              {
                imageUri ? (
                  <img
                    style={
                      {
                        width: '100%',
                        height: '136px',
                        borderRadius: 16,
                        objectFit: 'cover',
                      }
                    }
                    src={STORAGE_URL + imageUri}
                    alt="avatar"
                  />
                ) : loading ? (
                  <>
                    <Spinner
                      type="spinningBubbles"
                      color={colors.primary}
                      width="48px"
                      height="48px"
                    />
                    <Caption1 color="#949494">이미지 파일 업로드 중 ...</Caption1>
                  </>
                ) : (
                  <Flex type="column" align="center">
                    <UploadImg
                      src={images.imgae_upload_img_gray}
                      alt="upload_btn"
                    />
                    <Caption2 style={{ marginTop: 4 }} color={colors.grey_94}>
                  이미지 추가
                    </Caption2>
                  </Flex>
                )
              }
            </ImageUploadBox>
          </div>
        )
      }
    </Files>
  )
}

export default SalonImageUploadForm
