import React, { useEffect, useState } from 'react'
import { CastManageListTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'

const CastManagePage = () => {
  useRenderLogger('CastManagePage')
  const { authStore, userStore, castStore, liveRoomRecordingStore } = useStore()

  // variables
  const currentUser = authStore.currentUser || null
  const myCastList = castStore.myCastList || null
  const myLiveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null
  const myCastListCount = castStore.myCastListCount || null

  // functions
  const fetchMyCastList = castStore.fetchMyCastList || (() => {})
  const updateCast = castStore.updateCast || (() => {})
  const deleteCast = castStore.deleteCast || (() => {})
  const fetchMyUploadedRecordingList =
    liveRoomRecordingStore.fetchMyLiveRoomRecordingList || (() => {})

  const updateUser = userStore.updateUser || (() => {})

  const requestYoutubeSync = async params => {
    return await castStore.requestYoutubeSync(
      { user: authStore?.currentUser },
      params,
    )
  }
  const requestRssSync = async params => {
    return await castStore.requestRssSync(
      { user: authStore?.currentUser },
      params,
    )
  }

  const [currentPage, setCurrentPage] = useState(1)
  const { isLoading, setIsLoading } = useTagLoading()

  /**
   * recording
   */
  const liveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null
  const deleteLiveRoomRecording =
    liveRoomRecordingStore.deleteLiveRoomRecording || (() => {})
  const updateLiveRoomRecording =
    liveRoomRecordingStore.updateLiveRoomRecording || (() => {})

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPage > 1) {
        await fetchMyCastList(currentUser['id'], 10 * (currentPage - 1))
      }
      else if (currentPage <= 1) {
        await fetchMyCastList(currentUser['id'], 0)
      }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyCastList, currentPage, setIsLoading])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchMyUploadedRecordingList({ userId: currentUser['id'] })
      // if (currentPage > 1) {
      //   await fetchMyUploadedRecordingList(10 * (currentPage - 1))
      // }
      // else if (currentPage <= 1) {
      //   await fetchMyUploadedRecordingList()
      // }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyUploadedRecordingList, currentPage, setIsLoading])

  // 곡이 있으면 목록 페이지
  //     없으면 처음 입장 페이지
  if (!myCastList) {
    return <div>error...</div>
  }
  return (
    <CastManageListTemplate
      isLoading={isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      user={currentUser}
      castList={myCastList}
      castListCount={myCastListCount}
      updateCast={updateCast}
      deleteCast={deleteCast}
      fetchMyUploadedRecordingList={fetchMyUploadedRecordingList}
      myLiveRoomRecordingList={myLiveRoomRecordingList}
      requestYoutubeSync={requestYoutubeSync}
      requestRssSync={requestRssSync}
      updateUser={updateUser}
      /**
       * recording
       */
      liveRoomRecordingList={liveRoomRecordingList}
      editLiveRoomRecording={updateLiveRoomRecording}
      deleteLiveRoomRecording={deleteLiveRoomRecording}
    />
  )
}

export default observer(CastManagePage)
