import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Caption1 } from '../typography'

const Label = styled.label`
  display: inline-block;
  font-family: NotoSansCJKkr-Medium;
  font-size: 20px;
  margin-bottom: 16px;
  color: ${props => props.color};

  @media ${mobile} {
    font-size: 16px;
    margin-bottom: 14px;
  }
`

const FormLabel = ({ style, color, children, required, tooltip, htmlFor }) => {
  return (
    <>
      <Label
        style={style && style}
        color={color && color}
        htmlFor={htmlFor && htmlFor}
      >
        {children}
      </Label>
      {tooltip && <Label>{tooltip}</Label>}
      {
        required && (
          <Caption1 type="Regular" color="#949494" style={{ marginLeft: '10px' }}>
          *필수로 입력해주세요.
          </Caption1>
        )
      }
    </>
  )
}

export default FormLabel
