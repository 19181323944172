import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  Flex,
  FormInput,
  H5,
  H6,
  Image,
  Img,
} from '@components/atoms'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import {
  CastListItem,
  ConfirmPopup,
  RssCastUploadPopup,
  YnPopup,
  YoutubeVideoCastUploadPopup,
} from '@components/molecules'
import { images } from '@images/'
import {
  DEFAULT_TAG_LIST,
  NOT_ALLOWED_TAG_LIST,
  RSS_SYNC_ERROR,
  RSS_SYNC_STATE,
  SUBJECT_TAG_LIST,
  YOUTUBE_SYNC_ERROR,
  YOUTUBE_SYNC_STATE,
  YOUTUBE_UPLOAD_TYPE,
} from '@consts/cast'
import { message } from 'antd'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { FeedCard, FeedDetailModal, TagSelectModal } from '..'

const TAG_SELECT_TYPE = {
  RSS: 'RSS',
  YOUTUBE: 'YOUTUBE',
}

const HouseDetailHomeSection = ({
  house,
  isMyPage,
  setSelectedTabIdx,
  initFeedId: _initFeedId,
}) => {
  const [youtubeUploadType, setYoutubeUploadType] = useState(
    YOUTUBE_UPLOAD_TYPE['VIDEO'],
  )
  const [uploadRssLink, setUploadRssLink] = useState('')
  const [uploadYoutubeLink, setUploadYoutubeLink] = useState('')
  const [tagSelectType, setTagSelectType] = useState(null)
  const [rssResultPopupContent, setRssResultPopupContent] = useState(null)
  const [youtubeResultPopupContent, setYoutubeResultPopupContent] = useState(
    null,
  )
  const [
    isShowYoutubeChannelSyncButton,
    setIsShowYoutubeChannelSyncButton,
  ] = useState(house?.['isShowYoutubeChannelSyncButton'] || true)
  const [isShowRssInfoSyncButton, setIsShowRssInfoSyncButton] = useState(
    house?.['isShowRssInfoSyncButton'] || true,
  )
  const [initFeedId, setInitFeedId] = useState(_initFeedId)
  const {
    isShowing: showHouseMember,
    toggle: toggleHouseMember,
  } = YnPopup.usePopup()

  const { authStore, houseStore, castStore } = useStore()
  const history = useHistory()
  const houseId = house?.id

  const {
    isShowing: showTagSelect,
    toggle: toggleTagSelect,
  } = TagSelectModal.useModal()
  const {
    isShowing: showDetail,
    toggle: toggleDetail,
  } = FeedDetailModal.useModal()
  const {
    isShowing: showRssCastUploadPopup,
    toggle: popupRssCastUploadToggle,
  } = RssCastUploadPopup.useModal()
  const {
    isShowing: showYoutubeVideoCastUploadPopup,
    toggle: popupYoutubeVideoCastUploadToggle,
  } = YoutubeVideoCastUploadPopup.useModal()
  const {
    isShowing: showUploadRssResultPopup,
    toggle: popupUploadRssResultToggle,
  } = ConfirmPopup.usePopup()
  const {
    isShowing: showUploadYoutubeResultPopup,
    toggle: popupUploadYoutubeResultToggle,
  } = ConfirmPopup.usePopup()

  const houseFeedList = houseStore.houseFeedList || null
  const houseCastList = houseStore.houseCastList || null

  const requestYoutubeSync = castStore.requestYoutubeSync || (() => {})
  const requestRssSync = castStore.requestRssSync || (() => {})

  const [targetFeedId, setTargetFeedId] = React.useState(null)

  React.useEffect(() => {
    const feedItem =
      houseFeedList
      && houseFeedList.find(
        houseFeed =>
          houseFeed?.id && initFeedId && houseFeed?.id === parseInt(initFeedId),
      )
    if (feedItem) {
      const isHouseMember =
        authStore.currentUser
        && authStore.myDetailJoinedHouseList?.filter(
          _house =>
            _house.id === house?.id
            && _house?._myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'],
        )?.length > 0
        && !(
          !isMyPage
          && feedItem?.House?.isPrivate
          && !feedItem?.House?.isFollowed
        )

      if (feedItem?.isHouseMember && !isHouseMember) {
        toggleHouseMember()
      }
      else {
        const isAuth = authStore.tryAuth()
        if (!isAuth) return

        setTargetFeedId(initFeedId)
        toggleDetail()

        handleLoadDetail(feedItem.id)
      }
      setInitFeedId(null)
    }

    return () => {}
  }, [house, houseFeedList])

  const handleLoadDetail = async id => {
    const isAuth = await authStore.tryAuth()
    if (!isAuth) return

    setTargetFeedId(id)
    toggleDetail()
  }

  const onCastRssUpload = async () => {
    setUploadRssLink('')
    popupRssCastUploadToggle()
  }

  const onCastYoutubeChannelUpload = async () => {
    setUploadYoutubeLink('')
    setYoutubeUploadType(YOUTUBE_UPLOAD_TYPE['CHANNEL'])
    popupYoutubeVideoCastUploadToggle()
  }

  const onRssConvertNext = () => {
    if (uploadRssLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    popupRssCastUploadToggle()
    setTagSelectType(TAG_SELECT_TYPE['RSS'])
    toggleTagSelect()
  }

  const onYoutubeConvertNext = () => {
    if (uploadYoutubeLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }

    popupYoutubeVideoCastUploadToggle()
    setTagSelectType(TAG_SELECT_TYPE['YOUTUBE'])
    toggleTagSelect()
  }

  const onRssCastUpload = async (tagKeywordList = []) => {
    if (uploadRssLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }

    const houseParams = {
      house,
    }

    const param = {
      tagKeywordList: tagKeywordList.map(item => item.keyword),
      rssUrl: uploadRssLink,
    }

    const result = await requestRssSync(houseParams, param)
    setRssResultPopupContent(null)

    if (result.isSuccess) {
      const title = 'RSS 캐스트 연동 신청 완료'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'RSS 링크의 캐스트 변환 등록이 진행중입니다.'}
          </Caption2>
          <div style={{ display: 'flex' }}>
            <Caption2 color={'#242424'}>{'최대 3일'}</Caption2>
            <Caption2 color={'#949494'}>{'까지 소요될 수 있습니다.'}</Caption2>
          </div>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title = 'RSS 캐스트 연동 오류'
      let body = <></>
      switch (RSS_SYNC_ERROR[result.errorLabel]) {
        case RSS_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'RSS 채널 링크가 정확하지 않습니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['DUPLICATED_RSS']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 RSS 링크입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case RSS_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'네트워크가 불안정합니다'}</Caption2>
            </div>
          )
          break
        default:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'서버 오류. 관리자에게 문의하세요.'}
              </Caption2>
            </div>
          )
          break
      }

      setRssResultPopupContent({ title, body })
    }
    else {
      const title = 'RSS 캐스트 연동 오류'
      const body = (
        <div
          style={
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }
          }
        >
          <Caption2 color={'#949494'}>
            {'서버 오류. 관리자에게 문의하세요.'}
          </Caption2>
        </div>
      )
      setRssResultPopupContent({ title, body })
    }

    popupUploadRssResultToggle()
    toggleTagSelect()
  }

  const onYoutubeVideoCastUpload = async (tagKeywordList = []) => {
    if (uploadYoutubeLink.length === 0) {
      message.warn('링크를 입력해주세요.')
      return
    }
    const houseParams = {
      house,
    }
    const param = {
      tagKeywordList,
    }

    if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']) {
      param['youtubeVideoUrl'] = uploadYoutubeLink
    }
    else if (youtubeUploadType === YOUTUBE_UPLOAD_TYPE['CHANNEL']) {
      param['youtubeChannelUrl'] = uploadYoutubeLink
    }

    const result = await requestYoutubeSync(houseParams, param)

    setYoutubeResultPopupContent()
    if (result.isSuccess) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환'
          : '채널 연동 완료'
      const body =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO'] ? (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 동영상이 캐스트로 변환 중입니다.'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#242424'}>{'최대 10분'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        ) : (
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <Caption2 color={'#949494'}>
              {'유튜브 채널의 동영상 캐스트 변환 등록이'}
            </Caption2>
            <div style={{ display: 'flex' }}>
              <Caption2 color={'#949494'}>{'진행중입니다. '}</Caption2>
              <Caption2 color={'#242424'}>{'최대 1일'}</Caption2>
              <Caption2 color={'#949494'}>
                {'까지 소요될 수 있습니다.'}
              </Caption2>
            </div>
          </div>
        )
      setYoutubeResultPopupContent({ title, body })
    }
    else if (result.errorLabel) {
      const title =
        youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
          ? '유튜브 동영상 변환 실패'
          : '채널 연동 실패'
      let body = <></>
      switch (YOUTUBE_SYNC_ERROR[result.errorLabel]) {
        case YOUTUBE_SYNC_ERROR['INVALIDATE_LINK']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크가 정확하지 않습니다.'
                    : '유튜브 채널 링크가 정확하지 않습니다.'
                }
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break

        case YOUTUBE_SYNC_ERROR['DUPLICATED_CHANNEL']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>{'이미 연동된 채널입니다.'}</Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['DUPLICATED_VIDEO']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'이미 연동된 동영상입니다.'}
              </Caption2>
              <Caption2 color={'#949494'}>{'다시 시도해주세요.'}</Caption2>
            </div>
          )
          break
        case YOUTUBE_SYNC_ERROR['SERVER_ERROR']:
          body = (
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#949494'}>
                {'네트워크가 불안정합니다.'}
              </Caption2>
            </div>
          )
          break
        default:
          return
      }

      setYoutubeResultPopupContent({ title, body })
    }

    popupUploadYoutubeResultToggle()
    toggleTagSelect()
  }

  React.useLayoutEffect(() => {
    return () => {
      houseStore.houseFeedInit()
    }
  }, [houseStore, houseId])

  React.useEffect(() => {
    if (isShowRssInfoSyncButton !== house?.['isShowRssInfoSyncButton']) {
      setIsShowRssInfoSyncButton(house?.['isShowRssInfoSyncButton'])
    }
    if (
      isShowYoutubeChannelSyncButton
      !== house?.['isShowYoutubeChannelSyncButton']
    ) {
      setIsShowYoutubeChannelSyncButton(
        house?.['isShowYoutubeChannelSyncButton'],
      )
    }
  }, [house])

  React.useEffect(() => {
    const fetchContentList = async () => {
      await houseStore.fetchFeedList(houseId, {
        // 유저 채널 리스트에서 쓰는 프로퍼티가 적으므로 임시 최적화
        __limit: 4,
        __order: 'publishedAtDesc',
      })

      await houseStore.fetchCastList(houseId)
    }

    if (houseId) {
      fetchContentList()
    }

    // return () => {
    //   houseStore.houseFeedInit()
    // }
  }, [houseStore, houseId])

  if (!house) return <></>
  return (
    <Section>
      {
        !isMyPage && house.isPrivate && !house.isFollowed ? (
          <Flex
            type="column"
            style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
          >
            <H6 color={colors.grey_64}>비공개 계정입니다.</H6>
            <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
            피드를 보고 싶으면 팔로우를 요청하세요
            </Caption1>
          </Flex>
        ) : (
          <>
            {/* 피드 */}
            <Flex justify="space-between" align="center">
              <H5 type="bold">피드</H5>
              {
houseFeedList?.length > 0 && (
                  <Caption1
                    onClick={() => setSelectedTabIdx(1)}
                    color={colors.primary}
                    style={{ cursor: 'pointer' }}
                  >
                더 보기
                  </Caption1>
                )
              }
            </Flex>
            <List>
              {
                houseFeedList && houseFeedList.length > 0 ? (
                  houseFeedList.map((item, index) => {
                    if (index > 3) return true
                    return (
                    // FeedListItem이 Image태그밖에 없어서 임시 컴포넌트
                      <FeedCard
                        from="home"
                        item={item}
                        key={item.id}
                        handleLoadDetail={handleLoadDetail}
                      />
                    )
                  })
                ) : (
                  <Flex
                    type="column"
                    style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
                  >
                    <H6 color={colors.grey_64}>피드가 없습니다</H6>
                    {
                      isMyPage && (
                        <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
                    피드 업로드는 앱에서 하실 수 있습니다
                        </Caption1>
                      )
                    }
                  </Flex>
                )
              }
            </List>
          </>
        )
      }
      {/* 캐스트 */}
      <Flex justify="space-between" align="center">
        <H5 type="bold">캐스트</H5>
        {
houseCastList?.length > 0 && (
            <Caption1
              onClick={() => setSelectedTabIdx(2)}
              color={colors.primary}
              style={{ cursor: 'pointer' }}
            >
            더 보기
            </Caption1>
          )
        }
      </Flex>
      <List>
        {
          houseCastList && houseCastList.length > 0 ? (
            houseCastList.map((item, index) => {
              if (index > 3) return true
              return (
                <CastListItem
                  item={item}
                  key={`${item.id}_${index}`}
                  category="user"
                />
              )
            })
          ) : (
            <Flex
              type="column"
              style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
            >
              <H6 color={colors.grey_64}>캐스트가 없습니다</H6>
              {
                isMyPage && (
                  <Caption1
                    onClick={() => history.push('/p/cast/manage')}
                    color={colors.grey_bf}
                    style={{ cursor: 'pointer', marginTop: 8 }}
                  >
                지금 바로 캐스트를 등록해보세요!
                  </Caption1>
                )
              }
            </Flex>
          )
        }

        {
          isMyPage && (
            <Flex align="center" style={{ flexWrap: 'wrap', gap: 12 }}>
              {
                isMyPage
              && !(
                house['rssInfoSyncState'] === RSS_SYNC_STATE['REQUEST']
                || house['rssInfoSyncState'] === RSS_SYNC_STATE['ACCEPTED']
                || house['rssInfoSyncState'] === RSS_SYNC_STATE['ADMIN']
              )
              && isShowRssInfoSyncButton
              && house['userId'] === authStore.currentUser['id'] && (
                  <RssBtn>
                    <Flex
                      style={
                        {
                          alignItems: 'center',
                        }
                      }
                    >
                      <img
                        style={
                          {
                            width: '24px',
                            height: '24px',
                            marginRight: '8px',
                          }
                        }
                        src={images.rss_img}
                        alt={'RSS 연동'}
                      />
                      <RssSyncButton
                        state={house['rssInfoSyncState']}
                        onClick={() => onCastRssUpload()}
                      />
                      <img
                        onClick={
                          async () => {
                            const result = await houseStore.hideRssInfoSync(house)
                            if (result) {
                              setIsShowRssInfoSyncButton(false)
                            }
                          }
                        }
                        style={
                          {
                            marginLeft: '10px',
                          }
                        }
                        src={images.circle_close_btn_img_gray}
                        alt={'RSS 캐스트 연동 가리기'}
                      />
                    </Flex>
                    <MakeBtn
                      style={{ width: 510, marginTop: 14 }}
                      onClick={() => onCastRssUpload()}
                    >
                      <img
                        src={images.salon_star_img}
                        style={{ width: 24, height: 24 }}
                        alt="star_icon"
                      />
                    별 25개
                    </MakeBtn>
                  </RssBtn>
                )
              }
              {
                isMyPage
            && house['youtubeChannelSyncState']
              !== YOUTUBE_SYNC_STATE['ACCEPTED']
            && house['youtubeChannelSyncState']
              !== YOUTUBE_SYNC_STATE['REQUEST']
            && house['youtubeChannelSyncState']
              !== YOUTUBE_SYNC_STATE['SUCCESS']
            && isShowYoutubeChannelSyncButton
            && house['userId'] === authStore.currentUser['id'] ? (
                    <YoutubeBtn>
                      <Flex style={{ alignItems: 'center' }}>
                        <img
                          style={
                            {
                              width: '24px',
                              height: '24px',
                              marginRight: '8px',
                            }
                          }
                          src={images.youtube_img}
                          alt={'유튜브 채널 캐스트 연동'}
                        />
                        {
                          <YoutubeChannelButton
                            state={house['youtubeChannelSyncState']}
                            onClick={() => onCastYoutubeChannelUpload()}
                          />
                        }
                        <img
                          onClick={
                            async () => {
                              const result = await houseStore.hideYoutubeChannelSync(
                                house,
                              )
                              if (result) {
                                setIsShowYoutubeChannelSyncButton(false)
                              }
                            }
                          }
                          style={{ marginLeft: '10px' }}
                          src={images.circle_close_btn_img_gray}
                          alt={'유튜브 채널 캐스트 연동 가리기'}
                        />
                      </Flex>
                      <MakeBtn
                        style={{ width: 510, marginTop: 14 }}
                        onClick={() => onCastYoutubeChannelUpload()}
                      >
                        <img
                          src={images.salon_star_img}
                          style={{ width: 24, height: 24 }}
                          alt="star_icon"
                        />
                  별 25개
                      </MakeBtn>
                    </YoutubeBtn>
                  ) : (
                    <></>
                  )
              }
            </Flex>
          )
        }
      </List>

      <FeedDetailModal.View
        isShowing={showDetail}
        toggle={toggleDetail}
        feedId={targetFeedId}
        fromHouse
      />
      <RssCastUploadPopup.View
        isShowing={showRssCastUploadPopup}
        toggle={popupRssCastUploadToggle}
        onChange={onRssConvertNext}
        title={'RSS 캐스트 연동'}
        body={
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#242424'}>
                {'운영하고 계시는 팟캐스트 RSS를 입력해주시면,'}
              </Caption2>
              <Caption2 color={'#242424'}>
                {'오디오 콘텐츠를 제공하실 수 있습니다'}
              </Caption2>
            </div>
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '288px',
                  height: '58px',
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  marginTop: '16px',
                }
              }
            >
              <Caption3 color={'#ea4653'}>
                *타인의 저작물 혹은 뮤직비디오를 변환하여 캐스트를 제공할 시
              </Caption3>
              <Caption3 color={'#ea4653'}>
                저작권법에 따라 계정 제한 및 처벌받을 수 있습니다.
              </Caption3>
            </div>

            <FormInput
              style={
                {
                  border: '0px',
                  backgroundColor: '#f4f4f4',
                  marginTop: '16px',
                }
              }
              placeholder={'RSS 링크를 입력해주세요'}
              value={uploadRssLink}
              onChange={e => setUploadRssLink(e.target.value.trim())}
            />
          </div>
        }
      />
      <YoutubeVideoCastUploadPopup.View
        isShowing={showYoutubeVideoCastUploadPopup}
        toggle={popupYoutubeVideoCastUploadToggle}
        onChange={onYoutubeConvertNext}
        title={
          youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
            ? '유튜브 동영상 변환'
            : '유튜브 채널 캐스트 연동'
        }
        body={
          <div
            style={
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              }
            >
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '유튜브 동영상 링크를 입력해주시면,'
                    : '유튜브 채널 링크를 입력해주시면,'
                }
              </Caption2>
              <Caption2 color={'#242424'}>
                {
                  youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                    ? '자동으로 캐스트로 변환하여 등록합니다.'
                    : '전체 동영상을 자동으로 캐스트로 변환하여 등록합니다.'
                }
              </Caption2>
            </div>
            <div
              style={
                {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '288px',
                  height: '58px',
                  backgroundColor: '#fafafa',
                  borderRadius: '8px',
                  marginTop: '16px',
                }
              }
            >
              <Caption3 color={'#ea4653'}>
                *타인의 저작물 혹은 뮤직비디오를 변환하여 캐스트를 제공할 시
              </Caption3>
              <Caption3 color={'#ea4653'}>
                저작권법에 따라 계정 제한 및 처벌받을 수 있습니다.
              </Caption3>
            </div>

            <FormInput
              style={
                {
                  border: '0px',
                  backgroundColor: '#f4f4f4',
                  marginTop: '16px',
                }
              }
              placeholder={
                youtubeUploadType === YOUTUBE_UPLOAD_TYPE['VIDEO']
                  ? '유튜브 동영상 주소 입력'
                  : '유튜브 채널 주소 입력'
              }
              value={uploadYoutubeLink}
              onChange={e => setUploadYoutubeLink(e.target.value.trim())}
            />
          </div>
        }
      />
      <TagSelectModal.View
        isShowing={showTagSelect}
        toggle={toggleTagSelect}
        // mbti={wathcedMbti}
        subjectTagList={SUBJECT_TAG_LIST}
        defaultTagList={DEFAULT_TAG_LIST}
        validate={
          _tag => {
            const isInvalidateTag = NOT_ALLOWED_TAG_LIST.map(
              __tag => __tag.keyword,
            ).includes(_tag)
            if (isInvalidateTag) {
              message.error('해당 태그는 일반 태그로 등록할 수 없습니다.')
              return false
            }
            return true
          }
        }
        onSubmit={
          tagList => {
            if (tagSelectType === TAG_SELECT_TYPE['YOUTUBE']) {
              onYoutubeVideoCastUpload(tagList)
            }
            else if (tagSelectType === TAG_SELECT_TYPE['RSS']) {
              onRssCastUpload(tagList)
            }
          }
        }
      />
      {
        rssResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadRssResultPopup}
            toggle={
              () => {
                popupUploadRssResultToggle()
                window.location.reload()
              }
            }
            title={rssResultPopupContent.title}
            body={rssResultPopupContent.body}
          />
        )
      }
      {
        youtubeResultPopupContent && (
          <ConfirmPopup.View
            isShowing={showUploadYoutubeResultPopup}
            toggle={
              () => {
                popupUploadYoutubeResultToggle()
                window.location.reload()
              }
            }
            title={youtubeResultPopupContent.title}
            body={youtubeResultPopupContent.body}
          />
        )
      }
      {
        <YnPopup.View
          isShowing={showHouseMember}
          toggle={toggleHouseMember}
          title="하우스 멤버 콘텐츠"
          body={'해당 콘텐츠는 멤버만 청취 가능합니다.'}
          yText="멤버 가입하기"
          onOk={
            () => {
              history.push(`/house/${house?.linkKey}`)
            }
          }
        />
      }
    </Section>
  )
}

export default observer(HouseDetailHomeSection)

const Section = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin: 24px 0;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  @media ${mobile} {
    justify-content: center;
  }
`

const MakeBtn = styled(Flex)`
  cursor: pointer;
  height: 32px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${colors.white_ff};
  background: ${colors.primary};
  border-radius: 4px;
`

const RssSyncButton = ({ state = 0, onClick = () => {} }) => {
  switch (state) {
    case RSS_SYNC_STATE['NO']:
      return <Caption1 onClick={onClick}>RSS 캐스트 연동</Caption1>
    case RSS_SYNC_STATE['REQUEST']:
      return (
        <Caption1
          onClick={
            () =>
              message.info(
                'RSS 연동이 요청되었습니다. 관리자 승인 후 연동됩니다.',
              )
          }
        >
          RSS 연동 요청 완료
        </Caption1>
      )
    case RSS_SYNC_STATE['REJECTED']:
      return (
        <Caption1
          onClick={
            () => {
              message.info('올바른 링크로 다시 신청해주세요.')
              onClick()
            }
          }
        >
          RSS 연동 반려
        </Caption1>
      )
    case RSS_SYNC_STATE['ACCEPTED']:
      return (
        <Caption1
          onClick={
            () =>
              message.info('RSS가 연동되었습니다. (완료까지 최대 1일 소요)')
          }
        >
          RSS 연동 완료
        </Caption1>
      )
    case RSS_SYNC_STATE['ADMIN']:
      return (
        <Caption1
          onClick={() => message.info('관리자가 직접 연동한 RSS 입니다.')}
        >
          RSS 관리자 직접 연동
        </Caption1>
      )
    default:
      return <></>
  }
}

const YoutubeChannelButton = ({ state = 0, onClick = () => {} }) => {
  switch (state) {
    case YOUTUBE_SYNC_STATE['NOT_REQUEST']:
      return <Caption1 onClick={onClick}>유튜브 채널 캐스트 연동</Caption1>
    case YOUTUBE_SYNC_STATE['REQUEST']:
      return (
        <Caption1
          onClick={
            () =>
              message.info(
                '채널 연동이 요청되었습니다. 관리자 승인 후 연동됩니다.',
              )
          }
        >
          채널 연동 요청 완료
        </Caption1>
      )
    case YOUTUBE_SYNC_STATE['ACCEPTED']:
      return (
        <Caption1
          onClick={
            () =>
              message.info('채널 연동이 진행중입니다. (최대 1일 소요)')
          }
        >
          채널 연동 진행중..
        </Caption1>
      )
    case YOUTUBE_SYNC_STATE['REJECTED']:
      return (
        <Caption1
          onClick={
            () => {
              message.info('올바른 채널로 다시 신청해주세요.')
              onClick()
            }
          }
        >
          채널 연동 반려
        </Caption1>
      )
    case YOUTUBE_SYNC_STATE['SERVER_ERROR']:
      return (
        <Caption1
          onClick={
            () => {
              message.info(
                '채널 연동중 에러가 발생했습니다. 연동을 다시 신청해주세요.',
              )
              onClick()
            }
          }
        >
          채널 연동 에러
        </Caption1>
      )
    case YOUTUBE_SYNC_STATE['SUCCESS']:
      return (
        <Caption1
          onClick={() => message.info('이미 채널 연동이 완료되었습니다.')}
        >
          유튜브 채널 연동 완료
        </Caption1>
      )
    default:
      return <></>
  }
}

const RssBtn = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 1px;

  width: 526px;
  height: 88px;
  cursor: pointer;

  border-radius: 8px;
  border: solid 1px #d4d4d4;


  color: '#242424'
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`

const YoutubeBtn = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 1px;

  width: 526px;
  height: 88px;
  cursor: pointer;

  border-radius: 8px;
  border: solid 1px #d4d4d4;


  color: '#242424'
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
`
