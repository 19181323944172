import { Flex } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { useMoreHeight } from '@utils/hooks'
import React from 'react'
import styled, { css } from 'styled-components'

const TagList = styled(Flex)`
  max-width: 1146px;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
  flex-wrap: wrap;
  min-height: 50px;
  overflow: hidden;

  ${props =>
    props.haveMore
    && !props.showMore
    && css`
      max-height: 50px;
    `}

  > div {
    margin: 5px 7px;
  }

  > div:first-child,
  > div:last-child {
    margin: 5px 0;
  }
`

const TagBox = styled(Flex)`
  min-width: 54px;
  height: 40px;
  border-radius: 20px;
  border: solid 2px #eff7ff;
  background-color: #eff7ff;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${colors.primary};
  font-size: 14px;
`

const MoreBtn = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 35px;
  cursor: pointer;
`

const MixtapeDetailTagSection = ({ tagList, handleSearchTag }) => {
  const { haveMore, handleShowMore, showMore } = useMoreHeight({
    defaultHeight: 50,
    id: 'tag_box',
    item: tagList,
  })

  // const [haveMore, setHaveMore] = useState(false) // 3줄 이상인지 여부
  // const [showMore, setShowMore] = useState(false) // 더보기 유무

  // const handleShowMore = () => {
  //   setShowMore(!showMore)
  // }

  // // 박스의 높이를 구해서 넘치는(더보기) 유무를 계산
  // useEffect(() => {
  //   const boxHeight = document.getElementById('tag_box').clientHeight
  //   if (boxHeight > 50) {
  //     setHaveMore(true)
  //   }
  // }, [])

  return (
    <Flex
      style={{ position: 'relative', width: '100%', justifyContent: 'center' }}
    >
      <TagList id="tag_box" haveMore={haveMore} showMore={showMore}>
        {
          tagList
          && tagList.map(item => (
            <TagBox
              key={item.id}
              onClick={() => handleSearchTag(item.Tag.keyword)}
            >
              {item.Tag.keyword}
            </TagBox>
          ))
        }
      </TagList>
      {
        haveMore && (
          <MoreBtn
            src={showMore ? images.move_up_gray_img : images.move_down_gray_img}
            alt="more_img"
            onClick={() => handleShowMore()}
          />
        )
      }
    </Flex>
  )
}

export default MixtapeDetailTagSection
