import { Network } from '.'

export default class StarNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getStarHistories = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/stars/histories',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork getStarHistories error: ', err)
    }
  }

  postExchangeStar = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/stars/exchanges',
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork postExchangeStar error: ', err)
      return err
    }
  }

  postPresentStarToLive = async (liveRoomId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/live-rooms/${liveRoomId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork postPresentStarToLive error: ', err)
      return err
    }
  }

  postPresentStarToMixtape = async (mixtapeId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/mixtapes/${mixtapeId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork postPresentStarToMixtape error: ', err)
      return err
    }
  }

  getStarHistoriesFromLive = async (liveRoomId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/live-rooms/${liveRoomId}`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork getStarHistoriesFromLive error: ', err)
      return err
    }
  }

  getStarHistoriesFromMixtape = async (mixtapeId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/mixtapes/${mixtapeId}`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork getStarHistoriesFromMixtape error: ', err)
      return err
    }
  }

  getStarHistoriesFromChannel = async (userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/channels/${userId}`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork getStarHistoriesFromChannel error: ', err)
      return err
    }
  }

  postPresentStarToChannel = async (receivingUserId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/channels/${receivingUserId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork postPresentStarToChannel error: ', err)
      return err
    }
  }

  postPresentStarToCast = async (castId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/casts/${castId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork postPresentStarToCast error: ', err)
      return err
    }
  }

  getStarHistoriesFromCast = async (castId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/stars/donations/casts/${castId}`,
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('StarNetwork getStarHistoriesFromCast error: ', err)
      return err
    }
  }
}
