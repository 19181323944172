// 이메일 검증 정규식
export const verifyEmail = email => {
  const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
  if (email.match(regExp) != null) {
    return false
  }
  if (email !== '') {
    return true
  }
}

// 비밀번호 8자 이상 정규식
export const verifyPw = password => {
  const regExp = /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,}$/
  if (password.match(regExp) != null) {
    return false
  }
  if (password !== '') {
    return true
  }
}

export const isEmptyObject = object => {
  return Object.keys(object).length === 0
}
