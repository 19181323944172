export const desktop = `only screen and (min-width: 961px)`
export const smallDesktop = `only screen and (min-width:481px) and (max-width: 1200px)`
export const mobile = `only screen and (max-width:480px)`

/* deprecated */
export const tablet = `only screen and (min-width: 0px) and (max-width: 0px)`
// export const tablet = `only screen and (min-width: 481px) and (max-width: 960px)`

/* will deprecated */
export const fullHD = `only screen and (min-width: 1801px)`
export const higherDesktop = `only screen and (min-width: 1280px)`
export const mobileAndTablet = `only screen and (max-width: 960px)`
export const mobileDesktop = `only screen and (max-width: 992px)`
export const smallMobile = `only screen and (max-width:380px)`
