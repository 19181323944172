import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { CategoryCastListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import qs from 'qs'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

interface Props {
  match: Any;
  location: Any;
}

/**
 * 전체 || 좋아하는 || 구독한 || 기록 || 검색결과 || 태그 플라 전부 이곳에서.
 * @param {*} param0
 */
const CastCategoryListPage = ({ match, location }: Props) => {
  useRenderLogger('CastCategoryListPage')

  const { castStore, searchStore, tagStore } = useStore()

  const [offset, setOffset] = useState(0)
  const [haveMore, setHaveMore] = useState(true)

  const history = useHistory()

  const handleSearchTag = async tag => {
    // fetch 태그 검색
    await searchStore.searchTagKeyword(tag)
    await searchStore.fetchSearchCastList(tag)
    history.push(`/p/cast/list/search?keyword=${tag}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // 각 카테고리에 맞는 fetch
    const _fetchList = async () => {
      if (match.params.category) {
        const { keyword } = qs.parse(location.search, {
          ignoreQueryPrefix: true,
          // 문자열 맨 앞의 ?를 생력
        })

        let isMore = true
        switch (match.params.category) {
          case 'history':
            await tagStore.fetchPopularTag()
            isMore = await castStore.fetchHistoryCastList()
            setHaveMore(isMore)
            break
          case 'popular':
            isMore = await castStore.fetchPopularCastList()
            setHaveMore(isMore)
            break
          case 'latest':
            isMore = await castStore.fetchLatestCastList()
            setHaveMore(isMore)
            break
          case 'follow':
            isMore = await castStore.fetchFollowCastList()
            setHaveMore(isMore)
            break
          case 'recommendation':
            isMore = await castStore.fetchRecommendationCastList()
            setHaveMore(isMore)
            break
          case 'search':
            searchStore.setMixtapeSearchKeyword(keyword)
            isMore = searchStore.fetchSearchCastList(keyword, {
              limit: 20,
            })
            // searchStore.fetchTagKeywordList(keyword)
            searchStore.fetchTagKeywordList()
            // tagStore.fetchRepresentiveTagList()
            setHaveMore(isMore)
            break
          default:
            break
        }
        setOffset(0)
      }
    }
    _fetchList()
  }, [castStore, searchStore, location, match, tagStore])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return useObserver(() => (
    <CategoryCastListTemplate
      offset={offset}
      setOffset={setOffset}
      haveMore={haveMore}
      setHaveMore={setHaveMore}
      category={match.params.category}
      keyword={searchStore.mixtapeSearchKeyword}
      historyCastList={castStore.historyCastList}
      popularCastList={castStore.popularCastList}
      latestCastList={castStore.latestCastList}
      followCastList={castStore.followCastList}
      searchedCastList={searchStore.searchedCastList}
      recommendationCastList={castStore.recommendationCastList}
      fetchHistoryCastList={castStore.fetchHistoryCastList}
      fetchPopularCastList={castStore.fetchPopularCastList}
      fetchLatestCastList={castStore.fetchLatestCastList}
      fetchFollowCastList={castStore.fetchFollowCastList}
      fetchRecommendationCastList={castStore.fetchRecommendationCastList}
      fetchSearchCastList={searchStore.fetchSearchCastList}
      popularTag={tagStore.popularTag}
      handleSearchTag={handleSearchTag}
    />
  ))
}

export default CastCategoryListPage
