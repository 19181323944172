import { observable } from 'mobx'

export default class FAQModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable title
  @observable content

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.title = props.title
      this.content = props.content
    }
  }
}
