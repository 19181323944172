import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H6, Flex, H5, Caption1 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'
import { images } from '@resources/'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  min-width: 382px;
  min-height: 580px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ModalWrapper = styled.div`
  min-width: 382px;
  outline: 0;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onClose, title, body, foreverClose }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  const _foreverClose = () => {
    foreverClose()
    handleClose()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <Flex
                type="column"
                style={
                  {
                    width: '100%',
                    height: '212px',
                    backgroundColor: '#ea4653',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                }
              >
                <img
                  src={images.caution_img}
                  style={
                    {
                      width: '80px',
                      height: '80px',
                      marginBottom: '8px',
                    }
                  }
                  alt="caution_img"
                />
                <H5
                  type="Bold"
                  color={colors.white_ff}
                  style={{ whiteSpace: 'pre' }}
                >
                    추가적인 전송권 침해가 확인되면
                  <br />
                  {' '}
DJ 자격 영구 박탈 및 수익 소멸 등의
                  <br />
                  {' '}
제재가 있을 수 있습니다.
                </H5>
              </Flex>
              <Flex
                type="column"
                style={
                  {
                    width: '100%',
                    height: '294px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                }
              >
                <H6 color={colors.black_24}>
                    소수의 아티스트, 앨범의 음악으로만
                  <br />
                    이루어진 플라는 아티스트와 저작권자의
                  <br />
                    전송권을 침해합니다.
                </H6>
                <Caption1
                  color={colors.brown_grey}
                  style={{ marginTop: '24px' }}
                >
                    흐름 서비스는 ‘디지털 음성 송신’ 계약에 따라
                  <br />
                    아티스트의 권리를 보호하고,
                  <br />
                    나아가 유저분들에게 다양한 음악적 경험을 드리고자
                  <br />
                    전송권을 침해하지 않는 음악 콘텐츠만을
                  <br />
                    제공하고 있습니다.
                </Caption1>
              </Flex>
              <Flex
                style={
                  {
                    justifyContent: 'space-between',
                    height: '74px',
                    backgroundColor: colors.white,
                    padding: '25px 40px',
                  }
                }
              >
                <H6
                  color={colors.brown_grey}
                  style={{ cursor: 'pointer' }}
                  onClick={_foreverClose}
                >
                    다시 보지 않기
                </H6>
                <H6 style={{ cursor: 'pointer' }} onClick={handleClose}>
                    닫기
                </H6>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
