import { images } from '@images/'
import React from 'react'

const Image = (props: React.ImgHTMLAttributes & React.CSSProperties) => {
  const { src, style, width, height, ...imgProps } = props
  return (
    <img
      {...imgProps}
      src={src || images.default_profile_img}
      style={{ width, height, ...style }}
      alt={`${src}_img`}
    />
  )
}

export default Image
