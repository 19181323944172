import { action, observable } from 'mobx'
import moment from 'moment'
import { Store } from '.'
import { UserModel } from './models'
import { Network } from './networks'

export default class StarStore {
  @observable myStarHistoryList
  @observable userStarRankList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network
    this.init()
  }

  @action init = () => {
    this.myStarHistoryList = []
    this.userStarRankList = []
  }

  @action fetchTotalStarHistories = async ({
    filter,
    date = moment()
      .date(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .utc(),
    lastItem,
  }) => {
    if (lastItem?.isFinished) return []
    let options = {}
    if (lastItem) {
      options = {
        __limit: 20,
        __order: 'changedAtDesc',
        __pageIdValue: lastItem?.id,
        __pageValue: lastItem?.changedAt,
        __pageOrder: 'desc',
        __pageLimit: 20,
        __pageField: 'changedAt',
        changedAt: `${moment(date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')},${moment(date)
          .add(1, 'month')
          .subtract(1, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        changedAtOperator: 'between',
      }
    }
    else {
      options = {
        __order: 'changedAtDesc',
        changedAt: `${moment(date)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')},${moment(date)
          .add(1, 'month')
          .subtract(1, 'milliseconds')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')}`,
        changedAtOperator: 'between',
      }
    }

    switch (filter) {
      case 'all':
        break
      case 'purchased':
        options.type = 1
        break
      case 'exchanged':
        options.type = 2
        break
      case 'gifted':
        options.type = 3
        break
      default:
        break
    }

    const result = await this.network.starNetwork.getStarHistories(options)
    if (!result || result.length === 0) {
      if (lastItem) {
        lastItem.isFinished = true
      }
    }

    this.myStarHistoryList = result

    return result
  }

  @action exchangeStar = async value => {
    if (!value) return false

    const result = await this.network.starNetwork.postExchangeStar(value)

    if (result) this.store.authStore.fetchUserData()

    return result
  }

  /**
   *
   * @param {*} liveRoomId
   * @param {*} options
   * {
        "receivingUserId":19614
        "starValue":100,
        "message":"" // 커스텀 도네이션 메시지
     }
   * @returns
   */
  @action
  presentStarToLive = async (liveRoomId, options) => {
    return this.store.useLoading(async () => {
      if (!liveRoomId || !options) return false

      const result = await this.network.starNetwork.postPresentStarToLive(
        liveRoomId,
        options,
      )

      if (result?.id) {
        this.store.authStore.currentUser.currentStar =
          this.store.authStore.currentUser.currentStar - options.starValue
      }

      return result
    })
  }

  /**
   *
   * @param {*} liveRoomId
   * @param {*} options
   * @returns
   */
  @action
  getStarHistoriesFromLive = async (liveRoomId, options = {}) => {
    if (!liveRoomId) return false

    const result = await this.network.starNetwork.getStarHistoriesFromLive(
      liveRoomId,
      options,
    )

    this.store.liveRoomStore.currLiveRoomDonatedHistoryList = result

    return result
  }

  /**
   *
   * @param {*} mixtapeId
   * @param {*} options
   * {
        "receivingUserId":19614
        "starValue":100,
     }
   * @returns
   */
  @action
  presentStarToMixtape = async (mixtapeId, options) => {
    if (!mixtapeId) return false

    const result = await this.network.starNetwork.postPresentStarToMixtape(
      mixtapeId,
      options,
    )

    return result
  }

  /**
   *
   * @param {*} mixtapeId
   * @param {*} options
   * @returns
   */
  @action
  getStarHistoriesFromMixtape = async (mixtapeId, options = {}) => {
    if (!mixtapeId) return []

    const result = await this.network.starNetwork.getStarHistoriesFromMixtape(
      mixtapeId,
      options,
    )

    return result
  }

  /**
   * 유저에게 별을 선물한 유저리스트
   * @param {*} userId
   * @param {*} options
   * @returns
   */
  @action
  getStarHistoriesFromChannel = async (userId, options = {}) => {
    if (!userId) return []

    const result = await this.network.starNetwork.getStarHistoriesFromChannel(
      userId,
      options,
    )

    if (!result) return

    if (options.__offset === 0) {
      this.userStarRankList = result
        .filter(elem => !!elem)
        .map(elem => {
          return {
            starValue: elem.starValue,
            User: new UserModel(this.store, elem.SendingUser),
          }
        })
    }
    else {
      this.userStarRankList = this.userStarRankList.concat(
        result
          .filter(elem => !!elem)
          .map(elem => {
            return {
              starValue: elem.starValue,
              User: new UserModel(this.store, elem.SendingUser),
            }
          }),
      )
    }

    return result
  }

  /**
   *
   * @param {*} receivingUserId
   * @param {*} options
   * {
        "starValue":100,
     }
   * @returns
   */
  @action
  presentStarToChannel = async (receivingUserId, options) => {
    if (!receivingUserId) return false

    const result = await this.network.starNetwork.postPresentStarToChannel(
      receivingUserId,
      options,
    )

    if (result?.id) {
      this.store.authStore.currentUser.currentStar =
        this.store.authStore.currentUser.currentStar - options.starValue
    }

    return result
  }

  /**
   * 캐스트에 별 선물
   * @param {*} castId
   * @param {*} options
   * {
        "starValue":100,
     }
   * @returns
   */
  @action
  presentStarToCast = async (castId, options) => {
    return this.store.useLoading(async () => {
      if (!castId) return false

      const result = await this.network.starNetwork.postPresentStarToCast(
        castId,
        options,
      )

      if (!result) return

      if (options.comment) {
        await this.store.commentStore.fetchCastReply(castId)
        this.store.castStore.castDetail.commentCount += 1
      }

      if (result?.id) {
        this.store.authStore.currentUser.currentStar =
          this.store.authStore.currentUser.currentStar - options.starValue
      }

      return result
    })
  }

  /**
   * 캐스트에 별을 선물한 유저리스트
   * @param {*} castId
   * @param {*} options
   * @returns
   */
  @action
  getStarHistoriesFromCast = async (castId, options = {}) => {
    if (!castId) return []

    const result = await this.network.starNetwork.getStarHistoriesFromCast(
      castId,
      options,
    )

    return result
  }
}
