export const colors = {
  white: '#fafafa',
  brown_grey: '#949494',
  white_f4: '#f4f4f4',
  white_ff: '#ffffff',
  white_fa: '#fafafa',

  black_24: '#242424',

  primary: '#594ee9',
  secondary: '#0066FF',
  warning: '#ea4653',

  grey_d4: '#d4d4d4',
  grey_64: '#646464',
  grey_94: '#949494',
  grey_bf: '#bfbfbf',
}
