import React, { useEffect } from 'react'
import { API_URL } from '@consts/'

const PaymentForm = ({ paymentParams, submitRef, isOnIframe }) => {
  useEffect(() => {
    if (isOnIframe && paymentParams) {
      submitRef.current.submit()
    }
  }, [isOnIframe, paymentParams, submitRef])

  return (
    <div
      style={
        {
          width: '100%',
          height: '200vh',
          // background: 'rgb(248,248,248)',
          position: 'fixed',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          zIndex: '1000',
        }
      }
    >
      {
        paymentParams && (
          <>
            <iframe
              id="payment-iframe"
              title="플랜 결제하기 - 흐름"
              name="paymentForm"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              style={
                {
                  width: '500px',
                  height: '620px',
                  overflow: 'hidden',
                  position: 'fixed',
                  margin: 0,
                  padding: 0,
                  top: '160px',
                  left: '40%',
                  right: '0',
                  bottom: '0',
                  border: 'none',
                  borderRadius: '16px',
                  boxShadow: '0 5px 14px 0 rgba(0, 0, 0, 0.16)',
                  zIndex: '9999',
                  background: 'white',
                }
              }
            />
            <form
              ref={submitRef}
              id="paymentForm"
              method="POST"
              target="paymentForm"
              action={`${API_URL}/purchases/inicis/request`}
              style={{ display: 'none' }}
            >
              {
                Object.keys(paymentParams).map((key, index) => {
                  return (
                    <input
                      key={index}
                      type="hidden"
                      name={key}
                      value={paymentParams[key]}
                    />
                  )
                })
              }

              <input type="submit" value="결제요청" />
            </form>
          </>
        )
      }
    </div>
  )
}

export default PaymentForm
