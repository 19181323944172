import React from 'react'
import styled from 'styled-components'
import { Modal, H4, Caption1 } from '@components/atoms'
import { RoundBtn, FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'

const ModalBody = styled(FlexRowBox)`
  width: 580px;
  height: 280px;
  flex-direction: column;
`

const View = ({ isShowing, toggle }) => {
  return (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody>
          <H4>포인트 적립 및 환전 신청 중지 상태</H4>
          <Caption1 style={{ margin: '20px 0 60px 0' }} color="#949494">
            흐름(HREUM de salon) 커뮤니티 가이드라인을 위반한 사실이
            확인되어
            <br />
            본 계정은 포인트 적립과 환전 신청이 잠시 중단되었습니다.
            <br />
            <br />
            자세한 내용 확인 및 포인트 적립과 환전 신청의 재개를 원하신다면
            <br />
            고객센터 이메일 info@hreum.me 로 문의 주십시오.
            <br />
          </Caption1>
          <RoundBtn
            text="확인"
            style={
              {
                width: '230px',
                height: '48px',
                border: 'solid 2px #f4f4f4',
                backgroundColor: colors.primary,
                color: '#ffffff',
              }
            }
            onClick={toggle}
          />
        </ModalBody>
      </Modal.View>
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
