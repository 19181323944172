import { Flex } from '@components/atoms'
import { UserReportPopup } from '@components/molecules'
import { SalonChattingSection, SalonInfoSection } from '@components/organisms'
import { colors } from '@resources/colors'
import { mobileDesktop } from '@styles/media'
import React from 'react'
import styled from 'styled-components'
import { PageLayout } from '..'

interface ISalonDetailTemplate {
  sendLiveRoomUserFeedback: Function;
  sendLiveRoomFeedback: Function;
}

const SalonDetailTemplate: React.FC<ISalonDetailTemplate> = ({
  sendLiveRoomUserFeedback,
  sendLiveRoomFeedback,
  toggleSalon,
  toggleSalonMixtape,
}) => {
  const {
    isShowing: showUserReport,
    toggle: toggleUserReport,
  } = UserReportPopup.usePopup()

  const [reason, setReason] = React.useState(0)
  const targetInfo = React.useRef(null)
  const [isSwitchOn, setIsSwitchOn] = React.useState(false)

  const _toggleUserReport = (type, userId, roomId, roomTitle) => {
    targetInfo.current = { type, userId, roomId, roomTitle }
    toggleUserReport()
  }

  const handleReportUser = async (userId, reason) => {
    if (!targetInfo || !reason) return

    let _reason = ''
    if (reason === 1)
      _reason =
        targetInfo.current.type === 'user'
          ? '부적절한 채팅 내용'
          : '부적절한 살롱 주제'
    else _reason = '커뮤니티 가이트라인 위반'

    const message = `종류: ${targetInfo.current.type} \n사유: ${_reason} \n살롱id: ${targetInfo.current.roomId} \n살롱 제목: ${targetInfo.current.roomTitle} userId: ${targetInfo.current.userId}`

    if (targetInfo.current.type === 'user')
      await sendLiveRoomUserFeedback(userId, _reason, message)
    else await sendLiveRoomFeedback(targetInfo.current.roomId, _reason, message)
  }

  return (
    <PageLayout
      style={{ paddingTop: 0, backgroundColor: colors.white_f4 }}
      rootStyle={{ backgroundColor: colors.white_f4 }}
    >
      <Content>
        <SalonChattingSection
          _toggleUserReport={_toggleUserReport}
          setIsSwitchOn={setIsSwitchOn}
          toggleSalonMixtape={toggleSalonMixtape}
          toggleSalon={toggleSalon}
        />
        <SalonInfoSection
          _toggleUserReport={_toggleUserReport}
          isSwitchOn={isSwitchOn}
          setIsSwitchOn={setIsSwitchOn}
          toggleSalonMixtape={toggleSalonMixtape}
        />
      </Content>
      <UserReportPopup.View
        isShowing={showUserReport}
        toggle={toggleUserReport}
        onOk={handleReportUser}
        onCancel={() => console.log('onCancel')}
        userId={targetInfo.current?.userId}
        type={targetInfo.current?.type}
        reason={reason}
        setReason={setReason}
      />
    </PageLayout>
  )
}

export default SalonDetailTemplate

const Content = styled(Flex)`
  max-width: 1194px;
  width: 100%;
  margin: 32px 0px;

  > div + div {
    margin-left: 24px;
  }

  @media ${mobileDesktop} {
    flex-direction: column;

    > div + div {
      margin-left: 0px;
      margin-top: 24px;
    }
  }
`
