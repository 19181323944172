import { Caption1, Caption2, Div, Flex, ProfileImg } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { mobileDesktop } from '@styles/media'
import { useStore, useWindowSize } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AppDownloadModal } from '..'
import CastUploadPopup from './CastUploadPopup'

type ItemProps = {
  title: string,
  subTitle?: string,
  path: string,
  imgSrc: string,
  ative: boolean,
  onClick: any,
  isNav: boolean,
  isLogin: boolean,
  href: string,
}
type Props = {}

const HouseBadge = ({ level = 0 }) => {
  let levelImage = images.house_member_icon_img
  switch (level) {
    case 40:
      levelImage = images.salon_host_img
      break
    case 30:
      levelImage = images.salon_host_img
      break
    case 20:
      levelImage = images.house_member_icon_img
      break
    default:
      levelImage = images.house_follower_icon_img
      break
  }

  return (
    <Flex
      style={
        {
          position: 'absolute',
          bottom: -4,
          right: -4,
          backgroundColor: colors.white_f4,
          borderRadius: '50%',
          width: 16,
          height: 16,
        }
      }
    >
      <img
        src={levelImage}
        alt="house_level_icon"
        style={{ width: 14, height: 14, position: 'absolute', top: 1, left: 1 }}
      />
    </Flex>
  )
}

const SidebarItem: React.FC<ItemProps> = ({
  title,
  subTitle,
  path,
  imgSrc,
  active,
  onClick,
  isNav,
  isLogin = true,
  href,
}) => {
  return (
    <>
      {
        isLogin && (
          <MenuItem
            className={
              `${active ? 'active_menu_item' : ''} ${
                subTitle ? 'show-overflow' : ''
              }`
            }
            onClick={onClick}
            active={active}
            icon={
              <img
                src={
                  images[`sidebar_${active ? 'active' : 'inactive'}_${imgSrc}`]
                }
                style={{ width: 20, height: 20 }}
                alt={`icon_${path}`}
              />
            }
          >
            {
              !href ? (
                <Div display="flex" alignItems="center">
                  {title}
                  {
                    subTitle && (
                      <Caption2 style={{ marginLeft: 4 }}>{`| ${subTitle}`}</Caption2>
                    )
                  }
                </Div>
              ) : (
                href && (
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'black' }}
                  >
                    <Div display="flex" alignItems="center">
                      {title}
                      {
                        subTitle && (
                          <Caption2 style={{ marginLeft: 4 }}>
                            {`| ${subTitle}`}
                          </Caption2>
                        )
                      }
                    </Div>
                  </a>
                )
              )
            }

            {isNav && <NavLink to={path} />}
          </MenuItem>
        )
      }
    </>
  )
}

const Sidebar: React.FC<Props> = () => {
  const location = useLocation()

  const { appStateStore, authStore, houseStore } = useStore()
  const showSidebar = appStateStore.showSidebar || false
  const toggleSidebar = appStateStore.toggleSidebar || false
  const currentUser = authStore.currentUser || null

  const isMd = useWindowSize() <= 992

  const [openSubMenu, setOpenSubMenu] = useState('none')
  const [showMoreMyHouseList, setShowMoreMyHouseList] = useState(false)

  const {
    isShowing: showAppDownload,
    toggle: toggleAppDownload,
  } = AppDownloadModal.useModal()

  useEffect(() => {
    if (isMd && !appStateStore.showSidebar) {
      appStateStore.toggleShowSidebar()
    }
    if (!isMd && !appStateStore.toggleSidebar) {
      appStateStore.toggleShowSidebar()
    }
  }, [appStateStore, isMd])

  useEffect(() => {
    if (
      location.pathname.includes('/p/cast/manage')
      || location.pathname.includes('/p/audio')
      || location.pathname.includes('/p/dj/mixtape')
      || location.pathname.includes('/p/dj/song')
      || location.pathname.includes('/p/exchange')
      || location.pathname.includes('/user')
    ) {
      setOpenSubMenu('myChannel')
    }
    else if (
      location.pathname.includes('/p/cast-house/manage')
      || location.pathname.includes('/house')
    ) {
      setOpenSubMenu(`house_${houseStore?.currentHouse?.id}`)
    }
    else {
      setOpenSubMenu('myChannel')
    }
  }, [location.pathname, houseStore, houseStore.currentHouse])

  const {
    isShowing: showCastUploadPopup,
    toggle: popupCastUploadToggle,
  } = CastUploadPopup.useModal()

  return (
    <>
      <CustomSidebar
        toggled={toggleSidebar} // 반응 포인트 활성화때, 펼쳐짐 유무
        breakPoint="lg" // 반응 포인트
        collapsed={showSidebar} // 접힘 유무
        width="236px" // 펼쳐진 상태의 넓이
        collapsedWidth="0px" // 접힌 상태의 넓이
      >
        <SidebarContent>
          <Menu>
            {
              currentUser && (
                <SubMenu
                  onClick={
                    () => {
                      // if (openSubMenu === 'myChannel') setOpenSubMenu('none')
                    }
                  }
                  open={openSubMenu === 'myChannel'}
                  defaultOpen={openSubMenu === 'myChannel'}
                  onOpenChange={
                    open => {
                      // if(open) {
                      if(openSubMenu !== 'myChannel'){
                        setOpenSubMenu('myChannel')
                      }
                      else if(openSubMenu === 'myChannel'){
                        setOpenSubMenu('none')
                      }
                    }
                  }
                  title={currentUser?.name}
                  icon={
                    <ProfileImg
                      src={currentUser?.imageUri}
                      profileColor={currentUser?.profileColor}
                      style={
                        {
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          border: `solid 1px ${colors.white_f4}`,
                        }
                      }
                    />
                  }
                >
                  <MenuItem
                    active={location.pathname.includes('/user')}
                    className={
                      location.pathname.includes('/user')
                    && 'active_menu_item'
                    }
                  >
                  내 채널
                    <NavLink to={`/user/${currentUser?.account}`} />
                  </MenuItem>
                  <MenuItem
                    active
                    className={'inactive_menu_item'}
                    onClick={
                      () => {
                        popupCastUploadToggle()
                        setOpenSubMenu('myChannel')
                      }
                    }
                  >
                  캐스트 등록
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes('/p/cast/manage')}
                    className={
                      location.pathname.includes('/p/cast/manage')
                    && 'active_menu_item'
                    }
                  >
                  녹음 관리
                    <NavLink to="/p/cast/manage" />
                  </MenuItem>
                  {
                  // <MenuItem
                  //   active={location.pathname.includes('/p/audio')}
                  //   className={
                  //     location.pathname.includes('/p/audio') && 'active_menu_item'
                  //   }
                  // >
                  // 오디오 관리
                  //   <NavLink to="/p/audio" />
                  // </MenuItem>
                  }
                  <MenuItem
                    active={
                      location.pathname.includes('/p/dj/mixtape')
                    || location.pathname.includes('/p/dj/song')
                    }
                    className={
                      (location.pathname.includes('/p/dj/mixtape')
                      || location.pathname.includes('/p/dj/song'))
                    && 'active_menu_item'
                    }
                  >
                  플라 관리
                    <NavLink to="/p/dj/mixtape/list" />
                  </MenuItem>
                  <MenuItem
                    active={location.pathname.includes('/p/exchange')}
                    className={
                      location.pathname.includes('/p/exchange')
                    && 'active_menu_item'
                    }
                  >
                  포인트 환전
                    <NavLink to="/p/exchange/confirm-password" />
                  </MenuItem>
                </SubMenu>
              )
            }
            <SidebarItem
              title="홈"
              path="/"
              imgSrc="home"
              active={location.pathname === '/'}
              isNav
            />
            <SidebarItem
              // isLogin={!!currentUser}
              title="살롱"
              subTitle="라이브 오디오"
              path="/p/salon/list"
              imgSrc="salon"
              active={location.pathname.includes('/p/salon')}
              isNav
            />
            <SidebarItem
              // isLogin={!!currentUser}
              title="캐스트"
              subTitle="팟캐스트"
              path="/p/cast/list"
              imgSrc="cast"
              active={
                location.pathname.includes('/p/cast/list')
              }
              isNav
            />
            {
              currentUser ? (
                <SidebarItem
                  isLogin={!!currentUser}
                  title="플라"
                  subTitle="플레이리스트 라디오"
                  path="/p/plra/list"
                  imgSrc="mixtapes"
                  active={
                    location.pathname.includes('/p/plra')
                  && !location.pathname.includes('/p/dj')
                  }
                  isNav
                />
              ) : (
                <SidebarItem
                  title="플라"
                  subTitle="플레이리스트 라디오"
                  path="/p/plra/list/total/guest"
                  imgSrc="mixtapes"
                  active={
                    location.pathname.includes('/p/plra')
                  && !location.pathname.includes('/p/dj')
                  }
                  isNav
                />
              )
            }
          </Menu>
          <Br />
          {
authStore.myDetailJoinedHouseList?.length > 0 && (
              <Menu>
                <Caption1
                  color={colors.grey_64}
                  style={{ marginBottom: 16, paddingLeft: 30 }}
                >
                참여 중인 하우스
                </Caption1>
                {
authStore.myDetailJoinedHouseList?.map((house, index) => {
  if (index >= 0 && index < 10) {
    return (
      <SubMenu
        onClick={
          () => {
            if (openSubMenu === `house_${house?.id}`)
              setOpenSubMenu('none')
          }
        }
        open={openSubMenu === `house_${house?.id}`}
        defaultOpen={openSubMenu === `house_${house?.id}`}
        onOpenChange={
          open =>
            open && setOpenSubMenu(`house_${house?.id}`)
        }
        key={`${house?.id}_1`}
        title={house?.title}
        icon={
          <Div
            width="32px"
            height="32px"
            borderRadius="50%"
            position="relative"
          >
            <ProfileImg
              src={house?.imageUrl}
              profileColor={house?.profileColor}
              style={
                {
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  border: `solid 1px ${colors.white_f4}`,
                }
              }
            />
            <HouseBadge level={house?._myLevel} />
            {
house?.isHouseNewContents > 0 && (
                <Div
                  width="5px"
                  height="5px"
                  borderRadius="50%"
                  backgroundColor="red"
                  position="absolute"
                  top="0px"
                  right="-4px"
                />
              )
            }
          </Div>
        }
      >
        <MenuItem
          active={
            location.pathname.includes(
              `/house/${house?.linkKey}`,
            )
          }
          className={
            location.pathname.includes(
              `/house/${house?.linkKey}`,
            ) && 'active_menu_item'
          }
          onClick={
            async () => {
              if (house?.isHouseNewContents > 0) {
                const res = await houseStore.createHouseConnect(
                              house?.id,
                )
                if (res) {
                  house.isHouseNewContents = 0
                }
              }
            }
          }
        >
                        채널
          <NavLink to={`/house/${house?.linkKey}`} />
        </MenuItem>
        <MenuItem
          active={
            location.pathname.includes(
              `/p/cast-house/manage/${house?.id}`,
            )
          }
          className={
                          house?.contentPermissionLevel > house?._myLevel
                            ? 'disable_menu_item'
                            : location.pathname.includes(
                              `/p/cast-house/manage/${house?.id}`,
                            ) && 'active_menu_item'
          }
        >
                        캐스트 관리
          <NavLink to={`/p/cast-house/manage/${house?.id}`} />
        </MenuItem>
      </SubMenu>
    )
  }
  if (
                  authStore.myDetailJoinedHouseList?.length > 5
                  && !showMoreMyHouseList
  ) {
    if (index !== 10) return
    return (
      <Flex
        key="more"
        onClick={() => setShowMoreMyHouseList(true)}
        style={
          {
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            width: 200,
            height: 40,
            backgroundColor: colors.white_fa,
            color: colors.grey_64,
            margin: '8px',
            fontSize: 14,
          }
        }
      >
                      더 보기
      </Flex>
    )
  }
  if (showMoreMyHouseList && index >= 10) {
    return (
      <>
        <SubMenu
          onClick={
            () => {
              if (openSubMenu === `house_${house?.id}`)
                setOpenSubMenu('none')
            }
          }
          open={openSubMenu === `house_${house?.id}`}
          defaultOpen={openSubMenu === `house_${house?.id}`}
          onOpenChange={
            open =>
              open && setOpenSubMenu(`house_${house?.id}`)
          }
          key={`${house?.id}_2`}
          title={house?.title}
          icon={
            <Div
              width="32px"
              height="32px"
              borderRadius="50%"
              position="relative"
            >
              <ProfileImg
                src={house?.imageUrl}
                profileColor={house?.profileColor}
                style={
                  {
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    border: `solid 1px ${colors.white_f4}`,
                  }
                }
              />
              <HouseBadge level={house?._myLevel} />
            </Div>
          }
        >
          <MenuItem
            active={
              location.pathname.includes(
                `/house/${house?.linkKey}`,
              )
            }
            className={
              location.pathname.includes(
                `/house/${house?.linkKey}`,
              ) && 'active_menu_item'
            }
          >
                          채널
            <NavLink to={`/house/${house?.linkKey}`} />
          </MenuItem>
          <MenuItem
            active={
              location.pathname.includes(
                `/p/cast-house/manage/${house?.id}`,
              )
            }
            className={
                            house?.contentPermissionLevel > house?._myLevel
                              ? 'disable_menu_item'
                              : location.pathname.includes(
                                `/p/cast-house/manage/${house?.id}`,
                              ) && 'active_menu_item'
            }
          >
                          캐스트 관리
            <NavLink to={`/p/cast-house/manage/${house?.id}`} />
          </MenuItem>
        </SubMenu>
        {
authStore.myDetailJoinedHouseList?.length - 1
                        === index && (
            <Flex
              onClick={() => setShowMoreMyHouseList(false)}
              style={
                {
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 200,
                  height: 40,
                  backgroundColor: colors.white_fa,
                  color: colors.grey_64,
                  margin: '8px',
                  fontSize: 14,
                }
              }
            >
                          간략히 보기
            </Flex>
          )
        }
      </>
    )
  }
})
                }
                <Br />
              </Menu>
            )
          }
          <Menu>
            <SidebarItem
              isLogin={!!currentUser}
              title="별"
              path="/p/star/main"
              imgSrc="star"
              active={location.pathname.includes('/p/star')}
              isNav
            />
            <SidebarItem
              isLogin={!!currentUser}
              title="플랜"
              path="/p/plan"
              imgSrc="plan"
              active={location.pathname === '/p/plan'}
              isNav
            />
          </Menu>
          {currentUser && <Br />}
          <Menu>
            <SidebarItem
              title="공지사항"
              path="/p/notice/list"
              imgSrc="notice"
              active={location.pathname.includes('/p/notice')}
              isNav
            />
            <SidebarItem
              title="자주하는 질문"
              path="/p/faq"
              imgSrc="faq"
              active={location.pathname === '/p/faq'}
              isNav
            />
            <SidebarItem
              title="서비스 소개"
              path="/service"
              imgSrc="service"
              active={location.pathname === '/service'}
              href="https://hreum.kr"
            />
            <SidebarItem
              title="앱 다운로드"
              path="/download"
              imgSrc="download"
              active={location.pathname === '/download'}
              onClick={toggleAppDownload}
            />
          </Menu>
        </SidebarContent>
      </CustomSidebar>
      <AppDownloadModal.View
        isShowing={showAppDownload}
        toggle={toggleAppDownload}
      />
      <CastUploadPopup.View
        showCastUploadPopup={showCastUploadPopup}
        popupCastUploadToggle={popupCastUploadToggle}
      />
    </>
  )
}

export default observer(Sidebar)

const CustomSidebar = styled(ProSidebar)`
  position: sticky;
  top: 56px;
  height: calc(100vh - 56px);
  /* height: auto; */

  @media ${mobileDesktop} {
    top: 50px;
    height: -webkit-fill-available;
  }

  .pro-sidebar-inner {
    background-color: white;
    border-right: solid 1px #f4f4f4;
  }

  // 메뉴 하나
  .pro-menu-item {
    height: 48px;
    padding: 4px 8px;
    margin: 4px 0;
    box-sizing: border-box;
    font-size: 14px;
  }

  .pro-menu-item > .pro-inner-item {
    padding: 8px !important;
  }

  .show-overflow > .pro-inner-item > .pro-item-content {
    text-overflow: initial !important;
    overflow: visible !important;
  }

  .pro-inner-item > .pro-item-content {
    flex: 0.8 !important;
  }

  .pro-inner-item > .pro-icon-wrapper {
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  // 서브메뉴 높이 늘어나는 현상 유지
  .pro-sub-menu {
    height: initial;
  }

  .pro-sub-menu > .pro-inner-list-item {
    background-color: #ffffff !important;
  }

  .pro-inner-item {
    height: 40px;
  }

  .pro-item-content {
    color: #242424;
  }

  .active_menu_item .pro-inner-item {
    border-radius: 8px;
    background-color: #f8f8ff;

    .pro-item-content {
      color: #594ee9;
    }
  }
`

const Br = styled.div`
  height: 1px;
  width: 100%;
  background-color: #f4f4f4;
`
