import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 0.875rem;
  text-align: left;
  color: #646464;
  max-width: 978px;
  margin: 0 auto 0 auto;
  line-height: 25px;
  word-break: keep-all;
`

const P = styled.p`
  margin-top: 15px;
`

const SubscribeMsg = ({ style }) => {
  return (
    <Wrapper style={style}>
      <P>서비스 이용 주의사항</P>
      <P>
        국내에서 결제 완료된 흐름(HREUM) 프리미엄 서비스 플랜은
        국내에서만 이용하실 수 있습니다.
      </P>
      <P>
        해외 서비스 대상국가가 아닌 지역에서는 서비스 이용이 불가하며, 일부
        콘텐츠는 저작권자의 별도 요청, 저작권 문제, 기타 사업권역 문제 등으로
        국내 혹은 해외 서비스 이용이 사전 예고 없이 제한될 수 있습니다.
        <br />
        흐름(HREUM) 서비스에서 유튜브 등의 제3자 서비스로 연결되는
        콘텐츠의 경우 광고 삭제가 불가능하며 제3자 서비스의 사정에 따라 콘텐츠
        이용에 제한이 있을 수 있습니다.
      </P>
      <P>서비스 이용 권장사양</P>
      <P>
        PC : windows와 Mac 최신버젼 권장(웹 브라우저: 크롬 최신버젼 권장)
        <br />
        스마트폰 : Android 4.0 이상 | iOS 11.0 이상
        <br />
        태블릿 : Android 4.0 이상 | iOS 11.0 이상
      </P>
      <P>이용권 구매 주의사항</P>
      <P>모든 이용권은 부가세(VAT)포함 가격입니다.</P>
      <P>
        상품 가격과 플랜 내용은 내부 정책으로 인해 적절한 공지와 함께 변경 될 수
        있습니다.
      </P>
      <P>
        플랜을 변경하거나 결제 수단을 변경하기 위해서는 플랜을 취소하고 만료
        예정일 이후에 원하는 플랜 혹은 결제 수단으로 다시 결제하셔야 합니다.
      </P>
      <P>
        자동결제는 이용 플랜에 따라 1개월 단위 혹은 3개월 단위로 이루어지므로
        구매하신 날과 매월 혹은 3개월 단위의 동일한 날짜에 결제가 이루어집니다.
        자동결제 시 결제 당일을 제외한 이용기간 중 언제든지 자동결제를 해지하실
        수 있습니다.
        <br />
        자동결제 플랜을 해지할 경우 더 이상 추가 결제되지 않으며 남은 기간
        동안은 계속 이용하실 수 있습니다.
      </P>
      <P>
        모든 종류의 할인 프로모션 상품은 사전 예고 없이 종료 될 수 있습니다.
        또한 인증 등을 통해 할인받을 수 있는 다양한 플랜 상품은 할인 혜택이
        적용되어 있으므로 서비스팀 내부 규정에 따른 다른 추가혜택 적용이 어려울
        수 있습니다.
      </P>
      <P>
        통신사, 신용카드 정보 변경 및 기타 결제수단의 잔액부족 시에는 결제실패로
        자동결제가 해지될 수 있습니다.
      </P>
      <P>
        고객센터를 통한 자동결제해지 신청은 본인만 가능합니다.
        <br />
        모든 흐름(HREUM)프리미엄 상품은 선불결제 상품으로 이미
        결제하여 구매 및 사용하신 이용권에 대해서는 결제취소 및 환불되지
        않습니다.
      </P>
    </Wrapper>
  )
}

export default SubscribeMsg
