import {
  Caption1,
  Div,
  Flex,
  Image,
  ImageIcon,
  Img,
  ProfileImg,
} from '@components/atoms'
import {
  YnPopup,
  ConfirmPopup,
  SalonPasswordPopup,
} from '@components/molecules'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle, twoEllipsisStyle } from '@styles/fontStyles'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  item: any,
  onClick: Function,
}

const SalonCard: React.FC<Props> = ({ item }) => {
  const { authStore, houseStore } = useStore()
  const history = useHistory()

  const {
    isShowing: showPassword,
    toggle: togglePassword,
  } = SalonPasswordPopup.usePopup()

  const {
    isShowing: showHouseMember,
    toggle: toggleHouseMember,
  } = YnPopup.usePopup()

  const {
    isShowing: showSocial,
    toggle: toggleSocial,
  } = ConfirmPopup.usePopup()

  const {
    isShowing: showLoginNeed,
    toggle: toggleLoginNeed,
  } = ConfirmPopup.usePopup()

  const textColor = item?.imageUrl ? colors.white_ff : colors.black_24

  const isMyHouseLiveRoom =
    authStore.currentUser
    && authStore.myDetailJoinedHouseList?.filter(
      house =>
        house.id === item?.houseId
        && house?._myLevel >= HOUSES_HAVE_USERS_LEVEL['MEMBER'],
    )?.length > 0

  const onClick = async () => {
    // const isAuth = await authStore.tryAuth()
    // if (!isAuth) return
    // setDynamicLink(_dynamicLink)

    history.push({
      pathname: `/salon/${item.linkKey}`,
      state: { isPassword: true },
    })
  }

  const handleClick = async () => {
    let myHouseLevel;
    if(item?.houseId){
      myHouseLevel = await houseStore.fetchMyHouseLevel(item?.houseId)
    }

    if (item.isPrivate && item.password) {
      togglePassword()
    }
    else if (item?.isHouseMember && myHouseLevel < HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
      toggleHouseMember()
    }
    else if (item?.isSocial && !authStore.currentUser) {
      /** 비회원 소셜 접근시 */
      toggleSocial()
    }
    else if (item?.isBlockedUnknownUser && !authStore.currentUser) {
      toggleLoginNeed()
    }
    else {
      onClick()
    }
  }

  const micCount = item?.LiveRoomsHaveUsers?.filter(
    user => user.level >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'],
  )?.length

  const currentPlaySong =
    item?.Mixtape?.MixtapesHaveSongs[item?.Mixtape?.songPlayedIndex]?.Song

  return (
    <>
      <Card onClick={handleClick}>
        {
item?.imageUrl && (
            <>
              <BlurBackground />
              <BackgroundImage
                src={item.imageUrl}
                style={
                  {
                    backgroundColor: colors.white_f4,
                  }
                }
              />
            </>
          )
        }
        <Content>
          <Flex style={{ width: '100%', height: '72px', marginBottom: 16 }}>
            {
              item.imageUrl && (
                <SalonImage src={item.imageUrl} style={{ marginRight: 16 }} />
              )
            }
            <Flex type="column" style={{ width: '100%', height: '100%' }}>
              {
                ((item.LiveRoomsHaveTags && item.LiveRoomsHaveTags.length > 0)
                || item?.House) && (
                  <TopicList>
                    {
item?.House && (
                        <Caption1 color={textColor}>
                          <Img
                            src={images.house_icon_img}
                            width="16px"
                            height="16px"
                            marginBottom="-2px"
                            marginRight="2px"
                          />
                          {item?.House?.title}
                        </Caption1>
                      )
                    }
                    {
                      item.LiveRoomsHaveTags && item.LiveRoomsHaveTags.length > 0 && (
                        <Caption1
                          color={textColor}
                          style={{ textAlign: 'left', ...oneEllipsisStyle }}
                        >
                          {
                            item.LiveRoomsHaveTags.map(
                              tag => `#${tag.Tag.keyword} `,
                            )
                          }
                        </Caption1>
                      )
                    }
                  </TopicList>
                )
              }
              <Flex align="center">
                {
                  !!item?.isHouseMember && (
                    <Div
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="18px"
                      height="18px"
                      borderRadius="50%"
                      backgroundColor="rgba(36, 36, 36, 0.56)"
                      marginRight="4px"
                    >
                      <Img
                        src={
                        item?.imageUrl
                          ? images.house_type_member_icon
                          : images.house_type_member_icon_yellow
                        }
                        width="16px"
                        height="16px"
                      />
                    </Div>
                  )
                }
                {
                  !!item?.isPrivate && (
                    <Image
                      src={images.lock_img_red}
                      alt="lock_img"
                      width="20px"
                      height="20px"
                      style={{ marginRight: 4 }}
                    />
                  )
                }
                {
                  !!item?.isSocial && (
                    <ImageIcon
                      name="liveRoomSocialType"
                      width="20px"
                      height="20px"
                      marginRight="4px"
                    />
                  )
                }
                <Caption1
                  color={textColor}
                  type="Medium"
                  align="left"
                  style={twoEllipsisStyle}
                >
                  {item.title}
                </Caption1>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="flex-start" align="center" style={{ width: '100%' }}>
            {
              item.LiveRoomsHaveUsers
            && item.LiveRoomsHaveUsers.length > 0
            && item.isPrivate ? (
                  <UserImageList>
                    <UserImage
                      src={item.LiveRoomsHaveUsers[0]['User']['imageUri']}
                      profileColor={
                        item.LiveRoomsHaveUsers[0]['User']['profileColor']
                      }
                      index={0}
                    />
                  </UserImageList>
                ) : (
                  <UserImageList>
                    {
                      item.LiveRoomsHaveUsers.map((user, index) =>
                        index === 3 ? (
                          <UserMoreImage
                            key={`${item.id}_${user.User.id}_${index}`}
                            index={index}
                          >
                            {`${item.LiveRoomsHaveUsers.length - 3}+`}
                          </UserMoreImage>
                        ) : (
                          index < 4 && (
                            <UserImage
                              src={user.User.imageUri}
                              profileColor={user.User.profileColor}
                              key={`${item.id}_${user.User.name}_${index}`}
                              index={index}
                            />
                          )
                        ),
                      )
                    }
                  </UserImageList>
                )
            }
            {
              item.LiveRoomsHaveUsers
            && item.LiveRoomsHaveUsers.length > 0
            && item.isPrivate ? (
                  <Caption1 style={oneEllipsisStyle} color={textColor}>
                    {item.LiveRoomsHaveUsers[0]['User']['name']}
                  </Caption1>
                ) : (
                  <Caption1 style={oneEllipsisStyle} color={textColor}>
                    {
                      item.LiveRoomsHaveUsers.map(
                        (user, idx) =>
                          `${user.User.name}${
                            idx + 1 !== item.LiveRoomsHaveUsers.length ? ',' : ''
                          }`,
                      )
                    }
                  </Caption1>
                )
            }
          </Flex>
          <Flex
            justify="space-between"
            style={
              {
                position: 'absolute',
                left: 16,
                bottom: 16,
                width: 'calc(100% - 32px)',
                right: 16,
              }
            }
          >
            <Flex align="center">
              <Img
                src={
                  item?.imageUrl ? images.mic_img_white : images.mic_img_gray
                }
                marginRight="4px"
                width="16px"
                height="16px"
              />
              <Caption1 style={{ marginBottom: 2 }} color={textColor}>
                {micCount || 0}
              </Caption1>
              <Img
                src={
                  item?.imageUrl
                    ? images.person_img_white
                    : images.person_img_gray
                }
                margin="0 4px 0 8px"
                width="16px"
                height="16px"
              />
              <Caption1 style={{ marginBottom: 2 }} color={textColor}>
                {item?.LiveRoomsHaveUsers?.length || 0}
              </Caption1>
            </Flex>
            {
              currentPlaySong && (
                <Flex>
                  <Caption1 color={textColor} style={oneEllipsisStyle}>
                    {`${currentPlaySong?.singer} - ${currentPlaySong?.name}`}
                  </Caption1>
                </Flex>
              )
            }
          </Flex>
        </Content>
      </Card>
      <SalonPasswordPopup.View
        isShowing={showPassword}
        toggle={togglePassword}
        salonPassword={item.password}
        onOk={onClick}
      />
      {
item?.isHouseMember && (
          <YnPopup.View
            isShowing={showHouseMember}
            toggle={toggleHouseMember}
            title="하우스 멤버 콘텐츠"
            body={'해당 콘텐츠는 멤버만 청취 가능합니다.'}
            yText="멤버 가입하기"
            onOk={
              () => {
                history.push(`/house/${item?.House?.linkKey}`)
              }
            }
          />
        )
      }
      {
item?.isSocial && (
          <ConfirmPopup.View
            isShowing={showSocial}
            toggle={toggleSocial}
            title="소셜 콘텐츠"
            body={'해당 콘텐츠는 함께 팔로우해야 청취 가능합니다.'}
          // yText="멤버 가입하기"
          // onOk={
          //   () => {
          //     // history.push(`/house/${item?.House?.linkKey}`)
          //   }
          // }
          />
        )
      }
      {
item?.isBlockedUnknownUser && (
          <ConfirmPopup.View
            isShowing={showLoginNeed}
            toggle={toggleLoginNeed}
            title="비로그인 제한"
            body={'해당 콘텐츠는 로그인해야 청취 가능합니다.'}
          />
        )
      }
    </>
  )
}

export default observer(SalonCard)

const Card = styled(Flex)`
  position: relative;
  flex-direction: column;
  width: 384px;
  height: 192px;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white_ff};
  cursor: pointer;
  overflow: hidden;
`

const Content = styled(Flex)`
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 3;
`

const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 192px;
  min-height: 192px;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 2;
`

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 192px;
  min-height: 192px;
  height: 100%;

  object-fit: cover;
  z-index: 1;
`

const SalonImage = styled.img`
  width: 72px;
  height: 72px;
  object-fit: contain;
  border-radius: 12px;
`

const TopicList = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;

  overflow: hidden;
  margin-bottom: 2px;
  gap: 8px;
`

const UserImageList = styled(Flex)`
  position: relative;
  width: auto;
  margin-right: 10px;
  height: 24px;
`

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  /* position: absolute; */

  margin-left: ${({ index }) => (index === 0 ? 0 : -12)}px;
  border: solid 1px #f4f4f4;
`

const UserMoreImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Bold;
  font-size: 12px;
  color: #646464;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 1px #f4f4f4;
  background-color: #f4f4f4;

  margin-left: ${({ index }) => (index === 0 ? 0 : -12)}px;
`
