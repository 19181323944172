import React, { useEffect } from 'react'
import { WarningUserPopup } from '@components/molecules'
import { CastListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { isMobile } from 'react-device-detect'

const CastListPage = () => {
  useRenderLogger('CastListPage')

  const { castStore, authStore } = useStore()

  const { isShowing, toggle } = WarningUserPopup.usePopup()

  useEffect(() => {
    if (authStore.warningHistories && authStore.warningHistories.length > 0) {
      if (!authStore.warningHistories[0].isReaded) {
        toggle()
      }
    }

    // eslint-disable-next-line
  }, [authStore])

  useEffect(() => {
    castStore.fetchPopularCastList()
    castStore.fetchLatestCastList()
    castStore.fetchFollowCastList()
    castStore.fetchHistoryCastList()
    castStore.fetchRecommendationCastList()
  }, [])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return (
    <>
      <CastListTemplate
        popularCastList={castStore.popularCastList}
        latestCastList={castStore.latestCastList}
        followCastList={castStore.followCastList}
        historyCastList={castStore.historyCastList}
        recommendationCastList={castStore.recommendationCastList}
      />
      <WarningUserPopup.View
        isShowing={isShowing}
        toggle={toggle}
        foreverClose={authStore.foreverClose}
      />
    </>
  )
}

export default observer(CastListPage)
