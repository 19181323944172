import React from 'react'
import { MixtapeAddForm } from '@components/organisms'
import styled from 'styled-components'
import { mobileAndTablet } from '@styles/media'
import { observer } from 'mobx-react'
import { Tooltip } from '@components/molecules'
import { DJ_LEVEL } from '@consts/'
import { MIXTAPE_WARNING_STATUS } from '@consts/'
import { images } from '@resources/'
import { PageLayout } from '..'
import { colors } from '@resources/colors'

const TooltipContent = styled.div`
  width: 600px;
  padding: 16px;
  color: ${colors.secondary};
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
  text-align: left;
`

const MixtapeWarning = styled.p`
  position: sticky;
  top: 57px;
  width: calc(100% + 413px);
  height: 60px;
  background-color: #aa0000;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;

  @media ${mobileAndTablet} {
    width: 100%;
  }
`

const CautionMsg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 150px;
  width: 100%;
  height: 60px;
  background-color: #ea4653;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;

  @media ${mobileAndTablet} {
    top: 70px;
  }
`

const AccountDjMsg = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 57px;
  width: calc(100% + 413px);
  height: 60px;
  background-color: #f4f4f4;
  color: #242424;
  font-size: 14px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;

  @media ${mobileAndTablet} {
    top: 70px;
  }
`

const DjMixtapeAddTemplate = ({
  currentUser,
  _getYoutubeLink,
  setIsUploaded,
  uploadMixtapeImage,
  autoCompleteTagList,
  fetchAutocompleteTags,
  tempMixtape,
  addMixtapeSongListToTempMixtape,
  replaceMixtapeSongListToTempMixtape,
  deleteMixtapeSongToTempMixtape,
  createMixtape,
  applyMixtapeForDJ,
  setIsCompleted,
  isUpdated,
  updateMixtape,
}) => {
  return (
    <>
      <PageLayout style={{ paddingTop: 0 }}>
        {currentUser && currentUser.discJockeyLevel < DJ_LEVEL['APPLICANT'] ? (
          <>
            <AccountDjMsg>
              구성한 플라는 DJ심사에만 활용되며 심사 완료 전까지 앱에 노출되지
              않습니다.
              <Tooltip
                style={{ position: 'absolute', right: 10 }}
                content={
                  <TooltipContent>
                    보통 24시간 내에 DJ승인 혹은 반려 절차가 완료됩니다.
                    <br />
                    또한 DJ 신청이 원활하게 진행되기 위해서는 다음과 같은 조건을
                    준수해야 합니다.
                    <br />
                    <br />
                    1. 1시간 이상의 플라' 구성하기
                    <br />
                    DJ 승인을 위해 심사 자료로 활용할 수 있는 '플라'를 전체 재생
                    길이 1시간 이상으로 구성하여 제출해야 합니다.
                    <br />
                    *단, 같은 노래를 중복하여 연속으로 삽입하거나, 극단적으로
                    적은 수의 가수 혹은 앨범의 곡 만으로 구성하거나, 혹은
                    지나치게 연속된 동일 가수, 앨범의 노래로 구성된 플라는
                    심사가 불가능합니다.
                    <br />
                    흐름 서비스 이용자들이 다양한 음악을 즐길 수 있도록
                    도와주세요.
                    <br />
                    <br />
                    2. 적절한 제목과 설명, 그리고 대표 이미지
                    <br />
                    심사를 위한 플라의 오디오 구성이
                    끝났다면 제목, 설명 그리고 대표 이미지를 입력해야 합니다.
                    무드, 분위기, 기분, 상황 등 플라를
                    구성할 때 염두해 두었던 생각을 잘 표현할 수 있는 정보를
                    입력하십시오.
                    <br />
                    *단, 국내외 저작권법에 위배되는 정보가 포함되어 있을 경우
                    경고 없이 플라가 삭제 및 비공개 처리될 수 있음을
                    안내드립니다. (특정 가수명, 앨범명 등을 언급하며 플라 청취의
                    선곡 리스트를 제시하는 등)
                    <br />
                    또한 개인적 청취의 목적으로 오해할 수 있는 제목, 설명의
                    플라는 콘텐츠 다양성을 위해 피해 주십시오. (ex. ‘내가
                    들으려고 만든 플라’ 이런 제목 혹은 설명은 반려될 수
                    있습니다.)
                    <br />
                    <br />
                    흐름 서비스는 DJ 신청 결과에 대한 피드백을 모든 이용자에게
                    이메일과 앱 알림으로 보내드리고 있습니다.
                    <br />
                    만약 위 2개의 조건을 모두 충족하였는데도 별다른 피드백 없이
                    DJ 승인이 연기되고 있다면, '내 채널->문의하기' 버튼을 통해
                    서비스팀에 문의 부탁드립니다.
                    <br />
                    <br />위 내용은 내 채널 => 옵션 버튼 => 고객센터 의
                    FAQ에서도 확인하실 수 있습니다.
                  </TooltipContent>
                }
              />
            </AccountDjMsg>
            {tempMixtape && tempMixtape.totalPlayTime < 1800 && (
              <MixtapeWarning style={{ zIndex: '1', top: '118px' }}>
                *총 재생시간 30분 이상의 플라만 공개 및 제출 가능합니다.
              </MixtapeWarning>
            )}
          </>
        ) : (
          <>
            {tempMixtape && tempMixtape.totalPlayTime < 1800 && (
              <MixtapeWarning>
                *총 재생시간 30분 이상의 플라만 공개 및 제출 가능합니다.
              </MixtapeWarning>
            )}
            {tempMixtape &&
              tempMixtape.warningStatus <
                MIXTAPE_WARNING_STATUS.NOT_WARNING && (
                <CautionMsg
                  style={{
                    top:
                      tempMixtape &&
                      tempMixtape.totalPlayTime >= 1800 &&
                      '90px',
                  }}
                >
                  <img
                    src={images.caution_img}
                    alt="caution_img"
                    style={{ width: '40px', height: '40px' }}
                  />
                  전송권 침해 이력이 있습니다. 추가적인 전송권 침해가 확인되면
                  DJ 자격 영구 박탈 및 수익 소멸 등의 제재가 있을 수 있습니다.
                </CautionMsg>
              )}
          </>
        )}
        <MixtapeAddForm
          currentUser={currentUser}
          uploadMixtapeImage={uploadMixtapeImage}
          autoCompleteTagList={autoCompleteTagList}
          fetchAutocompleteTags={fetchAutocompleteTags}
          tempMixtape={tempMixtape}
          addMixtapeSongListToTempMixtape={addMixtapeSongListToTempMixtape}
          replaceMixtapeSongListToTempMixtape={
            replaceMixtapeSongListToTempMixtape
          }
          deleteMixtapeSongToTempMixtape={deleteMixtapeSongToTempMixtape}
          createMixtape={createMixtape}
          applyMixtapeForDJ={applyMixtapeForDJ}
          setIsCompleted={setIsCompleted}
          isUpdated={isUpdated}
          updateMixtape={updateMixtape}
        />
      </PageLayout>
    </>
  )
}

export default observer(DjMixtapeAddTemplate)
