import { Network } from '..'

export default class SearchNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getSearchSongs = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/searchs/songs`,
        'get',
        params,
      )
      return data || []
    }
    catch (err) {
      console.log('SearchNetwork getSearchSongs error: ', err)
    }
  }

  getSearchMixtapes = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/mixtapes',
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('SearchNetwork getSearchMixtapes', error)
    }
  }

  getTagMixtapePopularList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/tags/mixtapes/popular-tag-hreums',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('SearchNetwork getTagMixtapePopularList error: ', err)
    }
  }

  getSearchTag = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/tags',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchTag', error)
    }
  }

  getSearchTagsMixtapesLatest = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/tags/mixtapes/latest',
        'get',
        params,
      )
      return data.Mixtapes || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchTagsMixtapesLatest', error)
    }
  }

  getSearchTagsMixtapesPopular = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/tags/mixtapes/popular-tag-hreums',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchTagsMixtapesPopular', error)
    }
  }

  getSearchsCasts = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/casts',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchsCasts', error)
    }
  }

  getSearchsSalonUsers = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/salon-users',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchsSalonUsers', error)
    }
  }

  getSearchsHouses = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/houses',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchsHouses', error)
    }
  }

  getSearchsSalons = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/salons',
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('SearchNetwork getSearchsSalons', error)
    }
  }
}
