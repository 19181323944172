import React, { useState } from 'react'
import { Form, FormLabel, H6 } from '@components/atoms'
import styled from 'styled-components'
import { SubmitBtn } from '@components/molecules'
import { useForm } from 'react-hook-form'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import AdultUploadForm from './upload/AdultUploadForm'

const FormGroup = styled.div`
  margin: 10px 0px;
  width: 100%;
`

const Download = styled.a`
  margin: 40px 0 0 0;
  font-family: NotoSansCJKkr-Medium;
  color: ${colors.secondary};

  display: flex;
  justify-content: center;
  align-items: center;
`

const pdfFile = require('@resources/file/privacy_consent_20210118.pdf')

const AdultAuthForm = ({
  postAdultCertification,
  setComplete,
  accountRevenueLicense,
}) => {
  const [fileUrl, setFileUrl] = useState(null)
  const [fileUri, setFileUri] = useState(null)
  const { handleSubmit } = useForm()

  const onSubmit = async data => {
    if (!fileUri) return
    const params = [
      {
        fileType: 0,
        fileUri,
        type: 0,
        responseState: 0,
      },
    ]
    const res = await postAdultCertification(params)
    if (res) {
      const res2 = await accountRevenueLicense()
      if (res2) {
        setComplete(true)
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* <FormGroup>
        <FormLabel color="#646464">성인 인증 방법 선택</FormLabel>
        <Controller
          as={
            <SelectBox
              options={[
                { value: 1, label: '주민등록증 사진 제출' },
                { value: 2, label: '미성년자 보호자 동의서 제출' },
              ]}
            />
          }
          id="fileType"
          name="fileType"
          placeholder="인증 방법 선택"
          control={control}
          onChange={([selected]) => {
            return selected
          }}
          defaultValue={null}
          rules={{ required: true }}
        />
      </FormGroup> */}
      <Download href={pdfFile} download>
        <H6>개인정보제공 동의서 다운로드</H6>
        <img src={images.move_img} alt="nextBtn" />
      </Download>
      <FormGroup style={{ marginTop: '40px' }}>
        <FormLabel color="#646464">동의서 업로드</FormLabel>
        <AdultUploadForm
          fileUrl={fileUrl}
          fileUri={fileUri}
          setFileUrl={setFileUrl}
          setFileUri={setFileUri}
        />
      </FormGroup>
      <SubmitBtn
        style={{ marginTop: '50px' }}
        type="submit"
        disabled={!fileUri}
      >
        제출하기
      </SubmitBtn>
    </Form>
  )
}

export default AdultAuthForm
