import {
  Caption1,
  Flex,
  FormGroup,
  FormInput,
  FormLabel,
  H3,
  InvisibleInput,
  WarningMsg,
} from '@components/atoms'
import { UserModel } from '@stores/models'
import React, { useEffect, useState } from 'react'
import { MbtiModal, ProfileUploadForm } from '@components/organisms'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { OkCancelPopup, SubmitBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import { colors } from '@resources/colors'
import { ERROR_CODE } from '@consts/'
import { PageLayout } from '..'

type Props = {
  currentUser: UserModel,
  getCheckEmail: Function,
  getCheckName: Function,
  checkPassword: Function,
  updateUser: Function,
  uploadImage: Function,
}

// Todo: FormLabel을 아래 스타일로 디자인을 통일할 예정 ( 진서님과 상의후, FormLabel 수정 )
const CustomFormLabel = props => {
  const { children, ...labelProps } = props
  return (
    <FormLabel {...labelProps} style={{ fontSize: 12, marginBottom: 8 }}>
      {children}
    </FormLabel>
  )
}

const MyInfoTemplate: React.FC<Props> = ({
  currentUser,
  getCheckEmail,
  checkPassword,
  getCheckName,
  updateUser,
  uploadImage,
}) => {
  const history = useHistory()

  const [imageUri, setImageUri] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [checkConfirm, setCheckConfirm] = useState(false) // 비밀번호 확인 체크
  const [confirmEmail, setConfirmEmail] = useState(false) // 이메일 중복 체크
  const [confirmName, setConfirmName] = useState(false) // 이메일 중복 체크
  const [accountError, setAccountError] = useState({ msg: '', isError: false })

  const {
    isShowing: showConfirmPrivate,
    toggle: toggleConfirmPrivate,
  } = OkCancelPopup.usePopup()

  const {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
  })

  const { isShowing: showMbti, toggle: toggleMbti } = MbtiModal.useModal()

  const wathcedMbti = watch('mbti')
  const watchedIsPrivate = watch('isPrivate')

  // 새 비밀번호 확인
  const confirmPw = value => {
    if (value === getValues().newPassword) {
      setCheckConfirm(false)
    }
    else {
      setCheckConfirm(true)
    }
  }

  // 닉네임 중복 체크
  const _verifyName = async () => {
    if (currentUser.name === getValues().name) {
      setConfirmName('Y')
      return
    }
    const check = await getCheckName(getValues().name)
    if (check && check.length === 0) {
      setConfirmName('Y')
    }
    else if (check && check.length > 0) {
      setConfirmName('N')
    }
  }

  // 이메일 중복 체크
  const _verifyEmail = async () => {
    if (currentUser.email === getValues().email) {
      setConfirmEmail('Y')
      return
    }
    const check = await getCheckEmail(getValues().email)
    if (check && check.length === 0) {
      setConfirmEmail('Y')
    }
    else if (check && check.length > 0) {
      setConfirmEmail('N')
    }
  }

  const onSubmit = async data => {
    if (currentUser.isExistPassword && data.newPassword && checkConfirm) return
    if (data.name && confirmName === 'N') return
    if (data.email && confirmEmail === 'N') return

    if (currentUser.isExistPassword) {
      const checked = await checkPassword(data.password)

      if (checked.status && checked.status >= 400) {
        alert('비밀번호가 일치하지 않습니다.')
        return
      }
    }

    const params = {}

    if (data.name && data.name !== currentUser.name) params.name = data.name
    if (data.account && data.account !== currentUser.account)
      params.account = data.account
    if (data.email && data.email !== currentUser.email)
      params.email = data.email
    if (imageUri && currentUser.imageKey !== imageUri)
      params.imageUri = imageUri
    if (currentUser.isExistPassword && data.newPassword)
      params.password = data.newPassword
    if (data.organization) params.organization = data.organization
    if (data.duty) params.duty = data.duty
    if (data.mbti) params.mbti = data.mbti
    params.isPrivate = data.isPrivate === 'Y'

    try {
      await updateUser(currentUser, params)

      alert('정보 수정이 완료되었습니다.')
      history.push(`/user/${currentUser?.account}`)
    }
    catch (e) {
      if (e?.response?.status === 409) {
        if (
          e?.response?.data?.errorCode
          === ERROR_CODE['USERS_ACCOUNT_NOT_ALLOWED_UPDATED_AT']
        ) {
          setAccountError({
            msg: '마지막으로 계정을 변경 한 후 2주 뒤에 변경할 수 있습니다',
            isError: true,
          })
        }
        else if (
          e?.response?.data?.errorCode
          === ERROR_CODE['USERS_ACCOUNT_DUPLICATED']
        ) {
          setAccountError({
            msg: '이미 존재하는 계정입니다',
            isError: true,
          })
        }
        else if (
          e?.response?.data?.errorCode
          === ERROR_CODE['USERS_ACCOUNT_NOT_ALLOWED_CHARACTERS']
        ) {
          setAccountError({
            msg: '허용되지 않는 특수문자 혹은 글자수 초과입니다',
            isError: true,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (currentUser) {
      setImageUri(currentUser.imageKey)
      setImageUrl(currentUser.imageUri)
      setValue([
        { name: currentUser.name },
        { account: currentUser.account },
        { email: currentUser.email },
        { organization: currentUser.organization },
        { duty: currentUser.duty },
        { mbti: currentUser.mbti },
        { isPrivate: currentUser.isPrivate ? 'Y' : 'N' },
      ])
    }
  }, [currentUser, setValue])

  return (
    <PageLayout>
      <H3 style={{ marginBottom: 48 }}>개인정보 수정</H3>
      <ProfileUploadForm
        imageUrl={imageUrl}
        setImageUri={setImageUri}
        setImageUrl={setImageUrl}
        uploadImage={uploadImage}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputBox>
          <FormGroup>
            <CustomFormLabel htmlFor="nickname">닉네임</CustomFormLabel>
            <CustomFormInput
              id="nickname"
              placeholder="닉네임"
              name="name"
              ref={register({ required: true })}
              onBlur={() => _verifyName()}
            />
            {
              confirmName === 'N' && (
                <WarningMsg>이미 존재하는 닉네임입니다</WarningMsg>
              )
            }
          </FormGroup>
          <FormGroup>
            <CustomFormLabel htmlFor="account">계정</CustomFormLabel>
            <CustomFormInput
              id="account"
              placeholder="계정"
              name="account"
              ref={register({ required: true })}
              onChange={
                e => {
                  setAccountError({ isError: false, msg: '' })
                }
              }
              // onBlur={() => _verifyName()}
            />
            {
accountError?.isError && (
                <WarningMsg>{accountError?.msg}</WarningMsg>
              )
            }
          </FormGroup>
          {// 카카오 회원이 아닐 때,
            !currentUser.isKakaoAccount && (
              <FormGroup>
                <CustomFormLabel>가입 이메일</CustomFormLabel>
                <CustomFormInput
                  placeholder="가입 이메일"
                  disabled={currentUser?.isKakaoAccount}
                  name="email"
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  onBlur={() => _verifyEmail()}
                />
                {
                  errors.email && errors.email.type === 'pattern' && (
                    <WarningMsg>이메일 형식이 올바르지 않습니다</WarningMsg>
                  )
                }
                {
                  confirmEmail === 'N' && (
                    <WarningMsg>이미 사용중인 이메일입니다</WarningMsg>
                  )
                }
              </FormGroup>
            )
          }
          {// 비밀번호가 있는 회원일 때,
            currentUser.isExistPassword && (
              <>
                <FormGroup>
                  <CustomFormLabel htmlFor="password">
                  기존 비밀번호
                  </CustomFormLabel>
                  <CustomFormInput
                    id="password"
                    placeholder="기존 비밀번호"
                    name="password"
                    type="password"
                    ref={
                      register({
                        required: true,
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
                      })
                    }
                  />
                  {
                    errors.password && errors.password.type === 'required' && (
                      <WarningMsg>비밀번호를 입력해주세요</WarningMsg>
                    )
                  }
                  {
                    errors.password && errors.password.type === 'minLength' && (
                      <WarningMsg>비밀번호 8자 이상 입력해주세요</WarningMsg>
                    )
                  }
                  {
                    errors.password && errors.password.type === 'pattern' && (
                      <WarningMsg>영문, 숫자 조합으로 입력해주세요</WarningMsg>
                    )
                  }
                </FormGroup>
                <FormGroup>
                  <CustomFormLabel htmlFor="newPassword">
                  새 비밀번호
                  </CustomFormLabel>
                  <CustomFormInput
                    id="newPassword"
                    placeholder="새 비밀번호 (숫자, 문자 포함 8자 이상)"
                    disabled={!currentUser.isExistPassword}
                    name="newPassword"
                    type="password"
                    ref={
                      register({
                        required: false,
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
                      })
                    }
                    onBlur={() => confirmPw(getValues().confirmPassword)}
                  />
                  {
                    errors.newPassword
                  && errors.newPassword.type === 'minLength' && (
                      <WarningMsg>비밀번호 8자 이상 입력해주세요</WarningMsg>
                    )
                  }
                  {
                    errors.newPassword
                  && errors.newPassword.type === 'pattern' && (
                      <WarningMsg>영문, 숫자 조합으로 입력해주세요</WarningMsg>
                    )
                  }
                </FormGroup>
                <FormGroup>
                  <CustomFormLabel htmlFor="confirmPassword">
                  비밀번호 확인
                  </CustomFormLabel>
                  <CustomFormInput
                    id="confirmPassword"
                    placeholder="비밀번호 확인"
                    disabled={!currentUser.isExistPassword}
                    name="confirmPassword"
                    type="password"
                    ref={register({ required: false, minLength: 8 })}
                    onBlur={e => confirmPw(e.target.value)}
                    style={checkConfirm ? { border: '1px solid #ea4653' } : {}}
                  />
                  {
                    checkConfirm && (
                      <WarningMsg>비밀번호가 일치하지 않습니다.</WarningMsg>
                    )
                  }
                </FormGroup>
              </>
            )
          }
          <FormGroup>
            <Flex style={{ width: '100%' }}>
              <FormGroup>
                <CustomFormLabel htmlFor="organization">소속</CustomFormLabel>
                <CustomFormInput
                  id="organization"
                  placeholder="소속"
                  name="organization"
                  ref={register({ required: false })}
                />
              </FormGroup>
              <FormGroup style={{ margin: '0 8px' }}>
                <CustomFormLabel htmlFor="duty">직무</CustomFormLabel>
                <CustomFormInput
                  id="duty"
                  placeholder="직무"
                  name="duty"
                  ref={register({ required: false })}
                />
              </FormGroup>
              <FormGroup>
                <CustomFormLabel htmlFor="asd3">MBTI</CustomFormLabel>
                <InvisibleInput name="mbti" ref={register()} />
                <MbtiBtn onClick={toggleMbti}>
                  {getValues().mbti ? getValues().mbti : '유형 선택'}
                </MbtiBtn>
              </FormGroup>
            </Flex>
            <FormGroup>
              <InvisibleInput name="isPrivate" ref={register()} />
              <Caption1
                color={colors.primary}
                style={{ cursor: 'pointer' }}
                onClick={toggleConfirmPrivate}
              >
                {watchedIsPrivate === 'N' ? '비공개' : '공개'}
                {' '}
계정으로 전환
              </Caption1>
            </FormGroup>
          </FormGroup>
          <Flex
            style={{ marginTop: 30, width: '100%', justifyContent: 'flex-end' }}
          >
            <SubmitBtn
              type="submit"
              disabled={
                !getValues().name
                || (!currentUser.isKakaoAccount && !getValues().email)
                || (currentUser.isExistPassword && !getValues().password)
                || (currentUser.isExistPassword && errors.password)
                || errors.email
                || errors.name
              }
              style={
                {
                  borderRadius: 4,
                  width: 80,
                  height: 36,
                  padding: 0,
                  fontSize: 14,
                }
              }
            >
              저장
            </SubmitBtn>
            <SubmitBtn
              type="button"
              onClick={() => history.goBack()}
              style={
                {
                  borderRadius: 4,
                  border: `solid 1px ${colors.grey_d4}`,
                  backgroundColor: colors.white_ff,
                  color: colors.grey_d4,
                  width: 80,
                  height: 36,
                  padding: 0,
                  fontSize: 14,
                  marginLeft: 16,
                }
              }
            >
              취소
            </SubmitBtn>
          </Flex>
        </InputBox>
      </Form>
      <MbtiModal.View
        isShowing={showMbti}
        toggle={toggleMbti}
        mbti={wathcedMbti}
        setValue={mbti => setValue([{ mbti }])}
      />
      <OkCancelPopup.View
        isShowing={showConfirmPrivate}
        toggle={toggleConfirmPrivate}
        onOk={
          () => {
            setValue('isPrivate', watchedIsPrivate === 'Y' ? 'N' : 'Y')
          }
        }
        title={`${watchedIsPrivate === 'N' ? '비공개' : '공개'} 계정으로 전환`}
        body={
          watchedIsPrivate === 'N'
            ? `비공개 게정으로 전환하시면
        내가 맞팔로우를 한 유저에게만
        자신의 피드와 친구들을 공개합니다`
            : `공개 계정으로 전환하시면
        모든 유저에게만 자신의 피드와
        친구들을 공개합니다`
        }
      />
    </PageLayout>
  )
}

export default MyInfoTemplate

const Form = styled.form`
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const InputBox = styled.div`
  max-width: 520px;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CustomFormInput = styled(FormInput)`
  font-size: 12px;
  padding: 10px;
`

const MbtiBtn = styled(Flex)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 40px;
  font-size: 12px;
  border-radius: 4px;
  border: solid 1px #646464;
  background-color: #ffffff;
  cursor: pointer;
`
