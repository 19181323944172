import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'

const MsgBox = styled.div`
  font-family: NotoSansCJKkr-Medium;
  font-size: 1rem;
  color: #646464;
  text-align: center;

  @media ${mobile} {
    font-size: 0.75rem;
  }
`

const AgainMsg = ({ style }) => {
  return (
    <MsgBox style={{ style }}>
      플랜 변경 혹은 결제 수단 변경은 이용중인 플랜 취소 후<br />
      만료 예정일 이후에 다시 결제 하셔야합니다
    </MsgBox>
  )
}

export default AgainMsg
