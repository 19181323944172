import React, { useEffect, useState } from 'react'
import { DjMixtapeAddTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { Redirect } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useRenderLogger, useStore } from '@utils/hooks'

interface Props {
  location: Any;
}

const DjMixtapeAddPage = ({ location }: Props) => {
  useRenderLogger('DjMixtapeAddPage')
  const { authStore, mixtapeStore, tagStore } = useStore()

  const currentUser = authStore.currentUser || null

  const autoCompleteTagList = tagStore.autoCompleteTagList || null
  const fetchAutocompleteTags = tagStore.fetchAutocompleteTags || (() => {})

  const tempMixtape = mixtapeStore.tempMixtape || null
  const addMixtapeSongListToTempMixtape =
    mixtapeStore.addMixtapeSongListToTempMixtape || (() => {})
  const replaceMixtapeSongListToTempMixtape =
    mixtapeStore.replaceMixtapeSongListToTempMixtape || (() => {})
  const deleteMixtapeSongToTempMixtape =
    mixtapeStore.deleteMixtapeSongToTempMixtape || (() => {})
  const uploadMixtapeImage = mixtapeStore.uploadMixtapeImage || (() => {})
  const createMixtape = mixtapeStore.createMixtape || (() => {})
  const applyMixtapeForDJ = mixtapeStore.applyMixtapeForDJ || (() => {})
  const updateMixtape = mixtapeStore.updateMixtape || (() => {})

  const [isCompleted, setIsCompleted] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    // fetchUploadSongList()
    if (location.pathname === '/p/dj/mixtape/update') {
      setIsUpdated(true)
    }
    else if (location.pathname === '/p/dj/mixtape/add') {
      setIsUpdated(false)
      // getTempMixtape('reset')
    }
  }, [
    // fetchUploadSongList,
    location,
    tempMixtape,
  ])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return (
    <>
      {isCompleted && <Redirect to="/p/dj/mixtape/list" />}
      <DjMixtapeAddTemplate
        currentUser={currentUser}
        autoCompleteTagList={autoCompleteTagList}
        fetchAutocompleteTags={fetchAutocompleteTags}
        tempMixtape={tempMixtape}
        addMixtapeSongListToTempMixtape={addMixtapeSongListToTempMixtape}
        replaceMixtapeSongListToTempMixtape={
          replaceMixtapeSongListToTempMixtape
        }
        deleteMixtapeSongToTempMixtape={deleteMixtapeSongToTempMixtape}
        uploadMixtapeImage={uploadMixtapeImage}
        createMixtape={createMixtape}
        applyMixtapeForDJ={applyMixtapeForDJ}
        setIsCompleted={setIsCompleted}
        isUpdated={isUpdated}
        updateMixtape={updateMixtape}
      />
    </>
  )
}

export default observer(DjMixtapeAddPage)
