import { Network } from '.'

export default class PurchaseNetwork {
  constructor(network: Network) {
    this.network = network
  }
  // 구독 중인 plan 정보 조회
  getMyPlan = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/purchases/subscriptions/validations',
        'post',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getMyPlan error: ', error)
    }
  }

  // 판매 중인 plan 목록 조회
  getPurchasePlans = async ({ plan, period }) => {
    /**
     * @params type           '1' => android / '2' => ios / '3' => inicis
     * @params paymentType    '1' => subscription
     * @params planType       '1' => standard / '2' => student => 기존 정책 ( 2021. 04. 20 )
     * @params planType       ( 2023. 02. 08 ) 명세에 맞게 수정
     * @params version       '2' => 가격 정책 새 버전 / '1' => 기존 정책 ( 2021. 04. 20 )
     * @params version       '3' => 가격 정책 새 버전 / '1' => ( 2023. 02. 08 )
     */

    const type = '3'
    const paymentType = '1'
    const planType = plan
    const version = '3'

    const sku = `${type}_${paymentType}_${planType}_${period}_${version}`

    try {
      const { data } = await this.network._axiosApiAuth(
        `/purchase-items?sku=${sku}`,
        'get',
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getPurchasePlans error: ', error)
    }
  }

  // plan 결제에 필요한 파라미터 요청
  getPaymentParams = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/purchases/inicis/info?purchaseItemId=${params.purchaseItemId}`
          + `&paymentMethod=${params.paymentMethod}`
          + `&isMobileWeb=${params.isMobileWeb}`,
        'get',
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getPaymentParams error: ', error)
    }
  }

  // plan 결제 수단 변경
  changePaymentMethod = async params => {
    try {
      const { data } = await this.network._axiosApiAuth('', 'post', params)
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork changePaymentMethod error: ', error)
    }
  }

  // plan 구독 취소
  cancelPlan = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/purchases/subscriptions/cancel`,
        'post',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork cancelPlan error: ', error)
    }
  }

  // student plan 조회
  getValidateStudent = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/student-certifications/',
        'get',
        params,
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getValidateStudent error: ', error)
    }
  }
  // student plan을 위한 인증 (폼 전송)
  validateStudent = async params => {
    try {
      const {
        data,
      } = await this.network._axiosApiAuth('/student-certifications', 'post', [
        params,
      ])
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork validateStudent error: ', error)
    }
  }

  getPurchaseStar = async () => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/purchase-items?platform=3&isSubscription=0',
        'get',
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getPurchaseStar error: ', error)
    }
  }

  getPurchaseInicisInfoStars = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/purchases/inicis/info/stars?purchaseItemId=${params.purchaseItemId}&paymentMethod=${params.paymentMethod}&isMobileWeb=${params.isMobileWeb}&successUrl=${params.successUrl}&failUrl=${params.failUrl}`,
        'get',
      )
      return data || []
    }
    catch (error) {
      console.log('PurchaseNetwork getPurchaseInicisInfoStars error: ', error)
    }
  }
}
