import React, { useEffect } from 'react'
import { PaymentCompleteTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const PaymentCompletePage = () => {
  useRenderLogger('PaymentCompletePage')
  const { authStore, purchaseStore } = useStore()

  const currentUser = authStore.currentUser || null

  const myPlan = purchaseStore.myPlan || null
  const getMyPlan = purchaseStore.getMyPlan || (() => {})

  useEffect(() => {
    getMyPlan()
  }, [getMyPlan])

  return (
    <PaymentCompleteTemplate user={currentUser} isFirstPlan myPlan={myPlan} />
  )
}

export default observer(PaymentCompletePage)
