import { Caption1, Caption2, Div, H5, H6, ImageIcon } from '@components/atoms'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { colors } from '@resources/colors'
import { oneEllipsisStyle, twoEllipsisStyle } from '@styles/fontStyles'
import { converseUnitEng } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import HouseEditModal from './modal/HouseEditModal'

const HouseCreateBox = ({ handleCreateHosueModal }) => {
  return (
    <Div
      display="flex"
      flexDirection="column"
      width="248px"
      minWidth="248px"
      height="146px"
      borderRadius="8px"
      position="relative"
      background={`url(/images/recommend-house-background.png) center / cover`}
      overflow="hidden"
      boxShadow="0 4px 8px 0 rgb(0 0 0 / 16%)"
      padding="16px"
      cursor="pointer"
      onClick={handleCreateHosueModal}
    >
      <ImageIcon
        name="circlePlusPrimary"
        width="32px"
        height="32px"
        marginBottom="40px"
      />
      <H6 align="left" color={colors.grey_64}>
        오디오 커뮤니티
      </H6>
      <Caption1 align="left">하우스 만들기</Caption1>
    </Div>
  )
}

const RecommendHouseListItem = ({ house }) => {
  const history = useHistory()

  return (
    <Div
      width="248px"
      minWidth="248px"
      height="146px"
      borderRadius="8px"
      position="relative"
      background={`url(${house.thumbnailUrl}) center / cover`}
      overflow="hidden"
      cursor="pointer"
      onClick={
        () => {
          history.push(`/house/${house.linkKey}`)
        }
      }
    >
      <BlurBackImg />
      <Div
        position="absolute"
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        padding="16px"
        zIndex={2}
      >
        <Div
          display="flex"
          justifyContent="space-between"
          width="100%"
          height="56px"
        >
          <Div
            background={`url(${house.thumbnailUrl}) center / cover`}
            width="56px"
            height="56px"
            borderRadius="12px"
          />
          <Caption2 color={colors.white_ff}>추천</Caption2>
        </Div>
        <Div marginTop="16px" display="flex" flexDirection="column" gap="2px">
          <H6 align="left" color={colors.white_ff} style={oneEllipsisStyle}>
            {house.title}
          </H6>
          <Caption1 align="left" color={colors.white_ff}>
            {`멤버 ${converseUnitEng(house.memberCount || 0)}명`}
          </Caption1>
        </Div>
      </Div>
    </Div>
  )
}

const MyHouseListItem = ({ house, handleReadNewContents }) => {
  const history = useHistory()

  const getHouseBadgeImage = useCallback(() => {
    switch (house?._myLevel) {
      case HOUSES_HAVE_USERS_LEVEL['OWNER']:
        return 'houseOwnerBadge'
      case HOUSES_HAVE_USERS_LEVEL['HOST']:
        return 'houseOwnerBadge'
      case HOUSES_HAVE_USERS_LEVEL['MEMBER']:
        return 'houseMemeberBadge'
      default:
        return 'houseFollowerBadge'
    }
  }, [house._myLevel])

  return (
    <Div
      width="146px"
      minWidth="146px"
      height="146px"
      borderRadius="8px"
      position="relative"
      background={`url(${house.thumbnailUrl}) center / cover`}
      cursor="pointer"
      onClick={
        async () => {
          if (house?.isHouseNewContents > 0) {
            await handleReadNewContents(house)
          }
          history.push(`/house/${house.linkKey}`)
        }
      }
    >
      <Div
        position="absolute"
        width="100%"
        height="100%"
        left="0"
        top="0"
        backgroundColor="rgba(0,0,0,0.4)"
        borderRadius="8px"
      />
      {
house?.isHouseNewContents > 0 && (
          <Div
            position="absolute"
            width="12px"
            height="12px"
            right="-6px"
            top="-6px"
            borderRadius="50%"
            backgroundColor={colors.warning}
            border={`1px solid ${colors.white_ff}`}
            zIndex={3}
          />
        )
      }
      <ImageIcon
        name={getHouseBadgeImage()}
        position="absolute"
        left="8px"
        top="8px"
        width="24px"
        height="24px"
        zIndex={2}
      />
      <Div
        position="absolute"
        display="flex"
        alignItems="flex-end"
        width="100%"
        height="100%"
        padding="8px"
        zIndex={2}
      >
        <Caption1 align="left" color={colors.white_ff} style={twoEllipsisStyle}>
          {house.title}
        </Caption1>
      </Div>
    </Div>
  )
}

const HomeHouseSection = () => {
  const { authStore, houseStore } = useStore()

  const myDetailJoinedHouseList = authStore.myDetailJoinedHouseList || []
  const isRecommend = myDetailJoinedHouseList?.length === 0

  const tryAuth = authStore.tryAuth || (() => {})

  const fetchHomeHouseRecommendation =
    houseStore.fetchHomeHouseRecommendation || (() => {})
  const updateTempHouse = houseStore.updateTempHouse || (() => {})

  const [houseList, setHouseList] = useState([])

  const {
    isShowing: showHoustEditModal,
    toggle: toggleHouseEditModal,
  } = HouseEditModal.useModal()

  const handleCreateHosueModal = useCallback(async () => {
    const isAuth = await tryAuth()
    if (!isAuth) return

    await updateTempHouse()
    toggleHouseEditModal()
  }, [tryAuth, updateTempHouse, toggleHouseEditModal])

  const handleReadNewContents = useCallback(async house => {
    if (house?.isHouseNewContents > 0) {
      const res = await houseStore.createHouseConnect(house?.id)
      if (res) {
        if (house?.isHouseNewContents) {
          house.isHouseNewContents = 0
        }
      }
    }
  }, [])

  const fetchHouseList = useCallback(async () => {
    if (myDetailJoinedHouseList.length > 0) {
      setHouseList(myDetailJoinedHouseList)
    }
    else {
      const { houseList: list } = await fetchHomeHouseRecommendation({
        limit: 5,
      })
      setHouseList(list)
    }
  }, [myDetailJoinedHouseList])

  useEffect(() => {
    fetchHouseList()

    return () => {
      setHouseList([])
    }
  }, [authStore.currentUser, fetchHouseList])

  return (
    <Div
      display="flex"
      flexDirection="column"
      width="100%"
      flex="1"
      maxWidth="1704px"
    >
      <Div
        display="flex"
        flex="1"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <H5 type="Bold">
          {!isRecommend ? '참여중인 하우스' : '하우스에 가입해보세요 :)'}
        </H5>
        {/* {!isRecommend && <Caption1 color={colors.secondary}>더 보기</Caption1>} */}
      </Div>
      <Div
        display="flex"
        gap="24px"
        width="100%"
        overflowX="scroll"
        padding="6px 4px"
        height="162px"
        marginTop="24px"
      >
        {
          isRecommend ? (
            <>
              <HouseCreateBox handleCreateHosueModal={handleCreateHosueModal} />
              {
houseList?.map(house => (
  <RecommendHouseListItem house={house} key={house.id} />
))
              }
            </>
          ) : (
            <>
              {
houseList?.map(house => (
  <MyHouseListItem
    house={house}
    key={house.id}
    handleReadNewContents={handleReadNewContents}
  />
))
              }
              <HouseCreateBox handleCreateHosueModal={handleCreateHosueModal} />
            </>
          )
        }
      </Div>
      <HouseEditModal.View
        isEdit={false}
        isShowing={showHoustEditModal}
        toggle={toggleHouseEditModal}
      />
    </Div>
  )
}

export default observer(HomeHouseSection)

const BlurBackImg = styled(Div)`
  width: 100%;
  height: 100%;
  object-fit: contain;

  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  /* z-index: 2; */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`
