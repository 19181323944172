import { FaqTemplate } from '@components/templates'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

type Props = {}

const FaqPage: React.FC<Props> = () => {
  useRenderLogger('FaqPage')

  const { customerCenterStore } = useStore()

  const faqList = customerCenterStore.faqList || null

  const { isLoading, setIsLoading } = useTagLoading()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await customerCenterStore.fetchFAQList()
      setIsLoading(false)
    }
    fetchData()
  }, [customerCenterStore, setIsLoading])

  return <FaqTemplate faqList={faqList} isLoading={isLoading} />
}

export default observer(FaqPage)
