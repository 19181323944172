import React, { useEffect, useState } from 'react'
import { Flex, H3, Button } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import MixtapeListItem from '@components/molecules/item/MixtapeListItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { mobile } from '@styles/media'
import { CastShimmer } from '@components/molecules'
// import { useWindowSize } from '@utils/hooks'

// 전체 영역
const ListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1704px;
  width: 100%;
  margin-bottom: 30px;

  @media ${mobile} {
    min-width: auto;
  }
`

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
`

// 정렬 버튼
const SortBtn = styled(Button)`
  width: 80px;
  height: 40px;
  border-radius: 20px;
  border: ${props => (props.active ? `solid 2px ${colors.primary}` : 'none')};
  color: ${props =>
    props.active ? `${colors.primary}` : `${colors.brown_grey}`};
`

// 리스트 내용 영역
const ListContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

/**
 * 카테고리별 플라 리스트 컴포넌트
 */
const CategoryMixtapeList = ({
  offset,
  setOffset,
  haveMore,
  setHaveMore,
  list,
  category,
  keyword,
  tag,
  fetchHomePopularTotalMixtapeList,
  fetchTotalMixtapeList,
  fetchMyFollowMixtapeList,
  fetchSearchMixtapeList,
  fetchTagLatestMixtapeList,
  fetchTagPopulartMixtapeList,
  fetchMyLikeMixtapeList,
}) => {
  // const deviceSize = useWindowSize()
  const hideIdx = 0
  // switch (true) {
  //   case deviceSize >= 1905:
  //     hideIdx = list.length % 6
  //     break
  //   case deviceSize >= 1632:
  //     hideIdx = list.length % 5
  //     break
  //   case deviceSize >= 1361:
  //     hideIdx = list.length % 4
  //     break
  //   case deviceSize >= 1089:
  //     hideIdx = list.length % 3
  //     break

  //   default:
  //     break
  // }

  // 전체 플라일 때 기본 정렬값 '인기'
  const [sort, setSort] = useState(category === 'total' ? 'latest' : 'popular')

  const handleSort = value => {
    setOffset(0)
    setSort(value)
    setHaveMore(true)
    if (category === 'total') {
      if (value === 'popular') {
        fetchHomePopularTotalMixtapeList()
      }
      else if (value === 'latest') {
        fetchTotalMixtapeList({ limit: 20 })
      }
    }
    else if (category === 'tag') {
      if (value === 'popular') {
        fetchTagPopulartMixtapeList(keyword)
      }
      else if (value === 'latest') {
        fetchTagLatestMixtapeList(keyword)
      }
    }
  }

  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(offset + 20)
  }

  useEffect(() => {
    if (offset === 0) {
      if (list && list.length < 20) {
        if (category !== 'total') setHaveMore(false)
      }
      else if (list && list.length >= 20) {
        setHaveMore(true)
      }
    }
  }, [offset, list, setHaveMore, category])

  useEffect(() => {
    const fetchMoreList = async () => {
      if (offset !== 0) {
        let isMore = true
        switch (category) {
          case 'total':
            isMore = await fetchTotalMixtapeList({ limit: 20, offset })
            setHaveMore(isMore)
            break
          case 'history':
            break
          case 'like':
            isMore = await fetchMyLikeMixtapeList({ limit: 20, offset })
            setHaveMore(isMore)
            break
          case 'subscribe':
            isMore = await fetchMyFollowMixtapeList({ limit: 20, offset })
            setHaveMore(isMore)
            break
          case 'search':
            isMore = await fetchSearchMixtapeList(keyword, {
              limit: 20,
              offset,
            })
            setHaveMore(isMore)
            break
          case 'tag':
            if (sort === 'latest') {
              isMore = await fetchTagLatestMixtapeList(keyword, {
                limit: 20,
                offset,
              })
              setHaveMore(isMore)
            }
            else if (sort === 'popular') {
              isMore = await fetchTagPopulartMixtapeList(keyword, {
                limit: 20,
                offset,
              })
              setHaveMore(isMore)
            }
            break
          default:
            break
        }
      }
    }
    fetchMoreList()
  }, [
    setHaveMore,
    category,
    keyword,
    sort,
    offset,
    fetchTotalMixtapeList,
    fetchMyFollowMixtapeList,
    fetchSearchMixtapeList,
    fetchTagLatestMixtapeList,
    fetchTagPopulartMixtapeList,
    fetchMyLikeMixtapeList,
  ])

  if (!list?.length) return <></>
  return (
    <ListBox style={{ marginTop: category === 'tag' && '16px' }}>
      <ListHeader>
        <H3 type="Bold">
          {
            category === 'history'
              ? '즐겨듣는 플라'
              : category === 'total'
                ? '전체 플라'
                : category === 'like'
                  ? '좋아하는 플라'
                  : category === 'subscribe'
                    ? '구독한 DJ의 플라'
                    : category === 'search'
                      ? '플라'
                      : `#${tag}`
          }
        </H3>
        {
          (category === 'total' || category === 'tag') && (
            <Flex>
              <SortBtn
                active={sort === 'latest'}
                onClick={() => handleSort('latest')}
              >
              최신
              </SortBtn>
              <SortBtn
                active={sort === 'popular'}
                onClick={() => handleSort('popular')}
              >
              인기
              </SortBtn>
            </Flex>
          )
        }
      </ListHeader>
      {
        (category === 'total' && sort === 'latest')
      || category === 'subscribe'
      || category === 'tag'
      || category === 'search' ? (
            <InfiniteScroll
              dataLength={list.length}
              scrollThreshold={0.9}
              next={countOffset}
              hasMore={haveMore}
              hasChildren
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
              className="scroll_div"
              scrollableTarget="scrollableDiv"
              style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
            >
              <ListContent>
                {
list?.length ? (
  list.map((item, index) => {
    if (index > list.length - hideIdx - 1) return <></>
    return (
      <MixtapeListItem
        item={item}
        key={`category_${item.id}_${index}`}
        category={category}
        sort={sort}
      />
    )
  })
) : (
  <>
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
    <CastShimmer />
  </>
)
                }
              </ListContent>
            </InfiniteScroll>
          ) : (
            <ListContent>
              {
                list
            && list.map((item, index) => {
              if (index > list.length - hideIdx - 1) return <></>
              return (
                <MixtapeListItem
                  item={item}
                  key={`category_${item.id}_${index}`}
                  category={category}
                  sort={sort}
                />
              )
            })
              }
            </ListContent>
          )
      }
    </ListBox>
  )
}

export default CategoryMixtapeList
