import { observable } from 'mobx'
import FeedModel from './FeedModel'
import UserModel from './UserModel'

export default class FeedsHaveUsersModel {
  @observable id

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable feedId
  @observable userId

  @observable Feed = null
  @observable User = null

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.feedId = props.feedId
      this.userId = props.userId

      this.Feed = (props.Feed && new FeedModel(stores, props.Feed)) || null
      this.User = (props.User && new UserModel(stores, props.User)) || null
    }
  }
}
