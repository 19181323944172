import { Div } from '@components/atoms'
import { images } from '@images/'
import { colors } from '@resources/colors'
import React from 'react'
import styled, { css } from 'styled-components'

const TagBox = ({
  item,
  index,
  isTemp = false,
  onClick = () => {},
  onRemove,
}) => {
  return (
    <Box
      isTemp={isTemp}
      onClick={
        () => {
          if (!isTemp) onClick(item)
        }
      }
    >
      {item.keyword}
      {
        isTemp && (
          <img
            src={images.circle_close_btn_img_trans}
            alt="close_btn"
            style={{ width: 16, height: 16, marginLeft: 4 }}
            onClick={() => onRemove(item, index)}
          />
        )
      }
    </Box>
  )
}

export default TagBox

const Box = styled(Div)`
  transition: 0.35s;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: fit-content; */
  height: 28px;
  border-radius: 14px;
  cursor: pointer;

  ${({ isTemp }) =>
    isTemp
      ? css`
          background-color: ${colors.primary};
          border: 1px solid ${colors.primary};
          color: ${colors.white_ff};
          font-size: 10px;
          padding: 13px 6px 13px 13px;
        `
      : css`
          padding: 13px;
          background-color: ${colors.white_ff};
          border: 1px solid ${colors.grey_d4};
          color: ${colors.black_24};
          font-size: 10px;
        `}
`
