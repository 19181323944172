import styled from 'styled-components'

const designList = {
  roundPrimary: `
    border-radius: 24px;
    background: #5b49f3;
    color: #ffffff;
    `,
  borderEA: `
    border: 1px solid #eaeaea;
    border-radius: 8px;
    background: #ffffff;
    color: #242424;
    `,
  whiteF4: `
    border-radius: 4px;
    background: #f4f4f4;
    color: #242424;
    `,
  borderFillRoundEA: `
    border: 1px solid #eaeaea;
    border-radius: 18px;
    background: #ffffff;
    color: #242424;
    `,
  primary: `
    border-radius: 4px;
    background: #594ee9;
    color: #ffffff;
  `,
  unfollow: `
    border-radius: 4px;
    background: #f4f4f4;
    color: #949494;
  `,
}

const sizeList = {
  lg: `
    height:48px;
    line-height:20px;
    font-size: 14px;
    `,
  md: `
    height:40px;
    line-height:20px;
    font-size: 14px;
  `,
  xm: `
    height:36px;
    line-height:18px;
    font-size: 12px;
  `,
  sm: `
    height:32px;
    line-height:20px;
    font-size: 14px;
  `,
  ms: `
    height:28px;
    line-height:20px;
    font-size: 12px;
  `,
  xs: `
    height:24px;
    line-height:18px;
    font-size: 12px;
  `,
}

const StyledButton = styled.button.attrs(
  (props: React.HTMLProps<HTMLButtonElement>) => ({
    style: {
      ...props,
      ...props.style,
    },
  }),
)`
  padding: 0;
  border: none;
  box-sizing: border-box;
  ${({ designType }) => designList[designType || 'roundPrimary']};
  ${({ size }) => sizeList[size || 'lg']};
  transition: 0.15s;
  cursor: pointer;
`

export default StyledButton
