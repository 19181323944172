import React from 'react'
import styled from 'styled-components'

// type Props = {
//   quiz: string
//   style?: React.CSSProperties
//   onClick: any
//   color?: string
// }

const QuizBtn = ({
  quiz,
  style,
  onClick,
  color = '#242424',
}) => {
  return (
    <Btn style={style} onClick={onClick} color={color}>
      {quiz}
    </Btn>
  )
}

const Btn = styled.button`
  // css로 모바일 확대 축소 막는 방법
  // 세로 터치는 가능하고, 전체 확대는 불가능하게 됨.
  touch-action: pan-y;

  // 클릭 시, focus되었을 때, css 가리기
  :focus {
    outline: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  white-space: pre-line;

  color: ${({ color }) => color};
  font-family: BMDOHYEON;
  font-weight: 500;
  font-size: 14px;

  width: 100%;
  height: 48px;

  :active {
    background-color: #5d4afc;
    color: #ffffff;
  }
`

export default QuizBtn
