import { Flex, Caption2, Caption1 } from '@components/atoms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'
import { CopiedPopup } from '..'

const SalonResponseMobileBox = ({ style, dynamicLink }) => {
  const [linkCopied, setLinkCopied] = React.useState(false)

  const handleCopy = () => {
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 2500)
  }

  return (
    <>
      <Box style={style && style}>
        <Caption1>모바일에서 살롱 링크로 접속하기</Caption1>
        <CopyToClipboard text={dynamicLink} onCopy={handleCopy}>
          <LinkBox>
            <CopyLinkBtn src={images.copy_link_img_black} alt="copy_btn" />
            <Caption2 type="Medium">링크복사</Caption2>
          </LinkBox>
        </CopyToClipboard>
      </Box>
      {linkCopied && <CopiedPopup />}
    </>
  )
}

export default SalonResponseMobileBox

const Box = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 68px;
  background-color: ${colors.white_ff};
  border-radius: 16px;
  padding: 0 20px;
`

const LinkBox = styled(Flex)`
  cursor: pointer;
  width: 92px;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  border-radius: 16px;
  border: solid 1px ${colors.white_f4};
  background-color: ${colors.white_ff};

  box-sizing: border-box;
`

const CopyLinkBtn = styled.img`
  width: 16px;
  height: 16px;
`
