import { action, computed, observable } from 'mobx'
import { Store } from '.'
import { ModalsInterface } from '../utils/modals'

export default class AppStateStore {
  @observable currentMenu

  @observable showSidebar // collpased sidebar
  @observable toggleSidebar // in mobile toggle sidebar
  @observable soundInfo // global sound url status
  @observable modalsState
  @observable toastState

  constructor(store: Store, network) {
    this.init()
  }

  @action init = () => {
    this.currentMenu = 'home'
    this.showSidebar = false
    this.toggleSidebar = true
    this.soundInfo = null

    this.modalsState = []
    this.toastState = []
  }

  @action.bound
  toggleShowSidebar = () => {
    this.showSidebar = !this.showSidebar

    if (this.showSidebar) this.toggleSidebar = false
    else this.toggleSidebar = true
  }

  @action playSound = async url => {
    this.soundInfo = await { url }
  }

  @action updateModalsState = value => {
    this.modalsState = value
  }

  @action updateToastState = value => {
    this.toastState = value
  }

  @action onModal = (state: ModalsInterface) => {
    const hash = Math.random()
      .toString(36)
      .substr(2, 11)

    this.updateModalsState([
      ...this.modalsState,
      { ...state, hash, status: 'on' },
    ])
  }

  @action offModal = hash => {
    this.updateModalsState(
      this.modalsState.map(_modal =>
        _modal.hash === hash
          ? { type: _modal.type, status: 'off' }
          : { ..._modal },
      ),
    )
  }

  @action getModalState = hash => {
    return this.modalsState.find(modal => modal.hash === hash)
  }

  @action onToast = payload => {
    const hash = Math.random()
      .toString(36)
      .substr(2, 11)

    this.updateToastState([...this.toastState, { ...payload, hash }])

    setTimeout(() => {
      this.updateToastState(this.toastState.filter(t => t.hash !== hash))
    }, payload.option?.expires ?? 3000)
  }

  @action
  querify = () => {
    const query = new URLSearchParams(window.location.search)

    const queryObject = {}

    query.forEach((v, k) => {
      queryObject[k] = v
    })

    return queryObject
  }
}
