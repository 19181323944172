import {
  Caption1,
  Caption2,
  Div,
  Flex,
  H6,
  Image,
  Img,
  ProfileImg,
} from '@components/atoms'
import { CastReservedImageText, CopiedPopup } from '@components/molecules'
import {
  CastDetailCommentSection,
  CastSearchBar,
  CastPresentStarModal,
} from '@components/organisms'
import { CASTS_HAVE_TAGS_LEVEL } from '@consts/cast'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { CastModel } from '@stores/models'
import { oneEllipsisStyle, twoEllipsisStyle } from '@styles/fontStyles'
import { converseUnitEng, secondsToTime } from '@utils/format'
import { observer } from 'mobx-react'
import moment from 'moment'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { PageLayout } from '..'

const InfoDot = () => {
  return (
    <div
      style={
        {
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '4px',
          paddingRight: '4px',
        }
      }
    >
      <div
        style={
          {
            width: '2px',
            height: '2px',
            backgroundColor: colors.brown_grey,
            borderRadius: '1px',
          }
        }
      />
    </div>
  )
}

const CastDetailTemplate = ({
  castDetail,
  isMyPage,
  isHouseCast,
  followUser,
  followHouse,
  category,
  sort,
  fetchLoadPlayCast,
  tryAuth,
  toggleLike,
  myLevel,
}: {
  castDetail: CastModel,
  isMyPage: boolean,
  isHouseCast: boolean,
  followUser: () => void,
  followHouse: () => void,
  category?: string,
  sort?: string,
  fetchLoadPlayCast: any,
  myLevel: any,
}) => {
  const history = useHistory()
  const {
    isShowing: showPresent,
    toggle: togglePresent,
  } = CastPresentStarModal.useModal()

  const handleOpenPresent = async () => {
    const isAuth = await tryAuth()
    if (isAuth) {
      await togglePresent()
    }
  }

  const [linkCopied, setLinkCopied] = React.useState(false)

  const handleCopy = () => {
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 2500)
  }

  return (
    <PageLayout style={{ padding: '0 48px' }}>
      <CastSearchBar />
      <Flex type="column" style={{ width: '100%', maxWidth: '1064px' }}>
        <Flex
          style={
            {
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
            }
          }
        >
          <Flex type="column" style={{ width: '100%', maxWidth: 384 }}>
            <BlurContainer
              background={castDetail?.imageUrl || images.default_song_img}
            >
              <BlurBackImg />
              <MainImg
                src={castDetail?.imageUrl || images.default_song_img}
                alt="cast"
              />
              <CastReservedImageText castDetail={castDetail} />
            </BlurContainer>

            <Div display="flex" marginTop="16px" justifyContent="space-between">
              <Flex
                style={
                  {
                    alignItems: 'center',
                    cursor: 'pointer',
                  }
                }
                onClick={
                  e => {
                    e.stopPropagation()
                    if (isHouseCast) {
                      history.push(
                        `/house/${castDetail && castDetail?.House.linkKey}`,
                      )
                    }
                    else {
                      history.push(
                        `/user/${castDetail && castDetail?.User.account}`,
                      )
                    }
                  }
                }
              >
                <UserImage
                  src={
                    isHouseCast
                      ? castDetail?.House?.imageUrl
                      : castDetail?.User.imageUri
                  }
                  profileColor={castDetail?.User.profileColor}
                  style={{ marginRight: '8px' }}
                />
                <Caption2
                  color={colors.grey_64}
                  align="left"
                  style={
                    {
                      ...oneEllipsisStyle,
                      maxHeight: '18px',
                    }
                  }
                >
                  {
                    isHouseCast
                      ? castDetail?.House?.title
                      : castDetail?.User?.name
                  }
                </Caption2>
                {
                  !isHouseCast && castDetail?.User?.isInfluencer && (
                    <img
                      src={images.check_blue_img}
                      style={{ width: 14, height: 14, margin: '0 4px' }}
                      alt="influencer"
                    />
                  )
                }
              </Flex>
              {
                isHouseCast
                  ? myLevel < HOUSES_HAVE_USERS_LEVEL['MEMBER'] && (
                    <FollowBtn
                      followed={
                        myLevel !== null
                        && myLevel >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                      }
                      onClick={followHouse}
                    >
                      {
                        myLevel !== null
                      && myLevel >= HOUSES_HAVE_USERS_LEVEL['FOLLOWER']
                          ? '팔로잉'
                          : '팔로우'
                      }
                    </FollowBtn>
                  )
                  : !isMyPage && (
                    <FollowBtn
                      followed={castDetail?.User?.isFollowed}
                      onClick={followUser}
                    >
                      {castDetail?.User?.isFollowed ? '팔로잉' : '팔로우'}
                    </FollowBtn>
                  )
              }
            </Div>
          </Flex>
          {
            isHouseCast && castDetail?.isHouseMember && (
              <Div
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="24px"
                height="24px"
                borderRadius="50%"
                backgroundColor="rgba(36, 36, 36, 0.56)"
                position="absolute"
                top="10px"
                left="350px"
              >
                <Img
                  src={images.house_type_member_icon}
                  width="20px"
                  height="20px"
                />
              </Div>
            )
          }
          <Flex
            style={
              {
                width: '100%',
                maxWidth: 656,
                flexDirection: 'column',
                marginLeft: '24px',
              }
            }
          >
            <TagGroup>
              {
castDetail?.CastsHaveTags.filter(
                _castsHaveTag =>
                  _castsHaveTag.level
                    === CASTS_HAVE_TAGS_LEVEL['VISIBLE_SYSTEM']
                  || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['TOPIC']
                  || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['NORMAL']
                  || _castsHaveTag.level === CASTS_HAVE_TAGS_LEVEL['NO'],
              )
                .sort((a, b) => {
                  const aLevel = a.level
                  const bLevel = b.level
                  if (aLevel > bLevel) {
                    return 1
                  }
                  if (aLevel === bLevel) {
                    return 0
                  }
                  if (aLevel < bLevel) {
                    return -1
                  }
                })
                .map(_castsHaveTag => (
                  <Tag
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                    key={_castsHaveTag?.id}
                  >
                    {_castsHaveTag?.Tag?.keyword}
                  </Tag>
                ))
              }
            </TagGroup>

            <Flex
              style={
                {
                  width: '100%',
                }
              }
            >
              <Caption1
                style={
                  {
                    ...twoEllipsisStyle,
                    color: '#242424',
                    fontSize: '24px',
                    width: '100%',
                  }
                }
                align="left"
              >
                {castDetail?.title}
              </Caption1>
            </Flex>
            <Flex
              style={
                {
                  alignItems: 'center',
                  marginTop: '12px',
                  marginBottom: '12px',
                  gap: 12,
                }
              }
            >
              <CastBtnBox
                style={{ width: 160 }}
                onClick={
                  async () => {
                    if(castDetail?.isReservedYet) {
                      return
                    }

                    await fetchLoadPlayCast(castDetail, category, sort)
                  // popupInfoToggle()
                  }
                }
                disabled={castDetail?.isReservedYet}
              >
                <Img
                  src={castDetail?.isReservedYet ? images.play_gray_d4_img : images.play_gray_img}
                  width="12px"
                  height="12px"
                  marginRight="4px"
                />
                <Caption2 color={castDetail?.isReservedYet ? colors.grey_d4 : '#000000'}>재생하기</Caption2>
              </CastBtnBox>
              <CastBtnBox>
                <Flex
                  style={
                    {
                      alignItems: 'center',
                      color: '#242424',
                    }
                  }
                >
                  <img
                    src={
                      castDetail?.UsersLikeCasts.length > 0
                        ? images.heart_img_red_24
                        : images.like_default_img
                    }
                    alt="cast_heart_img"
                    style={
                      {
                        width: '24',
                        height: '24',
                        marginRight: '8px',
                        cursor: 'pointer',
                      }
                    }
                    onClick={toggleLike}
                  />
                  <InfoCount
                    style={
                      {
                        size: '14px',
                        color: '#242424',
                        paddingBottom: '2px',
                      }
                    }
                  >
                    {converseUnitEng(castDetail?.likeCount || 0)}
                  </InfoCount>
                </Flex>
              </CastBtnBox>
              <CastBtnBox onClick={handleOpenPresent}>
                <img
                  src={images.present_img}
                  alt="send_start_icon"
                  style={
                    {
                      width: 24,
                      height: 24,
                    }
                  }
                />
                <Caption2>선물</Caption2>
              </CastBtnBox>

              <CopyToClipboard
                text={document.location.href}
                onCopy={handleCopy}
              >
                <CopyLinkBtn>
                  <img src={images.copy_link_img_black} alt="copy_btn" />
                </CopyLinkBtn>
              </CopyToClipboard>
            </Flex>
            <InfoTime>
              <Caption2>
                {`${secondsToTime(castDetail?.duration || 0)}`}
              </Caption2>
              <InfoDot />
              <Caption2>
                {`조회수 ${converseUnitEng(castDetail?.playCount || 0)}회`}
              </Caption2>
              <InfoDot />
              <Caption2>
                {moment(castDetail?.publishedAt).format('YYYY. MM. DD')}
              </Caption2>
            </InfoTime>
          </Flex>
        </Flex>
        <Flex
          style={
            {
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginTop: '40px',
            }
          }
        >
          <H6>{`에피소드 소개`}</H6>
          <div
            style={
              {
                width: '100%',
                height: '1px',
                backgroundColor: '#f4f4f4',
                marginTop: '16px',
                marginBottom: '16px',
              }
            }
          />
          <Caption1
            align="left"
            type={'Regular'}
            style={
              {
                color: '#242424',
                marginBottom: '24px',
                whiteSpace: 'pre-wrap',
              }
            }
          >
            {castDetail?.description ?? '-'}
          </Caption1>
          <Flex
            style={
              {
                width: '100%',
                justifyContent: 'center',
                marginTop: '40px',
              }
            }
          >
            <CastDetailCommentSection
              castId={castDetail?.id}
              totalCount={castDetail?.commentCount || 0}
              commentList={castDetail?.commentList || []}
            />
          </Flex>
        </Flex>
      </Flex>
      <CastPresentStarModal.View
        isShowing={showPresent}
        toggle={togglePresent}
        castId={castDetail?.id}
        castTitle={castDetail?.title}
        castOwner={castDetail?.User}
      />
      {linkCopied && <CopiedPopup />}
    </PageLayout>
  )
}

export default observer(CastDetailTemplate)

const InfoCount = styled.div`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color: #646464;
`
const InfoTime = styled.div`
width:100%;
display:flex;
align-items:center;
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color #949494;
`

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
`

const InfoImg = styled.img`
  width: 100%;
  height: 216px;
  /* margin: 0 28px 40px 0;
  padding: 53px 0 52px; */
  border-radius: 8px;
  object-fit: cover;
  /* -webkit-backdrop-filter: blur(32.1px);
  backdrop-filter: blur(32.1px);
  background-color: #fff; */
`

const TagGroup = styled(Flex)`
  flex-wrap: wrap;
`

const Tag = styled(Flex)`
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: auto;
  height: 28px;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #f4f4f4;
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  color: #646464;
`

const FollowBtn = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ followed }) =>
    followed ? colors.white_f4 : colors.primary};

    margin-left:12px;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: ${({ followed }) => (followed ? colors.grey_94 : colors.white_ff)};
}
`

const CastBtnBox = styled(Flex)`
  width: 72px;
  height: 28px;
  font-size: 12px;
  background-color: ${props => props.disabled ? colors.white_f4 : colors.white_ff};
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const CopyLinkBtn = styled(Flex)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${colors.grey_d4};

  > img {
    width: 16px;
    height: 16px;
  }
`

const BlurContainer = styled.div`
  position: relative;
  width: 100%;
  height: 216px;
  object-fit: contain;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

const MainImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
`

const BlurBackImg = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  object-fit: contain;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* z-index: 2; */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`
