import React, { useState } from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup'
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import Link from 'next/link'
// import Flex from '../Flex'
import { Flex, Img } from '@components/atoms'
import { Link } from 'react-router-dom'

import { mobile } from '@styles/media'
import { CopiedPopup } from '@components/molecules'
import SocialkingTextBtn from './SocialkingTextBtn'
import { convertToPricingComma } from '../../utils/string'
import EventLayout from './EventLayout'

const hreumLogo = require('../../../public/images/hreum_logo.png')
const mainImg = require('../../../public/images/socialking_main_img.png')
const mainGif = require('../../../public/images/socialking_main_gif.gif')

// type Props = {
//   setStep: Function
// }

const SocialkingMainTemplate = ({ setStep }) => {
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const handleCopy = () => {
    // LOG_EVENT.LANDING_CLICK_SHARE_SOCIALKING()
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
  }

  return (
    <EventLayout>
      <Link href="/">
        <Img
          src={hreumLogo}
          width={132}
          height={24}
          alt="hreum_logo"
          style={{ cursor: 'pointer' }}
        />
      </Link>
      <TitleText>인맥왕 테스트</TitleText>
      <SubTitleText>나의 인맥은 상위 몇 퍼센트일까?</SubTitleText>
      <Flex
        style={
          {
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
      >
        <Img
          src={mainImg}
          width={326}
          height={164}
          alt="mainImg"
          style={{ margin: '28px 0 32px 0' }}
        />
        <Img
          src={mainGif}
          width={140}
          height={140}
          alt="mainGif"
          style={{ position: 'absolute', marginLeft: 8 }}
        />
      </Flex>
      <Flex type="row" style={{ marginTop: 40 }}>
        <CountText>현재까지</CountText>
        <CountText style={{ color: '#5b49f3', margin: '0 4px' }}>
          총&nbsp;
          <CountUp
            start={0}
            end={213449}
            duration={2}
            formattingFn={(value) => convertToPricingComma(value.toString())}
          />
          명
        </CountText>
        <CountText>참여했어요!</CountText>
      </Flex>
      <SocialkingTextBtn
        style={
          {
            backgroundColor: '#5d4afc',
            color: '#ffffff',
            margin: '40px 0 16px 0',
          }
        }
        onClick={
          () => {
            setStep(1)
            // LOG_EVENT.LANDING_CLICK_START_SOCIALKING()
          }
        }
      >
        테스트 시작
      </SocialkingTextBtn>
      <CopyToClipboard text={window?.location?.href} onCopy={handleCopy}>
        <SocialkingTextBtn>친구한테 공유하기</SocialkingTextBtn>
      </CopyToClipboard>
      {isCopied && <CopiedPopup />}
    </EventLayout>
  )
}

export default SocialkingMainTemplate

const TitleText = styled.h4`
  font-family: BMDOHYEON;
  font-size: 24px;
  margin: 24px 0 20px 0;
  font-weight: normal;
`

const SubTitleText = styled.h4`
  font-family: AppleSDGothicNeo;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: #242424;
`

const CountText = styled.p`
  font-family: BMDOHYEON;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  text-align: center;
  line-height: 1.3;

  /* @media ${mobile} { font-size : 20px;} */
`
