import React, { useCallback, useEffect, useState } from 'react'
import { Caption1, Div, H4, StyledButton } from '@components/atoms'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'

const isAnimation = true
const isShow = true
const canClosed = false
const isRadius = true
const width = 320
const height = 'auto'
const wait = false
const padding = '12px'
const ConfirmModal = ({ hash }) => {
  const { appStateStore } = useStore()

  const offModal = appStateStore.offModal || (() => {})
  const getModalState = appStateStore.getModalState || (() => {})

  const [payload, setPayload] = useState()

  const onClose = useCallback(() => {
    offModal(hash)
  }, [hash, offModal])

  const handleConfirm = useCallback(async () => {
    if (payload?.onConfirm) {
      await payload?.onConfirm()
    }
    onClose()
  }, [onClose, payload])

  const handleCancel = useCallback(async () => {
    if (payload?.onCancel) {
      await payload?.onCancel()
    }
    onClose()
  }, [onClose, payload])

  const getPayload = useCallback(async () => {
    if (hash) {
      const state = await getModalState(hash)
      if (state?.payload) {
        setPayload(state.payload)
      }
    }
  }, [hash, getModalState])

  useEffect(() => {
    getPayload()
  }, [])

  return (
    <Div
      top="0"
      width="100vw"
      height="100%"
      overflow="hidden"
      position="fixed"
      zIndex={2000}
    >
      <Div
        width="100%"
        height="100%"
        position="fixed"
        bottom="0"
        animation={
          isAnimation
            ? isShow
              ? 'fadeIn forwards 0.45s'
              : 'fadeOut forwards ease-in 0.45s'
            : ''
        }
        background="rgba(0,0,0,0.3)"
        onClick={canClosed && wait ? onClose : () => {}}
      />
      <Div
        position="absolute"
        width={width}
        height={height}
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        animation={
          isAnimation
            ? isShow
              ? 'fadeIn forwards 0.45s'
              : 'fadeOut forwards ease-in 0.25s'
            : ''
        }
      >
        <Div
          display="flex"
          flexDirection="column"
          alignItems="center"
          background="white"
          width={width}
          height={height}
          padding={padding ?? padding}
          borderRadius={isRadius ? '10px' : '0'}
        >
          <H4 type="Bold" style={{ margin: '12px 0px 20px 0px' }}>
            {payload?.title}
          </H4>
          <Caption1 color="#949494" style={{ whiteSpace: 'pre' }}>
            {payload?.body}
          </Caption1>
          <Div
            display="flex"
            justifyContent="space-between"
            marginTop="30px"
            width="100%"
            gap="20px"
          >
            <StyledButton
              designType="whiteF4"
              size="lg"
              flex="1"
              onClick={handleCancel}
            >
              취소
            </StyledButton>
            <StyledButton
              designType="primary"
              size="lg"
              flex="1"
              onClick={handleConfirm}
            >
              확인
            </StyledButton>
          </Div>
        </Div>
      </Div>
    </Div>
  )
}

export default observer(ConfirmModal)
