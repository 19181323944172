import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex, Button, Caption2 } from '@components/atoms'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 24px 28px 16px 28px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const MbtiBtn = ({ mbti, value, onClick }) => {
  return (
    <MbtiBox active={mbti === value} onClick={() => onClick(mbti)}>
      {mbti}
    </MbtiBox>
  )
}

const View = ({ isShowing, toggle, mbti, setValue }) => {
  const [showMbtiTest, setShowMbtiTest] = useState(false)
  const [initialMbti, setInitialMbti] = useState(null)

  /**
   * 랜딩페이지에서 MBTI 결과값 받아오기
   */
  useEffect(() => {
    const receiveMessage = event => {
      if (typeof event.data === 'string') {
        const result = JSON.parse(event.data)
        setValue(result.mbti.toUpperCase())
        setShowMbtiTest(false)
      }
    }

    if (showMbtiTest) {
      window.addEventListener('message', receiveMessage, false)
    }

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [showMbtiTest, setValue])

  useEffect(() => {
    if (mbti && !initialMbti) {
      setInitialMbti(mbti)
    }
  }, [mbti, initialMbti])

  useEffect(() => {
    if (!isShowing) setInitialMbti(null)
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={
          () => {
            toggle()
            setShowMbtiTest(false)
          }
        }
        />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H6>당신의 MBTI는 무엇인가요?</H6>
              <Caption2
                color={colors.grey_64}
                style={{ margin: '9px 0 26px 0' }}
              >
                  내 MBTI를 입력하고 나와 비슷한 성향의 친구들을 만나보세요!
              </Caption2>
              <MbtiList>
                <MbtiBtn mbti="ISTJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ISFJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="INFJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="INTJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ISTP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ISFP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="INFP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="INTP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ESTP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ESFP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ENFP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ENTP" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ESTJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ESFJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ENFJ" value={mbti} onClick={setValue} />
                <MbtiBtn mbti="ENTJ" value={mbti} onClick={setValue} />
              </MbtiList>
              <Caption2
                color={colors.grey_64}
                style={{ margin: '25px 0 24px 0' }}
              >
                  MBTI를 모르신다구요? 그럼 지금 한번 검사해보세요!
              </Caption2>
              <MbtiTestBtn onClick={() => setShowMbtiTest(true)}>
                  부캐 테스트로 MBTI 유형 확인하기
              </MbtiTestBtn>
              <Flex
                justify="space-between"
                style={{ width: '100%', marginTop: 24 }}
              >
                <CancelBtn
                  onClick={
                    () => {
                      setValue(initialMbti)
                      toggle()
                    }
                  }
                >
                    취소
                </CancelBtn>
                <ConfirmBtn onClick={toggle}>확인</ConfirmBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
        {
          showMbtiTest && (
            <Flex
              style={
                {
                  zIndex: 9999,
                  position: 'fixed',
                  top: 30,
                  left: '25%',
                  width: '100vw',
                  height: '90vh',
                }
              }
              onClick={() => setShowMbtiTest(false)}
            >
              <iframe
                title="MBTI-TEST"
                src="https://hreum.me/p/socialking"
                width="900px"
                height="100%"
                name="mbti_iframe"
              />
            </Flex>
          )
        }
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  useModal,
}

const MbtiList = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  width: 472px;
  height: 472px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${colors.white_f4};
`

const MbtiBox = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 113px;
  border-radius: 4px;
  background-color: ${colors.white_fa};

  font-family: NotoSansCJKkr-Bold;
  font-size: 16px;
  color: ${({ active }) => (active ? colors.primary : colors.grey_94)};

  ${({ active }) =>
    active
    && css`
      border: 1px solid ${colors.primay};
    `}
`

const MbtiTestBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: solid 1px ${colors.primary};
  background-color: ${colors.white_ff};

  font-size: 14px;
  color: ${colors.primary};
`

const CancelBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 48px;
  padding: 14px 99px;
  border-radius: 8px;
  background-color: ${colors.white_f4};

  font-size: 14px;
  color: ${colors.grey_64};
`

const ConfirmBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 48px;
  padding: 14px 99px;
  border-radius: 8px;
  background-color: ${colors.primary};

  font-size: 14px;
  color: ${colors.white_ff};
`
