import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import { images } from '@images/'

const Img = styled.img`
  margin: 0px 0px 50px 0px;
  width: 323px;
  height: 84px;

  @media ${mobile} {
    width: 220px;
    height: 58px;
    margin: 0px 0px 30px 0px;
  }
`

const LogoImg = () => {
  return (
    <Link to="/p/login">
      <Img src={images.hreum_logo} alt="logo_img" />
    </Link>
  )
}

export default LogoImg
