import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import Select from 'react-select'

const CustomSelect = styled(Select)`
  & > div {
    padding: 0.7rem;
    border: 1px solid #949494;
    border-radius: 7px;
    background-color: #ffffff;
    font-family: NotoSansCJKkr-Medium;
    white-space: nowrap;
    min-height: auto;

    & div {
      padding: 0;
      margin: 0;
    }

    & span {
      display: none;
    }

    & svg {
      color: #242424;
    }
  }

  @media ${mobile} {
    font-size: 12px;
  }
`

const SelectBox = props => {
  return (
    <CustomSelect
      styles={{
        control: (provided, state) => ({
          ...provided,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }),
        menu: (provided, state) => ({
          ...provided,
          marginTop: '3px !important',
          maxHeight: '500px',
          borderRadius: '5px !important',
          boxShadow: '0 0 0 0 rgb(204, 204, 204)',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'black' : 'rgb(51, 51, 51)',
          padding: '8px !important',
          backgroundColor: state.isSelected ? '#fafafa' : '#fff',
          '&:hover': {
            backgroundColor: state.isSelected ? '#fafafa' : '#fafafa',
          },
        }),
        placeholder: defaultStyles => {
          return {
            ...defaultStyles,
            color: props.placholderColor || 'rgba(148, 148, 148, 0.6)',
          }
        },
      }}
      id={props.id}
      name={props.name}
      isSearchable={false}
      placeholder={props.placeholder}
      options={props.options}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      defaultInputValue={props.defaultInputValue}
      value={props.value}
    />
  )
}

export default SelectBox
