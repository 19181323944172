import { observable } from 'mobx'
import { UserModel } from '.'

export default class LiveRoomUserModel {
  @observable id

  @observable level
  @observable _isMutedMic
  set isMutedMic(value) {
    this._isMutedMic = value
    if (value) {
      this.micVolume = 0
    }
  }
  get isMutedMic() {
    return this._isMutedMic
  }
  @observable levelUpdatedAt
  @observable prevLevel

  @observable liveRoomId
  @observable userId
  @observable User

  @observable micVolume = 0

  @observable LiveRoomsHaveUsersAgoraTokens = []
  get agoraUid() {
    let uid = 0
    if (
      this.LiveRoomsHaveUsersAgoraTokens
      && this.LiveRoomsHaveUsersAgoraTokens.length > 0
    ) {
      uid = this.LiveRoomsHaveUsersAgoraTokens[
        this.LiveRoomsHaveUsersAgoraTokens.length - 1
      ]['id']
    }

    if (!uid) {
      uid = this.userId
    }

    return uid || 0
  }

  constructor(stores, props) {
    this.micVolume = 0
    if (props) {
      this.id = props.id

      this.level = props.level
      this.isMutedMic = props.isMutedMic
      this.levelUpdatedAt = props.levelUpdatedAt
      this.prevLevel = props.prevLevel

      this.liveRoomId = props.liveRoomId
      this.userId = props.userId

      this.User = new UserModel(stores, props.User)

      this.micVolume = props.micVolume || 0

      this.LiveRoomsHaveUsersAgoraTokens =
        props.LiveRoomsHaveUsersAgoraTokens || []
    }
  }
}
