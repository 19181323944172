import { useEffect, useState } from 'react'

/**
 * 웹 화면의 넓이를 반환
 * isMobile = size <= 420
 */
const useWindowSize = () => {
  const [size, setSize] = useState()

  useEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth)
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
export default useWindowSize
