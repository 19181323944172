import { observable } from 'mobx'

import { UserModel, CommentsHaveTagsModel, TagModel, SongModel } from '.'
import ChildCommentModel from './ChildCommentModel'

export default class CommentModel {
  @observable id
  @observable content
  @observable rawContent
  @observable isReply
  @observable createdAt
  @observable updatedAt
  @observable pinnedAt
  @observable blockedAt
  @observable editedAt

  @observable targetId
  @observable targetTableName
  @observable userId

  @observable User = {}
  @observable Target = {}
  @observable CommentsHaveTags = []
  @observable UsersLikeComments = []
  @observable DonationCastHistory = []
  @observable ChildComments = []
  @observable replyPreview = []
  @observable replyCount

  @observable tags = []

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.content = props.content
      this.rawContent = props.rawContent
      this.isReply = props.isReply
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.pinnedAt = props.pinnedAt
      this.blockedAt = props.blockedAt
      this.editedAt = props.editedAt

      this.targetId = props.targetId
      this.targetTableName = props.targetTableName
      this.userId = props.userId

      this.User = (props.User && new UserModel(stores, props.User)) || {}
      this.Target =
        (props.Target
          && props.targetTableName === 'Songs'
          && new SongModel(stores, props.Target))
        || {}
      this.CommentsHaveTags =
        (props.CommentsHaveTags
          && props.CommentsHaveTags.map(
            elem => new CommentsHaveTagsModel(stores, elem),
          ))
        || []
      this.CommentsHaveUsers =
        (props.CommentsHaveUsers
          && props.CommentsHaveUsers.map(elem => elem))
        || []

      this.UsersLikeComments = props.UsersLikeComments
      this.DonationCastHistory = props.DonationCastHistory

      this.ChildComments =
        (props.ChildComments
          && props.ChildComments.map(
            elem => new ChildCommentModel(stores, elem),
          ))
        || []

      this.replyPreview =
        (props.replyPreview
          && props.replyPreview.map(
            elem => new ChildCommentModel(stores, elem),
          ))
        || []

      this.replyCount = props.replyCount

      this.tags =
        (props.tags && props.tags.map(elem => new TagModel(stores, elem))) || []
    }
  }
  fetchTags = async () => {
    if (this.stores) {
      this.tags = await Promise.all(
        this.CommentsHaveTags.map(CommentsHaveTag => {
          return this.stores.commentStore.fetchTags(CommentsHaveTag['tagId'])
        }),
      )
    }
  }
}
