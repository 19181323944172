import React, { useEffect, useState } from 'react'
import { HouseCastManageListTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'
import { useParams } from 'react-router-dom'

const HouseCastManagePage = () => {
  useRenderLogger('HouseCastManagePage')

  // const [isMyPage, setIsMyPage] = useState(false)

  const { houseId } = useParams()

  const {
    authStore,
    houseStore,
    castStore,
    liveRoomRecordingStore,
  } = useStore()

  // variables
  const currentUser = authStore.currentUser || null
  const houseCastList = houseStore.houseCastList || null
  const houseCastListCount = houseStore.houseCastListCount || 0
  const myLiveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null
  const houseDetail = houseStore.houseDetailObj[houseId]?.elem || null

  // functions
  const updateCast = castStore.updateCast || (() => {})
  const deleteCast = castStore.deleteCast || (() => {})
  const fetchMyUploadedRecordingList =
    liveRoomRecordingStore.fetchMyLiveRoomRecordingList || (() => {})
  const requestYoutubeSync = castStore.requestYoutubeSync || (() => {})
  const requestRssSync = castStore.requestRssSync || (() => {})
  const publishRssUrl = castStore.publishRssUrl || (() => {})
  const hideYoutubeChannelSync = houseStore.hideYoutubeChannelSync || (() => {})
  const hideRssInfoSync = houseStore.hideRssInfoSync || (() => {})

  const [currentPage, setCurrentPage] = useState(1)
  const { isLoading, setIsLoading } = useTagLoading()

  /**
   * recording
   */
  const liveRoomRecordingList =
    liveRoomRecordingStore.myLiveRoomRecordingList || null
  const deleteLiveRoomRecording =
    liveRoomRecordingStore.deleteLiveRoomRecording || (() => {})
  const updateLiveRoomRecording =
    liveRoomRecordingStore.updateLiveRoomRecording || (() => {})

  useEffect(() => {
    if (houseId) {
      houseStore.init()
      houseStore.fetchHouseDetail(houseId, {
        __include: `HousesHaveUsers.Users,CastRSSInfos`,
        'HousesHaveUsers.userId': authStore?.currentUser?.id,
        __attributes: `HousesHaveUsers.level,`,
      })
    }
  }, [houseId, houseStore, authStore])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (currentPage > 1) {
        await houseStore.fetchManagedCastList(houseId, 10 * (currentPage - 1))
      }
      else if (currentPage <= 1) {
        await houseStore.fetchManagedCastList(houseId, 0)
      }
      setIsLoading(false)
    }

    if (houseDetail) {
      fetchData()
    }

    // eslint-disable-next-line
  }, [houseDetail, houseStore, houseId, currentPage, setIsLoading])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchMyUploadedRecordingList({ userId: currentUser['id'] })
      // if (currentPage > 1) {
      //   await fetchMyUploadedRecordingList(10 * (currentPage - 1))
      // }
      // else if (currentPage <= 1) {
      //   await fetchMyUploadedRecordingList()
      // }
      setIsLoading(false)
    }
    fetchData()

    // eslint-disable-next-line
  }, [fetchMyUploadedRecordingList, currentPage, setIsLoading])

  useEffect(() => {
    if (houseDetail) {
      // setIsMyPage(houseDetail.userId === authStore?.currentUser?.id)
      if (
        houseDetail?.contentPermissionLevel
        > houseDetail?.HousesHaveUsers[0]?.level
      ) {
        window.location.href = '/'
      }
    }
  }, [houseDetail])

  // 곡이 있으면 목록 페이지
  //     없으면 처음 입장 페이지
  if (!houseCastList) {
    return <div>error...</div>
  }

  return (
    <HouseCastManageListTemplate
      houseDetail={houseDetail}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      user={currentUser}
      castList={houseCastList}
      castListCount={houseCastListCount}
      updateCast={updateCast}
      deleteCast={deleteCast}
      fetchMyUploadedRecordingList={fetchMyUploadedRecordingList}
      myLiveRoomRecordingList={myLiveRoomRecordingList}
      requestYoutubeSync={requestYoutubeSync}
      requestRssSync={requestRssSync}
      publishRssUrl={publishRssUrl}
      hideYoutubeChannelSync={hideYoutubeChannelSync}
      hideRssInfoSync={hideRssInfoSync}
      /**
       * recording
       */
      liveRoomRecordingList={liveRoomRecordingList}
      editLiveRoomRecording={updateLiveRoomRecording}
      deleteLiveRoomRecording={deleteLiveRoomRecording}
    />
  )
}

export default observer(HouseCastManagePage)
