import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { HomeMixtapeListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

interface Props {
  location: Any;
}

/**
 * 전체 || 좋아하는 || 구독한 || 기록 || 검색결과 || 태그 플라 전부 이곳에서.
 * @param {*} param0
 */
const MixtapeCategoryTotalListPage = ({ location }: Props) => {
  useRenderLogger('MixtapeCategoryTotalListPage')

  const history = useHistory()
  const { mixtapeStore, searchStore, tagStore } = useStore()

  const [offset, setOffset] = useState(0)
  const [haveMore, setHaveMore] = useState(true)

  const handleSearchTag = async tag => {
    setOffset(0)
    setHaveMore(true)
    // fetch 태그 검색
    await searchStore.searchTagKeyword(tag)
    await searchStore.fetchTagLatestMixtapeList(tag)
    history.push(`/p/plra/list/tag?keyword=${tag}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    let isMore = true

    tagStore.fetchRepresentiveTagList()
    isMore = mixtapeStore.fetchTotalMixtapeList({ limit: 200 })
    // isMore = mixtapeStore.fetchHomePopularTotalMixtapeList()
    setHaveMore(isMore)

    setOffset(0)
  }, [mixtapeStore, tagStore, searchStore, location])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return useObserver(() => (
    <HomeMixtapeListTemplate
      offset={offset}
      setOffset={setOffset}
      haveMore={haveMore}
      setHaveMore={setHaveMore}
      category="total"
      keyword={searchStore.mixtapeSearchKeyword}
      setMixtapeSearchKeyword={searchStore.setMixtapeSearchKeyword || null}
      fetchTagKeywordList={searchStore.fetchTagKeywordList || []}
      searchTagKeywordList={searchStore.searchTagKeywordList || []}
      searchedMixtapeList={searchStore.searchedMixtapeList || []}
      handleSearchTag={handleSearchTag}
      tag={searchStore.mixtapeTagKeyword}
      representiveTagList={tagStore.representiveTagList || []}
      myListenedMixtapeList={mixtapeStore.myListenedMixtapeList || []}
      totalMixtapeList={mixtapeStore.totalMixtapeList || []}
      myLikeMixtapeList={mixtapeStore.myLikeMixtapeList || []}
      myFollowMixtapeList={mixtapeStore.myFollowMixtapeList || []}
      tagMixtapeList={searchStore.tagMixtapeList || []}
      fetchHomePopularTotalMixtapeList={
        mixtapeStore.fetchHomePopularTotalMixtapeList || (() => {})
      }
      fetchTotalMixtapeList={mixtapeStore.fetchTotalMixtapeList || (() => {})}
      fetchMyFollowMixtapeList={
        mixtapeStore.fetchMyFollowMixtapeList || (() => {})
      }
      fetchSearchMixtapeList={searchStore.fetchSearchMixtapeList || (() => {})}
      fetchTagLatestMixtapeList={
        searchStore.fetchTagLatestMixtapeList || (() => {})
      }
      fetchTagPopulartMixtapeList={
        searchStore.fetchTagPopulartMixtapeList || (() => {})
      }
      fetchMyLikeMixtapeList={mixtapeStore.fetchMyLikeMixtapeList || (() => {})}
    />
  ))
}

export default MixtapeCategoryTotalListPage
