
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
  SocialkingRankingTemplate,
} from '../../components/socialking'
import { IS_DEV } from '../../resources'

const SocialkingRankingPage = () => {

  // LOGGING Init and ENTER_PAGE
  // useEffect(() => {
  // initGA()
  // gaLogPageView()
  // LOG_EVENT.LANDING_ENTER_SOCIALKING_TEST_MAIN(IS_DEV)
  // }, [])

  return (
    <div
      style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
        }
      }
    >
      <Helmet>
        <title>인맥왕 테스트 | 서열표</title>
        <meta
          name="description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta property="og:title" content="인맥왕 테스트" />
        <meta property="og:url" content="https://hreum.me/sociaking/ranking/" />
        <meta
          property="og:description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta
          property="og:image"
          content={`https://storage.hreum.me/resources/socialking/socialking_og_img.png`}
        />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          name="keywords"
          content="인맥왕,흐름 mbti,흐름 mbti,흐름 인맥왕 테스트, 흐름 인맥왕 테스트,mbti,MBTI,엠비티아이,인맥왕 테스트,인맥왕테스트"
        />

        <meta
          name="twitter:domain"
          content="https://hreum.me/sociaking/ranking/"
        />
        <meta name="twitter:title" content="인맥왕 테스트 | 서열표" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="인맥왕 테스트 | 서열표" />
        <meta
          name="twitter:description"
          content="나의 인맥은 상위 몇 퍼센트일까? mbti"
        />
        <meta
          name="twitter:image"
          content="https://storage.hreum.me/resources/socialking/socialking_og_img.png"
        />
      </Helmet>
      <SocialkingRankingTemplate />
    </div>
  )
}

export default SocialkingRankingPage
