import React, { useState } from 'react'
import { Header, Footer } from '@components/organisms'
import {
  BodyWrapper,
  H1,
  H6,
  FormInput,
  WarningMsg,
  Form,
} from '@components/atoms'
import { RoundBtn } from '@components/molecules'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const RegisterPasswordTemplate = ({ registerPassword, setIsCompleted }) => {
  const [password, setPassword] = useState('')
  const [checkConfirm, setCheckConfirm] = useState(false) // 비밀번호 확인 체크

  const authPassword = async password => {
    if (checkConfirm) return
    const res = await registerPassword(password)
    if (res && res.status && res.status === 401) {
      setIsCompleted(false)
    }
    else {
      setIsCompleted(true)
    }
  }

  // 비밀번호 확인
  const confirmPw = value => {
    if (value === password) {
      setCheckConfirm(false)
    }
    else {
      setCheckConfirm(true)
    }
  }

  return (
    <>
      <Header />
      <BodyWrapper>
        <H1>흐름 비밀번호 설정</H1>
        <H6 style={{ marginTop: '20px' }}>
          흐름에서 사용할 회원님의 비밀번호를 기입해주세요.
        </H6>
        <Form>
          <InputBox style={{ width: '518px', margin: '50px 0 0 0' }}>
            <FormInput
              placeholder="비밀번호 입력"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </InputBox>
          <InputBox>
            {/* 비밀번호 확인 */}
            <FormInput
              placeholder="비밀번호 확인"
              type="password"
              onBlur={e => confirmPw(e.target.value)}
              style={checkConfirm ? { border: '1px solid #ea4653' } : null}
            />
            {
              checkConfirm && (
                <WarningMsg>비밀번호가 일치하지 않습니다.</WarningMsg>
              )
            }
          </InputBox>

          <RoundBtn
            onClick={() => authPassword(password)}
            type="button"
            text="저장"
            color="#ffffff"
            style={
              {
                width: '230px',
                height: '48px',
                borderRadius: '24px',
                border: `solid 2px ${colors.primary}`,
                backgroundColor: colors.primary,
                marginTop: '50px',
              }
            }
          />
        </Form>
      </BodyWrapper>
      <Footer />
    </>
  )
}
export default RegisterPasswordTemplate
