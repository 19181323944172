import { AgoraRtm } from '../utils'

const LIVE_ROOM_ACTION_TYPE = {
  JOIN_LIVE_ROOM: 'JOIN_LIVE_ROOM',
  DELETE_LIVE_ROOM: 'DELETE_LIVE_ROOM',
  UPDATE_LIVE_ROOM: 'UPDATE_LIVE_ROOM',
  LEAVE_LIVE_ROOM: 'LEAVE_LIVE_ROOM',
  DONATE_LIVE_ROOM: 'DONATE_LIVE_ROOM',
  UPDATE_LIVE_ROOM_USER: 'UPDATE_LIVE_ROOM_USER',
  UPDATE_LIVE_ROOM_USER_LIST: 'UPDATE_LIVE_ROOM_USER_LIST',
  BAN_LIVE_ROOM_USER: 'BAN_LIVE_ROOM_USER',
  SEND_CHAT: 'SEND_CHAT',
  SEND_CHAT_DELETE: 'SEND_CHAT_DELETE',
  SEND_CHAT_FIXED: 'SEND_CHAT_FIXED',
  SEND_CHAT_FIXED_DELETE: 'SEND_CHAT_FIXED_DELETE',
  SEND_REQUEST_HOST: 'SEND_REQUEST_HOST',
  SEND_ACCEPTED_HOST: 'SEND_ACCEPTED_HOST',
  SEND_REQUEST_GUEST: 'SEND_REQUEST_GUEST',
  SEND_ACCEPTED_GUEST: 'SEND_ACCEPTED_GUEST',
  LIVE_ROOM_RECORDING: 'LIVE_ROOM_RECORDING',
  CONTROL_MIXTAPE: 'CONTROL_MIXTAPE',
  CONTROL_AUDIO: 'CONTROL_AUDIO',
  MUTE_ALL_MIC: 'MUTE_ALL_MIC',
  PREVENT_ACCESS_MULTIPLE: 'PREVENT_ACCESS_MULTIPLE',
}

export const CONTROL_MIXTAPE_TYPE = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  VOLUME: 'VOLUME',
  DELETE: 'DELETE',
}
export const CONTROL_AUDIO_TYPE = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  VOLUME: 'VOLUME',
  DELETE: 'DELETE',
}

export const LIVE_ROOM_ACTION = {
  JOIN_LIVE_ROOM: {
    TYPE: LIVE_ROOM_ACTION_TYPE['JOIN_LIVE_ROOM'],
    SENDER: async ({ liveRoomId, userId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[JOIN_LIVE_ROOM]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['JOIN_LIVE_ROOM'],
        liveRoomId,
        userId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId, userId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[JOIN_LIVE_ROOM]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}`,
      ),
  },
  DELETE_LIVE_ROOM: {
    TYPE: LIVE_ROOM_ACTION_TYPE['DELETE_LIVE_ROOM'],
    SENDER: async ({ liveRoomId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[DELETE_LIVE_ROOM]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['DELETE_LIVE_ROOM'],
        liveRoomId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[DELETE_LIVE_ROOM]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}`,
      ),
  },
  UPDATE_LIVE_ROOM: {
    TYPE: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM'],
    SENDER: async ({
      liveRoomId,
      title,
      description,
      isPrivate,
      password,
      isMutedMic,
      mixtapeId,
      LiveRoomsHaveTags,
      imageUri,
      isBlockedUnknownUser,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[UPDATE_LIVE_ROOM]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM'],
        liveRoomId,
        title,
        description,
        isPrivate,
        password,
        isMutedMic,
        mixtapeId,
        LiveRoomsHaveTags,
        imageUri,
        isBlockedUnknownUser,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      title,
      description,
      isPrivate,
      password,
      isMutedMic,
      mixtapeId,
      LiveRoomsHaveTags,
      imageUri,
      isBlockedUnknownUser,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[UPDATE_LIVE_ROOM]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, title=${title}, description=${description}, isPrivate=${isPrivate}, password=${password}, isMutedMic=${isMutedMic}, mixtapeId=${mixtapeId}, LiveRoomsHaveTags=${LiveRoomsHaveTags}, imageUri=${imageUri}, isBlockedUnknownUser=${isBlockedUnknownUser}`,
      ),
  },
  LEAVE_LIVE_ROOM: {
    TYPE: LIVE_ROOM_ACTION_TYPE['LEAVE_LIVE_ROOM'],
    SENDER: async ({ liveRoomId, userId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[LEAVE_LIVE_ROOM]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['LEAVE_LIVE_ROOM'],
        liveRoomId,
        userId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId, userId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[LEAVE_LIVE_ROOM]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}`,
      ),
  },
  DONATE_LIVE_ROOM: {
    TYPE: LIVE_ROOM_ACTION_TYPE['DONATE_LIVE_ROOM'],
    SENDER: async ({
      liveRoomId,
      userId,
      message,
      systemMessage,
      donatedAt,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[DONATE_LIVE_ROOM]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['DONATE_LIVE_ROOM'],
        liveRoomId,
        userId,
        message,
        systemMessage,
        donatedAt,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      message,
      systemMessage,
      donatedAt,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[DONATE_LIVE_ROOM]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, message=${message}, systemMessage=${systemMessage}, donatedAt=${donatedAt}`,
      ),
  },
  SEND_REQUEST_GUEST: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_REQUEST_GUEST'],
    SENDER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_REQUEST_HOST]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_REQUEST_GUEST'],
        liveRoomId,
        userId,
        level,
        levelUpdatedAt,
        prevLevel,
        isMutedMic,
        isChangedUserInfo,
        senderName,
        senderId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_REQUEST_GUEST]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, level=${level}, levelUpdatedAt=${levelUpdatedAt}, prevLevel=${prevLevel}, isMutedMic=${isMutedMic}, isChangedUserInfo=${isChangedUserInfo}, senderName=${senderName} senderId=${senderId}`,
      ),
  },
  SEND_ACCEPTED_GUEST: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_ACCEPTED_GUEST'],
    SENDER: async ({
      liveRoomId,
      level,
      userId,
      isAccepted,
      receiverName,
      senderId,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_ACCEPTED_GUEST]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_ACCEPTED_GUEST'],
        liveRoomId,
        level,
        userId,
        isAccepted,
        receiverName,
        senderId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      level,
      isAccepted,
      receiverName,
      senderId,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_ACCEPTED_GUEST]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, level=${level} isAccepted=${isAccepted} receiverName=${receiverName} senderId=${senderId}`,
      ),
  },
  SEND_REQUEST_HOST: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_REQUEST_HOST'],
    SENDER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_REQUEST_HOST]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_REQUEST_HOST'],
        liveRoomId,
        userId,
        level,
        levelUpdatedAt,
        prevLevel,
        isMutedMic,
        isChangedUserInfo,
        senderName,
        senderId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_REQUEST_HOST]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, level=${level}, levelUpdatedAt=${levelUpdatedAt}, prevLevel=${prevLevel}, isMutedMic=${isMutedMic}, isChangedUserInfo=${isChangedUserInfo}, senderName=${senderName} senderId=${senderId}`,
      ),
  },
  SEND_ACCEPTED_HOST: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_ACCEPTED_HOST'],
    SENDER: async ({
      liveRoomId,
      level,
      userId,
      isAccepted,
      receiverName,
      senderId,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_ACCEPTED_HOST]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_ACCEPTED_HOST'],
        liveRoomId,
        level,
        userId,
        isAccepted,
        receiverName,
        senderId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      level,
      isAccepted,
      receiverName,
      senderId,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_ACCEPTED_HOST]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, level=${level} isAccepted=${isAccepted} receiverName=${receiverName} senderId=${senderId}`,
      ),
  },
  UPDATE_LIVE_ROOM_USER: {
    TYPE: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM_USER'],
    SENDER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[UPDATE_LIVE_ROOM_USER]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM_USER'],
        liveRoomId,
        userId,
        level,
        levelUpdatedAt,
        prevLevel,
        isMutedMic,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[UPDATE_LIVE_ROOM_USER]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}, level=${level}, levelUpdatedAt=${levelUpdatedAt}, prevLevel=${prevLevel}, isMutedMic=${isMutedMic}`,
      ),
  },
  // 유저들 상태 일괄 처리
  UPDATE_LIVE_ROOM_USER_LIST: {
    TYPE: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM_USER_LIST'],
    SENDER: async (
      userIdList,
      { liveRoomId, level, levelUpdatedAt, prevLevel, isMutedMic },
    ) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[UPDATE_LIVE_ROOM_USER_LIST]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['UPDATE_LIVE_ROOM_USER_LIST'],
        liveRoomId,
        userIdList,
        level,
        levelUpdatedAt,
        prevLevel,
        isMutedMic,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      userIdList,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[UPDATE_LIVE_ROOM_USER_LIST]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userIdList=${userIdList}, level=${level}, levelUpdatedAt=${levelUpdatedAt}, prevLevel=${prevLevel}, isMutedMic=${isMutedMic}`,
      ),
  },
  BAN_LIVE_ROOM_USER: {
    TYPE: LIVE_ROOM_ACTION_TYPE['BAN_LIVE_ROOM_USER'],
    SENDER: async ({ liveRoomId, userId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[BAN_LIVE_ROOM_USER]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['BAN_LIVE_ROOM_USER'],
        liveRoomId,
        userId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId, userId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[BAN_LIVE_ROOM_USER]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, userId=${userId}`,
      ),
  },
  SEND_CHAT: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_CHAT'],
    SENDER: async ({
      liveRoomId,
      chatMessage,
      userLevel,
      userId,
      messageType,
      imageUri,
      data,
    }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_CHAT]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}, messageType=${messageType}`,
        )
        return false
      }
      const _data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_CHAT'],
        liveRoomId,
        chatMessage,
        userLevel,
        userId,
        messageType,
        imageUri,
        data,
      }
      return await AgoraRtm.sendChannelMessage(_data)
    },
    LISTENER: async ({
      liveRoomId,
      chatMessage,
      userLevel,
      userId,
      messageType,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_CHAT]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, chatMessage=${chatMessage}, userLevel=${userLevel}, userId=${userId}, messageType=${messageType}`,
      ),
  },
  SEND_CHAT_FIXED: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_CHAT_FIXED'],
    SENDER: async ({ liveRoomId, chatMessage, userId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_CHAT_FIXED]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_CHAT_FIXED'],
        liveRoomId,
        chatMessage,
        userId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({
      liveRoomId,
      chatMessage,
      userLevel,
      userId,
      messageType,
      imageUri,
    }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_CHAT_FIXED]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, chatMessage=${chatMessage}, userId=${userId}`,
      ),
  },
  SEND_CHAT_FIXED_DELETE: {
    TYPE: LIVE_ROOM_ACTION_TYPE['SEND_CHAT_FIXED_DELETE'],
    SENDER: async ({ liveRoomId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[SEND_CHAT_FIXED_DELETE]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['SEND_CHAT_FIXED_DELETE'],
        liveRoomId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[SEND_CHAT_FIXED_DELETE]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}`,
      ),
  },
  LIVE_ROOM_RECORDING: {
    TYPE: LIVE_ROOM_ACTION_TYPE['LIVE_ROOM_RECORDING'],
    SENDER: async ({ liveRoomRecording, isStop }) => {
      // const _liveRoomRecording = liveRoomRecording
      // if (!_liveRoomRecording) {
      //   console.error(
      //     '[ERROR]',
      //     '[LIVE_ROOM_ACTION]',
      //     '[LIVE_ROOM_RECORDING]',
      //     '[SENDER]',
      //     `Invalid liveRoomRecording. liveRoomRecording=${liveRoomRecording}`,
      //   )
      //   return false
      // }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['LIVE_ROOM_RECORDING'],
        liveRoomRecording,
        isStop,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomRecording, isStop }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[LIVE_ROOM_RECORDING]',
        '[LISTENER]',
        `liveRoomRecording=${liveRoomRecording} isStop=${isStop}`,
      ),
  },
  CONTROL_MIXTAPE: {
    TYPE: LIVE_ROOM_ACTION_TYPE['CONTROL_MIXTAPE'],
    SENDER: async ({ liveRoomId, mixtapeId, controlType, volume }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[CONTROL_MIXTAPE]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['CONTROL_MIXTAPE'],
        liveRoomId,
        mixtapeId,
        controlType,
        volume,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId, mixtapeId, controlType, volume }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[CONTROL_MIXTAPE]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, mixtapeId=${mixtapeId}, controlType=${controlType}, volume=${volume}`,
      ),
  },

  CONTROL_AUDIO: {
    TYPE: LIVE_ROOM_ACTION_TYPE['CONTROL_AUDIO'],
    SENDER: async ({ liveRoomId, songId, controlType, startedAt, volume }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[CONTROL_AUDIO]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['CONTROL_AUDIO'],
        liveRoomId,
        songId,
        controlType,
        startedAt,
        volume,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId, songId, controlType, startedAt, volume }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[CONTROL_AUDIO]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}, songId=${songId}, controlType=${controlType}, startedAt=${startedAt}, volume=${volume}`,
      ),
  },

  MUTE_ALL_MIC: {
    TYPE: LIVE_ROOM_ACTION_TYPE['MUTE_ALL_MIC'],
    SENDER: async ({ liveRoomId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[MUTE_ALL_MIC]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['MUTE_ALL_MIC'],
        liveRoomId,
      }
      return await AgoraRtm.sendChannelMessage(data)
    },
    LISTENER: async ({ liveRoomId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[MUTE_ALL_MIC]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}`,
      ),
  },

  PREVENT_ACCESS_MULTIPLE: {
    TYPE: LIVE_ROOM_ACTION_TYPE['PREVENT_ACCESS_MULTIPLE'],
    SENDER: async (agoraUid, { liveRoomId, userId }) => {
      const _liveRoomId = parseInt(liveRoomId)
      if (!_liveRoomId) {
        console.error(
          '[ERROR]',
          '[LIVE_ROOM_ACTION]',
          '[PREVENT_ACCESS_MULTIPLE]',
          '[SENDER]',
          `invalid liveRoomId. liveRoomId=${liveRoomId}`,
        )
        return false
      }
      const data = {
        type: LIVE_ROOM_ACTION_TYPE['PREVENT_ACCESS_MULTIPLE'],
        liveRoomId,
        userId,
      }
      return await AgoraRtm.sendPeerMessage(agoraUid, data)
    },
    LISTENER: async ({ liveRoomId }) =>
      console.log(
        '[LOG]',
        '[LIVE_ROOM_ACTION]',
        '[PREVENT_ACCESS_MULTIPLE]',
        '[LISTENER]',
        `liveRoomId=${liveRoomId}`,
      ),
  },
}
