import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import { colors } from '@resources/colors'

const Button = styled.button`
  -webkit-appearance: none;
  border: 2px solid ${props => props.color};
  border-radius: 24px;
  cursor: pointer;
  width: 320px;
  height: 100%;
  min-height: 48px;
  background: none;
  font: inherit;
  color: ${props => props.color};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    width: 100%;
    height: 100%;
  }

  @media ${mobile} {
    width: 100%;
  }

  :focus {
    outline: none;
  }

  :hover {
    border: 2px solid
      ${props => (props.borderColor ? props.borderColor : colors.primary)};
    background: ${props =>
    props.borderColor ? props.borderColor : colors.primary};
    color: ${({ hoverColor }) => hoverColor || '#ffffff'};
  }
`

const RoundBtn = ({
  borderColor,
  color,
  style,
  onClick,
  href,
  text,
  img,
  imgStyle,
  type,
  hoverColor,
}) => {
  const handleClick = e => {
    if (onClick) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      onClick(e)
    }
  }

  return (
    <Button
      type={type}
      style={style || null}
      onClick={handleClick}
      color={color}
      borderColor={borderColor}
      hoverColor={hoverColor}
    >
      {img ? <img src={img} alt="img_" style={imgStyle} /> : false}
      {href ? <Link to={href}>{text}</Link> : text}
    </Button>
  )
}

export default RoundBtn
