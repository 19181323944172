import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Flex, H4, H6, Caption1 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    min-height: 170px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px 16px 16px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const ConfirmBtn = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  border: none;
  background-color: ${colors.secondary};
  color: #ffffff;
  cursor: pointer;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

/**
 * 단순 확인 구조의 팝업 공용 컴포넌트
 * title: 제목
 * body : 내용
 * onClose : 닫기 핸들러
 */
const View = ({ isShowing, toggle, onClose, title, body }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <H4 type="Bold">{title}</H4>
              <Caption1
                color="#949494"
                style={{ whiteSpace: 'pre', margin: '15px 0 20px 0' }}
              >
                {body}
              </Caption1>
              <Flex justify="center">
                <ConfirmBtn onClick={handleClose}>
                  <H6>확인</H6>
                </ConfirmBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
