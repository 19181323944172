import React from 'react'
import {
  MixtapeDetailInfo,
  MixtapeDetailPlayList,
  MixtapeDetailTagSection,
  SearchMixtapeForm,
} from '@components/organisms'
import { Flex, H3 } from '@components/atoms'
import styled from 'styled-components'
import { useObserver } from 'mobx-react'
import { useWindowSize } from '@utils/hooks'
import { PageLayout } from '..'

const DetailBox = styled(Flex)`
  max-width: 1194px;
  width: 100%;
  flex-direction: column;
`

const Section = styled(Flex)`
  width: 100%;
  margin-top: 30px;
  height: 1000px;
  justify-content: space-between;

  flex-direction: ${({ isResponsive }) => (isResponsive ? 'column' : 'row')};
  align-items: ${({ isResponsive }) => (isResponsive ? 'center' : 'upset')};
`

const MixtapeDetailTemplate = ({
  mixtape,
  likeMixtape,
  dislikeMixtape,
  follow,
  unFollow,
  likeSong,
  disLikeSong,
  category,
  sort,
  handleSearchTag,
  toggleComment,
  targetCommentList,
}) => {
  const isResponsive = useWindowSize() - (48 + 48 + 216) < 1194

  return useObserver(() => (
    <>
      {// Tag 더보기 렌더 순서로 때문에 널체크 미리
        mixtape && (
          <PageLayout style={{ paddingTop: '0' }}>
            <SearchMixtapeForm />
            <DetailBox id="detail_box">
              <H3 type="Bold">{mixtape && mixtape.name}</H3>

              <MixtapeDetailTagSection
                tagList={mixtape && mixtape.MixtapesHaveTags}
                handleSearchTag={handleSearchTag}
              />

              <Section isResponsive={isResponsive}>
                <MixtapeDetailInfo
                  targetCommentList={targetCommentList}
                  toggleComment={toggleComment}
                  mixtape={mixtape}
                  likeMixtape={likeMixtape}
                  dislikeMixtape={dislikeMixtape}
                  follow={follow}
                  unFollow={unFollow}
                />

                <MixtapeDetailPlayList
                  mixtape={mixtape}
                  likeSong={likeSong}
                  disLikeSong={disLikeSong}
                  category={category}
                  sort={sort}
                />
              </Section>
            </DetailBox>
          </PageLayout>
        )
      }
    </>
  ))
}

export default MixtapeDetailTemplate
