import React, { useRef } from 'react'
import { images } from '@resources/'
import styled from 'styled-components'
import { Caption1 } from '@components/atoms'
import { mobile } from '@styles/media'
import { useDrag, useDrop } from 'react-dnd'
import { FlexRowBox } from '..'

const onelineStyle = {
  display: 'inline-block',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '500px',
  textAlign: 'left',
}

const NameBox = styled.div`
  float: left;
  height: 40px;
  p {
    text-align: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  @media ${mobile} {
    width: 192px;
  }
`

const TempMixtapeSongItem = ({
  tempMixtape,
  song,
  songs,
  id,
  index,
  replaceMixtapeSongListToTempMixtape,
  moveList,
  handleDelete,
}) => {
  const handleDnd = async (d, h) => {
    await moveList(d, h)
    // replaceMixtapeSongListToTempMixtape(tempMixtape, songs)
  }

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      handleDnd(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        replaceMixtapeSongListToTempMixtape(tempMixtape, songs)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <FlexRowBox ref={ref} style={{ opacity }}>
      <img
        src={song.Song.imageUrl || images.mixtape}
        style={
          {
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            marginRight: '12px',
          }
        }
        alt="cover_image"
      />
      <NameBox>
        <Caption1 style={onelineStyle}>{song.Song.name}</Caption1>
        <Caption1 style={onelineStyle} color="#949494">
          {song.Song.singer}
        </Caption1>
      </NameBox>
      <FlexRowBox style={{ flex: '2', justifyContent: 'flex-end' }}>
        <img
          src={images.dnd_img}
          style={
            {
              width: '24px',
              height: '24px',
              marginRight: '20px',
              cursor: 'pointer',
            }
          }
          alt="dnd_img"
        />
        <img
          src={images.remove_red_img}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
          alt="remove_img"
          onClick={() => handleDelete(song)}
        />
      </FlexRowBox>
    </FlexRowBox>
  )
}

export default TempMixtapeSongItem
