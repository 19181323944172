import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import FlexRowBox from './FlexRowBox'
import { colors } from '@resources/colors'

const Img = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const CustomSelect = styled(Select)`
  & > div {
    border: 1px solid #949494;
    border-radius: 7px;
    background-color: #ffffff;
    font-family: NotoSansCJKkr-Medium;
    min-height: 48px;

    & span {
      display: none;
    }

    & svg {
      color: #242424;
    }
  }
`

// const dot = (color = '#ccc') => ({
//   alignItems: 'center',
//   display: 'flex',

//   ':before': {
//     backgroundColor: color,
//     borderRadius: 10,
//     content: '" "',
//     display: 'block',
//     height: 48,
//   },
// })

const bankStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      display: 'flex',
      height: '48px',
      alignItems: 'center',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'white'
        : isFocused
        ? '#f4f4f4'
        : null,
      color: isDisabled
        ? 'red'
        : isSelected
        ? colors.secondary > 2
          ? 'white'
          : colors.secondary
        : '#242424',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#f4f4f4' : '#f4f4f4'),
      },
    }
  },
  // input: (styles) => ({ ...styles}),
  placeholder: (styles) => ({ ...styles, color: 'rgba(148, 148, 148, 0.6)' }),
  // singleValue: (styles, { data }) => ({ ...styles}),
}

const formatOptionLabel = ({ value, label, icon }) => {
  const bank_type = require(`@images/bank/${icon}_bank.png`)
  return (
    <FlexRowBox>
      <Img src={bank_type} alt={icon} />
      <div>{label}</div>
    </FlexRowBox>
  )
}

const BankBox = (props) => {
  return (
    <CustomSelect
      styles={bankStyles}
      id={props.id}
      name={props.name}
      isSearchable={false}
      placeholder={props.placeholder}
      options={props.options}
      onChange={props.onChange}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default BankBox
