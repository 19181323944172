import { SalonReservationListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'

interface ISalonReservationListPage {}

const SalonReservationListPage: React.FC<ISalonReservationListPage> = () => {
  useRenderLogger('SalonReservationListPage')

  const { authStore, liveRoomStore } = useStore()

  const [nextCursor, setNextCursor] = React.useState(null)
  const [hasMore, setHasMore] = React.useState(true)
  // const [dynamicLink, setDynamicLink] = React.useState('')
  const [isInitializedPage, setIsInitializedPage] = React.useState(false)

  // 리스트 스크롤링
  const handleScrollList = async () => {
    const __nextCursor = await liveRoomStore.fetchAllReservationLiveRoomList(nextCursor)
    if(__nextCursor) {
      setNextCursor(__nextCursor)
    }
    else {
      setHasMore(false)
      setIsInitializedPage(true)
    }
  }

  React.useEffect(() => {
    // if (authStore.currentUser) liveRoomStore.fetchMyLiveRoomList()
    const fetchData = async () => {
      const __nextCursor = await liveRoomStore.fetchAllReservationLiveRoomList()
      if(__nextCursor) {
        setNextCursor(__nextCursor)
      }
      else {
        setHasMore(false)
      }
      setIsInitializedPage(true)
    }
    fetchData()
  }, [liveRoomStore, authStore.currentUser])

  return (
    <SalonReservationListTemplate
      salonList={liveRoomStore.allReservationLiveRoomList || []}
      hasMore={isInitializedPage && hasMore}
      isInitializedPage={isInitializedPage}
      handleScrollList={handleScrollList}
    />
  )
}

export default observer(SalonReservationListPage)
