import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { mobile, tablet, mobileDesktop } from '@styles/media'
import {
  Color,
  TextWrapper,
  H1,
  H2,
  H4,
  H6,
  SubscribeMsg,
  Flex,
  H5,
  Caption1,
  Caption2,
} from '@components/atoms'
import {
  SwitchSection,
  MoveBtn,
  Standard,
  ForStudent,
} from '@components/molecules'
import { PricingCard, PaymentModal } from '@components/organisms'
import { colors } from '@resources/colors'
import { useStore } from '@utils/hooks'
import {
  LIVE_ROOM_MESSAGE_TYPE,
  LIVE_ROOMS_HAVE_USERS_LEVEL,
} from '@consts/liveRoom'
import { PageLayout } from '..'

const CustomTitleWrapper = styled.div`
  padding: 40px 20px 16px 20px;

  @media ${mobile} {
    padding: 30px 20px;
  }
`

const SubscribeMsgWrapper = styled.div`
  padding: 50px;

  @media ${tablet} {
    padding: 40px;
  }

  @media ${mobile} {
    padding: 0 20px;
  }
`

const NewLineMobile = styled.br`
  display: none;

  @media ${mobile} {
    display: block;
  }
`

const NewLineDesktop = styled.br`
  display: block;

  @media ${mobile} {
    display: none;
  }
`

const SubscriptionModalTemplate = ({
  user,
  isValidatedStudent,
  getPaymentParams,
  getPurchasePlans,
  myPlan,
  mainToggle = () => {},
}) => {
  const history = useHistory()
  const { liveRoomStore, purchaseStore } = useStore()

  const getMyPlan = purchaseStore.getMyPlan || (() => {})

  const { isShowing, toggle } = PaymentModal.useModal()
  const [purchasePlan, setPurchasePlan] = useState(null)
  const [paymentParams, setPaymentParams] = useState(null)
  const [isOnIframe, setIsOnIframe] = useState(true)
  const [alreadyJoinedView, setAlreadyJoinedView] = useState(false)
  const [switchPlanView, setSwitchPlanView] = useState(false)

  const handleClick = async ({ plan, period, newPlan }) => {
    try {
      // 플랜 분기 처리 (이미 가입한 플랜, 가입한 플랜과 다른 플랜 전환)
      const isMyPlan = !!(
        myPlan
        && myPlan.validation
        && myPlan.validation.isValidate
        && !myPlan.validation.isExpired
      )

      if (isMyPlan) {
        window.scrollTo(0, 0)

        if (plan === myPlan.plan) {
          return setAlreadyJoinedView(true)
        }
        return setSwitchPlanView(true)
      }

      // Before 학생 인증 휘발성 로직
      /* 학생 인증 휘발성 버그 수정. jhlim 2020-10-18 */
      if (plan === 'student' && !isValidatedStudent) {
        // if (plan === 'student') {
        return history.push('/p/plan/student/validation')
      }

      // 결제
      setIsOnIframe(true)

      const planResult = await getPurchasePlans({ plan: newPlan, period })

      setPurchasePlan(planResult)

      toggle()
    }
    catch (e) {
      console.log('payment error', e)
    }
  }

  useEffect(() => {
    window.addEventListener('message', function(e) {
      const code = e.data && e.data.code ? e.data.code : ''

      const successFunc = async () => {
        await getMyPlan()

        if (
          !!liveRoomStore?.currLiveRoom
          && liveRoomStore?.currLiveRoomUser?.level
            === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
          && !purchaseStore?.isLimitSalonTime
          && liveRoomStore?.isNoticedEndSoonMessage
        ) {
          await liveRoomStore.sendChat(
            user,
            `[안내]\n플랜 구독으로 살롱의 시간제한이 없습니다.`,
            LIVE_ROOM_MESSAGE_TYPE['SYSTEM'],
          )
        }

        mainToggle()
      }

      if (code === 'SUCCESS') {
        // window.location.href = '/p/plan/payment/complete'
        successFunc()
        setIsOnIframe(false)
        setPaymentParams(null)
      }
      else if (code === 'FAIL') {
        alert('결제가 실패되었습니다. 흐름 운영팀으로 문의 부탁드립니다.')
        setIsOnIframe(false)
        setPaymentParams(null)
      }
    })
  }, [])

  return (
    <>
      <PaymentModal.View
        user={user}
        getPaymentParams={getPaymentParams}
        paymentParams={paymentParams}
        purchasePlan={purchasePlan}
        isShowing={isShowing}
        toggle={toggle}
        isOnIframe={isOnIframe}
        setIsOnIframe={setIsOnIframe}
        setPaymentParams={setPaymentParams}
      />

      {/* Default Subscription View */}
      {
        !alreadyJoinedView && !switchPlanView && (
          <>
            <TextWrapper>
              {/* Before 학생 인증 휘발성 로직 */}
              {/* 학생 인증 휘발성 버그 수정. jhlim 2020-10-18 */}
              {isValidatedStudent && <H1>학생 인증 성공!</H1>}
              {
                !isValidatedStudent && (
                  <div style={{ width: '100%' }}>
                    <CustomTitleWrapper>
                      <H4 style={{ width: '100%', fontSize: '32px' }}>
                    알맞는 플랜을 골라보세요
                      </H4>
                    </CustomTitleWrapper>
                    <H6
                      style={
                        {
                          color: '#646464',
                          padding: '0 20px',
                          wordBreak: 'keep-all',
                        }
                      }
                    >
                  플랜 취소 후
                      <NewLineMobile />
                  다음 결제일이 돌아오기 전에 다시 플랜을 시작할 경우
                      <NewLineMobile />
                      <NewLineDesktop />
                  예정되었던 결제일 이후
                      <NewLineMobile />
                  결제일 부터 해당 결제금이 청구 됩니다.
                    </H6>
                  </div>
                )
              }
            </TextWrapper>

            <SwitchSection
              offSwitchName="1개월"
              offSwitchContents={
                <div
                  style={
                    {
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '30px 0 50px 0',
                    }
                  }
                >
                  <SwitchArea>
                    <PricingCard
                      title="Standard"
                      price="7900"
                      period="월"
                      description={
                        [
                          `모든 광고 제거`,
                          `살롱 시간제한 X`,
                          <Flex
                            style={
                              {
                                display: 'inline-flex',
                                gap: 8,
                                alignItems: 'center',
                              }
                            }
                          >
                            <Caption1>별 55개 매달 충전</Caption1>
                            <Caption2 color="#0066ff">보너스 +5개</Caption2>
                          </Flex>,
                        ]
                      }
                      mainButton={
                        {
                          text: '플랜 시작하기',
                          color: colors.primary,
                          onClick: () =>
                            handleClick({
                              plan: 'standard',
                              period: 1,
                              newPlan: '1',
                            }),
                        }
                      }
                    />
                    <PricingCard
                      title="Premium"
                      price="12500"
                      period="월"
                      description={
                        [
                          `모든 광고 제거`,
                          `살롱 시간제한 X`,
                          <Flex
                            style={
                              {
                                display: 'inline-flex',
                                gap: 8,
                                alignItems: 'center',
                              }
                            }
                          >
                            <Caption1>별 110개 매달 충전</Caption1>
                            <Caption2 color="#0066ff">보너스 +10개</Caption2>
                          </Flex>,
                        ]
                      }
                      mainButton={
                        {
                          text: '플랜 시작하기',
                          color: colors.primary,
                          onClick: () =>
                            handleClick({
                              plan: 'standard',
                              period: 1,
                              newPlan: '3',
                            }),
                        }
                      }
                    />
                  </SwitchArea>
                </div>
              }
              onSwitchName="3개월"
              onSwitchContents={
                <div
                  style={
                    {
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '30px 0 50px 0',
                    }
                  }
                >
                  <SwitchArea>
                    <PricingCard
                      title="Standard"
                      price="19500"
                      period="3개월"
                      description={
                        [
                          `모든 광고 제거`,
                          `살롱 시간제한 X`,
                          <Flex
                            style={
                              {
                                display: 'inline-flex',
                                gap: 8,
                                alignItems: 'center',
                              }
                            }
                          >
                            <Caption1>별 55개 매달 충전</Caption1>
                            <Caption2 color="#0066ff">보너스 +5개</Caption2>
                          </Flex>,
                        ]
                      }
                      mainButton={
                        {
                          text: '플랜 시작하기',
                          color: colors.primary,
                          onClick: () =>
                            handleClick({
                              plan: 'standard',
                              period: 3,
                              newPlan: '1',
                            }),
                        }
                      }
                    />
                    <PricingCard
                      title="Premium"
                      price="35000"
                      period="3개월"
                      description={
                        [
                          `모든 광고 제거`,
                          `살롱 시간제한 X`,
                          <Flex
                            style={
                              {
                                display: 'inline-flex',
                                gap: 8,
                                alignItems: 'center',
                              }
                            }
                          >
                            <Caption1>별 110개 매달 충전</Caption1>
                            <Caption2 color="#0066ff">보너스 +10개</Caption2>
                          </Flex>,
                        ]
                      }
                      mainButton={
                        {
                          text: '플랜 시작하기',
                          color: colors.primary,
                          onClick: () =>
                            handleClick({
                              plan: 'standard',
                              period: 3,
                              newPlan: '3',
                            }),
                        }
                      }
                    />
                  </SwitchArea>
                </div>
              }
            />

            <SubscribeMsgWrapper>
              <SubscribeMsg />
            </SubscribeMsgWrapper>
          </>
        )
      }

      {/* Already Joined Plan View */}
      {
        alreadyJoinedView && (
          <div style={{ padding: '150px 0' }}>
            <H4>
              {user.name}
              {' '}
님은
            </H4>
            <>
              {
                myPlan.plan === 'student' ? (
                  <ForStudent
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                ) : (
                  <Standard
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                )
              }
              <H4 style={{ display: 'inline-block' }}>
              &nbsp;&nbsp;이용 중 입니다
              </H4>
            </>

            <MoveBtn
              href="/p/plan"
              text="내 구독 정보로 이동"
              style={{ margin: '40px auto 0 auto' }}
            />
          </div>
        )
      }

      {/* Switch Plan View */}
      {
        switchPlanView && (
          <div style={{ padding: '150px 0' }}>
            <H4>
              {user.name}
              {' '}
님은
            </H4>
            <div>
              {
                myPlan.plan === 'student' ? (
                  <ForStudent
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                ) : (
                  <Standard
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                )
              }
              <H4 style={{ display: 'inline-block' }}>
              &nbsp;&nbsp;이용 중 입니다
              </H4>
            </div>
            <div>
              {
                myPlan.plan === 'student' ? (
                  <Standard
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                ) : (
                  <ForStudent
                    style={{ display: 'inline-block', fontSize: '1.5rem' }}
                  />
                )
              }
              <H4 style={{ display: 'inline-block' }}>
              &nbsp;&nbsp;으로 전환하기 위해서는
              </H4>
            </div>
            <H4>현재 플랜을 취소하고 다시 결제 하셔야 합니다</H4>

            <MoveBtn
              href="/p/plan"
              text="내 구독 정보로 이동"
              style={{ margin: '40px auto 0 auto' }}
            />
          </div>
        )
      }
    </>
  )
}

export default SubscriptionModalTemplate

const SwitchArea = styled.div`
  gap: 16px;
  max-width: 1024px;
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;

  @media ${mobileDesktop} {
    flex-direction: column;
    align-items: center;

    > div + div {
      margin-top: 24px;
    }
  }
`
