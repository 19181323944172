import React, { useState, useEffect, useCallback } from 'react'
import LoginForm from '@components/organisms/form/LoginForm'
import { Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { isMobile } from 'react-device-detect'
import { useRenderLogger, useStore } from '@utils/hooks'

interface Props {
  match: Any;
}

const LoginPage = ({ match }: Props) => {
  useRenderLogger('LoginPage')
  const { authStore } = useStore()

  const login = authStore.login || (() => {})
  const kakaoLogin = authStore.kakaoLogin || (() => {})
  const isCompleted = authStore.jsonWebToken || null

  const [email, setEmail] = useState('') // 이메일
  const [pw, setPw] = useState('') // 비밀번호
  const [targetPath, setTargetPath] = useState('/')
  const [isAutoLogin, setIsAutoLogin] = useState(false)
  const [error, setError] = useState(false)

  const location = useLocation()
  const getLocation = () => location
  const callbackLocation = useCallback(getLocation, [])

  useEffect(() => {
    const locationToFixed = callbackLocation()
    let _targetPath = '/'

    if (locationToFixed.state && locationToFixed.state.from) {
      _targetPath =
        locationToFixed.state.from.pathname
          + locationToFixed.state.from.search ?? ''
    }

    setTargetPath(_targetPath)
  }, [callbackLocation])

  const toggleAutoLogin = () => {
    setIsAutoLogin(!isAutoLogin)
  }

  return (
    <div>
      {
        isCompleted ? (
          isMobile ? (
            <Redirect to={'/p/audio'} />
          ) : (
            <Redirect to={targetPath} />
          )
        ) : (
          <Redirect to="/p/login" />
        )
      }
      <LoginForm
        isAutoLogin={isAutoLogin}
        toggleAutoLogin={toggleAutoLogin}
        email={email}
        pw={pw}
        setEmail={setEmail}
        setPw={setPw}
        login={login}
        error={error}
        setError={setError}
        kakaoLogin={kakaoLogin}
      />
    </div>
  )
}

export default observer(LoginPage)
