import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex } from '@components/atoms'
import { images } from '@resources/'
import { YnPopup } from '@components/molecules'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { useHistory } from 'react-router-dom'
import { DonationStarForm } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 680px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle, castId, castTitle, castOwner }) => {
  const history = useHistory()

  const { starStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null

  const [starPlan, setStarPlan] = React.useState('')
  const [donaMsg, setDonaMsg] = React.useState('')

  const { isShowing: showLack, toggle: toggleLack } = YnPopup.usePopup()

  const handleSend = async () => {
    if (starPlan === '' || Number(starPlan) < 10) return
    if (currentUser && currentUser.currentStar < starPlan) {
      toggleLack()
      return
    }

    const result = await starStore.presentStarToCast(castId, {
      starValue: Number(starPlan),
      receivingUserId: castOwner.id,
      comment: {
        // "targetId": 1, // 생략 가능
        // "targetTableName": "Casts", // 생략 가능
        // "userId": 105, // 생략 가능
        isReply: 1,
        content:
          donaMsg !== ''
            ? donaMsg
            : `@${currentUser?.name} 님이 @${castOwner.name} 의 ${castTitle} 캐스트에 별 ${starPlan}개를 선물하였습니다.`,
        rawContent:
          donaMsg !== ''
            ? donaMsg
            : `@[${currentUser?.name}](id:${currentUser?.id}) 님이 @[${castOwner.name}](id:${castOwner.id}) 의 ${castTitle} 캐스트에 별 ${starPlan}개를 선물하였습니다.`,
        Users: [{ id: currentUser?.id }],
      },
    })

    if (result?.response?.status === 409) {
      toggleLack()
      return
    }
    setStarPlan('')
    setDonaMsg('')
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <CloseBtn onClick={toggle} src={images.x_btn_img} />
            <MobdlBody>
              <Flex style={{ height: 64 }} justify="center">
                <H6>별 선물하기</H6>
              </Flex>
              <DonationStarForm
                currentUser={currentUser}
                starPlan={starPlan}
                setStarPlan={setStarPlan}
                donaMsg={donaMsg}
                setDonaMsg={setDonaMsg}
                handleSend={handleSend}
              />
            </MobdlBody>
          </Modal>
          <YnPopup.View
            isShowing={showLack}
            toggle={toggleLack}
            yText="충전하기"
            title="별이 부족합니다"
            body="충전하기 버튼을 눌러 별을 충전해주세요"
            onOk={() => history.push('/p/star/main?tab=charge')}
          />
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}

const CloseBtn = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  width: 16px;
  height: 16px;
  object-fit: cover;
`
