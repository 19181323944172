import { Flex, H4, H6, Caption1, Div } from '@components/atoms'
import { EqualizerBox } from '@components/molecules'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { handleSongImgError } from '@utils/handler'
import { useMoreWidth } from '@utils/hooks'
import { secondToTimeString } from '@utils/time'
import { observer } from 'mobx-react'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const MobileSalonPlayListSection = ({ mixtape, onClick }) => {
  const [open, setOpen] = React.useState('')

  const handleClick = e => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }

  const { haveMore } = useMoreWidth({
    defaultWidth: 250,
    id: 'name_box',
    item:
      mixtape
      && mixtape.MixtapesHaveSongs
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex]
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
      && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song.name,
  })

  React.useEffect(() => {
    const scrollBox = document.getElementById('detail_playlist')
    scrollBox.scrollTop = 0
  }, [mixtape])

  return (
    <ActionSheet
      open={open}
      onClick={() => setOpen(prev => (prev === 'up' ? 'down' : 'up'))}
      id="detail_playlist"
    >
      <ActionSheetHeader />
      <ListHeader>
        <Flex
          type="column"
          style={
            {
              width: '87%',
              height: '100%',
              justifyContent: 'space-between',
            }
          }
        >
          <Flex style={{ alignItems: 'center' }}>
            {/* <LiveImage src={images.live_img} alt="live_img" /> */}
            <Div
              width="44px"
              height="24px"
              fontSize="12px"
              color={colors.white_ff}
              backgroundColor={colors.primary}
              padding="3px 6px"
              borderRadius="4px"
              marginRight="16px"
            >
              PLRA
            </Div>
            <Marquee id="name_box" haveMore={haveMore}>
              <H4 type="Bold" align="left" style={{ whiteSpace: 'nowrap' }}>
                {
                  mixtape
                  && mixtape.MixtapesHaveSongs
                  && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex]
                  && mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
                  && `${
                    mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song.name
                  } - ${
                    mixtape.MixtapesHaveSongs[mixtape.songPlayedIndex].Song
                      .singer
                  }`
                }
              </H4>
            </Marquee>
          </Flex>
          <Flex style={{ marginTop: 6 }}>
            <H6 color={colors.primary} style={{ marginRight: 8 }}>
              {mixtape && secondToTimeString(mixtape.totalPlayTime)}
            </H6>
            <H6 color={colors.brown_grey}>
              {mixtape && mixtape.MixtapesHaveSongs.length}
곡
            </H6>
          </Flex>
        </Flex>
        <PlayBtn
          src={images.mixtape_play_btn_black_img}
          alt="play_btn"
          onClick={handleClick}
        />
      </ListHeader>
      <PlayList>
        {
          mixtape
          && mixtape.MixtapesHaveSongs.map(item => (
            <ItemView
              isPlaying={item.songOrder === mixtape.songPlayedIndex}
              key={item.id}
            >
              <TrackImage
                src={
                  item && item.Song.imageUrl
                    ? item.Song.imageUrl
                    : images.default_song_img
                }
                alt="track_img"
                onError={e => handleSongImgError(e)}
              />
              {
                item.songOrder === mixtape.songPlayedIndex && (
                  <>
                    <OpacityImage />
                    <EqualizerBox style={{ top: '38px', left: '34px' }} />
                  </>
                )
              }
              <InfoBox>
                <H6 align="left" style={{ marginBottom: '6px' }}>
                  {item && item.Song.name}
                </H6>
                <Caption1 type="Regular" align="left" color={colors.brown_grey}>
                  {item && item.Song.singer}
                </Caption1>
              </InfoBox>
              <Flex
                style={
                  {
                    alignItems: 'center',
                    // minWidth: '158px',
                    justifyContent: 'flex-end',
                  }
                }
              >
                <H6 type="Regular" color={colors.brown_grey}>
                  {item && secondToTimeString(item.Song.duration)}
                </H6>
                <LikeBtn
                  src={
                    item.Song.isLiked
                      ? images.like_red_img
                      : images.like_default_img
                  }
                  alt="like_img"
                  onClick={handleClick}
                />
              </Flex>
            </ItemView>
          ))
        }
      </PlayList>
    </ActionSheet>
  )
}

export default observer(MobileSalonPlayListSection)

const actionSheetOpenAni = keyframes`
  0% { top:calc(100% - 154px);}
  100%   { top:84px; z-index:5000;}
`
const actionSheetCloseAni = keyframes`
  0%   { top:84px; z-index:5000;}
  100% { top:calc(100% - 154px);}
`

const ActionSheet = styled(Flex)`
  align-item: center;
  padding: 16px;
  position: fixed;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.white_ff};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  top: calc(100% - 154px);

  ${({ open }) =>
    open === 'up'
      ? css`
          animation: ${actionSheetOpenAni} 0.15s linear;
          animation-fill-mode: both;
        `
      : open === 'down'
        && css`
          animation: ${actionSheetCloseAni} 0.15s linear;
          animation-fill-mode: both;
        `}
`

const ActionSheetHeader = styled(Flex)`
  position: absolute;
  top: 10px;
  left: calc(50% - 15px);
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: ${colors.grey_d4};
`

// const View = styled(Flex)`
//   flex-direction: column;
//   max-width: 684px;
//   width: 100%;
//   height: 800px;
//   border-radius: 16px;
//   box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.16);
//   background-color: #ffffff;
//   padding: 32px 16px;
//   overflow: auto;
// `

const ListHeader = styled(Flex)`
  justify-content: space-between;
  /* width: 85%; */
  width: 100%;
  height: 64px;
  margin-bottom: 12px;
  padding: 0 10px;
`

const LiveImage = styled.img`
  margin-right: 16px;
  width: 56px;
  height: 28px;
`

const PlayBtn = styled.img`
  margin-left: 6px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`

const PlayList = styled(Flex)`
  flex-direction: column;
  overflow: auto;
  height: 100%;
  > div {
    margin: 4px 0;
  }

  > div:first-child {
    margin-top: 0;
  }
`

const marqueeAni = keyframes`
/* from {transform: translate(0, 0);}
to {transform: translate(-100%, 0); } */
/* 0% {transform: translate(0, 0);}
49% {transform: translate(-100%, 0); }
50% {transform: translate(100%, 0); }
100% {transform: translate(0, 0);} */
from   { transform: translate(0%);}
  99%,to { transform: translate(-100%);}
`
const Marquee = styled.div`
  max-width: 77%;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${props =>
    props.haveMore
    && css`
      > h4:after {
        content: '';
        white-space: nowrap;
        padding-right: 50px;
      }

      > h4 {
        margin: 0;
        padding-left: 100%;
        display: inline-block;
        white-space: nowrap;
        animation: ${marqueeAni} 7s linear infinite;
      }
    `}
`

// 플라 아이템

const ItemView = styled(Flex)`
  max-width: 652px;
  width: 100%;
  height: 88px;
  border-radius: 16px;
  padding: 8px 16px 8px 8px;
  position: relative;

  background-color: ${props =>
    props.isPlaying ? colors.white_f4 : colors.white_ff};
`

const TrackImage = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 8px;
  border: solid 1px ${colors.grey_d4};
  background-color: rgba(36, 36, 36, 0.5);
`

const OpacityImage = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  background-color: black;
  opacity: 0.3;
`

const InfoBox = styled(Flex)`
  max-width: 390px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 46px 0 16px;
`

const LikeBtn = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 32px;
`
