import { Network } from '.'

export default class CustomerCenterNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getFAQList = async () => {
    try {
      const { data } = await this.network._axiosApiAuth('/faqs', 'get')
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getFAQList error', err)
    }
  }

  getInquiryList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/inquiries',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getInquiryList error', err)
    }
  }

  getNotices = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/notices',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getNoticeList error', err)
    }
  }
  getNoticeFromLinkKey = async (linkKey, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/notices/_linkKey/${linkKey}`,
        'get',
        where,
      )
      return data || null
    }
    catch (err) {
      console.log('CustomerCenterNetwork getNoticeList error', err)
    }
  }

  getNoticesCount = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/notices/counts',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getNoticeListCount error', err)
    }
  }

  getNoticePopupList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth('/popups', 'get', where)
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getNoticePopupList error', err)
    }
  }

  getBannerEventList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/banner-events',
        'get',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork getBannerEventList error', err)
    }
  }

  postInquiry = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/inquiries',
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork postInquiry error', err)
    }
  }

  postAudioFeedBack = async (songId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feedback-songs/${songId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.log('CustomerCenterNetwork postAudioFeedBack error', err)
    }
  }

  postLiveRoomFeedback = async (liveRoomId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feedbacks/live-rooms/${liveRoomId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.error('CustomerCenterNetwork postLiveRoomFeedback', err)
    }
  }

  postLiveRoomUserFeedback = async (userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feedbacks/users/${userId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.error('CustomerCenterNetwork postLiveRoomUserFeedback', err)
    }
  }

  postFeedFeedback = async (feedId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/feedbacks/feeds/${feedId}`,
        'post',
        where,
      )
      return data || []
    }
    catch (err) {
      console.error('CustomerCenterNetwork postFeedFeedback', err)
    }
  }

  // postFeedback = async (where) => {
  //   try {
  //     const { data } = await this.network._axiosApiAuth('/alert-notifications', 'post', where)
  //     return data || []
  //   }
  //   catch (err) {
  //     console.log('FeedbackNetwork postFeedback error: ', err)
  //   }
  // }

  // putFeedback = async (id, value) => {
  //   try {
  //     const { data } = await this.network._axiosApiAuth('/alert-notifications/' + id, 'put', value)
  //     return data || null
  //   }
  //   catch (err) {
  //     console.log('FeedbackNetwork putFeedback error: ', err)
  //   }
  // }
}
