import React, { useState } from 'react'
import {
  Form,
  FormInput,
  FormTextArea,
  FormLabel,
  Caption1,
} from '@components/atoms'
import styled from 'styled-components'
import {
  SubmitBtn,
  FlexColumnBox,
  RowToColumnBox,
  SelectBox,
  Tooltip,
  FlexRowBox,
} from '@components/molecules'
import { mobile, tablet } from '@styles/media'
import { useForm, Controller } from 'react-hook-form'
import { message } from 'antd'
import { YOUTUBE_URL_LEVEL, STORAGE_URL } from '@consts'
import { colors } from '@resources/colors'
import ImageUploadForm from './ImageUploadForm'
import AudioUploadForm from './AudioUploadForm'
import TagForm from './TagForm'

const TooltipContent = styled.div`
  width: 435px;
  padding: 14px;
  color: ${colors.secondary};
  font-family: NotoSansCJKkr-Regular;

  @media ${mobile} {
    width: 308px;
    padding: 12px;
  }
`

const SubmitBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  text-align: center;

  @media ${mobile} {
    margin: 15px 0px;
  }
`

const FormGroup = styled.div`
  padding: 10px 0px;
  width: 100%;

  @media ${mobile} {
    padding: 8px 0px;
  }
`

const AudioForm = styled(Form)`
  width: 100%;
  max-width: 1000px;

  @media ${mobile} {
    width: 100%;
  }
`

const ImageUploadBox = styled(FormGroup)`
  max-width: 452px;
  width: 100%;
  @media ${mobile} {
    padding: 20px 0px 12px 0px;
  }
`

const LicenseGroup = styled(FormGroup)`
  width: 518px;

  @media ${mobile} {
    width: 100%;
  }
`

const AudioInfoInputBox = styled(FlexColumnBox)`
  width: 100%;
  max-width: 518px;

  @media ${tablet} {
    padding-left: 20px;
  }

  @media ${mobile} {
    height: 320px;
  }
`

const UploadForm = props => {
  const {
    user,
    uploadSong,
    _getYoutubeLink,
    createTagList,
    setIsUploaded,
    uploadSongAudio,
    uploadSongImage,
    getTag,
  } = props

  const {
    register,
    handleSubmit,
    control,
    getValues,
    // errors,
    // setError,
    // clearError,
    setValue,
  } = useForm()

  const [audioFormData, setAudioFormData] = useState()
  const [imageUri, setImageUri] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [tags, setTags] = useState([])

  // 유저 유튜브 URL 셀프 수정
  const setYoutubeUrl = async () => {
    if (!getValues().youtubeUrl && getValues().singer && getValues().name) {
      const res = await _getYoutubeLink(getValues().name, getValues().singer)
      if (res) {
        setValue([{ youtubeUrl: res }, { yLinkId: res }])
      }
      else {
        setValue([{ youtubeUrl: '' }, { yLinkId: 'LINK_ERROR' }])
      }
    }
  }

  // 메타데이터 이미지 업로드
  const _uploadSongImage = async picture => {
    if (!picture || !picture['data'] || !picture['format']) return
    setImageUri('')

    const byteArray = new Uint8Array(picture['data'])
    const blob = new Blob([byteArray], { type: picture['format'] })

    const formData = new FormData()
    formData.append('image', blob)

    const uploadedSong = await uploadSongImage(
      formData,
      sessionStorage.getItem('jwt_token'),
    )
    if (!uploadedSong || !uploadedSong['imageUri']) return

    setImageUri(uploadedSong['imageUri'])
    setImageUrl(STORAGE_URL + uploadedSong['imageUri'])
    return uploadedSong
  }

  // 등록하기
  const onSubmit = async data => {
    if (!audioFormData) return

    const params = [
      {
        name: data.name,
        singer: data.singer,
        // originalUri: audioFormData && audioFormData.originalUri,
        // mp3Uri: audioFormData && audioFormData.mp3Uri,
        // m3u8Uri: audioFormData && audioFormData.m3u8Uri,
        imageUri: imageUri && imageUri,
        lyrics: data.lyrics,
        license: data.license.value,
        userId: user && user.id,
        yLinkId: data.yLinkId,
        youtubeUrl: data.yLinkId,
        youtubeUrlLevel: YOUTUBE_URL_LEVEL.NATIVE_LINKED,
      },
    ]

    if (data.yLinkId === 'LINK_ERROR') {
      params[0].youtubeUrlLevel = await YOUTUBE_URL_LEVEL.NO_LINK // LINK_ERROR 시, NO_LINK LEVEL
    }

    // 메타데이터에서 가져온 url이 없을 시, 유저의 입력으로 링크를 설정
    // if (data.youtubeUrl !== '') {
    //   params[0].youtubeUrl = await data.youtubeUrl
    //   params[0].youtubeUrlLevel = await YOUTUBE_URL_LEVEL.NATIVE_LINKED // 링크 등록 시, 100 level
    // }

    // 오디오 파일 업로드
    const uploadedAudio = await uploadSongAudio(audioFormData)
    if (uploadedAudio) {
      params[0].originalUri = await uploadedAudio.originalUri
      params[0].duration = await uploadedAudio.duration

      // 노래 업로드
      const uploadedSong = await uploadSong(params)
      if (uploadedSong) {
        // 업로드된 곡 태그 등록
        await createTagList(uploadedSong.id, tags)

        setIsUploaded(true)
        message.success('오디오 등록 완료')
      }
      else {
        message.error('오디오 등록 실패')
      }
    }
  }

  return (
    <AudioForm onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <AudioUploadForm
          setAudioFormData={setAudioFormData}
          audioFormData={audioFormData}
          setValue={setValue}
          _uploadSongImage={_uploadSongImage}
          getTag={getTag}
          imageUri={imageUri}
        />
      </FormGroup>

      <RowToColumnBox
        style={
          {
            width: '100%',
            alignItems: 'end',
            justifyContent: 'space-between',
          }
        }
      >
        <ImageUploadBox>
          <ImageUploadForm
            setImageUri={setImageUri}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            uploadSongImage={uploadSongImage}
            title="앨범커버 이미지"
          />
        </ImageUploadBox>
        <AudioInfoInputBox>
          <FormGroup>
            <FormLabel color="#646464" required>
              제목
            </FormLabel>
            <FormInput
              name="name"
              ref={register({ required: true })}
              onBlur={() => setYoutubeUrl()}
              placeholder="제목 입력"
            />
          </FormGroup>
          <FormGroup>
            <FormLabel color="#646464" required>
              아티스트 명
            </FormLabel>
            <FormInput
              name="singer"
              ref={register({ required: true })}
              onBlur={() => setYoutubeUrl()}
              placeholder="아티스트 명 입력"
            />
            <input
              name="yLinkId"
              ref={register({ required: true })}
              style={{ display: 'none' }}
            />
          </FormGroup>
          {/* <FormGroup>
            <FormLabel color="#646464">유튜브 링크</FormLabel>
            <FormInput
              name="youtubeUrl"
              ref={register()}
              placeholder="URL 링크 입력"
            />
            <input
              name="yLinkId"
              ref={register({ required: true })}
              style={{ display: 'none' }}
            />
          </FormGroup> */}
        </AudioInfoInputBox>
      </RowToColumnBox>

      <FormGroup>
        <FormLabel color="#646464">가사</FormLabel>
        <FormTextArea
          name="lyrics"
          ref={register()}
          style={{ height: '150px', resize: 'none', margin: 0 }}
          placeholder="가사 입력"
        />
      </FormGroup>

      {/* 태그 입력 */}
      <FormGroup>
        <TagForm tags={tags} setTags={setTags} />
      </FormGroup>

      {/* 라이센스 */}
      <FlexRowBox style={{ width: '100%', justifyContent: 'center' }}>
        <LicenseGroup>
          <Controller
            as={
              <SelectBox
                style={
                  {
                    width: '100%',
                    height: '48px',
                    borderRadius: '7px',
                    border: 'solid 1px #949494',
                    backgroundColor: '#ffffff',
                  }
                }
                options={
                  [
                    { value: '0', label: '표준 흐름 라이선스' },
                    { value: '1', label: '크리에이티브 커먼즈 - 저작자 표시' },
                  ]
                }
              />
            }
            placeholder="라이센스 선택"
            control={control}
            rules={{ required: true }}
            onChange={
              ([selected]) => {
              // Place your logic here
                return selected
              }
            }
            defaultValue={{ value: '0', label: '표준 흐름 라이선스' }}
            name="license"
          />
        </LicenseGroup>
        <Tooltip
          content={
            <TooltipContent>
              <Caption1 color="#646464">
                표준 흐름 라이선스는 서비스 약관에서 확인할 수 있습니다.
                또는 크리에이티브 커먼즈 저작자 표시 라이선스를 사용할 수
                있습니다.
              </Caption1>
            </TooltipContent>
          }
        />
      </FlexRowBox>

      <SubmitBox>
        <SubmitBtn type="submit" disabled={!getValues().yLinkId}>
          등록하기
        </SubmitBtn>
      </SubmitBox>
    </AudioForm>
  )
}

export default UploadForm
