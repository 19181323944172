import React, { useState } from 'react'
import { CastUploadTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { getTag } from '@utils/media'
import { observer } from 'mobx-react'
import { message } from 'antd'

import { useRenderLogger, useStore } from '@utils/hooks'
import { CAST_UPLOAD_TYPE } from '@consts/cast'

const CastUploadPage = ({ location, match }) => {
  useRenderLogger('CastUploadPage')
  const { houseStore, castStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const createCast = castStore.createCast || (() => {})
  const uploadCastAudio = castStore.uploadCastAudio || (() => {})
  const uploadCastImage = castStore.uploadCastImage || (() => {})
  const createAudioFile = castStore.createAudioFile || (() => {})

  const fetchUserHouseList = async () => {
    const options = {
      __required: 'HousesHaveUsers',
      'HousesHaveUsers.userId': currentUser?.id,
      isHaveContentPermission: 1,
      __attributes:
        'id,linkKey,contentPermissionLevel,imageUri,title,HousesHaveUsers.id,HousesHaveUsers.userId,HousesHaveUsers.level',
    }

    const houseList = await houseStore.fetchHouseList(options)
    return houseList || []
  }

  const [isUploaded, setIsUploaded] = useState(false)

  const {
    type = CAST_UPLOAD_TYPE['UPLOAD'],
    recording = null,
    basePlace,
    isPersonal,
  } = location.state || {}

  if (type === CAST_UPLOAD_TYPE['RECORDING']) {
    if (!recording) {
      message.error('오류. 관리자에게 문의하세요.')
      return <Redirect to="/p/cast/manage" />
    }
  }

  const _redirectUrl = basePlace?.id
    ? `/p/cast-house/manage/${basePlace.id}`
    : `/p/cast/manage`

  return (
    <>
      {isUploaded && <Redirect to={_redirectUrl} />}
      <CastUploadTemplate
        user={currentUser}
        fetchUserHouseList={fetchUserHouseList}
        createCast={createCast}
        setIsUploaded={setIsUploaded}
        uploadCastAudio={uploadCastAudio}
        uploadCastImage={uploadCastImage}
        createAudioFile={createAudioFile}
        getTag={getTag}
        type={type}
        recording={recording}
        basePlace={basePlace}
        isPersonal={isPersonal}
      />
    </>
  )
}

export default observer(CastUploadPage)
