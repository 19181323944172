import React from 'react'
import { TempComplete } from '@components/templates'
import { useRenderLogger } from '@utils/hooks'

const TempCompletePage = props => {
  useRenderLogger('TempCompletePage')

  const { location } = props

  return (
    <TempComplete
      email={
        location
        && location.state
        && location.state.email
        && location.state.email
      }
    />
  )
}

export default TempCompletePage
