import React from 'react'
import { BodyWrapper, LogoImg, H1, H4, H6 } from '@components/atoms'
import { MoveBtn } from '@components/molecules'

const TempComplete = (props) => {
  const { email } = props

  return (
    <BodyWrapper>
      <LogoImg />

      <H1>임시 비밀번호 발급</H1>

      <H4 style={{ margin: '50px 0' }}>{email && email}</H4>

      <H6 color="#949494" style={{ marginBottom: '40px' }}>
        임시 비밀번호가 전송되었습니다.
        <br />
        24시간 이내에 앱내에서 새로운 비밀번호로 변경해 주세요
      </H6>

      <MoveBtn text="로그인 하러가기" href="/p/login" />
    </BodyWrapper>
  )
}

export default TempComplete
