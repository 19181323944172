import React from 'react'
import { Flex, H3, Caption1, H4 } from '@components/atoms'
import styled from 'styled-components'
import { colors } from '@resources/colors'
import CastListItem from '@components/molecules/item/CastListItem'
import { Link } from 'react-router-dom'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'

// 전체 영역
const ListBox = styled(Flex)`
  max-width: 1704px;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  @media ${mobile} {
    min-width: auto;
  }
`

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;

  max-width: 1607px;
  width: 100%;
`

// 리스트 내용 영역
const ListContent = styled(Flex)`
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

/**
 * 모든 카테고리 플라 리스트 컴포넌트
 */
const WholeCastList = ({
  popularCastList,
  latestCastList,
  followCastList,
  historyCastList,
  recommendationCastList,
}) => {
  const maxItemCount = window.innerWidth >= 1905 ? 6 : 5
  return (
    <div style={{ paddingTop: '24px' }}>
      <H3 style={{ textAlign: 'left', marginBottom: 24 }}>캐스트</H3>
      {
        historyCastList && historyCastList.length > 0 && (
          <ListBox>
            <ListHeader>
              <H4 type="Bold">최근 들은 캐스트</H4>
              <Link to="/p/cast/list/history">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                historyCastList.map(
                  (item, index) =>
                    index < maxItemCount && (
                      <CastListItem
                        item={item}
                        key={`history-${index}-${item.id}`}
                        category="history"
                      />
                    ),
                )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
        recommendationCastList && recommendationCastList.length > 0 && (
          <ListBox>
            <ListHeader>
              <H4 type="Bold">추천</H4>
              <Link to="/p/cast/list/recommendation">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                recommendationCastList.map(
                  (item, index) =>
                    index < maxItemCount && (
                      <CastListItem
                        item={item}
                        key={`recommendation-${index}-${item.id}`}
                        category="recommendation"
                      />
                    ),
                )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
        latestCastList && latestCastList.length > 0 && (
          <ListBox>
            <ListHeader>
              <H4 type="Bold">최신</H4>
              <Link to="/p/cast/list/latest">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                latestCastList.map(
                  (item, index) =>
                    index < maxItemCount && (
                      <CastListItem
                        item={item}
                        key={`latest-${index}-${item.id}`}
                        category="latest"
                      />
                    ),
                )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
        popularCastList && popularCastList.length > 0 && (
          <ListBox>
            <ListHeader>
              <H4 type="Bold">인기</H4>
              <Link to="/p/cast/list/popular">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                popularCastList.map(
                  (item, index) =>
                    index < maxItemCount && (
                      <CastListItem
                        item={item}
                        key={`popular-${index}-${item.id}`}
                        category="popular"
                      />
                    ),
                )
              }
            </ListContent>
          </ListBox>
        )
      }
      {
        followCastList && followCastList.length > 0 && (
          <ListBox>
            <ListHeader>
              <H4 type="Bold">팔로우</H4>
              <Link to="/p/cast/list/follow">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더 보기
                </Caption1>
              </Link>
            </ListHeader>
            <ListContent>
              {
                followCastList.map(
                  (item, index) =>
                    index < maxItemCount && (
                      <CastListItem
                        item={item}
                        key={`follow-${index}-${item.id}`}
                        category="follow"
                      />
                    ),
                )
              }
            </ListContent>
          </ListBox>
        )
      }
    </div>
  )
}

export default observer(WholeCastList)
