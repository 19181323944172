export const IS_DEV =
  process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development'

export const DOMAIN = `hreum.me`
export const API_VERSION = 'v6'

export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL
export const API_URL = process.env.REACT_APP_API_URL
export const RSS_PUBLISH_URL = process.env.REACT_APP_RSS_PUBLISH_URL

export * from './liveRoom'
export * from './cast'
export * from './house'

export const ARTIST_LEVEL = {
  NOT_YET: 0,
  APPLICANT: 10,
  GENERAL: 100,
}

export const FRIEND_LEVEL = {
  DISTANCE_FRIEND: 1000,
  NORMAL_FRIEND: 2000,
  BEST_FRIEND: 3000,
}

export const YOUTUBE_URL_LEVEL = {
  NO_LINK: 0,
  NOT_AVAILABLE: 10,
  EMBED_BLOCKED: 5,
  NATIVE_LINKED: 100,
  MANAGER_CHECKED: 101,
}

export const FOLLOWER_ORDER_TYPE = [
  { title: '팔로우한 날짜 최신순' },
  { title: '팔로우한 날짜 오래된 순' },
]
export const FOLLOWING_ORDER_TYPE = [
  { title: '팔로우한 날짜 최신순' },
  { title: '팔로우한 날짜 오래된 순' },
]

export const DJ_LEVEL = {
  NOT_YET: 0,
  APPLICANT: 10,
  BABY: 100,
  GENERAL: 200,
  PARTNER: 300,
  OFFICIAL: 1000,
}

/**
 * mixtape 및 user status
 */

export const MIXTAPE_WARNING_STATUS = {
  NOT_WARNING: 200, // 경고 표시 없음 (정상 플라 or 경고 14일 이후 플라)
  JUST_NOT_WARNING: 170, // 경고 1회, 14일 이상 경고 표시 없음
  JUST_WARNING: 150, // 경고 1회, 14일 이내라 수정화면에서 경고 표시 필요
  PUBLISHABLE: 100, // 경고는 있지만 공개 가능 (경고 7일 이후 14일 이내)
  UNPUBLISHABLE: 0, // 경고 표시 및 공개 불가능 (경고 받은지 7일 이내)
}

export const USER_WARNING_STATUS = {
  NOT_WARNING: 200, // 경고 표시 없음 ( 경고 받지 않은 유저 )
  IN_GRACE: 150, // 경고 표시, 첫 경고 2회 발생 2주 유예기간
  MAINTAIN_WARNING: 100, // 경고 표시, 유예기간 지났지만 유예기간 안에 플라 추가 경고로 경고 표시
  DANGER: 0, // 경고 표시 없음, 90일 이내 경고 2회 누적으로 추가 경고시 DJ 자격 박탈
}

export const AD_LEVEL = {
  NEW_USER_REFERRAL: 100, // 회원가입 한달 이내 추천인코드 등록 회원
  NEW_USER: 50, // 회원가입 일주일 이내 회원
  BASIC_POPUP: 5, // 회원가입 한달 이후 광고 보기전 회원
  BASIC: 10, // 회원가입 한달 이후 무료 회원
  STANDARD: 200, // 유료회원 standard plan 이용
  STUDENT: 150, // 유료회원 student plan 이용
}

export const MIXTAPES_HAVE_TAGS_LEVEL = {
  DESCRIPTION_TAG: 0,
  NORMAL_TAG: 11,
  GENRE_TAG: 21,
}

export const STATUS_CODE_401_MSG = {
  '카카오로 가입한 계정입니다.': '카카오로 가입한 계정입니다.',
  '일치하는 계정이 없습니다.': '일치하는 계정이 없습니다.',
  '비밀번호가 일치하지 않습니다.': '비밀번호가 일치하지 않습니다.',
}

export const IGNORE_ERROR_ROUTER = ['/users-play-songs', '/users-play-mixtapes']

export const genreTagList = [
  {
    keyword: '발라드',
  },
  {
    keyword: '댄스',
  },
  {
    keyword: '아이돌',
  },
  {
    keyword: '힙합',
  },
  {
    keyword: '랩',
  },
  {
    keyword: '소울',
  },
  {
    keyword: '알앤비',
  },
  {
    keyword: '인디',
  },
  {
    keyword: '팝',
  },
  {
    keyword: '재즈',
  },
  {
    keyword: '락',
  },
  {
    keyword: '메탈',
  },
  {
    keyword: '트로트',
  },
  {
    keyword: 'EDM',
  },
  {
    keyword: '하우스',
  },
  {
    keyword: '일레트로니카',
  },
  {
    keyword: 'OST',
  },
  {
    keyword: '블루스',
  },
  {
    keyword: '포크',
  },
  {
    keyword: '컨트리',
  },
  {
    keyword: '클래식',
  },
  {
    keyword: '뉴에이지',
  },
  {
    keyword: '동요',
  },
  {
    keyword: '7080',
  },
  {
    keyword: '8090',
  },
  {
    keyword: '2000년대',
  },
  {
    keyword: 'JPOP',
  },
  {
    keyword: 'BGM',
  },
  {
    keyword: '해외',
  },
  {
    keyword: '19금',
  },
  {
    keyword: 'CCM',
  },
]

export const ERROR_CODE = {
  LIVE_ROOMS_JOIN_NOT_FOUND: 'LIVE_ROOMS_JOIN_NOT_FOUND',
  LIVE_ROOMS_JOIN_EXCEED_USER: 'LIVE_ROOMS_JOIN_EXCEED_USER',
  LIVE_ROOMS_JOIN_BANNED_USER: 'LIVE_ROOMS_JOIN_BANNED_USER', // 강퇴당한 유저
  LIVE_ROOMS_JOIN_BLOCKED_USER: 'LIVE_ROOMS_JOIN_BLOCKED_USER', // 호스트 이상 유저에게 차단당한 유저
  LIVE_ROOMS_EXCEED_MIC_USER: 'LIVE_ROOMS_EXCEED_MIC_USER',
  LIVE_ROOMS_JOIN_NOT_OPENED: 'LIVE_ROOMS_JOIN_NOT_OPENED',
  LIVE_ROOMS_JOIN_NOT_FRIEND_USER: 'LIVE_ROOMS_JOIN_NOT_FRIEND_USER',
  LIVE_ROOMS_JOIN_NOT_HOUSE_MEMBER_USER:
    'LIVE_ROOMS_JOIN_NOT_HOUSE_MEMBER_USER',
  LIVE_ROOMS_JOIN_WRONG_PASSWORD: 'LIVE_ROOMS_JOIN_WRONG_PASSWORD',
  LIVE_ROOMS_JOIN_NOT_HREUM_USER: 'LIVE_ROOMS_JOIN_NOT_HREUM_USER',

  /* deprecated */
  USER_ACCOUNT_DUPLICATED: 'USER_ACCOUNT_DUPLICATED', // Users.account 중복
  USER_ACCOUNT_NOT_ALLOWED_CHARACTERS: 'USER_ACCOUNT_NOT_ALLOWED_CHARACTERS', // Users.account 불가능한 문자 포함
  USER_ACCOUNT_NOT_ALLOWED_UPDATED_AT: 'USER_ACCOUNT_NOT_ALLOWED_UPDATED_AT', // Users.account 한번 변경후 14일 동안 변경 불가, Users.accountUpdatedAt 참조
  /* deprecated end */

  USERS_ACCOUNT_DUPLICATED: 'USERS_ACCOUNT_DUPLICATED', // Users.account 중복
  USERS_ACCOUNT_NOT_ALLOWED_CHARACTERS: 'USERS_ACCOUNT_NOT_ALLOWED_CHARACTERS', // Users.account 불가능한 문자 포함
  USERS_ACCOUNT_NOT_ALLOWED_UPDATED_AT: 'USERS_ACCOUNT_NOT_ALLOWED_UPDATED_AT', // Users.account 한번 변경후 14일 동안 변경 불가, Users.accountUpdatedAt 참조
  USERS_IS_BANNED: 'USERS_IS_BANNED',

  /* 비로그인 유저 블락 */
  LIVE_ROOMS_BLOCK_UNKNOWN_USER: 'LIVE_ROOMS_BLOCK_UNKNOWN_USER',

  /* feed 에서 house member 권한 없음 */
  FEEDS_ONLY_HOUSE_MEMBER: 'FEEDS_ONLY_HOUSE_MEMBER',
}

export const STAR_HISTORY_TYPE = {
  PURCHASED: 1,
  EXCHANGED: 2,
  GIFTED: 3,
  EXPIRED: 4,
}

export const STAR_SKU_TYPE = {
  '3_2_1_25': { starCount: 25, price: 2500, point: 1975, bonus: 5 },
  '3_2_1_101': { starCount: 101, price: 9900, point: 7676, bonus: 20 },
  '3_2_1_1050': { starCount: 1050, price: 99000, point: 76650, bonus: 200 },
  '3_2_1_3200': { starCount: 3200, price: 299000, point: 224000, bonus: 600 },
}

export const SKU = {
  android_sub_standard_1: '1_1_1_1',
  android_sub_standard_1_2: '1_1_1_1_2',

  android_sub_basic_1_3: '1_1_2_1_3',
  android_sub_basic_3_3: '1_1_2_3_3',

  android_sub_standard_1_3: '1_1_1_1_3',
  android_sub_standard_3_3: '1_1_1_3_3',

  android_sub_premium_1_3: '1_1_3_1_3',
  android_sub_premium_3_3: '1_1_3_3_3',

  ios_sub_standard_1: '2_1_1_1',
  ios_sub_standard_1_2: '2_1_1_1_2',

  ios_sub_basic_1_3: '2_1_2_1_3',
  ios_sub_basic_3_3: '2_1_2_3_3',

  ios_sub_standard_1_3: '2_1_1_1_3',
  ios_sub_standard_3_3: '2_1_1_3_3',

  ios_sub_premium_1_3: '2_1_3_1_3',
  ios_sub_premium_3_3: '2_1_3_3_3',

  inicis_sub_student_1: '3_1_2_1',
  inicis_sub_student_1_2: '3_1_2_1_2',
  inicis_sub_student_3: '3_1_2_3',
  inicis_sub_student_3_2: '3_1_2_3_2',

  inicis_sub_standard_1: '3_1_1_1',
  inicis_sub_standard_1_2: '3_1_1_1_2',
  inicis_sub_standard_1_3: '3_1_1_1_3',
  inicis_sub_standard_3: '3_1_1_3',
  inicis_sub_standard_3_2: '3_1_1_3_2',
  inicis_sub_standard_3_3: '3_1_1_3_3',

  inicis_sub_premium_1_3: '3_1_3_1_3',
  inicis_sub_premium_3_3: '3_1_3_3_3',

  star_sub_basic_1_3: '4_1_2_1_3',
  star_sub_basic_3_3: '4_1_2_3_3',
}

export const INICIS_ERROR_CODE = {
  HR001: '서버 통신에 장애가 있습니다.',
  V022: '필수값이 누락되었습니다.',
  V013: '존재하지 않는 상품입니다.',
  V014: '존재하지 않는 상품입니다.',
  V023: '요청 정보가 올바르지 않습니다.',
  R201: '승인요청 완료건으로 재승인요청불가',
  V811: '결제창 요청 가능시간(30분)이 초과되었습니다.',
  '1200': '정상완료',
  '1201': '거래제한(정지)카드',
  '1202': '1일 사용금액초과',
  '1203': '미등록카드',
  '1204': '유효기간 만료(경과)',
  '1205': '할부개월 오류',
  '1206': '할부금액 오류',
  '1207': '거래정지 가맹점',
  '1208': '미등록 가맹점',
  '1209': 'KEY-IN 불가 가맹점',
  '1210': '카드사 거래 TIMEOUT(카드사장애)',
  '1211': '법인카드 할부불가',
  '1212': '할부판매대상 가맹점 아님',
  '1213': '금액 오류',
  '1214': '카드번호 오류',
  '1215': '카드사용횟수 초과',
  '1216': '월 사용한도 초과',
  '1217': '전문 FORMAT 오류',
  '1218': '단말기 ID오류',
  '1219': '비밀번호 상이',
  '1220': '거래날짜 상이',
  '1221': '비밀번호 오류회수초과',
  '1222': '거래취소불가(기매입된 거래)',
  '1223': '유효기간 오류',
  '1224': '원거래 없음',
  '1225': '이미 취소 된 거래임',
  '1226': '도난, 분실카드',
  '1227': '해당카드 이용불가(조회불가능)',
  '1228': '거래구분오류',
  '1229': '가맹점한도초과',
  '1230': '가맹점오류',
  '1231': '가맹점 자기매출오류',
  '1232': '연체카드',
  '1233': '발급은행 오류',
  '1234': '기타자료 오류(VAN문의요망)',
  '1235': '카드사 통신장애',
  '1236': '중복거래',
  '1237': '1회 사용금액초과',
  '1238': 'KEY-IN 거래불가',
  '1239': '1일사용횟수초과',
  '1240': '금융망 통신장애',
  '1241': 'VAN 통신장애',
  '1242': 'VAN(KOVAN)통신장애',
  '1243': 'VAN(KFTC) 통신장애',
  '1244': 'OK CashBag 통신장애',
  '1245': '은행 통신장애',
  '1246': '증권사 통신장애',
  '1247': '본인인증필드 오류',
  '1251': '주민(사업자)번호 상이',
  '1252': '주민(사업자)번호/비밀번호 동시상이',
  '1253': '인증서비스 불가(카드사장애)',
  '1254': '잔액부족',
  '1255': '카드사인증거래TIMEOUT',
  '1256': '인증불가카드사',
  '1257': '포인트 미달카드',
  '1258': '본인인증요망',
  '1270': '전화요망(520-4500) - 비씨',
  '1271': '전화요망(3700-2500) - 국민',
  '1272': '전화요망(3700-2500) - 국민',
  '1273': '전화요망(3700-5000) - 국민',
  '1274': '전화요망(524-8100) - 외환',
  '1275': '전화요망(556-0670) - 장은',
  '1276': '전화요망(1588-8900) - 삼성',
  '1277': '전화요망(3420-7200) - 엘지',
  '1278': '전화요망(1577-6000) - 현대',
  '1279': '전화요망(3708-8000) - 신한',
  '1280': '전화요망(731-8150) - 한미',
  '1281': '전화요망(722-5151) - 동화',
  '1282': '전화요망(051-647-4000) - 주택',
  '1283': '전화요망(053-760-6333) - 대동',
  '1284': '전화요망(3457-8822) - 보람',
  '1285': '전화요망:(773-2121) - 하나',
  '1286': '전화요망(222-2000) - 평화',
  '1287': '전화요망(240-2513) - 수협',
  '1288': '전화요망(224-8701) - 축협(농협)',
  '1289': '전화요망(062-232-0365) - 광주',
  '1290': '전화요망(064-20-0200) - 제주',
  '1291': '전화요망(0652-250-7114) - 전북',
  '1292': '전화요망(1588-4114) - 조흥',
  '1293': '전화요망(0431-220-7114) - 충북',
  '1294': '전화요망(552-2700) - 동양',
  '1295': '전화요망(722-7013) - 씨티',
  '1296': '전화요망 - 해외카드(02-3430-5800)',
  '1297': '전화요망 - 해외카드(02-3430-5800)',
  '1298': '전화요망(790-7568) - 비자',
  '1299': '해당카드사 전화요망',
  '12A0': '거치기간 오류',
  '12A1': '비씨카드-당일정산마감완료, EDI가맹점당일매출분 정산완료',
  '12A2': '상품권 한도초과',
  '12A3': '무통장 거래횟수 초과',
  '12A4': '잔액증명 발급계좌',
  '12A5': '비씨카드-취소가능여부 전화확인 요망',
  '12A6': '비씨카드-비씨대행요청(비씨에서 신용한도로 대행승인요청경우)',
  '12A7': '심야거래승인 제한업소',
  '12A8': '카드사 시스템장애',
  '12AA': '비씨카드-기업카드회원 한도초과',
  '12B0': '카드MS값 오류',
  '12B1': '공과금 한도초과',
  '12B2': '해지카드',
  '12B3': '반송 또는 회수카드',
  '12B4': '대금지급보류가맹점',
  '12B5': '거래제한업종',
  '12B6': '가맹점할부특약한도초과',
  '12B7': '국민카드-CASH ID 원장없음',
  '12B8': '통화코드 오류',
  '12C0': '비실명계좌',
  '12C1': '이중등록계좌',
  '12E0': '요주의회원 가맹점',
  '12E1': '결제일 불일치',
  '12E2': '제휴카드 아님',
  '12E3': '기업대표카드 사용불가',
  '12E4': '참가기관 시스템장애',
  '12E5': '참가기관 업무개시이전',
  '12E6': '참가기관 업무종료',
  '12E7': '참가기관 db access error',
  '12F0': '승인기관과 취소기간이 상이함. 취소불가',
  '12F1': '승인가능금액 부족',
  '12F2': '허위카드',
  '12F3': '전카드 사용기간 경과',
  '12F4': '할부불가카드',
  '12F5': '잠시후 재시도 요망(승인 Record Lock)',
  '12F6': '잠시후 재시도 요망(승인집계 Record Lock)',
  '12F7': '개인회원은 강제승인을 할 수 없음',
  '12F8': '강제승인대상 법인(업체)회원이 아님',
  '12F9': '해지가맹점',
  '12FA': '전카드 승인시 현카드 사고로 승인 거절',
  '12FB': 'CVC 오류',
  '12FC': '할부 신용관리 금액 초과',
  '12FD': '카드사용자 확인 후 승인 가능',
  '12FE': '회원등록 당일 거래승인 불가',
  '12FF': '조기경보 예상 카드',
  '12FG': '조기경보 예상 가맹점',
  '12FH': '취소되지 않은 거래',
  '12FI': '사고등록계좌,카드',
  '12FJ': '법적등록계좌',
  '12FK': '소속은행장애(비씨카드)',
  '12FL': '복수은행 통합한도 기초과',
  '12FM': '복수은행 할부한도 기초과',
  '12FN': '복수은행 통합한도 초과',
  '12FO': '은행확인 후 승인 가능',
  '12FP': '업체한도 확인 후 승인 가능',
  '12FQ': '승인전 조회완료',
  '12FR': '카드미수령',
  '12FS': '부정카드',
  '12FT': '은행연합회 불량 정보 등록',
  '12FU': '퇴직 거래 정지',
  '12FV': '보증취하거래정지',
  '12FW': '연락불가 거래정지',
  '12FX': '신용불량 거래정지',
  '12FY': '법인회사한도초과',
  '12FZ': '최저금액오류',
  '12G0': '특인 예약거래 있음',
  '12G1': '특인 예약거래 없음',
  '12G3': '예약내용 불일치(백화점)',
  '12G4': '자동승인가능',
  '12G5': '할부는 12개월 이내로 가능',
  '12G6': '년한도 초과',
  '12G8': 'D/M 금지카드',
  '12G9': '불량회원',
  '12GA': '자기매출거래불가',
  '12GB': '해당국가 찾을 수 없음',
  '12GC': '거래중지불량가맹점',
  '12GD': '인증서 발급이 이미 해지상태',
  '12GE': '인증서 발급 회원이 아님',
  '12GF': '지급계좌오류',
  '12GG': '기업개별한도 초과',
  '12GH': '기업업체한도 초과',
  '12GI': '가족회원한도 초과',
  '12GJ': '제휴가맹점 승인불가',
  '12GK': '가맹점 건당 승인 한도 초과',
  '12GL': '가맹점 월간 승인 한도 초과',
  '12GM': '가맹점 일일 승인 한도 초과',
  '12GN': '거래일자 오류',
  '12GO': '기업최고한도초과',
  '12GP': '가맹점공동이용 비대상가맹점',
  '12GQ': '타카드 연체중인 카드',
  '12GR': '이미 매입처리된 거래',
  '12GS': '승인번호부적당',
  '12GT': '고객통합한도 초과',
  '12GU': '거래마감시간경과',
  '12GV': 'RCL/CRB/NEG 등재카드',
  '12GW': '해외위규자',
  '12GX': '특수한도 초과',
  '12GY': '타사불량가맹점',
  '12GZ': '해당거래불가 가맹점',
  '12H0': '원거래상이(취소금액오류)',
  '12H2': '회원등급코드오류',
  '12H3': '이체은행미등재',
  '12H4': '고유번호 중복',
  '12H5': '포인트승인불가 가맹점',
  '12H6': '임시카드 국내승인 불가',
  '12H7': '전문중복',
  '12H8': '기처리전문',
  '12H9': '카드발급상태이상',
  '12HA': '거래금액미입력',
  '12HB': '카드정보확인요망',
  '12HC': '기업외고 한도초과',
  '12HD': '승인내역 불일치',
  '12HE': '심방미완료 가맹점',
  '12HF': '이중참조번호오류',
  '12HG': '취소시 기타오류(전화요망)',
}

export const INSTA_FEED_SYNC_STATE = {
  NOTYET: 0, // 동기화 전 - 버튼노출
  SENDED_REQUEST: 1, // 서버로 요청 보냄 - 버튼(동기화중)노출
  SYNCING: 10, // 동기화 중 - 버튼노출 x
  SUCCESS: 20, // 동기화 성공 - 버튼노출 x
  POST_FAILED: 30, // 인스타그램 피드 불러오기, 별지급은 완료되었으나 피드 create 실패 - 버튼노출
  SYNC_FAILED: 40, // 인스타그램 피드 불러오기 실패 - 버튼 노출
  SERVER_WAIT: 50, // 재시작 상태  - 버튼 노출(동기화중)
  SERVER_ERROR: 60, // 이외 서버에러  - 버튼 노출
  STAR_FAILED: 70, // 피드 생성은 완료되었으나 별 지급 실패
  INSTAGRAM_TOKEN_EXPIRED: 80, // 인스타그램 토큰 만료
}

export const CONNECTION_STATE = {
  NOTYET: 'NOTYET',
  PENDING: 'PENDING',
  ENABLED: 'ENABLED',
  DISABLED_BY_USER: 'DISABLED_BY_USER',
  UNHANDLED_EXCEPTION: 'UNHANDLED_EXCEPTION',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
}

export const RSS_INFO_SYNC_STATE = {
  NO: 0,
  REQUEST: 1,
  REJECTED: 2,
  ACCEPTED: 3,
  ADMIN: 99,
}

/* 키값으로 참조하도록 수정 */
export const CHANNEL_CAST_ORDER_TYPE = {
  PUBLISHEDAT_DESC: { title: '최신화부터' },
  PUBLISHEDAT_ASC: { title: '첫 화부터' },
  POPULAR: { title: '인기' },
}

export const CHANNEL_CAST_ORDER_TYPE_LIST = Object.keys(
  CHANNEL_CAST_ORDER_TYPE,
).map(key => CHANNEL_CAST_ORDER_TYPE[key])

export const HOUSE_DEFAULT_TAG_LIST = [
  {
    keyword: '케이팝',
  },
  {
    keyword: '힙합',
  },
  {
    keyword: '클래식',
  },
  {
    keyword: '인디',
  },
  {
    keyword: '아이돌',
  },
  {
    keyword: '사회',
  },
  {
    keyword: '테크',
  },
  {
    keyword: '연애',
  },
  {
    keyword: '토크',
  },
  {
    keyword: '주식',
  },
  {
    keyword: '코인',
  },
  {
    keyword: '커리어',
  },
]

export const HOUSE_SUBJECT_TAG_LIST = [
  {
    keyword: '음악',
  },
  {
    keyword: '뉴스',
  },
  {
    keyword: '스타트업',
  },
  {
    keyword: '정치',
  },
  {
    keyword: '경제',
  },
  {
    keyword: '시사',
  },
  {
    keyword: '문화예술',
  },
  {
    keyword: '책',
  },
  {
    keyword: '스포츠',
  },
  {
    keyword: '언어',
  },
  {
    keyword: '신앙',
  },
  {
    keyword: 'ASMR',
  },
  { keyword: '사회' },
  { keyword: '과학' },
  { keyword: '사랑' },
  { keyword: '마음' },
  { keyword: '커리어' },
  { keyword: '네트워킹' },
  { keyword: '운동' },
  { keyword: '게임' },
]
