import { observable, action } from 'mobx'
import {
  YOUTUBE_SYNC_ERROR,
  RSS_SYNC_ERROR,
  YOUTUBE_SYNC_STATE,
} from '@consts/cast'
import { isMobile } from 'react-device-detect'
import PlayerManager from '@utils/PlayerManager'
import { RSS_INFO_SYNC_STATE } from '@consts/'
import { message } from 'antd'
import { Store } from '.'
import { Network } from './networks'
import { CastModel } from './models'

// class CastViewModel {
//   @observable _elem = null
//   @computed get elem() {
//     return this._elem
//   }
//   set elem(value) {
//     if (
//       this._elem
//       && value
//       && typeof this._elem === 'object'
//       && typeof value === 'object'
//     ) {
//       Object.keys(value).forEach(key => {
//         this._elem[key] = value[key]
//       })
//     }
//     else {
//       this._elem = value
//     }
//   }

//   screenCount = 0
//   constructor(stores, props) {
//     if (props) {
//       this.elem = props.elem || null
//     }
//   }
// }

export default class CastStore {
  @observable myCastList
  @observable myCastListCount

  @observable userCastList

  @observable latestCastList
  @observable popularCastList
  @observable followCastList
  @observable historyCastList

  @observable __exceptedPopularIdList
  @observable __exceptedLatestIdList
  @observable __exceptedFollowIdList
  @observable __exceptedHistoryIdList
  @observable __tagKeywordList
  @observable __nextPopularCursor
  @observable __nextLatestCursor
  @observable __nextFollowCursor
  @observable __nextHistoryCursor

  @observable castDetail

  /** 재생 관련 */
  @observable currentPlayCast
  @observable currentPlaySong
  @observable currentUsersPlayCasts
  @observable currentUsersPlaySong

  @observable currentControlList // 현재 재생 중인 흐름 연관 리스트
  @observable isAutoNext // 다음 흐름 자동 재생
  @observable currentCastIdx // 현재 흐름 index

  @observable currentPlayCategory // 현재 재생 중인 흐름 리스트 카테고리
  @observable currentPlaySort // 현재 재생 중인 흐름 리스트 정렬

  @observable songPlayeIdx // 현재 재생 중인 곡의 흐름 인덱스
  @observable interactPlay // 사용자가 직접 재생시켰을 때
  @observable stopPlayer // 플레이어 밖에서 플레이어를 멈출 때

  @observable playing // 재생 여부
  @observable isLoadingPlayer
  @observable url // 플레이어 url
  @observable muted // 플레이어 음소거 유무
  @observable volume // 플레이어 볼륨

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = async (isInitializedPlayer = true) => {
    this.myCastList = []
    this.myCastListCount = 0

    this.userCastList = []

    this.latestCastList = []
    this.popularCastList = []
    this.followCastList = []
    this.historyCastList = []
    this.recommendationCastList = []

    this.__exceptedPopularIdList = null
    this.__exceptedLatestIdList = null
    this.__exceptedFollowIdList = null
    this.__exceptedHistoryIdList = null
    this.__tagKeywordList = ['전체']
    this.__nextPopularCursor = null
    this.__nextLatestCursor = null
    this.__nextFollowCursor = null
    this.__nextHistoryCursor = null
    this.__nextRecommendationCastList = null
    this.__tagKeywordRecommendationList = null

    this.castDetail = null

    if(isInitializedPlayer) {
      this.stopPlayer = false

      this.playing = true
      this.isLoadingPlayer = false
      this.url = null
      this.muted = true
    }
  }

  @action fetchCast = async castId => {
    if (!castId) {
      console.error('[CastStore][fetchCast] CastId is required.')
      return
    }

    const where = {
      __include:
        'UsersLikeCasts,AudioFiles,LiveRoomRecordings,CastsHaveTags.Tags,Houses,Users',
      __attributes: 'Users.name,Users.imageUri,Users.isInfluencer,Users.account',
      __order: 'createdAtDesc',
    }
    const _cast = await this.network.castNetwork.getCast(castId, where)

    if (!_cast) {
      console.error('[CastStore][fetchCast] getCast is failed.')
      return
    }
    return new CastModel(this.store, _cast)
  }

  @action fetchCastDetail = async castId => {
    if (!castId) {
      console.error('[CastStore][fetchCast] CastId is required.')
      return
    }

    const where = {
      __include:
        'UsersLikeCasts,AudioFiles,LiveRoomRecordings,CastsHaveTags.Tags,Users,Houses',
      __addColumn: this.store.authStore?.currentUser
        ? 'isFollowed,lastPlayTimeStamp'
        : null,
      'UsersLikeCasts.userId': this.store.authStore?.currentUser?.id,
      __order: 'createdAtDesc',
    }
    const _cast = await this.network.castNetwork.getCast(castId, where)

    if (!_cast) {
      console.error('[CastStore][fetchCast] getCast is failed.')
      return
    }
    this.castDetail = new CastModel(this.store, _cast)

    await this.store.commentStore.fetchCastReply(castId)

    const castUserIsFollowed = await this.store.userStore.fetchIsFollowed(
      this.castDetail.User,
    )

    this.castDetail['User']['isFollowed'] = castUserIsFollowed

    return new CastModel(this.store, _cast)
  }
  @action fetchCastDetailFromLinkKey = async castLinkKey => {
    if (!castLinkKey) {
      console.error('[CastStore][fetchCast] CastId is required.')
      return
    }

    const where = {
      __include:
        'UsersLikeCasts,AudioFiles,LiveRoomRecordings,CastsHaveTags.Tags,Users,Houses',
      __addColumn: this.store.authStore?.currentUser
        ? 'isFollowed,lastPlayTimeStamp'
        : null,
      'UsersLikeCasts.userId': this.store.authStore?.currentUser?.id,
      __order: 'createdAtDesc',
    }
    const _cast = await this.network.castNetwork.getCastFromLinkKey(castLinkKey, where)

    if (!_cast) {
      console.error('[CastStore][fetchCast] getCast is failed.')
      return
    }
    this.castDetail = new CastModel(this.store, _cast)

    await this.store.commentStore.fetchCastReply(_cast.id)

    const castUserIsFollowed = await this.store.userStore.fetchIsFollowed(
      this.castDetail.User,
    )

    this.castDetail['User']['isFollowed'] = castUserIsFollowed

    return new CastModel(this.store, _cast)
  }

  @action fetchUserCastList = async (userId, __limit = 20) => {
    if (!userId) {
      console.error('[CastStore][fetchMyCastList] UserId is required.')
      return
    }

    const _offset = this.userCastList.length

    const where = {
      __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings,Users,Houses',
      __order: 'createdAtDesc',
      userId,
      __limit,
      __offset: _offset,
      isPublished: 1,
      houseId: 'null',
      houseIdOperator: 'is',
    }
    const castList = await this.network.castNetwork.getCastList(where)

    if (!castList) {
      console.error('[CastStore][fetchMyCastList] getCastList failed.')
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))

    if (this.userCastList.length > 0) {
      this.userCastList.push(..._castList)
    }
    else {
      this.userCastList = _castList
    }

    return castList.length >= 20
  }

  @action fetchMyCastList = async (userId, __offset) => {
    if (!userId) {
      console.error('[CastStore][fetchMyCastList] UserId is required.')
      return
    }

    const where = {
      __include: 'UsersLikeCasts,AudioFiles,LiveRoomRecordings',
      __order: 'createdAtDesc',
      userId,
      __limit: 10,
      __offset,
      houseId: 'null',
      houseIdOperator: 'is',
    }
    const _castList = await this.network.castNetwork.getCastList(where)

    if (!_castList) {
      console.error('[CastStore][fetchMyCastList] getCastList failed.')
      return
    }

    this.myCastList = _castList.map(cast => new CastModel(this.store, cast))

    if (__offset === 0) {
      const castListCount = await this.network.castNetwork.getCastListCount(
        where,
      )

      this.myCastListCount = castListCount
    }
  }

  @action fetchPopularCastList = async isOriginal => {
    const options = {
      __limit: 20,
      isOriginal,
      __exceptedIdList: this.__exceptedPopularIdList,
      __tagKeywordList: this.__tagKeywordList
        ?.slice()
        ?.map(val => {
          if (val === '전체' || val === '오리지널') {
            return ''
          }
          return val
        })
        ?.join(','),
    }

    if (this.__nextPopularCursor) {
      Object.assign(options, {
        __nextCursor: this.__nextPopularCursor,
      })
    }

    const result = await this.network.castNetwork.getPopularCastList(options)
    const { castList, __nextCursor, __exceptedIdList } = result || {}
    if (!castList) {
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))

    if (this.popularCastList.length > 0) {
      this.popularCastList.push(..._castList)
      this.popularCastList = [...this.popularCastList]
    }
    else {
      this.popularCastList = _castList
    }
    if (__nextCursor) {
      this.__nextPopularCursor = __nextCursor
    }

    if (__exceptedIdList) {
      this.__exceptedPopularIdList = __exceptedIdList
    }

    return !!__nextCursor
  }

  @action fetchLatestCastList = async isOriginal => {
    if (!this.store.authStore?.currentUser) return

    const options = {
      __limit: 20,
      isOriginal,
      __exceptedIdList: this.__exceptedLatestIdList,
      __tagKeywordList: this.__tagKeywordList
        ?.slice()
        ?.map(val => {
          if (val === '전체' || val === '오리지널') {
            return ''
          }
          return val
        })
        ?.join(','),
    }

    if (this.__nextLatestCursor) {
      Object.assign(options, {
        __nextCursor: this.__nextLatestCursor,
      })
    }

    const result = await this.network.castNetwork.getLatestCastList(options)
    const { castList, __nextCursor, __exceptedIdList } = result || {}

    if (!castList) {
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))

    if (this.latestCastList.length > 0) {
      this.latestCastList.push(..._castList)
      this.latestCastList = [...this.latestCastList]
    }
    else {
      this.latestCastList = _castList
    }

    if (__nextCursor) {
      this.__nextLatestCursor = __nextCursor
    }

    if (__exceptedIdList) {
      this.__exceptedLatestIdList = __exceptedIdList
    }

    return !!__nextCursor
  }

  @action fetchFollowCastList = async isOriginal => {
    if (!this.store.authStore?.currentUser) return

    const options = {
      __limit: 20,
      isOriginal,
      __exceptedIdList: this.__exceptedFollowIdList,
      __tagKeywordList: this.__tagKeywordList
        ?.slice()
        ?.map(val => {
          if (val === '전체' || val === '오리지널') {
            return ''
          }
          return val
        })
        ?.join(','),
    }

    if (this.__nextFollowCursor) {
      Object.assign(options, {
        __nextCursor: this.__nextFollowCursor,
      })
    }

    const result = await this.network.castNetwork.getFollowCastList(options)
    const { castList, __nextCursor, __exceptedIdList } = result || {}

    if (!castList) {
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))
    if (this.followCastList.length > 0) {
      this.followCastList.push(..._castList)
      this.followCastList = [...this.followCastList]
    }
    else {
      this.followCastList = _castList
    }

    if (__nextCursor) {
      this.__nextFollowCursor = __nextCursor
    }

    if (__exceptedIdList) {
      this.__exceptedFollowIdList = __exceptedIdList
    }

    return !!__nextCursor
  }

  @action fetchRecommendationCastList = async () => {
    const options = {
      __limit: 12,
      __include: 'Houses',
    }

    if (this.__nextRecommendationCastList) {
      Object.assign(options, {
        __nextCursor: this.__nextRecommendationCastList,
      })
    }

    if (this.__tagKeywordRecommendationList) {
      Object.assign(options, {
        __tagKeywords: this.__tagKeywordRecommendationList,
      })
    }

    const result = await this.network.castNetwork.getRecommendationCastList(
      options,
    )

    if (!result) {
      return
    }

    const { castList, __nextCursor, __tagKeywords } = result

    if (!castList) {
      return
    }

    const _castList = castList.map(cast => new CastModel(this.store, cast))
    if (this.recommendationCastList.length > 0) {
      this.recommendationCastList.push(..._castList)
      this.recommendationCastList = [...this.recommendationCastList]
    }
    else {
      this.recommendationCastList = _castList
    }

    if (__nextCursor) {
      this.__nextRecommendationCastList = __nextCursor
    }

    if (__tagKeywords) {
      this.__tagKeywordRecommendationList = __tagKeywords
    }
    return !!__nextCursor
  }

  @action fetchHomeRecommendationCastList = async ({
    __nextCursor,
    __limit,
  }) => {
    const fetchedRecommendationCastList = await this.network.castNetwork.getRecommendationCastList({
      __nextCursor,
      __limit,
    })
    if (!fetchedRecommendationCastList) {
      return
    }

    const recommendationList = fetchedRecommendationCastList.castList.map(elem => new CastModel(this.store, elem))
    return {
      contentList: recommendationList,
      __nextCursor: fetchedRecommendationCastList?.__nextCursor,
    }
  }

  @action fetchHistoryCastList = async () => {
    if (
      !this.store.authStore.currentUser
      || this.historyCastList?.[this.historyCastList?.length - 1]?.isFinished
    ) {
      return
    }

    const _offset = this.historyCastList?.length

    const result = await this.network.castNetwork.getHistoryCastList({
      __limit: 20,
      __offset: _offset,
      __required: 'Casts',
      __include: 'Casts.AudioFiles,Casts.Users,Casts.CastsHaveTags.Tags,Houses',
      'Casts.isPublished': 1,
      __order: 'createdAtDesc',
    })
    if (!result || result?.length <= 0) {
      if (this.historyCastList?.[this.historyCastList?.length - 1]) {
        this.historyCastList[this.historyCastList?.length - 1].isFinished = true
      }
      return
    }

    const _historyCastList = result.map(
      _history => new CastModel(this.store, _history['Cast']),
    )

    if (_offset && this.historyCastList.length > 0) {
      this.historyCastList = [...this.historyCastList.slice(0, _offset), ..._historyCastList]
    }
    else {
      this.historyCastList = _historyCastList
    }

    return result.length >= 20
  }

  @action createCast = async (cast, tagList = []) => {
    if (!cast) {
      console.error('[CastStore][createCast] Cast is required.')
      return
    }
    if (
      !this.store
      || !this.store.authStore
      || !this.store.authStore.currentUser
    ) {
      console.error('[CastStore][createCast] CurrentUser is required.')
      return
    }

    const {
      title,
      description,
      imageUri,
      audioFileId,
      liveRoomRecordingId,
      isPublished,
      houseId,
      isHouseMember,
      reservedPublishedAt,
      isReserved,
    } = cast

    const value = {
      title,
      description,
      imageUri,
      isPublished,
      houseId,
      isHouseMember,
      Tags: tagList,
      reservedPublishedAt,
      isReserved,
    }

    if (audioFileId) {
      value.audioFileId = audioFileId
    }
    else if (liveRoomRecordingId) {
      value.liveRoomRecordingId = liveRoomRecordingId
    }

    const newCastList = await this.network.castNetwork.postCast([value])

    if (!newCastList || !(newCastList.length > 0)) {
      console.error('[CastStore][createCast] Cast create failed.')
      return
    }

    const newCast = new CastModel(this.store, newCastList[0])
    this.myCastList.unshift(newCast)

    return newCast
  }

  @action.bound
  async createAudioFile({
    title,
    description = null,
    imageUri,
    originalUri,
    duration,
    userId,
  }) {
    if (!title || !originalUri) {
      console.error(
        '[CastStore][createAudioFile] Title & originalUri is required.',
      )
      return
    }
    const where = [
      {
        title,
        description,
        imageUri,
        originalUri,
        duration,
        userId,
      },
    ]
    const result = await this.network.castNetwork.postAudioFile(where)

    if (!result || result.length === 0) {
      console.error('[CastStore][createAudioFile] Create audiofile is failed.')
    }

    return result[0]
  }

  @action.bound
  async uploadCastAudio(butterfilesFile) {
    return this.store.useLoading(async () => {
      // const res = await this.network.castNetwork.uploadCastAudio(file)
      // return res
      if (!butterfilesFile?.src?.file?.name || !butterfilesFile?.src?.file?.type) {
        return
      }

      const presignedUrlData = await this.network.castNetwork.getAudioFilePresignedUrl(
        {
          isChunked: 1,
          chunkCount: 1,
          filename: butterfilesFile?.custom?.name || butterfilesFile?.src?.file?.name,
        },
      )
      const presignedUrl =
        presignedUrlData?.uploadUrlList?.length > 0
        && presignedUrlData?.uploadUrlList?.[0]
      if (presignedUrl) {
        // const _audioFormData = new FormData()
        // _audioFormData.append('audio', file?.src?.file)
        // _audioFormData.append('Content-Type', file?.src?.file?.type)
        // _audioFormData.append('key', presignedUrlData?.uploadData?.Key)

        const uploadedRes = await this.network.castNetwork.putAudioFilePresignedUrl(
          presignedUrl,
          await butterfilesFile?.src?.file?.arrayBuffer(),
          {
            headers: {
              'Content-Type': butterfilesFile?.custom?.type || butterfilesFile?.src?.file?.type,
              'Content-Encoding': 'base64',
            },
          },

          // _audioFormData,
          // {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          // },
        )
        const data = await this.network.castNetwork.postAudioFilePresignedUrl({
          uploadId: presignedUrlData?.uploadData?.UploadId,
          uploadedPartList: [
            {
              ETag: uploadedRes?.headers?.ETag || uploadedRes?.headers?.etag,
              PartNumber: 1, // 1번부터 시작
            },
          ],
          key: presignedUrlData?.uploadData?.Key,
        })
        return data
      }
    })
  }

  @action.bound
  async uploadCastImage(file) {
    // return this.store.useLoading(async () => {
    const res = await this.network.castNetwork.uploadCastImage(file)
    return res
    // })
  }

  @action updateCast = async (cast, tagList) => {
    if (!cast || !cast['id']) {
      console.error('[CastStore][updateCast] Cast is required.')
      return
    }
    if (
      !this.store
      || !this.store.authStore.currentUser
      || !this.store.authStore.currentUser['id']
    ) {
      console.error('[CastStore][updateCast] CurrentUser is required.')
      return
    }

    const {
      title,
      description,
      imageUri,
      audioFileId,
      liveRoomRecordingId,
      isPublished,
      isHouseMember,
      reservedPublishedAt,
      isReserved,
    } = cast

    const value = {
      title,
      description,
      imageUri,
      isPublished,
      isHouseMember,
      Tags: tagList,
      reservedPublishedAt,
      isReserved,
    }

    if (audioFileId) {
      value.audioFileId = audioFileId
    }
    else if (liveRoomRecordingId) {
      value.liveRoomRecordingId = liveRoomRecordingId
    }

    const newCast = await this.network.castNetwork.putCast(cast['id'], value)
    if (!newCast) {
      console.error('[CastStore][putCast] Cast update failed.')
      return
    }

    this.myCastList = this.myCastList.map(_cast =>
      _cast['id'] === newCast['id']
        ? new CastModel(this.store, newCast)
        : _cast,
    )

    return true
  }

  @action deleteCast = async castId => {
    if (!castId) {
      console.error('[CastStore][deleteCast] Id is required.')
      return
    }

    const deleteResult = await this.network.castNetwork.deleteCast(castId)

    if (!deleteResult) {
      console.error('[CastStore][deleteCast] Delete cast is failed.')
      return
    }

    this.myCastList = this.myCastList.filter(_cast => _cast['id'] !== castId)
  }

  @action requestYoutubeSync = async (
    { user, house },
    { youtubeChannelUrl, youtubeVideoUrl, tagKeywordList } = {},
  ) => {
    const ret = {
      isSuccess: false,
    }
    if (!youtubeChannelUrl && !youtubeVideoUrl) {
      message.error('유튜브 링크가 잘못되었습니다')
      return ret
    }

    const result = await this.network.castNetwork.postYoutubeSyncJobs({
      youtubeChannelUrl,
      youtubeVideoUrl,
      Tags: tagKeywordList,
      ...(user ? {} : { houseId: house['id'] }),
    })
    if (result) {
      ret.isSuccess = result.isSuccess
      if (result.isSuccess) {
        ret.data = result.data

        if (youtubeChannelUrl) {
          let syncState = YOUTUBE_SYNC_STATE['REQUEST']
          if (result?.data?.syncState) {
            syncState = result?.data?.syncState
          }

          if (user) {
            user['youtubeChannelSyncState'] = syncState
          }
          else if (house) {
            house['youtubeChannelSyncState'] = syncState
          }
        }
      }
      else if (
        !result.isSuccess
        && result.error?.code
        && Object.keys(YOUTUBE_SYNC_ERROR).find(
          label => YOUTUBE_SYNC_ERROR[label] === result.error?.code,
        )
      ) {
        ret.errorCode = result.error?.code
        ret.errorLabel = result.error?.message
      }
    }
    return ret
  }
  @action requestRssSync = async (
    { user, house },
    { rssUrl, tagKeywordList = [] },
  ) => {
    const ret = {
      isSuccess: false,
    }
    if (!rssUrl) {
      return ret
    }

    const result = await this.network.castNetwork.postRssSyncInfo({
      rssUrl,
      ...(user ? {} : { houseId: house['id'] }),
      tagKeywordList,
    })
    if (result) {
      ret.isSuccess = result.isSuccess
      if (result.isSuccess) {
        ret.data = result.data

        let syncState = RSS_INFO_SYNC_STATE['REQUEST']
        if (result?.data?.syncState) {
          syncState = result?.data?.syncState
        }
        if (user) {
          user['rssInfoSyncState'] = syncState
        }
        else if (house) {
          house['rssInfoSyncState'] = syncState
        }
      }
      else if (
        !result.isSuccess
        && result.error?.code
        && Object.keys(RSS_SYNC_ERROR).find(
          label => RSS_SYNC_ERROR[label] === result.error?.code,
        )
      ) {
        ret.errorCode = result.error?.code
        ret.errorLabel = result.error?.message
      }
    }
    return ret
  }

  @action publishRssUrl = async (
    { house, email },
  ) => {
    const ret = {
      isSuccess: false,
    }

    const options = {
      houseId : house?.id
    }

    if(email){
      options.email = email
    }
    const result = await this.network.castNetwork.postRssPublishUrl(options)


    if (result) {
      ret.isSuccess = result.isSuccess
      if (result.isSuccess) {
        ret.data = result.data


      }
      else if (
        !result.isSuccess
        && result.error?.code
      ) {
        ret.errorCode = result.error?.code
        ret.errorLabel = result.error?.message
      }
    }
    return ret
  }

  // 캐스트 재생
  @action.bound
  async fetchLoadPlayCast(
    cast,
    categoryName = null,
    sortBy = null,
    isInteractPlay = true,
  ) {
    this.currentUsersPlaySong = {}
    this.store.playerStore.currentPlayMixtape = {}
    this.currentPlayCast = {}
    this.currentPlaySong = {}
    this.url = ''
    this.playing = false

    // 비회원 클릭 시, 로그인 유도
    // this.store.authStore.tryAuth()

    if (!cast) return
    return this.store.useLoading(async () => {
      clearTimeout(this._handlerResetIsReady)
      this._handlerResetIsReady = setTimeout(() => {
        this.isLoadingPlayer = false
      }, 15000)
      this.isLoadingPlayer = true

      this.myPlayAuth = true
      this.isTryPlay = true
      if (!this.myPlayAuth) return false

      const where = {
        __include:
          'UsersLikeCasts,AudioFiles,LiveRoomRecordings,CastsHaveTags.Tags,Users,Houses',
        __addColumn: this.store.authStore?.currentUser
          ? 'isFollowed,lastPlayTimeStamp'
          : null,
        'UsersLikeCasts.userId': this.store.authStore?.currentUser?.id,
        __order: 'createdAtDesc',
      }
      const _cast = await this.network.castNetwork.getCast(cast.id, where)

      if (!_cast) {
        console.error('[CastStore][fetchLoadPlayCast] getCast is failed.')
        return
      }
      this.currentPlayCast = new CastModel(this.store, _cast)

      if (!this.currentPlayCast.isPublished || this.currentPlayCast.deletedAt) {
        alert('비공개되었거나 삭제된 캐스트입니다.')
        return false
      }

      // this.currentPlayCast = this.castDetail

      this.currentPlaySong = this.currentPlayCast?.LiveRoomRecording
        ? this.currentPlayCast?.LiveRoomRecording
        : this.currentPlayCast?.AudioFile

      // this.logPlayMixtape(this.currentPlayCast)

      this.fetchControlList(categoryName, sortBy)

      this.interactPlay = isInteractPlay
      this.stopPlayer = false

      if (isMobile) {
        this.url =
          /* chrome web mobile view 만 안되는듯? 추가적인 테스트가 없어서 주석처리 */
          /* AudioFiles, LiveRoomRecordings */
          // this.currentPlaySong?.m3u8Url
          ''
          || this.currentPlaySong?.mp3Url

          /* LiveRoomRecordings */
          || this.currentPlaySong?.mp4Url

          /* AudioFiles */
          || this.currentPlaySong?.originalUrl
      }
      else {
        this.url =
          /* AudioFiles, LiveRoomRecordings */
          this.currentPlaySong?.m3u8Url
          || this.currentPlaySong?.mp3Url

          /* LiveRoomRecordings */
          || this.currentPlaySong?.mp4Url

          /* AudioFiles */
          || this.currentPlaySong?.originalUrl
      }

      return !!this.currentPlaySong
    })
  }

  /** setter */
  @action.bound
  setPlaying = async value => {
    this.playing = value
  }

  @action.bound
  setMuted = async value => {
    this.muted = value
  }

  @action.bound
  setVolume = async value => {
    this.volume = value
    const playStatus = JSON.parse(sessionStorage.getItem('cast-play-status'))
    const _playStatus = {
      ...playStatus,
      volume: value,
    }
    PlayerManager().setValue('player', { type: 'cast', ..._playStatus })
    sessionStorage.setItem('cast-play-status', JSON.stringify(_playStatus))
  }

  @action.bound
  updateInteractPlay = value => {
    this.interactPlay = value
  }

  createCastHistory = async currentPlayCastId => {
    if (!currentPlayCastId) {
      return
    }

    try {
      const usersPlayCasts = await this.network.castNetwork.postCastHistory(
        currentPlayCastId,
        {
          appIdentifierId: null,
          lastPlayTimeStamp: this.currentPlayCast?.lastPlayTimeStamp,
        },
      )
      this.currentUsersPlayCasts = usersPlayCasts
    }
    catch (error) {
      console.log('[createCastHistory]', error)
    }
  }

  updateCastHistory = async (
    currentPlayCastId,
    currentUsersPlayCastsId,
    currentPlayedTime,
  ) => {
    if (!currentPlayCastId || !currentUsersPlayCastsId || !currentPlayedTime) {
      return
    }

    // 마지막으로 수정한 시간이 12시간 지나면 새로 로그 생성 ( pause 한 후 12시간이 지나면 )
    if (
      new Date(this.currentUsersPlayCasts?.updatedAt).getTime() + 43200000
      < new Date().getTime()
    ) {
      await this.createCastHistory(this.currentPlayCast?.id)

      return
    }

    try {
      const usersPlayCasts = await this.network.castNetwork.putCastHistory(
        currentPlayCastId,
        currentUsersPlayCastsId,
        { lastPlayTimeStamp: Math.floor(currentPlayedTime) },
      )
      this.currentUsersPlayCasts = usersPlayCasts
    }
    catch (error) {
      console.log('[updateCastHistory]', error)
    }
  }

  @action deleteCastHistory = async castId => {
    if (!castId) {
      return false
    }

    const result = await this.network.castNetwork.deleteCastHistory(castId)

    if (!result) {
      return false
    }

    // const historyIndex = this.castHistoryList?.findIndex?.(
    //   (val) => val?.Cast?.id === castId,
    // )

    // const homeIndex = this.castHomeHistoryList?.findIndex?.(
    //   (val) => val?.Cast?.id === castId,
    // )

    // if (historyIndex > -1) {
    //   this.castHistoryList?.splice?.(historyIndex, 1)
    // }

    // if (homeIndex > -1) {
    //   this.castHomeHistoryList?.splice?.(homeIndex, 1)

    //   if (this.castHomeHistoryList?.length === 0) {
    //     await this.fetchCastHomeHsitoryList()
    //   }
    // }

    return true
  }

  @action.bound
  nextPlayCast = async () => {
    if (this.currentControlList.length > 1) {
      const currIndex = this.currentControlList.findIndex(
        item => item.id === this.currentPlayCast.id,
      )

      this.currentCastIdx = currIndex

      const nextIndex =
        currIndex !== this.currentControlList.length - 1 ? currIndex + 1 : 0

      await this.fetchLoadPlayCast(this.currentControlList[nextIndex])

      this.currentControlList.shift()
    }
  }

  @action likeCast = async id => {
    const result = await this.network.castNetwork.postLikeCast(id)
    return result
  }

  @action unLikeCast = async id => {
    const result = await this.network.castNetwork.deleteLikeCast(id)
    return result
  }

  @action.bound
  _likeCast = async () => {
    if (this.currentPlayCast.UsersLikeCasts.length > 0) {
      const result = await this.unLikeCast(this.currentPlayCast?.id)
      if (!result) return
      this.currentPlayCast.UsersLikeCasts = []
      if (this.currentPlayCast.likeCount > 0) {
        this.currentPlayCast.likeCount--
      }
    }
    else {
      const result = await this.likeCast(this.currentPlayCast?.id)
      if (!result) return
      this.currentPlayCast.UsersLikeCasts.push(result)
      this.currentPlayCast.likeCount++
    }
  }

  // 다음 흐름 자동 재생 토글
  @action.bound
  toggleAutoNext = () => {
    this.isAutoNext = !this.isAutoNext
  }

  @action.bound
  updateCurrentControllList = list => {
    this.currentControlList = list
  }

  /**
   * 재생한 흐름 종류의 다음 흐름 리스트
   * @param {*} value
   */
  @action.bound
  fetchControlList = async (categoryName = null, sortBy = null) => {
    this.currentPlayCategory = categoryName
    this.currentPlaySort = sortBy

    switch (categoryName) {
      //  case 'total':
      //    if (sortBy === 'popular') {
      //      await this.store.mixtapeStore.fetchHomePopularTotalMixtapeList()
      //    }
      //    else if (sortBy === 'latest') {
      //      await this.store.mixtapeStore.fetchTotalMixtapeList({ limit: 20 })
      //    }
      //    this.currentControlList = this.store.mixtapeStore.totalMixtapeList
      //    break
      case 'history':
        await this.fetchHistoryCastList({ limit: 20 })
        this.currentControlList = this.historyCastList
        break
      case 'recommendation':
        await this.fetchRecommendationCastList({ limit: 20 })
        this.currentControlList = this.recommendationCastList
        break
      case 'latest':
        await this.fetchLatestCastList({ limit: 20 })
        this.currentControlList = this.latestCastList
        break
      case 'popular':
        await this.fetchPopularCastList({ limit: 20 })
        this.currentControlList = this.popularCastList
        break
      case 'follow':
        await this.fetchFollowCastList({ limit: 20 })
        this.currentControlList = this.followCastList
        break
      case 'user':
        await this.fetchUserCastList(
          this.castDetail?.store?.userStore?.thisUser?.id,
        )
        this.currentControlList = this.userCastList
        break
      case 'search':
        await this.store.searchStore.fetchSearchCastList(
          this.store.searchStore.mixtapeSearchKeyword,
        )
        this.currentControlList = this.store.searchStore.searchedCastList
        break
      //  case 'tag':
      //    if (sortBy === 'popular') {
      //      await this.store.searchStore.fetchTagPopulartMixtapeList(
      //        this.store.searchStore.mixtapeTagKeyword,
      //      )
      //    }
      //    else if (sortBy === 'latest') {
      //      await this.store.searchStore.fetchTagLatestMixtapeList(
      //        this.store.searchStore.mixtapeTagKeyword,
      //      )
      //    }
      //    this.currentControlList = this.store.searchStore.tagMixtapeList
      //    break
      default:
        break
    }

    if (this.currentControlList && this.currentControlList.length > 1) {
      const currIndex = this.currentControlList.findIndex(
        item => item.id === this.currentPlayCast.id,
      )

      this.currentCastIdx = currIndex
    }
  }

  @action.bound
  fetchCastDeepLinks = async castId => {
    return this.store.useLoading(async () => {
      const res = await this.network.userNetwork.getDeepLinks({
        castId,
      })

      return res
    })
  }
}
