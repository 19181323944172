import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Flex, Caption2 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`

const Modal = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  width: 320px;
`

const MobdlBody = styled.div``

const ButtonList = styled(Flex)`
  flex-direction: column;
  > div:last-child {
    border: none;
  }
`

const ButtomItem = styled(Flex)`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #f4f4f4;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onClose, onDelete, onReport }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={handleClose} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <ButtonList>
                <ButtomItem onClick={onReport}>
                  <Caption2>사용자 차단</Caption2>
                </ButtomItem>
                <ButtomItem onClick={onReport}>
                  <Caption2>신고</Caption2>
                </ButtomItem>
              </ButtonList>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
