import { Network } from '..'

export default class RevenueNetwork {
  constructor(network: Network) {
    this.network = network
  }

  getAccumulations = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/revenues/accumulations',
        'get',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork getAccumulations error: ', err)
    }
  }

  getRevenueUser = async (id) => {
    try {
      const { data } = await this.network._axiosApiAuth(`/users/${id}`, 'get', {
        autoUpdateRevenue: true,
      })
      return data || []
    } catch (error) {
      console.log('UserNetwork getUsers error: ', error)
    }
  }

  getRevenueNotifications = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/revenue-notifications',
        'get',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork getRevenueNotifications error: ', err)
    }
  }

  getRevenueHistories = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/revenue-histories',
        'get',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork getRevenueHistories error: ', err)
    }
  }

  getBankAccount = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/bank-accounts',
        'get',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork getBankAccount error: ', err)
    }
  }

  postBankAccount = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/bank-accounts',
        'post',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork postBankAccount error: ', err)
    }
  }

  // putBankAccount = async (id, params) => {
  //   try {
  //     const { data } = await this.network._axiosApiAuth(
  //       `/bank-accounts/${id}`,
  //       'put',
  //       params,
  //     )
  //     return data || null
  //   } catch (err) {
  //     console.log('RevenueNetwork putBankAccount error: ', err)
  //   }
  // }

  postAdultCertification = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/adult-certifications',
        'post',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork postAdultCertification error: ', err)
    }
  }

  postRevenueNotifications = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/revenue-notifications',
        'post',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork postRevenueNotifications error: ', err)
    }
  }

  postRevenueHistories = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/revenue-histories',
        'post',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork postRevenueHistories error: ', err)
    }
  }

  putRevenueNotifications = async (params) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenue-notifications`,
        'put',
        params,
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork putRevenueNotifications error: ', err)
    }
  }

  postRevenueLicense = async () => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/revenues/license`,
        'post',
      )
      return data || null
    } catch (err) {
      console.log('RevenueNetwork postRevenueLicense error: ', err)
    }
  }
}
