import { Div } from '@components/atoms'
import { SalonResponseMobileBox } from '@components/molecules'
import { SalonListenerInfo } from '@components/organisms'
import { useStore } from '@utils/hooks'
import { Observer, observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import LiveRoomMusicBox from '../card/LiveRoomMusicBox'

interface ISalonInfoSection {
  _toggleUserReport: Function;
  isSwitchOn: Boolean;
  setIsSwitchOn: Function;
}

const SalonInfoSection: React.FC<ISalonInfoSection> = ({
  _toggleUserReport,
  isSwitchOn,
  setIsSwitchOn,
  toggleSalonMixtape,
}) => {
  const { authStore, liveRoomStore, starStore } = useStore()

  const currentUser = authStore.currentUser || null
  const currLiveRoomUserList = liveRoomStore.currLiveRoomUserList || []
  const currLiveRoom = liveRoomStore._currLiveRoom || null

  const _sortData = userList => {
    const contributorList = liveRoomStore.currLiveRoomDonatedHistoryList
      ?.slice()
      ?.sort((a, b) => {
        return b?.starValue - a?.starValue
      })

    const array = []
    for (let j = 0; j < contributorList.length; j++) {
      for (let i = 0; i < userList?.slice().length; i++) {
        if (
          userList?.slice()?.[i]?.userId === contributorList[j]?.sendingUserId
        ) {
          const user = userList?.slice()[i]

          user.starValue = contributorList[j]?.starValue

          array.push(user)
        }
      }
    }

    const sortedArray = [
      ...array,
      ...userList?.slice()?.filter(item => array.indexOf(item) < 0),
    ]

    return sortedArray
  }

  const reducer = (temp, curVal) => temp + curVal

  React.useEffect(() => {
    if (currLiveRoom) {
      starStore.getStarHistoriesFromLive(currLiveRoom['id'])
    }
  }, [currLiveRoom, starStore])

  return (
    <Section>
      <SalonResponseMobileBox
        style={{ marginBottom: 24 }}
        dynamicLink={currLiveRoom?.dynamicLink}
      />
      <LiveRoomMusicBox toggleSalonMixtape={toggleSalonMixtape} />
      <Observer>
        {
          () => {
            const data = _sortData(currLiveRoomUserList)
            const starCount =
            liveRoomStore.currLiveRoomDonatedHistoryList
              ?.slice()
              ?.map(item => parseInt(item?.starValue, 10))
              ?.reduce(reducer, 0) || 0

            return (
              <SalonListenerInfo
                loadSalonUser={liveRoomStore.loadSalonUser}
                currentUser={currentUser}
                currLiveRoom={currLiveRoom}
                currLiveRoomUserList={data}
                starCount={starCount}
                _toggleUserReport={
                  userId =>
                    _toggleUserReport(
                      'user',
                      userId,
                  currLiveRoom?.id,
                  currLiveRoom?.title,
                    )
                }
                presentStarToLive={starStore.presentStarToLive || (() => {})}
                sendChat={liveRoomStore.sendChat || (() => {})}
                isSwitchOn={isSwitchOn}
                setIsSwitchOn={setIsSwitchOn}
              />
            )
          }
        }
      </Observer>
    </Section>
  )
}

export default observer(SalonInfoSection)

const Section = styled(Div)`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  min-height: 960px;
  max-height: 960px;
  width: 100%;
  align-items: center;

  height: 100%;
`
