import QueryString from 'qs'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { HomePage } from '.'
import MobileCastPage from './MobileCastPage'
import MobileMixtapePage from './MobileMixtapePage'
import MobileSalonPage from './MobileSalonPage'

const DynamicPage = props => {
  const param = document.location.href.split('?')
  const query = QueryString.parse(param[1], {
    ignoreQueryPrefix: true,
  })

  /* 앱 딥링크 로직 배포 대응 */
  delete query['dataType']

  if (Object.keys(query).length >= 1 && query.mixtapeId) {
    return <MobileMixtapePage {...props} />
  }
  if (Object.keys(query).length >= 1 && query.castId) {
    return <MobileCastPage {...props} />
  }
  if (Object.keys(query).length >= 1 && query.liveRoomId) {
    return <MobileSalonPage {...props} />
  }
  if (Object.keys(query).length >= 1 && query.castId) {
    return <Redirect to={`/cast/${query.castId}`} />
  }
  if (Object.keys(query).length >= 1 && query.feedId) {
    if (query.houseId) {
      return (
        <Redirect
          to={
            {
              pathname: `/house/${query.houseId}`,
              state: {
                init: {
                  feedId: query.feedId,
                },
              },
            }
          }
        />
      )
    }
    if (query.userId) {
      return (
        <Redirect
          to={
            {
              pathname: `/user/${query.userId}`,
              state: {
                init: {
                  feedId: query.feedId,
                },
              },
            }
          }
        />
      )
    }
  }
  if (Object.keys(query).length === 1 && query.userId) {
    return <Redirect to={`/user/${query.userId}`} />
  }
  if (Object.keys(query).length === 1 && query.houseId) {
    return <Redirect to={`/house/${query.houseId}`} />
  }

  return <HomePage {...props} />
}

export default DynamicPage
