import styled from 'styled-components'

const TextBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border: none;
  border-radius: 32px;
  cursor: pointer;

  color: #646464;
  font-family: BMDoHyeon;
  font-size: 14px;

  max-width: 272px;
  width: 100%;
  height: 56px;

  :active {
    background-color: #5d4afc;
    opacity: 0.64;
    color: #ffffff;
  }
`

export default TextBtn
