import React from 'react'
import styled from 'styled-components'
import Button from '@components/atoms/button'
// import { Link } from 'react-router-dom';

const P = styled.p`
  font-family: NotoSansCJKkr-Medium;
  font-size: ${props => props.size}rem;
  color: ${props => props.color};
`

const CancelBtn = props => {
  return (
    <Button
      onClick={props.onClick}
      color="#ea4653"
      size={props.size}
      style={props.style}
    >
      <P color="#ea4653">{props.text}</P>
    </Button>
  )
}

export default CancelBtn
