import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { mobile } from '@styles/media'
import { Flex, Caption2 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { AD_LEVEL } from '@consts/'

const fadeIn = keyframes`
  from { opacity: 0;}
  to { opacity: 1; }
`

const Modal = styled.div`
  animation-name: ${fadeIn};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;

  position: fixed;
  bottom: 88px;
  right: 88px;
  /* transform: translate(-50%, -50%); */
  z-index: 9061;
  background: white;
  border-radius: 16px;
  /* overflow: scroll; */
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    min-height: 170px;
    top: 40%;
  }

  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.24);
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 400px;
  height: 240px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const ConfirmBtn = styled.button`
  width: 100%;
  height: 40px;
  color: #ffffff;
  border: none;
  margin: 0 16px;
  cursor: pointer;

  border-radius: 8px;
  background-color: ${colors.primary};
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const AdHreumPlanPopup = observer(() => {
  const { isShowing, toggle } = usePopup()
  const { authStore } = useStore()
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      toggle()
    }, 1000)
    // eslint-disable-next-line
  }, [])

  return isShowing
    && authStore.currentUser
    && authStore.currentUser['adLevel'] < AD_LEVEL['STUDENT']
    ? ReactDOM.createPortal(
      <>
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <CloseBtn onClick={toggle} src={images.x_btn_img} />
            <MobdlBody>
              <img
                src={images.ad_hreum_plan_img}
                alt="ad_img"
                style={{ width: 400, height: 166 }}
              />
              <Flex justify="center">
                <ConfirmBtn
                  onClick={
                    () => {
                      history.push('/p/plan')
                      toggle()
                    }
                  }
                >
                  <Caption2>자세히 보기</Caption2>
                </ConfirmBtn>
              </Flex>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default AdHreumPlanPopup

const CloseBtn = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  width: 16px;
  height: 16px;
  object-fit: cover;
`
