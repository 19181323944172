import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import moment from 'moment'

import { UserModel, LiveRoomModel } from './models'
import { Store } from '.'
import { Network } from './networks'

class Autocomplete {
  willFetchLength = 0
  fetchedLength = 0

  @observable autocompleteUserList = []
  @observable autocompleteLiveRoomList = []
  @observable isFinishedUser = false
  @observable isFinishedLiveRoom = false

  @observable isLoadingAutocomplete = false

  keyword = ''
}

const IS_DEV = process.env.NODE_ENV === 'development'
export default class LiveRoomSearchStore {
  @persist('list') @observable searchHistory

  // 검색기록 클릭 시, 이벤트 처리하기 위한 변수 - jhlim 20191205
  preventResetInputTextOneTimes = false

  @observable searchedTextAutocomplete
  @observable searchedText

  @observable autocompleteObj: Object<Autocomplete> = {}
  // @observable _currentAutocomplete: Autocomplete | null = null
  get currentAutocomplete() {
    if(this.autocompleteObj && this.searchedText && this.autocompleteObj[this.searchedText]) {
      return this.autocompleteObj[this.searchedText]
    }
    return null
  }
  LIMIT_SEARCH = 20

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action init = () => {
    this.searchHistory = []

    this.inputText = ''
    this.searchedText = ''
    this.searchedTextAutocomplete = ''

    this.autocompleteObj = {}
    // this.currentAutocomplete = null
  }

  @action.bound
  async fetchAutocompleteUserList(search, startIndex = 0) {
    const searchUserList = await this._fetchSearchUserList({
      keyword: search,
      startIndex,
      limit: this.LIMIT_SEARCH,
    })
    if (!searchUserList) return

    return searchUserList
  }

  @action.bound
  async fetchAutocompleteLiveRoomList(search, startIndex = 0) {
    const searchLiveRoomList = await this._fetchSearchLiveRoomList({
      keyword: search,
      startIndex,
      limit: this.LIMIT_SEARCH,
    })

    if (!searchLiveRoomList) return

    return searchLiveRoomList
  }

  @action setSearchedText = text => {
    this.searchedText = text
  }

  @action.bound
  async fetchAutocomplete(text, startIndex = 0) {
    if (!text) return

    this.searchedText = text
    const startTime = new Date().getTime()
    let _currentAutocomplete = null
    try {
      /**
       * 캐싱 추가 - jhlim 20191204
       */
      if (!this.autocompleteObj[text]) {
        const autocomplete = new Autocomplete()
        autocomplete.keyword = text
        this.autocompleteObj[text] = autocomplete
      }
      _currentAutocomplete = this.autocompleteObj[text]
      if (startIndex === 0) {
        _currentAutocomplete.isLoadingAutocomplete = true
      }

      /**
       * fetch 속도를 고려해서 await 하기 전에 this.currentAutocomplete 에 할당
       * 각 키워드 마다 fetch 하는 속도가 일정하지 않기 때문에 미리 설정
       * fetch 후에 this.currentAutocomplete 에 할당하면 fetch 속도에 따라 먼저 호출된 함수에서 fetch 가 더 느리면 먼저 호출된 키워드가 활성화됨.
       * await 전에 해야할 작업. (await 응답 순서를 보장하지 않으므로)
       * this.currentAutocomplete['isLoadingAutocomplete'] 값으로 autocomplete 로딩중 유무도 판단
       * jhlim 20191211
       */
      // this.currentAutocomplete = _currentAutocomplete

      if (
        !(_currentAutocomplete.fetchedLength > startIndex)
        && !(_currentAutocomplete.willFetchLength > startIndex)
      ) {
        _currentAutocomplete.willFetchLength += this.LIMIT_SEARCH
        // this.autocompleteUserList = []

        const [
          _autocompleteUserList = [],
          _autocompleteLiveRoomList = [],
        ] = await Promise.all([
          // this.fetchAutocompleteUserList(text, startIndex),
          [],
          this.fetchAutocompleteLiveRoomList(text, startIndex),
        ])

        _currentAutocomplete.isFinishedUser =
          !_autocompleteUserList
          || !(_autocompleteUserList.length >= this.LIMIT_SEARCH)
        _currentAutocomplete.isFinishedLiveRoom =
          !_autocompleteLiveRoomList
          || !(_autocompleteLiveRoomList.length >= this.LIMIT_SEARCH)

        const autocompleteUserList = _autocompleteUserList.map(user => {
          user['User'] = new UserModel(this.store, user)
          return user
        })
        const autocompleteLiveRoomList = _autocompleteLiveRoomList.map(
          liveRoom => new LiveRoomModel(this.store, liveRoom),
        )

        // if (maxLength < autocompleteUserList.length) {
        //   maxLength = autocompleteUserList.length
        // }

        _currentAutocomplete.autocompleteUserList.splice(
          startIndex,
          0,
          ...autocompleteUserList,
        )

        _currentAutocomplete.autocompleteLiveRoomList.splice(
          startIndex,
          0,
          ...autocompleteLiveRoomList,
        )

        _currentAutocomplete.fetchedLength += this.LIMIT_SEARCH
      }
    }
    catch (error) {
      console.log('jhlim error', error)
    }

    if (_currentAutocomplete) {
      _currentAutocomplete.isLoadingAutocomplete = false
    }

    IS_DEV
      && console.log(
        `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
          + `[DEV][LOG][SearchStore][fetchAutocomplete] time=${new Date().getTime()
            - startTime}`,
        text,
        this.currentAutocomplete && this.currentAutocomplete.fetchedLength,
      )
  }

  async _fetchSearchUserList(where) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/salon-users',
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomSearchStore _fetchSearchUserList', error)
    }
  }

  async _fetchSearchLiveRoomList(where) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/salons',
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('LiveRoomSearchStore _fetchSearchLiveRoomList', error)
    }
  }

  async fetchSearchTopicList({
    search,
    startIndex = 0,
    limit = this.LIMIT_SEARCH,
  }) {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/searchs/topics',
        'get',
        {
          keyword: search,
          startIndex,
          limit,
        },
      )
      return data || []
    }
    catch (error) {
      console.log('SearchStore fetchSearchTopicList', error)
    }
  }

  @action.bound
  addHistory(searchText) {
    if (searchText !== '') {
      const index = this.searchHistory.findIndex(item => item === searchText)
      if (index > -1) {
        this.searchHistory.splice(index, 1)
        this.searchHistory.splice(0, 0, searchText)
      }
      else {
        this.searchHistory.splice(0, 0, searchText)
      }
    }
  }

  @action.bound
  deleteHistory(index = -1) {
    if (index < 0) return

    this.searchHistory.splice(index, 1)
  }
  @action.bound
  clearHistory() {
    this.searchHistory = []
  }
}
