import { observable } from 'mobx'

export default class RevenueNotificationModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  /**
   * 1. 검토를 요청헀습니다. - 유저 액션
   * 2. 검토가 완료되었습니다. - 관리자 액션
   * 3. 수익 창출 및 환전이 재개되었습니다. - 관리자 액션
   * 4. 수익 창출 및 환전이 잠시 중단되었습니다. 고객센터에 문의해 주세요. - 관리자 액션
   * 5. 30,000원 환전이 완료되었습니다. - 관리자 액션
   * 6. 10,000원 환전 신청을 했습니다. - 유저 액션
   * 7. 환전 수단이 변경되었습니다. - 관리자 액션
   * 8. 환전 수단을 변경 요청했습니다. - 유저 액션
   */
  @observable message
  @observable isReaded

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.message = props.message
      this.isReaded = props.isReaded
    }
  }
}
