import React from 'react'
import { TextWrapper, H1, SubTitle, H3, H6, AgainMsg } from '@components/atoms'
import { MoveBtn } from '@components/molecules'
import { getTime } from '@utils/time'
import { convertToPricingComma, sliceFromBack, capitalize } from '@utils/string'
import { PageLayout } from '..'

const PaymentCompleteTemplate = ({ user, isFirstPlan, myPlan }) => {
  const myPlanInfo = myPlan && myPlan.validation ? myPlan.validation : null
  const isValidate = myPlanInfo && myPlanInfo.isValidate

  return (
    <>
      {
        isValidate && (
          <PageLayout>
            <SubTitle>
              {user.name}
님
            </SubTitle>

            <TextWrapper>
              <H1 style={{ marginTop: '21px' }}>결제 완료!</H1>
            </TextWrapper>

            <H3 color="#ff9742" style={{ paddingTop: '40px' }}>
              {capitalize(myPlan.plan)}
            </H3>

            <H6 style={{ marginTop: '30px' }}>{user.email}</H6>

            {
              myPlanInfo.payMethod === 'Card' && (
                <H6 style={{ marginTop: '20px' }}>카드 **** **** **** 0526</H6>
              )
            }
            {
              myPlanInfo.payMethod === 'HPP' && (
                <H6 style={{ marginTop: '20px' }}>
              휴대폰
                  {myPlanInfo.HPP_Num}
                </H6>
              )
            }

            <H6>
              {convertToPricingComma(myPlanInfo.purchaseItem.price)}
원 /
              {' '}
              {sliceFromBack(myPlanInfo.purchaseItem.offerPeriod, 1)}
            개월
            </H6>

            <H6 style={{ marginTop: '20px' }}>
            다음 결제일 :
              {' '}
              {getTime(myPlanInfo.expirationDate, 'YYYY-MM-DD')}
            </H6>

            <AgainMsg style={{ marginTop: '20px' }} />

            <MoveBtn
              style={{ marginTop: '40px' }}
              href="/p/plan"
              text="내 구독 정보로 이동"
            />
          </PageLayout>
        )
      }
    </>
  )
}

export default PaymentCompleteTemplate
