import React from 'react'
import styled from 'styled-components'
import { mobileDesktop } from '@styles/media'
import { convertToPricingComma } from '@utils/string'
import { Flex } from '@components/atoms'
import { images } from '@images/index'

const OriginalPriceWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`

const OriginalPrice = styled.span`
  font-family: NotoSansCJKkr-Bold;
  font-size: 24px;
  color: #5b49f3;
`

const OriginalPricePeriod = styled.span`
  font-family: NotoSansCJKkr-Regular;
  font-size: 16px;
  color: #646464;
`

const Text = styled.span`
  width: 100%;
  text-align: ${props => (props.align ? props.align : 'center')};
  word-break: keep-all;
  line-height: 1.6;
  letter-spacing: normal;
  margin: 0;
  padding: 0;

  font-family: ${props =>
    props.type ? `NotoSansCJKkr-${props.type}` : 'NotoSansCJKkr-Bold'};
  color: ${props => (props.color ? props.color : '#242424')};
  font-size: 32px;

  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
`

const PricingCard = ({
  title,
  price,
  period,
  priceDescription,
  description,
  mainButton,
}) => {
  return (
    <Card>
      {
        title && (
          <Text type="Bold" align="left" size={20}>
            {title}
          </Text>
        )
      }
      {
        price && (
          <OriginalPriceWrap style={{ margin: '12px 0 18px 0' }}>
            <OriginalPrice>
              {convertToPricingComma(price)}
원
            </OriginalPrice>
            <OriginalPricePeriod>
              {' '}
/
              {period}
            </OriginalPricePeriod>
          </OriginalPriceWrap>
        )
      }
      {priceDescription && <Text type="Medium">{priceDescription}</Text>}
      <Flex
        type="column"
        style={{ gap: 16, justifyContent: 'flex-start', width: '100%' }}
      >
        {
description?.map(item => (
  <div key={item} style={{ textAlign: 'left', width: '100%' }}>
    <img
      src={images.check_purple_img}
      alt="check"
      width="12px"
      height="12px"
    />
    <Text
      type="Medium"
      size={14}
      style={{ marginLeft: '8px', whiteSpace: 'pre' }}
    >
      {item}
    </Text>
  </div>
))
        }
      </Flex>
      {
        mainButton && mainButton.text && (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ButtonWrapper onClick={mainButton.onClick}>
              {mainButton.text}
            </ButtonWrapper>
          </div>
        )
      }
    </Card>
  )
}

export default PricingCard

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 328px;
  height: 320px;

  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
`

const ButtonWrapper = styled.button`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-size: 14px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #594ee9;
  color: white;
  background-color: #594ee9;
`
