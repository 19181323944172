import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useStore } from './hooks'
import { modalsConfig, ModalsInterface } from './modals'

const Modals = () => {
  const { appStateStore } = useStore()

  const modalsState = appStateStore.modalsState || []

  // Modal open시, body scroll 막기
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <>
      {
        modalsState.map((modal, index) => {
          if (modal.status === 'on') {
            return (
              <ModalsComponents {...modal} hash={modal.hash || ''} key={index} />
            )
          }
          return null
        })
      }
    </>
  )
}

export default observer(Modals)

const ModalsComponents = (props: ModalsInterface & { hash: string }) => {
  return props?.type ? modalsConfig(props?.hash)[props?.type] || <></> : <></>
}
