import React from 'react'
import styled from 'styled-components'
import {
  Caption1,
  Image,
} from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import moment from 'moment'

const CastReservedImageTextConatiner = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px; */
  object-fit: contain;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* z-index: 2; */
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default ({castDetail}) => {
  return (
    castDetail?.isReservedYet
      ? (
        <CastReservedImageTextConatiner>
          <Image
            src={images.lock_img_white}
            alt="lock_img"
            width="24px"
            height="24px"
            style={{ margin: 4 }}
          />
          <Caption1 color={colors.white_ff}>{[`${moment(castDetail?.reservedPublishedAt).format('MM월 DD일 HH:mm')}`,<br />,`공개`]}</Caption1>
        </CastReservedImageTextConatiner>
      )
      : <></>
  )
}
