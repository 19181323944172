import React, { useState, useEffect } from 'react'
import FlexRowBox from '../box/FlexRowBox'
import { H6 } from '@components/atoms'
import styled from 'styled-components'

const Bar = styled(FlexRowBox)`
  width: 120px;
  height: 3px;
  background-color: #f4f4f4;
  border-radius: 10px;

  > div:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  > div:nth-child(2) {
    border-radius: 0px;
  }

  > div:nth-child(3) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`

const Step = styled.div`
  width: 40px;
  height: 3px;
  background-color: #66bc6a;
  border-radius: 2px;
`

const ThreeStepBar = ({ oneStep, twoStep, threeStep }) => {
  const [step, setStep] = useState(0)

  useEffect(() => {
    setStep(oneStep + twoStep + threeStep)
  }, [oneStep, twoStep, threeStep])

  return (
    <FlexRowBox>
      <H6 style={{ marginRight: '10px' }} color="#949494">
        {step}/3
      </H6>
      <Bar>
        {step === 1 ? (
          <Step />
        ) : step === 2 ? (
          <>
            <Step />
            <Step />
          </>
        ) : step === 3 ? (
          <>
            <Step />
            <Step />
            <Step />
          </>
        ) : (
          false
        )}
      </Bar>
    </FlexRowBox>
  )
}

export default ThreeStepBar
