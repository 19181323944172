import React, { useEffect, useState } from 'react'
import { PageLayout } from '@components/templates'
import { Caption1, Div, H6 } from '@components/atoms'
import {
  HouseDetailInfoSection,
  HouseDetailCastSection,
  HouseDetailFeedSection,
  HouseDetailHomeSection,
} from '@components/organisms'
import { CustomTab } from '@components/molecules'
import { colors } from '@resources/colors'

const HouseDetailTemplate = ({ isMyPage, houseDetail, initFeedId }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0)

  useEffect(() => {
    setSelectedTabIdx(0)
  }, [houseDetail])

  return (
    <PageLayout style={{ padding: '0 0 142px 0' }}>
      <Div
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
      >
        <HouseDetailInfoSection isMyPage={isMyPage} houseDetail={houseDetail} />

        <CustomTab
          selectedIndex={selectedTabIdx}
          onSelect={index => setSelectedTabIdx(index)}
          className="user_detail_tab"
          style={{ width: '100%', maxWidth: 1064 }}
          panelStyle={{ marginTop: 34 }}
          menu={['홈', '피드', '캐스트']}
          contents={
            [
              <HouseDetailHomeSection
                isMyPage={isMyPage}
                house={houseDetail}
                setSelectedTabIdx={setSelectedTabIdx}
                initFeedId={initFeedId}
              />,
              <>
                {
                  !isMyPage
              && houseDetail?.isPrivate
              && !houseDetail?.isFollowed ? (
                      <Div
                        flexDirection="column"
                        width="100%"
                        margin="20px 0"
                        textAlign="center"
                      >
                        <H6 color={colors.grey_64}>비공개 계정입니다.</H6>
                        <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
                    피드를 보고 싶으면 팔로우를 요청하세요
                        </Caption1>
                      </Div>
                    ) : (
                      <HouseDetailFeedSection
                        isMyPage={isMyPage}
                        initFeedId={initFeedId}
                        houseDetail={houseDetail}
                      />
                    )
                }
              </>,
              <HouseDetailCastSection isMyPage={isMyPage} houseDetail={houseDetail} />,
            ]
          }
        />
      </Div>
    </PageLayout>
  )
}

export default HouseDetailTemplate
