import React from 'react'
import { CastUploadForm } from '@components/organisms'
import { PageLayout } from '..'

const CastUploadTemplate = props => {
  const {
    user,
    fetchUserHouseList = () => {},
    createCast = () => {},
    setIsUploaded,
    uploadCastAudio,
    uploadCastImage,
    createAudioFile = () => {},
    getTag,
    isEdit = false,
    cast = null,
    updateCast = () => {},
    type,
    recording,
    basePlace,
    isPersonal,
  } = props

  return (
    <PageLayout>
      <CastUploadForm
        user={user}
        fetchUserHouseList={fetchUserHouseList}
        createCast={createCast}
        setIsUploaded={setIsUploaded}
        uploadCastAudio={uploadCastAudio}
        uploadCastImage={uploadCastImage}
        createAudioFile={createAudioFile}
        getTag={getTag}
        isEdit={isEdit}
        cast={cast}
        updateCast={updateCast}
        type={type}
        recording={recording}
        basePlace={basePlace}
        isPersonal={isPersonal}
      />
    </PageLayout>
  )
}

export default CastUploadTemplate
