import { action, observable } from 'mobx'
import React from 'react'
import { Modal } from 'antd'
import { persist } from 'mobx-persist'
import { USER_WARNING_STATUS } from '@consts/'
import { dateYMD } from '@utils/format'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'
import { Store } from '.'
import { Network } from './networks'
import { HouseModel, UserModel } from './models'

const { confirm } = Modal

export default class AuthStore {
  @observable currentUser
  @observable jsonWebToken
  @persist('list') @observable warningHistories
  @observable failAuth // auth 인증 실패 유무

  @observable myDetailJoinedHouseList

  @observable newKakaoUser

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.jsonWebToken = null

    this.newKakaoUser = { user: [], profile: '' }
    this.warningHistories = []
    this.failAuth = false
    this.myDetailJoinedHouseList = []
  }

  @action.bound
  async initClient() {
    if (window['sessionStorage'].getItem('jwt_token')) {
      this.jsonWebToken = window['sessionStorage'].getItem('jwt_token')
    }
    else {
      this.jsonWebToken = window['localStorage'].getItem('hreum_jwt_token')
    }
  }

  isLogined = () => {
    return !!this.currentUser
  }

  @action.bound
  async login(email, password, isAutoLogin: false) {
    // if (!email || !password) return
    return this.store.useLoading(async () => {
      let res = false

      if (email && password) {
        res = await this.network.authNetwork.postLogin({ email, password })
      }
      else if (this.jsonWebToken) {
        res = await this.network.authNetwork.postLogin({
          jsonWebToken: this.jsonWebToken,
        })
      }
      else {
        return
      }
      if (res && res.status) {
        return res
      }

      this.jsonWebToken = res['token']
      this.jsonWebToken
        && window['sessionStorage'].setItem('jwt_token', this.jsonWebToken)
      this.failAuth = false
      this.currentUser = new UserModel(this.store, res)

      this.fetchMyJoinedHouse()

      if (isAutoLogin) {
        window['localStorage'].setItem('hreum_jwt_token', this.jsonWebToken)
      }

      this.store.purchaseStore.getMyPlan()

      // 경고받은 유저 일 때, 경고 팝업을 띄울 경고 ID 저장
      if (this.currentUser['warningStatus'] < USER_WARNING_STATUS.NOT_WARNING) {
        const index = this.warningHistories.findIndex(
          item => item.id === this.currentUser['firstWarningHistoryId'],
        )

        if (index > -1) {
          if (this.warningHistories[index].isReaded) return
          this.warningHistories.splice(index, 1)
          this.warningHistories.splice(0, 0, {
            id: this.currentUser['firstWarningHistoryId'],
            isReaded: false,
          })
        }
        else {
          this.warningHistories.splice(0, 0, {
            id: this.currentUser['firstWarningHistoryId'],
            isReaded: false,
          })
        }
      }

      // if (res.status === 401) {
      //   this.authError = res.response
      // }
      return res
    })
  }

  @action.bound
  async kakaoLogin(kakao_account, kToken) {
    return this.store.useLoading(async () => {
      const {
        email,
        profile,
        phone_number,
        gender,
        birthyear,
        birthday,
      } = kakao_account
      const phone = `0${phone_number && phone_number.split(' ')[1]}`.replace(
        /-/g,
        '',
      )
      const birth = `${birthyear && birthyear}-${birthday
        && birthday.substr(0, 2)}-${birthday.substr(2, 2)}`
      const sex = gender === 'MALE' ? 'm' : 'f'

      const emailCheckList = await this.network.userNetwork.getUsers({
        email,
      })
      // 로그인 처리 로직 data 0이면 회원가입 처리
      if (emailCheckList.length !== 0) {
        /**
         * 이미 가입되어 있는 이메일이 있으면
         * 카카오인지, 아닌지 체크
         */
        if (emailCheckList[0].isKakaoAccount) {
          // 카카오로 가입된 이메일로 카카오 로그인 하려고 하니 그대로 로그인 처리하면 됨
          const res = await this.network.authNetwork.postLogin({
            email,
            kToken,
          })
          // 카카오 로그인 자동 로그인
          localStorage.setItem('hreum_jwt_token', res.token)

          sessionStorage.setItem('jwt_token', res.token)

          this.currentUser = new UserModel(this.store, res)
          this.jsonWebToken = res.token
          this.failAuth = false
          this.fetchMyJoinedHouse()
          return res
        }
        alert('이메일로 가입된 계정입니다.', '이메일로 로그인해주세요')
        return { status: false, value: 'login' }
      }
      // 회원 가입 처리
      const isPossibleRegister = await this.checkPhoneExist(phone)
      if (isPossibleRegister) {
        /**
         * tempUser에 추가할 항목
         * email
         * 전화번호
         * 생년월일
         * 성별
         * 카카오계정 true
         * 카카오톡 프로필 이미지
         */
        const newKakaoUser = [
          {
            isKakaoAccount: true,
            email,
            phone,
            birthday: birth,
            sex,
          },
        ]

        this.newKakaoUser.user = newKakaoUser
        this.newKakaoUser.profile = profile

        return 'KAKAO_REGISTER'

        // const newUser = await this.register(newKakaoUser)

        // sessionStorage.setItem('jwt_token', newUser[0].token)
        // localStorage.setItem('hrem_jwt_token', newUser[0].token)
        // this.jsonWebToken = newUser[0].token

        // if (newUser) {
        //   // 카카오 프로필 사진 자동 업로드
        //   const res = await this.network.userNetwork.uploadKakaoImage(
        //     profile.profile_image_url,
        //   )

        //   const updatedUser = await this.network.userNetwork.putUser(
        //     newUser[0].id,
        //     res,
        //   )
        //   if (updatedUser) {
        //     this.currentUser = new UserModel(this.store, updatedUser)
        //   }
        //   return res
        // }
      }
    })
  }

  /**
   * 카카오 회원 가입 함수
   * 약관 동의 페이지 거치기 위해서 kakaoLogin에서 가입 로직 분할
   * @param {*} isAgreeMarketing : 마케팅 동의 수신 여부
   */
  @action.bound
  kakaoRegister = async isAgreeMarketing => {
    return this.store.useLoading(async () => {
      /**
       * tempUser에 추가할 항목
       * email
       * 전화번호
       * 생년월일
       * 성별
       * 카카오계정 true
       * 카카오톡 프로필 이미지
       */
      const newKakaoUser = this.newKakaoUser.user
      const { profile } = this.newKakaoUser

      // 회원 탈퇴후 재가입 7일 유예 로직
      if (newKakaoUser && newKakaoUser[0]) {
        if (newKakaoUser[0]['phone']) {
          const isJoinable = await this.checkUserJoinable({
            phone: newKakaoUser[0]['phone'],
          })
          if (!isJoinable) return false
        }
        else if (newKakaoUser[0]['email']) {
          const isJoinable = await this.checkUserJoinable({
            email: newKakaoUser[0]['email'],
          })
          if (!isJoinable) return false
        }
      }

      const newUser = await this.register(newKakaoUser)

      sessionStorage.setItem('jwt_token', newUser[0].token)
      localStorage.setItem('hrem_jwt_token', newUser[0].token)
      this.jsonWebToken = newUser[0].token

      if (newUser) {
        // 카카오 프로필 사진 자동 업로드
        const res = await this.network.userNetwork.uploadKakaoImage(
          profile.profile_image_url,
        )

        const updatedUser = await this.network.userNetwork.putUser(
          newUser[0].id,
          { ...res, isAgreeMarketing }, // 마케팅 동의여부 추가
        )
        if (updatedUser) {
          this.currentUser = new UserModel(this.store, updatedUser)
        }
        return res
      }
    })
  }

  @action checkPhoneExist = async phone => {
    /**
     * [휴대폰 체크 로직]
     * 2020. 04. 16 - 31seul
     * 가입된 휴대폰이 없으면 가입 가능 return true
     * 가입 된 휴대폰이 있지만 3개 미만이면 추가가입 가능 (추가가입 원하면 return true)
     * 가입된 휴대폰이 있고, 3개 이상이면 추가가입 불가능 return false
     */

    // 여기 할차례!!!!!!!!!!!!!!!!!!!!!!!!!@!@!@!@!@!@!@

    try {
      const data = await this.network.userNetwork.getUsers({ phone })
      let phoneInfo = ''

      data.forEach(element => {
        let phoneBlind = ''
        let isBlind = true

        for (let i = 4; i < element.email.length; i += 1) {
          if (element.email[i] === '@') {
            isBlind = false
          }
          phoneBlind += isBlind ? `*` : element.email[i]
        }
        const subEmailInfo = element.email.substring(0, 4)
        phoneInfo += `\n${subEmailInfo}${phoneBlind}`
      })
      if (data.length > 0) {
        if (data.length < 3) {
          return new Promise(resolve => {
            confirm({
              title: '폰 번호로 가입된 계정이 있습니다\n추가가입 하시겠습니까?',
              content: (
                <pre style={{ margin: 0, fontFamily: 'NotoSansCJKkr-Medium' }}>
                  {phoneInfo}
                </pre>
              ),
              onOk() {
                resolve(true)
              },
              onCancel() {
                resolve(false)
              },
            })
          })
        }
        alert(
          '해당 번호로 3개의 계정이 있습니다(최대 3개)\n아래의 계정으로 로그인해주세요',
          <pre style={{ margin: 0, fontFamily: 'NotoSansCJKkr-Medium' }}>
            {phoneInfo}
          </pre>,
        )
        return false
      }

      return true
    }
    catch (error) {
      console.log('checkPhoneNotExist error: ', error)
    }
  }

  @action.bound
  async logout() {
    return this.store.useLoading(async () => {
      window['sessionStorage'].removeItem('jwt_token')
      window['sessionStorage'].clear()
      window['localStorage'].removeItem('hreum_jwt_token')
      this.jsonWebToken = null
      this.currentUser = null
      this.store.playerStore.init()
      this.store.songStore.init()
      this.store.mixtapeStore.init()
      this.init()
    })
  }

  @action.bound
  async register(newUser) {
    return this.store.useLoading(async () => {
      if (!newUser) return

      const res = await this.network.userNetwork.postUser(newUser)
      if (!res) return

      sessionStorage.setItem('jwt_token', res[0].token)
      localStorage.setItem('hreum_jwt_token', res[0].token)
      this.currentUser = res[0]
      this.jsonWebToken = res[0].token

      return res
    })
  }

  @action.bound
  async registerPassword(newPassword) {
    return this.store.useLoading(async () => {
      if (!newPassword) return

      const res = await this.network.userNetwork.putUser(this.currentUser.id, {
        password: newPassword,
      })
      if (!res) return

      this.currentUser = new UserModel(this.store, res)

      return res
    })
  }

  @action.bound
  async deleteUser() {
    return this.store.useLoading(async () => {
      if (!this.currentUser) return

      const res = await this.network.userNetwork.deleteUser({
        id: this.currentUser.id,
      })
      if (!res) return
      window.location.reload()

      return res
    })
  }

  @action.bound
  async sendSms(phone) {
    return this.store.useLoading(async () => {
      await this.network.authNetwork.postSms(phone).then(
        res => {
          return true
        },
        err => {
          return err.response
        },
      )
    })
  }

  @action.bound
  async smsCheck(phone, token) {
    return this.store.useLoading(async () => {
      return await this.network.authNetwork.postSmsCheck({
        phone,
        token,
      })
    })
  }

  @action.bound
  async getCheckPhone(phone) {
    if (phone === '') return false
    const result = await this.network.userNetwork.getJoinablePhone({
      phone,
    })
    return result.activeUserList || []
  }

  @action.bound
  async getCheckEmail(email) {
    if (email === '') return false
    return await await this.network.userNetwork.getUsers({
      email,
    })
  }

  @action.bound
  async getCheckName(name) {
    if (name === '') return false
    return await await this.network.userNetwork.getUsers({
      name,
    })
  }

  // 임시 비밀번호
  @action.bound
  async tempPassword(email) {
    await this.network.authNetwork.postTempPassword(email)
  }

  // 비밀번호 인증
  @action checkPassword = async password => {
    return this.store.useLoading(async () => {
      const res = await this.network.authNetwork.postCheckPassword(
        this.currentUser.email,
        password,
      )
      return res
    })
  }

  // 경고 팝업 다시 보지 않기
  @action.bound
  foreverClose = () => {
    this.warningHistories[0] = { ...this.warningHistories[0], isReaded: true }
  }

  @action.bound
  changePassword = async ({ email, phone, token, password }) => {
    return this.store.useLoading(async () => {
      const res = await this.network.authNetwork.putUsersSmsPwd({
        email,
        phone,
        token,
        password,
      })

      return !!res
    })
  }

  // 비회원이 회원 기능을 시도했을 때
  @action.bound
  tryAuth = () => {
    if (!this.currentUser) {
      this.failAuth = true
    }
    else {
      this.failAuth = false
    }

    return !this.failAuth
  }

  @action fetchUserData = async () => {
    try {
      if (this.isLogined()) {
        const options = {
          __addColumn: 'warningStatus',
          __include:
            'Nows.Mixtapes.Users,Nows.Mixtapes.MixtapesHaveTags.Tags,Nows.UsersReadNows',
          'Nows.UsersReadNows.userId': this.currentUser.id,
        }

        const { data } = await this.network._axiosApiAuth(
          `/users/${this.currentUser.id}`,
          'get',
          options,
        )
        this.currentUser = new UserModel(this.store, data)
      }
    }
    catch (err) {
      console.log('fetchUserData: ', err)
    }
  }

  @action checkUserJoinable = async userInfo => {
    const res = await this.network.userNetwork.getUsersJoinable(userInfo)

    if (res && !res.isJoinable && res.withdrawalAt) {
      alert(
        `${dateYMD(
          res.withdrawalAt,
        )}에 탈퇴한 이력이 남아있습니다.\n탈퇴일로부터 7일 뒤 재가입하실 수 있습니다.`,
      )
      return false
    }
    if (res && res.isJoinable) {
      return true
    }
  }

  @action fetchMyJoinedHouse = async () => {
    if (!this.currentUser?.id) return

    return this.store.useLoading(async () => {
      const response = await this.network.userNetwork.getUser(
        this.currentUser?.id,
        {
          __include: 'HousesHaveUsers.Houses',
          // 'HousesHaveUsers.Houses.isPrivateMemberList': 0,
          // 'HousesHaveUsers.level': 20,
          'HousesHaveUsers.level': 0,
          'HousesHaveUsers.levelOperator': 'gte',
          __addColumn: 'isHouseNewContents',
        },
      )

      if (!response || response.HousesHaveUsers.length === 0) return

      const houseList = response.HousesHaveUsers?.slice()
      let owner = []
      let host = []
      let member = []
      let payment = []
      let follower = []
      let request = []

      houseList.forEach(element => {
        switch (element.level) {
          //  유저가 오너 호스트로 있는 하우스 (중에서 업데이트 된 순으로 배치)
          case HOUSES_HAVE_USERS_LEVEL['OWNER']:
            owner.push(element)
            break
          //  유저가 호스트로 있는 하우스 (중에서 업데이트 된 순으로 배치)
          case HOUSES_HAVE_USERS_LEVEL['HOST']:
            host.push(element)
            break
          case HOUSES_HAVE_USERS_LEVEL['MEMBER']:
            //  유저가 유료 멤버로 있는 하우스 (중에서 업데이트 된 순으로 배치)
            if (element.House?.isPremiumMember) {
              payment.push(element)
            }
            else {
              //  유저가 멤버로 있는 하우스 (중에서 업데이트 된 순으로 배치)
              member.push(element)
            }
            break
          //  유저가 팔로워로 있는 하우스 (중에서 업데이트 된 순으로 배치)
          case HOUSES_HAVE_USERS_LEVEL['FOLLOWER']:
            follower.push(element)
            break
          // 요청중 하우스도 표시
          case HOUSES_HAVE_USERS_LEVEL['REQUEST']:
            request.push(element)
            break
          default:
            break
        }
      })

      owner = owner
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )

      host = host
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )

      member = member
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )

      payment = payment
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )

      follower = follower
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )
      request = request
        .sort(
          (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
        )
        .map(
          item =>
            new HouseModel(this.store, {
              ...item.House,
              _myLevel: item?.level,
            }),
        )

      this.myDetailJoinedHouseList = [
        ...owner,
        ...host,
        ...payment,
        ...member,
        ...follower,
        ...request,
      ]

      // this.myDetailJoinedHouseList = response.HousesHaveUsers?.sort(
      //   (a, b) => b?.House?.isHouseNewContents - a?.House?.isHouseNewContents,
      // ).map(
      //   item =>
      //     new HouseModel(this.store, { ...item.House, _myLevel: item?.level }),
      // )
    })
  }
}
