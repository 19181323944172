import styled from 'styled-components'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'

const SubmitBtn = styled.button`
  -webkit-appearance: none;
  border-radius: 24px;
  cursor: pointer;
  width: 320px;
  // margin : 40px 0px;
  padding: 12px 100px;
  font: inherit;
  border: ${props =>
    props.disabled ? `2px solid #f4f4f4` : `2px solid ${colors.primary}`};
  background: ${props => (props.disabled ? `#f4f4f4` : `${colors.primary}`)};
  color: ${props => (props.disabled ? `#949494` : `#ffffff`)};

  ${props =>
    !props.disabled
    && `:hover {
      border: 2px solid #0087ff;
      background: #0087ff;
    }`}

  @media ${mobile} {
    margin: 10px 0px;
    width: 100%;
  }
`

export default SubmitBtn
