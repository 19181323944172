import { observable } from 'mobx'
import CastModel from './CastModel'
import TagModel from './TagModel'

export default class CastsHaveTagsModel {
  @observable id
  @observable level

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable castId
  @observable tagId

  @observable Cast = null
  @observable Tag = null

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id
      this.level = props.level

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.castId = props.castId
      this.tagId = props.tagId

      this.Cast = (props.Cast && new CastModel(stores, props.Cast)) || null
      this.Tag = (props.Tag && new TagModel(stores, props.Tag)) || null
    }
  }
}
