import { Flex, H5, Caption1, Div, ImageIcon } from '@components/atoms'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import ReserveSalonCard from './card/ReserveSalonCard'
import SalonCard from './card/SalonCard'

const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  max-width: 1607px;
  width: 100%;
`

const SearchSalonSection = ({ active = false, handleShowMore }) => {
  const { searchStore } = useStore()

  const [startIndex, setStartIndex] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [list, setList] = useState([])

  const globalKeyword = searchStore.globalKeyword || null
  const fetchSearchSalonList = searchStore.fetchSearchSalonList || (() => {})

  const firstReserveLiveRoomIdx = list?.findIndex(
    liveRoom => liveRoom['isReserved'] && !liveRoom['isOpened'],
  )

  const handleSearchSalonList = useCallback(
    async (_globalKeyword, _startIndex, limit, _active) => {
      const _list = await fetchSearchSalonList(
        _globalKeyword,
        _startIndex,
        limit,
      )
      if (_list.length < limit || !_active) setHasMore(false)
      setList(prev => prev.concat(_list))
    },
    [fetchSearchSalonList],
  )

  useEffect(() => {
    if (!globalKeyword) return
    if (active) {
      handleSearchSalonList(globalKeyword, startIndex, 20, active)
    }
    else {
      handleSearchSalonList(globalKeyword, startIndex, 4, active)
    }
  }, [active, globalKeyword, startIndex])

  useEffect(() => {
    return () => {
      setList([])
      setHasMore(true)
      setStartIndex(0)
    }
  }, [active, globalKeyword])

  return (
    <Flex
      type="column"
      style={
        {
          marginTop: 20,
          maxWidth: 1704,
          width: '100%',
        }
      }
    >
      {
list?.length > 0 ? (
  <>
    <ListHeader>
      <H5 type="Bold" align="left">
              살롱 | 라이브 오디오
      </H5>

      <Caption1
        color={colors.secondary}
        style={{ cursor: 'pointer' }}
        onClick={handleShowMore}
      >
              더보기
      </Caption1>
    </ListHeader>

    <InfiniteScroll
      style={{ overflowX: 'hidden' }}
      dataLength={list.length}
      scrollThreshold={0.7}
      next={() => setStartIndex(prev => prev + 20)}
      hasMore={hasMore}
      scrollableTarget="scrollableDiv"
      hasChildren
      loader={
        <ReactLoading
          type="spin"
          color="black"
          style={
            {
              width: '50px',
              height: '50px',
              margin: '0 auto 50px auto',
            }
          }
        />
      }
    >
      <List>
        {
          list
                && list.map((room, index) => {
                  if (room['isReserved'] && !room['isOpened']) {
                    if (index === firstReserveLiveRoomIdx) {
                      // 첫번째 예약살롱일 때, 줄바꿈 div 삽입
                      return (
                        <>
                          <div style={{ width: '100%' }} />
                          <ReserveSalonCard
                            salon={room}
                            key={`reserve_${room.id}`}
                            // type="list"
                            type="reservationList"
                          />
                        </>
                      )
                    }
                    return (
                      <ReserveSalonCard
                        salon={room}
                        key={`reserve_${room.id}`}
                        // type="list"
                        type="reservationList"
                      />
                    )
                  }
                  return <SalonCard item={room} key={room.id} />
                })
        }
      </List>
    </InfiniteScroll>
  </>
) : active ? (
  <Div
    display="flex"
    width="100%"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    paddingTop="90px"
  >
    <Div display="flex" flexDirection="column" alignItems="center">
      <ImageIcon name="noContents" width="120px" height="120px" />
      <Div>
        <Caption1 type="Bold">"라이브"&nbsp;</Caption1>
        <Caption1 color={colors.grey_64} style={{ margin: '12px 0 8px' }}>
                검색 결과가 없어요
        </Caption1>
      </Div>
      <Caption1 color={colors.grey_bf}>검색어를 확인해보세요!</Caption1>
    </Div>
  </Div>
) : (
  <></>
)
      }
    </Flex>
  )
}

export default observer(SearchSalonSection)

const List = styled(Flex)`
  padding: 24px 0 48px 0;
  flex-wrap: wrap;
  width: calc(100% + 39px);

  gap: 24px;

  @media ${mobile} {
    justify-content: center;
  }
`

// users/recommedations 로직 개발되면?

// import { Flex, H5, Caption1 } from '@components/atoms'
// import { colors } from '@resources/colors'
// import { mobile } from '@styles/media'
// import { Link } from 'react-router-dom'
// import React, { useCallback, useEffect, useState } from 'react'
// import styled from 'styled-components'
// import { observer } from 'mobx-react'
// import { useStore } from '@utils/hooks'
// import InfiniteScroll from 'react-infinite-scroll-component'
// import ReactLoading from 'react-loading'
// import { SalonCard } from '..'
// import ReserveSalonCard from '../card/ReserveSalonCard'

// const ListHeader = styled(Flex)`
//   justify-content: space-between;
//   align-items: center;
//   max-width: 1607px;
//   width: 100%;
// `

// const SearchSalonSection = ({ active = false }) => {
//   const { userStore } = useStore()

//   const fetchHomeRecommendation =
//     userStore.fetchHomeRecommendation || (() => {})

//   const [list, setList] = useState([])
//   const [nextCursor, setNextCursor] = useState(null)

//   const firstReserveLiveRoomIdx = list?.findIndex(
//     liveRoom => liveRoom['isReserved'] && !liveRoom['isOpened'],
//   )

//   const handleFetchHomeRecommendation = useCallback(
//     async ({ __limit, init = false }) => {
//       const { contentList, __nextCursor } = await fetchHomeRecommendation({
//         __nextCursor: init ? null : nextCursor,
//         contentTableName: 'LiveRooms',
//         __limit,
//       })

//       if (init) {
//         setList(contentList)
//       }
//       else {
//         setList(prev => prev.concat(contentList))
//       }
//       setNextCursor(__nextCursor)
//     },
//     [fetchHomeRecommendation, setList, setNextCursor, nextCursor],
//   )

//   useEffect(() => {
//     if (active) {
//       handleFetchHomeRecommendation({ __limit: 20 })
//     }
//     else {
//       handleFetchHomeRecommendation({ __limit: 4, init: true })
//     }
//   }, [active])

//   return (
//     <Flex
//       type="column"
//       style={
//         {
//           marginTop: 20,
//           maxWidth: 1704,
//           width: '100%',
//         }
//       }
//     >
//       {
//         list && (
//           <>
//             <ListHeader>
//               <H5 type="Bold" align="left">
//               살롱 | 라이브 오디오
//               </H5>
//               <Link to="/p/salon/list">
//                 <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
//                 더보기
//                 </Caption1>
//               </Link>
//             </ListHeader>

//             <InfiniteScroll
//               style={{ overflowX: 'hidden' }}
//               dataLength={list.length}
//               scrollThreshold={0.7}
//               next={() => handleFetchHomeRecommendation({ __limit: 20 })}
//               hasMore={active && nextCursor}
//               scrollableTarget="scrollableDiv"
//               hasChildren
//               loader={
//                 <ReactLoading
//                   type="spin"
//                   color="black"
//                   style={
//                     {
//                       width: '50px',
//                       height: '50px',
//                       margin: '0 auto 50px auto',
//                     }
//                   }
//                 />
//               }
//             >
//               <List>
//                 {
//                   list
//                 && list.map((room, index) => {
//                   if (room['isReserved'] && !room['isOpened']) {
//                     if (index === firstReserveLiveRoomIdx) {
//                       // 첫번째 예약살롱일 때, 줄바꿈 div 삽입
//                       return (
//                         <>
//                           <div style={{ width: '100%' }} />
//                           <ReserveSalonCard
//                             salon={room}
//                             key={`reserve_${room.id}`}
//                             // type="list"
//                             type="reservationList"
//                           />
//                         </>
//                       )
//                     }
//                     return (
//                       <ReserveSalonCard
//                         salon={room}
//                         key={`reserve_${room.id}`}
//                         // type="list"
//                         type="reservationList"
//                       />
//                     )
//                   }
//                   return <SalonCard item={room} key={room.id} />
//                 })
//                 }
//               </List>
//             </InfiniteScroll>
//           </>
//         )
//       }
//     </Flex>
//   )
// }

// export default observer(SearchSalonSection)

// const List = styled(Flex)`
//   padding: 24px 0 48px 0;
//   flex-wrap: wrap;
//   width: calc(100% + 39px);

//   gap: 24px;

//   @media ${mobile} {
//     justify-content: center;
//   }
// `
