import React from 'react'
import styled from 'styled-components'
import { Flex, Caption2, ProfileImg, Div, Caption3 } from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@resources/'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { useHistory } from 'react-router-dom'
import { castDuration, converseUnitEng } from '@utils/format'
import { timeConvert } from '@utils/time'

const ItemBox = styled(Flex)`
  position: relative;
  flex-direction: column;
  max-width: 248px;
  width: 100%;
  height: 202px;
  background-color: ${colors.white_ff};
  overflow: hidden;
  cursor: pointer;
`

const ItemImage = styled.img`
  max-width: 248px;
  width: 100%;
  height: 140px;
  object-fit: cover;
`

const ItemInfo = styled(Flex)`
  width: 100%;
  height: 62px;
  padding-top: 8px;
`

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 4px;
`

/**
 * 플라 리스트 아이템
 */
const MixtapeListItem = ({ item, category, sort }) => {
  const history = useHistory()

  return (
    <ItemBox
      onClick={
        () =>
          history.push({
            pathname: `/plra/${item && item.linkKey}`,
            state: { category, sort },
          })
      }
    >
      <HreumLogo>PLRA</HreumLogo>
      <PlayTimeBox>{castDuration(item?.totalPlayTime || 0)}</PlayTimeBox>
      <ItemImage
        src={(item && item.imageUrl) || images.default_song_img}
        alt="default_img"
      />
      <ItemInfo>
        <UserImage
          src={item?.User.imageUri}
          profileColor={item?.User.profileColor}
          onClick={
            e => {
              e.stopPropagation()
              history.push(`/user/${item && item.User.account}`)
            }
          }
        />
        <Div display="flex" flex="1" flexDirection="column">
          <Caption2 style={{ ...oneEllipsisStyle, width: '100%' }} align="left">
            {item && item.name}
          </Caption2>
          <Caption3
            color={colors.grey_94}
            align="left"
            style={{ ...oneEllipsisStyle, width: '100%', margin: '2px 0' }}
          >
            {item && item.User.name}
          </Caption3>
          <Div display="flex" alignItems="center">
            <Caption3 color={colors.grey_94}>
              {`조회수 ${converseUnitEng(item?.playCount || 0)}회`}
            </Caption3>
            <InfoDot />
            <Caption3 color={colors.grey_94}>
              {`${timeConvert(item?.publishedAt)}`}
            </Caption3>
          </Div>
        </Div>
      </ItemInfo>
    </ItemBox>
  )
}

export default MixtapeListItem

const HreumLogo = styled(Div)`
  position: absolute;
  left: 8px;
  top: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 20px;
  border-radius: 2px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 10px;
  line-height: 18px;
`

const PlayTimeBox = styled(Div)`
  position: absolute;
  right: 8px;
  top: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 20px;
  border-radius: 2px;
  background: rgba(24, 24, 24, 0.56);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  color: ${colors.white_ff};
  font-size: 10px;
  line-height: 18px;
`

const InfoDot = () => {
  return (
    <div
      style={
        {
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '4px',
          paddingRight: '4px',
        }
      }
    >
      <div
        style={
          {
            width: '2px',
            height: '2px',
            backgroundColor: colors.brown_grey,
            borderRadius: '1px',
          }
        }
      />
    </div>
  )
}
