import React from 'react'
import { SearchMixtapeForm, WholeMixtapeList } from '@components/organisms'
import { Flex } from '@components/atoms'
import { SearchTagCard } from '@components/molecules'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { PageLayout } from '..'

const SearchTagCardList = styled(Flex)`
  margin: 32px 0 40px 0;
  width: 100%;

  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1704px;
  width: calc(100% + 39px);

  margin-left: -24px;

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`

const MixtapeListTemplate = ({
  handleSearchTag,
  myListenedMixtapeList,
  totalMixtapeList,
  myLikeMixtapeList,
  myFollowMixtapeList,
  representiveTagList,
}) => {
  return (
    <PageLayout style={{ paddingTop: '0' }}>
      <SearchMixtapeForm />
      <SearchTagCardList>
        {
          representiveTagList
          && representiveTagList.map(tag => (
            <SearchTagCard
              key={tag}
              tag={tag}
              onClick={_tag => handleSearchTag(_tag)}
            />
          ))
        }
      </SearchTagCardList>
      <WholeMixtapeList
        myListenedMixtapeList={myListenedMixtapeList}
        totalMixtapeList={totalMixtapeList}
        myLikeMixtapeList={myLikeMixtapeList}
        myFollowMixtapeList={myFollowMixtapeList}
      />
    </PageLayout>
  )
}

export default MixtapeListTemplate
