import { observable } from 'mobx'

export default class NoticeModel {
  @observable id
  @observable createdAt
  @observable updatedAt

  @observable title
  @observable content

  /**
   * popup
   */
  // @observable popupImageUri
  // @observable publishedAt
  // @observable expiredAt
  // @observable linkUrl

  // @computed get popupImageUrl() {
  //   return this.popupImageUri
  //     ? STORAGE_URL + encodeURIComponent(this.popupImageUri)
  //     : ''
  // }
  @observable linkKey

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      this.title = props.title
      this.content = props.content

      // this.popupImageUri = props.popupImageUri
      // this.publishedAt = props.publishedAt
      // this.expiredAt = props.expiredAt
      // this.linkUrl = props.linkUrl
      this.linkKey = props.linkKey
    }
  }
}
