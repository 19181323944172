import { observable } from 'mobx'
import { UserModel, MixtapeModel, SongModel } from '.'

export default class TagModel {
  @observable id
  @observable keyword
  @observable totalCount

  @observable isDisplayedHome
  @observable isDisplayedSearch
  @observable backgroundColor
  @observable tintColor

  @observable createdAt
  @observable updatedAt

  @observable CommentsHaveTags = []
  @observable Users = []
  isFinishedUsers = false
  @observable Mixtapes = []
  isFinishedMixtapes = false

  @observable Targets = { Songs: [] }
  @observable targetCount = 0
  @observable imageSource

  constructor(stores, props) {
    if (props) {
      this.id = props.id
      this.keyword = props.keyword
      this.totalCount = props.totalCount

      this.isDisplayedHome = props.isDisplayedHome
      this.isDisplayedSearch = props.isDisplayedSearch
      this.backgroundColor = props.backgroundColor
      this.tintColor = props.tintColor

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt

      // this.CommentsHaveTags = (props.CommentsHaveTags && props.CommentsHaveTags.map(elem => new CommentsHaveTagsModel(stores, elem))) || []
      this.Users =
        (props.Users &&
          props.Users.map((user) => new UserModel(stores, user))) ||
        []
      this.Mixtapes =
        (props.Mixtapes &&
          props.Mixtapes.map((mixtape) => new MixtapeModel(stores, mixtape))) ||
        []

      if (props.Targets) {
        Object.keys(props.Targets).map((key) => {
          const dataObject = props.Targets[key]
          switch (key) {
            case 'Songs':
              this.Targets['Songs'] = props.Targets['Songs']
                .map((song) => new SongModel(stores, song))
                .sort((a, b) => b['tagCount'] - a['tagCount'])
              break
            default:
              break
          }
          return dataObject
        })
        this.imageSource =
          (this.Targets['Songs'] &&
            this.Targets['Songs'].length > 0 &&
            this.Targets['Songs'][0]['imageSource']) ||
          undefined
      }
      this.targetCount = props.targetCount
    }
  }
}
