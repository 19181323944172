import styled from 'styled-components'

const Button = styled.button`
display : flex;
justify-content : center;
align-items : center;

-webkit-appearance:none;
border : none;
cursor : pointer;
width : fit-content;
padding : 0;
background : none;
font : inherit;
color : ${(props) => props.color};

// :hover {
//     border-bottom : 1px solid ${(props) => props.color}
// }
`

export default Button
