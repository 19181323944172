import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { images } from '@images/'

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Medium;
  font-size: 16px;
  color: #646464;
  cursor: pointer;
  text-decoraion: underline;

  img {
    width: 22px;
    height: 22px;
    margin: 8px;
  }

  @media ${mobile} {
    font-size: 12px;
  }
`

const AgreeLink = styled.a.attrs(props => ({
  href: props.href,
  target: props.target,
}))`
  color: #646464;
  text-decoration: underline;
`

const AgreeBox = props => {
  return (
    <Box>
      {
        props.checked ? (
          <img
            src={images.check_img}
            alt="check_img"
            onClick={() => props.onClick()}
          />
        ) : (
          <img
            src={images.unCheck_img}
            alt="check_img"
            onClick={() => props.onClick()}
          />
        )
      }
      <AgreeLink href={props.href} target="_blank">
        {props.text}
      </AgreeLink>
    </Box>
  )
}

export default AgreeBox
