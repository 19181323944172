import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { images } from '@images/'

const Img = styled.img`
  width: 204px;
  height: 60px;
  margin: 15px;

  @media ${mobile} {
    width: 176px;
    height: 52px;
  }
`

const AppleBtn = () => {
  return (
    <Link to="#">
      <Img src={images.apple_down_img} alt="down_img" />
    </Link>
  )
}

export default AppleBtn
