import { Caption2, Flex, ProfileImg } from '@components/atoms'
import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'
import { MentionInput } from '..'

const CastCommentInputBox = React.forwardRef((props, ref) => {
  const {
    submitComment,
    value,
    setValue,
    setTargetUser,
    onClick,
    handleSearchMention,
    _autoCompleteUserObj,
    style,
    currentUserImage,
    currentUserProfileColor,
    ...inputProps
  } = props

  const [keyword, setKeyword] = React.useState('')
  const [isTrigger, setIsTrigger] = React.useState(false)

  const fetchMentionList = async value => {
    if (value.lastIndexOf('@') > -1) {
      const triggerIdx = value.lastIndexOf('@')

      if (value[triggerIdx + 1] === '[') return

      setIsTrigger(true)
      const searchKeyword = value.substring(triggerIdx + 1, value.length)
      if (!_autoCompleteUserObj[value]) await handleSearchMention(searchKeyword)
      setKeyword(searchKeyword)
    }
    else {
      setIsTrigger(false)
      setKeyword('')
    }
  }

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setValue(event.target.value)
    fetchMentionList(event.target.value)
    // if (mentions?.length > 0) {
    //   setTargetUser(
    //     mentions.map(mention => ({
    //       id: mention.id,
    //       name: mention.display.substr(1),
    //     })),
    //   )
    // }
  }

  return (
    <InputBox style={style && style}>
      <Flex style={{ width: '100%' }} className="cast_comment">
        <WriterProfileImg
          src={currentUserImage}
          profileColor={currentUserProfileColor || null}
        />
        <MentionInput
          value={value}
          setValue={setValue}
          handleChange={handleChange}
          ref={ref}
          mentionList={_autoCompleteUserObj[keyword]}
          isTrigger={isTrigger}
          setIsTrigger={setIsTrigger}
          style={
            {
              width: '100%',
              height: 76,
              marginLeft: 8,
              border: '1px solid #d4d4d4',
              borderRadius: 4,
            }
          }
          {...inputProps}
        />
      </Flex>
      <Flex style={{ width: '100%', justifyContent: 'flex-end', marginTop: 8 }}>
        <SendBtn onClick={() => value && submitComment(value)} value={value}>
          <Caption2 color={value ? colors.white : colors.grey_bf}>
            댓글
          </Caption2>
        </SendBtn>
      </Flex>
    </InputBox>
  )
})

export default CastCommentInputBox

const InputBox = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 112px;

  justify-content: flex-start;
`

const SendBtn = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ value }) =>
    value ? colors.primary : colors.white_f4};
  cursor: pointer;
`

const WriterProfileImg = styled(ProfileImg)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
