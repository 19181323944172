export const CAST_UPLOAD_TYPE = {
  UPLOAD: 'UPLOAD',
  RECORDING: 'RECORDING',
}

export const RSS_SYNC_ERROR = {
  INVALIDATE_LINK: 1,
  DUPLICATED_RSS: 2,
  SERVER_ERROR: 99,
}

export const YOUTUBE_SYNC_ERROR = {
  INVALIDATE_LINK: 1,
  DUPLICATED_CHANNEL: 2,
  DUPLICATED_VIDEO: 3,
  SERVER_ERROR: 99,
}

export const YOUTUBE_UPLOAD_TYPE = {
  VIDEO: 'VIDEO',
  CHANNEL: 'CHANNEL',
}

/**
 * syncState
 * 0: no value
 * 1: user 가 요청 후 관리자 심사 대기
 * 2: user 가 요청 후 관리자가 작업 거절
 * 3: user 가 요청 후 관리자가 작업 승인 = 싱크중
 * 99: admin 이 직접 삽입
 */
export const RSS_SYNC_STATE = {
  NO: 0,
  REQUEST: 1,
  REJECTED: 2,
  ACCEPTED: 3,
  ADMIN: 99,
}

export const YOUTUBE_SYNC_STATE = {
  NOT_REQUEST: 0,
  REQUEST: 50,
  REJECTED: 100,
  ACCEPTED: 200, // 승인 후 작업 중
  SERVER_ERROR: 250, // 재신청 필요
  SUCCESS: 300, // 변환 완료
}

export const CASTS_HAVE_TAGS_LEVEL = {
  NO: 0,
  DESCRIPTION: 10,
  NORMAL: 20,
  TOPIC: 30,
  VISIBLE_SYSTEM: 40,
  INVISIBLE_SYSTEM: 50,
}

export const DEFAULT_TAG_LIST = [
  {
    keyword: '케이팝',
  },
  {
    keyword: '힙합',
  },
  {
    keyword: '클래식',
  },
  {
    keyword: '인디',
  },
  {
    keyword: '아이돌',
  },
  {
    keyword: '사회',
  },
  {
    keyword: '테크',
  },
  {
    keyword: '연애',
  },
  {
    keyword: '토크',
  },
  {
    keyword: '주식',
  },
  {
    keyword: '코인',
  },
  {
    keyword: '커리어',
  },
]

export const SUBJECT_TAG_LIST = [
  {
    keyword: '음악',
    check: false,
    icon: '🎶',
  },
  {
    keyword: '뉴스',
    check: false,
    icon: '🗞',
  },
  {
    keyword: '게임',
    check: false,
    icon: '🎮',
  },
  {
    keyword: '정치',
    check: false,
    icon: '⚖️',
  },
  {
    keyword: '운동',
    check: false,
    icon: '💪',
  },
  {
    keyword: '경제',
    check: false,
    icon: '📈',
  },
  {
    keyword: '언어',
    check: false,
    icon: '🗣',
  },
  {
    keyword: '신앙',
    check: false,
    icon: '🙏',
  },
  {
    keyword: '시사',
    check: false,
    icon: '🔍',
  },
  {
    keyword: '마음',
    check: false,
    icon: '🍀',
  },
  {
    keyword: '사랑',
    check: false,
    icon: '💘',
  },
  {
    keyword: '사회',
    check: false,
    icon: '👥',
  },
  {
    keyword: '과학',
    check: false,
    icon: '🧪',
  },
  {
    keyword: '책',
    check: false,
    icon: '📖',
  },
  {
    keyword: '커리어',
    check: false,
    icon: '🧑‍💻',
  },
  {
    keyword: '네트워킹',
    check: false,
    icon: '🌐',
  },

  {
    keyword: '문화예술',
    check: false,
    icon: '🎨',
  },
  {
    keyword: '스타트업',
    check: false,
    icon: '🚀',
  },
  {
    keyword: 'ASMR',
    check: false,
    icon: '👂',
  },

  {
    keyword: '스포츠',
    check: false,
    icon: '🏆',
  },
]

export const NOT_ALLOWED_TAG_LIST = [
  // topic
  {
    keyword: '음악',
  },
  {
    keyword: '뉴스',
  },
  {
    keyword: '게임',
  },
  {
    keyword: '정치',
  },
  {
    keyword: '운동',
  },
  {
    keyword: '경제',
  },
  {
    keyword: '언어',
  },
  {
    keyword: '신앙',
  },
  {
    keyword: '시사',
  },
  {
    keyword: '마음',
  },
  {
    keyword: '사랑',
  },
  {
    keyword: '사회',
  },
  {
    keyword: '과학',
  },
  {
    keyword: '책',
  },
  {
    keyword: '커리어',
  },
  {
    keyword: '네트워킹',
  },
  {
    keyword: '문화예술',
  },
  {
    keyword: '스타트업',
  },
  {
    keyword: 'ASMR',
  },
  {
    keyword: '스포츠',
  },

  // subject
  {
    keyword: '오리지널',
  },
  {
    keyword: '유튜브',
  },
]
