import { Network } from '.'

export default class HouseNetwork {
  constructor(network: Network) {
    this.network = network
  }

  /**
   * 하우스 리스트 조회
   * @param {object} options
   * @returns hoseList
   */
  getHouseList = async options => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/houses',
        'get',
        options,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork getHouseList error: ', error)
    }
  }

  getHouseCount = async options => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/houses/counts',
        'get',
        options,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork getHouseList error: ', error)
    }
  }

  /**
   * 특정 하우스 조회
   * @param {number} houseId
   * @param {object} options
   * @returns house
   */
  getHouse = async (houseId, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork getHouse error: ', error)
    }
  }
  getHouseFromLinkKey = async (linkKey, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/_linkKey/${linkKey}`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork getHouseFromLinkKey error: ', error)
    }
  }

  /**
   * 하우스 멤버 리스트 조회
   * @param {number} houseId
   * @param {object} options
   * @returns
   */
  getHouseUsersList = async (houseId, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users`,
        'get',
        options,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork getHouseUsersList error: ', error)
    }
  }

  /**
   * 하우스 이름 중복검사
   * @param {object} options {title : "example"}
   * @returns {boolean} true(중복) false(사용가능)
   */
  getHouseDuplication = async options => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/duplication`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork getHouseDuplication error: ', error)
    }
  }

  /**
 * 하우스생성
 * @param {array} options
 * [
    {
        "title": "adfasf", // 하우스명
        "imageUri":"testset", // 하우스 이미지 경로
        "mainTagKeywordList":["asdfsaf"], // 주제(메인) 태그
        "subTagKeywordList":["123123"], // 태그(서브) 태그
        "description":"tssts", // 설명
        "isPrivateMemberList":0, // 멤버리스트 공개 여부
        "isPrivateMemberRoom":1 // 멤버룸 공개 여부
    }
]
 * @returns {array}
 */
  postHouse = async options => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses`,
        'post',
        options,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork postHouse error: ', error)
    }
  }

  /**
   * 하우스 수정
   * @param {object} options
   *
   *   {
   *       "title": "adfasf", // 하우스명
   *       "imageUri":"testset", // 하우스 이미지 경로
   *       "mainTagKeywordList":["asdfsaf"], // 주제(메인) 태그
   *       "subTagKeywordList":["123123"], // 태그(서브) 태그
   *       "description":"tssts", // 설명
   *       "isPrivateMemberList":0, // 멤버리스트 공개 여부
   *       "isPrivateMemberRoom":1 // 멤버룸 공개 여부
   *   }
   *
   * @returns {array}
   */
  putHouse = async (houseId, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}`,
        'put',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork putHouse error: ', error)
    }
  }

  /**
   * 하우스 삭제
   * @param {*} houseId
   * @returns
   */
  deleteHouse = async houseId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}`,
        'delete',
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork deleteHouse error: ', error)
    }
  }

  /**
   * 하우스 접속 기록 생성
   * @param {number} houseId
   * @returns
   */
  postUserHouseConnect = async houseId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users-connect-houses`,
        'post',
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork postUserHouseConnect error: ', error)
    }
  }

  /**
   * 하우스 멤버 추가
   * @param {number} houseId
   * @param {number} userId
   * @returns
   */
  postHouseUser = async (houseId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users/${userId}`,
        'post',
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork postHouseUser error: ', error)
      return error?.response
    }
  }
  /**
   * 하우스 멤버 초대
   * @param {number} houseId
   * @returns
   */
  postHouseUsersInvite = async (houseId, options) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/invitations`,
        'post',
        options,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork postHouseUsersInvite error: ', error)
    }
  }

  /**
   * 하우스 멤버 수정
   * @param {number} houseId
   * @param {number} userId
   * @param {object} where
   * @returns
   */
  putHouseUser = async (houseId, userId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users/${userId}`,
        'put',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork putHouseUser error: ', error)
      throw error
    }
  }
  /**
   * 하우스 멤버 일괄 수정
   * @param {number} houseId
   * @param {object} where
   * @returns
   */
  putHouseUserList = async (houseId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users`,
        'put',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork putHouseUserList error: ', error)
    }
  }
  /**
   * 하우스 멤버 삭제
   * @param {number} houseId
   * @param {number} userId
   * @returns
   */
  deleteHouseUser = async (houseId, userId) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/${houseId}/users/${userId}`,
        'delete',
      )
      return data || []
    }
    catch (error) {
      console.log('HouseNetwork deleteHouseUser error: ', error)
    }
  }

  getHousesRecommendations = async options => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/houses/recommendations`,
        'get',
        options,
      )
      return data || null
    }
    catch (error) {
      console.log('HouseNetwork getHousesRecommendations error: ', error)
    }
  }
}
