import { RSS_PUBLISH_URL } from '@consts/index'
import { axiosDefault, upload } from '@utils/axios'
import { Network } from '.'

export default class CastNetwork {
  constructor(network: Network) {
    this.network = network
  }

  postAudioFile = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/audio-files`,
        'post',
        where,
      )
      return data || []
    }
    catch (error) {
      console.error('[CastNetwork][postAudioFile] error', error)
    }
  }

  getCast = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.error('[CastNetwork][getCast] error', error)
    }
  }
  getCastFromLinkKey = async (linkKey, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/_linkKey/${linkKey}`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.error('[CastNetwork][getCastFromLinkKey] error', error)
    }
  }

  getCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(`/casts`, 'get', where)
      return data || []
    }
    catch (error) {
      console.error('c error', error)
    }
  }

  getCastListCount = async params => {
    try {
      const { data } = await this.network._axiosApi(
        '/casts/counts',
        'get',
        params,
      )
      return (data && data.totalCount) || []
    }
    catch (error) {
      console.log('[CastNetwork][getCastListCount] error: ', error)
    }
  }

  getLatestCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/recent`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('[CastNetwork][getLatestCastList] error: ', error)
    }
  }

  getPopularCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/popularities`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('[CastNetwork][getPopularCastList] error: ', error)
    }
  }

  getFollowCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/follows`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('[CastNetwork][getFollowCastList] error: ', error)
    }
  }

  getHistoryCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/histories`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('[CastNetwork][getHistoryCastList] error: ', error)
    }
  }

  getRecommendationCastList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/recommendations`,
        'get',
        where,
      )

      return data || {}
    }
    catch (error) {
      console.log('[CastNetwork][getRecommendationCastList] error: ', error)
    }
  }

  postCast = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(`/casts`, 'post', where)
      return data || null
    }
    catch (error) {
      console.log('[CastNetwork][postCast] error: ', error)
    }
  }

  putCast = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}`,
        'put',
        where,
      )
      return data || null
    }
    catch (error) {
      console.log('[CastNetwork][putCast] error: ', error)
    }
  }

  deleteCast = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}`,
        'delete',
      )
      return data || null
    }
    catch (error) {
      console.log('[CastNetwork][deleteCast] error: ', error)
    }
  }

  uploadCastAudio = async formData => {
    try {
      const { data } = await upload(
        '/uploads/audio-files',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('[CastNetwork][uploadCastAudio] error: ', error)
    }
  }
  getAudioFilePresignedUrl = async query => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/uploads/audio-files/presigned-url`,
        'get',
        query,
      )

      return data || null
    }
    catch (error) {
      console.log('[CastNetwork][getAudioFilePresignedUrl] error: ', error)
    }
  }
  putAudioFilePresignedUrl = async (url, body, options) => {
    try {
      const config = {
        ...(options || {}),
        headers: {
          ...(options?.['headers'] || {}),
        },
      }

      const res = await axiosDefault(
        url,
        'put',
        body,
        config,
      )

      return res
    }
    catch (error) {
      console.log('[CastNetwork][putAudioFilePresignedUrl] error: ', error)
    }
  }
  postAudioFilePresignedUrl = async (body) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/uploads/songs/presigned-url/complete',
        'post',
        body,
      )

      return data || null
    }
    catch (error) {
      console.log('[CastNetwork][postAudioFilePresignedUrl] error: ', error)
    }
  }

  uploadCastImage = async formData => {
    try {
      const { data } = await upload(
        '/uploads/audio-files/image',
        sessionStorage.getItem('jwt_token'),
        formData,
      )
      return data || null
    }
    catch (error) {
      console.error('[CastNetwork][uploadCastImage] error: ', error)
    }
  }

  postYoutubeSyncJobs = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/youtube-sync-jobs`,
        'post',
        where,
      )
      return { data: data || null, isSuccess: true }
    }
    catch (error) {
      console.log('[CastNetwork][postYoutubeSyncJobs] error: ', error)
      return { error: error?.response?.data?.error, isSuccess: false }
    }
  }

  postRssSyncInfo = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/rss-infos`,
        'post',
        where,
      )
      return { data: data || null, isSuccess: true }
    }
    catch (error) {
      console.log('[CastNetwork][postRssSyncInfo] error: ', error)
      return { error: error?.response?.data?.error, isSuccess: false }
    }
  }

  postRssPublishUrl = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/rss`,
        'post',
        where,
        {},
        RSS_PUBLISH_URL,
      )
      return { data: data || null, isSuccess: true }
    }
    catch (error) {
      console.log('[CastNetwork][postRssPublishUrl] error: ', error)
      return { error: error?.response?.data?.error, isSuccess: false }
    }
  }

  postCastHistory = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}/users-play-casts`,
        'post',
        where,
      )
      return data || null
    }
    catch (error) {
      console.log('CastNetwork postCastHistory error: ', error)
    }
  }

  deleteCastHistory = async (id, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}/users-play-casts`,
        'delete',
        where,
      )
      return data || null
    }
    catch (error) {
      console.log('CastNetwork deleteCastHistory error: ', error)
    }
  }

  putCastHistory = async (id, usersPlayCastId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}/users-play-casts/${usersPlayCastId}`,
        'put',
        where,
      )
      return data || null
    }
    catch (error) {
      console.log('CastNetwork putCastHistory error: ', error)
    }
  }

  postLikeCast = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}/likes`,
        'post',
      )
      return data || null
    }
    catch (error) {
      console.log('CastNetwork postLikeCast error: ', error)
    }
  }

  deleteLikeCast = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/casts/${id}/likes`,
        'delete',
      )
      return data || null
    }
    catch (error) {
      console.log('CastNetwork deleteLikeCast error: ', error)
    }
  }
}
