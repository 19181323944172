import React, { useState } from 'react'
import styled from 'styled-components'
import {
  BodyWrapper,
  H1,
  H6,
  FormInput,
  WarningMsg,
  Form,
  FormLabel,
  LogoImg,
  TextWrapper,
  Flex,
  InvisibleInput,
} from '@components/atoms'
import Button from '@components/atoms/button'
import { CheckTextBox, FlexRowBox, SubmitBtn } from '@components/molecules'

import { mobile } from '@styles/media'
import { useForm, Controller } from 'react-hook-form'
import { colors } from '@resources/colors'
import MbtiModal from '../modal/MbtiModal'

const HeaderBox = styled.div`
  width: 522px;
  margin: 20px 0px 50px 0px;
  @media ${mobile} {
    width: 100%;
    margin: 20px 0px 30px 0px;
  }
`

const FormGroup = styled.div`
  margin: 10px 0px;
  width: 100%;
`

const InputBox = styled.div`
  width: 100%;
  margin: 10px 0px;
  @media ${mobile} {
    margin: 8px 0px;
  }
`

const ExistPhoneList = styled.pre`
  margin: 0;
  font-family: NotoSansCJKkr-Medium;
`

const SexBox = styled(Flex)`
  width: 50%;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 2%;
  @media ${mobile} {
    padding-bottom: 4%;
  }
  > div:first-child {
    margin-right: 5px;
  }
`

const RegisterForm = ({
  getCheckPhone,
  getCheckEmail,
  time,
  timer,
  code,
  setCode,
  smsCheck,
  signUp,
  setComplete,
  termsAgree,
  privateAgree,
  isAgreeMarketing,
  checkUserJoinable,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    clearError,
    setError,
    control,
    setValue,
    watch,
  } = useForm({ mode: 'onBlur' })

  const wathcedMbti = watch('mbti')

  const { isShowing: showMbti, toggle: toggleMbti } = MbtiModal.useModal()

  const [checkConfirm, setCheckConfirm] = useState(false) // 비밀번호 확인 체크
  const [confirmEmail, setConfirmEmail] = useState(false) // 이메일 중복 체크
  const [confirmPhone, setConfirmPhone] = useState('') // 휴대폰 번호 중복 체크
  const [sendConfirm, setSendConfirm] = useState(false) // 인증번호 전송완료 여부
  const [correct, setCorrect] = useState(false) // 인증번호 일치 여부
  const [phoneList, setPhoneList] = useState(false) // 개인정보 수집이용 동의 여부
  // 비밀번호 확인
  const confirmPw = value => {
    if (value === getValues().password) {
      setCheckConfirm(false)
    }
    else {
      setCheckConfirm(true)
    }
  }

  // 휴대폰번호 중복 확인
  const verifyPhone = async () => {
    const check = await getCheckPhone(getValues().phone)
    let phoneInfo = ''
    check
      && check.forEach(element => {
        let phoneBlind = ''
        let isBlind = true
        if(element.email) {
          for (let i = 4; i < element.email.length; i += 1) {
            if (element.email[i] === '@') {
              isBlind = false
            }
            phoneBlind += isBlind ? `*` : element.email[i]
          }
          const subEmailInfo = element.email.substring(0, 4)
          phoneInfo += `\n${subEmailInfo}${phoneBlind}`
          setPhoneList(phoneInfo)
        }
      })

    if (check && check.length === 0) {
      setConfirmPhone('Y')
    }
    else if (check && check.length < 3) {
      setConfirmPhone('Y')
    }
    else if (check && check.length >= 3) {
      setConfirmPhone('N')
    }
  }

  // 이메일 중복 체크
  const _verifyEmail = async () => {
    const check = await getCheckEmail(getValues().email)
    if (check && check.length === 0) {
      setConfirmEmail('Y')
    }
    else if (check && check.length > 0) {
      setConfirmEmail('N')
    }
  }

  // 성별 선택
  const handleSex = sex => {
    setValue('sex', sex)
    clearError('sex')
  }

  const handleSendSms = async (e, phone) => {
    // 회원 탈퇴후 재가입 7일 유예 로직
    const isJoinable = await checkUserJoinable({ phone })
    if (!isJoinable) return

    time(e, phone)
    setSendConfirm(true)
  }

  // 회원가입
  const onSubmit = async data => {
    const params = [
      {
        email: data.email,
        password: data.password,
        phone: data.phone,
        birthday: data.birthday,
        sex: data.sex,
        organization: data.organization || null,
        duty: data.duty || null,
        isAgreeMarketing,
        mbti: data.mbti || null,
      },
    ]

    // 가입 params 입력 체크
    if (errors.email) {
      return false
    }
    if (errors.password || checkConfirm) {
      return false
    }
    if (errors.birthday) {
      return false
    }
    if (errors.sex) {
      return false
    }
    if (confirmPhone !== 'Y') {
      return false
    }
    if (correct !== 'Y') {
      setError([{ type: 'required', name: 'phone' }])
      return false
    }
    if (!termsAgree) {
      return false
    }
    if (!privateAgree) {
      return false
    }

    await signUp(params)
    await setComplete(true)
  }

  // 핸드폰 인증
  const _smsCheck = async e => {
    e.preventDefault()

    const result = await smsCheck(getValues().phone, code)
    if (result && result.status === 401) {
      setCorrect('N')
    }
    else {
      setCorrect('Y')
    }
  }

  return (
    <BodyWrapper>
      <LogoImg />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <H1>이메일로 시작하기</H1>
        <HeaderBox>
          <H6 color="#646464">가입한 회원 정보로 흐름(HREUM)의&nbsp;</H6>
          <H6 color="#646464">앱과 웹 서비스 모두를 이용할 수 있습니다.</H6>
        </HeaderBox>
        <InputBox>
          {/* 이메일 입력 박스 */}
          <FormInput
            name="email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
            placeholder="이메일 주소 입력"
            onBlur={() => _verifyEmail()}
            style={errors.email ? { border: '1px solid #ea4653' } : null}
          />
          {
            errors.email && errors.email.type === 'required' && (
              <WarningMsg>이메일을 입력해주세요.</WarningMsg>
            )
          }
          {
            errors.email && errors.email.type === 'pattern' && (
              <WarningMsg>이메일 형식이 올바르지 않습니다.</WarningMsg>
            )
          }
          {
            confirmEmail === 'N' && (
              <WarningMsg>이미 사용중인 이메일입니다.</WarningMsg>
            )
          }
        </InputBox>
        <InputBox>
          {/* 비밀번호 입력 박스 */}
          <FormInput
            name="password"
            ref={
              register({
                required: true,
                minLength: 8,
                pattern: /^(?=.*[a-z])(?=.*[0-9])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
              })
            }
            placeholder="비밀번호 (8자 이상)"
            type="password"
            style={errors.password ? { border: '1px solid #ea4653' } : null}
          />
          {
            errors.password && errors.password.type === 'required' && (
              <WarningMsg>비밀번호를 입력해주세요.</WarningMsg>
            )
          }
          {
            errors.password && errors.password.type === 'minLength' && (
              <WarningMsg>비밀번호 8자 이상 입력해주세요.</WarningMsg>
            )
          }
          {
            errors.password && errors.password.type === 'pattern' && (
              <WarningMsg>영문 숫자 조합으로 입력해주세요.</WarningMsg>
            )
          }
        </InputBox>
        <InputBox>
          {/* 비밀번호 확인 */}
          <FormInput
            placeholder="비밀번호 확인"
            type="password"
            onBlur={e => confirmPw(e.target.value)}
            style={checkConfirm ? { border: '1px solid #ea4653' } : null}
          />
          {
            checkConfirm && (
              <WarningMsg>비밀번호가 일치하지 않습니다.</WarningMsg>
            )
          }
        </InputBox>
        <InputBox>
          {/* 생년월일/성별 */}
          <FormGroup style={{ display: 'flex', padding: 0, margin: 0 }}>
            <div style={{ paddingRight: '10px' }}>
              <FormLabel color="#646464">생년월일/성별</FormLabel>
              <FormInput
                name="birthday"
                ref={register({ required: true, minLength: 8 })}
                placeholder="8자리 생년월일"
                style={errors.birthday ? { border: '1px solid #ea4653' } : null}
              />
            </div>
            <SexBox>
              <Controller
                as={
                  <CheckTextBox
                    text="남성"
                    onClick={
                      () => {
                        handleSex('m')
                      }
                    }
                    checked={getValues().sex === 'm'}
                  />
                }
                name="sex"
                control={control}
                rules={{ required: true }}
              />
              <Controller
                as={
                  <CheckTextBox
                    text="여성"
                    onClick={
                      () => {
                        handleSex('f')
                      }
                    }
                    checked={getValues().sex === 'f'}
                  />
                }
                name="sex"
                control={control}
                rules={{ required: true }}
              />
            </SexBox>
          </FormGroup>
          {
            errors.birthday && errors.birthday.type === 'required' ? (
              <WarningMsg>생년월일을 입력해주세요.</WarningMsg>
            ) : errors.sex && errors.sex.type === 'required' ? (
              <WarningMsg>성별을 선택해주세요.</WarningMsg>
            ) : (
              false
            )
          }
          {
            errors.birthday && errors.birthday.type === 'minLength' && (
              <WarningMsg>생년월일을 8자리로 입력해주세요.</WarningMsg>
            )
          }
        </InputBox>
        {/* 휴대폰 번호 */}
        <FormGroup>
          <InputBox style={{ display: 'inherit' }}>
            <FormLabel color="#646464">휴대폰 번호 인증</FormLabel>
            <FlexRowBox>
              <FormInput
                name="phone"
                ref={
                  register({
                    required: true,
                    pattern: /^(?:(010-?\d{4})|(01[1|6|7|8|9]-?\d{3,4}))-?\d{4}$/,
                  })
                }
                placeholder="ex. 01005260221 ( - 없이 )"
                disabled={sendConfirm}
                style={
                  confirmPhone === 'N'
                  || (errors.phone && {
                    border: '1px solid #ea4653',
                  })
                }
                onChange={() => verifyPhone()}
              />
              {
                !errors.phone && confirmPhone === 'Y' ? (
                  sendConfirm ? (
                    <Button
                      disabled
                      style={
                        {
                          color: '#66bc6a',
                          padding: '0px 20px 0px 40px',
                          whiteSpace: 'pre',
                        }
                      }
                    >
                    전송완료
                    </Button>
                  ) : (
                    <Button
                      onClick={
                        e => {
                          handleSendSms(e, getValues().phone)
                        }
                      }
                      style={
                        {
                          color: colors.secondary,
                          padding: '0px 19px',
                          whiteSpace: 'pre',
                        }
                      }
                    >
                    인증번호 전송
                    </Button>
                  )
                ) : (
                  <Button
                    style={
                      {
                        color: '#949494',
                        padding: '0px 19px',
                        whiteSpace: 'pre',
                      }
                    }
                    disabled
                  >
                  인증번호 전송
                  </Button>
                )
              }
            </FlexRowBox>
            {
              errors.phone && errors.phone.type === 'pattern' && (
                <WarningMsg>올바르지 않은 휴대폰 번호입니다.</WarningMsg>
              )
            }
            {
              !errors.phone && phoneList.length > 0 && (
                <>
                  <WarningMsg>해당 번호로 가입된 계정이 있습니다.</WarningMsg>
                  {phoneList && <ExistPhoneList>{phoneList}</ExistPhoneList>}
                </>
              )
            }
            {/* {!errors.phone && confirmPhone === 'N' && (
              <WarningMsg>이미 인증된 번호입니다.</WarningMsg>
            )} */}
            {
              errors.phone && errors.phone.type === 'required' && (
                <WarningMsg>휴대폰 인증을 해주세요.</WarningMsg>
              )
            }
          </InputBox>

          {/* 인증번호 입력 박스 */}
          {
            sendConfirm ? (
              <>
                <InputBox>
                  <FlexRowBox>
                    <FlexRowBox
                      style={
                        {
                          position: 'relative',
                          width: '100%',
                        }
                      }
                    >
                      <FormInput
                        style={{ width: '100%', height: 'fit-content' }}
                        placeholder="인증번호 6자리 입력"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        disabled={correct === 'Y'}
                      />
                      {
                        correct !== 'Y' && (
                          <span
                            style={
                              {
                                color: '#646464',
                                position: 'absolute',
                                right: '5%',
                                zIndex: '10',
                                paddingTop: '4px',
                              }
                            }
                          >
                            {timer}
                          </span>
                        )
                      }
                    </FlexRowBox>
                    {
                      correct === 'Y' ? (
                        <Button
                          style={
                            {
                              color: '#66bc6a',
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                          disabled
                        >
                      인증완료
                        </Button>
                      ) : code !== '' && timer !== '00:00' ? (
                        <Button
                          onClick={e => _smsCheck(e)}
                          style={
                            {
                              color: colors.secondary,
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                        >
                      인증하기
                        </Button>
                      ) : (
                        <Button
                          style={
                            {
                              color: '#949494',
                              padding: '0px 20px 0px 40px',
                              whiteSpace: 'pre',
                            }
                          }
                          disabled
                        >
                      인증하기
                        </Button>
                      )
                    }
                  </FlexRowBox>
                  <FlexRowBox>
                    <FlexRowBox
                      style={
                        {
                          width: '750px',
                        }
                      }
                    >
                      {
                        correct === 'N' ? (
                          <WarningMsg>인증번호가 올바르지 않습니다.</WarningMsg>
                        ) : timer === '00:00' ? (
                          <WarningMsg>시간이 초과되었습니다.</WarningMsg>
                        ) : (
                          <WarningMsg />
                        )
                      }
                    </FlexRowBox>
                    {
                      correct !== 'Y' && (
                        <WarningMsg
                          onClick={
                            e => {
                              handleSendSms(e, getValues().phone)
                            }
                          }
                          style={
                            {
                              padding: 0,
                              color: '#949494',
                              cursor: 'pointer',
                              marginRight: 'initial',
                              width: '100%',
                            }
                          }
                        >
                      인증번호 재전송
                        </WarningMsg>
                      )
                    }
                  </FlexRowBox>
                </InputBox>
              </>
            ) : (
              false
            )
          }
        </FormGroup>
        <Flex style={{ width: '100%' }}>
          <FormGroup style={{ marginRight: 10 }}>
            <FormLabel htmlFor="organization">소속</FormLabel>
            <FormInput
              id="organization"
              placeholder="소속"
              name="organization"
              ref={register({ required: false })}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="duty">직무</FormLabel>
            <FormInput
              id="duty"
              placeholder="직무"
              name="duty"
              ref={register({ required: false })}
            />
          </FormGroup>
        </Flex>
        <Flex style={{ width: '100%', justifyContent: 'flex-start' }}>
          <FormGroup>
            <FormLabel htmlFor="asd3">MBTI</FormLabel>
            <InvisibleInput name="mbti" ref={register()} />
            <MbtiBtn onClick={toggleMbti}>
              {getValues().mbti ? getValues().mbti : '유형 선택'}
            </MbtiBtn>
          </FormGroup>
        </Flex>
        <SubmitBtn style={{ margin: '50px 0' }} type="submit">
          시작하기
        </SubmitBtn>
      </Form>
      <MbtiModal.View
        isShowing={showMbti}
        toggle={toggleMbti}
        mbti={wathcedMbti}
        setValue={mbti => setValue([{ mbti }])}
      />
    </BodyWrapper>
  )
}

export default RegisterForm

const MbtiBtn = styled(Flex)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 248px;
  height: 48px;
  border-radius: 7px;
  border: solid 1px #646464;
  background-color: #ffffff;
  cursor: pointer;
`
