import React from 'react'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import './CustomTab.css'
import 'react-tabs/style/react-tabs.css'

const CustomTab = props => {
  const {
    className,
    menu,
    contents,
    style,
    tabListStyle,
    tabStyle,
    panelStyle,
    defaultIndex,
    selectedIndex = false,
    onSelect,
    forceRenderTabPanel = false,
  } = props

  return (
    <Tabs
      forceRenderTabPanel={forceRenderTabPanel}
      defaultIndex={typeof selectedIndex === 'number' && selectedIndex >= 0 ? null : defaultIndex || 0}
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      className={className ? `custom_tabs ${className}` : 'custom_tabs'}
      style={style != null ? style : null}
    >
      <TabList style={tabListStyle != null ? tabListStyle : null}>
        {
          menu.map((list, index) => (
            <Tab style={tabStyle != null ? tabStyle : {}} key={index}>
              {list}
            </Tab>
          ))
        }
      </TabList>
      {
        contents.map((list, index) => (
          <TabPanel key={index} style={panelStyle != null ? panelStyle : null}>
            {list}
          </TabPanel>
        ))
      }
    </Tabs>
  )
}

export default CustomTab
