import React, { useState } from 'react'
import styled from 'styled-components'
import { Caption1, FormInput } from '@components/atoms'
import { colors } from '@resources/colors'
import FlexColumnBox from './FlexColumnBox'
import FlexRowBox from './FlexRowBox'

const SearchBox = styled(FlexColumnBox)`
    position:absolute;
    top:50px;
    width : 518px;
    height:fit-content;
    max-height:240px;
    overflow: auto;
    justify-content : flex-start;
    border-radius: 7px;
    backdrop-filter: blur(30px);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    z-index:2;
}

  > div {
    width:100%;
    height:48px;
    padding : 12px 20px;
  }
`

const SearchItem = styled(FlexRowBox)`
  cursor: pointer;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const SearchTagBox = ({
  inputText,
  onChange,
  onClick,
  autoCompleteTagList,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleClick = async (keyword, isBlur = true) => {
    if (!keyword) return
    await onClick(keyword)

    if (isBlur) {
      setIsFocused(false)
    }
  }

  return (
    <>
      {isFocused && <Overlay onClick={() => setIsFocused(false)} />}
      <div
        style={{ width: '603px', position: 'relative' }}
        onFocus={() => setIsFocused(true)}
      >
        <FormInput
          type="text"
          style={{ width: '518px' }}
          value={inputText}
          onChange={e => onChange(e.target.value.replace(/ /gi, ''))}
          onKeyDown={
            e => {
              if (e.keyCode === 13) {
                e.preventDefault()
                handleClick(inputText, false)
              }
            }
          }
        />
        {
          isFocused && !!inputText && (
            <SearchBox>
              {
                autoCompleteTagList
              && autoCompleteTagList.map(item => {
                return (
                  <SearchItem
                    onClick={() => handleClick(item.keyword)}
                    key={item.id}
                  >
                    <Caption1>{`#${item.keyword}`}</Caption1>
                    <Caption1 color="#949494">{`${item.totalCount}개`}</Caption1>
                  </SearchItem>
                )
              })
              }
            </SearchBox>
          )
        }
        <Caption1
          color={colors.secondary}
          style={{ marginLeft: '30px', cursor: 'pointer' }}
          onClick={() => handleClick(inputText)}
        >
          태그 추가
        </Caption1>
      </div>
    </>
  )
}

export default SearchTagBox
