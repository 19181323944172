import { observable } from 'mobx'
import HouseModel from './HouseModel'
import TagModel from './TagModel'

export default class HousesHaveTagsModel {
  @observable id

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  /**
   * 10: 서브 태그
   * 30: 메인 태그(주제)
   */
  @observable level

  @observable houseId
  @observable tagId

  @observable House = null
  @observable Tag = null

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.level = props.level

      this.houseId = props.houseId
      this.tagId = props.tagId

      this.House = (props.House && new HouseModel(stores, props.House)) || null
      this.Tag = (props.Tag && new TagModel(stores, props.Tag)) || null
    }
  }
}
