import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { CastDetailTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { HOUSES_HAVE_USERS_LEVEL } from '@consts/house'

const CastDetailPage = ({ match, location }) => {
  useRenderLogger('CastDetailPage')

  const { castStore, authStore, userStore, houseStore } = useStore()
  const { castDetail } = castStore
  const isMyPage = authStore.currentUser?.id === castDetail?.User?.id
  const isHouseCast = !!castDetail?.houseId
  const [myLevel, setMyLevel] = useState(null)

  const fetchCastDetailFromLinkKey = useCallback(
    castLinkKey => {
      castStore.fetchCastDetailFromLinkKey(castLinkKey)
    },
    [castStore],
  )

  const followUser = () => {
    if (castDetail?.User?.isFollowed) {
      userStore.unFollow(castDetail?.User)
    }
    else {
      userStore.follow(castDetail?.User)
    }
  }

  const followHouse = async () => {
    if (!authStore.currentUser) return

    if (myLevel === null || myLevel === undefined) {
      try {
        const res = await houseStore.followHouse(
          castDetail?.House?.id,
          authStore.currentUser.id,
        )
        setMyLevel(res?.level)

        if (res?.level === HOUSES_HAVE_USERS_LEVEL['FOLLOWER']) {
          if (castDetail?.House) {
            castDetail.House.followerCount += 1
          }
        }
        else if (res?.level >= HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
          if (castDetail?.House) {
            castDetail.House.memberCount += 1
          }
        }
      }
      catch (error) {
        console.error('CastDetailPage handleFollow error', error)
      }
    }
    else if (myLevel <= HOUSES_HAVE_USERS_LEVEL['REQUEST']) {
      try {
        const res = await houseStore.deleteHouseUser(
          castDetail?.House?.id,
          authStore.currentUser.id,
          authStore.currentUser.name,
        )
        setMyLevel(null)

        if (res?.level <= HOUSES_HAVE_USERS_LEVEL['REQUEST']) {
          if (castDetail?.House) {
            castDetail.House.followerCount -= 1
          }
        }
        else if (res?.level >= HOUSES_HAVE_USERS_LEVEL['MEMBER']) {
          if (castDetail?.House) {
            castDetail.House.memberCount -= 1
          }
        }
      }
      catch (error) {
        console.error('CastDetailPage handleFollow error', error)
      }
    }
  }

  const toggleLike = async () => {
    if (castDetail.UsersLikeCasts.length > 0) {
      const result = await castStore.unLikeCast(castDetail?.id)
      if (!result) return
      castDetail.UsersLikeCasts = []
      if (castDetail.likeCount > 0) {
        castDetail.likeCount--
      }
    }
    else {
      const result = await castStore.likeCast(castDetail?.id)
      if (!result) return
      castDetail.UsersLikeCasts.push(result)
      castDetail.likeCount++
    }
  }

  useEffect(() => {
    if (match.params.linkKey) {
      fetchCastDetailFromLinkKey(match.params.linkKey)
    }
  }, [match, fetchCastDetailFromLinkKey])

  useEffect(() => {
    if (isHouseCast && match.params.linkKey) {
      const fetchHouseInfo = async () => {
        if (authStore?.currentUser?.id && castDetail) {
          const result = await houseStore.fetchHouseDetail(
            castDetail?.houseId,
            {
              __include: 'HousesHaveUsers',
              'HousesHaveUsers.userId': authStore?.currentUser?.id,
            },
          )

          setMyLevel(
            result?.HousesHaveUsers?.[0]?.level > 0
              ? result?.HousesHaveUsers?.[0]?.level
              : result?.HousesHaveUsers?.[0]?.level === 0
                ? 0
                : null,
          )
        }
      }

      fetchHouseInfo()
    }
  }, [isHouseCast, castDetail, match])

  return (
    <CastDetailTemplate
      castDetail={castDetail}
      isMyPage={isMyPage}
      isHouseCast={isHouseCast}
      myLevel={myLevel}
      followUser={followUser}
      followHouse={followHouse}
      category={
        location.state && location.state.category && location.state.category
      }
      sort={location.state && location.state.sort && location.state.sort}
      fetchLoadPlayCast={castStore.fetchLoadPlayCast || (() => {})}
      tryAuth={authStore.tryAuth || (() => {})}
      toggleLike={toggleLike || (() => {})}
    />
  )
}

export default observer(CastDetailPage)
