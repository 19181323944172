import { HomeTemplate } from '@components/templates'
import { useRenderLogger } from '@utils/hooks'
import { observer } from 'mobx-react'
import React from 'react'

const HomePage = () => {
  useRenderLogger('HomePage')

  return <HomeTemplate />
}

export default observer(HomePage)
