import React from 'react'
import { CastSearchBar, WholeCastList } from '@components/organisms'
import { observer } from 'mobx-react'
import { PageLayout } from '..'

// const SearchTagCardList = styled(Flex)`
//   margin: 32px 0 40px 0;
//   width: 100%;

//   justify-content: flex-start;
//   flex-wrap: wrap;
//   max-width: 1704px;
//   width: calc(100% + 39px);

//   margin-left: -24px;

//   > div {
//     margin-left: 24px;
//     margin-bottom: 24px;
//   }

//   @media ${mobile} {
//     justify-content: center;
//   }
// `

const CastListTemplate = ({
  popularCastList = [],
  latestCastList = [],
  followCastList = [],
  historyCastList = [],
  recommendationCastList = [],
}) => {
  return (
    <PageLayout style={{ paddingTop: '0' }}>
      <CastSearchBar />
      {/* <SearchTagCardList>
        {representiveTagList &&
          representiveTagList.map(tag => (
            <SearchTagCard
              key={tag}
              tag={tag}
              onClick={tag => handleSearchTag(tag)}
            />
          ))}
      </SearchTagCardList> */}
      <WholeCastList
        popularCastList={popularCastList}
        latestCastList={latestCastList}
        followCastList={followCastList}
        historyCastList={historyCastList}
        recommendationCastList={recommendationCastList}
      />
    </PageLayout>
  )
}

export default observer(CastListTemplate)
