import React, { useState } from 'react'
import { SettingWayTemplate } from '@components/templates'
import { observer } from 'mobx-react'

import { lPad } from '@utils/string'
import { Redirect } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import { useRenderLogger, useStore } from '@utils/hooks'

interface Props {
  location: Any;
}

const SettingWayPage = ({ location }: Props) => {
  useRenderLogger('SettingWayPage')
  const { authStore, revenueStore } = useStore()

  const currentUser = authStore.currentUser || null
  const sendSms = authStore.sendSms || (() => {})
  const smsCheck = authStore.smsCheck || (() => {})
  const getCheckPhone = authStore.getCheckPhone || (() => {})
  const checkPassword = authStore.checkPassword || (() => {})

  const postBankAccount = revenueStore.postBankAccount || (() => {})

  const [code, setCode] = useState('') // 인증 번호
  const [timer, setTimer] = useState('') // 타이머
  const [phoneVal, setPhoneVal] = useState(null) // 타이머 인터벌 아이디
  const [isTimer, setIsTimer] = useState(false) // 타이머 진행 여부
  const [complete, setComplete] = useState(false) // 등록 성공여부

  const lastLocation = useLastLocation()

  // 카운트 다운
  let num = 60 * 3 // 몇분을 설정할지의 대한 변수 선언
  // 타이머 함수
  function timerFn() {
    let min = num / 60
    min = Math.floor(min)
    const sec = num - 60 * min

    setTimer(`${lPad(min)}:${lPad(sec)}`)

    if (timer === '00:00') {
      clearInterval(phoneVal)
    }
    num--
  }

  const time = (e, phone) => {
    e.preventDefault()

    sendSms(phone)

    if (isTimer === true) {
      clearInterval(phoneVal)
      setIsTimer(false)
    }

    setPhoneVal(setInterval(timerFn, 1000))

    setIsTimer(true)
  }

  if (timer === '00:00') {
    clearInterval(phoneVal)
  }

  return (
    <>
      {
        (lastLocation && lastLocation.pathname !== '/p/exchange/revenue/info')
        || (lastLocation === null && <Redirect to="/p/exchange/confirm-password" />)
      }
      {
        complete && (
          <Redirect
            to={{ pathname: '/p/exchange/revenue/info', state: { isAuth: true } }}
          />
        )
      }
      <SettingWayTemplate
        isResetting={location && location.state && location.state.isResetting}
        currentUser={currentUser}
        postBankAccount={postBankAccount}
        code={code}
        getCheckPhone={getCheckPhone}
        setCode={setCode}
        time={(e, phone) => time(e, phone)}
        timer={timer}
        smsCheck={smsCheck}
        setComplete={setComplete}
        checkPassword={checkPassword}
      />
    </>
  )
}

export default observer(SettingWayPage)
