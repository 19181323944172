import { NoticeListTemplate } from '@components/templates'
import { useRenderLogger, useStore, useTagLoading } from '@utils/hooks'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {}

const NoticeListPage: React.FC<Props> = () => {
  useRenderLogger('NoticeListPage')

  const { customerCenterStore } = useStore()

  const noticeList = customerCenterStore.noticeList || null
  const noticeListCount = customerCenterStore.noticeListCount || null

  const history = useHistory()
  const { isLoading, setIsLoading } = useTagLoading()
  const [currentPage, setCurrentPage] = useState(1)

  const navhNoticeDetail = linkKey => {
    history.push(`/notice/${linkKey}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await customerCenterStore.fetchNoticeList({
        offset: (currentPage - 1) * 10,
      })
      setIsLoading(false)
    }
    fetchData()
  }, [customerCenterStore, currentPage, setIsLoading])

  return (
    <NoticeListTemplate
      noticeList={noticeList}
      isLoading={isLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      noticeListCount={noticeListCount}
      navhNoticeDetail={navhNoticeDetail}
    />
  )
}

export default observer(NoticeListPage)
