import React from 'react'
import styled from 'styled-components'
import { Modal, H3, Caption1, Flex } from '@components/atoms'
import {
  FlexRowBox,
  // CustomTab,
  FlexColumnBox,
} from '@components/molecules'
import { secondToTimeString } from '@utils/time'
import {
  // SearchAudioSection,
  // LikeAudioSection,
  MyUploadAudioSection,
} from '..'

const ModalBody = styled(FlexRowBox)`
  width: 700px;
  max-height: 750px;
  min-height: 750px;
  height: 100%;
  flex-direction: column;
`

const Content = styled(FlexColumnBox)`
  padding: 20px 40px 0px 40px;
  width: 100%;
  position: relative;
`

const CurrentState = styled(FlexRowBox)`
  position: absolute;
  top: 35px;
  right: 40px;
`

const View = ({
  isShowing,
  toggle,
  handleConfirm,
  tempMixtape,
  addMixtapeSongListToTempMixtape,
}) => {
  return (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle}>
        <ModalBody>
          <H3>오디오 추가하기</H3>
          <Content>
            <Flex>
              <CurrentState>
                <Caption1 style={{ marginRight: '20px' }}>
                  총 재생시간
                  {' '}
                  {secondToTimeString(tempMixtape && tempMixtape.totalPlayTime)}
                </Caption1>
                <Caption1>
                  {(tempMixtape && tempMixtape.MixtapesHaveSongs.length) || 0}
곡
                </Caption1>
              </CurrentState>
            </Flex>
            <MyUploadAudioSection />
            {/* <CustomTab
              menu={['업로드 오디오', '좋아하는 오디오', '검색']}
              contents={[
                <MyUploadAudioSection />,
                <LikeAudioSection />,
                <SearchAudioSection />,
              ]}
            /> */}
          </Content>
        </ModalBody>
      </Modal.View>
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
