import React, { useEffect } from 'react'
import { PlanMyInfoTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

const PlanMyInfoPage = () => {
  useRenderLogger('PlanMyInfoPage')
  const { authStore, purchaseStore } = useStore()

  const currentUser = authStore.currentUser || null
  const deleteUser = authStore.deleteUser || (() => {})

  const myPlan = purchaseStore.myPlan || null
  const getMyPlan = purchaseStore.getMyPlan || (() => {})

  useEffect(() => {
    getMyPlan()
  }, [getMyPlan])

  return (
    <PlanMyInfoTemplate
      user={currentUser}
      myPlan={myPlan}
      deleteUser={deleteUser}
    />
  )
}

export default observer(PlanMyInfoPage)
