import React, { useState } from 'react'
import { TempPassword } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

const TempPasswordPage = () => {
  useRenderLogger('TempPasswordPage')
  const { authStore } = useStore()

  const tempPassword = authStore.tempPassword || (() => {})
  const getCheckEmail = authStore.getCheckEmail || (() => {})
  const [email, setEmail] = useState('') // 가입한 이메일 입력
  const [success, setSuccess] = useState('') // 전송 성공 여부

  const _tempPassword = async e => {
    e.preventDefault()
    if (email !== '') {
      const checkEmail = await getCheckEmail(email)
      if (checkEmail[0].isKakaoAccount) {
        setSuccess('kakao')
      }
      else if (checkEmail.length !== 0) {
        tempPassword(email).then(
          res => {
            setSuccess('Y')
          },
          err => {
            setSuccess('N')
          },
        )
      }
      else {
        setSuccess('N')
      }
    }
  }

  return (
    <>
      {
        success === 'Y' ? (
          <Redirect to={{ pathname: '/p/find/temp-complete', state: { email } }} />
        ) : (
          false
        )
      }
      <TempPassword
        email={email}
        setEmail={setEmail}
        _tempPassword={_tempPassword}
        success={success}
      />
    </>
  )
}

export default observer(TempPasswordPage)
