import { Flex } from '@components/atoms'
import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'

const MbtiBadge = ({ mbti, style }) => {
  if (mbti) {
    return <Badge style={style && style}>{mbti}</Badge>
  }
  return <></>
}

export default MbtiBadge

const Badge = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px ${colors.white_f4};
  background-color: ${colors.white_fa};

  font-family: NotoSansCJKkr-Bold;
  font-size: 10px;
  color: #ff1a69;
`
