import { observable } from 'mobx'
import HouseModel from './HouseModel'
import UserModel from './UserModel'

export default class HousesHaveUsersModel {
  @observable id

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable isDeleted
  /**
   * 0: follower
   * 20: member
   * 30: host
   * 40: owner
   */
  @observable level

  @observable isMyFollow

  @observable mainRelevacneOrder
  @observable subRelevacneOrder

  @observable houseId
  @observable userId

  @observable House = null
  @observable User = null

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.isDeleted = props.isDeleted
      this.level = props.level

      this.isMyFollow = props.isMyFollow

      this.mainRelevacneOrder = props.mainRelevacneOrder
      this.subRelevacneOrder = props.subRelevacneOrder

      this.houseId = props.houseId
      this.userId = props.userId

      this.House =
        (props.House
          && (props.House instanceof HouseModel
            ? props.House
            : new HouseModel(stores, props.House)))
        || null
      this.User = (props.User && new UserModel(stores, props.User)) || null
    }
  }
}
