import React, { useState } from 'react'
import styled from 'styled-components'
import { FormLabel, FormInput, WarningMsg } from '@components/atoms'
import { SelectBox, RoundBtn } from '@components/molecules'
import { mobile } from '@styles/media'
import { useForm, Controller } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import { colors } from '@resources/colors'

const Form = styled.form`
  max-width: 518px;
  width: 100%;
  margin: auto;
`

const Description = styled.p`
  text-align: center;
  font-family: NotoSansCJKkr-Medium;
  font-size: 16px;
  color: #646464;
  padding-top: 30px;
  padding-bottom: 40px;
  word-break: keep-all;

  @media ${mobile} {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
`

const FormGroup = styled.div`
  margin: 10px 0px;
  width: 100%;
`

const RoundBtnWrap = styled.div`
  display: flex;
  justifycontent: center;
  max-width: 230px;
  height: 48px;
  margin: 40px auto 0 auto;
`

const StudentPlanForm = ({
  validateStudent,
  // isValidatedStudent
}) => {
  // Before 학생 인증 휘발성 로직
  const [isStudentAuth, setIsStudentAuth] = useState(false) // 학생인증 휘발성 체크

  const { register, handleSubmit, control, errors } = useForm()
  const onSubmit = async data => {
    // Success after validate form value

    const params = {
      schoolName: data.schoolName,
      studentName: data.studentName,
      curriculum: data.curriculum.value,
    }
    const isStudentAuth = await validateStudent(params)
    setIsStudentAuth(isStudentAuth)
  }

  return (
    <div style={{ width: '100%' }}>
      {/* // Before 학생 인증 휘발성 로직 */}
      {/* {isValidatedStudent && <Redirect to="/p/plan/subscribe" />} */}
      {/* {isStudentAuth && <Redirect to="/p/plan/subscribe" />} */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Description>
          아래 내용을 입력하고 제출하면 학생 플랜을 시작하실 수 있습니다
        </Description>
        <FormGroup style={{ marginBottom: '20px' }}>
          <FormLabel color="#646464" htmlFor="curriculum">
            교육 과정
          </FormLabel>

          <Controller
            as={
              <SelectBox
                options={
                  [
                    { value: 'elementary', label: '초등학교 이상' },
                    { value: 'middle', label: '중학교 이상' },
                    { value: 'high', label: '고등학교 이상' },
                    { value: 'university', label: '대학교 이상' },
                  ]
                }
              />
            }
            id="curriculum"
            name="curriculum"
            placeholder="본인의 교육 과정을 선택해주세요"
            control={control}
            onChange={
              ([selected]) => {
                return selected
              }
            }
            rules={{ required: true }}
          />

          {
            errors.curriculum && (
              <WarningMsg style={{ marginLeft: '10px' }}>
              필수로 입력해주세요
              </WarningMsg>
            )
          }
        </FormGroup>
        <FormGroup>
          <FormLabel color="#646464" htmlFor="school-name">
            학교 이름
          </FormLabel>

          <FormInput
            id="school-name"
            name="schoolName"
            ref={register({ required: true })}
            placeholder="학교 이름을 입력해주세요"
          />

          {
            errors.schoolName && (
              <WarningMsg style={{ marginLeft: '10px' }}>
              필수로 입력해주세요
              </WarningMsg>
            )
          }
        </FormGroup>
        <FormGroup>
          <FormLabel color="#646464" htmlFor="student-name">
            이름
          </FormLabel>

          <FormInput
            id="student-name"
            name="studentName"
            ref={register({ required: true })}
            placeholder="이름을 입력해주세요"
          />

          {
            errors.studentName && (
              <WarningMsg style={{ marginLeft: '10px' }}>
              필수로 입력해주세요
              </WarningMsg>
            )
          }
        </FormGroup>

        <RoundBtnWrap>
          <RoundBtn
            type="submit"
            text="제출"
            color={colors.primary}
            style={{ width: '100%' }}
          />
        </RoundBtnWrap>
      </Form>
    </div>
  )
}

export default StudentPlanForm
