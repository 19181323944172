import { Caption1, Flex } from '@components/atoms'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'
import { DeletePopup } from '..'

const SwitchButton = ({ isSwitchOn, name, onChange }) => {
  return (
    <div>
      <CheckBoxWrapper>
        <CheckBox
          id={name}
          type="checkbox"
          checked={isSwitchOn}
          onChange={onChange}
        />
        <CheckBoxLabel htmlFor={name} />
      </CheckBoxWrapper>
    </div>
  )
}

const SalonDetailThreeDot = ({
  level,
  password,
  openThreeDot,
  setOpenThreeDot,
  isMuteJoinLiveRoom,
  isMuteRequestGuest,
  toggleJoinUserAlarm = () => {},
  toggleRequestGuestAlarm = () => {},
  toggleSalon = () => {},
  muteAllMic = () => {},
  changeMic = () => {},
  handleReturnToPrevLevel = () => {},
  handleReturnListener = () => {},
  handleReport = () => {},
  handleExitLiveRoom = () => {},
  handleFinishLiveRoom = () => {},
  startLiveRoomRecording = () => {},
  stopLiveRoomRecording = () => {},
  isLiveRoomRecording,
  currLiveRoomUser,
}) => {
  const {
    isShowing: showDeleteSalon,
    toggle: toggleDeleteSalon,
  } = DeletePopup.useModal()

  if (openThreeDot) {
    return (
      <>
        <ThreeDotMenu>
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem style={{ justifyContent: 'flex-end', paddingRight: 16 }}>
                <Caption1 style={{ cursor: 'pointer', marginRight: 22 }}>
                살롱 입장 알림
                </Caption1>
                <SwitchButton
                  isSwitchOn={!isMuteJoinLiveRoom}
                  name="isMuteJoinLiveRoom"
                  onChange={toggleJoinUserAlarm}
                />
              </DotItem>
            )
          }
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem style={{ justifyContent: 'flex-end', paddingRight: 16 }}>
                <Caption1 style={{ cursor: 'pointer', marginRight: 22 }}>
                게스트 신청 알림
                </Caption1>
                <SwitchButton
                  isSwitchOn={!isMuteRequestGuest}
                  name="isMuteRequestGuest"
                  onChange={toggleRequestGuestAlarm}
                />
              </DotItem>
            )
          }
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem>
                <Caption1
                  onClick={
                    () => {
                      const {
                        liveRoomId,
                        agoraUid,
                        agoraRtcChannelId,
                        agoraRtcUserToken,
                      } = currLiveRoomUser

                      isLiveRoomRecording
                        ? stopLiveRoomRecording({ liveRoomId })
                        : startLiveRoomRecording({
                          agoraUid,
                          liveRoomId,
                          agoraRtcChannelId,
                          agoraRtcUserToken,
                        })
                    }
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {isLiveRoomRecording ? '살롱 녹음 종료하기' : '살롱 녹음하기'}
                </Caption1>
              </DotItem>
            )
          }
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem onClick={toggleSalon}>
                <Caption1 style={{ cursor: 'pointer' }}>
                살롱 정보 수정하기
                </Caption1>
              </DotItem>
            )
          }
          {
            level === LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] && (
              <DotItem>
                <Caption1
                  onClick={
                    () => {
                      handleReturnListener()
                      setOpenThreeDot(false)
                    }
                  }
                  style={{ cursor: 'pointer' }}
                >
                리스너로 돌아가기
                </Caption1>
              </DotItem>
            )
          }
          {
            level === LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem>
                <Caption1
                  onClick={handleReturnToPrevLevel}
                  style={{ cursor: 'pointer' }}
                >
                참여자로 돌아가기
                </Caption1>
              </DotItem>
            )
          }
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
              <DotItem>
                <Caption1 onClick={muteAllMic} style={{ cursor: 'pointer' }}>
                전체 마이크 끄기
                </Caption1>
              </DotItem>
            )
          }
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] && (
              <DotItem>
                <Caption1 onClick={changeMic} style={{ cursor: 'pointer' }}>
                마이크 변경
                </Caption1>
              </DotItem>
            )
          }
          <DotItem>
            <Caption1 onClick={handleReport} style={{ cursor: 'pointer' }}>
              신고하기
            </Caption1>
          </DotItem>
          {
            level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && password && (
              <DotItem>
                <Caption1>비밀번호: </Caption1>
                <Caption1 color={colors.warning} style={{ marginLeft: 6 }}>
                  {password}
                </Caption1>
              </DotItem>
            )
          }
          <DotItem>
            <Caption1
              onClick={handleExitLiveRoom}
              style={{ cursor: 'pointer' }}
            >
              살롱 나가기
            </Caption1>
          </DotItem>
          {
            level === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER'] && (
              <DotItem>
                <Caption1
                  onClick={toggleDeleteSalon}
                  style={{ cursor: 'pointer' }}
                >
                살롱 종료하기
                </Caption1>
              </DotItem>
            )
          }
        </ThreeDotMenu>
        <Overlay onClick={() => setOpenThreeDot(false)} />
        <DeletePopup.View
          isShowing={showDeleteSalon}
          toggle={toggleDeleteSalon}
          title="살롱 종료"
          deleteText="종료"
          body={
            '살롱을 종료하면 방이 사라지고\n모든 참여자들이 퇴장됩니다\n살롱을 유지하고 싶을 시 다른 참여자에게\n호스트 권한을 부여하고\n‘살롱 종료하기’를 선택해주세요'
          }
          onDelete={
            () => {
              handleFinishLiveRoom()
              setOpenThreeDot(false)
            }
          }
        />
      </>
    )
  }
  return null
}

export default SalonDetailThreeDot

const ThreeDotMenu = styled(Flex)`
  position: absolute;
  top: 40px;
  right: 33px;
  width: 240px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background-color: ${colors.white_ff};
  z-index: 11;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
`

const DotItem = styled(Flex)`
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
`

const CheckBoxWrapper = styled.div`
  position: relative;
  height: 20px;
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 20px);
  width: 40px;
  height: 20px;
  border-radius: 14px;
  background: ${colors.grey_d4};
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;

    width: 16px;
    height: 16px;
    margin: 2px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 14px;
  width: 40px;
  height: 20px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: ${colors.primary};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 22px;
      transition: 0.2s;
    }
  }
`
