import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { H6, Flex } from '@components/atoms'
import { UserFollowInfoItem } from '@components/molecules'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { FOLLOWER_ORDER_TYPE, FOLLOWING_ORDER_TYPE } from '@consts/'
import { useHistory } from 'react-router-dom'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 800px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 24px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const CustomInifiniteScroll = styled(InfiniteScroll)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle, userId, type }) => {
  const history = useHistory()
  const { authStore, userStore } = useStore()

  const currentUser = authStore.currentUser || null

  const userDetailFollowerUserList =
    userStore.userDetailFollowerUserList || null
  const userDetailFollowingUserList =
    userStore.userDetailFollowingUserList || null

  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)

  const handleFollow = userDetail => {
    if (userDetail && userDetail.isFollowed) {
      userStore.unFollow(userDetail)
    }
    else {
      userStore.follow(userDetail)
    }
  }

  const handleNext = () => {
    setOffset(offset + 20)
  }

  React.useEffect(() => {
    const fetchUserList = async () => {
      let result
      if (type === 'following') {
        result = await userStore.fetchFollowingUserList({
          userId,
          orderType: FOLLOWER_ORDER_TYPE[0],
          searchText: '',
          lastItem:
            userStore.userDetailFollowingUserList
            && userStore.userDetailFollowingUserList[
              userStore.userDetailFollowingUserList.length - 1
            ],
        })
      }
      else if (type === 'follower') {
        result = await userStore.fetchFollowerUserList({
          userId,
          orderType: FOLLOWING_ORDER_TYPE[0],
          searchText: '',
          lastItem:
            userStore.userDetailFollowerUserList
            && userStore.userDetailFollowerUserList[
              userStore.userDetailFollowerUserList.length - 1
            ],
        })
      }

      if (result && result < 20) setHasMore(false)
      else if (result >= 20) setHasMore(true)
    }

    fetchUserList()
  }, [type, userStore, offset, userId])

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <CustomInifiniteScroll
                dataLength={
                  type === 'following'
                    ? userDetailFollowingUserList.length
                    : userDetailFollowerUserList.length
                }
                scrollThreshold={0.8}
                next={handleNext}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                height={740}
                loader={
                  <ReactLoading
                    type="spin"
                    color="black"
                    style={
                      {
                        width: '50px',
                        height: '50px',
                        margin: '0 auto 50px auto',
                      }
                    }
                  />
                }
              >
                <Flex style={{ height: 64 }} justify="center">
                  <H6>{type === 'following' ? '팔로잉' : '팔로워'}</H6>
                </Flex>
                {
                  type === 'following'
                    ? userDetailFollowingUserList
                      && userDetailFollowingUserList.length > 0
                      && userDetailFollowingUserList.map((item, index) => (
                        <UserFollowInfoItem
                          item={item}
                          key={item.id}
                          currentUserId={currentUser?.id}
                          style={{ marginBottom: 16 }}
                          handleUser={
                            () => {
                              history.push(`/user/${item.account}`)
                              toggle()
                            }
                          }
                          handleFollow={handleFollow}
                        />
                      ))
                    : userDetailFollowerUserList
                      && userDetailFollowerUserList.length > 0
                      && userDetailFollowerUserList.map((item, index) => (
                        <UserFollowInfoItem
                          item={item}
                          key={item.id}
                          currentUserId={currentUser?.id}
                          style={{ marginBottom: 16 }}
                          handleUser={
                            () => {
                              history.push(`/user/${item.account}`)
                              toggle()
                            }
                          }
                          handleFollow={handleFollow}
                        />
                      ))
                }
              </CustomInifiniteScroll>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}
