import { IS_DEV } from '@consts/'
import React, { useEffect } from 'react'
import { isChrome } from 'react-device-detect'
import { BrowserRouter } from 'react-router-dom'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Routes from './Routes'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgTRAOAbwmBs2oQyS8dOC_vKt8x5kxv-g",
  authDomain: "mixtape-cc764.firebaseapp.com",
  databaseURL: "https://mixtape-cc764.firebaseio.com",
  projectId: "mixtape-cc764",
  storageBucket: "mixtape-cc764.appspot.com",
  messagingSenderId: "220281443591",
  appId: "1:220281443591:web:b7ead7bf0a3cf477e521a2",
  measurementId: "G-YN45M2GNLF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {
    // prettier-ignore
    if (typeof console === "object" && console.log && typeof console.log === "function") {
      const color = {
        primary: 'color:#594ee9;font-size:12px;',
        secondary: 'color:#EA9800;font-size:12px;',
      }
      console.productLog(
        // eslint-disable-next-line
    "%c <====================================>             \
    \n%c   _   _ ______  _____  _   _ ___  ___             \
    \n%c  | | | || ___ \\|  ___|| | | ||  \\/  |           \
    \n%c  | |_| || |_/ /| |__  | | | || .  . |             \
    \n%c  |  _  ||    / |  __| | | | || |\\/| |            \
    \n%c  | | | || |\\ \\ | |___ | |_| || |  | |           \
    \n%c  \\_| |_/\\_| \\_|\\____/  \\___/ \\_|  |_/       \
    \n%c                                                   \
    \n%c <====================================>",
        color.primary,
        color.primary,
        color.primary,
        color.primary,
        color.primary,
        color.primary,
        color.primary,
        color.primary,
        color.primary,
      )
    }

    const domain = window.location.href

    // if (
    //   !isChrome
    //   && !domain.includes('?liveRoomId=')
    //   && !domain.includes('?mixtapeId=')
    //   && !domain.includes('?castId=')
    // ) {
    //   alert(
    //     '본 홈페이지는 크롬 브라우저에 최적화 되어있습니다. 크롬 브라우저에서 이용 부탁드립니다.',
    //   )
    // }
  }, [])

  return (
    <div className="App" id="scrollableDiv">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <video id="AGORA_PLAYER" style={{ display: 'none' }} />
    </div>
  )
}

export default App
