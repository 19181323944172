import React, { useState } from 'react'
import { UploadTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { getTag } from '@utils/media'
import { observer } from 'mobx-react'

import { useRenderLogger, useStore } from '@utils/hooks'

const UploadPage = () => {
  useRenderLogger('UploadPage')
  const { songStore, authStore } = useStore()

  const currentUser = authStore.currentUser || null
  const uploadSong = songStore.uploadSong || (() => {})
  const createTagList = songStore.createTagList || (() => {})
  const getYoutubeLink = songStore.getYoutubeLink || (() => {})
  const uploadSongAudio = songStore.uploadSongAudio || (() => {})
  const uploadSongImage = songStore.uploadSongImage || (() => {})

  const [isUploaded, setIsUploaded] = useState(false)

  return (
    <>
      {
        isUploaded && (
          <Redirect
            to={{ pathname: '/p/dj/mixtape/list', state: { type: 'SONG' } }}
          />
        )
      }
      <UploadTemplate
        user={currentUser}
        uploadSong={uploadSong}
        _getYoutubeLink={getYoutubeLink}
        createTagList={createTagList}
        setIsUploaded={setIsUploaded}
        uploadSongAudio={uploadSongAudio}
        uploadSongImage={uploadSongImage}
        getTag={getTag}
      />
    </>
  )
}

export default observer(UploadPage)
