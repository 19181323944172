import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  Flex,
  FormInput,
  H5,
  H6,
  Image,
  Img,
} from '@components/atoms'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import { colors } from '@resources/colors'
import {
  CastListItem,
  MixtapeListItem,
} from '@components/molecules'
import { images } from '@images/'
import {
} from '@consts/cast'
import { message } from 'antd'
import { FeedCard, FeedDetailModal, TagSelectModal } from '..'

const TAG_SELECT_TYPE = {
  RSS: 'RSS',
  YOUTUBE: 'YOUTUBE',
}

const UserDetailHomeSection = ({ user, isMyPage, setSelectedTabIdx, initFeedId: _initFeedId }) => {
  const [
    isShowYoutubeChannelSyncButton,
    setIsShowYoutubeChannelSyncButton,
  ] = useState(isMyPage && user && user['isShowYoutubeChannelSyncButton'])
  const [initFeedId, setInitFeedId] = useState(_initFeedId)

  const [isShowRssInfoSyncButton, setIsShowRssInfoSyncButton] = useState(
    isMyPage && user && user['isShowRssInfoSyncButton'],
  )

  const {
    authStore,
    feedStore,
    castStore,
    mixtapeStore,
    userStore,
  } = useStore()
  const history = useHistory()
  const userId = user?.id

  const {
    isShowing: showDetail,
    toggle: toggleDetail,
  } = FeedDetailModal.useModal()

  const userFeedList = feedStore.userFeedList || null
  const userCastList = castStore.userCastList || null
  const userMixtapeList = mixtapeStore.userMixtapeList || null

  React.useEffect(() => {
    if(initFeedId && user && !(!isMyPage && user.isPrivate && !user.isFollowed)) {
      const isAuth = authStore.tryAuth()
      if (!isAuth) return

      setTargetFeedId(initFeedId)
      toggleDetail()
      setInitFeedId(null)
    }

    return () => {

    }
  }, [user])

  const [targetFeedId, setTargetFeedId] = React.useState(null)

  const handleLoadDetail = async id => {
    const isAuth = await authStore.tryAuth()
    if (!isAuth) return

    setTargetFeedId(id)
    toggleDetail()
  }

  React.useEffect(() => {
    if (isShowRssInfoSyncButton !== user?.['isShowRssInfoSyncButton']) {
      setIsShowRssInfoSyncButton(user?.['isShowRssInfoSyncButton'])
    }
    if (
      isShowYoutubeChannelSyncButton
      !== user?.['isShowYoutubeChannelSyncButton']
    ) {
      setIsShowYoutubeChannelSyncButton(
        user?.['isShowYoutubeChannelSyncButton'],
      )
    }
  }, [user])

  React.useEffect(() => {
    const fetchUserContentList = async () => {
      await feedStore.fetchUserFeedList(userId, {
        // 유저 채널 리스트에서 쓰는 프로퍼티가 적으므로 임시 최적화
        __attributes:
        "id,houseId,userId,content,rawContent,likeCount,commentCount,audioFileId,isHouseMember,createdAt,editedAt,publishedAt,FeedsHaveUsers.id,FeedsHaveUsers.userId,Houses.id,Houses.title,Houses.imageUri,Houses.isPremiumMember,Users.id,Users.name,Users.imageUri,Users.profileColor,Users.isInfluencer,FeedImages.id,FeedImages.imageUri,FeedImages.feedId,AudioFiles.id,AudioFiles.isFeed,AudioFiles.originalUri,AudioFiles.duration",
        // 'FeedImages.imageUri,FeedsHaveUsers.id,Users.id,Users.account,id,linkKey,userId,publishedAt,audioFileId',
        __limit: 4,
        __order: 'publishedAtDesc',
        houseId: 'null',
        houseIdOperator: 'is',
      })

      await castStore.fetchUserCastList(userId, 4)

      await mixtapeStore.fetchUserMixtapeList({
        __limit: 4,
        userId,
        popularChartLevel: 0,
      })
    }

    if (userId) {
      fetchUserContentList()
    }

    return () => {
      feedStore.init()
      castStore.init(false)
      mixtapeStore.init()
    }
  }, [feedStore, castStore, mixtapeStore, userId])

  if (!user) return <></>
  return (
    <Section style={{ marginTop: 36 }}>
      {
        !isMyPage && user.isPrivate && !user.isFollowed ? (
          <Flex
            type="column"
            style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
          >
            <H6 color={colors.grey_64}>비공개 계정입니다.</H6>
            <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
            피드를 보고 싶으면 팔로우를 요청하세요
            </Caption1>
          </Flex>
        ) : (
          <>
            {/* 피드 */}
            <Flex justify="space-between" align="center">
              <H5 type="bold">피드</H5>
              {
userFeedList?.length > 0 && (
                  <Caption1
                    onClick={() => setSelectedTabIdx(1)}
                    color={colors.primary}
                    style={{ cursor: 'pointer' }}
                  >
                더 보기
                  </Caption1>
                )
              }
            </Flex>
            <List>
              {
                userFeedList && userFeedList.length > 0 ? (
                  userFeedList.map((item, index) => {
                    if (index > 3) return true
                    return (
                    // FeedListItem이 Image태그밖에 없어서 임시 컴포넌트
                      <FeedCard
                        from="home"
                        item={item}
                        key={item.id}
                        handleLoadDetail={handleLoadDetail}
                      />
                    )
                  })
                ) : (
                  <Flex
                    type="column"
                    style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
                  >
                    <H6 color={colors.grey_64}>피드가 없습니다</H6>
                    {
                      isMyPage && (
                        <Caption1 color={colors.grey_bf} style={{ marginTop: 8 }}>
                    피드 업로드는 앱에서 하실 수 있습니다
                        </Caption1>
                      )
                    }
                  </Flex>
                )
              }
            </List>
          </>
        )
      }
      {/* 캐스트 */}
      <Flex justify="space-between" align="center">
        <H5 type="bold">캐스트</H5>
        {
userCastList?.length > 0 && (
            <Caption1
              onClick={() => setSelectedTabIdx(2)}
              color={colors.primary}
              style={{ cursor: 'pointer' }}
            >
            더 보기
            </Caption1>
          )
        }
      </Flex>
      <List>
        {
          userCastList && userCastList.length > 0 ? (
            userCastList.map((item, index) => {
              if (index > 3) return true
              return (
                <CastListItem
                  item={item}
                  key={`${item.id}_${index}`}
                  category="user"
                />
              )
            })
          ) : (
            <Flex
              type="column"
              style={{ width: '100%', margin: '20px 0', textAlign: 'center' }}
            >
              <H6 color={colors.grey_64}>캐스트가 없습니다</H6>
              {
                isMyPage && (
                  <Caption1
                    onClick={() => history.push('/p/cast/manage')}
                    color={colors.grey_bf}
                    style={{ cursor: 'pointer', marginTop: 8 }}
                  >
                지금 바로 캐스트를 등록해보세요!
                  </Caption1>
                )
              }
            </Flex>
          )
        }

      </List>

      {/* 플라 */}
      <Flex justify="space-between" align="center">
        <H5 type="bold">플라</H5>
        {
userMixtapeList?.length > 0 && (
            <Caption1
              onClick={() => setSelectedTabIdx(3)}
              color={colors.primary}
              style={{ cursor: 'pointer' }}
            >
            더 보기
            </Caption1>
          )
        }
      </Flex>
      <List>
        {
          userMixtapeList
          && userMixtapeList.map((item, index) => {
            if (index > 3) return true
            return <MixtapeListItem item={item} key={`${item.id}_${index}`} />
          })
        }

        {
          isMyPage && (
            <Flex
              justify="space-between"
              align="center"
              style={
                {
                  width: '100%',
                  height: '64',
                  borderRadius: 8,
                  border: '1px solid #f4f4f4',
                  padding: '10px 12px 10px 16px',
                }
              }
            >
              <Flex align="center">
                <img
                  src={images.mixtape_add_img}
                  style={{ width: 36, height: 36, marginRight: 8 }}
                  alt="mixtape_add"
                />
                <Caption1>플라 : 음악 플레이리스트 라디오</Caption1>
              </Flex>
              <MakeBtn onClick={() => history.push('/p/dj/mixtape/add')}>
              제작하기
              </MakeBtn>
            </Flex>
          )
        }
      </List>

      <FeedDetailModal.View
        isShowing={showDetail}
        toggle={toggleDetail}
        feedId={targetFeedId}
      />
    </Section>
  )
}

export default observer(UserDetailHomeSection)

const Section = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin: 24px 0;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  @media ${mobile} {
    justify-content: center;
  }
`

const MakeBtn = styled(Flex)`
  cursor: pointer;
  height: 32px;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${colors.white_ff};
  background: ${colors.primary};
  border-radius: 4px;
`

