import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { H6 } from '@components/atoms'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'
import { images } from '@images/'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9060;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  min-width: 300px;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9061;
  background: white;
  border-radius: 16px;
  overflow: scroll;
  min-width: 320px;
  min-height: 170px;

  @media ${mobile} {
    height: 199px;
    top: 40%;
  }
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 45px;
  width: 400px;
  height: 614px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const OnboardImg = styled.img`
  width: 300px;
  height: 388px;
  border: none;
`

const ConfirmBtn = styled.button`
  width: 103px;
  height: 40px;
  border-radius: 24px;
  border: none;
  cursor: pointer;
  background-color: ${props => (props.active ? colors.primary : '#f4f4f4')};
  color: ${props => (props.active ? '#ffffff' : '#949494')};
`

const CurrentDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 5px;
  background-color: ${props => (props.active ? colors.primary : '#f4f4f4')};
`

const usePopup = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = ({ isShowing, toggle, onClose }) => {
  const [step, setStep] = useState(1)

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    toggle()
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              {
                step === 1 ? (
                  <OnboardImg src={images.onboard_img_1} />
                ) : step === 2 ? (
                  <OnboardImg src={images.onboard_img_2} />
                ) : step === 3 ? (
                  <OnboardImg src={images.onboard_img_3} />
                ) : step === 4 ? (
                  <OnboardImg src={images.onboard_img_4} />
                ) : (
                  false
                )
              }
              <FlexRowBox
                style={{ justifyContent: 'center', margin: '30px 0' }}
              >
                <CurrentDot active={step === 1} />
                <CurrentDot active={step === 2} />
                <CurrentDot active={step === 3} />
                <CurrentDot active={step === 4} />
              </FlexRowBox>
              <FlexRowBox
                style={
                  {
                    padding: '0 40px',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                  }
                }
              >
                <ConfirmBtn
                  active={step !== 1}
                  onClick={
                    () => {
                      if (step > 1) {
                        setStep(step - 1)
                      }
                    }
                  }
                >
                  <H6>이전</H6>
                </ConfirmBtn>
                {
                  step === 4 ? (
                    <ConfirmBtn active onClick={handleClose}>
                      <H6>플라 만들기</H6>
                    </ConfirmBtn>
                  ) : (
                    <ConfirmBtn active onClick={() => setStep(step + 1)}>
                      <H6>다음</H6>
                    </ConfirmBtn>
                  )
                }
              </FlexRowBox>
            </MobdlBody>
          </Modal>
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
}

export default {
  View,
  usePopup,
}
