import styled from 'styled-components'
import { mobile } from '@styles/media'

const FormTextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  font-size: 16px;
  font-family: NotoSansCJKkr-Medium;
  border: 1px solid #949494;
  border-radius: 7px;
  padding: 0.7rem;
  margin: 10px 0px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @media ${mobile} {
    font-size: 12px;
  }
`

export default FormTextArea
