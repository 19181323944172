import { action, observable } from 'mobx'
import { SKU } from '@consts/index'
import { Network } from './networks'
import { Store } from '.'

export default class PurchaseStore {
  @observable myPlan
  @observable isValidatedStudent
  @observable isCanceled
  @observable purchaseStarList

  @observable isLimitSalonTime = true

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.myPlan = null
    this.isValidatedStudent = false
    this.isCanceled = false
    this.purchaseStarList = []
  }

  @action.bound
  async initClient() {}

  @action.bound
  async getMyPlan(params) {
    return this.store.useLoading(async () => {
      const result = await this.network.purchaseNetwork.getMyPlan(params)
      const data = result
      let plan
      let planName

      if (data.validation.isValidate && data.adLevel) {
        if (data.adLevel === 200) {
          plan = 'standard'
          planName = 'Standard'
        }
        else if (data.adLevel === 150) {
          plan = 'student'
          planName = 'For Student'
        }
      }

      switch (data?.validation?.purchaseItem?.sku) {
        case SKU['android_sub_standard_1']:
        case SKU['android_sub_standard_1_2']:
        case SKU['inicis_sub_standard_1']:
        case SKU['inicis_sub_standard_1_2']:
        case SKU['inicis_sub_standard_1_3']:
        case SKU['inicis_sub_standard_3']:
        case SKU['inicis_sub_standard_3_2']:
        case SKU['inicis_sub_standard_3_3']:
        case SKU['inicis_sub_premium_1_3']:
        case SKU['inicis_sub_premium_3_3']:
        case SKU['android_sub_standard_1_3']:
        case SKU['android_sub_standard_3_3']:
        case SKU['ios_sub_standard_1_3']:
        case SKU['ios_sub_standard_3_3']:
        case SKU['ios_sub_standard_1']:
        case SKU['ios_sub_standard_1_2']:
        case SKU['android_sub_premium_1_3']:
        case SKU['android_sub_premium_3_3']:
        case SKU['ios_sub_premium_1_3']:
        case SKU['ios_sub_premium_3_3']:
          if (data?.adLevel === 200 && !data.validation.isExpired) {
            this.isLimitSalonTime = false
          }
          else {
            this.isLimitSalonTime = true
          }
          break

        default:
          this.isLimitSalonTime = true
          break
      }

      const customData = Object.assign(data, { plan, planName })
      this.myPlan = customData
    })
  }

  @action.bound
  async getValidateStudent() {
    /* 학생 인증 휘발성 버그 수정. jhlim 2020-10-18 */
    // return this.store.useLoading(async () => {
    //   const res = await this.network.purchaseNetwork.getValidateStudent({
    //     userId: this.store.authStore.currentUser.id,
    //   })
    //   if (res.length !== 0) {
    //     this.isValidatedStudent = true
    //   }
    // })
  }

  @action.bound
  async validateStudent(params) {
    return this.store.useLoading(async () => {
      const res = await this.network.purchaseNetwork.validateStudent(params)
      if (res[0]) {
        this.isValidatedStudent = true
      }

      return !!this.isValidatedStudent
    })
  }

  @action.bound
  async getPaymentParams(params) {
    return this.store.useLoading(async () => {
      const result = await this.network.purchaseNetwork.getPaymentParams(params)

      return result
    })
  }

  @action.bound
  async getPurchasePlans(params) {
    return this.store.useLoading(async () => {
      const result = await this.network.purchaseNetwork.getPurchasePlans(params)
      const data = result && result[0] ? result[0] : null

      return { ...data, plan: params.plan, period: params.period }
    })
  }

  @action.bound
  async cancelPlan(params) {
    return this.store.useLoading(async () => {
      const res = await this.network.purchaseNetwork.cancelPlan(params)

      if (!res) return false

      this.isCanceled = true
    })
  }

  @action.bound
  fetchPurchaseStar = async () => {
    return this.store.useLoading(async () => {
      const res = await this.network.purchaseNetwork.getPurchaseStar()

      if (!res) return

      this.purchaseStarList = res
    })
  }

  @action.bound
  purchaseRequstStar = params => {
    return this.store.useLoading(async () => {
      const res = await this.network.purchaseNetwork.getPurchaseInicisInfoStars(
        params,
      )
      if (!res) return
      return res
    })
  }
}
