import { useLocation } from 'react-router-dom'

export default function useQuery() {
  const query = new URLSearchParams(useLocation().search)

  const queryObject = {}

  query.forEach((v, k) => {
    queryObject[k] = v
  })

  return queryObject
}
