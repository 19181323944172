import { STORAGE_URL } from '@consts/'
import { computed, observable } from 'mobx'
import FeedModel from './FeedModel'

export default class FeedImageModel {
  @observable id

  @observable _imageUri
  @observable thumbnailUri

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable feedId

  @observable Feed = null

  set imageUri(value) {
    this._imageUri = value

    /* thumbnailUri */
    if (this._imageUri && typeof this._imageUri === 'string') {
      const splitedPath = this._imageUri.split('/')
      const filename = splitedPath.splice(splitedPath.length - 1, 1)[0]
      this.thumbnailUri = `${splitedPath.join('/')}/thumbnail/${filename}`
    }
  }

  @computed get imageUri() {
    return this._imageUri
  }

  @computed get imageUrl() {
    return this._imageUri ? STORAGE_URL + this._imageUri : ''
  }

  @computed get imageSource() {
    return this._imageUri ? { uri: STORAGE_URL + this._imageUri } : { uri: '' }
  }

  @computed get thumbnailUrl() {
    return this.thumbnailUri ? STORAGE_URL + this.thumbnailUri : ''
  }
  @computed get thumbnailSource() {
    return this.thumbnailUri && { uri: STORAGE_URL + this.thumbnailUri }
  }

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.imageUri = props.imageUri

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.feedId = props.feedId

      this.Feed = (props.Feed && new FeedModel(stores, props.Feed)) || null
    }
  }
}
