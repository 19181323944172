import React from 'react'
import { CardBox, FlexRowBox } from '@components/molecules'
import { H4, H6, Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { hideNumber } from '@utils/string'
import { colors } from '@resources/colors'
import { images } from '@images/'

const Container = styled(FlexRowBox)`
  align-items: flex-start;
  flex-direction: column;
  width: 300px;
  height: 264px;
  padding: 40px 30px;
  box-sizing: border-box !important;
`

const RevenueAccountCard = ({ currentBankAccount }) => {
  return (
    <CardBox style={{ margin: '15px 30px 15px 0px' }}>
      <Container>
        <H4>환전 계좌</H4>
        <FlexRowBox style={{ marginTop: '66px' }}>
          <H6 type="Regular" style={{ marginRight: '16px' }}>
            {
              currentBankAccount && currentBankAccount.bankName
                ? currentBankAccount.bankName
                : '승인 대기중...'
            }
          </H6>
          <H6 type="Regular">
            {currentBankAccount && currentBankAccount.accountHolder}
          </H6>
        </FlexRowBox>
        <H6 style={{ margin: '4px 0px 16px 0px' }}>
          {
            currentBankAccount
            && currentBankAccount.accountNumber
            && hideNumber(currentBankAccount.accountNumber)
          }
        </H6>
        <FlexRowBox style={{ cursor: 'pointer' }}>
          <Link
            to={
              {
                pathname: '/p/exchange/setting-way',
                state: { isResetting: true },
              }
            }
          >
            <FlexRowBox>
              <Caption1 color={colors.secondary}>계좌 변경하기</Caption1>
              <img
                src={images.move_img}
                alt="move"
                style={{ marginLeft: '2px', width: '14px', height: '14px' }}
              />
            </FlexRowBox>
          </Link>
        </FlexRowBox>
      </Container>
    </CardBox>
  )
}

export default RevenueAccountCard
