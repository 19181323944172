import React from 'react'
import { AdultAuthForm } from '@components/organisms'
import { H1, H6, Caption1 } from '@components/atoms'
import { PageLayout } from '..'

const NeedAdultTemplate = ({
  postAdultCertification,
  setComplete,
  accountRevenueLicense,
}) => {
  return (
    <PageLayout>
      <H1>개인 정보 제공 및 보호자 동의</H1>
      <H6 style={{ margin: '20px 0px 0 0px' }}>
        만 19세 이상의 성인 인증(보호자 동의)와 개인정보 제공 동의를 위한
        동의서를 제출하고 검토를 요청 합니다.
      </H6>
      <Caption1 color="#949494" style={{ margin: '10px 0 20px 0' }}>
        *만 19세: 2020년 기준, 2001년 출생 및 생일이 지난 사람
      </Caption1>
      <H6>
        환전 수단 설정에서 입력한 예금주의 이름과 인증하는 회원의 이름이
        동일해야하며
        <br />
        검토 요청 후 승인 혹은 반려될 수 있습니다.
      </H6>
      <AdultAuthForm
        accountRevenueLicense={accountRevenueLicense}
        postAdultCertification={postAdultCertification}
        setComplete={setComplete}
      />
    </PageLayout>
  )
}

export default NeedAdultTemplate
