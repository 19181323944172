import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import { Button, Caption2, Flex, ProfileImg } from '@components/atoms'
import { DeletePopup, DJBadge, UserReportPopup } from '@components/molecules'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { Link } from 'react-router-dom'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { PresentStarModal } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 400px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(({ isShowing, toggle }) => {
  const { liveRoomStore, userStore, customerCenterStore } = useStore()
  const { salonUser, currLiveRoomUser } = liveRoomStore
  const [reason, setReason] = React.useState('')

  const {
    isShowing: showPresent,
    toggle: togglePresent,
  } = PresentStarModal.useModal()

  const {
    isShowing: showUserReport,
    toggle: toggleUserReport,
  } = UserReportPopup.usePopup()

  const { isShowing: showBan, toggle: toggleBan } = DeletePopup.useModal()

  const _followUser = () => {
    if (salonUser?.User?.isFollowed) {
      userStore.unFollow(salonUser?.User)
    }
    else {
      userStore.follow(salonUser?.User)
    }
  }

  const handleReportUser = async (userId, reason) => {
    if (!reason) return

    let _reason = ''
    if (reason === 1) _reason = '부적절한 채팅 내용'
    else _reason = '커뮤니티 가이트라인 위반'

    const message = `종류: user \n사유: ${_reason} \n살롱id: ${salonUser?.liveRoomId} \n살롱 제목: ${liveRoomStore.currLiveRoom.title} userId: ${salonUser?.User?.id}`

    await customerCenterStore.sendLiveRoomUserFeedback(userId, _reason, message)
  }

  return isShowing
    ? ReactDOM.createPortal(
      <>
        <ModalOverlay onClick={() => toggle()} />
        <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Modal>
            <MobdlBody>
              <Content>
                <UserInfo>
                  <Link
                    to={`/user/${salonUser?.User?.account}`}
                    target="_blank"
                  >
                    <ProfileImg
                      src={salonUser?.User?.imageUri}
                      profileColor={salonUser?.User?.profileColor}
                      style={
                        {
                          width: 56,
                          height: 56,
                          borderRadius: '50%',
                          marginRight: 8,
                        }
                      }
                    />
                  </Link>
                  <Flex type="column" style={{ flex: 1 }} align="flex-start">
                    <DJBadge
                      discJockeyLevel={salonUser?.User?.discJockeyLevel}
                      style={{ marginBottom: 6 }}
                    />
                    <Caption2
                      align="left"
                      style={oneEllipsisStyle}
                      type="Bold"
                    >
                      {salonUser?.User?.name}
                    </Caption2>
                  </Flex>
                  <FollowBtn
                    isFollowed={salonUser?.User?.isFollowed}
                    style={{ marginLeft: 20 }}
                    onClick={_followUser}
                  >
                    {salonUser?.User?.isFollowed ? '팔로잉' : '팔로우'}
                  </FollowBtn>
                </UserInfo>
                {
                  currLiveRoomUser['level']
                    >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
                    && salonUser['level']
                      < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
                    <BtnItem
                      onClick={
                        () => {
                          // liveRoomStore.acceptToHost(salonUser?.User)
                          liveRoomStore.requestToHost(salonUser?.User)
                          toggle()
                        }
                      }
                    >
                        호스트 초대하기
                    </BtnItem>
                  )
                }
                {
                  currLiveRoomUser['level']
                    >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
                    && salonUser['level']
                      < LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] && (
                    <BtnItem
                      onClick={
                        () => {
                          liveRoomStore.requestToGuest(salonUser?.User)
                          toggle()
                        }
                      }
                    >
                        게스트 초대하기
                    </BtnItem>
                  )
                }
                {
                  currLiveRoomUser['level']
                    >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
                    && salonUser['level']
                      === LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] && (
                    <BtnItem
                      onClick={
                        () => {
                          liveRoomStore.getBackToListener(salonUser?.User)
                          toggle()
                        }
                      }
                    >
                        리스너로 되돌리기
                    </BtnItem>
                  )
                }
                {
                  currLiveRoomUser['level']
                    === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
                    && salonUser['level']
                      === LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
                    <BtnItem
                      onClick={
                        () => {
                          liveRoomStore.getBackToGuest(salonUser)
                          toggle()
                        }
                      }
                    >
                        게스트로 되돌리기
                    </BtnItem>
                  )
                }
                {
                  currLiveRoomUser['level']
                    === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER'] && (
                    <BtnItem onClick={toggleBan}>유저 내보내기</BtnItem>
                  )
                }
                <BtnItem onClick={togglePresent}>별 선물하기</BtnItem>
                <BtnItem onClick={toggleUserReport}>유저 신고하기</BtnItem>
              </Content>
            </MobdlBody>
          </Modal>
          <PresentStarModal.View
            isShowing={showPresent}
            toggle={togglePresent}
            userId={salonUser?.User?.id}
          />
          <UserReportPopup.View
            isShowing={showUserReport}
            toggle={toggleUserReport}
            onOk={handleReportUser}
            onCancel={() => console.log('onCancel')}
            userId={salonUser?.User?.id}
            type={'user'}
            reason={reason}
            setReason={setReason}
          />
          <DeletePopup.View
            isShowing={showBan}
            toggle={toggleBan}
            title="내보내기"
            body={`${salonUser?.User.name}님을 내보낼까요?\n내보내기 이후에 해당 유저는\n다시 해당 살롱에 참여할 수 없습니다`}
            deleteText="내보내기"
            onDelete={
              () => {
                liveRoomStore.banUser(salonUser?.User)
                toggle()
              }
            }
          />
        </ModalWrapper>
      </>,
      document.body,
    )
    : null
})

export default {
  View,
  useModal,
}

const Content = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UserInfo = styled(Flex)`
  width: 100%;
  height: 104px;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`

const FollowBtn = styled(Button)`
  width: 56px;
  height: 36px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  border-radius: 18px;
  font-size: 12px;

  ${({ isFollowed }) =>
    isFollowed
      ? css`
          background-color: ${colors.white_f4};
          color: ${colors.grey_94};
        `
      : css`
          background-color: ${colors.primary};
          color: ${colors.white_ff};
        `}
`

const BtnItem = styled(Flex)`
  width: 100%;
  height: 48px;
  border-top: solid 1px ${colors.white_f4};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: NotoSansCJKkr-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`
