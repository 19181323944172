import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { Network } from './networks'
import { Store } from '.'
import {
  CastModel,
  HouseModel,
  LiveRoomModel,
  MixtapeModel,
  SongModel,
  UserModel,
} from './models'

export default class SearchStore {
  @observable globalKeyword
  @observable globalInputKeyword

  @persist('list') @observable searchHistory

  @observable songResults
  @persist('list') @observable audioSearchHistory

  @observable mixtapeSearchResults
  @observable mixtapeSearchKeyword
  @persist('list') @observable mixtapeSearchHistory
  @observable autoCompleteKeywordList

  @observable mixtapeTagKeyword
  @observable searchTagKeywordList

  @observable searchedMixtapeList
  @observable searchedCastList
  @observable tagMixtapeList

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.globalKeyword = this.store.appStateStore.querify().keyword
    this.globalInputKeyword = this.store.appStateStore.querify().keyword
    this.searchHistory = [] // 글로벌 검색 내역

    this.songResults = []
    this.audioSearchHistory = []

    this.mixtapeSearchResults = [] // 흐름 검색 결과
    this.mixtapeSearchKeyword = '' // 흐름 검색어
    this.mixtapeSearchHistory = [] // 흐름 검색 내역

    this.autoCompleteKeywordList = [] // 흐름 검색 예상 검색어 리스트

    this.mixtapeTagKeyword = '' // 흐름 검색 태그
    this.searchTagKeywordList = [] // 검색어 연관 태그 리스트

    this.tagMsearchedMixtapeListixtapeList = []
    this.tagMixtapeList = []

    this.searchedCastList = []
  }

  @action.bound
  async initClient() {}

  /* 오디오 검색 */
  @action.bound
  async fetchSearchSongs(search, startIndex = 0) {
    this.songResults.clear()
    return this.store.useLoading(async () => {
      this.addAudioSearchHistory(search)
      const searchSongList = await this.network.searchNetwork.getSearchSongs({
        search,
        startIndex,
      })
      if (!searchSongList) return

      this.songResults.splice(
        startIndex,
        this.songResults.length - startIndex,
        ...searchSongList.map(elem => new SongModel(this.store, elem)),
      )
      return searchSongList
    })
  }

  @action.bound
  addAudioSearchHistory(searchText) {
    if (searchText !== '') {
      const index = this.audioSearchHistory.findIndex(
        item => item === searchText,
      )
      if (index > -1) {
        this.audioSearchHistory.splice(index, 1)
        this.audioSearchHistory.splice(0, 0, searchText)
      }
      else {
        this.audioSearchHistory.splice(0, 0, searchText)
      }
    }
  }

  @action.bound
  deleteAudioSearchHistory(index = -1) {
    if (index < 0) return

    this.audioSearchHistory.splice(index, 1)
  }

  @action.bound
  clearAudioSearchHistory() {
    this.audioSearchHistory = []
  }
  /** ************************** */

  /* 흐름 검색 */
  @action.bound
  setMixtapeSearchKeyword = value => {
    this.mixtapeSearchKeyword = value
  }

  @action.bound
  addSearchHistory(searchText) {
    if (searchText !== '') {
      const index = this.searchHistory.findIndex(item => item === searchText)
      if (index > -1) {
        this.searchHistory.splice(index, 1)
        this.searchHistory.splice(0, 0, searchText)
      }
      else {
        this.searchHistory.splice(0, 0, searchText)
      }
    }
  }

  @action.bound
  addMixtapeSearchHistory(searchText) {
    if (searchText !== '') {
      const index = this.mixtapeSearchHistory.findIndex(
        item => item === searchText,
      )
      if (index > -1) {
        this.mixtapeSearchHistory.splice(index, 1)
        this.mixtapeSearchHistory.splice(0, 0, searchText)
      }
      else {
        this.mixtapeSearchHistory.splice(0, 0, searchText)
      }
    }
  }

  @action.bound
  deleteSearchHistory(index = -1) {
    if (index < 0) return

    this.searchHistory.splice(index, 1)
  }

  @action.bound
  deleteMixtapeSearchHistory(index = -1) {
    if (index < 0) return

    this.mixtapeSearchHistory.splice(index, 1)
  }

  @action.bound
  clearSearchHistory() {
    this.searchHistory = []
  }

  @action.bound
  clearMixtapeSearchHistory() {
    this.mixtapeSearchHistory = []
  }
  /** ************************** */

  /* 흐름 태그 검색 */
  @action.bound
  searchTagKeyword = async value => {
    this.mixtapeTagKeyword = await value
  }

  /* 검색어 관련 태그 리스트 */
  @action.bound
  fetchTagKeywordList = async (startIndex = 0) => {
    // return this.store.useLoading(async () => {
    if (!this.mixtapeSearchKeyword) return
    const tagResults = await this.network.searchNetwork.getSearchTag({
      search: this.mixtapeSearchKeyword,
      startIndex,
      limit: 5,
    })

    this.searchTagKeywordList = tagResults.filter(elem => !!elem)
    // })
  }

  /* 흐름 검색 예상 검색어 */
  @action.bound
  fetchAutoCompleteKeywordList = async search => {
    if (!search) return
    this.autoCompleteKeywordList = await []
    const mixtapeResults = await this.network.searchNetwork.getSearchMixtapes({
      search,
      limit: 5,
    })

    const tagResults = await this.network.searchNetwork.getSearchTag({
      search,
      limit: 5,
    })

    const _autoCompleteKeywordList = mixtapeResults
      .filter(elem => !!elem)
      .map(elem => elem.name)
      .concat(tagResults.filter(elem => !!elem).map(elem => elem.keyword))

    // 중복 키워드 제거
    this.autoCompleteKeywordList = _autoCompleteKeywordList.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    )
    return !!this.autoCompleteKeywordList
  }

  @action.bound
  fetchAutoCompleteKeywordCastList = async search => {
    if (!search) return
    this.autoCompleteKeywordList = await []
    const castResults = await this.network.searchNetwork.getSearchsCasts({
      search,
      limit: 5,
    })

    const tagResults = await this.network.searchNetwork.getSearchTag({
      search,
      limit: 5,
    })

    const _autoCompleteKeywordList = castResults
      .filter(elem => !!elem)
      .map(elem => elem.title)
      .concat(tagResults.filter(elem => !!elem).map(elem => elem.keyword))

    // 중복 키워드 제거
    this.autoCompleteKeywordList = _autoCompleteKeywordList.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    )
    return !!this.autoCompleteKeywordList
  }

  // 검색한 흐름 리스트
  // mixtapeStore가 맞는지...
  @action.bound
  fetchSearchMixtapeList = async (search, option) => {
    const limit = option && option.limit ? option.limit : 20 // 기본 4개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    if (!search) return
    this.autoCompleteKeywordList = await []
    const _searchedMixtapeList = await this.network.searchNetwork.getSearchMixtapes(
      {
        search,
        limit,
        startIndex: offset,
      },
    )

    if (offset === 0) {
      this.searchedMixtapeList = _searchedMixtapeList
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))
    }
    else {
      this.searchedMixtapeList = this.searchedMixtapeList.concat(
        _searchedMixtapeList
          .filter(elem => !!elem)
          .map(elem => new MixtapeModel(this.store, elem)),
      )
    }

    return _searchedMixtapeList.length > limit
  }

  // 태그로 검색한 최신 흐름 리스트
  // mixtapeStore가 맞는지...
  @action.bound
  fetchTagLatestMixtapeList = async (tag, option) => {
    if (!tag) return
    this.mixtapeSearchKeyword = tag
    const limit = option && option.limit ? option.limit : 20 // 기본 4개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    if (!tag) return
    this.autoCompleteKeywordList = await []
    const res = await this.network.searchNetwork.getSearchTagsMixtapesLatest({
      keyword: tag,
      limit,
      startIndex: offset,
    })

    if (offset === 0) {
      this.tagMixtapeList = res
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))
    }
    else {
      this.tagMixtapeList = this.tagMixtapeList.concat(
        res
          .filter(elem => !!elem)
          .map(elem => new MixtapeModel(this.store, elem)),
      )
    }

    return res.length === limit
  }

  // 태그로 검색한 흐름 리스트
  // mixtapeStore가 맞는지...
  // popular offset 로직 반영
  @action.bound
  fetchTagPopulartMixtapeList = async (tag, option) => {
    if (!tag) return
    this.mixtapeSearchKeyword = tag
    const limit = option && option.limit ? option.limit : 20 // 기본 4개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    let lastFetchedId = 0

    if (offset) {
      lastFetchedId =
        this.tagMixtapeList.slice().length > 0
          ? this.tagMixtapeList.slice()[this.tagMixtapeList.slice().length - 1]
            .id
          : -1
    }

    if (!tag) return
    this.autoCompleteKeywordList = await []
    const res = await this.network.searchNetwork.getSearchTagsMixtapesPopular({
      keyword: tag,
      limit,
      lastFetchedId,
    })

    if (offset === 0) {
      this.tagMixtapeList = res
        .filter(elem => !!elem)
        .map(elem => new MixtapeModel(this.store, elem))
    }
    else {
      this.tagMixtapeList = this.tagMixtapeList.concat(
        res
          .filter(elem => !!elem)
          .map(elem => new MixtapeModel(this.store, elem)),
      )
    }

    return res.length === limit
  }

  fetchSearchCastList = async (search, option) => {
    const limit = option && option.limit ? option.limit : 20 // 기본 4개
    const offset = option && option.offset ? option.offset : 0 // 기본 0

    if (!search) return
    this.autoCompleteKeywordList = await []
    const _searchedCastList = await this.network.searchNetwork.getSearchsCasts({
      search,
      limit,
      startIndex: offset,
      __order: 'publishedAtDesc',
    })

    if (offset === 0) {
      this.searchedCastList = _searchedCastList
        .filter(elem => !!elem)
        .map(elem => new CastModel(this.store, elem))
    }
    else {
      this.searchedCastList = this.searchedCastList.concat(
        _searchedCastList
          .filter(elem => !!elem)
          .map(elem => new CastModel(this.store, elem)),
      )
    }

    return _searchedCastList.length > limit
  }

  @action.bound
  setGlobalKeyword = value => {
    this.globalKeyword = value
  }
  @action.bound
  setGlobalInputKeyword = value => {
    this.globalInputKeyword = value
  }

  // 글로벌 검색 ACTION
  @action.bound
  async fetchSearchUserList(search, startIndex = 0, limit = 4) {
    const searchUserList = await this.network.searchNetwork.getSearchsSalonUsers(
      {
        keyword: search,
        startIndex,
        limit,
      },
    )
    if (!searchUserList) return

    const userList = searchUserList.map(user => new UserModel(this.store, user))

    return userList
  }

  @action.bound
  async fetchSearchHouseList(search, startIndex = 0, limit = 4) {
    const searchHouseList = await this.network.searchNetwork.getSearchsHouses({
      keyword: search,
      startIndex,
      limit,
    })
    if (!searchHouseList) return

    const houseList = searchHouseList.map(
      house => new HouseModel(this.store, house),
    )

    return houseList
  }

  @action.bound
  async fetchSearchSalonList(search, startIndex = 0, limit = 4) {
    const searchSalonList = await this.network.searchNetwork.getSearchsSalons({
      keyword: search,
      startIndex,
      limit,
    })
    if (!searchSalonList) return

    const salonList = searchSalonList.map(
      liveRoom => new LiveRoomModel(this.store, liveRoom),
    )

    return salonList
  }

  @action.bound
  async fetchSearchCastsList(search, startIndex = 0, limit = 4) {
    const searchCastList = await this.network.searchNetwork.getSearchsCasts({
      search,
      startIndex,
      limit,
    })
    if (!searchCastList) return

    const castList = searchCastList.map(cast => new CastModel(this.store, cast))

    return castList
  }

  @action.bound
  async fetchSearchMitxtapesList(search, startIndex = 0, limit = 4) {
    const searchMixtapeList = await this.network.searchNetwork.getSearchMixtapes(
      {
        search,
        startIndex,
        limit,
      },
    )
    if (!searchMixtapeList) return

    const mixtapeList = searchMixtapeList.map(
      mixtape => new MixtapeModel(this.store, mixtape),
    )

    return mixtapeList
  }
}
