import React from 'react'
import './Popup.css'
import styled, { keyframes } from 'styled-components'
import { Caption2 } from '@components/atoms'
import ReactDOM from 'react-dom'

const boxFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const AnimatioWrapper = styled.div`
  animation-name: ${boxFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 9999;

  position: fixed;
  top: 62px;
  left: 45%;
`

const PopupInner = styled.div`
  width: 197px;
  height: 42px;
  background: rgba(36, 36, 36);
  border-radius: 6px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
`

const CopiedPopup = () => {
  return ReactDOM.createPortal(
    <AnimatioWrapper>
      <PopupInner>
        <Caption2 type="Medium">링크가 복사되었습니다.</Caption2>
      </PopupInner>
    </AnimatioWrapper>,
    document.body,
  )
}

export default CopiedPopup
