import {
  Caption1,
  Caption2,
  Caption3,
  Flex,
  ProfileImg,
} from '@components/atoms'
import { MIXTAPES_HAVE_TAGS_LEVEL } from '@consts/'
import { images } from '@images/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { converseUnitEng } from '@utils/format'
import { timeConvert } from '@utils/time'
import React from 'react'

const SalonMixtapeItem = ({ item, selected, onSelect }) => {
  const tagList =
    item.MixtapesHaveTags
    && item.MixtapesHaveTags.filter(
      val => val.level === MIXTAPES_HAVE_TAGS_LEVEL['GENRE_TAG'],
    )
      .map(val => `${(val.Tag && `#${val.Tag.keyword}`) || ''}`)
      .slice(0, 3)

  return (
    <Flex
      onClick={() => onSelect(item)}
      align="center"
      style={
        {
          width: '100%',
          height: 160,
          borderBottom: `solid 1px ${colors.white_f4}`,
          background: selected ? colors.white_f4 : 'transparent',
          padding: '0 26px',
          cursor: 'pointer',
        }
      }
    >
      <Flex style={{ width: '100%', height: 112 }}>
        <Flex
          style={
            {
              maxWidth: 112,
              width: '100%',
              marginRight: 12,
              position: 'relative',
              borderRadius: 4,
            }
          }
        >
          {
            selected && (
              <>
                <div
                  style={
                    {
                      background: selected ? colors.black_24 : 'transparent',
                      opacity: selected ? 0.64 : 1,
                      width: '100%',
                      height: '100%',
                      borderRadius: 4,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }
                  }
                />
                <img
                  src={images.check_white_img}
                  style={
                    {
                      width: 32,
                      height: 32,
                      position: 'absolute',
                      left: '40px',
                      top: '40px',
                    }
                  }
                  alt="check"
                />
              </>
            )
          }
          <img
            src={item?.imageUrl || images.default_song_img}
            style={
              {
                width: '100%',
                height: 112,
                borderRadius: 4,
                objectFit: 'cover',
              }
            }
            alt="mixtape_img"
          />
        </Flex>
        <Flex type="column" style={{ width: '100%' }}>
          <Caption1 align="left" style={oneEllipsisStyle}>
            {item?.name}
          </Caption1>
          <Caption2
            align="left"
            style={{ ...oneEllipsisStyle, margin: '4px 0' }}
          >
            {item?.description}
          </Caption2>
          <Caption3 align="left">
            {/* 흐름의 현재 재생 중인 곡 서버 미개발 */}
            {/* {
              `${item?.MixtapesHaveSongs
              && item.MixtapesHaveSongs[item.songPlayedIndex]?.Song?.singer} -
            ${item?.MixtapesHaveSongs
              && item.MixtapesHaveSongs[item.songPlayedIndex]?.Song?.name}`
            } */}
          </Caption3>
          <Flex
            style={
              {
                width: '100%',
                justifyContent: 'space-between',
                margin: '8px 0 12px 0',
              }
            }
          >
            <Flex align="center">
              <ProfileImg
                style={
                  {
                    width: 20,
                    height: 20,
                    marginRight: 8,
                    borderRadius: '50%',
                  }
                }
                src={item?.User?.imageUri}
                profileColor={item?.User?.profileColor}
              />
              <Caption3 align="left" style={oneEllipsisStyle}>
                {item?.User?.name}
              </Caption3>
            </Flex>
            <Flex align="center">
              <img
                src={images.heart_img_red_12}
                alt="heart_img"
                style={{ width: 10, height: 10, marginRight: 4 }}
              />
              <Caption3 style={{ marginRight: 8, lineHeight: '10px' }}>
                {
                  `${converseUnitEng(
                    parseInt((!!item.likeCount && item.likeCount) || 0),
                  )}`
                }
              </Caption3>
              <img
                src={images.play_black_img}
                alt="heart_img"
                style={{ width: 10, height: 10, marginRight: 4 }}
              />
              <Caption3 style={{ lineHeight: '10px' }}>
                {
                  `${converseUnitEng(
                    parseInt((!!item.playCount && item.playCount) || 0),
                  )}`
                }
              </Caption3>
            </Flex>
          </Flex>
          <Flex style={{ width: '100%', justifyContent: 'space-between' }}>
            <Caption3 type="Regular" color={colors.grey_94}>
              {tagList?.join(' ')}
            </Caption3>
            <Caption3 type="Regular" color={colors.grey_94}>
              {timeConvert(item?.publishedAt)}
            </Caption3>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalonMixtapeItem
