import React from 'react'
import { BodyWrapper, LogoImg, Flex, H5, H6, Caption1 } from '@components/atoms'
import { CheckTextBox, SubmitBtn } from '@components/molecules'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { colors } from '@resources/colors'

const AgreeBox = styled(Flex)`
  flex-direction: column;
  margin: 15px 0;
`

const PolicContainer = styled.div`
  max-width: 518px;
  height: 112px;
  border-radius: 7px;
  border: solid 1px #949494;

  overflow: auto;
  padding: 12px;

  @media ${mobile} {
    max-width: 100%;
    height: 64px;
  }
`

const PolicyTitle = styled.h1`
  font-family: NotoSansCJKkr-Bold;
  font-size: 14px;
  margin-bottom: 10px;
`

const PolicyItemPointTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 12px;
  margin: 6px 0;
`

const PolicyItemTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 12px;
  margin: 6px 0;
`

const PolicyItemDescription = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 12px;
  color: #242424;
`

const PolicyItemSubBlock = styled.div`
  margin: 6px;
`

const AccountAgreeTemplate = ({
  isAgreeMarketing,
  setIsAgreeMarketing,
  setIsStep,
  termsAgree,
  setTermsAgree,
  privateAgree,
  setPrivateAgree,
  isKakao,
}) => {
  return (
    <BodyWrapper>
      <LogoImg />
      <Flex type="column">
        <Flex type="column" style={{ marginBottom: '15px' }}>
          <CheckTextBox
            text={<H5>전체동의</H5>}
            style={{ justifyContent: 'left', marginBottom: '8px' }}
            checked={termsAgree && privateAgree && isAgreeMarketing}
            onClick={() => {
              if (!termsAgree && !privateAgree && !isAgreeMarketing) {
                setTermsAgree(true)
                setPrivateAgree(true)
                setIsAgreeMarketing(true)
              } else {
                setTermsAgree(false)
                setPrivateAgree(false)
                setIsAgreeMarketing(false)
              }
            }}
          />
          <Caption1 color="#949494" align="left" style={{ marginLeft: '32px' }}>
            이용약관 동의 (필수), 개인정보 수집이용 동의 (필수), 마케팅 정보
            수신 동의 (선택)
          </Caption1>
        </Flex>
        <AgreeBox>
          <CheckTextBox
            text={
              <Flex>
                <H6 color="#646464">이용약관 동의</H6>
                <H6 color={colors.secondary}>(필수)</H6>
              </Flex>
            }
            style={{ justifyContent: 'left', marginBottom: '16px' }}
            checked={termsAgree}
            onClick={() => setTermsAgree(!termsAgree)}
          />
          <PolicContainer>
            <PolicyItemDescription>
              흐름(HREUM de salon)은 정보통신망 이용촉진 및 정보보호 등에
              개인정보처리방침을 정하여 고객 권익 보호에 최선을 다하고 있습니다.
              <br />이 개인정보처리방침은 흐름(HREUM de salon)에서
              수집하는 정보의 유형과 수집 방법, 정보를 수집하는 이유, 개인정보
              보호 설정 등에 대한 이해를 돕기 위한 것입니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              본 개인정보처리방침에서 사용하는 주요한 용어의 정의는 다음과
              같습니다.
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 서비스: 흐름(HREUM de salon) 솔루션이 제공하는 인터넷 상의
              모든 서비스
              <br />
              • 회원: 솔루션과 서비스 이용 계약을 체결하고 아이디를 부여 받아
              솔루션이 제공하는 서비스를 이용하는 자<br />
              • 회원등급: 솔루션이 제공하는 서비스에 대해 특정 기능 접근 여부
              등의 서비스 이용 경험에서 차이를 갖고 있는 솔루션 내의 회원 분류
              체계
              <br />• 콘텐츠: 예를 들면 오디오와 플레이리스트(이하 ‘흐름’)처럼
              서비스를 이용한 자가 서비스 상으로 보거나, 서비스를 통하여
              접속하거나 또는 서비스를 통하여 기고할 수 있는 텍스트, 소프트웨어,
              스크립트, 그래픽, 사진, 이미지 파일, 사운드, 음악, 비디오, 선곡
              리스트, 시청각 혼합, 상호작용 내용과 기타 자료
            </PolicyItemDescription>

            <PolicyItemTitle>발효일: 2019년 11월 07일</PolicyItemTitle>
            <PolicyItemTitle>
              흐름(HREUM de salon)의 정책이 적용되는 형태는 아래와
              같습니다.
            </PolicyItemTitle>

            <PolicyItemDescription>
              HREUM de salon WEB, HREUM de salon APP(Android, iOS), 사이트, 기기
              <br />이 개인정보처리방침에 관해 궁금한 사항이 있으면 문의하세요.
            </PolicyItemDescription>

            <PolicyItemTitle>
              HREUM de salon에서 수집하는 정보의 유형
            </PolicyItemTitle>

            <PolicyItemDescription>
              HREUM de salon은 모든 회원에게 더 나은 서비스를 제공하기 위해
              회원의 언어와 같은 기본적인 정보와 회원이 가장 유용하다고 생각할
              콘텐츠 등과 같은 복합적인 정보를 수집합니다. 수집하는 정보와 그
              정보가 이용되는 방식은 사용자가 서비스를 어떻게 이용하고 개인정보
              보호 설정을 어떻게 관리하는지에 따라 다릅니다.
            </PolicyItemDescription>

            <PolicyItemTitle>회원이 생성하거나 제공하는 정보</PolicyItemTitle>

            <PolicyItemDescription>
              • 필수 제공 정보
              <br />
              회원은 계정을 만들 때 성명, 아이디, 이메일 주소, 휴대폰연락처,
              비밀번호 성별, 생년월일 등을 포함한 개인정보를 HREUM de salon에
              제공합니다.
              <br />
              • 선택 제공 정보
              <br />
              계정에 성별, 프로필 이미지, 생년월일, 닉네임, 주소, 소속, 직위
              등을 추가하도록 선택할 수 있습니다. 계정에 로그인하지 않더라도,
              서비스 업데이트를 받기 위해 이메일 주소를 제공하는 것과 같이
              정보를 제공하도록 선택할 수 있습니다.
              <br />
              • 직접 업로드, 생성한 콘텐츠 정보
              <br />
              HREUM de salon은 회원이 서비스를 이용하면서 생성, 업로드하거나
              다른 사람에게 받는 콘텐츠를 수집합니다. 여기에는 회원이 저장하는
              오디오, 이미지, 댓글 등이 포함 됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              회원이 서비스를 이용할 때 HREUM de salon이 수집하는 정보
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 회원의 활동
              <br />
              서비스 상의 회원 활동 정보를 수집하여 좋아할 만한 오디오,
              플라 등의 콘텐츠를 추천하는 목적으로
              활용합니다. 회원은 HREUM de salon 계정에 저장된 활동 정보를 확인하
              고 관리할 수 있습니다. 수집하는 활동 정보에는 다음이 포함될 수
              있습니다. 검색하는 단어, 멤버룸과 개인 DM에서의 텍스트 정보, 오디오 기능을 사용할 때 음성 및 오디오
              정보, 회원이 교류하거나 콘텐츠 를 공유하는 사람들, 어플리케이션
              사용 기록.
              <br />
              • 회원의 앱, 브라우저, 기기
              <br />
              회원이 HREUM de salon 서비스에 액세스할 때 사용하는 앱, 브라우저,
              기기에 대한 정보를 수집합니다. 이 정보를 이용하여 자동 업데이트
              등을 제공할 수 있습니다. 수집하 는 정보에는 고유 식별자, 브라우저
              유형 및 설정, 기기 유형 및 설정, 운영체제, 통신사명과 전화번호를
              포함한 모바일 네트워크 정보, 애플리케이션 버전 번호가 포함 될 수
              있습니다. 또한 IP주소, 비정상 종료 보고서, 시스템 활동, 요청
              날짜와 시간, 리퍼러 URL 등 사용자의 앱, 브라우저, 기기와 서비스의
              HREUM de salon 상호작용 등에 대 한 정보를 수집할 수 있습니다.
              <br />
              • 회원의 상태 정보 (위치, 기상, 온도 등)
              <br />
              서비스를 이용할 때 사용자 위치에 관한 정보를 수집하여 장소나 날씨.
              온도에 따른 오디오. 플라 등을 추천하는 기능
              등을 제공할 수 있습니다. HREUM de salon에서 수집하는 위치 데이터의
              유형은 기기 및 계정 설정에 따라 부분적으로 달라집니다. 위치
              정확도는 다음과 같은 여러 요인에 의해 결정됩니다. GPS, IP 주소,
              기기의 센서 데이터, Wi-Fi 액세스 포인트, 기지국, 블루투스 지원
              기기 등 사용자의 기기 주변 사물에 대한 정보.
              <br />
              • 업무 처리 과정에서 자동 생성, 추가 수집되는 정보
              <br />
              서비스 이용과정이나 서비스 제공 업무 처리 과정에서 다음과 같은
              정보들이 자동으로 생성되거나 추가로 수집될 수 있습니다. IP 주소,
              쿠키, 접속로그, 사용 일시, 서비스 이용 기록, 불량 이용 기록, 결제
              기록.
              <br />• 상세한 위치정보 이용에 대한 내용은 '흐름(HREUM de
              salon)' 위치정보 이용약관'을 참고한다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              특정 서비스의 이용 시 추가적으로 수집되는 정보
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 회원이 HREUM de salon에 일반 문의를 남기거나 알림(통보) 대상
              추가 및 회원등급 변경 시에 개인정보 수집에 대한 별도 동의를 구한
              후 성명, 이메일 주소, 휴대폰 번호 등의 추가적인 정보가 수집될 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>개인정보 수집 방법</PolicyItemTitle>
            <PolicyItemTitle>
              HREUM de salon은 다음과 같은 방법으로 개인정보를 수집합니다.
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 어플리케이션, 홈페이지 수집, 서면, 게시판, 이메일, 오프라인
              수집(이벤트 응모, 세미나 참석 등<br />
              • 로그 분석 프로그램을 통한 생성정보 수집
              <br />• ‘쿠키(cookie)’에 의한 정보 수집
            </PolicyItemDescription>

            <PolicyItemTitle>정보를 수집하는 이유 및 목적</PolicyItemTitle>

            <PolicyItemDescription>
              수집된 정보를 사용하여 더 나은 서비스를 구축합니다. HREUM de
              salon은 회원들의 개인정보를 다음에서 고지하는 범위 내에서
              사용하며, 고객의 사전 동의 없이는 동 범위 를 초과하여 이용하거나
              원칙적으로 회원의 개인정보를 외부에 공개하지 않습니다. 고객들이
              사전에 동의한 경우, 법령의 규정에 의거하거나, 수사 목적으로 법령에
              정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우에는 예외로
              합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>서비스 제공</PolicyItemTitle>

            <PolicyItemDescription>
              • 회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의
              콘텐츠 공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>서비스 유지 및 개선</PolicyItemTitle>

            <PolicyItemDescription>
              • 문제 발생 시 원인을 추적하거나 회원이 신고하는 문제를 해결하는
              등 서비스 정상 운영을 위해 회원 정보를 이용합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>새 서비스 개발</PolicyItemTitle>

            <PolicyItemDescription>
              • 기존 서비스에서 수집하는 정보를 이용하여 새 서비스를 개발합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>맞춤 서비스 제공</PolicyItemTitle>

            <PolicyItemDescription>
              • 수집하는 정보를 이용하여 추천, 맞춤 콘텐츠 및 검색결과를
              제공하는 등 사용자를 위해 서비스를 맞춤 설정합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>실적 측정</PolicyItemTitle>

            <PolicyItemDescription>
              • HREUM de salon 서비스가 어떻게 이용되는지 분석하고 측정하는데
              데이터를 이용합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>사용자와 커뮤니케이션</PolicyItemTitle>

            <PolicyItemDescription>
              • 이메일 주소 등 수집 정보를 이용하여 회원과 직접 상호 작용합니다.
              이메일 주소와 같은 정보는 불만처리 등의 민원을 처리, 이벤트 및
              광고성 정보 제공 및 참여기회 제공 등을 위한 소통 창구로
              이용됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              HREUM de salon과 회원, 그리고 대중을 보호
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 서비스의 안정성과 신뢰성을 개선하기 위해 정보를 이용합니다.
              여기에는 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인,
              개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사
              확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁
              조정을 위한 기록보존, 고지사항 전달 등이 포함됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>개인정보 보호 설정</PolicyItemTitle>

            <PolicyItemDescription>
              HREUM de salon 서비스 내에서 HREUM de salon에게 공유되는 정보와
              다른 회원에게 표시되는 내 정보를 관리할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              개인 정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
            </PolicyItemTitle>

            <PolicyItemDescription>
              HREUM de salon은 쿠키를 통해 회원들의 이용형태, 인기 검색어, 고객
              규모 등을 파악합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가
              회원의 브라우저에게 보내는 아주 작은 텍스트 파일로 회원 컴퓨터의
              하드디스크에 저장됩니다. 이후 회원이 서비스를 활용할 때 하드
              디스크에 저장되어 있는 쿠키의 내용을 읽어 회원의 환경설 정을
              유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다. 쿠키는 개인을
              식별하는 정보를 자동적/능동적으로 수집하지 않으며, 회원은 언제든지
              이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 회원은
              웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
              저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
              수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 일부 서비스는
              이용에 어려움이 있을 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>정보 관리 및 업데이트</PolicyItemTitle>

            <PolicyItemDescription>
              • 회원 정보 관리
              <br />
              회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 수정할
              수 있습니다. 회원의 개인정보에 대한 열람 또는 수정을 하고자 할
              경우에는 솔루션의 서비스 종류에 따른 회원정보 수정에 해당하는
              기능을 통해 본인 확인 절차를 거친 후 직접 열람 또는 수정하거나,
              개인정보 관련 담당자에게 이메일로 연락하면 지체 없이 수 정할 수
              있습니다. 만일 회원의 대리인이 방문하여 열람 또는 정정을 요구하는
              경우에는 회원의 진정한 대리인인지 여부를 확인하기위해, 대리관계를
              나타내는 증표 를 제시하도록 요구할 수 있습니다. 회원이 개인정보의
              오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지
              개인정보를 이용 또는 제공하지 않습니다. 또한 프로필 이미지, 닉네임
              등 선택 정보 를 추가 기입할 수 있습니다.
              <br />
              • 활동 기록 관리
              <br />
              회원은 검색 기록, 청취 기록 등의 활동 내용을 관리할 수 있습니다.
              활동의 일부 또는 전부를 삭제할 수 있습니다.
              <br />
              • 업로드 콘텐츠에 관한 정보 관리
              <br />
              HREUM de salon 서비스에서 회원은 다른 사람들과 콘텐츠를 공유할 수
              있고 공유 방식을 제어할 수 있습니다. 예를 들어 업로드한
              플라(PLRA)을 공개적으로 공유하거나 비공개로 유지할 수 있습니다.
              공개적으로 정보를 공유할 경우, HREUM de salon 검색을 비롯한
              검색엔진을 통해 해당 콘텐츠에 액세스가 가능해질 수 있다는 점을
              유념하시기 바랍니 다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              개인정보의 보유, 이용기간 및 파기 절차
            </PolicyItemTitle>

            <PolicyItemDescription>
              회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
              달성되면 지체 없이 파기합니다. 단, 다음과 같이 데이터의 유형에
              따라 보관되는 기간이 다릅니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              사용자가 삭제할 때까지 보관되는 정보
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 회원 가입 시 기입하는 필수 개인 정보
              <br />
              • 회원의 활동 내역
              <br />
              • 회원이 업로드한 콘텐츠
              <br />• 회원의 계정
            </PolicyItemDescription>

            <PolicyItemTitle>
              특정 기간이 지나면 만료되는 데이터
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 회원이 검색하는 단어를 처리하여 결과를 제공하거나 다른 회원과의
              콘텐츠 공유를 돕는 등의 서비스 제공을 위해 회원 정보를 이용합니다.
              <br />
              • 회원이 멤버룸, 개인DM을 통해 나누는 텍스트는 2년 동안 서버에 보유한 후, 회사는 회원의 개인정보 보호를 위해 파기합니다.
              <br />
              • 녹음된 오디오는 2년 동안 서버에 보유한 후, 회사는 회원의 개인정보 보호를 위해 파기합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              계정을 삭제할 때까지 보관되는 정보
            </PolicyItemTitle>

            <PolicyItemDescription>
              • 회원이 HREUM de salon이 제공하는 기능과 어떻게 상호작용하는지,
              어떻게 하면 HREUM de salon 서비스를 개선할 수 있을지 이해하는 데
              도움이 되는 일부 데이터는 회원이 계정을 삭제할 때까지 보관됩니다.
            </PolicyItemDescription>

            <PolicyItemDescription>
              회원이 HREUM de salon 계정에 저장된 데이터를 삭제하면 HREUM은 해당
              데이터를 제거하는 절차를 즉시 시작합니다. 우선 정보가 더 이상
              표시되지 않도록 즉시 삭제하는 것을 목표로 하며, 이 정보는 더 이상
              사용자의 HREUM de salon 환경을 맞춤설정하는 데 사용되지 않습니다.
              이후 HREUM de salon 보관 시스템에서 데이터를 안전하고 완전하게
              삭제합니다. 종이에 출력된 정보의 경우 분쇄기로 분쇄하며 전자적
              파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
              방법으로 삭제합니다. 다만, 이용약관에서 기술한 약관에 따라 탈퇴 후
              1년간 저장해야하는 이유에 필요한 개인정보 요소들과 관계법령의
              규정에 의하여 보존할 필요가 있는 데이터는 지정된 기간 동안 회원
              정보를 보관합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>정보 보안 유지</PolicyItemTitle>

            <PolicyItemDescription>
              HREUM de salon은 회원 정보를 지속적으로 보호하는 보안 기능이
              구축되어 있습니다. 서비스를 유지하면서 얻는 유용한 정보는 보안
              위협을 감지하고 자동으로 차단하는 데 도움이 됩니다. 회원이 알아야
              한다고 생각되는 위험 요소를 감지하면 사용자에게 알리고 보호 기능을
              강화할 수 있는 단계를 안내합니다.
            </PolicyItemDescription>

            <PolicyItemDescription>
              다음과 같은 조치를 통해 정보 무단 액세스, 변경, 공개, 파기로부터
              사용자와 HREUM de salon을 보호하기 위해 노력하고 있습니다.
            </PolicyItemDescription>

            <PolicyItemDescription>
              • 암호화를 사용하여 전송 중에 데이터를 비공개로 유지합니다.
              <br />
              • 여러 단계의 인증, 내부 보안 진단 등 다양한 보안 기능을 통하여
              계정 보호를 지원합니다.
              <br />
              • 시스템 무단 액세스를 방지하기 위해 물리적 보안 조치를 포함하여
              HREUM de salon의 정보 수집, 저장 및 처리 관행을 검토합니다.
              <br />• 개인정보 액세스 권한을 개인정보를 처리하기 위해 액세스가
              필요한 HREUM de salon 직원 및 대리인으로 제한됩니다. 액세스 권한을
              가진 사람은 계약을 통해 엄격한 기밀 유지의 의무를 갖게 되며 이러한
              의무를 어길 경우 제재를 받거나 계약이 해지될 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>방침 정보</PolicyItemTitle>

            <PolicyItemDescription>
              이 개인정보처리방침은 HREUM de salon 솔루션 내에서 적용됩니다.
              다음 항목에는 이 개인정보처리방침이 적용되지 않습니다.
            </PolicyItemDescription>

            <PolicyItemDescription>
              • HREUM de salon 솔루션을 광고하는 다른 회사 및 조직의 정보 관행
              <br />• 타사 또는 개인이 제공하는 서비스
            </PolicyItemDescription>

            <PolicyItemDescription>
              HREUM de salon 은 이 개인정보처리방침을 수시로 변경할 수 있습니다.
              HREUM de salon 은 회원의 명시적인 동의 없이 이 개인정보처리방침에
              설명된 회원의 권한을 축소하지 않습 니다. 항상 마지막 변경 사항이
              게시된 날짜를 표시하고 회원이 검토를 위해 보관 처리된 버전에
              액세스할 수 있도록 합니다. 변경 사항이 중대할 경우에는 개인정보처
              리방침과 관련한 변경 고지 이메일을 발송하는 등 적극적으로
              알립니다.
            </PolicyItemDescription>
          </PolicContainer>
          <PolicContainer style={{ marginTop:'15px' }}>
            <PolicyTitle>
              흐름(HREUM de salon) 위치정보 이용약관
            </PolicyTitle>

            <PolicyItemTitle>제1조 (목적)</PolicyItemTitle>

            <PolicyItemDescription>
              이 약관은 흐름(HREUM de salon) (이하 “회사”)이 제공하는
              위치정보사업 또는 위치기반서비스사업과 관련하여 회사와
              개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을
              규정함을 목적으로 합니다
            </PolicyItemDescription>

            <PolicyItemTitle>제2조 (약관 외 준칙)</PolicyItemTitle>

            <PolicyItemDescription>
              이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한
              법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
              전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및
              개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제3조 (서비스 내용 및 요금)</PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 직접 위치정보를 수집하거나 위치정보사업자로부터
              위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.
              <br />
              ① 날씨 기반 플라 추천 서비스: 사용자의
              위치에 따른 날씨 정보를 제공받아, 날씨 기반 플라 추천을
              제공합니다.
              <br />
              ② 피드 추천: 서비스 내 이미지와 텍스트를 융합한 게시물인 피드에
              위치 정보를 입력할 수 있고, 이를 통해 위치 검색 혹은 사용자의 현재
              위치에 따른 피드를 추천할 수 있습니다.
              <br />
              ③ 유저 추천: 검색 혹은 사용자의 현재 위치에 따라 해당 위치와
              인접해있는 유저를 추천할 수 있습니다.
              <br />
              ④ 살롱 추천: 검색 혹은 사용자의 현재 위치에 따라 살롱을 추천할 수
              있습니다.
              <br />
              2. 제1항 위치기반서비스의 이용요금은 무료입니다
            </PolicyItemDescription>

            <PolicyItemTitle>제4조 (개인위치정보주체의 권리)</PolicyItemTitle>

            <PolicyItemDescription>
              1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중
              일부 또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및
              위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
              <br />
              2. 개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한
              동의의 전부 또는 일부를 철회할 수 있습니다.
              <br />
              3. 개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의
              일시적인 중지를 요구할 수 있습니다. 이 경우 회사는 요구를 거절하지
              아니하며, 이를 위한 기술적 수단을 갖추고 있습니다
              <br />
              4. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를
              요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할
              수 있습니다. 이 경우 회사는 정당한 이유 없이 요구를 거절하지
              아니합니다.
              <br />
              ① 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
              <br />
              ② 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에
              관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및
              내용
              <br />
              5. 회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한
              경우에는 지체 없이 수집된 개인위치정보 및 위치정보
              수집ㆍ이용ㆍ제공사실 확인자료를 파기합니다. 단, 동의의 일부를
              철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보
              수집ㆍ이용ㆍ제공사실 확인자료에 한합니다.
              <br />
              6. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 위하여 이 약관
              제13조의 연락처를 이용하여 회사에 요구할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제5조 (법정대리인의 권리)</PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 만14세 미만 아동으로부터 개인위치정보를 수집ㆍ이용 또는
              제공하고자 하는 경우에는 만14세 미만 아동과 그 법정대리인의 동의를
              받아야 합니다.
              <br />
              2. 법정대리인은 만14세 미만 아동의 개인위치정보를
              수집ㆍ이용ㆍ제공에 동의하는 경우 동의유보권, 동의철회권 및
              일시중지권, 열람ㆍ고지요구권을 행사할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제6조 (위치정보 이용ㆍ제공사실 확인자료 보유근거 및 보유기간)
            </PolicyItemTitle>

            <PolicyItemDescription>
              회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
              근거하여 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실
              확인자료를 위치정보시스템에 자동으로 기록하며, 6개월 이상
              보관합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제7조 (서비스의 변경 및 중지)</PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정
              또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의
              전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.
              <br />
              2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
              공지하거나 개인위치정보주체에게 통지합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제8조 (개인위치정보 제3자 제공시 즉시 통보)
            </PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의
              개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를
              제공하는 경우에는 제공 받는 자 및 제공목적을 사전에
              개인위치정보주체에게 고지하고 동의를 받습니다.
              <br />
              2. 회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게
              제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회
              개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시
              통보합니다.
              <br />
              3. 다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여
              지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
              <br />
              ① 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
              수신 기능을 갖추지 아니한 경우
              <br />② 개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치
              외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한
              경우
            </PolicyItemDescription>

            <PolicyItemTitle>
              제9조 (8세 이하의 아동 등의 보호의무자의 권리)
            </PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라
              한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를
              위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
              동의가 있는 것으로 봅니다.
              <br />
              ① 8세 이하의 아동
              <br />
              ② 장애인복지법제2조제2항제2호의 규정에 의한 정신적 장애를 가진
              자로서 장애인고용촉진및직업재활법 제2조제2호의 규정에 의한
              중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여
              장애인등록을 한 자에 한한다)
              <br />
              ③ 금치산자
              <br />
              2. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여
              개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는
              서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에
              제출하여야 합니다.
              <br />
              3. 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
              동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제10조 (손해배상)</PolicyItemTitle>

            <PolicyItemDescription>
              개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률
              제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에
              대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는
              과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제11조 (분쟁의 조정)</PolicyItemTitle>

            <PolicyItemDescription>
              1. 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와
              협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
              방송통신위원회에 재정을 신청할 수 있습니다.
              <br />
              2. 회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해
              당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는
              개인정보보호법에 따라 개인정보분쟁조정위원회에 조정을 신청할 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제12조 (사업자 정보)</PolicyItemTitle>

            <PolicyItemDescription>
              회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
              <br />
              상호 : 주식회사 나이비
              <br />
              주소 : 서울시 성북구 보문동 5가 173
              <br />
              전화번호 : 1833-6845
            </PolicyItemDescription>

            <PolicyItemTitle>부칙</PolicyItemTitle>

            <PolicyItemDescription>
              제 1 조 시행일
              <br />
              본 약관은 2022년 1월 1일부터 적용됩니다.
              <br />
              <br />
              제 2 조 위치정보관리 책임자 정보
              <br />
              회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이
              서비스 이용과정에서 발생한 민원사항 처리를 비롯하여
              개인위치정보주체의 권리 보호를 위해 힘쓰고 있습니다.
              <br />
              <br />
              위치정보 관리책임자 : 김동현
              <br />
              전화번호 : 1833-6845
            </PolicyItemDescription>
          </PolicContainer>
        </AgreeBox>
        <AgreeBox>
          <CheckTextBox
            text={
              <Flex>
                <H6 color="#646464">개인정보 수집이용 동의</H6>
                <H6 color={colors.secondary}>(필수)</H6>
              </Flex>
            }
            style={{ justifyContent: 'left', marginBottom: '16px' }}
            checked={privateAgree}
            onClick={() => setPrivateAgree(!privateAgree)}
          />
          <PolicContainer>
            <PolicyItemPointTitle>제1장 총 칙</PolicyItemPointTitle>

            <PolicyItemTitle>제1조 (목적)</PolicyItemTitle>
            <PolicyItemDescription>
              본 약관은 대한민국 소재의 주식회사 나이비가 『흐름(HREUM de
              salon)』(이하 “솔루션”)라는 이름으로 제공하는 디지털음성송신
              서비스 및 관리와 관련하여 솔루션과 가입 회원(이하 “회원”)이
              서비스를 활용하는데 합의 해야할 의무, 책임사항 등 필요한 사항을
              규정하고 보장하는데 그 목적이 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제2조 (약관의 효력 및 변경)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 본 약관의 내용은 솔루션이 운영하는 서비스 화면에 게시하거나
              기타의 방법으로 회원에게 공지함으로서 효력이 발생됩니다.
              <br />
              2. 본 약관에 중요한 변경이 있는 경우 그러한 변경내용을 귀하께
              통지하려고 노력하겠지만 귀하도 주기적으로 최신
              버전(https://hreum.me/p/policy/terms/)을 검토하여야 합니다.
              솔루션은 언제든지 독자적인 재량으로 본 약관 및 방침을 수정하거나
              개정할 수 있고, 귀하는 그러한 수정 또는 개정에 구속될 것에
              동의합니다. 본 약관에 포함된 어떠한 내용도 제3자 권리 또는 이익을
              부여하는 것으로 간주되지 아니합니다.
              <br />
              3. 솔루션이 전 항에 따라 개정약관을 공지 또는 통지하면서 회원에게
              약관 변경 적용일까지 의사표시를 하지 않으면 의사표시가 동의된
              것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이
              명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에
              동의한 것으로 봅니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제3조 (용어의 정의)</PolicyItemTitle>
            <PolicyItemDescription>
              본 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              1. 서비스 : 솔루션이 제공하는 인터넷 상의 모든 서비스
              <br />
              2. 이용자 : 중복되지 않는 솔루션이 제공하는 서비스를 받는 회원 및
              비회원을 포함하여 중복되지 않은 월간 서비스를 이용하는
              사용자(유료회원)
              <br />
              3. 청소년 이용자 : 위 정의된 “이용자” 중 민법 제4조에 따라 성년에
              이르지 아니한 만 19세 미만자
              <br />
              4. 콘텐츠: 서비스를 이용한 자가 서비스 상으로 보거나, 서비스를
              통하여 접속하거나 또는 서비스를 통하여 기고할 수 있는 텍스트,
              소프트웨어, 스크립트, 그래픽, 사 진, 이미지 파일, 사운드, 비디오,
              플레이리스트, 시청각 혼합, 상호작용 내용과 기타 자료
              <br />
              5. 회원 : 솔루션과 서비스 이용 계약을 체결하고 아이디를 부여 받아
              솔루션이 제공하는 서비스를 이용하는 자<br />
              6. 디제이(DJ) : 회원 중 심사를 통해 권한을 부여받아 회원이 청취할
              수 있는 플라 (서비스 내의 플레이리스트 채널을 의미하는 웹캐스팅
              단위) 콘텐츠를 제공하는 자.
              <br />
              7. 아이디 : 회원의 식별과 회원의 서비스 이용을 위하여 회원이
              선정하고 솔루션이 승인하는 문자나 숫자 혹은 그 조합을 말합니다.
              <br />
              8. 채널: 회원 가입을 완료한 이용자에게 솔루션이 부여하고 회원의
              정보와 업로드, 공유 및 기타 솔루션에서 제공하는 기능을 통해 이용한
              콘텐츠가 표시되는 공간.
              <br />
              9. 비밀번호 : 회원이 부여 받은 아이디와 일치된 회원임을 확인하고,
              회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의
              조합을 말합니다.
              <br />
              10. 이용 중지 : 솔루션이 약관에 의거하여 회원의 서비스 이용을
              제한하는 것을 말합니다.
              <br />
              11. 해지: 솔루션 또는 회원이 이용 계약을 중지하는 것을 말합니다.
              <br />
              12. 광고: 솔루션을 이용하며 노출될 수 있는 오디오, 디스플레이
              광고를 말합니다.
              <br />
              13. 살롱: 유저들이 오디오와 텍스트로 소통할 수 있는 솔루션 내 공간
            </PolicyItemDescription>

            <PolicyItemPointTitle>제2장 서비스 이용 계약</PolicyItemPointTitle>

            <PolicyItemTitle>제4조 (약관에 대한 승낙)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 이용자는 솔루션이 정한 가입 양식에 따라 회원정보를 기입한 후 본
              약관에 동의한다는 의사표시를 함으로서 회원가입이 완료됩니다.
              <br />
              2. 솔루션 웹사이트 또는 솔루션 어플리케이션에서, 솔루션 웹사이트
              또는 솔루션 어플리케이션을 통하여 귀하에게 제공된 솔루션 제품,
              소프트웨어, 데이터 피드 및 서비스를 이용하거나 방문하면 귀하는 (1)
              본 서비스 약관 (“본 약관), (2) 솔루션의 개인정보 취급방침과, (3)
              솔루션에서 제공하는 커뮤니티 지침(Community Guideline)
              (https://hreum.me/p/policy/terms/) 에 동의한 것으로
              간주됩니다. 만약 귀하가 이러한 약관, 개인정보 취급방침 또는
              커뮤니티 지침에 동의하지 아니 한다면 본 서비스를 이용하지 마시기
              바랍니다. 본 서비스는대한민국 소재의 ㈜나이비가가 제공하고, 한국
              지역에서의 서비스는 ㈜나이비가 운영합니다.
              <br />
              3. 귀하는 귀하가 대한민국 민법상 성년이거나, 미성년이지만
              성인으로서의 권한을 가지거나, 법적인 부모 또는 후견인의 동의를
              받았으며, 본 약관에 규정된 조건, 의 무, 확인, 진술 및 보증에
              합의하고 본 약관을 준수할 수 있는 완전한 권한이 있음을 확인합니다.
              본 서비스는 만 14세 미만의 아동을 대상으로 한 서비스가 아니므로
              어떠한 경우라도 귀하는 귀하가 만 14세 이상임을 확인합니다. 귀하가
              만 14세 미만인 경우에는 본 서비스를 이용하실 수 없습니다. 귀하에게
              적합한 다양한 서비스 가 있으므로, 귀하에게 적절한 서비스에 대하여
              부모님과 상의하시기 바랍니다.
              <br />
              4. 당사는 귀하의 본 서비스 이용에 어떠한 조건이 적용되는지 귀하가
              알 수 있도록 본 서비스와 함께 본 약관을 제공합니다. 귀하는 당사자
              본 약관을 검토할 수 있는 합리적인 기회를 귀하에게 제공하였으며
              귀하가 이에 동의하였음을 인정합니다.
              <br />
              5. 청소년(만 19세 미만인 자)이나 피한정후견인, 피성년후견인이
              서비스를 이용하려면 법정대리인의 동의를 얻어야 합니다. 또한 연령에
              따라 일부 서비스에 대하여 이용이 제한될 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제5조 (개인정보의 보호)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기
              위해 관계법령이 정하는 바와 개인정보 보호정책을 준수합니다.
              솔루션은 개인정보 보호정책 을 솔루션이 운영하는 서비스 상에
              게시합니다.
              <br />
              2. 이용자가 게시판, 메일, 채팅 등 온라인상에서 자발적으로 제공하는
              개인정보는 다른 사람이 수집하여 사용할 가능성이 있으며 이러한
              위험까지 솔루션이 부담하 지는 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제6조 (회원에 대한 통지)</PolicyItemTitle>
            <PolicyItemDescription>
              솔루션은 회원에 대한 통지를 하는 경우 이 약관에 별도의 규정이 없는
              회원정보에 기입된 개인정보를 통하여 전자적인 방법을 통해 합니다.
              다만 회원 전체에 대한 통지의 경우에는 솔루션이 운영하는 서비스
              화면에 게시하는 것으로 통지를 갈음할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제7조 (회원의 의무)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회원은 본 약관에서 규정하는 사항과 이용 안내 또는 공지 사항
              등을 통하여 솔루션이 공지하는 사항을 준수하여야 하며, 기타
              솔루션의 업무에 방해되는 행위를 하여서는 안 됩니다.
              <br />
              2. 귀하는 허락 없이 타인의 계정을 이용할 수 없습니다.
              <br />
              3. 계정 개설시 귀하는 정확하고 완전한 정보를 제공하여야 합니다.
              귀하는 귀하의 계정으로 행한 활동에 대하여 단독 책임을 지며, 계정
              비밀번호의 보안을 유지하여 야 합니다. 귀하는 귀하 계정의 보안
              침해나 무단 이용시 즉시 그러한 사실을 솔루션에 통지하여야 합니다.
              <br />
              4. 회원은 솔루션의 명시적인 동의가 없는 한 서비스의 이용 권한,
              기타 이용 계약상 지위를 타인에게 양도, 증여 할 수 없으며 이를
              담보로 제공할 수 없습니다.
              <br />
              5. 회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를
              하여서는 안 됩니다.
              <br />
              <PolicyItemSubBlock>
                ① 타인의 아이디와 비밀번호 등을 도용하는 행위
                <br />
                ② 본 서비스를 통하여 얻은 정보를 솔루션의 사전 승낙 없이 회원의
                이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에
                사용하거나 제 3자에게 제공하는 행위
                <br />
                ③ 지식재산권을 침해하는 내용을 게시 또는 기타의 방법으로
                타인에게 유포하는 행위.
                <br />
                ④ 범죄와 결부된다고 객관적으로 판단되는 행위
                <br />
                ⑤ 솔루션의 승인을 받지 않고 다른 이용자의 개인정보를 수집 또는
                저장하는 행위
                <br />⑥ 기타 관계 법령에 위배되는 행위
              </PolicyItemSubBlock>
            </PolicyItemDescription>

            <PolicyItemPointTitle>제3장 서비스 이용</PolicyItemPointTitle>

            <PolicyItemTitle>제8조 (서비스)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 본 약관은 본 서비스에 콘텐츠를 기고한 이용자를 포함하여 본
              서비스의 모든 이용자에게 적용됩니다. “콘텐츠”에는 귀하가 본 서비스
              상으로 보거나, 본 서비스를 통하여 접속하거나 또는 본 서비스에
              기고할 수 있는 텍스트, 소프트웨어, 스크립트, 그래픽, 사진, 사운드,
              비디오, 플레이 리스트, 시청각 혼합, 상호작용 내용과 기타 자료가
              포함됩니다. 본 서비스에는 솔루션 채널, 솔루션 ‘Embeddable Player’
              및 기타 어플리케이션 등 솔루션을 통해 제공된 모든 제품, 소프트웨어
              및 서비스를 포함하나 이에 한정되지 아니하는 솔루션의 모든 면이
              포함됩니다.
              <br />
              2. 흐름(HREUM de salon) 솔루션은 다양한 서비스를 통해
              독창적인 콘텐츠 생산과 아이디어 공유의 장을 만들어주며 다양하고
              적법한 광고주를 위한 광고 배포 플랫폼의 역활 을 합니다.
              <br />
              3. 본 서비스에는 솔루션이 소유하거나 지배하지 않는 제3자
              웹사이트와의 링크, 제 3 자 오디오, 제 3자 이미지, 제 3자 텍스트가
              포함될 수 있습니다. 솔루션은 제3자 의 콘텐츠, 개인정보 취급방침 및
              행위를 통제하지 아니하고, 이와 관련하여 어떠한 책임도 지지
              않습니다. 아울러, 솔루션은 제3자의 콘텐츠를 검열하거나 편집하지
              아니하며, 그와 같이 할 수도 없습니다. 본 서비스를 이용하면 귀하는
              명시적으로 귀하의 제3자 웹사이트 이용으로 인하여 발생하는 모든
              책임으로부터 솔루션을 면제 합니다.
              <br />
              4. 이에 따라, 당사는 귀하에게, 본 서비스를 떠나는 경우 이를
              인식하고 귀하가 방문 혹은 이용한 기타 각 웹사이트의 약관 및
              개인정보 취급방침을 숙독할 것은 권장 합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제9조 (일반적인 서비스 이용 – 허락사항 및 제한사항)
            </PolicyItemTitle>
            <PolicyItemDescription>
              솔루션은 이하에 기재된 내용을 조건으로 하여 귀하가 본 약관에
              규정된 바에 따라 본 서비스에 접속하고 본 서비스를 이용하는 것을
              허용합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              1. 귀하는 솔루션의 사전 서면 승인 없이는 본 서비스 또는 콘텐츠의
              어느 부분이라도 그리고 이를 어떤 매체로도 배포하지 않을 것에
              동의합니다. 단, 솔루션이 본 서 비스에서 제공된 기능(예를 들어,
              Embeddable Player)을 통하여 그러한 배포를 위한 수단을 제공하는
              경우는 제외합니다.
              <br />
              2. 귀하는 본 서비스의 일부라도 변경하거나 수정하지 않을 것에
              동의합니다.
              <br />
              3. 귀하는 본 서비스 자체의 플레이백 페이지, Embeddable Player 또는
              기타 솔루션이 지정하는 명시적으로 인정된 수단 이외의 기술이나
              수단을 통하여 콘텐츠에 접속하지 않을 것에 동의합니다.
              <br />
              4. 귀하는 솔루션이 서비스하는 콘텐츠를 공공장소 및 영리를 목적으로
              하는 영업장, 매장 등에서 공중에 대한 공개 재현용 등 사적인 이용을
              벗어난 범위에서 사용할 수 없으며, 저작물 사용 시 저작물 관리
              주체에 공연사용료를 납부할 의무가 있으며 저작물 사용으로 인한
              일체의 모든 추가 비용을 납부 혹은 부담을 해야 합니다.
              <br />
              5. 귀하는 솔루션의 사전 서면 승인을 얻은 경우를 제외한 상업적
              목적을 위하여 본 서비스를 이용하지 않을 것에 동의합니다.
              <br />
              6. 아래 기재된 내용은 금지된 상업적인 목적에 포함되지 아니합니다.
              <PolicyItemSubBlock>
                ① 귀하의 사업 또는 예술 기업을 선전하기 위하여 솔루션에 원본
                오디오를 업로드하거나 솔루션에 원본 채널을 유지하는 것.
                <br />
                ② 광고제한에 따라 광고가 가능한 블로그 또는 웹사이트에서
                Embeddable Player를 통하여 솔루션의 일부 콘텐츠를 송신하는 것.
                <br />③ 솔루션이 서면으로 명시적으로 인정한 목적.
              </PolicyItemSubBlock>
              7. 귀하의 웹사이트에서 Embeddable Player를 이용하는 경우, 귀하는
              솔루션 서비스로 돌아가는 링크를 포함하나 이에 한정되지 아니하고
              일부 또는 기능을 수정, 증설하거나 차단할 수 없습니다.
              <br />
              8. 귀하는 인간이 전통적인 온라인 웹 브라우저 및 기타 전자적인
              방법을 이용하여 일정 기간 동안 합리적으로 생성할 수 있는 것보다
              많은 요청 메시지를 동일한 기간 내에 솔루션 서버에 전송하는
              방식으로 본 서비스에 접속하는 “robots,” “spiders”, 또는 “offline
              readers”를 비롯한 자동화 시스템을 이용하거나 실행 (launch)하 지
              않을 것에 동의합니다. 전술한 내용에도 불구하고, 솔루션은
              공개적으로 이용 가능한 검색가능 자료 색인(그러한 자료의
              캐시(caches) 또는 보관이 아님)을 작성 하기 위해서만 그리고 이에
              필요한 범위 내에서만 공개적 검색엔진 사업자에게 자료를 복사할
              스파이더 (spider)를 이용할 수 있도록 허용합니다. 솔루션은 일반적으
              로 또는 특별한 경우 이러한 예외인정을 취소할 권리를 보유합니다.
              귀하는 본 서비스로부터 계정 명칭을 포함한 개인식별정보를
              수집하거나 수확하지 아니하며 또한 상업적인 거래유인 목적을 위하여
              본 서비스가 제공한 통신시스템(예컨데, 의견 및 이메일)을 이용하지
              않을 것에 동의합니다. 귀하는 상업적인 목적을 위하여 본 서 비스의
              이용자로부터 그 이용자의 콘텐츠와 관련하여 거래를 유인하지 않을
              것에 동의합니다.
              <br />
              9. 본 서비스 이용시 귀하는 모든 관련 법률을 준수합니다.
              <br />
              10. 솔루션은 언제든지 본 서비스의 특정 부분을 수정 및 중단할
              권리를 보유합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제10조 (귀하의 콘텐츠 이용)</PolicyItemTitle>
            <PolicyItemDescription>
              상기 일반적인 제한에 추가하여, 콘텐츠 이용시 귀하는 특히 이하에
              기재된 제한 및 조건에 따라야 합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              1. 본 서비스상의 콘텐츠와 본 서비스상의 상표, 서비스표 및
              로고(“본건 표장”)는 법률에 규정된 저작권 및 기타 지적재산권에 따라
              솔루션이 소유하거나 솔루션에게 라이센스가 허여됩니다.
              <br />
              2. 콘텐츠는 있는 그대로 귀하에게 제공됩니다. 귀하는 본 서비스의
              제공된 기능을 통하여 의도한 대로, 그리고 본 약관에 의해 허용된
              대로 참고 목적 또는 개인적인 목적에 한하여 콘텐츠에 접속할 수
              있습니다. 귀하는 해당 콘텐츠에 대하여 본 서비스에서 솔루션이
              표시한 “다운로드” 또는 그와 유사한 링크를 발견하지 않는 한 콘
              텐츠를 다운로드 하면 안 됩니다. 귀하는 솔루션 또는 각 콘텐츠의
              라이센서의 사전 서면 동의 없이는 어떠한 목적으로도 콘텐츠를 복사,
              복제(reproduce), 온라인으 로 제공하거나 전자적인 방법으로
              전송하거나, 발표, 각색, 배포, 전송, 방송, 송신, 전시(display),
              판매, 라이센스 허여하거나 달리 이용하지 않아야 합니다. 솔루션과
              라이센서들은 본 서비스 및 콘텐츠에서 명시적으로 허여되지 않은 모든
              권리를 보유합니다.
              <br />
              3. 귀하는 본 서비스의 보안 관련 기능 또는 콘텐츠의 이용 또는
              복사를 방지 또는 제한하거나, 본 서비스 또는 본 서비스에 포함된
              콘텐츠의 이용에 대한 제한을 실행 하는 기능을 회피하거나,
              비활성화하거나 달리 방해하지 않을 것에 동의합니다.
              <br />
              4. 귀하는 본 서비스 이용시 귀하는 여러 출처에서 나온 콘텐츠에
              노출되며, 솔루션은 그러한 콘텐츠의 또는 그러한 콘텐츠와 관련된
              정확성, 유용성, 안전 또는 지적 재산권에 대하여 책임을 지지 않음을
              알고 있습니다. 귀하는 또한 부정확하거나, 불쾌감을 주거나,
              무례하거나, 논란의 여지가 있는 콘텐츠에 노출될 수 있음을 이해하 고
              이를 인정하며, 귀하는 그와 관련하여 솔루션에 대하여 가지고 있거나
              가질 수 있는 법률상 또는 형평법상 권리 또는 구제수단을 포기할 것에
              동의하고 이에 이를 포기하며, 관련 법률이 허용하는 범위 내에서
              귀하의 본 서비스 이용과 관련된 모든 사안에 관하여 법률이 허용하는
              최대 범위까지 솔루션과 그 소유자, 운영자, 계열 회사, 라이센서 및
              라이센시를 면책하고 손해를 입지 않도록 하는 것에 동의합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제11조 (귀하의 콘텐츠와 행위)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 귀하는 솔루션의 계정 보유자 자격으로 본 서비스에 오디오 및
              이용자 의견을 포함한 콘텐츠를 제출할 수 있습니다. 귀하는, 솔루션이
              귀하가 제출한 콘텐츠의 기밀 성을 보장하지 않음을 알고 있습니다.
              <br />
              2. 귀하 자신의 콘텐츠와 귀하의 콘텐츠를 본 서비스에 제출하고
              발표한 결과에 대해서 귀하만이 책임을 집니다. 귀하는 귀하가
              제출하는 콘텐츠를 발표하는데 필요한 라이센스, 권리, 동의 및 승인을
              소유하거나 보유하고 있으며, 귀하는 본 약관에 따라 본 서비스를
              통하여 발표하기 위하여 콘텐츠에 대한 모든 특허, 상표, 영업비밀,
              저작권 또는 기타 전유적 권리를 솔루션에 라이선스 하였음을 확인,
              진술 및 보증합니다.
              <br />
              3. 귀하는 귀하의 콘텐츠에 대한 소유권 전부를 보유함을 명확히
              합니다. 그러나, 솔루션에 콘텐츠를 제출함으로써, 귀하는 본
              서비스(및 그 2차적 저작물)의 일부 또 는 전부를, 어떠한 미디어
              포맷으로 어떠한 미디어 채널을 통하여 선전하고 재배포하는 것을
              비롯하여, 본 서비스 및 솔루션(및 그 승계인 및 계열회사)의 사업과
              관련 하여 콘텐츠를 이용, 복제, 배포, 2차적 저작물을 작성하거나,
              전시, 발표, 각색, 온라인에 제공하거나 전자적인 방법으로 전송하고,
              송신하고, 공연(perform)할 수 있 는 세계적이고, 비독점적이고,
              무상으로 제공되고, 양도가능하며, 서브라이센스를 허여할 수
              있는(sublicensable) 라이센스를 솔루션에 허여합니다. 귀하는 또한 본
              서비스의 모든 이용자에게, 본 서비스를 통하여 귀하의 콘텐츠에
              접속하고, 본 약관 및 본 서비스의 기능을 통하여 허용되는, 그
              콘텐츠의 이용, 복제, 배포, 전시, 발표, 온라인에 제공하거나
              전자적인 방법으로 전송하고, 공연할 수 있는 비독점적 라이센스를 본
              서비스의 모든 이용자에게 허여합니다. 귀하가 본 서비스에 제출한
              콘텐 츠에 대하여 귀하가 허여한 상기 라이센스는 귀하가 본
              서비스에서 귀하의 콘텐츠를 제거하거나 삭제한 후 상업적으로
              합리적인 기간 내에 효력을 상실합니다. 귀하 는 솔루션이 제거되거나
              삭제된 귀하의 콘텐츠의 서버 사본(server copy)을 보유할 수 있으나,
              이를 전시, 배포하거나 공연할 수 없음을 알고 있고 이에 동의합니다.
              귀하가 제출한 이용자 의견에 대하여 귀하가 허여한 상기 라이센스는
              영구적이며 취소할 수 없습니다.
              <br />
              4. 귀하는 또한 귀하가 본 서비스에 제출한 콘텐츠는, 귀하가 이를
              게시하고 또한 본 약관에 정해진 라이센스 권리 전부를 솔루션에
              허여할 수 있도록, 귀하가 적법한 소유권자로부터 승인을 받았거나
              달리 법적으로 그러한 권한이 있는 경우를 제외하고는, 제3자가
              저작권을 보유한 자료나 기타 제3자가 전유적 권리를 가진 자료를
              포함하지 않을 것에 동의합니다.
              <br />
              5. 귀하는 또한 귀하가 현재 솔루션 커뮤니티 지침(Community
              Guideline)과 상반되거나 관련 현지, 국내 및 국제 법률 및 규정에
              상반되는 콘텐츠나 기타 자료를 본 서비스에 제출하지 않을 것에
              동의합니다.
              <br />
              6. 솔루션은 이용자 또는 기타 라이센서가 본 서비스에 제출한
              콘텐츠나 본 서비스에 게시된 의견, 권고 또는 조언을 보증하지
              아니하며, 솔루션은 콘텐츠와 관련된 일 체의 책임을 명시적으로
              부인합니다. 솔루션은 본 서비스를 통한 저작권 침해 행위나
              지적재산권 침해를 허용하지 아니하며, 콘텐츠가 타인의 지적재산권을
              침해한다 는 사실을 적절하게 고지 받는 경우 그 콘텐츠 일체를
              제거합니다. 솔루션은 사전 통지 없이 콘텐츠를 제거할 권리를
              보유합니다.
              <br />
              7. 솔루션은 솔루션 운영에 해가 된다고 판단되는 이용자의 콘텐츠에
              대해 솔루션의 내부 규정 및 본 약관에 의거하여 사전 통지 없이
              콘텐츠를 제거 혹은 비공개 처리 할 권리를 보유합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제12조 (정보의 제공)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한
              정보를 공지 사항이나 전자우편 등의 방법으로 회원에게 제공할 수
              있습니다.
              <br />
              2. 솔루션은 회원에게 보다 나은 서비스 혜택 제공을 위해 다양한 전달
              방법(전화, 안내문, 전자우편, 휴대폰 문자 메시지 등)을 통해 서비스
              관련 정보를 제공할 수 있 습니다. 단, 솔루션은 회원이 서비스 혜택
              정보 제공을 원치 않는다는 의사를 밝히는 경우 정보 제공대상에서
              해당 회원을 제외 하며, 그로 인해 해당 회원에게 서비스 이용에
              불이익이 발생하더라도 이에 대해서는 솔루션이 책임지지 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제13조 (유료 서비스의 이용)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 무료 서비스 중 광고 제거, 웹에서 흐름 재생 등을 포함한 부가적
              기능에 대한 유료서비스를 제공하고 있습니다.
              <br />
              2. 유료 서비스에 대한 약관은 ‘흐름(HREUM de salon)
              유료서비스 이용약관’을 따릅니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제14조 (회원의 게시물 등)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각
              호에 해당한다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.
              <PolicyItemSubBlock>
                ① 다른 회원 또는 제 3자를 비방하거나 명예를 손상시키는 내용인
                경우
                <br />
                ② 공공질서 및 미풍양속에 위반되는 내용인 경우
                <br />
                ③ 범죄적 행위에 결부된다고 인정되는 내용일 경우
                <br />
                ④ 솔루션의 저작권, 제 3자의 저작권 등 기타 권리를 침해하는
                내용인 경우
                <br />
                ⑤ 솔루션에서 규정한 게시 기간이나 용량을 초과한 경우
                <br />
                ⑥ 회원이 게시판에 음란물을 게재하거나 음란 사이트를 링크하는
                경우
                <br />
                ⑦ 기타 관계 법령에 위반된다고 판단되는 경우
                <br />⑧ 정상적인 솔루션의 운영에 방해가 된다고 판단되는 경우
              </PolicyItemSubBlock>
              2. 솔루션은 솔루션의 사정상 불가피한 경우 회원의 게시물이나 등록한
              내용을 게시 중단 및 삭제 등의 조치를 취할 수 있습니다.
              <br />
              3. 회원의 게시물 등으로 인한 법률상 이익침해를 근거로, 다른 회원
              또는 제3자가 회원 또는 솔루션을 대상으로 하여 민형사상의 법적
              조치(예: 형사고소, 가처분 신 청, 손해배상청구 등 민사소송의
              제기)를 취하는 경우, 솔루션은 동 법적 조치의 결과인 법원의 확정
              판결이 있을 때까지 관련 게시물 등에 대한 접근을 잠정적으로 제 한할
              수 있습니다. 게시물 등의 접근 제한과 관련한 법적 조치의 소명,
              법원의 확정 판결에 대한 소명 책임은 게시물 등에 대한 조치를
              요청하는 자가 부담합니다.
              <br />
              4. 무료 제공되고 있는 게시물은 전적으로 업로드 하는 당사자가
              저작권에 대한 확인을 거친 후 업로드 해야하며, 업로드 하는 오디오를
              포함한 모든 콘텐츠로 인해 발 생되는 문제는 전적으로 업로드한
              회원에게 있습니다.
              <br />
              5. 업로드 된 게시물에 저작권법 상의 문제가 있을 시에 본인이
              솔루션에 전자메일을 통해 알림으로써 게시를 중단을 요청할 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제15조 (광고 게재 및 광고주와의 거래)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 서비스의 운영과 관련하여 서비스화면, 홈페이지에
              전자우편 및 서신우편, 문자메시지 등(이하 ‘이메일 등’이라 함)에
              각종 정보나 광고를 게재할 수 있습니다.
              <br />
              2. 제1항과 관련하여 회원은 관련 법령에 따른 거래 관련 정보 및 고객
              문의 등에 대한 답변 등을 제외하고는 언제든지 이메일 등에 대하여
              수신을 거절할 수 있으며, 솔루션은 수신거절 방법을 명시합니다. 단,
              솔루션은 관련 법령에 따라 동의 의무가 면제되는 거래관련 정보 및
              고객문의 사항 등을 제외한 광고 등을 이메일 등을 통하여 회원에게
              전송할 경우 회원의 사전 수신 동의를 받아서 전송합니다.
              <br />
              3. 본 서비스 상에 게재되어 있는 광고나 본 서비스를 통한 광고주의
              판촉 활동에 회원이 참여하거나 교신 또는 거래한 결과는 회원과
              광고주 사이의 문제로서 솔루션은 이와 관련하여 어떠한 책임도 지지
              않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제16조 (포인트의 이용)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 회원에게 포인트를 부여할 수 있습니다.
              <br />
              2. 솔루션은 포인트의 획득, 사용 방법 등에 관련된 세부이용지침은
              해당 사이트 또는 서비스 별로 마련하여 고지한 절차 또는 세칙 등에
              따릅니다.
              <br />
              3. 부정한 방법으로 포인트 혹은 사이버머니를 획득한 사실이 확인될
              경우 솔루션은 회원의 포인트 혹은 사이버 머니의 회수, 회원의
              아이디(고유번호) 삭제 및 형사 고발 등 기타 조치를 취할 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제17조 (서비스 이용 책임)</PolicyItemTitle>
            <PolicyItemDescription>
              회원은 솔루션이 제공하는 서비스를 이용하여 상품을 판매하는 영업
              활동을 할 수 없으며, 특히 해킹, 돈벌이 광고, 음란사이트 등을 통한
              상업 행위, 상용 소프트웨어 불법 배포 등을 할 수 없습니다. 이를
              어기고 발생한 영업 활동의 결과 및 손실, 관계 기관에 의한 처벌 등
              법적 조치 등에 관해서는 솔루션이 책임을 지지 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제18조 (서비스 제공의 중지 등)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수
              있습니다.
              <PolicyItemSubBlock>
                ① 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
                <br />② 기타 불가항력적 사유가 있는 경우
              </PolicyItemSubBlock>
              2. 솔루션은 서비스에 대한 운영상, 법률상, 기술상의 필요에 따라
              상당한 이유가 있는 경우에 한하여 제공하고 있는 전부 또는 일부
              서비스를 변경할 수 있습니다.
              <br />
              3. 솔루션은 무료로 제공되는 서비스의 특성 상 관련법에 특별한
              규정이 없는 한 회원에게 별도의 보상은 하지 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제19조 (서비스 별 약관)</PolicyItemTitle>
            <PolicyItemDescription>
              서비스의 이용을 위하여 솔루션 이용약관 이외의 별도의 약관(또는
              공지)이 존재할 수 있으며, 별도의 약관과 솔루션 이용약관의 내용이
              상충될 경우 해당 서비스와 가장 인접한 약관이 우선됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제20조 (녹음 서비스의 동의)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 유저가 개설한 살롱에 접속한 유저들은 호스트의 권한으로 살롱에서
              나누어진 대화들에 대해 녹음할 수 있는 권리를 부여받습니다.
              <br />
              2. 유저의 권한으로 녹음이 이뤄질 경우, 솔루션에서는 녹음이 되고
              있다는 표시를 UI 등을 통해 대화에 참여하고 있는 유저들이 인식할 수
              있도록 해야하며, 해당 표시가 있는 상태에서 녹음된 오디오는 녹음을
              시작한 유저에게 소유권이 귀속되고, 대화에 참여한 유저들은 상업적,
              비상업적 목적으로 사용할 수 있도록 오디오의 저작권을 귀속하는 것에
              동의합니다.
              <br />
              3. 녹음된 오디오 콘텐츠에 대해서는 녹음을 시작한 유저의
              ‘흐름 (HREUM de salon)’ 서비스 개인 클라우드 공간에
              저장되고, 녹음 당사자인 유저만 이에 접근할 수 있습니다.
              <br />
              4. 회사는 공개된 형태로 유저들이 접근할 수 있는 형태로 개설된
              살롱에서 공유된 오디오 및 텍스트에 대해 수집하여 음악 등의 저작물
              검출 및 정산과 추천 및 맞춤형 광고 등 개인화 서비스를 위한 목적의
              데이터로 활용할 수 있습니다.
              <br />
              5. 회원이 녹음 서비스 이용 시 발생한 상대방과 분쟁에 대해서는
              회사는 책임지지 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제21조 (저작권 정책)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 음악 저작물에 대하여 음악저작물 관리&보상 단체들과의
              ‘디지털음성송신 사용 및 보상계약’을 체결하여 DJ 회원이 합법적으로
              ‘플라’ 콘텐츠 구성에 있 어 음악 저작물을 활용할 수 있는 환경을
              제공합니다.
              <br />
              2. 솔루션은 제3자의 저작권을 침해했다고 주장된 모든 종류의 내용에
              대하여 분명한 저작권 정책을 적용합니다. 이러한 정책에 대한 자세한
              내용은 부칙에서 찾을 수 있습니다.
              <br />
              3. 회사는 개인정보를 제외한 유저들이 솔루션을 사용하면서 취득하고
              분석한 데이터에 대한 재산권을 소유합니다.
              <br />
              4. 솔루션의 저작권 정책의 일환으로, 이용자가 반복위반자 혹은
              악용회원으로 결정된 경우, 솔루션은 해당 이용자의 서비스에 대한
              접속을 해지하거나 원활한 서비 스 이용에 필요한 핵심 기능 이용을
              제한할 수 있습니다. 반복위반자란 2회 이상의 침해행위가 인정되고
              통보된 이용자, 그리고 악용회원이란 1회 이상의 심각한 수준 의
              저작권 침해행위가 인정되고 통보된 이용자를 뜻합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제22조 (한국 외 사용자에게 적용되는 특별 조항)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 솔루션은 모두에게 일관된 표준이 적용되는 글로벌 서비스를
              형성하기 위해 노력하고 있지만, 동시에 각 지역의 현지 법률을
              준수하기 위해서도 노력하고 있습니다. 다음 조항은 한국 외에서
              솔루션과 교류하는 회원에게 적용됩니다.
              <PolicyItemSubBlock>
                ① 회원은 개인 데이터가 한국 내에서 처리되는 것에 동의해야
                합니다.
                <br />
                ② 한국이 수출을 금지하거나 한국의 특별 지정 국민 리스트에 등재된
                국가에 있는 사용자는 솔루션에서 상업 활동(예, 광고나 결제)에
                참여하거나 서비스를 이용할 수 없습니다.
                <br />③ 흐름(HREUM de salon)이 공지한 정식 서비스 런칭
                국가 리스트 외의 국가에서 이용하는 사용자는 솔루션에서의 활동을
                많은 부분 제한될 수 있습니다.
              </PolicyItemSubBlock>
            </PolicyItemDescription>

            <PolicyItemTitle>제23조 (보증의 부인)</PolicyItemTitle>
            <PolicyItemDescription>
              귀하는 귀하의 본 서비스 사용과 관련하여 귀하 단독으로 위험을
              부담하기로 동의합니다. 법률이 허용하는 최대 범위 내에서, 솔루션,
              솔루션의 임직원, 이사 및 대리 인은 본 서비스 및 귀하의 본 서비스
              이용과 관련하여 명시적이거나 묵시적인 모든 보증을 배제합니다.
              법률이 허용하는 최대 범위 내에서, 솔루션은 본 사이트의 콘텐 츠
              또는 본 사이트와 링크된 사이트의 콘텐츠의 정확성이나 완전성에
              대하여 모든 보증, 조건이나 진술을 배제하며, 콘텐츠의 오류,
              과실이나 부정확성, 당사의 서비스 에 대한 귀하의 접속 및 사용으로
              인하여 발생하는 여하한 성질의 상해(personal injury) 또는 재산적
              손해(property damage),당사의 보안서버 및/또는 그에 저장 된 모든
              개인정보 및/또는 금융정보에 대한 무단 접속 또는 무단 사용, 당사
              서비스로 또는 당사 서비스로부터의 전송 장애 또는 중단, 제3자가
              당사 서비스로 또는 당 사 서비스를 통하여 전송하는 버그, 바이러스,
              트로이 목마 및 유사한 것, 및/또는 콘텐츠의 오류 또는 누락이나 본
              서비스에 게재, 이메일 송부, 전송되거나 달리 본 서 비스를 통하여
              제공된 콘텐츠의 사용으로 인하여 발생하는 여하한 종류의 손실이나
              손해에 대하여 어떠한 책임도 부담하지 않습니다. 솔루션은 본 서비스,
              하이퍼링 크된 서비스 또는 배너 등의 광고를 통하여 제3자가
              광고하거나 제공하는 제품이나 서비스에 대하여 어떠한 보증, 승인,
              보장 또는 그에 대한 책임을 부담하지 않으며, 솔루션은 제품 또는
              서비스를 위한 귀하와 제3자와의 거래의 당사자가 아니며, 어떠한
              방식으로도 그러한 거래를 모니터링할 책임을 부담하지 않습니다.
              어떠한 매체 를 통해서건 또는 어떠한 상황에서건 제품이나 서비스를
              구입하는 것과 관련하여, 적절한 경우, 귀하는 최선의 판단력으로서
              주의를 기울여야 합니다. 모바일로 솔루 션 광고를 시청하면 데이터
              요금이 발생할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제24조 (크리에이터, DJ 활동)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 흐름(HREUM de salon) 서비스와 크리에이터, DJ 유저:
              <br />
              크리에이터, DJ 유저는 서비스 이용시 다음 각 호의 내용을 준수 및 인지하고 있어야
              합니다.
              <PolicyItemSubBlock>
                ① 플라는 선곡 리스트와 이를 소개하는 글로 이루어진
                플라(=음악 플레이리스트 라디오)를 구성하여 음악을 송출하는 콘텐츠입니다.
                <br />
                ② 캐스트는 '크리에이터'가 구성하여 일반 유저가 감상할 수 있도록 제공하는 오디오 콘텐츠입니다.
                <br />
                ③ 회원은 주식회사 나이비(이하 “회사”)이 안내하는 특정 절차를
                거쳐 플라를 제작할 수 있는 DJ를 신청할 수 있으며, 회사는 회원이
                제출한 신청 정보를 토대로 DJ자격에 대한 승인 여부를 결정합니다.
                <br />
                ④ 회원은 DJ신청 및 승인 직후는 일반DJ로 활동하며 해당 일반DJ의
                활동 이력을 토대로 ‘파트너DJ’로 승격될 수 있습니다.
                <br />
                ⑤ 파트너 DJ 승격 이후 커뮤니티가이드라인 위반 혹은 그 밖의
                서비스에 심각한 해를 끼친다고 판단되는 콘텐츠를 구성 혹은 방치할
                경우 파트너DJ혜택을 포함한 전반적인 솔루션이 제공하는 서비스의
                이용에 제한을 받을 수 있습니다. 이는 적절한 방법을 통하여 해당
                조치 이전에 회원에게 고지됩니다.
                <br />
                ⑥ 일반 DJ는 흐름(HREUM de salon) 서비스에서 제공하는
                ‘수익분배 서비스’를 이용할 수 없으며 ‘파트너DJ’만 이용
                가능합니다.
                <br />
                ⑦ 모든 계급의 DJ들은 그들이 만든 콘텐츠가 서비스에 해를
                가한다고 판단될 경우 내규에 따라 예고없이 플라와 프로필 정보가
                비공개 및 삭제 조치될 수 있으며 누적된 수익 또한 예고없이 이용
                중지 및 초기화 조치 될 수 있습니다. 이에 대한 자세한 내용은 아래
                ‘3.DJ 활동과 서비스의 제한’을 참고하십시오.
              </PolicyItemSubBlock>
              2. 크리에이터, DJ 준수 사항:
              <br />
              DJ는 흐름(HREUM de salon) 서비스에서 활동하며 플라 및
              아티스트 정보(이하 “콘텐츠”) 에 대해 다음 각 호의 내용을 준수해야
              합니다.
              <PolicyItemSubBlock>
                ① 플라는 개별적인 음원들이 모여 최소 30분 이상의 전체 재생
                길이를 가지고 있어야 합니다.
                <br />
                ② 플라의 제목과 설명은 플라, DJ의 테마 및 장르를 표현할 수
                있어야 하며, 단문, 타 브랜드 홍모문구, 의미 없는 글 등을 피해야
                합니다
                <br />
                ③ 플라의 컨셉에 맞는적정수의 #태그가 등록되어야 합니다.
                <br />
                ④ 한 개의 플라 내에 동일한 특정 가수 및 동일한 특정 앨범 내의
                수록곡이 최대 3곡을 넘지 말아야 합니다. 그 이상으로 제작된
                플라는 회사의 커뮤니티가이드라인에 따라 노출이 제한됩니다.
                <br />
                ⑤ 크리에이터는 타인의 저작물에 대한 침해를 하지 말아야 하며, 저작권 침해 시 저작권법에 의거하여 관련기관의 처벌을 받을 수 있습니다.
                <br />
                ⑥ 흐름(HREUM de salon) 커뮤니티가이드라인을 준수하는
                올바른 성격의 프로필 이미지 및 플라 대표이미지로 설정해야 합니다.
              </PolicyItemSubBlock>
              3. 크리에이터, DJ활동과 서비스의 제한:
              <br />
              아래에 해당하는 경우 회사는 회원의 DJ 신청 권한 및 DJ 활동을
              제한하거나, 회원이 제작한 플라를 비공개 혹은 삭제 처리하는 등
              회사에서 제공하는 모든 서비스에 서 회원의 이용을 제한할 수
              있습니다.
              <PolicyItemSubBlock>
                ① 서비스 커뮤니티가이드라인을 위반하여 솔루션의 서비스에 해를
                가한다고 판단되는 콘텐츠를 구성
                <br />
                ② 흐름(HREUM de salon) 서비스의 ‘디지털음성송신’ 관계
                법령과 어긋나는 플라를 구성할 시.
                <br />
                ③ 타 크리에이터, DJ의 캐스트와 플라를 고의적이라고 여겨질 수준으로 동일하게 구성하여
                원 크리에이터, DJ의 오리지널리티를 훼손한 점이 밝혀졌을 때
                <br />
                ④ 비신탁 저작물을 저작권자의 허락 없이 플라에 구성하여
                원저작자로부터 신고 받을 시.
                <br />
                ⑤ 6개월 이상 플라를 제작 및 수정이 없는 경우.
                <br />
                ⑥ 전체 재생시간 30분 미만의 플라를 제작하는 경우
                <br />
                ⑦ 욕설, 비속어, 음란물 등 타인에게 불쾌감을 줄 수 있는 내용을
                포함한 콘텐츠를 게시하는 경우.
                <br />
                ⑧ 2개 이상의 음원을 1개의 오디오 파일로 합하여 플라에 등록한
                경우.
                <br />
                ⑨ 본 동의문 내용 및 ‘흐름(HREUM de salon) 이용약관’, 기타
                관계법령에 위반되는 경우.
                <br />
                ⑩ 주제와 부합하지 않는 플라를 게시한 경우.
                <br />
                ⑪ 사적 목적을 위해 선곡에 대한 대가로 금품을 받거나 영리를
                추구하는 하는 경우.
                <br />⑫ 기타 본 약관 및 커뮤니티가이드라인 등 회사에서 규정한
                내용을 참고하여 회원에 대한 서비스 이용 제재가 필요한 경우.
              </PolicyItemSubBlock>
              4. DJ신청 및 유의사항:
              <br />
              회원은 DJ 신청 시 아래 유의사항을 확인하여야 하며, 회사는
              유의사항을 지키지 않은 회원의 DJ 활동 제한 등 서비스 이용을 제한할
              수 있습니다.
              <PolicyItemSubBlock>
                ① 제목/주제에 맞는 수록 곡 선정 및 플라의 제목과 설명 작성, 플라
                대표 이미지 등록
                <br />
                ② 30분 이상의 전체 재생 시간을 갖는 플레이리스트 등록
                <br />
                ③ 적절한 프로필 이미지와 닉네임을 프로필 정보로 등록
                <br />
                ④ 하나의 플라 내에 동일한 특정 가수 및 동일한 특정 앨범의
                수록곡을 최대 3곡내로 선곡
                <br />⑤ 그 외 흐름(HREUM de salon) 커뮤니티 가이드라인에
                위배되어 서비스 이용에 대해 제한을 받을 때
              </PolicyItemSubBlock>
              5. 크리에이터, DJ 활동과 콘텐츠 이용 범위
              <br />
              크리에이터, DJ가 작성한 게시물의 이용 범위에 대해 크리에이터, DJ유저는 다음 각 호의 내용을
              준수 및 인지하고 있어야 합니다.
              <PolicyItemSubBlock>
                ① 회원은 자신이 제작한 플라에 대하여 회사의 모든 서비스를 운영,
                전시, 전송 배포, 홍보, 하기 위한 목적으로 사용료 없는 비독점적
                사용권을 회사에게 부여합니 다. 사용권은 다음 ‘나’호의 내용을
                준수하며 사용권 부여는 회사가 서비스를 운영하는 동안 유효하며,
                회원의 탈퇴 후에도 유효합니다.
                <br />② 서비스의 원활한 운영을 위해, 회사에 의해 앱과 웹사이트
                및 솔루션이 운영하는 내부, 외부 채널에 등록된 DJ플레이리스트의
                경우 삭제 또는 편집이 불가능할 수 있으며, 회원 탈퇴 시에는
                서비스가 지속되는 기간 동안 영구 보관됩니다.
              </PolicyItemSubBlock>
              6. 23조 내용을 제외한 다른 ‘흐름(HREUM de salon) 서비스
              이용약관’ 내용과의 관계는 다음 각 호의 내용에 따른다.
              <PolicyItemSubBlock>
                ① 흐름(HREUM de salon) 이용에 관한 제반 사항은 23조
                내용에서 별도로 정한 사항을 제외하고는 ‘흐름(HREUM de
                salon) 서비스 이용약관’에 따릅니다.
                <br />② 본 23조의 내용과 제23조 외의 ‘흐름(HREUM de salon)
                서비스 이용약관’의 내용이 상충하는 경우, 본 23조 내용을 우선
                적용하며, 본 23조 내용에 명시되지 않은 사항 에 대해서는
                ‘흐름(HREUM de salon) 서비스 이용약관’에 의하고,
                ‘흐름(HREUM de salon) 서비스 이용약관’에 규정되지 않은
                사항은 관련 법령 및 상관습에 의합니다.
              </PolicyItemSubBlock>
            </PolicyItemDescription>

            <PolicyItemPointTitle>
              제4장 계약해지 및 이용제한
            </PolicyItemPointTitle>

            <PolicyItemTitle>제25조 (계약 해지 및 이용 제한)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회원이 이용 계약을 해지하고자 하는 경우에는 회원 본인이 서비스
              상에서 회원 탈퇴를 솔루션에 진행해야 합니다.
              <br />
              2. 솔루션은 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전
              통지 없이 이용 계약을 해지하거나 또는 기간을 정하여 서비스 이용을
              중지할 수 있습니다.
              <PolicyItemSubBlock>
                ① 타인의 개인정보, 아이디 및 비밀번호를 도용한 경우
                <br />
                ② 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
                <br />
                ③ 솔루션, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우
                <br />
                ④ 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
                <br />
                ⑤ 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을
                계획 또는 실행하는 경우
                <br />
                ⑥ 서비스 운영을 고의로 방해한 경우
                <br />
                ⑦ 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를
                전송하거나 광고성 정보를 전송하는 경우
                <br />
                ⑧ 정보통신 설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터
                바이러스 프로그램 등을 유포하는 경우
                <br />
                ⑨ 솔루션의 서비스를 이용하여 얻은 정보를 솔루션의 사전 승낙 없이
                복제 또는 유통시키거나 상업적으로 이용하는 경우
                <br />
                ⑩ 본 약관과 서비스 커뮤니티가이드라인을 포함한 서비스 이용
                조건에 위반한 경우
                <br />⑪ 회원 가입 후 연속하여 1년 이상 서비스를 이용하기 위해
                log-in한 기록이 없는 경우. 단, 이 경우 솔루션은 사전에 회원에게
                이용계약의 해지를 예고하고 이용계 약 유지여부를 선택하도록 할 수
                있습니다.
              </PolicyItemSubBlock>
              3. 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당
              정보를 지체없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
              이유로 명시한 기간동안 보존합니다.
              <PolicyItemSubBlock>
                ① 회원탈퇴시 보존 개인정보
                <br />
                - 보존항목: 이름, 아이디, 이메일주소, 휴대폰연락처
                <br />
                - 보존근거: 서비스 이용의 혼선 방지, 불량 이용자의 재가입 방지,
                명예훼손 등 권리침해 분쟁 및 수사협조, 그 외 관계 법령에 의한
                예외적인 보관이 필요한 경우
                <br />
                - 보존기간: 회원탈퇴 후 1년
                <br />② 고객상담 및 회원의 추가적인 피해 방지를 위해 30일간
                보관하는 경우
              </PolicyItemSubBlock>
            </PolicyItemDescription>

            <PolicyItemPointTitle>
              제5장 손해 배상 및 기타 사항
            </PolicyItemPointTitle>

            <PolicyItemTitle>제26조 (손해 배상)</PolicyItemTitle>
            <PolicyItemDescription>
              솔루션은 귀하 계정의 무단 이용으로 인하여 귀하가 입은 손실에
              대하여 책임을 지지는 않지만, 귀하는 그러한 무단 이용으로 인하여
              솔루션 또는 타인이 입은 손실에 대하여 책임을 질 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제27조 (책임의 제한)</PolicyItemTitle>
            <PolicyItemDescription>
              법률이 허용하는 최대 범위 내에서, 솔루션, 솔루션의 임직원, 이사나
              대리인은 보증, 계약, 불법행위나 기타 법리에 근거한 것인지 여부에
              관계없이, 또한 회사가 그러 한 손해의 가능성에 대하여 고지받았는지
              여부와 관계없이 어떠한 경우에도 콘텐츠의 오류, 과실이나 부정확성,
              당사의 서비스에 대한 귀하의 접속 및 사용으로 인하 여 발생하는
              여하한 성질의 상해(personal injury) 또는 재산적 손해(property
              damage), 당사의 보안서버 및/또는 그에 저장된 모든 개인정보 및/또는
              금융정보에 대한 무단 접속 또는 무단 사용, 당사 서비스로 또는 당사
              서비스로부터의 전송 장애 또는 중단, 제3자가 당사 서비스로 또는
              당사 서비스를 통하여 전송하는 버그, 바 이러스, 트로이 목마 및
              유사한 것, 및/또는 콘텐츠의 오류 또는 누락이나 본 서비스에 게재,
              이메일 송부, 전송되거나 달리 본 서비스를 통하여 제공된 콘텐츠의
              귀하 의 사용으로 인하여 발생하는 여하한 종류의 손실이나 손해로부터
              발생하는 직접, 간접, 부수적, 특별, 징벌적인 손실이나 경비 또는
              결과적 손해에 있어서 귀하에 대 하여 책임을 부담하지 않습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              당사는 일부 관할권에서는 법적으로 배제될 수 없는 보증, 포기 및
              조건이 적용될 수 있다는 점을 이해합니다. 귀하의 관할권이 이에
              해당하는 경우, 법률이 허용하는 범위 내에서 솔루션은 그러한
              보증이나 조건에 근거한 청구에 대한 솔루션의 책임을 귀하에게 본
              서비스를 다시 제공하는 것 또는 본 서비스를 귀하에게 다시 제공하
              는데 소요되는 비용으로 한정합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              귀하는 특별히 솔루션은 제3자의 콘텐츠 또는 비방, 공격적이거나
              불법적인 행위에 대하여 책임지지 아니하며, 그러한 콘텐츠나 행위로
              인한 위해 또는 손해의 위험을 전적으로 귀하가 부담한다는 사실을
              인정합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              솔루션은 본 서비스가 타지역에서의 사용에 적절하다거나 가능하다고
              진술하지 않습니다. 다른 관할지역에서 본 서비스에 접속하거나
              이용하는 이용자들은 스스로의 결단으로서 본 서비스에 접속하거나
              이용하는 것이고, 현지 법률을 준수할 책임이 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제28조 (면책 조항)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 법률이 허용하는 범위 내에서, 귀하는 귀하의 본 서비스 이용 및
              접속, 귀하의 본 약관규정의 위반, 귀하의 제3자의 저작권, 재산권
              내지 프라이버시권 등 권리침해, 귀하의 콘텐츠가 제3자에게 손해를
              야기했다는 주장으로 인한 일체의 청구, 손해, 의무, 손실, 책임, 비용
              및 부채와 경비(변호사 비용을 포함하나 이에 한정되지 않음) 로부터
              솔루션, 솔루션의 모기업, 임직원, 이사 및 대리인을 보호하고
              면책하며 이로 인하여 손해를 입지않도록 하기로 동의합니다. 이러한
              방어 및 면책 의무는 본 약 관이나 귀하의 본 서비스 이용이 종료된
              이후에도 유효하게 존속합니다.
              <br />
              2. 솔루션은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
              제공 할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
              <br />
              3. 솔루션은 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나
              서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지
              않습니다.
              <br />
              4. 솔루션은 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도,
              정확성 등 내용에 관하여는 책임을 지지 않습니다.
              <br />
              5. 솔루션이 회원에게 제공하는 녹음파일을 인공지능 기술을 통해
              텍스트로 변환하여 제공하는 기능의 경우 발화자의 어조, 발음, 억양
              등에 따라 변환된 텍스트 파일이 실제 녹음파일과 다를 수 있습니다.
              솔루션은 변환된 텍스트 파일의 정확성, 신뢰성을 보증하지 않으며,
              변환된 텍스트 파일이 녹음파일과 불일치하여 발생하는 손해에 대하여
              어떠한 책임도 부담하지 않습니다.
              <br />
              6. 솔루션은 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데
              가입자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.
              <br />
              7. 솔루션은 회원이 서비스 내에 게시한 정보가 타인의 저작권,
              프로그램 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을
              부담하지 않습니다.
              <br />
              8. 솔루션은 회원이 솔루션의 앱과 웹의 관리 시스템 통하여 삭제한
              콘텐츠나 오디오, 텍스트 파일에 대하여 복구할 수 없으며,
              천재지변이나 회원의 귀책사유로 인하여 삭제된 콘텐츠 및 파일에
              대하여 어떠한 책임도 지지 않습니다.
              <br />
              9. 솔루션은 회원이 음담패설, 허위정보 혹은 범죄행위 등에 관한
              콘텐츠 및 오디오, 텍스트 파일을 유포하거나 회원이 대화 상대방의
              동의없이 무단으로 제3자에게 전송, 배포, 공유, 양도, 대여하는 경우,
              이로 인하여 발생하는 일체의 손해에 대하여 책임을 지지 않습니다.
              <br />
              10. 솔루션은 회원이 솔루션이 제공하는 서비스를 이용함에 있어서
              제3자에 대하여 개인정보 보호법, 통신비밀보호법, 저작권법 기타 관련
              법령상 필요한 동의 또는 안내 등 조치를 취하였다는 전제에서
              서비스를 제공하며, 회원이 위와 같은 조치를 취하지 않음으로 인하여
              발생하는 일체의 손해에 대하여 책임을 지지 않습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제29조 (양도)</PolicyItemTitle>
            <PolicyItemDescription>
              본 약관 및 본 약관상의 허여된 여하한 권리 및 라이센스는, 귀하가
              양도하거나 이전할 수는 없으나, 솔루션은 제한없이 양도할 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제30조 (관할법원)</PolicyItemTitle>
            <PolicyItemDescription>
              서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우,
              회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다. 일부 국가의
              법원에서는 분쟁 유형에 따라 대한민국 법률을 적용하지 않을 수
              있습니다. 귀하가 이러한 국가에 거주하고, 대한민국 법률의 적용이
              배제되는 경우, 거주 국가의 법률이 본 약관과 관련된 분쟁에 적용되며
              그렇지 않은 경우, 귀하는 본 약관 또는 서비스와 관련되거나,
              이로부터 야기된 일체의 분쟁에 대해 대한민국 법률이 적용되며,
              대한민국 국제사법의 적용은 배제된다는 것에 동의합니다. 마찬가지로,
              거주 국가의 법원이 귀하가 대한민국 서울지방법원의 관할에 합의하는
              것을 허용하지 않는 경우, 본 약관과 관련된 분쟁에 관하여 귀하의
              거주지 재판관할이나 법정지가 적용됩니다. 그렇지 않은 경우, 본 약관
              또는 서비스와 관련되거나, 이로부터 야기된 모든 청구는 대한민국
              법원이 전속관할을 가지며, 귀하와 회사는 해당 법원이 인적 관할을
              갖는 것에 동의합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>부 칙 1.</PolicyItemTitle>
            <PolicyItemDescription>
              이 약관은 2022년 01월 01일부터 적용됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>부 칙 2. 솔루션 저작권 정책</PolicyItemTitle>
            <PolicyItemDescription>
              솔루션에 업로드되는 저작물의 경우 아래 두가지 경우의 라이선스 정책
              중에서 해당하는 라이선스를 선택하여 등록할 수 있습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              1. 표준 흐름 라이선스
              <br />
              업로드한 모든 오디오에 표준 흐름 라이선스가
              기본 설정으로 유지됩니다. 표준 흐름 라이선스
              약관을 검토하려면 흐름(HREUM de salon) 서비스 약관을
              참조하세요.
              <br />
              2. Creative Commons 라이선스
              <br />
              크리에이티브 커먼즈 라이선스는 콘텐츠 크리에이터가 자신의 작품
              사용 권한을 다른 사용자에게 부여하는 표준 방식을 제공합니다.
              솔루션은 사용자가 오디오에 크 리에이티브 커먼즈 CC BY 라이선스를
              표시할 수 있도록 허용하고 있습니다. 오디오에 CC BY 라이선스 표시를
              넣으면 저작권은 본인이 보유하며 다른 사용자는 라이선스의 조항에
              의거하여 해당 작품을 재사용할 수 있습니다.
              <br />
              A. 흐름(HREUM de salon) 크리에이티브 커먼즈
              <br />
              모든 사용자는 업로드한 오디오에 크리에이티브 커먼즈 라이선스를
              표시할 수 있습니다. 크리에이티브 커먼즈 라이선스가 원본 콘텐츠에
              있기 때문에 오디오에 소유권 주장이 제기되면 크리에이티브 커먼즈
              라이선스로 표시할 수 없습니다. 원본 오디오를 크리에이티브 커먼즈
              라이선스로 표시하면 오디오를 재사용하고 수정할 수 있는 권리가 전체
              솔루션 커뮤니티에 부여됩니다.
              <br />
              B. 크리에이티브 커먼즈 라이선스 표시 대상
              <br />
              업로드한 오디오가 CC BY 라이선스에 따라 사용자가 라이선스를 부여할
              수 있는 콘텐츠로만 구성된 경우에 한해 크리에이티브 커먼즈
              라이선스로 표시할 수 있습니 다. 이와 같이 라이선스를 부여할 수
              있는 콘텐츠의 몇 가지 예는 다음과 같습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              • 직접 만든 콘텐츠
              <br />
              • CC BY 라이선스로 표시된 다른 오디오
              <br />• 공개 도메인에 있는 오디오
            </PolicyItemDescription>
            <PolicyItemDescription>
              *저작물은 시간이 지나면 저작권 보호를 받지 못하게 되어 모든
              사용자가 무료로 사용할 수 있는 ‘공개 도메인’에 속하게 된다고
              이야기 합니다. 저작물 발표 장소와 시 기 등 다양한 요인에 따라
              저작권 보호 기간은 달라지며 공개 도메인에 관한 규정은 국가별로
              다르다는 점을 주의하시기 바랍니다. 솔루션은 이용자의 콘텐츠에 대한
              저작권 보호 혹은 공개 도메인 여부를 보증하지 못하며 솔루션의
              서비스를 어떤 형태로든 이용한 자는 이에 동의한다고 간주됩니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              부 칙 3. 흐름(HREUM de salon) 서비스 커뮤니티 가이드라인
            </PolicyItemTitle>
            <PolicyItemDescription>
              흐름(HREUM de salon)의 목표는 오디오 콘텐츠를 통한 경험의
              즐거움을 극대화하는 동시에 생태계를 올바른 방향으로 이끌고 또한
              창의성을 고취시켜 행복한 서비스 경험을 선사하는 것입니다. 많은
              사람들이 서로의 플라와 살롱을 즐기고 이를 통해 새로운 음악을
              접하는 등, 많은 사람들과 오디오 콘텐츠를 통해 소통할 수 있는
              글로벌 커뮤니티를 구축하기 위하여 노력하 고 있습니다. 나아가
              흐름(HREUM de salon)은 이 커뮤니티를 안전하게 유지할 수
              있도록 최선을 다하고 있습니다. 본 커뮤니티 가이드라인은 다양한
              국적과 넓은 문화권의 사용자 참여 를 매우 중요하게 생각하며,
              서비스를 제공하는 국가 혹인 지역의 문화 및 제도적 규범을
              존중합니다. 동시에 본 커뮤니티 가이드라인은 흐름(HREUM de
              salon)의 가치를 반영 하고 솔루션에서 제공하는 모든 서비스 상의
              행동강령을 규정함으로서 솔루션을 즐기는 모든 이들의 안전과
              즐거움을 지켜줄 것입니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              이를 위하여, 흐름(HREUM de salon)은 거짓으로 사용자를
              현혹시키거나 저작권을 포함한 타인의 이익에 해당되는 다양한 권리를
              침해하는 콘텐츠 및 계정을 삭제 조치하며, 사용자들 간의 긍정적인
              상호 작용을 위한 환경을 조성하고자 노력하고 있습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              본 흐름(HREUM de salon) 커뮤니티 가이드라인은 기본적으로
              흐름(HREUM de salon)의 이용약관을 기초로하며 서비스의 가치를
              더했습니다. 우리는 커뮤니티 가이드라인을 위반하는 동영상, 오디오,
              이미지, 텍스트 등의 콘텐츠를 삭제하고, 반복적으로 또는 심각한
              위반이 발견되는 계정은 일시적으로 정지하거나 사용을 금지합니다.
              특정 상황의 경우에는 커뮤니 티를 안전하게 유지하기 위해 그러한
              계정을 해당 국가의 관련 규제 기관에 보고합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              커뮤니티 가이드라인은 모든 사용자 및 흐름(HREUM de
              salon)에서 공유되는 모든 내용에 적용되며, 흐름(HREUM de
              salon) 플랫폼에서 허용되는 것과 허용되지 않는 것에 대한 일반적인
              지침을 제공합니다. 또한, 우리는 가이드라인에 따라 삭제되는 일부
              콘텐츠가 대중에게는 가치가 있는 콘텐츠임을 인지하고 있습니다.
              따라서, 아래에 명시된 바와 같이 특정 상황에서는 예외가 있을 수
              있습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              우리는 커뮤니티 환경에 발맞춰 계속 발전하고, 새로운 위험 요소를
              없애며, 흐름(HREUM de salon)이 창의성을 고취하고 즐거움을 줄
              수 있는 안전한 플랫폼으로 유지될 수 있도록 커뮤니티 가이드라인을
              정기적으로 업데이트합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              서비스 커뮤니티 가이드라인은 흐름(HREUM de salon) 플랫폼의
              모든 콘텐츠가 준수해야하는 최소한의 가이드라인 입니다. 아래 내용을
              숙지하시고 위반 사항이 없도록 각별히 주의해 주십시오. 위반 사항
              발견시 예고 없이 유료 혹은 무료 서비스 이용에 대한 제한을 받을 수
              있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>1.불건전한 콘텐츠</PolicyItemTitle>
            <PolicyItemDescription>
              우리는 위험 인물 또는 위험 조직이 흐름(HREUM de salon)
              플랫폼을 이용해 테러, 범죄 또는 타인에게 해를 가할 수 있는 행동을
              조장하는 것을 허용하지 않으며, 나아가 범죄 행위의 묘 사 또는
              조장뿐만 아니라 특정 규제 품목의 거래, 판매, 판촉 및 사용을
              금지합니다. 또한, 지나치게 폭력적이거나 충격적인 콘텐츠, 특히
              폭력, 고통을 조장하거나 또는 미화하는 콘텐츠는 허용되지 않습니다.
              특정 상황, 예를 들어 뉴스로서 가치가 있 거나 문제에 대한 인식을
              높이기 위한 콘텐츠는 예외로 허용됩니다. 집단 따돌림, 헤이트
              스피치와 같은 보호받는 특정, 불특정 다수 혹은 개인에 대한 공격성이
              담긴 콘텐츠도 허용하지 않습니다. 흐름(HREUM de salon)은
              과도한 성적 만족을 위한 플랫폼이 아닙니다. 이에 선정적 콘텐츠의
              경우 예고없이 서비스 이용에 제한을 받을 수 있으며 관계 법령에
              의거하여 국가의 수사기 관에 적극 협조합니다. 그 밖의 아래에
              해당된다고 판단되는 모든 콘텐츠는 플랫폼 상에서의 생성 부터 타
              플랫폼으로의 공유까지 모든 것이 금지됩니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              - 아동의 안전에 심각한 해를 입히는 콘텐츠
              <br />
              - 스팸, 명의도용, 허위 정보 유포 등 진실성과 진정성이 심각하게
              결여된 콘텐츠
              <br />- 지식재산권이라고 인정될 수 있는 모든 종류의 콘텐츠에 대해
              타인의 지식재산권을 침해한다고 생각되는 모든 콘텐츠
            </PolicyItemDescription>

            <PolicyItemTitle>2. 플랫폼에 대한 기술적 위협</PolicyItemTitle>
            <PolicyItemDescription>
              - 흐름(HREUM de salon) 솔루션 서비스 그룹은 해당 솔루션과
              관련된 모든 네트워크에 대한 해킹, 사용자들의 액세스를 제한, 강제
              우회하는 행위를 금지합니다.
              <br />
              - 트로이 목마, 로직폭탄 등 기술적으로 고안된 악의적이거나 의도가
              불분명하더라도 플랫폼의 서비스 제공에 있어서 유해하다고 생각되는
              파일의 유포
              <br />
              - 수정, 디컴파일 등 수단을 막론하고 플랫폼의 허락을 받지 않은 흐름
              플랫폼에 기반한 모든 종류의 파생 제품(문서, 데이터셋, 테이블,
              알고리즘, 소스코드)생산을 금 지 합니다.
              <br />- 플랫폼에 대한 모든 종류의 자동화 스크립트를 사용한 데이터
              수집 행위를 금지 합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>3. 저작권 존중</PolicyItemTitle>
            <PolicyItemDescription>
              흐름(HREUM de salon) 플랫폼은 대한민국에서 ‘디지털음성송신’
              계약을 기반으로 다양한 종류의 오디오 콘텐츠 서비스를 제공하고
              있습니다. 이에 해당 계약과 관련된 국가 법령을 위반하는 모든 저작권
              침해 행위를 금지 합니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              흐름(HREUM de salon)은 글로벌 오디오 산업의 파트너로서
              서비스 국가의 저작권과 관련된 법령을 철저히 준수하고자 노력합니다.
              저작권자 본인이 자신의 권리가 침해되었다고 생각되는 경우
              흐름(HREUM de salon) 고객센터를 통해 신고해 주십시오.
            </PolicyItemDescription>

            {/* 유료서비스 이용약관  */}
            <PolicyTitle>
              흐름(HREUM de salon) 유료 서비스 이용약관
            </PolicyTitle>

            <PolicyItemPointTitle>제1장 총 칙</PolicyItemPointTitle>

            <PolicyItemTitle>제1조 (목적)</PolicyItemTitle>
            <PolicyItemDescription>
              본 약관은 대한민국 소재의 ㈜나이비가 『흐름(HREUM de
              salon)』(이하 “솔루션”, “회사”)라는 이름으로 제공하는
              디지털음성송신 서비스 및 관리, 그리고 솔루션 관련 제반 서비스의
              무료, 유료서비스를 이용함에 있어 솔루션과 회원간 제반 법률관계 및
              기타 관련 사항을 규정함을 목적으로 합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제2조 (용어의 정의)</PolicyItemTitle>
            <PolicyItemDescription>
              이 약관에서 사용되는 용어의 정의는 다음과 같습니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              1. ‘회원가입’이라 함은 유료서비스를 포함하여 솔루션이 제공하는
              서비스를 이용하고자 하는 자(‘고객’이라고도 합니다)가 이용약관에
              동의하고 회사와 그 이용계약 을 체결하는 것을 의미합니다.
              회원가입을 하는 경우 고객은 솔루션의 회원이 됩니다.
              <br />
              2. ‘회원탈퇴’라 함은 회원이 흐름(HREUM de salon) 서비스
              이용약관 또는 흐름(HREUM de salon) 유료서비스 이용약관의
              체결로 발생한 제반 권리와 의무 등의 법률관계를 자의로 영구히
              종료하거나, 포기하는 것을 의미합니다.
              <br />
              3. ‘유료서비스’라 함은 회원이 회사에 일정 금액을 지불해야만 이용할
              수 있는 회사의 서비스 또는 이용권(‘상품’과 같은 의미이며 이하
              솔루션 내에서 부르는 “플 랜”으로 지칭)을 의미하거나, 회사 또는
              제3자와의 거래 내지 약정 조건을 수락하는 대가로 이용하게 되는
              회사의 서비스 또는 플랜을 의미합니다. 유료서비스의 세부 내용은
              각각의 서비스 또는 플랜 결제 페이지 및 해당 서비스 또는 플랜의
              결제 페이지에 상세히 설명되어 있습니다. 플랜 이용중인 계정을
              제3자로부터 양도 받는 행 위, 회사와 계약을 통해 솔루션이 제공하는
              서비스를 이용하는 제휴 서비스의 이용자 또한 본 약관을 적용 합니다.
              <br />
              4. ‘유료회원’이라 함은 별도의 금액을 지불하여 유료서비스를 구매한
              회원 및 회사 또는 제3자가 정하는 이벤트, 마케팅에 참여하는 등의
              방법으로 회사의 유료서비 스를 이용하는 회원을 말합니다.
              <br />
              5. ‘무료회원’이라 함은 유료회원이 아닌 회원으로 회원가입 이후에
              기본적으로 모든 회원에게 부여되는 자격을 가진 회원을 의미합니다.
              <br />
              6. ‘이용자’라 함은 중복되지 않는 솔루션이 제공하는 서비스를 받는
              회원 및 비회원을 포함하여 중복되지 않은 월간 서비스를 이용하는
              사용자(유료회원)
              <br />
              7. ‘결제’라 함은 회원이 특정 유료서비스를 이용하기 위하여 각종
              지불수단을 통하여 회사가 정한 일정 금액을 일시적으로 혹은 정해진
              날짜에 회사에 지불하는 것을 의미합니다.
              <br />
              8. ‘학생 인증’이라 함은 플랜 종류 중 유료서비스 구매하는 회원이
              학생임을 인증하고 할인된 가격으로 구매할 수 있는 ‘For
              Student’플랜의 결제에 필요한 인증 입 니다.
            </PolicyItemDescription>
            <PolicyItemDescription>
              이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을
              제외하고는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제3조 (약관의 효력/변경 등)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 이 약관은 유료서비스를 이용하기를 희망하는 회원이 동의함으로써
              효력이 발생하며, 회원이 이 약관에 대하여 “동의하기”와 같은 버튼을
              클릭한 경우 혹은 회사 에서 정한 적절한 전자적인 방법을 통해 약관
              내용을 전달한 뒤 유료서비스를 이용하는 행위 처럼 통상 약관에 대한
              동의로 생각될 수 있는 경우 이 약관의 내용을 충분 히 이해하고 그
              적용에 동의한 것으로 봅니다.
              <br />
              2. 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수
              있습니다. 회사가 약관을 개정하는 경우에는 해당 약관의 적절한 곳에
              적용일자 및 변경내용을 명 시합니다. 다만, 회원에게 불리한 약관의
              변경의 경우에는 그 개정 내용을 고객이 알기 쉽도록 전자적인 방법을
              통해 개별 혹은 전체적으로 통지합니다. 회원의 연락처 미기재,
              변경,오류 등으로 인하여 개별 통지가 어려운 경우 이 약관에 대해
              보다 알기 쉽도록 전자적인 방법으로 회원 전체에게 보여질 수 있는
              곳을 통해 통지 합니다.
              <br />
              3. 회사가 제2항의 공지 또는 통보를 하면서 회원이 적극적인 거부
              의사를 표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는
              내용을 고지하였으나, 회원 이 명시적으로 약관 변경에 대한
              거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행일자 부로
              변경 약관에 동의한 것으로 간주합니다. 개정/변경 약관에 대하여
              거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수
              있습니다. 다만, 계약의 해지, 또는 회원 탈퇴로 인해 서비스 이용에
              대한 일부 불이익이 있을 수 있 습니다.
              <br />
              4. 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지
              적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이
              탈퇴 후에도 유효하게 적용될 수 있습니다.
              <br />
              5. 이 유료서비스약관의 제반 규정은, 흐름(HREUM de salon)의
              관련 규정에 우선 적용되며, 이용약관과 이 약관의 정의, 내용 등이
              서로 상충되는 경우 이 약관의 관련 규정을 적용합니다. 이 약관에
              명시되지 아니한 사항에 대하여는 콘텐츠산업진흥법, 전자상거래
              등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률 등 관련
              법령에 따릅니다.
            </PolicyItemDescription>

            <PolicyItemPointTitle>
              제 2장 유료서비스 이용 계약
            </PolicyItemPointTitle>

            <PolicyItemTitle>
              제4조 (유료서비스 이용계약의 성립 및 승낙의 제한)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 유료서비스에 대한 이용계약은 이 약관에 동의한다는
              의사표시(동의함을 선택과 같은 3조 1항의 설명 참조)와 함께 이용
              신청을 하고 회사가 이를 승낙함으로써 비 로소 성립합니다.
              <br />
              2. 회원은 유료서비스 이용계약을 체결하기 전에 해당 유료서비스에
              대하여 이 약관에서 회사가 명시, 고지하는 사항을 숙지하고, 착오
              없이 정확히 거래할 수 있도록 하여야 합니다.
              <br />
              3. 회사와 회원간 유료서비스 이용계약은 회사의 승낙이 회원에게
              도달한 시점(유료서비스의 “구매/결제완료 등”의 표시가 회원에게
              절차상 표시된 시점)에 성립합 니다.
              <br />
              4. 회사는 흐름(HREUM de salon) 이용약관에서 명시한 제한 혹은
              위반 사유가 발생하는 경우, 사용자의 이용신청을 승낙하지 아니하거나
              소정의 조건 성취 또는 제한 사유가 해소될 때까지 일정 기간 동안
              승낙을 유보할 수 있습니다.
              <br />
              5. 회원은 유료서비스 이용 신청을 위한 제반 사항을 기재할 경우
              회원의 현재의 사실과 일치하는 정보를 기재하여야 하며, 회원이
              이용하고자 하는 유료서비스의 결제 방법의 선택 및 선택한 결제방법에
              필요한 결제정보를 정확히 회사에 제공하여야 하며, 해당 사항이
              변경될 경우 지체 없이 회사가 정한 절차에 따라 변경사항을 고지,
              반영하여야 합니다.
              <br />
              6. 회사는 흐름(HREUM de salon) 서비스 이용약관의 기재사항
              이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수
              있습니다. 이를 위해 회사가 문의한 사항에 대 해 회원은 성실하게
              고지합니다. 회사는 이 경우 수집하는 회원의 정보를 흐름(HREUM
              de salon) 서비스 이용약관, 흐름(HREUM de salon) 유료 서비스
              이용약관, 개인정보처리방침 및 정보통신망의 이용촉진 및 정보보호
              등에 관한 법률이 정한 바에 따라 이용, 관리합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제5조 (결제수단 등)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회원이 유료서비스의 결제를 위하여 이용할 수 있는 지불수단은
              다음 각 호와 같습니다.
              <PolicyItemSubBlock>
                ① 신용카드
                <br />
                ② 휴대폰 이동통신사 요금
                <br />
                ③ 오픈 마켓 사업자(스토어)
                <br />④ 기타 회사가 제3자와 제휴계약 등을 체결하여 회원에게 결제
                가능 여부 및 그 방법을 안내하게 되는 결제 수단에 의한 대금 지급
              </PolicyItemSubBlock>
              2. 결제 가격 중 외화 결제 시 환율 및 구매 스토어 수수료 등으로
              예상 지불 금액과 실제 청구 금액에 차이가 발생할 수 있습니다.
              <br />
              3. 결제를 통해 회원은 아래와 같은 행위를 할 수 있습니다.
              <br />
              - 유료서비스의 구입 및 가입
              <br />
              4. 회사는 유료서비스를 이용하는 회원의 거래금액에 대하여 내부정책
              및 외부 결제업체(은행사, 카드사 등), 기타 관련 법령의 변경에 따라
              회원 당 월 누적 결제액, 결제 한도 등의 거래한도를 정할 수 있으며,
              회원은 회사가 정한 거래한도를 초과하는 범위의 유료서비스를
              이용하고자 할 경우 거래한도의 초과로 인하여 유료서비 스의 추가
              이용이 불가능할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemPointTitle>제3장 유료 서비스 이용</PolicyItemPointTitle>

            <PolicyItemTitle>제6조 (유료 서비스와 유료회원)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사가 회원의 이용신청을 승낙한 때(신규 및 일부 서비스의 경우,
              이용 신청 접수 후 사전 공지한 지정된 일자)로부터 유료 서비스는
              개시되며, 회사의 기술적 사유 등 기타 사정에 의하여 서비스를 개시할
              수 없는 경우에는 제3조(약관의 효력/변경 등)의 방법에 따라 회원에게
              사전 공지합니다.
              <br />
              2. 회사는 회원의 이용신청이 있게 되는 경우, 그 사실을 통지하며,
              회사의 통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 불일치
              사실을 정정 또는 수정을 요 청하여야 하며, 회사는 회원의 요청에
              따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약
              철회 등에 관한 제9조(청약 철회 및 서비스 이용계약의 해제 • 해지)의
              규정에 따릅니다.
              <br />
              3. 회사는 다음 각 호와 같은 유료 서비스를 제공하며, 회사의 사정,
              기타 제반 여건에 따라 서비스 내용과 속성을 추가하거나 변경할 수
              있습니다. 각 유료서비스의 구 체적인 내용은 홈페이지의 적절한 곳에
              공지하고 있습니다.
              <br />
              ①Standard(For Student) : 이용계약 시점부터 솔루션 내의 모든 광고를
              삭제하고 웹에서 플라 재생이 가능한 속성을 갖는 유료 서비스이며
              회원이 등록한 결제수단을 통하여 1개월 단위 혹은 3개월 단위로
              이용요금이 자동으로 결제되고 해당 단위 만큼 이용기간이 자동
              갱신되는 서비스
              <br />
              * For Student의 경우 Standard와 서비스 내용과 속성이 동일하지만
              일정기간 동안 회원의 학생 인증 여부에 따라 할인된 금액으로
              제공되는 유료 서비스
              <br />
              4. 회사는 음악 외의 기타 콘텐츠나 부가서비스(어학, 가사, 동영상
              등)가 함께 제공되는 결합 서비스, 다른 회사와의 계약을 통한 제휴
              서비스 등을 제공할 수 있습니 다.
              <br />
              5. 회원이 휴대폰, 무선 모뎀이 내장/장착된 PC 등에서 무선네트워크를
              통하여 솔루션 웹사이트에 접속하거나 무선네트워크가 연결된 상태의
              기기 내에 탑재된 솔루션 어플리케이션을 통하여 솔루션의 제반 서비스
              및 플랜을 이용하는 경우, 해외서비스 제공 가능시 해외에서 솔루션의
              제반 서비스 및 플랜을 이용하게 되는 경우에는 회원과 회원이 가입한
              해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가
              발생합니다. 이 경우 발생하는 데이터 통화료는 회원과 해당
              통신사간에 체결된 통신 요금제에 따라 과금/청구/수납 되므로, 데이터
              통화료 분에 대해서는 회사는 어떠한 책임도 지지 않습니다.
              <br />
              6. 회사와 음원의 권리를 보유하고 있는 개인, 단체, 법인(‘권리자
              등’) 간의 계약의 체결 여부 및 개별 계약의 내용, ‘권리자 등’의 사후
              요청에 따라 개별 유료서비스 또 는 음악파일의 이용이 제한되거나
              서비스 가능 지역, 음악파일의 속성 등 내용이 사전 또는 사후 변경될
              수 있습니다. 회사는 ‘권리자 등’의 요청이 있게 되는 경우, 해 당
              요청에 따라 유료서비스를 지원하는 기기의 종류, 회원별 이용 가능
              기기 대 수, 광고 삽입 여부 등 콘텐츠 제공에 필요한 제반 내용의
              일부를 변경할 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제7조 (Standard 플랜과 For Student 플랜, 별, 포인트)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. Standard 플랜은 회원이 해당 플랜의 결제 왼료시부터 약정 기간
              동안 흐름(HREUM de salon) 웹과 앱에서 광고를 제거한 채
              솔루션을 통한 오디오 감상을 실시간으로 이용할 수 있는 유료
              서비스입니다.
              <br />
              2. 모든 플랜은 1개월 단위 혹은 3개월 단위의 정기결제형이 있습니다.
              <br />
              3. 플랜의 종류 및 결제수단, 이용기간, 이용가능 기기와 그 대수 등
              조건에 대한 상세사항은플랜 선택 페이지 혹은 결제 페이지 등에 게시,
              안내합니다.
              <br />
              4. For Student 플랜은 Standard 플랜이 유료 서비스 이용자에게
              제공하는 모든 혜택과 속성이 동일하지만, 일정기간 동안만 결제할 수
              있는 회원의 학생 인증 여부 에 따라 할인 혜택을 드리는 플랜 입니다.
              <br />
              즉, For Student 플랜은 회사의 내부 사정에 의해 그 할인 이벤트가
              예고없이 종료될 수 있습니다.
              <br />
              5. 별은 회원이 결제완료 시부터 약정 기간 동안 회원이 스스로
              보유하거나 흐름 내 다른 회원에게 선물할 수 있습니다. 별은
              회사에서 별도로 공지하는 전환율 및 정책에 따라 포인트로 변환할 수
              있습니다.
              <br />
              6. 별은 구매일 및 보유하게 된 날짜로부터 3년 이내에 사용해야 하고,
              기간 내 사용되지 않은 별은 상실시효에 근거하여 자동 소멸됩니다.
              다만, 다른 법령에서 이보다 단기의 시효의 규정이 있는 때에는 그
              규정에 의합니다.
              <br />
              7. 포인트는 회사에서 별도로 공지하는 환전율, 지급시기 및 정책에
              따라 현금으로 환전할 수 있는 전자적 형태의 재화입니다.
              <br />
              8. 포인트 환전은 세금 신고를 위해 운영사인 (주)나이비 소재지인
              대한민국의 국민만 가능하며, 향후 환전할 수 있는 국가는 추가 될 수 있습니다.
            </PolicyItemDescription>

            <PolicyItemPointTitle>
              제4장 유료서비스의 변경, 청약 철회, 해지
            </PolicyItemPointTitle>

            <PolicyItemTitle>
              제8조 (회원의 유료서비스 해지, 변경 등)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 회원이 이미 구매/가입한 유료 서비스는 원칙적으로 변경 처리가
              되지 않고 해지 후 익월을 위한 다른 플랜으로 가입하도록 되어
              있습니다.
              <br />
              2. 회사는 유료 서비스에 대한 변경이 있게 될 경우, 이 약관
              제3조(약관의 효력/변경 등)가 정하는 방법에 따라 그 사실 및 내용,
              변경일자를 적절한 방법으로 고지합니 다.
            </PolicyItemDescription>

            <PolicyItemTitle>
              제9조 (청약 철회 및 서비스 이용계약의 해제 • 해지)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 유료 회원은 이미 구매/결제한 유료 서비스를 구매/결제 철회하거나
              계약을 해지할 수 없는 것이 원칙이나 관계 법령에서 정한 청약 철회
              사유에 해당한다고 판단될 경우 해당 결제/구매일로 부터 7일 이내에
              회사에 전자적인 방법을 통해 공식적으로 철회 의사를 밝혀야 합니다.
              <br />
              2. 전자상거래 등에서의 소비자 보호에 관한 법률, 콘텐츠산업진흥법,
              콘텐츠이용자보호지침 등 기타 관계 법령에서 정한 청약 철회 제한
              사유에 해당하는 콘텐츠의 경우에 해당하는 경우에는 청약 철회가
              제한됩니다.
              <br />
              3. 회사는 회원이 유료서비스 내지 플랜을 구매하기 전 이용 화면에
              표시, 약관 링크 연결 등의 방법을 통해 아래 각 호의 사항을 회원이
              알 수 있도록 안내 합니다.
              <PolicyItemSubBlock>
                ① 유료서비스/플랜 이름
                <br />
                ② 가격, 자동 결제 여부, 청약 철회 및 해지 가능 여부, 환불 등
                거래조건
                <br />
                ③ 이용 가능 계정 수, 및 기기 사양
                <br />④ 콘텐츠의 교환,반품, 보증과 그 대금 환급의 조건 및 절차
              </PolicyItemSubBlock>
              4. 청약 철회 의사 표현은 회원이 전자우편 등의 전자적인 방법으로 할
              수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이
              발생하고, 회사는 회원의 의사표시를 수령한 후 불필요한 지체 없이
              의사에 대한 응답을 회원에게 회신합니다.
              <br />
              5. 회사는 회원이 청약 철회, 해지/해제 신청을 확인 후 환불 금액이
              있을 경우, 원칙적으로 회원의 해당 의사표시를 수령한 날로부터
              3영업일 이내에 결제수단 별 사업자에게 대금의 청구 정지 내지 취소를
              요청하고, 회원이 결제한 동일 결제수단으로 환불함을 원칙으로
              합니다. 단, 회사가 사전에 회원에게 공지한 경우 및 아래의 각 경우와
              같이 개별 결제 수단별 환불 방법, 환불 가능 기간 등이 차이가 있을
              수 있습니다.
              <PolicyItemSubBlock>
                ① 신용카드 등 수납 확인이 필요한 결제수단의 경우에는 수납
                확인일로부터 3영업일 이내
                <br />
                ② 결제수단 별 사업자가 회사와의 약정을 통하여 사전에 대금 청구
                정지 내지 결제 취소 가능 기한 등을 미리 정하여 둔 경우로 해당
                기한을 지난 환불의 경우
                <br />
                ③ 회원이 유료서비스/ 플랜의 이용 결과, 얻은 이익이 있거나 중도
                해지의 경우
                <br />
                ④ 회원이 환불 처리에 필요한 정보 내지 자료를 회사에 즉시
                제공하지 않거나 잘못된 정보를 제공할 경우
                <br />
                (현금 환불 시 신청인의 계좌 및 신분증 사본을 제출하지
                아니하거나, 타인 명의의 계좌를 제공하는 경우 등)
                <br />
                ⑤ 해당 회원의 명시적 의사표시가 있는 경우
                <br />⑥ 결제 이후 서비스를 이용하지 않은 상태에서 3일 내 환불
                요청 시 휴대폰 결제의 경우 결제 당월은 결제 취소만 가능, 익월의
                경우 휴대폰 결제 건 입금 확인 후 결제자 본인 계좌로만 환불 가능.
              </PolicyItemSubBlock>
              6. 자동결제 플랜을 결제한 회원이 해당 플랜을 중도 해지한 경우,
              회사는 회원에게 해지 시점 전, 예정되어 있던 다음 결제일까지 해당
              플랜의 혜택을 제공하고 이미 결제된 금액에 대해서는 환불하지
              않습니다.
              <br />
              7. 회사는 콘텐츠이용자보호지침 등에 따라, 회사가 부담하였거나
              부담할 부대비용 등을 수수료를 차감하여 환불할 수 있습니다.
              <br />
              8. 회원이 유료서비스 또는 플랜을 선물 받거나, 프로모션 등을 통해
              무료/무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니한
              서비스에 대하여는 회사는 환불 의무를 부담하지 않습니다.
              <br />
              9. 회원이 서비스 이용약관, 유료 서비스 이용약관에서 정하는 회원 및
              사용자의 의무를 위반하였을 경우, 계약을 해제, 해지하거나 서비스
              이용 제한, 손해배상 청구 등의 조치를 취할 수 있습니다.
              <br />
              10. 회원의 신청 또는 동의에 따라 정기결제 중인 유료서비스의 경우,
              해당 회원이 유료서비스의 이용요금을 체납하는 경우 연체가 발생한 날
              자동으로 플랜 해지가 될 수 있으므로, 정기결제를 통한 플랜 혜택을
              유지하고자 하는 회원은 이용요금의 체납 또는 결제수단의 연체가
              발생하지 않도록 사전에 조치하여야 합니다.
              <br />
              11. 정기결제 유료서비스를 이용 중인 회원이 탈퇴하는 경우 해당
              플랜은 즉시 해지되며, 이 경우 회원의 계정 정보와 내 채널의 저장
              내용 등 흐름(HREUM de salon) 솔루션 이용 내용은 관련 법령이
              정하는 경우를 제외하고는 흐름(HREUM de salon) 서비스
              이용약관 및 흐름(HREUM de salon) 개인정보처리방침 약관을
              따릅니다.
              <br />
              12. 별을 보유한 회원이 본 서비스로부터 탈퇴한 경우 유료서비스의
              구매 내역이 모두 사라지며, 따라서 환불이 불가능합니다.
              <br />
              13. 포인트 혹은 현금으로 환전이 일어난 유료서비스는 환불이
              불가능합니다.
              <br />
              14. 본 서비스를 마지막으로 사용한 날로부터 3년이 경과한 때에
              남아있는 상품은 환불이 불가능합니다.
              <br />
              15. 귀하는 언제든지 장래의 정액 서비스 사용을 중단할 수 있습니다.
              서비스 기간이 개시된 이후의 정액 서비스를 중도 취소할 경우, 결제
              플랫폼 수수료, 해지 시점까지의 기간에 해당하는 비용 및 해지에 따른
              위약금(잔여기간에 해당하는 요금의 10%)이 발생할 수 있다는 점을
              주지하시기 바랍니다. 그리고 중도 취소 시점에 유료서비스를 일부라도
              사용하신 경우, 해당 사용분에 대한 비용이 환급 금액에서 공제될 수
              있습니다. 정액 서비스가 적법하게 취소되는 경우, 해당 정액 서비스로
              지급된 별 또는 포인트는 다시 회수될 수 있습니다. 또한, 귀하가
              거주하는 국가의 법률에 따라 허용되는 모든 상품의 환불은 귀하가
              상품의 구매에 이용한 결제 플랫폼(예를 들어, 앱스토어)의 환불
              규정에 따라 처리되고 이에 따라 제한될 수 있습니다. 따라서, 해당
              사업자의 환불 규정 및 정책을 귀하가 직접 확인해 보실 것을
              권고합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제10조 (과오금)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사의 귀책사유로 과오금이 발생한 경우 과오금 입증 시점으로
              부터 30일 이내에 과오금 전액을 환불합니다. 단, 회원의 귀책사유로
              과오금이 발생한 경우, 회사 가 과오금을 환불하는데 소요되는 비용은
              합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을
              차감 후 과오금을 환불할 수 있습니다.
              <br />
              2. 과오금 입증 시점은 회사가 회원에게 과오금에 대한 내용을 통지한
              날로 합니다.
              <br />
              3. 회사는 정당하게 요금이 부과되었음을 입증하며 회원이 요구하는
              과오금에 대하여 환불을 거부할 수 있습니다.
              <br />
              4. 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 콘텐츠
              이용자보호지침이 정하는 바에 따릅니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제11조 (유료서비스의 정지, 중단)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사는 원칙적으로 연중무휴 1일 24시간 유료서비스를 제공합니다.
              <br />
              2. 회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검 및
              보수 시에는 유료서비스의 전부 또는 일부의 제공을 제한, 중지,
              중단할 수 있습니다. 이 경우 회사 는 가능한 한 그 중단사유, 중단
              기간 등을 이 약관 제3조(약관의 효력/변경 등)의 방법을 통하여
              사전에 회원에게 공지하며, 아래 각 호의 불가피한 경우에는 경위와
              원인이 확인된 즉시 사후에 공지할 수 있습니다.
              <PolicyItemSubBlock>
                ① 회원, 기타 불특정 제3자의 불법, 범죄행위로 인하여 기술적으로
                정상적인 서비스의 제공이 어려운 경우
                <br />
                ② 시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 또는
                유료서비스 이용의 폭주 등으로 정상적인 유료서비스 제공이
                불가능할 경우
                <br />③ 기타 천재지변, 국가비상사태, 정전 등 회사가 통제할 수
                없는 불가항력적 사유로 인한 경우
              </PolicyItemSubBlock>
              3. 회사는 제2항의 각 호에 해당하는 사유가 아닌 회사의 귀책사유로
              서비스의 제공이 중단됨으로 인하여 회원이 입은 손해에 대하여 콘텐츠
              이용자보호지침에서 정하는 바에 따라 배상합니다. 다만, 천재지변 등
              불가항력으로 인한 경우는 이용중지 또는 장애발생 시간에 산입하지
              아니합니다.
              <br />
              4. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책,
              운영상의 긴요한 사유로 수정, 중단, 변경할 수 있으며, 이에 대하여
              관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지
              않습니다.
            </PolicyItemDescription>

            <PolicyItemPointTitle>
              제5장 계약당사자의 의무, 손해배상 등
            </PolicyItemPointTitle>

            <PolicyItemTitle>
              제12조 (회원의 의무, 위반시 회사의 조치 등)
            </PolicyItemTitle>
            <PolicyItemDescription>
              1. 회원은 회사가 제공하는 유료서비스 이용 시 관계법령, 약관,
              세부이용지침, 서비스 이용안내 및 솔루션이 운영하는 서비스 사이트
              혹은 앱에 공지한 주의사항, 회 사가 서비스 이용과 관련하여 회원에게
              통지하는 커뮤니티 가이드라인과 같은 주의사항 등을 준수하여야 하며,
              기타 회사 및 타인의 명예를 훼손하거나 서비스 운영 기타 업무수행에
              지장을 초래하는 행위를 해서는 안됩니다.
              <br />
              2. 회원은 아이디 및 비밀번호를 관리할 책임이 있으며, 본인이 아닌
              타인이 사용하게 하여서는 안됩니다. 이를 위반하여 회원의 개인정보를
              타인이 사용하여 유료서 비스를 이용함으로써 발생한 결과에 대한
              책임은 회원에게 있습니다. 회원은 타인의 명의, 아이디, 비밀번호,
              휴대폰번호, 계좌번호, 신용카드번호 등 개인정보를 도 용하거나
              부정하게 사용하여서는 안됩니다.
              <br />
              3. 회원은 회사가 사전에 허용한 경우를 제외하고는 유료서비스를
              영업활동 등 영리목적으로 이용하거나 이 약관에서 금지하는 행위를
              하거나 허용하는 범위를 벗어 난 이용행위를 하여서는 안됩니다.
              <br />
              4. 회원은 유료서비스 이용과정에서 위법행위 또는 선량한 풍속 기타
              사회질서에 반하는 행위를 하여서는 아니 되며, 제3자와 유상으로
              거래하거나 현금으로 전환하 는 행위를 하여서는 안됩니다.
              <br />
              5. 회원은 유료서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이
              서비스의 이용 이외의 목적으로 사용하여서는 안됩니다.
              <br />
              6. 회원은 유료서비스를 사적 이용 범위 내에서 사용하여야 하고
              영리를 목적으로 하는 영업장, 매장 등에서 재생 등의 방법으로 사용할
              수 없습니다. 또한 플랜을 이 용하여 콘텐츠를 녹음, 녹화 등의
              방법으로 복제하여 불법적으로 유포, 공유하여서는 아니 되며, 이를
              위반하여 발생한 제반 문제에 대한 책임은 회원 본인에게 있습니 다.
              <br />
              (이 경우, 국내외 제3자의 저작권 등을 침해하는 행위로서 회사가 IP
              접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지
              조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를 기망하는
              수단과 방법을 통하여 솔루션의 서비스에 접속하는 등 제3자의 저작권
              등을 침해하는 행위를 포함합니다.)
              <br />
              7. 회사는 제1항 내지 제6항의 회원의 위반 행위가 있는 경우 해당
              회원에 대하여 위반 사실을 고지하고 서비스 제공을 일정기간 정지시킬
              수 있고, 동위반행위가 지 속적으로 재발할 경우 서비스 이용계약을
              해제 또는 해지할 수 있습니다. 본항의 회사의 의사표시는 회원에게
              도달한 날로부터 효력이 발생합니다. 회사의 해제/해지 및 이용 정지에
              대하여 회원은 회사가 정한 절차에 따라 이의신청할 수 있습니다.
              <br />
              8. 유료서비스 이용과 관련하여 진실한 정보를 입력하지 않은 회원은
              법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수
              있습니다.
              <br />
              9. 민법에 의한 미성년회원이 유료서비스를 이용하고자 하는 경우,
              법정대리인(부모님)의 동의를 얻거나 계약 체결 후 추인을 얻지 않으면
              미성년자 본인 또는 법정대 리인이 그 계약을 취소할 수 있습니다. 만
              14세 미만 아동이 서비스를 이용하기 위한 정보 제공 시에는
              법정대리인의 동의를 받아야 합니다.
              <br />
              10. 청소년 유해 매체물을 이용하기 위해서는 만 19세 이상의
              회원이어야 하며(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자를
              포함합니다), 정보 통신망 이용촉 진 및 정보 보호 등에 관한 법률 및
              청소년 보호법의 규정에 의한 인증수단을 통해 성인 인증을 받아야
              합니다. 인증을 받지 않는 경우, 해당 서비스의 이용이 제한됩니 다.
            </PolicyItemDescription>

            <PolicyItemTitle>제13조 (손해배상)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사는 서비스의 결함에 의하여 회사가 제공하는 유료서비스의
              내용인 콘텐츠가 손상, 훼손, 삭제되어 서비스 이용에 손해를 입은
              회원에게 해당 콘텐츠의 복원 또 는 그에 상응하는 적절한 방법으로
              배상합니다. 회원에게 손해가 발생한 경우 회사는 회원에게 실제
              발생한 손해만을 배상합니다. 다만, 회사의 고의 또는 과실 없이
              회원에게 발생한 일체의 손해에 대하여는 책임을 지지 아니합니다.
              회사는 이 약관에서 명시되지 아니한 회사의 귀책사유로 인하여
              유료서비스의 이용 회원에게 손해 가 발생한 경우 회사의 배상 책임과
              관련하여 회사는 ‘콘텐츠 이용자 보호지침’의 관련 규정 및 기타
              상관례를 따릅니다.
              <br />
              2. 고객이 이 약관의 이용 제한 관련 각 규정에 근거, 서비스의 이용이
              제한되거나 이용계약이 해지된 경우, 고객이 보유한 모든
              디지털콘텐츠의 사용권은 상실되며 회 사는 해당 디지털콘텐츠의
              구매대금을 반환할 의무를 부담하지 아니합니다.
              <br />
              3. 회원이 이 약관상의 의무를 위반함으로 인하여 회사에 손해가
              발생한 경우 또는 회원이 유료서비스를 이용함에 있어 회사에 손해를
              입힌 경우, 회원은 회사에 그 손 해를 배상하여야 합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제14조 (면책)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사는 다음 각 호의 사유로 인하여 회원 또는 제3자에게 발생한
              손해에 대하여는 그 책임을 지지 아니합니다.
              <PolicyItemSubBlock>
                ① 천재지변 또는 이에 준하는 불가항력으로 인해 유료서비스를
                제공할 수 없는 경우
                <br />
                ② 회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우
                <br />
                ③ 회사의 관리영역이 아닌 공중통신선로의 장애로 서비스이용이
                불가능한 경우
                <br />④ 기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한
                경우
              </PolicyItemSubBlock>
              2. 회사는 회원이 유료서비스를 이용하여 기대하는 수익을 얻지
              못하거나 상실한 것, 서비스에 게시된 콘텐츠에 대한 취사 선택 또는
              이용으로 발생하는 손해 등에 대 해서는 책임을 지지 않습니다. 또한
              회원이 서비스에 게재한 리뷰, 평점 등 게시물의 정확성 등 내용에
              대하여는 책임을 지지 않습니다.
              <br />
              3. 회사는 회원 상호간 또는 회원과 제3자 상호간에 유료서비스와
              관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사에
              귀책사유가 없는 한 이로 인하여 발 생한 손해를 배상할 책임이
              없습니다.
            </PolicyItemDescription>

            <PolicyItemTitle>제15조 (준거법과 분쟁의 해결)</PolicyItemTitle>
            <PolicyItemDescription>
              1. 회사와 이용자 간에 발생한 분쟁에 관한 소송은 대한민국
              서울중앙지방법원을 관할 법원으로 합니다. 다만, 제소 당시 이용자의
              주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
              민사소송법상의 관할법원에 제기합니다.
              <br />
              2. 회사와 이용자 간에 제기된 소송에는 한국법을 적용합니다.
            </PolicyItemDescription>

            <PolicyItemTitle>부 칙 1.</PolicyItemTitle>
            <PolicyItemDescription>
              이 약관은 2022년 01월 01일부터 적용됩니다.
            </PolicyItemDescription>
          </PolicContainer>
        </AgreeBox>
        <AgreeBox>
          <CheckTextBox
            text={
              <Flex>
                <H6 color="#646464">마케팅 정보 수신 동의</H6>
                <H6 color="#949494">(선택)</H6>
              </Flex>
            }
            style={{ justifyContent: 'left', marginBottom: '16px' }}
            checked={isAgreeMarketing}
            onClick={() => setIsAgreeMarketing(!isAgreeMarketing)}
          />
          <PolicContainer>
            <PolicyItemTitle>1. 광고성 정보의 이용목적</PolicyItemTitle>
            <PolicyItemDescription>
              흐름(HREUM de salon)이 제공하는 이용자 맞춤형 서비스 및 상품
              추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나
              서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해
              이용자에게 제공합니다.
            </PolicyItemDescription>
            <PolicyItemTitle>2. 미동의 시 불이익 사항</PolicyItemTitle>
            <PolicyItemDescription>
              개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의
              거부하시더라도 서비스 이용에 제한되지 않습니다.
              <br /> 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅
              정보 안내 서비스가 제한됩니다.
            </PolicyItemDescription>
            <PolicyItemTitle>3. 서비스 정보 수신 동의 철회</PolicyItemTitle>
            <PolicyItemDescription>
              흐름(HREUM de salon)에서 제공하는 마케팅 정보를 원하지 않을
              경우 ‘정보 수정 > 알림 설정‘에서 철회를 요청할 수 있습니다.
              <br /> 또한 향후 마케팅 활용에 새롭게 동의하고자 하는 경우에는
              ‘정보 수정 > 알림 설정’에서 동의하실 수 있습니다.
            </PolicyItemDescription>
            <PolicyItemTitle>시행일자 : 2020.08.11</PolicyItemTitle>
          </PolicContainer>
        </AgreeBox>
        <SubmitBtn
          disabled={!(termsAgree && privateAgree)}
          style={{ marginTop: '35px', alignSelf: 'center' }}
          onClick={() => setIsStep('register')}
        >
          동의하기
        </SubmitBtn>
      </Flex>
    </BodyWrapper>
  )
}

export default AccountAgreeTemplate
