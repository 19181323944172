import styled from 'styled-components'
import { Caption1 } from '.'

const WarningMsg = styled(Caption1)`
  color: #ea4653;
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-top: 6px;
`

export default WarningMsg
