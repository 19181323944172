import { H5, Flex, Caption1, Div } from '@components/atoms'
import { MixtapeListItem } from '@components/molecules'
import { colors } from '@resources/colors'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

// 헤더
const ListHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  max-width: 1607px;
  width: 100%;
`

const HomeHreumSection = ({ active = false }) => {
  const { userStore, mixtapeStore } = useStore()

  const latesMixtapeList = mixtapeStore.totalMixtapeList || []

  const fetchPersonalizationMixtapeList = mixtapeStore.fetchPersonalizationMixtapeList || (() => {})

  const fetchTotalMixtapeList = mixtapeStore.fetchTotalMixtapeList || (() => {})

  const [list, setList] = useState([])
  const [nextCursor, setNextCursor] = useState(null)

  const handleFetchHomeRecommendation = useCallback(
    async ({ __limit, init = false }) => {
      const { contentList, __nextCursor } = await fetchPersonalizationMixtapeList({
        __nextCursor: init ? null : nextCursor,
        __limit,
      }) || {}

      if (init) {
        setList(contentList)
      }
      else {
        setList(prev => prev.concat(contentList))
      }
      setNextCursor(__nextCursor)
    },
    [fetchPersonalizationMixtapeList, setList, setNextCursor, nextCursor],
  )

  useEffect(() => {
    if (active) {
      handleFetchHomeRecommendation({ __limit: 20 })
    }
    else {
      handleFetchHomeRecommendation({ __limit: 6, init: true })
      fetchTotalMixtapeList({ limit: 10 })
    }
  }, [active, fetchTotalMixtapeList])

  return (
    <Flex
      type="column"
      style={
        {
          maxWidth: 1704,
          width: '100%',
        }
      }
    >
      {
        list && list.length > 0 && (
          <>
            <ListHeader>
              <H5 type="Bold" align="left">
              인기 플라 | 음악 플레이리스트 라디오
              </H5>
              <Link to="/p/plra/list">
                <Caption1 color={colors.secondary} style={{ cursor: 'pointer' }}>
                더보기
                </Caption1>
              </Link>
            </ListHeader>

            <InfiniteScroll
              style={{ overflowX: 'hidden' }}
              dataLength={list.length}
              scrollThreshold={0.7}
              next={() => handleFetchHomeRecommendation({ __limit: 20 })}
              hasMore={active && nextCursor}
              scrollableTarget="scrollableDiv"
              hasChildren
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
            >
              <List>
                {
                  list
                && list.map(item => <MixtapeListItem item={item} key={item.id} />)
                }
                {
                  !active && (
                    <>
                      <Div width="100%" />
                      {
latesMixtapeList?.map((item, index) => {
  if (index < 6) {
    return <MixtapeListItem item={item} key={item.id} />
  }
})
                      }
                    </>
                  )
                }
              </List>
            </InfiniteScroll>
          </>
        )
      }
    </Flex>
  )
}

export default observer(HomeHreumSection)

const List = styled(Flex)`
  padding: 24px 0 48px 0;
  flex-wrap: wrap;
  width: calc(100% + 39px);

  gap: 24px;

  @media ${mobile} {
    justify-content: center;
  }
`
