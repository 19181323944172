import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Flex, Img } from '@components/atoms'
import { QuizBtn } from '.'
// import EventLayout from '../EventLayout'
// import Flex from '../Flex'
import { quizData } from './quizData'
import EventLayout from './EventLayout'

const hreumLogo = require('../../../public/images/hreum_logo.png')

// type Props = {
//   step: number
//   handleAnswer: Function
// }

const SocialkingQuizTemplate = ({ step, handleAnswer }) => {
  const [anime, setAnime] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  return (
    <EventLayout style={{ padding: 24 }}>
      <Flex
        type="row"
        style={
          {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }
        }
      >
        <HeaderText style={{ color: '#5d4afc' }}>
Q
          {step}
.
        </HeaderText>
        <ProgressText style={{ marginLeft: 16 }}>
          {step}
          {' '}
/ 12
        </ProgressText>
      </Flex>
      <QuizBox anime={anime} onAnimationEnd={() => setAnime(false)}>
        <QuizText
          style={
            {
              width: '100%',
              justifyContent: 'flex-start',
              margin: '48px 0',
              whiteSpace: 'pre-wrap',
              lineHeight: 1.67,
            }
          }
        >
          {quizData[step - 1].text}
        </QuizText>
        <QuizBtn
          quiz={quizData[step - 1].answer1}
          style={
            {
              marginBottom: 16,
              fontFamily: 'AppleSDGothicNeo',
              fontWeight: 600,
              fontSize: 16,
              height: 80,
            }
          }
          onClick={
            () => {
              setAnime(true)
              handleAnswer(quizData[step - 1], 'Y')
            }
          }
        />
        <QuizBtn
          quiz={quizData[step - 1].answer2}
          style={
            {
              fontFamily: 'AppleSDGothicNeo',
              fontWeight: 600,
              fontSize: 16,
              height: 80,
            }
          }
          onClick={
            () => {
              setAnime(true)
              handleAnswer(quizData[step - 1], 'N')
            }
          }
        />
      </QuizBox>
      <Img
        src={hreumLogo}
        width={132}
        height={24}
        alt="hreum_logo"
        style={{ marginTop: 32 }}
      />
    </EventLayout>
  )
}

export default SocialkingQuizTemplate

const QuizText = styled.h4`
  font-family: BMDoHyeon;
  font-size: 24px;
  margin: 0;
  line-height: 1.38;
  font-weight: 500;
`

const HeaderText = styled.p`
  font-family: BMDoHyeon;
  font-size: 32px;
  margin: 0;
  text-align: center;
  font-weight: normal;
`

const ProgressText = styled.p`
  font-family: BMDoHyeon;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  text-align: center;
  font-weight: 500;
  color: #646464;
`

const boxFade = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`

const QuizBox = styled.div`
  animation: ${({ anime }) =>
    anime
      ? css`
          ${boxFade} 0.2s
        `
      : ''};

  width: 100%;
  display: flex;
  flex-direction: column;
`
