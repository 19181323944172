import React, { useEffect } from 'react'
import { WarningUserPopup } from '@components/molecules'
import { MixtapeListTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const MixtapeListPage = () => {
  useRenderLogger('MixtapeListPage')

  const history = useHistory()
  const { mixtapeStore, searchStore, tagStore, authStore } = useStore()

  const { isShowing, toggle } = WarningUserPopup.usePopup()

  const handleSearchTag = async tag => {
    // fetch 태그 검색
    await searchStore.searchTagKeyword(tag)
    await searchStore.fetchTagLatestMixtapeList(tag)
    history.push(`/p/plra/list/tag?keyword=${tag}`)
  }

  // useEffect(() => {
  //   if (
  //     authStore.warningHistories
  //     && authStore.warningHistories[0]
  //     && !authStore.warningHistories[0].isReaded
  //   ) {
  //     toggle()
  //   }
  // }, [authStore, toggle])
  useEffect(() => {
    if (authStore.warningHistories && authStore.warningHistories.length > 0) {
      if (!authStore.warningHistories[0].isReaded) {
        toggle()
      }
    }
    // eslint-disable-next-line
  }, [authStore])

  useEffect(() => {
    mixtapeStore.fetchHomeMixtapeList()
    tagStore.fetchRepresentiveTagList()
  }, [mixtapeStore, tagStore])

  useEffect(() => {
    if (isMobile) {
      alert('해당 메뉴는 PC 또는 어플 환경에서 지원합니다.')
    }
  }, [])

  return (
    <>
      <MixtapeListTemplate
        handleSearchTag={handleSearchTag}
        myListenedMixtapeList={mixtapeStore.myListenedMixtapeList || []}
        totalMixtapeList={mixtapeStore.totalMixtapeList || []}
        myLikeMixtapeList={mixtapeStore.myLikeMixtapeList || []}
        myFollowMixtapeList={mixtapeStore.myFollowMixtapeList || []}
        representiveTagList={tagStore.representiveTagList || []}
      />
      <WarningUserPopup.View
        isShowing={isShowing}
        toggle={toggle}
        foreverClose={authStore.foreverClose}
      />
    </>
  )
}

export default observer(MixtapeListPage)
