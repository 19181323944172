export default class LiveRoomRecordingNetwork {
  constructor(network: Network) {
    this.network = network
  }

  checkLiveRoomRecording = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings/check`,
        'get',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork checkLiveRoomRecording error: ',
        error,
      )
    }
  }

  getLiveRoomRecordingList = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings`,
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork getLiveRoomRecordingList error: ',
        error,
      )
    }
  }

  startLiveRoomRecording = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings/start`,
        'post',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork startLiveRoomRecording error: ',
        error,
      )
    }
  }

  stopLiveRoomRecording = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings/stop`,
        'put',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork stopLiveRoomRecording error: ',
        error,
      )
    }
  }

  putLiveRoomRecording = async (liveRoomRecordingId, where) => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings/${liveRoomRecordingId}`,
        'put',
        where,
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork putLiveRoomRecording error: ',
        error,
      )
    }
  }

  deleteLiveRoomRecording = async liveRoomRecordingId => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/live-room-recordings/${liveRoomRecordingId}`,
        'delete',
      )
      return data || {}
    }
    catch (error) {
      console.log(
        'LiveRoomRecordingNetwork deleteLiveRoomRecording error: ',
        error,
      )
    }
  }
}
