import React from 'react'
import { Caption1 } from '@components/atoms'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { FlexColumnBox, SongItem } from '@components/molecules'
import { useStore } from '@utils/hooks'

const SearchHistorytBox = styled(FlexColumnBox)`
  width: 100%;
  margin: 0;
  justify-content: flex-start;
  align-items: end;
  height: 100%;
  max-height: 550px;
`

const HistoryList = styled(FlexColumnBox)`
    width: 100%;
    align-items:end;

    p {
        margin 8px 0;
    }
`

const LikeAudioSection = () => {
  const { songStore, mixtapeStore } = useStore()
  const myLikeSongs = songStore.myLikeSongs || null

  const tempMixtape = mixtapeStore.tempMixtape || null
  const addMixtapeSongListToTempMixtape =
    mixtapeStore.addMixtapeSongListToTempMixtape || (() => {})
  const deleteMixtapeSongToTempMixtape =
    mixtapeStore.deleteMixtapeSongToTempMixtape || (() => {})

  const handleAdd = item => {
    addMixtapeSongListToTempMixtape(tempMixtape, [item])
  }
  const handleDelete = item => {
    const mixtapeSongIndex =
      tempMixtape
      && tempMixtape['MixtapesHaveSongs'].findIndex(
        elem => elem['songId'] === item['id'],
      )

    deleteMixtapeSongToTempMixtape(tempMixtape, mixtapeSongIndex)
  }

  return (
    <FlexColumnBox>
      {
        myLikeSongs.length === 0 ? (
          <HistoryList>
            <Caption1>아직 좋아한 오디오가 없습니다.</Caption1>
          </HistoryList>
        ) : (
          <SearchHistorytBox>
            {
              myLikeSongs.map(item => (
                <SongItem
                  item={item}
                  key={item.id}
                  handleAdd={handleAdd}
                  handleDelete={handleDelete}
                  isExist={
                    tempMixtape
                && tempMixtape['MixtapesHaveSongs'].findIndex(
                  elem => elem['songId'] === item['id'],
                ) > -1
                  }
                />
              ))
            }
          </SearchHistorytBox>
        )
      }
    </FlexColumnBox>
  )
}

export default observer(LikeAudioSection)
