import React, { useEffect, useState } from 'react'
import { AccountAgreeTemplate } from '@components/templates'
import { useLocation, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useRenderLogger, useStore } from '@utils/hooks'

/**
 * 약관 동의 페이지
 * - 현재 카카오 유저만 사용하는 약관 동의 페이지임.
 * - 일반 유저는 RegisterPage에서 약관동의 분기처리 중.
 * 추후 이슈 발생시, 통합 예정
 * 2020. 09. 02
 */
const AccountPrivacyPage = () => {
  useRenderLogger('AccountPrivacyPage')
  const { authStore } = useStore()

  const kakaoRegister = authStore.kakaoRegister || (() => {})

  const location = useLocation()
  const history = useHistory()

  const [isStep, setIsStep] = useState('agree') // 회원 가입 단계
  const [termsAgree, setTermsAgree] = useState(false) // 이용약관 동의
  const [privateAgree, setPrivateAgree] = useState(false) // 개인정보 수집이용 동의
  const [isAgreeMarketing, setIsAgreeMarketing] = useState(false) // 마케팅 동의 여부

  useEffect(() => {
    const handleKakaoRegister = async () => {
      if (isStep === 'register') {
        const isRegistered = await kakaoRegister(isAgreeMarketing)
        if (isRegistered) {
          history.push('/')
        }
      }
    }
    handleKakaoRegister()
  }, [isStep, isAgreeMarketing, kakaoRegister, history])

  return (
    <AccountAgreeTemplate
      isKakao={location.state.isKakao} // 일반회원이랑 약관동의페이지 합쳐질 때 사용할 분기처리
      isAgreeMarketing={isAgreeMarketing}
      setIsAgreeMarketing={setIsAgreeMarketing}
      setIsStep={setIsStep}
      termsAgree={termsAgree}
      setTermsAgree={setTermsAgree}
      privateAgree={privateAgree}
      setPrivateAgree={setPrivateAgree}
    />
  )
}

export default observer(AccountPrivacyPage)
