import { H6 } from '@components/atoms'
import { colors } from '@resources/colors'
import { MobileLeadPopup } from '@components/molecules'
import { images } from '@resources/'
import {
  useRenderLogger,
  useStore,
  // useStore
} from '@utils/hooks'
import QueryString from 'qs'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SalonReserveTemplate } from '@components/templates'

const TermsSample = () => {
  useRenderLogger('TermsSample')

  useEffect(() => {
    return () => {}
  }, [])

  return (
    <div>
      <H6>사이드바 없고 로그인 안해도 접근 가능 페이지 (앱 유도 바도 없음)</H6>
    </div>
  )
}

export default observer(TermsSample)
