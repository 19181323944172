import * as React from 'react'

import styled from 'styled-components'

import { desktop } from '@styles/media'
import { PageLayout } from '@components/templates'
import { Helmet } from 'react-helmet'


const PolicContainer = styled.div`
  max-width: 1194px;
  margin: 40px auto 100px auto;
  padding: 20px;

  @media ${desktop} {
    margin: 80px auto 200px auto;
    padding: 0;
  }
`

const PolicyTitle = styled.h1`
  font-family: NotoSansCJKkr-Bold;
  font-size: 2.375rem;
  margin-bottom: 40px;
`

// const PolicyItemPointTitle = styled.p`
//   font-family: NotoSansCJKkr-Bold;
//   font-size: 1.5rem;
//   margin: 30px 0;
//   line-height: 1.5;
// `

const PolicyItemTitle = styled.p`
  font-family: NotoSansCJKkr-Bold;
  font-size: 1.125rem;
  margin: 30px 0;
  line-height: 1.5;
`

const PolicyItemDescription = styled.div`
  font-family: NotoSansCJKkr-Regular;
  font-size: 1.125rem;
  line-height: 1.89;
  color: #242424;
`

const PolicyMarketingPage = () => {
  return (
    <PageLayout>
      <Helmet>
      <title>흐름 마케팅 수신동의: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱</title>
        <meta
          name="description"
          content="지금 흐름에서 친구들과 함께 대화 주제, 분위기에 맞는 플라를 틀어놓고 음성으로 소통해 보세요! 오디오 SNS"
        />
        <meta property="og:title" content="흐름 마케팅 수신동의: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱" />
        <meta name="twitter:title" content="흐름 마케팅 수신동의: 소셜 오디오 커뮤니티 - HREUM de salon 흐름 드 살롱" />
        <meta property="og:url" content="https://hreum.me/p/policy/marketing/" />
        <meta name="twitter:domain" content="https://hreum.me/p/policy/marketing/" />
        <meta
          property="og:description"
          content="지금 흐름에서 친구들과 함께 대화 주제, 분위기에 맞는 플라를 틀어놓고 음성으로 소통해 보세요! 오디오 SNS"
        />
        <meta property="og:site_name" content="흐름 | HREUM de salon" />
        {/* <meta
          property="og:image"
          content="https://hello.hreum.me/images/hreum_landing_thumbnail.png"
        /> */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="지금 흐름에서 친구들과 함께 대화 주제, 분위기에 맞는 플라를 틀어놓고 음성으로 소통해 보세요! 오디오 SNS"
        />
        {/* <meta
          name="twitter:image"
          content="https://hello.hreum.me/images/hreum_landing_thumbnail.png"
        /> */}
      </Helmet>
      <PolicContainer>
        <PolicyTitle>흐름(HREUM) 마케팅 수신 동의</PolicyTitle>

        <PolicyItemTitle>1. 광고성 정보의 이용목적</PolicyItemTitle>
        <PolicyItemDescription>
          흐름(HREUM)이 제공하는 이용자 맞춤형 서비스 및 상품 추천, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나 서신우편, 문자(SMS 또는 카카오 알림톡), 푸시, 전화 등을 통해 이용자에게 제공합니다.
        </PolicyItemDescription>

        <PolicyItemTitle>2. 미동의 시 불이익 사항</PolicyItemTitle>
        <PolicyItemDescription>
          개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의 거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
        </PolicyItemDescription>

        <PolicyItemTitle>3. 서비스 정보 수신 동의 철회</PolicyItemTitle>
        <PolicyItemDescription>
          흐름(HREUM)에서 제공하는 마케팅 정보를 원하지 않을 경우 ‘내 채널 > 알림 설정‘에서 철회를 요청할 수 있습니다. 또한 향후 마케팅 활용에 새롭게 동의하고자 하는 경우에는 ‘내 채널 > 알림 설정’에서 동의하실 수 있습니다.
        </PolicyItemDescription>

        <PolicyItemTitle>시행일자 : 2021.01.01</PolicyItemTitle>
      </PolicContainer>
    </PageLayout>
  )
}

export default PolicyMarketingPage
