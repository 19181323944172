import React, { useState } from 'react'
import { NeedAdultTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import { useRenderLogger, useStore } from '@utils/hooks'

const NeedAdultPage = () => {
  useRenderLogger('NeedAdultPage')
  const { revenueStore } = useStore()

  const postAdultCertification =
    revenueStore.postAdultCertification || (() => {})
  const accountRevenueLicense = revenueStore.accountRevenueLicense || (() => {})

  const lastLocation = useLastLocation()

  const [complete, setComplete] = useState(false)

  return (
    <>
      {
        (lastLocation && lastLocation.pathname !== '/p/exchange/revenue/info')
        || (lastLocation === null && <Redirect to="/p/exchange/confirm-password" />)
      }
      {
        complete && (
          <Redirect
            to={{ pathname: '/p/exchange/revenue/info', state: { isAuth: true } }}
          />
        )
      }
      <NeedAdultTemplate
        postAdultCertification={postAdultCertification}
        accountRevenueLicense={accountRevenueLicense}
        setComplete={setComplete}
      />
    </>
  )
}

export default observer(NeedAdultPage)
