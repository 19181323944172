// date를 yyyy월 mm월 dd일 로 변환
export function dateFormat(value) {
  let date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}년 ${mm}월 ${dd}일 `)
}

// yyyy.mm.dd
export function dateYMD(value) {
  let date = new Date(value)
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}.${mm}.${dd}`)
}

// yyyy.mm.dd hh:mm
export function dateYMDMS(value) {
  let date = new Date(value)
  let _mm = date.getMinutes()
  let hh = date.getHours()
  let dd = date.getDate()
  let mm = date.getMonth() + 1 // January is 0!
  const yyyy = date.getFullYear()

  if (_mm < 10) {
    _mm = `0${_mm}`
  }
  if (hh < 10) {
    hh = `0${hh}`
  }
  if (dd < 10) {
    dd = `0${dd}`
  }
  if (mm < 10) {
    mm = `0${mm}`
  }
  return (date = `${yyyy}.${mm}.${dd} ${hh}:${_mm}`)
}

// m월 dd일 hh시 mm분
export function dateMDMS(value) {
  let date = new Date(value)
  let _mm = date.getMinutes()
  let hh = date.getHours()
  let dd = date.getDate()
  const mm = date.getMonth() + 1 // January is 0!

  if (_mm === 0) {
    _mm = ''
  }
  else if (_mm < 10) {
    _mm = `0${_mm}분`
  }
  else {
    _mm = `${_mm}분`
  }

  if (hh === 12) {
    hh = `오후 ${hh}`
  }
  else if (hh >= 13) {
    hh = `오후 ${hh - 12}`
  }
  else if (hh < 13) {
    hh = `오전 ${hh}`
  }

  if (dd < 10) {
    dd = `0${dd}`
  }
  // if (mm < 10) {
  //   mm = `0${mm}`
  // }
  return (date = `${mm}월 ${dd}일 ${hh}시 ${_mm}`)
}

export const castDuration = duration => {
  const tempH = Math.floor(duration / 3600)
  const convertH = tempH > 0 ? tempH : ''
  const tempM = Math.floor((duration - tempH * 3600) / 60)
  const convertM =
    tempM > 0
      ? tempM > 10
        ? tempM
        : tempH
          ? `0${tempM}`
          : tempM
      : tempH
        ? '00'
        : '0'
  const tempS = Math.floor(duration - (tempH * 3600 + tempM * 60))
  const convertS = tempS > 0 ? (tempS >= 10 ? tempS : `0${tempS}`) : '00'

  return tempH
    ? `${convertH}:${convertM}:${convertS}`
    : `${convertM}:${convertS}`
}

export const converseUnitEng = num => {
  let label = ''

  if (num <= 99999) {
    // 100,000 이하 숫자는 x,xxx 숫자 표현
    const str = num.toString()
    return (
      (str && str.replace && str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) || ''
    )
  }
  if (num > 9999999) {
    // 1,000,000 이하 숫자는 xxx.xm으로 표현
    label = 'm'
    return (Math.floor((num / 1000000) * 10) / 10).toFixed(1) + label
  }
  if (num > 99999) {
    // 100,000 이하 숫자는 xxx.xk로 표현
    label = 'k'
    return (Math.floor((num / 1000) * 10) / 10).toFixed(1) + label
  }
}

export const timeForToday = value => {
  const today = new Date()
  const timeValue = new Date(value)

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60,
  )
  if (betweenTime < 1) return '방금 전'
  if (betweenTime < 60) {
    return `${betweenTime}분 전`
  }

  const betweenTimeHour = Math.floor(betweenTime / 60)
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간 전`
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24)
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일 전`
  }

  return `${Math.floor(betweenTimeDay / 365)}년 전`
}

export const timeConvert = time => {
  const date = new Date(time)
  const currDate = new Date()

  const interval = currDate.getTime() + 3000 - date.getTime() // curdate가 서버에 보다 몇초정도 늦는듯

  if (Math.floor(interval / 31557600000) > 0) {
    return `${Math.floor(interval / 31557600000)}년 전`
  }
  if (Math.floor(interval / 2629800000) > 0) {
    return `${Math.floor(interval / 2629800000)}개월 전`
  }
  if (Math.floor(interval / 604800016.56) > 0) {
    return `${Math.floor(interval / 604800016.56)}주 전`
  }
  if (Math.floor(interval / 86400000) > 0) {
    return `${Math.floor(interval / 86400000)}일 전`
  }
  if (Math.floor(interval / 3600000) > 0) {
    return `${Math.floor(interval / 3600000)}시간 전`
  }
  if (Math.floor(interval / 60000) > 0) {
    return `${Math.floor(interval / 60000)}분 전`
  }
  return `${Math.floor(interval / 1000)}초 전`
}

export function urlify(string) {
  if (!string) {
    return ''
  }

  const urls = string.match(/(https?:\/\/[^\s]+)/g)
  if (urls) {
    urls.forEach(function(url) {
      string = string.replace(
        url,
        `<a target="_blank" style="color:white" href="${url}">${url}</a>`,
      )
    })
  }
  return string.replace('(', '<br/>(')
}

export async function parseMentionView(string, fetchUserAccount) {

  try{
    /**
     * 유저 닉네임 아이디 구분하는데, 유저 닉네임에 ()가 들어갈 수 있어서, )이후에 띄어쓰기까지 함께 구분
     * => 이부분도 경우의 수를 고려해서 추후 개선 필요
     */
    const mentions = string.match(/[@](.*?)[)] /g)
    // 이슈 시, 시도 >> /@\[[^\]]*\]\(id:[^)]*\)*/
    if (mentions) {
      await Promise.all(mentions.map(async (mention) => {
        const display = mention.match(/@\[([^%]+)\]/)
        const id = mention.match(/\(id:([^%]+)\)/)

        if(!display || !id){
          return string.replace('(', '<br/>(')
        }
        let account = null
        if(fetchUserAccount) {
          account = await fetchUserAccount(id[1])
        }
        string = string.replace(
          mention,
          `<a target="_blank" href="/user/${account}">@${display[1]}</a>`,
        )
      }))
    }
    return string.replace('(', '<br/>(')
  }
  catch(error){
    console.log('[utils][parseMentionView] error', error)
    return string.replace('(', '<br/>(')
  }
}

export function secondsToTime(seconds) {
  if (seconds < 60) {
    return `${seconds}초`
  }
  if (seconds < 3600) {
    var min = Math.floor(seconds / 60)
    var sec = seconds - min * 60
    return `${min}분 ${sec}초`
  }
  const hours = Math.floor(seconds / 3600)
  var min = Math.floor((seconds - hours * 3600) / 60)
  var sec = seconds - hours * 3600 - min * 60
  return `${hours}시간 ${min}분 ${sec}초`
}
