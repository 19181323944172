import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { SocialkingResultTemplate } from '../../components/socialking'
import { mbtiData } from '../../components/socialking/mbtiData'

const SocialkingResultPage = ({match}) => {

  const [mbtiType, setMbtiType] = useState(null)

  useEffect(()=>{
    if (match.params.mbti) {
      setMbtiType(match.params.mbti)
    }
  },[match])

  const shareNaverBlog = () => {

    share(
      `https://hreum.me/p/socialking/${mbtiType}/`,
      mbtiData[mbtiType]?.title,
    )
  }

  const share = (_url, _title)=>{
    const url = encodeURI(encodeURIComponent(_url));
    const title = encodeURI(_title);
    const shareURL = `https://share.naver.com/web/shareView.nhn?url=${url}&title=${title}`;

    window.open( shareURL, 'share', 'width=500, height=500' );
  }

  if (window.parent) {
    window.parent.postMessage(JSON.stringify({ mbti: mbtiType }), '*')
  }

  return (
    <div
      style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
        }
      }
    >
      <Helmet>
        <title>{mbtiData[mbtiType]?.title}</title>
        <meta
          name="description"
          content="인맥왕 테스트 I 나의 인맥은 상위 몇 퍼센트일까?
"
        />
        <meta property="og:title" content={mbtiData[mbtiType]?.title} />
        <meta
          property="og:url"
          content={`https://hreum.me/p/socialking/${mbtiType}/`}
        />
        <meta
          property="og:description"
          content="인맥왕 테스트 I 나의 인맥은 상위 몇 퍼센트일까?
"
        />
        <meta
          property="og:image"
          content={`https://storage.hreum.me/resources/socialking/socialking_${mbtiType}_img.png`}
        />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta
          name="keywords"
          content="인맥왕,흐름 mbti,흐름 mbti,흐름 인맥왕 테스트, 흐름 인맥왕 테스트,mbti,MBTI,엠비티아이,인맥왕 테스트,인맥왕테스트"
        />
        <meta
          name="twitter:domain"
          content={`https://hreum.me/p/socialking/${mbtiType}/`}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={mbtiData[mbtiType]?.title} />
        <meta
          name="twitter:description"
          content="인맥왕 테스트 I 나의 인맥은 상위 몇 퍼센트일까?
"
        />
        <meta
          name="twitter:image"
          content={`https://storage.hreum.me/resources/socialking/socialking_${mbtiType}_img.png`}
        />

      </Helmet>
      <SocialkingResultTemplate
        mbtiType={mbtiType}
        shareNaverBlog={shareNaverBlog}
      />
    </div>
  )
}

SocialkingResultPage.getInitialProps = async (ctx) => {
  const mbtiType = ctx.asPath.replace('/p/socialking/', '').replace('/', '')

  return { mbtiType }
}

export default SocialkingResultPage
