import { Network } from '.'

export default class TagNetwork {
  constructor(network: Network) {
    this.network = network
  }

  // 태그 조회
  getTags = async params => {
    try {
      const { data } = await this.network._axiosApiAuth('/tags/', 'get', params)
      return data || []
    }
    catch (error) {
      console.log('TagNetwork getTags error: ', error)
    }
  }

  // 특정 태그 조회
  getTag = async id => {
    try {
      const { data } = await this.network._axiosApiAuth(`/tags/${id}`, 'get')
      return data || []
    }
    catch (error) {
      console.log('TagNetwork getTag error: ', error)
    }
  }

  getRepresentiveTags = async params => {
    try {
      const { data } = await this.network._axiosApiAuth(
        `/representative-tags/recommend-tag-list`,
        'get',
        params,
      )
      return data || null
    }
    catch (error) {
      console.log('TagNetwork getRepresentiveTags error: ', error)
    }
  }

  getTagsPopularties = async where => {
    try {
      const { data } = await this.network._axiosApiAuth(
        '/tags/popularities',
        'get',
        where,
      )
      return data || []
    }
    catch (error) {
      console.log('TagNetwork _fetchPopularTags', error)
    }
  }
}
