import { images } from '@images/'
import React from 'react'
import KakaoLogin from 'react-kakao-login'

const KakaoLoginBtn = ({ kakaoLogin }) => {
  return (
    <KakaoLogin
      jsKey="e03cbdf47ecdf5d7725c4f103ca213ed"
      onSuccess={
        result => {
          kakaoLogin(result.profile.kakao_account, result.response.access_token)
        }
      }
      onFailure={result => console.log('kakao login fail :', result)}
      render={
        (props: any) => (
          <img
            onClick={props.onClick}
            src={images.kakao_login_btn_img}
            alt="kakao_login_img"
            style={{ width: '320px', height: '48px', cursor: 'pointer' }}
          />
        )
      }
      getProfile
    />
  )
}

export default KakaoLoginBtn
