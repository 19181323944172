import React from 'react'
import { H3 } from '@components/atoms'

const ForStudent = ({ style }) => {
  return (
    <H3 color="#ff9742" style={style}>
      For Student
    </H3>
  )
}

export default ForStudent
