import { Flex } from '@components/atoms'
import { images } from '@resources/'
import React from 'react'

const BioSocialBtn = ({ socialType, value }) => {
  const imgType = `bio_${socialType}_img`

  return (
    <a
      href={socialType === 'email' ? `mailto:${value}` : value}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Flex
        justify="center"
        align="center"
        style={{ width: 40, height: 40, cursor: 'pointer', margin: '0 4px' }}
      >
        <img
          src={images[`${imgType}`]}
          alt="social_img"
          style={{ width: 24, height: 24 }}
        />
      </Flex>
    </a>
  )
}

export default BioSocialBtn
