import React, { useState } from 'react'
import styled from 'styled-components'
import { Caption1 } from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@images/'
import FlexRowBox from './FlexRowBox'

const SearchInputBox = styled(FlexRowBox)`
  width: 100%;
  max-width: 564px;
  height: 48px;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: none;
  padding: 12px;
  margin-right: 30px;
`

const SearchInput = styled.input`
  border: none;
  width: 100%;
  max-width: 564px;
  height: 48px;
  font-size: 16px;
  font-family: NotoSansCJKkr-Medium;
  margin-left: 8px;
`

const SearchAudioBox = ({ fetchSearchSongs, setIsSearched }) => {
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearch = async () => {
    await fetchSearchSongs(searchKeyword)
    // setSearchKeyword('')
    setIsSearched(true)
  }

  return (
    <FlexRowBox>
      <SearchInputBox>
        <img
          src={images.search_gray_btn_img}
          alt="search_btn"
          style={{ width: '24px', height: '24px' }}
        />
        <SearchInput
          placeholder="오디오 제목을 검색해주세요"
          autoFocus
          value={searchKeyword}
          onChange={e => setSearchKeyword(e.target.value)}
          onKeyDown={e => e.keyCode === 13 && handleSearch()}
        />
      </SearchInputBox>
      <Caption1
        color={colors.primary}
        style={{ cursor: 'pointer' }}
        onClick={() => handleSearch()}
      >
        검색
      </Caption1>
    </FlexRowBox>
  )
}

export default SearchAudioBox
