import React, { useState, useEffect } from 'react'
import RegisterForm from '@components/organisms/form/RegisterForm'
import { lPad } from '@utils/string'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AccountAgreeTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'

const RegisterPage = () => {
  useRenderLogger('RegisterPage')
  const { authStore } = useStore()

  const sendSms = authStore.sendSms || (() => {})
  const smsCheck = authStore.smsCheck || (() => {})
  const getCheckPhone = authStore.getCheckPhone || (() => {})
  const getCheckEmail = authStore.getCheckEmail || (() => {})
  const checkUserJoinable = authStore.checkUserJoinable || (() => {})

  const register = authStore.register || (() => {})

  const [code, setCode] = useState('') // 인증 번호
  const [timer, setTimer] = useState('') // 타이머
  const [phoneVal, setPhoneVal] = useState(null) // 타이머 인터벌 아이디
  const [isTimer, setIsTimer] = useState(false) // 타이머 진행 여부
  const [complete, setComplete] = useState(false) // 가입 성공여부

  const [isStep, setIsStep] = useState('agree') // 회원 가입 단계
  const [termsAgree, setTermsAgree] = useState(false) // 이용약관 동의
  const [privateAgree, setPrivateAgree] = useState(false) // 개인정보 수집이용 동의
  const [isAgreeMarketing, setIsAgreeMarketing] = useState(false) // 마케팅 동의 여부

  // 카운트 다운
  let num = 60 * 3 // 몇분을 설정할지의 대한 변수 선언
  // 타이머 함수
  function timerFn() {
    let min = num / 60
    min = Math.floor(min)
    const sec = num - 60 * min

    setTimer(`${lPad(min)}:${lPad(sec)}`)

    if (timer === '00:00') {
      clearInterval(phoneVal)
    }
    num--
  }

  const time = async (e, phone) => {
    e.preventDefault()

    sendSms(phone)

    if (isTimer === true) {
      clearInterval(phoneVal)
      setIsTimer(false)
    }

    setPhoneVal(setInterval(timerFn, 1000))

    setIsTimer(true)
  }

  if (timer === '00:00') {
    clearInterval(phoneVal)
  }

  useEffect(() => {
    // 인터벌 함수 unmount 될때 제거(cleanup)
    return function cleanup() {
      clearInterval(phoneVal)
    }
  }, [phoneVal])
  return (
    <>
      {complete ? <Redirect to="/p/register/complete" /> : false}
      {
        isStep === 'agree' ? (
          <AccountAgreeTemplate
            isAgreeMarketing={isAgreeMarketing}
            setIsAgreeMarketing={setIsAgreeMarketing}
            setIsStep={setIsStep}
            termsAgree={termsAgree}
            setTermsAgree={setTermsAgree}
            privateAgree={privateAgree}
            setPrivateAgree={setPrivateAgree}
          />
        ) : (
          <RegisterForm
            code={code}
            getCheckPhone={getCheckPhone}
            getCheckEmail={getCheckEmail}
            setCode={setCode}
            time={(e, phone) => time(e, phone)}
            timer={timer}
            smsCheck={smsCheck}
            signUp={register}
            setComplete={setComplete}
            termsAgree={termsAgree}
            privateAgree={privateAgree}
            isAgreeMarketing={isAgreeMarketing}
            checkUserJoinable={checkUserJoinable}
          />
        )
      }
    </>
  )
}

export default observer(RegisterPage)
