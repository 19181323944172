import React from 'react'
import styled from 'styled-components'
import { mobile } from '@styles/media'
import { images } from '@images/'

const CheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansCJKkr-Medium;
  font-size: 16px;
  color: #646464;
  cursor: pointer;

  word-break: keep-all;

  img {
    width: 22px;
    height: 22px;
    ${props => props.isText && `margin-right:8px;`}
  }

  @media ${mobile} {
    font-size: 12px;
  }
`

const CheckTextBox = props => {
  return (
    <CheckBox onClick={props.onClick} style={props.style} isText={props.text}>
      {
        props.checked ? (
          <img
            src={
              props.color === 'green' ? images.green_check_img : images.check_img
            }
            alt="check_img"
          />
        ) : (
          <img src={images.unCheck_img} alt="check_img" />
        )
      }
      {props.text}
    </CheckBox>
  )
}

export default CheckTextBox
