import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  H6,
  Flex,
  Button,
  Caption1,
  Caption2,
  Caption3,
  FormInput,
  FormLabel,
  ProfileImg,
  Img,
} from '@components/atoms'
import { observer } from 'mobx-react'
import { mobile, tablet } from '@styles/media'
import { useStore } from '@utils/hooks'
import { colors } from '@resources/colors'
import { TagBox } from '@components/molecules'
import { LIVE_ROOM_REQUEST_TOPIC_LIST } from '@consts/liveRoom'
import { useHistory } from 'react-router-dom'
import { STORAGE_URL } from '@consts'
import { images } from '@images/'
import { SalonImageUploadForm } from '..'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  border-radius: 16px;
  max-width: 520px;
  max-height: 850px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 24px;
`

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const View = observer(
  ({
    isShowing,
    toggle,
    isEdit = false,
    fetchUserHouseList = () => {},
    houseDetail = null,
  }) => {
    const { liveRoomStore, authStore, tagStore } = useStore()
    const history = useHistory()

    const { currentUser } = authStore
    const { currLiveRoom } = liveRoomStore
    const _autoCompleteTagObj = tagStore._autoCompleteTagObj || {}
    const fetchAutocompleteTags = tagStore.fetchAutocompleteTags || (() => {})

    // const [type, setType] = React.useState(1) // 1: 공개, 2: 비공개
    const [title, setTitle] = React.useState('') // 제목, === '' 이면, 기본값으로
    const [password, setPassword] = React.useState('') // 비밀번호
    const [imageUri, setImageUri] = React.useState(null) // 살롱 이미지
    const [tempTagList, setTempTagList] = React.useState([]) // 주제 리스트
    const [tagInput, setTagInput] = React.useState('') // 주제 검색값
    const [isSearching, setIsSearching] = React.useState(false) // 주제 검색중

    /**
     * salon select place
     */
    const UPLOAD_PLACE_TYPE = {
      PERSONAL: 'PERSONAL',
      HOUSE: 'HOUSE',
    }
    const [
      showUserUploadPlaceList,
      setShowUserUploadPlaceList,
    ] = React.useState(false)

    const _basePersonalPlace = {
      item: currentUser,
      type: UPLOAD_PLACE_TYPE['PERSONAL'],
    }
    const _basePlace = currLiveRoom
      ? {
        item: currLiveRoom['houseId']
          ? { id: currLiveRoom['houseId'] }
          : currentUser,
        type: currLiveRoom['houseId']
          ? UPLOAD_PLACE_TYPE['HOUSE']
          : UPLOAD_PLACE_TYPE['PERSONAL'],
      }
      : _basePersonalPlace
    const [selectedPlace, setSelectedPlace] = React.useState(_basePlace)
    const [uploadPlaceList, setUploadPlaceList] = React.useState([])

    React.useEffect(() => {
      if (currentUser) {
        setSelectedPlace({
          item: currentUser,
          type: UPLOAD_PLACE_TYPE['PERSONAL'],
        })
      }
      else {
        setSelectedPlace(_basePlace)
      }
    }, [currentUser])

    /**
     * salon select type
     */
    const LIVE_ROOM_TYPE = {
      PUBLIC: 'PUBLIC',
      PUBLIC_ONLY_LOGIN_USER: 'PUBLIC_ONLY_LOGIN_USER',
      PRIVATE: 'PRIVATE',
      SOCIAL: 'SOCIAL',
      MEMBER: 'MEMBER',
    }

    const _setBaseType = liveRoom => {
      if (liveRoom['isPrivate']) {
        return LIVE_ROOM_TYPE['PRIVATE']
      }
      if (liveRoom['isSocial']) {
        return LIVE_ROOM_TYPE['SOCIAL']
      }
      if (liveRoom['isHouseMember']) {
        return LIVE_ROOM_TYPE['MEMBER']
      }

      if (liveRoom['isBlockedUnknownUser']) {
        return LIVE_ROOM_TYPE['PUBLIC_ONLY_LOGIN_USER']
      }

      return LIVE_ROOM_TYPE['PUBLIC']
    }

    const _baseType = !currLiveRoom
      ? LIVE_ROOM_TYPE['PUBLIC']
      : _setBaseType(currLiveRoom)
    const [selectedType, setSelectedType] = React.useState(_baseType)
    const [showType, setShowType] = React.useState(false)

    const handleChangeTitle = e => {
      if (title.length >= 43) return
      setTitle(e.target.value)
    }

    const _loadSelectedType = _type => {
      switch (_type) {
        case LIVE_ROOM_TYPE['PUBLIC']:
          return (
            <Flex style={{ alignItems: 'center' }}>
              <Img
                src={images.salon_type_public}
                width="20px"
                height="20px"
                style={{ marginRight: '8px' }}
              />
              <Caption3>공개 (비회원 참여 가능)</Caption3>
            </Flex>
          )
        case LIVE_ROOM_TYPE['PUBLIC_ONLY_LOGIN_USER']:
          return (
            <Flex style={{ alignItems: 'center' }}>
              <Img
                src={images.salon_type_public}
                width="20px"
                height="20px"
                style={{ marginRight: '8px' }}
              />
              <Caption3>공개 (비회원 참여 불가)</Caption3>
            </Flex>
          )
        case LIVE_ROOM_TYPE['SOCIAL']:
          return (
            <Flex style={{ alignItems: 'center' }}>
              <Img
                src={images.salon_type_social}
                width="20px"
                height="20px"
                style={{ marginRight: '8px' }}
              />
              <Caption3>소셜</Caption3>
            </Flex>
          )
        case LIVE_ROOM_TYPE['MEMBER']:
          return (
            <Flex style={{ alignItems: 'center' }}>
              <Img
                src={images.salon_type_member}
                width="20px"
                height="20px"
                style={{ marginRight: '8px' }}
              />
              <Caption3>멤버</Caption3>
            </Flex>
          )
        case LIVE_ROOM_TYPE['PRIVATE']:
          return (
            <Flex style={{ alignItems: 'center' }}>
              <Img
                src={images.salon_type_private}
                width="20px"
                height="20px"
                style={{ marginRight: '8px' }}
              />
              <Caption3>비공개</Caption3>
            </Flex>
          )
        default:
          return <></>
      }
    }

    const handleCheckPassword = e => {
      if (
        (e.key >= 0 && e.key <= 9)
        || e.key === 'Backspace'
        || e.key === 'ArrowRight'
        || e.key === 'ArrowLeft'
      )
        return true
      return e.preventDefault()
    }

    // 자동완성 검색
    const searchTagList = async text => {
      try {
        if (_autoCompleteTagObj[text]) {
          return
        }
        _autoCompleteTagObj[text] = { list: [] }
        const tagList = await fetchAutocompleteTags(text)

        if (_autoCompleteTagObj[text]) {
          _autoCompleteTagObj[text]['list'] = tagList
        }
      }
      catch (error) {
        console.log(error)
      }
    }

    // 태그 검색어
    const handleChangeTag = async e => {
      setTagInput(e.target.value)
      await searchTagList(e.target.value)
    }

    // 추천 태그
    const handleClickRequestTag = tag => {
      if (tempTagList.length >= 5) return
      const { keyword } = tag

      setIsSearching(false)
      setTagInput('')

      const index = tempTagList.findIndex(
        elem => elem && elem['keyword'] === keyword,
      )
      if (index < 0) {
        tempTagList.push({ keyword })
        setTempTagList([...tempTagList])
      }
    }

    // 선택 태그 제거
    const handleRemoveTag = (tag, index) => {
      tempTagList.splice(index, 1)
      setTempTagList([...tempTagList])
    }

    const submitLiveRoom = async () => {
      const tempLiveRoom = {
        title: title.length > 0 ? title : `${currentUser['name']}님의 살롱`,
        imageUri,
        topicKeywordList: tempTagList.map(tag => tag['keyword']),
        mixtapeVolume: liveRoomStore.ON_VOICE_MIXTAPE_VOLUME,
        // password: type === 2 ? password : null,

        isPrivate: selectedType === LIVE_ROOM_TYPE['PRIVATE'],
        password: password || null,

        isSocial: selectedType === LIVE_ROOM_TYPE['SOCIAL'],
        isHouseMember: selectedType === LIVE_ROOM_TYPE['MEMBER'],

        isBlockedUnknownUser:
          selectedType === LIVE_ROOM_TYPE['PUBLIC_ONLY_LOGIN_USER'],
      }

      if (selectedPlace['type'] === UPLOAD_PLACE_TYPE['HOUSE']) {
        tempLiveRoom['houseId'] = selectedPlace['item']['id']
      }

      if (isEdit) {
        const isEdited = await liveRoomStore.editLiveRoom(tempLiveRoom)
        if (isEdited) {
          toggle()
        }
      }
      else {
        const isCreated = await liveRoomStore.createLiveRoom(tempLiveRoom)
        if (isCreated) {
          // history.push(`/salon/${liveRoomStore.tempLiveRoom['linkKey']}`)
          history.push({
            pathname: `/salon/${isCreated['linkKey']}`,
            state: { isPassword: true },
          })
        }
      }
    }

    React.useEffect(() => {
      if (isEdit && currLiveRoom) {
        // setType(currLiveRoom.isPrivate ? 2 : 1)
        setTitle(currLiveRoom.title)
        setImageUri(currLiveRoom.imageUri)
        setTempTagList(
          currLiveRoom.LiveRoomsHaveTags.map(item => {
            return {
              keyword: item.Tag.keyword,
            }
          }),
        )
        setSelectedType(_setBaseType(currLiveRoom))

        if (currLiveRoom?.password) {
          setPassword(currLiveRoom?.password)
        }
      }
    }, [isEdit, currLiveRoom])

    React.useEffect(() => {
      if (houseDetail) {
        setSelectedPlace({
          item: houseDetail,
          type: UPLOAD_PLACE_TYPE['HOUSE'],
        })
      }
    }, [houseDetail])

    return isShowing
      ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={() => toggle()} />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <MobdlBody>
                <H6 style={{ marginBottom: 12 }}>
                  {isEdit ? '살롱 수정' : '살롱 열기'}
                </H6>
                <RequiredBox>
                  <SalonImageUploadForm
                    imageUri={imageUri}
                    uploadImage={
                      filePath => {
                        liveRoomStore.uploadLiveRoomCoverImage(
                          filePath,
                          image => {
                            setImageUri(image)
                          },
                        )
                      }
                    }
                  />
                  <Flex
                    type="column"
                    style={
                      {
                        width: '100%',
                        paddingLeft: '16px',
                      }
                    }
                  >
                    {
                      !isEdit && (
                        <TypeListBox
                          style={
                            {
                              marginBottom: 8,
                              position: 'relative',
                              pointer: 'cursor',
                            }
                          }
                          onClick={
                            async () => {
                              if (houseDetail) return
                              setShowUserUploadPlaceList(!showUserUploadPlaceList)
                              const houseList = (await fetchUserHouseList()) || []
                              setUploadPlaceList([
                                ...houseList.map(item => {
                                  return {
                                    item,
                                    type: UPLOAD_PLACE_TYPE['HOUSE'],
                                  }
                                }),
                              ])
                            }
                          }
                        >
                          {
                            houseDetail ? (
                              <>
                                <House house={houseDetail} />
                                <Flex align="center">
                                  <Caption3
                                    color={colors.brown_grey}
                                    style={{ marginRight: '12px' }}
                                  >
                                  하우스
                                  </Caption3>
                                </Flex>
                              </>
                            ) : (
                              <>
                                {
                                  selectedPlace['type']
                              === UPLOAD_PLACE_TYPE['PERSONAL'] ? (
                                      <User user={selectedPlace['item']} />
                                    ) : (
                                      <House house={selectedPlace['item']} />
                                    )
                                }
                                <Flex align="center">
                                  <Caption3
                                    color={colors.brown_grey}
                                    style={{ marginRight: '12px' }}
                                  >
                                    {
                                      selectedPlace['type']
                                  === UPLOAD_PLACE_TYPE['PERSONAL']
                                        ? '개인'
                                        : '하우스'
                                    }
                                  </Caption3>
                                  <Img
                                    src={
                                      showUserUploadPlaceList
                                        ? images.move_up_gray_img
                                        : images.move_down_gray_img
                                    }
                                    width="12px"
                                    height="12px"
                                  />
                                </Flex>
                                {
                                  showUserUploadPlaceList && (
                                    <AcceptContentsBox>
                                      <>
                                        <AcceptContentsItem
                                          onClick={
                                            e => {
                                              e.stopPropagation()
                                              setSelectedPlace({
                                                item: currentUser,
                                                type: UPLOAD_PLACE_TYPE['PERSONAL'],
                                              })
                                              setShowUserUploadPlaceList(false)
                                            }
                                          }
                                        >
                                          <User user={currentUser} />
                                          <Caption3>개인</Caption3>
                                        </AcceptContentsItem>
                                        {
                                          uploadPlaceList.map(place => {
                                            return (
                                              <AcceptContentsItem
                                                onClick={
                                                  e => {
                                                    e.stopPropagation()
                                                    setSelectedPlace(place)
                                                    setShowUserUploadPlaceList(false)
                                                  }
                                                }
                                                key={place?.['item']?.['id']}
                                              >
                                                <House house={place?.['item']} />
                                                <Caption3>하우스</Caption3>
                                              </AcceptContentsItem>
                                            )
                                          })
                                        }
                                      </>
                                    </AcceptContentsBox>
                                  )
                                }
                              </>
                            )
                          }
                        </TypeListBox>
                      )
                    }

                    <TypeListBox
                      style={
                        {
                          marginBottom: 8,
                          position: 'relative',
                          pointer: 'cursor',
                        }
                      }
                      onClick={
                        () => {
                          setShowType(!showType)
                        }
                      }
                    >
                      {_loadSelectedType(selectedType)}
                      <Img
                        src={
                          showUserUploadPlaceList
                            ? images.move_up_gray_img
                            : images.move_down_gray_img
                        }
                        width="12px"
                        height="12px"
                      />
                      {
                        showType && (
                          <AcceptContentsBox>
                            <TypeItem
                              onClick={
                                () => {
                                  setShowType(false)
                                  setSelectedType(LIVE_ROOM_TYPE['PUBLIC'])
                                }
                              }
                            >
                              <Img
                                src={images.salon_type_public}
                                width="20px"
                                height="20px"
                                style={{ marginRight: '8px' }}
                              />
                              <Caption3>공개 (비회원 참여 가능)</Caption3>
                            </TypeItem>
                            <TypeItem
                              onClick={
                                () => {
                                  setShowType(false)
                                  setSelectedType(
                                    LIVE_ROOM_TYPE['PUBLIC_ONLY_LOGIN_USER'],
                                  )
                                }
                              }
                            >
                              <Img
                                src={images.salon_type_public}
                                width="20px"
                                height="20px"
                                style={{ marginRight: '8px' }}
                              />
                              <Caption3>공개 (비회원 참여 불가)</Caption3>
                            </TypeItem>
                            {
                              selectedPlace['type']
                            === UPLOAD_PLACE_TYPE['PERSONAL'] ? (
                                  <TypeItem
                                    onClick={
                                      () => {
                                        setShowType(false)
                                        setSelectedType(LIVE_ROOM_TYPE['SOCIAL'])
                                      }
                                    }
                                  >
                                    <Img
                                      src={images.salon_type_social}
                                      width="20px"
                                      height="20px"
                                      style={{ marginRight: '8px' }}
                                    />
                                    <Caption3>소셜</Caption3>
                                  </TypeItem>
                                ) : (
                                  <TypeItem
                                    onClick={
                                      () => {
                                        setShowType(false)
                                        setSelectedType(LIVE_ROOM_TYPE['MEMBER'])
                                      }
                                    }
                                  >
                                    <Img
                                      src={images.salon_type_member}
                                      width="20px"
                                      height="20px"
                                      style={{ marginRight: '8px' }}
                                    />
                                    <Caption3>멤버</Caption3>
                                  </TypeItem>
                                )
                            }
                            <TypeItem
                              onClick={
                                () => {
                                  setShowType(false)
                                  setSelectedType(LIVE_ROOM_TYPE['PRIVATE'])
                                }
                              }
                            >
                              <Img
                                src={images.salon_type_private}
                                width="20px"
                                height="20px"
                                style={{ marginRight: '8px' }}
                              />
                              <Caption3>비공개</Caption3>
                            </TypeItem>
                          </AcceptContentsBox>
                        )
                      }
                    </TypeListBox>
                  </Flex>
                </RequiredBox>
                {
                  // <>
                  //   <Caption2
                  //     color={colors.grey_94}
                  //     style={{ marginTop: 24 }}
                  //   >
                  //     {
                  //       // type === 1
                  //       //   ? '*누구나 살롱에 입장할 수 있습니다'
                  //       //   : '*링크를 통해 살롱에 입장할 수 있습니다'
                  //     }
                  //   </Caption2>
                  //   <Caption1
                  //     align="left"
                  //     color={colors.grey_94}
                  //     style={{ width: '100%', margin: '24px 0 16px 0' }}
                  //   >
                  //       * 선택 사항
                  //   </Caption1>
                  // </>
                }
                <FormGroup style={{ display: 'flex', marginTop: '16px' }}>
                  <Flex type="column" style={{ width: '100%' }}>
                    <FormLabel
                      style={
                        {
                          fontSize: 12,
                          marginBottom: 8,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }
                      }
                    >
                      <Caption2>제목</Caption2>
                    </FormLabel>
                    <Flex
                      align="center"
                      style={
                        {
                          border: '1px solid #949494',
                          borderRadius: 7,
                          width: '100%',
                        }
                      }
                    >
                      <FormInput
                        style={{ border: 'none', fontSize: 12, height: 40 }}
                        placeholder={`${currentUser.name}님의살롱입니다`}
                        value={title}
                        onChange={handleChangeTitle}
                        onKeyDown={
                          e => {
                            if (title.length >= 43 && e.keyCode === 8) {
                              e.preventDefault()
                              setTitle(title.slice(0, -1))
                            }
                          }
                        }
                      />
                      <Caption2
                        style={{ whiteSpace: 'nowrap', marginRight: 12 }}
                      >
                        {`${title.length} / 43`}
                      </Caption2>
                    </Flex>
                  </Flex>
                  {
                    selectedType === LIVE_ROOM_TYPE['PRIVATE'] && (
                      <Flex
                        type="column"
                        style={{ width: 120, marginLeft: 12 }}
                      >
                        <FormLabel
                          style={
                            {
                              fontSize: 12,
                              marginBottom: 8,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }
                          }
                        >
                          <Caption2>비밀번호</Caption2>
                        </FormLabel>
                        <FormInput
                          style={{ fontSize: 12, height: 40 }}
                          placeholder="숫자 4자리"
                          value={password}
                          onChange={
                            e => {
                              const isNumber = /[0-9]*/
                              if (isNumber.test(e.target.value)) {
                                setPassword(e.target.value)
                              }
                            }
                          }
                          maxLength={4}
                          pattern="[0-9]+"
                          onKeyDown={handleCheckPassword}
                        />
                      </Flex>
                    )
                  }
                </FormGroup>
                <FormGroup style={{ position: 'relative' }}>
                  <FormLabel
                    style={
                      {
                        fontSize: 12,
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }
                  >
                    <Caption2>주제 추가</Caption2>
                    <Caption2>{`${tempTagList.length} / 5`}</Caption2>
                  </FormLabel>
                  {
                    isSearching && (
                      <Overlay onClick={() => setIsSearching(false)} />
                    )
                  }
                  <FormInput
                    style={{ fontSize: 12, height: 40 }}
                    placeholder="원하시는 주제를 입력해보세요"
                    onFocus={() => setIsSearching(true)}
                    value={tagInput}
                    onChange={handleChangeTag}
                    onKeyDown={
                      e => {
                        if (e.keyCode === 13) {
                          e.preventDefault()
                          handleClickRequestTag({ keyword: tagInput })
                        }
                      }
                    }
                  />
                  {
                    isSearching && !!tagInput && (
                      <SearchBox>
                        {
                          _autoCompleteTagObj
                          && _autoCompleteTagObj[tagInput]
                          && _autoCompleteTagObj[tagInput]['list'].map(item => {
                            return (
                              <SearchItem
                                onClick={() => handleClickRequestTag(item)}
                                key={item.id}
                              >
                                <Caption1>{`#${item.keyword}`}</Caption1>
                                <Caption1 color="#949494">{`${item.totalCount}개`}</Caption1>
                              </SearchItem>
                            )
                          })
                        }
                      </SearchBox>
                    )
                  }
                </FormGroup>
                <Caption1
                  color={colors.grey_94}
                  align="left"
                  style={{ width: '100%' }}
                >
                    *추천 주제
                </Caption1>
                <Flex
                  style={
                    {
                      width: '100%',
                      marginTop: 16,
                      flexWrap: 'wrap',
                      gap: 8,
                    }
                  }
                >
                  {
                    LIVE_ROOM_REQUEST_TOPIC_LIST.map((tag, index) => (
                      <TagBox
                        item={tag}
                        index={index}
                        key={index}
                        onClick={handleClickRequestTag}
                      />
                    ))
                  }
                </Flex>
                {
tempTagList?.length > 0 && (
                    <Flex
                      style={
                        {
                          width: '100%',
                          flexWrap: 'wrap',
                          gap: 8,
                          marginTop: 16,
                        }
                      }
                    >
                      {
                        tempTagList.map((tag, index) => (
                          <TagBox
                            item={tag}
                            isTemp
                            onRemove={handleRemoveTag}
                            index={index}
                            key={index}
                          />
                        ))
                      }
                    </Flex>
                  )
                }
                <SelectedBtn
                  onClick={submitLiveRoom}
                  disabled={
                    selectedType === LIVE_ROOM_TYPE['PRIVATE']
                      ? !(password.length === 4)
                      : false
                  }
                >
                  {isEdit ? '살롱 저장' : '살롱 시작'}
                </SelectedBtn>
              </MobdlBody>
            </Modal>
          </ModalWrapper>
        </>,
        document.body,
      )
      : null
  },
)

export default {
  View,
  useModal,
}

const RequiredBox = styled(Flex)`
  width: 100%;
`

const SelectedBtn = styled(Button)`
  position: absolute;
  bottom: 16px;

  width: calc(100% - 16px);
  height: 48px;
  border-radius: 24px;
  background-color: ${colors.primary};
  color: ${colors.white_ff};
  font-size: 14px;

  :disabled {
    background-color: ${colors.white_f4};
    color: ${colors.grey_d4};
  }
`

const TypeBtn = styled(Button)`
  width: 224px;
  height: 56px;
  border-radius: 8px;
  color: ${colors.primary};
  font-size: 14px;

  ${({ active }) =>
    active
      ? css`
          background-color: ${colors.white_ff};
          border: 1px solid ${colors.white_primary};
        `
      : css`
          border: 1px solid ${colors.grey_d4};
          color: ${colors.grey_94};
        `}
`

const FormGroup = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`

const SearchBox = styled(Flex)`
flex-direction:column;
    position:absolute;
    width : 518px;
    height:fit-content;
    max-height:240px;
    overflow: auto;
    justify-content : flex-start;
    border-radius: 7px;
    backdrop-filter: blur(30px);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    z-index:2;
}

  > div {
    width:100%;
    height:48px;
    padding : 12px 20px;
  }
`

const SearchItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

/**
 * select box
 */
const TypeListBox = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 10px;
  border: 1px solid ${colors.grey_d4};
  color: ${colors.black};
  background-color: #ffffff;

  @media ${mobile} {
    margin-left: 0px;
  }
`

const AcceptContentsBox = styled(Flex)`
  flex-direction: column;
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  max-width: 465px;
  height: fit-content;
  max-height: 200px;
  overflow: auto;
  justify-content: flex-start;
  border-radius: 7px;
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 2;

  min-width: 200px;
`

const AcceptContentsItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 11px 8px;
  justify-content: space-between;

  :hover {
    background-color: #f4f4f4;
  }
`

const TypeItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding: 11px 8px;
  align-items: center;
  /* justify-content: space-between; */

  :hover {
    background-color: #f4f4f4;
  }
`

const User = ({ user }) => {
  if (user) {
    return (
      <Flex align="center">
        <UserImage src={user['imageUri']} profileColor={user['profileColor']} />
        <Caption2 style={{ marginLeft: '8px' }} color="#242424">
          {user['name']}
        </Caption2>
      </Flex>
    )
  }
  return <></>
}

const UserImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const House = ({ house }) => {
  if (house) {
    return (
      <Flex align="center">
        <HouseImage src={STORAGE_URL + house['imageUri']} />
        <Caption2 style={{ marginLeft: '8px' }} color="#242424">
          {house['title']}
        </Caption2>
      </Flex>
    )
  }
  return <></>
}

const HouseImage = styled(ProfileImg)`
  width: 24px;
  height: 24px;
  border-radius: 6px;
`
