import { Caption1, Caption2, Flex, H6, H4, ProfileImg } from '@components/atoms'
import {
  DJBadge,
  MobileLeadBox,
  MobileLeadPopup,
  MoveBtn,
} from '@components/molecules'
import { MobileSalonPlayListSection } from '@components/organisms'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { useMoreHeight } from '@utils/hooks'
import React from 'react'
import styled, { css } from 'styled-components'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SalonMobileTemplate = ({ mixtapeDetail }) => {
  const { isShowing, toggle } = MobileLeadPopup.usePopup()

  const { showMore, handleShowMore, haveMore } = useMoreHeight({
    defaultHeight: 32,
    id: 'tag_box',
    item: mixtapeDetail && mixtapeDetail.MixtapesHaveTags,
  })

  if (mixtapeDetail) {
    return (
      <>
        {
          mixtapeDetail.isPublished ? (
            <div style={{ position: 'relative', height: '100%' }}>
              <HeaderBox backgroundImageUrl={mixtapeDetail.imageUrl}>
                <Gradient />
                <img
                  src={images.hreum_salon_logo_img}
                  alt="hreum_salon_logo"
                  style={{ width: 144, height: 20 }}
                  onClick={() => (window.location = 'https://hreum.kr')}
                />
                <H6
                  color={colors.white_ff}
                  style={{ margin: '26px 0 20px 0', ...ellipsisStyle }}
                >
                  {mixtapeDetail.name}
                </H6>
                <Flex style={{ position: 'relative' }}>
                  <TagList id="tag_box" haveMore={haveMore} showMore={showMore}>
                    {
                      mixtapeDetail.MixtapesHaveTags
                    && mixtapeDetail.MixtapesHaveTags.map(item => (
                      <TagBox key={item.id}>{item.Tag.keyword}</TagBox>
                    ))
                    }
                  </TagList>
                  {
                    haveMore && (
                      <MoreBtn
                        src={
                          showMore
                            ? images.move_up_white_img
                            : images.move_down_white_img
                        }
                        alt="more_img"
                        onClick={() => handleShowMore()}
                      />
                    )
                  }
                </Flex>
              </HeaderBox>
              <ContentBox>
                <Flex align="center">
                  <ProfileImg
                    src={mixtapeDetail?.User?.thumbnailUri}
                    profileColor={mixtapeDetail?.User?.profileColor}
                    style={
                      {
                        marginRight: 12,
                        width: 56,
                        height: 56,
                        objectFit: 'cover',
                        borderRadius: '50%',
                      }
                    }
                    onClick={toggle}
                  />
                  <Flex type="column">
                    <DJBadge
                      discJockeyLevel={mixtapeDetail.User?.discJockeyLevel}
                    />
                    <Caption1 style={{ marginTop: '4px', ...oneEllipsisStyle }}>
                      {mixtapeDetail.User?.name}
                    </Caption1>
                  </Flex>
                </Flex>
                <Caption2
                  align="left"
                  style={{ marginTop: 16, whiteSpace: 'pre' }}
                  color={colors.grey_64}
                >
                  {mixtapeDetail.description}
                </Caption2>
              </ContentBox>
              <MobileLeadBox onClick={toggle} />
              <MobileSalonPlayListSection
                mixtape={mixtapeDetail}
                onClick={toggle}
              />
              <MobileLeadPopup.View isShowing={isShowing} toggle={toggle} />
            </div>
          ) : (
            <Flex
              id="tag_box"
              type="column"
              justify="center"
              align="center"
              style={{ width: '100%', height: '100vh' }}
            >
              <img
                src={images.caution_img_red}
                style={{ width: 64, height: 64 }}
                alt="caution_icon"
              />
              <H4 style={{ margin: '24px 0 32px 0' }}>
              비공개되었거나 삭제된 플라입니다.
              </H4>
              <MoveBtn href="/" text="홈으로 가기" />
            </Flex>
          )
        }
      </>
    )
  }
  return null
}

export default SalonMobileTemplate

const HeaderBox = styled(Flex)`
  position: relative;
  padding: 38px 16px 0 16px;

  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 384px;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl
    && css`
      background-image: url(${backgroundImageUrl});
      background-repeat: no-repeat;
      background-size: 100%;
    `}

  > * {
    z-index: 2;
  }

  > div:first-child {
    z-index: 1;
  }
`

const Gradient = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 192px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.72),
    rgba(119, 119, 119, 0) 96%
  );
`

const TagList = styled(Flex)`
  max-width: 90%;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 32px;
  overflow: hidden;

  ${props =>
    props.haveMore
    && !props.showMore
    && css`
      max-height: 32px;
    `}

  > div {
    margin: 0px 7px 5px 7px;
  }

  > div:first-child,
  > div:last-child {
    margin: 0 0 5px 0;
  }
`

const TagBox = styled(Flex)`
  min-width: 54px;
  height: 32px;
  border-radius: 20px;
  background-color: rgba(36, 36, 36, 0.7);
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${colors.white_ff};
  font-size: 12px;
`

const MoreBtn = styled.img`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 8px 7px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  background-color: rgba(36, 36, 36, 0.7);
  border-radius: 50%;
`

const ContentBox = styled(Flex)`
  flex-direction: column;
  padding: 16px;
`
