import {
  Flex,
  FormInput,
  FormLabel,
  FormTextArea,
  // WarningMsg,
} from '@components/atoms'
import { colors } from '@resources/colors'
import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { mobileDesktop } from '@styles/media'
import { SubmitBtn } from '@components/molecules'
import { BackgroundUploadForm, ProfileUploadForm } from '@components/organisms'
import { BANNED_WORDS } from '@resources'
import { PageLayout } from '..'

const UserUpdateTemplate = ({
  currentUser,
  getCheckName,
  updateUser,
  uploadImage,
}) => {
  const history = useHistory()

  const [imageUri, setImageUri] = React.useState(null)
  const [imageUrl, setImageUrl] = React.useState(null)
  const [backgroundImageUri, setBackgroundImageUri] = React.useState(null)
  const [backgroundImageUrl, setBackgroundImageUrl] = React.useState(null)
  // const [confirmName, setConfirmName] = React.useState(false) // 닉네임 중복 체크

  const {
    register,
    handleSubmit,
    //  errors,
    // getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
  })

  // 닉네임 중복 체크
  // const _verifyName = async () => {
  //   if (currentUser.name === getValues().name) {
  //     setConfirmName('Y')
  //     return
  //   }
  //   const check = await getCheckName(getValues().name)
  //   if (check && check.length === 0) {
  //     setConfirmName('Y')
  //   }
  //   else if (check && check.length > 0) {
  //     setConfirmName('N')
  //   }
  // }

  const onSubmit = async data => {
    // if (data.name && confirmName === 'N') return

    const params = {}

    // if (data.name && data.name !== currentUser.name) params.name = data.name
    if (imageUri && currentUser.imageKey !== imageUri)
      params.imageUri = imageUri
    if (data.newPassword) params.password = data.newPassword
    if (
      backgroundImageUri
      && currentUser.backgroundImageUri !== backgroundImageUri
    )
      params.backgroundImageUri = backgroundImageUri
    params.bioInstagram = data.bioInstagram || ''
    params.bioWebsite = data.bioWebsite || ''
    params.description = data.description || ''
    params.bioYoutube = data.bioYoutube || ''
    params.bioEmail = data.bioEmail || ''

    /** 입력 금지어 체크 * */
    for (const word of BANNED_WORDS) {

      const isIncludeBannedWord =
      data.bioInstagram?.includes(word)
      || data.bioWebsite?.includes(word)
      || data.description?.includes(word)
      || data.bioYoutube?.includes(word)
      || data.bioEmail?.includes(word)

      if (isIncludeBannedWord) {

        params.backgroundImageUri = null
        params.bioInstagram = ''
        params.bioWebsite = ''
        params.description = ''
        params.bioYoutube = ''
        params.bioEmail = ''
      }
    }


    const updated = await updateUser(currentUser, params)

    if (!updated) return

    alert('정보 수정이 완료되었습니다.')

    history.push(`/user/${currentUser?.account}`)
  }

  React.useEffect(() => {
    if (currentUser) {
      setImageUri(currentUser.imageKey)
      setImageUrl(currentUser.imageUri)
      setBackgroundImageUri(currentUser.backgroundImageUri)
      setBackgroundImageUrl(currentUser.backgroundImageUrl)
      setValue([
        // { name: currentUser.name },
        { description: currentUser.description },
        { bioYoutube: currentUser.bioYoutube },
        { bioWebsite: currentUser.bioWebsite },
        { bioInstagram: currentUser.bioInstagram },
        { bioEmail: currentUser.bioEmail },
      ])
    }
  }, [currentUser, setValue])

  return (
    <PageLayout style={{ position: 'relative', maxWidth: '100vw', padding: 0 }}>
      <BackgroundUploadForm
        imageUrl={backgroundImageUrl}
        setImageUri={setBackgroundImageUri}
        setImageUrl={setBackgroundImageUrl}
        uploadImage={uploadImage}
      />
      <Section>
        <Flex
          style={{ width: '100%', marginBottom: 24 }}
          justify="space-between"
        >
          <ProfileUploadForm
            style={
              {
                width: 104,
                height: 104,
                border: `solid 4px ${colors.white_ff}`,
              }
            }
            imageUrl={imageUrl}
            setImageUri={setImageUri}
            setImageUrl={setImageUrl}
            uploadImage={uploadImage}
          />
        </Flex>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            {/* <FormGroup>
              <FormLabel style={{ fontSize: 14 }}>닉네임</FormLabel>
              <FormInput
                placeholder="닉네임"
                name="name"
                ref={register({ required: true, minLength: 4 })}
                onBlur={() => _verifyName()}
              />
              {
                confirmName === 'N' && (
                  <WarningMsg>이미 존재하는 닉네임입니다</WarningMsg>
                )
              }
            </FormGroup> */}
            <FormGroup>
              <FormLabel style={{ fontSize: 12, marginBottom: 8 }}>
                설명
              </FormLabel>
              <FormTextArea
                style={{ height: 310, margin: 0, resize: 'none', fontSize: 12 }}
                placeholder="설명"
                name="description"
                ref={register({ required: false })}
              />
            </FormGroup>
          </InputBox>
          <InputBox>
            <FormGroup>
              <FormLabel style={{ fontSize: 12, marginBottom: 8 }}>
                웹사이트
              </FormLabel>
              <CustomFormInput
                placeholder="웹사이트"
                name="bioWebsite"
                ref={register({ required: false })}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel style={{ fontSize: 12, marginBottom: 8 }}>
                유튜브
              </FormLabel>
              <CustomFormInput
                placeholder="유튜브"
                name="bioYoutube"
                ref={register({ required: false })}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel style={{ fontSize: 12, marginBottom: 8 }}>
                인스타그램
              </FormLabel>
              <CustomFormInput
                placeholder="인스타그램"
                name="bioInstagram"
                ref={register({ required: false })}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel style={{ fontSize: 12, marginBottom: 8 }}>
                공개 이메일
              </FormLabel>
              <CustomFormInput
                placeholder="공개 이메일"
                name="bioEmail"
                ref={register({ required: false })}
              />
            </FormGroup>
            <Flex justify="flex-end" style={{ width: '100%', marginTop: 20 }}>
              <SubmitBtn
                type="submit"
                style={
                  {
                    borderRadius: 4,
                    width: 80,
                    height: 36,
                    padding: 0,
                    fontSize: 14,
                  }
                }
              >
                저장
              </SubmitBtn>
              <SubmitBtn
                type="button"
                onClick={() => history.goBack()}
                style={
                  {
                    borderRadius: 4,
                    border: `solid 1px ${colors.grey_d4}`,
                    backgroundColor: colors.white_ff,
                    color: colors.grey_64,
                    width: 80,
                    height: 36,
                    padding: 0,
                    fontSize: 14,
                    marginLeft: 16,
                  }
                }
              >
                취소
              </SubmitBtn>
            </Flex>
          </InputBox>
        </Form>
      </Section>
    </PageLayout>
  )
}

export default UserUpdateTemplate

const Section = styled(Flex)`
  max-width: 1064px;
  width: 100%;
  z-index: 2;

  flex-direction: column;
  width: 100%;
`

const Form = styled.form`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${mobileDesktop} {
    flex-direction: column;
    align-items: center;
  }
`

const InputBox = styled.div`
  max-width: 520px;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormGroup = styled.div`
  padding: 10px 0px;
  width: 100%;
  text-align: left;
`

const CustomFormInput = styled(FormInput)`
  font-size: 12px;
  padding: 10px;
`
