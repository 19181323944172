import React from 'react'
import { H1, SubTitle, SubscribeMsgShort } from '@components/atoms'
import { MoveBtn } from '@components/molecules'
import { PlanInfoCard } from '@components/organisms'
import { PageLayout } from '..'

const PlanMyInfoTemplate = ({ user, myPlan, deleteUser }) => {
  const planInfo = myPlan && myPlan.validation ? myPlan.validation : {}
  let paymentMethod = ''

  if (planInfo.paymentMethod) {
    if (planInfo.paymentMethod === 'MOBILE') paymentMethod = '휴대폰'
    else if (planInfo.paymentMethod === 'CARD') paymentMethod = '카드'
  }
  else if (planInfo.purchaseItem && planInfo.purchaseItem.platform) {
    const { platform } = planInfo.purchaseItem

    if (platform === 1) paymentMethod = 'App Store (구글)'
    else if (platform === 2) paymentMethod = 'App Store (애플)'
  }

  const handleClick = () => {
    const isConfirm = window.confirm('정말 회원 탈퇴하시겠습니까?')

    if (isConfirm) {
      deleteUser()
    }
  }

  return (
    <PageLayout>
      <SubTitle>
        {user && user.name}
        님의
      </SubTitle>
      <H1 style={{ marginBottom: 30 }}>구독 정보</H1>

      <PlanInfoCard
        plan={myPlan && myPlan.plan ? myPlan.plan : ''}
        email={user && user.email}
        platform={planInfo.purchaseItem && planInfo.purchaseItem.platform}
        paymentMethod={paymentMethod}
        paymentCardNumber={planInfo.CARD_Num || planInfo.cardno}
        paymentPhoneNumber={planInfo.HPP_Num}
        autoRenewing={planInfo.autoRenewing}
        paymentNextDate={planInfo.expirationDate}
        isExpired={planInfo.isExpired}
      />

      <MoveBtn
        style={{ marginTop: 30 }}
        onClick={handleClick}
        text="회원 탈퇴"
      />
      <SubscribeMsgShort />
    </PageLayout>
  )
}

export default PlanMyInfoTemplate
