import { Flex, H3, H5, H6 } from '@components/atoms'
import { LiveRoomModel } from '@stores/models'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'

import { ReserveSalonCard, SalonCard } from '@components/organisms'
import { colors } from '@resources/colors'
import { useHistory } from 'react-router-dom'
import { PageLayout } from '..'

interface ISalonReservationListTemplate {
  representiveTagList: any;
  salonSearchKeyword: string;
  salonList: Array<LiveRoomModel>;
  handleSearchTag: Function;
  hasMore: boolean;
  handleScrollList: Function;
}

const SalonReservationListTemplate: React.FC<ISalonReservationListTemplate> = ({
  representiveTagList,
  salonSearchKeyword,
  salonList,
  handleSearchTag,
  hasMore,
  handleScrollList,
  isInitializedPage,
}) => {
  const history = useHistory()
  return (
    <PageLayout>
      <Flex type="column" style={{ width: '100%', maxWidth: 1680 }}>
        <H3 align="left" type="Bold" style={{ marginBottom: 24 }}>
          SALON 살롱
        </H3>
        <Flex>
          <H6
            onClick={() => history.push('/p/salon/list')}
            type="Bold"
            color={colors.grey_bf}
            style={
              {
                cursor: 'pointer',
                marginRight: 28,
              }
            }
          >
            살롱
          </H6>
          <H6
            onClick={() => history.push('/p/salon/reservationList')}
            type="Bold"
            style={
              {
                cursor: 'pointer',
                borderBottom: `2px solid ${colors.black_24}`,
              }
            }
          >
            오픈 예정 살롱
          </H6>
        </Flex>
      </Flex>
      {
        !isInitializedPage || (salonList && salonList.length > 0) ? (
          <Content>
            <InfiniteScroll
            // scrollableTarget="scrollableDiv"
              dataLength={salonList.length}
              scrollThreshold={0.9}
              next={handleScrollList}
              hasMore={hasMore}
              className="scroll_div"
              scrollableTarget="scrollableDiv"
              // style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
              style={{ width: '100%', overflow: 'visible' }}
              hasChildren
              loader={
                <ReactLoading
                  type="spin"
                  color="black"
                  style={
                    {
                      width: '50px',
                      height: '50px',
                      margin: '0 auto 50px auto',
                    }
                  }
                />
              }
            >
              <List style={{ marginTop: 48 }}>
                {
                  salonList
                && salonList.map((room, index) => {
                  if (room['isReserved'] && !room['isOpened']) {
                    return (
                      <ReserveSalonCard
                        salon={room}
                        key={`reserve_${room.id}`}
                        type="reservationList"
                      />
                    )
                  }
                  return <SalonCard item={room} key={room.id} />
                })
                }
              </List>
            </InfiniteScroll>
          </Content>
        ) : (
          <H5>예약된 살롱이 없습니다!</H5>
        )
      }
    </PageLayout>
  )
}

export default observer(SalonReservationListTemplate)

const Content = styled(Flex)`
  max-width: 1704px;
  width: calc(100% + 39px);
  margin-left: -24px;
  height: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* width: calc(100% + 39px);

  margin-left: -24px; */

  > div {
    margin-left: 24px;
    margin-bottom: 48px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
