import React, { useState, useEffect , useCallback } from 'react'
import {
  Button,
  Flex,
  Img,
} from '@components/atoms'
import { images } from '@images'
import moment from 'moment'

const TimePicker = ({
  reservedPublishedAt,
  setReservedPublishedAt,
}) => {
  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', flex:1}}>
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(parseInt(moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm').format('HH')) > 11 ? -12 : 12, 'hour').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_up_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
        <div style={{padding: '1px 4px 2px 4px', borderWidth: '0 0 1px 0', borderColor: '#bfbfbf', borderStyle: 'solid'}}>
          {(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('a') === 'am' ? '오전' : '오후'}
        </div>
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(parseInt(moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm').format('HH')) > 11 ? -12 : 12, 'hour').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_down_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
      </div>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', flex:1}}>
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(1, 'hour').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_up_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
        <input
          style={{padding: '1px 4px', borderWidth: '0 0 1px 0', borderColor: '#bfbfbf', borderStyle: 'solid'}}
          size={2}
          placeholder=""
          value={(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('hh')}
          onChange={
            e => {
              let newValue = parseInt(e.target.value?.slice(-2))
              if(newValue > 12) {
                newValue %= 10
              }
              if(newValue === 12) {
                newValue = 0
              }
              if(!(newValue >= 0 && newValue < 12)) {
                return
              }
              const newValueStr = `${newValue}`.padStart(2, '0')
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${newValueStr}:${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        />
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(-1, 'hour').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_down_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
      </div>
      :
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', flex:1}}>
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(1, 'minute').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_up_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
        <input
          style={{padding: '1px 4px', borderWidth: '0 0 1px 0', borderColor: '#bfbfbf', borderStyle: 'solid'}}
          size={2}
          placeholder=""
          value={(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('mm')}
          onChange={
            e => {
              let newValue = parseInt(e.target.value?.slice(-2))
              if(newValue >= 60) {
                newValue %= 10
              }
              if(!(newValue >= 0 && newValue < 60)) {
                return
              }
              const newValueStr = `${newValue}`.padStart(2, '0')
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).format('HH')}:${newValueStr}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        />
        <Button
          style={{ padding: 20 }}
          onClick={
            () => {
              const newReservedPublishedAt = moment(`${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD') : moment()).format('YYYY-MM-DD')} ${(reservedPublishedAt ? moment(reservedPublishedAt, 'YYYY-MM-DD HH:mm') : moment()).add(-1, 'minute').format('HH:mm')}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
              setReservedPublishedAt(newReservedPublishedAt)
            }
          }
        >
          <Img
            src={images.move_down_gray_img}
            width="12px"
            height="12px"
          />
        </Button>
      </div>
    </div>
  )
}

export default TimePicker
