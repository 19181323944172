import React from 'react'
import styled from 'styled-components'
import { Modal, H4, Caption1 } from '@components/atoms'
import { RoundBtn, FlexRowBox } from '@components/molecules'
import { colors } from '@resources/colors'

const ModalBody = styled(FlexRowBox)`
  width: 580px;
  height: 240px;
  flex-direction: column;
`

const View = ({ isShowing, toggle, level }) => {
  return (
    <>
      <Modal.View isShowing={isShowing} toggle={toggle}>
        {
          level === 1004 ? (
            <ModalBody>
              <H4>환전 신청 자격을 검토 하겠습니다.</H4>
              <Caption1 style={{ margin: '20px 0 60px 0' }} color="#949494">
              제출하신 정보를 서비스팀에서 검토할 예정 입니다.
                <br />
              검토 기간 중 본인 확인을 위하여 회원님이 인증하신 이메일 혹은
              핸드폰으로
                <br />
              연락드릴 수 있으며, 일주일 정도의 시간이 걸릴 수 있습니다.
              </Caption1>
              <RoundBtn
                text="확인"
                style={
                  {
                    width: '230px',
                    height: '48px',
                    border: 'solid 2px #f4f4f4',
                    backgroundColor: colors.primary,
                    color: '#ffffff',
                  }
                }
                onClick={toggle}
              />
            </ModalBody>
          ) : (
            <ModalBody>
              <H4>환전 신청 단계를 완료해주세요.</H4>
              <Caption1 style={{ margin: '20px 0 60px 0' }} color="#949494">
              환전 신청 전에 화면 하단에 있는
                <br />
              환전 신청을 위한 3단계를 완료해주세요.
              </Caption1>
              <RoundBtn
                text="확인"
                style={
                  {
                    width: '230px',
                    height: '48px',
                    border: 'solid 2px #f4f4f4',
                    backgroundColor: colors.primary,
                    color: '#ffffff',
                  }
                }
                onClick={toggle}
              />
            </ModalBody>
          )
        }
      </Modal.View>
    </>
  )
}

export default {
  View,
  useModal: Modal.useModal,
}
