/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { observer } from 'mobx-react'
import { useStore } from '@utils/hooks'
import srtParser2 from "srt-parser-2";

// import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Caption1,
  Caption2,
  Caption3,
  Div,
  Flex,
  H6,
  Image,
  Img,
  ProfileImg,
} from '@components/atoms'
import { colors } from '@resources/colors'
import { images } from '@images/'
import { castDuration, timeForToday, urlify } from '@utils/format'
import {
  CommentInputBox,
  DeletePopup,
  FeedThreeDotPopup,
  MentionCaption,
  UserReportPopup,
} from '@components/molecules'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { STORAGE_URL } from '@consts/'
import ReactPlayer from 'react-player'
import { mobile } from '@styles/media'
import { CommentListItem } from '..'

const QRCode = require('qrcode.react')

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9040;
  width: 100vw;
  height: 100vh;
  min-width: 300px;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(36, 36, 36, 0.7);
`

const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9050;
  background: white;
  max-width: 960px;
  max-height: 600px;

  width: 100%;
  height: 100%;
`

const MobdlBody = styled.div`
  display: flex;
  position: relative;

  width: 100%;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

// const CustomInifiniteScroll = styled(InfiniteScroll)`
//   overflow-y: auto;
//   overflow-x: hidden;
//   width: 100%;

//   ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
//   }
// `

const ModalWrapper = styled.div`
  min-width: 260px;
  outline: 0;
`

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  function toggle() {
    !isShowing ? disableBodyScroll() : enableBodyScroll()
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle,
  }
}

const CommentMessage = ({ message }) => {
  return (
    <MessageAnimation>
      <MessageBox>
        <Caption1 color={colors.white_ff} type="Regular">
          {message}
        </Caption1>
      </MessageBox>
    </MessageAnimation>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: dots => (
    <Div bottom="0px" padding="12px 0">
      <ul style={{ margin: '0px' }}>
        {' '}
        {dots}
        {' '}
      </ul>
    </Div>
  ),
  // customPaging: i => (
  //   <div
  //     style={
  //       {
  //         width: '30px',
  //         color: 'blue',
  //         border: '1px blue solid',
  //       }
  //     }
  //   >
  //     {i + 1}
  //   </div>
  // ),
}

const View = observer(
  ({ isShowing, toggle, feedId, userId, type, fromHouse = false }) => {
    const {
      authStore,
      feedStore,
      commentStore,
      customerCenterStore,
      userStore,
    } = useStore()

    const currentUser = authStore.currentUser || null
    const feedDetail = feedStore.feedDetail || null
    const _autoCompleteUserObj = commentStore._autoCompleteUserObj || null
    const _fetchUserAccount = userStore.fetchUserAccount

    const playerRef = useRef() // player element

    const [textArea, setTextArea] = React.useState('')
    const [targetUser, setTargetUser] = React.useState([])
    const [isEdit, setIsEdit] = React.useState(false) // 댓글 수정 유무
    const [editId, setEditId] = React.useState(null) //  댓글 수정 아이디
    const [message, setMessage] = React.useState('') // 댓글 액션 메세지
    const [showMessage, setShowMessage] = React.useState(false) // 댓글 메세지 노출 유무
    const [parentCommentId, setParentCommentId] = React.useState(null) // 답글 부모 댓글 id
    const [parentCommentName, setParentCommentName] = React.useState(null) // 답글 부모 유저 이름
    const [reason, setReason] = React.useState(0) // 신고 사유
    const [playedSeconds, setPlayedSeconds] = React.useState(0) // 오디오 재생 시간
    const [muted, setMuted] = React.useState(false) // 오디오 음소거
    const [playing, setPlaying] = React.useState(false) // 오디오 재생 여부

    const feedType = useMemo(() => {
      //     1) 텍스트(or 음성)만 있을 경우의 UI (이미지, 링크 X)
      // 2) 음성과 텍스트가 같이 있을 경우의 UI (이미지, 링크 X)
      // 3) 링크 AND (음성 or 텍스트가 있을 경우의 UI) → 링크 미리보기 이미지 활용 (페이스북, 트위터 참고)
      // 4) 이미지 AND (음성 or 텍스트 or 링크가 있을 경우의 UI) →  링크가 있을 경우에는 하이퍼 링크정도로 잡고 이미지 미리보기 이미지 활용
      if (feedDetail?.FeedImages?.length > 0) {
        setPlaying(true)
        return 'haveImage'
      }
      const urls = feedDetail?.content?.match(/(https?:\/\/[^\s]+)/g)
      if (urls?.length > 0) {
        return 'haveLink'
      }
      return 'haveText'
    }, [feedDetail])

    const _init = () => {
      setTextArea('')
      setTargetUser([])
      setIsEdit(false)
      setEditId(null)
      setMessage('')
      setShowMessage(false)
      setParentCommentId(null)
      setParentCommentName(null)
      setReason(0)
    }

    const {
      isShowing: deleteShowing,
      toggle: deleteToggle,
    } = DeletePopup.useModal()

    const {
      isShowing: showThreeDot,
      toggle: toggleThreeDot,
    } = FeedThreeDotPopup.usePopup()

    const {
      isShowing: showUserReport,
      toggle: toggleUserReport,
    } = UserReportPopup.usePopup()

    const inputRef = React.useRef(null)
    const targetInfo = React.useRef(null)

    React.useEffect(() => {
      if (feedId) {
        const options = {
          __include: 'Users,FeedImages',
        }
        if (authStore?.isLogined?.()) {
          options['__include'] = fromHouse
            ? 'Users,FeedImages,UsersLikeFeeds,Houses,AudioFiles,AudiFiles,AudioFiles.AudioFileTexts'
            : 'Users,FeedImages,UsersLikeFeeds,AudioFiles,AudioFiles.AudioFileTexts'
          options['UsersLikeFeeds.userId'] = authStore?.currentUser?.id
        }

        feedStore.fetchFeed(feedId, options)
      }
    }, [feedStore, authStore, feedId, fromHouse])

    // 댓글 액션 메세지 호출
    const actionMessage = message => {
      setMessage(message)
      setShowMessage(true)
      setTimeout(() => setShowMessage(false), 2500)
    }

    const submitComment = async () => {
      const contentDOM = document.getElementsByClassName(
        'comment_input_mentions-textarea__input',
      )

      const params = [
        {
          userId: currentUser && currentUser.id,
          targetId: feedId,
          isReply: 1,
          targetTableName: 'Feeds',
          content: contentDOM[0].innerHTML,
          rawContent: textArea,
          Users: targetUser,
          parentCommentId: parentCommentId || null,
        },
      ]

      if (isEdit) {
        if (!editId) return
        await commentStore.editComment(editId, params[0], 'Feeds')
        actionMessage('댓글이 수정이 완료되었습니다.')
      }
      else {
        const result = await commentStore.postComment(params, 'Feeds')
        if (result) {
          actionMessage('댓글 등록 완료')
        }
      }

      setIsEdit(false)
      setEditId(null)
      setTextArea('')
      setParentCommentId(null)
    }

    const handleLikeFeed = async feed => {
      if (feed) {
        if (feed && feed.isLiked) {
          const res = await feedStore.dislikeFeed(feed)
          if (!res) return

          feed.likeCount--
        }
        else {
          const res = await feedStore.likeFeed(feed)
          if (!res) return

          feed.likeCount++
        }
      }
    }

    const handleSearchMention = async keyword => {
      return await commentStore.searchUserList(keyword)
    }

    const handleReply = (item, isChild = false) => {
      setParentCommentName(item.User.name)
      if (!isChild) {
        setParentCommentId(item.id)
      }
      if (isChild) {
        setParentCommentId(item.parentCommentId)
      }
      setTextArea(`@[${item.User.name}](id:${item.User.id}) `)
      setTargetUser([{ id: item.User.id, name: item.User.name }])
      inputRef.current.focus()
    }

    const handleEdit = (id, rawContent) => {
      setEditId(id)
      setIsEdit(true)
      setTextArea(rawContent)
      inputRef.current.focus()
    }

    // 댓글 삭제 함수
    const removeComment = async () => {
      await commentStore.removeComment(editId, 'Feeds')
      actionMessage('댓글이 삭제되었습니다.')
    }

    const handleDelete = id => {
      setEditId(id)
      deleteToggle()
    }

    const _toggleUserReport = (type, userId, item) => {
      targetInfo.current = { type, userId, item }
      toggleUserReport()
    }

    const handleReport = async (userId, reason) => {
      if (!targetInfo || !reason) return

      let _reason = ''
      if (reason === 1)
        _reason =
          targetInfo.current.type === 'user'
            ? '부적절한 피드 내용'
            : '부적절한 댓글 내용'
      else _reason = '커뮤니티 가이트라인 위반'

      const message =
        `[신고내용]`
        + `\n<br>신고 사유: ${_reason}`
        + `\n<br> 텍스트 내용: ${targetInfo.current.item.content}`
        + `\n<br>\n<br> [댓글 정보]`
        + `\n<br> id: ${targetInfo.current.item.id}\n<br> 작성자: ${targetInfo.current.item.User.name}\n<br> 내용: ${targetInfo.current.item.content}\n<br>\n<br> [신고자 정보]`
        + `\n<br> userId: ${userId}`

      if (targetInfo.current.type === 'user')
        await customerCenterStore.sendFeedsFeedback(userId, _reason, message)
      else
        await customerCenterStore.sendFeedback({ type: '댓글 신고', message })
    }

    React.useEffect(() => {
      if (!isShowing) {
        _init()
        setPlaying(false)
      }
    }, [isShowing])

    const parseSrtText = useMemo(() => {
      if(feedDetail?.AudioFile?.AudioFileTexts[0].srtText){
        const parser = new srtParser2();
        const result = parser.fromSrt(feedDetail?.AudioFile?.AudioFileTexts[0].srtText)
        return result
      } return []
    },[feedDetail?.AudioFile?.AudioFileTexts[0].srtText])

    return isShowing
      ? ReactDOM.createPortal(
        <>
          <ModalOverlay onClick={() => toggle()} />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <Modal>
              <MobdlBody>
                <Div width="600px" height="600px" className="feed-image">
                  {
                    feedType !== 'haveImage'
                    ? <Div
                      width="100%"
                      height="100%"
                      padding="24px"
                      display="flex"
                      flexDirection="column"
                      borderRight="1px solid #f4f4f4"
                    >
                      <MentionCaption
                        content={urlify(feedDetail?.rawContent || '')}
                        size="14px"
                        style={{ lineHeight: '24px' }}
                        fetchUserAccount={_fetchUserAccount}
                      />
                      {
                        feedDetail?.AudioFile?.AudioFileTexts?.length > 0 && (
                          <Div
                            width="100%"
                            height="fit-content"
                            borderRadius="8px"
                            border="1px solid #f4f4f4"
                            padding="12px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Div
                              position="relative"
                              width="fit-content"
                              height="28px"
                              borderRadius="16px"
                              border="1px solid #f4f4f4"
                              display="flex"
                              alignItems="center"
                              gap="6px"
                              padding="4px 12px 4px 4px"
                              cursor="pointer"
                              overflow="hidden"
                              onClick={
() => {
                                playerRef.current.seekTo(0)
                                if(!playing) {
                                  if (muted) setMuted(false)
                                  setPlaying(true)
                                }
 else setPlaying(false)
                              }
}
                            >
                              <Div position="absolute" top="0" left="0" width={`${(playedSeconds || 0) / feedDetail?.AudioFile?.duration * 100}%`} height="100%" backgroundColor="#949494" opacity="0.5" />
                              <Img src={playing ? images.mixtape_pause_btn_black_img : images.mixtape_play_btn_black_img} width="20px" height="20px" zIndex={2} />
                              <H6 color="#242424" style={{zIndex:2}}>{castDuration(feedDetail?.AudioFile?.duration - (playedSeconds || 0) || 0)}</H6>
                            </Div>
                            <Div textAlign="left">
                              {
                                parseSrtText?.map(text => {
                                  const played = playedSeconds >= text.startSeconds
                                  return <SrtText color={played ? colors.primary : "#646464"} key={text.id}>{text.text}</SrtText>
                                })
                              }
                            </Div>
                          </Div>
                        )
                      }
                      <Caption2 align="left" color={colors.brown_grey} marginTop="12px">
                        {
                          feedDetail
                          && feedDetail.editedAt > feedDetail.createdAt
                            ? `${timeForToday(feedDetail.editedAt)} (수정됨)`
                            : timeForToday(feedDetail.createdAt)
                        }
                      </Caption2>
                      </Div>
                      :feedDetail?.FeedImages?.length > 1 ? (
  <Slider {...settings}>
    {
feedDetail?.FeedImages?.map(item => (
  <Div width="600px" height="600px" key={item.id}>
    <BlurContainer
      background={
                                item?.imageUrl || images.default_song_img
      }
    >
      <BlurBackImg />
      <MainImg
        src={item?.imageUrl || images.default_song_img}
        alt="cast"
      />
    </BlurContainer>
    {/* <Image
      src={item.imageUrl}
      width="600px"
      height="100%"
      style={{ objectFit: 'cover' }}
    /> */}
  </Div>
))
    }
  </Slider>
) : feedDetail?.FeedImages?.length === 1 ? (
  <Div width="600px" height="600px">
    <BlurContainer
      background={
                            feedDetail?.FeedImages?.[0]?.imageUrl
                            || images.default_song_img
      }
    >
      <BlurBackImg />
      <MainImg
        src={
                              feedDetail?.FeedImages?.[0]?.imageUrl
                              || images.default_song_img
        }
        alt="cast"
      />
    </BlurContainer>
    {/* <Image
      src={
                            feedDetail?.FeedImages
                            && feedDetail.FeedImages.length > 0
                            && feedDetail.FeedImages[0].imageUrl
      }
      width="600px"
      height="100%"
      style={{ objectFit: 'cover' }}
    /> */}
  </Div>
) : (
                        <></>
                      )
                  }
                </Div>

                <Flex type="column" style={{ width: 360, height: '100%' }}>
                  <HeaderArea>
                    {
feedDetail?.houseId ? (
  <Link
    style={{ color: colors.black_24 }}
    to={`/house/${feedDetail?.House?.linkKey}`}
    target="_blank"
  >
    <Flex align="center">
      <ProfileImg
        style={
          {
            width: 32,
            height: 32,
            marginRight: 8,
            borderRadius: '50%',
          }
        }
        src={`${STORAGE_URL}${feedDetail?.House?.imageUri}`}
      />
      <Flex
        type="column"
        justify="space-between"
        align="flex-start"
      >
        <Flex align="center">
          <Caption2 type="Bold">
            {feedDetail?.House?.title}
          </Caption2>
          <Img
            src={images.house_icon_img}
            width="12px"
            height="12px"
            marginLeft="2px"
          />
        </Flex>
        <Caption3 color={colors.grey_94}>
          {feedDetail?.User?.name}
        </Caption3>
      </Flex>
    </Flex>
  </Link>
) : (
  <Link
    style={{ color: colors.black_24 }}
    to={`/user/${feedDetail?.User?.account}`}
    target="_blank"
  >
    <Flex align="center">
      <ProfileImg
        style={
          {
            width: 32,
            height: 32,
            marginRight: 8,
            borderRadius: '50%',
          }
        }
        src={feedDetail?.User?.imageUri}
      />
      <Caption2 type="Bold">
        {feedDetail?.User?.name}
      </Caption2>
    </Flex>
  </Link>
)
                    }
                    {/* <Image
                    src={images.three_dot_black}
                    width="20px"
                    height="20px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggleThreeDot}
                  /> */}
                  </HeaderArea>
                  <ContentArea>
                    {
feedType === 'haveImage' && <Flex
                      type="column"
                      style={
                        {
                          width: '100%',
                          padding: 16,
                          borderBottom: `1px solid ${colors.white_f4}`,
                        }
                      }
>
                      <MentionCaption
                        content={feedDetail?.rawContent}
                        size="14px"
                        style={{ lineHeight: '24px' }}
                        fetchUserAccount={_fetchUserAccount}
                      />
                      {
                        feedType === 'haveImage'
                          && feedDetail?.AudioFile?.AudioFileTexts?.length > 0 && (
                            <Div
                              width="100%"
                              height="fit-content"
                              borderRadius="8px"
                              border="1px solid #f4f4f4"
                              padding="12px"
                              display="flex"
                              flexDirection="column"
                            >
                              <Div
                                position="relative"
                                width="fit-content"
                                height="28px"
                                borderRadius="16px"
                                border="1px solid #f4f4f4"
                                display="flex"
                                alignItems="center"
                                gap="6px"
                                padding="4px 12px 4px 4px"
                                cursor="pointer"
                                overflow="hidden"
                                onClick={
() => {
                                  playerRef.current.seekTo(0)
                                  if(!playing) {
                                    if (muted) setMuted(false)
                                    setPlaying(true)
                                  }
 else setPlaying(false)
                                }
  }
                              >
                                <Div position="absolute" top="0" left="0" width={`${(playedSeconds || 0) / feedDetail?.AudioFile?.duration * 100}%`} height="100%" backgroundColor="#949494" opacity="0.5" />
                                <Img src={playing ? images.mixtape_pause_btn_black_img : images.mixtape_play_btn_black_img} width="20px" height="20px" zIndex={2} />
                                <H6 color="#242424" style={{zIndex:2}}>{castDuration(feedDetail?.AudioFile?.duration - (playedSeconds || 0) || 0)}</H6>
                              </Div>
                              <Div textAlign="left">
                                {
                                  parseSrtText?.map(text => {
                                    const played = playedSeconds >= text.startSeconds
                                    return <SrtText color={played ? colors.primary : "#646464"} key={text.id}>{text.text}</SrtText>
                                  })
                                }
                              </Div>
                            </Div>
                          )
                      }
                      <Caption2 align="left" color={colors.brown_grey} style={{marginTop:feedDetail?.AudioFile?.AudioFileTexts?.length > 0 ? 12 : 0}}>
                        {
                          feedDetail
                          && feedDetail.editedAt > feedDetail.createdAt
                            ? `${timeForToday(feedDetail.editedAt)} (수정됨)`
                            : timeForToday(feedDetail.createdAt)
                        }
                      </Caption2>
                            </Flex>
}
                    <Flex style={{ margin: '16px' }}>
                      <Caption1 type="Bold" style={{ marginRight: 4 }}>
                          댓글
                      </Caption1>
                      <Caption1 color="#707070">
                        {feedDetail?.commentList?.length || 0}
                      </Caption1>
                    </Flex>
                    <CommentList>
                      {
                        feedDetail
                          && feedDetail.commentList
                          && feedDetail.commentList.length > 0
                          && feedDetail.commentList.map(comment => (
                            <CommentListItem
                              key={comment.id}
                              item={comment}
                              handleReply={handleReply}
                              handleEdit={handleEdit}
                              handleDelete={handleDelete}
                              handleReport={_toggleUserReport}
                            />
                          ))
                      }
                    </CommentList>
                  </ContentArea>
                  {
                    parentCommentId && (
                      <ChildReplyStatus>
                        <Caption3 color={colors.grey_94} align="left">
                          {`@${parentCommentName}님에게 답글 남기는 중`}
                        </Caption3>
                        <Image
                          src={images.x_btn_img}
                          style={{ width: 12, height: 12, cursor: 'pointer' }}
                          onClick={() => setParentCommentId(null)}
                        />
                      </ChildReplyStatus>
                    )
                  }
                  <FooterArea>
                    <Flex
                      justify="space-between"
                      style={{ padding: '0 16px', marginBottom: 9 }}
                    >
                      <Flex>
                        <Image
                          src={
                              feedDetail?.isLiked
                                ? images.heart_img_red_12
                                : images.heart_img_black_default
                          }
                          width="24px"
                          height="24px"
                          style={{ marginRight: 12, cursor: 'pointer' }}
                          onClick={() => handleLikeFeed(feedDetail)}
                        />
                        <Image
                          src={images.comment_default_img}
                          width="24px"
                          height="24px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => inputRef.current.focus()}
                        />
                      </Flex>
                      {
feedDetail?.isHouseMember && (
                          <Img
                            src={images.salon_type_member}
                            width="24px"
                            height="24px"
                            marginRight={feedDetail?.AudioFile ? 8 : 0}
                          />
                        )
                      }
                      {
feedType ==='haveImage' && feedDetail?.AudioFile && (
                          <Div
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="24px"
                            height="24px"
                            backgroundColor={
                              muted ? colors.white_f4 : colors.white_ff
                            }
                            borderRadius="50%"
                            border={`1px solid ${colors.white_f4}`}
                          >
                            <Img
                              onClick={
                                () => {
                                  if (muted) {
                                    setMuted(false)
                                    if (playerRef && playerRef.current) {
                                      playerRef.current.seekTo(0)
                                    }
                                  }
                                  else {
                                    setMuted(true)
                                  }
                                }
                              }
                              src={
                                muted
                                  ? images.volume_mute_img_gray
                                  : images.volume_btn_img_black
                              }
                            />
                          </Div>
                        )
                      }
                    </Flex>
                    {
feedDetail?.likeCount > 0 && (
                        <Caption2
                          align="left"
                          type="Bold"
                          style={{ marginBottom: 13, padding: '0 16px' }}
                        >
                          {`좋아요 ${feedDetail?.likeCount}개`}
                        </Caption2>
                      )
                    }
                    <CommentInputBox
                      ref={inputRef}
                      aria-label="With textarea"
                      placeholder="댓글 달기"
                      value={textArea}
                      setValue={setTextArea}
                      setTargetUser={setTargetUser}
                      submitComment={submitComment}
                      handleSearchMention={handleSearchMention}
                      _autoCompleteUserObj={_autoCompleteUserObj}
                      style={
                        {
                          width: 'calc(100% - 16px)',
                          marginLeft: 8,
                          alignItems: 'center',
                        }
                      }
                    />
                  </FooterArea>
                </Flex>
                {
feedDetail?.AudioFile && (
                    <>
                      <ReactPlayer
                        ref={playerRef}
                        muted={muted}
                        volume={0.7}
                        playing={playing}
                        url={
                          `${STORAGE_URL}${feedDetail?.AudioFile?.m3u8Uri
                          ?? feedDetail?.AudioFile?.mp3Uri
                          ?? feedDetail?.AudioFile?.originalUri}`
                        }
                        controls={false}
                        config={
                          {
                            file: {
                              attributes: {
                                // crossOrigin: 'true',
                                controlsList: 'nodownload', // <- this is the important bit
                                id: 'audio-elem ent',
                                autoPlay: false,
                              },
                            },
                          }
                        }
                        style={{ display: 'none' }}
                        onProgress={
                          state => {
                            if (state.played === 1) {
                              setPlayedSeconds(feedDetail.AudioFile.duration)
                            }
                            else {
                              setPlayedSeconds(state.playedSeconds)
                            }
                          }
                        }
                        onPlay={() => setPlaying(true)}
                        onPause={() => setPlaying(false)}
                        onEnded={
() => {
                            setPlaying(false);
                            if (playerRef?.current) playerRef.current.seekTo(0);
                          }
                        }
                      />
                      <PlayBar>
                        <ProgressBar
                          readOnly
                          type="range"
                          min={0}
                          max={feedDetail?.AudioFile?.duration}
                          step="any"
                          value={playedSeconds}
                        />
                      </PlayBar>
                    </>
                  )
                }
                <InduceBox>
                  <H6>모바일에서 편하게 더 많은 피드들과 함께 소통하세요.</H6>
                  <QRCode
                    value="https://app.hreum.me/stores"
                    size={64}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}
                    level={'L'}
                    includeMargin={false}
                    renderAs={'svg'}
                    imageSettings={
                      {
                        src: 'https://hreum.me/favicon.ico',
                        x: null,
                        y: null,
                        height: 12,
                        width: 12,
                        excavate: true,
                      }
                    }
                  />
                </InduceBox>
              </MobdlBody>
            </Modal>
            {showMessage && <CommentMessage message={message} />}
            <DeletePopup.View
              isShowing={deleteShowing}
              toggle={deleteToggle}
              title="댓글 삭제"
              body={`이 댓글을\n정말 삭제하시겠습니까?`}
              onDelete={
                () => {
                  removeComment()
                  deleteToggle()
                }
              }
            />
            <UserReportPopup.View
              isShowing={showUserReport}
              toggle={toggleUserReport}
              onOk={handleReport}
              onCancel={() => console.log('onCancel')}
              userId={targetInfo.current?.userId}
              type={targetInfo.current?.type}
              reason={reason}
              setReason={setReason}
            />
            {/* 기능 보류 */}
            <FeedThreeDotPopup.View
              isShowing={showThreeDot}
              toggle={toggleThreeDot}
            />
          </ModalWrapper>
        </>,
        document.body,
      )
      : null
  },
)

export default {
  View,
  useModal,
}

const PlayBar = styled(Flex)`
  position: absolute;
  bottom: -2px;
  width: 960px;
  height: 2px;
  overflow: hidden;
`

export const SrtText = styled.span`
  display: inline-block;
  text-align: left;
  margin: 0;
  padding: 0;
  font-family: ${props =>
    props.type ? `NotoSansCJKkr-${props.type}` : 'NotoSansCJKkr-Medium'};
  color: ${props => props.color};
  font-size: 12px;

  @media ${mobile} {
    font-size: 10px;
  }
`

const InduceBox = styled(Flex)`
  position: absolute;
  bottom: -88px;
  width: 960px;
  height: 80px;
  background-color: ${colors.white_ff};
  justify-content: space-between;
  align-items: center;

  padding: 0 40px;
`

const HeaderArea = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;

  padding: 0 16px;
  border-bottom: 1px solid ${colors.white_f4};
`

const ContentArea = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-height: 420px;
  height: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const FooterArea = styled(Flex)`
  flex-direction: column;
  padding: 12px 0 8px 0;
  border-top: 1px solid ${colors.white_f4};
  width: 100%;
`

const CommentList = styled(Flex)`
  flex-direction: column;
  padding: 16px;
  width: 100%;

  > div {
    margin-bottom: 24px;
  }
`

// Comment Action Message
const messageFade = keyframes`
  0% { opacity: 0;}
  20% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`

const MessageAnimation = styled.div`
  animation-name: ${messageFade};
  animation-duration: 2s;
  animation-iteration-count: no;
  animation-fill-mode: forwards;
  z-index: 100;
`

const MessageBox = styled(Flex)`
  width: 196px;
  height: 52px;
  border-radius: 32px;
  box-shadow: 0 4px 8px 0 rgba(36, 36, 36, 0.4);
  border: solid 1px #242424;
  background-color: #242424;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 50px;
  left: 35%;
`

const ChildReplyStatus = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${colors.white_f4};
  width: 100%;
  min-height: 48px;
  height: 48px;
  padding: 0 16px 0 24px;
`

const ProgressBar = styled.input.attrs(props => ({
  // 고정적인 Props를 전달할 수 있습니다.
  type: 'range',
  // 혹은 다이나믹한 Props도 전달할 수 있습니다.
  // size: props.size || "100px",
}))`
  -webkit-appearance: none;

  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 2px;
  margin: 0;
  background-color: ${colors.brown_grey};
  cursor: pointer;

  :focus {
    outline: none;
  }

  ::-webkit-slider-runnable-track {
    background: #ddd;
  }

  /*
  * 1. Set to 0 width and remove border for a slider without a thumb
  */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0px; /* 1 */
    height: 0px;
    background: ${colors.brown_grey};
    box-shadow: -100vw 0 0 100vw ${colors.primary};
  }

  ::-moz-range-track {
    height: 0px;
    background: #ddd;
  }

  ::-moz-range-thumb {
    background: ${colors.brown_grey};
    height: 0px;
    width: 0px;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw ${colors.primary};
    box-sizing: border-box;
  }

  ::-ms-fill-lower {
    background: ${colors.primary};
  }

  ::-ms-thumb {
    background: ${colors.brown_grey};
    height: 0px;
    width: 0px;
    box-sizing: border-box;
  }

  ::-ms-ticks-after {
    display: none;
  }

  ::-ms-ticks-before {
    display: none;
  }

  ::-ms-track {
    background: #ddd;
    color: transparent;
    height: 0px;
    border: none;
  }

  ::-ms-tooltip {
    display: none;
  }
`

const BlurContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

const MainImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 2;
`

const BlurBackImg = styled.div`
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* z-index: 2; */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
`

