import { observable } from 'mobx'
import FeedModel from './FeedModel'
import TagModel from './TagModel'

export default class FeedsHaveTagsModel {
  @observable id

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable feedId
  @observable tagId

  @observable Feed = null
  @observable Tag = null

  constructor(stores, props) {
    this.stores = stores
    if (props) {
      this.id = props.id

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.feedId = props.feedId
      this.tagId = props.tagId

      this.Feed = (props.Feed && new FeedModel(stores, props.Feed)) || null
      this.Tag = (props.Tag && new TagModel(stores, props.Tag)) || null
    }
  }
}
