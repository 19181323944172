import { Caption2, Div, Flex, H4, H6, ImageIcon } from '@components/atoms'
import {
  SalonChattBtnList,
  SalonDetailThreeDot,
  OkCancelPopup,
  ContentPopup,
  PlanRequiredPopup,
} from '@components/molecules'
import {
  BroadcasterUser,
  RequestGuestModal,
  SalonChattingMessage,
  SubscriptPlanModal,
} from '@components/organisms'
import { LIVE_ROOMS_HAVE_USERS_LEVEL } from '@consts/liveRoom'
import { images } from '@resources/'
import { colors } from '@resources/colors'
import { oneEllipsisStyle } from '@styles/fontStyles'
import { useStore } from '@utils/hooks'
import { message } from 'antd'
import { observer } from 'mobx-react'

import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import moment from 'moment'
import SalonChattingSendForm from '../SalonChattingSendForm'
import LiveRoomFinishSection from './LiveRoomFinishSection'

interface ISalonChattingSection {
  _toggleUserReport: Function;
  setIsSwitchOn: Function;
}

const SalonChattingSection: React.FC<ISalonChattingSection> = observer(
  ({ _toggleUserReport, setIsSwitchOn, toggleSalon }) => {
    const {
      isShowing: showRequestGuest,
      toggle: toggleRequestGuest,
    } = RequestGuestModal.useModal()

    const {
      isShowing: showSubscriptPlan,
      toggle: toggleSubscriptPlan,
    } = SubscriptPlanModal.useModal()

    const history = useHistory()

    const [textArea, setTextArea] = useState('')
    const [moreChattingInfo, setMoreChattingInfo] = useState(true)
    const [openThreeDot, setOpenThreeDot] = useState(false)
    const [requestedGuest, setRequestedGuest] = useState(false)
    const [prevKeypress, setPrevKeypress] = useState(null)

    const [isMoreFixedComment, setIsMoreFixedComment] = useState(false)
    const ref = useRef(null)
    const [fixedCommentHeight, setFixedCommentHeight] = useState(38)

    const { authStore, liveRoomStore, liveRoomRecordingStore } = useStore()

    const {
      isShowing: recordingStartShowing,
      toggle: recordingStartToggle,
    } = OkCancelPopup.usePopup()

    const {
      isShowing: planRequiredShowing,
      toggle: planRequiredToggle,
    } = PlanRequiredPopup.useModal()

    const currentUser = authStore.currentUser || null
    const currLiveRoomUser = liveRoomStore.currLiveRoomUser || null
    const currLiveRoomUserList = liveRoomStore.currLiveRoomUserList || null
    const { isMutedMic, level = 0 } = currLiveRoomUser || {}
    const liveRoomMessageList = liveRoomStore.liveRoomMessageList || []
    const currLiveRoom = liveRoomStore._currLiveRoom || null
    const isMutedRemoteAll = liveRoomStore.isMutedRemoteAll || null
    const isShowFixedComment = liveRoomStore.isShowFixedComment || null

    const liveRoomBroadcasterList = liveRoomStore.currLiveRoomUserList.filter(
      LiveRoomUser =>
        LiveRoomUser['level'] >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'],
    )
    const requestUserList = currLiveRoomUserList.filter(
      liveRoomUser =>
        liveRoomUser['level'] === LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'],
    )
    const requestCount = requestUserList.length
    const isRecording = !!liveRoomRecordingStore.currLiveRoomRecording

    liveRoomBroadcasterList.sort((a, b) => {
      // if (a.level <= LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST']) {
      //   return 0
      // }
      return b.level - a.level
    })

    const remainTime = Math.round(
      ((liveRoomStore?.FREE_OWNER_MAINTAINABLE_SEC || 7200)
        - moment().diff(moment(currLiveRoom?.openedAt), 'seconds'))
        / 60,
    )

    const toggleChattingInfo = () => {
      setMoreChattingInfo(!moreChattingInfo)
    }

    const submitMessage = async (eType, e) => {
      if (eType === 'click') {
        e.preventDefault()
        await liveRoomStore.sendChat(currentUser, textArea)
        setTextArea('')
      }
      else if (eType === 'keyPress') {
        // // enter 빠르게 두번 클릭 방지
        // if (e.charCode === 13 && prevKeypress === 13) {
        //   return
        // }
        setPrevKeypress(e.charCode)

        if (!e.shiftKey && e.charCode === 13) {
          e.preventDefault()
          if (textArea.trim().length === 0) return
          await liveRoomStore.sendChat(currentUser, e.target.value)
          setTextArea('')
        }
      }
    }

    const handleRequestSpeaker = async isRequested => {
      if (isRequested) {
        const canceled = await liveRoomStore.cancelSpeaker()
        if (!canceled) return
        setRequestedGuest(false)
      }
      else {
        const requested = await liveRoomStore.requestSpeaker()
        if (!requested) return
        setRequestedGuest(true)
      }
    }

    const handleSwitchMuteStateMyMic = async isMutedMic => {
      await liveRoomStore.onPressSwitchMuteStateMyMic(isMutedMic)
    }

    const toggleJoinUserAlarm = () => {
      liveRoomStore.isMuteJoinLiveRoom = !liveRoomStore.isMuteJoinLiveRoom
    }

    const toggleRequestGuestAlarm = () => {
      liveRoomStore.isMuteRequestGuest = !liveRoomStore.isMuteRequestGuest
    }

    React.useEffect(() => {
      if (currLiveRoom?.isShowPlanRequiredPopup) {
        planRequiredToggle()
      }
    }, [currLiveRoom?.isShowPlanRequiredPopup])

    React.useEffect(() => {
      if (level !== LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'])
        setRequestedGuest(false)
    }, [level])

    React.useEffect(() => {
      const chattingDom = document.getElementById('chatting_list')
      if (chattingDom && chattingDom.scrollHeight > 0) {
        chattingDom.scrollTop = chattingDom.scrollHeight
      }
    }, [liveRoomBroadcasterList])

    React.useEffect(() => {
      setFixedCommentHeight(ref?.current?.clientHeight || 38)
    }, [isMoreFixedComment])

    // Recording
    const handleRecording = () => {
      recordingStartToggle()
    }

    const handleRecOk = () => {
      const {
        liveRoomId,
        agoraUid,
        agoraRtcChannelId,
        agoraRtcUserToken,
      } = currLiveRoomUser

      isRecording
        ? liveRoomRecordingStore.stopRecording({
          liveRoomId,
        })
        : liveRoomRecordingStore.startRecording({
          agoraUid,
          liveRoomId,
          agoraRtcChannelId,
          agoraRtcUserToken,
        })
    }

    /** change mic */
    const [deviceList, setDeviceList] = useState([])
    const {
      isShowing: contentShowing,
      toggle: contentToggle,
    } = ContentPopup.usePopup()
    const [tempMic, setTempMic] = useState(null)

    const micChangeBody = () => {
      return (
        <Flex type={'column'}>
          <H4>{`마이크 설정`}</H4>
          {
            liveRoomStore.currLiveRoomMicName ? (
              <Flex
                justify={'center'}
                style={{ marginBottom: '16px', marginTop: '8px' }}
              >
                <CurrMicName style={{ marginRight: '4px' }}>
                  {`${liveRoomStore.currLiveRoomMicName}`}
                </CurrMicName>
                <CurrMicLabel>{`연결됨`}</CurrMicLabel>
              </Flex>
            ) : (
              <div />
            )
          }
          {
            deviceList.map(device => {
              return (
                <MicItem
                  isActive={
                    device && tempMic && device.deviceId === tempMic.deviceId
                  }
                  onClick={
                    async () => {
                      setTempMic(device)
                    }
                  }
                  key={device.deviceId}
                >
                  {`${device.label}`}
                  {
                    device && tempMic && device.deviceId === tempMic.deviceId && (
                      <CheckImg
                        src={images.check_purple_img}
                        style={{ width: '12px', height: '12px' }}
                      />
                    )
                  }
                </MicItem>
              )
            })
          }
        </Flex>
      )
    }

    const changeMic = async () => {
      if (tempMic) {
        liveRoomStore.changeCurrMic(tempMic.deviceId)
        liveRoomStore.getCurrMicrophone()
        setOpenThreeDot(false)
        message.success('마이크가 변경되었습니다.')
      }
    }

    const openChangeMicPopup = async () => {
      const _deviceList = await liveRoomStore.getMicrophoneDeviceList()
      setDeviceList(_deviceList)
      setTempMic(null)

      if (liveRoomStore.getCurrMicrophone()) {
        const _device = _deviceList.find(
          _item => _item.label === liveRoomStore.currLiveRoomMicName,
        )
        if (_device) {
          setTempMic(_device)
        }
      }

      contentToggle()
    }

    return (
      <Section>
        {currLiveRoom?.isFinished && <LiveRoomFinishSection />}
        {/* <SectionBackground src={currLiveRoom?.imageUrl} /> */}
        <SalongOverlay />
        <div
          style={
            {
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',

              borderRadius: '16px',
              backgroundImage: `url(${currLiveRoom?.imageUrl})`,
              // opacity: '0.72',
              // backdropFilter: 'blur(24px)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }
          }
        />
        <Content>
          <Flex
            justify="space-between"
            style={
              {
                width: '100%',
                marginBottom: 20,
                paddingLeft: 10,
                paddingRight: 10,
              }
            }
          >
            <Flex>
              <HeaderBtn>
                <img
                  src={
                    isMutedRemoteAll
                      ? images.volume_mute_img
                      : images.volume_btn_img
                  }
                  alt="vol_icon"
                  onClick={
                    () =>
                      liveRoomStore.onPressSwitchMuteStateRemoteAllMic(
                        isMutedRemoteAll,
                      )
                  }
                />
              </HeaderBtn>
              {
                level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] && (
                  <HeaderBtn
                    style={
                      {
                        justifyContent: 'center',
                        marginLeft: '16px',
                      }
                    }
                    onClick={handleRecording}
                  >
                    <RecText>{'REC'}</RecText>
                    <img
                      src={
                        isRecording
                          ? images.salon_rec_active_img
                          : images.salon_rec_inactive_img
                      }
                      alt="rec_icon"
                      style={{ width: '16px', height: '16px' }}
                    />
                  </HeaderBtn>
                )
              }
            </Flex>
            <HeaderBtn>
              <img
                src={images.three_dot_white_img}
                alt="three_dot_icon"
                onClick={() => setOpenThreeDot(true)}
              />
            </HeaderBtn>
          </Flex>
          {/* 살롱 고정 댓글           */}
          {
currLiveRoom?.fixedComment && isShowFixedComment && (
              <FinCommentInfo
                ref={ref}
                onClick={
                  () => {
                    if (!isMoreFixedComment) {
                      setIsMoreFixedComment(true)
                    }
                  }
                }
              >
                <Div display="flex" alginItems="flex-start">
                  <ImageIcon
                    name="whitePin"
                    width="12px"
                    height="12px"
                    marginRight="8px"
                  />
                  <Caption2
                    color={colors.white_ff}
                    type="Regular"
                    style={
                      isMoreFixedComment
                        ? {
                          flex: 1,
                          textAlign: 'left',
                          marginTop: -2,
                        }
                        : {
                          ...oneEllipsisStyle,
                          flex: 1,
                          textAlign: 'left',
                          marginTop: -2,
                        }
                    }
                  >
                    {currLiveRoom.fixedComment}
                  </Caption2>
                  <ImageIcon
                    name="grayArrowBottom"
                    width="12px"
                    height="12px"
                    marginTop="2px"
                    cursor="pointer"
                    transform={
                      isMoreFixedComment ? 'rotate(180deg)' : 'rotate(360deg)'
                    }
                    onClick={() => setIsMoreFixedComment(false)}
                  />
                </Div>

                {
                  isMoreFixedComment && (
                    <Div display="flex" gap="8px" marginTop="16px">
                      {
                        level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'] ? (
                          <>
                            <Div
                              flex="1"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                              height="32px"
                              border="1px solid white"
                              borderRadius="4px"
                              cursor="pointer"
                              onClick={
                                async () => {
                                  await liveRoomStore.deleteFixedComment()
                                  setIsMoreFixedComment(false)
                                }
                              }
                            >
                              <Caption2 color={colors.white_ff} type="Regular">
                          고정 취소
                              </Caption2>
                            </Div>
                            <Div
                              flex="1"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                              height="32px"
                              border="1px solid white"
                              borderRadius="4px"
                              cursor="pointer"
                              onClick={() => setIsMoreFixedComment(false)}
                            >
                              <Caption2 color={colors.white_ff} type="Regular">
                          접기
                              </Caption2>
                            </Div>
                          </>
                        ) : (
                          <Div
                            flex="1"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            height="32px"
                            border="1px solid white"
                            borderRadius="4px"
                            cursor="pointer"
                            onClick={() => setIsMoreFixedComment(false)}
                          >
                            <Caption2 color={colors.white_ff} type="Regular">
                        접기
                            </Caption2>
                          </Div>
                        )
                      }
                    </Div>
                  )
                }
              </FinCommentInfo>
            )
          }
          <ChattingInfo>
            <Flex style={{ alignItems: 'center' }}>
              <Recording isRecording={isRecording} />
              <H6
                style={{ marginLeft: '8px' }}
                align="left"
                color={colors.white_ff}
                onClick={toggleChattingInfo}
              >
                {currLiveRoom?.title}
              </H6>
            </Flex>
            {
              moreChattingInfo && (
                <>
                  <Br style={{ margin: '24px 0 15px 0' }}>-</Br>
                  <BroadcasterList>
                    {
liveRoomBroadcasterList?.map((user, index) => (
  <BroadcasterUser
    user={user}
    key={`${user.id}_${index}`}
    loadSalonUser={liveRoomStore.loadSalonUser}
  />
))
                    }
                  </BroadcasterList>
                </>
              )
            }
          </ChattingInfo>
          {
            liveRoomMessageList && liveRoomMessageList.length > 0 && (
              <ChattingMessageList
                id="chatting_list"
                moreChattingInfo={moreChattingInfo}
                broadCasterCount={liveRoomBroadcasterList?.length}
                isShowFixedComment={isShowFixedComment}
                fixedCommentHeight={fixedCommentHeight}
              >
                {
                  liveRoomMessageList.map((data, index) => (
                    <SalonChattingMessage
                      loadSalonUser={liveRoomStore.loadSalonUser}
                      currentUserId={currentUser?.id}
                      data={data}
                      index={index}
                      key={`${data}_${index}`}
                      _toggleUserReport={
                        userId =>
                          _toggleUserReport(
                            'user',
                            userId,
                      currLiveRoom?.id,
                      currLiveRoom?.title,
                          )
                      }
                      _toggleSubscriptPlan={toggleSubscriptPlan}
                    />
                  ))
                }
              </ChattingMessageList>
            )
          }
          <Flex
            style={
              {
                width: 'calc(100% - 32px)',
                position: 'absolute',
                left: 16,
                bottom: 16,
              }
            }
          >
            <SalonChattingSendForm
              style={{ marginRight: 16 }}
              placeholder="채팅을 입력하세요"
              className="form-control"
              onChange={e => setTextArea(e.target.value)}
              aria-label="With textarea"
              value={textArea}
              onKeyPress={e => submitMessage('keyPress', e)}
              onClick={e => submitMessage('click', e)}
              level={level}
            />
            <SalonChattBtnList
              level={level}
              requestedGuest={requestedGuest}
              requestCount={requestCount}
              isMutedMic={isMutedMic}
              handleExitLiveRoom={() => history.push('/p/salon/list')}
              handleRequestSpeaker={handleRequestSpeaker}
              handleSwitchMuteStateMyMic={handleSwitchMuteStateMyMic}
              handleShowPresentStar={() => setIsSwitchOn(true)}
              toggleRequestGuest={toggleRequestGuest}
            />
          </Flex>
        </Content>
        <SalonDetailThreeDot
          level={level}
          password={currLiveRoom?.password}
          startLiveRoomRecording={liveRoomRecordingStore.startRecording}
          stopLiveRoomRecording={liveRoomRecordingStore.stopRecording}
          isLiveRoomRecording={isRecording}
          currLiveRoomUser={liveRoomStore?.currLiveRoomUser}
          openThreeDot={openThreeDot}
          setOpenThreeDot={setOpenThreeDot}
          isMuteJoinLiveRoom={liveRoomStore.isMuteJoinLiveRoom}
          isMuteRequestGuest={liveRoomStore.isMuteRequestGuest}
          toggleJoinUserAlarm={toggleJoinUserAlarm}
          toggleRequestGuestAlarm={toggleRequestGuestAlarm}
          toggleSalon={toggleSalon}
          muteAllMic={liveRoomStore.muteAllMic}
          changeMic={openChangeMicPopup}
          handleReport={
            () =>
              _toggleUserReport(
                'salon',
                null,
              currLiveRoom?.id,
              currLiveRoom?.title,
              )
          }
          handleExitLiveRoom={
            () => {
            // Todo: 살롱 나가기 버튼 기존 기능 제거
            // liveRoomStore.leaveLiveRoom()
              history.push('/p/salon/list')
            }
          }
          handleReturnListener={
            async () => {
              const returned = await liveRoomStore.returnToListener()
              if (!returned) return
              alert('리스너로 돌아왔습니다.')
            }
          }
          handleReturnToPrevLevel={
            async () => {
              const returned = await liveRoomStore.returnToPrevLevel()
              if (!returned) return
              alert('참여자로 돌아왔습니다.')
            }
          }
          handleFinishLiveRoom={
            () => {
              liveRoomStore.finishLiveRoom(true)
            // history.push('/p/salon/list')
            }
          }
        />
        <SubscriptPlanModal.View
          isShowing={showSubscriptPlan}
          toggle={toggleSubscriptPlan}
          requestUserList={requestUserList}
          acceptToGuest={liveRoomStore.acceptToGuest}
          deleteRequestUser={
            userIdList =>
              liveRoomStore.updateLiveRoomUserList({
                level: LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER'],
                prevLevel: LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'],
                userIdList,
              })
          }
        />
        <RequestGuestModal.View
          isShowing={showRequestGuest}
          toggle={toggleRequestGuest}
          requestUserList={requestUserList}
          acceptToGuest={liveRoomStore.acceptToGuest}
          deleteRequestUser={
            userIdList =>
              liveRoomStore.updateLiveRoomUserList({
                level: LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER'],
                prevLevel: LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'],
                userIdList,
              })
          }
        />
        <OkCancelPopup.View
          isShowing={recordingStartShowing}
          toggle={recordingStartToggle}
          title={isRecording ? '살롱 녹음 종료' : '살롱 녹음 시작'}
          body={
            <div>
              {
                isRecording
                  ? '살롱 녹음을 정말로 종료하시겠습니까?'
                  : '살롱 녹음을 시작하시겠습니까?'
              }
            </div>
          }
          isRightOk
          onOk={handleRecOk}
          okBtnBgColor={isRecording ? colors.warning : colors.primary}
        />
        <PlanRequiredPopup.View
          isShowing={planRequiredShowing}
          toggle={planRequiredToggle}
          confirmText={'플랜 구독하기'}
          cancelText={'괜찮아요'}
          onConfirm={
            async e => {
              toggleSubscriptPlan()
            }
          }
          onCancel={
            () => {
            // this.store.appStateStore.baseNavigation.pop()
            }
          }
          title={'살롱 이용시간'}
          body={
            <div>
              {`살롱 이용시간이 ${remainTime}분 남았습니다.\n플랜을 구독하고\n시간제한 없이 살롱을 이용해 보세요.`}
            </div>
          }
        />
        <ContentPopup.View
          isShowing={contentShowing}
          toggle={contentToggle}
          title={`현재: ${liveRoomStore.currLiveRoomMicName}`}
          body={micChangeBody()}
          onOk={changeMic}
        />
      </Section>
    )
  },
)

export default SalonChattingSection

const RecText = styled(Flex)`
  font-family: NotoSansCJKkr-Medium;
  font-size: 10px;
  color: #ffffff;
  margin-right: 4px;
  padding-bottom: 1px;
`

const Section = styled(Flex)`
  position: relative;
  flex-direction: column;
  max-width: 520px;
  width: 100%;
  align-items: center;

  min-height: 960px;
  max-height: 960px;
  height: 100%;

  border-radius: 16px;
  padding: 24px 16px 16px 16px;
`

// const SectionBackground = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   border-radius: 16px;
//   background-image: url(${({ backgroundImage }) => backgroundImage});
//   opacity: 0.72;
//   backdrop-filter: blur(24px);
//   background-size: cover;
//   background-repeat: no-repeat;
// `

// const SectionBackground = styled.img`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;

//   border-radius: 16px;
//   opacity: 0.72;
//   backdrop-filter: blur(24px);
//   object-fit: cover;
// `

const SalongOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.72);
  z-index: 1;
`

const Content = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  z-index: 3;
`

const HeaderBtn = styled.div`
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`

const ChattingInfo = styled(Flex)`
  flex-direction: column;
  max-width: 472px;
  width: 100%;
  max-height: 390px;
  height: fit-content;

  overflow: auto;

  padding: 24px;
  border-radius: 16px;

  z-index: 1001;

  background-color: rgba(24, 24, 24, 0.5);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
`

const FinCommentInfo = styled(Div)`
  display: flex;
  flex-direction: column;
  max-width: 472px;
  width: 100%;
  max-height: 390px;
  height: fit-content;
  overflow: auto;
  padding: 11px;
  border-radius: 16px;
  z-index: 1001;
  background-color: rgba(24, 24, 24, 0.5);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  margin-bottom: 8px;
`

const Br = styled.div`
  width: 100%;
  height: 1px !important;
  background-color: #bfbfbf;
`

const BroadcasterList = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;

  > div {
    margin-right: 16px;
  }

  > div:nth-child(4n) {
    margin-right: 0px;
  }
`

const ChattingMessageList = styled(Flex)`
  /* margin-top: 64px;
  margin-bottom: 24px; */
  width: 100%;
  margin-top: -10px;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;

  max-height: ${({
    moreChattingInfo,
    broadCasterCount,
    isShowFixedComment,
    fixedCommentHeight,
  }) => {
    // moreChattingInfo ? (broadCasterCount > 4 ? '348px' : '460px') : '644px'};
    let height =
      broadCasterCount < 1
        ? 718
        : moreChattingInfo
          ? broadCasterCount < 1
            ? 758
            : broadCasterCount > 4
              ? 459
              : 571
          : 755

    if (isShowFixedComment) {
      height -= fixedCommentHeight
    }
    return `${height}px`
    // '100px'
  }};
  min-height: ${({
    moreChattingInfo,
    broadCasterCount,
    isShowFixedComment,
    fixedCommentHeight,
  }) => {
    // moreChattingInfo ? (broadCasterCount > 4 ? '348px' : '460px') : '644px'};
    let height =
      broadCasterCount < 1
        ? 718
        : moreChattingInfo
          ? broadCasterCount < 1
            ? 758
            : broadCasterCount > 4
              ? 459
              : 571
          : 755

    if (isShowFixedComment) {
      height -= fixedCommentHeight
    }

    return `${height}px`
  }};
  height: 100%;
  overflow-y: auto;

  > div + div {
    margin-top: 14px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`

const VolumenBar = styled.input`
  width: 56px;
  height: 1px;
  border-radius: 1px;
  border: solid 1px ${colors.white_ff};

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
  }
`

const Recording = ({ isRecording = false, onClick = () => {} }) => {
  if (isRecording) {
    return (
      <div
        style={
          {
            width: '8px',
            height: '8px',
            borderRadius: '4px',
            backgroundColor: '#ea4653',
          }
        }
      />
    )
  }
  return <div />
}

/**
 * mic change
 */
const MicItem = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  height: 36px;
  background-color: ${props => (props.isActive ? '#fafafa' : '#ffffff')};
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => (props.isActive ? '#242424' : '#646464')};
  cursor: pointer;
`

const CurrMicName = styled.div`
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
`

const CurrMicLabel = styled.div`
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #06f;
`

const CheckImg = styled.img`
  width: 12px;
  height: 12px;
`
