import { Flex } from '@components/atoms'
import { HomeHouseSection, HomeContentsSection } from '@components/organisms'
import React from 'react'
import { PageLayout } from '..'

const HomeTemplate = () => {
  return (
    <PageLayout style={{ paddingTop: 32 }}>
      <Flex type="column" style={{ width: '100%', alignItems: 'center' }}>
        <HomeHouseSection />
        <HomeContentsSection />
      </Flex>
    </PageLayout>
  )
}

export default HomeTemplate
