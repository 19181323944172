import React from 'react'
import { Table } from 'antd'
import { Caption1, Caption2 } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma, hideNumber } from '@utils/string'
import { dateFormat } from '@utils/format'

const RowHeader = styled(Caption1)`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  height: 40px;
`

const RowItem = styled(Caption2)`
  font-family: NotoSansCJKkr-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => (props.color ? props.color : '#242424')};

  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
`

const columns = [
  {
    title: <RowHeader style={{ textAlign: 'left' }}>환전 신청일</RowHeader>,
    key: 'createdAt',
    dataIndex: 'createdAt',
    width: '12%',
    render: text => (
      <RowItem style={{ textAlign: 'left' }}>{dateFormat(text)}</RowItem>
    ),
  },
  {
    title: <RowHeader>신청 포인트</RowHeader>,
    key: 'requestRevenueAmount',
    dataIndex: 'requestRevenueAmount',
    width: '15%',
    render: text => <RowItem>{`${convertToPricingComma(text)}원`}</RowItem>,
  },
  {
    title: <RowHeader>신청 후 잔여 포인트</RowHeader>,
    key: 'afterCurrentRevenue',
    dataIndex: 'afterCurrentRevenue',
    width: '15%',
    render: text => <RowItem>{`${convertToPricingComma(text)}원`}</RowItem>,
  },
  {
    title: <RowHeader>환전 계좌</RowHeader>,
    key: 'accountNumber',
    dataIndex: 'accountNumber',
    width: '30%',
    render: (text, record) => (
      <RowItem type="Regular">
        {`${record.bankName}  ${hideNumber(text)}`}
      </RowItem>
    ),
  },
  {
    title: <RowHeader>환전 상태</RowHeader>,
    key: 'responseState',
    dataIndex: 'responseState',
    width: '15%',
    render: text => (
      <>
        {
          text === 0 ? (
            <RowItem color="#949494">환전 신청 심사중</RowItem>
          ) : text === 11 ? (
            <RowItem color="#66bc6a">환전 완료</RowItem>
          ) : (
            <RowItem color="#ea4653">환전 반려</RowItem>
          )
        }
      </>
    ),
  },
]

const ExchangeHistoryCard = ({ revenueHistories }) => {
  return (
    <Table
      className="history-table"
      columns={columns}
      dataSource={revenueHistories && revenueHistories}
      pagination={{ pageSize: 10, defaultPageSize: 10 }}
      size={'small'}
      style={
        {
          backgroundColor: 'white',
          borderRadius: '16px',
          boxShadow: '0 5px 14px 0 rgba(0,0,0,0.16)',
          maxWidth: '1000px',
          width: '100%',
          padding: '30px 42px',
          margin: '15px 0',
        }
      }
      rowKey={record => record.id}
    />
  )
}

export default ExchangeHistoryCard
