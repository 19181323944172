import { MobileCastTemplate } from '@components/templates'
import { useRenderLogger, useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import QueryString from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'

const MobileCastPage = () => {
  useRenderLogger('MobileCastPage')

  const { castStore, appStateStore } = useStore()

  const fetchCast = castStore.fetchCast || (() => {})
  const fetchCastDeepLinks = castStore.fetchCastDeepLinks || (() => {})

  const [castDetail, setCastDetail] = useState(null)
  const [dynamicLink, setDynamicLink] = useState(null)

  const param = document.location.href.split('?')
  const query = QueryString.parse(param[1], {
    ignoreQueryPrefix: true,
  })

  const fetchCastDetail = useCallback(async () => {
    if (query?.castId) {
      const res = await fetchCast(query.castId)
      const res2 = await fetchCastDeepLinks(query.castId)
      // Todo: 401 해결되면 반영
      if (res) {
        setCastDetail(res)
      }
      if (res2?.shortLink) {
        setDynamicLink(res2.shortLink)
      }
    }
  }, [query.castId, fetchCast])

  useEffect(() => {
    fetchCastDetail()
  }, [fetchCastDetail])

  useEffect(() => {
    const induceBar = document.getElementById('app-induce-bar')
    if (induceBar) {
      induceBar.style.display = 'none'
    }
  }, [])

  return (
    <>
      {!isMobile && <Redirect to={`/cast/${query?.castId}`} />}
      <MobileCastTemplate
        castDetail={castDetail}
        dynamicLink={dynamicLink}
        onToast={appStateStore.onToast || (() => {})}
      />
    </>
  )
}

export default observer(MobileCastPage)
