import styled from 'styled-components'

const Img = styled.img.attrs((props: React.HTMLProps<HTMLImageElement>) => ({
  style: {
    ...props,
    ...props.style,
  },
}))``

export default Img
