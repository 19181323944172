import React, { useEffect, useState } from 'react'
import { FindPasswordTemplate } from '@components/templates'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import { lPad } from '@utils/string'
import { useRenderLogger, useStore } from '@utils/hooks'

interface Props {
  history: Any;
}

const FindPasswordPage = ({ history }: Props) => {
  useRenderLogger('FindPasswordPage')
  const { authStore } = useStore()

  const sendSms = authStore.sendSms || (() => {})
  const smsCheck = authStore.smsCheck || (() => {})
  const getCheckPhone = authStore.getCheckPhone || (() => {})
  const changePassword = authStore.changePassword || (() => {})

  const getCheckEmail = authStore.getCheckEmail || (() => {})

  const [code, setCode] = useState('') // 인증 번호
  const [timer, setTimer] = useState('') // 타이머
  const [phoneVal, setPhoneVal] = useState(null) // 타이머 인터벌 아이디
  const [isTimer, setIsTimer] = useState(false) // 타이머 진행 여부
  const [complete, setComplete] = useState(false) // 가입 성공여부

  const [email, setEmail] = useState('') // 가입한 이메일 입력

  // 카운트 다운
  let num = 60 * 3 // 몇분을 설정할지의 대한 변수 선언
  // 타이머 함수
  function timerFn() {
    let min = num / 60
    min = Math.floor(min)
    const sec = num - 60 * min

    setTimer(`${lPad(min)}:${lPad(sec)}`)

    if (timer === '00:00') {
      clearInterval(phoneVal)
    }
    num--
  }

  const time = (e, phone) => {
    e.preventDefault()

    sendSms(phone)

    if (isTimer === true) {
      clearInterval(phoneVal)
      setIsTimer(false)
    }

    setPhoneVal(setInterval(timerFn, 1000))

    setIsTimer(true)
  }

  if (timer === '00:00') {
    clearInterval(phoneVal)
  }

  useEffect(() => {
    // 인터벌 함수 unmount 될때 제거(cleanup)
    return function cleanup() {
      clearInterval(phoneVal)
    }
  }, [phoneVal])

  return (
    <>
      {
        complete === 'Y' ? (
          <Redirect to={{ pathname: '/p/find/temp-complete', state: { email } }} />
        ) : (
          false
        )
      }
      <FindPasswordTemplate
        clearInterval={() => clearInterval(phoneVal)}
        history={history}
        email={email}
        setEmail={setEmail}
        complete={complete}
        code={code}
        getCheckPhone={getCheckPhone}
        getCheckEmail={getCheckEmail}
        setCode={setCode}
        time={(e, phone) => time(e, phone)}
        timer={timer}
        smsCheck={smsCheck}
        setComplete={setComplete}
        changePassword={changePassword}
      />
    </>
  )
}

export default observer(FindPasswordPage)
