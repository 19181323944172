import { action, observable } from 'mobx'
import moment from 'moment'

import { isEmptyObject } from '@utils/vallidation'
import { persist } from 'mobx-persist'
import {
  LiveRoomModel,
  LiveRoomUserModel,
  MixtapeModel,
  SongModel,
  UserModel,
} from './models'
import { Store } from '.'
import { Network } from './networks'

import {
  LIVE_ROOMS_HAVE_USERS_LEVEL,
  LIVE_ROOM_MESSAGE_TYPE,
  MIXTAPES_HAVE_TAGS_LEVEL,
  ERROR_CODE,
  sounds,
  STORAGE_URL,
} from '../resources'
import { AgoraRtm, upload, ClientRole, AgoraRtc } from '../utils'
import {
  CONTROL_AUDIO_TYPE,
  CONTROL_MIXTAPE_TYPE,
  LIVE_ROOM_ACTION,
} from './LiveRoomAction'

const linkify = require('linkify-it')()

const IS_DEV = process.env['NODE_ENV'] === 'development'

const LiveRoomStoreLogger = (...props) => {
  IS_DEV && console.log('[LiveRoomStore]', ...props)
}

export default class LiveRoomStore {
  ON_VOICE_MIXTAPE_VOLUME = 0.3
  MUTE_VOICE_MIXTAPE_VOLUME = 1

  isOpenLiveRoomPlayScreen = false
  isStartedLeaveAction = false

  FREE_OWNER_MAINTAINABLE_SEC = 7200 // 구독중이지 않은 오너의 방 유지 가능 시간 (2시간)
  NOTICE_REMAIN_TIME_SEC = 600 // 구독중이지 않은 오너의 방 종료까지 남은 시간 (10분)

  @observable _currLiveRoom
  @observable deletedCurrLiveRoom
  get currLiveRoom() {
    return this._currLiveRoom
  }
  set currLiveRoom(value) {
    if (value) {
      // this.store.currentPlaySongStore.isPlayedUploadedAudio = false
      // this.store.mixtapeStore.currentPlayingRecommendationMixtape = null
      // this.store.mixtapeStore.currentPlayingMixtape = null
      this.store.playerStore.init()
    }

    this._currLiveRoom = value
  }

  @observable liveRoomDetail

  @observable isJoinedAndNavigated

  @observable currLiveRoomUserList
  @observable currLiveRoomUser
  @observable currLiveRoomDonatedHistoryList = []

  @observable isJoined
  @observable streamId
  @observable isMutedRemoteAll

  @observable isMutedOnlyMixtape
  @observable _personalMixtapeVolume
  get personalMixtapeVolume() {
    return this._personalMixtapeVolume
  }

  set personalMixtapeVolume(volume) {
    this._personalMixtapeVolume = volume
  }

  @observable isPersonalVolumeOn
  @observable isOpenedPersonalVolume

  @observable liveRoomList
  @observable liveRoomListNextCursor

  @observable liveRoomMessageList
  @observable liveRoomRequestSpeakerList

  @observable allReservationLiveRoomList

  @observable popularMixtapeData
  @observable likedMixtapeData

  @observable needConfirmGuestFromHost
  @observable needConfirmGuestFromListener
  @observable needConfirmHost
  @observable needConfirmListener
  @observable agoraDevice

  @observable isJoinedLiveRoom // join이 완료되었는 지 => SalonDetailPage

  @observable isNoticedEndSoonMessage = false

  isNoticedPingFinishRoom

  @observable _currentPlayingLiveRoomMixtape
  get currentPlayingLiveRoomMixtape() {
    return this._currentPlayingLiveRoomMixtape
  }
  set currentPlayingLiveRoomMixtape(value) {
    if (value) {
      // this.store.currentPlaySongStore.isPlayedUploadedAudio = false
      // this.store.mixtapeStore.currentPlayingRecommendationMixtape = null
      // this.store.mixtapeStore.currentPlayingMixtape = null
      this.store.playerStore.init()
    }

    this._currentPlayingLiveRoomMixtape = value
  }

  // 살롱 유저 상세 모달 상태
  @observable showSalonUser
  @observable salonUser

  @persist @observable isMuteJoinLiveRoom
  @persist @observable isMuteRequestGuest

  @observable isShowFixedComment

  @observable currLiveRoomMicName

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.liveRoomDetail = null

    this.isOpenLiveRoomPlayScreen = false
    this.liveRoomList = []
    this.liveRoomListNextCursor = null
    this.allReservationLiveRoomList = []
    this.isMuteJoinLiveRoom = false
    this.isMuteRequestGuest = false

    this.agoraDevice = null

    this.isJoinedLiveRoom = false
    this.agoraRtmChannelId = null

    this.currLiveRoomMicName = null

    this.init()

    LIVE_ROOM_ACTION['JOIN_LIVE_ROOM'][
      'LISTENER'
    ] = this._receiveActionJoinLiveRoom
    LIVE_ROOM_ACTION['DELETE_LIVE_ROOM'][
      'LISTENER'
    ] = this._receiveActionDeleteLiveRoom
    LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM'][
      'LISTENER'
    ] = this._receiveActionUpdateLiveRoom
    LIVE_ROOM_ACTION['LEAVE_LIVE_ROOM'][
      'LISTENER'
    ] = this._receiveActionLeaveLiveRoom
    LIVE_ROOM_ACTION['DONATE_LIVE_ROOM'][
      'LISTENER'
    ] = this._receiveActionDonateLiveRoom
    LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM_USER'][
      'LISTENER'
    ] = this._receiveActionUpdateLiveRoomUser
    LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM_USER_LIST'][
      'LISTENER'
    ] = this._receiveActionUpdateLiveRoomUserList
    LIVE_ROOM_ACTION['BAN_LIVE_ROOM_USER'][
      'LISTENER'
    ] = this._receiveActionBanLiveRoomUser
    LIVE_ROOM_ACTION['SEND_CHAT']['LISTENER'] = this._receiveActionSendChat
    LIVE_ROOM_ACTION['SEND_CHAT_FIXED'][
      'LISTENER'
    ] = this._receiveActionSendChatFixed
    LIVE_ROOM_ACTION['SEND_CHAT_FIXED_DELETE'][
      'LISTENER'
    ] = this._receiveActionSendChatFixedDelete
    LIVE_ROOM_ACTION['LIVE_ROOM_RECORDING'][
      'LISTENER'
    ] = this._receiveActionLiveRoomRecording
    LIVE_ROOM_ACTION['CONTROL_MIXTAPE'][
      'LISTENER'
    ] = this._receiveActionControlMixtape
    LIVE_ROOM_ACTION['CONTROL_AUDIO'][
      'LISTENER'
    ] = this._receiveActionControlAudio
    LIVE_ROOM_ACTION['MUTE_ALL_MIC']['LISTENER'] = this._receiveActionMuteAllMic
    LIVE_ROOM_ACTION['PREVENT_ACCESS_MULTIPLE'][
      'LISTENER'
    ] = this._receiveActionPreventAccessMultiple
    LIVE_ROOM_ACTION['SEND_REQUEST_HOST'][
      'LISTENER'
    ] = this._receiveActionRequestHost
    LIVE_ROOM_ACTION['SEND_ACCEPTED_HOST'][
      'LISTENER'
    ] = this._receiveActionAcceptedHost
    LIVE_ROOM_ACTION['SEND_REQUEST_GUEST'][
      'LISTENER'
    ] = this._receiveActionRequestGuest
    LIVE_ROOM_ACTION['SEND_ACCEPTED_GUEST'][
      'LISTENER'
    ] = this._receiveActionAcceptedGuest

    AgoraRtm.init({
      handlerMessageReceived: async data => {
        if (
          data['type']
          && LIVE_ROOM_ACTION[data['type']]
          && LIVE_ROOM_ACTION[data['type']]['LISTENER']
        ) {
          await LIVE_ROOM_ACTION[data['type']]['LISTENER'](data)
        }
      },
      handlerChannelMessageReceived: async data => {
        if (
          data['type']
          && LIVE_ROOM_ACTION[data['type']]
          && LIVE_ROOM_ACTION[data['type']]['LISTENER']
        ) {
          await LIVE_ROOM_ACTION[data['type']]['LISTENER'](data)
        }
      },
      handlerChannelMemberLeft: async ({
        channelId = this.agoraRtmChannelId, // channelMemberLeft 의 인자가 web에서는 uid만 오고 있어, 인자값 store에서 받아옴.
        uid,
      }) => {
        if (this.currLiveRoom && channelId && uid) {
          const splitedChannelIdList = channelId.split('_')
          if (splitedChannelIdList.length > 0) {
            const liveRoomId =
              splitedChannelIdList[splitedChannelIdList.length - 1]
            const userId = this.getUserIdFromAgoraUid(parseInt(uid))
            if (userId) {
              await this._leaveLiveRoomOtherLocal(liveRoomId, userId)
            }
          }
        }
      },
    })
  }

  @action init = () => {
    this.currLiveRoom = null
    this.deletedCurrLiveRoom = null
    this.currLiveRoomUserList = []
    this.currLiveRoomUser = null
    this.currLiveRoomDonatedHistoryList = []

    this.liveRoomMessageList = []
    this.liveRoomRequestSpeakerList = []

    this.popularMixtapeData = { isFinished: false, list: [] }
    this.likedMixtapeData = { isFinished: false, list: [] }

    this.currentPlayingLiveRoomMixtape = null
    this.currentPlayingLiveRoomAudio = null

    this.isMutedOnlyMixtape = false
    this.isPersonalVolumeOn = false
    this.isOpenedPersonalVolume = false
    this.personalMixtapeVolume = null

    this.isShowFixedComment = false

    this.isStartedLeaveAction = false
    this.isNoticedPingFinishRoom = false

    this.isNoticedEndSoonMessage = false

    this.showSalonUser = false
    this.salonUser = null

    this.initModalState()

    this.initRtcEngin()

    this.agoraRtmChannelId = null
  }

  @action setIsJoinedLiveRoom = value => {
    this.isJoinedLiveRoom = value
  }

  @action joinInit = () => {
    this.currLiveRoomUserList = []
    this.currLiveRoomUser = null
    this.currLiveRoomDonatedHistoryList = []

    this.liveRoomMessageList = []
    this.liveRoomRequestSpeakerList = []

    this.popularMixtapeData = { isFinished: false, list: [] }
    this.likedMixtapeData = { isFinished: false, list: [] }

    this.currentPlayingLiveRoomMixtape = null

    this.isStartedLeaveAction = false
    this.isNoticedPingFinishRoom = false

    this.showSalonUser = false
    this.salonUser = null

    this.initModalState()

    this.initRtcEngin()

    this.agoraRtmChannelId = null
  }

  @action toggleSalonUser = () => {
    this.showSalonUser = !this.showSalonUser
  }

  @action loadSalonUser = async user => {
    if (this.store.authStore.currentUser['id'] === user?.User['id']) return

    this.salonUser = await user
    await this.toggleSalonUser()

    await this.store.userStore.fetchIsFollowed(user?.User)
  }

  @action initModalState = () => {
    this.needConfirmGuestFromHost = false
    this.needConfirmGuestFromListener = false
    this.needConfirmHost = false
    this.needConfirmListener = false
  }

  @action initRtcEngin = () => {
    if (AgoraRtc) AgoraRtc._clear()
    this.streamId = null
    this.isJoined = false
    this.isJoinedAndNavigated = false
    this.isMutedRemoteAll = false
  }

  getUserIdFromAgoraUid = agoraUid => {
    let userId = 0
    if (this.currLiveRoomUserList) {
      const liveRoomsHaveUsers = this.currLiveRoomUserList.find(
        _liveRoomsHaveUsers => {
          return _liveRoomsHaveUsers['agoraUid'] === agoraUid
        },
      )
      if (liveRoomsHaveUsers && liveRoomsHaveUsers['userId']) {
        userId = liveRoomsHaveUsers['userId']
      }
    }
    return userId
  }

  @action setRtcEngine = async () => {
    /**
     * default setting
     */
    this.initRtcEngin()
    AgoraRtc.init()

    /**
     * 통화 볼륨 & 미디어 볼륨 컨트롤
     * https://docs.agora.io/en/Voice/faq/system_volume
     */
    //  await this._engine.setAudioProfile(
    //   AudioProfile.MusicHighQuality,
    //   AudioScenario.GameStreaming,
    // )

    /**
     * test
     */
    AgoraRtc.addListener('exception', async err => {
      // 예외 처리 에러코드 => 로깅 제거
      // 2001 : AUDIO_INPUT_LEVEL_TOO_LOW
      // 2002 : AUDIO_OUTPUT_LEVEL_TOO_LOW
      // 4001 : AUDIO_INPUT_LEVEL_TOO_LOW_RECOVER
      // 4002 : AUDIO_OUTPUT_LEVEL_TOO_LOW_RECOVER
      if (
        err?.code === 2001
        || err?.code === 2002
        || err?.code === 4001
        || err?.code === 4002
      )
        return
      LiveRoomStoreLogger('[LOG]', '[AgoraRtc]', '[event]', '[Error]', err)
      // await this.leaveLiveRoom()
    })

    AgoraRtc.addListener('crypt-error', async err => {
      LiveRoomStoreLogger('[LOG]', '[AgoraRtc]', '[event]', '[Error]', err)
      await this.leaveLiveRoom()
    })

    AgoraRtc.addListener('user-left', async (evt, reason) => {
      /**
       * 앱 강제종료 대응을 위함
       * 하지만
       * 현재 살롱 접속 -> 살롱 나가기 -> 살롱 접속 -> 앱 강제종료에서 이벤트 트리거가 안됨.
       * Audience 나가면 트리거 안됨.
       * Broadcaster 나가면 트리거 됨.
       */
      if (reason === 'Quit') {
        /* Broadcaster 일 때, 나가는 유저에 대해서 시스템 메세지와 보이는 UI 와 동기화 */
        // this._leaveLiveRoomOtherLocal(
        //   this.currLiveRoom && this.currLiveRoom['id'],
        //   evt.uid,
        // )
        const userId = this.getUserIdFromAgoraUid(evt.uid)
        if (userId) {
          this._leaveLiveRoomOtherLocal(
            this.currLiveRoom && this.currLiveRoom['id'],
            userId,
          )
        }
      }

      LiveRoomStoreLogger(
        '[LOG]',
        '[AgoraRtc]',
        '[event]',
        '[user-left]',
        evt,
        reason,
      )
    })

    AgoraRtc.addListener('connection-state-change', (curState, prevState) => {
      if (curState === 'CONNECTED') {
        LiveRoomStoreLogger(
          '[LOG]',
          '[AgoraRtc]',
          '[event]',
          '[connection-state-change]',
          '[jogin Chaneel success]',
        )
        this.isJoined = true
      }
    })

    /**
     * Audio volume indication
     * max 3 명 까지 트래킹 가능
     */
    await AgoraRtc.enableAudioVolumeIndication()
    AgoraRtc.addListener('volume-indicator', elem => {
      // LiveRoomStoreLogger(
      //   '[LOG]',
      //   '[AgoraRtc]',
      //   '[event]',
      //   '[volume-indicator]',
      //   elem.length >= 1 && elem[0],
      // )
      if (Array.isArray(elem)) {
        elem.forEach(data => {
          let userId = this.getUserIdFromAgoraUid(data.uid)
          if (data.uid === 0) {
            userId = this.store.authStore.currentUser?.id
          }

          if (userId) {
            const liveRoomUser = this.currLiveRoomUserList.find(
              _liveRoomUser => _liveRoomUser['userId'] === userId,
            )
            if (liveRoomUser && !liveRoomUser['isMutedMic']) {
              // app ( volume )  =   web ( level )
              // liveRoomUser['micVolume'] = data.volume || 0
              liveRoomUser['micVolume'] = data.level || 0
            }
          }
        })
      }
    })

    /**
     * Audio management
     * volumne issue control: https://docs.agora.io/en/live-streaming/faq/audio_low
     */
    // await AgoraRtc.adjustRecordingSignalVolume(100) // 0 ~ 100, 100: Original volume.
    // await AgoraRtc.adjustPlaybackSignalVolume(100)
    // await AgoraRtc.adjustAudioMixingVolume(100)
    // await AgoraRtc.adjustAudioMixingPublishVolume(100)
    // await AgoraRtc.adjustAudioMixingPlayoutVolume(100)
  }

  @action editLiveRoom = async liveRoom => {
    return this.store.useLoading(async () => {
      try {
        const rooms = await this.network.liveRoomNetwork.putLiveRoom(
          this.currLiveRoom['id'],
          liveRoom,
        )
        if (!rooms || rooms.length < 1) {
          LiveRoomStoreLogger('[editLiveRoom error] rooms undefined')
          return false
        }

        await this._sendActionUpdateLiveRoom({
          liveRoomId: rooms['id'],
          ...rooms,
        })
        await this.updateLiveRoomLocal({ liveRoomId: rooms['id'], ...rooms })

        return true
      }
      catch (error) {
        LiveRoomStoreLogger('LiveRoomStore editLiveRoom error', error)
      }
    })
  }

  @action fetchAutocompleteTopics = async inputText => {
    const data = this.store.liveRoomSearchStore.fetchSearchTopicList({
      search: inputText,
      startIndex: 0,
      limit: 100,
    })
    return data
  }

  @action createLiveRoom = async (
    createRoomInfo = { title: '테스트', isPrivate: 0 },
    isReserved = false,
    isReservedOpen = false,
  ) => {
    return this.store.useLoading(async () => {
      /**
       * 살롱 생성
       * 1. postLiveRoom 진행
       * 2. joinLiveRoom 진행
       *  1. 성공시 3 진행
       *  2. 실패시 deleteLiveRoom
       * 3. postCompleteLiveRoom
       *  1. 성공시 완료
       *  2. 실패시 deleteLiveRoom
       */
      try {
        await this.leaveLiveRoom()

        /** START 1. postLiveRoom */
        let createdLiveRoomList = null

        if (!isReservedOpen) {
          createRoomInfo['stores'] = null
          const param = [createRoomInfo]
          createdLiveRoomList = await this.network.liveRoomNetwork.postLiveRoom(
            param,
          )

          if (!createdLiveRoomList || createdLiveRoomList.length < 1) {
            console.error(
              '[LOG][LiveRoomStore][createLiveRoom] Rooms is undefined.',
            )
            return false
          }
          /** 예약 살롱은 post만 진행 */
          if (isReserved) {
            return true
          }
        }
        else if (isReservedOpen && createRoomInfo?.['id']) {
          if (!this.store.authStore?.currentUser?.['id']) {
            console.error(
              '[LOG][LiveRoomStore][createLiveRoom] Required currentUser.',
            )
            return false
          }
          const result = await this.network.liveRoomNetwork.postLiveRoomsHaveUsers(
            createRoomInfo['id'],
            this.store.authStore.currentUser['id'],
          )
          createdLiveRoomList = [{ ...createRoomInfo, ...result['data'] }]
        }

        const createdLiveRoom = createdLiveRoomList[0]

        const { currentUser } = this.store.authStore
        if (!currentUser) {
          LiveRoomStoreLogger('[createliveRoom error] login needed')
          return false
        }

        this.currLiveRoom = new LiveRoomModel(this.store, createdLiveRoom)
        /** END 1. postLiveRoom */

        /** START 2. joinLiveRoom */
        const joinResult = await this._joinAgora(createdLiveRoom, true)
        if (!joinResult) {
          await this.network.liveRoomNetwork.deleteLiveRoom(
            createdLiveRoom['id'],
          )

          LiveRoomStoreLogger('[createliveRoom error] join error')
          return false
        }
        /** END 2. joinLiveRoom */

        /** START 3. postCompleteLiveRoom */
        const completedLiveRoom = await this.network.liveRoomNetwork.postCompleteLiveRoom(
          {
            liveRoomId: createdLiveRoom['id'],
            userId: currentUser['id'],
          },
        )

        if (
          !completedLiveRoom
          || isEmptyObject(completedLiveRoom)
          || !Array.isArray(completedLiveRoom['LiveRoomsHaveUsers'])
          || completedLiveRoom['LiveRoomsHaveUsers'].length < 1
        ) {
          await this.network.liveRoomNetwork.deleteLiveRoom(
            createdLiveRoom['id'],
          )

          LiveRoomStoreLogger('[createliveRoom error] live room complete error')
          return false
        }

        const completedLiveRoomsHaveUsers =
          completedLiveRoom['LiveRoomsHaveUsers'][0]
        this.currLiveRoomUser = new LiveRoomUserModel(this.store, {
          ...completedLiveRoomsHaveUsers,
          User: currentUser,
        })
        this.currLiveRoomUser['agoraRtcUserToken'] =
          createdLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtcUserToken']
        this.currLiveRoomUser['agoraRtmUserToken'] =
          createdLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtmUserToken']
        this.currLiveRoomUser['agoraRtcChannelId'] =
          createdLiveRoom['agoraRtcChannelId']
        this.currLiveRoomUser['agoraRtmChannelId'] =
          createdLiveRoom['agoraRtmChannelId']

        await this.store.playerStore._stopPlayer()
        await this.fetchLiveRoomList({})

        // return true
        return completedLiveRoom
        /** END 3. postCompleteLiveRoom */
      }
      catch (error) {
        if (this.currLiveRoom) {
          await this.network.liveRoomNetwork.deleteLiveRoom(
            this.currLiveRoom['id'],
          )
        }
        await this.leaveLiveRoom()
        LiveRoomStoreLogger('LiveRoomStore createLiveRoom error', error)
      }
    })
  }

  @action _joinAgora = async (tokenLiveRoom, isCreating = false) => {
    try {
      if (!tokenLiveRoom) {
        LiveRoomStoreLogger(
          `[_joinAgora]`,
          `[error]`,
          `invalid agora token or uid`,
        )
        await this.leaveLiveRoom()
        return false
      }

      const {
        agoraRtcChannelId,
        agoraRtmChannelId,
        LiveRoomsHaveUsers,
        ExistedLiveRoomsHaveUsers,
      } = tokenLiveRoom

      this.agoraRtmChannelId = agoraRtmChannelId

      if (!LiveRoomsHaveUsers || !(LiveRoomsHaveUsers.length > 0)) {
        LiveRoomStoreLogger(
          `[_joinAgora]`,
          `[error]`,
          `invalid LiveRoomsHaveUsers`,
        )
        await this.leaveLiveRoom()
        return false
      }

      const { LiveRoomsHaveUsersAgoraTokens } = LiveRoomsHaveUsers[0]

      let agoraUid = this.store?.authStore?.currentUser?.id
      let agoraRtcUserToken = LiveRoomsHaveUsers[0]['agoraRtcUserToken']
      let agoraRtmUserToken = LiveRoomsHaveUsers[0]['agoraRtmUserToken']

      if (
        LiveRoomsHaveUsersAgoraTokens
        && LiveRoomsHaveUsersAgoraTokens.length > 0
        && LiveRoomsHaveUsersAgoraTokens[0]['agoraRtcUserToken']
        && LiveRoomsHaveUsersAgoraTokens[0]['agoraRtmUserToken']
      ) {
        agoraUid = LiveRoomsHaveUsersAgoraTokens[0]['id']
        agoraRtcUserToken =
          LiveRoomsHaveUsersAgoraTokens[0]['agoraRtcUserToken']
        agoraRtmUserToken =
          LiveRoomsHaveUsersAgoraTokens[0]['agoraRtmUserToken']
      }

      if (
        !agoraUid
        || !agoraRtcChannelId
        || !agoraRtcUserToken
        || !agoraRtmChannelId
        || !agoraRtmUserToken
      ) {
        LiveRoomStoreLogger(
          `[_joinAgora]`,
          `[error]`,
          `invalid agora token or uid`,
        )
        await this.leaveLiveRoom()
        return false
      }

      /** START 3. agora join */
      // 살롱 새로 생성하거나, 이전에 접속했던 오너면 Broadcaster

      let role = isCreating ? 'Broadcaster' : 'Listener'

      /** 이전에 접속했던 유저 중 게스트 이상이었으면 Broadcaster */
      if (
        LiveRoomsHaveUsers?.[0]?.['level']
        >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST']
      ) {
        role = 'Broadcaster'
      }

      await this.setRtcEngine()

      if (!AgoraRtc) {
        await this.leaveLiveRoom()
        return false
      }

      // await AgoraRtc.setChannelProfile(ChannelProfile.LiveBroadcasting)
      await AgoraRtc.joinChannel(agoraRtcChannelId, agoraRtcUserToken, agoraUid)

      if (role === 'Broadcaster') {
        const permissioned = await this.getDevicesPermission(role)
        if (!permissioned) return

        // AgoraRtc.setClientRole 에서 분기처리
        // await AgoraRtc.createAudio(true)
        await AgoraRtc.setClientRole(ClientRole.Broadcaster)
        // await AgoraRtc.publish()
      }
      else {
        await AgoraRtc.setClientRole(ClientRole.Audience)
      }

      await AgoraRtc.muteLocalAudioStream(true)

      const connectionState = await AgoraRtc.getConnectionState()
      let isConnected = null

      // if (connectionState === 1 || connectionState === 5) {
      if (
        connectionState === 'DISCONNECTED'
        || connectionState === 'DISCONNECTING'
      ) {
        isConnected = false
      }
      isConnected = true

      if (!isConnected) {
        await this.leaveLiveRoom()
        return false
      }
      const resRtmJoinChannel = await AgoraRtm.joinChannel(
        agoraRtmChannelId,
        agoraRtmUserToken,
        agoraUid,
      )

      if (!resRtmJoinChannel) {
        /* todo: joinChannel reject, delete server data */
        LiveRoomStoreLogger(
          '[joinLiveRoom error] RtmJoinChanel fail',
          resRtmJoinChannel,
        )
        await this.leaveLiveRoom()
        return false
      }

      if (
        this.currLiveRoom
        && this.currLiveRoom['id']
        && Array.isArray(ExistedLiveRoomsHaveUsers)
      ) {
        ExistedLiveRoomsHaveUsers.forEach(existedLiveRoomsHaveUsers => {
          if (
            existedLiveRoomsHaveUsers
            && Array.isArray(
              existedLiveRoomsHaveUsers['LiveRoomsHaveUsersAgoraTokens'],
            )
          ) {
            existedLiveRoomsHaveUsers['LiveRoomsHaveUsersAgoraTokens'].forEach(
              liveRoomsHaveUsersAgoraTokens => {
                this._sendActionPreventAccessMultiple(
                  liveRoomsHaveUsersAgoraTokens['id'],
                  this.currLiveRoom['id'],
                  this.store?.authStore?.currentUser?.id,
                )
              },
            )
          }
        })
      }
      /** END 3. agora join */

      return true
    }
    catch (error) {
      console.error(`[_joinAgora]`, `[error]`, error)
    }
  }

  @action joinLiveRoom = async (liveRoomId, isFromLink = false) => {
    /**
     * 살롱 접속
     * 0. getLiveRoom: room 존재 검사 및 liveRoom 객체 생성
     * 1. postLiveRoomsHaveUsers 진행
     *  1. 성공시 2 진행
     * 2. fcm subscribe Topic 진행
     *  1. 성공시 3 진행
     *  2. 실패시 deleteLiveRoomsHaveUsers 진행
     * 3. agora join 진행
     *  1. 성공시 4 진행
     *  2. 실패시
     *    - deleteLiveRoomsHaveUsers
     *    - fcm Topic unsubscribe
     * 4. postCompleteLiveRoomsHaveUsers 진행
     *  1. 성공시 5 진행
     *  2. 실패시
     *    - deleteLiveRoomsHaveUsers
     *    - fcm Topic unsubscribe
     *    - agora leave channel
     * 5. user 정보 받아와서
     */
    return this.store.useLoading(async () => {
      try {
        const { authStore } = this.store
        const { currentUser } = authStore

        // if (!currentUser && !currentUser['id']) {
        //   LiveRoomStoreLogger('[joinLiveRoom error] User is not logined')
        //   return false
        // }

        /** START 0. getLiveRoom */
        if (this.currLiveRoom) this.joinInit()
        else if (!this.currLiveRoom) this.init()

        if (!liveRoomId) {
          LiveRoomStoreLogger('[joinLiveRoom error] liveRoomId id not exist')
          return false
        }

        // 검증으로 currLiveRoom이 이미 존재할 시, fetch pass
        if (!this.currLiveRoom) {
          const param = {
            __include:
              'Users,LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags,LiveRoomsHaveUsers.LiveRoomsHaveUsersAgoraTokens,UsersReserveLiveRooms,FixedCommentUsers',
            'UsersReserveLiveRooms.userId': currentUser?.id, // 내 userId',
            __attributes:
              'Users.name,Users.id,Users.account,Users.imageUri,Users.profileColor,UsersReserveLiveRooms.userId',
          }
          const liveRoom = await this.network.liveRoomNetwork.getLiveRoom(
            liveRoomId,
            param,
          )

          if (!liveRoom || isEmptyObject(liveRoom)) {
            alert('존재하지 않는 살롱입니다.')
            window.location.href = '/p/salon/list'
            LiveRoomStoreLogger('[joinLiveRoom error] Room is not exist')
            return false
          }
          // 비밀번호 로직 SalonCard 컴포넌트로
          // if (
          //   liveRoom?.isPrivate
          //   && liveRoom?.userId !== this.store.authStore?.currentUser?.id
          //   && !isFromLink
          // ) {
          //   alert('링크가 있어야 접속할 수 있는 비공개 살롱입니다')
          //   return false
          // }

          this.currLiveRoom = new LiveRoomModel(this.store, liveRoom)
        }
        const liveRoomUserList = this.currLiveRoom['LiveRoomsHaveUsers']

        if (liveRoomUserList.length !== 0) {
          this.currLiveRoomUserList.push(
            ...liveRoomUserList.map(
              liveRoomUser => new LiveRoomUserModel(this.store, liveRoomUser),
            ),
          )
        }
        /** END 0. getLiveRoom */

        /**
         *  If Reserve LiveRoom
         * 예약 상태일 때, join시 return
         * 방장이 join시 진행
         */
        if (
          this.currLiveRoom?.isReserved
          && !this.currLiveRoom?.isOpened
          && this.currLiveRoom?.userId !== currentUser?.id
        )
          return

        /** START 1. postLiveRoomsHaveUsers */
        let res
        if (currentUser?.id) {
          res = await this.network.liveRoomNetwork.postLiveRoomsHaveUsers(
            this.currLiveRoom['id'],
            currentUser?.id,
            { isMutedMic: true },
          )
        }
        else {
          res = await this.network.liveRoomNetwork.postLiveRoomsHaveGuest(
            this.currLiveRoom['id'],
            { isMutedMic: true },
          )
        }

        const joinedLiveRoom = res['data']
        if (!joinedLiveRoom || isEmptyObject(joinedLiveRoom)) {
          const errorCode = res['errorCode']
          if (errorCode === ERROR_CODE['LIVE_ROOMS_JOIN_BANNED_USER']) {
            alert('강퇴당한 살롱입니다')
          }
          if (errorCode === ERROR_CODE['LIVE_ROOMS_JOIN_EXCEED_USER']) {
            alert('인원이 초과되어 입장하실 수 없습니다')
          }
          if (errorCode === ERROR_CODE['LIVE_ROOMS_BLOCK_UNKNOWN_USER']) {
            alert('해당 콘텐츠는 로그인해야 청취 가능합니다')
          }

          LiveRoomStoreLogger('[joinLiveRoom error] post join error')
          return false
        }

        /** END 1. postLiveRoomsHaveUsers */

        /** START 3. agora join */
        // 살롱 새로 생성하거나, 이전에 접속했던 오너면 Broadcaster
        console.log('moonsae joinedLiveRoom', joinedLiveRoom)
        const joinResult = await this._joinAgora(
          joinedLiveRoom,
          this.currLiveRoom['userId'] === currentUser?.id,
        )
        if (!joinResult) {
          await this.leaveLiveRoom()
          LiveRoomStoreLogger('[joinLiveRoom error] join error')
          return false
        }
        // let role = isCreating ? 'Broadcaster' : 'Listener'
        // if (liveRoom['userId'] === currentUser['id']) {
        //   role = 'Broadcaster'
        // }

        // const channelConfig = {
        //   agoraRtcChannelId: this.agoraRtcChannelId,
        //   agoraRtcUserToken: this.agoraRtcUserToken,

        //   agoraRtmChannelId: this.agoraRtmUserToken,
        //   agoraRtmUserToken: this.agoraRtmUserToken,
        // }

        // if (
        //   !this.agoraRtcChannelId
        //   || !this.agoraRtcUserToken
        //   || !this.agoraRtmChannelId
        //   || !this.agoraRtmUserToken
        // ) {
        //   LiveRoomStoreLogger(
        //     '[joinLiveRoom error] Room name or Room token is not exist',
        //   )
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // // lhh rtc
        // await this.setRtcEngine(channelConfig, role)

        // if (!AgoraRtc) {
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // await AgoraRtc.joinChannel(
        //   this.agoraRtcChannelId,
        //   this.agoraRtcUserToken,
        //   this.store.authStore.currentUser['id'],
        // )

        // if (role === 'Broadcaster') {
        //   // AgoraRtc.setClientRole 에서 분기처리
        //   // await AgoraRtc.createAudio(true)
        //   await AgoraRtc.setClientRole(ClientRole.Broadcaster)
        //   // await AgoraRtc.publish()
        // }
        // else {
        //   await AgoraRtc.setClientRole(ClientRole.Audience)
        // }

        // await AgoraRtc.muteLocalAudioStream(true)

        // const connectionState = await AgoraRtc.isConnected
        // if (!connectionState) {
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // /** END 3. agora join */
        // const resRtmJoinChannel = await AgoraRtm.joinChannel(
        //   this.agoraRtmChannelId,
        //   this.agoraRtmUserToken,
        //   this.store.authStore.currentUser['id'],
        // )

        // if (!resRtmJoinChannel) {
        //   /* todo: joinChannel reject, delete server data */
        //   LiveRoomStoreLogger(
        //     '[joinLiveRoom error] RtmJoinChanel fail',
        //     resRtmJoinChannel,
        //   )
        //   await this.leaveLiveRoom()
        //   return false
        // }

        /** START 4. postCompleteLiveRoomsHaveUsers */
        if (currentUser?.id) {
          const completeJoinResult = await this.network.liveRoomNetwork.postCompleteLiveRoomsHaveUsers(
            this.currLiveRoom['id'],
            currentUser?.id,
          )

          if (!completeJoinResult || isEmptyObject(completeJoinResult)) {
            await this.leaveLiveRoom()
            return false
          }

          if (!completeJoinResult['isMutedMic']) {
            await AgoraRtc.muteLocalAudioStream(false)
          }

          // const putLiveRoomsHaveUsersResult = await this.network.liveRoomNetwork.putLiveRoomsHaveUsers(
          //   this.currLiveRoom['id'],
          //   currentUser['id'],
          //   { isMutedMic: true },
          // )
          // const updateToMute =
          //   putLiveRoomsHaveUsersResult && putLiveRoomsHaveUsersResult['data']

          // completeJoinResult['isMutedMic'] = true

          // // lhh rtc
          // if (!updateToMute) {
          //   await AgoraRtc.muteLocalAudioStream(false)
          //   completeJoinResult['isMutedMic'] = false
          // }
          // // lhh rtc end

          this.currLiveRoomUser = new LiveRoomUserModel(this.store, {
            ...completeJoinResult,
            User: currentUser,
          })

          this.currLiveRoomUser['agoraRtcUserToken'] =
            joinedLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtcUserToken']
          this.currLiveRoomUser['agoraRtmUserToken'] =
            joinedLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtmUserToken']
          this.currLiveRoomUser['agoraRtcChannelId'] =
            joinedLiveRoom['agoraRtcChannelId']
          this.currLiveRoomUser['agoraRtmChannelId'] =
            joinedLiveRoom['agoraRtmChannelId']

          const prevCurrLiveRoomUser = this.currLiveRoomUserList.find(
            liveRoomUser => liveRoomUser['userId'] === currentUser?.id,
          )
          if (!prevCurrLiveRoomUser) {
            this.currLiveRoomUserList.push(this.currLiveRoomUser)
          }
          else {
            Object.keys(this.currLiveRoomUser).forEach(
              key => (prevCurrLiveRoomUser[key] = this.currLiveRoomUser[key]),
            )
          }

          await this._sendActionJoinLiveRoom({
            liveRoomId: this.currLiveRoom['id'],
            userId: this.currLiveRoomUser['User']['id'],
          })

          if (
            this.currLiveRoom
            && this.currLiveRoom['mixtapeId']
            && (this.currLiveRoomUser['level']
              >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
              || !this.currLiveRoom['isMutedMixtape'])
          ) {
            await this._playLiveRoomMixtape(
              { id: this.currLiveRoom['mixtapeId'] },
              !!this.currLiveRoom['isMutedMixtape'],
              this.isMutedRemoteAll,
              this.isPersonalVolumeOn
                ? this.personalMixtapeVolume
                : this.currLiveRoom['mixtapeVolume'],
            )
          }
          else {
            await this.store.playerStore._stopPlayer()
          }
        }
        await this._startTimer()

        /**
         * liveRoomRecording 체크
         * - 해당 살롱이 녹음중인지 체크
         */
        await this.store?.liveRoomRecordingStore?.checkCurrLiveRoomRecording({
          liveRoomId: this.currLiveRoom?.['id'],
        })

        this.isJoinedLiveRoom = true
        return true
        /** END 4. postCompleteLiveRoomsHaveUsers */
      }
      catch (error) {
        await this.leaveLiveRoom()
        LiveRoomStoreLogger('LiveRoomStore joinLiveRoom error', error)
      }
    })
  }
  @action joinLiveRoomFromLinkKey = async (
    liveRoomLinkKey,
    isFromLink = false,
  ) => {
    /**
     * 살롱 접속
     * 0. getLiveRoom: room 존재 검사 및 liveRoom 객체 생성
     * 1. postLiveRoomsHaveUsers 진행
     *  1. 성공시 2 진행
     * 2. fcm subscribe Topic 진행
     *  1. 성공시 3 진행
     *  2. 실패시 deleteLiveRoomsHaveUsers 진행
     * 3. agora join 진행
     *  1. 성공시 4 진행
     *  2. 실패시
     *    - deleteLiveRoomsHaveUsers
     *    - fcm Topic unsubscribe
     * 4. postCompleteLiveRoomsHaveUsers 진행
     *  1. 성공시 5 진행
     *  2. 실패시
     *    - deleteLiveRoomsHaveUsers
     *    - fcm Topic unsubscribe
     *    - agora leave channel
     * 5. user 정보 받아와서
     */
    return this.store.useLoading(async () => {
      try {
        const { authStore } = this.store
        const { currentUser } = authStore

        // if (!currentUser && !currentUser['id']) {
        //   LiveRoomStoreLogger('[joinLiveRoom error] User is not logined')
        //   return false
        // }

        /** START 0. getLiveRoom */
        if (this.currLiveRoom) this.joinInit()
        else if (!this.currLiveRoom) this.init()

        if (!liveRoomLinkKey) {
          LiveRoomStoreLogger(
            '[joinLiveRoom error] liveRoomLinkKey id not exist',
          )
          return false
        }

        // 검증으로 currLiveRoom이 이미 존재할 시, fetch pass
        if (!this.currLiveRoom) {
          const param = {
            __include:
              'Users,LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags,LiveRoomsHaveUsers.LiveRoomsHaveUsersAgoraTokens,UsersReserveLiveRooms,FixedCommentUsers',
            'UsersReserveLiveRooms.userId': currentUser?.id, // 내 userId',
            __attributes:
              'Users.name,Users.id,Users.account,Users.imageUri,Users.profileColor,UsersReserveLiveRooms.userId',
          }
          const liveRoom = await this.network.liveRoomNetwork.getLiveRoomFromLinkKey(
            liveRoomLinkKey,
            param,
          )

          if (!liveRoom || isEmptyObject(liveRoom)) {
            alert('존재하지 않는 살롱입니다.')
            window.location.href = '/p/salon/list'
            LiveRoomStoreLogger('[joinLiveRoom error] Room is not exist')
            return false
          }
          // 비밀번호 로직 SalonCard 컴포넌트로
          // if (
          //   liveRoom?.isPrivate
          //   && liveRoom?.userId !== this.store.authStore?.currentUser?.id
          //   && !isFromLink
          // ) {
          //   alert('링크가 있어야 접속할 수 있는 비공개 살롱입니다')
          //   return false
          // }

          this.currLiveRoom = new LiveRoomModel(this.store, liveRoom)
        }
        const liveRoomUserList = this.currLiveRoom['LiveRoomsHaveUsers']

        if (liveRoomUserList.length !== 0) {
          this.currLiveRoomUserList.push(
            ...liveRoomUserList.map(
              liveRoomUser => new LiveRoomUserModel(this.store, liveRoomUser),
            ),
          )
        }
        /** END 0. getLiveRoom */

        /**
         *  If Reserve LiveRoom
         * 예약 상태일 때, join시 return
         * 방장이 join시 진행
         */
        if (
          this.currLiveRoom?.isReserved
          && !this.currLiveRoom?.isOpened
          && this.currLiveRoom?.userId !== currentUser?.id
        ) {
          /* 아직 열리지 않은 살롱에 대하여서는 성공 리턴 & reserve page 렌더 */
          return true
        }

        /** START 1. postLiveRoomsHaveUsers */
        let res
        if (currentUser?.id) {
          res = await this.network.liveRoomNetwork.postLiveRoomsHaveUsers(
            this.currLiveRoom['id'],
            currentUser?.id,
            { isMutedMic: true },
          )
        }
        else {
          res = await this.network.liveRoomNetwork.postLiveRoomsHaveGuest(
            this.currLiveRoom['id'],
            { isMutedMic: true },
          )
        }

        const joinedLiveRoom = res['data']
        if (!joinedLiveRoom || isEmptyObject(joinedLiveRoom)) {
          const errorCode = res['errorCode']
          if (errorCode === ERROR_CODE['LIVE_ROOMS_JOIN_BANNED_USER']) {
            alert('강퇴당한 살롱입니다')
          }
          if (errorCode === ERROR_CODE['LIVE_ROOMS_JOIN_EXCEED_USER']) {
            alert('인원이 초과되어 입장하실 수 없습니다')
          }
          if (errorCode === ERROR_CODE['LIVE_ROOMS_BLOCK_UNKNOWN_USER']) {
            alert('해당 콘텐츠는 로그인해야 청취 가능합니다')
          }

          LiveRoomStoreLogger('[joinLiveRoom error] post join error')
          return false
        }

        /** END 1. postLiveRoomsHaveUsers */

        /** START 3. agora join */
        // 살롱 새로 생성하거나, 이전에 접속했던 오너면 Broadcaster
        const joinResult = await this._joinAgora(
          joinedLiveRoom,
          this.currLiveRoom['userId'] === currentUser?.id,
        )
        if (!joinResult) {
          await this.leaveLiveRoom()
          LiveRoomStoreLogger('[joinLiveRoom error] join error')
          return false
        }
        // let role = isCreating ? 'Broadcaster' : 'Listener'
        // if (liveRoom['userId'] === currentUser['id']) {
        //   role = 'Broadcaster'
        // }

        // const channelConfig = {
        //   agoraRtcChannelId: this.agoraRtcChannelId,
        //   agoraRtcUserToken: this.agoraRtcUserToken,

        //   agoraRtmChannelId: this.agoraRtmUserToken,
        //   agoraRtmUserToken: this.agoraRtmUserToken,
        // }

        // if (
        //   !this.agoraRtcChannelId
        //   || !this.agoraRtcUserToken
        //   || !this.agoraRtmChannelId
        //   || !this.agoraRtmUserToken
        // ) {
        //   LiveRoomStoreLogger(
        //     '[joinLiveRoom error] Room name or Room token is not exist',
        //   )
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // // lhh rtc
        // await this.setRtcEngine(channelConfig, role)

        // if (!AgoraRtc) {
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // await AgoraRtc.joinChannel(
        //   this.agoraRtcChannelId,
        //   this.agoraRtcUserToken,
        //   this.store.authStore.currentUser['id'],
        // )

        // if (role === 'Broadcaster') {
        //   // AgoraRtc.setClientRole 에서 분기처리
        //   // await AgoraRtc.createAudio(true)
        //   await AgoraRtc.setClientRole(ClientRole.Broadcaster)
        //   // await AgoraRtc.publish()
        // }
        // else {
        //   await AgoraRtc.setClientRole(ClientRole.Audience)
        // }

        // await AgoraRtc.muteLocalAudioStream(true)

        // const connectionState = await AgoraRtc.isConnected
        // if (!connectionState) {
        //   await this.leaveLiveRoom()
        //   return false
        // }

        // /** END 3. agora join */
        // const resRtmJoinChannel = await AgoraRtm.joinChannel(
        //   this.agoraRtmChannelId,
        //   this.agoraRtmUserToken,
        //   this.store.authStore.currentUser['id'],
        // )

        // if (!resRtmJoinChannel) {
        //   /* todo: joinChannel reject, delete server data */
        //   LiveRoomStoreLogger(
        //     '[joinLiveRoom error] RtmJoinChanel fail',
        //     resRtmJoinChannel,
        //   )
        //   await this.leaveLiveRoom()
        //   return false
        // }

        /** START 4. postCompleteLiveRoomsHaveUsers */
        if (currentUser?.id) {
          const completeJoinResult = await this.network.liveRoomNetwork.postCompleteLiveRoomsHaveUsers(
            this.currLiveRoom['id'],
            currentUser?.id,
          )

          if (!completeJoinResult || isEmptyObject(completeJoinResult)) {
            await this.leaveLiveRoom()
            return false
          }

          if (!completeJoinResult['isMutedMic']) {
            await AgoraRtc.muteLocalAudioStream(false)
          }

          // const putLiveRoomsHaveUsersResult = await this.network.liveRoomNetwork.putLiveRoomsHaveUsers(
          //   this.currLiveRoom['id'],
          //   currentUser['id'],
          //   { isMutedMic: true },
          // )
          // const updateToMute =
          //   putLiveRoomsHaveUsersResult && putLiveRoomsHaveUsersResult['data']

          // completeJoinResult['isMutedMic'] = true

          // // lhh rtc
          // if (!updateToMute) {
          //   await AgoraRtc.muteLocalAudioStream(false)
          //   completeJoinResult['isMutedMic'] = false
          // }
          // // lhh rtc end

          this.currLiveRoomUser = new LiveRoomUserModel(this.store, {
            ...completeJoinResult,
            User: currentUser,
          })

          this.currLiveRoomUser['agoraRtcUserToken'] =
            joinedLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtcUserToken']
          this.currLiveRoomUser['agoraRtmUserToken'] =
            joinedLiveRoom['LiveRoomsHaveUsers'][0]['agoraRtmUserToken']
          this.currLiveRoomUser['agoraRtcChannelId'] =
            joinedLiveRoom['agoraRtcChannelId']
          this.currLiveRoomUser['agoraRtmChannelId'] =
            joinedLiveRoom['agoraRtmChannelId']

          const prevCurrLiveRoomUser = this.currLiveRoomUserList.find(
            liveRoomUser => liveRoomUser['userId'] === currentUser?.id,
          )
          if (!prevCurrLiveRoomUser) {
            this.currLiveRoomUserList.push(this.currLiveRoomUser)
          }
          else {
            Object.keys(this.currLiveRoomUser).forEach(
              key => (prevCurrLiveRoomUser[key] = this.currLiveRoomUser[key]),
            )
          }

          await this._sendActionJoinLiveRoom({
            liveRoomId: this.currLiveRoom['id'],
            userId: this.currLiveRoomUser['User']['id'],
          })

          if (
            this.currLiveRoom
            && this.currLiveRoom['mixtapeId']
            && (this.currLiveRoomUser['level']
              >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
              || !this.currLiveRoom['isMutedMixtape'])
          ) {
            await this._playLiveRoomMixtape(
              { id: this.currLiveRoom['mixtapeId'] },
              !!this.currLiveRoom['isMutedMixtape'],
              this.isMutedRemoteAll,
              this.isPersonalVolumeOn
                ? this.personalMixtapeVolume
                : this.currLiveRoom['mixtapeVolume'],
            )
          }
          else {
            await this.store.playerStore._stopPlayer()
          }
        }
        await this._startTimer()

        /**
         * liveRoomRecording 체크
         * - 해당 살롱이 녹음중인지 체크
         */
        await this.store?.liveRoomRecordingStore?.checkCurrLiveRoomRecording({
          liveRoomId: this.currLiveRoom?.['id'],
        })

        this.isJoinedLiveRoom = true
        return true
        /** END 4. postCompleteLiveRoomsHaveUsers */
      }
      catch (error) {
        await this.leaveLiveRoom()
        LiveRoomStoreLogger('LiveRoomStore joinLiveRoom error', error)
      }
    })
  }

  @action
  fetchCurrLiveRoom = async where => {
    if (!this.currLiveRoom?.id) return
    const liveRoom = await this.network.liveRoomNetwork.getLiveRoom(
      this.currLiveRoom?.id,
      where,
    )

    if (!this.currLiveRoom && liveRoom)
      this.currLiveRoom = new LiveRoomModel(this.store, liveRoom)

    return liveRoom
  }

  @action
  fetchLiveRoom = async (liveRoomId, where) => {
    if (!liveRoomId) return
    const liveRoom = await this.network.liveRoomNetwork.getLiveRoom(
      liveRoomId,
      where,
    )

    if (liveRoom?.response?.status === 404) {
      return null
    }

    return liveRoom
  }

  @action _leaveLiveRoomOtherLocal = async (liveRoomId, userId) => {
    try {
      const isExistUser = !!this.currLiveRoomUserList.find(
        liveRoomUser => liveRoomUser['User']['id'] === userId,
      )

      this.currLiveRoomUserList = this.currLiveRoomUserList.filter(
        liveRoomUser => liveRoomUser['User']['id'] !== userId,
      )

      const liveRoomsHaveUser = await this.network.liveRoomNetwork.getLiveRoomsHaveUsers(
        this.currLiveRoom['id'],
        userId,
      )
      if (!liveRoomsHaveUser || isEmptyObject(liveRoomsHaveUser)) {
        /**
         * 유저가 의도적으로 나가서 디비에서 지워진 경우, 동기화 처리 로직
         */
      }
      else {
        /**
         * 유저가 의도적으로 나간게 아님,,
         * 다른 유저의 나가기 처리??
         */
      }

      // const user = await this.store.userStore.fetchUser(userId)
      const user = await this.network.userNetwork.getUser(userId)
      if (!user) {
        console.error(
          '[LOG]',
          '[_receiveActionLeaveLiveRoom]',
          '[LEAVE_LIVE_ROOM]',
          liveRoomId,
          userId,
          'user is not exist',
        )
      }

      if (isExistUser) {
        // this.liveRoomMessageList.push({
        //   messageType: LIVE_ROOM_MESSAGE_TYPE['SYSTEM'],
        //   message: `님이 퇴장했습니다`,
        //   LiveRoomUser: { User: user, userId: user['id'] },
        // })
      }
    }
    catch (error) {
      LiveRoomStoreLogger('LiveRoomStore leaveLiveRoom error', error)
    }
  }

  @action _leaveLiveRoom = async (isStopMusic = true) => {
    LiveRoomStoreLogger('[LiveRoomStore_leaveLiveRoom ]')
    try {
      if (AgoraRtc) {
        // await AgoraRtc.removeAllListeners()
        await AgoraRtc.leaveChannel()
        await AgoraRtc.unpulishLocalAudio()
      }

      if (
        this.currLiveRoomUser
        && this.currLiveRoomUser['level'] === LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
      ) {
        // await this.finishLiveRoom()
      }

      await AgoraRtm.leaveChannel()

      await this._stopLiveRoomMixtape(isStopMusic, false, true)

      if (this.currLiveRoom) {
        this.init()
      }
    }
    catch (error) {
      LiveRoomStoreLogger('LiveRoomStore _leaveLiveRoom error', error)
    }
  }

  @action leaveLiveRoom = async (isStopMusic = true) => {
    LiveRoomStoreLogger('[LiveRoomStore leaveLiveRoom]')

    try {
      this.isStartedLeaveAction = true

      const liveRoomId = this.currLiveRoom && this.currLiveRoom['id']
      const userId =
        this.store.authStore
        && this.store.authStore.currentUser
        && this.store.authStore.currentUser['id']

      if (liveRoomId && userId) {
        await this.network.liveRoomNetwork.deleteLiveRoomsHaveUsers(
          liveRoomId,
          userId,
        )
        await this._sendActionLeaveLiveRoom({
          liveRoomId,
          userId,
        })
      }

      await this._leaveLiveRoom(isStopMusic)
      this.currentPlayingLiveRoomAudio = null
      this.currentPlayingLiveRoomMixtape = null

      this._clearTimer()
      this.setIsJoinedLiveRoom(false)
    }
    catch (error) {
      LiveRoomStoreLogger('LiveRoomStore leaveLiveRoom error', error)
    }
  }

  @action finishLiveRoom = async initCheck => {
    /**
     * 살롱 종료
     * 1. 서버 룸 삭제
     * 2. 삭제 rtm 전송
     * 3. rtc 초기화 (local)
     * 4. rtm 초기화 (local)
     * 5. store init (local)
     */
    try {
      const deleteResult = await this.network.liveRoomNetwork.deleteLiveRoom(
        this.currLiveRoomUser['liveRoomId'],
      )

      if (!deleteResult) {
        console.error('finishLiveRoom deleteLiveRoom error')
        return false
      }
      await this._sendActionDeleteLiveRoom({
        liveRoomId: this.currLiveRoom['id'],
      })

      await this.finishLiveRoomLocal(this.currLiveRoom['id'], initCheck)
      return true
    }
    catch (error) {
      LiveRoomStoreLogger('LiveRoomStore finishLiveRoom error', error)
      return false
    }
  }

  @action finishLiveRoomLocal = async (liveRoomId, initCheck, popUp) => {
    if (AgoraRtc) {
      // await AgoraRtc.removeAllListeners()
      await AgoraRtc.leaveChannel()
      await AgoraRtc.unpulishLocalAudio()
    }

    await AgoraRtm.leaveChannel()

    await this._stopLiveRoomMixtape(true, true, true, true)

    if (liveRoomId) {
      if (this.liveRoomList) {
        const index = this.liveRoomList.findIndex(
          liveRoom => liveRoom['id'] && liveRoom['id'] === liveRoomId,
        )
        if (index > -1) {
          this.liveRoomList.splice(index, 1)
        }
      }

      if (this.currentAutocomplete?.autocompleteLiveRoomList) {
        const index = this.liveRoomList.findIndex(
          liveRoom => liveRoom['id'] && liveRoom['id'] === liveRoomId,
        )
        if (index > -1) {
          this.currentAutocomplete.autocompleteLiveRoomList.splice(index, 1)
        }
      }
    }

    if (this.currLiveRoomUser?.level >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      const resultDeleted = await this.network.liveRoomNetwork.getDeletedLiveRoom(
        this.currLiveRoom?.id,
      )
      if (resultDeleted) {
        this.deletedCurrLiveRoom = resultDeleted
      }
    }

    if (!initCheck) {
      this.init()
    }
    else {
      this.currLiveRoom['isFinished'] = true
    }
  }

  @action.bound
  fetchMyLiveRoomList = async () => {
    return this.store.useLoading(async () => {
      /* 내 살롱과 기본 살롱 order 및 UI 변동 가능성으로 따로 fetch */
      /* 내 살롱 */
      const myLiveRoomList = []
      if (this.store.authStore.isLogined()) {
        /* include 속도 고려해서 hasMany include 는 따로 fetch */
        /* LiveRooms.LiveRoomsHaveUsers */
        const myLiveRoomList1 = await this.network.liveRoomNetwork.getLiveRoomList(
          {
            userId: this.store.authStore.currentUser['id'],
            __attributes: `id,linkKey,title,userId,createdAt,deletedAt,LiveRoomsHaveUsers.id,LiveRoomsHaveUsers.level,LiveRoomsHaveUsers.Users.id,LiveRoomsHaveUsers.Users.account,LiveRoomsHaveUsers.Users.imageUri,LiveRoomsHaveUsers.Users.name,LiveRoomsHaveUsers.Users.profileColor`,
            __include:
              'LiveRoomsHaveUsers.Users,LiveRoomsHaveUsers.LiveRoomsHaveUsersAgoraTokens',
            __isMineNoOwner: true,
            __limit: 1,
            __order: 'createdAtDesc',
            isOpened: 1,
            // isReserved: 1,
            // __orField: 'isOpened,isReserved',
          },
        )

        /* LiveRooms.LiveRoomsHaveTags */
        const myLiveRoomList2 = await this.network.liveRoomNetwork.getLiveRoomList(
          {
            userId: this.store.authStore.currentUser['id'],
            __include: 'LiveRoomsHaveTags.Tags',
            __isMineNoOwner: true,
            __limit: 1,
            __order: 'createdAtDesc',
            isOpened: 1,
            // isReserved: 1,
            // __orField: 'isOpened,isReserved',
          },
        )

        /* LiveRooms.Mixtapes */
        const myLiveRoomList3 = await this.network.liveRoomNetwork.getLiveRoomList(
          {
            userId: this.store.authStore.currentUser['id'],
            __include: 'Mixtapes.MixtapesHaveSongs.Songs',
            __addColumn: 'mixtapePlayedTime',
            __isMineNoOwner: true,
            __limit: 1,
            __order: 'createdAtDesc',
            isOpened: 1,
            // isReserved: 1,
            // __orField: 'isOpened,isReserved',
          },
        )

        let liveRoom
        if (myLiveRoomList1 && myLiveRoomList1.length > 0) {
          liveRoom = myLiveRoomList1[0]
        }
        if (myLiveRoomList2 && myLiveRoomList2.length > 0) {
          liveRoom = { ...liveRoom, ...myLiveRoomList2[0] }
        }
        if (myLiveRoomList3 && myLiveRoomList3.length > 0) {
          liveRoom = { ...liveRoom, ...myLiveRoomList3[0] }
        }

        if (liveRoom) {
          myLiveRoomList.push(new LiveRoomModel(this.store, liveRoom))
        }

        this.liveRoomList = myLiveRoomList.concat(this.liveRoomList)
      }
    })
  }

  @action.bound
  fetchOptionLiveRoomList = async option => {
    return this.store.useLoading(async () => {
      const liveRoomList = await this.network.liveRoomNetwork.getLiveRoomList({
        __include:
          // 'LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags,Mixtapes.MixtapesHaveSongs.Songs',
          'LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags',
        __addColumn: 'mixtapePlayedTime',
        // isPrivate: 0,
        ...option,
      })
      if (!liveRoomList) return
      this.liveRoomList = liveRoomList.map(
        room => new LiveRoomModel(this.store, room),
      )
    })
  }

  @action fetchLiveRoomList = async ({
    limit = 10,
    isReset = true,
    isOnlyOpened = false,
  }) => {
    const wrapper = isReset ? this.store.useLoading : func => func()
    return wrapper(async () => {
      try {
        if (isNaN(parseInt(limit))) {
          limit = 10
        }

        if (isReset) {
          this.liveRoomListNextCursor = null
          this.liveRoomList = []
        }

        const option = {}
        if (isOnlyOpened) {
          option.isOpened = 1
        }
        const data = await this.network.liveRoomNetwork.getRecoomendLiveRoomList(
          {
            __limit: limit,
            __nextCursor: this.liveRoomListNextCursor,
            ...option,
          },
        )

        if (!data?.liveRoomList || data?.liveRoomList?.length <= 0) {
          return
        }

        const liveRoomList =
          data?.liveRoomList?.map(
            liveRoom => new LiveRoomModel(this.store, liveRoom),
          ) || []

        if (this.liveRoomList?.length > 0) {
          this.liveRoomList.push(...liveRoomList)
        }
        else {
          this.liveRoomList = liveRoomList
        }

        let isFinished = false
        if (data?.__nextCursor === 'null') {
          isFinished = true
          this.liveRoomListNextCursor = null
        }
        else {
          this.liveRoomListNextCursor = data?.__nextCursor
        }

        return { isFinished }
      }
      catch (error) {
        console.log('LiveRoomStore fetchLiveRoomList error', error)
      }
    })
  }

  @action uploadLiveRoomCoverImage = (formData, _setCover) => {
    const { jsonWebToken } = this.store.authStore
    // SalonImageUploadForm 으로 로직 이동 ( web )
    // const formData = new FormData()
    // formData.append('name', 'testName'); // you can append anyone.
    // formData.append('image', {
    //   uri: filePath,
    //   type: 'image/jpeg', // or photo.type
    //   name: 'testPhotoName',
    // })
    upload('/uploads/live-rooms/', jsonWebToken, formData).then(({ data }) => {
      _setCover(data['imageUri'])
    })
  }

  @action uploadLiveRoomChat = (formData, user, _imageSize) => {
    const { jsonWebToken } = this.store.authStore

    this.store.useLoading(async () => {
      upload('/uploads/live-rooms/chat', jsonWebToken, formData).then(
        async ({ data }) => {
          if (data) {
            const liveRoomId = this.currLiveRoom['id']
            const userLevel = this.currLiveRoomUser['level']
            const userId = user['id']

            const result = await LIVE_ROOM_ACTION['SEND_CHAT']['SENDER']({
              liveRoomId,
              userLevel,
              userId,
              imageUri: data['imageUri'],
              chatMessage: '',
            })

            const imageSize = {
              height: 0,
              width: 0,
            }

            if (_imageSize.height > _imageSize.width) {
              imageSize.height = 280
              imageSize.width = (_imageSize.width / _imageSize.height) * 280
            }
            else {
              imageSize.width = 280
              imageSize.height = (_imageSize.height / _imageSize.width) * 280
            }

            if (result) {
              if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
                this.addChatMessageLocal({
                  liveRoomId,
                  userLevel,
                  userId,
                  imageSize,
                  imageUri: data['imageUri'],
                  chatMessage: '',
                })
              }
            }
          }
        },
      )
    })
  }

  @action banUser = async user => {
    if (!user || !this.currLiveRoom) {
      console.error('required params')
      return false
    }

    const deletedLiveRoomsHaveUsers = await this.network.liveRoomNetwork.deleteLiveRoomsHaveUsers(
      this.currLiveRoom['id'],
      user['id'],
    )

    if (deletedLiveRoomsHaveUsers) {
      try {
        await this._sendActionBanLiveRoomUser(user)
        await this._leaveLiveRoomOtherLocal(this.currLiveRoom['id'], user['id'])

        return true
      }
      catch (error) {
        console.error('[banUser] error', error)
        return false
      }
    }
  }

  @action muteAllMic = async () => {
    /**
     * 전체 마이크 뮤트 rtm 발송
     */
    if (!this.currLiveRoom) {
      console.error('[muteAllMic] required params')
      return false
    }

    const liveRoomId = this.currLiveRoom['id']

    // /* send hreum server */
    // const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
    //   liveRoomId,
    //   {
    //     mixtapeVolume: this.MUTE_VOICE_MIXTAPE_VOLUME,
    //   },
    // )
    // if (!updatedLiveRoom) {
    //   console.error('[LiveRoomStore]', '[muteAllMic]', 'failed putLiveRoom.')
    //   return
    // }

    /* send other client */
    const res = await this._sendActionMuteAllMic(liveRoomId)
    if (!res) {
      console.error('[LiveRoomStore]', '[muteAllMic]', 'failed putLiveRoom.')
      return
    }

    // /* set local data */
    // this.currLiveRoom['mixtapeVolume'] = updatedLiveRoom['mixtapeVolume']
    await this.muteAllMicLocal()

    return true
  }

  @action muteAllMicLocal = async () => {
    const result = await this.onPressSwitchMuteStateMyMic(false)
    if (result) {
      // await this.store.currentPlaySongStore.setVolume(
      //   this.MUTE_VOICE_MIXTAPE_VOLUME,
      // )
      // if (this.currLiveRoom && this.currLiveRoom['id']) {
      //   this.currLiveRoom['mixtapeVolume'] = this.MUTE_VOICE_MIXTAPE_VOLUME
      // }

      // await this._engine(ClientRole.Audience)
      return true
    }
    return false
  }

  @action onPressSwitchMuteStateRemoteAllMic = async isMutedRemoteAll => {
    /**
     * remote 살롱 전부 off
     *
     * todo
     * - 백그라운드 플라 재생시 플라 재생 mute/unmute
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser) {
      console.error('[onPressSwitchMuteStateRemoteAllMic] required params')
      return
    }

    if (!AgoraRtc) {
      console.error(
        '[onPressSwitchMuteStateRemoteAllMic] rtc engine is not exist',
      )
      return
    }

    try {
      const isSwitchToMute = !isMutedRemoteAll

      await AgoraRtc.muteAllRemoteAudioStreams(isSwitchToMute)
      this.isMutedRemoteAll = isSwitchToMute

      // Todo: 아래 로직 플레이어 muted하면 되는게 아닌지?..
      this.store.playerStore.setMuted(isSwitchToMute)
      // if (this.currentPlayingLiveRoomAudio) {
      //   if (isSwitchToMute) {
      //     await this._stopLiveRoomAudio(
      //       this.currLiveRoom['isMutedMixtape'],
      //       true,
      //       false,
      //     )
      //   }
      //   else if (this.currentPlayingLiveRoomAudio) {
      //     await this._playLiveRoomAudio(
      //       this.currentPlayingLiveRoomAudio,
      //       this.currLiveRoom['isMutedMixtape'],
      //       this.isMutedRemoteAll,
      //       this.isPersonalVolumeOn
      //         ? this.personalMixtapeVolume
      //         : this.currLiveRoom['mixtapeVolume'],
      //       this.currLiveRoom?.songStartedAt,
      //     )
      //   }
      // }
      // else if (this.currentPlayingLiveRoomMixtape) {
      //   if (isSwitchToMute) {
      //     await this._stopLiveRoomMixtape(
      //       this.currLiveRoom['isMutedMixtape'],
      //       true,
      //       this.isMutedOnlyMixtape,
      //       this.isMutedOnlyMixtape,
      //     )
      //   }
      //   else if (this.currentPlayingLiveRoomMixtape) {
      //     await this._playLiveRoomMixtape(
      //       this.currentPlayingLiveRoomMixtape,
      //       this.currLiveRoom['isMutedMixtape'],
      //       this.isMutedRemoteAll,
      //       this.isPersonalVolumeOn
      //         ? this.personalMixtapeVolume
      //         : this.currLiveRoom['mixtapeVolume'],
      //       this.isMutedOnlyMixtape,
      //     )
      //   }
      // }

      return true
    }
    catch (error) {
      console.error('[onPressSwitchMuteStateRemoteAllMic] error', error)
      return false
    }
  }

  @action onPressSwitchMuteStateMyMic = async isMutedMic => {
    if (isMutedMic && !this.agoraDevice) {
      const permissioned = await this.getDevicesPermission()
      if (!permissioned) return
    }
    if (!this.currLiveRoom || !this.currLiveRoomUser) {
      console.error('[onPressSwitchMuteStateMyMic] required params')
      return false
    }

    if (!AgoraRtc) {
      console.error('[onPressSwitchMuteStateMyMic] rtc engine is not exist')
      return false
    }

    try {
      const { User } = this.currLiveRoomUser

      // true: mute, false: unMute
      const isSwitchToMute = !isMutedMic
      const update = await this.updateLiveRoomUser(User, {
        isMutedMic: isSwitchToMute,
      })

      if (update) {
        await AgoraRtc.muteLocalAudioStream(isSwitchToMute)

        return true
      }
      return false
    }
    catch (error) {
      console.error('[onPressSwitchMuteStateMyMic] error', error)
      return false
    }
  }

  @action isMutedMic = async () => {
    /**
     * LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] 미만은 isMutedMic는 false지만 마이크 안나옴
     * LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'] 이상인 경우,
     * this.currLiveRoomUser['isMutedMic']로 관리
     *  - 서버와 실시간 동기화
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser) {
      console.error('[isMutedMic] required params')
      return false
    }

    if (
      this.currLiveRoomUser['level'] >= LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST']
      && !this.currLiveRoomUser['isMutedMic']
    ) {
      return true
    }
    return false
  }

  @action getBackToGuest = async liveRoomUser => {
    const { User, prevLevel } = liveRoomUser
    if (!this.currLiveRoom || !this.currLiveRoomUser || !User) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (
      this.currLiveRoomUser['level'] !== LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
    ) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(User, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'],
    })

    if (update) {
      return true
    }
  }

  @action getBackToListener = async user => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 BroadCaster => Listener
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser || !user) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(user, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER'],
    })

    if (update) {
      return true
    }
  }

  @action getBackToPrevLevel = async liveRoomUser => {
    /**
     * [호스트권한 취소]
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    const { User, prevLevel } = liveRoomUser
    if (!this.currLiveRoom || !this.currLiveRoomUser || !User) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (
      this.currLiveRoomUser['level'] !== LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER']
    ) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(User, {
      level: prevLevel,
    })

    if (update) {
      return true
    }
  }

  @action returnToListener = async () => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    const { User } = this.currLiveRoomUser

    if (!this.currLiveRoom || !this.currLiveRoomUser || !User) {
      console.error(
        '[acceptToGuest] required params',
        !this.currLiveRoom,
        !this.currLiveRoomUser,
        !User,
      )
      return false
    }

    if (
      this.currLiveRoomUser['level'] !== LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST']
    ) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(User, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER'],
    })

    if (update) {
      return true
    }
  }

  @action returnToPrevLevel = async () => {
    /**
     * [참여자로 되돌아가기]
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    const { User, prevLevel } = this.currLiveRoomUser
    if (!this.currLiveRoom || !this.currLiveRoomUser || !User) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (
      this.currLiveRoomUser['level'] !== LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
    ) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(User, {
      level: prevLevel,
    })

    if (update) {
      return true
    }
  }

  @action acceptToHost = async user => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser || !user) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const update = await this.updateLiveRoomUser(user, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'],
    })

    if (update) {
      return true
    }
  }

  @action acceptToGuest = async user => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser || !user) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }
    const update = await this.updateLiveRoomUser(user, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST'],
    })

    if (update) {
      return true
    }
  }

  @action confirmListener = async () => {
    this.needConfirmListener = false
  }

  @action confirmHost = async () => {
    this.needConfirmHost = false
    this.needConfirmGuest = false
    this.needConfirmListener = false
    if (this.isPersonalVolumeOn && !!this.currentPlayingLiveRoomMixtape) {
      this.isPersonalVolumeOn = false

      if (
        this.personalMixtapeVolume <= 0
        && this.currLiveRoom['mixtapeVolume'] > 0
      ) {
        this.isMutedOnlyMixtape = false
      }
      this.store.playerStore.setVolume(this.currLiveRoom['mixtapeVolume'])
    }
  }

  @action confirmGuest = async () => {
    this.needConfirmGuestFromListener = false
    // await this._updateToSpeaker()
  }

  @action _updateToSpeaker = async () => {
    if (
      this.currLiveRoom
      && !this.currLiveRoom['isMutedMixtape']
      && this.currentPlayingLiveRoomMixtape
    ) {
      this.isFirstBroadcaster = true
    }
    await AgoraRtc.setClientRole(ClientRole.Broadcaster)
    await this.onPressSwitchMuteStateMyMic(false)
  }

  @action requestSpeaker = async () => {
    const { User } = this.currLiveRoomUser || {}

    if (!this.currLiveRoom || !User) {
      console.error('[requestSpeaker] required params')
      return false
    }

    const updated = await this.updateLiveRoomUser(User, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST'],
    })

    if (updated) {
      alert('손들기 완료')
    }
    return true
  }

  @action cancelSpeaker = async () => {
    const { User } = this.currLiveRoomUser || {}
    if (!this.currLiveRoom || !User) {
      console.error('[cancelSpeaker] required params')
      return false
    }

    const updated = await this.updateLiveRoomUser(User, {
      level: LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER'],
    })

    if (updated) {
      alert('손들기 취소')
    }
    return true
  }

  @action addChatMessageLocal = async ({
    liveRoomId,
    chatMessage,
    userLevel,
    userId,
    messageType = LIVE_ROOM_MESSAGE_TYPE['CHAT'],
    imageUri,
    imageSize,
    linkUrl,
    data = {},
  }) => {
    let LiveRoomUser = this.currLiveRoomUserList.find(
      liveRoomUser => liveRoomUser['userId'] === userId,
    )
    /* 채팅을 칠때, 로컬 살롱유저 리스트에 유저가 없으면 채팅이 이상하게 표시됨. */
    if (!LiveRoomUser) {
      const _liveRoomUser = await this.network.liveRoomNetwork.getLiveRoomsHaveUsers(
        liveRoomId,
        userId,
        { __include: 'Users,LiveRoomsHaveUsersAgoraTokens' },
      )
      /**
       * todo:
       *  LiveRoomNetwork object return 값은 다른 네트워크랑 통일되게 null 값으로 바꾸기
       *  아고라는 접속해 있지만 흐름서버는 유저가 삭제되어 유저 싱크 안맞을 때, 동기화 로직 추가
       */
      if (
        _liveRoomUser
        && !isEmptyObject(_liveRoomUser)
        && this.currLiveRoomUserList
      ) {
        LiveRoomUser = new LiveRoomUserModel(this.store, _liveRoomUser)
        this.currLiveRoomUserList.push(LiveRoomUser)
      }
    }

    if (LiveRoomUser) {
      this.liveRoomMessageList.push({
        messageType,
        liveRoomId,
        chatMessage,
        LiveRoomUser,
        imageUri,
        imageSize,
        linkUrl,
        data,
      })
    }
    else {
      /**
       * todo:
       *  로그서버로 에러 전송해서 에러 세부사항 디버깅
       *  흐름 서버와 아고라 rtm 서버와 동기화가 안되어있음
       *  아고라 rtm 에는 유저가 있어서 채팅이 전송되지만, 흐름서버에는 유저가 없어서 채팅에서 유저를 표시할 수 없음
       */
    }

    if (this.liveRoomMessageList?.length > 1000) {
      this.liveRoomMessageList.shift()
    }
  }

  @action updateLiveRoomLocal = async ({
    liveRoomId,
    title,
    description,
    isPrivate,
    password,
    isMutedMic,
    mixtapeId,
    LiveRoomsHaveTags,
    imageUri,
    isBlockedUnknownUser,
  }) => {
    if (!liveRoomId) {
      console.error('liveRoomId is not exist')
      return false
    }

    if (this.currLiveRoom['id'] !== liveRoomId) {
      console.error('currLiveRoom id is not matched liveRoomId')
      return false
    }

    this.currLiveRoom['title'] = title
    this.currLiveRoom['description'] = description
    this.currLiveRoom['isPrivate'] = isPrivate
    this.currLiveRoom['password'] = password
    this.currLiveRoom['LiveRoomsHaveTags'] = LiveRoomsHaveTags
    this.currLiveRoom['imageUri'] = imageUri
    this.currLiveRoom['isBlockedUnknownUser'] = isBlockedUnknownUser

    if (typeof isMutedMic === 'boolean') {
      /**
       * 해당 컬럼을 쓸거면(전체 뮤트를 위해 서버 column사용) 해당 로직 필요
       */
      this.currLiveRoom['isMutedMic'] = isMutedMic
    }

    if (mixtapeId) {
      /**
       * todo
       * 현재 재생중인 흐름으로 변경 로직 추가 필요
       */
      this.currLiveRoom['mixtapeId'] = mixtapeId
    }

    if (
      !this.store?.authStore?.currentUser?.['id']
      && this.currLiveRoom['isBlockedUnknownUser']
    ) {
      alert('해당 콘텐츠는 로그인해야 청취 가능합니다')
    }
  }

  @action updateLiveRoomUser = async (
    user,
    { level, isMutedMic },
    isChangedUserInfo = false,
  ) => {
    if (!this.currLiveRoom || !user) {
      console.error('[updateLiveRoomUser] required params')
      return false
    }

    const res = await this.network.liveRoomNetwork.putLiveRoomsHaveUsers(
      this.currLiveRoom['id'],
      user['id'],
      {
        level,
        isMutedMic,
      },
    )

    const updatedLiveRoomsHaveUsers = res['data']
    if (
      !updatedLiveRoomsHaveUsers
      || isEmptyObject(updatedLiveRoomsHaveUsers)
    ) {
      const errorCode = res['errorCode']
      LiveRoomStoreLogger('lhh errorCode', errorCode)
      if (errorCode === ERROR_CODE['LIVE_ROOMS_EXCEED_MIC_USER']) {
        alert('최대 게스트 및 호스트 숫자를 초과하였습니다')
      }
      return false
    }

    updatedLiveRoomsHaveUsers['isChangedUserInfo'] = isChangedUserInfo

    LiveRoomStoreLogger('updatedLiveRoomsHaveUsers', updatedLiveRoomsHaveUsers)
    LiveRoomStoreLogger('isChangedUserInfo', isChangedUserInfo)

    await this._sendActionUpdateLiveRoomUser(updatedLiveRoomsHaveUsers)
    this.updateLiveRoomUserLocal(updatedLiveRoomsHaveUsers)

    return true
  }

  @action updateLiveRoomUserLocal = async ({
    liveRoomId,
    userId,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
  }) => {
    LiveRoomStoreLogger('lhh updateLiveRoomUserLocal', level, prevLevel)
    if (this.currLiveRoom && this.currLiveRoom['id'] === liveRoomId) {
      const liveRoomUser = this.currLiveRoomUserList.find(
        user => user['userId'] === userId,
      )

      if (isChangedUserInfo) {
        const user = await this.network.userNetwork.getUser(userId)
        if (user) {
          liveRoomUser['User'] = user
          const _user = new UserModel(this.store, user)
          // eslint-disable-next-line
          this.liveRoomMessageList.map(message => {
            if (message['LiveRoomUser']) {
              if (message['LiveRoomUser']['userId'] === user['id']) {
                message['LiveRoomUser']['User'] = _user
              }
            }
          })

          if (this.currLiveRoomUser['userId'] === userId) {
            this.currLiveRoomUser['User'] = _user
          }
        }
      }

      if (liveRoomUser) {
        liveRoomUser['level'] = level
        liveRoomUser['isMutedMic'] = isMutedMic
        liveRoomUser['levelUpdatedAt'] = levelUpdatedAt
        liveRoomUser['prevLevel'] = prevLevel

        if (
          this.currLiveRoomUser
          && this.currLiveRoomUser['level']
            >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
          && level === LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST']
          && prevLevel === LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER']
          && !this.isMuteRequestGuest
        ) {
          // web logic
          this.store.appStateStore.playSound(sounds.soundEffectRequestGuest)

          // app logic
          // const sound = new Sound(soundEffectRequestGuest, (error) => {
          //   if (error) {
          //     LiveRoomStoreLogger(
          //       '[LOG][LiveRoomStore][updateLiveRoomUserLocal][new Sound] error',
          //       error,
          //     )
          //   }
          //   else {
          //     sound.setVolume(0.5)
          //     sound.play(() => sound.release()) // have to put the call to play() in the onload callback
          //   }
          // })
        }
      }

      if (this.currLiveRoomUser['userId'] === userId) {
        this.currLiveRoomUser['level'] = level
        this.currLiveRoomUser['isMutedMic'] = isMutedMic
        this.currLiveRoomUser['levelUpdatedAt'] = levelUpdatedAt
        this.currLiveRoomUser['prevLevel'] = prevLevel

        if (level === LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST']) {
          if (
            this.currLiveRoom
            && !this.currLiveRoom['isMutedMixtape']
            && this.currentPlayingLiveRoomMixtape
          ) {
            this.isFirstBroadcaster = true
          }
          await AgoraRtc.setClientRole(ClientRole.Audience)
        }

        if (level === LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER']) {
          if (
            this.currLiveRoom
            && !this.currLiveRoom['isMutedMixtape']
            && this.currentPlayingLiveRoomMixtape
          ) {
            this.isFirstBroadcaster = true
          }
          await AgoraRtc.setClientRole(ClientRole.Audience)
        }
      }
    }
  }

  @action updateLevelFromRemote = async (level, prevLevel) => {
    LiveRoomStoreLogger('lhh updateLevelFromRemote', level, prevLevel)
    this.initModalState()
    /** 마이크는 확인하고 켜야되기 때문에 따로 모달 클릭 이벤트로 처리 */
    if (
      prevLevel === LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
      && level === LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST']
    ) {
      this.needConfirmGuestFromHost = true
    }
    if (
      prevLevel <= LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST']
      && level === LIVE_ROOMS_HAVE_USERS_LEVEL['GUEST']
    ) {
      this.needConfirmGuestFromListener = true
      await this._updateToSpeaker()
    }
    if (level === LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      this.needConfirmHost = true
      await this._updateToSpeaker()
    }
    if (level === LIVE_ROOMS_HAVE_USERS_LEVEL['LISTENER']) {
      this.needConfirmListener = true
    }
  }

  @action updateLiveRoomUserLocalMic = async ({
    liveRoomId,
    userId,
    isMutedMic,
  }) => {
    if (this.currLiveRoom && this.currLiveRoom['id'] === liveRoomId) {
      const liveRoomUser = this.currLiveRoomUserList.find(
        user => user['userId'] === userId,
      )
      if (liveRoomUser) {
        liveRoomUser['isMutedMic'] = isMutedMic
      }

      if (this.currLiveRoomUser['userId'] === userId) {
        this.currLiveRoomUser['isMutedMic'] = isMutedMic
      }

      // if (this.currentPlayingLiveRoomMixtape && !isMutedMic) {
      //   this.store.currentPlaySongStore.setVolume(
      //     this.currLiveRoom['mixtapeVolume'],
      //   )
      //   this.setVolumeLiveRoomMixtape(
      //     this.currentPlayingLiveRoomMixtape,
      //     this.currLiveRoom['mixtapeVolume'],
      //   )
      // }
    }
  }

  @action fetchLiveRoomPopularMixtapeData = async (isReset = true) => {
    if (isReset || !this.popularMixtapeData) {
      this.popularMixtapeData = { isFinished: false, list: [] }
    }

    if (this.popularMixtapeData.isFinished) {
      return this.popularMixtapeData
    }

    /**
     * 지금은 전체 Flow에서 인기순 정렬하지만
     * Flow가 많아지면 적당한 날짜설정으로 범위안에서 인기순 골라야함
     */
    const _mixtapeList = await this.network.mixtapeNetwork.getPopularRealtimeMixtapeList()
    // this.popularMixtapeData.isFinished = !_mixtapeList || !(_mixtapeList.length === 20)
    this.popularMixtapeData.isFinished = true
    this.popularMixtapeData.list.push(
      ...((_mixtapeList
        && _mixtapeList.map(mixtape => new MixtapeModel(this.store, mixtape)))
        || []),
    )
    return this.popularMixtapeData
  }

  @action fetchLiveRoomTagPopularMixtapeData = async (
    tagKeyword,
    isReset = true,
  ) => {
    if (isReset || !this.popularMixtapeData) {
      this.popularMixtapeData = { isFinished: false, list: [] }
    }

    if (this.popularMixtapeData.isFinished) {
      return this.popularMixtapeData
    }

    const startIndex = this.popularMixtapeData.list.length
    let _mixtapeList = await this.network.searchNetwork.getTagMixtapePopularList(
      {
        keyword: tagKeyword,
        startIndex,
      },
    )

    if (!_mixtapeList) {
      const fetchedTagDetail = await this.store.searchStore.fetchTagDetailMixtape(
        { keyword: tagKeyword },
        startIndex,
      )
      _mixtapeList = fetchedTagDetail['Mixtapes']
    }

    this.popularMixtapeData.isFinished =
      !_mixtapeList || !(_mixtapeList.length === 20)
    this.popularMixtapeData.list.push(
      ...((_mixtapeList
        && _mixtapeList.map(
          mixtape => mixtape && new MixtapeModel(this.store, mixtape),
        ))
        || []),
    )

    return this.popularMixtapeData
  }

  @action fetchLiveRoomLikedMixtapeData = async (
    userId,
    isReset = true,
    extraOptions = {},
  ) => {
    if (!userId) {
      return null
    }

    if (isReset || !this.likedMixtapeData) {
      this.likedMixtapeData = { isFinished: false, list: [] }
    }

    if (this.likedMixtapeData.isFinished) {
      return this.likedMixtapeData
    }

    const lastItem =
      this.likedMixtapeData.list.length > 0
      && this.likedMixtapeData.list[this.likedMixtapeData.list.length - 1]

    const options = {
      __required: 'UsersLikeMixtapes,Users',
      'UsersLikeMixtapes.userId': userId,
      isPublished: 1,
      __limit: 20,
      __include: 'MixtapesHaveTags.Tags',
      // 'MixtapesHaveTags.Tags,MixtapesHaveSongs,MixtapesHaveSongs.Songs',
      __attributes: `id,linkKey,name,description,imageUri,mixtapesHaveSongsUpdatedAt,isPublished,publishedAt,likeCount,playCount,\
Users.id,Users.account,Users.name,Users.imageUri,\
MixtapesHaveTags.id,MixtapesHaveTags.level,MixtapesHaveTags.Tags.id,MixtapesHaveTags.Tags.keyword,\
UsersLikeMixtapes.id,UsersLikeMixtapes.createdAt`,
      __order: 'UsersLikeMixtapes.createdAtDesc',
      __pageLimit: 20,
      __pageField: 'UsersLikeMixtapes.createdAt',
      __pageOrder: 'desc',
      __pageIdValue: (lastItem && lastItem.id) || undefined,
      __pageValue:
        (lastItem && lastItem.UsersLikeMixtapes[0].createdAt) || undefined,
      popularChartLevel: 0,
      ...extraOptions,
    }

    const _mixtapeList = await this.network.mixtapeNetwork.getMixtapeList(
      options,
    )
    this.likedMixtapeData.isFinished =
      !_mixtapeList || !(_mixtapeList.length === 20)
    this.likedMixtapeData.list.push(
      ...((_mixtapeList
        && _mixtapeList.map(mixtape => new MixtapeModel(this.store, mixtape)))
        || []),
    )
    return this.likedMixtapeData
  }

  @action fetchLiveRoomTagLikedMixtapeData = async (
    userId,
    tagId,
    isReset = true,
    extraOptions = {},
  ) => {
    if (!userId) {
      return null
    }

    if (isReset || !this.likedMixtapeData) {
      this.likedMixtapeData = { isFinished: false, list: [] }
    }

    if (this.likedMixtapeData.isFinished) {
      return this.likedMixtapeData
    }

    const lastItem =
      this.likedMixtapeData.list.length > 0
      && this.likedMixtapeData.list[this.likedMixtapeData.list.length - 1]

    const options = {
      __required: 'UsersLikeMixtapes,Users,MixtapesHaveTags',
      'UsersLikeMixtapes.userId': userId,
      'MixtapesHaveTags.tagId': tagId,
      'MixtapesHaveTags.level': MIXTAPES_HAVE_TAGS_LEVEL['DESCRIPTION_TAG'],
      'MixtapesHaveTags.levelOperator': 'gt',
      isPublished: 1,
      __limit: 20,
      __include: 'MixtapesHaveTags.Tags',
      // 'MixtapesHaveTags.Tags,MixtapesHaveSongs,MixtapesHaveSongs.Songs',
      __attributes: `id,linkKey,name,description,imageUri,mixtapesHaveSongsUpdatedAt,isPublished,publishedAt,likeCount,playCount,\
Users.id,Users.account,Users.name,Users.imageUri,\
MixtapesHaveTags.id,MixtapesHaveTags.level,MixtapesHaveTags.Tags.id,MixtapesHaveTags.Tags.keyword,\
UsersLikeMixtapes.id,UsersLikeMixtapes.createdAt`,
      __order: 'UsersLikeMixtapes.createdAtDesc',
      __pageLimit: 20,
      __pageField: 'UsersLikeMixtapes.createdAt',
      __pageOrder: 'desc',
      __pageIdValue: (lastItem && lastItem.id) || undefined,
      __pageValue:
        (lastItem && lastItem.UsersLikeMixtapes[0].createdAt) || undefined,
      popularChartLevel: 0,
      ...extraOptions,
    }

    const _mixtapeList = await this.network.mixtapeNetwork.getMixtapeList(
      options,
    )
    this.likedMixtapeData.isFinished =
      !_mixtapeList || !(_mixtapeList.length === 20)
    this.likedMixtapeData.list.push(
      ...((_mixtapeList
        && _mixtapeList.map(mixtape => new MixtapeModel(this.store, mixtape)))
        || []),
    )
    return this.likedMixtapeData
  }

  @action addMixtapeInLiveRoom = async mixtape => {
    if (
      !this.currLiveRoom
      || !this.currLiveRoom['id']
      || !mixtape
      || !mixtape['id']
    ) {
      return false
    }

    const mixtapeId = mixtape['id']
    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      { mixtapeId, songId: null },
    )
    this.currentPlayingLiveRoomAudio = null
    if (!updatedLiveRoom) {
      return false
    }

    this._playLiveRoomMixtape(
      mixtape,
      false,
      this.isMutedRemoteAll,
      this.isPersonalVolumeOn
        ? this.personalMixtapeVolume
        : this.currLiveRoom['mixtapeVolume'],
    )
    this._sendActionControlMixtape(mixtape['id'], CONTROL_MIXTAPE_TYPE['PLAY'])

    return true
  }

  @action addAudioInLiveRoom = async song => {
    if (
      !this.currLiveRoom
      || !this.currLiveRoom['id']
      || !song
      || !song['id']
    ) {
      return false
    }

    const songId = song['id']
    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      { songId, mixtapeId: null },
    )
    this.currentPlayingLiveRoomMixtape = null

    if (!updatedLiveRoom) {
      return false
    }

    this._sendActionControlAudio(
      song['id'],
      CONTROL_AUDIO_TYPE['PLAY'],
      updatedLiveRoom?.songStartedAt,
    )

    await this._playLiveRoomAudio(
      song,
      false,
      this.isMutedRemoteAll,
      this.currLiveRoom['mixtapeVolume'],
      updatedLiveRoom?.songStartedAt,
    )

    this.currLiveRoom['songStartedAt'] = updatedLiveRoom?.songStartedAt

    return true
  }

  @action _playLiveRoomAudio = async (
    song,
    isMutedMixtape = false,
    isMutedRemoteAll = false,
    mixtapeVolume = this.ON_VOICE_MIXTAPE_VOLUME,
    songStartedAt = null,
  ) => {
    IS_DEV
      && LiveRoomStoreLogger(
        `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
          + `[DEV][LOG][LiveRoomStore][_playLiveRoomAudio] mixtapeId=${song
            && song['id']}, curSongId=${this.currentPlayingLiveRoomAudio
            && this.currentPlayingLiveRoomAudio['id']}`,
      )

    if (
      !this.currLiveRoom
      || !this.currLiveRoom['id']
      || !song
      || !song['id']
    ) {
      return false
    }

    this.store.appStateStore.isLoading = true

    let res

    const liveRoomSong = await this.fetchLiveRoom(this.currLiveRoom?.id, {
      __attributes: 'songId,songStartedAt',
    })

    if (!liveRoomSong) {
      return false
    }

    let result

    if (liveRoomSong?.songId === song?.id) {
      result = await this.network.songNetwork.getSong(song['id'])
    }
    else {
      if (!liveRoomSong?.songId) {
        this.currentPlayingLiveRoomAudio = null
        this.currLiveRoom.songStartedAt = null
        return false
      }
      result = await this.network.songNetwork.getSong(liveRoomSong?.songId)
      songStartedAt = liveRoomSong?.songStartedAt
    }

    this.store.appStateStore.isLoading = false

    if (!result) {
      return false
    }

    const fetchedSong = new SongModel(this.store, result)
    if (fetchedSong) {
      if (fetchedSong['id'] && fetchedSong?.m3u8Uri) {
        // await stores.currentPlaySongStore.pauseMusic()
        await this.store.playerStore._stopPlayer()
        this.currentPlayingLiveRoomAudio = fetchedSong
        this.currLiveRoom.songStartedAt = songStartedAt
        this.currentPlayingLiveRoomMixtape = null

        if (!isMutedMixtape && !isMutedRemoteAll) {
          await this.store.playerStore.setVolume(mixtapeVolume)
          await this.store.playerStore.fetchLoadPlayLiveRoomAudio(fetchedSong)
          // this.store.currentPlaySongStore.isPlay = true

          res = true
        }

        if (res || isMutedMixtape || isMutedRemoteAll) {
          this.currLiveRoom['Song'] = fetchedSong
          this.currLiveRoom['isMutedMixtape'] = isMutedMixtape
          this.store.playerStore.setMuted(false)
        }
        else {
          this.currentPlayingLiveRoomAudio = null
        }
      }
    }

    this.store.appStateStore.isLoading = false
    return res
  }

  @action _playLiveRoomMixtape = async (
    mixtape,
    isMutedMixtape = false,
    isMutedRemoteAll = false,
    mixtapeVolume = this.ON_VOICE_MIXTAPE_VOLUME,
    isMutedOnlyMixtape = false,
  ) => {
    IS_DEV
      && LiveRoomStoreLogger(
        `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
          + `[DEV][LOG][LiveRoomStore][_playLiveRoomMixtape] mixtapeId=${mixtape
            && mixtape['id']}, curMixtapeId=${this.currentPlayingLiveRoomMixtape
            && this.currentPlayingLiveRoomMixtape['id']}`,
      )

    if (
      !this.currLiveRoom
      || !this.currLiveRoom['id']
      || !mixtape
      || !mixtape['id']
    ) {
      return false
    }

    this.store.appStateStore.isLoading = true

    let res
    /**
     * 흐름 곡 목록 동기화를 위함.
     */
    const fetchedMixtape = await this.network.mixtapeNetwork.getMixtape(
      mixtape['id'],
      {
        __include: 'MixtapesHaveSongs.Songs,Users',
        __addColumn: 'mixtapePlayedTime', // 현재 재생중인 곡 상태 트래킹위해 추가
      },
    )
    if (fetchedMixtape) {
      if (fetchedMixtape && fetchedMixtape['MixtapesHaveSongs']) {
        // await this.store.currentPlaySongStore.pauseMusic()
        await this.store.playerStore._stopPlayer()
        this.currentPlayingLiveRoomMixtape = new MixtapeModel(
          this.store,
          fetchedMixtape,
        )
        this.currentPlayingLiveRoomAudio = null

        // const songIdList =
        //   (fetchedMixtape['MixtapesHaveSongs']
        //     && fetchedMixtape['MixtapesHaveSongs']
        //       .map(elem => elem['songId'])
        //       .filter(elem => !!elem))
        //   || []

        /**
         * (로컬 재생), 흐름 뮤트 => 재생
         *  => (isMutedRemoteAll = false), [play] && isMutedMixtape = false
         * (로컬 뮤트), 흐름 뮤트 => 재생
         *  => (isMutedRemoteAll = true), isMutedMixtape = false
         * (흐름 재생), 로컬 뮤트 => 재생
         *  => (isMutedMixtape = false), [play] && isMutedRemoteAll = false
         * (흐름 뮤트), 로컬 뮤트 => 재생
         *  => (isMutedMixtape = true),  isMutedRemoteAll = false
         */
        if (!isMutedMixtape && !isMutedRemoteAll && !isMutedOnlyMixtape) {
          LiveRoomStoreLogger('_playLiveRoomMixtape 2')
          // res = await this.store.currentPlaySongStore.playSongs(songIdList, false, true, undefined, undefined, fetchedMixtape['id'])
          // res = await this.store.currentPlaySongStore.playMusic(
          //   0,
          //   0,
          //   true,
          //   undefined,
          //   fetchedMixtape['id'],
          //   true,
          //   mixtapeVolume,
          // )
          res = await this.store.playerStore.fetchLoadPlaySalonMixtape(
            fetchedMixtape,
            mixtapeVolume || 0,
          )
        }
        if (res || isMutedMixtape || isMutedRemoteAll || isMutedOnlyMixtape) {
          this.currLiveRoom['Mixtape'] = new MixtapeModel(
            this.store,
            fetchedMixtape,
          )
          this.currLiveRoom['isMutedMixtape'] = isMutedMixtape
          this.store.playerStore.setMuted(false)
          // this.store.mixtapeStore._updateLocalMixtapeData(fetchedMixtape['id'])
          // this.store.mixtapeStore.logPlayMixtape(
          //   this.currentPlayingLiveRoomMixtape,
          // )
        }
        else {
          this.currentPlayingLiveRoomMixtape = null
        }
      }
    }

    this.store.appStateStore.isLoading = false
    return res
  }

  @action setPlayLiveRoomMixtape = async mixtape => {
    if (!mixtape || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setPlayLiveRoomMixtape]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        isMutedMixtape: false,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setPlayLiveRoomMixtape]',
        'failed putLiveRoom.',
      )
      return
    }

    await this._playLiveRoomMixtape(
      mixtape,
      false,
      this.isMutedRemoteAll,
      this.currLiveRoom['mixtapeVolume'],
    )
    await this._sendActionControlMixtape(
      mixtape['id'],
      CONTROL_MIXTAPE_TYPE['PLAY'],
    )
  }

  @action setPlayLiveRoomAudio = async song => {
    if (!song || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setPlayLiveRoomAudio]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        isMutedMixtape: false,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setPlayLiveRoomAudio]',
        'failed putLiveRoom.',
      )
      return
    }
    await this._playLiveRoomAudio(
      song,
      false,
      this.isMutedRemoteAll,
      this.currLiveRoom['mixtapeVolume'],
      this.currLiveRoom['songStartedAt'],
    )

    await this._sendActionControlAudio(
      song['id'],
      CONTROL_MIXTAPE_TYPE['PLAY'],
      this.currLiveRoom['songStartedAt'],
    )
  }

  @action _stopLiveRoomMixtape = async (
    isMutedMixtape = true,
    isMutedRemoteAll = true,
    isLeave = false,
    isMutedOnlyMixtape = true,
  ) => {
    /**
     * (로컬 재생), 흐름 재생 => 뮤트
     *  => (isMutedRemoteAllMic = false), [stop] && isMutedMixtape = true
     * (로컬 뮤트), 흐름 재생 => 뮤트
     *  => (isMutedRemoteAllMic = false), isMutedMixtape = true
     * (흐름 재생), 로컬 재생 => 뮤트
     *  => (isMutedMixtape = false), [stop] && isMutedRemoteAllMic = true
     * (흐름 뮤트), 로컬 재생 => 뮤트
     *  => (isMutedMixtape = true), isMutedRemoteAllMic = true
     */

    if (this.store.playerStore) {
      if (!isMutedMixtape && !isMutedRemoteAll) {
        if (isLeave) {
          await this.store.playerStore.clear()
        }
        else if (!isLeave) {
          await this.store.playerStore._stopPlayer()
        }
      }
      if (!isMutedMixtape && isMutedRemoteAll && !isLeave) {
        await this.store.playerStore._stopPlayer()
      }
      if (isMutedMixtape && isLeave) {
        await this.store.playerStore.clear()
      }
      if (isMutedMixtape && !isLeave) {
        await this.store.playerStore._stopPlayer()
      }
    }

    if (this.currLiveRoom && isMutedMixtape) {
      this.currLiveRoom['isMutedMixtape'] = true
    }

    if (isMutedOnlyMixtape) {
      this.isMutedOnlyMixtape = true
    }
  }

  @action _stopLiveRoomAudio = async (
    isMutedMixtape = true,
    isMutedRemoteAll = true,
    isLeave = false,
  ) => {
    /**
     * (로컬 재생), 흐름 재생 => 뮤트
     *  => (isMutedRemoteAllMic = false), [stop] && isMutedMixtape = true
     * (로컬 뮤트), 흐름 재생 => 뮤트
     *  => (isMutedRemoteAllMic = false), isMutedMixtape = true
     * (흐름 재생), 로컬 재생 => 뮤트
     *  => (isMutedMixtape = false), [stop] && isMutedRemoteAllMic = true
     * (흐름 뮤트), 로컬 재생 => 뮤트
     *  => (isMutedMixtape = true), isMutedRemoteAllMic = true
     */

    if (this.store.playerStore) {
      if (!isMutedMixtape && !isMutedRemoteAll) {
        if (isLeave) {
          await this.store.playerStore.clear()
        }
        else if (!isLeave) {
          await this.store.playerStore._stopPlayer()
        }
      }
      if (!isMutedMixtape && isMutedRemoteAll && !isLeave) {
        await this.store.playerStore._stopPlayer()
      }
      if (isMutedMixtape && isLeave) {
        await this.store.playerStore.clear()
      }
      if (isMutedMixtape && !isLeave) {
        await this.store.playerStore._stopPlayer()
      }
    }

    if (this.currLiveRoom && isMutedMixtape) {
      this.currLiveRoom['isMutedMixtape'] = true
    }
  }

  @action setDeleteLiveRoomMixtape = async mixtape => {
    if (!mixtape || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setDeleteLiveRoomMixtape]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        mixtapeId: null,
      },
    )

    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setMuteLiveRoomMixtape]',
        'failed putLiveRoom.',
      )
      return
    }

    this.currentPlayingLiveRoomMixtape = null
    this.currLiveRoom['Mixtape'] = null

    await this._stopLiveRoomMixtape(true, true, false, this.isMutedOnlyMixtape)

    await this._sendActionControlMixtape(
      mixtape['id'],
      CONTROL_MIXTAPE_TYPE['DELETE'],
    )
  }

  @action setMuteLiveRoomAudio = async song => {
    if (!song || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setMuteLiveRoomAudio]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        isMutedMixtape: true,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setMuteLiveRoomAudio]',
        'failed putLiveRoom.',
      )
      return
    }

    await this._stopLiveRoomAudio(true, this.isMutedRemoteAll, false)
    await this._sendActionControlAudio(song['id'], CONTROL_AUDIO_TYPE['PAUSE'])
  }

  @action setDeleteLiveRoomAudio = async song => {
    if (!song || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setDeleteLiveRoomAudio]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        songId: null,
      },
    )

    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setDeleteLiveRoomAudio]',
        'failed putLiveRoom.',
      )
      return
    }

    this.currentPlayingLiveRoomAudio = null

    await this._stopLiveRoomAudio(true, true, false)

    await this._sendActionControlAudio(song['id'], CONTROL_AUDIO_TYPE['DELETE'])
  }

  @action setMuteLiveRoomMixtape = async mixtape => {
    if (!mixtape || !this.currLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setMuteLiveRoomMixtape]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        isMutedMixtape: true,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setMuteLiveRoomMixtape]',
        'failed putLiveRoom.',
      )
      return
    }

    await this._stopLiveRoomMixtape(true, this.isMutedRemoteAll)
    await this._sendActionControlMixtape(
      mixtape['id'],
      CONTROL_MIXTAPE_TYPE['PAUSE'],
    )
  }

  @action _setVolumeLiveRoomMixtape = async volume => {
    if (
      isNaN(parseInt(volume))
      || volume < 0
      || volume > 1
      || !this.currLiveRoom
    ) {
      console.error(
        '[LiveRoomStore]',
        '[_setVolumeLiveRoomMixtape]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    this.currLiveRoom['mixtapeVolume'] = volume

    // 로컬에서 직접 살롱 볼륨을 조절했을 경우 전체 볼륨 조절 영향 x
    if (this.store.playerStore.controlledLocalSalonVolume) return
    this.store.playerStore.setVolume(volume)
  }

  @action setVolumeLiveRoomMixtape = async (mixtape, volume) => {
    if (
      !mixtape
      || isNaN(parseInt(volume))
      || volume < 0
      || volume > 1
      || !this.currLiveRoom
    ) {
      console.error(
        '[LiveRoomStore]',
        '[setVolumeLiveRoomMixtape]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        mixtapeVolume: volume,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setVolumeLiveRoomMixtape]',
        'failed putLiveRoom.',
      )
      return
    }

    await this._setVolumeLiveRoomMixtape(volume)
    await this._sendActionControlMixtape(
      mixtape['id'],
      CONTROL_MIXTAPE_TYPE['VOLUME'],
      volume,
    )
  }

  @action setVolumeLiveRoomAudio = async (song, volume) => {
    if (
      !song
      || isNaN(parseInt(volume))
      || volume < 0
      || volume > 1
      || !this.currLiveRoom
    ) {
      console.error(
        '[LiveRoomStore]',
        '[setVolumeLiveRoomAudio]',
        'invalid args or no currLiveRoom.',
      )
      return
    }

    const updatedLiveRoom = await this.network.liveRoomNetwork.putLiveRoom(
      this.currLiveRoom['id'],
      {
        mixtapeVolume: volume,
      },
    )
    if (!updatedLiveRoom) {
      console.error(
        '[LiveRoomStore]',
        '[setVolumeLiveRoomAudio]',
        'failed putLiveRoom.',
      )
      return
    }

    await this._setVolumeLiveRoomMixtape(volume)
    await this._sendActionControlAudio(
      song['id'],
      CONTROL_AUDIO_TYPE['VOLUME'],
      null,
      volume,
    )
  }

  @action sendChat = async (
    user,
    message,
    messageType = LIVE_ROOM_MESSAGE_TYPE['CHAT'],
    data = {},
  ) => {
    if (!user || !message || !this.currLiveRoom || !this.currLiveRoomUser) {
      console.error('[LiveRoomStore]', '[sendChat]', 'required params')
      return
    }

    const res = await this._sendActionSendChat(user, message, messageType, data)
    if (res) {
      const liveRoomId = this.currLiveRoom['id']
      const chatMessage = message
      const userLevel = this.currLiveRoomUser['level']
      const userId = user['id']

      const linkUrl = linkify.match(chatMessage)
      this.addChatMessageLocal({
        liveRoomId,
        chatMessage,
        userLevel,
        userId,
        messageType,
        linkUrl: linkUrl ? linkUrl[0]?.url : null,
        data,
      })

      if (messageType === LIVE_ROOM_MESSAGE_TYPE['STAR']) {
        await this.store.starStore.getStarHistoriesFromLive(liveRoomId)
      }
    }
  }

  @action
  _startTimer = async () => {
    IS_DEV
      && LiveRoomStoreLogger(
        `[${moment().format('YYYY.MM.DD h:mm:ss')}]`
          + `[DEV][LOG][PurchaseStore][_startTimer]`,
      )
    const LIVE_ROOM_PING_INTERVAL_SEC = 10
    // const FREE_OWNER_MAINTAINABLE_SEC = 60 // 구독중이지 않은 오너의 방 유지 가능 시간 (2시간)
    // const NOTICE_REMAIN_TIME_SEC = 50 // 구독중이지 않은 오너의 방 종료까지 남은 시간 (10분)

    const pingLiveRoom = async () => {
      if (this.currLiveRoom) {
        /**
         * OWENER || HOST 일 때, post ping으로 owner가 나가도 liveRoom 유지
         * @author Copotter
         */
        if (
          this.store.authStore.isLogined()
          && this.currLiveRoomUser
          && (this.currLiveRoom['userId']
            === this.store.authStore.currentUser['id']
            || this.currLiveRoomUser['level']
              >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST'])
        ) {
          const updatedLiveRoom = await this.network.liveRoomNetwork.postLiveRoomPing(
            this.currLiveRoom['id'],
          )
          if (updatedLiveRoom) {
            /* eslint-disable */
            /**
             * 무료 유저 살롱 시간제한 체크 로직
             */

            if (
              moment().diff(moment(updatedLiveRoom?.openedAt), 'seconds') <
                this.FREE_OWNER_MAINTAINABLE_SEC &&
              moment().diff(moment(updatedLiveRoom?.openedAt), 'seconds') >=
                this.FREE_OWNER_MAINTAINABLE_SEC -
                  this.NOTICE_REMAIN_TIME_SEC &&
              this.currLiveRoomUser?.level ===
                LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER'] &&
              this.store.purchaseStore?.isLimitSalonTime &&
              !this.isNoticedEndSoonMessage
            ) {
              const remainTime = Math.round(
                (this.FREE_OWNER_MAINTAINABLE_SEC -
                  moment().diff(moment(updatedLiveRoom?.openedAt), 'seconds')) /
                  60,
              )

              if (
                this.store.purchaseStore?.myPlan?.adLevel !== 200 &&
                this.store.purchaseStore?.isLimitSalonTime
              ) {
                this.currLiveRoom.isShowPlanRequiredPopup = true
              }

              await this.sendChat(
                this.store.authStore?.currentUser,
                `살롱 이용시간이 ${remainTime}분 남았습니다.\n${remainTime}분 후 지금 이용중인 살롱이 자동 종료됩니다.`,
                LIVE_ROOM_MESSAGE_TYPE['END_SOON'],
                {
                  remainTime,
                },
              )

              this.isNoticedEndSoonMessage = true
            }

            if (
              moment().diff(moment(updatedLiveRoom?.openedAt), 'seconds') >
                this.FREE_OWNER_MAINTAINABLE_SEC &&
              this.currLiveRoomUser?.level ===
                LIVE_ROOMS_HAVE_USERS_LEVEL['OWNER'] &&
              this.store.purchaseStore?.isLimitSalonTime
            ) {
              const result = await this.finishLiveRoom(true)

              return
            }

            /* eslint-enable */
          }
        }
        else {
          // const curMoment = moment.utc()
          const fetchedLiveRoom = await this.network.liveRoomNetwork.getLiveRoom(
            this.currLiveRoom['id'],
            {
              __attributes: 'id,isBlockedUnknownUser,openedAt,userId',
              __addColumn: 'ownerPingState',
            },
          )
          if (
            fetchedLiveRoom
            && fetchedLiveRoom['isBlockedUnknownUser']
            && !this.store?.authStore?.currentUser?.['id']
          ) {
            await this.leaveLiveRoom()
            window.location.href = '/p/salon/list'
            alert('해당 콘텐츠는 로그인해야 청취 가능합니다')
            return
          }

          if (!this.currLiveRoom && fetchedLiveRoom)
            this.currLiveRoom = new LiveRoomModel(this.store, fetchedLiveRoom)

          const LIVE_ROOM_OWNER_PING_STATE = {
            PING_GRACE_PERIOD: 'PING_GRACE_PERIOD',
            PING_START_FINISH_ROOM: 'PING_START_FINISH_ROOM',
            PING_FINISH_ROOM: 'PING_FINISH_ROOM',
          }
          if (fetchedLiveRoom && fetchedLiveRoom['ownerPingState']) {
            switch (fetchedLiveRoom['ownerPingState']) {
              case LIVE_ROOM_OWNER_PING_STATE['PING_GRACE_PERIOD']:
                break
              case LIVE_ROOM_OWNER_PING_STATE['PING_START_FINISH_ROOM']:
                if (!this.isNoticedPingFinishRoom) {
                  this.liveRoomMessageList.push({
                    messageType: LIVE_ROOM_MESSAGE_TYPE['SYSTEM'],
                    message: `살롱을 만든 호스트의 연결이 끊겨 5분 후 살롱이 닫힙니다`,
                  })
                  this.isNoticedPingFinishRoom = true
                }
                break
              case LIVE_ROOM_OWNER_PING_STATE['PING_FINISH_ROOM']:
                await this.leaveLiveRoom(true)
                break
              default:
                break
            }
          }
        }
      }
    }

    // const syncLiveRoomUser = async () => {
    //   if (this.currLiveRoom) {
    //     if (this.store.authStore.isLogined()) {
    //       /**
    //        * OWENER || HOST 일 때, post ping으로 owner가 나가도 liveRoom 유지
    //        * @author Copotter
    //        */

    //       const fetchedLiveRoom = await this.network.liveRoomNetwork.getLiveRoom(
    //         this.currLiveRoom['id'],
    //         {
    //           __include: 'LiveRoomsHaveUsers',
    //           __attributes: 'id,LiveRoomsHaveUsers.userId',
    //         },
    //       )

    //       if (
    //         fetchedLiveRoom['LiveRoomsHaveUsers'].length
    //         < this.currLiveRoomUserList.length
    //       ) {
    //         this.currLiveRoomUserList = this.currLiveRoomUserList.filter(
    //           (val, index) =>
    //             val.userId
    //             === fetchedLiveRoom?.LiveRoomsHaveUsers[index]?.userId,
    //         )
    //       }
    //     }
    //   }
    // }

    // await syncLiveRoomUser()
    await pingLiveRoom()

    this.validateTimer = setInterval(() => {
      pingLiveRoom()
      // syncLiveRoomUser()
    }, LIVE_ROOM_PING_INTERVAL_SEC * 1000)
  }

  _clearTimer = () => {
    clearInterval(this.validateTimer)
  }

  @action hideReservationLiveRoom = async liveRoom => {
    try {
      const liveRoomId = liveRoom?.['id']
      if (!liveRoomId) {
        console.error('[LiveRoomStore][hideReservationLiveRoom] Require id.')
        return false
      }

      const result = await this.network.liveRoomNetwork.postLiveRoomReservationHide(
        liveRoomId,
      )
      if (!result || result.length === 0) {
        console.error('[LiveRoomStore][hideReservationLiveRoom] Failed hide.')
        return false
      }

      this.allReservationLiveRoomList = this.allReservationLiveRoomList.filter(
        _liveRoom => _liveRoom.id !== liveRoom.id,
      )

      return true
    }
    catch (error) {
      console.error('[LiveRoomStore][hideReservationLiveRoom] error', error)
    }
  }

  @action getCurrMicrophone = () => {
    const device = AgoraRtc.getCurrMicrophone()
    if (!device) {
      return false
    }

    this.currLiveRoomMicName = device
    return true
  }

  @action getMicrophoneDeviceList = async () => {
    try {
      const _deviceList = await AgoraRtc.getMicrophones()

      return _deviceList
    }
    catch (error) {
      console.error('[LiveRoomStore][hideReservationLiveRoom] error', error)
    }
  }

  @action changeCurrMic = async deviceId => {
    try {
      if (!deviceId) {
        console.error('[LiveRoomStore][changeCurrMic] Require params.')
        return false
      }

      await AgoraRtc.localAudioStream.setDevice(deviceId)

      return true
    }
    catch (error) {
      console.error('[LiveRoomStore][changeCurrMic] error', error)
      return false
    }
  }

  @action _receiveActionRequestHost = async ({
    level,
    liveRoomId,
    userId,
    senderName,
    senderId,
  }) => {
    try {
      console.log(
        '[LOG]',
        '[_receiveActionRequestHost]',
        '[RECEIVE_REQUEST_HOST]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      if (userId !== this.currLiveRoomUser['userId']) return

      this.store.appStateStore.onModal({
        type: 'confirm',
        payload: {
          title: '호스트 초대',
          body: `${senderName}님이\n살롱 호스트로 초대했습니다`,
          onConfirm: async () => {
            this._sendActionAcceptedHost({
              liveRoomId,
              userId: this.store.authStore.currentUser?.id,
              level,
              isAccepted: true,
              receiverName: this.store.authStore.currentUser?.name,
              senderId,
            })
          },
          onCancel: async () => {
            this._sendActionAcceptedHost({
              liveRoomId,
              userId: this.store.authStore.currentUser?.id,
              level,
              isAccepted: false,
              receiverName: this.store.authStore.currentUser?.name,
              senderId,
            })
          },
        },
      })

      return true
    }
    catch (error) {
      console.error('[LOG][_receiveActionJoinLiveRoom] error', error)
    }
  }

  @action _receiveActionRequestGuest = async ({
    level,
    liveRoomId,
    userId,
    senderName,
    senderId,
  }) => {
    try {
      console.log(
        '[LOG]',
        '[_receiveActionRequestGuest]',
        '[RECEIVE_REQUEST_GUEST]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      if (userId !== this.currLiveRoomUser['userId']) return
      this.store.appStateStore.onModal({
        type: 'confirm',
        payload: {
          title: '게스트 초대',
          body: `${senderName}님이\n살롱 게스트로 초대했습니다`,
          onConfirm: async () => {
            this._sendActionAcceptedGuest({
              liveRoomId,
              userId: this.store.authStore.currentUser?.id,
              level,
              isAccepted: true,
              receiverName: this.store.authStore.currentUser?.name,
              senderId,
            })
          },
          onCancel: async () => {
            this._sendActionAcceptedGuest({
              liveRoomId,
              userId: this.store.authStore.currentUser?.id,
              level,
              isAccepted: false,
              receiverName: this.store.authStore.currentUser?.name,
              senderId,
            })
          },
        },
      })

      return true
    }
    catch (error) {
      console.error('[LOG][_receiveActionJoinLiveRoom] error', error)
    }
  }

  /* rtm listener */
  /**
   * todo:
   * 각 액션 리스너에 store func 를 overriding 해서 사용
   */
  @action _receiveActionJoinLiveRoom = async ({ liveRoomId, userId }) => {
    try {
      LiveRoomStoreLogger(
        '[LOG]',
        '[_receiveActionJoinLiveRoom]',
        '[JOIN_LIVE_ROOM]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      const liveRoomsHaveUser = await this.network.liveRoomNetwork.getLiveRoomsHaveUsers(
        this.currLiveRoom['id'],
        userId,
        { __include: 'LiveRoomsHaveUsersAgoraTokens' },
      )

      if (!liveRoomsHaveUser || isEmptyObject(liveRoomsHaveUser)) {
        LiveRoomStoreLogger(
          '[LOG]',
          '[AgoraRtc]',
          '[event]',
          '[UserJoined] error: uid liveRoomsHaveUser is not exist',
        )
        return false
      }

      const isExistUser = this.currLiveRoomUserList.find(
        liveRoomUser => liveRoomUser['userId'] === userId,
      )

      const param = {
        ...liveRoomsHaveUser,
        User: new UserModel(this.store, liveRoomsHaveUser['User']),
      }
      const _user = new LiveRoomUserModel(this.store, param)

      if (isExistUser) {
        Object.keys(isExistUser).map(key => (isExistUser[key] = _user[key]))
        return false
      }

      /** 나갔다가 들어와도 기존 채팅 메시지 해당 유저와 싱크 추가 */
      // eslint-disable-next-line
      this.liveRoomMessageList.map(message => {
        if (message['LiveRoomUser']) {
          if (message['LiveRoomUser']['userId'] === _user['userId']) {
            message['LiveRoomUser'] = _user
          }
        }
      })

      this.currLiveRoomUserList.push(_user)
      this.liveRoomMessageList.push({
        messageType: LIVE_ROOM_MESSAGE_TYPE['SYSTEM'],
        message: `님이 참여했습니다`,
        LiveRoomUser: liveRoomsHaveUser,
      })

      /**
       * Sound Effect
       * - 살롱 참여 알림
       * - 호스트&오너만 들림
       * - 호스트&오너 온오프 기능
       * * callback에서 처리해야 동작함
       */
      if (
        this.currLiveRoomUser
        && this.currLiveRoomUser['level'] >= LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
        && !this.isMuteJoinLiveRoom
      ) {
        // web logic
        this.store.appStateStore.playSound(sounds.soundEffectJoinUser)

        // app logic
        // const sound = new Sound(soundEffectJoinUser, error => {
        //   if (error) {
        //     LiveRoomStoreLogger(
        //       '[LOG][LiveRoomStore][_receiveActionJoinLiveRoom][new Sound] error',
        //       error,
        //     )
        //   }
        //   else {
        //     // sound.setVolume(1)
        //     sound.play(() => sound.release()) // have to put the call to play() in the onload callback
        //   }
        // })
      }

      return true
    }
    catch (error) {
      console.error('[LOG][_receiveActionJoinLiveRoom] error', error)
    }
  }
  @action _receiveActionDeleteLiveRoom = async ({ liveRoomId }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionDeleteLiveRoom]',
      '[DELETE_LIVE_ROOM]',
      liveRoomId,
    )

    if (!this.currLiveRoom['id'] === liveRoomId) {
      return
    }
    this.finishLiveRoomLocal(liveRoomId, true, true)
  }
  @action _receiveActionUpdateLiveRoom = async ({
    liveRoomId,
    title,
    description,
    isPrivate,
    password,
    isMutedMic,
    mixtapeId,
    LiveRoomsHaveTags,
    imageUri,
    isBlockedUnknownUser,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionUpdateLiveRoom]',
      '[UPDATE_LIVE_ROOM]',
      liveRoomId,
      title,
      description,
      isPrivate,
      password,
      isMutedMic,
      mixtapeId,
      LiveRoomsHaveTags,
      imageUri,
      isBlockedUnknownUser,
    )

    this.updateLiveRoomLocal({
      liveRoomId,
      title,
      description,
      isPrivate,
      password,
      isMutedMic,
      mixtapeId,
      LiveRoomsHaveTags,
      imageUri,
      isBlockedUnknownUser,
    })
  }
  @action _receiveActionLeaveLiveRoom = async ({ liveRoomId, userId }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionLeaveLiveRoom]',
      '[LEAVE_LIVE_ROOM]',
      liveRoomId,
      userId,
    )
    await this._leaveLiveRoomOtherLocal(liveRoomId, userId)
  }
  @action _receiveActionDonateLiveRoom = async ({
    liveRoomId,
    userId,
    message,
    systemMessage,
    donatedAt,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionDonateLiveRoom]',
      '[DONATE_LIVE_ROOM]',
      liveRoomId,
      userId,
      message,
      systemMessage,
      donatedAt,
    )
  }
  @action _receiveActionUpdateLiveRoomUser = async ({
    liveRoomId,
    userId,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionUpdateLiveRoomUser]',
      '[UPDATE_LIVE_ROOM_USER]',
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    )
    if (this.currLiveRoomUser['userId'] === userId) {
      this.updateLevelFromRemote(level, prevLevel)
    }

    await this.updateLiveRoomUserLocal({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    })
  }
  @action _receiveActionBanLiveRoomUser = async ({ liveRoomId, userId }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionBanLiveRoomUser]',
      '[BAN_LIVE_ROOM_USER]',
      liveRoomId,
      userId,
    )
    if (
      this.currLiveRoom
      && this.currLiveRoom['id'] === liveRoomId
      && this.store.authStore.currentUser
      && this.store.authStore.currentUser['id'] === userId
    ) {
      await this._leaveLiveRoom(true)
    }
    else if (
      this.currLiveRoom
      && this.currLiveRoom['id'] === liveRoomId
      && this.store.authStore.currentUser
      && this.store.authStore.currentUser['id'] !== userId
    ) {
      await this._leaveLiveRoomOtherLocal(liveRoomId, userId)
    }
  }
  @action _receiveActionSendChat = async ({
    liveRoomId,
    chatMessage,
    userLevel,
    userId,
    messageType,
    imageUri,
    data = {},
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionSendChat]',
      '[SEND_CHAT]',
      liveRoomId,
      chatMessage,
      userLevel,
      userId,
      messageType,
      imageUri,
      data,
    )

    if (imageUri) {
      const image = new Image()
      image.src = STORAGE_URL + imageUri
      image.onload = () => {
        if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
          const imageSize = {
            height: 0,
            width: 0,
          }
          if (image.height > image.width) {
            imageSize.height = 280
            imageSize.width = (image.width / image.height) * 280
          }
          else {
            imageSize.width = 280
            imageSize.height = (image.height / image.width) * 280
          }

          this.addChatMessageLocal({
            liveRoomId,
            chatMessage,
            userLevel,
            userId,
            messageType,
            imageUri,
            imageSize,
          })
        }
      }
    }
    else if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
      const linkUrl = linkify.match(chatMessage)
      this.addChatMessageLocal({
        liveRoomId,
        chatMessage,
        userLevel,
        userId,
        messageType,
        imageUri,
        data,
        linkUrl: linkUrl ? linkUrl[0]?.url : null,
      })

      if (messageType === LIVE_ROOM_MESSAGE_TYPE['STAR']) {
        await this.store.starStore.getStarHistoriesFromLive(liveRoomId)
      }
    }
  }

  @action _receiveActionSendChatFixed = async ({
    liveRoomId,
    chatMessage,
    userId,
  }) => {
    console.log(
      '[LOG]',
      '[_receiveActionSendChat]',
      '[SEND_CHAT_FIXED]',
      liveRoomId,
      chatMessage,
      userId,
    )

    if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
      const params = {
        __include: 'FixedCommentUsers',
        __attributes: 'id,fixedComment,fixedCommentUserId',
      }

      const fixedUser = await this.network.liveRoomNetwork.getLiveRoom(
        this.currLiveRoom?.id,
        params,
      )

      if (fixedUser?.response?.status === 404) {
        console.log('[receiveActionSendChatFixed Error]', 'liveRoom not exist')
        return
      }

      this.currLiveRoom.fixedComment = chatMessage
      this.currLiveRoom.fixedCommentUserId = userId
      this.currLiveRoom.FixedCommentUser = new UserModel(
        this.store,
        fixedUser.FixedCommentUser,
      )

      this.isShowFixedComment = true
    }
  }

  @action _receiveActionSendChatFixedDelete = async ({ liveRoomId }) => {
    console.log(
      '[LOG]',
      '[_receiveActionSendChat]',
      '[SEND_CHAT_FIXED_DELETE]',
      liveRoomId,
    )

    if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
      this.currLiveRoom.fixedComment = null
      this.currLiveRoom.fixedCommentUserId = null
      this.currLiveRoom.FixedCommentUser = null
    }
  }

  @action _receiveActionLiveRoomRecording = async ({
    liveRoomRecording = null,
    isStop = false,
  }) => {
    console.log(
      '[LOG]',
      '[_receiveActionLiveRoomRecording]',
      '[LIVE_ROOM_RECORDING]',
      liveRoomRecording,
    )

    if (
      this.currLiveRoom
      && liveRoomRecording
      && this.currLiveRoom['id'] === liveRoomRecording['liveRoomId']
      && !isStop
    ) {
      this.store.liveRoomRecordingStore.currLiveRoomRecording = liveRoomRecording

      this.liveRoomMessageList.push({
        messageType: LIVE_ROOM_MESSAGE_TYPE['RECORDING_START'],
        message: `녹음이 시작되었습니다.`,
        LiveRoomUser: liveRoomRecording['LiveRoomUser'],
      })
    }
    else {
      this.store.liveRoomRecordingStore.currLiveRoomRecording = null

      this.liveRoomMessageList.push({
        messageType: LIVE_ROOM_MESSAGE_TYPE['RECORDING_END'],
        message: `녹음이 되었습니다.`,
        LiveRoomUser: liveRoomRecording['LiveRoomUser'],
        Audio: liveRoomRecording,
      })
    }
  }

  @action _receiveActionControlMixtape = async ({
    liveRoomId,
    mixtapeId,
    controlType,
    volume,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionControlMixtape]',
      '[CONTROL_MIXTAPE]',
      liveRoomId,
      mixtapeId,
      controlType,
      volume,
    )
    if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
      switch (controlType) {
        case CONTROL_MIXTAPE_TYPE['PLAY']:
          if (mixtapeId) {
            await this._playLiveRoomMixtape(
              { id: mixtapeId },
              false,
              this.isMutedRemoteAll,
              this.isPersonalVolumeOn
                ? this.personalMixtapeVolume
                : this.currLiveRoom['mixtapeVolume'],
            )
          }
          break
        case CONTROL_MIXTAPE_TYPE['PAUSE']:
          if (
            this.currLiveRoomUser.level < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
          ) {
            this.currentPlayingLiveRoomMixtape = null
          }
          await this._stopLiveRoomMixtape(true)
          break
        case CONTROL_MIXTAPE_TYPE['DELETE']:
          this.currentPlayingLiveRoomMixtape = null

          await this._stopLiveRoomMixtape(
            true,
            true,
            false,
            this.isMutedOnlyMixtape,
          )
          break
        case CONTROL_MIXTAPE_TYPE['VOLUME']:
          if (this.isPersonalVolumeOn) {
            this.currLiveRoom['mixtapeVolume'] = volume
            break
          }
          await this._setVolumeLiveRoomMixtape(volume)
          break
        default:
          break
      }
    }
  }

  @action _receiveActionControlAudio = async ({
    liveRoomId,
    songId,
    controlType,
    startedAt,
    volume,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionControlAudio]',
      '[CONTROL_AUDIO]',
      liveRoomId,
      songId,
      controlType,
      startedAt,
      volume,
    )
    if (this.currLiveRoom && liveRoomId === this.currLiveRoom['id']) {
      switch (controlType) {
        case CONTROL_AUDIO_TYPE['PLAY']:
          if (songId) {
            await this._playLiveRoomAudio(
              { id: songId },
              false,
              this.isMutedRemoteAll,
              this.currLiveRoom['mixtapeVolume'],
              startedAt,
            )
          }
          break
        case CONTROL_AUDIO_TYPE['PAUSE']:
          if (
            this.currLiveRoomUser.level < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']
          ) {
            this.currentPlayingLiveRoomAudio = null
          }
          await this._stopLiveRoomAudio(true, true, false)
          break
        case CONTROL_AUDIO_TYPE['DELETE']:
          this.currentPlayingLiveRoomAudio = null

          await this._stopLiveRoomAudio(true, true, false)
          break
        case CONTROL_AUDIO_TYPE['VOLUME']:
          await this._setVolumeLiveRoomMixtape(volume)
          break
        default:
          break
      }
    }
  }

  @action _receiveActionMuteAllMic = async ({ liveRoomId }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionMuteAllMic]',
      '[MUTE_ALL_MIC]',
      liveRoomId,
    )

    if (liveRoomId) {
      await this.muteAllMicLocal()
    }
  }

  @action _receiveActionPreventAccessMultiple = async ({
    liveRoomId,
    userId,
  }) => {
    try {
      LiveRoomStoreLogger(
        '[LOG]',
        '[_receiveActionPreventAccessMultiple]',
        '[JOIN_LIVE_ROOM]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      if (
        this.currLiveRoomUser
        && this.currLiveRoomUser['userId']
        && userId === this.currLiveRoomUser['userId']
      ) {
        try {
          await this._leaveLiveRoom(true)
          this._clearTimer()
        }
        catch (error) {
          LiveRoomStoreLogger('LiveRoomStore leaveLiveRoom error', error)
        }

        alert('다른곳에서 같은 아이디로 살롱을 접속하여 해당 접속이 종료됩니다')
      }

      return true
    }
    catch (error) {
      console.error('_receiveActionPreventAccessMultiple error', error)
    }
  }
  /* rtm listener end */

  /* rtm sender */
  @action _sendActionJoinLiveRoom = async ({ liveRoomId, userId }) => {
    const result = await LIVE_ROOM_ACTION['JOIN_LIVE_ROOM']['SENDER']({
      liveRoomId,
      userId,
    })
    return result
  }
  @action _sendActionDeleteLiveRoom = async ({ liveRoomId }) => {
    const result = await LIVE_ROOM_ACTION['DELETE_LIVE_ROOM']['SENDER']({
      liveRoomId,
    })
    return result
  }
  @action _sendActionUpdateLiveRoom = async ({
    liveRoomId,
    title,
    description,
    isPrivate,
    password,
    isMutedMic,
    mixtapeId,
    LiveRoomsHaveTags,
    imageUri,
    isBlockedUnknownUser,
  }) => {
    const result = await LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM']['SENDER']({
      liveRoomId,
      title,
      description,
      isPrivate,
      password,
      isMutedMic,
      mixtapeId,
      LiveRoomsHaveTags,
      imageUri,
      isBlockedUnknownUser,
    })
    return result
  }
  @action _sendActionLeaveLiveRoom = async ({ liveRoomId, userId }) => {
    const result = LIVE_ROOM_ACTION['LEAVE_LIVE_ROOM']['SENDER']({
      liveRoomId,
      userId,
    })
    return result
  }
  @action _sendActionDonateLiveRoom = async ({
    liveRoomId,
    userId,
    message,
    systemMessage,
    donatedAt,
  }) => {
    const result = await LIVE_ROOM_ACTION['DONATE_LIVE_ROOM']['SENDER']({
      liveRoomId,
      userId,
      message,
      systemMessage,
      donatedAt,
    })
    return result
  }
  @action _sendActionUpdateLiveRoomUser = async ({
    liveRoomId,
    userId,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
  }) => {
    const result = await LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM_USER']['SENDER']({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    })
    return result
  }
  @action _sendActionBanLiveRoomUser = async user => {
    if (!user || !this.currLiveRoom) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']
    const userId = user['id']

    const result = await LIVE_ROOM_ACTION['BAN_LIVE_ROOM_USER']['SENDER']({
      liveRoomId,
      userId,
    })
    return result
  }
  @action _sendActionSendChat = async (
    user,
    message,
    messageType = LIVE_ROOM_MESSAGE_TYPE['CHAT'],
    data = {},
  ) => {
    if (!user || !message || !this.currLiveRoom || !this.currLiveRoomUser) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']
    const chatMessage = message
    // const userName = user['name']
    // const userImageUri = user['imageUri']

    const userLevel = this.currLiveRoomUser['level']
    const userId = user['id']

    const result = await LIVE_ROOM_ACTION['SEND_CHAT']['SENDER']({
      liveRoomId,
      chatMessage,
      userLevel,
      userId,
      messageType,
      data,
    })
    return result
  }

  @action _sendActionSendChatFixed = async () => {
    if (
      !this.currLiveRoom?.['fixedComment']
      || !this.currLiveRoom
      || !this.currLiveRoomUser
    ) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']
    const chatMessage = this.currLiveRoom?.['fixedComment']

    const userId = this.currLiveRoom?.fixedCommentUserId

    const result = await LIVE_ROOM_ACTION['SEND_CHAT_FIXED']['SENDER']({
      liveRoomId,
      chatMessage,
      userId,
    })

    return result
  }
  @action _sendActionSendChatFixedDelete = async () => {
    if (!this.currLiveRoom) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']

    const result = await LIVE_ROOM_ACTION['SEND_CHAT_FIXED_DELETE']['SENDER']({
      liveRoomId,
    })

    return result
  }

  @action _sendActionLiveRoomRecording = async ({
    liveRoomRecording = null,
    isStop = false,
  }) => {
    const result = await LIVE_ROOM_ACTION['LIVE_ROOM_RECORDING']['SENDER']({
      liveRoomRecording,
      isStop,
    })

    /**
     * after sync action
     * - send, receive 유저들 공통으로 변경되는 동작
     */
    if (!isStop) {
      this.liveRoomMessageList.push({
        messageType: LIVE_ROOM_MESSAGE_TYPE['RECORDING_START'],
        message: `녹음이 시작되었습니다.`,
        LiveRoomUser: liveRoomRecording['LiveRoomUser'],
      })
    }
    else {
      this.liveRoomMessageList.push({
        messageType: LIVE_ROOM_MESSAGE_TYPE['RECORDING_END'],
        message: `녹음이 종료되었습니다.`,
        LiveRoomUser: liveRoomRecording['LiveRoomUser'],
        Audio: liveRoomRecording,
      })
    }

    return result
  }

  @action _sendActionControlAudio = async (
    songId,
    controlType,
    startedAt,
    volume,
  ) => {
    if (!songId || !controlType || !this.currLiveRoom) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']

    const result = await LIVE_ROOM_ACTION['CONTROL_AUDIO']['SENDER']({
      liveRoomId,
      songId,
      controlType,
      startedAt,
      volume,
    })
    return result
  }

  @action _sendActionControlMixtape = async (
    mixtapeId,
    controlType,
    volume,
  ) => {
    if (!mixtapeId || !controlType || !this.currLiveRoom) {
      console.error('required params')
      return
    }

    const liveRoomId = this.currLiveRoom['id']

    const result = await LIVE_ROOM_ACTION['CONTROL_MIXTAPE']['SENDER']({
      liveRoomId,
      mixtapeId,
      controlType,
      volume,
    })
    return result
  }
  @action _sendActionMuteAllMic = async liveRoomId => {
    if (!liveRoomId) {
      console.error('required params')
      return false
    }

    const result = await LIVE_ROOM_ACTION['MUTE_ALL_MIC']['SENDER']({
      liveRoomId,
    })
    return result
  }
  @action _sendActionPreventAccessMultiple = async (
    agoraUid,
    liveRoomId,
    userId,
  ) => {
    if (!agoraUid || !liveRoomId || !userId) {
      console.error('required params')
      return false
    }

    const result = await LIVE_ROOM_ACTION['PREVENT_ACCESS_MULTIPLE']['SENDER'](
      agoraUid,
      {
        liveRoomId,
        userId,
      },
    )
    return result
  }
  /* rtm sender end */

  final = async () => {
    if (this.currLiveRoom) {
      await this.leaveLiveRoom()
    }
    this.init()
  }

  /**
   * 유저들을 일괄 상태 업데이트 시킬 때, ( 현재 여러명 게스트 신청 삭제 로직에 사용 )
   * 현재 level 변경만 가능 ( level 상향 시, 에러 코드 필요 )
   * @author Copotter
   */
  @action.bound
  updateLiveRoomUserList = async ({ level, prevLevel, userIdList }) => {
    if (!userIdList) return
    return this.store.useLoading(async () => {
      const res = await this.network.liveRoomNetwork.putLiveRoomsHaveUsersList(
        this.currLiveRoom['id'],
        {
          level, // target level
          options: {
            where: {
              userIdList, // target userIdList
              level: prevLevel, // target users prevLevel
            },
          },
        },
      )

      // 추후, 레벨 상향 로직 추가시
      // if (!res || !userIdList) {
      //   const errorCode = res['errorCode']
      //   if (errorCode === ERROR_CODE['LIVE_ROOMS_EXCEED_MIC_USER']) {
      //     alert('최대 게스트 및 호스트 숫자를 초과하였습니다')
      //   }
      //   return false
      // }

      this._sendActionUpdateLiveRoomUserList(userIdList, {
        level,
        prevLevel,
        liveRoomId: this.currLiveRoom['id'],
      })
      userIdList.map(userId =>
        this.updateLiveRoomUserLocal({
          level,
          prevLevel,
          userId,
          liveRoomId: this.currLiveRoom['id'],
        }),
      )

      if (!res) return
      return res
    })
  }

  @action _sendActionUpdateLiveRoomUserList = async (
    userIdList,
    {
      liveRoomId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    },
  ) => {
    const result = await LIVE_ROOM_ACTION['UPDATE_LIVE_ROOM_USER_LIST'][
      'SENDER'
    ](userIdList, {
      liveRoomId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    })
    return result
  }

  @action _receiveActionUpdateLiveRoomUserList = async ({
    liveRoomId,
    userIdList,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
  }) => {
    LiveRoomStoreLogger(
      '[LOG]',
      '[_receiveActionUpdateLiveRoomUserList]',
      '[UPDATE_LIVE_ROOM_USER_LIST]',
      liveRoomId,
      userIdList,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
    )

    if (
      userIdList.filter(userId => userId === this.currLiveRoomUser['userId'])
        .length > 0
    ) {
      // prevLevel(게스트 신청 레벨)에서 하향 시, 피드백 x => 피드백 없는 거절 로직
      if (prevLevel !== LIVE_ROOMS_HAVE_USERS_LEVEL['REQUEST_GUEST']) {
        this.updateLevelFromRemote(level, prevLevel)
      }
    }

    userIdList.map(userId =>
      this.updateLiveRoomUserLocal({
        liveRoomId,
        userId,
        level,
        levelUpdatedAt,
        prevLevel,
        isMutedMic,
        isChangedUserInfo,
      }),
    )
  }

  @action.bound
  getDevicesPermission = async (role = 'Listener') => {
    const res = await AgoraRtc.getMicrophones()
    const _device = res && res[0]
    if (_device) {
      this.agoraDevice = _device
      return true
    }
    if (!_device) {
      this.agoraDevice = null
      if (role === 'Broadcaster') {
        alert(
          '마이크 장치가 없거나, 권한이 거부되었습니다.\nPC에서 오너, 호스트는 마이크 장치가 있어야 입장이 가능합니다.',
        )
      }
      else {
        alert('마이크 장치가 없거나, 권한이 거부되었습니다.')
      }
      return false
    }
  }

  @action.bound
  reserveJoinLiveRoom = async (liveRoom, reserve = true) => {
    // 비회원 클릭 시, 로그인 유도
    const isAuth = this.store.authStore.tryAuth()
    if (!isAuth) return

    return this.store.useLoading(async () => {
      let res
      if (reserve) {
        res = await this.network.liveRoomNetwork.postLiveRoomReserve(
          liveRoom['id'],
        )
        if (!res) return
        liveRoom.UsersReserveLiveRooms = [
          { userId: this.store.authStore.currentUser['id'] },
        ]
      }
      else {
        res = await this.network.liveRoomNetwork.deleteLiveRoomReserve(
          liveRoom['id'],
        )
        if (!res) return
        liveRoom.UsersReserveLiveRooms = []
      }

      return res
    })
  }

  @action.bound
  fetchLiveRoomDetail = (liveRoomId, isMobile = false) => {
    return this.store.useLoading(async () => {
      const param = {
        __include:
          'Users,LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags,LiveRoomsHaveUsers.LiveRoomsHaveUsersAgoraTokens,UsersReserveLiveRooms',
        'UsersReserveLiveRooms.userId': this.store.authStore?.currentUser?.id, // 내 userId',
        __attributes:
          'Users.name,Users.id,Users.account,Users.imageUri,Users.profileColor,UsersReserveLiveRooms.userId',
      }
      const liveRoom = await this.network.liveRoomNetwork.getLiveRoom(
        liveRoomId,
        param,
      )

      if (isMobile) {
        this.liveRoomDetail = new LiveRoomModel(this.store, liveRoom)
        return
      }
      if (!liveRoom || isEmptyObject(liveRoom)) {
        alert('존재하지 않는 살롱입니다.')
        window.location.href = '/p/salon/list'
        LiveRoomStoreLogger('[joinLiveRoom error] Room is not exist')
        return false
      }
      this.currLiveRoom = new LiveRoomModel(this.store, liveRoom)
    })
  }
  @action.bound
  fetchLiveRoomDetailFromLinkKey = (liveRoomLinkKey, isMobile = false) => {
    return this.store.useLoading(async () => {
      const param = {
        __include:
          'Users,LiveRoomsHaveUsers.Users,LiveRoomsHaveTags.Tags,LiveRoomsHaveUsers.LiveRoomsHaveUsersAgoraTokens,UsersReserveLiveRooms',
        'UsersReserveLiveRooms.userId': this.store.authStore?.currentUser?.id, // 내 userId',
        __attributes:
          'Users.name,Users.id,Users.account,Users.imageUri,Users.profileColor,UsersReserveLiveRooms.userId',
      }
      const liveRoom = await this.network.liveRoomNetwork.getLiveRoomFromLinkKey(
        liveRoomLinkKey,
        param,
      )

      if (isMobile) {
        this.liveRoomDetail = new LiveRoomModel(this.store, liveRoom)
        return
      }
      if (!liveRoom || isEmptyObject(liveRoom)) {
        alert('존재하지 않는 살롱입니다.')
        window.location.href = '/p/salon/list'
        LiveRoomStoreLogger('[joinLiveRoom error] Room is not exist')
        return false
      }
      this.currLiveRoom = new LiveRoomModel(this.store, liveRoom)
    })
  }

  @action fetchAllReservationLiveRoomList = async (nextCursor = null) => {
    return this.store.useLoading(async () => {
      try {
        /* 내 살롱과 기본 살롱 order 및 UI 변동 가능성으로 따로 fetch */
        /* 내 살롱 */
        const myLiveRoomList = []
        if (!nextCursor && this.store.authStore.isLogined()) {
          /* include 속도 고려해서 hasMany include 는 따로 fetch */
          /* LiveRooms.LiveRoomsHaveUsers */
          const myLiveRoomList1 = await this.network.liveRoomNetwork.getLiveRoomList(
            {
              userId: this.store.authStore.currentUser['id'],
              __attributes: `isHouseMember,id,linkKey,title,description,isPrivate,password,isSocial,isReserved,reservedOpenedAt,userId,createdAt,deletedAt,LiveRoomsHaveUsers.id,LiveRoomsHaveUsers.level,LiveRoomsHaveUsers.Users.id,LiveRoomsHaveUsers.Users.account,LiveRoomsHaveUsers.Users.imageUri,LiveRoomsHaveUsers.Users.name,LiveRoomsHaveUsers.Users.profileColor`,
              __include:
                'LiveRoomsHaveUsers.Users,Users,UsersReserveLiveRooms,Houses',
              __order: 'reservedOpenedAtAsc',
              isOpened: 0,
              isReserved: 1,
            },
          )

          /* LiveRooms.LiveRoomsHaveTags */
          const myLiveRoomList2 = await this.network.liveRoomNetwork.getLiveRoomList(
            {
              userId: this.store.authStore.currentUser['id'],
              __include: 'LiveRoomsHaveTags.Tags',
              __order: 'reservedOpenedAtAsc',
              isOpened: 0,
              isReserved: 1,
            },
          )

          myLiveRoomList1.map(liveRoom => {
            const _index = myLiveRoomList2.findIndex(_liveRoom => {
              return liveRoom['id'] === _liveRoom['id']
            })
            if (_index > -1) {
              liveRoom = { ...liveRoom, ...myLiveRoomList2[_index] }
              myLiveRoomList.push(liveRoom)
            }
            return true
          })
        }

        /* 기본 살롱 리스트 */
        /* include 속도 고려해서 hasMany include 는 따로 fetch */
        /* LiveRooms.LiveRoomsHaveUsers */

        let liveRoomList = []
        const {
          liveRoomList: liveRoomList1,
          __nextCursor,
        } = await this.network.liveRoomNetwork.getRecommendationsReservedLiveRoom(
          { __nextCursor: nextCursor },
        )

        // const liveRoomList11 = await this.network.liveRoomNetwork.getLiveRoomList(
        //   {
        //     // id: myLiveRoomList
        //     userId: this.store.authStore.currentUser?.['id'] || null,
        //     userIdOperator: 'not',
        //     __attributes: `id,linkKey,title,description,isPrivate,password,isSocial,isReserved,reservedOpenedAt,userId,createdAt,deletedAt,LiveRoomsHaveUsers.id,LiveRoomsHaveUsers.level,LiveRoomsHaveUsers.Users.id,LiveRoomsHaveUsers.Users.account,LiveRoomsHaveUsers.Users.imageUri,LiveRoomsHaveUsers.Users.name,LiveRoomsHaveUsers.Users.profileColor,UsersFollowedUsers.userId`,
        //     __include:
        //       'LiveRoomsHaveUsers.Users.UsersFollowedUsers,Users,UsersReserveLiveRooms',
        //     'LiveRoomsHaveUsers.Users.UsersFollowedUsers.userId': this.store
        //       .authStore?.currentUser?.id,
        //     // isPrivate: 0,
        //     // __order:
        //     // 'LiveRoomsHaveUsers.Users.UsersFollowedUsers.userIdDesc,userCountDesc,createdAtAsc,idAsc',
        //     __order: 'reservedOpenedAtAsc',
        //     isOpened: 0,
        //     isReserved: 1,
        //   },
        // )
        if (Array.isArray(liveRoomList1)) {
          /* LiveRooms.LiveRoomsHaveTags */
          // const liveRoomList2 = await this.network.liveRoomNetwork.getLiveRoomList(
          //   {
          //     id: liveRoomList1.map(liveRoom => liveRoom['id']).join(','),
          //     idOperator: 'in',
          //     __include: 'LiveRoomsHaveTags.Tags',
          //     isOpened: 0,
          //     isReserved: 1,
          //   },
          // )

          liveRoomList = liveRoomList1
          // if (Array.isArray(liveRoomList2)) {
          //   liveRoomList2.map(liveRoom2 => {
          //     const index = liveRoomList.findIndex(
          //       liveRoom => liveRoom['id'] === liveRoom2['id'],
          //     )
          //     liveRoomList[index] = { ...liveRoomList[index], ...liveRoom2 }
          //     return true
          //   })
          // }
        }

        if (!liveRoomList) {
          console.log('LiveRoomStore fetchAllReservationLiveRoomList undefined')
          return
        }

        const _mergedLiveRoomList = [...myLiveRoomList, ...liveRoomList]
        if (nextCursor) {
          this.allReservationLiveRoomList.push(
            ..._mergedLiveRoomList.map(
              liveRoom => new LiveRoomModel(this.store, liveRoom),
            ),
          )
        }
        else {
          this.allReservationLiveRoomList = _mergedLiveRoomList.map(
            liveRoom => new LiveRoomModel(this.store, liveRoom),
          )
        }
        return __nextCursor === 'null' ? null : __nextCursor
      }
      catch (error) {
        console.log(
          'LiveRoomStore fetchAllReservationLiveRoomList error',
          error,
        )
      }
    })
  }

  @action fixedComment = async chat => {
    /**
     * 채팅 고정
     */
    const { User } = this.currLiveRoomUser
    if (!this.currLiveRoom || !this.currLiveRoomUser || !User) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const result = await this.network.liveRoomNetwork.putFixedComment(
      this.currLiveRoom?.id,
      { content: chat?.chatMessage, userId: chat?.LiveRoomUser?.userId },
    )

    const params = {
      __include: 'FixedCommentUsers',
      __attributes: 'id,fixedComment,fixedCommentUserId',
    }

    const fixedUser = await this.network.liveRoomNetwork.getLiveRoom(
      this.currLiveRoom?.id,
      params,
    )

    if (fixedUser?.response?.status === 404) {
      return false
    }

    if (result) {
      this.currLiveRoom.fixedComment = chat?.chatMessage
      this.currLiveRoom.fixedCommentUserId = chat?.LiveRoomUser?.userId
      this.currLiveRoom.FixedCommentUser = new UserModel(
        this.store,
        fixedUser.FixedCommentUser,
      )

      this.isShowFixedComment = true

      await this._sendActionSendChatFixed()
      return true
    }
    return false
  }

  @action deleteFixedComment = async () => {
    /**
     * 채팅 고정 취소
     */
    if (!this.currLiveRoom) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const result = await this.network.liveRoomNetwork.deleteFixedComment(
      this.currLiveRoom?.id,
    )

    if (result) {
      this.currLiveRoom.fixedComment = null
      this.currLiveRoom.fixedCommentUserId = null
      this.currLiveRoom.FixedCommentUser = null

      await this._sendActionSendChatFixedDelete()
      return true
    }
    return false
  }

  // 살롱 평가하기
  // /live-rooms/:liveRoomId/survays
  @action survaysLiveRoom = async (liveRoomId, rating) => {
    const body = {
      rating,
    }

    const result = await this.network.liveRoomNetwork.postSurvaysSalon(
      liveRoomId,
      body,
    )

    return result
  }

  @action.bound
  deleteLiveroomMessage = async idx => {
    const message = this.liveRoomMessageList[idx]
    if (message) {
      this.liveRoomMessageList = this.liveRoomMessageList.filter(
        item => item !== message,
      )
    }
  }

  @action requestToHost = async user => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => BroadCaster
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser || !user) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const option = {
      ...user,
      ...this.currLiveRoom,
      senderName: this.store.authStore.currentUser?.name,
      senderId: this.store.authStore.currentUser?.id,
      userId: user.id,
      liveRoomId: this.currLiveRoom.id,
    }

    this._sendActionRequestHost(option)

    return true
  }

  @action requestToGuest = async user => {
    /**
     * 1. 서버 유저 레벨 업데이트
     *  - 서버 업데이트
     *  - rtm 발송
     * 2. rtc 상태 변경 Listener => Guest
     */
    if (!this.currLiveRoom || !this.currLiveRoomUser || !user) {
      console.error('[acceptToGuest] required params')
      return false
    }

    if (this.currLiveRoomUser['level'] < LIVE_ROOMS_HAVE_USERS_LEVEL['HOST']) {
      console.error('this user is not auth')
      return false
    }

    const option = {
      ...user,
      ...this.currLiveRoom,
      senderName: this.store.authStore.currentUser?.name,
      senderId: this.store.authStore.currentUser?.id,
      userId: user.id,
      liveRoomId: this.currLiveRoom.id,
    }

    this._sendActionRequestGuest(option)

    return true
  }

  @action _sendActionRequestGuest = async ({
    liveRoomId,
    userId,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
    senderName,
    senderId,
  }) => {
    const result = await LIVE_ROOM_ACTION['SEND_REQUEST_GUEST']['SENDER']({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    })
    return result
  }

  @action _sendActionAcceptedGuest = async ({
    liveRoomId,
    userId,
    level,
    isAccepted,
    receiverName,
    senderId,
  }) => {
    const result = await LIVE_ROOM_ACTION['SEND_ACCEPTED_GUEST']['SENDER']({
      liveRoomId,
      userId,
      level,
      isAccepted,
      receiverName,
      senderId,
    })
    return result
  }

  @action _sendActionRequestHost = async ({
    liveRoomId,
    userId,
    level,
    levelUpdatedAt,
    prevLevel,
    isMutedMic,
    isChangedUserInfo,
    senderName,
    senderId,
  }) => {
    const result = await LIVE_ROOM_ACTION['SEND_REQUEST_HOST']['SENDER']({
      liveRoomId,
      userId,
      level,
      levelUpdatedAt,
      prevLevel,
      isMutedMic,
      isChangedUserInfo,
      senderName,
      senderId,
    })
    return result
  }

  @action _sendActionAcceptedHost = async ({
    liveRoomId,
    userId,
    level,
    isAccepted,
    receiverName,
    senderId,
  }) => {
    const result = await LIVE_ROOM_ACTION['SEND_ACCEPTED_HOST']['SENDER']({
      liveRoomId,
      userId,
      level,
      isAccepted,
      receiverName,
      senderId,
    })
    return result
  }

  @action _receiveActionAcceptedGuest = async ({
    level,
    liveRoomId,
    userId,
    receiverName,
    isAccepted,
    senderId,
  }) => {
    try {
      if (senderId !== this.currLiveRoomUser['userId']) return
      console.log(
        '[LOG]',
        '[_receiveActionAcceptedGuest]',
        '[RECEIVE_REQUEST_Guest]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      /** 추후 여러가지 문구를 돌려서 보여주거나 다각화 고민? */
      if (!isAccepted) {
        this.store.appStateStore.onToast({
          msg: `${receiverName}님이 지금은 대화에 참여할 수 없어요`,
        })
        return true
      }

      const user = { id: userId }

      const result = await this.acceptToGuest(user)
      /** 추후 필요 없으면 삭제? */
      if (result) {
        this.store.appStateStore.onToast({
          msg: `${receiverName}님이 게스트 요청을 승인했어요`,
        })
      }

      return true
    }
    catch (error) {
      console.error('[LOG][_receiveActionAcceptedHost] error', error)
    }
  }

  @action _receiveActionAcceptedHost = async ({
    level,
    liveRoomId,
    userId,
    receiverName,
    isAccepted,
    senderId,
  }) => {
    try {
      if (senderId !== this.currLiveRoomUser['userId']) return
      console.log(
        '[LOG]',
        '[_receiveActionAcceptedHost]',
        '[RECEIVE_REQUEST_HOST]',
        liveRoomId,
        userId,
      )

      if (!liveRoomId || !userId) {
        console.error('liveRoomId or userId is not exist')
        return false
      }

      /** 추후 여러가지 문구를 돌려서 보여주거나 다각화 고민? */
      if (!isAccepted) {
        this.store.appStateStore.onToast({
          msg: `${receiverName}님이 지금은 대화에 참여할 수 없어요`,
        })
        return true
      }

      const user = { id: userId }

      const result = await this.acceptToHost(user)
      /** 추후 필요 없으면 삭제? */
      if (result) {
        this.store.appStateStore.onToast({
          msg: `${receiverName}님이 호스트 요청을 승인했어요`,
        })
      }

      return true
    }
    catch (error) {
      console.error('[LOG][_receiveActionAcceptedHost] error', error)
    }
  }
}

/**
 * agora state
 */

// const AudioRemoteState = state => {
//   let _state = null
//   switch (state) {
//     case 0:
//       _state = 'Stopped'
//       break
//     case 1:
//       _state = 'Starting'
//       break
//     case 2:
//       _state = 'Decoding'
//       break
//     case 3:
//       _state = 'Frozen'
//       break
//     case 4:
//       _state = 'Failed'
//       break
//     default:
//       break
//   }

//   return _state
// }

// const AudioRemoteStateReason = state => {
//   let _state = null
//   switch (state) {
//     case 0:
//       _state = 'Internal'
//       break
//     case 1:
//       _state = 'NetworkCongestion'
//       break
//     case 2:
//       _state = 'NetworkRecovery'
//       break
//     case 3:
//       _state = 'LocalMuted'
//       break
//     case 4:
//       _state = 'LocalUnmuted'
//       break
//     case 5:
//       _state = 'RemoteMuted'
//       break
//     case 6:
//       _state = 'RemoteUnmuted'
//       break
//     case 7:
//       _state = 'RemoteOffline'
//       break
//     default:
//       break
//   }

//   return _state
// }

// const StreamSubscribeState = state => {
//   let _state = null
//   switch (state) {
//     case 0:
//       _state = 'Idle'
//       break
//     case 1:
//       _state = 'NoSubscribed'
//       break
//     case 2:
//       _state = 'Subscribed'
//       break
//     case 3:
//       _state = 'Subscribing'
//       break
//     default:
//       break
//   }
//   return _state
// }

// const StreamPublishState = state => {
//   let _state = null
//   switch (state) {
//     case 0:
//       _state = 'Idle'
//       break
//     case 1:
//       _state = 'NoPublished'
//       break
//     case 2:
//       _state = 'Published'
//       break
//     case 3:
//       _state = 'Publishing'
//       break
//     default:
//       break
//   }
//   return _state
// }

// const ConnectionStateType = state => {
//   let _state = null
//   switch (state) {
//     case 1:
//       _state = 'Disconnected'
//       break
//     case 2:
//       _state = 'Connecting'
//       break
//     case 3:
//       _state = 'Reconnecting'
//       break
//     case 4:
//       _state = 'Disconnected'
//       break
//     case 5:
//       _state = 'Failed'
//       break
//     default:
//       break
//   }

//   return _state
// }

// const ConnectionChangedReason = reason => {
//   let _reason = null
//   switch (reason) {
//     case 0:
//       _reason = 'Connecting'
//       break
//     case 1:
//       _reason = 'JoinSuccess'
//       break
//     case 2:
//       _reason = 'Interrupted'
//       break
//     case 3:
//       _reason = 'BannedByServer'
//       break
//     case 4:
//       _reason = 'JoinFailed'
//       break
//     case 5:
//       _reason = 'LeaveChannel'
//       break
//     case 6:
//       _reason = 'InvalidAppId'
//       break
//     case 7:
//       _reason = 'InvalidChannelName'
//       break
//     case 8:
//       _reason = 'InvalidToken'
//       break
//     case 9:
//       _reason = 'TokenExpired'
//       break
//     case 10:
//       _reason = 'RejectedByServer'
//       break
//     case 11:
//       _reason = 'SettingProxyServer'
//       break
//     case 12:
//       _reason = 'RenewToken'
//       break
//     case 13:
//       _reason = 'ClientIpAddressChanged'
//       break
//     case 14:
//       _reason = 'KeepAliveTimeout'
//       break
//     case 15:
//       _reason = 'ProxyServerInterrupted'
//       break
//     default:
//       break
//   }
//   return _reason
// }

// const AudioLocalState = state => {
//   let _state = null
//   switch (state) {
//     case 1:
//       _state = 'Recording'
//       break
//     case 2:
//       _state = 'Encoding'
//       break
//     case 3:
//       _state = 'Failed'
//       break
//     case 0:
//       _state = 'Stoped'
//       break
//     default:
//       break
//   }

//   return _state
// }

// const AudioLocalError = error => {
//   let _error
//   switch (error) {
//     case 0:
//       _error = 'Ok'
//       break
//     case 1:
//       _error = 'Failure'
//       break
//     case 2:
//       _error = 'DeviceNoPermission'
//       break
//     case 3:
//       _error = 'DeviceBusy'
//       break
//     case 4:
//       _error = 'RecordFailure'
//       break
//     case 5:
//       _error = 'EncodeFailure'
//       break
//     default:
//       break
//   }

//   return _error
// }
