import { Flex, Caption1, Div } from '@components/atoms'
import { MixtapeListItem } from '@components/molecules'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import { colors } from '@resources/colors'
import { mobile } from '@styles/media'
import { observer } from 'mobx-react'

const UserDetailMixtapeSection = ({
  userDetail,
}) => {
  const { authStore, mixtapeStore } = useStore()
  const history = useHistory()

  const userMixtapeList = mixtapeStore.userMixtapeList || null

  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(true)

  const handleOffset = () => {
    setOffset(offset + 1)
  }

  React.useEffect(() => {
    mixtapeStore.init()
  }, [mixtapeStore])

  React.useEffect(() => {
    const fetchMixtapeList = async () => {
      if (userDetail?.id) {
        const more = await mixtapeStore.fetchUserMixtapeList({
          lastItem:
            mixtapeStore.userMixtapeList
            && mixtapeStore.userMixtapeList[
              mixtapeStore.userMixtapeList.length - 1
            ],
          __limit: 20,
          userId: userDetail.id,
          popularChartLevel: 0,
        })

        setHasMore(more >= 20)
      }
    }
    fetchMixtapeList()
  }, [mixtapeStore, userDetail, offset])

  return (
    <MixtapeListBox>
      <Flex justify="flex-end" align="flex-end">
        {
authStore?.currentUser?.id === userDetail?.id && (
            <Caption1
              color={colors.secondary}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/p/dj/mixtape/list')}
            >
            플라 관리
            </Caption1>
          )
        }
      </Flex>
      {
userMixtapeList?.length > 0 ? (
  <InfiniteScroll
    dataLength={userMixtapeList.length}
    scrollThreshold={0.9}
    next={handleOffset}
    hasMore={hasMore}
    hasChildren
    loader={
      <ReactLoading
        type="spin"
        color="black"
        style={
          {
            width: '50px',
            height: '50px',
            margin: '0 auto 50px auto',
          }
        }
      />
    }
    className="scroll_div"
    scrollableTarget="scrollableDiv"
    style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
  >
    <List>
      {
        userMixtapeList
              && userMixtapeList.map((item, index) => (
                <MixtapeListItem item={item} key={`${item.id}_${index}`} />
              ))
      }
    </List>
  </InfiniteScroll>
) : (
  <Div
    marginTop="84px"
    width="100%"
    textAlign="center"
    color={colors.grey_64}
  >
          플라가 없습니다
  </Div>
)
      }
    </MixtapeListBox>
  )
}

export default observer(UserDetailMixtapeSection)

const MixtapeListBox = styled(Flex)`
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
`

const List = styled(Flex)`
  margin-top: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -24px;

  width: calc(100% + 24px);

  > div {
    margin-left: 24px;
    margin-bottom: 24px;
  }

  @media ${mobile} {
    justify-content: center;
  }
`
